import React, { EventHandler, FunctionComponent, ReactNode } from 'react';
import { Field, WrappedFieldProps } from 'redux-form';
import { css } from 'aphrodite/no-important';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { Checkbox } from '../Checkbox';

import styles from './styles';

interface CheckboxListProps {
  options: Array<{ label: string | ReactNode; value: string }>;
  values: Array<string>;
  disabled: boolean;
  onChange: EventHandler<any>;
  messages?: Record<string, MessageDescriptor>;
}

export const CheckboxList: FunctionComponent<CheckboxListProps> = ({
  options,
  values = [],
  onChange,
  disabled,
}) => {
  const onValueChanged = (value: string, isChecked: boolean) => {
    if (!onChange) {
      return;
    }

    const isPresent = values.includes(value);
    if (isChecked && !isPresent) {
      onChange([...values, value]);
    }

    if (!isChecked && isPresent) {
      onChange(values.filter((v) => v !== value));
    }
  };

  return (
    <div>
      <ul className={css(styles.list)}>
        {options.map(({ label, value }) => (
          <li
            key={value}
            data-test-id={`checkbox-list-option-${value}`}
            className={css(styles.item)}
          >
            <Checkbox
              name={value}
              label={label}
              disabled={disabled}
              input={{
                value: values.includes(value),
                onChange: (checked: boolean) => onValueChanged(value, checked),
              }}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

const CheckboxListWrapper: FunctionComponent<CheckboxListProps & WrappedFieldProps> = ({
  input: { value = [], onChange },
  meta: { error, submitting, submitFailed },
  messages,
  ...rest
}) => {
  const actual = value || [];
  return (
    <>
      <CheckboxList
        {...rest}
        values={typeof actual === 'string' || !Array.isArray(actual) ? [actual] : actual}
        disabled={submitting}
        onChange={onChange}
      />
      {messages && submitFailed && error && messages[error] && (
        <FormattedMessage {...messages[error]}>
          {(text) => <span className={css(styles.error)}>{text}</span>}
        </FormattedMessage>
      )}
    </>
  );
};

interface CheckboxListFieldProps extends Omit<CheckboxListProps, 'values'> {
  name: string;
}

const CheckboxListField: FunctionComponent<CheckboxListFieldProps> = ({ name, ...rest }) => (
  <div data-test-id={`checkbox-list-field-${name}`}>
    <Field name={name} component={CheckboxListWrapper} {...rest} />
  </div>
);

export default CheckboxListField;
