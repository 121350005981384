import { defineMessages } from 'react-intl';

export default defineMessages({
  deleteSavedCardDel: {
    id: 'DeleteSavedCards.deleteSavedCardDel',
    defaultMessage: 'Delete',
  },
  deleteSavedCardDelButton: {
    id: 'DeleteSavedCards.deleteSavedCardDelButton',
    defaultMessage: 'Delete customer card',
  },
  deleteSavedCardHeader: {
    id: 'DeleteSavedCards.deleteSavedCardHeader',
    defaultMessage: 'Delete this customer card?',
  },
  savedCardNumber: {
    id: 'DeleteSavedCards.savedCardNumber',
    defaultMessage: 'Card no: {number}',
  },
  savedCardStartDate: {
    id: 'DeleteSavedCards.savedCardStartDate',
    defaultMessage: 'Start date: {validityStart}',
  },
  savedCardExpiryDate: {
    id: 'DeleteSavedCards.savedCardExpiryDate',
    defaultMessage: 'Expiry date: {validityEnd}',
  },
  savedCardNumberNA: {
    id: 'DeleteSavedCards.savedCardNumberNA',
    defaultMessage: 'Card no: N/A',
  },
  savedCardStartDateNA: {
    id: 'DeleteSavedCards.savedCardStartDateNA',
    defaultMessage: 'Start date: N/A',
  },
  savedCardExpiryDateNA: {
    id: 'DeleteSavedCards.savedCardExpiryDateNA',
    defaultMessage: 'Expiry date: N/A',
  },
  passengerNotFoundErrorMessage: {
    id: 'DeleteSavedCards.passengerNotFoundErrorMessage',
    defaultMessage: "Customer's passenger not found, please refresh the page and try again",
  },
  savedCardNotFoundErrorMessage: {
    id: 'DeleteSavedCards.savedCardNotFoundErrorMessage',
    defaultMessage: "Passenger's saved card not found, please refresh the page and try again",
  },
  genericErrorMessage: {
    id: 'DeleteSavedCards.genericErrorMessage',
    defaultMessage: "Failed to delete passenger's saved card, please try again",
  },
});
