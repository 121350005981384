import request from '@contactcentre-web/utils/request';

export interface ManagedGroup {
  name: string;
  number: number;
  isToc?: boolean;
  hasSsoProvider?: boolean;
  ssoConfiguration?: {
    forceSsoUsers: boolean;
    hasSsoProvider: boolean;
  };
}

export interface ManagedGroupWithId extends ManagedGroup {
  id: string;
}

export interface Permissions {
  canManageUsers: boolean;
  canEditUsers: boolean;
  canApproveDiscretionaryRefunds: boolean;
  canApproveCompensations: boolean;
  canLogCall: boolean;
  canEditCrmIntegrationMappings: boolean;
  canUseBookingFlow: boolean;
  canEditCustomerProfile: boolean;
  canRequestDiscretionaryRefund: boolean;
  canMoveOrderToAnotherAccount: boolean;
  canReplaceOrder: boolean;
  canResendEmails: boolean;
  canBebocPnrIntoEuPlatform: boolean;
  canRequestStandardRefund: boolean;
  canCancelRailcard: boolean;
  canWaiveFraudBlocker: boolean;
  canRequestCoj: boolean;
  canResetPasswords: boolean;
  canUseCurrencyConverter: boolean;
  canOperateFlexiSeasonTickets: boolean;
  canSetupNewManagedGroup: boolean;
  canRequestCompensation: boolean;
  canRequestExternalRefund: boolean;
  canBulkUpload: boolean;
  canRequestUnlimitedRefund: boolean;
  canApproveUnlimitedRefund: boolean;
  canConvertFulfilments: boolean;
  signoutDisabled: boolean;
  canAddPendingMecClaim: boolean;
  canViewMecClaim: boolean;
  canManageMecClaim: boolean;
  canGenerateMecReport: boolean;
  canManageMecReasonCodes: boolean;
  canValidatePaperTickets: boolean;
  canFinalReviewPaperTickets: boolean;
  canRevokeCustomerPassword: boolean;
}

export interface Features {
  customerType: boolean;
}

export interface UserDetails {
  firstName: string;
  lastName: string;
  homeManagedGroupId: string;
  managedGroup: ManagedGroupWithId;
  managedGroups: Array<ManagedGroup>;
  permissions: Permissions;
  roleName: string;
  site: string;
  userId: string;
  userName: string;
  features: Features;
}

export interface BusinessSettings {
  persistPassengerDetails: boolean;
  sncfDiscountCardRetailFlowEnabled: boolean;
}

export const getUserDetails = () => request<UserDetails>('/user-info/me');

export const getBusinessSettings = () =>
  request<BusinessSettings>('/platformapi/tenantSettings', {
    method: 'GET',
    headers: {
      Accept: 'application/vnd.trainline.platform-reference-data.v1+json',
    },
  });
