import { StyleSheet } from 'aphrodite/no-important';
import {
  spacers,
  borderRadius,
  typography,
  fonts,
} from '@trainline/depot-web/css/themes/trainline';

// TODO: refactor colors if reuse ? since they are not part of ta-components.
export default StyleSheet.create({
  container: {
    border: 'solid 1px #B9D3FC',
    background: '#F4F7FF',
    borderRadius: borderRadius.dp_r4,
    padding: spacers.dp_s32,
    marginBottom: spacers.dp_s32,
  },
  title: {
    fontFamily: typography.dp_title3_font_family,
    fontSize: typography.dp_title3_font_size,
    fontWeight: fonts.dp_font_weight_bold,
    lineHeight: typography.dp_title3_line_height,
    letterSpacing: typography.dp_title3_letter_spacing,
    paddingBottom: spacers.dp_s24,
  },
  fieldContainer: {
    width: '602px',
  },
  button: {
    marginTop: spacers.dp_s8,
  },
});
