import {
  ExtractionResult,
  MIN_CUSTOMERID_COUNT,
  MAX_CUSTOMERID_COUNT,
} from '@contactcentre-web/customer-revocation/utils/utils';

import messages from '../../messages';

import { NotificationType, RevocationNotificationProps } from './RevocationNotification';

export const mapNotificationProps = ({
  isSuccessful,
  customerIds,
  validationErrors,
  validationWarnings,
}: ExtractionResult) => {
  const notificationState: RevocationNotificationProps = {
    type: NotificationType.Error,
    messages: [],
    values: {},
  };
  if (isSuccessful) {
    notificationState.type = NotificationType.Success;
    notificationState.messages.push(messages.verificationSuccessMessage);
    notificationState.values = { ...notificationState.values, count: customerIds.length };
  }
  if (validationWarnings.duplicatesRemovedCount > 0) {
    notificationState.type = NotificationType.Warning;
    notificationState.messages.push(messages.duplicatesRemovedWarning);
    notificationState.values = {
      ...notificationState.values,
      duplicatesCount: validationWarnings.duplicatesRemovedCount,
    };
  }
  if (validationWarnings.longerThanEightCharactersEntryCount > 0) {
    notificationState.type = NotificationType.Warning;
    notificationState.messages.push(messages.moreThanEightCharactersWarning);
    notificationState.values = {
      ...notificationState.values,
      longerThanEightCharactersEntryCount: validationWarnings.longerThanEightCharactersEntryCount,
    };
  }
  if (validationErrors.isOutOfRange) {
    notificationState.type = NotificationType.Error;
    notificationState.messages = [messages.verificationInvalidInputRange]; // Error message overrides all
    notificationState.values = {
      count: customerIds.length,
      min: MIN_CUSTOMERID_COUNT,
      max: MAX_CUSTOMERID_COUNT,
    };
  }
  return notificationState;
};
