import React from 'react';

import ErrorBoundary from '@contactcentre-web/common/ErrorBoundary/ErrorBoundary';
import Customer from '@contactcentre-web/customer/Customer';

const CustomerPage = () => (
  <ErrorBoundary>
    <Customer />
  </ErrorBoundary>
);

export default CustomerPage;
