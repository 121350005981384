import { StyleSheet } from 'aphrodite/no-important';
import { palettes, spacers, borderRadius } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    padding: spacers.dp_s32,
    border: `1px solid ${palettes.dp_app_border_base}`,
    borderRadius: borderRadius.dp_r4,
    backgroundColor: palettes.dp_app_backdrop_dark,
    width: '70%',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: spacers.dp_s16,
  },
});
