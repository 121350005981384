import { defineMessages } from 'react-intl';

export default defineMessages({
  void: {
    id: 'OrderActions.voidBookingButton',
    defaultMessage: 'Void',
  },

  cancelExpiryTime: {
    id: 'OrderActions.cancelExpiryTime',
    defaultMessage: ' ({expiryTime} left)',
  },

  viewBookings: {
    id: 'OrderActions.viewBookings',
    defaultMessage: 'View bookings',
  },

  orderHistory: {
    id: 'OrderActions.orderHistory',
    defaultMessage: 'Order history',
  },

  refund: {
    id: 'OrderActions.refund',
    defaultMessage: 'Refund',
  },
  replace: {
    id: 'OrderActions.replace',
    defaultMessage: 'Replace',
  },
  changeOfJourney: {
    id: 'OrderActions.changeOfJourney',
    defaultMessage: 'Change of Journey refund',
  },
  lock: {
    id: 'OrderActions.lock',
    defaultMessage: 'Lock',
  },
  openInCapitaine: {
    id: 'OrderActions.openInCapitaine',
    defaultMessage: 'Open in BEBOC /Capitaine',
  },
  currencyConverter: {
    id: 'OrderActions.currencyConverter',
    defaultMessage: 'Currency Converter',
  },
  resendEmail: {
    id: 'OrderActions.resendEmail',
    defaultMessage: 'Resend email',
  },
  convertToCTR: {
    id: 'OrderActions.convertToCTR',
    defaultMessage: 'Convert to CTR',
  },
  refreshBooking: {
    id: 'OrderActions.refreshBooking',
    defaultMessage: 'Refresh booking',
  },
  fulfilmentConverter: {
    id: 'OrderActions.fulfilmentConverter',
    defaultMessage: 'Fulfilment converter',
  },
  coj: {
    id: 'OrderActions.coj',
    defaultMessage: 'Change Journey',
  },
  logCall: {
    id: 'OrderActions.logCall',
    defaultMessage: 'Log Call',
  },
  compensation: {
    id: 'OrderActions.compensation',
    defaultMessage: 'Compensation',
  },
  customerEmailError: {
    id: 'OrderActions.customerEmailError',
    defaultMessage:
      "A contact cannot be logged as there is no email address for the customer. Please update the customer's account with an email address.",
  },
  discretionaryRefund: {
    id: 'OrderActions.discretionaryRefund',
    defaultMessage: 'Discretionary refund',
  },
  tcsRefund: {
    id: 'OrderActions.tcsRefund',
    defaultMessage: "T&C's refund",
  },
});
