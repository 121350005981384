import React, { useEffect, useState } from 'react';
import { css } from 'aphrodite/no-important';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { IconSearch } from '@trainline/depot-web';

import TextField from '@contactcentre-web/common/TextField';
import Button from '@contactcentre-web/common/Button/Button';
import { pushToDataLayer } from '@contactcentre-web/utils/tracker';

import styles from './styles';
import messages from './messages';

const REDUX_SEARCH_FORM_ID = 'reduxSearchForm';

type FormData = {
  search?: string;
};

interface Props {
  inline?: boolean;
}

const Search = ({
  handleSubmit,
  initialize,
  initialValues,
  inline,
}: Props & InjectedFormProps<FormData, Props>) => {
  const history = useHistory();
  const [inputFocused, setInputFocused] = useState(false);

  useEffect(() => {
    // Hack to fix values not being initialized from inititalValues until 2nd render
    // https://github.com/redux-form/redux-form/issues/621
    initialize(initialValues);
  }, []);

  const onSumbit = ({ search }: FormData) => {
    if (search) {
      history.push(`/customers/?q=${encodeURIComponent(search.trim())}`);

      pushToDataLayer({
        event: 'ga_search',
        ga_event: {
          category: 'search',
          action: 'search',
          value: 0,
          nonInteraction: 0,
        },
        search_form_type: 'standard',
      });
      pushToDataLayer({
        event: 'ga4_search',
        event_name: 'search',
        event_params: {
          search_form_type: 'standard',
        },
      });
    }
  };

  return (
    <form id={REDUX_SEARCH_FORM_ID} onSubmit={handleSubmit(onSumbit)}>
      <section className={css(styles.container, inline && styles.searchHeaderInline)}>
        <div className={css(styles.searchInputContainer)}>
          <IconSearch
            className={`search-input-icon ${css(
              [styles.searchIcon],
              inputFocused && styles.searchIconActive
            )}`}
          />
          <TextField
            data-testid="search-v2-text-field"
            type="search"
            name="search"
            styleSheet={{
              input: styles.searchInput,
            }}
            displayErrorContainer={false}
            onFocus={() => setInputFocused(true)}
            onBlur={() => setInputFocused(false)}
            role="searchbox"
            aria-details="Enter a booking reference, order ID or email"
          />
        </div>
        <Button
          type="submit"
          variant="primary"
          size="small"
          testId="search-form-submit"
          aria-details="Search for bookings"
        >
          <FormattedMessage {...messages.searchButton} />
        </Button>
      </section>
    </form>
  );
};

const validate = ({ search }: FormData) => ({
  search: !search ? 'searchFieldMustNotBeEmpty' : undefined,
});

export default reduxForm<FormData, Props>({
  form: REDUX_SEARCH_FORM_ID,
  validate,
  enableReinitialize: true,
})(Search);
