import request from '@contactcentre-web/utils/request';

interface NoteGet {
  topic: string;
  topicId: string;
}

interface NoteSet extends NoteGet {
  note: string;
}

export const getNotesRequest = ({ topic, topicId }: NoteGet) =>
  request(`/api/notes?topic=${topic}&topicId=${topicId}`);

export const addNoteRequest = ({ topic, topicId, note }: NoteSet) =>
  request('/api/notes', {
    method: 'POST',
    body: {
      body: note,
      Topic: topic,
      TopicId: topicId,
    },
  });
