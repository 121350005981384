import Price from '@contactcentre-web/redux-common/types/Price';
import ProductType from '@contactcentre-web/redux-common/types/ProductType';

export type ProductDetails = {
  departureDate?: Date;
  from?: string;
  to?: string;
  inventoryReference?: string;
  isReturn?: boolean;
  name?: string;
  localAreaValidity?: string;
  returnDate?: Date;
};

export type CustomerBooking = {
  type: ProductType;
  orderReference: string;
  orderDate: Date;
  bookingStatus: string;
  customerId: string;
  price: Price;
  productDetails: ProductDetails;
  error?: boolean;
};

export enum ColumnName {
  BY_DEPARTURE = 'departureDate',
  BY_ORDER_DATE = 'orderDate',
  BY_ORDER_ID = 'orderReference',
  BY_BOOKING_STATUS = 'bookingStatus',
  BY_PRICE = 'price',
  BY_FROM = 'from',
  BY_CTR_PNR = 'inventoryReference',
  BY_RETURN = 'returnDate',
}

export type AppliedSort = {
  sortPropName: string;
  sortOrderDesc: boolean;
};

export enum TicketTypeFilters {
  TrainAndCoaches = 'trainAndCoaches',
  Season = 'season',
  LondonTravelcard = 'londonTravelcard',
  Railcard = 'railcard',
}

export enum BookingStatusFilters {
  Issued = 'Issued',
  Failed = 'Failed',
  Cancelled = 'Cancelled',
  Cancelling = 'Cancelling',
  Exchanged = 'Exchanged',
  Issuing = 'Issuing',
  Locked = 'Locked',
  PartiallyCancelled = 'PartiallyCancelled',
  Superseded = 'Superseded',
  Unknown = 'Unknown',
}

export type FilterType = TicketTypeFilters | BookingStatusFilters;
