import React, { Dispatch, FunctionComponent, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { Heading } from '@trainline/depot-web';

import Loader from '@contactcentre-web/common/Loader';
import Link from '@contactcentre-web/common/Link';
import PageContainer from '@contactcentre-web/common/PageContainer';
import { useCanManageRoles } from '@contactcentre-web/hooks/api/useCanManageRoles';
import type State from '@contactcentre-web/redux-common/types/State';
import type Action from '@contactcentre-web/redux-common/types/Action';

import { actions, selectors } from '../../module';

import ChangeRoleForm from './components/ChangeRoleForm/ChangeRoleForm';
import messages from './messages';
import styles from './styles';

export const FORM_ID = 'changeRole';

interface Params {
  username: string;
}

interface StateProps {
  isChangeRoleSuccess: boolean;
  isLoadUserPending: boolean;
  isUserLoaded: boolean;
  isLoadingEditedUserMgAndRoles: boolean;
  editedUserManagedGroupsAndRoles?: Array<{
    managedGroup: string;
    role: string;
  }>;
  userId: string;
}

interface DispatchProps {
  loadUser: (username: string) => void;
  resetState: () => void;
  loadEditedUserMgAndRoles: (username: string) => void;
}

type Props = StateProps & DispatchProps;

export const ChangeRole: FunctionComponent<Props> = ({
  isChangeRoleSuccess,
  isLoadUserPending,
  isUserLoaded,
  loadUser,
  resetState,
  loadEditedUserMgAndRoles,
  isLoadingEditedUserMgAndRoles,
  editedUserManagedGroupsAndRoles,
  userId,
}) => {
  const history = useHistory();
  const { username } = useParams<Params>();
  const loadUserRef = useRef(loadUser);
  const resetStateRef = useRef(resetState);
  const loadEditedUserMgAndRolesRef = useRef(loadEditedUserMgAndRoles);
  const { data: editingUserRoles, isLoading: isLoadingEditingUserRoles } = useCanManageRoles();

  React.useEffect(() => {
    resetStateRef.current();
    return () => resetStateRef.current();
  }, [resetStateRef]);

  React.useEffect(() => {
    if (!isUserLoaded) {
      loadUserRef.current(username);
    }
  }, [isUserLoaded, username, loadUserRef]);

  React.useEffect(() => {
    if (isUserLoaded) {
      loadEditedUserMgAndRolesRef.current(userId);
    }
  }, [loadEditedUserMgAndRolesRef, userId, isUserLoaded]);

  React.useEffect(() => {
    if (isChangeRoleSuccess) {
      history.push(`/edit_user/${username}?successfulAction=changeRole`);
    }
  }, [isChangeRoleSuccess]);

  const isLoadingDetails =
    isLoadUserPending || isLoadingEditedUserMgAndRoles || isLoadingEditingUserRoles;

  return (
    <PageContainer>
      <div className={css(styles.container)}>
        {isLoadingDetails ? (
          <Loader />
        ) : (
          <div className={css(styles.innerContainer)}>
            <div className={css(styles.header)}>
              <Heading typeStyle="title1" as="h1" color="base">
                <FormattedMessage
                  {...messages.editUser}
                  values={{
                    username: <span className={css(styles.username)}>{username}</span>,
                  }}
                />
              </Heading>
            </div>
            <ChangeRoleForm
              userId={userId}
              initialValues={{
                managedGroupRoles: editedUserManagedGroupsAndRoles,
              }}
              editingUserRoles={editingUserRoles || []}
            />
            <div className={css(styles.cancel)}>
              <Link linkType="internal" to={`/edit_user/${username}`}>
                <FormattedMessage {...messages.cancel} />
              </Link>
            </div>
          </div>
        )}
      </div>
    </PageContainer>
  );
};

const mapStateToProps = (state: State) => ({
  isChangeRolePending: selectors.isChangeRolePending(state),
  isChangeRoleSuccess: selectors.isChangeRoleSuccess(state),
  isLoadUserPending: selectors.isLoadUserPending(state),
  isUserLoaded: selectors.isUserLoaded(state),
  editedUserManagedGroupsAndRoles: selectors.getUserManagedGroupsAndRoles(state),
  isLoadingEditedUserMgAndRoles: selectors.isLoadingUserMgAndRoles(state),
  userId: selectors.getUserId(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  loadUser: (username: string) => dispatch(actions.loadUserAttempt(username)),
  resetState: () => dispatch(actions.resetState()),
  loadEditedUserMgAndRoles: (username: string) =>
    dispatch(actions.loadUserMgAndRolesAttempt(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeRole);
