import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage, useIntl } from 'react-intl';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { Heading, StatusMessage, Paragraph } from '@trainline/depot-web';

import { SeachFormData } from '@contactcentre-web/hooks/api/useVirtualTerminal';
import TextField from '@contactcentre-web/common/TextField';
import Link from '@contactcentre-web/common/Link';
import Button from '@contactcentre-web/common/Button';

import messages from './messages';
import styles from './styles';

export const FORM_ID = 'VirtualTerminalSearchForm';
export interface Props {
  onSearch: (orderId: string) => void;
  message:
    | 'transactionFound'
    | 'transactionNotFound'
    | 'userWithoutPermission'
    | 'requiredOrderId'
    | 'defaultErrorMessage'
    | undefined;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  url?: string;
}
const SearchForm = ({
  handleSubmit,
  onSearch,
  message,
  isLoading,
  url,
  isError,
  isSuccess,
}: Props & InjectedFormProps<SeachFormData, Props>) => {
  const { formatMessage } = useIntl();
  return (
    <form
      id={FORM_ID}
      className={css([
        styles.container,
        message === 'transactionFound' && styles.containerWithTransaction,
      ])}
      onSubmit={handleSubmit(({ friendlyOrderId }) => onSearch(friendlyOrderId))}
    >
      <Heading typeStyle="title3" as="h2" color="base">
        <FormattedMessage {...messages.title} />
      </Heading>
      <div className={css(styles.body)}>
        {url && isSuccess && (
          <div className={css(styles.notification)}>
            <StatusMessage status="info">
              <FormattedMessage
                data-testId="search-form-order-link"
                {...messages.originalOrderLink}
                values={{
                  a: (msg: string) => (
                    <Link linkType="external" href={url}>
                      {msg}
                    </Link>
                  ),
                  b: (msg: string) => <b>{msg}</b>,
                }}
              />
            </StatusMessage>
          </div>
        )}
        <div className={css(styles.inputContainer)}>
          <TextField
            label={formatMessage(messages.orderId)}
            name="friendlyOrderId"
            messages={messages}
          />
          <Button
            type="submit"
            variant="secondary"
            size="small"
            styleSheet={styles.button}
            loading={isLoading}
          >
            <FormattedMessage {...messages.validateButton} />
          </Button>
        </div>
        {message && (
          <div className={css(styles.messageContainer)}>
            <Paragraph
              typeStyle="body"
              as="p"
              color={isError ? 'negative' : 'subdued'}
              fontWeight="regular"
              testId="search-form-message"
            >
              <FormattedMessage {...messages[message]} />
            </Paragraph>
          </div>
        )}
      </div>
    </form>
  );
};

export const validate = ({
  friendlyOrderId,
}: SeachFormData): Record<string, string | undefined> => ({
  friendlyOrderId: friendlyOrderId ? undefined : 'validationFriendlyOrderIdRequired',
});

export default reduxForm<SeachFormData, Props>({
  form: FORM_ID,
  validate,
})(SearchForm);
