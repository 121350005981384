import { defineMessages } from 'react-intl';

export default defineMessages({
  searchUser: {
    id: 'SearchUser.searchUser',
    defaultMessage: 'Search for user',
  },
  search: {
    id: 'SearchUser.search',
    defaultMessage: 'Search',
  },
  description: {
    id: 'SearchUser.description',
    defaultMessage:
      'Tips: Searching by name only works if you use first and last name. If it is one word, it will be treated as username',
  },
  username: {
    id: 'SearchUser.username',
    defaultMessage: 'First and last name or username',
  },
  usernameIsRequired: {
    id: 'SearchUser.usernameIsRequired',
    defaultMessage: 'Name or username is required',
  },
  userNotFound: {
    id: 'SearchUser.userNotFound',
    defaultMessage: 'User not found',
  },
  genericError: {
    id: 'SearchUser.genericError',
    defaultMessage: 'Something went wrong, please try again',
  },
  results: {
    id: 'SearchUser.results',
    defaultMessage: '{numberOfResults} results for "{searchQuery}"',
  },
  disabled: {
    id: 'SearchUser.disabled',
    defaultMessage: '(disabled)',
  },
});
