import ProductType from '@contactcentre-web/redux-common/types/ProductType';

import { CustomerBooking, FilterType, TicketTypeFilters, BookingStatusFilters } from './types';

const ticketTypeFilterToProductType = {
  [TicketTypeFilters.TrainAndCoaches]: ProductType.TravelProduct,
  [TicketTypeFilters.Season]: ProductType.Season,
  [TicketTypeFilters.LondonTravelcard]: ProductType.Travelcard,
  [TicketTypeFilters.Railcard]: ProductType.Railcard,
};

export const getFilteredBookings = (bookings: Array<CustomerBooking>, filters: Set<FilterType>) => {
  const bookingsWithId = bookings.map((booking, index) => ({
    id: index,
    ...booking,
  }));

  let productsWithSameType = [] as Array<CustomerBooking & { id: number }>;
  let productsWithSameStatus = [] as Array<CustomerBooking & { id: number }>;

  filters.forEach((filter) => {
    if (Object.values(TicketTypeFilters).includes(filter as TicketTypeFilters)) {
      productsWithSameType = [
        ...productsWithSameType,
        ...bookingsWithId.filter(
          ({ type }) => type === ticketTypeFilterToProductType[filter as TicketTypeFilters]
        ),
      ];
    }

    if (Object.values(BookingStatusFilters).includes(filter as BookingStatusFilters)) {
      productsWithSameStatus = [
        ...productsWithSameStatus,
        ...bookingsWithId.filter(({ bookingStatus }) => bookingStatus === filter),
      ];
    }
  });

  if (
    Object.values(TicketTypeFilters).some((ticketTypeFilter) => filters.has(ticketTypeFilter)) &&
    Object.values(BookingStatusFilters).some((bookingStatusFilter) =>
      filters.has(bookingStatusFilter)
    )
  ) {
    return productsWithSameType.filter((productWithSameType) =>
      productsWithSameStatus.find(
        (productWithSameStatus) => productWithSameStatus.id === productWithSameType.id
      )
    );
  }

  return [...productsWithSameStatus, ...productsWithSameType];
};
