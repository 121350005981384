import { StyleSheet } from 'aphrodite/no-important';
import {
  borderRadius,
  fonts,
  spacers,
  typography,
} from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    borderRadius: borderRadius.dp_r4,
    border: `1px solid ${colors.gray.alpha2}`,
    marginBottom: spacers.dp_s32,
    boxShadow: '0 8px 12px 0 rgba(33, 49, 77, 0.1)',
  },
  header: {
    display: 'flex',
    borderBottom: `1px solid ${colors.gray.alpha12}`,
    padding: `${spacers.dp_s16} ${spacers.dp_s32}`,
    gap: spacers.dp_s24,
  },
  colAgent: {
    overflowWrap: 'break-word',
    maxWidth: '250px',
  },
  labelPrimary: {
    color: colors.info.base,
    lineHeight: typography.dp_body_line_height,
    fontWeight: fonts.dp_font_weight_bold,
  },
  labelSecondary: {
    fontSize: typography.dp_micro_font_size,
    letterSpacing: typography.dp_micro_letter_spacing,
    lineHeight: typography.dp_micro_line_height,
    marginTop: spacers.dp_s4,
    textTransform: 'uppercase',
    fontWeight: fonts.dp_font_weight_bold,
    color: colors.gray.alpha30,
  },
  body: {
    padding: `${spacers.dp_s32}`,
  },
});
