import { Heading } from '@trainline/depot-web';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import ApprovalQueue from './components/ApprovalQueue/ApprovalQueue';
import messages from './messages';
import styles from './styles';

const ApprovalQueueContainer = () => (
  <>
    <div className={css(styles.headingContainer)}>
      <Heading typeStyle="title1" as="h1" color="base">
        <FormattedMessage {...messages.virtualTerminal} />
      </Heading>
    </div>
    <section className={css(styles.container)}>
      <ApprovalQueue />
    </section>
  </>
);
export default ApprovalQueueContainer;
