import React, { useEffect, Dispatch } from 'react';
import { connect, useSelector } from 'react-redux';
import qs from 'query-string';
import { useRouteMatch, useHistory, useLocation, Redirect } from 'react-router-dom';

import { getCurrentManagedGroupId } from '@contactcentre-web/authentication/redux/selectors';
import type State from '@contactcentre-web/redux-common/types/State';
import type Action from '@contactcentre-web/redux-common/types/Action';
import Loader from '@contactcentre-web/common/Loader';

import { iframePostMessageGenerator } from '../utils/iframePostMessage';

import { actions } from './module';

interface StateProps {
  changedOrderReference?: string;
  managedGroupId?: string;
}

interface DispatchProps {
  recordAgent: (orderReference: string, bookingId: string) => void;
}

interface Props extends StateProps, DispatchProps {}

type Params = {
  customerId: string;
  orderReference: string;
  bookingId: string;
};

export const CojRedirect = ({ recordAgent, changedOrderReference }: Props) => {
  const {
    params: { customerId, orderReference, bookingId },
  } = useRouteMatch<Params>();
  const history = useHistory();
  const { search } = useLocation();
  const { bookingchanged: bookingChanged } = qs.parse(search);
  const recordAgentRef = React.useRef(recordAgent);

  const managedGroupId = useSelector(getCurrentManagedGroupId);
  useEffect(() => {
    if (bookingChanged) {
      recordAgentRef.current(orderReference, bookingId);
    }
  }, [orderReference, bookingId, bookingChanged, recordAgentRef]);

  useEffect(() => {
    if (bookingChanged && changedOrderReference) {
      iframePostMessageGenerator({
        orderReference: changedOrderReference,
        action: 'UPDATE',
        tenantId: managedGroupId,
      });
    }
  }, [bookingChanged, changedOrderReference]);

  useEffect(() => {
    if (changedOrderReference && bookingChanged === 'true') {
      history.push(
        `/customers/${customerId}/bookings/${changedOrderReference}?bookingchanged=true`
      );
    }
  }, [changedOrderReference, bookingChanged, customerId]);

  if (!bookingChanged || bookingChanged === 'false') {
    return <Redirect to={`/customers/${customerId}/bookings/${orderReference}`} />;
  }

  if (!changedOrderReference) {
    return <Loader />;
  }

  return null;
};

const mapStateToProps = (state: State) => ({
  changedOrderReference: state.cojRedirect.changedOrderReference,
  managedGroupId: getCurrentManagedGroupId(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  recordAgent: (customerId: string, orderReference: string) =>
    dispatch(actions.recordAgent(customerId, orderReference)),
});

const ConnectedCojRedirect = connect(mapStateToProps, mapDispatchToProps)(CojRedirect);

ConnectedCojRedirect.displayName = 'CojRedirect';

export default ConnectedCojRedirect;
