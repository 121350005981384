import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormSection, Field, reduxForm } from 'redux-form';
import { useParams, useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { css } from 'aphrodite/no-important';
import { StatusMessage } from '@trainline/depot-web';

import Loader from '@contactcentre-web/common/Loader';
import Link from '@contactcentre-web/common/Link';
import PageContainer from '@contactcentre-web/common/PageContainer';
import Button from '@contactcentre-web/common/Button';
import matchShape from '@contactcentre-web/utils/shape/match';
import {
  canWaiveFraudBlocker as canWaiveFraudBlockerSelector,
  getCurrentManagedGroupId,
} from '@contactcentre-web/authentication/redux/selectors';

import ProductTotals from '../common/ProductTotals';
import RefundNonProcessableQuotePrompt from '../common/RefundNonProcessableQuotePrompt';
import { iframePostMessageGenerator } from '../../utils/iframePostMessage';

import SameDayVoidStatus from './components/SameDayVoidStatus/SameDayVoidStatus';
import SameDayVoidBooking from './components/SameDayVoidBooking/SameDayVoidBooking';
import SameDayVoidFees from './components/SameDayVoidFees/SameDayVoidFees';
import SameDayVoidTotal from './components/SameDayVoidTotal/SameDayVoidTotal';
import actions from './module';
import selectors from './selectors';
import messages from './messages';
import styles from './styles';

const FORM_ID = 'SameDayForm';

export const SameDayVoid = ({
  order,
  bookings,
  isLoadPending,
  isLoadSuccess,
  isLoadFailed,
  isRefundSuccess,
  isRefundPending,
  isRefundFailed,
  isRefundFailedWithErrors,
  isUpdatePending,
  isUpdateFailed,
  quote,
  ticketReturnModes,
  handleSubmit,
  load,
  change,
  canWaiveFraudBlocker,
  hasRefundPermissions,
  managedGroupId,
  dispose,
}) => {
  const history = useHistory();
  const { customerId } = useParams();
  const loadRef = React.useRef(load);
  const {
    orderReference,
    voidable: { isVoidable },
  } = order;

  React.useEffect(() => {
    if (!isRefundSuccess && !isRefundFailed && !isRefundPending) {
      loadRef.current();
    }
  }, [loadRef, isRefundSuccess, isRefundPending, isRefundFailed]);

  React.useEffect(
    () =>
      history.listen(() => {
        dispose();
      }),
    []
  );

  React.useEffect(() => {
    if (isRefundSuccess) {
      iframePostMessageGenerator({ orderReference, action: 'VOID', tenantId: managedGroupId });
    }
  }, [isRefundSuccess]);

  if (isLoadPending || !order) {
    return (
      <div className={css(styles.loaderContainer)}>
        <Loader />
      </div>
    );
  }

  if (isLoadFailed) {
    return (
      <div className={css(styles.loadErrorContainer)}>
        <StatusMessage status="negative">
          <FormattedMessage {...messages.loadFailed} />
        </StatusMessage>
      </div>
    );
  }

  if (!isLoadSuccess) {
    return null;
  }

  if (!isRefundPending && !isRefundSuccess && !isRefundFailed && !isVoidable) {
    return (
      <PageContainer>
        <div className={css(styles.loadErrorContainer)}>
          <StatusMessage status="negative">
            <FormattedMessage {...messages.notVoidable} />
          </StatusMessage>
        </div>
      </PageContainer>
    );
  }

  const returnAdvisory = ticketReturnModes.map((m, i) => (
    <span key={`returnModeAdvisory-${i}`}>
      {' '}
      <FormattedMessage
        {...messages[m.name]}
        values={{
          address: m.returnAddress
            ? Object.values(m.returnAddress)
                .filter((x) => !!x)
                .join(', ')
            : '',
        }}
      />
    </span>
  ));

  const noPermissionsAlert = hasRefundPermissions ? undefined : (
    <div className={css(styles.notification)}>
      <StatusMessage status="warning">
        <FormattedMessage {...messages.noPermissions} testId="no-refund-permissions" />
      </StatusMessage>
    </div>
  );

  const generateWarnings = (warnings) => {
    const alerts = warnings
      .map(
        (warning) =>
          messages[`warning_${warning.reasonCode.toLowerCase()}`] && (
            <StatusMessage key={warning} status="warning">
              <FormattedMessage
                {...messages[`warning_${warning.reasonCode.toLowerCase()}`]}
                values={{ b: (msg) => <b>{msg}</b>, br: <br /> }}
                testId="quote-warning-alert"
              />
            </StatusMessage>
          )
      )
      .filter((alert) => alert != null);

    if (warnings.length > 0 && alerts.length === 0) {
      return [
        <StatusMessage status="warning">
          <FormattedMessage {...messages.warning_unspecified} testId="quote-warning-alert" />
        </StatusMessage>,
      ];
    }

    return alerts;
  };

  return (
    <PageContainer>
      <form id="SameDayVoidForm" onSubmit={handleSubmit}>
        {isRefundSuccess && (
          <div className={css(styles.notification)}>
            <StatusMessage status="info">
              <FormattedMessage
                {...messages.infoRefundSubmittedMessage}
                values={{
                  link: (msg) => (
                    <Link
                      linkType="internal"
                      to={`/customers/${customerId}/bookings/${orderReference}/history`}
                    >
                      {msg}
                    </Link>
                  ),
                }}
                testId="SDVRefundSubmittedInfo"
              />
              {returnAdvisory}
            </StatusMessage>
          </div>
        )}
        {quote.unprocessableReasons &&
          quote.unprocessableReasons.length > 0 &&
          generateWarnings(quote.unprocessableReasons)}
        <FormSection name="refundables">
          {bookings.map((booking, idx) => (
            <SameDayVoidBooking
              key={idx}
              {...booking}
              number={idx + 1}
              isSDVRefundsCCRSEnabled
              noPermissionsAlert={noPermissionsAlert}
            />
          ))}
          <SameDayVoidStatus
            isRefundFailed={isRefundFailedWithErrors}
            isUpdateFailed={isUpdateFailed}
          />
          <section className={css(styles.ProductTotals)}>
            <ProductTotals productTotal={quote.totalRefundableAmount} discounts={quote.discounts} />
          </section>
          <SameDayVoidFees
            isUpdatePending={isUpdatePending}
            isRefundSuccess={isRefundSuccess}
            {...quote}
          />
        </FormSection>

        <Field
          name="overrideFraudProvider"
          component={(field) => <input type="hidden" {...field.input} />}
        />
        <SameDayVoidTotal {...quote} isUpdatePending={isUpdatePending} />
        {!isRefundSuccess && quote.canProcess && hasRefundPermissions && (
          <div className={css(styles.footer)}>
            <Button
              type="submit"
              variant="primary"
              size="xlarge"
              testId="processRefundButton"
              loading={isRefundPending}
              disabled={isUpdatePending || isRefundPending}
            >
              <FormattedMessage {...messages.processRefund} />
            </Button>
          </div>
        )}
        {canWaiveFraudBlocker && quote.isOverridable && !quote.isOverriden && (
          <section className={css(styles.footer)}>
            <RefundNonProcessableQuotePrompt
              onOverride={() => {
                change('overrideFraudProvider', true);
              }}
              isEnablingOverride={isUpdatePending}
            />
          </section>
        )}
      </form>
    </PageContainer>
  );
};

SameDayVoid.propTypes = {
  order: PropTypes.object,
  bookings: PropTypes.array,
  isLoadPending: PropTypes.bool.isRequired,
  isLoadSuccess: PropTypes.bool.isRequired,
  isLoadFailed: PropTypes.bool.isRequired,
  isRefundFailed: PropTypes.bool.isRequired,
  isRefundFailedWithErrors: PropTypes.bool.isRequired,
  isRefundPending: PropTypes.bool.isRequired,
  isRefundSuccess: PropTypes.bool.isRequired,
  isUpdatePending: PropTypes.bool.isRequired,
  isUpdateFailed: PropTypes.bool.isRequired,
  quote: PropTypes.object,
  match: matchShape,
  load: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  ticketReturnModes: PropTypes.array.isRequired,
  updateQuote: PropTypes.func,
  change: PropTypes.func,
  canWaiveFraudBlocker: PropTypes.bool.isRequired,
  hasRefundPermissions: PropTypes.bool,
  managedGroupId: PropTypes.string,
  dispose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  order: selectors.getOrder(state),
  bookings: selectors.getBookings(state),
  isLoadPending: selectors.isLoadPending(state.sdv),
  isLoadSuccess: selectors.isLoadSuccess(state.sdv),
  isLoadFailed: selectors.isLoadFailed(state.sdv),
  isUpdatePending: selectors.isUpdatePending(state.sdv),
  isUpdateFailed: selectors.isUpdateFailed(state.sdv),
  isRefundPending: selectors.isRefundPending(state.sdv),
  isRefundSuccess: selectors.isRefundSuccess(state.sdv),
  isRefundFailed: selectors.isRefundFailed(state.sdv),
  isRefundFailedWithErrors: selectors.isRefundFailedWithErrors(state.sdv),
  quote: selectors.getQuote(state),
  initialValues: selectors.getInitialValues(state),
  ticketReturnModes: selectors.getTicketReturnModes(state),
  currentValues: state.form[FORM_ID] &&
    state.form[FORM_ID].values && {
      ...state.form[FORM_ID].values,
    },
  canWaiveFraudBlocker: canWaiveFraudBlockerSelector(state),
  hasRefundPermissions: selectors.hasRefundPermissions(state),
  managedGroupId: getCurrentManagedGroupId(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispose: () => dispatch(actions.dispose()),
  load: () => {
    dispatch(actions.loadAttempt());
  },
});

const SameDayVoidForm = reduxForm({
  form: FORM_ID,
  enableReinitialize: true,
  onSubmit: (_, dispatch) => {
    dispatch(actions.refundAttempt());
  },

  onChange: ({ refundables, overrideFraudProvider }, dispatch, props) => {
    const { pristine } = props;

    if (pristine) {
      return;
    }

    const refundableIds =
      (refundables && Object.keys(refundables).filter((key) => refundables[key])) || [];
    dispatch(actions.updateAttempt(refundableIds, overrideFraudProvider));
  },
})(SameDayVoid);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SameDayVoidForm));
