import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import styles from './styles';
import messages from './messages';

export const HelpBanner = () => (
  <div className={css(styles.container)}>
    <h2 className={css(styles.title)} data-test="SelectTicketsToTAndCRefund">
      <FormattedMessage {...messages.title} />
    </h2>
    <FormattedMessage {...messages.message} />
  </div>
);

export default HelpBanner;
