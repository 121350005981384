import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StatusMessage } from '@trainline/depot-web';

import Link from '@contactcentre-web/common/Link';

import messages from './messages';

interface Props {
  resetSeasonStatus?: string;
  cancelSeasonStatus?: string;
  customerId: string;
  orderId: string;
}

const FlexiSeasonAlert = ({ resetSeasonStatus, cancelSeasonStatus, customerId, orderId }: Props) =>
  resetSeasonStatus === 'success' || cancelSeasonStatus === 'success' ? (
    <StatusMessage status="info">
      {resetSeasonStatus === 'success' && (
        <p data-testid="reset-season-success">
          <FormattedMessage
            {...messages.resetSeasonSuccess}
            values={{
              link: (msg: string) => (
                <Link
                  linkType="internal"
                  to={`/customers/${customerId}/bookings/${orderId}/history`}
                >
                  {msg}
                </Link>
              ),
            }}
          />
        </p>
      )}
      {cancelSeasonStatus === 'success' && (
        <p data-testid="cancel-season-success">
          <FormattedMessage
            {...messages.cancelSeasonSuccess}
            values={{
              link: (msg: string) => (
                <Link
                  linkType="internal"
                  to={`/customers/${customerId}/bookings/${orderId}/history`}
                >
                  {msg}
                </Link>
              ),
            }}
          />
        </p>
      )}
    </StatusMessage>
  ) : null;

export default FlexiSeasonAlert;
