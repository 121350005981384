import { css } from 'aphrodite/no-important';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import FormattedDateInLondonTz from '@contactcentre-web/common/FormattedDateInLondonTz';
import { Passenger as PassengerType } from '@contactcentre-web/hooks/api/useCustomerPassengers';

import DeletePassenger from '../DeletePassenger/DeletePassenger';
import EditPassenger from '../EditPassenger/EditPassenger';
import SavedCardsList from '../SavedCardsList/SavedCardsList';

import messages from './messages';
import styles from './styles';

interface Props {
  passenger: PassengerType;
}

const Passenger = ({ passenger }: Props) => {
  const {
    firstName,
    lastName,
    type,
    email,
    dateOfBirth,
    phoneNumber,
    phoneCountryCode,
    savedCards,
  } = passenger;

  return (
    <section className={css(styles.passengerContent)} data-testid="passengerCard">
      <div className={css(styles.passengerName)} data-testid="passenger-name">
        {firstName} {lastName}
      </div>
      {type === 'AccountHolder' && (
        <div className={css(styles.accountHolder)} data-testid="passenger-account-type">
          <span className={css(styles.accountHolderLabel)}>
            <FormattedMessage {...messages.passengerAccountHolder} />
          </span>
        </div>
      )}
      {type === 'Passenger' && (
        <div className={css(styles.passengerContainer)} data-testid="passenger-type">
          <span className={css(styles.passengerContainerLabel)}>
            <FormattedMessage {...messages.passengerLabel} />
          </span>
        </div>
      )}
      <div className={css(styles.textLable)} data-testid="passenger-email">
        {email}
      </div>
      {dateOfBirth && (
        <div className={css(styles.textLable)} data-testid="passenger-date-of-birth">
          <FormattedMessage
            {...messages.passengerDOB}
            values={{
              dob: <FormattedDateInLondonTz value={dateOfBirth} />,
            }}
          />
        </div>
      )}
      <div className={css(styles.textLable)} data-testid="passenger-phone-number">
        {phoneNumber && phoneCountryCode ? (
          <FormattedMessage
            {...messages.passengerContactNumber}
            values={{
              number: `${phoneCountryCode} ${phoneNumber}`,
            }}
          />
        ) : (
          <FormattedMessage {...messages.passengerContactNumberNA} />
        )}
      </div>
      <EditPassenger passenger={passenger} />
      {savedCards && savedCards.length > 0 && (
        <div className={css(styles.savedCards)}>
          <SavedCardsList savedCards={savedCards} passengerId={passenger.passengerId} />
        </div>
      )}
      {type !== 'AccountHolder' && <DeletePassenger passenger={passenger} />}
    </section>
  );
};

export default Passenger;
