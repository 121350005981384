import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'SeasonBooking.ResetTicketsModal.title',
    defaultMessage: 'Reset all tickets?',
  },
  description: {
    id: 'SeasonBooking.ResetTicketsModal.description',
    defaultMessage:
      '{canReset, select, true {The reset will unlock the {downloaded} downloaded and {activated} activated tickets from the device, allowing the customers to download them again. If a ticket has already been activated, resetting will not change its status.} other {There are no tickets that can be reset.}}',
  },
  confirmReset: {
    id: 'SeasonBooking.ResetTicketsModal.confirmReset',
    defaultMessage: 'Reset all tickets',
  },
  error: {
    id: 'SeasonBooking.ResetTicketsModal.error',
    defaultMessage: 'Something went wrong while resetting the ticket. Try again.',
  },
});
