import { StyleSheet } from 'aphrodite/no-important';
import { fonts, spacers, typography } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  passengers: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: spacers.dp_s32,
  },
  passengerDetail: {
    color: colors.gray.alpha80,
    textTransform: 'uppercase',
    fontSize: typography.dp_mini_font_size_m,
    width: '250px',
    display: 'flex',
    flexDirection: 'row',
  },
  ticketDetails: {
    display: 'table',
    width: '100%',
    borderSpacing: 0,
  },
  row: {
    display: 'table-row',
    width: '100%',
  },
  column: {
    display: 'table-cell',
    paddingBottom: spacers.dp_s16,
  },

  passengersIcon: {
    fill: colors.blue.dark,
    marginRight: spacers.dp_s4,
    display: 'inline-block',
  },
  passenger: {
    display: 'inline-block',
    verticalAlign: 'top',
  },
  passengerName: {
    fontWeight: fonts.dp_font_weight_bold,
  },
  passengerDetailItem: {
    maxWidth: '250px',

    ':not(:first-child)': {
      marginBottom: spacers.dp_s4,
    },
  },
  travelTitle: {
    color: colors.gray.alpha30,
    textTransform: 'uppercase',
    fontSize: typography.dp_mini_font_size_m,
    padding: 0,
  },
  onBoardServices: {
    color: colors.info.dark,
  },
  onBoardServicesIcon: {
    marginLeft: spacers.dp_s8,
  },
  list: {
    listStyleType: 'none',
  },
  icon: {
    color: colors.info.base,
  },
});
