import { StyleSheet } from 'aphrodite/no-important';
import { fonts, palettes, spacers, typography } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  anchor: {
    display: 'block',
  },
  status: {
    color: palettes.dp_app_text_subdued,
    fontSize: typography.dp_title2_font_size_m,
    lineHeight: typography.dp_title2_line_height_m,
    fontWeight: fonts.dp_font_weight_bold,
    textAlign: 'center',
  },
  description: {
    textAlign: 'center',
    fontWeight: fonts.dp_font_weight_bold,
    fontSize: typography.dp_title2_font_size_m,
    marginTop: spacers.dp_s16,
  },
  generateErrorContainer: {
    marginTop: spacers.dp_s16,
    textAlign: 'center',
    color: palettes.dp_app_text_negative,
  },
  documentContainer: {
    marginTop: spacers.dp_s16,
  },
  document: {
    marginBottom: spacers.dp_s8,
  },
});
