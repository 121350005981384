import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'DelayRepayClaim.title',
    defaultMessage: 'Delay Repay requested',
  },
  journeyLabel: {
    id: 'DelayRepayClaim.journeyLabel',
    defaultMessage: 'journey',
  },
  claimLabel: {
    id: 'DelayRepayClaim.claimLabel',
    defaultMessage: 'Claim Id',
  },
  statusLabel: {
    id: 'DelayRepayClaim.statusLabel',
    defaultMessage: 'Status',
  },
  delayRepayStatus_created: {
    id: 'DelayRepayClaim.delayRepayStatus_created',
    defaultMessage: 'Created',
  },
  delayRepayStatus_eligible: {
    id: 'DelayRepayClaim.delayRepayStatus_eligible',
    defaultMessage: 'Eligible',
  },
  delayRepayStatus_processing: {
    id: 'DelayRepayClaim.delayRepayStatus_processing',
    defaultMessage: 'Processing',
  },
  delayRepayStatus_approved: {
    id: 'DelayRepayClaim.delayRepayStatus_approved',
    defaultMessage: 'Approved',
  },
  delayRepayStatus_paid: {
    id: 'DelayRepayClaim.delayRepayStatus_paid',
    defaultMessage: 'Paid',
  },
  delayRepayStatus_unsuccessful: {
    id: 'DelayRepayClaim.delayRepayStatus_unsuccessful',
    defaultMessage: 'Unsuccessful',
  },
  delayRepayStatus_expired: {
    id: 'DelayRepayClaim.delayRepayStatus_expired',
    defaultMessage: 'Expired',
  },
  delayRepayStatus_unknown: {
    id: 'DelayRepayClaim.delayRepayStatus_unknown',
    defaultMessage: 'Unknown ({status})',
  },
});
