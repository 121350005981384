import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  modal: {
    width: '515px',
  },
  modalTitle: {
    display: 'flex',
    gap: spacers.dp_s16,
  },
  modalBody: {
    marginBottom: spacers.dp_s16,
  },
});
