import React, { Dispatch, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { path } from 'ramda';
import { Paragraph, StatusMessage } from '@trainline/depot-web';

import Tooltip from '@contactcentre-web/common/Tooltip';
import type State from '@contactcentre-web/redux-common/types/State';
import type Action from '@contactcentre-web/redux-common/types/Action';
import StackedLabel from '@contactcentre-web/common/StackedLabel';
import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency/FormattedCurrency';
import Button from '@contactcentre-web/common/Button';
import EntanglementWarning, {
  filterEntanglementWarnings,
} from '@contactcentre-web/common/EntanglementWarning';
import { MessageCodeType } from '@contactcentre-web/common/EntanglementWarning/EntanglementWarning';
import OrderNoteItem from '@contactcentre-web/common/OrderNoteItem/OrderNoteItem';

import { ApprovalFailedMessage, actions } from '../../module';
import selectors, { RequestItem } from '../../selectors';
import RefundActionConfirm, { RefundActions } from '../RefundActionConfirm/RefundActionConfirm';

import styles from './styles';
import messages from './messages';

interface StateProps {
  isActionInProgress: boolean;
  actionHasSucceeded: boolean;
  actionHasFailed: boolean;
  approvalFailedMessage?: ApprovalFailedMessage;
  requestId?: string;
}

interface DispatchProps {
  approveRefund: (requestId: string) => void;
  rejectRefund: (requestId: string) => void;
  deleteRefund: (requestId: string) => void;
  overrideRefund: (requestId: string) => void;
}

interface Props extends DispatchProps, StateProps, RequestItem {
  returnUrl?: string;
}

export const RefundCardDetails: FunctionComponent<Props> = ({
  id,
  reason,
  bookingFees,
  paymentFees,
  deliveryFees,
  adminFees,
  totalRefundable,
  isActionInProgress,
  customerId,
  orderReference,
  bookingId,
  tmcQuoteReference,
  quoteReference,
  returnUrl,
  lastOrderNote,
  approveRefund,
  rejectRefund,
  deleteRefund,
  overrideRefund,
  actionHasSucceeded,
  actionHasFailed,
  requestId,
  approvalFailedMessage,
  quoteConditions,
  refundableReasons,
  hasQuoteExpired,
}) => {
  const [{ action, confirmPromptVisible }, setConfirmAction] = React.useState<{
    action?: RefundActions;
    confirmPromptVisible: boolean;
  }>({
    action: undefined,
    confirmPromptVisible: false,
  });
  const [noteExpanded, setNoteExpanded] = React.useState(false);
  const isScannedTicket = quoteConditions?.some(
    (quoteCondition) =>
      path(['reason', 'code'], quoteCondition) &&
      path(['reason', 'code'], quoteCondition) === 'usedTicketRefund'
  );
  const entanglementWarnings = filterEntanglementWarnings(quoteConditions);
  const shouldShowEntanglementAlert = entanglementWarnings?.length > 0;
  const hasPartialReturnRefundAgainstDisruption = quoteConditions?.some(
    (quoteCondition) =>
      path(['reason', 'code'], quoteCondition) &&
      path(['reason', 'code'], quoteCondition) === 'partialReturnRefundAgainstDisruption'
  );

  const showApprovalConfirm = (actionName: RefundActions) => {
    setConfirmAction({
      confirmPromptVisible: true,
      action: actionName,
    });
  };

  const hideApprovalConfirm = () => {
    setConfirmAction({
      confirmPromptVisible: false,
      action: undefined,
    });
  };

  const processRefundAction = (approvalId: string, actionName: RefundActions) => {
    if (actionName === 'approve') {
      approveRefund(approvalId);
    } else {
      rejectRefund(approvalId);
    }
  };

  React.useEffect(() => {
    if (actionHasSucceeded || actionHasFailed) {
      setConfirmAction({
        confirmPromptVisible: false,
        action: undefined,
      });
    }
  }, [actionHasSucceeded, actionHasFailed]);

  const quoteRef = tmcQuoteReference || quoteReference;

  type GroupedRefundableReasonsType = {
    reasonId: string;
    description: string;
    ticketTypes: Array<{ type: string; count: number }>;
  };
  const groupedRefundableReasons: Array<GroupedRefundableReasonsType> = refundableReasons?.reduce(
    (acc: Array<GroupedRefundableReasonsType>, { id: reasonId, description, ticketType }) => {
      const reasonIndex = acc.findIndex((accReason) => accReason.reasonId === reasonId);

      if (reasonIndex > -1) {
        const ticketIndex = acc[reasonIndex].ticketTypes.findIndex(
          (ticket) => ticket.type === ticketType
        );

        if (ticketIndex > -1) {
          acc[reasonIndex].ticketTypes[ticketIndex].count += 1;
        } else {
          acc[reasonIndex].ticketTypes.push({ type: ticketType, count: 1 });
        }
      } else {
        acc.push({
          reasonId,
          description,
          ticketTypes: [{ type: ticketType, count: 1 }],
        });
      }
      return acc;
    },
    []
  );

  const EditButton = () => (
    <Button
      variant="tertiary"
      size="small"
      title="Edit request"
      ariaLabel="aria"
      fullWidth
      testId="edit-button"
      styleSheet={styles.button}
      disabled={hasQuoteExpired}
      to={`/customers/${customerId}/bookings/${orderReference}/discretionary/${bookingId}/requests/${quoteRef}?returnUrl=${returnUrl}`}
    >
      <FormattedMessage {...messages.edit} />
    </Button>
  );

  const ApproveButton = () => (
    <Button
      variant="primary"
      size="small"
      title="Approve request"
      fullWidth
      testId="approve-button"
      disabled={hasQuoteExpired || (actionHasFailed && requestId === id)}
      styleSheet={styles.button}
      onClick={() => showApprovalConfirm('approve')}
    >
      <FormattedMessage {...messages.approve} />
    </Button>
  );

  const RejectButton = () => (
    <Button
      variant="destructive"
      size="small"
      title="Reject request"
      fullWidth
      styleSheet={styles.button}
      testId="reject-button"
      onClick={() => showApprovalConfirm('reject')}
    >
      <FormattedMessage {...messages.reject} />
    </Button>
  );

  const DeleteButton = () => (
    <Button
      variant="destructive"
      size="small"
      title="Clear request"
      fullWidth
      styleSheet={styles.button}
      testId="clear-button"
      onClick={() => deleteRefund(id)}
    >
      <FormattedMessage {...messages.clear} />
    </Button>
  );

  const OverrideError = () => (
    <div className={css(styles.notification)}>
      <StatusMessage status="warning">
        <Paragraph typeStyle="small" as="p" color="base" fontWeight="bold">
          <FormattedMessage {...messages.approvalRefundRequestRejectedByFraudHeader} />
        </Paragraph>
        <Paragraph typeStyle="small" as="p" color="base" fontWeight="regular">
          <FormattedMessage {...messages.approvalRefundRequestRejectedByFraudBody} />
        </Paragraph>
        <Button
          variant="tertiary"
          size="small"
          title="Override and approve request"
          styleSheet={styles.overrideButton}
          testId="override-button"
          loading={isActionInProgress}
          onClick={() => overrideRefund(id)}
        >
          <FormattedMessage {...messages.approvalRefundRequestRejectedByFraudAction} />
        </Button>
      </StatusMessage>
    </div>
  );

  return (
    <div className={css(styles.container)}>
      {requestId === id &&
        approvalFailedMessage === ApprovalFailedMessage.approvalRefundRequestRejectedByFraud && (
          <OverrideError />
        )}
      {shouldShowEntanglementAlert &&
        entanglementWarnings.map((entanglementWarning, index) => (
          <EntanglementWarning
            messageCode={entanglementWarning as MessageCodeType}
            level="negative"
            styleSheet={styles.notification}
            testId={`entanglementWarning.${entanglementWarning}`}
            key={index}
          />
        ))}
      {hasPartialReturnRefundAgainstDisruption && (
        <div
          className={css(styles.notification)}
          data-testid="partial-return-refund-against-disruption-alert"
        >
          <StatusMessage status="warning">
            <FormattedMessage {...messages.partialReturnRefundAgainstDisruption} />
          </StatusMessage>
        </div>
      )}
      {isScannedTicket && (
        <div className={css(styles.notification)} data-testid="scanned-ticket-alert">
          <StatusMessage status="warning">
            <FormattedMessage {...messages.scannedTicket} />
          </StatusMessage>
        </div>
      )}
      <div className={css(styles.columns)}>
        <div>
          {groupedRefundableReasons && (
            <StackedLabel
              styleSheet={{ label: styles.label, value: styles.value }}
              label={<FormattedMessage {...messages.reasonCodes} />}
            >
              {groupedRefundableReasons?.length > 0 ? (
                <div data-testid="reasons-grouped">
                  {groupedRefundableReasons.map(({ reasonId, description, ticketTypes }) => (
                    <div key={reasonId} data-testid={`reason-${description}-group`}>
                      <div className={css(styles.reasonName)}>{description}</div>
                      <ul className={css(styles.ticketTypeList)}>
                        {ticketTypes.map(({ type, count }) => (
                          <li key={type}>
                            {type} x{count}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              ) : (
                <span data-testid="reason-code">{reason}</span>
              )}
            </StackedLabel>
          )}
          {lastOrderNote && lastOrderNote.length > 0 && (
            <OrderNoteItem
              expand={() => setNoteExpanded((prevNoteExpanded) => !prevNoteExpanded)}
              isExpanded={noteExpanded}
              message={lastOrderNote}
            />
          )}
        </div>
        <div>
          <StackedLabel
            orientation="horizontal"
            styleSheet={{
              container: styles.feesContainer,
              label: styles.label,
              value: styles.value,
            }}
            label={<FormattedMessage {...messages.fees_service} />}
          >
            {bookingFees ? (
              <FormattedCurrency {...bookingFees} />
            ) : (
              <FormattedMessage {...messages.fees_noValue} />
            )}
          </StackedLabel>
          <StackedLabel
            orientation="horizontal"
            styleSheet={{
              container: styles.feesContainer,
              label: styles.label,
              value: styles.value,
            }}
            label={<FormattedMessage {...messages.fees_payment} />}
          >
            {paymentFees ? (
              <FormattedCurrency {...paymentFees} />
            ) : (
              <FormattedMessage {...messages.fees_noValue} />
            )}
          </StackedLabel>
          <StackedLabel
            orientation="horizontal"
            styleSheet={{
              container: styles.feesContainer,
              label: styles.label,
              value: styles.value,
            }}
            label={<FormattedMessage {...messages.fees_delivery} />}
          >
            {deliveryFees ? (
              <FormattedCurrency {...deliveryFees} />
            ) : (
              <FormattedMessage {...messages.fees_noValue} />
            )}
          </StackedLabel>
          <StackedLabel
            orientation="horizontal"
            styleSheet={{
              container: styles.feesContainer,
              label: styles.label,
              value: styles.value,
            }}
            label={<FormattedMessage {...messages.fees_admin} />}
          >
            {adminFees ? (
              <FormattedCurrency {...adminFees} />
            ) : (
              <FormattedMessage {...messages.fees_noValue} />
            )}
          </StackedLabel>
          <StackedLabel
            orientation="horizontal"
            styleSheet={{
              container: styles.feesContainer,
              label: styles.label,
              value: styles.value,
            }}
            label={<FormattedMessage {...messages.total} />}
          >
            {totalRefundable ? (
              <FormattedCurrency {...totalRefundable} />
            ) : (
              <FormattedMessage {...messages.fees_noValue} />
            )}
          </StackedLabel>
        </div>
        <div>
          {confirmPromptVisible && action && (
            <RefundActionConfirm
              action={action}
              total={totalRefundable}
              isProcessing={isActionInProgress}
              onProcess={() => processRefundAction(id, action)}
              onClose={() => hideApprovalConfirm()}
            />
          )}
          {hasQuoteExpired ? (
            <>
              <div className={css(styles.expiredQuoteTooltip)}>
                <Tooltip orientation="Above" anchor={<EditButton />}>
                  <FormattedMessage {...messages.expiredQuote} />
                </Tooltip>
              </div>
              <div className={css(styles.expiredQuoteTooltip)}>
                <Tooltip orientation="Above" anchor={<ApproveButton />}>
                  <FormattedMessage {...messages.expiredQuote} />
                </Tooltip>
              </div>
              <DeleteButton />
            </>
          ) : (
            <>
              <EditButton />
              <ApproveButton />
              <RejectButton />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  isActionInProgress:
    selectors.isApprovalInProgress(state) ||
    selectors.isRejectInProgress(state) ||
    selectors.isOverrideInProgress(state),
  actionHasSucceeded: selectors.hasApprovalSucceeded(state) || selectors.hasRejectSucceeded(state),
  actionHasFailed: selectors.hasApprovalFailed(state) || selectors.hasRejectFailed(state),
  approvalFailedMessage: selectors.approvalFailedMessage(state),
  requestId: selectors.requestId(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  approveRefund: (requestId: string) => dispatch(actions.approvalAttempt(requestId)),
  rejectRefund: (requestId: string) => dispatch(actions.rejectAttempt(requestId)),
  deleteRefund: (requestId: string) => dispatch(actions.deleteAttempt(requestId)),
  overrideRefund: (requestId: string) => dispatch(actions.overrideAttempt(requestId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RefundCardDetails);
