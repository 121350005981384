const OrderActionKeys = {
  viewBookings: 'viewBookings',
  orderHistory: 'orderHistory',
  logCall: 'logCall',
  cancelOrder: 'cancelOrder',
  refund: 'refund',
  changeOfJourney: 'changeOfJourney',
  replace: 'replace',
  currencyConverter: 'currencyConverter',
  openInCapitaine: 'openInCapitaine',
  resendEmails: 'resendEmails',
  fulfilmentConverter: 'fulfilmentConverter',
  refresh: 'refresh',
  compensation: 'compensation',
  moveOrder: 'moveOrder',
  removeCollectionRestrictions: 'removeCollectionRestrictions',
  detaxe: 'detaxe',
};

export default OrderActionKeys;

export const allActionsExcept = (
  ...except: (keyof typeof OrderActionKeys)[]
): { [k in keyof typeof OrderActionKeys]?: unknown } =>
  (Object.keys(OrderActionKeys) as (keyof typeof OrderActionKeys)[])
    .filter((a) => !except.includes(a))
    .reduce(
      (acc, curr: string) => ({
        ...acc,
        [curr]: {},
      }),
      {}
    );

export const allActions = (): { [k in keyof typeof OrderActionKeys]?: unknown } =>
  Object.keys(OrderActionKeys).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: {},
    }),
    {}
  );
