import { defineMessages } from 'react-intl';

export default defineMessages({
  serviceFee: {
    id: 'SameDayVoidFees.serviceFee',
    defaultMessage: 'Service Fee',
  },
  paymentFee: {
    id: 'SameDayVoidFees.paymentFee',
    defaultMessage: 'Payment Fee',
  },
});
