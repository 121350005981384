import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import FormattedDateInLondonTz from '@contactcentre-web/common/FormattedDateInLondonTz/FormattedDateInLondonTz';

import SeatReservation from '../SeatReservation/SeatReservation';
import DeliveryAddress from '../DeliveryAddress/DeliveryAddress';

import messages from './messages';
import styles from './styles';

const InfoRow = ({ message, value }) => (
  <p className={css(styles.grayLabel, styles.label)}>
    <FormattedMessage
      {...message}
      values={{
        value: <span className={css(styles.infoValue)}>{value}</span>,
      }}
    />
  </p>
);

InfoRow.propTypes = {
  message: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

const Passenger = ({
  passengerNum,
  name,
  railcard,
  tickets,
  seatingPreferences,
  deliveryRecipient,
  dateOfBirth,
}) => (
  <div className={css([styles.row, styles.alignTop, styles.passenger])}>
    <div className={css(styles.left)}>
      <p className={css(styles.grayLabel)}>
        <FormattedMessage {...messages.passenger} values={{ passengerNum }} />
      </p>
      <p>{name}</p>
      <InfoRow
        data-test="passenger-dob"
        message={messages.dateOfBirth}
        value={
          dateOfBirth ? (
            <FormattedDateInLondonTz
              value={dateOfBirth}
              year="numeric"
              month="short"
              day="numeric"
            />
          ) : (
            <FormattedMessage {...messages.notAvailable} />
          )
        }
      />
    </div>
    {tickets.map((ticket, index) => (
      <div key={index}>
        <p className={css(styles.grayLabel)}>
          {ticket.seatReservation ? (
            <SeatReservation {...ticket.seatReservation} />
          ) : (
            <FormattedMessage {...messages.unreservedSeating} />
          )}
        </p>

        <InfoRow
          message={messages.seatingPreferencesLabel}
          value={seatingPreferences || <FormattedMessage {...messages.notAvailable} />}
        />

        <InfoRow
          message={messages.railcardLabel}
          value={railcard || <FormattedMessage {...messages.notAvailable} />}
        />

        <InfoRow
          message={messages.ticketType}
          value={
            ticket && ticket.type ? ticket.type : <FormattedMessage {...messages.notAvailable} />
          }
        />
        {ticket && ticket.deliveryMethod && (
          <InfoRow message={messages.deliveryMethod} value={ticket.deliveryMethod} />
        )}
        {deliveryRecipient && <DeliveryAddress {...deliveryRecipient} />}
        <InfoRow
          message={messages.ticketClass}
          value={
            ticket && ticket.class ? ticket.class : <FormattedMessage {...messages.notAvailable} />
          }
        />
      </div>
    ))}
  </div>
);

Passenger.propTypes = {
  passengerNum: PropTypes.number.isRequired,
  name: PropTypes.string,
  railcard: PropTypes.string,
  tickets: PropTypes.arrayOf(
    PropTypes.shape({
      deliveryMethod: PropTypes.string,
      type: PropTypes.string,
      class: PropTypes.string,
      seatReservation: PropTypes.object,
    })
  ),
  seatingPreferences: PropTypes.string,
  deliveryRecipient: PropTypes.object,
  dateOfBirth: PropTypes.string,
};

export default Passenger;
