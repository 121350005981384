import React from 'react';
import { css } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { IconInfo } from '@trainline/depot-web';

import Tooltip from '@contactcentre-web/common/Tooltip';

import messages from './messages';
import styles from './styles';

const BoardingTooltip = ({ boardBefore }) => {
  const boardBeforeMinutes = boardBefore / 1000 / 60;
  const boardBeforeHrs = boardBeforeMinutes / 60;
  const isHrs = boardBeforeHrs >= 1;

  if (!boardBefore) {
    return null;
  }

  return (
    <Tooltip
      orientation="Above"
      anchor={
        <span className={css(styles.iconContainer)}>
          <IconInfo />
        </span>
      }
    >
      <FormattedMessage
        {...messages.boardBefore}
        values={{
          time: isHrs ? (
            <FormattedMessage {...messages.hours} values={{ boardBeforeHrs }} />
          ) : (
            <FormattedMessage {...messages.minutes} values={{ boardBeforeMinutes }} />
          ),
        }}
      />
    </Tooltip>
  );
};

BoardingTooltip.propTypes = {
  boardBefore: PropTypes.number,
};

export default BoardingTooltip;
