import { useMutation } from 'react-query';

import request from '@contactcentre-web/utils/request';

export const useTrackOpenInBeboc = (orderReference: string) =>
  useMutation(() =>
    request(`/api/orders/${orderReference}/openInCapitaine`, {
      method: 'POST',
    })
  );
