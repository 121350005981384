import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleWare from 'redux-saga';
import { loadFeatures } from 'feature-toggle';

import { appInit } from '@contactcentre-web/feature-flags/module';
import errorHandleMiddleware from '@contactcentre-web/middlewares/errorHandleMiddleware';
import localeFilterMiddleware from '@contactcentre-web/middlewares/localeFilterMiddleware';

import createReducer from './reducers';
import createSaga from './sagas';

// TODO: Make reducers HOT reloadable.
// https://stackoverflow.com/questions/34243684/make-redux-reducers-and-other-non-components-hot-loadable

const sagaMiddleware = createSagaMiddleWare();

const composeEnhancers =
  // eslint-disable-next-line no-underscore-dangle
  (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export default function configureStore(features) {
  const store = createStore(
    createReducer(features),
    composeEnhancers(applyMiddleware(errorHandleMiddleware, localeFilterMiddleware, sagaMiddleware))
  );

  store.dispatch(loadFeatures(features));

  sagaMiddleware.run(createSaga(features));
  store.dispatch(appInit());
  return store;
}
