export enum LocalStorageKeys {
  PROFILES_KEY = 'userProfiles',
  SSO_LOGIN_PREFERRED = 'SSOPreferredAsLogin',
  NEW_LOGIN_FLOW = 'newLoginFlow',
  MANUAL_ENTRY_CONSOLE_SETTINGS = 'manualEntryConsoleSettings',
}

function get(key: LocalStorageKeys) {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : null;
}

function set(key: LocalStorageKeys, value: any) {
  localStorage.setItem(key, JSON.stringify(value));
}

function clear(key: string) {
  localStorage.removeItem(key);
}

export default {
  get,
  set,
  clear,
};
