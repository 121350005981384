import { defineMessages } from 'react-intl';

export default defineMessages({
  error: {
    id: 'TermsAndConditions.error',
    defaultMessage: 'Oops, something went wrong and the request has failed. Please try again.',
  },
  confirmSdvEligibleRefundHeader: {
    id: 'TermsAndConditions.confirmSdvEligibleRefundHeader',
    defaultMessage: 'Confirm Terms & Conditions Refund',
  },
  confirmSdvEligibleRefundBody: {
    id: 'TermsAndConditions.confirmSdvEligibleRefundBody',
    defaultMessage:
      'This order is eligible for Same Day Void cancellation - are you sure you want to proceed to request a Terms & Conditions refund?',
  },
  noPermissions: {
    id: 'TermsAndConditions.noPermissions',
    defaultMessage: "You don't have permission to request a refund",
  },
  notEligibleForAutomatedRefund: {
    id: 'TermsAndConditions.notEligibleForAutomatedRefund',
    defaultMessage:
      'A refund is eligible. Please follow the process in Flexi ticket Decision Tree and raise a ticket to the Refunds team to process the refund.',
  },
});
