import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import StackedLabel from '@contactcentre-web/common/StackedLabel/StackedLabel';
import Link from '@contactcentre-web/common/Link';
import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency/FormattedCurrency';
import FormattedDateInTz from '@contactcentre-web/common/FormattedDateInTz/FormattedDateInTz';
import FormattedTimeInTz from '@contactcentre-web/common/FormattedTimeInTz/FormattedTimeInTz';
import Price from '@contactcentre-web/redux-common/types/Price';

import styles from './styles';
import messages from './messages';

interface Props {
  orderId: string;
  requestedDate: Date;
  requestingUserName?: string;
  amount: Price;
  customerId?: string;
}

const CompensationApprovalHeader: FunctionComponent<Props> = ({
  orderId,
  requestedDate,
  requestingUserName,
  amount,
  customerId,
}) => (
  <div className={css(styles.header)}>
    <div>
      <StackedLabel
        label={
          <span className={css(styles.headerLabel)}>
            <FormattedMessage {...messages.orderId} />
          </span>
        }
      >
        <Link linkType="external" href={`/customers/${customerId}/bookings/${orderId}`}>
          {orderId}
        </Link>
      </StackedLabel>
    </div>
    <div>
      <StackedLabel
        label={
          <span className={css(styles.headerLabel)}>
            <FormattedMessage {...messages.date} />
          </span>
        }
      >
        <FormattedMessage
          {...messages.requestedDate}
          values={{
            day: (
              <FormattedDateInTz value={requestedDate} year="numeric" month="short" day="numeric" />
            ),
            time: <FormattedTimeInTz value={requestedDate} />,
          }}
        />
      </StackedLabel>
    </div>
    <div>
      <StackedLabel
        label={
          <span className={css(styles.headerLabel)}>
            <FormattedMessage {...messages.requestingUserName} />
          </span>
        }
      >
        <span>{requestingUserName}</span>
      </StackedLabel>
    </div>
    <div>
      <StackedLabel
        label={
          <span className={css(styles.headerLabel)}>
            <FormattedMessage {...messages.total} />
          </span>
        }
      >
        <span className={css(styles.bold)}>
          <FormattedCurrency {...amount} />
        </span>
      </StackedLabel>
    </div>
  </div>
);

export default CompensationApprovalHeader;
