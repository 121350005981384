import { StatusMessage } from '@trainline/depot-web';
import { css } from 'aphrodite/no-important';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import type State from '@contactcentre-web/redux-common/types/State';

import { ErrorContext, selectors } from '../../module';

import messages from './messages';
import styles from './styles';

interface StateProps {
  errorContext?: ErrorContext;
}
export type Props = StateProps;

export const ResendEmailFailure = ({ errorContext }: Props) => {
  const {
    wasResendingConfirmationEmail,
    wasResendingFulfilmentEmail,
    bookingConfirmationError,
    bookingsFulfilmentErrors,
  } = errorContext || {};
  const hasBookingConfirmationSucceeded =
    wasResendingConfirmationEmail && !bookingConfirmationError;
  const hasBookingFulfilmentSucceeded =
    wasResendingFulfilmentEmail && bookingsFulfilmentErrors?.length === 0;
  const hasError =
    errorContext &&
    (!!bookingConfirmationError ||
      (bookingsFulfilmentErrors && bookingsFulfilmentErrors?.length > 0));
  if (!hasError) {
    return null;
  }
  const getMessagebyName = (name: string) => {
    const messageKeys = Object.entries(messages);
    const key = messageKeys.find((p) => p[0] === name)?.[1];
    return key;
  };
  return (
    <div className={css(styles.container)}>
      <StatusMessage status="warning">
        {hasBookingConfirmationSucceeded && (
          <section>
            <FormattedMessage {...messages.confirmationEmailSentSuccessfully} />
          </section>
        )}
        {bookingConfirmationError &&
          (getMessagebyName(`errors_${bookingConfirmationError}`) ? (
            <FormattedMessage {...getMessagebyName(`errors_${bookingConfirmationError}`)} />
          ) : (
            <FormattedMessage {...messages.errors_bookingConfirmationResendFailed_generic} />
          ))}
        {hasBookingFulfilmentSucceeded && (
          <section>
            <FormattedMessage {...messages.fulfilmentEmailsSentSuccessfully} />
          </section>
        )}
        {bookingsFulfilmentErrors && bookingsFulfilmentErrors?.length > 0 && (
          <section>
            <FormattedMessage {...messages.errors_fulfilmentResendFailed} />
            <ul className={css(styles.errorBookings)}>
              {bookingsFulfilmentErrors?.map(
                ({ id, origin, destination, isReturn, travelDate }) => (
                  <li key={id} className={css(styles.errorBookingsItem)}>
                    <FormattedMessage
                      {...messages.bookingText}
                      values={{
                        origin,
                        destination,
                        isReturn,
                        travelDate,
                      }}
                    />
                  </li>
                )
              )}
            </ul>
          </section>
        )}
      </StatusMessage>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  errorContext: selectors.getErrorContext(state),
});

const ResendEmailFailureContainer = connect(mapStateToProps)(ResendEmailFailure);
ResendEmailFailureContainer.displayName = 'ResendEmailFailure';

export default ResendEmailFailureContainer;
