import React from 'react';

import PageContainer from '@contactcentre-web/common/PageContainer/PageContainer';
import ErrorBoundary from '@contactcentre-web/common/ErrorBoundary/ErrorBoundary';
import CustomerRevocation from '@contactcentre-web/customer-revocation/CustomerRevocation';

import styles from './styles';

const CustomerRevocationPage = () => (
  <PageContainer styleSheet={styles.container}>
    <ErrorBoundary>
      <CustomerRevocation />
    </ErrorBoundary>
  </PageContainer>
);

export default CustomerRevocationPage;
