import { defineMessages } from 'react-intl';

export default defineMessages({
  statesGroup_success: {
    id: 'ManageGroupAlert.statesGroup_success',
    defaultMessage: 'The following have been created for {managedGroupName} successfully: {roles}',
  },
  statesGroup_existing: {
    id: 'ManageGroupAlert.statesGroup_existing',
    defaultMessage: 'The following were previously created for {managedGroupName} : {roles}',
  },
  statesGroup_failure: {
    id: 'ManageGroupAlert.statesGroup_failure',
    defaultMessage: 'The following have failed to create for {managedGroupName}: {roles}',
  },
});
