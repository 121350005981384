import { handleActions } from 'redux-actions';

import { actionTypes } from './module';
import DetaxeLoadStatus from './types/DetaxeLoadStatus';

export const initialState = {
  products: [],
  createRefundStatus: '',
  getRefundStatus: '',
  setRefundStatus: '',
  refundStatus: {},
};

export default handleActions(
  {
    [actionTypes.LOAD_SUCCESS]: (state, { payload: { products } }) => ({
      ...state,
      products,
    }),
    [actionTypes.CREATE_REFUND_ATTEMPT]: (state) => ({
      ...state,
      createRefundStatus: DetaxeLoadStatus.Loading,
    }),
    [actionTypes.CREATE_REFUND_SUCCESS]: (state) => ({
      ...state,
      createRefundStatus: DetaxeLoadStatus.Success,
    }),
    [actionTypes.CREATE_REFUND_FAILED]: (state) => ({
      ...state,
      createRefundStatus: DetaxeLoadStatus.Failed,
    }),
    [actionTypes.GET_REFUND_ATTEMPT]: (state) => ({
      ...state,
      getRefundStatus: DetaxeLoadStatus.Loading,
    }),
    [actionTypes.GET_REFUND_SUCCESS]: (state, { payload: { refundStatus } }) => ({
      ...state,
      refundStatus,
      getRefundStatus: DetaxeLoadStatus.Success,
    }),
    [actionTypes.GET_REFUND_FAILED]: (state) => ({
      ...state,
      getRefundStatus: DetaxeLoadStatus.Failed,
    }),
    [actionTypes.SET_REFUND_STATUS_ATTEMPT]: (state) => ({
      ...state,
      setRefundStatus: DetaxeLoadStatus.Loading,
    }),
    [actionTypes.SET_REFUND_STATUS_SUCCESS]: (state) => ({
      ...state,
      setRefundStatus: DetaxeLoadStatus.Success,
    }),
    [actionTypes.SET_REFUND_STATUS_FAILED]: (state) => ({
      ...state,
      setRefundStatus: DetaxeLoadStatus.Failed,
    }),
  },
  initialState
);
