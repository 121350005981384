import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  table: {
    width: '100%',
    padding: `${spacers.dp_s8} 0`,
  },
  header: {
    textAlign: 'left',
    paddingLeft: `calc(${spacers.dp_s32} + 1px)`,
  },
  totalAmount: {
    lineHeight: 'normal',
    width: '100px',
    textAlign: 'right',
  },
  extraSpace: {
    paddingRight: '38px',
    textAlign: 'right',
  },
});
