import { useMutation, MutationOptions } from 'react-query';

import request from '@contactcentre-web/utils/request';

export type PassengerData = {
  firstName: string;
  lastName: string;
  id: string;
};

export type PassengersData = {
  passengers: Array<PassengerData>;
};

export const useEditBookingPassengers = (
  orderId: string,
  bookingId: string,
  mutationOptions: MutationOptions<unknown, unknown, PassengersData, unknown>
) =>
  useMutation<unknown, unknown, PassengersData, unknown>(
    (passengerData) =>
      request(`/api/orders/${orderId}/bookings/${bookingId}/alterPassengers`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/vnd.trainline.contact-centre-product.v1+json',
        },
        body: passengerData,
      }),
    mutationOptions
  );
