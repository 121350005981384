import { StyleSheet } from 'aphrodite/no-important';
import { spacers, palettes, typography } from '@trainline/depot-web/css/themes/trainline';

import zIndex from '@contactcentre-web/styles/zIndex';

const fadeMoveIn = {
  '0%': {
    opacity: 0,
    transform: 'translateY(-50px)',
  },
  '100%': {
    opacity: 1,
    transform: 'translateY(0)',
  },
};

export default StyleSheet.create({
  header: {
    position: 'sticky',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    background: palettes.dp_app_backdrop_dark,
    zIndex: zIndex.header,
  },
  nav: {
    width: 1036,
    margin: `${spacers.dp_s16} 0 ${spacers.dp_s16} 0`,
    animationName: [fadeMoveIn],
    animationDuration: '1s',
    animationFillMode: 'forwards',
  },
  topColumn: {
    display: 'flex',
    alignItems: 'center',
  },
  bottomColumn: {
    display: 'flex',
    marginTop: spacers.dp_s12,
  },
  topRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1,
    alignItems: 'center',
  },
  conversationId: {
    marginTop: spacers.dp_s12,
    opacity: 0.6,
    fontSize: typography.dp_mini_font_size_m,
  },
  managedGroupContainer: {
    marginLeft: spacers.dp_s32,
  },
  searchForm: {
    alignItems: 'center',
  },
  searchButton: {
    marginLeft: spacers.dp_s16,
  },
  fieldContainer: {
    width: '400px',
  },
  telephonyIcon: {
    marginLeft: spacers.dp_s16,
    height: '40px',
    width: '40px',
  },
});
