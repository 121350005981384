import { StyleSheet } from 'aphrodite/no-important';
import { spacers, borderRadius } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  container: {
    padding: `${spacers.dp_s32} 0 0 0`,
  },
  notice: {
    width: '100%',
    padding: `${spacers.dp_s24}`,
    borderRadius: borderRadius.dp_r4,
    borderStyle: 'solid',
    borderWidth: '1px',
  },
  successful: {
    borderColor: colors.brand.light,
    backgroundColor: colors.brand.lighter,
  },
  failed: {
    borderColor: colors.loud.base,
    backgroundColor: colors.loud.light,
  },
});
