import { defineMessages } from 'react-intl';

export default defineMessages({
  changeManagedGroupTitle: {
    id: 'ManagedGroupsPage.changeManagedGroupTitle',
    defaultMessage: 'Change managed group?',
  },
  changeManagedGroupBody: {
    id: 'ManagedGroupsPage.changeManagedGroupBody',
    defaultMessage:
      'This order is within {name} managed group. {br} You need to change managed groups to view it.',
  },
  noAccessToManagedGroupTitle: {
    id: 'ManagedGroupsPage.noAccessToManagedGroupTitle',
    defaultMessage: 'You do not have access to this booking',
  },
  noAccessToManagedGroupBody: {
    id: 'ManagedGroupsPage.noAccessToManagedGroupBody',
    defaultMessage:
      'This order is within a different managed group which you do not have access to.',
  },
  modalReturnToHomeButton: {
    id: 'ManagedGroupsPage.modalReturnToHomeButton',
    defaultMessage: 'Return to home',
  },
  modalChangeButton: {
    id: 'ManagedGroupsPage.modalChangeButton',
    defaultMessage: 'Change',
  },
});
