import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  changeOfJourney: {
    padding: `${spacers.dp_s32} 0`,
  },
  loaderContainer: {
    padding: spacers.dp_s48,
  },
  header: {
    marginBottom: spacers.dp_s32,
  },
});
