import { StyleSheet } from 'aphrodite/no-important';
import { borderRadius, palettes, spacers } from '@trainline/depot-web/css/themes/trainline';

const leftContainerWidth = '500px';

export default StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: spacers.dp_s48,
  },
  spacer: {
    marginBottom: spacers.dp_s16,
  },
  description: {
    width: '600px',
    marginBottom: spacers.dp_s24,
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  usernameInput: {
    width: leftContainerWidth,
  },
  searchButton: {
    marginLeft: spacers.dp_s8,
    marginTop: '28px', // to compensate for the search input label height
  },
  resultsContainer: {
    marginTop: spacers.dp_s24,
    width: leftContainerWidth,
  },
  resultsList: {
    marginTop: spacers.dp_s16,
    listStyle: 'none',
  },
  listItem: {
    margin: `${spacers.dp_s8} -${spacers.dp_s12}`,
  },
  listItemButton: {
    padding: `${spacers.dp_s8} ${spacers.dp_s12}`,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    ':hover': {
      backgroundColor: palettes.dp_app_selection_lighter,
      borderRadius: borderRadius.dp_r4,
    },
    ':focus': {
      borderRadius: borderRadius.dp_r4,
    },
  },
});
