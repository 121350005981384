import PropTypes from 'prop-types';

const lazyFunction =
  (f) =>
  ({ ...args }) =>
    f().apply(this, args);

const refundReason = PropTypes.shape({
  id: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  parent: PropTypes.shape(lazyFunction(() => refundReason)),
});

export default refundReason;
