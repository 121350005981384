import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import BookingDetail from '../BookingDetail/BookingDetail';
import { OrderHistoryCard, OrderHistoryCardHeader } from '../OrderHistoryCard/OrderHistoryCard';
import type { RefundItem } from '../../module';

import BookingTicketType from './BookingTicketType';
import RefundTable from './RefundTable';
import messages from './messages';
import styles from './styles';

const RefundCard: FunctionComponent<RefundItem> = ({
  agent,
  createdAt,
  amountRequested,
  policyType,
  amountRefunded,
  adminFee,
  promoDiscount,
  reasonCode,
  paidBy,
  billingEmailAddress,
  paymentType,
  card,
  requestorType,
  bookings,
  refundables,
}) => (
  <OrderHistoryCard
    createdAt={createdAt}
    cardTitle={
      policyType && messages[policyType] ? (
        <FormattedMessage {...messages[policyType]} />
      ) : (
        <FormattedMessage {...messages.bookingRefunded} />
      )
    }
    headerItems={
      <>
        <OrderHistoryCardHeader
          label={<FormattedMessage {...messages.booking} />}
          value={<BookingDetail bookings={bookings} />}
        />
        <OrderHistoryCardHeader
          label={
            policyType === 'discretionary' ? (
              <FormattedMessage {...messages.approvedBy} />
            ) : (
              <FormattedMessage {...messages.agent} />
            )
          }
          value={
            <FormattedMessage
              {...messages.requestedBy}
              values={{
                requestorType,
                agent,
              }}
            />
          }
          testId={`requestorType-${requestorType}`}
        />
      </>
    }
    cardBody={
      <div>
        <strong>
          <FormattedMessage {...messages.refundBody} values={{ requestorType }} />
        </strong>
        <ul className={css(styles.ticketsByTypeList)}>
          {bookings &&
            bookings.map((booking, index) => (
              <div key={index}>
                <BookingTicketType {...booking} />
              </div>
            ))}
        </ul>
        <div>{paidBy}</div>
        <div>
          {(card || paymentType) && (
            <FormattedMessage
              {...messages.paymentDetails}
              values={{
                type: card ? card.type : paymentType,
                details: card ? card.tokenisedCardNumber : billingEmailAddress,
              }}
            />
          )}
        </div>
        <RefundTable
          amountRequested={amountRequested}
          amountRefunded={amountRefunded}
          adminFee={adminFee}
          promoDiscount={promoDiscount}
          reasonCode={reasonCode}
          policyType={policyType}
          refundables={refundables}
        />
      </div>
    }
  />
);

export default RefundCard;
