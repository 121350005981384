import { StyleSheet } from 'aphrodite/no-important';
import { borderRadius, palettes, spacers } from '@trainline/depot-web/css/themes/trainline';

import { leftContainerWidth, rightContainerWidth } from '../../styles';

export const selectStyle = {
  borderRadius: spacers.dp_s4,
  border: `1px solid ${palettes.dp_app_border_dark}`,
  borderWidth: '1px 1px 1px 0px',
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
};

export default StyleSheet.create({
  forms: {
    display: 'flex',
  },
  leftContainer: {
    width: leftContainerWidth,
  },
  rightContainer: {
    width: rightContainerWidth,
    marginLeft: spacers.dp_s16,
  },
  form: {
    border: `solid 1px ${palettes.dp_app_border_base}`,
    borderRadius: borderRadius.dp_r8,
    padding: spacers.dp_s24,
  },
  transationForm: {
    background: palettes.dp_app_backdrop_dark,
  },
  customerForm: {
    borderBottomWidth: 0,
    background: palettes.dp_app_backdrop_base,
    marginTop: spacers.dp_s24,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  refundForm: {
    background: palettes.dp_app_backdrop_base,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
  paymentForm: {
    padding: spacers.dp_s24,
    boxShadow: `0px 0px 4px ${palettes.dp_comp_box_shadow}, 0px 4px 16px ${palettes.dp_comp_box_shadow}`,
    display: 'grid',
    rowGap: spacers.dp_s16,
  },
  formGrid: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
    gap: spacers.dp_s16,
    marginTop: spacers.dp_s16,
  },
  biggerColumn: {
    gridColumn: 'span 2',
  },
  currencyContainer: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    alignItems: 'flex-end',
  },
  inputCurrency: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
});
