import { StyleSheet } from 'aphrodite/no-important';
import { typography, spacers } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  container: {
    display: 'flex',
  },
  infoBlock: {
    fontSize: typography.dp_body_font_size,
    lineHeight: typography.dp_body_line_height,
    marginRight: spacers.dp_s32,
    width: '190px',
  },
  infoPaidByBlock: {
    marginLeft: spacers.dp_s32,
  },
  blockTitle: {
    color: colors.blue.cyan,
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    textTransform: 'uppercase',
    marginTop: spacers.dp_s16,
    marginBottom: spacers.dp_s4,
  },
  billingEmailAddress: {
    wordBreak: 'break-all',
  },
});
