import { StyleSheet } from 'aphrodite/no-important';
import { fonts, typography, spacers } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  container: {
    borderTop: `solid 1px ${colors.gray.alpha30}`,
    marginTop: spacers.dp_s16,
    paddingTop: spacers.dp_s8,
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    color: colors.gray.alpha30,
    textTransform: 'uppercase',
    marginBottom: spacers.dp_s4,
    fontWeight: fonts.dp_font_weight_bold,
  },
  values: {
    fontWeight: fonts.dp_font_weight_bold,
  },
  insuranceIcon: {
    display: 'inline',
    width: '16px',
    height: '16px',
    verticalAlign: 'sub',
    marginRight: spacers.dp_s8,
  },
});
