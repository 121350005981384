import { defineMessages } from 'react-intl';

export default defineMessages({
  requestedDate: {
    id: 'TracsOnAccountApprovalItem.requestedDate',
    defaultMessage: '{day} at {time}',
  },
  agent: {
    id: 'TracsOnAccountApprovalItem.agent',
    defaultMessage: 'Agent: {agentName}',
  },
  approveButton: {
    id: 'TracsOnAccountApprovalItem.approveButton',
    defaultMessage: 'Approve',
  },
  rejectButton: {
    id: 'TracsOnAccountApprovalItem.rejectButton',
    defaultMessage: 'Reject',
  },
  refund: {
    id: 'TracsOnAccountApprovalItem.refund',
    defaultMessage: 'Refund ({currencyCode}) <strong>{amount}</strong>',
  },
  reasonCodes: {
    id: 'TracsOnAccountApprovalItem.reasonCodes',
    defaultMessage: 'Reason codes & ticket types',
  },
  notes: {
    id: 'TracsOnAccountApprovalItem.notes',
    defaultMessage: 'Notes from agent',
  },
  managedGroup: {
    id: 'TracsOnAccountApprovalItem.managedGroup',
    defaultMessage: 'Managed group: {managedGroup}',
  },
  corporateReference: {
    id: 'TracsOnAccountApprovalItem.corporateReference',
    defaultMessage: 'Corporate Reference: {corporateReference}',
  },
  approveError: {
    id: 'TracsOnAccountApprovalItem.approveError',
    defaultMessage: 'There was an error approving the refund. Please try again.',
  },
  rejectError: {
    id: 'TracsOnAccountApprovalItem.rejectError',
    defaultMessage: 'There was an error rejecting the refund. Please try again.',
  },
});
