import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { StatusMessage } from '@trainline/depot-web';

import { useCallId } from '@contactcentre-web/hooks/api/useCall';
import { useCrmReasonCodes } from '@contactcentre-web/hooks/api/useCrmIntegration';
import { useApplicationUsed } from '@contactcentre-web/hooks/api/useApplicationUsed';
import { Modal, ModalHeader, ModalTitle } from '@contactcentre-web/common/Modal';
import { getSelectedCustomer } from '@contactcentre-web/redux-common/selectors/customer';
import {
  canLogCall,
  getUsername,
  getCurrentManagedGroupNumber,
} from '@contactcentre-web/authentication/redux/selectors';

import { actions, selectors } from './module';
import OrderContext from './components/OrderContext';
import OrderNoteForm from './components/OrderNoteForm/OrderNoteForm';
import * as surveyChoiceComp from './components/SurveyChoice';
import messages from './messages';
import styles from './styles';

export const FORM_ID = 'OrderNoteForm';

export const OrderNotes = ({
  orderId,
  customer,
  agentName,
  handleSubmit,
  pristine,
  submitting,
  hasSaved,
  actionRenderer,
  canLogCrm,
  valid,
  anyTouched,
  reset: resetForm,
  change,
  loadCallCrmQueues,
  loadSurveyChoices,
  callQueues,
  surveyChoices,
  callQueuesError,
  saveOrderNoteReset,
  note,
  managedGroupNumber,
  logCrmReasonCodesEmpty,
  logCrmReasonCodesFailure,
  logAcsLoadCallFailure,
  logAcsLoadCallNotFound,
  saveOrderNoteAttempt,
}) => {
  const [visible, setVisible] = React.useState(false);
  const loadCallCrmQueuesRef = React.useRef(loadCallCrmQueues);
  const loadSurveyChoicesRef = React.useRef(loadSurveyChoices);

  React.useEffect(() => {
    if (hasSaved) {
      setVisible(false);
      if (resetForm) {
        resetForm();
      }
    }
    return () => saveOrderNoteReset();
  }, [hasSaved, resetForm]);

  React.useEffect(() => {
    if (visible) {
      loadCallCrmQueuesRef.current();
      loadSurveyChoicesRef.current();
    }
  }, [loadCallCrmQueuesRef, loadSurveyChoicesRef, visible]);

  const {
    isSuccess: isSuccessFetchingCallId,
    isLoading: isLoadingCallId,
    data: { callId, agentQueueId: callCrmQueueId } = {},
  } = useCallId({
    enabled: visible && canLogCrm,
    onError: (error) =>
      error?.response?.status === 404 ? logAcsLoadCallNotFound() : logAcsLoadCallFailure(),
  });

  const { data: { items: reasonCodes = [] } = {}, isSuccess: isSuccessPollingCrmReasonCodes } =
    useCrmReasonCodes(callId, {
      enabled: isSuccessFetchingCallId,
      onSuccess: (data) => {
        if (data?.items?.length === 0) {
          logCrmReasonCodesEmpty({ callId });
        }
      },
      onError: (error) => {
        logCrmReasonCodesFailure({ callId, errors: error?.response?.status });
      },
    });

  const { data: { items: applicationTypes = [] } = {} } = useApplicationUsed({
    enabled: visible && canLogCrm,
  });

  return (
    <>
      {React.cloneElement(actionRenderer(), {
        onClick: () => {
          setVisible(true);
          resetForm(FORM_ID);
        },
      })}
      {visible && (
        <Modal
          onClose={() => setVisible(false)}
          styleSheet={{
            content: styles.modalContent,
          }}
        >
          <ModalHeader>
            <ModalTitle>
              <FormattedMessage {...messages.header} />
            </ModalTitle>
          </ModalHeader>
          {callQueuesError && (
            <div data-testid="call-queues-error" className={css(styles.alertBox)}>
              <StatusMessage status="warning">
                <FormattedMessage
                  {...(messages[callQueuesError] || messages.callQueuesGenericError)}
                />
              </StatusMessage>
            </div>
          )}
          <form
            data-test="LogCall"
            className={css(styles.form)}
            id={FORM_ID}
            method="post"
            onSubmit={handleSubmit((data) =>
              saveOrderNoteAttempt({ ...data, callId, callCrmQueueId })
            )}
          >
            <OrderContext orderId={orderId} customer={customer} agentName={agentName} />
            <OrderNoteForm
              canSubmit={valid && !pristine && !submitting}
              isLoading={isLoadingCallId}
              isBusy={submitting}
              canLogCrm={canLogCrm}
              change={change}
              reasonCodes={reasonCodes}
              applicationTypes={applicationTypes}
              callQueues={callQueues}
              surveyChoices={surveyChoices}
              disabled={!valid || !anyTouched}
              form={FORM_ID}
              callId={callId}
              note={note}
              isFreshdeskTicketNotAvailable={
                !isSuccessPollingCrmReasonCodes && isSuccessFetchingCallId
              }
              managedGroupNumber={managedGroupNumber}
            />
          </form>
        </Modal>
      )}
    </>
  );
};

OrderNotes.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  actionRenderer: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  clearAsyncError: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  orderId: PropTypes.string.isRequired,
  customer: PropTypes.object.isRequired,
  agentName: PropTypes.string.isRequired,
  hasSaved: PropTypes.bool,
  valid: PropTypes.bool,
  anyTouched: PropTypes.bool,
  canLogCrm: PropTypes.bool.isRequired,
  reset: PropTypes.func,
  reasonCodes: PropTypes.arrayOf(PropTypes.object),
  callQueues: PropTypes.arrayOf(PropTypes.object),
  surveyChoices: PropTypes.arrayOf(PropTypes.object),
  loadCallCrmQueues: PropTypes.func.isRequired,
  loadSurveyChoices: PropTypes.func.isRequired,
  callQueuesError: PropTypes.string,
  saveOrderNoteReset: PropTypes.func,
  note: PropTypes.string,
  managedGroupNumber: PropTypes.number,
  logCrmReasonCodesEmpty: PropTypes.func.isRequired,
  logCrmReasonCodesFailure: PropTypes.func.isRequired,
  logAcsLoadCallFailure: PropTypes.func.isRequired,
  logAcsLoadCallNotFound: PropTypes.func.isRequired,
  saveOrderNoteAttempt: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const callQueuesError = selectors.getCallCrmQueuesErrors(state);
  const callQueues = selectors.getCallCrmQueues(state);
  const surveyChoices = selectors.getSurveyChoices(state);
  const defaultQueue = callQueues.find((queue) => queue.isDefault);
  const defaultSurveyChoice = surveyChoices.find((choice) => choice.sendSurvey);
  const initialNote = 'Customer Query:\n\nOptions Given:\n\nAgreed Solution:';

  return {
    agentName: getUsername(state),
    customer: getSelectedCustomer(state.customer),
    hasSaved: selectors.hasSaved(state),
    canLogCrm: canLogCall(state),
    callQueues,
    surveyChoices,
    callQueuesError,
    note: initialNote,
    initialValues: {
      note: initialNote,
      callCrmQueueId: defaultQueue && defaultQueue.queueId,
      surveyChoice: defaultSurveyChoice && defaultSurveyChoice.sendSurvey ? 'yes' : 'no',
      surveyChoiceId: defaultSurveyChoice && defaultSurveyChoice.surveyChoiceId,
    },
    managedGroupNumber: getCurrentManagedGroupNumber(state),
  };
};

const mapDispactchToProps = (dispatch) => ({
  loadCallCrmQueues: () => dispatch(actions.loadCallCrmQueues()),
  loadSurveyChoices: () => dispatch(actions.loadSurveyChoices()),
  saveOrderNoteReset: () => dispatch(actions.saveOrderNoteReset()),
  logCrmReasonCodesEmpty: (payload) => dispatch(actions.logCrmReasonCodesEmpty(payload)),
  logCrmReasonCodesFailure: (payload) => dispatch(actions.logCrmReasonCodesFailure(payload)),
  logAcsLoadCallFailure: (payload) => dispatch(actions.logAcsLoadCallFailure(payload)),
  logAcsLoadCallNotFound: (payload) => dispatch(actions.logAcsLoadCallNotFound(payload)),
  saveOrderNoteAttempt: (payload) => dispatch(actions.saveOrderNoteAttempt(FORM_ID, payload)),
});

export const validate = (
  { note, reasonCodeId, callCrmQueueId, surveyChoiceId, applicationUsedId },
  { canLogCrm }
) => {
  const result = {};

  if (!note) {
    result.note = 'noteRequired';
  }
  if (canLogCrm) {
    if (reasonCodeId === null || reasonCodeId === undefined) {
      result.reasonCodeId = 'reasonRequired';
    }
    if (!applicationUsedId) {
      result.applicationUsedId = 'applicationRequired';
    }
  }
  if (!callCrmQueueId) {
    result.callCrmQueueId = 'callCrmQueueRequired';
  }
  if (!surveyChoiceId) {
    result.surveyChoiceId = 'surveyChoiceRequired';
  }

  return result;
};

export default connect(
  mapStateToProps,
  mapDispactchToProps
)(
  reduxForm({
    form: FORM_ID,
    validate,
    onChange: ({ surveyChoice }, dispatch, { touch, change, surveyChoices }) => {
      if (surveyChoice && surveyChoice.trim().toLowerCase() === 'yes') {
        const defaultSurveyChoice =
          surveyChoices && surveyChoices.find((choice) => choice.sendSurvey).surveyChoiceId;
        change(surveyChoiceComp.surveyChoiceId, defaultSurveyChoice);
      }
      touch(surveyChoiceComp.surveyChoiceId);
    },
    enableReinitialize: true,
    touchOnBlur: false,
  })(OrderNotes)
);
