import { StyleSheet } from 'aphrodite/no-important';
import { spacers, typography, fonts } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  paymentTotal: {
    paddingTop: spacers.dp_s24,
  },
  totalRefund: {
    paddingBottom: spacers.dp_s24,
    textAlign: 'right',
    flex: 1,
  },
  totalToRefundLabel: {
    color: colors.gray.alpha33,
    fontWeight: fonts.dp_font_weight_regular,
  },
  totalToRefundValue: {
    fontFamily: typography.dp_title2_font_family,
    fontSize: typography.dp_title2_font_size_m,
    fontWeight: fonts.dp_font_weight_bold,
    lineHeight: typography.dp_title2_line_height_m,
    letterSpacing: '-0.3px', // TODO: replace with depot-web typography when updated in depot-web
  },
  calculatingAmount: {
    fontFamily: typography.dp_title2_font_family,
    fontSize: typography.dp_title2_font_size_m,
    fontWeight: fonts.dp_font_weight_bold,
    lineHeight: typography.dp_title2_line_height_m,
    letterSpacing: '-0.3px', // TODO: replace with depot-web typography when updated in depot-web
    color: colors.gray.alpha30,
    fontStyle: 'italic',
  },
  loaderContainer: {
    display: 'inline-block',
    marginRight: spacers.dp_s4,
    verticalAlign: 'middle',
  },
});
