import React, { FunctionComponent } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { IconSecure } from '@trainline/depot-web';

import styles from './styles';
import messages from './messages';

const Lock: FunctionComponent = ({ children }) => (
  <FormattedMessage {...messages.bookingLocked}>
    {(txt) => (
      <div className={css(styles.container)}>
        <IconSecure className={css(styles.iconSecure)} />
        <strong className={css(styles.label)}>{txt}</strong>
        <span className={css(styles.message)}> - {children}</span>
      </div>
    )}
  </FormattedMessage>
);

export default Lock;
