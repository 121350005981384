import { StyleSheet } from 'aphrodite/no-important';
import { spacers, palettes, fonts } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: spacers.dp_s48,
  },
  title: {
    textAlign: 'center',
  },
  details: {
    marginTop: spacers.dp_s16,
  },
  actionLinks: {
    marginTop: spacers.dp_s16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  actionLink: {
    marginTop: spacers.dp_s8,
  },
  value: {
    color: palettes.dp_brand_primary_core,
    fontWeight: fonts.dp_font_weight_bold,
  },
});
