import { useQuery } from 'react-query';

import request from '@contactcentre-web/utils/request';

const POLL_MAPPING_FREQUENCY_IN_MILISSECONDS = 3600000; // 1 hour

export enum MappingStatuses {
  OK = 'Ok',
  TelephonyAccountAlreadyMapped = 'TelephonyAccountAlreadyMapped',
  TelephonyAccountNotFound = 'TelephonyAccountNotFound',
  Unknown = 'Unknown',
}

export interface MappingStatus {
  errors: MappingError[];
  totalCount: number;
  items: MappingItem[];
}

export interface MappingItem {
  tccUserId: string;
  tccUsername: string;
  updatedAt: string;
  source: 'Storm' | 'AmazonConnect';
  telephonyUserId: string;
  telephonyUsername: string;
  tccEmail: string;
  mappingStatus: MappingStatuses;
}

export interface MappingError {
  code: string;
  detail: string;
}

type MappingStatusError = Error & { response: Response };

export const useMappingStatusPoll = () =>
  useQuery<MappingStatus, MappingStatusError>(
    'mappingStatus',
    () => request(`/agent-call-v2/agentmappings`),
    {
      refetchInterval: POLL_MAPPING_FREQUENCY_IN_MILISSECONDS,
    }
  );
