import { StyleSheet } from 'aphrodite/no-important';
import { spacers, borderRadius } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    backgroundColor: '#fbfbfc',
    borderRadius: borderRadius.dp_r4,
    border: '1px solid #e0e0e0',
    boxShadow: '0 4px 8px 0 rgba(33, 49, 77, 0.2)',
    marginTop: spacers.dp_s32,
    overflow: 'hidden',
  },
});
