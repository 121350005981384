import { defineMessages } from 'react-intl';

export default defineMessages({
  successMessage: {
    id: 'NewAgentConfirmation.successMessage',
    defaultMessage: 'Account successfully created',
  },
  username: {
    id: 'NewAgentConfirmation.username',
    defaultMessage: 'Username',
  },
  temporaryPassword: {
    id: 'NewAgentConfirmation.temporaryPassword',
    defaultMessage: 'Temporary password',
  },
  info: {
    id: 'NewAgentConfirmation.info',
    defaultMessage: 'Please take note of these login credentials and provide them to the agent.',
  },
  addAnotherAgent: {
    id: 'NewAgentConfirmation.addAnotherAgent',
    defaultMessage: 'Add another agent',
  },
});
