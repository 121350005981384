import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import { CustomerBooking, FilterType } from '../../types';

import FilterMenu from './FilterMenu';
import AppliedFilters from './AppliedFilters';
import styles from './styles';
import messages from './messages';

interface Props {
  bookings: Array<CustomerBooking>;
  filteredBookings: Array<CustomerBooking>;
  bookingsCount: number;
  appliedFilters: Set<FilterType>;
  addFilters: (filters: Set<FilterType>) => void;
  removeFilter: (filter: FilterType) => void;
}

const BookingsFilterHeader = ({
  bookings,
  filteredBookings,
  bookingsCount,
  appliedFilters,
  addFilters,
  removeFilter,
}: Props) => (
  <div className={css(styles.container)}>
    <div className={css(styles.filtersResults)}>
      <strong className={css(styles.bookingsCountText)}>
        <FormattedMessage {...messages.bookingsCount} values={{ bookingsCount }} />
      </strong>
      <FilterMenu
        bookings={bookings}
        filteredBookings={filteredBookings}
        appliedFilters={appliedFilters}
        addFilters={addFilters}
      />
    </div>
    {appliedFilters.size > 0 && (
      <div className={css(styles.appliedFiltersContainer)}>
        <AppliedFilters appliedFilters={appliedFilters} removeFilter={removeFilter} />
      </div>
    )}
  </div>
);

export default BookingsFilterHeader;
