import React, { useState } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { StatusMessage } from '@trainline/depot-web';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import FormattedDateInLondonTz from '@contactcentre-web/common/FormattedDateInLondonTz';
import Button from '@contactcentre-web/common/Button';
import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from '@contactcentre-web/common/Modal';
import Link from '@contactcentre-web/common/Link';
import { useDeleteSavedCard, SavedCard } from '@contactcentre-web/hooks/api/useCustomerPassengers';

import messages from './messages';
import styles from './styles';

interface Props {
  savedCard: SavedCard;
  passengerId: string;
}

const getErrorMessageCode = (code: string, isEnd?: boolean): MessageDescriptor | null => {
  const idx = Object.getOwnPropertyNames(messages).indexOf(`${code}ErrorMessage`);
  return idx > -1
    ? Object.values(messages)[idx]
    : !isEnd
    ? getErrorMessageCode(`generic`, true)
    : null;
};

const DeleteSavedCards = ({ savedCard, passengerId }: Props) => {
  const { savedCardId, name, number, validityStart, validityEnd } = savedCard;

  const { customerId } = useParams<{ customerId: string }>();
  const [isVisible, setIsVisible] = useState(false);
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error } = useDeleteSavedCard(
    customerId,
    passengerId,
    savedCardId,
    {
      onSuccess: () => {
        setIsVisible(false);
        queryClient.invalidateQueries('customerPassengers');
      },
    }
  );

  const errorMessage = error?.errors?.errors[0].code.split('.')[1] || 'generic';

  return (
    <>
      <div className={css(styles.deleteSavedCard)}>
        <Link
          linkType="action"
          onClick={() => setIsVisible(true)}
          testId="delete-saved-card-open-modal"
        >
          <FormattedMessage {...messages.deleteSavedCardDel} />
        </Link>
      </div>
      {isVisible && (
        <Modal onClose={() => setIsVisible(false)} testId="delete-saved-card-form-modal">
          <ModalHeader>
            <ModalTitle>
              <FormattedMessage {...messages.deleteSavedCardHeader} />
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            {isError && (
              <div className={css(styles.errorContainer)}>
                <StatusMessage status="negative">
                  <FormattedMessage {...getErrorMessageCode(errorMessage)} />
                </StatusMessage>
              </div>
            )}
            <div className={css(styles.passengerName)} data-test="passenger-name">
              {name} {number}
            </div>
            <div className={css(styles.textLabel)} data-test="saved-card-number">
              {number ? (
                <FormattedMessage
                  {...messages.savedCardNumber}
                  values={{
                    number,
                  }}
                />
              ) : (
                <FormattedMessage {...messages.savedCardNumberNA} />
              )}
            </div>
            <div className={css(styles.textLabel)} data-test="saved-card-start">
              {validityStart ? (
                <FormattedMessage
                  {...messages.savedCardStartDate}
                  values={{
                    validityStart: <FormattedDateInLondonTz value={validityStart} />,
                  }}
                />
              ) : (
                <FormattedMessage {...messages.savedCardStartDateNA} />
              )}
            </div>
            <div className={css(styles.textLabel)} data-test="saved-card-expiry">
              {validityEnd ? (
                <FormattedMessage
                  {...messages.savedCardExpiryDate}
                  values={{
                    validityEnd: <FormattedDateInLondonTz value={validityEnd} />,
                  }}
                />
              ) : (
                <FormattedMessage {...messages.savedCardExpiryDateNA} />
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="primary"
              size="large"
              testId="submit-saved-card-delete"
              loading={isLoading}
              onClick={() => mutate()}
            >
              <FormattedMessage {...messages.deleteSavedCardDelButton} />
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default DeleteSavedCards;
