import { useMutation } from 'react-query';
import { saveAs } from 'file-saver';

import request from '@contactcentre-web/utils/request';

export const useDownloadPdf = (fileName?: string, errorHandler?: (error: unknown) => void) =>
  useMutation<Blob, unknown, string, unknown>((link) => request(link), {
    onSuccess: (response) => {
      const blob = new Blob([response], { type: 'application/pdf' });
      saveAs(blob, fileName);
    },
    onError: (error) => {
      if (errorHandler) errorHandler(error);
    },
  });
