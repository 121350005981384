import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { IconPassenger } from '@trainline/depot-web';

import Lock from '@contactcentre-web/common/Lock';
import Railcard from '@contactcentre-web/common/Railcard';
import { Journey, TicketType, Validity } from '@contactcentre-web/redux-common/types/SeasonProduct';
import ConditionsOfUse from '@contactcentre-web/redux-common/types/ConditionsOfUse';
import Link from '@contactcentre-web/common/Link';
import { DeliveryMethodId } from '@contactcentre-web/redux-common/types/DeliveryMethod';

import TermsAndConditionsModal from '../TermsAndConditionsModal';

import messages from './messages';
import styles from './styles';

export interface Props {
  journey: Journey;
  ticketType: TicketType;
  externallyModified: boolean;
  conditionsOfUse: ConditionsOfUse[];
  smartcardNumber?: string;
  isFlexiSeason: boolean;
  deliveryMethod: string;
  validity: Validity;
}

const BookingInfo = ({
  journey,
  ticketType,
  externallyModified,
  conditionsOfUse,
  smartcardNumber,
  isFlexiSeason,
  deliveryMethod,
  validity,
}: Props) => {
  const [showTermsAndConditionsModal, toggleTermsAndConditionsModal] = React.useState(false);
  const displayPhotocardNumber =
    journey.passenger.identificationDocument?.number &&
    !(isFlexiSeason && deliveryMethod === DeliveryMethodId.AtocMTicket);

  return (
    <>
      {externallyModified && (
        <Lock>
          <FormattedMessage {...messages.externallyModified} />
        </Lock>
      )}
      <div className={css(styles.table, externallyModified && styles.locked)}>
        <div className={css(styles.header)}>
          <div className={css(styles.cell)} />
          <div className={css(styles.cell)}>
            <FormattedMessage {...messages.passenger} />
          </div>
          {(smartcardNumber || displayPhotocardNumber) && (
            <div
              className={css(styles.cell)}
              data-testid={smartcardNumber ? 'smartcard-title' : 'photocard-title'}
            >
              <FormattedMessage {...messages[smartcardNumber ? 'smartcard' : 'photocard']} />
            </div>
          )}
          <div className={css(styles.cell)}>
            <FormattedMessage {...messages.class} />
          </div>
          <div className={css(styles.cell)}>
            <FormattedMessage {...messages.ticketType} />
          </div>
          <div className={css(styles.cell)}>
            <FormattedMessage {...messages.validity} />
          </div>
        </div>
        <div className={css(styles.row)}>
          <div className={css(styles.cell)}>
            <div className={css(styles.passengerTypeHeader)}>
              <div>
                <IconPassenger />
              </div>
              <div className={css(styles.passengerTypeContent)}>
                <FormattedMessage
                  {...messages.passengerType}
                  values={{ type: journey.passenger.passengerType }}
                />
              </div>
            </div>
            {journey.passenger.railcard && (
              <div className={css(styles.railcardContainer)}>
                <Railcard card={journey.passenger.railcard} />
              </div>
            )}
          </div>
          <div className={css(styles.cell)}>
            <strong>{journey.passenger.fullName}</strong>
          </div>
          {displayPhotocardNumber ? (
            <div className={css(styles.cell)}>
              <strong data-testid="photocard-number">
                {journey.passenger.identificationDocument?.number}
              </strong>
            </div>
          ) : smartcardNumber ? (
            <div className={css(styles.cell)}>
              <strong data-testid="smartcard-number">{smartcardNumber}</strong>
            </div>
          ) : null}
          <div className={css(styles.cell)}>{journey.class}</div>
          <div className={css(styles.cell)}>
            {showTermsAndConditionsModal && (
              <TermsAndConditionsModal
                toggleModal={(): void =>
                  toggleTermsAndConditionsModal(!showTermsAndConditionsModal)
                }
                conditionsOfUse={conditionsOfUse}
              />
            )}
            <Link
              linkType="action"
              onClick={(): void => toggleTermsAndConditionsModal(!showTermsAndConditionsModal)}
              testId="ticket-type"
            >
              {ticketType.displayName}
            </Link>
          </div>
          <div className={css(styles.cell)}>{validity.displayName}</div>
        </div>
      </div>
    </>
  );
};

export default BookingInfo;
