import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    marginTop: spacers.dp_s16,
  },
  column: {
    width: '640px',
    marginBottom: spacers.dp_s16,
  },
  fieldContainer: {
    position: 'relative',
  },
  info: {
    position: 'absolute',
    top: '24px',
    left: '700px',
  },
});
