import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'RefreshPnrConfirmation.header',
    defaultMessage: 'Confirm refresh of booking',
  },
  body: {
    id: 'RefreshPnrConfirmation.body',
    defaultMessage:
      'This function will create a new version of the customer’s booking; it may also generate multiple bookings. The new version(s) may no longer match what the customer sees in the app. Please note this may take a few seconds.',
  },
  proceed: {
    id: 'RefreshPnrConfirmation.proceed',
    defaultMessage: 'Proceed',
  },
  cancel: {
    id: 'RefreshPnrConfirmation.cancel',
    defaultMessage: 'Cancel',
  },
});
