import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '../Button';

import messages from './messages';
import styles from './styles';

interface Props {
  totalCount: number;
  pageSize: number;
  page: number;
  isLoading: boolean;
  fetchNextPage: () => void;
}

const LoadMoreButton = ({ totalCount, pageSize, page, fetchNextPage, isLoading }: Props) => {
  const remainingItems = totalCount - pageSize * page;
  const loadMoreItemsCount = remainingItems > pageSize ? pageSize : remainingItems;

  return loadMoreItemsCount > 0 ? (
    <Button
      variant="tertiary"
      size="small"
      styleSheet={styles.loadMore}
      onClick={fetchNextPage}
      testId="loadMore"
      loading={isLoading}
      showLoadingIndicator
    >
      <FormattedMessage
        {...(isLoading ? messages.loading : messages.load)}
        values={{ loadMoreItemsCount }}
      />
    </Button>
  ) : null;
};

export default LoadMoreButton;
