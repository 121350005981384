import React, { useState, FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { StatusMessage } from '@trainline/depot-web';

import StackedLabel from '@contactcentre-web/common/StackedLabel/StackedLabel';
import Button from '@contactcentre-web/common/Button';
import OrderNoteItem from '@contactcentre-web/common/OrderNoteItem/OrderNoteItem';
import Price from '@contactcentre-web/redux-common/types/Price';

import CompensationApprovalPrompt from '../CompensationApprovalPrompt/CompensationApprovalPrompt';
import CompensationRejectionPrompt from '../CompensationRejectionPrompt/CompensationRejectionPrompt';

import styles from './styles';
import messages from './messages';

interface StateProps {
  reasonCode?: string;
  note?: string;
  amount: Price;
  orderId: string;
  customerId: string;
  id: string;
  isReasonCodeArchived: boolean;
  isResolutionInProgress: boolean;
  hasResolutionSucceeded: boolean;
}
interface DispatchProps {
  onApprove: (reasonCode: string, note: string) => void;
  onReject: (data: { reason?: string; note?: string }) => void;
  onEdit: (customerId: string, orderId: string, id: string) => void;
}

const CompensationApprovalDetails: FunctionComponent<StateProps & DispatchProps> = ({
  reasonCode,
  note,
  amount,
  onApprove,
  onReject,
  onEdit,
  customerId,
  orderId,
  id,
  isResolutionInProgress,
  hasResolutionSucceeded,
  isReasonCodeArchived,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={css(styles.container)}>
      <div>
        <StackedLabel
          orientation="vertical"
          label={
            <span className={css(styles.label)}>
              <FormattedMessage {...messages.reasonCode} />
            </span>
          }
        >
          <span className={css(styles.reasonCode)}>{reasonCode}</span>
        </StackedLabel>
        {isReasonCodeArchived && (
          <StatusMessage status="warning">
            <FormattedMessage {...messages.reasonCodeArchived} />
          </StatusMessage>
        )}
        {note && note.length > 0 && (
          <OrderNoteItem
            expand={() => setExpanded((expanded) => !expanded)}
            isExpanded={expanded}
            message={note}
          />
        )}
      </div>
      <div className={css(styles.buttonsContainer)}>
        <Button
          variant="tertiary"
          size="small"
          fullWidth
          styleSheet={styles.button}
          onClick={() => onEdit(customerId, orderId, id)}
          testId={`edit-compensation@${id}`}
        >
          <FormattedMessage {...messages.edit} />
        </Button>
        <CompensationApprovalPrompt
          amount={amount}
          onConfirm={() => onApprove(reasonCode || '', note || '')}
          isResolutionInProgress={isResolutionInProgress}
          hasResolutionSucceeded={hasResolutionSucceeded}
        >
          <Button variant="primary" size="small" fullWidth styleSheet={styles.button}>
            <FormattedMessage {...messages.approve} />
          </Button>
        </CompensationApprovalPrompt>
        <CompensationRejectionPrompt
          onReject={(data) => onReject(data)}
          isResolutionInProgress={isResolutionInProgress}
          hasResolutionSucceeded={hasResolutionSucceeded}
        >
          <Button variant="destructive" size="small" fullWidth styleSheet={styles.button}>
            <FormattedMessage {...messages.reject} />
          </Button>
        </CompensationRejectionPrompt>
      </div>
    </div>
  );
};

export default CompensationApprovalDetails;
