import { createActions } from 'redux-actions';

export const PREFIX = 'PROFILE';
export const SET_LOCALE = 'SET_LOCALE';
export const SET_LOCALE_SUCCESS = 'SET_LOCALE_SUCCESS';

export const actionTypes = {
  SET_LOCALE: `${PREFIX}/${SET_LOCALE}`,
  SET_LOCALE_SUCCESS: `${PREFIX}/${SET_LOCALE_SUCCESS}`,
};

export const actions = createActions(
  {
    [SET_LOCALE]: (locale, skipInit) => ({ locale, skipInit }),
    [SET_LOCALE_SUCCESS]: (locale) => ({ locale }),
  },
  { prefix: PREFIX }
);
