import React from 'react';
import { css } from 'aphrodite/no-important';

import styles from './styles';

interface Props {
  name: string;
}

const RailcardLabel = ({ name }: Props) => (
  <span className={css(styles.railcardLabel)}>{name}</span>
);

export default RailcardLabel;
