import { defineMessages } from 'react-intl';

export default defineMessages({
  bookingLockedMessage: {
    id: 'BookingLocked.bookingLockedMessage',
    defaultMessage: ' - this booking has been manually locked.',
  },
  capitaineLock: {
    id: 'BookingLocked.capitaineLock',
    defaultMessage:
      "this booking has been modified in <a>BEBOC /Capitaine</a>. The booking details here may be out of date. Some functions (eg. refund, etc) can't be used here anymore.",
  },
  tracksLock: {
    id: 'BookingLocked.tracksLock',
    defaultMessage:
      "this booking has been modified in TraCS. The booking details here may be out of date. Some functions (eg. refund, etc) can't be used here anymore.",
  },
});
