import React, { createRef, SyntheticEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { Heading } from '@trainline/depot-web';
import StatusMessage from '@trainline/depot-web/components/Messages/StatusMessage';

import Button from '@contactcentre-web/common/Button';
import Select from '@contactcentre-web/common/Select';
import { useBulkUpload } from '@contactcentre-web/hooks/api/useBulkUpload';

import messages from './messages';
import styles from './styles';
import FraudPreventionUploadStatusMessage from './components/FraudPreventionUploadStatusMessage/FraudPreventionUploadStatusMessage';

const BulkUpload = () => {
  const { mutate: bulkUploadMutate, isLoading, isSuccess, error } = useBulkUpload();
  const [showFileTooLargeStatus, setShowFileTooLargeStatus] = React.useState<boolean>(false);
  const { formatMessage } = useIntl();
  const uploadOptions = [
    {
      label: formatMessage(messages.uploadFraudPreventionData),
      value: messages.uploadFraudPreventionData.id,
    },
  ];
  const acceptedFileType = '.csv';
  const inputRef = createRef<HTMLInputElement>();

  const submitFile = (file: File) => {
    const formData = new FormData();
    formData.append('File', file, file.name);
    formData.append('name', file.name);
    bulkUploadMutate(formData);
  };

  const handleFileInput = (e: SyntheticEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const file = (target.files as FileList)[0];
    if (file.size > 500000) {
      setShowFileTooLargeStatus(true);
    } else {
      setShowFileTooLargeStatus(false);
      submitFile(file);
    }
  };

  return (
    <>
      <Heading typeStyle="title1" as="h1" color="base">
        <FormattedMessage {...messages.bulkUpload} />
      </Heading>
      <div className={css(styles.content)}>
        {showFileTooLargeStatus && (
          <StatusMessage status="negative">
            <FormattedMessage {...messages['bulkUploadSizeError']} />
          </StatusMessage>
        )}
        {(isSuccess || error) && (
          <FraudPreventionUploadStatusMessage isSuccess={isSuccess} uploadError={error} />
        )}
        <div className={css(styles.selectBox)}>
          <Select
            key="uploadType"
            label={<FormattedMessage {...messages.bulkUploadType} />}
            testId="uploadType"
            options={uploadOptions}
            value={uploadOptions[0].value}
            disabled
          />
          <input
            type="file"
            accept={acceptedFileType}
            onChange={handleFileInput}
            hidden
            ref={inputRef}
          />
          <Button
            variant="primary"
            size="large"
            loading={isLoading}
            onClick={() => inputRef.current?.click()}
            styleSheet={styles.button}
          >
            <FormattedMessage {...messages.bulkUpload} />
          </Button>
        </div>
      </div>
    </>
  );
};

export default BulkUpload;
