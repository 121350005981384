import { StyleSheet } from 'aphrodite/no-important';
import { fonts, typography, spacers } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  itemTableHeader: {
    textAlign: 'left',
    width: '200px',
    fontSize: typography.dp_small_font_size_m,
    lineHeight: typography.dp_small_line_height,
  },
  header: {
    fontSize: typography.dp_small_font_size_m,
    lineHeight: typography.dp_small_font_size_m,
    fontWeight: fonts.dp_font_weight_semibold,
    color: colors.gray.alpha34,
    textAlign: 'left',
    padding: `0 ${spacers.dp_s48}`,
  },

  paymentBlock: {
    width: '100%',
  },

  rightAlign: {
    marginLeft: 'auto',
  },

  breakdownToggle: {
    background: 'none',
    color: colors.white,
    cursor: 'pointer',
    textDecoration: 'underline',
  },

  totalContainer: {
    borderTop: `1px solid ${colors.blue.cyan}`,
    margin: `${spacers.dp_s8} ${spacers.dp_s16} 0`,
    paddingTop: spacers.dp_s16,
  },

  totalContainerTable: {
    borderCollapse: 'collapse',
    width: '100%',
  },

  orderTotal: {
    fontWeight: fonts.dp_font_weight_bold,
  },

  totalTickets: {
    display: 'flex',
    fontSize: typography.dp_body_font_size,
    fontWeight: fonts.dp_font_weight_bold,
    lineHeight: 'normal',
    paddingBottom: spacers.dp_s16,
    marginBottom: spacers.dp_s16,
    borderBottom: `1px dashed ${colors.blue.cyan}`,
  },

  paymentTable: {
    borderCollapse: 'collapse',
    tableLayout: 'fixed',
    width: '100%',
  },

  ticketsTableHeader: {
    color: colors.blue.cyan,
    fontSize: typography.dp_micro_font_size,
    fontWeight: fonts.dp_font_weight_regular,
    lineHeight: typography.dp_micro_line_height,
    padding: 0,
    textTransform: 'uppercase',
    width: '100px',
  },

  ticketsCellAlignRight: {
    textAlign: 'right',
  },
  totalCreditTrainline: {
    paddingRight: 60,
  },

  insuranceContainer: {
    marginBottom: spacers.dp_s8,
  },
});
