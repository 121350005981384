import React, { ReactElement, EventHandler } from 'react';
import { Field, WrappedFieldProps, WrappedFieldInputProps } from 'redux-form';
import ReactSelect, {
  components,
  MenuPlacement,
  MenuProps,
  NonceProvider,
  OptionProps,
  SingleValueProps,
} from 'react-select';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';
import { useIntl, FormattedMessage, MessageDescriptor } from 'react-intl';
import { IconChevronDown } from '@trainline/depot-web';

import Label from '../Label/Label';

import messages from './messages';
import styles, {
  controlStyle,
  controlNotDisabledStyle,
  controlDisabledStyle,
  controlErrorStyle,
  singleValueStyle,
  singleValueDisabledStyle,
  menuStyle,
  menuListStyle,
  selectedOptionStyle,
  optionStyle,
  indicatorStyle,
  placeholderStyle,
  focusedStyle,
} from './styles';

type Option = {
  label: string | JSX.Element;
  value: string | number;
};

interface SimpleSelectProps {
  noChevron?: boolean;
  validationError?: boolean;
  disabled?: boolean;
  searchable?: boolean;
  options?: Option[];
  value?: string;
  label?: string | ReactElement;
  placeholder?: string | ReactElement;
  onChange?: EventHandler<any>;
  input?: WrappedFieldInputProps;
  selectStyle?: StyleDeclarationValue;
  valueStyle?: StyleDeclarationValue;
  chevronStyle?: StyleDeclarationValue;
  containerStyle?: StyleDeclarationValue;
  labelStyle?: StyleDeclarationValue;
  testId?: string;
  messages?: Record<string, MessageDescriptor>;
  displayErrorContainer?: boolean;
  showOptionValue?: boolean;
  selectDirection?: MenuPlacement;
}

interface SelectProps extends SimpleSelectProps {
  name?: string;
}

const cspNonce = document.querySelector('meta[property="csp-nonce"]')?.getAttribute('content');

const SimpleSelect = ({
  noChevron = false,
  validationError,
  disabled,
  searchable,
  options,
  value,
  label,
  showOptionValue,
  placeholder,
  onChange,
  input,
  selectStyle,
  valueStyle,
  chevronStyle,
  containerStyle,
  selectDirection = 'auto',
}: SimpleSelectProps) => {
  const { formatMessage } = useIntl();

  const DropdownIndicator = (noChevron: boolean) =>
    noChevron ? null : (
      <IconChevronDown
        className={css(styles.chevron, chevronStyle, disabled && styles.chevronDisabled)}
      />
    );

  const Menu = (props: MenuProps<Option, false>) => (
    <div data-testid={input?.name ? `${input?.name}-menu-container` : 'menu-container'}>
      <components.Menu {...props}>{props.children}</components.Menu>
    </div>
  );

  const Option = (props: OptionProps<Option, false>) => (
    <components.Option {...props}>
      {props.children}
      {showOptionValue && (
        <div className={css(styles.optionValue, props.isSelected && styles.optionValueSelected)}>
          {props?.data?.value}
        </div>
      )}
    </components.Option>
  );

  const SingleValue = (props: SingleValueProps<Option>) => (
    <components.SingleValue {...props}>
      {props.children}
      {showOptionValue && ` (${props?.data?.value})`}
    </components.SingleValue>
  );

  const customStyles = {
    control: (_: unknown, { isFocused }: { isFocused: boolean }) => ({
      ...controlStyle,
      ...(disabled ? controlDisabledStyle : controlNotDisabledStyle),
      ...selectStyle,
      ...(validationError ? controlErrorStyle : {}),
      ...(isFocused ? focusedStyle : {}),
    }),
    valueContainer: () => ({}),
    placeholder: (base: StyleDeclarationValue) => ({ ...base, ...placeholderStyle }),
    indicatorSeparator: () => ({
      ...indicatorStyle,
    }),
    input: () => ({}),
    singleValue: (base: StyleDeclarationValue) => ({
      ...base,
      ...singleValueStyle,
      ...(disabled ? singleValueDisabledStyle : {}),
      ...valueStyle,
    }),
    menu: (base: StyleDeclarationValue) => ({
      ...base,
      ...menuStyle,
    }),
    menuList: (base: StyleDeclarationValue) => ({ ...base, ...menuListStyle }),
    option: (base: StyleDeclarationValue, { isSelected }: { isSelected: boolean }) => ({
      ...base,
      ...optionStyle,
      ...(isSelected ? selectedOptionStyle : {}),
    }),
  };

  return (
    <div className={css(containerStyle)}>
      {label && <Label htmlFor={input?.name} label={label} />}
      <NonceProvider nonce={cspNonce ?? ''} cacheKey="ccweb">
        <ReactSelect
          key="select"
          inputId={input?.name}
          id={input?.name ? `select-input-${input.name}` : 'select-input'}
          instanceId={input?.name}
          isDisabled={disabled}
          isClearable={false}
          isSearchable={searchable}
          placeholder={placeholder}
          noOptionsMessage={() => formatMessage(messages.noResultsText)}
          styles={customStyles}
          components={{
            DropdownIndicator: () => DropdownIndicator(noChevron),
            Menu,
            Option,
            SingleValue,
          }}
          options={options}
          {...input}
          value={options?.filter(
            ({ value: optionValue }) => optionValue === (input?.value || value)
          )}
          onChange={
            onChange
              ? (option) => onChange(option?.value)
              : (option) => input?.onChange(option?.value)
          }
          onBlur={() => input?.onBlur(input.value)}
          menuPlacement={selectDirection}
        />
      </NonceProvider>
    </div>
  );
};

const SelectWrapper = (props: SimpleSelectProps & WrappedFieldProps) => {
  const {
    testId,
    messages: errorMessages,
    displayErrorContainer = true,
    meta: { touched, error },
  } = props;

  const validationError = touched && !!error;

  return (
    <div data-test={testId}>
      <SimpleSelect {...props} validationError={validationError} />
      {displayErrorContainer && (
        <div className={errorMessages && css(styles.errorContainer)}>
          {errorMessages && errorMessages[error] && validationError && (
            <FormattedMessage {...errorMessages[error]} />
          )}
        </div>
      )}
    </div>
  );
};

const Select = ({ name, ...otherProps }: SelectProps) =>
  // if name prop is provided we automatically connect to redux form
  name ? (
    <Field name={name} component={SelectWrapper} {...otherProps} />
  ) : (
    <SimpleSelect {...otherProps} />
  );

export default Select;
