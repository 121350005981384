import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { IconReturn, IconSingle } from '@trainline/depot-web';

import FormattedDateInTz from '@contactcentre-web/common/FormattedDateInTz';
import FormattedDateInLondonTz from '@contactcentre-web/common/FormattedDateInLondonTz';

import type { LatestBooking } from '../../utils/searchService';

import styles from './styles';
import messages from './messages';

const MostRecentBooking: FunctionComponent<LatestBooking> = ({
  error,
  orderReference,
  departureDate,
  from,
  to,
  orderDate,
  isReturn,
  bookedVia,
}) => {
  if (error) {
    return (
      <div className={css(styles.errorContainer)}>
        {orderReference ? (
          <FormattedMessage {...messages.errorWithOrderReference} values={{ orderReference }} />
        ) : (
          <FormattedMessage {...messages.error} />
        )}
      </div>
    );
  }

  const noBookings = !orderReference && !error;

  if (noBookings) {
    return (
      <div className={css(styles.noPreviousBookingsContainer)}>
        <FormattedMessage {...messages.noPreviousBookings} />
      </div>
    );
  }

  const arrowIcon = isReturn ? (
    <span>
      <IconReturn />
    </span>
  ) : (
    <span>
      <IconSingle />
    </span>
  );

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.orderDate)}>
        <FormattedMessage
          {...messages.journeyDetails}
          values={{
            date: (
              <FormattedDateInLondonTz
                value={orderDate}
                year="numeric"
                month="short"
                day="numeric"
                weekday="short"
                hour="2-digit"
                minute="2-digit"
              />
            ),
          }}
        />
      </div>
      <div className={css(styles.orderReferenceContainer)}>
        <FormattedMessage {...messages.orderReference} />{' '}
        <span className={css(styles.orderReference)}>{orderReference}</span>
      </div>
      <div className={css(styles.journeyRoute)}>
        {from} {arrowIcon} {to}
      </div>
      <div className={css(styles.departureDetails)}>
        <span>
          <FormattedDateInTz
            value={departureDate}
            year="numeric"
            month="short"
            day="numeric"
            weekday="short"
            hour="2-digit"
            minute="2-digit"
          />
        </span>
        <span className={css(styles.bookedVia)}>{`(${bookedVia})`}</span>
      </div>
    </div>
  );
};

export default MostRecentBooking;
