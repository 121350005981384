import React from 'react';

import ManualEntryConsoleAdd from '@contactcentre-web/manual-entry-console-add/ManualEntryConsoleAdd';
import PageContainer from '@contactcentre-web/common/PageContainer/PageContainer';

import styles from './styles';

const ManualEntryConsoleAddPage = () => (
  <PageContainer styleSheet={styles.container}>
    <ManualEntryConsoleAdd />
  </PageContainer>
);

export default ManualEntryConsoleAddPage;
