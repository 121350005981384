import { StyleSheet } from 'aphrodite/no-important';
import { palettes } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  statusIssued: {
    color: palettes.dp_app_text_positive,
  },
  statusIssuing: {
    color: palettes.dp_app_text_warning,
  },
  statusOthers: {
    color: palettes.dp_app_text_negative,
  },
  statusSuperseded: {
    color: palettes.dp_app_info_mid,
  },
  noColor: {
    color: palettes.dp_app_text_base,
  },
});
