import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: spacers.dp_s48,
  },
  innerContainer: {
    width: '374px',
  },
  header: {
    marginBottom: spacers.dp_s16,
  },
  button: {
    marginBottom: spacers.dp_s8,
  },
  alert: {
    marginBottom: spacers.dp_s12,
  },
  cancel: {
    marginTop: spacers.dp_s24,
  },
});
