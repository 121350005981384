import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'CompensationApprovalPrompt.title',
    defaultMessage: 'Confirm customer compensation',
  },
  body: {
    id: 'CompensationApprovalPrompt.body',
    defaultMessage:
      'Are you sure you wish to reimburse {amount, number, withDecimalPlaces} {currencyCode} to the customer? This may take up to 24 hours to be reflected in the customer’s account.',
  },
  buttonApprove: {
    id: 'CompensationApprovalPrompt.buttonApprove',
    defaultMessage: 'Approve request',
  },
  buttonCancel: {
    id: 'CompensationApprovalPrompt.buttonCancel',
    defaultMessage: 'Cancel',
  },
});
