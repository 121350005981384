import { defineMessages } from 'react-intl';

export default defineMessages({
  journeyCount: {
    id: 'SupersededBooking.Journey.journeyCount',
    defaultMessage: 'Journey {journeyNum} of {journeyCount}',
  },

  directionOfJourney: {
    id: 'SupersededBooking.Journey.directionOfJourney',
    defaultMessage: '{origin} to {destination}',
  },

  duration: {
    id: 'SupersededBooking.Journey.duration',
    defaultMessage: 'Duration:',
  },

  changes: {
    id: 'SupersededBooking.Journey.changes',
    defaultMessage: 'Changes:',
  },

  fulfillment: {
    id: 'SupersededBooking.Journey.fulfillment',
    defaultMessage: 'Fulfillment status: {status}',
  },

  returnIsValidFor: {
    id: 'SupersededBooking.Journey.returnIsValidFor',
    defaultMessage: 'The return is valid for {period}',
  },
});
