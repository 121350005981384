import React, { FunctionComponent } from 'react';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { Field, WrappedFieldInputProps, WrappedFieldProps } from 'redux-form';

import TextInput from '../TextInput/TextInput';

import styles from './styles';

const VALID_AMOUNT_REGEX = /^\d*(\.\d{0,2})?$/i;

interface MoneyInputProps {
  messages: Record<string, MessageDescriptor>;
  styleSheet?: {
    input?: StyleDeclarationValue;
    container?: StyleDeclarationValue;
    inputContainer?: StyleDeclarationValue;
    currency?: StyleDeclarationValue;
    errorContainer?: StyleDeclarationValue;
  };
  displayErrorContainer?: boolean;
  currencyText: string;
  disabled?: boolean;
}

export const MoneyInput: FunctionComponent<
  MoneyInputProps & WrappedFieldProps & WrappedFieldInputProps
> = (props) => {
  const {
    input,
    messages,
    meta: { touched, error, submitting },
    styleSheet,
    displayErrorContainer = true,
    currencyText,
    disabled,
  } = props;

  const validationError = touched && !!error;

  return (
    <div className={css(styles.container, styleSheet && styleSheet.container)}>
      <div className={css(styles.inputContainer, styleSheet && styleSheet.inputContainer)}>
        <TextInput
          styleSheet={{
            ...(!validationError ? styles.input : styles.inputError),
            ...(styleSheet && styleSheet.input),
          }}
          {...input}
          {...props}
          onChange={(e) => {
            if (VALID_AMOUNT_REGEX.test(e.target.value)) {
              input.onChange(e);
            }
          }}
          disabled={submitting || disabled}
        />
        <div
          className={css(
            styles.currency,
            validationError && styles.currencyError,
            styleSheet && styleSheet.currency,
            submitting || disabled ? styles.currencyDisabled : undefined
          )}
        >
          {currencyText}
        </div>
      </div>
      {displayErrorContainer && touched && error && messages[error] && (
        <div className={css(styles.errorContainer, styleSheet && styleSheet.errorContainer)}>
          <FormattedMessage {...messages[error]} />
        </div>
      )}
    </div>
  );
};

interface MoneyFieldProps extends MoneyInputProps {
  name: string;
}

export const MoneyField: FunctionComponent<MoneyFieldProps> = ({ name, ...others }) => (
  <Field
    name={name}
    type="number"
    step="0.01"
    placeholder="0.00"
    component={MoneyInput}
    {...others}
  />
);

export default MoneyField;
