import { StyleSheet } from 'aphrodite/no-important';
import { typography, spacers, fonts } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  container: {
    margin: `${spacers.dp_s8} 0`,
    textAlign: 'right',
  },
  totalToRefundLabel: {
    color: colors.gray.alpha33,
    fontWeight: fonts.dp_font_weight_regular,
  },
  totalToRefundValue: {
    fontFamily: typography.dp_title2_font_family,
    fontSize: typography.dp_title2_font_size_m,
    fontWeight: fonts.dp_font_weight_bold,
    lineHeight: typography.dp_title2_line_height_m,
    letterSpacing: '-0.3px', // TODO: replace with depot-web typography when updated in depot-web
  },
});
