const currencyMap = {
  gbp: '£',
  eur: '€',
  usd: '$',
};

export const currencyToSymbol = (code: string) => {
  const idx = Object.keys(currencyMap).indexOf(code.toLowerCase());

  return Object.values(currencyMap)[idx];
};

export const formatPrice = (decimalNum: number) => decimalNum.toFixed(2);

export const sortByPrice = (list: any, sortPropName: string, orderDesc: boolean) => {
  const sorted = [...list].sort((a, b) => a[sortPropName].amount - b[sortPropName].amount);
  return orderDesc ? sorted.reverse() : sorted;
};

export default {
  currencyToSymbol,
};
