import { StyleSheet } from 'aphrodite/no-important';
import {
  typography,
  spacers,
  borderRadius,
  fonts,
} from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  passengerContent: {
    width: '324px',
    minHeight: '241px',
    background: colors.white,
    border: `1px solid ${colors.gray.alpha12}`,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.12), 0px 2px 6px rgba(0, 0, 0, 0.12)',
    padding: spacers.dp_s32,
    borderRadius: borderRadius.dp_r2,
    marginRight: spacers.dp_s32,
    marginTop: spacers.dp_s32,
  },
  accountHolder: {
    background: colors.brand.lighter,
    borderRadius: borderRadius.dp_r2,
    width: '150px',
    height: '24px',
    padding: spacers.dp_s4,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: spacers.dp_s8,
  },
  accountHolderLabel: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    fontWeight: fonts.dp_font_weight_bold,
    color: colors.blue.heavy,
  },
  passengerContainer: {
    background: colors.gray.alpha4,
    borderRadius: borderRadius.dp_r2,
    width: '88px',
    height: '24px',
    padding: spacers.dp_s4,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: spacers.dp_s8,
  },
  passengerContainerLabel: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    fontWeight: fonts.dp_font_weight_bold,
    color: colors.gray.alpha60,
    paddingLeft: spacers.dp_s8,
    wordBreak: 'break-word',
  },
  textLable: {
    fontSize: typography.dp_small_font_size_m,
    letterSpacing: typography.dp_small_letter_spacing,
    lineHeight: typography.dp_small_line_height,
    marginBottom: spacers.dp_s8,
    wordBreak: 'break-word',
  },
  passengerName: {
    fontSize: typography.dp_body_font_size,
    lineHeight: typography.dp_body_line_height,
    letterSpacing: typography.dp_body_letter_spacing,
    fontWeight: fonts.dp_font_weight_bold,
    marginBottom: spacers.dp_s8,
    wordBreak: 'break-word',
  },
  savedCards: {
    marginTop: spacers.dp_s32,
  },
});
