import { defineMessages } from 'react-intl';

export default defineMessages({
  createdAt: {
    id: 'Beboc.createdAt',
    defaultMessage: '{day} at {time}',
  },
  bookingsLocked: {
    id: 'Beboc.bookingsLocked',
    defaultMessage: 'European bookings locked',
  },
  booking: {
    id: 'Beboc.booking',
    defaultMessage: 'Booking',
  },
  appliesTo: {
    id: 'Beboc.appliesTo',
    defaultMessage: 'Applies to all European bookings in order {orderReference}',
  },
  agent: {
    id: 'Beboc.agent',
    defaultMessage: 'agent',
  },
  body: {
    id: 'Beboc.body',
    defaultMessage:
      'All bookings for European carriers have been locked. The information here is out of date - please check {link}',
  },
  capitaine: {
    id: 'Beboc.capitaine',
    defaultMessage: 'BEBOC /Capitaine',
  },
});
