import { is } from 'ramda';

import { FetchError } from '@contactcentre-web/utils/error';
import { noticeError } from '@contactcentre-web/utils/tracker';
import { actions } from '@contactcentre-web/header/module';
import { LOAD_TRANSACTION_SUMMARY_FAIL } from '@contactcentre-web/customer-order/module';

export const GENERAL_ERROR_MSG = 'Sorry, something went wrong: Please try again later';

const isDevelopment = process.env.NODE_ENV === 'development';
/*
 * @todo:
 * Replace it once app goes into production with
 * a fully-fledged logger utility. This one serves
 * purely for debugging purposes in development env.
 */
const log = (err) => {
  if (isDevelopment) {
    /* eslint-disable no-console */
    console.log('');
    console.log(
      '%c Error detected in middleware:',
      'background:red; color:white; font-weight:bold'
    );
    console.error(err);
    console.log('');
    /* eslint-enable no-console */
  }
};

export const provideErrorMessage = (error) =>
  error.name === 'Error' ? error.message : GENERAL_ERROR_MSG;

/**
 * Note: This function could be significantly simplified
 * and improved once "redux-actions" type API started
 * being used.
 *
 * @see {@link https://github.com/acdlite/redux-actions}
 * for further information.
 */
const errorHandler = () => (next) => (action) => {
  // HACK: There's some weird ... s(tuff) ... in OrderNotes related to redux-form management that causes `undefined` actions to be dispatched
  // Attempted to fix the form but I've only made it worse, something for us to understand and fix later.
  if (!action) {
    return undefined;
  }

  let message = '';
  try {
    const error = action.payload instanceof Error ? action.payload : action.error;
    if (error) {
      log(error);
      if (is(Object, error) && !(error instanceof FetchError)) {
        noticeError(error, {
          at: 'middleware',
        });
      }
      if (is(Object, error) && error instanceof Error) {
        message = provideErrorMessage(error);

        if (action.type === LOAD_TRANSACTION_SUMMARY_FAIL) {
          message = 'transactionSummaryLoadError';
          next(actions.showErrorMessage(message, error.conversationId));
        } else {
          next(actions.showErrorMessage(message, error.conversationId));
        }
      }
      if (is(String, error)) {
        if (error) {
          noticeError(error, {
            at: 'middleware',
          });
        }
        message = error || GENERAL_ERROR_MSG;
        next(actions.showErrorMessage(message, error.conversationId));
      }
    }

    return next(action);
  } catch (err) {
    log(err);
    return next(actions.showErrorMessage(provideErrorMessage(err)));
  }
};

export default errorHandler;
