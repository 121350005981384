import React from 'react';
import { FormattedMessage } from 'react-intl';

import Select from '@contactcentre-web/common/Select';
import { ApplicationUsed } from '@contactcentre-web/hooks/api/useApplicationUsed';

import messages from './messages';

interface ApplicationUsedProps {
  applicationTypes: ApplicationUsed[];
}

const CrmApplicationUsed = ({ applicationTypes }: ApplicationUsedProps) => (
  <Select
    name="applicationUsedId"
    placeholder={<FormattedMessage {...messages.selectAnApplication} />}
    options={applicationTypes}
    selectDirection="top"
  />
);

export default CrmApplicationUsed;
