import { StyleSheet } from 'aphrodite/no-important';
import { fonts, typography } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  label: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    fontWeight: fonts.dp_font_weight_bold,
    textTransform: 'uppercase',
    color: colors.gray.alpha30,
  },
});
