import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';

import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency/FormattedCurrency';
import priceShape from '@contactcentre-web/utils/shape/price';

import ExpandableTableRow from '../ExpandableTableRow';
import PriceRow from '../PriceRow';

import ProductBreakdown from './ProductBreakdown';
import ProductDetails from './ProductDetails';
import ProductCoJHeader from './ProductCoJHeader';
import styles from './styles';
import messages from './messages';

const supportedReductionCategories = ['voucher', 'promoDiscount'];

const ProductRow = ({
  details,
  type,
  id,
  totalPrice,
  inventoryPrice,
  breakdown = [],
  reductions = [],
  productLinks = [],
  isFlexiSeason,
  discount,
}) => (
  <ExpandableTableRow
    key={id}
    thead={
      productLinks
        .filter(
          ({ type: productLinkType }) =>
            productLinkType === 'replaces' || productLinkType === 'replaced-by'
        )
        .map((productLink) => <ProductCoJHeader productLink={productLink} />)[0]
    }
    tbody={
      <>
        <ProductDetails
          type={type}
          details={details}
          isFlexiSeason={isFlexiSeason}
          discount={discount}
        />
        <td className={css(styles.productPriceCell)}>
          {inventoryPrice && <FormattedCurrency {...inventoryPrice} />}
        </td>
        {totalPrice && Object.keys(totalPrice).length ? (
          <td className={css(styles.pricePaidCell)}>
            <FormattedCurrency {...totalPrice} />
          </td>
        ) : null}
      </>
    }
    type={type}
  >
    {breakdown.map((item, index) => (
      <ProductBreakdown
        key={index}
        {...item}
        inventoryTotalPrice={inventoryPrice}
        currencyCode={totalPrice ? totalPrice.currencyCode : ''}
      />
    ))}
    {reductions.map(
      ({ category, reduction }, index) =>
        supportedReductionCategories.includes(category) && (
          <PriceRow
            key={index}
            message={{ ...messages[category] }}
            price={reduction}
            testId={`${category}-reduction`}
          />
        )
    )}
  </ExpandableTableRow>
);

ProductRow.propTypes = {
  details: PropTypes.object,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  totalPrice: priceShape,
  inventoryPrice: priceShape,
  breakdown: PropTypes.arrayOf(PropTypes.object),
  reductions: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      reduction: priceShape,
    })
  ),
  productLinks: PropTypes.arrayOf(PropTypes.object),
  isFlexiSeason: PropTypes.bool,
  discount: PropTypes.shape({ name: PropTypes.string }),
};

export default ProductRow;
