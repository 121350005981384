import React, { FunctionComponent, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InjectedFormProps, reduxForm } from 'redux-form';

import TextField from '@contactcentre-web/common/TextField/TextField';
import Select from '@contactcentre-web/common/Select';
import Button from '@contactcentre-web/common/Button';
import { validateEmail } from '@contactcentre-web/utils/validation/validators';
import { ModalBody, ModalFooter } from '@contactcentre-web/common/Modal';

import { Language } from '../../module';
import { FORM_ID, EditCustomerProfilePayload } from '../../sagas';

import styles from './styles';
import messages from './messages';

interface Props {
  languages: Array<Language> | null;
  firstName: string;
  lastName: string;
  emailAddress: string;
  preferredCustomerLanguage: string;
  customerId: string;
  isSubmitting: boolean;
  onEdit: (values: EditCustomerProfilePayload) => void;
}

export const EditCustomerProfileForm: FunctionComponent<
  Props & InjectedFormProps<EditCustomerProfilePayload, Props>
> = ({
  handleSubmit,
  isSubmitting,
  firstName,
  lastName,
  emailAddress,
  preferredCustomerLanguage,
  languages = [],
  customerId,
  onEdit,
  initialize,
}) => {
  const { formatMessage } = useIntl();

  const languageOptions = languages?.map(({ language, countryCode }) => ({
    label: language,
    value: countryCode,
  }));

  useEffect(() => {
    initialize({
      firstName,
      lastName,
      emailAddress,
      preferredCustomerLanguage,
    });
  }, []);

  return (
    <form
      id={FORM_ID}
      onSubmit={handleSubmit((values) => onEdit({ ...values, customerId }))}
      autoComplete="off"
    >
      <ModalBody styleSheet={styles.form}>
        <TextField
          disabled={isSubmitting}
          name="firstName"
          label={formatMessage(messages.firstNameLabel)}
          type="text"
          messages={messages}
        />
        <TextField
          disabled={isSubmitting}
          name="emailAddress"
          label={formatMessage(messages.emailAddressLabel)}
          type="text"
          messages={messages}
        />
        <TextField
          disabled={isSubmitting}
          name="lastName"
          label={formatMessage(messages.lastNameLabel)}
          type="text"
          messages={messages}
        />
        <Select
          name="preferredCustomerLanguage"
          disabled={isSubmitting}
          options={languageOptions}
          label={formatMessage(messages.customerLanguageLabel)}
          messages={messages}
        />
      </ModalBody>
      <ModalFooter>
        <Button type="submit" variant="primary" size="large" loading={isSubmitting}>
          <FormattedMessage {...messages.updateDetailsButton} />
        </Button>
      </ModalFooter>
    </form>
  );
};

export const validate = ({
  firstName,
  lastName,
  emailAddress,
  preferredCustomerLanguage,
}: EditCustomerProfilePayload) => ({
  firstName: !firstName || firstName.length > 30 ? 'firstNameErrorMessage' : undefined,
  lastName: !lastName || lastName.length > 30 ? 'lastNameErrorMessage' : undefined,
  preferredCustomerLanguage: !preferredCustomerLanguage
    ? 'preferredCustomerLanguageErrorMessage'
    : undefined,
  emailAddress:
    !emailAddress || !validateEmail(emailAddress) ? 'emailAddressErrorMessage' : undefined,
});

export default reduxForm<EditCustomerProfilePayload, Props>({
  form: FORM_ID,
  validate,
})(EditCustomerProfileForm);
