import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  bookingInformationFare: {
    marginTop: spacers.dp_s24,
    marginBottom: spacers.dp_s8,
  },
  refundStatusFare: {
    marginTop: spacers.dp_s12,
    marginBottom: spacers.dp_s4,
  },
});
