import { StyleSheet } from 'aphrodite/no-important';
import { spacers, palettes } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  notesHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  backButton: {
    marginRight: spacers.dp_s24,
    alignItems: 'center',
    display: 'flex',
  },
  chevronBack: {
    color: palettes.dp_app_text_inverted,
  },
  headingDisplay: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flex: '80% 0 1',
  },
  headingInfo: {
    flex: '85% 0 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: spacers.dp_s12,
  },
});
