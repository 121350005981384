import { MutationOptions, useInfiniteQuery, useMutation } from 'react-query';

import request from '@contactcentre-web/utils/request';
import Price from '@contactcentre-web/redux-common/types/Price';

import { PaginationResponse } from './sharedTypes';

export interface TracsOnAccountPayRequest {
  platform: string;
  orderReference: string;
  reasonCodeId: string;
  managedGroupNumber: number;
  refundAmount: Price;
  corporateReference: string;
  requestingCustomer: { lastName: string };
  notes: string;
}

type ErrorShape = {
  code: string;
  detail?: string;
  meta?: { context?: Record<string, string | number>; severity?: string };
};

export type TracsOnAccountError = {
  errors?: Array<ErrorShape> | { errors: Array<ErrorShape> };
};

export const usePayTracsOnAccount = (
  mutationOptions: MutationOptions<unknown, TracsOnAccountError, TracsOnAccountPayRequest, unknown>
) =>
  useMutation(
    (data) =>
      request<unknown>('/compensations/onaccount', {
        method: 'POST',
        body: JSON.stringify(data),
      }),
    mutationOptions
  );

export enum ApprovalItemStatus {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export interface TracsOnAccountApprovalQueueItemResponse {
  id: string;
  platform: string;
  orderReference: string;
  managedGroupNumber: number;
  compensationType: string;
  corporateReference: string;
  status: ApprovalItemStatus;
  reasonCode: {
    code: string;
    description: string;
  };
  refundAmount: Price;
  requestedBy: { executedAt: Date; displayName: string };
  requestingCustomer: { lastName: string };
  notes: string;
}

interface TracsOnAccountApprovalQueueRequest {
  itemsPerPage: number;
}

const fetchItems = ({
  pageParam = 1,
  queryKey,
}: {
  pageParam?: number;
  queryKey: (string | TracsOnAccountApprovalQueueRequest)[];
}) => {
  // first element is the query key string, we can ignore/skip it
  const [, queryParams] = queryKey;
  const { itemsPerPage } = queryParams as TracsOnAccountApprovalQueueRequest;

  return request<PaginationResponse<TracsOnAccountApprovalQueueItemResponse>>(
    `compensations/onaccount?page=${pageParam}&perPage=${itemsPerPage}`
  );
};

export const useTracsOnAccountApprovalQueue = (itemsPerPage: number) =>
  useInfiniteQuery(['tracsOnAccountApprovalQueue', { itemsPerPage }], fetchItems, {
    getNextPageParam: (lastPage) => (lastPage.page || 0) + 1,
  });

export enum OnAccountAction {
  Reject = 'reject',
  Approve = 'approve',
}

export type UpdateTracsOnAccountStatusPayload = {
  id: string;
  action: OnAccountAction;
};

export const useTracsOnAccountValidator = (
  id: string,
  action: OnAccountAction,
  mutationOptions: MutationOptions<unknown, unknown, void, unknown>
) =>
  useMutation(
    () =>
      request(`/compensations/onaccount/${id}/${action}`, {
        method: 'POST',
      }),
    mutationOptions
  );
