import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { IconWarning } from '@trainline/depot-web';

import styles from './styles';
import messages from './messages';

const FareRestrictions = () => (
  <div className={css(styles.container)}>
    <strong className={css(styles.label)}>
      <IconWarning className={css(styles.iconWarning)} />
      <FormattedMessage {...messages.fareRestrictions} />
    </strong>
    <span className={css(styles.message)}>
      <FormattedMessage {...messages.passengersTogether} />
    </span>
  </div>
);

export default FareRestrictions;
