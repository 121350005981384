import { StyleSheet } from 'aphrodite/no-important';
import { palettes, borderRadius, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    border: `1px solid ${palettes.dp_app_border_base}`,
    borderRadius: borderRadius.dp_r8,
    padding: spacers.dp_s16,
    flex: 1,
  },
  image: {
    display: 'block',
    marginBottom: spacers.dp_s16,
    width: '100%',
  },
  button: {
    marginLeft: 'auto',
    display: 'block',
  },
  errorContainer: {
    marginBottom: spacers.dp_s16,
  },
});
