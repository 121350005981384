import React, { Fragment, FunctionComponent } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';

import { StatusIndicatorColored } from '@contactcentre-web/common/StatusIndicator';

import OrderHistory from '../../../OrderHistory/OrderHistory';
import type { FareRefundableGroupedByRefundStatus } from '../../helpers';
import RefundJourney from '../RefundJourney/RefundJourney';
import RefundPassengerDetails from '../RefundPassengerDetails/RefundPassengerDetails';
import { StatusMapperType } from '../../Refund';

import messages from './messages';
import styles from './styles';

interface Props {
  isDiscretionaryPolicy: boolean;
  numberOfFaresGroupedByRefundStatus: number;
  faresGroupedByRefundStatus: Record<string, FareRefundableGroupedByRefundStatus[]>;
  statusMapper: StatusMapperType;
}

const RefundFareStatus: FunctionComponent<Props> = ({
  isDiscretionaryPolicy,
  faresGroupedByRefundStatus,
  numberOfFaresGroupedByRefundStatus,
  statusMapper,
}) => (
  <div className={isDiscretionaryPolicy ? css(styles.refundStatusAndReasonCodeGrid) : undefined}>
    <OrderHistory.ItemTitle>
      <FormattedMessage {...messages.refundStatus} />
    </OrderHistory.ItemTitle>
    {isDiscretionaryPolicy && (
      <OrderHistory.ItemTitle>
        <FormattedMessage {...messages.reasonCode} />
      </OrderHistory.ItemTitle>
    )}
    {Object.values(faresGroupedByRefundStatus).map((fares, index) => (
      <Fragment key={index}>
        {numberOfFaresGroupedByRefundStatus > 1 && (
          <>
            <RefundJourney index={index} section="refundStatus" />
            {isDiscretionaryPolicy && <RefundJourney index={index} section="refundStatus" />}
          </>
        )}
        {fares.map(({ status, passengers, statusError, reasonCode }, index) => (
          <Fragment key={index}>
            <StatusIndicatorColored color={statusMapper[status]?.color}>
              {statusMapper[status] ? (
                <FormattedMessage {...statusMapper[status].message} />
              ) : (
                status
              )}
              {' ('}
              {passengers.map((passenger, index) => (
                <RefundPassengerDetails
                  key={index}
                  passenger={passenger}
                  index={index}
                  length={passengers.length}
                />
              ))}
              {')'}
              {statusError && (
                <>
                  {' - '}
                  {messages[statusError] ? (
                    <FormattedMessage {...messages[statusError]} />
                  ) : (
                    statusError
                  )}
                </>
              )}
            </StatusIndicatorColored>
            {isDiscretionaryPolicy && (
              <OrderHistory.ItemContent>{reasonCode}</OrderHistory.ItemContent>
            )}
          </Fragment>
        ))}
      </Fragment>
    ))}
  </div>
);

export default RefundFareStatus;
