import React from 'react';

import RefundApprovalsContainer from '@contactcentre-web/refund-approvals/RefundApprovals';
import PageContainer from '@contactcentre-web/common/PageContainer';

import styles from './styles';

const RefundApprovalsPage = () => (
  <PageContainer styleSheet={styles.container}>
    <RefundApprovalsContainer />
  </PageContainer>
);

export default RefundApprovalsPage;
