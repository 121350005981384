import React, { FunctionComponent } from 'react';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';

import styles from '../styles';

interface Props {
  styleSheet?: StyleDeclarationValue;
}

const SidebarContent: FunctionComponent<Props> = ({ children, styleSheet }) => (
  <div className={css([styles.sidebarContent, styleSheet])}>{children}</div>
);

export default SidebarContent;
