import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { IconPassenger } from '@trainline/depot-web';

import FormattedDateInLondonTz from '@contactcentre-web/common/FormattedDateInLondonTz/FormattedDateInLondonTz';
import Railcard from '@contactcentre-web/common/Railcard';

import TicketTypeContainer from '../TicketType/TicketType';

import OnboardServices from './OnboardServices';
import ReservedSeat from './ReservedSeat';
import messages from './messages';
import styles from './styles';

const Passenger = ({
  type,
  name,
  railCards = [],
  tickets,
  dateOfBirth,
  customerOrderTravellers,
  id,
  businessSettings,
}) => {
  const renderPassengerName = () => {
    if (name) {
      return name;
    }

    const matchedTraveller = customerOrderTravellers?.items?.find(
      (passenger) => passenger.passengerId === id
    );

    if (
      matchedTraveller?.firstName &&
      matchedTraveller?.lastName &&
      businessSettings?.persistPassengerDetails
    ) {
      return `${matchedTraveller?.firstName} ${matchedTraveller?.lastName}`;
    }

    return <FormattedMessage {...messages.passengerTypeName} values={{ value: type }} />;
  };

  return (
    <>
      <div className={css(styles.passengers)}>
        <div className={css(styles.passengerDetail)}>
          <IconPassenger className={css(styles.passengersIcon)} />
          <div className={css(styles.passenger)}>
            <div
              className={css(styles.passengerName, styles.passengerDetailItem)}
              data-test="itinerary-header-passengerName"
            >
              {renderPassengerName()}
            </div>
            {dateOfBirth && (
              <span className={css(styles.passengerDetailItem)} data-test="passengerDOB">
                <FormattedMessage
                  {...messages.dateOfBirth}
                  values={{
                    value: (
                      <FormattedDateInLondonTz
                        data-test="passenger-dob"
                        value={dateOfBirth}
                        year="numeric"
                        month="short"
                        day="numeric"
                      />
                    ),
                  }}
                />
              </span>
            )}
            {railCards?.map((card, index) => (
              <Railcard key={index} card={card} />
            ))}
          </div>
        </div>
        <div className={css(styles.ticketDetails)}>
          <div className={css(styles.row)}>
            <div
              className={css(styles.column, styles.travelTitle)}
              data-test="itinerary-header-ticketType"
            >
              <FormattedMessage {...messages.ticketType} />
            </div>
            <div
              className={css(styles.column, styles.travelTitle)}
              data-test="itinerary-header-coachSeat"
            >
              <FormattedMessage {...messages.coachSeat} />
            </div>
            <div
              className={css(styles.column, styles.travelTitle)}
              data-test="itinerary-header-comfortClass"
            >
              <FormattedMessage {...messages.ticketClass} />
            </div>
            <div
              className={css(styles.column, styles.travelTitle)}
              data-test="itinerary-header-onBoardServices"
            >
              <FormattedMessage {...messages.onBoardServices} />
            </div>
            <div
              className={css(styles.column, styles.travelTitle)}
              data-test="itinerary-header-deliveryMethod"
            >
              <FormattedMessage {...messages.deliveryMethod} />
            </div>
          </div>
          {tickets.map((ticket, index) => (
            <div key={index} className={css(styles.row)}>
              <div className={css(styles.column)} data-test="itinerary-info-ticketType">
                <TicketTypeContainer ticket={ticket} />
              </div>
              <div className={css(styles.column)} data-test="itinerary-info-coachSeat">
                <ReservedSeat seatReservation={ticket.seatReservation} />
              </div>
              <div className={css(styles.column)} data-test="itinerary-info-comfortClass">
                {ticket.class ? ticket.class : <FormattedMessage {...messages.notAvailable} />}
              </div>
              <div className={css(styles.column)} data-test="itinerary-info-onBoardServices">
                <OnboardServices onboardServices={ticket.onboardServices} />
              </div>
              <div className={css(styles.column)} data-test="itinerary-info-deliveryMethod">
                {ticket.deliveryMethod}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

Passenger.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  railCards: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  tickets: PropTypes.arrayOf(
    PropTypes.shape({
      deliveryMethod: PropTypes.string,
      class: PropTypes.string,
      onboardServices: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
  dateOfBirth: PropTypes.string,
  customerOrderTravellers: PropTypes.shape({
    tenantId: PropTypes.string,
    itineraryId: PropTypes.string,
    items: PropTypes.array,
  }),
  id: PropTypes.string,
  businessSettings: PropTypes.shape({
    persistPassengerDetails: PropTypes.bool,
  }),
};

export default Passenger;
