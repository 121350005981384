import { StyleSheet } from 'aphrodite/no-important';
import { typography, spacers, borderRadius } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  expandableTableRow: {
    width: '100%',
    padding: `${spacers.dp_s16}`,
    borderRadius: borderRadius.dp_r2,
    border: `solid 1px #e7ebeb`,
    backgroundColor: '#f8fafa',
    marginBottom: spacers.dp_s8,
    fontSize: typography.dp_small_font_size_m,
    lineHeight: typography.dp_small_line_height,
  },
  expandedRow: {
    marginLeft: spacers.dp_s16,
    marginRight: spacers.dp_s16,
    paddingTop: spacers.dp_s16,
  },
  button: {
    height: '24px',
    width: '24px',
    color: colors.info.base,
    backgroundColor: colors.white,
    border: `1px solid ${colors.blue.light}`,
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.1)',
    borderRadius: borderRadius.dp_r2,
    marginLeft: spacers.dp_s16,
    textAlign: 'center',
  },
  flippedIcon: {
    transform: 'scaleY(-1)',
  },
  tr: {
    textAlign: 'right',
  },
  padH: {
    padding: `0 ${spacers.dp_s16}`,
  },
  extraSpace: {
    paddingRight: 40,
  },
  insuranceTableRow: {
    border: 'solid 1px #dfebff',
    backgroundColor: '#f7faff',
  },
});
