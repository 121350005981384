export enum DeliveryMethodId {
  AtocETicket = 'atoceticket',
  AtocMTicket = 'atocmticket',
  AtocSTicket = 'atocdynamiceticket',
  AtocKiosk = 'atockiosk',
  AtocSpecialDelivery = 'atocukspecialdelivery',
  AtocFirstClassPost = 'atocukfirstclasspost',
  AtocDigitalRailcard = 'atocdigitalrailcard',
}

export type DeliveryMethod = {
  id: DeliveryMethodId | string;
  displayName: string;
};

export default DeliveryMethod;
