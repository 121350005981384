import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  modalContent: {
    width: '740px',
    overflow: 'unset',
  },
  errorMessage: {
    marginBottom: spacers.dp_s12,
  },
});
