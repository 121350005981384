import { MutationOptions, useMutation, useQuery } from 'react-query';
import { useRef, useState } from 'react';

import request from '@contactcentre-web/utils/request';
import Price from '@contactcentre-web/redux-common/types/Price';

import { ApiError, ApiValidationError, PaginationResponse } from './sharedTypes';
import { usePostMessage } from './usePostMessage';

interface Link {
  rel: string;
  href: string;
  method: string;
}

export interface Customer {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isImplicitlyRegistered: boolean;
}

export interface PaymentDetail {
  accountHolder: string;
  totalTransaction: Price;
  paymentMethodDetails: string;
}

export interface VirtualTerminalResponse {
  platform: string;
  friendlyOrderReference: string;
  orderReference: string;
  customer: Customer;
  currencyCode: string;
  links: Array<Link>;
  totalValue: number;
  paymentDetails?: PaymentDetail;
}

export interface ReasonCode {
  code: string;
  description: string;
}
export interface CustomerName {
  firstName: string;
  lastName: string;
}
export interface RequestingCustomer {
  firstName: string;
  lastName: string;
  email: string;
}
export interface VirtualTerminalApprovalQueueResponse {
  id: string;
  platform: string;
  customerId: string;
  customerName: CustomerName;
  status: string;
  requesterUsername: string;
  reasonCode: ReasonCode;
  requestedDate: string;
  friendlyOrderId: string;
  notes: string;
  compensationAmount: Price;
  originalAmount: Price;
}
export interface VirtualTerminalRequest {
  platform: string;
  orderReference: string;
  paymentTokenId?: string;
  reasonCodeId: string;
  notes: string;
  value: Price;
  requestingCustomer: RequestingCustomer;
}

export interface VirtualTerminalData {
  customerForm: {
    firstName?: string;
    lastName?: string;
    email?: string;
    reasonCode?: string;
    notes?: string;
  };
  proceedCompensationForm: {
    totalAmount?: number;
    currencyCode?: string;
  };
}

export interface SeachFormData {
  friendlyOrderId: string;
}

export interface ModalStateData {
  state: StateTypes;
  errorCode: ErrorTypes;
}

export enum MessageTypes {
  SUCCESS = 'onCardFormDataSaved',
}

export enum TerminalTypes {
  CREDIT = 'credit',
  DEBIT = 'debit',
}

export enum StateTypes {
  INITIAL = '',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum ErrorTypes {
  INITIAL = '',
  GENERIC = 'SomeGenericErrorCode',
  ROLLBACK_FAILED = 'PlatformOrderCompensationRejectionFailed',
}

interface SuccessMessageData {
  messageType: MessageTypes.SUCCESS;
  data: {
    token: string;
    lastFourCardDigits: string;
  };
}

type PaymentFormMessage = SuccessMessageData;
type TerminalType = TerminalTypes.CREDIT | TerminalTypes.DEBIT;

export const useVirtualTerminalSearch = (friendlyOrderId?: string) =>
  useQuery<VirtualTerminalResponse, ApiError | ApiValidationError>(
    ['virtualTerminalSearch', friendlyOrderId],
    () => request(`/compensations/platformSearch?SearchTerm=${friendlyOrderId}`),
    { enabled: !!friendlyOrderId }
  );

export const useVirtualTerminalCompensation = (
  mutationOptions: MutationOptions<unknown, ApiValidationError, VirtualTerminalRequest, unknown>,
  terminalType: TerminalType
) =>
  useMutation(
    (data) =>
      request<unknown>(`/compensations/platformOrder/${terminalType}`, {
        method: 'POST',
        body: JSON.stringify(data),
      }),
    mutationOptions
  );

export const useCardPaymentForm = (url: string) => {
  const formRef = useRef<HTMLIFrameElement>(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [token, setToken] = useState<string>();
  const parsedUrl = url && new URL(url).origin;
  usePostMessage<PaymentFormMessage, PaymentFormMessage>(
    parsedUrl,
    (message) => {
      if (message.messageType === MessageTypes.SUCCESS) {
        setIsSuccess(true);
        setToken(message.data.token);
      }
    },
    formRef.current?.contentWindow ?? undefined
  );

  return { isSuccess, token };
};

export const useVirtualTerminalApprovalQueue = () =>
  useQuery(
    'virtualTerminalApprovalQueue',
    () =>
      request<PaginationResponse<VirtualTerminalApprovalQueueResponse>>(
        `/compensations/platformOrder`
      ),
    { select: ({ items }) => items }
  );
export const useVirtualTerminalApproveCompensation = () =>
  useMutation((compensationId: string) =>
    request<unknown>(`/compensations/platformOrder/${compensationId}/approve`, {
      method: 'POST',
    })
  );

export const useVirtualTerminalRejectCompensation = () =>
  useMutation((compensationId: string) =>
    request<unknown>(`/compensations/platformOrder/${compensationId}/reject`, {
      method: 'POST',
    })
  );
