import React, { useMemo, useState } from 'react';
import { Heading } from '@trainline/depot-web';
import { css } from 'aphrodite/no-important';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  ClaimStatus,
  ClaimFilter,
  useManualEntryConsoleReport,
} from '@contactcentre-web/hooks/api/useManualEntryConsole';
import Button from '@contactcentre-web/common/Button';
import IconDownload from '@contactcentre-web/common/Icons/IconDownload';
import FilterButton from '@contactcentre-web/common/FilterButton';
import RefundReasonModal from '@contactcentre-web/refunds/common/RefundReasonModal';
import { useFetchReasonCodes } from '@contactcentre-web/hooks/api/useCompensation';

import ManualEntryConsoleGenerateReportModal from '../ManualEntryConsoleGenerateReportModal/ManualEntryConsoleGenerateReportModal';

import messages from './messages';
import styles from './styles';

interface Props {
  claimStatus: ClaimStatus;
  productTypesFilter: ClaimFilter[];
  reasonCodeFilter?: string;
  totalCount: number;
  isError: boolean;
  claimStatusChanges: (newStatus: ClaimStatus) => void;
  productTypesFilterChanges: (newProductType: ClaimFilter) => void;
  reasonCodeFilterChanges: (reasonId?: string) => void;
}

const ManualEntryConsoleHeader = ({
  claimStatus,
  reasonCodeFilter,
  productTypesFilter,
  totalCount,
  isError,
  claimStatusChanges,
  productTypesFilterChanges,
  reasonCodeFilterChanges,
}: Props) => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const [isConfirmationDonwloadModalOpened, setIsConfirmationDonwloadModalOpened] = useState(false);
  const [isReasonsModalOpened, setIsReasonsModalOpened] = useState(false);

  const {
    postGenerateReport,
    isError: isErrorMECReport,
    isLoading: isLoadingReport,
  } = useManualEntryConsoleReport();
  const { data: reasons = [] } = useFetchReasonCodes();

  const reasonCodePath = useMemo(() => {
    let reason = reasons?.find(({ id }) => id === reasonCodeFilter);
    const tempPath = [];
    while (reason) {
      tempPath.push(reason.description);
      reason = reason.parent;
    }
    tempPath.reverse();

    return tempPath.join(' / ') || null;
  }, [reasonCodeFilter, reasons]);

  const onReasonSelect = (reasonCodeId?: string) => {
    setIsReasonsModalOpened(false);
    reasonCodeFilterChanges(reasonCodeId);
  };

  return (
    <>
      {isConfirmationDonwloadModalOpened && (
        <ManualEntryConsoleGenerateReportModal
          onClose={() => setIsConfirmationDonwloadModalOpened(false)}
          onConfimation={() => postGenerateReport()}
        />
      )}
      <div className={css(styles.container)} data-testid="manualEntryConsoleHeader">
        <Heading typeStyle="title1" as="h1" color="base">
          <FormattedMessage {...messages.title} />
        </Heading>
        <div className={css(styles.tabContainer)}>
          <div>
            {[ClaimStatus.Pending, ClaimStatus.Approved].map((status) => {
              const messageKey = `${status}ClaimsButton` as keyof typeof messages;

              return (
                <Button
                  key={status}
                  testId={`${status}ClaimsButton`}
                  variant="tertiary"
                  size="small"
                  onClick={() => claimStatusChanges(status)}
                  styleSheet={[styles.defaultTab, claimStatus === status && styles.activeTab]}
                >
                  <FormattedMessage {...messages[messageKey]} />
                </Button>
              );
            })}
          </div>
          {claimStatus === ClaimStatus.Pending && (
            <Button
              testId="createNewClaim"
              variant="tertiary"
              size="small"
              onClick={() => history.push('/mec-claim-add')}
            >
              <FormattedMessage {...messages.createNewClaim} />
            </Button>
          )}
          {totalCount !== 0 && claimStatus === ClaimStatus.Approved && (
            <div className={css(styles.download)}>
              <Button
                testId="downloadMECSheet"
                variant="tertiary"
                size="small"
                onClick={() => setIsConfirmationDonwloadModalOpened(true)}
                styleSheet={[styles.downloadButton]}
                loading={isLoadingReport}
                showLoadingIndicator
              >
                <FormattedMessage {...messages.downloadMECSheet} />
                {!isLoadingReport && <IconDownload />}
              </Button>
              {isErrorMECReport && (
                <div className={css(styles.donwloadError)}>
                  <FormattedMessage {...messages.unableToDownload} />
                </div>
              )}
            </div>
          )}
        </div>
        {!isError && (
          <div className={css(styles.totalContainer)}>
            <div className={css(styles.totalContainer)}>
              <FormattedMessage
                {...(claimStatus === ClaimStatus.Pending
                  ? messages.claimsQueue
                  : messages.approvedClaims)}
              />
              <span className={css(styles.totalNumber)}>{totalCount}</span>
            </div>
            {claimStatus !== ClaimStatus.Approved && (
              <div className={css(styles.totalContainer)} data-testid="filterContainer">
                {reasons.length > 0 && (
                  <>
                    <FilterButton
                      onClick={() => setIsReasonsModalOpened(true)}
                      active={!!reasonCodePath}
                      filterButtonType="modal"
                      onRemoveFilter={() => onReasonSelect()}
                      testId="reasonCodeFilter"
                    >
                      <div className={css(styles.reasonFilter)}>
                        {reasonCodePath ?? (
                          <FormattedMessage {...messages.reasonCodeFilterButton} />
                        )}
                      </div>
                    </FilterButton>
                    {isReasonsModalOpened && (
                      <RefundReasonModal
                        reasons={reasons}
                        value={reasonCodeFilter}
                        modalTitle={formatMessage(messages.filterReasonModalTitle)}
                        onClosed={() => setIsReasonsModalOpened(false)}
                        onConfirmed={onReasonSelect}
                      />
                    )}
                  </>
                )}
                {Object.values(ClaimFilter).map((filter) => {
                  const messageKey = `${filter}FilterButton` as keyof typeof messages;

                  return (
                    <FilterButton
                      key={filter}
                      testId={filter}
                      onClick={() => productTypesFilterChanges(filter)}
                      active={productTypesFilter.some((p) => p === filter)}
                    >
                      <FormattedMessage {...messages[messageKey]} />
                    </FilterButton>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ManualEntryConsoleHeader;
