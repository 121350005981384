import React, { Dispatch, FunctionComponent } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Heading } from '@trainline/depot-web';

import type State from '@contactcentre-web/redux-common/types/State';
import type Action from '@contactcentre-web/redux-common/types/Action';

import { actions as userActions, NextAction } from '../../redux/module';
import { actions as managedGroupActions } from '../../redux/managedGroup/module';
import selectors from '../../redux/managedGroup/selectors';
import * as userSelectors from '../../redux/selectors';

import SelectManagedGroupForm from './SelectManagedGroupForm';
import styles from './styles';
import messages from './messages';

interface StateProps {
  isLoggedIn: boolean;
  hasSubmitSucceeded: boolean;
  nextAction?: NextAction;
}

interface DispatchProps {
  resetState: () => void;
  authFlowForwarding: () => void;
}

type Props = StateProps & DispatchProps;

export const SelectManagedGroup: FunctionComponent<Props> = ({
  hasSubmitSucceeded,
  resetState,
  authFlowForwarding,
  nextAction,
  isLoggedIn,
}) => {
  const history = useHistory();
  const resetStateRef = React.useRef(resetState);
  const authFlowForwardingRef = React.useRef(authFlowForwarding);
  const replaceRef = React.useRef(history.replace);
  const redirectParam = new URLSearchParams(window.location.search).get('redirect');

  React.useEffect(() => {
    if (hasSubmitSucceeded && isLoggedIn) {
      authFlowForwardingRef.current();
      const defaultRedirect = (nextAction && nextAction.action) || '/';
      replaceRef.current(redirectParam ? redirectParam : defaultRedirect);
      resetStateRef.current();
    }
  }, [
    hasSubmitSucceeded,
    nextAction,
    isLoggedIn,
    resetStateRef,
    authFlowForwardingRef,
    replaceRef,
  ]);

  return (
    <section>
      <div className={css(styles.headerBar)} />
      <div className={css(styles.container)}>
        <Heading typeStyle="title1" as="h1" color="base">
          <FormattedMessage {...messages.title} />
        </Heading>
        <SelectManagedGroupForm />
      </div>
    </section>
  );
};

const mapStateToProps = (state: State) => ({
  isLoggedIn: userSelectors.selectLoggedIn(state),
  hasSubmitSucceeded: selectors.hasSubmitSucceeded(state),
  nextAction: userSelectors.nextAction(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  resetState: () => dispatch(managedGroupActions.resetState()),
  authFlowForwarding: () => dispatch(userActions.authFlowForwarding()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectManagedGroup);
