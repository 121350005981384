import { StyleSheet } from 'aphrodite/no-important';
import { spacers, typography, palettes } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  wrapper: {
    marginTop: spacers.dp_s64,
  },
  title: {
    fontSize: typography.dp_title2_font_size,
    color: palettes.dp_app_text_base,
  },
  userTitle: {
    marginTop: spacers.dp_s12,
  },
  user: {
    fontSize: typography.dp_title3_font_size,
    color: palettes.dp_app_text_base,
  },
  note: {
    marginTop: spacers.dp_s12,
    fontSize: typography.dp_small_font_size,
    color: palettes.dp_app_text_warning,
  },
});
