import { createSelector } from 'reselect';
import { compose, flatten, filter, find, path, pluck, any, prop } from 'ramda';

import * as orderSelectors from '@contactcentre-web/redux-common/selectors/order';
import * as userSelectors from '@contactcentre-web/authentication/redux/selectors';
import reduceToObj from '@contactcentre-web/utils/reduceToObj';
import {
  getProducts,
  getRefundableProducts,
  selectLocalAreaProducts,
} from '@contactcentre-web/redux-common/selectors/products';
import ProductType from '@contactcentre-web/redux-common/types/ProductType';

import { selectors as refundsSelectors, getRefundableEntanglements } from '../common/module';
import {
  addFriendlyTicketIndexes,
  applySplitSaveFixToRefundJourneys,
  getFriendlyTicketIdMap,
  getRefundableLocalAreaProduct,
  getRefundableProduct,
  getRefundReason,
  isFraudNonProcessable,
  isFraudNonProcessableQuoteOverride,
  isPartialRefundEntangled,
  isQuoteProcessable,
} from '../utils';
import { normaliseProduct } from '../normalise-product';

import {
  journeysWithRefundability,
  railcardsWithRefundability,
  localAreasWithRefundibility,
} from './utils';
import { loadRefundsState, REFUND_REASONS } from './module';

const localState = (state) => state.refundsTermsAndConditions;

const inProgress = createSelector(
  localState,
  (state) => state.loadRefundsState === loadRefundsState.LOAD_REFUNDS_INPROGRESS
);

const hasSucceeded = createSelector(
  localState,
  (state) => state.loadRefundsState === loadRefundsState.LOAD_REFUNDS_SUCCESS
);

const hasFailed = createSelector(
  localState,
  (state) => state.loadRefundsState === loadRefundsState.LOAD_REFUNDS_ERROR
);

const hasLoadedSuccessfully = createSelector(
  localState,
  (state) => state.loadRefundsState === loadRefundsState.LOAD_REFUNDS_SUCCESS
);

const getOrder = (state) => orderSelectors.getOrder(state);

const canRequestStandardRefund = (state) => userSelectors.canRequestStandardRefund(state);

const hasRefundPermissions = createSelector(canRequestStandardRefund, (permission) => permission);

const getQuotes = createSelector(localState, (state) => state.quotes);

const getQuotesValidationErrors = createSelector(
  localState,
  (state) => state.quotesValidationErrors
);

const canVoid = createSelector(getOrder, (order) => !!path(['voidable', 'isVoidable'], order));

const getRefundables = createSelector(localState, (state) => state.refundables);

const getRefunds = createSelector(localState, (state) => state.refunds);

const getAdminFee = (bookingId) =>
  createSelector(getRefundables, (refundables) =>
    compose(
      prop('selectedByDefault'),
      prop('selectability'),
      find((chargedFee) => chargedFee.type === 'admin-fee'),
      prop('chargedFees'),
      find((refund) => refund.productId === bookingId),
      prop('refundables')
    )(refundables)
  );

const getFeeRefundable = (fees, feeType, quote) => {
  const feeRefundable = fees.find(({ type }) => type.toLowerCase() === feeType.toLowerCase());

  if (!feeRefundable) {
    return undefined;
  }

  const { refundableId, selectability, status } = feeRefundable;
  const quoteRefundable = quote && quote.refundables.find(({ id }) => id === refundableId);

  return {
    id: refundableId,
    description: refundableId, // TODO: This will cause a lot of pain in the future, but we have to live with it for now
    isSelected: isQuoteProcessable(quote) && !!quoteRefundable,
    isChangeable: isQuoteProcessable(quote) && !!selectability && selectability.selectable,
    refundable: (status || '').toLowerCase() === 'refundable',
    price: quoteRefundable && quoteRefundable.refundableAmount,
    isEligible: !!selectability && selectability.selectedByDefault,
  };
};

const refundForBooking = (refunds, bookingId) =>
  compose(
    find(({ productId }) => productId === bookingId),
    filter((item) => !!item),
    flatten(),
    pluck('refundableGroups')
  )(refunds);

const includesTgvMaxjourney = (journeys) =>
  compose(
    any((c) => c.name.toLowerCase() === 'Abonnement TGVmax'.toLowerCase()),
    flatten(),
    pluck('railCards'),
    flatten(),
    pluck('farePassengers')
  )(journeys);

const getPassengerRefundablesWithEntanglements = (refundable, orderEntanglements) => {
  const entanglements = getRefundableEntanglements(refundable, orderEntanglements);

  return refundable.passengerRefundables.map((passengerRefundable) => {
    const passengerRefundableEntanglements = entanglements.find(
      ({ id }) => id === passengerRefundable.entanglementId
    );

    const allowedEntanglementCombinations =
      passengerRefundableEntanglements?.allowedCombinations.filter((combinationSet) =>
        combinationSet.includes(passengerRefundable.refundableId)
      );

    return {
      ...passengerRefundable,
      allowedEntanglementCombinations,
    };
  });
};

const travelBookingToRefundBooking = (
  {
    type,
    id,
    journeys,
    insurance,
    fulfilmentStatus,
    isReturn,
    afterSaleSystem,
    vendor,
    localAreaValidity,
    hasMultiplePassengers,
    deliveryMethod,
    railcardDetails,
  },
  refundables,
  refunds,
  quote,
  requestRefund,
  validationErrors,
  order,
  managedGroupNumber,
  orderEntanglements,
  localAreaProducts
) => {
  const productRefundable = getRefundableProduct(id, refundables);
  const passengerRefundablesWithEntanglements = getPassengerRefundablesWithEntanglements(
    productRefundable,
    orderEntanglements
  );
  const { journeys: refundJourneys, canProcess: canProcessJourneys } = journeysWithRefundability(
    journeys,
    passengerRefundablesWithEntanglements,
    quote,
    isReturn
  );

  const localAreaRefundable = getRefundableLocalAreaProduct(localAreaProducts, refundables);
  const localAreaPassengerRefundablesWithEntanglements = getPassengerRefundablesWithEntanglements(
    localAreaRefundable,
    orderEntanglements
  );
  const { journeys: refundLocalAreaJourneys, canProcess: canProcessLocalAreaJourneys } =
    localAreasWithRefundibility(
      localAreaProducts,
      localAreaPassengerRefundablesWithEntanglements,
      quote
    );

  const {
    chargedFees: productChargedFees,
    fees: productFees,
    railcardRefundables: productRailcardRefundables,
  } = productRefundable;
  const {
    chargedFees: localAreaChargedFees,
    fees: localAreaFees,
    railcardRefundables: localAreaRailcardRefundables,
  } = localAreaRefundable;

  const fees = [...productFees, ...localAreaFees];
  const chargedFees = [...productChargedFees, ...localAreaChargedFees];
  const allRailcardRefundables = [...productRailcardRefundables, ...localAreaRailcardRefundables];

  const { railcardRefundability, canProcess: canProcessRailcard } = railcardsWithRefundability(
    id,
    allRailcardRefundables,
    quote
  );

  const feeRefundables = {
    bookingFee: getFeeRefundable(fees, 'booking-fee', quote),
    paymentFee: getFeeRefundable(fees, 'payment-fee', quote),
    deliveryFee: getFeeRefundable(fees, 'delivery-fee', quote),
  };
  const refund = refundForBooking(refunds, id);
  const { adminFee } = reduceToObj(chargedFees, 'type');

  const partialRefundIsEntangled = isPartialRefundEntangled([
    ...refundJourneys,
    ...refundLocalAreaJourneys,
  ]);

  const fixedRefundJourneys = applySplitSaveFixToRefundJourneys(
    refundJourneys,
    passengerRefundablesWithEntanglements
  );

  const friendlyTicketIndexMapping = getFriendlyTicketIdMap([
    ...fixedRefundJourneys,
    ...refundLocalAreaJourneys,
  ]);

  const refundTravelJourneysWithTicketIndexes = addFriendlyTicketIndexes(
    fixedRefundJourneys,
    friendlyTicketIndexMapping
  );

  const refundLocalAreaJourneysWithTicketIndexes = addFriendlyTicketIndexes(
    refundLocalAreaJourneys,
    friendlyTicketIndexMapping
  );

  const refundAlertMessage = (() => {
    if (!quote) {
      return null;
    }

    switch (quote.ticketReturnMode) {
      case 'deletefromdevice':
        return 'deleteFromDevicePreProcess';
      case 'notfetched':
        return 'paperTicketPost';
      case 'post':
      case 'collectpost': {
        const isATOC = vendor?.toLowerCase() === 'atoc';
        const isMG20 = managedGroupNumber === 20;

        return isATOC && isMG20 ? 'customerAdvisory' : null;
      }
      default:
        return null;
    }
  })();

  const quoteConditionHasEntanglement = (condition) =>
    condition.reason?.code?.startsWith('entanglementWarning');

  const quoteHasEntanglement = !!quote?.quoteConditions?.find((condition) =>
    quoteConditionHasEntanglement(condition)
  );

  const uniqueUnprocessableReasons = [
    ...(quote?.unprocessableReasons ?? []),
    ...(requestRefund?.unprocessableReasons ?? []),
    ...(validationErrors ?? []),
  ]
    .filter(Boolean)
    .reduce(
      (reasonArr, unprocessableReason) =>
        reasonArr.some((r) => r.reasonCode === unprocessableReason?.reasonCode)
          ? reasonArr
          : [...reasonArr, unprocessableReason],
      []
    );

  const isOverridable = isFraudNonProcessable(quote, requestRefund);
  const isOverriden = isFraudNonProcessableQuoteOverride(quote);

  const reasonCode = getRefundReason(REFUND_REASONS, order, quote);

  const canProcess =
    (!!canProcessJourneys && !!canProcessLocalAreaJourneys) || !!canProcessRailcard;

  return {
    railcard: railcardRefundability &&
      railcardDetails && { ...railcardRefundability, ...railcardDetails },
    type,
    id,
    journeys: refundTravelJourneysWithTicketIndexes,
    localAreaJourneys: refundLocalAreaJourneysWithTicketIndexes,
    friendlyTicketIndexMapping,
    refundable: canProcess,
    insurance,
    fulfilmentStatus,
    fees: feeRefundables,
    quoteReference: quote && quote.quoteId,
    quoteUuid: quote && quote.quoteUuid,
    quoteHasEntanglement,
    quoteConditions: quote?.quoteConditions || [],
    reasonCode,
    state: {
      canRequest: false,
      canProcess: canProcess && (!isOverridable || isOverriden),
    },
    refund: refund && {
      ticketReturnMode: refund.ticketReturnMode && {
        name: refund.ticketReturnMode.name,
        returnAddress: refund.ticketReturnMode.address,
      },
    },
    adminFee: quote &&
      quote.adminFee && {
        id: 'adminFee',
        description: 'adminFee',
        price: quote.adminFee.value,
        isSelected: isQuoteProcessable(quote) && !!quote.adminFee.deducted,
        isChangeable: adminFee && adminFee.selectability && adminFee.selectability.selectable,
      },
    productTotal: quote && quote.totalRefundableAmount,
    discounts: quote?.discounts,
    refundAlertMessage,
    total: quote && quote.totalToRefund,
    mangled:
      !!refund && (refund.refundStatus === 'processing' || refund.refundStatus === 'requested'),
    partialRefundIsEntangled,
    includesTgvMaxjourney:
      type === ProductType.TravelProduct ? includesTgvMaxjourney(fixedRefundJourneys) : false,
    afterSaleSystem,
    unprocessableReasons: uniqueUnprocessableReasons,
    isOverridable,
    isOverriden,
    customisations: quote?.customisations && {
      ...quote.customisations,
      lastUsedDate: quote.customisations.lastUsedDate && {
        ...quote.customisations.lastUsedDate,
        value: new Date(quote.customisations.lastUsedDate?.value),
      },
    },
    localAreaValidity,
    hasMultiplePassengers,
    deliveryMethod,
  };
};

const getBookings = createSelector(
  getRefundableProducts,
  getRefundables,
  getRefunds,
  getQuotes,
  refundsSelectors.getRequestsState,
  getQuotesValidationErrors,
  getOrder,
  userSelectors.getCurrentManagedGroupNumber,
  selectLocalAreaProducts,
  (
    products,
    { refundables, orderEntanglements } = {},
    refunds,
    quotes,
    requestRefund,
    quotesValidationErrors,
    order,
    managedGroupNumber,
    localAreaProducts
  ) => {
    if (!products || !refundables) {
      return [];
    }

    const productCtrs = products.map((product) => product.ctr);
    const orphanLocalAreaProducts = localAreaProducts.filter(
      ({ ctr }) => !productCtrs.includes(ctr)
    );

    return [...products, ...orphanLocalAreaProducts]
      .map((p) => {
        const product = normaliseProduct(p);

        const filteredLocalAreaProducts = localAreaProducts.filter(
          ({ ctr }) => product.ctr && product.ctr === ctr
        );

        const productWithRefundability = travelBookingToRefundBooking(
          product,
          refundables,
          refunds,
          quotes && quotes[product.id],
          requestRefund && requestRefund[product.id],
          quotesValidationErrors &&
            quotesValidationErrors[product.id] &&
            quotesValidationErrors[product.id].unprocessableReasons,
          order,
          managedGroupNumber,
          orderEntanglements,
          filteredLocalAreaProducts
        );

        // After the hack done in filteredJourneys, this will fix the isReturn in case of
        // return split saves in the journeys
        product.isReturn = product.isReturn && productWithRefundability.journeys.length === 1;

        return productWithRefundability;
      })
      .filter((value) => Object.keys(value).length);
  }
);

const refundReasons = createSelector(localState, (state) => state.refundReasons);

const getOrderId = createSelector(getOrder, (order) => (order && order.id) || '');

export const getSeasonRefundableReasons = createSelector(
  getProducts,
  getRefundables,
  (products = [], { refundables } = {}) => {
    if (!products || !refundables || !refundables.length) {
      return [];
    }

    const seasonProducts = products.filter(({ type }) => type === ProductType.Season);

    if (!seasonProducts.length) {
      return [];
    }

    return seasonProducts.map(
      ({
        id,
        journey: {
          passenger: { farePassengerId },
        },
      }) => {
        const refundable = refundables.find(({ productId }) => productId === id);
        const passengerRefundable = refundable?.passengerRefundables?.find(
          ({ id: passengerRefundableId }) => passengerRefundableId === farePassengerId
        );

        return passengerRefundable?.reason;
      }
    );
  }
);

const isSeasonTicketDeliveryMethodPostOrToD = createSelector(
  getSeasonRefundableReasons,
  (refundableReasons) =>
    refundableReasons?.includes('refundsProtocol.notVoidableUnderGivenDeliveryType')
);

const isSeasonTicketEligibleForManualRefund = createSelector(
  getSeasonRefundableReasons,
  (refundableReasons) =>
    refundableReasons?.includes('refundsProtocol.notEligibleForAutomatedRefund')
);

const isRefundingSeasonOrder = createSelector(localState, (state) => state.seasonRefund.loading);
const refundSeasonOrderStatus = createSelector(localState, (state) => state.seasonRefund.status);

const selectors = {
  localState,
  inProgress,
  hasSucceeded,
  hasFailed,
  hasLoadedSuccessfully,
  getBookings,
  refundReasons,
  canVoid,
  getOrderId,
  getAdminFee,
  getSeasonRefundableReasons,
  isSeasonTicketDeliveryMethodPostOrToD,
  isSeasonTicketEligibleForManualRefund,
  isRefundingSeasonOrder,
  refundSeasonOrderStatus,
  hasRefundPermissions,
  getQuotes,
};

export default selectors;
