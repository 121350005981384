import moment from 'moment';

const humaniseDuration = (duration: number | string) => {
  const momentDuration = moment.duration(duration);
  const hrs = momentDuration.hours();
  const min = momentDuration.minutes();
  const ret = [];
  switch (hrs) {
    case 0:
      break;
    case 1:
      ret.push(`${hrs}hr`);
      break;
    default:
      ret.push(`${hrs}hrs`);
      break;
  }
  if (min > 0) ret.push(`${min}min`);
  return ret.join(' ');
};

export default humaniseDuration;
