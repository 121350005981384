import { useState } from 'react';

export enum SortDirection {
  ASC = 'Asc',
  DESC = 'Desc',
}

export type SortDirectionType = 'Asc' | 'Desc';

const SORT_DIRECTION_KEY = 'sortDirection';

const storeSortDirectionStorage = (sortDirection: SortDirectionType, page: string) => {
  const currentSearch = new URLSearchParams(location.search);
  currentSearch.set(SORT_DIRECTION_KEY, sortDirection);

  history.pushState(null, '', `?${currentSearch}`);

  localStorage.setItem(`${SORT_DIRECTION_KEY}_${page}`, sortDirection);
};

const loadSortDirectionStorage = (page: string): SortDirectionType =>
  (localStorage.getItem(`${SORT_DIRECTION_KEY}_${page}`) as SortDirectionType) ?? SortDirection.ASC;

const checkSortDirection = (order: SortDirectionType): SortDirectionType =>
  order === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;

const useToggleSortDirection = (page: string) => {
  const initialState = loadSortDirectionStorage(page);
  const [sortDirection, setSortDirection] = useState(initialState);

  const toggleSortDirection = () => setSortDirection((prevOrder) => checkSortDirection(prevOrder));
  const newSortDirection = checkSortDirection(sortDirection);

  return {
    newSortDirection,
    sortDirection,
    toggleSortDirection,
    loadSortDirectionStorage,
    storeSortDirectionStorage,
  };
};

export default useToggleSortDirection;
