import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form';
import { put, call, select, takeLatest } from 'redux-saga/effects';

import request from '@contactcentre-web/utils/request';
import { actions as orderActions } from '@contactcentre-web/redux-common/actions/order';
import { getBookings } from '@contactcentre-web/redux-common/selectors/order';
import { Order } from '@contactcentre-web/redux-common/types/Order';
import { TravelProduct } from '@contactcentre-web/redux-common/types/TravelProduct';
import type State from '@contactcentre-web/redux-common/types/State';

export interface RefreshRequestData {
  bookingId: string;
}
export interface ValidationErrors {
  order: Order;
}
export interface RefreshableBookingsData {
  bookingIndex: number;
  from: string;
  to: string;
  departureDay: Date;
  id: string;
}
export interface RefreshRequestResponse {
  refreshed: boolean;
}
export interface RefreshPnrState {
  success: any;
  refreshed: any;
  confirmationVisible: any;
  loading: boolean;
  failedMulti: any;
  failedAlreadyRefreshed: any;
  hidden: boolean;
}

// Constants
export const FORM_ID = 'RefreshPnr';

const PREFIX = 'REFRESH_PNR';
export const REFRESH_PNR_SHOW = `SHOW`;
export const REFRESH_PNR_ASK_CONFIRMATION = `ASK_CONFIRMATION`;
export const REFRESH_PNR_CANCEL = `CANCEL`;
export const REFRESH_PNR_CONFIRM = `CONFIRM`;
export const REFRESH_PNR_SUCCESS = `SUCCESS`;
export const REFRESH_PNR_FAILED = `FAILED`;
export const REFRESH_PNR_FAILED_MULTI = `FAILED_MULTI`;
export const REFRESH_PNR_FAILED_ALREADY_REFRESHED = `FAILED_ALREADY_REFRESHED`;

const nullPayload = () => null;

export const actions = createActions(
  {
    [REFRESH_PNR_SHOW]: nullPayload,
    [REFRESH_PNR_ASK_CONFIRMATION]: nullPayload,
    [REFRESH_PNR_CANCEL]: nullPayload,
    [REFRESH_PNR_CONFIRM]: nullPayload,
    [REFRESH_PNR_SUCCESS]: (data) => data,
    [REFRESH_PNR_FAILED]: nullPayload,
    [REFRESH_PNR_FAILED_MULTI]: nullPayload,
    [REFRESH_PNR_FAILED_ALREADY_REFRESHED]: nullPayload,
  },
  { prefix: PREFIX }
);

export const initialState = {
  success: false,
  refreshed: false,
  confirmationVisible: false,
  loading: false,
  failedMulti: false,
  failedAlreadyRefreshed: false,
  hidden: false,
};
type ActionPayload = RefreshRequestResponse;
const reducer = handleActions<RefreshPnrState, ActionPayload>(
  {
    [REFRESH_PNR_SHOW]: () => ({
      ...initialState,
      hidden: false,
    }),
    [REFRESH_PNR_ASK_CONFIRMATION]: (state) => ({
      ...state,
      confirmationVisible: true,
    }),
    [REFRESH_PNR_CANCEL]: (state) =>
      state.loading
        ? state
        : {
            ...state,
            confirmationVisible: false,
          },
    [REFRESH_PNR_CONFIRM]: (state) => ({
      ...state,
      loading: true,
    }),
    [REFRESH_PNR_SUCCESS]: (state, { payload: { refreshed } }) => ({
      ...state,
      hidden: false,
      confirmationVisible: false,
      loading: false,
      success: true,
      refreshed,
    }),
    [REFRESH_PNR_FAILED]: () => ({
      ...initialState,
    }),
    [REFRESH_PNR_FAILED_MULTI]: (state) => ({
      ...state,
      loading: false,
      success: false,
      confirmationVisible: false,
      failedMulti: true,
    }),
    [REFRESH_PNR_FAILED_ALREADY_REFRESHED]: (state) => ({
      ...state,
      loading: false,
      success: false,
      confirmationVisible: false,
      failedAlreadyRefreshed: true,
    }),
  },
  initialState,
  { prefix: PREFIX }
);

export default reducer;
const getOrder = (state: State) => state.orders.order;
const isSuccess = (state: RefreshPnrState) => state.success;
const isRefreshed = (state: RefreshPnrState) => state.refreshed;
const isConfirmationOpen = (state: RefreshPnrState) => state.confirmationVisible;
const isLoading = (state: RefreshPnrState) => state.loading;
const isFailedMulti = (state: RefreshPnrState) => state.failedMulti;
const isFailedAlreadyRefreshed = (state: RefreshPnrState) => state.failedAlreadyRefreshed;

const getRefreshableBookings = createSelector(
  getBookings,
  (bookings: Array<TravelProduct>): Array<RefreshableBookingsData> =>
    bookings
      .filter((booking) => booking.canBeRefreshed)
      .map(({ id, origin, destination, outDate }, index) => ({
        bookingIndex: index + 1,
        from: origin,
        to: destination,
        departureDay: outDate,
        id,
      }))
);

const isAnyBookingRefreshable = createSelector(
  getRefreshableBookings,
  (bookings) => bookings.length > 0
);

const getCapitaineUrl = createSelector(getOrder, (order) =>
  order ? (order.capitaineUrl as string) : null
);

const getHistoryUrl = createSelector(getOrder, (order) =>
  order ? `/customers/${order.customerId}/bookings/${order.orderReference}/history` : null
);

export const selectors = {
  isSuccess,
  isRefreshed,
  getRefreshableBookings,
  isAnyBookingRefreshable,
  isConfirmationOpen,
  isLoading,
  getCapitaineUrl,
  getHistoryUrl,
  isFailedMulti,
  isFailedAlreadyRefreshed,
};

export const refreshRequest = (
  orderReference: string,
  selectedBooking: string,
  refreshableBookings: Array<RefreshableBookingsData>,
  order: { travelBookings: Array<TravelProduct> }
) => {
  const getProductHref = (id: string) => order.travelBookings.find((booking) => booking.id === id);

  return Promise.all(
    refreshableBookings
      .filter(({ id }) => selectedBooking === 'allBookings' || id === selectedBooking)
      .map(({ id }) => {
        const product = getProductHref(id);
        return request<RefreshRequestResponse>(
          `/api/orders/${orderReference}/bookings/${id}/refresh`,
          {
            method: 'POST',
            body: { productHref: product?.productHref },
          }
        );
      })
  );
};

export function* confirmSaga() {
  try {
    const state: State = yield select();
    const order = getOrder(state);
    const { orderReference, customerId } = order;
    const { bookingId } = getFormValues(FORM_ID)(state) as RefreshRequestData;
    const refreshableBookings = getRefreshableBookings(state);

    const refreshRequestResponse: Array<RefreshRequestResponse> = yield call(
      refreshRequest,
      orderReference,
      bookingId,
      refreshableBookings,
      order
    );

    const response = {
      refreshed: !!refreshRequestResponse?.some((p) => p.refreshed),
    };

    if (response.refreshed) {
      yield put((orderActions as any).load(customerId, orderReference));
    }

    yield put(actions.success(response));
  } catch (e: any) {
    const { validationErrors } = e;
    if (validationErrors) {
      switch (validationErrors[0]?.code?.toLowerCase()) {
        case 'multiplepnrs':
          yield put(actions.failedMulti());
          break;
        case 'refreshedalready':
          yield put(actions.failedAlreadyRefreshed());
          break;
        default:
          yield put(actions.failed(e));
      }
    } else {
      yield put(actions.failed(e));
    }
  }
}

export function* saga() {
  yield takeLatest(`${PREFIX}/${REFRESH_PNR_CONFIRM}`, confirmSaga);
}
