import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import styles from './styles';
import messages from './messages';

export const OrderContext = ({ orderId, customer: { title, forename, surname }, agentName }) => (
  <section className={css(styles.noteContext)}>
    <section className={css(styles.noteContext_row)}>
      <FormattedMessage {...messages.orderId}>
        {(value) => (
          <span className={css(styles.noteContext_cell, styles.notesContext_label)}>{value}</span>
        )}
      </FormattedMessage>
      <span className={css(styles.noteContext_cell)}>{orderId}</span>
    </section>
    <section className={css(styles.noteContext_row)}>
      <FormattedMessage {...messages.customerId}>
        {(value) => (
          <span className={css(styles.noteContext_cell, styles.notesContext_label)}>{value}</span>
        )}
      </FormattedMessage>
      <span className={css(styles.noteContext_cell)}>
        {title} {forename} {surname}
      </span>
    </section>
    <section className={css(styles.noteContext_row)}>
      <FormattedMessage {...messages.agentId}>
        {(value) => (
          <span className={css(styles.noteContext_cell, styles.notesContext_label)}>{value}</span>
        )}
      </FormattedMessage>
      <span className={css(styles.noteContext_cell)}>{agentName}</span>
    </section>
  </section>
);

OrderContext.propTypes = {
  orderId: PropTypes.string.isRequired,
  customer: PropTypes.shape({
    title: PropTypes.string,
    forename: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
  }).isRequired,
  agentName: PropTypes.string.isRequired,
};

export default OrderContext;
