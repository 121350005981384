import { StyleSheet } from 'aphrodite/no-important';
import { palettes, spacers, typography } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  bookingTitle: {
    color: palettes.dp_app_text_base,
    fontSize: typography.dp_title1_font_size,
    marginTop: spacers.dp_s32,
    marginBottom: spacers.dp_s16,
  },
  details: {
    color: palettes.dp_app_text_base,
    display: 'flex',
    alignItems: 'flex-start',
  },
  detailsContainer: {
    marginRight: spacers.dp_s32,
  },
  detailsInfo: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-end',
    marginLeft: 'auto',
  },
  detailsInfoIcon: {
    marginRight: spacers.dp_s4,
    fill: palettes.dp_app_link_base,
  },
  passengersContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: spacers.dp_s32,
  },
  passengersIcon: {
    marginRight: spacers.dp_s4,
  },
  railCardContainer: {
    marginRight: spacers.dp_s32,
  },
  railCardIcon: {
    marginRight: spacers.dp_s8,
  },
  dateRow: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dateLabel: {
    textAlign: 'right',
    marginRight: spacers.dp_s8,
  },
});
