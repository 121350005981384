import { defineMessages } from 'react-intl';

export default defineMessages({
  showCallingPoints: {
    id: 'JourneyCallingPoints.showCallingPoints',
    defaultMessage: 'View stops',
  },
  hideCallingPoints: {
    id: 'JourneyCallingPoints.hideCallingPoints',
    defaultMessage: 'Hide stops',
  },
});
