import React, { Dispatch, FunctionComponent } from 'react';
import { css } from 'aphrodite/no-important';
import { injectIntl, FormattedMessage, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';

import Button from '@contactcentre-web/common/Button';
import TextField from '@contactcentre-web/common/TextField';
import type Action from '@contactcentre-web/redux-common/types/Action';

import { actions } from '../../redux/module';
import { FORM_ID } from '../../redux/changePassword/module';

import messages from './messages';
import styles from './styles';

interface ChangePasswordData {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface DispatchProps {
  onPasswordSubmit: (data: ChangePasswordData) => void;
}

interface OwnProps {
  username: string;
}

export type Props = DispatchProps & OwnProps & WrappedComponentProps;

export const ChangePasswordForm: FunctionComponent<
  Props & InjectedFormProps<ChangePasswordData, Props>
> = ({ handleSubmit, submitting, pristine, error, onPasswordSubmit, intl }) => (
  <form id={FORM_ID} onSubmit={handleSubmit(onPasswordSubmit)} autoComplete="off">
    <div className={css(styles.formContainer)}>
      <TextField
        id="password-old"
        label={intl.formatMessage(messages.oldPassword)}
        name="oldPassword"
        type="password"
        styleSheet={{ container: styles.fieldGroup }}
        autoComplete="off"
        messages={messages}
      />
      <TextField
        id="password-new"
        label={intl.formatMessage(messages.newPassword)}
        name="newPassword"
        type="password"
        styleSheet={{ container: styles.fieldGroup }}
        autoComplete="off"
        messages={messages}
      />
      <TextField
        id="password-confirm"
        label={intl.formatMessage(messages.confirmPassword)}
        name="confirmPassword"
        type="password"
        styleSheet={{ container: styles.fieldGroup }}
        autoComplete="off"
        messages={messages}
      />
      <Button
        type="submit"
        variant="primary"
        size="xlarge"
        fullWidth
        testId="submit-change-password"
        disabled={pristine || submitting}
        styleSheet={styles.button}
        loading={submitting}
      >
        <FormattedMessage {...messages.submitButton} />
      </Button>
      {error && <p className={css(styles.submitError)}>{error}</p>}
    </div>
  </form>
);

const REGEX_CONTAINS_NON_ALPHANUMERIC = /^(?=.*[^a-zA-Z0-9])/;
const REGEX_CONTAINS_DIGIT = /^(?=.*[0-9])/;
const REGEX_CONTAINS_UPPERCASE = /^(?=.*[A-Z])/;
const REGEX_CONTAINS_LOWERCASE = /^(?=.*[a-z])/;

export const validate = ({ newPassword, confirmPassword, oldPassword }: ChangePasswordData) => ({
  newPassword: !newPassword ? 'newPasswordIsRequired' : undefined,
  confirmPassword: !confirmPassword ? 'confirmPasswordIsRequired' : undefined,
  oldPassword: !oldPassword ? 'oldPasswordIsRequired' : undefined,
});

export const asyncValidate = (
  { newPassword, confirmPassword }: ChangePasswordData,
  _: unknown,
  props: Props
) => {
  let errorMessage = '';
  const { formatMessage } = props.intl;

  if (newPassword !== confirmPassword) {
    errorMessage = `${formatMessage(messages.passwordsShouldBeTheSame)}\n`;
  }

  if (newPassword.length < 6) {
    errorMessage = `${errorMessage}${formatMessage(messages.passwordIsTooShort)}\n`;
  }

  if (!REGEX_CONTAINS_NON_ALPHANUMERIC.test(newPassword)) {
    errorMessage = `${errorMessage}${formatMessage(messages.noAlphaNum)}\n`;
  }

  if (!REGEX_CONTAINS_DIGIT.test(newPassword)) {
    errorMessage = `${errorMessage}${formatMessage(messages.noDigit)}\n`;
  }

  if (!REGEX_CONTAINS_UPPERCASE.test(newPassword)) {
    errorMessage = `${errorMessage}${formatMessage(messages.noUpperCase)}\n`;
  }

  if (!REGEX_CONTAINS_LOWERCASE.test(newPassword)) {
    errorMessage = `${errorMessage}${formatMessage(messages.noLowerCase)}\n`;
  }

  return new Promise((resolve, reject) => {
    if (errorMessage !== '') {
      return reject({
        _error: errorMessage,
      });
    }
    return resolve(null);
  });
};

const ChangePasswordIntl = injectIntl(
  reduxForm<ChangePasswordData, Props>({
    form: FORM_ID,
    asyncBlurFields: [],
    asyncValidate,
    validate,
  })(ChangePasswordForm)
);

const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OwnProps) => ({
  onPasswordSubmit: (formData: ChangePasswordData) =>
    dispatch(actions.passwordChangeRequested(ownProps.username, formData)),
});

export default connect(null, mapDispatchToProps)(ChangePasswordIntl);
