import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import messages from './messages';

const CountryName = ({ countryCode }) => {
  const countryCodeKey = countryCode ? countryCode.toLowerCase() : 'gb';
  const messageForCountry = messages[countryCodeKey]
    ? messages[countryCodeKey]
    : messages.notExisting;
  return <FormattedMessage {...messageForCountry} />;
};

CountryName.propTypes = {
  countryCode: PropTypes.string,
};

export default CountryName;
