import { spacers } from '@trainline/depot-web/css/themes/trainline';
import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({
  container: {
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    background: 'none',
    right: spacers.dp_s24,
    top: spacers.dp_s24,
  },
  loader: {
    marginRight: spacers.dp_s16,
  },
});
