import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { IconChevronRight } from '@trainline/depot-web';

import refundReasonShape from '@contactcentre-web/utils/shape/refundReason';
import Button from '@contactcentre-web/common/Button';

import RefundReasonModal from '../RefundReasonModal';

import messages from './messages';
import styles from './styles';

export class RefundReasonWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reasonSelectionModalVisible: false,
    };
  }

  onReasonConfirmed(value) {
    const {
      input: { onChange },
    } = this.props;

    this.hideReasonSelectionModal();
    if (onChange) {
      onChange(value);
    }
  }

  showReasonSelectionModal() {
    this.setState({
      reasonSelectionModalVisible: true,
    });
  }

  hideReasonSelectionModal() {
    this.setState({
      reasonSelectionModalVisible: false,
    });
  }

  showReasons(path) {
    const { refundType } = this.props;
    if (refundType === 'termsAndConditions' && path.length === 1) {
      const [reason] = path;
      return <div>{reason.description}</div>;
    }

    if (path.length > 0) {
      return (
        <div className={css(styles.reasonContent)}>
          <ul className={css(styles.path)}>
            {path.map(({ id, description }) => (
              <li key={id} className={css(styles.pathItem)}>
                {description}
              </li>
            ))}
          </ul>
        </div>
      );
    }

    return (
      <div className={css(styles.reasonContent)}>
        <FormattedMessage {...messages.noReason} />
      </div>
    );
  }

  render() {
    const {
      disabled,
      meta: { error, submitting, touched } = {},
      reasons,
      input: { value },
      refundType,
      isUsedTicketRefund,
      isReasonCodePerRefundable,
      applyReasonCodeToAllFares,
      isApplyAllVisible,
      styleSheet,
    } = this.props;
    const { reasonSelectionModalVisible } = this.state;
    const path = [];
    let reason = reasons.find(({ id }) => id === value);

    while (reason) {
      path.push(reason);
      reason = reason.parent;
    }
    path.reverse();

    return (
      <>
        <div className={css(styleSheet?.container)}>
          {refundType !== 'termsAndConditions' ? (
            <FormattedMessage {...messages.selectReason} />
          ) : (
            <FormattedMessage {...messages.additionalInfo} />
          )}
          <Button
            variant="ghost"
            testId="reasonCode"
            size="small"
            styleSheet={[
              styles.reason,
              refundType !== 'termsAndConditions' && styles.changeableReasons,
              (disabled || submitting) && styles.inactive,
            ]}
            onClick={() => !disabled && !submitting && this.showReasonSelectionModal()}
          >
            {this.showReasons(path)}
            {refundType !== 'termsAndConditions' && <IconChevronRight />}
          </Button>

          {!disabled && !submitting && touched && error && (
            <span data-test-id="error" className={css(styles.error)}>
              <FormattedMessage
                {...(messages[`validation_${error}`] || messages.validation_unknown)}
              />
            </span>
          )}
          {reasonSelectionModalVisible && (
            <RefundReasonModal
              reasons={reasons}
              value={value}
              onClosed={() => this.hideReasonSelectionModal()}
              onConfirmed={(val) => this.onReasonConfirmed(val)}
              isUsedTicketRefund={isUsedTicketRefund}
            />
          )}
        </div>
        {isReasonCodePerRefundable && (
          // when isApplyAllVisible is false we still need the div to fill the grid column
          <div>
            {isApplyAllVisible && (
              <Button
                variant="tertiary"
                size="small"
                testId="apply-reasons-to-all"
                onClick={() => applyReasonCodeToAllFares(value)}
                disabled={!value || value === 'not-set' || disabled}
              >
                <FormattedMessage {...messages.applyReasonsToAll} />
              </Button>
            )}
          </div>
        )}
      </>
    );
  }
}

RefundReasonWrapper.propTypes = {
  disabled: PropTypes.bool,
  reasons: PropTypes.arrayOf(refundReasonShape),
  quoteUri: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  refundType: PropTypes.string,
  isUsedTicketRefund: PropTypes.bool,
  isReasonCodePerRefundable: PropTypes.bool.isRequired,
  applyReasonCodeToAllFares: PropTypes.func,
  isApplyAllVisible: PropTypes.bool,
  styleSheet: PropTypes.object,
};

const RefundReasons = ({ reasons, disabled, refundType, isUsedTicketRefund, ...others }) => (
  <Field
    name="reasonCode"
    component={RefundReasonWrapper}
    reasons={reasons || []}
    disabled={disabled}
    refundType={refundType}
    isUsedTicketRefund={isUsedTicketRefund}
    {...others}
  />
);

RefundReasons.propTypes = {
  reasons: PropTypes.array,
  disabled: PropTypes.bool,
  refundType: PropTypes.string,
  isUsedTicketRefund: PropTypes.bool,
};

export default RefundReasons;
