import React, { Dispatch, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Button from '@contactcentre-web/common/Button';
import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from '@contactcentre-web/common/Modal';
import type State from '@contactcentre-web/redux-common/types/State';
import type Action from '@contactcentre-web/redux-common/types/Action';

import { selectors, actions } from '../../module';

import messages from './messages';

interface StateProps {
  canDisable: boolean;
  isDisablePending: boolean;
  isDisableSuccess: boolean;
  userId: string;
}

interface DispatchProps {
  disableUser: (userId: string) => void;
}

type Props = StateProps & DispatchProps;

export const DisableUser: FunctionComponent<Props> = ({
  canDisable,
  isDisablePending,
  isDisableSuccess,
  disableUser,
  userId,
}) => {
  const [disableUserConfirmPromptVisible, setDisableUserConfirmPromptVisible] =
    React.useState(false);

  React.useEffect(() => {
    if (isDisableSuccess) {
      setDisableUserConfirmPromptVisible(false);
    }
  }, [isDisableSuccess]);

  return (
    <div>
      <Button
        variant="primary"
        size="xlarge"
        fullWidth
        testId="disable-user-button"
        disabled={!canDisable}
        onClick={() => setDisableUserConfirmPromptVisible(true)}
      >
        <FormattedMessage {...messages.disableUser} />
      </Button>
      {disableUserConfirmPromptVisible && (
        <Modal
          testId="disable-user-prompt"
          onClose={() => setDisableUserConfirmPromptVisible(false)}
        >
          <ModalHeader>
            <ModalTitle>
              <FormattedMessage {...messages.disableUserConfirmPromptHeader} />
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <FormattedMessage {...messages.disableUserConfirmPromptConfirmation} />
          </ModalBody>
          <ModalFooter contentAlignment="spaceBetween">
            <Button
              variant="tertiary"
              size="large"
              onClick={() => setDisableUserConfirmPromptVisible(false)}
              disabled={isDisablePending}
              testId="cancel-disable-user-prompt"
            >
              <FormattedMessage {...messages.disableUserConfirmPromptCancel} />
            </Button>
            <Button
              variant="primary"
              size="large"
              testId="disable-user-confirm-prompt-ok"
              loading={isDisablePending}
              onClick={() => disableUser(userId)}
            >
              <FormattedMessage {...messages.disableUserConfirmPromptYes} />
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  userId: selectors.getUserId(state),
  isDisablePending: selectors.isDisablePending(state),
  isDisableSuccess: selectors.isDisableSuccess(state),
  canDisable: selectors.canDisable(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  disableUser: (userId: string) => dispatch(actions.disableUserAttempt(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DisableUser);
