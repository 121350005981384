import { createSelector } from 'reselect';

import { getProducts } from '@contactcentre-web/redux-common/selectors/products';
import { ProductType } from '@contactcentre-web/redux-common/types/ProductType';
import { TravelProduct } from '@contactcentre-web/redux-common/types/TravelProduct';

import DetaxeLoadStatus from './types/DetaxeLoadStatus';

const localState = (state: any): any => state.detaxe;

export const detaxeEligibility = createSelector(localState, (state) => state.products);

export const getBookings = createSelector(
  getProducts,
  detaxeEligibility,
  (
    products,
    eligibility: Array<{
      productId: string;
      isEligible: boolean;
      links: Array<{ href: string; rel: string }>;
      refundTypes: string[];
    }>
  ) => {
    if (!eligibility) {
      return [];
    }

    const productEligibility: {
      [productId: string]: { isEligible: boolean; detaxeLink?: string; refundTypes: string[] };
    } = eligibility.reduce(
      (current, { isEligible, links, productId, refundTypes }) => ({
        ...current,
        [productId]: {
          isEligible,
          detaxeLink: links.find(({ rel }) => rel === 'External-form')?.href,
          refundTypes,
        },
      }),
      {}
    );

    return products
      .filter((p) => p.type === ProductType.TravelProduct)
      .map((p) => p as TravelProduct)
      .filter(({ id }) => productEligibility[id]?.isEligible && productEligibility[id]?.detaxeLink)
      .map(({ id, origin, destination, isReturn, outDate }: TravelProduct) => ({
        id,
        origin,
        destination,
        isReturn,
        outDate,
        detaxeUrl: productEligibility[id].detaxeLink,
        isEligible: productEligibility[id].isEligible,
        refundTypes: productEligibility[id].refundTypes,
      }));
  }
);

export const anyDetaxeEligibleBookings = createSelector(
  getBookings,
  (bookings) => bookings && bookings.length > 0
);

export const getCreateRefundStatus = createSelector(
  localState,
  (state) => state.createRefundStatus
);

export const getDetaxeRefundStatus = createSelector(localState, (state) => state.refundStatus);

export const getLoadingDetaxeRefundStatus = createSelector(
  localState,
  (state) => state.getRefundStatus === DetaxeLoadStatus.Loading
);

export const setRefundStatus = createSelector(localState, (state) => state.setRefundStatus);
