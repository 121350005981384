import { defineMessages } from 'react-intl';

export default defineMessages({
  isRefundFailed: {
    id: 'SameDayVoidStatus.isRefundFailed',
    defaultMessage:
      'Oops, something went wrong and the refund request has failed. Please try again.',
  },
  isUpdateFailed: {
    id: 'SameDayVoidStatus.isUpdateFailed',
    defaultMessage: 'Unable to calculate refund amount. Please reload the page.',
  },
});
