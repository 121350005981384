import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { StatusMessage } from '@trainline/depot-web';

import Link from '@contactcentre-web/common/Link';

import messages from './messages';
import styles from './styles';

export const ExchangesBookingAlert = ({ customerId, exchangedOrderReference, type }) =>
  exchangedOrderReference ? (
    <div className={css(styles.notification)}>
      <StatusMessage status="info">
        <FormattedMessage
          {...(type === 'amendReservation'
            ? messages.amendSeatReservationExchange
            : messages.exchangesOrder)}
          values={{
            link: (
              <Link
                linkType="internal"
                to={`/customers/${customerId}/bookings/${exchangedOrderReference}`}
              >
                <FormattedMessage {...messages.exchanges} />
              </Link>
            ),
          }}
        />
      </StatusMessage>
    </div>
  ) : null;

ExchangesBookingAlert.propTypes = {
  customerId: PropTypes.string.isRequired,
  exchangedOrderReference: PropTypes.string,
  type: PropTypes.string,
};

export default ExchangesBookingAlert;
