import { StyleSheet } from 'aphrodite/no-important';
import { palettes, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  icon: {
    color: palettes.dp_app_text_subdued,
    height: 'auto',
    width: spacers.dp_s48,
    margin: 'auto',
  },
});
