import { defineMessages } from 'react-intl';

export default defineMessages({
  cancelled: {
    id: 'CallingPoints.cancelled',
    defaultMessage: 'Cancelled',
  },
  notApplicable: {
    id: 'CallingPoints.notApplicable',
    defaultMessage: 'N/A',
  },
});
