import { defineMessages } from 'react-intl';

export default defineMessages({
  cancelled: {
    id: 'DisruptionReasons.cancelled',
    defaultMessage: 'The train has been cancelled',
  },
  cancelledOnOriginStop: {
    id: 'DisruptionReasons.cancelledOnOriginStop',
    defaultMessage:
      'This train is running, but the origin stop of the customer journey has been cancelled',
  },
  cancelledOnDestinationStop: {
    id: 'DisruptionReasons.cancelledOnDestinationStop',
    defaultMessage:
      'This train is running, but the destination stop of the customer journey has been cancelled',
  },
  cancelledOnOriginAndDestinationStop: {
    id: 'DisruptionReasons.cancelledOnOriginAndDestinationStop',
    defaultMessage:
      'This train is running, but the origin and destination stop of the customer journey has been cancelled',
  },
});
