import { StyleSheet } from 'aphrodite/no-important';
import {
  typography,
  borderRadius,
  spacers,
  fonts,
} from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  container: {
    paddingTop: spacers.dp_s48,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    color: colors.gray.alpha30,
    textTransform: 'uppercase',
    marginBottom: spacers.dp_s4,
  },
  directions: {
    maxWidth: '33%',
  },
  direction: {
    fontFamily: typography.dp_title2_font_family,
    fontSize: typography.dp_title2_font_size_m,
    fontWeight: fonts.dp_font_weight_bold,
    lineHeight: typography.dp_title2_line_height_m,
    letterSpacing: '-0.3px', // TODO: replace with depot-web typography when updated in depot-web
  },
  returnLabel: {
    color: colors.gray.alpha54,
    fontWeight: fonts.dp_font_weight_regular,
  },
  otherItems: {
    maxWidth: '15%',
  },
  value: {
    wordWrap: 'break-word',
    paddingRight: spacers.dp_s16,
  },
  dateValue: {
    color: colors.gray.alpha54,
  },
  price: {
    textAlign: 'right',
  },
  inventoryPrice: {
    fontStyle: 'italic',
    color: colors.gray.alpha54,
  },
  link: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    display: 'inline-block',
    backgroundColor: colors.info.light,
    color: colors.info.dark,
    fontWeight: fonts.dp_font_weight_bold,
    margin: `${spacers.dp_s4} 0 0 -${spacers.dp_s8}`,
    padding: `${spacers.dp_s4} ${spacers.dp_s8}`,
    cursor: 'pointer',
    ':visited': {
      color: colors.info.dark,
    },
    borderRadius: borderRadius.dp_r8,
  },
});
