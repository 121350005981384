import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { css } from 'aphrodite/no-important';

import Button from '@contactcentre-web/common/Button';
import TextField from '@contactcentre-web/common/TextField';
import { ModalBody, ModalFooter } from '@contactcentre-web/common/Modal';
import { PassengerData } from '@contactcentre-web/hooks/api/useCustomerPassengers';

import messages from './messages';
import styles from './styles';

export const FORM_ID = 'PassengerForm';

export interface Props {
  onSubmitForm: (data: PassengerData) => void;
  onCancelForm?: () => void;
  isSubmitting: boolean;
  isEditting?: boolean;
}

const textFieldStyle = {
  container: styles.s100,
  errorContainer: styles.errorContainer,
};

const todayDate = () => {
  const today = new Date();
  const dd = today.getDate();
  const mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();

  return `${yyyy}-${mm < 10 ? `0${mm}` : mm}-${dd < 10 ? `0${dd}` : dd}`;
};

export const PassengerForm = ({
  handleSubmit,
  onSubmitForm,
  onCancelForm,
  isSubmitting,
  isEditting,
}: Props & InjectedFormProps<PassengerData, Props>) => {
  const { formatMessage } = useIntl();

  return (
    <form onSubmit={handleSubmit((data) => onSubmitForm(data))} autoComplete="off">
      <ModalBody styleSheet={styles.form}>
        <section className={css(styles.row)}>
          <div className={css(styles.formFields)}>
            <TextField
              label={formatMessage(messages.passengerFormFirstNameLabel)}
              name="firstName"
              placeholder={formatMessage(messages.passengerFormFirstNameLabel)}
              type="text"
              messages={messages}
              styleSheet={textFieldStyle}
            />
          </div>
          <div className={css(styles.formFields)}>
            <TextField
              label={formatMessage(messages.passengerFormLastNameLabel)}
              name="lastName"
              placeholder={formatMessage(messages.passengerFormLastNameLabel)}
              type="text"
              messages={messages}
              styleSheet={textFieldStyle}
            />
          </div>
        </section>
        <section className={css(styles.row)}>
          <div className={css(styles.formFields)}>
            <TextField
              label={formatMessage(messages.passengerFormEmailLabel)}
              name="email"
              placeholder={formatMessage(messages.passengerFormEmailLabel)}
              type="text"
              messages={messages}
              styleSheet={textFieldStyle}
            />
          </div>
        </section>
        <section className={css(styles.row)}>
          <div className={css(styles.formFields, styles.s40)}>
            <TextField
              label={formatMessage(messages.passengerFormDOBLabel)}
              name="dateOfBirth"
              placeholder={formatMessage(messages.passengerFormDOBLabel)}
              type="date"
              messages={messages}
              max={todayDate()}
              styleSheet={textFieldStyle}
            />
          </div>
          <div className={css(styles.formFields, styles.s60)}></div>
        </section>
        <section className={css(styles.row)}>
          <div className={css(styles.formFields, styles.s40)}>
            <TextField
              label={formatMessage(messages.passengerFormCountryContactNumberLabel)}
              name="phoneCountryCode"
              placeholder={formatMessage(messages.passengerFormCountryContactNumberLabel)}
              type="text"
              messages={messages}
              styleSheet={textFieldStyle}
            />
          </div>
          <div className={css(styles.formFields, styles.s60)}>
            <TextField
              label={formatMessage(messages.passengerFormContactNumberLabel)}
              name="phoneNumber"
              placeholder={formatMessage(messages.passengerFormContactNumberLabel)}
              type="text"
              messages={messages}
              styleSheet={textFieldStyle}
            />
          </div>
        </section>
      </ModalBody>
      <ModalFooter contentAlignment={isEditting ? 'spaceBetween' : 'end'}>
        {isEditting && (
          <Button
            variant="tertiary"
            size="large"
            onClick={onCancelForm}
            testId="passenger-form-cancel-button"
          >
            <FormattedMessage {...messages.passengerFormCancelButton} />
          </Button>
        )}
        <Button
          type="submit"
          variant="primary"
          size="large"
          testId="submit-passenger-form"
          loading={isSubmitting}
        >
          <FormattedMessage
            {...(isEditting ? messages.passengerFormEditButton : messages.passengerFormAddButton)}
          />
        </Button>
      </ModalFooter>
    </form>
  );
};

const phoneNumberReg = /^[\s()+-]*([0-9][x\s()+-.]*){6,20}$/;
const phoneNumberCodeReg = /^[\s()+-]*([0-9]*){6}$/;

export const validate = ({
  firstName,
  lastName,
  dateOfBirth,
  phoneNumber,
  phoneCountryCode,
}: PassengerData): Record<string, string | undefined> => ({
  firstName: !firstName ? 'validationFirstNameRequired' : undefined,
  lastName: !lastName ? 'validationLastNameRequired' : undefined,
  dateOfBirth: !dateOfBirth ? 'validationDOBRequired' : undefined,
  phoneNumber:
    phoneNumber && !phoneNumberReg.test(phoneNumber.trim())
      ? 'validationPhoneNumber'
      : !phoneNumber && phoneCountryCode
      ? 'validationPhoneNumberRequired'
      : undefined,
  phoneCountryCode:
    phoneCountryCode && !phoneNumberCodeReg.test(phoneCountryCode.trim())
      ? 'validationPhoneCountryCode'
      : phoneNumber && !phoneCountryCode
      ? 'validationPhoneCountryCodeRequired'
      : undefined,
});

export default reduxForm<PassengerData, Props>({
  form: FORM_ID,
  validate,
})(PassengerForm);
