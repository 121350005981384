import React from 'react';
import { palettes } from '@trainline/depot-web/css/themes/trainline';

interface Props {
  className?: string;
}

const IconTelephonyWarning = ({ className = '' }: Props) => (
  <svg
    data-testid="telephonyWarning"
    className={className}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7879 30.0377L11.7871 30.0373L11.787 30.0372L11.7867 30.0371L11.4584 29.8517L11.4435 29.8666C10.7717 29.4514 10.248 28.7878 10.0269 27.9666C10.0268 27.9664 10.0268 27.9663 10.0267 27.9661L9.54557 26.1658C9.54554 26.1657 9.54551 26.1656 9.54547 26.1655C9.35457 25.4464 9.78369 24.7053 10.4995 24.5148L10.5002 24.5146L14.1008 23.5511C14.8188 23.36 15.5597 23.7888 15.7502 24.5057L15.7504 24.5065L15.9494 25.2502L15.9492 25.2502L15.9527 25.262C16.0721 25.6683 16.5008 25.9753 16.9701 25.871L16.9721 25.8705C18.4216 25.5419 20.2824 24.6369 22.4505 22.4567C24.6118 20.2834 25.5276 18.4259 25.8581 16.987C25.9859 16.4976 25.6535 16.0688 25.2449 15.9553L25.2449 15.9552L25.2403 15.954L24.4971 15.755L24.4963 15.7548C23.7805 15.5643 23.3513 14.8232 23.5423 14.1041C23.5423 14.104 23.5423 14.1039 23.5423 14.1037L24.5053 10.5009L24.5055 10.5002C24.696 9.78341 25.4368 9.35462 26.1546 9.54549C26.1547 9.54552 26.1548 9.54554 26.1549 9.54557L27.9541 10.027C27.9542 10.0271 27.9543 10.0271 27.9544 10.0271C28.8955 10.2808 29.6296 10.9328 30.0198 11.75L30.0272 11.7655L30.0342 11.7779L30.0344 11.7782L30.0344 11.7782L30.0349 11.779L30.0369 11.7829C30.04 11.7888 30.0456 11.7996 30.0532 11.8153C30.0686 11.8468 30.0923 11.898 30.1209 11.9682C30.1781 12.1085 30.255 12.325 30.3246 12.6132C30.4635 13.1886 30.5741 14.0531 30.4379 15.1733C30.1664 17.4073 28.9057 20.7161 24.8099 24.8093C20.7139 28.9026 17.407 30.1643 15.1756 30.4372C14.0568 30.5741 13.1939 30.4644 12.6197 30.3261C12.3322 30.2569 12.1163 30.1803 11.9763 30.1233C11.9063 30.0948 11.8553 30.0713 11.824 30.056C11.8083 30.0483 11.7975 30.0428 11.7917 30.0397L11.7879 30.0377Z"
      stroke={palettes.dp_app_warning_dark}
      strokeMiterlimit="10"
    />
    <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" stroke="#D0D4D5" />
  </svg>
);

export default IconTelephonyWarning;
