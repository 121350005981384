import { handleActions } from 'redux-actions';

import { getLocale } from '@contactcentre-web/localisation/localisation';
import type { LOCALE_CODES } from '@contactcentre-web/localisation/locales';

import { PREFIX, SET_LOCALE } from './module';

const defaultLocale = getLocale();

export interface ProfileState {
  locale: LOCALE_CODES;
}

// Reducer
export const initialState = {
  locale: defaultLocale,
};

const reducer = handleActions(
  {
    [SET_LOCALE]: (state, { payload: { locale } }) => ({
      ...state,
      locale,
    }),
  },
  initialState,
  { prefix: PREFIX }
);

export default reducer;
