import { defineMessages } from 'react-intl';

export default defineMessages({
  passengerTypeadult: {
    id: 'ProductBreakdown.passengerTypeadult',
    defaultMessage: '{count, plural, one {# x Adult Ticket} other {# x Adult Tickets}}',
  },
  passengerTypechild: {
    id: 'ProductBreakdown.passengerTypechild',
    defaultMessage: '{count, plural, one {# x Child Ticket} other {# x Child Tickets}}',
  },
  passengerTypesenior: {
    id: 'ProductBreakdown.passengerTypesenior',
    defaultMessage: '{count, plural, one {# x Senior Ticket} other {# x Senior Tickets}}',
  },
  passengerTypeUnknown: {
    id: 'ProductBreakdown.passengerTypeUnknown',
    defaultMessage: '{count} x {type}',
  },
  return: {
    id: 'ProductRow.return',
    defaultMessage: 'Return',
  },
  extra: {
    id: 'ProductBreakdown.extra',
    defaultMessage: '{extraQuantity} x {extraName}',
  },
  season: {
    id: 'ProductBreakdown.season',
    defaultMessage: '{isFlexiSeason, select, true {Flexi ticket} other {Season}}',
  },
  travelcard: {
    id: 'ProductBreakdown.travelcard',
    defaultMessage: 'Add-on',
  },
  voucher: {
    id: 'ProductRow.voucher',
    defaultMessage: 'Voucher applied',
  },
  promoDiscount: {
    id: 'ProductRow.promoDiscount',
    defaultMessage: 'Promo applied',
  },
  cojTitleCell: {
    id: 'ProductCoJHeader.cojTitleCell',
    defaultMessage:
      '{linkType, select, replaces {New booking} replacedby {Original booking before CoJ}}',
  },
  original: {
    id: 'ProductCoJHeader.original',
    defaultMessage: 'Original booking',
  },
});
