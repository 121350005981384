import { borderRadius, palettes, spacers } from '@trainline/depot-web/css/themes/trainline';
import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({
  wrapper: {
    width: '70%',
  },
  container: {
    border: `solid 1px ${palettes.dp_app_border_base}`,
    borderTopWidth: 0,
    background: palettes.dp_brand_white,
    padding: spacers.dp_s32,
  },
  containerLast: {
    borderRadius: `0 0 ${borderRadius.dp_r4} ${borderRadius.dp_r4}`,
  },
  rowFields: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: spacers.dp_s16,
    marginBottom: spacers.dp_s16,
    marginTop: spacers.dp_s16,
  },
  rowRefundReason: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, max-content)',
    marginBottom: spacers.dp_s16,
  },
});
