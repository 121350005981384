import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { StatusMessage } from '@trainline/depot-web';

import messages from './messages';
import styles from './styles';

const LEVELS = {
  isRefundFailed: 'negative',
  isUpdateFailed: 'negative',
};

const SameDayVoidStatus = (props) => {
  const status = Object.keys(props).reduce(
    (prev, curr) => (props[curr] ? { level: LEVELS[curr], messageKey: curr } : prev),
    null
  );

  if (status) {
    const { level, messageKey } = status;
    const message = messages[messageKey];
    return (
      <div className={css(styles.smallNotification)}>
        <StatusMessage status={level}>
          <FormattedMessage {...message} />
        </StatusMessage>
      </div>
    );
  }
  return null;
};

/* eslint react/no-unused-prop-types: off */
SameDayVoidStatus.propTypes = {
  isUpdateFailed: PropTypes.bool.isRequired,
  isRefundFailed: PropTypes.bool.isRequired,
};

export default SameDayVoidStatus;
