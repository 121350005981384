import { StyleSheet } from 'aphrodite/no-important';
import { spacers, typography, palettes } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  badgeContainer: {
    fontSize: typography.dp_micro_font_size,
    color: palettes.dp_brand_neutral_dark,
    backgroundColor: palettes.dp_brand_neutral_lighter,
    borderRadius: spacers.dp_s24,
    padding: `2px ${spacers.dp_s8}`,
    textAlign: 'center',
    width: 'max-content',
    border: `1px solid ${palettes.dp_brand_neutral_dark}`,
  },
});
