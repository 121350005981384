import { defineMessages } from 'react-intl';

export default defineMessages({
  accountHolder: {
    id: 'VirtualTerminalPaymentDetail.accountHolder',
    defaultMessage: 'Account Holder <strong>{value}</strong>',
  },
  totalTransaction: {
    id: 'VirtualTerminalPaymentDetail.totalTransaction',
    defaultMessage: 'Total transaction <strong>{value}</strong>',
  },
  paymentMethod: {
    id: 'VirtualTerminalPaymentDetail.paymentMethod',
    defaultMessage: 'Payment method <strong>{value}</strong>',
  },
});
