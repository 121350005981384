import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { css } from 'aphrodite/no-important';
import { Heading, Paragraph } from '@trainline/depot-web';

import request from '@contactcentre-web/utils/request';
import { getCurrentManagedGroupName } from '@contactcentre-web/authentication/redux/selectors';

import GenerateUserForm from './components/GenerateUserForm';
import ConfirmationPage from './components/ConfirmationPage';
import messages from './messages';
import styles from './styles';
import RolesList from './components/RolesList';

export enum States {
  Success = 'success',
  Failure = 'failure',
  Existing = 'existing',
}

export type Rolename =
  | 'admin'
  | 'readOnly'
  | 'agent'
  | 'superAdmin'
  | 'teamLead'
  | 'frontOfficeTeam'
  | 'refundTeam'
  | 'paymentTeam'
  | 'fraudTeam'
  | 'firstUser'
  | 'relationTeam';

interface RoleStatus {
  roleName: Rolename;
  state: States;
}

export type Data = {
  firstUser: {
    state?: States;
    username: string;
    password: string;
  };
  roles: Array<RoleStatus>;
};

export type FormData = {
  firstName: string;
  lastName: string;
  username: string;
};

export interface RoleList {
  totalCount: number;
  items: Array<{ roleName: Rolename }>;
}

const SetupManagedGroup = () => {
  const managedGroupName = useSelector(getCurrentManagedGroupName);

  const { data: rolesList } = useQuery(`roles_managed_group`, () =>
    request<RoleList>(`/authapi/managedGroupUser/roles`)
  );

  const { isLoading, data, mutate, isSuccess } = useMutation((formData: FormData) =>
    request<Data>(`/authapi/managedGroupUser/create`, {
      method: 'POST',
      body: formData,
    })
  );

  return (
    <>
      {rolesList &&
        (isSuccess ? (
          <ConfirmationPage data={data} managedGroupName={managedGroupName} rolesList={rolesList} />
        ) : (
          <>
            <Heading typeStyle="title1" as="h1" color="base">
              <FormattedMessage {...messages.title} />
            </Heading>
            <div className={css(styles.text)}>
              <Paragraph typeStyle="body" as="p" color="base" fontWeight="regular">
                <FormattedMessage {...messages.description} values={{ managedGroupName }} />
              </Paragraph>
            </div>
            <RolesList data={data} rolesList={rolesList} />
          </>
        ))}
      {(!data?.firstUser.state || data?.firstUser.state === States.Failure) && (
        <div className={css(styles.formContainer)}>
          <GenerateUserForm
            onSubmitData={mutate}
            isLoading={isLoading}
            isRegenerate={data?.firstUser.state === States.Failure}
          />
        </div>
      )}
    </>
  );
};

export default SetupManagedGroup;
