import { StyleSheet } from 'aphrodite/no-important';
import { spacers, typography, fonts } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  railCardRefundableContainer: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '2fr repeat(3, 1fr)',
    gap: `${spacers.dp_s8} ${spacers.dp_s16}`,
    padding: spacers.dp_s16,
  },
  railCardRefundableColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  railCardRefundableColumnGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, max-content)',
    gridTemplateRows: 'repeat(2, max-content)',
  },
  railCardRefundableLabel: {
    ':nth-child(2)': {
      gridColumn: '3',
    },
  },
  railCardRefundableFlex: {
    display: 'flex',
    alignItems: 'center',
    columnGap: spacers.dp_s8,
  },
  railCardRefundableArrow: {
    opacity: 0.5,
    margin: `0 ${spacers.dp_s8}`,
    fontSize: typography.dp_title1_font_size,
  },
  railCardRefundableValue: {
    fontWeight: fonts.dp_font_weight_bold,
  },
});
