import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { StatusMessage } from '@trainline/depot-web';

import messages from './messages';
import styles from './styles';

const RemoveCollectionRestrictionAlert = () => (
  <div className={css(styles.errorBanner)}>
    <StatusMessage status="negative">
      <FormattedMessage {...messages.changeCTR_genericError} />
    </StatusMessage>
  </div>
);

export default RemoveCollectionRestrictionAlert;
