import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'VirtualTerminalStateModal.title',
    defaultMessage: 'Compensation request submitted',
  },
  titleDebit: {
    id: 'VirtualTerminalStateModal.titleDebit',
    defaultMessage: 'Customer has been debited',
  },
  titleWarning: {
    id: 'VirtualTerminalStateModal.titleWarning',
    defaultMessage: 'Compensation request failed',
  },
  titleWarningDebit: {
    id: 'VirtualTerminalStateModal.titleWarningDebit',
    defaultMessage: 'Debit failed',
  },
  body: {
    id: 'VirtualTerminalStateModal.body',
    defaultMessage: 'Your compensation request has been sent for approval.',
  },
  bodyWarning: {
    id: 'VirtualTerminalStateModal.bodyWarning',
    defaultMessage: 'Something went wrong. Please try again.',
  },
  bodyWarningDebitGeneric: {
    id: 'VirtualTerminalStateModal.bodyWarningDebitGeneric',
    defaultMessage: 'The customer couldn’t be debited {br} {br} Code Issue: {errorCode}',
  },
  bodyWarningDebitRollbackFailed: {
    id: 'VirtualTerminalStateModal.bodyWarningDebitRollbackFailed',
    defaultMessage:
      'The customer couldn’t be debited, This will display in the order history as "Requested"',
  },
  button: {
    id: 'VirtualTerminalStateModal.button',
    defaultMessage: 'Done',
  },
});
