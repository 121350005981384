import { defineMessages } from 'react-intl';

export default defineMessages({
  serviceFee: {
    id: 'RefundFees.serviceFee',
    defaultMessage: 'Service Fee',
  },
  paymentFee: {
    id: 'RefundFees.paymentFee',
    defaultMessage: 'Payment Fee',
  },
  deliveryFee: {
    id: 'RefundFees.deliveryFee',
    defaultMessage: 'Delivery fee',
  },
});
