import React, { useRef, Dispatch, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { Heading } from '@trainline/depot-web';

import Loader from '@contactcentre-web/common/Loader';
import Link from '@contactcentre-web/common/Link';
import Button from '@contactcentre-web/common/Button';
import PageContainer from '@contactcentre-web/common/PageContainer';
import Select from '@contactcentre-web/common/Select';
import { useSites } from '@contactcentre-web/hooks/api/useSites';
import type State from '@contactcentre-web/redux-common/types/State';
import type Action from '@contactcentre-web/redux-common/types/Action';

import { actions, selectors } from '../../module';

import messages from './messages';
import styles from './styles';

export const FORM_ID = 'changeSite';

type ChangeSiteFormData = {
  site: string;
};

interface Params {
  username: string;
}

interface StateProps {
  isChangeSitePending: boolean;
  isChangeSiteSuccess: boolean;
  isUserLoaded: boolean;
  isLoadUserPending: boolean;
  userId: string;
  site: string | null;
}

interface DispatchProps {
  changeSite: (userId: string, site: string) => void;
  loadUser: (username: string) => void;
  resetState: () => void;
}

type Props = StateProps & DispatchProps;

export const ChangeSite: FunctionComponent<
  Props & InjectedFormProps<ChangeSiteFormData, Props>
> = ({
  changeSite,
  isChangeSitePending,
  isChangeSiteSuccess,
  handleSubmit,
  loadUser,
  isUserLoaded,
  isLoadUserPending,
  resetState,
  userId,
  site,
  initialize,
}) => {
  const history = useHistory();
  const { username } = useParams<Params>();
  const resetStateRef = useRef(resetState);
  const { data: sites, isLoading: isLoadingSites } = useSites();

  React.useEffect(() => {
    resetStateRef.current();
    return () => resetStateRef.current();
  }, [resetStateRef]);

  React.useEffect(() => {
    if (!isUserLoaded) {
      loadUser(username);
    }
  }, [isUserLoaded]);

  React.useEffect(() => {
    if (site) {
      initialize({ site });
    }
  }, []);

  React.useEffect(() => {
    if (isChangeSiteSuccess) {
      history.push(`/edit_user/${username}?successfulAction=changeSite`);
    }
  }, [isChangeSiteSuccess]);

  const sitesOptions = sites?.map(({ name }) => ({ label: name, value: name }));

  return (
    <PageContainer>
      {isLoadUserPending || isLoadingSites ? (
        <div className={css(styles.container)}>
          <Loader />
        </div>
      ) : (
        <form id={FORM_ID} onSubmit={handleSubmit(({ site }) => changeSite(userId, site))}>
          <div className={css(styles.container)}>
            <div className={css(styles.innerContainer)}>
              <div className={css(styles.header)}>
                <Heading typeStyle="title1" as="h1" color="base">
                  <FormattedMessage
                    {...messages.editUser}
                    values={{
                      username: <span className={css(styles.username)}>{username}</span>,
                    }}
                  />
                </Heading>
              </div>
              <Select
                label={<FormattedMessage {...messages.siteIsRequired} />}
                name="site"
                testId="ChangeSite-site"
                options={sitesOptions}
                messages={messages}
                searchable={false}
              />
              <Button
                type="submit"
                variant="primary"
                size="xlarge"
                fullWidth
                loading={isChangeSitePending}
                testId="change-site-confirm-button"
                styleSheet={styles.button}
              >
                <FormattedMessage {...messages.update} />
              </Button>
            </div>
            <div className={css(styles.cancel)}>
              <Link linkType="internal" to={`/edit_user/${username}`}>
                <FormattedMessage {...messages.cancel} />
              </Link>
            </div>
          </div>
        </form>
      )}
    </PageContainer>
  );
};

const mapStateToProps = (state: State) => ({
  isChangeSitePending: selectors.isChangeSitePending(state),
  isChangeSiteSuccess: selectors.isChangeSiteSuccess(state),
  isLoadUserPending: selectors.isLoadUserPending(state),
  isUserLoaded: selectors.isUserLoaded(state),
  site: selectors.getUserSite(state),
  userId: selectors.getUserId(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  loadUser: (username: string) => dispatch(actions.loadUserAttempt(username)),
  changeSite: (userId: string, site: string) => dispatch(actions.changeSiteAttempt(userId, site)),
  resetState: () => dispatch(actions.resetState()),
});

export const validate = (values: ChangeSiteFormData) =>
  ['site']
    .filter((key) => !values[key as keyof ChangeSiteFormData])
    .reduce(
      (prev, curr) => ({
        ...prev,
        [curr]: `${curr}IsRequired`,
      }),
      {}
    );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<ChangeSiteFormData, Props>({
    form: FORM_ID,
    validate,
  })(ChangeSite)
);
