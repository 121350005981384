import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import Tooltip from '@contactcentre-web/common/Tooltip';

import messages from './messages';
import styles from './styles';

const PaymentStatus = ({ paymentStatus, errors = [] }) => {
  let mappedErrors = errors
    ? errors.map((e) => ({
        code: e,
        message: messages[`paymentError_${e.toLowerCase()}`] || messages.paymentError_unknown,
      }))
    : [];
  if (paymentStatus === 'Failed' && mappedErrors.length === 0) {
    mappedErrors = [
      {
        code: '',
        message: messages.paymentError_unknown,
      },
    ];
  }
  const statusCss =
    (paymentStatus && styles[`status_${paymentStatus.toLowerCase()}`]) || styles.status_unknown;
  return (
    <>
      <div className={css(styles.paymentStatusHeader)} data-test="paymentStatusHeader">
        <FormattedMessage {...messages.paymentStatus} />
      </div>
      <div className={css(styles.tooltipWrapper)}>
        <Tooltip
          anchor={
            <span className={css(styles.paymentStatus, statusCss)} data-test="paymentStatus">
              {paymentStatus}
            </span>
          }
          orientation="Above"
          hideDelay={0}
        >
          {mappedErrors && mappedErrors.length ? (
            <ul className={css(styles.errorList)}>
              {mappedErrors.map(({ code: errorCode, message }, i) => (
                <li key={i}>
                  {errorCode} <FormattedMessage {...message} />
                </li>
              ))}
            </ul>
          ) : null}
        </Tooltip>
      </div>
    </>
  );
};

PaymentStatus.propTypes = {
  paymentStatus: PropTypes.string,
  errors: PropTypes.array,
};

export default PaymentStatus;
