import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IconCopy } from '@trainline/depot-web';

import Badge from '../Badge/Badge';
import Button from '../Button';

import messages from './messages';
import styles from './styles';

export interface Props {
  value: string;
}

const CopyToClipboard = ({ value }: Props) => {
  const [isCopied, setCopied] = React.useState(false);

  const handleCopy = (): void => {
    if (!isCopied) {
      navigator.clipboard.writeText(value);
      setCopied(true);
    }
  };

  React.useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (isCopied) {
      timeout = setTimeout(() => setCopied(false), 3000);
    }
    return (): void => {
      clearTimeout(timeout);
    };
  }, [isCopied]);

  return (
    <>
      <Button variant="ghost" styleSheet={styles.button} onClick={handleCopy}>
        <IconCopy />
      </Button>
      <Badge styleSheet={[styles.badge, isCopied && styles.badgeVisible]}>
        <FormattedMessage {...messages.copied} />
      </Badge>
    </>
  );
};

export default CopyToClipboard;
