import { StyleSheet } from 'aphrodite/no-important';
import {
  palettes,
  borderRadius,
  spacers,
  typography,
  fonts,
} from '@trainline/depot-web/css/themes/trainline';

export const loaderBorderColor = palettes.dp_brand_white;

export const defaultStyleButtonComponent = {
  cursor: 'pointer',
  border: '1px solid transparent',
  ':focus-visible': {
    outline: 'none',
    boxShadow: `0 0 0 4px ${palettes.dp_comp_focus_ring}`,
  },
  ':disabled': {
    cursor: 'not-allowed',
    backgroundColor: palettes.dp_app_backdrop_darker,
    color: palettes.dp_app_text_disabled,
    borderColor: 'transparent',
  },
  ':disabled:hover': {
    backgroundColor: palettes.dp_app_backdrop_darker,
  },
};

export default StyleSheet.create({
  primary: {
    ...defaultStyleButtonComponent,
    backgroundColor: palettes.dp_comp_button_primary_background,
    color: palettes.dp_app_text_inverted,
    borderRadius: borderRadius.dp_r4,
    ':hover': {
      backgroundColor: palettes.dp_comp_button_primary_background_hover,
    },
    ':active': {
      backgroundColor: palettes.dp_comp_button_primary_background_pressed,
    },
  },
  secondary: {
    ...defaultStyleButtonComponent,
    backgroundColor: palettes.dp_comp_button_secondary_background,
    color: palettes.dp_app_text_inverted,
    borderRadius: borderRadius.dp_r4,
    ':hover': {
      backgroundColor: palettes.dp_comp_button_secondary_background_hover,
    },
    ':active': {
      backgroundColor: palettes.dp_comp_button_secondary_background_pressed,
    },
  },
  tertiary: {
    ...defaultStyleButtonComponent,
    backgroundColor: palettes.dp_comp_button_tertiary_background,
    color: palettes.dp_app_text_base,
    borderRadius: borderRadius.dp_r4,
    borderColor: palettes.dp_app_border_base,
    ':hover': {
      backgroundColor: palettes.dp_comp_button_tertiary_background_hover,
    },
    ':active': {
      backgroundColor: palettes.dp_comp_button_tertiary_background_pressed,
    },
  },
  destructive: {
    ...defaultStyleButtonComponent,
    backgroundColor: palettes.dp_app_negative_dark,
    color: palettes.dp_app_text_inverted,
    borderRadius: borderRadius.dp_r4,
    ':hover': {
      backgroundColor: palettes.dp_app_negative_darker,
    },
    ':active': {
      backgroundColor: palettes.dp_app_negative_darkest,
    },
  },
  ghost: {
    ...defaultStyleButtonComponent,
    background: 'none',
    color: 'inherit',
    textAlign: 'left',
  },

  small: {
    padding: `${spacers.dp_s8} ${spacers.dp_s16}`,
    fontSize: typography.dp_body_font_size,
    fontWeight: fonts.dp_font_weight_bold,
    lineHeight: typography.dp_body_line_height,
    letterSpacing: typography.dp_large_letter_spacing,
  },
  medium: {
    padding: `10px ${spacers.dp_s16}`,
    fontFamily: typography.dp_title3_font_family,
    fontSize: typography.dp_large_font_size,
    fontWeight: fonts.dp_font_weight_bold,
    lineHeight: typography.dp_large_line_height,
    letterSpacing: typography.dp_title3_letter_spacing,
  },
  large: {
    padding: '10px 20px',
    fontFamily: typography.dp_title2_font_family,
    fontSize: typography.dp_title3_font_size,
    fontWeight: fonts.dp_font_weight_bold,
    lineHeight: typography.dp_title3_line_height,
    letterSpacing: typography.dp_title2_letter_spacing,
  },
  xlarge: {
    padding: '14px 20px',
    fontFamily: typography.dp_title1_font_family,
    fontSize: typography.dp_title1_font_size,
    fontWeight: fonts.dp_font_weight_bold,
    lineHeight: typography.dp_title1_line_height,
    letterSpacing: typography.dp_title1_letter_spacing,
  },

  fullWidth: {
    width: '100%',
  },
  loading: {
    ':disabled': {
      backgroundColor: undefined,
      color: undefined,
    },
    ':disabled:hover': {
      backgroundColor: undefined,
    },
  },
  buttonContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    marginLeft: spacers.dp_s8,
  },
});
