import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import OriginToDestination from '@contactcentre-web/common/OriginToDestination/OriginToDestination';
import FormattedDateInLondonTz from '@contactcentre-web/common/FormattedDateInLondonTz';
import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency';
import StackedLabel from '@contactcentre-web/common/StackedLabel';
import VendorStatus from '@contactcentre-web/common/VendorStatus';
import FulfilmentStatus from '@contactcentre-web/common/FulfilmentStatus';
import TooltipLabel from '@contactcentre-web/common/TooltipLabel';
import FulfilmentStatusEnum from '@contactcentre-web/redux-common/types/FulfilmentStatus';
import { Journey, Validity } from '@contactcentre-web/redux-common/types/SeasonProduct';
import Price from '@contactcentre-web/redux-common/types/Price';
import {
  DeliveryMethod,
  DeliveryMethodId,
} from '@contactcentre-web/redux-common/types/DeliveryMethod';

import messages from './messages';
import styles, { fillColor } from './styles';

export interface Props {
  index: number;
  journey: Journey;
  validity: Validity;
  deliveryMethod: DeliveryMethod;
  status: string;
  ctr?: string;
  transactionTotalPrice: Price;
  fulfilmentStatus: FulfilmentStatusEnum;
  isFlexiSeason: boolean;
}

const Header = ({
  index,
  journey: { origin, destination },
  validity: { from: validFrom, to: validTo },
  deliveryMethod,
  status,
  ctr,
  transactionTotalPrice,
  fulfilmentStatus,
  isFlexiSeason,
}: Props) => (
  <section className={css(styles.container)}>
    <div className={css(styles.header)}>
      <div className={css(styles.journey)}>
        <StackedLabel
          label={
            <FormattedMessage
              {...messages.booking}
              values={{
                index,
              }}
            />
          }
          styleSheet={{
            label: styles.label,
            value: styles.value,
          }}
        >
          <div className={css(styles.journeyValue)}>
            <OriginToDestination
              isReturn
              origin={origin}
              destination={destination}
              fillColor={fillColor}
            />
          </div>
        </StackedLabel>
        <span className={css(styles.productTypeLabel)}>
          <FormattedMessage {...messages.productType} values={{ isFlexiSeason }} />
        </span>
        {fulfilmentStatus && (
          <div className={css(styles.trainlineProductStatus)}>
            <FulfilmentStatus status={fulfilmentStatus} />
          </div>
        )}
      </div>
      <StackedLabel
        label={<FormattedMessage {...messages.validity} />}
        styleSheet={{
          label: styles.label,
          value: styles.value,
        }}
      >
        <StackedLabel
          label={<FormattedMessage {...messages.validFrom} />}
          styleSheet={{
            label: styles.validityLabel,
            value: styles.validityValue,
          }}
        >
          {validFrom && (
            <FormattedDateInLondonTz value={validFrom}>
              {(text: string) => <strong>{text}</strong>}
            </FormattedDateInLondonTz>
          )}
        </StackedLabel>
        <StackedLabel
          label={<FormattedMessage {...messages.validTo} />}
          styleSheet={{
            container: styles.validityToContainer,
            label: styles.validityLabel,
            value: styles.validityValue,
          }}
        >
          {validTo && (
            <FormattedDateInLondonTz value={validTo}>
              {(text: string) => <strong>{text}</strong>}
            </FormattedDateInLondonTz>
          )}
        </StackedLabel>
      </StackedLabel>
      <div>
        <StackedLabel
          label={<FormattedMessage {...messages.deliveryMethod} />}
          styleSheet={{
            label: styles.label,
            value: styles.deliveryMethodValue,
          }}
        >
          {deliveryMethod.id === DeliveryMethodId.AtocSTicket ? (
            <TooltipLabel helpTip={<FormattedMessage {...messages.sTicketTooltip} />}>
              {deliveryMethod.displayName}
            </TooltipLabel>
          ) : (
            deliveryMethod.displayName
          )}
        </StackedLabel>
        <div className={css(styles.status)}>
          <VendorStatus status={status} />
        </div>
      </div>
      {ctr && (
        <StackedLabel
          data-test-id="season-header-ctr"
          label={<FormattedMessage {...messages.ctr} />}
          styleSheet={{
            label: styles.label,
            value: styles.ctrValue,
          }}
        >
          {ctr}
        </StackedLabel>
      )}
      <StackedLabel
        label={<FormattedMessage {...messages.productPrice} />}
        styleSheet={{
          label: styles.label,
          value: styles.value,
        }}
      >
        <div className={css(styles.price)}>
          <FormattedCurrency {...transactionTotalPrice} />
        </div>
      </StackedLabel>
    </div>
  </section>
);

export default Header;
