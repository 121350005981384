import { defineMessages } from 'react-intl';

export default defineMessages({
  searchTitle: {
    id: 'SearchPage.title',
    defaultMessage: 'Search {managedGroup} bookings',
  },
  bookingSearchOptions: {
    id: 'SearchPage.bookingSearchOptions',
    defaultMessage: `You can search for a booking using one of the following:`,
  },
  bookingSearchOptionsList: {
    id: 'searchPage.bookingSearchOptionsList',
    defaultMessage: `
    <ul>
      <li>Order ID</li>
      <li>PNR</li>
      <li>CTR</li>
      <li>TraCS ID</li>
      <li>Email address</li>
      <li>Smartcard number</li>
    </ul>
    `,
  },
  bookingTransactionNumberOptions: {
    id: 'searchPage.bookingTransactionNumberOptions',
    defaultMessage: `
  <p><b>UK Bookings</b></p>
    <p>They have a CTR (Customer Transaction Record) typically <b>8</b> characters</p>
    {linebreak}
    <p><b>EU Bookings</b></p>
    <p>They have a PNR (Passenger Name Record), typically <b>6</b> characters</p>
    {linebreak}
    <p><b>Confirmation email</b></p>
    <p>Trainline bookings have a <b>12</b> digit Transaction ID</p>`,
  },
  searchOptionsHeading: {
    id: 'searchPage.searchOptionsHeading',
    defaultMessage: 'Search options',
  },
  howToFindBookingIdHeading: {
    id: 'searchPage.howToFindBookingIdHeading',
    defaultMessage: 'How can customers find booking ID?',
  },
});
