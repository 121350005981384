import useEventListener from '@use-it/event-listener';
import { SyntheticEvent } from 'react';

type SyntheticMessageEvent = MessageEvent & SyntheticEvent<Element, MessageEvent>;

export const usePostMessage = <T, U>(
  origin?: string,
  messageCallback?: (data: T) => void,
  targetWindow?: Window
) => {
  useEventListener<SyntheticMessageEvent>('message', (event) => {
    if (messageCallback && event.origin === origin) {
      messageCallback(event.data);
    }
  });

  return (message: U) => {
    if (origin) {
      targetWindow?.postMessage(message, origin);
    }
  };
};
