import { useQuery, UseQueryOptions } from 'react-query';

import request from '@contactcentre-web/utils/request';

type ErrorShape = {
  code: string;
  detail?: string;
  meta?: { context?: Record<string, string | number>; severity?: string };
};

export interface ApplicationUsed {
  label: string;
  value: string;
}

interface ApplicationUsedResponse {
  items: ApplicationUsed[];
  totalCount: number;
  errors: ErrorShape[];
}

export const useApplicationUsed = (
  options: UseQueryOptions<
    ApplicationUsedResponse,
    unknown,
    ApplicationUsedResponse,
    'crm-application-type'
  >
) =>
  useQuery(
    `crm-application-type`,
    () => request<ApplicationUsedResponse>(`/crm/freshdesk/applicationsused`, { method: 'GET' }),
    {
      ...options,
    }
  );
