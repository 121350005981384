import React from 'react';
import moment from 'moment';
import { FormattedDate } from 'react-intl';

interface Props {
  value: Date | string;
  year?: '2-digit' | 'numeric';
  month?: '2-digit' | 'numeric' | 'narrow' | 'short' | 'long';
  weekday?: 'narrow' | 'short' | 'long';
  day?: '2-digit' | 'numeric';
  hour?: '2-digit' | 'numeric';
  minute?: '2-digit' | 'numeric';
}

const FormattedDateInTz = ({ value, ...otherProps }: Props) => {
  const m = moment.parseZone(value);
  const valueNoTz = m.add(m.utcOffset(), 'm').utc().toDate();

  return <FormattedDate {...otherProps} value={valueNoTz} timeZone="UTC" />;
};

export default FormattedDateInTz;
