import { defineMessages } from 'react-intl';

export default defineMessages({
  fareRestrictions: {
    id: 'FareRestrictions.fareRestrictions',
    defaultMessage: 'Fares Restrictions: ',
  },
  passengersTogether: {
    id: 'FareRestrictions.passengersTogether',
    defaultMessage: ' Passengers must travel together',
  },
});
