import { defineMessages } from 'react-intl';

export default defineMessages({
  paymentStatus: {
    id: 'PaymentStatusNext.paymentStatus',
    defaultMessage: 'Payment status',
  },
  paymentError_processordeclined: {
    id: 'PaymentStatusNext.paymentError_processordeclined',
    defaultMessage:
      'This payment request has been declined by the customers bank. We do not have any further information. The customer should contact their bank directly to find out more and could attempt to pay using a different card or alternative payment method such as ApplePay.',
  },
  paymentError_processorerror: {
    id: 'PaymentStatusNext.paymentError_processorerror',
    defaultMessage:
      'There has been an error with processing the payment. We do not have further information. Please advise the customer to attempt the order again unless it is clear the payment has been taken (PO), this should then be raised to the Payments team to investigate.',
  },
  paymentError_paymentdetailsnotfound: {
    id: 'PaymentStatusNext.paymentError_paymentdetailsnotfound',
    defaultMessage: 'Advise the customer to check all saved payment details are up-to-date.',
  },
  paymentError_invalidcurrencyforcardtype: {
    id: 'PaymentStatusNext.paymentError_invalidcurrencyforcardtype',
    defaultMessage:
      'We currently do not support this payment method in the currency selected. Advise the customer to attempt payment with an alternative payment method if possible.',
  },
  paymentError_insufficientfunds: {
    id: 'PaymentStatusNext.paymentError_insufficientfunds',
    defaultMessage:
      'This payment request has been declined by the customers bank. We do not have any further information. The customer should contact their bank directly to find out more and could attempt to pay using a different card or alternative payment method such as ApplePay.',
  },
  paymentError_invalidcardnumber: {
    id: 'PaymentStatusNext.paymentError_invalidcardnumber',
    defaultMessage:
      'There has been an error with the card details entered. Advise the customer to remove card details from their account (if saved) and attempt booking again, checking the numbers are correct.',
  },
  paymentError_invalidaddress: {
    id: 'PaymentStatusNext.paymentError_invalidaddress',
    defaultMessage:
      'Please raise this case to the Payments Team to investigate further and advise the customer we will be in touch once we can confirm what has happened.',
  },
  paymentError_invalidcardexpirydate: {
    id: 'PaymentStatusNext.paymentError_invalidcardexpirydate',
    defaultMessage:
      'There has been an error with the card details entered. Advise the customer to remove card details from their account (if saved) and attempt booking again, checking the numbers are correct.',
  },
  paymentError_invalidcarddetailscardsecuritycode: {
    id: 'PaymentStatusNext.paymentError_invalidcarddetailscardsecuritycode',
    defaultMessage:
      'There has been an error with the card details entered. Advise the customer to remove card details from their account (if saved) and attempt booking again, checking the numbers are correct.',
  },
  paymentError_invalidamount: {
    id: 'PaymentStatusNext.paymentError_invalidamount',
    defaultMessage:
      'The payment method selected is currently not supported for this order. Please advise the customer to attempt the booking using an alternative payment method.',
  },
  paymentError_invalidcurrency: {
    id: 'PaymentStatusNext.paymentError_invalidcurrency',
    defaultMessage:
      'We currently do not support this payment method in the currency selected. Advise the customer to attempt payment with an alternative payment method if possible.',
  },
  paymentError_carddetokenisationfailed: {
    id: 'PaymentStatusNext.paymentError_carddetokenisationfailed',
    defaultMessage:
      'Please raise this case to the Payments Team to investigate further and advise the customer we will be in touch once we can confirm what has happened.',
  },
  paymentError_paymentmethodunavailable: {
    id: 'PaymentStatusNext.paymentError_paymentmethodunavailable',
    defaultMessage:
      'There has beeen a general system failure and the payment has not been processed. Advise the customer to attempt the booking again with an alternative payment method.',
  },
  paymentError_timeout: {
    id: 'PaymentStatusNext.paymentError_timeout',
    defaultMessage:
      'The card authorisation was sent to the customers bank however, the bank failed to return a response. The payment has not been processed. Advise the customer to attempt the booking again.',
  },
  paymentError_internalservererror: {
    id: 'PaymentStatusNext.paymentError_internalservererror',
    defaultMessage:
      'Please raise this case to the Payments Team to investigate further and advise the customer we will be in touch once we can confirm what has happened.',
  },
  paymentError_duplicate: {
    id: 'PaymentStatusNext.paymentError_duplicate',
    defaultMessage:
      'Please raise this case to the Payments Team to investigate further and advise the customer we will be in touch once we can confirm what has happened.',
  },
  paymentError_invalidpayerauthenticationresponse: {
    id: 'PaymentStatusNext.paymentError_invalidpayerauthenticationresponse',
    defaultMessage:
      '3D Secure (3DS)/Strong customer authentication fail. Secondary verification request has not been actioned by the customer. Please advise the customer to contact their bank to fully enroll with 3DS.',
  },
  paymentError_unabletoaquirelock: {
    id: 'PaymentStatusNext.paymentError_unabletoaquirelock',
    defaultMessage:
      'There has beeen a general system failure and the payment has not been processed. Advise the customer to attempt the booking again later and this should emitt successfully.',
  },
  paymentError_incorrectpaymentstate: {
    id: 'PaymentStatusNext.paymentError_incorrectpaymentstate',
    defaultMessage:
      'There has been a general system failure and the payment has not been processed. Advise the customer to attempt the booking again later and this should emitt successfully.',
  },
  paymentError_incorrectpaymentdetailsstate: {
    id: 'PaymentStatusNext.paymentError_incorrectpaymentdetailsstate',
    defaultMessage:
      'Please raise this case to the Payments Team to investigate further and advise the customer we will be in touch once we can confirm what has happened.',
  },
  paymentError_invalidpaymentmethod: {
    id: 'PaymentStatusNext.paymentError_invalidpaymentmethod',
    defaultMessage:
      'Please raise this case to the Payments Team to investigate further and advise the customer we will be in touch once we can confirm what has happened.',
  },
  paymentError_invalidcarddetailscardholdername: {
    id: 'PaymentStatusNext.paymentError_invalidcarddetailscardholdername',
    defaultMessage:
      'This payment request has been declined by the customers bank as the card holder name is invalid. The customer should contact their bank directly if they want to find out more and could attempt to try again with the corrected name.',
  },
  paymentError_invalidcarddetailscardnumber: {
    id: 'PaymentStatusNext.paymentError_invalidcarddetailscardnumber',
    defaultMessage:
      'This payment request has been declined by the customers bank as the card number is invalid. The customer should contact their bank directly if they want to find out more and could attempt to pay again with the corrected card number.',
  },
  'paymentError_invalidcarddetailscardnumber:binrange': {
    id: 'PaymentStatusNext.paymentError_invalidcarddetailscardnumber:binrange',
    defaultMessage:
      'This payment request has been declined by the customers bank as the card number is invalid. The customer should contact their bank directly if they want to find out more and could attempt to pay again with the corrected card number.',
  },
  'paymentError_invalidcarddetailscardnumber:failedvalidbinrangerules': {
    id: 'PaymentStatusNext.paymentError_invalidcarddetailscardnumber:failedvalidbinrangerules',
    defaultMessage:
      'This payment request has been declined by the customers bank as the card number is invalid. The customer should contact their bank directly if they want to find out more and could attempt to pay again with the corrected card number.',
  },
  'paymentError_invalidcardexpirydate:cardexpired': {
    id: 'PaymentStatusNext.paymentError_invalidcardexpirydate:cardexpired',
    defaultMessage:
      'This payment request has been declined by the customers bank as the card has expired. The customer should contact their bank directly if they want to find out more and could attempt to pay using a different card or alternative payment method such as Apple Pay.',
  },
  paymentError_invalidcarddetailscardtype: {
    id: 'PaymentStatusNext.paymentError_invalidcarddetailscardtype',
    defaultMessage:
      'This payment request has been declined by the customers bank as the card type is invalid. The customer should contact their bank directly if they want to find out more and could attempt to pay using a different card or alternative payment method such as Apple Pay.',
  },
  paymentError_invalidcarddetailsexpirymonth: {
    id: 'PaymentStatusNext.paymentError_invalidcarddetailsexpirymonth',
    defaultMessage:
      'This payment request has been declined by the customers bank as the card expiry month is invalid. The customer should contact their bank directly if they want to find out more and could attempt to pay again with the corrected expiry month.',
  },
  paymentError_invalidcarddetailsexpiryyear: {
    id: 'PaymentStatusNext.paymentError_invalidcarddetailsexpiryyear',
    defaultMessage:
      'This payment request has been declined by the customers bank as the card expiry year is invalid. The customer should contact their bank directly if they want to find out more and could attempt to pay again with the corrected expiry year.',
  },
  paymentError_invalidbillingaddressline1: {
    id: 'PaymentStatusNext.paymentError_invalidbillingaddressline1',
    defaultMessage:
      'This payment request has been declined by the customers bank as the billing address is invalid. The customer should contact their bank directly if they want to find out more and could attempt to pay again with the corrected billing address.',
  },
  paymentError_invalidbillingaddresscountrycode: {
    id: 'PaymentStatusNext.paymentError_invalidbillingaddresscountrycode',
    defaultMessage:
      'This payment request has been declined by the customers bank as the billing address is invalid. The customer should contact their bank directly if they want to find out more and could attempt to pay again with the corrected billing address.',
  },
  paymentError_invalidbillingaddresscity: {
    id: 'PaymentStatusNext.paymentError_invalidbillingaddresscity',
    defaultMessage:
      'This payment request has been declined by the customers bank as the billing address is invalid. The customer should contact their bank directly if they want to find out more and could attempt to pay again with the corrected billing address.',
  },
  paymentError_invalidbillingaddresspostcode: {
    id: 'PaymentStatusNext.paymentError_invalidbillingaddresspostcode',
    defaultMessage:
      'This payment request has been declined by the customers bank as the billing address is invalid. The customer should contact their bank directly if they want to find out more and could attempt to pay again with the corrected billing address.',
  },
  paymentError_invalidstatecode: {
    id: 'PaymentStatusNext.paymentError_invalidstatecode',
    defaultMessage:
      'This payment request has been declined by the customers bank as the billing address is invalid. The customer should contact their bank directly if they want to find out more and could attempt to pay again with the corrected billing address.',
  },
  paymentError_invalidemailaddress: {
    id: 'PaymentStatusNext.paymentError_invalidemailaddress',
    defaultMessage:
      'This payment request has been declined by the customers bank as the billing address is invalid. The customer should contact their bank directly if they want to find out more and could attempt to pay again with the corrected billing address.',
  },
  paymentError_paymentserviceproviderunavailable: {
    id: 'PaymentStatusNext.paymentError_paymentserviceproviderunavailable',
    defaultMessage:
      'There has been a general system failure and the payment has not been processed. Advise the customer to attempt the booking again later and this should emitt successfully.',
  },
  paymentError_invalidrefund: {
    id: 'PaymentStatusNext.paymentError_invalidrefund',
    defaultMessage:
      'Please raise this case to the Refunds Team to investigate further and advise the customer we will be in touch once we can confirm what has happened.',
  },
  paymentError_invalidrefundamount: {
    id: 'PaymentStatusNext.paymentError_invalidrefundamount',
    defaultMessage:
      'The refund amount is greater than the maximum amount allowed - please check the correct refund value has been confirmed. If the refund value is correct, please raise this case to the Refunds Team to investigate further and advise the customer we will be in touch once we can confirm what has happened.',
  },
  paymentError_unknown: {
    id: 'PaymentStatusNext.paymentError_unknown',
    defaultMessage:
      "Payment has failed. We don't currently have more details on the reason of the failure.",
  },
  error_code: {
    id: 'PaymentStatusNext.error_code',
    defaultMessage: '{errorCode}:',
  },
});
