import React from 'react';
import { css } from 'aphrodite/no-important';

import styles from './styles';

interface Props {
  textLength: number;
  maxLength?: number;
  align?: 'left' | 'right';
}

const TextCounter = ({ textLength, maxLength, align = 'left' }: Props) => (
  <span
    className={css(
      styles.container,
      maxLength && textLength > maxLength ? styles.threshold : undefined,
      align === 'right' ? styles.alignRight : undefined
    )}
  >
    {`${textLength}${maxLength ? `/${maxLength}` : ''}`}
  </span>
);

export default TextCounter;
