import React, { FunctionComponent, useEffect } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage, useIntl } from 'react-intl';
import { Heading } from '@trainline/depot-web';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { useManagedGroups } from '@contactcentre-web/hooks/api/useManagedGroups';
import { TracsOnAccountPayRequest } from '@contactcentre-web/hooks/api/useTracsOnAccount';
import TextField from '@contactcentre-web/common/TextField';
import { useFetchReasonCodes } from '@contactcentre-web/hooks/api/useCompensation';
import RefundReason from '@contactcentre-web/refunds/common/RefundReason';
import Select from '@contactcentre-web/common/Select';
import Button from '@contactcentre-web/common/Button';
import { currencyMap } from '@contactcentre-web/utils/currencies';

import styles, { selectStyle } from './styles';
import messages from './messages';

export interface Props {
  payTracsOnAccount: (data: TracsOnAccountPayRequest) => void;
  isLoading: boolean;
}

const FORM_ID = 'TracsOnAccountForm';

export const TracsOnAccountForm: FunctionComponent<
  Props & InjectedFormProps<TracsOnAccountFormData, Props>
> = ({ initialize, pristine, invalid, handleSubmit, payTracsOnAccount, isLoading }) => {
  const { formatMessage } = useIntl();
  const {
    data: reasons,
    isLoading: isLoadingReasons,
    isError: isErrorReasons,
  } = useFetchReasonCodes();
  const { data: managedGroups } = useManagedGroups();

  const managedGroupSelectOptions = managedGroups?.map(({ number, name }) => ({
    label: name,
    value: number,
  }));

  useEffect(() => {
    initialize({
      currencyCode: 'GBP',
    });
  }, []);

  const currencyOptions = Object.values(currencyMap).map((currency) => ({
    label: currency.code,
    value: currency.code,
  }));

  const handleFormSubmit = (form: TracsOnAccountFormData) => {
    payTracsOnAccount({
      platform: 'TraCS', // static for now
      orderReference: form.tracsOrderReference,
      reasonCodeId: form.reasonCode,
      managedGroupNumber: form.managedGroup,
      refundAmount: { amount: form.totalAmount, currencyCode: form.currencyCode },
      corporateReference: form.corporateReference,
      requestingCustomer: { lastName: form.surname },
      notes: form.notes,
    });
  };

  return (
    <form id={FORM_ID} onSubmit={handleSubmit(handleFormSubmit)} className={css(styles.forms)}>
      <div className={css(styles.leftContainer)}>
        <div className={css(styles.form, styles.transationForm)}>
          <Heading typeStyle="title3" as="h2" color="base">
            <FormattedMessage {...messages.transationFormTitle} />
          </Heading>
          <div className={css(styles.formGrid)}>
            <Select
              name="managedGroup"
              label={formatMessage(messages.managedGroup)}
              placeholder={formatMessage(messages.managedGroupPlaceholder)}
              options={managedGroupSelectOptions}
              messages={messages}
              noChevron
              showOptionValue
            />
            <TextField
              label={formatMessage(messages.corporateReference)}
              name="corporateReference"
              testId="tracsonaccount-corporateReference"
              messages={messages}
              maxLength={50}
            />
            <TextField
              label={formatMessage(messages.tracsOrderReference)}
              name="tracsOrderReference"
              testId="tracsonaccount-tracsOrderReference"
              messages={messages}
              maxLength={50}
            />
          </div>
        </div>
        <div className={css(styles.form, styles.customerForm)}>
          <Heading typeStyle="title3" as="h2" color="base">
            <FormattedMessage {...messages.customerFormTitle} />
          </Heading>
          <div className={css(styles.formGrid)}>
            <TextField
              label={formatMessage(messages.surname)}
              name="surname"
              testId="tracsonaccount-surname"
              messages={messages}
              maxLength={200}
            />
          </div>
        </div>
        <div className={css(styles.form, styles.refundForm)}>
          <Heading typeStyle="title3" as="h2" color="base">
            <FormattedMessage {...messages.refundFormTitle} />
          </Heading>
          <div className={css(styles.formGrid)}>
            <RefundReason
              reasons={reasons}
              disabled={isLoadingReasons || isErrorReasons}
              isReasonCodePerRefundable
              messages={messages}
              styleSheet={{ container: styles.biggerColumn }}
            />
            <TextField
              name="notes"
              label={formatMessage(messages.notes)}
              testId="tracsonaccount-notes"
              type="multiline-text"
              rows={7}
              maxLength={1000}
              messages={messages}
              styleSheet={{ container: styles.biggerColumn }}
            />
          </div>
        </div>
      </div>
      <aside className={css(styles.rightContainer)}>
        <div className={css(styles.form, styles.paymentForm)}>
          <Heading typeStyle="title3" as="h2" color="base">
            <FormattedMessage {...messages.paymentFormTitle} />
          </Heading>
          <div className={css(styles.currencyContainer)}>
            <TextField
              label={formatMessage(messages.total)}
              testId="tracsonaccount-totalAmount"
              type="number"
              min="0.1"
              step="0.01"
              name="totalAmount"
              messages={messages}
              styleSheet={{ input: styles.inputCurrency }}
            />
            <Select name="currencyCode" options={currencyOptions} selectStyle={selectStyle} />
          </div>
          <Button
            type="submit"
            variant="primary"
            size="small"
            disabled={pristine || invalid}
            loading={isLoading}
          >
            <FormattedMessage {...messages.payOnAccountSubmit} />
          </Button>
        </div>
      </aside>
    </form>
  );
};

export interface TracsOnAccountFormData {
  managedGroup: number;
  corporateReference: string;
  tracsOrderReference: string;
  surname: string;
  reasonCode: string;
  notes: string;
  totalAmount: number;
  currencyCode: string;
}

export const validate = (data: TracsOnAccountFormData): Record<string, string | undefined> => ({
  corporateReference: !data?.corporateReference
    ? 'validation_corporateReference_invalid'
    : undefined,
  tracsOrderReference: !data?.tracsOrderReference
    ? 'validation_tracsOrderReference_invalid'
    : undefined,
  surname: !data?.surname ? 'validation_surname_invalid' : undefined,
  notes: !data?.notes ? 'validation_notes_invalid' : undefined,
  totalAmount: !data?.totalAmount ? 'validation_totalAmount_invalid' : undefined,
  managedGroup: !data?.managedGroup ? 'validation_managedGroup_invalid' : undefined,
});

export default reduxForm<TracsOnAccountFormData, Props>({
  form: FORM_ID,
  validate,
})(TracsOnAccountForm);
