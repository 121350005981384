import React from 'react';
import PropTypes from 'prop-types';

import BookingFormWrapperContainer from '../Booking/BookingFormWrapper';

const Bookings = ({
  orderHistoryRoute,
  orderReference,
  bookings,
  customerId,
  refundReasons,
  refundReasonDisabled,
  confirmPrompt,
  orderId,
  warnWithMultipleFarePassengers,
  reload,
  actionMessages,
  noPermissionsAlert,
  refundType,
}) => (
  <section>
    {bookings.map((booking, index) => (
      <BookingFormWrapperContainer
        key={index}
        bookingId={booking.id}
        booking={booking}
        index={index + 1}
        orderHistoryRoute={orderHistoryRoute}
        customerId={customerId}
        orderReference={orderReference}
        refundReasons={refundReasons || []}
        refundReasonDisabled={refundReasonDisabled}
        confirmPrompt={confirmPrompt}
        orderId={orderId}
        warnWithMultipleFarePassengers={warnWithMultipleFarePassengers}
        reload={reload}
        actionMessages={actionMessages}
        noPermissionsAlert={noPermissionsAlert}
        refundType={refundType}
      />
    ))}
  </section>
);
Bookings.propTypes = {
  orderReference: PropTypes.string.isRequired,
  bookings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      quoteReference: PropTypes.string,
      onePlatformUri: PropTypes.string,
    })
  ).isRequired,
  orderHistoryRoute: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
  refundReasons: PropTypes.array,
  refundReasonDisabled: PropTypes.bool,
  confirmPrompt: PropTypes.object,
  orderId: PropTypes.string.isRequired,
  warnWithMultipleFarePassengers: PropTypes.bool,
  reload: PropTypes.func,
  actionMessages: PropTypes.shape({
    request: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    process: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  noPermissionsAlert: PropTypes.object,
  refundType: PropTypes.string,
};

export default Bookings;
