enum FulfilmentStatus {
  Unspecified = 'unspecified',
  Pending = 'pending',
  Issuing = 'issuing',
  Complete = 'complete',
  Failed = 'failed',
  Abandoned = 'abandoned',
  VoidPending = 'voidPending',
  Voided = 'voided',
  VoidFailed = 'voidFailed',
  Locked = 'locked',
  Issued = 'issued',
  Cancelled = 'cancelled',
}

export default FulfilmentStatus;
