import { Heading } from '@trainline/depot-web';
import { css } from 'aphrodite/no-important';
import moment from 'moment';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import {
  FulfilmentConvertData,
  FulfilmentConvertEligibilityData,
  FulfilmentConvertItem,
  FulfilmentConvertRequest,
} from '@contactcentre-web/hooks/api/useFulfilmentConverter';
import InfoBox from '@contactcentre-web/common/InfoBox';
import Button from '@contactcentre-web/common/Button';
import MultiSelectDropdown, {
  MultiSelectDropdownOptions,
} from '@contactcentre-web/common/MultiSelectDropdown/MultiSelectDropdown';

import FulfilmentConverterConfirmation from '../FulfilmentConverterConfirmation/FulfilmentConverterConfirmation';

import messages from './messages';
import styles from './styles';

export const FORM_ID = 'FulfilmentConverterForm';
interface StateProps {
  initialValues: {
    products: Array<string>;
  };
}
export interface FulfilmentConverterFormProps extends StateProps {
  bookings: Array<Booking>;
  onSubmitForm: (data: FulfilmentConvertRequest) => void;
  alreadyConverted: boolean;
  isLoading: boolean;
  eligibility?: FulfilmentConvertEligibilityData;
}

interface Booking {
  id: string;
  bookingIndex: number;
  origin: string;
  destination: string;
  departureDay: string;
}

type SelectType = {
  label: string;
  value: string;
  selected: boolean;
};

export const FulfilmentConverterForm = ({
  handleSubmit,
  bookings,
  onSubmitForm,
  alreadyConverted,
  isLoading,
  eligibility,
}: FulfilmentConverterFormProps &
  InjectedFormProps<FulfilmentConvertData, FulfilmentConverterFormProps>) => {
  const { formatMessage } = useIntl();
  const [showConfirmation, setShowConfirmation] = useState(false);
  let selectOptions = [] as Array<MultiSelectDropdownOptions>;
  const [data, setData] = useState<FulfilmentConvertData>();

  selectOptions = bookings.reduce((acc: Array<MultiSelectDropdownOptions>, current: Booking) => {
    const { bookingIndex, origin, destination, departureDay } = current;

    if (
      eligibility?.fulfilmentConversionOptions?.find(
        (p) =>
          p.productId === current.id &&
          p.deliveryOptionsAvailable.some((x) => x.toLowerCase() === 'atockiosk')
      )
    ) {
      acc.push({
        text: formatMessage(messages.bookingLabel, {
          bookingIndex: bookingIndex + 1,
          from: origin,
          to: destination,
          departureDay: moment(departureDay).format('ll'),
        }),
        value: current.id,
        selected: false,
      });
    }
    return acc;
  }, []);

  const confirmModal = (data: FulfilmentConvertData) => {
    setShowConfirmation((prevState) => !prevState);
    setData(data);
  };

  const proceedWithRequest = () => {
    setShowConfirmation(!showConfirmation);
    if (data) {
      const product = data.products.reduce((acc: Array<FulfilmentConvertItem>, cur: string) => {
        acc.push({
          fulfilmentOption: 'atocKiosk',
          productId: cur,
        });
        return acc;
      }, []);
      const request = {
        url: eligibility?.link?.href,
        conversionRequests: product,
      } as FulfilmentConvertRequest;
      onSubmitForm(request);
    }
  };
  const handleOptionSelected = (option: SelectType, isChecked: boolean) => {
    const item = selectOptions.find((i) => i.value === option.value);
    if (item) {
      selectOptions = [
        ...selectOptions.filter((i) => i.value !== option.value),
        { ...item, selected: isChecked },
      ];
    }
  };

  return (
    <>
      <form
        id={FORM_ID}
        className={css(styles.container)}
        onSubmit={handleSubmit((data) => confirmModal(data))}
      >
        <Heading typeStyle="title2" as="h1" color="base">
          <FormattedMessage {...messages.refreshBooking} />
        </Heading>
        {alreadyConverted && (
          <InfoBox
            styleSheet={styles.alreadyRefreshedBox}
            message={<FormattedMessage {...messages.alreadyConverted} />}
          />
        )}
        <div className={css(styles.body)}>
          <div className={css(styles.selectContainer)}>
            <MultiSelectDropdown
              name="products"
              data-test="fulfilment-converter-bookingId"
              items={selectOptions}
              onOptionChange={handleOptionSelected}
              label={formatMessage(messages.booking)}
              noSelectedOptionsText={formatMessage(messages.selectBooking)}
              errors={{
                validationProductIdRequired: formatMessage(messages.bookingMustBeSelected),
              }}
            />
          </div>
          <InfoBox styleSheet={styles.info} message={<FormattedMessage {...messages.info} />} />
        </div>
        <Button
          type="submit"
          variant="primary"
          size="large"
          testId="FulfilmentConverterSubmit"
          styleSheet={styles.button}
          loading={isLoading}
        >
          <FormattedMessage {...messages.refreshBooking} />
        </Button>
      </form>
      <FulfilmentConverterConfirmation
        onProceedClick={() => proceedWithRequest()}
        onCloseClick={() => setShowConfirmation((prevState) => !prevState)}
        visible={showConfirmation}
        loading={isLoading}
      />
    </>
  );
};
export const validate = ({
  products,
}: FulfilmentConvertData): Record<string, string | undefined> => ({
  products: products && products.length > 0 ? undefined : 'validationUrlRequired',
});
const mapStateToProps = () => ({
  initialValues: {
    products: [] as Array<string>,
  },
});

export default connect(mapStateToProps)(
  reduxForm<FulfilmentConvertData, FulfilmentConverterFormProps>({
    form: FORM_ID,
    validate,
  })(FulfilmentConverterForm)
);
