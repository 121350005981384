import { createActions, handleActions, Action } from 'redux-actions';
import { all, takeLatest, put, call } from 'redux-saga/effects';

import request from '@contactcentre-web/utils/request';

const PREFIX = 'COJ_REDIRECT';

export const RECORD_AGENT = 'RECORD_AGENT';
export const RECORD_AGENT_SUCCESS = 'RECORD_AGENT_SUCCESS';
export const RECORD_AGENT_FAILED = 'RECORD_AGENT_FAILED';

export const RECORD_AGENT_STATE_NONE = 'RECORD_AGENT_STATE_NONE';
export const RECORD_AGENT_STATE_INPROGRESS = 'RECORD_AGENT_STATE_INPROGRESS';
export const RECORD_AGENT_STATE_SUCCESS = 'RECORD_AGENT_STATE_SUCCESS';

export const actions = createActions(
  {
    [RECORD_AGENT]: (customerId: string, orderReference: string) => ({
      customerId,
      orderReference,
    }),
    [RECORD_AGENT_SUCCESS]: (changedOrderReference: string) => ({ changedOrderReference }),
    [RECORD_AGENT_FAILED]: () => null,
  },
  { prefix: PREFIX }
);

export interface CojRedirectState {
  loadState: string;
  changedOrderReference?: string;
}

export const initialState = {
  loadState: RECORD_AGENT_STATE_NONE,
};

type ActionPayload = { changedOrderReference: string };

const reducer = handleActions<CojRedirectState, ActionPayload>(
  {
    [RECORD_AGENT]: (state) => ({
      ...state,
      loadState: RECORD_AGENT_STATE_INPROGRESS,
    }),
    [RECORD_AGENT_SUCCESS]: (state, { payload: { changedOrderReference } }) => ({
      ...state,
      changedOrderReference,
      loadState: RECORD_AGENT_STATE_SUCCESS,
    }),
    [RECORD_AGENT_FAILED]: (state) => ({
      ...state,
      loadState: RECORD_AGENT_STATE_NONE,
    }),
  },
  initialState,
  { prefix: PREFIX }
);

type RecordAgentResponse = {
  changedOrderReference: string;
};

export const recordAgentRequest = async (orderReference: string, bookingId: string) =>
  request<RecordAgentResponse>(
    `/api/orders/${orderReference}/bookings/${bookingId}/coj-processed`,
    {
      method: 'POST',
    }
  );

type RecordAgentSagaPayload = {
  customerId: string;
  orderReference: string;
};

export function* recordAgentSaga({
  payload: { customerId, orderReference },
}: Action<RecordAgentSagaPayload>) {
  try {
    const { changedOrderReference }: RecordAgentResponse = yield call(
      recordAgentRequest,
      customerId,
      orderReference
    );
    yield put(actions.recordAgentSuccess(changedOrderReference));
  } catch (error) {
    yield put(actions.recordAgentFailed(error));
  }
}

export function* saga() {
  yield all([takeLatest(`${PREFIX}/${RECORD_AGENT}`, recordAgentSaga)]);
}

export default reducer;
