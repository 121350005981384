import { defineMessages } from 'react-intl';

export default defineMessages({
  batchNumber: {
    id: 'PaperTicketForm.batchNumber',
    defaultMessage: 'Batch no. {batchNumber}',
  },
  sequenceNumber: {
    id: 'PaperTicketForm.sequenceNumber',
    defaultMessage: 'Sequence no. {sequenceNumber}',
  },
  transactionId: {
    id: 'PaperTicketForm.transactionId',
    defaultMessage: 'Transaction ID',
  },
  from: {
    id: 'PaperTicketForm.from',
    defaultMessage: 'From',
  },
  to: {
    id: 'PaperTicketForm.to',
    defaultMessage: 'To',
  },
  price: {
    id: 'PaperTicketForm.price',
    defaultMessage: 'Price',
  },
  validUntil: {
    id: 'PaperTicketForm.validUntil',
    defaultMessage: 'Valid until',
  },
  ticketNumber: {
    id: 'PaperTicketForm.ticketNumber',
    defaultMessage: 'Ticket number',
  },

  ticketLength: {
    id: 'PaperTicketForm.ticketLength',
    defaultMessage: '15 Digits',
  },

  validate: {
    id: 'PaperTicketForm.validate',
    defaultMessage: 'Validate',
  },
  transactionIdRequired: {
    id: 'PaperTicketForm.transactionIdRequired',
    defaultMessage: 'Transaction ID is required',
  },
  fromStationNameRequired: {
    id: 'PaperTicketForm.fromStationNameRequired',
    defaultMessage: 'From is required',
  },
  toStationNameRequired: {
    id: 'PaperTicketForm.toStationNameRequired',
    defaultMessage: 'To is required',
  },
  priceRequired: {
    id: 'PaperTicketForm.priceRequired',
    defaultMessage: 'Price is required',
  },
  ticketNumberRequired: {
    id: 'PaperTicketForm.ticketNumberRequired',
    defaultMessage: 'Ticket number is required',
  },
  priceInvalid: {
    id: 'PaperTicketForm.priceInvalid',
    defaultMessage: 'Price must have both value and currency code',
  },
  validationUnsuccessful: {
    id: 'PaperTicketForm.validationUnsuccessful',
    defaultMessage: 'Validation was unsuccessful, please try again',
  },
  unknownError: {
    id: 'PaperTicketForm.unknownError',
    defaultMessage: 'Something went wrong, please try again',
  },
});
