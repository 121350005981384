import { defineMessages } from 'react-intl';

export default defineMessages({
  reserved: {
    id: 'BikeReservation.reserved',
    defaultMessage: '{quantity, plural, one {Reserved} other {# - Reserved}}',
  },
  reservationNotRequired: {
    id: 'BikeReservation.reservationNotRequired',
    defaultMessage: 'Reservation not required',
  },
  reservationRequired: {
    id: 'BikeReservation.reservationRequired',
    defaultMessage: 'Reservation required',
  },
  reservationNotAllowed: {
    id: 'BikeReservation.reservationNotAllowed',
    defaultMessage: 'Reservation not allowed',
  },
});
