import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { Heading, Paragraph } from '@trainline/depot-web';

import messages from './messages';
import styles from './styles';

const ResendEmailEligibilityFailure = () => (
  <div>
    <div className={css(styles.title)}>
      <Heading typeStyle="title3" as="h1" color="base">
        <FormattedMessage {...messages.confirmationEmailNotFound} />
      </Heading>
    </div>
    <Paragraph typeStyle="body" as="p" color="base" fontWeight="regular">
      <FormattedMessage {...messages.message} />
    </Paragraph>
  </div>
);

export default ResendEmailEligibilityFailure;
