import type { Card } from './Railcard';

export const DEFAULT_COLOR = '#3D596A';

type DataMapper = {
  [key: string]: {
    shortName: string;
    color: string;
  };
};

const cardTypeDataMapper: DataMapper = {
  'urn:trainline:cff:card:AbonnementGeneral': {
    shortName: 'Generalabonnement (GA)',
    color: '#254693',
  },
  'urn:trainline:cff:card:AbonnementGeneral1st': {
    shortName: 'Generalabonnement 1st Class (GA)',
    color: '#254693',
  },
  'urn:trainline:cff:card:AbonnementGeneral1stRailPlus': {
    shortName: 'Generalabonnement 1st Class (GA) RP',
    color: '#254693',
  },
  'urn:trainline:cff:card:AbonnementGeneral2nd': {
    shortName: 'Generalabonnement 2nd Class (GA)',
    color: '#254693',
  },
  'urn:trainline:cff:card:AbonnementGeneral2ndRailPlus': {
    shortName: 'Generalabonnement 2nd Class (GA) RP',
    color: '#254693',
  },
  'urn:trainline:cff:card:AbonnementGeneralRailPlus': {
    shortName: 'Generalabonnement (GA) RP',
    color: '#254693',
  },
  'urn:trainline:cff:card:DemiTarif': {
    shortName: 'Half-Fare',
    color: '#254693',
  },
  'urn:trainline:cff:card:DemiTarifRailPlus': {
    shortName: 'Half-Fare RP',
    color: '#254693',
  },
  'urn:trainline:db:card:BahnBonusCard': {
    shortName: 'BB',
    color: '#6d9c3c',
  },
  'urn:trainline:db:card:BahnCard25BusinessFirstClass': {
    shortName: 'BB25 1st Class',
    color: '#6d9c3c',
  },
  'urn:trainline:db:card:BahnCard25BusinessSecondClass': {
    shortName: 'BB25 2nd Class',
    color: '#6d9c3c',
  },
  'urn:trainline:db:card:BahnCard25FirstClass': {
    shortName: 'BC25 1st Class',
    color: '#6d9c3c',
  },
  'urn:trainline:db:card:BahnCard25SecondClass': {
    shortName: 'BC25 2nd Class',
    color: '#6d9c3c',
  },
  'urn:trainline:db:card:BahnCard50BusinessFirstClass': {
    shortName: 'BB50 1st Class',
    color: '#6d9c3c',
  },
  'urn:trainline:db:card:BahnCard50BusinessSecondClass': {
    shortName: 'BB50 2nd Class',
    color: '#6d9c3c',
  },
  'urn:trainline:db:card:BahnCard50FirstClass': {
    shortName: 'BC50 1st Class',
    color: '#6d9c3c',
  },
  'urn:trainline:db:card:BahnCard50SecondClass': {
    shortName: 'BC50 2nd Class',
    color: '#6d9c3c',
  },
  'urn:trainline:eurostar:card:FrequentTraveller': {
    shortName: 'Club Eurostar',
    color: '#3d596a',
  },
  'urn:trainline:ns:card:Voordeelurenabonnement': {
    shortName: 'Voordeelurenabo.',
    color: '#f7d631',
  },
  'urn:trainline:ns:card:VoordeelurenabonnementRailPlus': {
    shortName: 'Voordeelurenabo. RP',
    color: '#f7d631',
  },
  'urn:trainline:ntv:card:ItaloPiu': {
    shortName: 'Italo Più',
    color: '#8c000c',
  },
  'urn:trainline:obb:card:VORTEILScard': {
    shortName: 'VORTEILScard',
    color: '#ce1a35',
  },
  'urn:trainline:obb:card:FamilienOberoesterreich': {
    shortName: 'Familienkarte des Landes Oberösterreich',
    color: '#ce1a35',
  },
  'urn:trainline:obb:card:FamilienSalzburg': {
    shortName: 'Salzburger Familienpass',
    color: '#ce1a35',
  },
  'urn:trainline:obb:card:FamilienSteir': {
    shortName: 'Steir. Familienpass',
    color: '#ce1a35',
  },
  'urn:trainline:obb:card:FamilienTirol': {
    shortName: 'Euregio FamilyPass Tirol',
    color: '#ce1a35',
  },
  'urn:trainline:obb:card:FamilienVorarlberg': {
    shortName: 'Vorarlberger Familienpass',
    color: '#ce1a35',
  },
  'urn:trainline:obb:card:KaerntenCard': {
    shortName: 'Kärnten Card',
    color: '#ce1a35',
  },
  'urn:trainline:obb:card:StadtverkehrGraz': {
    shortName: 'Ticket Stadtverkehr Graz',
    color: '#ce1a35',
  },
  'urn:trainline:obb:card:StadtverkehrInnsbruck': {
    shortName: 'Ticket Stadtverkehr Innsbruck',
    color: '#ce1a35',
  },
  'urn:trainline:obb:card:StadtverkehrLinz': {
    shortName: 'Ticket Stadtverkehr Linz',
    color: '#ce1a35',
  },
  'urn:trainline:obb:card:StadtverkehrSalzburg': {
    shortName: 'Ticket Stadtverkehr Salzburg',
    color: '#ce1a35',
  },
  'urn:trainline:obb:card:StadtverkehrWien': {
    shortName: 'Ticket Stadtverkehr Wien (Kernzone)',
    color: '#ce1a35',
  },
  'urn:trainline:obb:card:VORTEILScard66': {
    shortName: 'VORTEILScard 66',
    color: '#ce1a35',
  },
  'urn:trainline:obb:card:VORTEILScardClassic': {
    shortName: 'VORTEILScard Classic',
    color: '#ce1a35',
  },
  'urn:trainline:obb:card:VORTEILScardFamily': {
    shortName: 'VORTEILScard Family',
    color: '#ce1a35',
  },
  'urn:trainline:obb:card:VORTEILScardJugen': {
    shortName: 'VORTEILScard Jugend',
    color: '#ce1a35',
  },
  'urn:trainline:obb:card:VORTEILScardSenior': {
    shortName: 'VORTEILScard Senior',
    color: '#ce1a35',
  },
  'urn:trainline:renfe:card:CarneJoven': {
    shortName: 'Carné Joven',
    color: '#c9091e',
  },
  'urn:trainline:renfe:card:TarjetaDorada': {
    shortName: 'Dorada',
    color: '#a17338',
  },
  'urn:trainline:renfe:card:TarjetaMasRenfe': {
    shortName: '+Renfe',
    color: '#8c006b',
  },
  'urn:trainline:renfe:card:TarjetaMasRenfeJoven50': {
    shortName: '+Renfe Joven',
    color: '#c9091e',
  },
  'urn:trainline:renfe:card:TarjetaMasRenfeOro': {
    shortName: '+Renfe Oro',
    color: '#bea34c',
  },
  'urn:trainline:renfe:card:TarjetaMasRenfePlata': {
    shortName: '+Renfe Plata',
    color: '#647479',
  },
  'urn:trainline:renfe:card:TarjetaMasRenfePremium': {
    shortName: '+Renfe Premium',
    color: '#647479',
  },
  'urn:trainline:sncf:card:AbonnementForfaitFirstClass': {
    shortName: 'Forfait 1',
    color: '#488A93',
  },
  'urn:trainline:sncf:card:AbonnementForfaitSecondClass': {
    shortName: 'Forfait 2',
    color: '#488A93',
  },
  'urn:trainline:sncf:card:AbonnementFrequence25FirstClass': {
    shortName: 'Fréq. 30% 1',
    color: '#7f2789',
  },
  'urn:trainline:sncf:card:AbonnementFrequence25SecondClass': {
    shortName: 'Fréq. 30% 2',
    color: '#7f2789',
  },
  'urn:trainline:sncf:card:AbonnementFrequenceFirstClass': {
    shortName: 'Fréq. 1',
    color: '#95416f',
  },
  'urn:trainline:sncf:card:AbonnementFrequenceSecondClass': {
    shortName: 'Fréq. 2',
    color: '#95416f',
  },
  'urn:trainline:sncf:card:AuvergneRhoneAlpesCarteIllicoLiberte': {
    shortName: 'illico LIBERTÉ',
    color: '#da0011',
  },
  'urn:trainline:sncf:card:AuvergneRhoneAlpesCarteIllicoLiberteJeunes': {
    shortName: 'illico JEUNES',
    color: '#ea6707',
  },
  'urn:trainline:sncf:card:AuvergneRhoneAlpesCarteIllicoMobilite': {
    shortName: 'illico MOBILITÉ',
    color: '#d25397',
  },
  'urn:trainline:sncf:card:AuvergneRhoneAlpesCarteIllicoSolidaire': {
    shortName: 'illico SOLIDAIRE',
    color: '#339e2a',
  },
  'urn:trainline:sncf:card:BourgogneFrancheComteCarte26Plus': {
    shortName: '26+',
    color: '#11639c',
  },
  'urn:trainline:sncf:card:BourgogneFrancheComteCarteTarifReduitSolidaire': {
    shortName: 'Tarif réduit solidaire',
    color: '#b55f93',
  },
  'urn:trainline:sncf:card:BretagneCarteActuel': {
    shortName: 'Actuël',
    color: '#ea9923',
  },
  'urn:trainline:sncf:card:Carte1225': {
    shortName: 'Jeune',
    color: '#fb8e63',
  },
  'urn:trainline:sncf:card:CarteAgentDeVoyages': {
    shortName: 'AGV',
    color: '#000000',
  },
  'urn:trainline:sncf:card:CarteEnfantPlus': {
    shortName: 'Enfant+',
    color: '#6c255f',
  },
  'urn:trainline:sncf:card:CarteEscapades': {
    shortName: 'Week-end',
    color: '#558fba',
  },
  'urn:trainline:sncf:card:CarteFamilleMilitaire': {
    shortName: 'Fam. Mil.',
    color: '#1f6594',
  },
  'urn:trainline:sncf:card:CarteFamilleNombreuse30': {
    shortName: 'Fam. 30%',
    color: '#1f6594',
  },
  'urn:trainline:sncf:card:CarteFamilleNombreuse40': {
    shortName: 'Fam. 40%',
    color: '#1f6594',
  },
  'urn:trainline:sncf:card:CarteFamilleNombreuse50': {
    shortName: 'Fam. 50%',
    color: '#1f6594',
  },
  'urn:trainline:sncf:card:CarteFamilleNombreuse75': {
    shortName: 'Fam. 75%',
    color: '#1f6594',
  },
  'urn:trainline:sncf:card:CarteGrandVoyageur': {
    shortName: 'GV',
    color: '#836a64',
  },
  'urn:trainline:sncf:card:CarteGrandVoyageurLeClub': {
    shortName: 'GV Le Club',
    color: '#836a64',
  },
  'urn:trainline:sncf:card:CarteGrandVoyageurPlus': {
    shortName: 'GV Plus',
    color: '#836a64',
  },
  'urn:trainline:sncf:card:CarteMilitaireFirstClass': {
    shortName: 'Mil. 1',
    color: '#667119',
  },
  'urn:trainline:sncf:card:CarteMilitaireSecondClass': {
    shortName: 'Mil. 2',
    color: '#667119',
  },
  'urn:trainline:sncf:card:CarteSenior': {
    shortName: 'Senior+',
    color: '#9e3a83',
  },
  'urn:trainline:sncf:card:CarteVoyageur': {
    shortName: 'Voyageur',
    color: '#836a64',
  },
  'urn:trainline:sncf:card:CentreValDeLoireCarteLoisirys': {
    shortName: 'Loisirys',
    color: '#587d0b',
  },
  'urn:trainline:sncf:card:CentreValDeLoireCarteTERApprenti': {
    shortName: 'TER Apprenti',
    color: '#bd093e',
  },
  'urn:trainline:sncf:card:CentreValDeLoireCarteTERBacPlus': {
    shortName: 'TER BAC+',
    color: '#db0045',
  },
  'urn:trainline:sncf:card:GrandEstCartePresto': {
    shortName: 'Presto',
    color: '#2f86cd',
  },
  'urn:trainline:sncf:card:GrandEstCartePrimo': {
    shortName: 'Primo',
    color: '#6A7C00',
  },
  'urn:trainline:sncf:card:GrandEstCarteSolidaire': {
    shortName: 'Solidaire',
    color: '#de6765',
  },
  'urn:trainline:sncf:card:HappyCard': {
    shortName: 'TGVmax',
    color: '#6A7C00',
  },
  'urn:trainline:sncf:card:HautsDeFranceCartePassEvasion': {
    shortName: "Pass'Évasion",
    color: '#de0072',
  },
  'urn:trainline:sncf:card:NormandieCarteTempoMoins26': {
    shortName: 'Tempo -26',
    color: '#e94b24',
  },
  'urn:trainline:sncf:card:NormandieCarteTempoPlus26': {
    shortName: 'Tempo +26',
    color: '#e94b24',
  },
  'urn:trainline:sncf:card:NouvelleAquitaineAbonnementInterneEtudiant': {
    shortName: 'Interne Étudiant',
    color: '#f38e41',
  },
  'urn:trainline:sncf:card:NouvelleAquitaineAbonnementModalis': {
    shortName: 'Modalis',
    color: '#221a71',
  },
  'urn:trainline:sncf:card:NouvelleAquitaineAbonnementPassAbonne': {
    shortName: 'Pass Abonné',
    color: '#9b1723',
  },
  'urn:trainline:sncf:card:NouvelleAquitaineAbonnementPasseo': {
    shortName: 'Passéo',
    color: '#6A7C00',
  },
  'urn:trainline:sncf:card:NouvelleAquitaineAbonnementScolaire': {
    shortName: 'Scolaire',
    color: '#f0436d',
  },
  'urn:trainline:sncf:card:NouvelleAquitaineCarteAvantagesEmploi': {
    shortName: 'Avantages Emploi',
    color: '#34b267',
  },
  'urn:trainline:sncf:card:NouvelleAquitaineCarteAvantagesPlus': {
    shortName: 'Carte +',
    color: '#9b1723',
  },
  'urn:trainline:sncf:card:NouvelleAquitaineCarteTremplin': {
    shortName: 'Tremplin',
    color: '#d9322b',
  },
  'urn:trainline:sncf:card:OccitanieCarteLibertio': {
    shortName: 'LibertiO’',
    color: '#d4326c',
  },
  'urn:trainline:sncf:card:OccitanieCarteLibertioJeunes': {
    shortName: 'LibertiO’ Jeunes',
    color: '#aa0f1f',
  },
  'urn:trainline:sncf:card:PaysDeLaLoireAbonnementForfaitTuttiMoins26': {
    shortName: 'Forfait tutti -26',
    color: '#53b2ab',
  },
  'urn:trainline:sncf:card:PaysDeLaLoireAbonnementForfaitTuttiPlus26': {
    shortName: 'Forfait tutti',
    color: '#2d91a4',
  },
  'urn:trainline:sncf:card:PaysDeLaLoireCarteMezzoMoins26': {
    shortName: 'mezzo-26',
    color: '#73b532',
  },
  'urn:trainline:sncf:card:PaysDeLaLoireCarteMezzoPlus26': {
    shortName: 'mezzo',
    color: '#2d91a4',
  },
  'urn:trainline:sncf:card:PaysDeLaLoireCarteMobi': {
    shortName: 'mobi',
    color: '#6f1b5d',
  },
  'urn:trainline:sncf:card:ProvenceAlpesCoteDAzurCarteZou5075Moins26': {
    shortName: 'ZOU! -26',
    color: '#cf222b',
  },
  'urn:trainline:sncf:card:ProvenceAlpesCoteDAzurCarteZou5075Plus26': {
    shortName: 'ZOU!',
    color: '#cf222b',
  },
  'urn:trainline:sncf:card:ProvenceAlpesCoteDAzurCarteZouEtudes': {
    shortName: 'ZOU! Études',
    color: '#cf222b',
  },
  'urn:trainline:thalys:card:TheCard': {
    shortName: 'TheCard',
    color: '#2c2a2f',
  },
  'urn:trainline:thalys:card:ThePassBusiness': {
    shortName: 'Frequent P.',
    color: '#2c2a2f',
  },
  'urn:trainline:thalys:card:ThePassPremium': {
    shortName: 'Premium P.',
    color: '#2c2a2f',
  },
  'urn:trainline:thalys:card:ThePassWeekEnd': {
    shortName: 'ThePass WE',
    color: '#2c2a2f',
  },
  'urn:trainline:trenitalia:card:Carnet3ViaggiAV': {
    shortName: 'Carnet 3 AV',
    color: '#cc1931',
  },
  'urn:trainline:trenitalia:card:Carnet5ViaggiTH': {
    shortName: 'Carnet 5 TH',
    color: '#fc3559',
  },
  'urn:trainline:trenitalia:card:Carnet10ViaggiAV': {
    shortName: 'Carnet 10 AV',
    color: '#cc1931',
  },
  'urn:trainline:trenitalia:card:Carnet10ViaggiAVBiz': {
    shortName: '10 AV Biz',
    color: '#cc1931',
  },
  'urn:trainline:trenitalia:card:Carnet10ViaggiFB': {
    shortName: 'Carnet 10 FB',
    color: '#757575',
  },
  'urn:trainline:trenitalia:card:Carnet10ViaggiIC': {
    shortName: 'Carnet 10 IC',
    color: '#1b72c4',
  },
  'urn:trainline:trenitalia:card:Carnet30ViaggiAVBiz': {
    shortName: '30 AV Biz',
    color: '#cc1931',
  },
  'urn:trainline:trenitalia:card:Carnet30ViaggiFBBiz': {
    shortName: '30 FB Biz',
    color: '#757575',
  },
  'urn:trainline:trenitalia:card:CartaArgento': {
    shortName: 'Argento',
    color: '#647479',
  },
  'urn:trainline:trenitalia:card:CartaFreccia': {
    shortName: 'Freccia',
    color: '#c53131',
  },
  'urn:trainline:trenitalia:card:CartaVerde': {
    shortName: 'Verde',
    color: '#1f8927',
  },
};

const carrierCardColorMap: { [key: string]: string } = {
  db: '#7FA662',
  cff: '#4E4898',
  obb: '#D53B4A',
  ns: '#FFC975',
  ntv: '#8C000C',
  thalys: '#3E4149',
};

export const getCardTypeDataByCode = ({ code, name }: Card) =>
  cardTypeDataMapper[code] || {
    color: (code && carrierCardColorMap[code.split(':')[2]]) || DEFAULT_COLOR,
    shortName: name || code,
  };

export default getCardTypeDataByCode;
