import React from 'react';

import ManualEntryConsoleAddForm from './components/ManualEntryConsoleAddForm/ManualEntryConsoleAddForm';
import ManualEntryConsoleAddHeader from './components/ManualEntryConsoleAddHeader/ManualEntryConsoleAddHeader';

const ManualEntryConsoleAdd = () => (
  <>
    <ManualEntryConsoleAddHeader />
    <ManualEntryConsoleAddForm />
  </>
);

export default ManualEntryConsoleAdd;
