import { StyleSheet } from 'aphrodite/no-important';
import { spacers, borderRadius, palettes } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  card: {
    borderRadius: borderRadius.dp_r4,
    backgroundColor: palettes.dp_app_backdrop_base,
    border: `1px solid ${palettes.dp_app_border_base}`,
    margin: `${spacers.dp_s24} 0`,
    overflow: 'hidden',
  },
});
