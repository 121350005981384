import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import localStorage, { LocalStorageKeys } from '@contactcentre-web/utils/localStorage';
import {
  ClaimStatus,
  ClaimFilter,
  useManualEntryConsoleApprovalQueue,
} from '@contactcentre-web/hooks/api/useManualEntryConsole';

import ManualEntryConsoleHeader from './components/ManualEntryConsoleHeader/ManualEntryConsoleHeader';
import ManualEntryConsoleQueueList from './components/ManualEntryConsoleQueueList/ManualEntryConsoleQueueList';

interface ManualEntryConsoleSettings {
  productTypesFilter?: ClaimFilter[];
  reasonCodeFilter?: string;
}

const ManualEntryConsole = () => {
  const NUMBER_OF_ITEMS_PER_PAGE = 50;
  const location = useLocation();
  const history = useHistory();
  const initialQueryStringClaimStatus =
    (new URLSearchParams(location.search).get('status') as ClaimStatus) ?? ClaimStatus.Pending;
  const [claimStatus, setClaimStatus] = useState(initialQueryStringClaimStatus);

  const initialQueryStringProductTypesFilter =
    (new URLSearchParams(location.search).getAll('productTypes') as ClaimFilter[]) ||
    ([] as ClaimFilter[]);
  const initialQueryStringReasonCodeFilter =
    new URLSearchParams(location.search).get('reasonCode') || undefined;
  const [productTypesFilter, setProductTypesFilter] = useState(
    initialQueryStringProductTypesFilter
  );
  const [reasonCodeFilter, setReasonCodeFilter] = useState<string | undefined>(
    initialQueryStringReasonCodeFilter
  );

  const loadSettingsFromLocalStorage = () => {
    const settings = localStorage.get(
      LocalStorageKeys.MANUAL_ENTRY_CONSOLE_SETTINGS
    ) as ManualEntryConsoleSettings;
    if (productTypesFilter.length === 0 && reasonCodeFilter === undefined) {
      setProductTypesFilter(settings?.productTypesFilter || []);
      setReasonCodeFilter(settings?.reasonCodeFilter);
    }
  };
  useEffect(loadSettingsFromLocalStorage, []);

  const { data, isLoading, isError, fetchNextPage } = useManualEntryConsoleApprovalQueue(
    NUMBER_OF_ITEMS_PER_PAGE,
    claimStatus,
    claimStatus == ClaimStatus.Approved ? [] : productTypesFilter,
    claimStatus == ClaimStatus.Approved ? undefined : reasonCodeFilter
  );
  const { pages = [] } = data || {};
  const allItems = pages.flatMap(({ items }) => items);
  const {
    pageSize = 0,
    totalCount = 0,
    page = 0,
  } = pages.length > 0 ? pages[pages.length - 1] : {};

  const updateProductTypesFilter = (value: ClaimFilter) => {
    const newValue = productTypesFilter.some((p) => p === value)
      ? productTypesFilter.filter((p) => p !== value)
      : [value, ...productTypesFilter];

    setProductTypesFilter(newValue);
  };

  const updateQueryString = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('status', claimStatus);

    searchParams.delete('productTypes');
    searchParams.delete('reasonCode');

    if (claimStatus != ClaimStatus.Approved) {
      if (reasonCodeFilter) {
        searchParams.set('reasonCode', reasonCodeFilter);
      }

      for (const i of productTypesFilter) searchParams.append('productTypes', i);
    }

    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };
  useEffect(updateQueryString, [claimStatus, reasonCodeFilter, productTypesFilter]);

  const updateLocalStorageSettings = () => {
    localStorage.set(LocalStorageKeys.MANUAL_ENTRY_CONSOLE_SETTINGS, {
      productTypesFilter,
      reasonCodeFilter,
    });
  };
  useEffect(updateLocalStorageSettings, [productTypesFilter, reasonCodeFilter]);

  const hasFiltersApplied = productTypesFilter.length > 0 || reasonCodeFilter !== undefined;
  return (
    <>
      <ManualEntryConsoleHeader
        claimStatus={claimStatus}
        productTypesFilter={productTypesFilter}
        reasonCodeFilter={reasonCodeFilter}
        totalCount={totalCount}
        isError={isError}
        claimStatusChanges={setClaimStatus}
        productTypesFilterChanges={updateProductTypesFilter}
        reasonCodeFilterChanges={setReasonCodeFilter}
      />
      <ManualEntryConsoleQueueList
        manualEntries={allItems}
        claimStatus={claimStatus}
        totalCount={totalCount}
        pageSize={pageSize}
        page={page}
        isLoading={isLoading}
        isError={isError}
        hasFiltersApplied={hasFiltersApplied}
        loadNextPage={fetchNextPage}
      />
    </>
  );
};
export default ManualEntryConsole;
