import React, { CSSProperties } from 'react';
import { css } from 'aphrodite/no-important';
import { palettes } from '@trainline/depot-web/css/themes/trainline';

import styles from './styles';

interface Props {
  styleSheet?: { container: CSSProperties };
  size?: number;
  borderWidth?: number;
  borderColor?: string;
  borderTopColor?: string;
}

export const Loader = ({
  styleSheet,
  size = 100,
  borderWidth = 10,
  borderColor = palettes.dp_brand_primary_light,
  borderTopColor = palettes.dp_brand_primary_core,
}: Props) => (
  <div className={css(styles.container, styleSheet && styleSheet.container)} data-testid="loader">
    <div
      className={css(styles.spinner)}
      style={{
        width: size,
        height: size,
        borderWidth,
        borderColor,
        borderTopColor,
      }}
    />
  </div>
);

export default Loader;
