import React, { FunctionComponent } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { css } from 'aphrodite/no-important';
import dayjs from 'dayjs';

import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency/FormattedCurrency';
import { getOpenReturnValidityPeriod } from '@contactcentre-web/utils/journeys';

import { COJBooking } from '../../module';

import messages from './messages';
import styles from './styles';

interface Props {
  bookingNo: number;
  booking: COJBooking;
}

const BookingHeader: FunctionComponent<Props> = ({
  bookingNo,
  booking: {
    origin,
    destination,
    isReturn,
    outDate,
    returnDate,
    deliveryMethod,
    fulfilmentStatus,
    passengerByType,
    transactionTotalPrice,
    inventoryTotalPrice,
    journeys,
    vendor,
  },
}) => {
  const formattedValidityPeriod = getOpenReturnValidityPeriod(journeys, outDate, vendor || '');
  const isOpenReturn = isReturn && !returnDate;
  return (
    <div className={css(styles.bookingHeader)}>
      <div className={css(styles.col)}>
        <h4 className={css(styles.title)}>
          <FormattedMessage {...messages.bookingTitle} values={{ bookingNo }} />
        </h4>
        <div>
          <FormattedMessage
            {...messages.origin}
            values={{
              origin: <strong>{origin}</strong>,
            }}
          />
        </div>
        <div>
          <FormattedMessage
            {...messages.destination}
            values={{
              isReturn,
              destination,
            }}
          >
            {(txt) => <strong>{txt}</strong>}
          </FormattedMessage>
        </div>
      </div>
      <div className={css(styles.col)}>
        <h4 className={css(styles.title)}>
          <FormattedMessage {...messages.datesTitle} />
        </h4>
        <div>
          <FormattedMessage
            {...messages.label}
            values={{
              label: (
                <strong>
                  <FormattedMessage {...messages.depart} />
                </strong>
              ),
              value: (
                <FormattedDate
                  value={outDate}
                  weekday="short"
                  day="2-digit"
                  month="short"
                  year="numeric"
                />
              ),
            }}
          />
        </div>
        {isReturn && !isOpenReturn && (
          <div>
            <FormattedMessage
              {...messages.label}
              values={{
                label: (
                  <strong>
                    <FormattedMessage {...messages.return} />
                  </strong>
                ),
                value: (
                  <FormattedDate
                    value={returnDate}
                    weekday="short"
                    day="2-digit"
                    month="short"
                    year="numeric"
                  />
                ),
              }}
            />
          </div>
        )}
        {/* Open return with a ValidityPeriod.  if its a valid date (ATOC) , then will display as valid until. otherwise display as duration*/}
        {isOpenReturn && formattedValidityPeriod && (
          <div>
            <FormattedMessage
              {...messages.label}
              values={{
                label: (
                  <strong>
                    <FormattedMessage {...messages.return} />
                  </strong>
                ),
                value: dayjs(formattedValidityPeriod).isValid() ? (
                  <FormattedMessage
                    {...messages.returnIsValidUntil}
                    values={{
                      period: (
                        <FormattedDate
                          value={formattedValidityPeriod}
                          weekday="short"
                          day="2-digit"
                          month="short"
                          year="numeric"
                        />
                      ),
                    }}
                  />
                ) : (
                  <FormattedMessage
                    {...messages.returnIsValidFor}
                    values={{
                      period: formattedValidityPeriod,
                    }}
                  />
                ),
              }}
            />
          </div>
        )}
      </div>
      <div className={css(styles.col)}>
        <h4 className={css(styles.title)}>
          <FormattedMessage {...messages.fulfilmentTitle} />
        </h4>
        <div>{deliveryMethod}</div>
        <div>{fulfilmentStatus}</div>
      </div>
      <div>
        <h4 className={css(styles.title)}>
          <FormattedMessage {...messages.passengersTitle} />
        </h4>
        <ul className={css(styles.passengerList)}>
          {Object.keys(passengerByType).map((type) => (
            <li key={type}>
              <FormattedMessage
                {...messages.passengerTypes}
                values={{ passengerType: type.toLowerCase(), count: passengerByType[type] }}
              />
            </li>
          ))}
        </ul>
      </div>
      <div className={css(styles.col)}>
        <h4 className={css(styles.title)}>
          <FormattedMessage {...messages.priceTitle} />
        </h4>
        <div className={css(styles.transactionPrice)}>
          <FormattedCurrency {...transactionTotalPrice} />
        </div>
        <div className={css(styles.inventoryPrice)}>
          {transactionTotalPrice.currencyCode !== inventoryTotalPrice.currencyCode && (
            <FormattedCurrency {...inventoryTotalPrice} />
          )}
        </div>
      </div>
    </div>
  );
};
export default BookingHeader;
