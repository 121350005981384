import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'SeasonRefundModal.title',
    defaultMessage: 'Are you sure you want to request a refund?',
  },
  description: {
    id: 'SeasonRefundModal.description',
    defaultMessage:
      'This action cannot be undone. If needed, please raise escalation ticket on FreshDesk to season team using the correct queue.',
  },
  confirmRefund: {
    id: 'SeasonRefundModal.confirmRefund',
    defaultMessage: 'Confirm refund',
  },
  cancelRefund: {
    id: 'SeasonRefundModal.cancelRefund',
    defaultMessage: 'Cancel',
  },
  refundError: {
    id: 'SeasonRefundModal.refundError',
    defaultMessage: `There was an issue refunding the customer's season order, please try again.`,
  },
});
