import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  submitButton: {
    width: '300px',
    marginTop: spacers.dp_s24,
  },
});
