import React from 'react';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';

import { LOCALE_CODES } from '@contactcentre-web/localisation/locales';
import { getLanguageName } from '@contactcentre-web/localisation/localisation';

interface Props {
  lang: LOCALE_CODES;
  inLang: LOCALE_CODES;
  styleSheet?: StyleDeclarationValue;
}

const FormattedLanguage = ({ lang, inLang, styleSheet }: Props) => (
  <span className={css(styleSheet)}>{getLanguageName(lang, inLang)}</span>
);

export default FormattedLanguage;
