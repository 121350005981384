import React, { FunctionComponent, useState } from 'react';
import { css } from 'aphrodite/no-important';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { StatusMessage } from '@trainline/depot-web';

import TextField from '@contactcentre-web/common/TextField';
import Button from '@contactcentre-web/common/Button';

import messages from './messages';
import styles from './styles';

export const SSO_FORM_ID = 'ssoLogin';

export interface SSOData {
  company: string;
}

export interface Props {
  onViaUsernameClick: () => void;
  isNewLoginFlow: boolean;
}

export const SSOForm: FunctionComponent<Props & InjectedFormProps<SSOData, Props>> = ({
  onViaUsernameClick,
  submitFailed,
  handleSubmit,
  isNewLoginFlow,
}) => {
  const [errorLogin, setErrorLogin] = useState('');

  React.useEffect(() => {
    const urlParameters = new URLSearchParams(window.location.search);
    const errorLogin = urlParameters.get('error');
    errorLogin && setErrorLogin(errorLogin);
  }, []);

  const messageKey = `SSO${errorLogin}` as keyof typeof messages;

  const handleClick = () => {
    if (isNewLoginFlow) {
      window.open(`/sso/internal`, '_self');
    }

    onViaUsernameClick();
  };

  return (
    <form id={SSO_FORM_ID} className={css(styles.form)} onSubmit={handleSubmit} autoComplete="off">
      {errorLogin && (
        <StatusMessage status="negative">
          {
            <FormattedMessage
              {...messages[messageKey]}
              values={{
                br: <br />,
              }}
            />
          }
        </StatusMessage>
      )}
      <TextField
        id={`${SSO_FORM_ID}-company`}
        label={<FormattedMessage {...messages.companyLabel} />}
        name="company"
        inputError={submitFailed}
        autoComplete="off"
        messages={messages}
        autoFocus
      />
      <Button type="submit" variant="primary" size="medium" fullWidth>
        <FormattedMessage {...messages.signIn} />
      </Button>
      <Button
        testId="signIn-via-username"
        variant="tertiary"
        size="medium"
        fullWidth
        onClick={handleClick}
      >
        <FormattedMessage {...messages.redirectLoginForm} />
      </Button>
    </form>
  );
};

export const validate = ({ company }: SSOData) => ({
  company: !company ? 'companyIsRequired' : undefined,
});

export default reduxForm<SSOData, Props>({
  form: SSO_FORM_ID,
  touchOnBlur: false,
  validate,
})(SSOForm);
