import { defineMessages } from 'react-intl';

import { GENERAL_ERROR_MSG } from './errorHandleMiddleware';

export default defineMessages({
  [GENERAL_ERROR_MSG]: {
    id: 'errorHandleMiddleware.GENERAL_ERROR_MSG',
    defaultMessage: 'Sorry, something went wrong: Please try again later',
  },
  conversationId: {
    id: 'errorHandleMiddleware.conversationId',
    defaultMessage: 'Conversation ID: {conversationId}',
  },
  delayRepayClaimError: {
    id: 'errorHandleMiddleware.delayRepayClaimError',
    defaultMessage:
      'There are issues on getting Delay Repay status. Please check with customer experience team',
  },
  transactionSummaryLoadError: {
    id: 'errorHandleMiddleware.transactionSummaryLoadError',
    defaultMessage: 'Something went wrong while loading the payment section information',
  },
});
