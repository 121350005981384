import React from 'react';
import { Link } from 'react-router-dom';
import { css } from 'aphrodite/no-important';
import { LogoTrainlineIconWordmark } from '@trainline/depot-web';
import { FunctionComponent } from 'enzyme';

import styles from './styles';

interface Props {
  color?: 'base' | 'inverted';
}

const Logo: FunctionComponent<Props> = ({ color = 'base' }) => {
  if (typeof window !== 'undefined' && window.self === window.top) {
    // only render logo when not in an iFrame

    return (
      <Link to="/" className={css([styles.logo, color === 'inverted' && styles.logoInverted])}>
        <LogoTrainlineIconWordmark />
      </Link>
    );
  }

  return null;
};

export default Logo;
