import React, { FunctionComponent } from 'react';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';

import styles from './styles';

interface Props {
  styleSheet?: StyleDeclarationValue;
}

const NoSearchResult: FunctionComponent<Props> = ({ children, styleSheet }) => (
  <p className={css([styles.message, styleSheet])}>{children}</p>
);

export default NoSearchResult;
