import { defineMessages } from 'react-intl';

export default defineMessages({
  editUser: {
    id: 'ChangeSite.editUser',
    defaultMessage: 'Edit user site - {username}',
  },
  site: {
    id: 'ChangeSite.site',
    defaultMessage: 'Site',
  },
  update: {
    id: 'ChangeSite.update',
    defaultMessage: 'Update',
  },
  cancel: {
    id: 'ChangeSite.cancel',
    defaultMessage: 'Cancel',
  },
  pleaseSelect: {
    id: 'ChangeSite.PleaseSelect',
    defaultMessage: 'Please select...',
  },
  siteIsRequired: {
    id: 'ChangeSite.siteIsRequired',
    defaultMessage: 'Please select a site.',
  },
});
