import { defineMessages } from 'react-intl';

export default defineMessages({
  friendlyOrderId: {
    id: 'manualEntryConsoleAddForm.friendlyOrderId',
    defaultMessage: 'Platform One or TraCS Order ID',
  },
  cancelButton: {
    id: 'manualEntryConsoleAddForm.cancelButton',
    defaultMessage: 'Cancel',
  },
  createClaim: {
    id: 'manualEntryConsoleAddForm.createClaim',
    defaultMessage: 'Create claim',
  },
  genericError: {
    id: 'manualEntryConsoleAddForm.genericError',
    defaultMessage: 'Something went wrong. Please try again.',
  },
  noMatchingOrder: {
    id: 'manualEntryConsoleAddForm.noMatchingOrder',
    defaultMessage:
      'No matching order was found in this managed group. This order may be in a different managed group.',
  },
  noProductToBeReclaimed: {
    id: 'manualEntryConsoleAddForm.noProductToBeReclaimed',
    defaultMessage:
      'The order contains no products to be reclaimed. Please try again or contact the Service Desk.',
  },
  'MRR.ProductResolutionFailed': {
    id: 'manualEntryConsoleAddForm.productResolutionFailed',
    defaultMessage: 'The order contains no products to be reclaimed.',
  },
});
