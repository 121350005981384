import { StyleSheet } from 'aphrodite/no-important';
import { palettes, spacers, typography } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  errorContainer: {
    height: '24px',
    fontSize: typography.dp_mini_font_size_m,
    lineHeight: typography.dp_body_line_height,
    color: palettes.dp_app_text_negative,
    marginBottom: spacers.dp_s4,
  },
});
