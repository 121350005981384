import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { Modal, ModalHeader, ModalTitle } from '@contactcentre-web/common/Modal';
import Button from '@contactcentre-web/common/Button';
import { useAddCustomerPassenger } from '@contactcentre-web/hooks/api/useCustomerPassengers';

import PassengerForm from '../PassengerForm/PassengerForm';

import messages from './messages';

const AddPassenger = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { customerId } = useParams<{ customerId: string }>();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useAddCustomerPassenger(customerId, {
    onSuccess: () => {
      setIsVisible(false);
      queryClient.invalidateQueries('customerPassengers');
    },
  });

  return (
    <>
      <Button
        variant="primary"
        size="small"
        onClick={() => setIsVisible(true)}
        testId="add-passenger-open-modal"
      >
        <FormattedMessage {...messages.addPassenger} />
      </Button>
      {isVisible && (
        <Modal onClose={() => setIsVisible(false)} testId="passenger-form-modal">
          <ModalHeader>
            <ModalTitle>
              <FormattedMessage {...messages.addPassenger} />
            </ModalTitle>
          </ModalHeader>
          <PassengerForm onSubmitForm={mutate} isSubmitting={isLoading} />
        </Modal>
      )}
    </>
  );
};

export default AddPassenger;
