import type State from '@contactcentre-web/redux-common/types/State';

export const getUserId = (state: State) => state.users?.userId;

export const canApproveCompensations = (state: State) =>
  state.users?.permissions?.canApproveCompensations || false;

export const canApproveDiscretionaryRefunds = (state: State) =>
  state.users?.permissions?.canApproveDiscretionaryRefunds || false;

export const canRequestDiscretionaryRefund = (state: State) =>
  state.users?.permissions?.canRequestDiscretionaryRefund || false;

export const canManageUsers = (state: State) => state.users?.permissions?.canManageUsers || false;

export const canSetupNewManagedGroup = (state: State) =>
  state.users?.permissions?.canSetupNewManagedGroup || false;

export const canEditCrmIntegrationMappings = (state: State) =>
  state.users?.permissions?.canEditCrmIntegrationMappings || false;

export const canEditUsers = (state: State) => state.users?.permissions?.canEditUsers || false;

export const canLogCall = (state: State) => state.users?.permissions?.canLogCall || false;

export const canUseBookingFlow = (state: State) =>
  state.users?.permissions?.canUseBookingFlow || false;

export const canEditCustomerProfile = (state: State) =>
  state.users?.permissions?.canEditCustomerProfile || false;

export const getCurrentManagedGroupName = (state: State) => state.users?.managedGroup?.name;

export const getCurrentManagedGroupNumber = (state: State) => state.users?.managedGroup?.number;

export const getCurrentManagedGroupId = (state: State) => state.users?.managedGroup?.id;

export const getAvailableManagedGroups = (state: State) => state.users?.managedGroups;

export const isPartOfMultipleManagedGroups = (state: State) =>
  state.users?.managedGroups?.length > 1;

export const canMoveOrder = (state: State) =>
  state.users?.permissions?.canMoveOrderToAnotherAccount || false;

export const canReplaceOrder = (state: State) => state.users?.permissions?.canReplaceOrder || false;

export const canResendEmails = (state: State) => state.users?.permissions?.canResendEmails || false;

export const canBebocPnrIntoEuPlatform = (state: State) =>
  state.users?.permissions?.canBebocPnrIntoEuPlatform || false;

export const canWaiveFraudBlocker = (state: State) =>
  state.users?.permissions?.canWaiveFraudBlocker || false;

export const canUseCurrencyConverter = (state: State) =>
  state.users?.permissions?.canUseCurrencyConverter || false;

export const canResetPasswords = (state: State) =>
  state.users?.permissions?.canResetPasswords || false;

export const canRequestCoj = (state: State) => state.users?.permissions?.canRequestCoj || false;

export const canCancelRailcard = (state: State) =>
  state.users?.permissions?.canCancelRailcard || false;

export const canRequestStandardRefund = (state: State) =>
  state.users?.permissions?.canRequestStandardRefund || false;

export const canOperateFlexiSeasonTickets = (state: State) =>
  state.users?.permissions?.canOperateFlexiSeasonTickets || false;

export const canRequestCompensation = (state: State) =>
  state.users?.permissions?.canRequestCompensation || false;

export const canRequestExternalRefund = (state: State) =>
  state.users?.permissions?.canRequestExternalRefund || false;

export const userId = (state: State) => state.users?.userId;

export const getHomeManagedGroupId = (state: State) => state.users?.homeManagedGroupId;

export const getUsername = (state: State) => state.users?.name;

export const canBulkUpload = (state: State) => state.users?.permissions?.canBulkUpload || false;

export const canRequestUnlimitedRefund = (state: State) =>
  state.users?.permissions?.canRequestUnlimitedRefund || false;

export const canApproveUnlimitedRefund = (state: State) =>
  state.users?.permissions?.canApproveUnlimitedRefund || false;

export const canConvertFulfilments = (state: State) =>
  state.users?.permissions?.canConvertFulfilments || false;

export const getUserSite = (state: State) => state.users?.site;

export const selectLoggedIn = (state: State) => state.users?.loggedIn;

export const selectAuthenticated = (state: State) => !!state.users?.authenticated;

export const hasLoggedOut = (state: State) => !!state.users?.loggedOut;

export const nextAction = (state: State) => state.users?.nextAction;

export const getRoleName = (state: State) => state.users?.roleName;

export const getUserFeatures = (state: State) => state.users?.features;

export const selectSignoutDisabled = (state: State) =>
  state.users?.permissions?.signoutDisabled || false;

export const canAddPendingMecClaim = (state: State) =>
  state.users?.permissions?.canAddPendingMecClaim;

export const canViewMecClaim = (state: State) => state.users?.permissions?.canViewMecClaim;

export const canManageMecClaim = (state: State) => state.users?.permissions?.canManageMecClaim;

export const canGenerateMecReport = (state: State) =>
  state.users?.permissions?.canGenerateMecReport;

export const canManageMecReasonCodes = (state: State) =>
  state.users?.permissions?.canManageMecReasonCodes;

export const canManageUnmatchedTickets = (state: State) =>
  canValidatePaperTickets(state) || canFinalReviewPaperTickets(state);

export const canValidatePaperTickets = (state: State) =>
  state.users?.permissions?.canValidatePaperTickets;

export const canFinalReviewPaperTickets = (state: State) =>
  state.users?.permissions?.canFinalReviewPaperTickets;

export const currentManagedGroupHasSsoProvider = (state: State) =>
  !!state.users?.managedGroup?.hasSsoProvider;

export const currentManagedGroupHasSsoConfiguration = (state: State) =>
  state.users?.managedGroup?.ssoConfiguration;

export const canRevokeCustomerPassword = (state: State) =>
  state.users?.permissions?.canRevokeCustomerPassword;
