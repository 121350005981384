import { defineMessages } from 'react-intl';

export default defineMessages({
  selectAnApplication: {
    id: 'OrderNotes.CrmApplicationUsed.selectAnApplication',
    defaultMessage: 'Select an application',
  },
  applicationRequired: {
    id: 'OrderNotes.CrmApplicationUsed.applicationRequired',
    defaultMessage: 'Please select an application',
  },
});
