import { all, call, put, takeLatest } from 'redux-saga/effects';
import type { Action } from 'redux-actions';

import { FetchError } from '@contactcentre-web/utils/error';

import { actions, constants } from './module';
import { search, SearchParams, SearchResultType, SearchResults } from './utils/searchService';

export function* searchSaga({
  payload: { searchQueryParams },
}: Action<{ searchQueryParams: SearchParams }>) {
  try {
    const response: SearchResults = yield call(search, searchQueryParams);
    // Apply the contract that action creator expects in case of Customers search
    yield put(actions.searchSucceeded(response, searchQueryParams));
  } catch (err) {
    if (err instanceof FetchError && err.status === 400) {
      yield put(
        actions.searchSucceeded(
          { totalCount: 0, items: [], searchResultType: SearchResultType.Customer },
          searchQueryParams
        )
      );
    } else {
      yield put(actions.searchFailed(err));
    }
  }
}

export default function* saga() {
  yield all([takeLatest(constants.SEARCH_REQUESTED, searchSaga)]);
}
