import { StyleSheet } from 'aphrodite/no-important';
import { typography, spacers, borderRadius } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    borderRadius: borderRadius.dp_r4,
    border: `1px solid ${colors.gray.alpha2}`,
    marginBottom: spacers.dp_s32,
    boxShadow: '0 8px 12px 0 rgba(33, 49, 77, 0.1)',
  },
  row: {
    display: 'flex',
    margin: `0 ${spacers.dp_s32} ${spacers.dp_s32}`,
  },
  table: {
    marginTop: spacers.dp_s32,
    width: '942px',
    borderSpacing: 0,
    borderRadius: borderRadius.dp_r2,
  },
  tableHead: {
    height: '34px',
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    color: colors.gray.alpha30,
    textTransform: 'uppercase',
    backgroundColor: colors.gray.alpha2,
  },
  tableHeader: {
    border: `1px solid ${colors.gray.alpha12}`,
    borderLeftWidth: 0,
    ':first-child': {
      borderLeftWidth: 1,
      borderTopLeftRadius: borderRadius.dp_r2,
    },
    ':last-child': {
      borderTopRightRadius: borderRadius.dp_r2,
    },
  },
  tableBody: {
    textAlign: 'center',
    height: '60px',
  },
  tableCell: {
    border: `1px solid ${colors.gray.alpha12}`,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    ':first-child': {
      borderLeftWidth: 1,
      borderBottomLeftRadius: borderRadius.dp_r2,
    },
    ':last-child': {
      borderBottomRightRadius: borderRadius.dp_r2,
    },
  },
  statusIndicatorActive: {
    borderColor: colors.positive.base,
    backgroundColor: colors.positive.base,
  },
  statusIndicatorInactive: {
    borderColor: colors.black,
    backgroundColor: colors.white,
  },
  arrow: {
    verticalAlign: 'middle',
  },
});
