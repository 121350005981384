import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { StatusMessage } from '@trainline/depot-web';

import PageContainer from '@contactcentre-web/common/PageContainer';
import Link from '@contactcentre-web/common/Link';

import messages from './messages';

export const MovedOrderAlert = ({ customerId, orderReference, emailAddress }) => (
  <PageContainer>
    <div data-testid="moved-order-alert">
      <StatusMessage status="positive">
        <FormattedMessage
          {...messages.text}
          values={{
            orderReference,
            emailAddress,
            link: (msg) => (
              <Link
                linkType="internal"
                to={`/customers/${customerId}/bookings/${orderReference}/history`}
              >
                {msg}
              </Link>
            ),
          }}
        />
      </StatusMessage>
    </div>
  </PageContainer>
);

MovedOrderAlert.propTypes = {
  customerId: PropTypes.string.isRequired,
  orderReference: PropTypes.string.isRequired,
  emailAddress: PropTypes.string.isRequired,
};

export default MovedOrderAlert;
