import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';

import Link from '@contactcentre-web/common/Link';

import styles from './styles';
import messages from './messages';

const BrowserNotSupportedBanner = () => (
  <div className={css(styles.container)}>
    <FormattedMessage
      {...messages.title}
      values={{
        a: (msg: string) => (
          <Link linkType="external" href="https://www.google.com/chrome/">
            {msg}
          </Link>
        ),
        b: (msg: string) => <b>{msg}</b>,
      }}
    />
  </div>
);

export default BrowserNotSupportedBanner;
