import { defineMessages } from 'react-intl';

export default defineMessages({
  document: {
    id: 'DocumentDownload.document',
    defaultMessage: 'PDF document',
  },
  downloading: {
    id: 'DocumentDownload.downloading',
    defaultMessage: 'Downloading...',
  },
});
