import { all, call, takeLatest, select, put } from 'redux-saga/effects';
import { startSubmit, stopSubmit, reset } from 'redux-form';
import type { Action } from 'redux-actions';

import request from '@contactcentre-web/utils/request';
import * as commonOrderSelectors from '@contactcentre-web/redux-common/selectors/order';
import { selectors as orderSelectors } from '@contactcentre-web/customer-order/module';
import { actions as approvalActions } from '@contactcentre-web/compensation-approvals/module';
import type Price from '@contactcentre-web/redux-common/types/Price';
import type State from '@contactcentre-web/redux-common/types/State';

import {
  PREFIX,
  COMPENSATION_LOAD,
  COMPENSATION_REQUEST_ATTEMPT,
  actions,
  FORM_ID,
} from './module';
import selectors from './selectors';
import type { CompensationReasons, PendingCompensation } from './reducer';

export interface RequestCompensationPayload {
  note: string;
  reasonCodeId: string;
  value: Price;
  products: Array<{
    productId: string;
    vendor?: string;
  }>;
}

export interface EditCompensationRequestPayload {
  note: string;
  reasonCodeId: string;
  value: Price;
}

interface Refunds {
  hasRefunds: boolean;
}

export const requestCompensationRequest = (
  body: RequestCompensationPayload & { orderId: string }
) =>
  request(`/compensations/create`, {
    body,
    method: 'POST',
  });

export const editCompensationRequest = (
  compensationId: string,
  body: EditCompensationRequestPayload
) =>
  request(`/compensations/${compensationId}`, {
    body,
    method: 'PUT',
  });

export const fetchReasonCodes = () => request<CompensationReasons>('/compensations/reasoncodes');

export const fetchRefunds = (orderId: string) => request<Refunds>(`/orders/${orderId}/refunds`);

export const fetchCompensation = (compensationId: string) =>
  request<PendingCompensation>(`/compensations/${compensationId}`);

export function* loadReasonCodes() {
  const reasonCodes: CompensationReasons = yield call(fetchReasonCodes);

  return reasonCodes;
}

export function* loadPendingCompensation(id: string) {
  const pendingCompensation: PendingCompensation | undefined = id
    ? yield call(fetchCompensation, id)
    : undefined;

  return pendingCompensation;
}

export function* loadRefunds() {
  const state: State = yield select();

  try {
    if (!selectors.loadRefundsInProgress(state)) {
      return;
    }
    if (!orderSelectors.isLoaded(state)) {
      // Order is still load, wait for it to finish.
      return;
    }

    const { id: orderId } = commonOrderSelectors.getOrder(state);
    const refundResponse: Refunds = yield call(fetchRefunds, orderId);

    yield put(actions.loadRefundsSucceeded(refundResponse));
  } catch (error) {
    yield put(actions.loadRefundsFailed());
  }
}

export function* loadSaga(action: Action<{ id: string }>) {
  const state: State = yield select();

  try {
    const { id } = action.payload;
    if (!selectors.loadInProgress(state)) {
      return;
    }
    if (!orderSelectors.isLoaded(state)) {
      // Order is still loading, wait for it to finish.
      return;
    }

    const [reasons, compensation]: [CompensationReasons, PendingCompensation | undefined] =
      yield all([call(loadReasonCodes), call(loadPendingCompensation, id)]);

    yield put(actions.loadSucceeded(reasons, compensation));

    yield call(loadRefunds);
  } catch (error) {
    yield put(actions.loadFailed());
  }
}

export function* processRequestSaga({
  payload: { values, orderId, orderReference, compensationId },
}: Action<{
  values: RequestCompensationPayload;
  orderId: string;
  orderReference: string;
  compensationId: string;
}>) {
  try {
    yield put(startSubmit(FORM_ID));
    if (compensationId) {
      approvalActions.reset();
      yield call(editCompensationRequest, compensationId, values);
    } else {
      const body = { orderId, ...values };
      yield call(requestCompensationRequest, body);
    }
    yield put(actions.requestSuccess());
    yield put(stopSubmit(FORM_ID));
    yield put(reset(FORM_ID));
    if (compensationId) {
      yield put(
        approvalActions.resolutionSuccess('confirm', compensationId, values.value, orderReference)
      );
      return;
    }
  } catch (error: any) {
    yield put(stopSubmit(FORM_ID));

    if (error && error.errors && error.errors.length > 0) {
      const err = error.errors[0];
      yield put(actions.requestFailure(err.code, err.meta.context));
    } else {
      yield put(actions.requestFailure('compensation_genericError'));
    }
  }
}

export default function* saga() {
  yield takeLatest(`${PREFIX}/${COMPENSATION_LOAD}`, loadSaga);
  yield takeLatest(`${PREFIX}/${COMPENSATION_REQUEST_ATTEMPT}`, processRequestSaga);
}
