import React, { FunctionComponent, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { useHistory } from 'react-router';
import { css } from 'aphrodite/no-important';
import { IconWarning, StatusMessage } from '@trainline/depot-web/components';

import { useErrorMapping } from '@contactcentre-web/hooks/api/useErrorMapping';
import Loader from '@contactcentre-web/common/Loader';
import RefundReason from '@contactcentre-web/refunds/common/RefundReason';
import TextField from '@contactcentre-web/common/TextField';
import { useFetchReasonCodes } from '@contactcentre-web/hooks/api/useCompensation';
import Button from '@contactcentre-web/common/Button';
import {
  ManualEntryConsoleClaimItemResponse,
  useManualEntryConsoleClaimEditItem,
} from '@contactcentre-web/hooks/api/useManualEntryConsole';

import messages from './messages';
import styles from './styles';

export interface Props {
  isLoading: boolean;
  manualEntryConsoleItem?: ManualEntryConsoleClaimItemResponse;
}

const FORM_ID = 'ManualEntryConsoleEditForm';

export const ManualEntryConsoleEditForm: FunctionComponent<
  Props & InjectedFormProps<MECEditFormData, Props>
> = ({ initialize, invalid, handleSubmit, manualEntryConsoleItem, isLoading }) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { mutate, isError, error } = useManualEntryConsoleClaimEditItem({
    onSuccess: () => {
      history.push('/mec-claim-approvals?status=pending');
    },
  });
  const errorMessage = useErrorMapping(error, messages, 'genericError');

  const {
    data: reasons,
    isLoading: isLoadingReasons,
    isError: isErrorReasons,
  } = useFetchReasonCodes();

  useEffect(() => {
    if (manualEntryConsoleItem) {
      initialize({
        recoveredAmount: manualEntryConsoleItem.recoveredGrossAmount.amount,
        adminFeeAmount: manualEntryConsoleItem.adminFeeAmount.amount,
        ticketCount: manualEntryConsoleItem.ticketCount,
        reasonCode: manualEntryConsoleItem.reasonCode?.id,
      });
    }
  }, [manualEntryConsoleItem]);

  const handleFormSubmit = (form: MECEditFormData) => {
    if (manualEntryConsoleItem) {
      mutate({
        claimId: manualEntryConsoleItem.id,
        recoveredAmount: {
          amount: form.recoveredAmount,
          currencyCode: manualEntryConsoleItem.recoveredGrossAmount.currencyCode,
        },
        adminFeeAmount: {
          amount: form.adminFeeAmount,
          currencyCode: manualEntryConsoleItem.adminFeeAmount.currencyCode,
        },
        ticketCount: form.ticketCount,
        reasonCodeId: form.reasonCode,
      });
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <form
      id={FORM_ID}
      onSubmit={handleSubmit(handleFormSubmit)}
      data-testid="manualEntryConsoleEditForm"
      className={css(styles.formContainer)}
    >
      {isError && (
        <StatusMessage status="negative">
          <FormattedMessage {...errorMessage} />
        </StatusMessage>
      )}
      {!!manualEntryConsoleItem?.multipleClaimsForOrder && (
        <StatusMessage status="warning">
          <div className={css(styles.warning)}>
            <IconWarning />
            <FormattedMessage {...messages.multipleClaimsForOrder} />
          </div>
        </StatusMessage>
      )}
      <div className={css(styles.form)}>
        <div className={css(styles.formRow)}>
          <TextField
            label={formatMessage(messages.ticketCount)}
            testId="mec-ticketCount"
            type="number"
            min="1"
            step="1"
            name="ticketCount"
            messages={messages}
          />
          <TextField
            label={formatMessage(messages.recoveredAmount, {
              currency: manualEntryConsoleItem?.recoveredGrossAmount.currencyCode,
            })}
            testId="mec-recoveredAmount"
            type="number"
            min="0.01"
            step="0.01"
            name="recoveredAmount"
            messages={messages}
          />
          <TextField
            label={formatMessage(messages.adminFeeAmount, {
              currency: manualEntryConsoleItem?.adminFeeAmount.currencyCode,
            })}
            testId="mec-adminFeeAmount"
            type="number"
            min="0"
            step="0.01"
            name="adminFeeAmount"
            messages={messages}
          />
        </div>
        <RefundReason
          reasons={reasons}
          disabled={isLoadingReasons || isErrorReasons}
          isReasonCodePerRefundable
          messages={messages}
        />
      </div>
      <div className={css(styles.formButtons)}>
        <Button
          type="button"
          variant="tertiary"
          size="large"
          onClick={() => history.push('/mec-claim-approvals')}
        >
          <FormattedMessage {...messages.cancelButton} />
        </Button>
        <Button
          type="submit"
          variant="primary"
          size="large"
          disabled={invalid || !manualEntryConsoleItem}
        >
          <FormattedMessage {...messages.saveButton} />
        </Button>
      </div>
    </form>
  );
};

export interface MECEditFormData {
  recoveredAmount: number;
  adminFeeAmount: number;
  ticketCount: number;
  reasonCode: string;
}

export const validate = (data: MECEditFormData): Record<string, string | undefined> => {
  const MAX_RECOVERED_AMOUNT = 10000;
  const validationRules: Record<string, (value: number) => string | undefined> = {
    recoveredAmount: (value: number) => {
      if (value > MAX_RECOVERED_AMOUNT) return `MMR.ClaimRecoverableTooLarge`;
      return undefined;
    },
    adminFeeAmount: (value: number) => {
      if (value >= data['recoveredAmount']) return `adminFeeAmountGreaterRecoveredAmount`;
      return undefined;
    },
  };

  const checkIfValid = (key: keyof MECEditFormData) => {
    const value = data[key];
    const isEmpty = (value: string | number) =>
      value === undefined || value === null || value === '';

    if (isEmpty(value)) {
      return { [key]: `validation_${key}_invalid` };
    }

    return validationRules[key];
  };

  return {
    ...checkIfValid('recoveredAmount'),
    ...checkIfValid('adminFeeAmount'),
    ...checkIfValid('ticketCount'),
    ...checkIfValid('reasonCode'),
  };
};

export default reduxForm<MECEditFormData, Props>({
  form: FORM_ID,
  validate,
})(ManualEntryConsoleEditForm);
