import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  notification: {
    marginTop: spacers.dp_s16,
    ':first-child': {
      marginTop: 0,
    },
    ':last-child': {
      marginBottom: spacers.dp_s32,
    },
  },
  multiLineMessage: {
    display: 'flex',
    flexDirection: 'column',
  },
});
