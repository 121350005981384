import { defineMessages } from 'react-intl';

export default defineMessages({
  RefundRequestStarted: {
    id: 'NotVoidableTooltip.refundRequestStarted',
    defaultMessage: "'Void' is not available because cancellation has already been requested.",
  },
  voidAlreadyInProgressForRefundable: {
    id: 'NotVoidableTooltip.voidAlreadyInProgressForRefundable',
    defaultMessage: "'Void' is not available because cancellation has already been requested.",
  },
  policyExpired: {
    id: 'NotVoidableTooltip.policyExpired',
    defaultMessage: "'Void' is not available because the cancellation period has expired.",
  },
  ticketAlreadyCollected: {
    id: 'NotVoidableTooltip.ticketAlreadyCollected',
    defaultMessage: "'Void' is not available because tickets have already been collected.",
  },
  productInInvalidState: {
    id: 'NotVoidableTooltip.productInInvalidState',
    defaultMessage: "'Void' is not available because a booking is in an invalid state.",
  },
  notAllRefundablesAreRefundable: {
    id: 'NotVoidableTooltip.notAllRefundablesAreRefundable',
    defaultMessage: "'Void' is not available because part of the order is not refundable.",
  },
  notSupported: {
    id: 'NotVoidableTooltip.notSupported',
    defaultMessage: "'Void' is not available for this order.",
  },
  notVoidableUnderGivenDeliveryType: {
    id: 'NotVoidableTooltip.notVoidableUnderGivenDeliveryType',
    defaultMessage: 'Void is not available for this delivery method.',
  },
  notVoidableUnderThisFareType: {
    id: 'NotVoidableTooltip.notVoidableUnderThisFareType',
    defaultMessage: 'Void is not available for this fare type.',
  },
  errorFetchingVoidability: {
    id: 'NotVoidableTooltip.errorFetchingVoidability',
    defaultMessage: 'Unable to check if this order can be cancelled.',
  },
  upstreamErrors: {
    id: 'NotVoidableTooltip.upstreamErrors',
    defaultMessage:
      "Refund can't be done due to some error(s). If booking is newer than 6 months, refresh the page (F5) to try again. Otherwise, contact refund team.",
  },
});
