import React, { Dispatch, FunctionComponent, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { css } from 'aphrodite/no-important';
import { Redirect } from 'react-router';

import Loader from '@contactcentre-web/common/Loader';
import type State from '@contactcentre-web/redux-common/types/State';
import type Action from '@contactcentre-web/redux-common/types/Action';

import { selectLoggedIn } from '../../redux/selectors';
import { actions } from '../../redux/module';

import styles from './styles';
import messages from './messages';

interface StateProps {
  hasLoggedOut: boolean;
}

interface DispatchProps {
  signOut: () => void;
}

type Props = StateProps & DispatchProps;

export const Logout: FunctionComponent<Props> = ({ signOut, hasLoggedOut }) => {
  const signOutRef = useRef(signOut);

  React.useEffect(() => {
    signOutRef.current();
  }, [signOutRef]);

  if (hasLoggedOut) {
    return <Redirect to="/login" />;
  }

  return (
    <div className={css(styles.container)}>
      <Loader />
      <div className={css(styles.action)}>
        <FormattedMessage {...messages.message} />
      </div>
    </div>
  );
};
const mapStateToProps = (state: State) => ({
  hasLoggedOut: !selectLoggedIn(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  signOut: () => dispatch(actions.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
