import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IconWarning } from '@trainline/depot-web';
import { css } from 'aphrodite/no-important';

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@contactcentre-web/common/Modal';
import Button from '@contactcentre-web/common/Button';

import styles from './styles';
import messages from './messages';

interface Props {
  onCloseModal: () => void;
}

const ToggleRecordingErrorModal = ({ onCloseModal }: Props) => (
  <Modal onClose={onCloseModal}>
    <ModalHeader>
      <ModalTitle>
        <div className={css(styles.modalTitle)}>
          <IconWarning />
          <FormattedMessage {...messages.title} />
        </div>
      </ModalTitle>
    </ModalHeader>
    <ModalBody>
      <FormattedMessage {...messages.body} />
    </ModalBody>
    <ModalFooter contentAlignment="end">
      <Button variant="secondary" size="large" onClick={onCloseModal}>
        <FormattedMessage {...messages.button} />
      </Button>
    </ModalFooter>
  </Modal>
);

export default ToggleRecordingErrorModal;
