import { StyleSheet } from 'aphrodite/no-important';
import { fonts, typography } from '@trainline/depot-web/css/themes/trainline';

import { negative, positive } from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  container: {
    fontSize: typography.dp_small_font_size_m,
    fontWeight: fonts.dp_font_weight_bold,
    textAlign: 'right',
  },
  successful: {
    color: positive.dark,
    borderBottom: `1px dashed ${positive.dark}`,
  },
  failed: {
    color: negative.dark,
    borderBottom: `1px dashed ${negative.dark}`,
  },
  errorList: {
    minWidth: '150px',
    listStyleType: 'none',
  },
});
