import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@contactcentre-web/common/Button';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@contactcentre-web/common/Modal';

import messages from './messages';

interface Props {
  onCloseClick: () => void;
  onProceedClick: () => void;
}

const ApprovalConfirmation = ({ onCloseClick, onProceedClick }: Props) => (
  <Modal onClose={onCloseClick}>
    <ModalHeader>
      <ModalTitle>
        <FormattedMessage {...messages.header} />
      </ModalTitle>
    </ModalHeader>
    <ModalBody>
      <FormattedMessage {...messages.body} />
    </ModalBody>
    <ModalFooter contentAlignment="spaceBetween">
      <Button variant="tertiary" size="large" onClick={onCloseClick}>
        <FormattedMessage {...messages.cancel} />
      </Button>
      <Button variant="primary" size="large" onClick={onProceedClick}>
        <FormattedMessage {...messages.proceed} />
      </Button>
    </ModalFooter>
  </Modal>
);

export default ApprovalConfirmation;
