import { StyleSheet } from 'aphrodite/no-important';
import { fonts, palettes, spacers, typography } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  section: {
    margin: `${spacers.dp_s24} ${spacers.dp_s64}`,
  },
  ticketDetails: {
    display: 'grid',
    gridTemplateColumns: '30% 1fr 1fr 1fr',
    gap: `${spacers.dp_s24} ${spacers.dp_s32}`,
    ':not(:first-child)': {
      marginTop: spacers.dp_s24,
    },
    ':not(:last-child)': {
      marginBottom: spacers.dp_s24,
    },
  },
  fromToRow: {
    gridRowStart: 'span 3',
  },
  title: {
    fontSize: typography.dp_small_font_size_m,
    fontWeight: fonts.dp_font_weight_semibold,
    textTransform: 'uppercase',
    color: palettes.dp_app_text_subdued,
  },
  text: {
    color: palettes.dp_app_text_base,
  },
  error: {
    color: palettes.dp_app_text_negative,
  },
  line: {
    border: 'none',
    borderTop: `1px dashed ${palettes.dp_app_border_dark}`,
  },
  loaderContainer: {
    alignItems: 'flex-start',
  },
  checkInUrl: {
    display: 'flex',
    alignItems: 'center',
    gap: spacers.dp_s8,
  },
});
