import { defineMessages } from 'react-intl';

export default defineMessages({
  field_email: {
    id: 'EmailSearchForm.field_email',
    defaultMessage: 'New account email address',
  },
  email_required: {
    id: 'EmailSearchForm.email_required',
    defaultMessage: 'Please enter an email address.',
  },
  email_invalid: {
    id: 'EmailSearchForm.email_invalid',
    defaultMessage: "The text you've entered is not a valid email address.",
  },
  email_noResults: {
    id: 'EmailSearchForm.email_noResults',
    defaultMessage: 'Email address is not recognised.',
  },
  email_multipleResults: {
    id: 'EmailSearchForm.email_multipleResults',
    defaultMessage:
      'Multiple accounts have been returned for this email address. Something is not right...',
  },
  email_sameEmail: {
    id: 'EmailSearchForm.email_sameEmail',
    defaultMessage: 'Target account is identical to origin account.',
  },
});
