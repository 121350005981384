import { StyleSheet } from 'aphrodite/no-important';
import { fonts, palettes, spacers, typography } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    flexDirection: 'column',
    padding: 0,
  },
  errorContainer: {
    fontSize: typography.dp_small_font_size_m,
    fontWeight: fonts.dp_font_weight_regular,
    color: palettes.dp_app_text_negative,
    marginBottom: spacers.dp_s4,
    marginTop: spacers.dp_s4,
  },
});
