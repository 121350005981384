import { StyleSheet } from 'aphrodite/no-important';
import { palettes } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  threshold: {
    color: palettes.dp_app_text_negative,
  },
  container: {
    display: 'flex',
  },
  alignRight: {
    justifyContent: 'flex-end',
  },
});
