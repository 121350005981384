import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import styles from './styles';
import messages from './messages';

interface Props {
  username: string;
  password: string;
  managedGroupName: string;
}

const FirstUserInfo = ({ username, password, managedGroupName }: Props) => (
  <div className={css(styles.wrapper)}>
    <h2 className={css(styles.title)}>
      <FormattedMessage {...messages.title} values={{ managedGroupName }} />
    </h2>
    <p className={css(styles.userTitle)}>
      <FormattedMessage {...messages.username} />
    </p>
    <p className={css(styles.user)}>{username}</p>
    <p className={css(styles.userTitle)}>
      <FormattedMessage {...messages.pwd} />
    </p>
    <p className={css(styles.user)}>{password}</p>
    <p className={css(styles.note)}>
      <FormattedMessage {...messages.note} />
    </p>
  </div>
);

export default FirstUserInfo;
