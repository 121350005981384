import { defineMessages } from 'react-intl';

export default defineMessages({
  createdAtDate: {
    id: 'manualEntryConsoleQueueItem.createdAt',
    defaultMessage: '{day} at {time}',
  },
  raisedBy: {
    id: 'manualEntryConsoleQueueItem.raisedBy',
    defaultMessage: 'Raised by: {name}',
  },
  reasonCode: {
    id: 'manualEntryConsoleQueueItem.reasonCode',
    defaultMessage: 'Reason code:',
  },
  noReasonCode: {
    id: 'manualEntryConsoleQueueItem.noReasonCode',
    defaultMessage: 'None selected',
  },
  product: {
    id: 'manualEntryConsoleQueueItem.product',
    defaultMessage:
      'Product: <b>{productType, select, singleUseTravelProduct {Daily Ticket} seasonTravelProduct {Season Ticket} railcardProduct {Railcard} other {{productType}}}</b>',
  },
  numberOfTickets: {
    id: 'manualEntryConsoleQueueItem.numberOfTickets',
    defaultMessage: 'Number of tickets: {numberOfTickets}',
  },
  compensationTotal: {
    id: 'manualEntryConsoleQueueItem.compensationTotal',
    defaultMessage: 'Compensation amount: <strong>{amount} {currencyCode}</strong>',
  },
  adminFee: {
    id: 'manualEntryConsoleQueueItem.adminFee',
    defaultMessage: 'Admin fee <span>{amount} {currencyCode}</span>',
  },
  recoverTotal: {
    id: 'manualEntryConsoleQueueItem.recoverTotalGross',
    defaultMessage: 'Recover total (Gross) <strong>{amount} {currencyCode}</strong>',
  },
  editButton: {
    id: 'manualEntryConsoleQueueItem.editButton',
    defaultMessage: 'Edit claim',
  },
  approveButton: {
    id: 'manualEntryConsoleQueueItem.approveButton',
    defaultMessage: 'Approve claim',
  },
  deleteButton: {
    id: 'manualEntryConsoleQueueItem.deleteButton',
    defaultMessage: 'Delete claim',
  },
  removeFromQueueButton: {
    id: 'manualEntryConsoleQueueItem.removeFromQueueButton',
    defaultMessage: 'Remove from queue',
  },
  netValue: {
    id: 'manualEntryConsoleQueueItem.recoverTotalNet',
    defaultMessage: 'Recover total (Net) <strong>{amount} {currencyCode} </strong>',
  },
});
