import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Paragraph } from '@trainline/depot-web';

import Button from '@contactcentre-web/common/Button';
import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from '@contactcentre-web/common/Modal';

import messages from './messages';

interface FulfilmentConverterConfirmationProp {
  visible: boolean;
  loading: boolean;
  onCloseClick: () => void;
  onProceedClick: () => void;
}

export const FulfilmentConverterConfirmation = ({
  visible,
  onCloseClick,
  onProceedClick,
  loading,
}: FulfilmentConverterConfirmationProp) =>
  visible ? (
    <Modal onClose={onCloseClick}>
      <ModalHeader>
        <ModalTitle>
          <FormattedMessage {...messages.header} />
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Paragraph typeStyle="body" as="p" color="base" fontWeight="regular">
          <FormattedMessage {...messages.body} />
        </Paragraph>
      </ModalBody>
      <ModalFooter contentAlignment="spaceBetween">
        <Button variant="tertiary" size="large" onClick={onCloseClick} disabled={loading}>
          <FormattedMessage {...messages.cancel} />
        </Button>
        <Button variant="primary" size="large" onClick={onProceedClick} loading={loading}>
          <FormattedMessage {...messages.proceed} />
        </Button>
      </ModalFooter>
    </Modal>
  ) : null;

export default FulfilmentConverterConfirmation;
