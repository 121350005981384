import React, { Dispatch, FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { css } from 'aphrodite/no-important';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Heading, Paragraph } from '@trainline/depot-web';

import PageContainer from '@contactcentre-web/common/PageContainer';
import Loader from '@contactcentre-web/common/Loader';
import { addPageAction } from '@contactcentre-web/utils/tracker';
import type {
  OrderItemsHistory,
  CompensationHistory,
  FulfilmentConverterHistory,
  CompensationCarrierRequestHistory,
  OrderHistoryItem as NewOrderHistoryItem,
  RefundHistory,
} from '@contactcentre-web/hooks/api/useOrderHistory';
import type State from '@contactcentre-web/redux-common/types/State';
import type Action from '@contactcentre-web/redux-common/types/Action';

import RefundCard from './components/RefundCard/RefundCard';
import Compensation from './components/Compensation/Compensation';
import FulfilmentConverter from './components/FulfilmentConverter/FulfilmentConverter';
import CompensationCarrierRequest from './components/CompensationCarrierRequest/CompensationCarrierRequest';
import ConfirmationEmail from './components/ConfirmationEmail/ConfirmationEmail';
import BookingHistory from './components/BookingHistory/BookingHistoryItem';
import Beboc from './components/Beboc/Beboc';
import Converted from './components/Converted/Converted';
import Replacement from './components/Replacement/Replacement';
import Reimbursement from './components/Reimbursement/Reimbursement';
import MoveOrder from './components/MoveOrder/MoveOrder';
import DelayRepayClaim from './components/DelayRepayClaim/DelayRepayClaim';
import RailcardCancellation from './components/RailcardCancellation/RailcardCancellation';
import ExternalRefund from './components/ExternalRefund/ExternalRefund';
import FlexiSeason from './components/FlexiSeason/FlexiSeason';
import Refund from './components/Refund/Refund';
import {
  selectors,
  actions,
  OrderHistoryItem,
  RefundItem,
  ConversionItem,
  ConfirmationEmailItem,
  BebocItem,
  BookingHistoryItem,
  ReimbursementItem,
  MoveOrderItem,
  DelayRepayClaimItem,
  RailcardCancellationItem,
  ExternalRefundItem,
  FlexiSeasonItem,
} from './module';
import messages from './messages';
import styles from './styles';
import OrderHistoryErrorMessage from './components/OrderHistoryErrorMessage/OrderHistoryErrorMessage';
import Refresh from './components/Refresh/Refresh';

interface Params {
  orderId: string;
  customerId: string;
}

interface StateProps {
  historyItems: Array<OrderHistoryItem> | null;
  loading: boolean;
  failed: boolean;
}

interface DispatchProps {
  loadOrderHistory: (orderReference: string) => void;
}

type Props = StateProps & DispatchProps;

export const OrderHistory: FunctionComponent<Props> = ({
  historyItems,
  loading,
  failed,
  loadOrderHistory,
}) => {
  const { orderId, customerId } = useParams<Params>();
  const queryClient = useQueryClient();

  const {
    data: { items: newHistoryItems = [], errors: newHistoryErrors = [] } = {},
    isFetching: isFetchingNewHistoryItems,
    status: newHistoryItemsStatus,
  } = queryClient.getQueryState<OrderItemsHistory>(['orderHistoryItems', orderId]) || {};

  const mergedHistoryItems = [...(historyItems || []), ...(newHistoryItems || [])];

  const sortedByDateHistoryItems = mergedHistoryItems.sort((item1, item2) => {
    const date = (item: OrderHistoryItem | NewOrderHistoryItem) =>
      new Date((item as NewOrderHistoryItem).date || (item as OrderHistoryItem).createdAt || 0);
    return date(item2).valueOf() - date(item1).valueOf();
  });

  React.useEffect(() => {
    // the order history items endpoint is called when the order page is loaded (to display the notification red dot)
    // but after that, the agent can perform multiple actions/events (eg: compensation, resend email)
    // and in order to ensure the most updated data, the endpoint has to be re-fetch here
    queryClient.invalidateQueries('orderHistoryItems');
    loadOrderHistory(orderId);
    addPageAction('visit-order-history');
  }, []);

  if (failed && newHistoryItemsStatus === 'error') {
    return null;
  }

  if (loading || isFetchingNewHistoryItems) {
    return (
      <div className={css(styles.loaderContainer)}>
        <Loader />
      </div>
    );
  }

  return (
    <PageContainer styleSheet={styles.container}>
      <div className={css(styles.title)}>
        <Heading typeStyle="title1" as="h1" color="base">
          <FormattedMessage {...messages.orderHistory} />
        </Heading>
      </div>
      {sortedByDateHistoryItems.length === 0 && newHistoryErrors.length === 0 && (
        <div className={css(styles.emptyQueueMessage)}>
          <Paragraph typeStyle="small" as="p" color="base" fontWeight="regular">
            <FormattedMessage {...messages.emptyOrderHistoryQueue} />
          </Paragraph>
        </div>
      )}
      {newHistoryErrors?.map(({ meta: { type } }) => {
        switch (type) {
          case 'Refund':
            return <OrderHistoryErrorMessage message={messages.refundError} key={type} />;
          case 'RefundRequestCreated':
            return (
              <OrderHistoryErrorMessage message={messages.refundRequestCreatedError} key={type} />
            );
          case 'RefundRequestRejected':
            return (
              <OrderHistoryErrorMessage message={messages.refundRequestRejectedError} key={type} />
            );
          case 'Compensation':
            return <OrderHistoryErrorMessage message={messages.compensationError} key={type} />;
          default:
            return <OrderHistoryErrorMessage message={messages.defaultError} key={type} />;
        }
      })}

      {sortedByDateHistoryItems.map(({ type, ...history }, index) => {
        switch (type) {
          case 'Refund':
          case 'RefundRequestCreated':
          case 'RefundRequestRejected':
            return (
              <Refund
                key={index}
                {...({ ...history, type } as RefundHistory)}
                isFirstOrderHistoryItem={index === 0}
              />
            );
          case 'Compensation':
            return <Compensation key={index} {...(history as CompensationHistory)} />;
          case 'FulfilmentConverted':
            return <FulfilmentConverter key={index} {...(history as FulfilmentConverterHistory)} />;
          case 'CompensationCarrierRequest':
            return (
              <CompensationCarrierRequest
                key={index}
                {...(history as CompensationCarrierRequestHistory)}
              />
            );
          case 'ConvertedFrom':
          case 'ConvertedTo':
            return (
              <Converted
                key={index}
                type={type}
                customerId={customerId}
                {...(history as Omit<ConversionItem, 'type'>)}
              />
            );
          case 'ConfirmationEmail':
            return <ConfirmationEmail key={index} {...(history as ConfirmationEmailItem)} />;
          case 'Beboc':
            return <Beboc key={index} {...(history as BebocItem)} />;
          case 'BookingHistory':
            return (
              <>
                <Refresh key={index} {...(history as BookingHistoryItem)} />
                <BookingHistory key={index + 1} {...(history as BookingHistoryItem)} />
              </>
            );
          case 'ReplacementTo':
          case 'ReplacementFrom':
            return (
              <Replacement
                key={index}
                type={type}
                customerId={customerId}
                {...(history as Omit<ConversionItem, 'type'>)}
              />
            );
          case 'Reimbursement':
            return <Reimbursement key={index} {...(history as ReimbursementItem)} />;
          case 'CojRefund':
            return <RefundCard key={index} {...(history as RefundItem)} />;
          case 'MoveOrder':
            return (
              <MoveOrder key={index} {...(history as MoveOrderItem)} orderReference={orderId} />
            );
          case 'DelayRepayClaim':
            return <DelayRepayClaim key={index} {...(history as DelayRepayClaimItem)} />;
          case 'RailcardCancellation':
            return <RailcardCancellation key={index} {...(history as RailcardCancellationItem)} />;
          case 'ExternalRefund':
            return (
              <ExternalRefund
                key={index}
                customerId={customerId}
                orderReference={orderId}
                {...(history as ExternalRefundItem)}
              />
            );
          case 'FlexiSeasonReset':
          case 'FlexiSeasonCancel':
            return (
              <FlexiSeason
                key={index}
                type={type}
                {...(history as Omit<FlexiSeasonItem, 'type'>)}
              />
            );
          default:
            return null;
        }
      })}
    </PageContainer>
  );
};

const mapStateToProps = (state: State) => ({
  historyItems: selectors.getHistoryItems(state),
  loading: selectors.isLoading(state),
  failed: selectors.isFailed(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  loadOrderHistory: (orderReference: string) => dispatch(actions.loadAttempt(orderReference)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);
