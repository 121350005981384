import { StyleSheet } from 'aphrodite/no-important';
import {
  spacers,
  palettes,
  borderRadius,
  typography,
  fonts,
} from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    display: 'flex',
    background: palettes.dp_app_backdrop_base,
    borderRadius: borderRadius.dp_r4,
    boxShadow: `0px 0px 4px ${palettes.dp_comp_box_shadow}, 0px 2px 6px ${palettes.dp_comp_box_shadow}`,
    marginTop: spacers.dp_s32,
    padding: `${spacers.dp_s24} ${spacers.dp_s64}`,
  },
  col: {
    wordBreak: 'break-word',
    flex: '1 1 auto',
    padding: `0 ${spacers.dp_s12}`,
    ':first-child': {
      paddingLeft: 0,
    },
  },
  title: {
    fontSize: typography.dp_small_font_size_m,
    lineHeight: typography.dp_small_line_height,
    color: '#8A9497',
    textTransform: 'uppercase',
    marginBottom: spacers.dp_s4,
    fontWeight: fonts.dp_font_weight_semibold,
  },
  localAreaValidityCol: {
    marginTop: spacers.dp_s12,
  },
  productTypeLabel: {
    color: '#8A9497',
    marginBottom: spacers.dp_s8,
  },
  status: {
    fontWeight: fonts.dp_font_weight_bold,
    fontSize: typography.dp_small_font_size_m,
  },
  priceCol: {
    textAlign: 'right',
  },
});
