import { StyleSheet } from 'aphrodite/no-important';
import {
  borderRadius,
  palettes,
  typography,
  spacers,
  fonts,
} from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';
import { zIndex } from '@contactcentre-web/styles/zIndex';

const expandableWrapper = {
  boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.1)',
  background: palettes.dp_app_backdrop_base,
  borderRadius: borderRadius.dp_r8,
  borderColor: palettes.dp_app_border_base,
  display: 'inline-block',
  padding: spacers.dp_s4,
};

const expandableAnchor = {
  fontSize: typography.dp_small_font_size_m,
  letterSpacing: typography.dp_small_letter_spacing,
  lineHeight: typography.dp_small_line_height,
};

export default StyleSheet.create({
  grayLabel: {
    color: colors.gray.alpha30,
  },

  legInfo: {
    marginTop: spacers.dp_s24,
  },

  leg: {
    fontSize: typography.dp_mini_font_size_m,
    fontWeight: fonts.dp_font_weight_semibold,
    textTransform: 'uppercase',
  },

  tripIcon: {
    marginRight: 10,
    verticalAlign: 'middle',
  },

  row: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
  },

  legDate: {
    minWidth: '150px',
    display: 'inline-block',
  },

  timeLabel: {
    minWidth: '70px',
    display: 'flex',
  },

  duration: {
    marginRight: spacers.dp_s4,
    display: 'inline-block',
  },

  transportInformation: {
    color: colors.gray.alpha80,
    textTransform: 'capitalize',
  },

  column: {
    display: 'flex',
    flexDirection: 'column',
  },

  header: {
    display: 'flex',
    color: colors.gray.alpha80,
  },

  boardingTooltip: {
    display: 'flex',
  },

  tabs: {
    position: 'relative',
    borderTop: `dashed 1px ${colors.gray.alpha12}`,
    marginTop: spacers.dp_s24,

    ':before': {
      content: '""',
      background: 'white',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: -99, // CustomerBookingJourney margin (64px) +  indentedContent margin (35px)
      right: -64, // CustomerBookingJourney margin (64px)
    },
  },

  expandableAnchors: {
    position: 'absolute',
    top: 0,
    right: 0,
    transform: `translate(-${spacers.dp_s8}, -50%)`,
    zIndex: zIndex.base,
  },

  expandablePassengerInfoWrapper: {
    ...expandableWrapper,
  },

  expandablePassengerInfoAnchor: {
    ...expandableAnchor,
    color: palettes.dp_app_link_base,
  },

  expandablePassengerInfoChevron: {
    color: palettes.dp_app_link_base,
  },

  expandableDisruptionInfoAnchor: {
    ...expandableAnchor,
    color: palettes.dp_app_negative_mid,
  },

  expandableDisruptionInfoChevron: {
    color: palettes.dp_app_negative_mid,
  },

  expandableDisruptionInfoWrapper: {
    ...expandableWrapper,
    marginRight: spacers.dp_s24,
  },

  expandableContent: {
    position: 'relative',
    marginTop: spacers.dp_s32,
  },
});
