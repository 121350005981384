import { StyleSheet } from 'aphrodite/no-important';
import { fonts, spacers, typography } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

const typographyStyles = {
  fontSize: typography.dp_small_font_size_m,
  letterSpacing: typography.dp_small_letter_spacing,
  lineHeight: typography.dp_small_line_height,
};

export default StyleSheet.create({
  callingPointsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '50%',
  },
  callingPointsItemContainer: {
    flexDirection: 'column',
  },
  callingPointsDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '50px',
  },

  callingPointItem: {
    display: 'flex',
  },
  callingPointItemTime: {
    marginRight: '40px',
  },
  callingPoints: {
    position: 'relative',
  },
  marker: {
    position: 'relative',
    paddingBottom: '60px',

    ':before': {
      content: '""',
      display: 'block',
      top: '11px',
      bottom: '-10px',
      left: '-6px',
      position: 'absolute',
      borderLeft: `3px solid ${colors.black}`,
    },
  },
  hideMarker: {
    ':before': {
      display: 'none',
    },
  },
  redMarker: {
    ':before': {
      borderColor: colors.negative.base,
    },
  },
  point: {
    position: 'relative',
    ':before': {
      content: '""',
      position: 'absolute',
      width: '7px',
      height: '7px',
      left: '-8px',
      top: '7px',
      border: `solid 2px ${colors.black}`,
      borderRadius: '50%',
      backgroundColor: colors.white,
    },
  },
  biggerPoint: {
    ':before': {
      width: '15px',
      height: '15px',
      left: '-12px',
      top: '3px',
    },
  },
  redPoint: {
    borderColor: colors.negative.base,
    ':before': {
      borderColor: colors.negative.base,
    },
  },
  hidePoint: {
    ':before': {
      display: 'none',
    },
  },
  location: {
    fontSize: typography.dp_small_font_size_m,
    letterSpacing: typography.dp_small_letter_spacing,
    lineHeight: typography.dp_small_line_height,
    fontWeight: fonts.dp_font_weight_bold,
    color: colors.gray.alpha58,
    marginLeft: spacers.dp_s16,
  },
  originOrDestinationLocation: {
    fontSize: typography.dp_body_font_size,
    letterSpacing: typography.dp_body_letter_spacing,
    fontWeight: fonts.dp_font_weight_bold,
    lineHeight: typography.dp_body_line_height,
    color: colors.gray.alpha80,
  },
  locationStriked: {
    textDecoration: 'line-through',
  },

  details: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
  },
  railIcon: {
    marginBottom: spacers.dp_s4,
  },
  carrier: {
    ...typographyStyles,
    color: colors.gray.alpha58,
  },
  duration: {
    marginTop: spacers.dp_s4,
  },
  cancelled: {
    ...typographyStyles,
    fontWeight: fonts.dp_font_weight_bold,
    color: colors.negative.base,
  },

  callingPointTimeContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  callingPointTime: {
    ...typographyStyles,
    color: colors.gray.alpha58,
    fontWeight: fonts.dp_font_weight_regular,
  },
  originOrDestinationCallingPointTime: {
    color: colors.gray.alpha80,
    fontWeight: fonts.dp_font_weight_bold,
  },
  callingPointTimeStriked: {
    textDecoration: 'line-through',
    color: colors.gray.alpha58,
    fontWeight: fonts.dp_font_weight_regular,
  },
  callingPointTimeEmpty: {
    width: '36px',
    textAlign: 'center',
  },
  callingPointTimeDelayed: {
    ...typographyStyles,
    color: colors.negative.base,
    fontWeight: fonts.dp_font_weight_bold,
  },
});
