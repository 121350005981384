import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';

import FormattedDateInLondonTz from '@contactcentre-web/common/FormattedDateInLondonTz';
import { getOrderReference } from '@contactcentre-web/redux-common/selectors/order';
import type State from '@contactcentre-web/redux-common/types/State';

import NotesLoading from '../NotesLoading/NotesLoading';
import type { Note } from '../../module';

import messages from './messages';
import styles from './styles';

interface StateProps {
  orderId: string;
}

type Props = StateProps & Note;

export const CustomerNotesItem: FunctionComponent<Props> = ({
  orderId,
  body: message,
  createdAt: date,
  topicId: noteOrderId,
  createdBy: { location, username, displayName },
}) => {
  if (orderId) {
    return (
      <div className={css(styles.customerNotesItem)}>
        <div className={css(styles.container)}>
          {noteOrderId !== orderId ? (
            <div data-test-id="historic" className={css(styles.notesheader)}>
              <FormattedMessage {...messages.historic} />
            </div>
          ) : (
            <div data-test-id="current" className={css(styles.notesheader)}>
              <FormattedMessage {...messages.current} />
            </div>
          )}
          <header className={css(styles.header)}>
            <span className={css(styles.name)}>{displayName}</span>
            <span className={css(styles.username)}>({username})</span>
            <span className={css(styles.location)}>{location}</span>
          </header>
          <div className={css(styles.message)} data-test-id="note-body">
            {message.split('\n').map((p, i) => (
              <div key={i}>{p}</div>
            ))}
          </div>
        </div>
        <div className={css(styles.date)}>
          {' '}
          <FormattedDateInLondonTz
            value={date}
            year="numeric"
            month="short"
            day="numeric"
            weekday="short"
            hour="2-digit"
            minute="2-digit"
          />
        </div>
      </div>
    );
  }

  return <NotesLoading />;
};

const mapStateToProps = (state: State) => ({
  orderId: getOrderReference(state),
});

export default connect(mapStateToProps)(CustomerNotesItem);
