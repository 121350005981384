import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { groupBy } from 'ramda';
import { StatusMessage } from '@trainline/depot-web';
import { css } from 'aphrodite/no-important';

import OriginToDestination from '@contactcentre-web/common/OriginToDestination/OriginToDestination';
import { Journey, Passenger } from '@contactcentre-web/redux-common/types/Journey';
import Link from '@contactcentre-web/common/Link';
import { Modal, ModalHeader, ModalTitle, ModalSubtitle } from '@contactcentre-web/common/Modal';
import { useEditBookingPassengers } from '@contactcentre-web/hooks/api/useBookingPassengers';

import AlterPassengersForm from './AlterPassengersForm/AlterPassengersForm';
import styles from './styles';
import messages from './messages';

interface Props {
  orderId: string;
  bookingId: string;
  journeys: Journey[];
  origin: string;
  destination: string;
  isReturn: boolean;
}

const AlterPassengers = ({
  orderId,
  bookingId,
  journeys,
  origin,
  destination,
  isReturn,
}: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const passengers = journeys
    .map(({ legs }) => legs)
    .flat()
    .map(({ passengers }) => passengers)
    .flat();
  const groupPassengerType = groupBy((passenger: Passenger) => passenger.type);
  const passengersByType = Object.values(groupPassengerType(passengers))
    .map((passengers) =>
      passengers.map(({ type, firstName, lastName, id }, index) => ({
        firstName,
        lastName,
        id,
        typeAndIndex: `${type} ${index + 1}`,
      }))
    )
    .flat();

  const { mutate, isLoading, isError, isSuccess } = useEditBookingPassengers(orderId, bookingId, {
    onSuccess: () => {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    },
  });

  return (
    <>
      <Link linkType="action" styleSheet={styles.editPassenger} onClick={() => setIsVisible(true)}>
        <FormattedMessage {...messages.editPassengerDetails} />
      </Link>
      {isVisible && (
        <Modal onClose={() => setIsVisible(false)}>
          <ModalHeader>
            <ModalTitle>
              <FormattedMessage {...messages.editPassengerDetails} />
            </ModalTitle>
            <ModalSubtitle>
              <OriginToDestination isReturn={isReturn} origin={origin} destination={destination} />
            </ModalSubtitle>
          </ModalHeader>
          {(isError || isSuccess) && (
            <div className={css(styles.errorContainer)}>
              <StatusMessage status={isError ? 'negative' : 'positive'}>
                {isError ? (
                  <FormattedMessage {...messages.editFailed} />
                ) : (
                  <FormattedMessage {...messages.editSuccess} />
                )}
              </StatusMessage>
            </div>
          )}
          <AlterPassengersForm
            initialValues={{ passengers: passengersByType }}
            onSubmitForm={mutate}
            isSubmitting={isLoading}
          />
        </Modal>
      )}
    </>
  );
};

export default AlterPassengers;
