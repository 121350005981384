import React from 'react';
import { css } from 'aphrodite/no-important';

import { getCardTypeDataByCode } from './dataMapper';
import styles from './styles';

export type Card = {
  code: string;
  name: string;
};

interface Props {
  card: Card;
}

const Railcard = ({ card }: Props) => {
  const { color, shortName } = getCardTypeDataByCode(card);

  return (
    <ul className={css(styles.cardList)}>
      <li
        data-test="itinerary-info-railcard"
        style={{
          background: color,
        }}
        className={css(styles.card)}
      >
        {shortName}
      </li>
    </ul>
  );
};

export default Railcard;
