import { StyleSheet } from 'aphrodite/no-important';
import { palettes, typography, fonts, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    flexBasis: '100%',
    marginRight: spacers.dp_s16,
  },

  title: {
    fontSize: typography.dp_small_font_size_m,
    lineHeight: typography.dp_small_font_size_m,
    fontWeight: fonts.dp_font_weight_bold,
    color: palettes.dp_app_info_darkest,
  },

  text: {
    fontSize: typography.dp_mini_font_size_m,
    lineHeight: typography.dp_mini_line_height_m,
    color: palettes.dp_app_text_subdued,
  },

  link: {
    textDecoration: 'underline',
  },
});
