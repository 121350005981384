import { useQuery, UseQueryOptions } from 'react-query';
import urlEncode from 'form-urlencoded';

import request from '@contactcentre-web/utils/request';

type ErrorShape = {
  code: string;
  detail?: string;
  meta?: { context?: Record<string, string | number>; severity?: string };
};

interface ReasonCode {
  id: string;
  name: string;
  parentId: string;
  showDecisionTree: boolean;
}

interface ReasonCodesResponse {
  items: Array<ReasonCode>;
  totalCount: number;
  errors: Array<ErrorShape>;
}

export const useCrmReasonCodes = (
  callId: string,
  options: UseQueryOptions<
    ReasonCodesResponse,
    unknown,
    ReasonCodesResponse,
    `crm-reason-codes-${string}`
  >
) =>
  useQuery(
    `crm-reason-codes-${callId}`,
    () =>
      request<ReasonCodesResponse>(
        `/crm/reasoncodes?${urlEncode({
          integrationType: 'FreshDesk',
          callId,
        })}`,
        { method: 'GET' }
      ),
    {
      retry: 8,
      retryDelay: 15000,
      ...options,
    }
  );
