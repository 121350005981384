import { createActions, handleActions } from 'redux-actions';

export const FORM_ID = 'selectManagedGroup';

// ACTIONS
export const PREFIX = 'SELECT_MANAGED_GROUP';

export const RESET_STATE = 'RESET_STATE';
export const SUBMIT_ATTEMPT = 'SUBMIT_ATTEMPT';
export const SUBMIT_SUCCESS = 'SUBMIT_SUCCESS';
export const SUBMIT_ERROR = 'SUBMIT_ERROR';

export const STATUS_NONE = 'STATUS_NONE';
export const STATUS_SUBMITTING = 'STATUS_SUBMITTING';
export const STATUS_SUCCESS = 'STATUS_SUCCESS';
export const STATUS_ERROR = 'STATUS_ERROR';

export const actions = createActions(
  {
    [RESET_STATE]: () => null,
    [SUBMIT_ATTEMPT]: (managedGroupValue: string) => ({ managedGroupValue }),
    [SUBMIT_SUCCESS]: () => null,
    [SUBMIT_ERROR]: () => null,
  },
  { prefix: PREFIX }
);

export interface SelectManagedGroupState {
  status: string;
}

export const initialState = {
  status: STATUS_NONE,
};

// REDUCER
const reducer = handleActions(
  {
    [RESET_STATE]: () => ({
      ...initialState,
    }),
    [SUBMIT_ATTEMPT]: (state) => ({
      ...state,
      status: STATUS_SUBMITTING,
    }),
    [SUBMIT_SUCCESS]: (state) => ({
      ...state,
      status: STATUS_SUCCESS,
    }),
    [SUBMIT_ERROR]: (state) => ({
      ...state,
      status: STATUS_ERROR,
    }),
  },
  initialState,
  { prefix: PREFIX }
);

export default reducer;
