import { defineMessages } from 'react-intl';

export default defineMessages({
  alert: {
    id: 'SupersededBooking.SupersededBooking.alert',
    defaultMessage: 'This booking was updated and is no longer valid. You can {viewBooking}.',
  },
  viewBooking: {
    id: 'SupersededBooking.SupersededBooking.viewBooking',
    defaultMessage: 'view the updated booking',
  },
});
