import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';

import styles from './styles';

export const DottedLoader = ({ styleSheet, bubbleStyleSheet }) => (
  <div className={css([styles.spinner, styleSheet])}>
    <div className={css([styles.bounce1, bubbleStyleSheet])} />
    <div className={css([styles.bounce2, bubbleStyleSheet])} />
    <div className={css([styles.bounce3, bubbleStyleSheet])} />
  </div>
);

DottedLoader.propTypes = {
  styleSheet: PropTypes.object,
  bubbleStyleSheet: PropTypes.object,
};

export default DottedLoader;
