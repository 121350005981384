import { isNil } from 'ramda';
import { createSelector } from 'reselect';

export const getSelectedCustomer = (state) => state.selectedCustomer;
export const getSelectedCustomerId = createSelector(
  getSelectedCustomer,
  (selectedCustomer) => selectedCustomer?.id
);
export const getCustomerEmail = createSelector(
  getSelectedCustomer,
  (customer) => (customer && customer.email) || null
);
export const getCustomerLoaded = createSelector(
  getSelectedCustomer,
  (customer) => !isNil(customer) && Object.keys(customer).length > 0
);
export const GET_CUSTOMER_SUCCEEDED = 'customers/GET_CUSTOMER_SUCCEDEED';
export const getCustomerSucceeded = (customerId, payload) => ({
  type: GET_CUSTOMER_SUCCEEDED,
  customerId,
  payload,
});
