import localStorage, { LocalStorageKeys } from '@contactcentre-web/utils/localStorage';

export interface UserProfile {
  locale?: string;
  loggedIn?: boolean;
}

interface UserProfiles {
  [key: string]: UserProfile;
}

const getProfiles = () => {
  const profilesString = localStorage.get(LocalStorageKeys.PROFILES_KEY);
  if (!profilesString) {
    return undefined;
  }
  const profiles = JSON.parse(profilesString) as UserProfiles;
  return profiles;
};

export const getUserProfile = (userId: string) => {
  const profiles = getProfiles();
  return profiles && profiles[userId];
};

export const saveUserProfile = (userId: string, profile: UserProfile) => {
  const profiles = getProfiles() || {};

  Object.keys(profiles).forEach((profileUserId) => {
    profiles[profileUserId] = { ...profiles[profileUserId], loggedIn: false };
  });

  profiles[userId] = { ...profile, loggedIn: true };

  localStorage.set(LocalStorageKeys.PROFILES_KEY, JSON.stringify(profiles));
};
