import React, { Dispatch } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import Button from '@contactcentre-web/common/Button';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@contactcentre-web/common/Modal';
import Action from '@contactcentre-web/redux-common/types/Action';
import type State from '@contactcentre-web/redux-common/types/State';

import { actions, selectors } from '../../module';

import messages from './messages';

interface StateProps {
  visible: boolean;
  loading: boolean;
}

interface DispatchProps {
  onCloseClick: () => void;
  onProceedClick: () => void;
}

export const RefreshPnrConfirmation = ({
  visible,
  loading,
  onCloseClick,
  onProceedClick,
}: StateProps & DispatchProps) =>
  visible ? (
    <Modal onClose={onCloseClick}>
      <ModalHeader>
        <ModalTitle>
          <FormattedMessage {...messages.header} />
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <FormattedMessage {...messages.body} />
      </ModalBody>
      <ModalFooter contentAlignment="spaceBetween">
        <Button variant="tertiary" size="large" onClick={onCloseClick} disabled={loading}>
          <FormattedMessage {...messages.cancel} />
        </Button>
        <Button variant="primary" size="large" onClick={onProceedClick} loading={loading}>
          <FormattedMessage {...messages.proceed} />
        </Button>
      </ModalFooter>
    </Modal>
  ) : null;

const mapStateToProps = ({ refreshPnr: state }: State) => ({
  visible: selectors.isConfirmationOpen(state),
  loading: selectors.isLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  onProceedClick: () => dispatch(actions.confirm()),
  onCloseClick: () => dispatch(actions.cancel()),
});

const RefreshConfirmationModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(RefreshPnrConfirmation);
RefreshConfirmationModal.displayName = 'RefreshPnrConfirmation';

export default RefreshConfirmationModal;
