import React, { useState } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { groupBy, compose, pluck, flatten } from 'ramda';
import { IconHelp, IconMultiPassengers, IconPassenger, IconRailcard } from '@trainline/depot-web';

import OriginToDestination from '@contactcentre-web/common/OriginToDestination/OriginToDestination';
import InsuranceIcon from '@contactcentre-web/common/InsuranceIcon/InsuranceIcon';
import FormattedDateInTz from '@contactcentre-web/common/FormattedDateInTz';
import Link from '@contactcentre-web/common/Link/Link';

import EntanglementsModal from '../EntanglementsModal/EntanglementsModal';

import messages from './messages';
import styles from './styles';

const groupPassengerType = groupBy((passenger) => passenger.type);
const groupPassengerRailcard = (passenger, isSDVRefundsCCRSEnabled = false) => {
  if (isSDVRefundsCCRSEnabled) {
    return compose(
      groupBy((p) => p.name),
      flatten,
      pluck('railCards')
    )(passenger);
  }

  return groupBy((p) => p.railcardType)(passenger);
};

const displayPassengersByType = (passengers) => {
  const groupedPassengers = groupPassengerType(passengers);
  return Object.keys(groupedPassengers).map((key) => (
    <div className={css(styles.passengersContainer)} key={key}>
      {groupedPassengers[key].length === 1 ? (
        <IconPassenger className={css(styles.passengersIcon)} />
      ) : (
        <IconMultiPassengers className={css(styles.passengersIcon)} />
      )}
      <FormattedMessage
        {...(messages[`passenger${key.toLowerCase()}`] || messages.passenger)}
        values={{
          passengerCount: groupedPassengers[key].length,
        }}
      />
    </div>
  ));
};

const displayRailCard = (passengers, isSDVRefundsCCRSEnabled) => {
  const passengersGrouped = groupPassengerRailcard(passengers, isSDVRefundsCCRSEnabled);
  const railCards = Object.keys(passengersGrouped)
    .filter((name) => name !== 'null' && name !== 'undefined')
    .map((name) => ({
      name,
      count: passengersGrouped[name].length,
    }));

  if (railCards.length) {
    return (
      <div className={css(styles.railCardContainer)}>
        <IconRailcard className={css(styles.railCardIcon)} />
        {railCards.map((railCard, idx) => (
          <FormattedMessage
            key={idx}
            {...(idx === railCards.length - 1 ? messages.railCard : messages.railCardWithSeparator)}
            values={railCard}
          />
        ))}
      </div>
    );
  }
  return null;
};

const JourneyDetails = ({
  origin,
  destination,
  departAt,
  arriveAt,
  farePassengers,
  isReturn,
  insurance,
  isSDVRefundsCCRSEnabled,
  isReasonCodePerRefundable,
  bookingHasEntanglements,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <div>
      <h2 className={css(styles.bookingTitle)}>
        <OriginToDestination isReturn={isReturn} origin={origin} destination={destination} />
      </h2>
      <div className={css(styles.details)}>
        <div className={css(styles.detailsContainer)}>
          <div className={css(styles.dateRow)}>
            <span className={css(styles.dateLabel)}>
              <FormattedMessage {...messages.depart} />
            </span>
            <span>
              <FormattedDateInTz
                value={departAt}
                year="numeric"
                month="short"
                day="numeric"
                weekday="short"
                hour="2-digit"
                minute="2-digit"
              />
            </span>
          </div>
          <div className={css(styles.dateRow)}>
            <span className={css(styles.dateLabel)}>
              <FormattedMessage {...messages.arrive} />
            </span>
            <span>
              <FormattedDateInTz
                value={arriveAt}
                year="numeric"
                month="short"
                day="numeric"
                weekday="short"
                hour="2-digit"
                minute="2-digit"
              />
            </span>
          </div>
        </div>
        {displayPassengersByType(farePassengers)}
        {displayRailCard(farePassengers, isSDVRefundsCCRSEnabled)}
        {insurance && <InsuranceIcon />}
        {!isReasonCodePerRefundable && bookingHasEntanglements && (
          <div className={css(styles.detailsInfo)}>
            <IconHelp className={css(styles.detailsInfoIcon)} />
            <Link linkType="action" onClick={() => setModalVisible(true)}>
              <FormattedMessage {...messages.entanglements} />
            </Link>
            {modalVisible && <EntanglementsModal setModalVisible={setModalVisible} />}
          </div>
        )}
      </div>
    </div>
  );
};

JourneyDetails.propTypes = {
  origin: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
  departAt: PropTypes.string.isRequired,
  arriveAt: PropTypes.string.isRequired,
  farePassengers: PropTypes.arrayOf(PropTypes.object).isRequired,
  isReturn: PropTypes.bool,
  isSDVRefundsCCRSEnabled: PropTypes.bool,
  isReasonCodePerRefundable: PropTypes.bool.isRequired,
  insurance: PropTypes.object,
  bookingHasEntanglements: PropTypes.bool,
};

export default JourneyDetails;
