import React, { useState } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { StatusMessage } from '@trainline/depot-web';
import moment from 'moment';

import { Modal, ModalHeader, ModalTitle } from '@contactcentre-web/common/Modal';
import Button from '@contactcentre-web/common/Button';
import {
  Passenger,
  useEditCustomerPassenger,
} from '@contactcentre-web/hooks/api/useCustomerPassengers';

import PassengerForm from '../PassengerForm/PassengerForm';

import messages from './messages';
import styles from './styles';

interface Props {
  passenger: Passenger;
}

const getErrorMessageCode = (code: string, isEnd?: boolean): MessageDescriptor | null => {
  const idx = Object.getOwnPropertyNames(messages).indexOf(`${code}ErrorMessage`);
  return idx > -1
    ? Object.values(messages)[idx]
    : !isEnd
    ? getErrorMessageCode(`generic`, true)
    : null;
};

const EditPassenger = ({ passenger }: Props) => {
  const { passengerId, firstName, lastName, dateOfBirth, email, phoneCountryCode, phoneNumber } =
    passenger;

  const { customerId } = useParams<{ customerId: string }>();
  const [isVisible, setIsVisible] = useState(false);
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error } = useEditCustomerPassenger(customerId, passengerId, {
    onSuccess: () => {
      setIsVisible(false);
      queryClient.invalidateQueries('customerPassengers');
    },
  });

  const errors = error?.errors?.errors[0].code.split('.')[1] || 'generic';

  return (
    <>
      <div className={css(styles.buttonContainer)}>
        <Button
          variant="tertiary"
          size="medium"
          fullWidth
          onClick={() => setIsVisible(true)}
          testId="edit-passenger-open-modal"
          styleSheet={styles.editButton}
        >
          <FormattedMessage {...messages.editPassengerButton} />
        </Button>
      </div>
      {isVisible && (
        <Modal onClose={() => setIsVisible(false)} testId="edit-passenger-form-modal">
          <ModalHeader>
            <ModalTitle>
              <FormattedMessage {...messages.editPassengerFormHeader} />
            </ModalTitle>
          </ModalHeader>
          {isError && (
            <div className={css(styles.errorContainer)}>
              <StatusMessage status="negative">
                <FormattedMessage {...getErrorMessageCode(errors)} />
              </StatusMessage>
            </div>
          )}
          <PassengerForm
            onSubmitForm={mutate}
            isSubmitting={isLoading}
            onCancelForm={() => setIsVisible(false)}
            isEditting={true}
            initialValues={{
              firstName: firstName,
              lastName: lastName,
              dateOfBirth: moment(dateOfBirth).format('YYYY-MM-DD'),
              email: email,
              cardNumber: undefined,
              phoneCountryCode: phoneCountryCode ? phoneCountryCode : undefined,
              phoneNumber: phoneNumber ? phoneNumber : undefined,
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default EditPassenger;
