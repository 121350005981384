import { defineMessages } from 'react-intl';

export default defineMessages({
  approveButton: {
    id: 'TracsOnAccountItemValidatorButton.approveButton',
    defaultMessage: 'Approve',
  },
  rejectButton: {
    id: 'TracsOnAccountItemValidatorButton.rejectButton',
    defaultMessage: 'Reject',
  },
  approveHeader: {
    id: 'TracsOnAccountItemValidatorButton.approveHeader',
    defaultMessage: 'Approve and submit this refund?',
  },
  approveConfirm: {
    id: 'TracsOnAccountItemValidatorButton.approveConfirm',
    defaultMessage: 'Approve',
  },
  approveCancel: {
    id: 'TracsOnAccountItemValidatorButton.approveCancel',
    defaultMessage: 'Cancel',
  },
  rejectHeader: {
    id: 'TracsOnAccountItemValidatorButton.rejectHeader',
    defaultMessage: 'Permanently reject this refund?',
  },
  rejectConfirm: {
    id: 'TracsOnAccountItemValidatorButton.rejectConfirm',
    defaultMessage: 'Reject',
  },
  rejectCancel: {
    id: 'TracsOnAccountItemValidatorButton.rejectCancel',
    defaultMessage: 'Cancel',
  },
});
