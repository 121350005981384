import { defineMessages } from 'react-intl';

export default defineMessages({
  incidentReferenceLabel: {
    id: 'CustomerRevocation.incidentReferenceLabel',
    defaultMessage: 'Incident reference',
  },
  customerIdsLabel: {
    id: 'CustomerRevocation.customerIdsLabel',
    defaultMessage: 'Customer IDs',
  },
  exportButton: {
    id: 'CustomerRevocation.exportButton',
    defaultMessage: 'Export',
  },
  revokeCustomersButton: {
    id: 'CustomerRevocation.revokeCustomersButton',
    defaultMessage: 'Revoke Customers',
  },
  uploadCSVButton: {
    id: 'CustomerRevocation.uploadCSVButton',
    defaultMessage: 'Upload File',
  },
  verifyCustomersButton: {
    id: 'CustomerRevocation.verifyCustomersButton',
    defaultMessage: 'Extract IDs',
  },
  revocationResultTableCustomerIdColumn: {
    id: 'CustomerRevocation.revocationResultTableCustomerIdColumn',
    defaultMessage: 'Customer ID',
  },
  revocationResultTableManagedGroupIdColumn: {
    id: 'CustomerRevocation.revocationResultTableManagedGroupIdColumn',
    defaultMessage: 'Managed Group ID',
  },
  revocationResultTableLanguageColumn: {
    id: 'CustomerRevocation.revocationResultTableLanguageColumn',
    defaultMessage: 'Language',
  },
  revocationResultTableStatusColumn: {
    id: 'CustomerRevocation.revocationResultTableStatusColumn',
    defaultMessage: 'Status',
  },
  verificationInfoMessage: {
    id: 'CustomerRevocation.verificationInfoMessage',
    defaultMessage:
      "Please enter between {min} - {max} customer IDs and click the 'Extract IDs' button to validate your input.",
  },
  verificationSuccessMessage: {
    id: 'CustomerRevocation.verificationSuccessMessage',
    defaultMessage:
      'Ready to revoke passwords for {count} customer IDs. Please review IDs above before revoking.',
  },
  verificationInvalidInputRange: {
    id: 'CustomerRevocation.verificationInvalidInputRange',
    defaultMessage:
      '{count} customer IDs detected. Please enter between {min} and {max} customer IDs.',
  },
  duplicatesRemovedWarning: {
    id: 'CustomerRevocation.duplicatesRemovedWarning',
    defaultMessage: 'WARNING: {duplicatesCount} duplicates were removed.',
  },
  moreThanEightCharactersWarning: {
    id: 'CustomerRevocation.moreThanEightCharactersWarning',
    defaultMessage:
      'WARNING: {longerThanEightCharactersEntryCount} entries have more than 8 characters.',
  },
  revokeSuccessfulMessage: {
    id: 'CustomerRevocation.revokeSuccessfulMessage',
    defaultMessage: 'Revoke operation completed. Successful revokes: {successCount}/{count}.',
  },
  revokeFailedMessage: {
    id: 'CustomerRevocation.revokeFailedMessage',
    defaultMessage: 'Revoke operation failed. Server returned {statusCode}',
  },
  revokeInProgressMessage: {
    id: 'CustomerRevocation.revokeInProgressMessage',
    defaultMessage: 'Revoke operation in progress, please hold...',
  },
});
