import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import striptags from 'striptags';

import Tooltip from '@contactcentre-web/common/Tooltip';

import styles from './styles';
import messages from './messages';

const OrderStatus = ({ status, errors = [] }) => {
  const configs = {};

  switch (status) {
    case 'Complete':
      configs.message = messages.successful;
      configs.style = styles.successful;
      break;
    case 'Fulfilment':
      configs.message = messages.fulfilment;
      break;
    case 'PaymentPending':
      configs.message = messages.paymentPending;
      break;
    case 'Failed':
      configs.message = messages.failed;
      configs.style = styles.failed;
      break;
    default:
      configs.message = messages.unknown;
      break;
  }

  return (
    <Tooltip
      data-test-value={configs.message}
      orientation="Below"
      align="Right"
      anchor={
        <span className={css(styles.container, configs.style)}>
          <FormattedMessage {...configs.message} />
        </span>
      }
    >
      {errors.length ? (
        <ul className={css(styles.errorList)}>
          {errors.map((error, i) => (
            <li key={i}>
              {messages[error.code] ? (
                <FormattedMessage {...messages[error.code]} />
              ) : (
                striptags(error.detail)
              )}
            </li>
          ))}
        </ul>
      ) : null}
    </Tooltip>
  );
};

OrderStatus.propTypes = {
  status: PropTypes.string,
  errors: PropTypes.array,
};

export default OrderStatus;
