import { css } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { StatusMessage } from '@trainline/depot-web';

import ProductType from '@contactcentre-web/redux-common/types/ProductType';
import {
  canApproveDiscretionaryRefunds as canApproveDiscretionaryRefundsSelector,
  canWaiveFraudBlocker as canWaiveFraudBlockerSelector,
} from '@contactcentre-web/authentication/redux/selectors';
import { getProducts } from '@contactcentre-web/redux-common/selectors/products';
import Button from '@contactcentre-web/common/Button';
import { DeliveryMethodId } from '@contactcentre-web/redux-common/types/DeliveryMethod';

import discretionarySelectors from '../../Discretionary/selectors';
import SeasonRefundModal from '../../TermsAndConditions/components/SeasonRefundModal';
import RefundModal from '../RefundModal';
import RefundNonProcessableQuotePrompt from '../RefundNonProcessableQuotePrompt';
import { selectors, actions } from '../module';

import { Booking } from './Booking';
import messages from './messages';
import styles from './styles';

const getTicketDetails = (ticketsDetails) => {
  if (Array.isArray(ticketsDetails) && ticketsDetails.length) {
    const [ticketDetails] = ticketsDetails;
    return ticketDetails;
  }
  return null;
};

export class BookingForm extends React.Component {
  static getDerivedStateFromProps(
    { hasErrored, success, requestWarning },
    { confirmationModalVisible }
  ) {
    if (confirmationModalVisible && (hasErrored || success || requestWarning)) {
      return {
        confirmationModalVisible: false,
      };
    }
    return null;
  }

  constructor() {
    super();
    this.refundableUnitListRef = React.createRef();
    this.refundableUnitListRef.current = [];

    this.state = {
      confirmationModalVisible: false,
      isSeasonRefundModalVisible: false,
    };
  }

  onRecalculate() {
    const { currentValues, recalculate } = this.props;
    recalculate(currentValues);
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { confirmPrompt } = this.props;
    if (confirmPrompt) {
      this.showConfirmationModal();
    } else {
      this.dispatchRefundRequest();
    }
  };

  dispatchRefundRequest() {
    const { handleSubmit } = this.props;
    handleSubmit();
  }

  showConfirmationModal() {
    this.setState({
      confirmationModalVisible: true,
    });
  }

  hideConfirmationModal() {
    this.setState({
      confirmationModalVisible: false,
    });
  }

  showSeasonRefundModal() {
    this.setState({
      isSeasonRefundModalVisible: true,
    });
  }

  hideSeasonRefundModal() {
    this.setState({
      isSeasonRefundModalVisible: false,
    });
  }

  applyReasonCodeToAllFaresFares(reasonCode) {
    this.props.refundables.forEach((refundableId) => {
      this.props.change(`fareRefundables.${refundableId}.reasonCode`, reasonCode);
    });
  }

  updateRefundableUnitListRef(refundableId, refundableUnit) {
    this.refundableUnitListRef.current[refundableId] = refundableUnit;
  }

  selectValidCombinations(allowedCombinations) {
    const TIME_TO_FADEOUT_IN_MS = 1500;

    this.props.refundables.forEach((refundableId) => {
      if (allowedCombinations.includes(refundableId)) {
        this.props.change(`fareRefundables.${refundableId}.isSelected`, true);
        const refundableRef = this.refundableUnitListRef.current[refundableId];

        if (!refundableRef) {
          return;
        }

        const initialClassNames = refundableRef.className;
        const changeRefundableClassName = (className) => {
          refundableRef.className = className;
        };

        changeRefundableClassName(`${initialClassNames} ${css(styles.selectedBox)}`);

        setTimeout(() => changeRefundableClassName(initialClassNames), TIME_TO_FADEOUT_IN_MS);
      } else {
        this.props.change(`fareRefundables.${refundableId}.isSelected`, false);
      }
    });
  }

  render() {
    const {
      index,
      booking,
      pending,
      hasErrored,
      selectionUpdated,
      updateQuoteError,
      canRequest,
      canProcess,
      confirmPrompt,
      actionMessages: { request = messages.requestRefund, process = messages.processRefund } = {
        request: messages.requestRefund,
        process: messages.processRefund,
      },
      refreshingQuote,
      selectionValid,
      hasActiveRefundables,
      hasLoadQuoteFailed,
      isApproveRefundPending,
      approveRefundError,
      onOverride,
      canWaiveFraudBlocker,
      canApproveDiscretionaryRefunds,
      noPermissionsAlert,
      orderReference,
      products,
      currentValues,
      requestRefundErrors,
      canRequestRefund,
    } = this.props;
    const {
      id,
      type,
      refundable,
      isOverridable,
      isOverriden,
      quoteUuid,
      deliveryMethod,
      unprocessableReasons,
    } = booking;
    const { confirmationModalVisible, isSeasonRefundModalVisible } = this.state;
    const hasPermissions = noPermissionsAlert === undefined;
    const displayManualRefundButton =
      type === ProductType.Season &&
      [
        DeliveryMethodId.AtocKiosk,
        DeliveryMethodId.AtocFirstClassPost,
        DeliveryMethodId.AtocSpecialDelivery,
      ].includes(deliveryMethod);
    const [product] = products;
    const ticketDetails = getTicketDetails(product.ticketDetails);
    const supportedRequestedRefundErrors = requestRefundErrors?.find((error) => messages[error]);
    const hasApproveRefundUnprocessableReasons =
      (approveRefundError?.unprocessableReasons?.length ?? 0) !== 0;
    const refundLessThanZeroFee =
      unprocessableReasons.length && unprocessableReasons[0].reasonCode === 'lessThanZero';
    const disableRequestRefundButton =
      (!refundable && !isOverriden) ||
      !canRequest ||
      selectionUpdated ||
      !!updateQuoteError ||
      refreshingQuote ||
      !canRequestRefund ||
      !!refundLessThanZeroFee;

    return (
      <form
        className={css(styles.form)}
        onSubmit={(e) => this.handleSubmit(e)}
        data-test-id={`booking.${id}`}
      >
        <Booking
          {...this.props}
          orderReference={orderReference}
          productId={product.id}
          ticketDetails={ticketDetails}
          number={index}
          pending={pending}
          refreshingQuote={refreshingQuote}
          isRefundable={canProcess || canRequest}
          onRecalculate={() => this.onRecalculate()}
          canUpdateQuote={selectionUpdated && selectionValid}
          updateQuoteError={updateQuoteError}
          hasActiveRefundables={hasActiveRefundables}
          hasLoadQuoteFailed={hasLoadQuoteFailed}
          isOverridable={isOverridable || false}
          overriding={isOverriden}
          noPermissionsAlert={noPermissionsAlert}
          canOverrideLastUsedDate={!!booking.customisations?.lastUsedDate}
          canApproveDiscretionaryRefunds={canApproveDiscretionaryRefunds}
          refundLessThanZeroFee={refundLessThanZeroFee}
          applyReasonCodeToAllFares={(reasonCode) =>
            this.applyReasonCodeToAllFaresFares(reasonCode)
          }
          selectValidCombinations={(allowedCombinations) =>
            this.selectValidCombinations(allowedCombinations)
          }
          updateRefundableUnitListRef={(refundableId, refundableUnit) =>
            this.updateRefundableUnitListRef(refundableId, refundableUnit)
          }
        />
        {confirmationModalVisible && (
          <RefundModal
            header={confirmPrompt.header}
            onCloseClick={() => this.hideConfirmationModal()}
            onCancelClick={() => (!pending ? this.hideConfirmationModal(false) : null)}
            onProceedClick={() => this.dispatchRefundRequest()}
            disabledButton={pending}
            processButtonTestId={`refundModalProcessButton-${index}`}
            loading={pending}
          >
            {confirmPrompt.body}
          </RefundModal>
        )}
        <div
          className={css(
            displayManualRefundButton ? styles.buttonsContainer : styles.buttonContainer
          )}
        >
          {displayManualRefundButton && (
            <>
              {isSeasonRefundModalVisible && (
                <SeasonRefundModal onClose={() => this.hideSeasonRefundModal()} />
              )}
              <Button
                variant="primary"
                size="xlarge"
                onClick={() => this.showSeasonRefundModal()}
                styleSheet={styles.button}
                testId="manual-refund"
              >
                <FormattedMessage {...messages.manualRefund} />
              </Button>
            </>
          )}
          {(!hasActiveRefundables || quoteUuid) && !hasLoadQuoteFailed && hasPermissions && (
            <>
              {canProcess ? (
                <Button
                  type="submit"
                  variant="primary"
                  size="xlarge"
                  testId={`processRefund-${index}`}
                  disabled={
                    (!refundable && !isOverriden) ||
                    selectionUpdated ||
                    !!updateQuoteError ||
                    refreshingQuote
                  }
                  loading={isApproveRefundPending}
                  styleSheet={styles.button}
                >
                  <FormattedMessage {...process} />
                </Button>
              ) : (
                <>
                  {isOverridable && !isOverriden ? (
                    canWaiveFraudBlocker && (
                      <RefundNonProcessableQuotePrompt
                        onOverride={() => onOverride(currentValues)}
                        isEnablingOverride={refreshingQuote}
                      />
                    )
                  ) : (
                    <Button
                      type="submit"
                      variant="primary"
                      size="xlarge"
                      testId={`requestRefund-${index}`}
                      disabled={disableRequestRefundButton}
                      loading={pending}
                      styleSheet={styles.button}
                    >
                      <FormattedMessage {...request} />
                    </Button>
                  )}
                </>
              )}
            </>
          )}
        </div>
        {hasErrored && !supportedRequestedRefundErrors && (
          <StatusMessage status="negative">
            <FormattedMessage {...messages.error} testId="RefundFailed" />
          </StatusMessage>
        )}
        {updateQuoteError && (
          <StatusMessage status="negative">
            <FormattedMessage {...messages.updateQuoteError} testId="QuoteUpdateFailed" />
          </StatusMessage>
        )}
        {hasApproveRefundUnprocessableReasons && (
          <StatusMessage status="negative">
            {approveRefundError.status === 404 ? (
              <FormattedMessage {...messages.approveRefundNotFound} testId="approveRefundFailed" />
            ) : (
              <FormattedMessage
                {...messages.approveRefundDefaultError}
                testId="approveRefundFailed"
              />
            )}
          </StatusMessage>
        )}
        {!canRequestRefund && (
          <StatusMessage status="negative">
            <FormattedMessage
              {...messages.discretionaryRefundNoEquivalentFareError}
              testId="noEquivalentFareAvailableError"
            />
          </StatusMessage>
        )}
        {requestRefundErrors?.map(
          (error, idx) =>
            messages[error] && (
              <div key={idx} className={css(styles.refundErrorMessage)}>
                <StatusMessage status="negative">
                  <FormattedMessage {...messages[error]} testId={`refundError-${error}`} />
                </StatusMessage>
              </div>
            )
        )}
      </form>
    );
  }
}

BookingForm.propTypes = {
  index: PropTypes.number.isRequired,
  orderReference: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  bookingId: PropTypes.string.isRequired,
  booking: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string.isRequired,
    refundable: PropTypes.bool.isRequired,
    quoteUuid: PropTypes.string,
    isOverridable: PropTypes.bool,
    isOverriden: PropTypes.bool,
    customisations: PropTypes.shape({
      lastUsedDate: PropTypes.shape({
        value: PropTypes.instanceOf(Date),
        isDefault: PropTypes.bool,
      }),
    }),
    deliveryMethod: PropTypes.string,
    unprocessableReasons: PropTypes.arrayOf(
      PropTypes.shape({
        reasonCode: PropTypes.string,
      })
    ),
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pending: PropTypes.bool.isRequired,
  refreshingQuote: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  requestWarning: PropTypes.bool.isRequired,
  hasErrored: PropTypes.bool.isRequired,
  selectionUpdated: PropTypes.bool.isRequired,
  updateQuoteError: PropTypes.object,
  canRequest: PropTypes.bool.isRequired,
  canProcess: PropTypes.bool.isRequired,
  confirmPrompt: PropTypes.shape({
    header: PropTypes.any.isRequired,
    body: PropTypes.any.isRequired,
  }),
  actionMessages: PropTypes.shape({
    request: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    process: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  recalculate: PropTypes.func.isRequired,
  currentValues: PropTypes.object,
  selectionValid: PropTypes.bool.isRequired,
  hasActiveRefundables: PropTypes.bool.isRequired,
  hasLoadQuoteFailed: PropTypes.bool.isRequired,
  isApproveRefundPending: PropTypes.bool.isRequired,
  approveRefundError: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  onOverride: PropTypes.func,
  canWaiveFraudBlocker: PropTypes.bool.isRequired,
  canApproveDiscretionaryRefunds: PropTypes.bool.isRequired,
  noPermissionsAlert: PropTypes.object,
  products: PropTypes.array,
  requestRefundErrors: PropTypes.array,
  refundables: PropTypes.array,
  change: PropTypes.func.isRequired,
  canRequestRefund: PropTypes.bool,
  isEntanglementWarningDisplayed: PropTypes.bool,
  bookingHasEntanglements: PropTypes.bool,
};

const mapStateToPropsFactory = (_, ownProps) => {
  const isSelectionUpdatedSelector = selectors.isSelectionUpdatedFactory(ownProps.bookingId);
  const isSelectionValidSelector = selectors.isSelectionValidFactory(ownProps.bookingId);
  const isPendingSelector = selectors.isPendingFactory(ownProps.bookingId);
  const isSuccessSelector = selectors.isSuccessFactory(ownProps.bookingId);
  const isWarningSelector = selectors.isWarningFactory(ownProps.bookingId);
  const isErrorSelector = selectors.isErrorFactory(ownProps.bookingId);
  const updateQuoteErrorSelector = selectors.updateQuoteErrorFactory(ownProps.bookingId);
  const isRefreshingQuoteSelector = selectors.isRefreshingQuoteFactory(ownProps.bookingId);

  const formValuesSelector = (state) =>
    state.form[ownProps.form] &&
    state.form[ownProps.form].values && {
      ...state.form[ownProps.form].values,
    };

  const hasLoadQuoteFailedSelector = selectors.hasLoadQuoteFailedFactory(ownProps.bookingId);
  const isApproveRefundPending = discretionarySelectors.isApproveRefundPending(ownProps.bookingId);
  const approveRefundErrorSelector = discretionarySelectors.getApproveRefundError(
    ownProps.bookingId
  );
  const requestRefundErrors = selectors.requestRefundErrors(ownProps.bookingId);
  const canRequestRefund = selectors.canRequestRefund(ownProps.bookingId);
  const isBannerInvalidEntanglements = selectors.isBannerInvalidEntanglements(ownProps.bookingId);

  return (state) => ({
    pending: isPendingSelector(state),
    success: isSuccessSelector(state),
    requestWarning: isWarningSelector(state),
    hasErrored: isErrorSelector(state),
    selectionUpdated: isSelectionUpdatedSelector(state),
    selectionValid: isSelectionValidSelector(state),
    updateQuoteError: updateQuoteErrorSelector(state),
    refreshingQuote: isRefreshingQuoteSelector(state),
    currentValues: formValuesSelector(state),
    hasLoadQuoteFailed: hasLoadQuoteFailedSelector(state),
    isApproveRefundPending: isApproveRefundPending(state),
    approveRefundError: approveRefundErrorSelector(state),
    canWaiveFraudBlocker: canWaiveFraudBlockerSelector(state),
    canApproveDiscretionaryRefunds: canApproveDiscretionaryRefundsSelector(state),
    requestRefundErrors: requestRefundErrors(state),
    products: getProducts(state),
    canRequestRefund: canRequestRefund(state),
    isEntanglementWarningDisplayed: isBannerInvalidEntanglements(state),
    bookingHasEntanglements: selectors.bookingHasEntanglements(state),
  });
};

function validateSelectionForRequote({ fareRefundables, railcardRefundables }) {
  const errors = {};

  const isSomeRefundableSelected = (refundables) =>
    Object.values(refundables).some((refundable) => !!refundable.isSelected);

  const mapErrors = (refundables) =>
    Object.keys(refundables).reduce(
      (acc, key) => ({
        ...acc,
        [key]: {
          isSelected: 'required',
        },
      }),
      {}
    );

  if (
    !isSomeRefundableSelected(fareRefundables) &&
    !isSomeRefundableSelected(railcardRefundables)
  ) {
    errors.fareRefundables = mapErrors(fareRefundables);
    errors.railcardRefundables = mapErrors(railcardRefundables);
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors,
  };
}

const getSelection = (
  { adminFee, fareRefundables = [], railcardRefundables = [] },
  refundReasons
) => {
  const fareRefundableIds = Object.keys(fareRefundables).filter(
    (refundableId) => !!fareRefundables[refundableId].isSelected
  );
  const railcardRefundableIds = Object.keys(railcardRefundables).filter(
    (refundableId) => !!railcardRefundables[refundableId].isSelected
  );

  // BE doesn't receive a reason type per refundable, but since all the refund reasons have the same type
  // we can pick the reason type from one selected refundable with reason code
  // a reason code is considered selected only if it's not empty or the value is different from not-set
  const oneSelectedReasonCode = Object.values(fareRefundables).find(
    (refundable) => !!refundable.reasonCode && refundable.reasonCode !== 'not-set'
  )?.reasonCode;
  const refundReasonByCode = refundReasons.find(({ id }) => id === oneSelectedReasonCode);

  const mapRefundable = (refundableIds, refundables) =>
    refundableIds.map((refundableId) => ({
      id: refundableId,
      reasonCode: refundables[refundableId].reasonCode,
      ...(refundables[refundableId].overrideAmount && {
        overrideAmount: refundables[refundableId].overrideAmount,
      }),
    }));

  const refundables = [
    ...mapRefundable(fareRefundableIds, fareRefundables),
    ...mapRefundable(railcardRefundableIds, railcardRefundables),
  ];

  return {
    adminFee: { deducted: adminFee || false },
    reason: refundReasonByCode?.type && {
      type: refundReasonByCode.type,
    },
    refundables,
  };
};

const getValidationErrors = ({ fareRefundables, railcardRefundables }) => {
  let { errors } = validateSelectionForRequote({ fareRefundables, railcardRefundables });
  //  on re-calculate without any reason, the BE sends back the reasonID with not-set
  const isReasonCodeInvalid = (reasonCodeToTest) =>
    reasonCodeToTest === null || reasonCodeToTest === undefined || reasonCodeToTest === 'not-set';

  const validateRefundableReasons = (refundables) =>
    Object.keys(refundables)
      .filter(
        (refundableId) =>
          refundables[refundableId].isSelected &&
          isReasonCodeInvalid(refundables[refundableId].reasonCode)
      )
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: {
            reasonCode: 'required',
          },
        }),
        {}
      );

  errors = {
    ...errors,
    fareRefundables: validateRefundableReasons(fareRefundables),
    railcardRefundables: validateRefundableReasons(railcardRefundables),
  };

  return {
    isValid: Object.keys(errors).length === 0,
    errors,
  };
};

const handleRequote = (values, dispatch, props, overridden) => {
  const validationResult = validateSelectionForRequote(values);
  if (!validationResult.isValid) {
    return;
  }

  const selection = {
    ...getSelection(values, props.refundReasons),
  };

  const requestPayload = {
    orderReference: props.orderReference,
    orderId: props.orderId,
    bookingId: props.bookingId,
    quoteReference: props.quoteReference,
    isOverriding: overridden || props.booking.isOverriden,
    selection,
  };

  if (
    props.booking.customisations?.lastUsedDate &&
    (props.booking.customisations?.lastUsedDate?.isDefault ||
      values.overrideLastUsedDate !== props.booking.customisations?.lastUsedDate?.value)
  ) {
    requestPayload.overrideLastUsedDate = values.overrideLastUsedDate;
  }

  dispatch(actions.updateQuote(requestPayload));
};

const validateForm = (values) => {
  if (Object.getOwnPropertyNames(values).length === 0) {
    return {};
  }
  const validationResult = getValidationErrors(values);
  if (validationResult.isValid) {
    return {};
  }

  return validationResult.errors;
};

export const handleChange = (values, dispatch, props) => {
  if (Object.getOwnPropertyNames(values).length === 0 || !props.anyTouched) {
    return;
  }

  const { booking, refundType } = props;

  const entanglementCombinations = [...booking.journeys, ...booking.localAreaJourneys].flatMap(
    ({ farePassengers }) =>
      farePassengers.flatMap(({ tickets }) =>
        tickets
          .filter(
            ({ entanglementEnforcementMode, allowedEntanglementCombinations }) =>
              entanglementEnforcementMode === 'strict' && allowedEntanglementCombinations
          )
          .flatMap(({ allowedEntanglementCombinations }) => allowedEntanglementCombinations)
      )
  );

  if (
    refundType &&
    refundType === 'termsAndConditions' &&
    entanglementCombinations.length &&
    values.fareRefundables
  ) {
    const ticketsSelected = Object.keys(values.fareRefundables).filter(
      (key) => values.fareRefundables[key].isSelected
    );

    const arraysEqual = (array1, array2) => {
      const array1Sorted = array1.slice().sort();
      const array2Sorted = array2.slice().sort();
      return (
        array1.length === array2.length &&
        array2Sorted.every((element, index) => element === array1Sorted[index])
      );
    };

    const entangledRefundableTickets = [...new Set(entanglementCombinations.flat())];

    const selectedEntangledRefundableTickets = ticketsSelected.filter((id) =>
      entangledRefundableTickets.includes(id)
    );

    const isValidCombination = entanglementCombinations.some(
      (entangledCombination) =>
        arraysEqual(selectedEntangledRefundableTickets, entangledCombination) ||
        selectedEntangledRefundableTickets.length === 0
    );

    dispatch(actions.entanglementInvalidCombination(!isValidCombination));
  }
  // Apparently props.valid is not indicating the current state of validation.
  // Will trigger validation manually.

  const result = validateSelectionForRequote(values);
  dispatch(actions.selectionUpdated(props.bookingId, result.isValid));
};

const handleSubmit = (values, dispatch, props) =>
  dispatch(
    actions.requestRefund(
      props.orderReference,
      props.bookingId,
      props.quoteReference,
      props.tmcQuoteReference,
      props.customerId
    )
  );

const mapDispatchToProps = (dispatch, ownProps) => ({
  recalculate: (values) => handleRequote(values, dispatch, ownProps),
  onOverride: (values) => handleRequote(values, dispatch, ownProps, true),
});

export default connect(
  mapStateToPropsFactory,
  mapDispatchToProps
)(
  reduxForm({
    destroyOnUnmount: true,
    touchOnChange: true,
    validate: validateForm,
    onChange: handleChange,
    onSubmit: handleSubmit,
  })(BookingForm)
);
