import { StyleSheet } from 'aphrodite/no-important';
import { palettes, borderRadius } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  button: {
    width: '24px',
    height: '24px',
    border: `1px solid ${palettes.dp_app_info_lighter}`,
    borderRadius: borderRadius.dp_r4,
    backgroundColor: palettes.dp_app_backdrop_dark,
  },
  badge: {
    position: 'fixed',
    left: '45%',
    top: '100%',
    zIndex: 2,
    opacity: 0,
    transition: '0.3s',
    transform: 'translateY(0)',
  },
  badgeVisible: {
    opacity: 1,
    transition: '0.3s',
    transform: 'translateY(-100px)',
  },
});
