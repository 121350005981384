import React from 'react';
import { FormattedMessage } from 'react-intl';
import { path } from 'ramda';
import { camelCase } from 'lodash';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';
import StatusMessage, {
  StatusMessageProps,
} from '@trainline/depot-web/components/Messages/StatusMessage';

import messages from './messages';

type Code = {
  code: string;
};

type QuoteCondition = {
  reason: Code;
};

export type MessageCodeType =
  | 'railcardOrPromotionSplit'
  | 'childFlatFareInBooking'
  | 'saverHalfReturnNotRefunded'
  | 'splitSavePartOfSegmentRefunded';

interface Props {
  messageCode: MessageCodeType;
  testId?: string;
  level?: StatusMessageProps['status'];
  styleSheet?: StyleDeclarationValue;
}

const entanglementCodes = Object.keys(messages);

const isKnownEntanglementWarning = (code: string) =>
  entanglementCodes.some((message) => message === code);

const mapToEntanglementCode = (quoteCondition: QuoteCondition) =>
  quoteCondition.reason?.code?.startsWith('entanglementWarning')
    ? camelCase((path(['reason', 'code'], quoteCondition) as string).split('.')[1])
    : null;

export const filterEntanglementWarnings = (quoteConditions: Array<QuoteCondition>) =>
  quoteConditions?.reduce((entanglements: Array<string>, quoteCondition: QuoteCondition) => {
    const condition = mapToEntanglementCode(quoteCondition);

    if (condition) {
      entanglements.push(condition);
    }

    return entanglements;
  }, []);

const EntanglementWarning = ({ messageCode, level = 'warning', testId, styleSheet }: Props) => (
  <div className={css(styleSheet)} data-testid={testId}>
    <StatusMessage status={level}>
      {isKnownEntanglementWarning(messageCode) ? (
        <FormattedMessage {...messages[messageCode]} />
      ) : (
        <FormattedMessage {...messages.entanglementWarning} />
      )}
    </StatusMessage>
  </div>
);

export default EntanglementWarning;
