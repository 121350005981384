import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';

import FormattedBookings from '@contactcentre-web/common/FormattedBookings';

import styles from './styles';
import messages from './messages';

const ExternalPaymentLabel = ({ externalPaymentBookings, allFailsAreExternalPaymentBookings }) => (
  <div
    className={
      allFailsAreExternalPaymentBookings ? null : css(styles.external_payment_recommendations)
    }
  >
    <FormattedMessage
      {...messages.reimbursement_fail_notice_external_payment}
      values={{
        bookings: <FormattedBookings bookings={externalPaymentBookings} />,
      }}
    />
  </div>
);

ExternalPaymentLabel.propTypes = {
  allFailsAreExternalPaymentBookings: PropTypes.bool.isRequired,
  externalPaymentBookings: PropTypes.arrayOf(
    PropTypes.shape({
      origin: PropTypes.string.isRequired,
      destination: PropTypes.string.isRequired,
      isReturn: PropTypes.bool,
    })
  ),
};

export default ExternalPaymentLabel;
