import { StyleSheet } from 'aphrodite/no-important';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  grayLabel: {
    whiteSpace: 'pre',
    color: colors.gray.alpha58,
  },
  address: {
    color: colors.black,
  },
});
