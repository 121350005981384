import React, { useState, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import Overlay from '@trainline-shareable/overlay';
import { Heading, IconInfo, IconRailcard } from '@trainline/depot-web';

import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency';
import Tooltip from '@contactcentre-web/common/Tooltip';
import Checkbox from '@contactcentre-web/common/Checkbox';
import TicketFromTo from '@contactcentre-web/common/TicketFromTo/TicketFromTo';
import TicketStatusModal, {
  ticketUsageStatusTypes,
} from '@contactcentre-web/customer-order/components/TicketStatusModal';
import TicketType from '@contactcentre-web/customer-order/components/TicketType';
import Button from '@contactcentre-web/common/Button';
import Price from '@contactcentre-web/redux-common/types/Price';
import { Railcard, RefundableTicket } from '@contactcentre-web/refunds/types';

import ValidRefundCombinationsCarousel from '../ValidRefundCombinationsCarousel/ValidRefundCombinationsCarousel';
import RefundReason from '../RefundReason';
import RailcardRefundable from '../RailcardRefundable/RailcardRefundable';
import RefundAmount from '../RefundAmount/RefundAmount';
import { RefundableStatus } from '../RefundableStatus/RefundableStatus';

import messages from './messages';
import styles from './styles';

export type RefundableUnitProps = RefundableTicket & {
  orderReference: string;
  productId: string;
  ticketDocuments: [];
  fareConditions: {
    name: string;
    description: string;
  }[];
  name: string;
  farePassengerId: string;
  deliveryMethod: string;
  customerPrice: Price;
  price: Price;
  railCards: { name: string }[];
  disabled?: boolean;
  originStation?: string;
  destinationStation?: string;
  seatReservation?: {
    seatNumber: string;
    compartment?: {
      identifier: string;
    };
  };
  isReasonCodePerRefundable: boolean;
  reasonsCode?: {
    reasons: [];
    disabled?: boolean;
    refundType: string;
    isUsedTicketRefund?: boolean;
  };
  applyReasonCodeToAllFares?: () => void;
  selectValidCombinations: (refundables: string[]) => void;
  isApplyAllVisible?: boolean;
  entanglements: string[][];
  friendlyTicketIndexMapping?: Record<string, string>;
  updateRefundableUnitListRef: (refundableId: string, element: HTMLElement) => void;
  productType?: string;
  railcard: Railcard;
  bookingHasEntanglements?: boolean;
  formName: string;
  formSection: string;
  refundLessThanZeroFee?: boolean;
  friendlyTicketIndexes: string[];
  localAreaValidity?: string;
  passengersCount?: Record<string, number>;
} & { passengerType: string };

export const RefundableUnit = ({
  refundableId,
  productId,
  orderReference,
  ticketDocuments,
  fareType,
  farePassengerId,
  fareConditions,
  deliveryMethod,
  customerPrice,
  price,
  name,
  refundable: isRefundable,
  notRefundableReason,
  notRefundableReasonDetail,
  railCards,
  isChangeable,
  isSelected,
  disabled,
  originStation,
  destinationStation,
  seatReservation,
  entanglements,
  friendlyTicketIndexMapping,
  isReasonCodePerRefundable,
  reasonsCode,
  applyReasonCodeToAllFares,
  selectValidCombinations,
  isApplyAllVisible,
  updateRefundableUnitListRef,
  productType,
  railcard,
  bookingHasEntanglements,
  formName,
  formSection,
  refundLessThanZeroFee,
  refundableAmount,
  localAreaValidity,
  canOverrideVoidableAmounts,
  passengersCount = {},
  friendlyTicketIndexes = [],
  passengerType,
}: RefundableUnitProps) => {
  const ref = useRef(null);
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const isRefundDisabled = disabled || !isChangeable || !!refundLessThanZeroFee;
  const seatReservationTxt =
    seatReservation && seatReservation.seatNumber && seatReservation.compartment?.identifier
      ? `${seatReservation.compartment.identifier} / ${seatReservation.seatNumber}`
      : null;

  const passengersCountLabel = Object.entries(passengersCount)
    .map(([groupedPassengerType, passengerCount]) =>
      formatMessage(
        messages[`passenger${groupedPassengerType.toLowerCase()}` as keyof typeof messages],
        {
          passengerCount,
        }
      )
    )
    .join(', ');

  const passengerTypeLabel = passengersCountLabel || name || passengerType;

  return (
    <section
      ref={(refCallback) => refCallback && updateRefundableUnitListRef(refundableId, refCallback)}
      className={css(styles.ticket)}
    >
      <div className={css(styles.checkBox)}>
        <Checkbox
          name="isSelected"
          value={isSelected}
          disabled={isRefundDisabled}
          errors={{
            required: formatMessage(messages.validation_refundablesRequired),
          }}
        />
      </div>
      {productType === 'railcard-product' ? (
        <RailcardRefundable
          railcardData={railcard}
          refundableQuote={refundableAmount}
          isRefundable={isRefundable}
          notRefundableReason={notRefundableReason}
        />
      ) : (
        <div className={css(styles.unit)}>
          <div className={css(styles.ticketDetails)}>
            <div>
              {!!localAreaValidity && (
                <>
                  <div className={css(styles.label)}>
                    <FormattedMessage {...messages.localAreaValidity} />
                  </div>

                  <p className={css(styles.labelText)}>{localAreaValidity}</p>
                </>
              )}

              {!localAreaValidity && (
                <>
                  <div className={css(styles.label)}>
                    <FormattedMessage {...messages.originDestination} />
                  </div>
                  {originStation && destinationStation ? (
                    <TicketFromTo
                      isUnlimitedSize
                      origin={originStation}
                      destination={destinationStation}
                    />
                  ) : (
                    <p className={css(styles.labelText)}>
                      <FormattedMessage {...messages.notApplicable} />
                    </p>
                  )}
                </>
              )}
            </div>
            <div>
              <h4 className={css(styles.label)}>
                <FormattedMessage {...messages.refundTicket} />
              </h4>
              {fareType ? (
                <TicketType ticket={{ type: fareType, fareConditions }} />
              ) : (
                <p className={css(styles.labelText)}>
                  <FormattedMessage {...messages.notApplicable} />
                </p>
              )}
            </div>
            <div>
              <>
                <h4 className={css(styles.label)}>
                  <FormattedMessage {...messages.coachSeat} />
                </h4>
                <p className={css(styles.labelText)} data-test-id="seat-reservation">
                  {seatReservationTxt || (
                    <FormattedMessage
                      data-test-id="seat-reservation-not-applicable"
                      {...messages.NA}
                    />
                  )}
                </p>
              </>
            </div>
            <div
              className={isReasonCodePerRefundable ? '' : css(styles.ticketSelectionHelperWrapper)}
            >
              <div className={isReasonCodePerRefundable ? '' : css(styles.ticketRefundableCheck)}>
                <h4 className={css(styles.label)}>
                  <FormattedMessage {...messages.refundable} />
                </h4>
                <RefundableStatus
                  isRefundable={isRefundable}
                  notRefundableReason={notRefundableReason}
                  notRefundableReasonDetail={notRefundableReasonDetail}
                />
              </div>
              {!isReasonCodePerRefundable && bookingHasEntanglements && (
                <>
                  {isSelected ? (
                    <>
                      <Button
                        testId="selectionHelperButton"
                        styleSheet={styles.selectionHelperButton}
                        buttonRef={ref}
                        onClick={() => setIsOpen(true)}
                        variant="ghost"
                      >
                        <IconInfo />
                      </Button>
                      <Overlay
                        testId="selectionHelperOverlay"
                        isOpen={isOpen}
                        onClose={() => setIsOpen(false)}
                        id="overlay"
                        type="popover"
                        position="bottom_right"
                        anchorRef={ref}
                        hideHeader
                        showNubbin
                        size={400}
                      >
                        <Heading typeStyle="title3" as="h5" color="base">
                          <FormattedMessage {...messages.tooltipTitle} />
                        </Heading>
                        <ValidRefundCombinationsCarousel
                          validCombinations={entanglements}
                          friendlyTicketIndexMapping={friendlyTicketIndexMapping}
                          setIsOpen={setIsOpen}
                          selectValidCombinations={selectValidCombinations}
                        />
                      </Overlay>
                    </>
                  ) : (
                    <Tooltip
                      data-testid="selectionHelperTooltip"
                      orientation="Above"
                      anchor={
                        <Button styleSheet={styles.selectionHelperButton} disabled variant="ghost">
                          <IconInfo />
                        </Button>
                      }
                    >
                      {isRefundable ? (
                        <FormattedMessage
                          {...messages.tooltipDisableSelection}
                          data-testId="tooltipDisableSelection"
                        />
                      ) : (
                        <FormattedMessage
                          {...messages.tooltipNonRefundableSelection}
                          data-testId="tooltipNonRefundableSelection"
                        />
                      )}
                    </Tooltip>
                  )}
                </>
              )}
            </div>
            <div>
              <h4 className={css(styles.label)}>
                <FormattedMessage {...messages.railcards} />
              </h4>
              <div data-testid="refundable-unit-railcards">
                {railCards && railCards.length > 0 ? (
                  <ul>
                    {railCards.map((railCard, index) => (
                      <li className={css(styles.railcardsList)} key={index}>
                        <div className={css(styles.railcardContainer)}>
                          <IconRailcard />
                          <span className={css(styles.railcardLabel, styles.labelText)}>
                            {railCard.name}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className={css(styles.labelText)}>
                    <FormattedMessage {...messages.NA} />
                  </div>
                )}
              </div>
            </div>
            <div>
              <div>
                <h4 className={css(styles.label)}>
                  <FormattedMessage {...messages.price} />
                </h4>
                <p className={css(styles.labelText)}>
                  {customerPrice?.amount > 0 ? (
                    <FormattedCurrency data-test-id="price" {...customerPrice} />
                  ) : (
                    <FormattedMessage data-test-id="no-price" {...messages.NA} />
                  )}
                </p>
              </div>
            </div>
            <div>
              <h4 className={css(styles.label)}>
                <FormattedMessage {...messages.RefundableAmount} />
              </h4>
              <p className={css(styles.labelText)}>
                <RefundAmount
                  refundableQuote={refundableAmount}
                  productPrice={customerPrice ?? price}
                  refundType={reasonsCode?.refundType}
                  refundableFormName={formName}
                  refundableFormSection={formSection}
                  form={`RefundableAmount.${refundableId}`}
                  initialValues={{ refundAmount: refundableAmount?.amount.toFixed(2) }}
                  canOverrideVoidableAmounts={canOverrideVoidableAmounts}
                />
              </p>
            </div>
            <div>
              <h4 className={css(styles.label)}>
                <FormattedMessage {...messages.passengers} />
              </h4>
              <p className={css(styles.labelText, styles.capitalize)}> {passengerTypeLabel}</p>
            </div>
          </div>
          <div
            className={css(
              isReasonCodePerRefundable ? styles.ticketActionsGrid : styles.ticketActionsRow
            )}
          >
            {isReasonCodePerRefundable && (
              <RefundReason
                reasons={reasonsCode?.reasons}
                disabled={reasonsCode?.disabled || isRefundDisabled}
                refundType={reasonsCode?.refundType}
                isUsedTicketRefund={reasonsCode?.isUsedTicketRefund}
                isReasonCodePerRefundable={isReasonCodePerRefundable}
                applyReasonCodeToAllFares={applyReasonCodeToAllFares}
                isApplyAllVisible={isApplyAllVisible}
              />
            )}
            <div
              className={css(
                isReasonCodePerRefundable ? styles.ticketStatusReason : styles.ticketStatus
              )}
            >
              {farePassengerId && ticketUsageStatusTypes.includes(deliveryMethod) && (
                <TicketStatusModal
                  orderReference={orderReference}
                  productId={productId}
                  farePassengerId={farePassengerId}
                  deliveryMethod={deliveryMethod}
                  documents={ticketDocuments}
                  displayText={<FormattedMessage {...messages.ticketStatus} />}
                />
              )}
            </div>
            {friendlyTicketIndexes.length > 0 && bookingHasEntanglements && (
              <div className={css(styles.ticketId)} data-testid="ticketId">
                <FormattedMessage
                  {...messages.ticketId}
                  values={{ ticketId: friendlyTicketIndexes.join(', ') }}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </section>
  );
};
