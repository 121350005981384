import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'aphrodite/no-important';
import { IconCheck, IconClose } from '@trainline/depot-web';

import Checkbox from '@contactcentre-web/common/Checkbox/Checkbox';
import FeeLabel from '@contactcentre-web/common/FeeLabel/FeeLabel';

import messages from './messages';
import styles from './styles';

const FeeRefundedInfo = () => <IconCheck className={css(styles.feeRefunded)} />;

const FeeNotRefundedInfo = () => <IconClose className={css(styles.feeNotRefunded)} />;

const SameDayVoidFees = ({ bookingFee, paymentFee, isRefundSuccess, isUpdatePending }) => {
  const bookingFeeLabel = <FeeLabel message={messages.serviceFee} value={bookingFee.price} />;
  const paymentFeeLabel = <FeeLabel message={messages.paymentFee} value={paymentFee.price} />;
  return (
    <div className={css(styles.feeDetails)}>
      <div className={css(styles.feeDetail)}>
        {isRefundSuccess ? (
          <div className={css(styles.feeRefundedTxt)}>
            {bookingFee.isSelected ? <FeeRefundedInfo /> : <FeeNotRefundedInfo />}
            {bookingFeeLabel}
          </div>
        ) : (
          <Checkbox
            id={bookingFee.description}
            name={bookingFee.id}
            label={bookingFeeLabel}
            disabled={isUpdatePending || !bookingFee.isChangeable}
          />
        )}
      </div>
      <div className={css(styles.feeDetail)}>
        {isRefundSuccess ? (
          <div className={css(styles.feeRefundedTxt)}>
            {paymentFee.isSelected ? <FeeRefundedInfo /> : <FeeNotRefundedInfo />}
            {paymentFeeLabel}
          </div>
        ) : (
          <Checkbox
            id={paymentFee.description}
            name={paymentFee.id}
            label={paymentFeeLabel}
            disabled={isUpdatePending || !paymentFee.isChangeable}
          />
        )}
      </div>
    </div>
  );
};

const priceShape = {
  amount: PropTypes.number.isRequired,
  currencyCode: PropTypes.string.isRequired,
};

const feeShape = {
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isChangeable: PropTypes.bool.isRequired,
  price: PropTypes.shape(priceShape),
};

SameDayVoidFees.propTypes = {
  bookingFee: PropTypes.shape(feeShape).isRequired,
  paymentFee: PropTypes.shape(feeShape).isRequired,
  isUpdatePending: PropTypes.bool.isRequired,
  isRefundSuccess: PropTypes.bool.isRequired,
};

export default SameDayVoidFees;
