import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';

import styles from './styles';
import { RadioBoxGroupContext } from './components/RadioBoxGroup';

const RadioBox = ({
  label = '',
  value,
  currentValue,
  style = { wrapper: [], input: [], label: [] },
  ...props
}) => {
  const stringLabel =
    label.constructor === String
      ? label
      : label && label.props && label.props && label.props.defaultMessage;

  const id = stringLabel.replace(/\W|_|\d/gi, '').toLowerCase();

  const isChecked = value === currentValue;

  const { name, onChange } = React.useContext(RadioBoxGroupContext);

  return (
    <div className={css(styles.wrapper, style.wrapper)} {...props}>
      <input
        type="radio"
        id={id}
        className={css(styles.input, style.input)}
        name={name}
        onChange={onChange}
        value={value}
        checked={isChecked}
      />
      <label htmlFor={id} className={css(styles.label, style.label)}>
        {label}
      </label>
      <div data-test-id="check" className={css(styles.check, isChecked && styles.checked)}>
        <span
          data-test-id="check-inner-circle"
          className={css(styles.innerCircle, isChecked && styles.visible)}
        />
      </div>
    </div>
  );
};

RadioBox.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  currentValue: PropTypes.string,
  style: PropTypes.shape({
    wrapper: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    label: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  }),
};

export default RadioBox;
