import { StyleSheet } from 'aphrodite/no-important';
import { typography, spacers } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  blockTitle: {
    color: colors.blue.cyan,
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    textTransform: 'uppercase',
    marginTop: 14,
    marginBottom: spacers.dp_s4,
  },
});
