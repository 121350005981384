import React from 'react';
import PropTypes from 'prop-types';

import priceShape from '@contactcentre-web/utils/shape/price';

import PriceRow from '../PriceRow';
import ExtraRow from '../ExtraRow';

import messages from './messages';
import styles from './styles';

const getPassengerTypeMessage = (type, count) => ({
  ...(messages[`passengerType${type}`] || messages.passengerTypeUnknown),
  values: {
    type,
    count,
  },
});

const ProductBreakdown = ({
  type: breakdownType,
  passengerType,
  passengerCount,
  subTotal,
  currencyCode,
  inventoryTotalPrice,
  details,
  extraName,
  extraQuantity,
  price: extraPrice,
  inventoryPrice: extraInventoryPrice,
}) => {
  if (breakdownType === 'fare-passenger' && details) {
    return (
      <>
        <PriceRow
          message={getPassengerTypeMessage(passengerType, passengerCount)}
          price={{ amount: subTotal, currencyCode }}
          inventoryPrice={inventoryTotalPrice}
          discounts={passengerCount === 1 ? details[0].discounts : []}
        />
        {passengerCount > 1 &&
          details.map(
            (
              { passengerType: type, direction, price = { amount: 0 }, inventoryPrice, discounts },
              index
            ) => (
              <PriceRow
                key={index}
                direction={direction}
                message={getPassengerTypeMessage(type, 1)}
                price={{ ...price, currencyCode }}
                styleSheet={{ itemCell: styles.detailPad }}
                inventoryPrice={inventoryPrice}
                discounts={discounts}
              />
            )
          )}
      </>
    );
  }

  if (breakdownType === 'extra-type') {
    return (
      <ExtraRow
        message={{ ...messages.extra, values: { extraQuantity, extraName } }}
        price={extraPrice}
        inventoryPrice={extraInventoryPrice}
      />
    );
  }

  return null;
};

export default ProductBreakdown;

ProductBreakdown.propTypes = {
  type: PropTypes.string.isRequired,
  passengerType: PropTypes.string,
  passengerCount: PropTypes.number,
  subTotal: PropTypes.number,
  currencyCode: PropTypes.string,
  inventoryTotalPrice: priceShape,
  details: PropTypes.arrayOf(PropTypes.object),
  extraName: PropTypes.string,
  extraQuantity: PropTypes.number,
  price: priceShape,
  inventoryPrice: priceShape,
};
