import { defineMessages } from 'react-intl';

export default defineMessages({
  changeableToAnyCard: {
    id: 'OrderActions.changeableToAnyCard',
    defaultMessage: 'Change CTR collection card',
  },
  header: {
    id: 'ChangeCTRCard.header',
    defaultMessage: 'Change CTR collection card',
  },
  note: {
    id: 'ChangeCTRCard.note',
    defaultMessage:
      'This function, for self - service ticket machine collection, will change the card used to collect tickets from the payment card used to make the booking, to any payment card. Any additional bookings that share the same CTR will also be affected.',
  },
  buttonText: {
    id: 'ChangeCTRCard.formTitle',
    defaultMessage: 'Change CTR collection to anycard',
  },
  bookingText: {
    id: 'ChangeCTRCard.bookingText',
    defaultMessage:
      'Booking {idx} - {ctr} - {origin} to {destination}{isReturn, select, true {\u2000RTN} other {}} - {departAt}',
  },
  bookingLabel: {
    id: 'ChangeCTRCard.bookingLabel',
    defaultMessage: 'Select Booking',
  },
  bookingPlaceholder: {
    id: 'ChangeCTRCard.bookingPlaceholder',
    defaultMessage: 'No Booking Selected',
  },
  reasonLabel: {
    id: 'ChangeCTRCard.reasonLabel',
    defaultMessage: 'Select Reasons',
  },
  reasonPlaceholder: {
    id: 'ChangeCTRCard.reasonPlaceholder',
    defaultMessage: 'No Reasons Selected',
  },
  changeCTR_genericError: {
    id: 'ChangeCTRFailedAlert.changeCTR_genericError',
    defaultMessage:
      'Unable to change to Anycard Collection. This could be because tickets have been collected.',
  },
});
