import { StyleSheet } from 'aphrodite/no-important';
import {
  fonts,
  spacers,
  borderRadius,
  typography,
} from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

const originDestinationColor = '#192325';

export default StyleSheet.create({
  page: {
    height: '100%',
    overflowY: 'auto',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formWrapper: {
    margin: `${spacers.dp_s16} 0`,
    padding: `${spacers.dp_s32} ${spacers.dp_s32} ${spacers.dp_s24}`,
    borderRadius: borderRadius.dp_r4,
    boxShadow: '0 8px 12px 0 rgba(33, 49, 77, 0.2)',
    backgroundColor: colors.gray.alpha2,
  },
  searchButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-end',
    marginBottom: '35px',
  },
  bottomForm: {
    display: 'flex',
    alignItems: 'center',
    marginTop: spacers.dp_s24,
  },
  notesBox: {
    width: '729px',
    height: '180px',
  },
  notesText: {
    width: '235px',
    height: '16px',
    margin: `${spacers.dp_s8} 494px 0 0`,
    fontsize: typography.dp_mini_font_size_m,
    lineHeight: typography.dp_mini_line_height_m,
    letterSpacing: 'normal',
    color: colors.gray.alpha60,
  },
  title: {
    margin: `0 481px ${spacers.dp_s24} 0`,
    fontSize: typography.dp_title1_font_size,
    fontWeight: fonts.dp_font_weight_bold,
    letterSpacing: typography.dp_title1_letter_spacing,
    color: colors.black,
  },
  originDestination: {
    margin: `${spacers.dp_s4} 0`,
    fontSize: typography.dp_body_font_size,
    fontWeight: fonts.dp_font_weight_bold,
    color: originDestinationColor,
  },
  status: {
    display: 'flex',
    margin: `${spacers.dp_s24} ${spacers.dp_s16} ${spacers.dp_s16} 0`,
  },
  statusMessage: {
    fontSize: typography.dp_small_font_size_m,
    color: originDestinationColor,
    marginRight: spacers.dp_s16,
  },
  fieldContainer: {
    width: '365px',
  },
  textFieldBox: {
    width: '365px',
    marginTop: spacers.dp_s4,
  },
  statusIndicatorInitiated: {
    borderColor: colors.warning.base,
    backgroundColor: colors.warning.base,
  },
  statusIndicatorSubmitted: {
    borderColor: colors.positive.base,
    backgroundColor: colors.positive.base,
  },
  statusIndicatorAppealed: {
    borderColor: colors.positive.base,
    backgroundColor: colors.positive.base,
  },
  statusIndicatorAccepted: {
    borderColor: colors.positive.base,
    backgroundColor: colors.positive.base,
  },
  statusIndicatorApproved: {
    borderColor: colors.positive.base,
    backgroundColor: colors.positive.base,
  },
  statusIndicatorRejected: {
    borderColor: colors.negative.base,
    backgroundColor: colors.negative.base,
  },
  statusIndicatorDefault: {},
  claimAmountField: {
    marginTop: spacers.dp_s24,
  },
  contactReasonText: {
    width: '365px',
    marginBottom: spacers.dp_s24,
  },
  textCounter: {
    marginRight: spacers.dp_s32,
  },
  downloadLink: {
    marginLeft: spacers.dp_s32,
  },
  downloadIcon: {
    transform: 'rotate(90deg)',
  },
  pdfDownloadErrorMessage: {
    marginBottom: spacers.dp_s24,
  },
  pdfErrorNotificationContainer: {
    marginBottom: spacers.dp_s24,
  },
});
