import { StyleSheet } from 'aphrodite/no-important';
import { typography, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  form: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'flex-start',
  },
  formFields: {
    display: 'flex',
    flex: '1 0 auto',
    flexFlow: 'column nowrap',
    alignItems: 'flex-start',
    ':not(:last-child)': {
      paddingRight: spacers.dp_s16,
    },
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    paddingBottom: spacers.dp_s16,
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: spacers.dp_s24,
    fontSize: typography.dp_title2_font_size,
  },
  submitButtonContainer: {
    display: 'flex',
    alignContent: 'flex-end',
    justifyContent: 'flex-end',
    width: '100%',
    height: '50px',
    padding: `${spacers.dp_s32} 0`,
    marginBottom: spacers.dp_s32,
  },
  s100: {
    width: '100%',
    flexDirection: 'column',
  },
  s40: {
    width: '40%',
  },
  s60: {
    width: '60%',
  },
  errorContainer: {
    width: '100%',
    paddingLeft: spacers.dp_s8,
  },
});
