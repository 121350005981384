import { defineMessages } from 'react-intl';

export default defineMessages({
  replacementBooking: {
    id: 'OrderHistoryReplacement.replacementBooking',
    defaultMessage: 'Booking replaced',
  },
  booking: {
    id: 'OrderHistoryReplacement.booking',
    defaultMessage: 'Booking',
  },
  agent: {
    id: 'OrderHistoryReplacement.agent',
    defaultMessage: 'Agent',
  },
  requestedBy: {
    id: 'OrderHistoryReplacement.requestedBy',
    defaultMessage:
      '{requestorType, select, Customer {Customer via self serve} Agent {{agent}} other { }}',
  },
  appliesTo: {
    id: 'OrderHistoryReplacement.appliesTo',
    defaultMessage: 'Applies to order {orderReference}',
  },
  body: {
    id: 'OrderHistoryReplacement.body',
    defaultMessage: 'This booking has been replaced. {link}',
  },
  ReplacementFrom: {
    id: 'OrderHistoryReplacement.replacementFrom',
    defaultMessage: 'View original booking',
  },
  ReplacementTo: {
    id: 'OrderHistoryReplacement.replacementTo',
    defaultMessage: 'View new booking',
  },
});
