import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';

import BookingsTableHeader from './BookingsTableHeader';
import BookingsTableRow from './BookingsTableRow';
import InsuranceTableRow from './InsuranceTableRow';
import styles from './styles';

const BookingsTable = ({ bookings, insurances }) => (
  <div className={css(styles.table)}>
    <BookingsTableHeader />
    {bookings.map(({ id, ...other }) => (
      <BookingsTableRow key={id} {...other} />
    ))}

    {insurances.length
      ? insurances.map(({ id, ...other }) => <InsuranceTableRow key={id} id={id} {...other} />)
      : null}
  </div>
);

BookingsTable.propTypes = {
  bookings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
  insurances: PropTypes.arrayOf(PropTypes.object),
};

export default BookingsTable;
