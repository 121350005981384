import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { css } from 'aphrodite/no-important';
import { Route, Switch, Redirect, useRouteMatch, useHistory, useParams } from 'react-router-dom';
import useForceUpdate from 'use-force-update';

import Loader from '@contactcentre-web/common/Loader';
import SupersededBookingContainer from '@contactcentre-web/superseded-booking/SupersededBooking';
import CustomerPassengers from '@contactcentre-web/customer-passengers/CustomerPassengers';
import CustomerBookings from '@contactcentre-web/customer-bookings/CustomerBookings';
import CustomerOrder from '@contactcentre-web/customer-order/CustomerOrder';
import { getLocale } from '@contactcentre-web/authentication/redux/profile/selectors';
import { actions as sidebarActions } from '@contactcentre-web/sidebar/module';
import {
  getCurrentManagedGroupNumber,
  canEditCustomerProfile as canEditCustomerProfileSelector,
} from '@contactcentre-web/authentication/redux/selectors';

import Header from './components/Header/Header';
import { actions, selectors } from './module';
import styles from './styles';

export const Customer = ({
  selectedCustomer,
  getCustomer,
  error,
  clearCustomer,
  clearSidebar,
  currentLocale,
  managedGroupNumber,
  canEditCustomerProfile,
}) => {
  const { customerId } = useParams();
  const getCustomerRef = React.useRef(getCustomer);
  const clearCustomerRef = React.useRef(clearCustomer);
  const clearSidebarRef = React.useRef(clearSidebar);
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const forceUpdate = useForceUpdate();

  React.useEffect(() => {
    getCustomerRef.current(customerId);
    const unlisten = history.listen(() => forceUpdate());

    return () => {
      unlisten();
      clearCustomerRef.current();
      clearSidebarRef.current();
    };
  }, [customerId, getCustomerRef, clearCustomerRef, clearSidebarRef]);

  const isCustomerBookingsPage = useRouteMatch({
    path: '/customers/:customerId/bookings',
    exact: true,
  });

  const isCustomerPassengersPage = useRouteMatch({
    path: '/customers/:customerId/passengers',
    exact: true,
  });

  return (
    <div className={css(styles.container)}>
      {!error && !selectedCustomer && (
        <div className={css(styles.spinner)}>
          <Loader />
        </div>
      )}
      {error ||
        (selectedCustomer && (
          <div className={css(styles.customerContainer)}>
            <div className={css(styles.header)}>
              <Header
                customerId={customerId}
                canNavigateToBookings={!isCustomerBookingsPage}
                expandHeader={() => {}}
                currentLocale={currentLocale}
                isCustomerPassengersPage={!!isCustomerPassengersPage}
                managedGroupNumber={managedGroupNumber}
                canEditCustomerProfile={canEditCustomerProfile}
                {...selectedCustomer}
              />
            </div>
            <div className={css(styles.content)}>
              {/* TODO: Move the routes below to CustomerPage component once we update to react router v6 */}
              <Switch>
                <Route
                  path={`${path}/bookings/:orderId/supersededbooking`}
                  component={SupersededBookingContainer}
                />
                <Route path={`${path}/bookings/:orderId`} component={CustomerOrder} />
                <Route path={`${path}/bookings`} exact>
                  <CustomerBookings selectedCustomer={selectedCustomer} />
                </Route>
                <Route path={`${path}/passengers`} exact>
                  <CustomerPassengers selectedCustomer={selectedCustomer} />
                </Route>
                <Route path="*">
                  <Redirect to={`${url}/bookings`} />
                </Route>
              </Switch>
            </div>
          </div>
        ))}
    </div>
  );
};

Customer.propTypes = {
  selectedCustomer: PropTypes.object,
  error: PropTypes.bool,
  getCustomer: PropTypes.func.isRequired,
  clearCustomer: PropTypes.func.isRequired,
  clearSidebar: PropTypes.func.isRequired,
  currentLocale: PropTypes.string.isRequired,
  managedGroupNumber: PropTypes.number.isRequired,
  canEditCustomerProfile: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  selectedCustomer: selectors.getSelectedCustomer(state.customer),
  error: selectors.getCustomerError(state.customer),
  currentLocale: getLocale(state),
  managedGroupNumber: getCurrentManagedGroupNumber(state),
  canEditCustomerProfile: canEditCustomerProfileSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCustomer: (customerId) => dispatch(actions.getCustomer(customerId)),
  clearCustomer: () => dispatch(actions.clearCustomer()),
  clearSidebar: () => dispatch(sidebarActions.closeSidebar(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
