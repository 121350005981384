import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    padding: `${spacers.dp_s32} 0`,
  },
  title: {
    marginBottom: spacers.dp_s32,
  },
  loaderContainer: {
    padding: spacers.dp_s32,
  },
  emptyQueueMessage: {
    textAlign: 'center',
  },
});
