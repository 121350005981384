import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';

import IconInsurance from '../Icons/IconInsurance';

import styles from './styles';
import messages from './messages';

const InsuranceIcon = () => (
  <div>
    <IconInsurance className={css(styles.insuranceIcon)} />
    <FormattedMessage {...messages.trainlineProtectInsurance} />
  </div>
);

export default InsuranceIcon;
