import React, { Dispatch, useState, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { css } from 'aphrodite/no-important';

import type State from '@contactcentre-web/redux-common/types/State';
import type Action from '@contactcentre-web/redux-common/types/Action';

import NotesTopic from './components/NotesTopic/NotesTopic';
import NotesOverview from './components/NotesOverview/NotesOverview';
import styles from './styles';
import { actions, selectors as noteSelectors, Topic, Notes, NoteSubmitFormData } from './module';

interface StateProps {
  notes: Notes;
  loading: boolean;
}

interface DispatchProps {
  onNoteSubmit: (topic: Topic, topicId: string, formData: NoteSubmitFormData) => void;
}

type Props = StateProps & DispatchProps;

export const CustomerNotes: FunctionComponent<Props> = ({ onNoteSubmit, loading, notes }) => {
  const [selectedTopic, setSelectedTopic] = useState<{ topic: Topic; topicId: string } | null>();

  const topicChangedHandler = (topic: Topic, topicId: string) =>
    setSelectedTopic({
      topic,
      topicId,
    });
  const { topic, topicId } = selectedTopic || {};

  return (
    <div className={css(styles.sidebar)}>
      {selectedTopic && topic && topicId ? (
        <NotesTopic
          clearTopic={() => setSelectedTopic(null)}
          selectTopic={setSelectedTopic}
          topicId={topicId}
          topic={topic}
          topicNotes={(notes[topic] && notes[topic][topicId]) || []}
          onNoteSubmit={(data: NoteSubmitFormData) => onNoteSubmit(topic, topicId, data)}
          loading={loading}
        />
      ) : (
        <NotesOverview loading={loading} notes={notes} onSetTopic={topicChangedHandler} />
      )}
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  notes: noteSelectors.getNotes(state.customerNotes),
  loading: noteSelectors.getLoading(state.customerNotes),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  onNoteSubmit: (topic: Topic, topicId: string, formData: NoteSubmitFormData) =>
    dispatch(actions.addNoteRequested({ topic, topicId, ...formData })),
  resetForm: (formId: string) => dispatch(reset(formId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerNotes);
