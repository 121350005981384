import { defineMessages } from 'react-intl';

export default defineMessages({
  changeManagedGroup: {
    id: 'ManagedGroup.changeManagedGroup',
    defaultMessage: 'Change',
  },
  modalTitle: {
    id: 'ManagedGroup.ChangeManagedGroupModal.modalTitle',
    defaultMessage: 'Are you sure you want to change managed groups?',
  },
  modalDescription: {
    id: 'ManagedGroup.ChangeManagedGroupModal.modalDescription',
    defaultMessage:
      'Your changes will not be saved if you switch managed groups mid-way through your work.',
  },
  cancelButton: {
    id: 'ManagedGroup.ChangeManagedGroupModal.cancelButton',
    defaultMessage: 'Cancel',
  },
});
