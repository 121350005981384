import { handleActions } from 'redux-actions';

import {
  REQUEST_REFUND_SUCCEEDED,
  UPDATE_QUOTE_SUCCESS,
  LOAD_QUOTE_SUCCEEDED,
  QUOTE_VALIDATION_FAILED,
} from '../common/module';

import { events, loadRefundsState, requestRefundState, approveRefundState } from './module';

const initialState = {
  loadRefundsState: loadRefundsState.LOAD_REFUNDS_NONE,
  requestRefundState: requestRefundState.REQUEST_REFUND_NONE,
  approveRefundState: {},
  quotes: {},
  quotesValidationErrors: {},
  refundReasons: [],
  returnUrl: '',
};
const reducer = handleActions(
  {
    [events.DISPOSE]: () => initialState,
    [events.LOAD_REFUNDS]: (state) => ({
      ...state,
      loadRefundsState: loadRefundsState.LOAD_REFUNDS_INPROGRESS,
    }),
    [events.LOAD_REFUNDS_SUCCEEDED]: (
      state,
      { payload: { refundables, refunds, refundReasons, pendingRefunds } }
    ) => ({
      ...state,
      loadRefundsState: loadRefundsState.LOAD_REFUNDS_SUCCESS,
      refundables,
      refunds,
      refundReasons,
      pendingRefunds,
    }),
    [events.LOAD_REFUNDS_FAILED]: (state) => ({
      ...state,
      loadRefundsState: loadRefundsState.LOAD_REFUNDS_ERROR,
    }),
    [events.LOAD_EXISTING_REFUND]: (state, { payload: { returnUrl } }) => ({
      ...state,
      loadRefundsState: loadRefundsState.LOAD_REFUNDS_INPROGRESS,
      returnUrl,
    }),
    [events.LOAD_EXISTING_REFUND_SUCCEEDED]: (
      state,
      { payload: { refundables, quote, refundReasons } }
    ) => ({
      ...state,
      loadRefundsState: loadRefundsState.LOAD_REFUNDS_SUCCESS,
      refundables,
      quote,
      refundReasons,
    }),
    [events.LOAD_EXISTING_REFUND_FAILED]: (state) => ({
      ...state,
      loadRefundsState: loadRefundsState.LOAD_REFUNDS_ERROR,
    }),
    [LOAD_QUOTE_SUCCEEDED]: (state, { payload: { bookingId, quote } }) =>
      state.loadRefundsState === loadRefundsState.LOAD_REFUNDS_SUCCESS
        ? {
            ...state,
            quotes: {
              ...state.quotes,
              [bookingId]: quote,
            },
          }
        : state,
    [UPDATE_QUOTE_SUCCESS]: (state, { payload: { bookingId, quote } }) =>
      state.loadRefundsState === loadRefundsState.LOAD_REFUNDS_SUCCESS
        ? {
            ...state,
            quotes: {
              ...state.quotes,
              [bookingId]: quote,
            },
            quotesValidationErrors: {
              ...state.quotesValidationErrors,
              [bookingId]: undefined,
            },
            approveRefundState: {
              ...state.approveRefundState,
              [bookingId]: {
                ...state.approveRefundState[bookingId],
                pending: false,
                success: false,
                error: undefined,
                unprocessableReasons: undefined,
              },
            },
          }
        : state,
    [QUOTE_VALIDATION_FAILED]: (state, { payload: { bookingId, errors } }) =>
      state.loadRefundsState === loadRefundsState.LOAD_REFUNDS_SUCCESS
        ? {
            ...state,
            quotesValidationErrors: {
              ...state.quotesValidationErrors,
              [bookingId]: {
                ...state.quotesValidationErrors[bookingId],
                unprocessableReasons: errors,
              },
            },
          }
        : state,
    [REQUEST_REFUND_SUCCEEDED]: (state, { payload: { bookingId } }) =>
      state.loadRefundsState === loadRefundsState.LOAD_REFUNDS_INPROGRESS
        ? {
            ...state,
            quotes: {
              ...state.quotes,
              [bookingId]: undefined,
            },
            requestRefundState: requestRefundState.REQUEST_REFUND_SUCCESS,
          }
        : state,
    [events.APPROVE_REFUND]: (state, { payload: { bookingId } }) => ({
      ...state,
      approveRefundState: {
        ...state.approveRefundState,
        [bookingId]: {
          ...state.approveRefundState[bookingId],
          pending: true,
          success: false,
          error: undefined,
        },
      },
    }),
    [approveRefundState.APPROVE_REFUND_SUCCESS]: (
      state,
      { payload: { refundId, status, bookingId } }
    ) => ({
      ...state,
      approveRefundState: {
        ...state.approveRefundState,
        [bookingId]: {
          ...state.approveRefundState[bookingId],
          refundId,
          status,
          pending: false,
          success: true,
        },
      },
    }),
    [approveRefundState.APPROVE_REFUND_FAILED]: (
      state,
      { payload: { bookingId, error, unprocessableReasons } }
    ) => ({
      ...state,
      approveRefundState: {
        ...state.approveRefundState,
        [bookingId]: {
          ...state.approveRefundState[bookingId],
          pending: false,
          success: false,
          error,
          unprocessableReasons,
        },
      },
    }),
  },
  initialState
);

export default reducer;
