import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { TravelProduct } from '@contactcentre-web/redux-common/types/TravelProduct';
import { CUSTOMER_ORDER_SUCCESS } from '@contactcentre-web/redux-common/actions/order';
import { getNotesRequested } from '@contactcentre-web/sidebar/components/CustomerNotes/module';
import { getProducts } from '@contactcentre-web/redux-common/selectors/products';
import request from '@contactcentre-web/utils/request';
import { getOrderId } from '@contactcentre-web/redux-common/selectors/order';
import { canRequestExternalRefund as canRequestExternalRefundSelector } from '@contactcentre-web/authentication/redux/selectors';
import type State from '@contactcentre-web/redux-common/types/State';

import { actionBuilder, actionTypes } from './module';
import { getBookings } from './selectors';
import DetaxeBooking from './types/DetaxeBooking';

export type Reason =
  | 'canceledTrainDisruption'
  | 'refundedCanceledTrainDisruption'
  | 'onboardComfortIssues'
  | 'missedConnection'
  | 'deathIllness'
  | 'voidVoucher'
  | 'fareTypeRefunds'
  | 'duplicateBooking'
  | 'historicPNR'
  | 'finalizeOptionSNCF'
  | 'railcard';

interface SetRefundData {
  status: string;
  reference: string;
  note: string;
  claimAmount: string;
  contactReason?: string;
}

interface AddNoteProps {
  topic: string;
  topicId: string;
  note: string;
}

export const addNoteRequest = ({ topic, topicId, note }: AddNoteProps): Promise<unknown> =>
  request('/api/notes', {
    method: 'POST',
    body: {
      body: note,
      Topic: topic,
      TopicId: topicId,
    },
  });

export const getDetaxeEligibilityRequest = (
  orderId: string
): Promise<{
  productsEligibility: DetaxeBooking[];
}> => request(`/ExceptionalRefunds/Eligibility/${orderId}`);

export const setRefundRequest = (
  { status, reference, claimAmount, contactReason }: SetRefundData,
  refundId: string
): Promise<unknown> =>
  request(`/ExceptionalRefunds/${refundId}/transition/${status}`, {
    method: 'POST',
    body: {
      state: status,
      payload: {
        reference,
        claimAmount: {
          amount: claimAmount,
          currencyCode: 'EUR',
        },
        contactReasonId: contactReason,
      },
    },
  });

export const createRefundRequest = (
  orderId: string,
  productId: string,
  refundType: string
): Promise<unknown> =>
  request(`/ExceptionalRefunds/${orderId}`, {
    method: 'POST',
    body: { productId, exceptionalRefundType: refundType },
  });

export const getRefundRequest = (refundId: string): Promise<unknown> =>
  request(`/ExceptionalRefunds/${refundId}`);

export const getContactReasons = (): Promise<unknown> =>
  request('/ExceptionalRefunds/contactReasons');

export function* loadDetaxeEligibilitySaga({
  payload: {
    order: { id },
  },
}: {
  type: string;
  payload: { order: { id: string } };
}) {
  try {
    const state: State = yield select();
    const canRequestExternalRefund = canRequestExternalRefundSelector(state);

    if (!canRequestExternalRefund) {
      return;
    }

    const { productsEligibility } = (yield call(getDetaxeEligibilityRequest, id)) as unknown as any;
    yield put(actionBuilder.loadSuccess(productsEligibility));
  } catch {}
}

export function* createRefundSaga({
  payload: { productId },
}: {
  type: string;
  payload: { productId: string };
}): Iterator<unknown> {
  try {
    const state = yield select();
    const orderId = getOrderId(state);
    const bookings = getBookings(state);
    const refundType = bookings.find(({ id }) => id === productId)?.refundTypes[0] || '';

    yield call(createRefundRequest, orderId, productId, refundType);
    yield put(actionBuilder.createRefundSuccess());
  } catch {
    yield put(actionBuilder.createRefundFailed());
  }
}

export function* getRefundSaga({
  payload: { refundId },
}: {
  type: string;
  payload: { refundId: string };
}): Iterator<unknown> {
  try {
    const state = yield select();
    const products = getProducts(state);

    const contactReasonsList = (yield call(getContactReasons)) as any;

    const refundStatus = (yield call(getRefundRequest, refundId)) as any;

    const contactReasons = contactReasonsList?.items?.map((reason: { id: Reason }) => reason.id);

    const product = products.find(({ id }) => id === refundStatus.productId);

    const response = {
      status: refundStatus.currentState.status,
      transitionsTo: refundStatus.currentState.transitionsTo,
      claimAmount: refundStatus.claimAmount?.amount,
      originStation: (product as TravelProduct)?.origin,
      destinationStation: (product as TravelProduct)?.destination,
      contactReasons: contactReasons,
      contactReasonId: refundStatus.contactReasonId,
    };
    yield put(actionBuilder.getRefundSuccess(response));
  } catch {
    yield put(actionBuilder.getRefundFailed());
  }
}

interface SetRefundSagaPayload {
  data: SetRefundData;
  refundId: string;
  orderId: string;
}

export function* setRefundSaga({
  payload: { data, refundId, orderId },
}: {
  type: string;
  payload: SetRefundSagaPayload;
}): Iterator<unknown> {
  try {
    yield call(setRefundRequest, data, refundId);

    if (data.note && data.note.length > 0) {
      yield call(addNoteRequest, {
        topic: 'Order',
        topicId: orderId,
        note: data.note,
      });
      yield put(getNotesRequested('Order', orderId));
    }
    yield put(actionBuilder.setRefundStatusSuccess());
  } catch {
    yield put(actionBuilder.setRefundStatusFailed());
  }
}

export default function* saga(): unknown {
  yield all([
    yield takeLatest(CUSTOMER_ORDER_SUCCESS, loadDetaxeEligibilitySaga),
    yield takeLatest(actionTypes.CREATE_REFUND_ATTEMPT, createRefundSaga),
    yield takeLatest(actionTypes.GET_REFUND_ATTEMPT, getRefundSaga),
    yield takeLatest(actionTypes.SET_REFUND_STATUS_ATTEMPT, setRefundSaga),
  ]);
}
