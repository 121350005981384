import React, { Fragment } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage, FormattedTime } from 'react-intl';
import { IconChanges, IconModeOfTransportTrain, Paragraph } from '@trainline/depot-web';
import moment from 'moment';

import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalSubtitle,
  ModalBody,
} from '@contactcentre-web/common/Modal';
import FormattedDateInLondonTz from '@contactcentre-web/common/FormattedDateInLondonTz/FormattedDateInLondonTz';

import messages from './messages';
import styles from './styles';
import CallingPoints from './components/CallingPoints/CallingPoints';

type Leg = {
  originDestination: string;
  arrivalDestination: string;
  finalDestination: string;
  carrier: string;
  stops: number;
  departAt: string;
  arriveAt: string;
  callingPoints: CallingPoint[];
};

export type CallingPoint = {
  locationName: string;
  scheduledTime: string;
};

type Journey = {
  origin: string;
  destination: string;
  duration: string;
  changes: JSX.Element;
  departAt: string;
  arriveAt: string;
  legs: Leg[];
};

interface JourneyProps {
  closeJourneyModal: (state: boolean) => void;
  journey: Journey;
}

const JourneyItemModal = ({
  closeJourneyModal,
  journey: { duration, changes, departAt, legs },
}: JourneyProps) => {
  const calculateTimeDuration = (initial: string, final: string): string => {
    const start = moment(initial);
    const end = moment(final);
    const diffMinutes = end.diff(start, 'minutes');
    const diffHours = end.diff(start, 'hours');
    return `${diffHours > 0 ? `${diffHours}hr` : ''}${diffMinutes % 60}min`;
  };
  return (
    <div>
      <Modal onClose={() => closeJourneyModal(false)} styleSheet={{ content: styles.modalContent }}>
        <ModalHeader>
          <ModalTitle>
            <FormattedMessage {...messages.modalTitle} />
          </ModalTitle>
          <ModalSubtitle>
            <FormattedMessage
              {...messages.modalSubTitle}
              values={{
                date: (
                  <FormattedDateInLondonTz
                    value={departAt}
                    year="numeric"
                    month="short"
                    day="numeric"
                    weekday="short"
                  />
                ),
                duration: duration,
                type: changes,
              }}
            />
          </ModalSubtitle>
        </ModalHeader>
        <ModalBody>
          {legs?.map(
            (
              {
                departAt,
                arriveAt,
                originDestination,
                arrivalDestination,
                finalDestination,
                carrier,
                callingPoints,
              }: Leg,
              index: number
            ) => (
              <Fragment key={index}>
                <Paragraph typeStyle="small" as="p" color="base" fontWeight="regular">
                  <div className={css(styles.iconContainer)}>
                    <IconModeOfTransportTrain className={css(styles.tripIcon)} />
                    <FormattedMessage
                      {...messages.destination}
                      values={{
                        destination: finalDestination,
                        carrier: carrier,
                      }}
                    />
                  </div>
                </Paragraph>
                <div className={css(styles.callingPointsContainer)}>
                  <div className={css(styles.callingPointsItemContainer)}>
                    <div data-test={`location-${index}`}>
                      <div className={css(styles.locationItem)}>
                        <div className={css(styles.callingPoints)}>
                          <div className={css(styles.marker)} data-test="marker">
                            <div
                              className={css(styles.point, styles.biggerPoint)}
                              data-test="point"
                            />
                          </div>
                        </div>
                        <div className={css(styles.locationItemTime)}>
                          <FormattedTime value={departAt} />
                        </div>
                        <div
                          className={css(styles.originOrDestinationLocation)}
                          data-test={`${originDestination}-location-name`}
                        >
                          {originDestination}
                        </div>
                      </div>
                      <CallingPoints
                        duration={calculateTimeDuration(departAt, arriveAt)}
                        callingPoints={callingPoints}
                      />

                      <div className={css(styles.locationItem)}>
                        <div>
                          <div data-test="marker">
                            <div
                              className={css(styles.point, styles.biggerPoint)}
                              data-test="point"
                            />
                          </div>
                        </div>
                        <div className={css(styles.locationItemTime)}>
                          <FormattedTime value={arriveAt} />
                        </div>
                        <div
                          className={css(styles.originOrDestinationLocation)}
                          data-test={`${arrivalDestination}-location-name`}
                        >
                          {arrivalDestination}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {legs.length > index + 1 && (
                  <div className={css(styles.changeContainer)}>
                    <div className={css(styles.iconContainer)}>
                      <IconChanges className={css(styles.tripIcon)} />
                      <FormattedMessage
                        {...messages.changingAt}
                        values={{
                          station: arrivalDestination,
                        }}
                      />
                    </div>
                    <div>{calculateTimeDuration(arriveAt, legs[index + 1].departAt)}</div>
                  </div>
                )}
              </Fragment>
            )
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default JourneyItemModal;
