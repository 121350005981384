import { defineMessages } from 'react-intl';

export default defineMessages({
  railcard: {
    id: 'RailcardRefundable.railcard',
    defaultMessage: 'Railcard {railcardType}',
  },
  productPrice: {
    id: 'RailcardRefundable.productPrice',
    defaultMessage: 'Product price',
  },
  refundableAmount: {
    id: 'RailcardRefundable.refundableAmount',
    defaultMessage: 'Refundable amount',
  },
  NA: {
    id: 'RailcardRefundable.NA',
    defaultMessage: 'N/A',
  },
  refundable: {
    id: 'RailcardRefundable.refundable',
    defaultMessage: 'Refundable',
  },
  validity: {
    id: 'RailcardRefundable.validity',
    defaultMessage: '<span>Valid from</span> <span>Valid to</span> {from} {arrow} {to}',
  },
  refundEligibility: {
    id: 'RailcardRefundable.refundEligibility',
    defaultMessage: 'Refund eligibility',
  },
  issuedTo: {
    id: 'RailcardRefundable.issuedTo',
    defaultMessage: 'Issued to <strong>{name}</strong>',
  },
  expiryDate: {
    id: 'RailcardRefundable.expiryDate',
    defaultMessage: 'Until {expiryDate}',
  },
  notRefundableUnderThisPolicy: {
    id: 'RailcardRefundable.notRefundableUnderThisPolicy',
    defaultMessage: 'Not refundable under this policy.',
  },
  voidAlreadyInProgressForRefundable: {
    id: 'RailcardRefundable.voidAlreadyInProgressForRefundable',
    defaultMessage:
      'This refundable is included in existing void request which is either already in progress or finished.',
  },
  voidableAlreadyVoided: {
    id: 'RailcardRefundable.voidableAlreadyVoided',
    defaultMessage:
      'This voidable is included in an existing void request which has completed successfully.',
  },
  policyExpired: {
    id: 'RailcardRefundable.policyExpired',
    defaultMessage: 'Policy is expired and is no longer refundable.',
  },
  ticketAlreadyCollected: {
    id: 'RailcardRefundable.ticketAlreadyCollected',
    defaultMessage: 'Same day void is not available for collected tickets.',
  },
  productInInvalidState: {
    id: 'RailcardRefundable.productInInvalidState',
    defaultMessage: 'Product is not in a state that allow refunds.',
  },
  RefundRequestStarted: {
    id: 'RailcardRefundable.RefundRequestStarted',
    defaultMessage: 'Refund request for this transaction has already been started.',
  },
  productModifiedExternally: {
    id: 'RailcardRefundable.productModifiedExternally',
    defaultMessage: 'Product has been modified by an external system.',
  },
});
