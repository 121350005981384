import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  NoteTextarea: {
    width: '100%',
    padding: `${spacers.dp_s16} ${spacers.dp_s16} 0`,
  },

  hiddenSizeArea: {
    maxHeight: '250px',
    background: colors.white,
    width: '100%',
    visibility: 'hidden',
    wordWrap: 'break-word',
  },

  container: {
    position: 'relative',
    width: '100%',
  },

  textAbsolute: {
    position: 'absolute',
    height: '100%',
    top: 0,
    width: '100%',
  },

  textAreaField: {
    background: colors.white,
    border: `1px solid ${colors.gray.alpha30}`,
    borderRadius: spacers.dp_s4,
    width: '100%',
    padding: `${spacers.dp_s12} ${spacers.dp_s16}`,
    resize: 'none',
  },
});
