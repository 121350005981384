import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  wrapper: {
    margin: `${spacers.dp_s16} auto`,
    width: '1036px',
  },
  link: {
    display: 'grid',
    textDecoration: 'none',
    color: colors.blue.royal,
  },
});
