import { StyleSheet } from 'aphrodite/no-important';
import {
  typography,
  fonts,
  borderRadius,
  palettes,
  spacers,
} from '@trainline/depot-web/css/themes/trainline';

const placeholder = {
  color: palettes.dp_app_text_subdued,
  fontWeight: fonts.dp_font_weight_regular,
};

export default StyleSheet.create({
  textarea: {
    resize: 'vertical',
  },
  root: {
    fontSize: typography.dp_body_font_size,
    fontWeight: fonts.dp_font_weight_semibold,
    lineHeight: typography.dp_body_line_height,
    color: palettes.dp_app_text_base,
    backgroundColor: palettes.dp_app_backdrop_base,
    border: `1px solid ${palettes.dp_app_border_dark}`,
    borderRadius: borderRadius.dp_r4,
    padding: `${spacers.dp_s8} ${spacers.dp_s12}`,
    width: '100%',

    ':focus': {
      borderWidth: borderRadius.dp_r2,
      borderColor: palettes.dp_app_selection_dark,
      padding: `calc(${spacers.dp_s8} - 1px) calc(${spacers.dp_s12} - 1px)`,
    },

    ':hover:not(:disabled)': {
      borderColor: palettes.dp_app_selection_dark,
      backgroundColor: palettes.dp_app_backdrop_dark,
    },

    ':disabled': {
      backgroundColor: palettes.dp_app_backdrop_dark,
      color: palettes.dp_app_text_disabled,
      cursor: 'not-allowed',
    },

    '::-webkit-search-decoration': {
      '-webkit-appearance': 'none',
    },
    '::-webkit-input-placeholder': placeholder,
    '::-moz-placeholder': placeholder,
    ':-ms-input-placeholder': placeholder,
    ':-moz-placeholder': placeholder,
    ':placeholder': placeholder,
    '::-webkit-contacts-auto-fill-button': {
      display: 'none !important',
      pointerEvents: 'none',
      position: 'absolute',
      right: 0,
      visibility: 'hidden',
    },
    '::-webkit-credentials-auto-fill-button': {
      pointerEvents: 'none',
      position: 'absolute',
      right: 0,
      visibility: 'hidden',
    },
    '::-ms-clear': {
      display: 'none',
    },
  },
  error: {
    borderColor: palettes.dp_app_negative_mid,

    ':hover': {
      borderColor: palettes.dp_app_negative_mid,
      backgroundColor: palettes.dp_app_backdrop_base,
    },

    ':focus': {
      borderColor: palettes.dp_app_negative_mid,
    },
  },
});
