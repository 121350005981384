import { useQuery } from 'react-query';

import request from '@contactcentre-web/utils/request';

interface Attributes {
  code: string;
  description: string;
  parentId?: string;
  selectable: boolean;
  csgCode: string;
}

export interface ReasonCode {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface ReasonCodeResponse {
  data: Array<ReasonCode>;
}

interface Reason {
  id: string;
  description: string;
  parent?: Reason;
}

const getParent = (id?: string, data?: Array<ReasonCode>): Reason | null | undefined => {
  const parent = id ? data?.find((item) => item.id === id) : null;
  return (
    parent &&
    ({
      id: parent.id,
      description: parent.attributes.description,
      parent: getParent(parent.attributes.parentId, data),
    } as Reason)
  );
};
const rehydrateReasonCodesHierarchy = (data: Array<ReasonCode>) =>
  data?.reduce((acc: Array<Reason>, cur) => {
    const item = getParent(cur.id, data);
    if (item) {
      acc.push(item);
    }
    return acc;
  }, []);

export const useFetchReasonCodes = () =>
  useQuery('reasonCodes', () => request<ReasonCodeResponse>(`/compensations/reasoncodes`), {
    select: ({ data }) => rehydrateReasonCodesHierarchy(data),
  });
