import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { RailcardRefundable } from '@contactcentre-web/hooks/api/useOrderHistory';
import { StatusIndicatorColored } from '@contactcentre-web/common/StatusIndicator';

import type { StatusMapperType } from '../../../Refund/Refund';
import OrderHistory from '../../../OrderHistory/OrderHistory';

import messages from './messages';

interface Props {
  statusMapper: StatusMapperType;
  railcardRefundables: Array<RailcardRefundable>;
}

const RefundRailcardStatus: FunctionComponent<Props> = ({ statusMapper, railcardRefundables }) => (
  <div>
    <OrderHistory.ItemTitle>
      <FormattedMessage {...messages.refundStatus} />
    </OrderHistory.ItemTitle>
    {railcardRefundables.map(({ status }, index) => (
      <StatusIndicatorColored color={statusMapper[status]?.color} key={index}>
        {statusMapper[status] ? <FormattedMessage {...statusMapper[status].message} /> : status}
      </StatusIndicatorColored>
    ))}
  </div>
);

export default RefundRailcardStatus;
