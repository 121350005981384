import React, { ReactElement, useState, FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import Price from '@contactcentre-web/redux-common/types/Price';
import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from '@contactcentre-web/common/Modal';
import Button from '@contactcentre-web/common/Button';

import messages from './messages';

interface StateProps {
  children: ReactElement<Node>;
  amount: Price;
  isResolutionInProgress: boolean;
  hasResolutionSucceeded: boolean;
}

interface DispatchProps {
  onConfirm: () => void;
}

const CompensationApprovalPrompt: FunctionComponent<StateProps & DispatchProps> = ({
  children,
  amount,
  onConfirm,
  isResolutionInProgress,
  hasResolutionSucceeded,
}) => {
  const [visible, setVisible] = useState(false);
  React.useEffect(() => {
    if (hasResolutionSucceeded) {
      setVisible(false);
    }
  }, [hasResolutionSucceeded]);

  return (
    <>
      {React.cloneElement(children, { onClick: () => setVisible(true) })}
      {visible && (
        <Modal onClose={() => setVisible(false)}>
          <ModalHeader>
            <ModalTitle>
              <FormattedMessage {...messages.title} />
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <FormattedMessage
              {...messages.body}
              values={{
                ...amount,
              }}
            />
          </ModalBody>
          <ModalFooter contentAlignment="spaceBetween">
            <Button variant="tertiary" size="large" onClick={() => setVisible(false)}>
              <FormattedMessage {...messages.buttonCancel} />
            </Button>
            <Button
              variant="primary"
              size="large"
              onClick={onConfirm}
              loading={isResolutionInProgress || false}
            >
              <FormattedMessage {...messages.buttonApprove} />
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default CompensationApprovalPrompt;
