import { createActions, handleActions } from 'redux-actions';

const PREFIX = 'SUPERSEDED_BOOKING/';

export const SUPERSEDED_BOOKING_LOAD = `${PREFIX}LOAD`;
export const SUPERSEDED_BOOKING_LOAD_SUCCESS = `${PREFIX}LOAD_SUCCESS`;
export const SUPERSEDED_BOOKING_LOAD_FAILED = `${PREFIX}LOAD_FAILED`;

export const { supersededBooking: actions } = createActions({
  [SUPERSEDED_BOOKING_LOAD]: (orderReference, bookingUri) => ({
    orderReference,
    bookingUri,
  }),
  [SUPERSEDED_BOOKING_LOAD_SUCCESS]: (order) => ({ order }),
  [SUPERSEDED_BOOKING_LOAD_FAILED]: (error) => error,
});

const initialState = {
  order: null,
  loading: true,
  error: null,
};

export default handleActions(
  {
    [SUPERSEDED_BOOKING_LOAD]: (state) => ({
      ...state,
      order: null,
      error: null,
      loading: true,
    }),
    [SUPERSEDED_BOOKING_LOAD_SUCCESS]: (state, action) => ({
      ...state,
      order: action.payload.order,
      error: null,
      loading: false,
    }),
    [SUPERSEDED_BOOKING_LOAD_FAILED]: (state, action) => ({
      ...state,
      order: null,
      error: action.payload,
      loading: false,
    }),
  },
  initialState
);
