import { StyleSheet } from 'aphrodite/no-important';
import { spacers, palettes, borderRadius } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  bookingItem: {
    backgroundColor: palettes.dp_app_backdrop_base,
    border: `1px solid ${palettes.dp_app_border_base}`,
    borderRadius: borderRadius.dp_r4,
    marginBottom: spacers.dp_s32,
  },
  row: {
    padding: spacers.dp_s24,
    borderBottom: `1px solid ${palettes.dp_app_border_base}`,
  },
  rowLast: {
    borderBottom: 'none',
  },
  ticketDetails: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  ticketsTitle: {
    marginBottom: spacers.dp_s8,
  },
  ticketList: {
    listStyleType: 'none',
  },
  ticketType: {
    padding: `${spacers.dp_s4} 0`,
    textTransform: 'capitalize',
  },
  buttonContainer: {
    alignSelf: 'center',
  },
});
