import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({
  list: {
    listStyle: 'none',
  },
  error: {
    color: 'red',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
  },
});
