import { StyleSheet } from 'aphrodite/no-important';
import { palettes, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  statusIndicator: {
    borderRadius: '100%',
    width: '8px',
    height: '8px',
    display: 'inline-block',
    marginRight: spacers.dp_s8,
    marginBottom: '1px',
  },
  statusLabel: {
    textTransform: 'capitalize',
  },
  base: {
    backgroundColor: palettes.dp_app_text_base,
  },
  positive: {
    backgroundColor: palettes.dp_app_positive_mid,
  },
  negative: {
    backgroundColor: palettes.dp_app_negative_dark,
  },
  warning: {
    backgroundColor: palettes.dp_app_warning_dark,
  },
});
