import { css } from 'aphrodite/no-important';
import React from 'react';
import { useIntl } from 'react-intl';
import { IconClose } from '@trainline/depot-web';

import Button from '@contactcentre-web/common/Button';
import TextField from '@contactcentre-web/common/TextField/TextField';

import messages from './messages';
import styles, { fieldStyles } from './styles';

interface Props {
  name: string;
  canRemove: boolean;
  onRemoveEmailClick: () => void;
  disabled: boolean;
}
const EmailInput = ({ canRemove, name, disabled, onRemoveEmailClick }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <div className={css(styles.fieldContainer)}>
      <div className={css(styles.inputContainer)}>
        <TextField
          styleSheet={fieldStyles}
          disabled={disabled}
          placeholder={formatMessage(messages.emailPlaceholder)}
          name={name}
          messages={messages}
        />
      </div>
      {canRemove && (
        <Button
          variant="ghost"
          styleSheet={styles.removeEmailButton}
          onClick={() => onRemoveEmailClick()}
        >
          <IconClose />
        </Button>
      )}
    </div>
  );
};

export default EmailInput;
