import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'VirtualTerminalSearchForm.title',
    defaultMessage: 'Search for a transaction',
  },
  orderId: {
    id: 'VirtualTerminalSearchForm.orderId',
    defaultMessage: 'Order ID',
  },
  validateButton: {
    id: 'VirtualTerminalSearchForm.validateButton',
    defaultMessage: 'Validate',
  },
  defaultErrorMessage: {
    id: 'VirtualTerminalSearchForm.defaultErrorMessage',
    defaultMessage: 'Something went wrong when searching for the order',
  },
  transactionFound: {
    id: 'VirtualTerminalSearchForm.transactionFound',
    defaultMessage: 'Transaction found',
  },
  transactionNotFound: {
    id: 'VirtualTerminalSearchForm.transactionNotFound',
    defaultMessage: 'Transaction not found',
  },
  originalOrderLink: {
    id: 'VirtualTerminalSearchForm.originalOrderLink',
    defaultMessage: '<b>Contact Centre</b> order found. <a>View original order</a>',
  },
  userWithoutPermission: {
    id: 'VirtualTerminalSearchForm.userWithoutPermission',
    defaultMessage: 'This user does not have permission',
  },
  requiredOrderId: {
    id: 'VirtualTerminalSearchForm.requiredOrderId',
    defaultMessage: 'A valid order id is required',
  },
});
