import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, mergeAll, path, pathOr, flatten, filter, map, pluck, values, uniq } from 'ramda';

import { actions } from '../module';

import BookingFormContainer from './BookingForm';

export class BookingFormWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.refundables = [
      ...this.getFarePassengerRefundables(),
      ...this.getFeeRefundables(),
      ...this.getRailcardRefundables(),
      ...this.getLocalAreaProductsRefundables(),
    ];
  }

  componentDidMount() {
    if (this.refundables.length > 0) {
      const { load } = this.props;
      load(this.refundables);
    }
  }

  getFormName = () => `BookingForm(${this.props.booking.quoteUuid}-${this.props.index})`;

  getInitialValues = () => ({
    reasonCode: this.props.booking.reasonCode,
    adminFee: path(['adminFee', 'isSelected'], this.props.booking),
    fareRefundables: {
      ...this.getFarePassengersSelection(this.props.booking.journeys),
      ...this.getFarePassengersSelection(this.props.booking.localAreaJourneys),
    },
    railcardRefundables: this.getRailcardSelection(this.props.booking),
    feeRefundables: this.getFeesSelection(this.props.booking),
    overrideLastUsedDate: this.props.booking.customisations?.lastUsedDate?.value,
  });

  getFarePassengerRefundables = () =>
    compose(
      uniq(),
      flatten(),
      pluck('refundableId'),
      filter(({ isEligible }) => isEligible),
      flatten(),
      pluck('tickets'),
      flatten(),
      pluck('farePassengers'),
      path(['journeys'])
    )(this.props.booking);

  getRailcardRefundables = () => {
    const { railcard } = this.props.booking;
    if (railcard?.refundableId && railcard?.isEligible) return [railcard.refundableId];

    return [];
  };

  getLocalAreaProductsRefundables = () =>
    this.props.booking.localAreaJourneys
      .flatMap(({ farePassengers }) => farePassengers)
      .flatMap(({ tickets }) => tickets)
      .filter(({ isEligible }) => isEligible)
      .map(({ refundableId }) => refundableId);

  getFeeRefundables = () =>
    compose(
      values(),
      pluck('id'),
      filter((f) => f && f.isEligible),
      pathOr({}, ['fees'])
    )(this.props.booking);

  getFarePassengersSelection = compose(
    mergeAll(),
    map(({ refundableId, refundableReasonCode }) => ({
      [refundableId]: { isSelected: true, reasonCode: refundableReasonCode },
    })),
    filter((f) => f && f.isSelected),
    flatten(),
    pluck('tickets'),
    flatten(),
    pluck('farePassengers')
  );

  getRailcardSelection = (booking) => {
    if (booking.railcard) {
      const { refundableId, isSelected, notRefundableReason } = booking.railcard;
      return isSelected
        ? { [refundableId]: { isSelected: true, reasonCode: [notRefundableReason] } }
        : {};
    }
    return {};
  };

  getFeesSelection = compose(
    mergeAll(),
    map((f) => ({ [f]: true })),
    values(),
    pluck('id'),
    filter((f) => f && f.isSelected),
    pathOr({}, ['fees'])
  );

  render() {
    const {
      index,
      booking: { state, quoteReference, alternativeQuoteReferences, tmcQuoteReference },
    } = this.props;

    return (
      <BookingFormContainer
        key={this.getFormName()}
        index={index}
        {...this.props}
        quoteReference={quoteReference}
        alternativeQuoteReferences={alternativeQuoteReferences}
        tmcQuoteReference={tmcQuoteReference}
        form={this.getFormName()}
        initialValues={this.getInitialValues()}
        canRequest={state ? state.canRequest : true}
        canProcess={state ? state.canProcess : false}
        refundables={this.refundables}
        hasActiveRefundables={this.refundables.length > 0}
      />
    );
  }
}

BookingFormWrapper.propTypes = {
  index: PropTypes.number.isRequired,
  bookingId: PropTypes.string.isRequired,
  booking: PropTypes.shape({
    journeys: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
      })
    ),
    localAreaJourneys: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
      })
    ),
    railcard: PropTypes.shape({
      refundableId: PropTypes.string,
      isEligible: PropTypes.bool,
    }),
    quoteUuid: PropTypes.string,
    reasonCode: PropTypes.string,
    quoteReference: PropTypes.string,
    alternativeQuoteReferences: PropTypes.array,
    tmcQuoteReference: PropTypes.string,
    state: PropTypes.shape({
      canRequest: PropTypes.bool,
      canProcess: PropTypes.bool,
    }),
    customisations: PropTypes.shape({
      lastUsedDate: PropTypes.shape({
        value: PropTypes.instanceOf(Date),
      }),
    }),
  }).isRequired,
  customerId: PropTypes.string.isRequired,
  refundReasons: PropTypes.array.isRequired,
  orderHistoryRoute: PropTypes.string.isRequired,
  orderReference: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  refundReasonDisabled: PropTypes.bool,
  confirmPrompt: PropTypes.object,
  load: PropTypes.func.isRequired,
  warnWithMultipleFarePassengers: PropTypes.bool,
  noPermissionsAlert: PropTypes.object,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  load: (refundables) =>
    !ownProps.booking.quoteUuid &&
    dispatch(actions.loadQuote(ownProps.orderId, ownProps.bookingId, refundables)),
});

const BookingFormWrapperContainer = connect(null, mapDispatchToProps)(BookingFormWrapper);
BookingFormWrapperContainer.displayName = 'ConnectedBookingFormWrapper';
export default BookingFormWrapperContainer;
