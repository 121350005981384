/* THIS IS A TEMPORARY COMPONENT< SHOULD BE REPLACED BY A DEPOT FILTER BUTTON */

import React, { MouseEventHandler, FunctionComponent } from 'react';
import { StyleDeclarationValue, css } from 'aphrodite/no-important';
import { IconChevronDown, IconClose } from '@trainline/depot-web';

import Button from '../Button';

import styles from './styles';

export interface CommonButtonProps {
  id?: string;
  testId?: string;
  active?: boolean;
  filterButtonType?: 'binary' | 'menu' | 'modal';
  styleSheet?: StyleDeclarationValue;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onRemoveFilter?: MouseEventHandler<HTMLButtonElement>;
}

const FilterButton: FunctionComponent<CommonButtonProps> = ({
  id,
  testId,
  active,
  styleSheet,
  onClick,
  filterButtonType = 'binary',
  onRemoveFilter,
  children,
}) => (
  <div data-testid={testId} className={css([styles.container, styleSheet])}>
    <Button
      id={id}
      variant="ghost"
      onClick={onClick}
      styleSheet={[
        styles.default,
        active && styles.active,
        filterButtonType !== 'binary' && styles.buttonWithIcon,
        filterButtonType !== 'binary' && active && styles.activeButtonWithRemoveButton,
      ]}
      testId={`${testId}Button`}
    >
      {children}
      {filterButtonType === 'modal' && !active && <IconChevronDown />}
    </Button>
    {filterButtonType !== 'binary' && active && (
      <Button
        variant="ghost"
        styleSheet={[styles.default, styles.removeButton, styles.active]}
        onClick={onRemoveFilter}
      >
        <IconClose />
      </Button>
    )}
  </div>
);

export default FilterButton;
