import { StyleSheet } from 'aphrodite/no-important';
import { spacers, palettes } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    marginBottom: spacers.dp_s24,
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: spacers.dp_s24,
    marginBottom: spacers.dp_s24,
  },
  defaultTab: {
    borderColor: palettes.dp_comp_input_binary_selected_border_base,
    color: palettes.dp_comp_input_binary_selected_background_base,
    backgroundColor: palettes.dp_comp_input_binary_background_base,
    ':first-child': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    ':last-child': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  activeTab: {
    borderColor: palettes.dp_comp_input_binary_selected_border_base,
    backgroundColor: palettes.dp_comp_input_binary_selected_background_base,
    color: palettes.dp_app_text_inverted,
    ':hover': {
      backgroundColor: palettes.dp_comp_input_binary_selected_background_hover,
    },
    ':active': {
      backgroundColor: palettes.dp_comp_input_binary_selected_background_pressed,
    },
  },
  totalContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacers.dp_s24,
    columnGap: spacers.dp_s4,
  },
});
