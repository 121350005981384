import { Action, createActions, handleActions } from 'redux-actions';
import { put, call, takeLatest } from 'redux-saga/effects';
import { compose, groupBy, mergeAll, uniqBy, flatten, map as rMap, toPairs, pluck } from 'ramda';
import { createSelector } from 'reselect';

import { getProducts } from '@contactcentre-web/redux-common/selectors/products';
import request from '@contactcentre-web/utils/request';
import { CUSTOMER_ORDER_LOAD } from '@contactcentre-web/redux-common/actions/order';
import { selectors as orderSelectors } from '@contactcentre-web/customer-order/module';
import ProductType from '@contactcentre-web/redux-common/types/ProductType';
import type State from '@contactcentre-web/redux-common/types/State';
import type Price from '@contactcentre-web/redux-common/types/Price';
import type { Product } from '@contactcentre-web/redux-common/types/Product';
import type { Order } from '@contactcentre-web/redux-common/types/Order';
import type { TravelProduct } from '@contactcentre-web/redux-common/types/TravelProduct';
import type { Journey, Leg, Passenger } from '@contactcentre-web/redux-common/types/Journey';
import type TransactionSummary from '@contactcentre-web/redux-common/types/TransactionSummary';
import type { SeasonProduct } from '@contactcentre-web/redux-common/types/SeasonProduct';
import type RailcardProduct from '@contactcentre-web/redux-common/types/RailcardProduct';
import type LocalAreaProduct from '@contactcentre-web/redux-common/types/LocalAreaProduct';

const PREFIX = 'ORDER_HISTORY';
export const LOAD_ATTEMPT = 'LOAD_ATTEMPT';
export const LOAD_SUCCESS = 'LOAD_SUCCESS';
export const LOAD_FAILED = 'LOAD_FAILED';

export const LOAD_STATUS_PENDING = 'LOAD_STATUS_PENDING';
export const LOAD_STATUS_SUCCESS = 'LOAD_STATUS_SUCCESS';
export const LOAD_STATUS_FAILED = 'LOAD_STATUS_FAILED';

export const actions = createActions(
  {
    [LOAD_ATTEMPT]: (orderReference) => ({
      orderReference,
    }),
    [LOAD_SUCCESS]: (history) => history,
    [LOAD_FAILED]: () => null,
  },
  { prefix: PREFIX }
);

type PriceWithType = {
  price: Price;
  type: string;
};

type RequestedBy = {
  displayName: string | null;
  id: string | null;
  location: string | null;
  username: string;
};

export type EntryType =
  | 'refresh'
  | 'partialRefund'
  | 'booking'
  | 'collectionRestrictionRemoved'
  | 'unknown';

type Entry = {
  bookingId: string | null;
  bookingUri: string | null;
  creationDateTime: Date;
  entryType: EntryType;
  fulfilmentStatus: string;
  inventory: string;
  requestedBy: RequestedBy | null;
  requestorType: string;
};

export type RefundableStatus =
  | 'Pending'
  | 'Voiding'
  | 'Voided'
  | 'Blocked'
  | 'Rejected'
  | 'Refunded'
  | 'Failed'
  | 'Approved';

export type RefundableType = 'ProductRefundable' | 'BookingFee' | 'PaymentFee' | 'DeliveryFee';

export type Refundable = {
  reason: string;
  status: RefundableStatus;
  ticketType: string;
  type: RefundableType;
};

type ReturnModes = {
  [id: string]: {
    deliveryMethodClass: string;
    name: string;
    returnAddress: string | null;
    status: string;
  };
};

export type OrderHistoryType =
  | 'Refund'
  | 'ConvertedFrom'
  | 'ConvertedTo'
  | 'ConfirmationEmail'
  | 'Beboc'
  | 'BookingHistory'
  | 'ReplacementTo'
  | 'ReplacementFrom'
  | 'Reimbursement'
  | 'DiscretionaryRefundRequested'
  | 'DiscretionaryRefundRejected'
  | 'CojRefund'
  | 'MoveOrder'
  | 'DelayRepayClaim'
  | 'RailcardCancellation'
  | 'ExternalRefund'
  | 'FlexiSeasonReset'
  | 'FlexiSeasonCancel';

export interface OrderHistory {
  type: OrderHistoryType;
  status: string;
  amountRefunded?: Price | null;
  amountRequested?: Price | null;
  autoApproved?: boolean | null;
  bookingId: string | null;
  creationDateTime: Date;
  discounts?: Array<PriceWithType> | null;
  emails?: Array<string> | null;
  entries?: Array<Entry> | null;
  fees?: Array<PriceWithType>;
  id: string | null;
  orderReference?: string | null;
  policyType?: string | null;
  productIds?: Array<string> | null;
  reason?: string | null;
  refundId: string | null;
  refundRequestStatus?: string | null;
  refundables?: Array<Refundable> | null;
  requestedBy?: RequestedBy | null;
  requestedRefund?: Price;
  requestorType?: string;
  returnModes?: ReturnModes;
  totalReimbursedAmount?: Price | null;
  canUpdateStatus?: boolean;
  claimAmount?: Price | null;
  vendor?: string;
  railcardId?: string;
  journeyId?: string;
  contactReason?: ContactReason;
}

export interface OrderHistoryState {
  loadStatus: string;
  history: Array<OrderHistory> | null;
}

export const initialState = {
  loadStatus: LOAD_STATUS_PENDING,
  history: null,
};

const reducer = handleActions<OrderHistoryState, Array<OrderHistory>>(
  {
    [`${PREFIX}/${LOAD_ATTEMPT}`]: (state) => ({
      ...state,
      history: null,
      loadStatus: LOAD_STATUS_PENDING,
    }),
    [`${PREFIX}/${LOAD_SUCCESS}`]: (state, action) => ({
      ...state,
      loadStatus: LOAD_STATUS_SUCCESS,
      history: action.payload,
    }),
    [`${PREFIX}/${LOAD_FAILED}`]: (state) => ({
      ...state,
      loadStatus: LOAD_STATUS_FAILED,
    }),
    [CUSTOMER_ORDER_LOAD]: () => initialState,
  },
  initialState
);

export default reducer;

const getOrder = (state: State) => state.orders.order;
const getHistory = (state: State) => state.orderHistory.history;
const getLoadingStatus = (state: State) => state.orderHistory.loadStatus;
const getTransactionSummary = (state: State) => orderSelectors.getTransactionSummary(state);

const formatTicketsByType = rMap(([type, value]: [string, Array<string>]) => ({
  type,
  count: value.length,
}));
const getUniqueId = ({ id }: { id: string }) => id;
const getName = ({ name }: { name: string }) => name;
const createListOfUniqueNames = rMap(([type, value]: [string, Array<Passenger>]) => ({
  [type]: uniqBy(getUniqueId, value).map(getName),
}));
const groupByType = groupBy(({ type }: { type: string }) => type.toLowerCase());
const getPassengers = compose<Array<Leg>, Array<Array<Passenger>>, Array<Passenger>>(
  flatten,
  pluck('passengers')
);
const getLegs = compose<Array<Journey>, Array<Array<Leg>>, Array<Leg>>(flatten, pluck('legs'));

const getTicketsByType = compose(
  formatTicketsByType,
  toPairs,
  mergeAll,
  createListOfUniqueNames,
  toPairs,
  groupByType,
  getPassengers,
  getLegs
);

const isReturn = (journeys: Array<Journey>) => journeys.length > 1;

export interface Booking {
  type?: ProductType;
  bookingId: string;
  origin: string | null;
  destination: string | null;
  isReturn: boolean;
  transactionReference?: string;
  localAreaValidity?: string | null;
  ticketsByType?: Array<{
    type: string;
    count: number;
  }> | null;
}

const getBookingInformation = (
  travelBookings: Array<TravelProduct>,
  bookingId: string | null
): Array<Booking> =>
  travelBookings
    .filter(({ id }) => bookingId === id)
    .map(({ transactionReference, journeys, id }) => ({
      transactionReference,
      bookingId: id,
      destination: journeys[0].destination,
      origin: journeys[0].origin,
      isReturn: isReturn(journeys),
      ticketsByType: getTicketsByType(journeys),
    }));

const collapseCurrencyValues = (currencyValues: Array<PriceWithType>, filterType: string) => {
  const amount = currencyValues
    .filter(({ type }) => type === filterType)
    .reduce((next, curr) => next + curr.price.amount, 0);

  return {
    amount,
    currencyCode: currencyValues.length > 0 ? currencyValues[0].price.currencyCode : '',
  };
};

const getOrigin = (product: TravelProduct | SeasonProduct | LocalAreaProduct) => {
  switch (product.type) {
    case ProductType.TravelProduct: {
      return product.journeys[0].origin;
    }
    case ProductType.Season: {
      return product.journey.origin;
    }
    default: {
      return null;
    }
  }
};

const getDestination = (product: TravelProduct | SeasonProduct | LocalAreaProduct) => {
  switch (product.type) {
    case ProductType.TravelProduct: {
      return product.journeys[0].destination;
    }
    case ProductType.Season: {
      return product.journey.destination;
    }
    default: {
      return null;
    }
  }
};

const getProductInformation = (
  products: Array<Product>,
  bookingId: string | null
): Array<Booking> => {
  const product = products.filter(({ id }) => bookingId === id) as unknown as Array<
    TravelProduct | SeasonProduct | LocalAreaProduct
  >;

  return product?.map((p) => ({
    type: p.type,
    transactionReference: p.type === ProductType.TravelProduct ? p.transactionReference : undefined,
    bookingId: p.id,
    origin: getOrigin(p),
    destination: getDestination(p),
    isReturn: p.type === ProductType.TravelProduct ? isReturn(p.journeys) : false,
    ticketsByType: p.type === ProductType.TravelProduct ? getTicketsByType(p.journeys) : [],
    localAreaValidity: p.type === ProductType.Travelcard ? p.localAreaValidity : null,
  }));
};

export type RefundPolicyType =
  | 'samedayvoid'
  | 'termsandconditions'
  | 'discretionary'
  | 'changeofjourney';

export interface RefundItem {
  bookings: Array<Booking>;
  orderReference?: string | null;
  agent: string | null;
  createdAt: Date;
  card?: {
    type: string;
    tokenisedCardNumber: string;
  };
  reasonCode?: string | null;
  status: string;
  policyType: RefundPolicyType | null;
  adminFee?: Price | null;
  promoDiscount?: Price | null;
  amountRefunded?: Price | null;
  amountRequested?: Price | null;
  refundId: string | null;
  paidBy?: string;
  billingEmailAddress?: string;
  paymentType?: string | null;
  type: OrderHistoryType;
  requestorType?: string;
  returnModes?: ReturnModes;
  refundables?: Array<Refundable> | null;
}

const getRefundHistoryItem = (
  { paymentTypeDetails: { paymentType, card, paidBy, billingEmailAddress } }: TransactionSummary,
  products: Array<Product>,
  {
    bookingId,
    orderReference,
    requestedBy,
    creationDateTime,
    reason,
    status,
    policyType,
    fees,
    discounts,
    amountRefunded,
    amountRequested,
    refundId,
    requestorType,
    returnModes,
    type,
    refundables,
  }: OrderHistory
): RefundItem => ({
  bookings: getProductInformation(products, bookingId),
  orderReference,
  agent: requestedBy ? requestedBy.displayName : null,
  createdAt: creationDateTime,
  card,
  reasonCode: reason,
  status,
  policyType: (policyType
    ? policyType.replace(/-/g, '').toLowerCase()
    : null) as RefundPolicyType | null,
  adminFee: fees ? collapseCurrencyValues(fees, 'admin-fee') : undefined,
  promoDiscount: discounts ? collapseCurrencyValues(discounts, 'promoDiscount') : undefined,
  amountRefunded,
  amountRequested,
  refundId,
  paidBy,
  billingEmailAddress,
  paymentType: paymentType === 'ZeroCharge' ? null : paymentType,
  type,
  requestorType,
  returnModes,
  refundables,
});

export interface BookingHistoryItem {
  bookings: Array<Booking>;
  createdAt: Date;
  orderReference?: string | null;
  customerId: string;
  entries?: Array<{
    id: string;
    bookingUri: string | null;
    agent: string | null;
    creationDateTime: Date;
    fulfilmentStatus: string;
    action: EntryType;
    requestorType: string;
  }>;
  type: OrderHistoryType;
}

const getBookingHistoryItem = (
  { travelBookings, orderReference, customerId }: Order,
  { bookingId, entries, type, creationDateTime }: OrderHistory
): BookingHistoryItem => ({
  bookings: getBookingInformation(travelBookings, bookingId),
  createdAt: creationDateTime,
  orderReference,
  customerId,
  entries: entries?.map((entry) => ({
    id: entry.inventory,
    bookingUri: entry.bookingUri,
    agent: entry.requestedBy ? entry.requestedBy.displayName : null,
    creationDateTime: entry.creationDateTime,
    fulfilmentStatus: entry.fulfilmentStatus,
    action: entry.entryType,
    requestorType: entry.requestorType,
  })),
  type,
});

export interface BebocItem {
  agent: string | null;
  createdAt: Date;
  orderReference?: string | null;
  capitaineUrl: string;
  type: OrderHistoryType;
}

const getBebocHistoryItem = (order: Order, item: OrderHistory): BebocItem => ({
  agent: item.requestedBy ? item.requestedBy.displayName : null,
  createdAt: item.creationDateTime,
  orderReference: item.orderReference,
  capitaineUrl: order.capitaineUrl,
  type: item.type,
});

export interface ConfirmationEmailItem {
  emails?: string[] | null;
  createdAt: Date;
  orderReference?: string | null;
  agent: string | null;
  type: OrderHistoryType;
}

const getConfirmationEmailHistoryItem = (item: OrderHistory): ConfirmationEmailItem => ({
  emails: item.emails,
  createdAt: item.creationDateTime,
  orderReference: item.orderReference,
  agent: item.requestedBy ? item.requestedBy.displayName : null,
  type: item.type,
});

export interface ConversionItem {
  bookings: Array<Booking>;
  agent: string | null;
  createdAt: Date;
  orderReference?: string | null;
  requestorType?: string;
  type: OrderHistoryType;
}

const getConversionHistoryItem = (
  { travelBookings }: Order,
  { bookingId, requestedBy, creationDateTime, orderReference, requestorType, type }: OrderHistory
): ConversionItem => ({
  bookings: getBookingInformation(travelBookings, bookingId),
  agent: requestedBy ? requestedBy.displayName : null,
  createdAt: creationDateTime,
  orderReference,
  requestorType,
  type,
});

export interface ReimbursementItem {
  bookings: Array<Booking>;
  createdAt: Date;
  totalReimbursedAmount?: Price | null;
  type: OrderHistoryType;
}

const getReimbursementHistoryItem = (
  { travelBookings }: Order,
  { bookingId, creationDateTime, totalReimbursedAmount, type }: OrderHistory
): ReimbursementItem => ({
  bookings: getBookingInformation(travelBookings, bookingId),
  createdAt: creationDateTime,
  totalReimbursedAmount,
  type,
});

export interface DiscretionaryRefundRequestedItem {
  bookings: Array<Booking>;
  createdAt: Date;
  agent?: string | null;
  type: OrderHistoryType;
}

const getDiscretionaryRefundRequestedHistoryItem = (
  { travelBookings }: Order,
  { bookingId, creationDateTime, type, requestedBy }: OrderHistory
): DiscretionaryRefundRequestedItem => ({
  bookings: getBookingInformation(travelBookings, bookingId),
  createdAt: creationDateTime,
  agent: requestedBy ? requestedBy.displayName : null,
  type,
});

export interface DiscretionaryRefundRejectedItem {
  bookings: Array<Booking>;
  createdAt: Date;
  agent?: string | null;
  type: OrderHistoryType;
  requestedRefund?: Price | null;
}

const getDiscretionaryRefundRejectedHistoryItem = (
  { travelBookings }: Order,
  { bookingId, creationDateTime, type, requestedBy, requestedRefund }: OrderHistory
): DiscretionaryRefundRejectedItem => ({
  bookings: getBookingInformation(travelBookings, bookingId),
  createdAt: creationDateTime,
  agent: requestedBy ? requestedBy.displayName : null,
  type,
  requestedRefund,
});

export interface MoveOrderItem {
  createdAt: Date;
  agent?: string | null;
  type: OrderHistoryType;
  fromCustomerEmail?: string | null;
  toCustomerEmail?: string | null;
}

const getMoveOrderItem = ({
  type,
  creationDateTime,
  requestedBy,
  emails,
}: OrderHistory): MoveOrderItem => ({
  createdAt: creationDateTime,
  agent: requestedBy?.displayName,
  type,
  fromCustomerEmail: emails && emails[0],
  toCustomerEmail: emails && emails[1],
});

export interface JourneyInformation {
  bookingReferenceId?: string;
  origin?: string;
  destination?: string;
}

const getJourneyInformation = (
  travelBookings: Array<TravelProduct>,
  journeyId?: string,
  bookingId?: string | null
): JourneyInformation => {
  const booking = travelBookings.find(({ id }) => id === bookingId);
  const journey = booking && booking.journeys.find(({ id }) => id === journeyId);

  return {
    bookingReferenceId: booking && booking.transactionReference,
    origin: journey && journey.origin,
    destination: journey && journey.destination,
  };
};

export type DelayRepayClaimStatus =
  | 'Processing'
  | 'Submitted'
  | 'Approved'
  | 'Rejected'
  | 'PaidByToc'
  | 'CreditIssueSuccess'
  | 'CreditIssueFailure';

export interface DelayRepayClaimItem {
  type: OrderHistoryType;
  createdAt: Date;
  journey: JourneyInformation;
  claimId: string | null;
  status: DelayRepayClaimStatus;
}

const getDelayRepayClaimItem = (
  { travelBookings }: Order,
  { type, bookingId, journeyId, creationDateTime, id, status }: OrderHistory
): DelayRepayClaimItem => ({
  type,
  createdAt: creationDateTime,
  journey: getJourneyInformation(travelBookings, journeyId, bookingId),
  claimId: id,
  status: status as DelayRepayClaimStatus,
});

export interface RailcardCancellationItem {
  type: OrderHistoryType;
  createdAt: Date;
  agent: string | null;
  railcardName?: string;
  railcardNumber?: string;
}

const getRailcardCancellationItem = (
  products: Array<Product>,
  { type, requestedBy, railcardId, creationDateTime }: OrderHistory
): RailcardCancellationItem => {
  const railcard = products.find(({ id }) => id === railcardId) as unknown as RailcardProduct;

  return {
    type,
    createdAt: creationDateTime,
    agent: requestedBy ? requestedBy.displayName : null,
    railcardName: railcard?.name,
    railcardNumber: railcard?.railcardNumber,
  };
};

export type ExternalRefundStatus =
  | 'initiated'
  | 'submitted'
  | 'appealed'
  | 'accepted'
  | 'approved'
  | 'rejected';

export type ExternalRefundVendor = 'DetaxeRefund';

export type ContactReason =
  | 'canceledTrainDisruption'
  | 'refundedCanceledTrainDisruption'
  | 'onboardComfortIssues'
  | 'missedConnection'
  | 'deathIllness'
  | 'voidVoucher'
  | 'fareTypeRefunds'
  | 'duplicateBooking'
  | 'historicPNR'
  | 'finalizeOptionSNCF'
  | 'railcard';
export interface ExternalRefundItem {
  type: OrderHistoryType;
  vendor?: ExternalRefundVendor;
  createdAt: Date;
  bookings: Array<Booking>;
  agent: string | null;
  status: ExternalRefundStatus;
  refundId: string | null;
  canUpdateStatus?: boolean;
  claimAmount?: Price | null;
  contactReason?: ContactReason;
}

const getExternalRefundItem = (
  products: Array<Product>,
  {
    type,
    vendor,
    requestedBy,
    bookingId,
    creationDateTime,
    status,
    refundId,
    canUpdateStatus,
    claimAmount,
    contactReason,
  }: OrderHistory
): ExternalRefundItem => ({
  type,
  vendor: vendor as ExternalRefundVendor,
  createdAt: creationDateTime,
  bookings: getProductInformation(products, bookingId),
  agent: requestedBy ? requestedBy.username : null,
  status: status as ExternalRefundStatus,
  refundId,
  canUpdateStatus,
  claimAmount,
  contactReason,
});

export interface FlexiSeasonItem {
  type: OrderHistoryType;
  createdAt: Date;
  bookings: Array<Booking>;
  agent: string | null;
}

const getFlexiSeasonHistoryItem = (
  products: Array<Product>,
  { type, creationDateTime, bookingId, requestedBy }: OrderHistory
): FlexiSeasonItem => ({
  type,
  createdAt: creationDateTime,
  bookings: getProductInformation(products, bookingId),
  agent: requestedBy ? requestedBy.displayName : null,
});

export type OrderHistoryItem =
  | RefundItem
  | ConfirmationEmailItem
  | BebocItem
  | BookingHistoryItem
  | ConversionItem
  | ReimbursementItem
  | DiscretionaryRefundRequestedItem
  | DiscretionaryRefundRejectedItem
  | MoveOrderItem
  | DelayRepayClaimItem
  | RailcardCancellationItem
  | ExternalRefundItem
  | FlexiSeasonItem
  | { type: string; createdAt: Date };

const getHistoryItems = createSelector(
  getOrder,
  getHistory,
  getProducts,
  getTransactionSummary,
  (order, history, products, transactionSummary): Array<OrderHistoryItem> | null => {
    if (!order || !history || !products || !transactionSummary) {
      return null;
    }

    return history.map((item) => {
      switch (item.type) {
        case 'Refund':
          return getRefundHistoryItem(transactionSummary, products, item);
        case 'ConfirmationEmail':
          return getConfirmationEmailHistoryItem(item);
        case 'Beboc':
          return getBebocHistoryItem(order, item);
        case 'BookingHistory':
          return getBookingHistoryItem(order, item);
        case 'ConvertedFrom':
        case 'ConvertedTo':
        case 'ReplacementTo':
        case 'ReplacementFrom':
          return getConversionHistoryItem(order, item);
        case 'Reimbursement':
          return getReimbursementHistoryItem(order, item);
        case 'DiscretionaryRefundRequested':
          return getDiscretionaryRefundRequestedHistoryItem(order, item);
        case 'DiscretionaryRefundRejected':
          return getDiscretionaryRefundRejectedHistoryItem(order, item);
        case 'CojRefund':
          return getRefundHistoryItem(transactionSummary, products, item);
        case 'MoveOrder':
          return getMoveOrderItem(item);
        case 'DelayRepayClaim':
          return getDelayRepayClaimItem(order, item);
        case 'RailcardCancellation':
          return getRailcardCancellationItem(products, item);
        case 'ExternalRefund':
          return getExternalRefundItem(products, item);
        case 'FlexiSeasonReset':
        case 'FlexiSeasonCancel':
          return getFlexiSeasonHistoryItem(products, item);
        default:
          return {
            type: item.type,
            createdAt: item?.creationDateTime,
          };
      }
    });
  }
);

const isLoading = createSelector(
  getLoadingStatus,
  (historyLoadingStatus) => historyLoadingStatus === LOAD_STATUS_PENDING
);

const isFailed = createSelector(
  getLoadingStatus,
  (historyLoadingStatus) => historyLoadingStatus === LOAD_STATUS_FAILED
);

const orderHistoryHasItems = createSelector(
  getHistory,
  (orderHistory) => orderHistory && orderHistory.length > 0
);

export const selectors = {
  getHistoryItems,
  isLoading,
  isFailed,
  orderHistoryHasItems,
};

const getRefundRequest = (refundId: string): Promise<unknown> =>
  request(`/ExceptionalRefunds/${refundId}`);

export function* loadSaga({
  payload: { orderReference },
}: Action<{ orderReference: string }>): any {
  try {
    const response: Array<OrderHistory> = yield call(
      request,
      `/api/orders/${orderReference}/orderhistory`
    );

    const uniqueRefundIds = [...new Set(response.flatMap(({ refundId }) => refundId) ?? [])].filter(
      Boolean
    ) as string[];

    for (let i = 0; i < uniqueRefundIds.length; i++) {
      const refundId = uniqueRefundIds[i];
      const refundStatus = yield call(getRefundRequest, refundId);

      if (refundId) {
        for (const res of response) {
          if (res.refundId === uniqueRefundIds[i]) {
            res.contactReason = refundStatus.contactReasonId;
          }
        }
      }
    }
    yield put(actions.loadSuccess(response));
  } catch (error) {
    yield put(actions.loadFailed(error));
  }
}

export function* saga() {
  yield takeLatest(`${PREFIX}/${LOAD_ATTEMPT}`, loadSaga);
}
