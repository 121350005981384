import { StyleSheet } from 'aphrodite/no-important';
import { palettes, spacers } from '@trainline/depot-web/css/themes/trainline';

import { sidebarWidth } from '@contactcentre-web/styles/sizes';
import { zIndex } from '@contactcentre-web/styles/zIndex';

export default StyleSheet.create({
  sidebar: {
    width: 0,
    transition: 'width 0.3s',
  },
  sidebarOpen: {
    width: sidebarWidth,
    position: 'fixed',
    top: 0,
    right: 0,
    height: '100%',
    zIndex: zIndex.sidebar,
  },
  sidebarHeader: {
    padding: `${spacers.dp_s24} 0`,
    background: palettes.dp_brand_primary_core,
    position: 'relative',
    color: palettes.dp_app_text_inverted,
    flexGrow: 0,
    flexShrink: 0,
  },
  close: {
    position: 'absolute',
    top: spacers.dp_s16,
    right: spacers.dp_s16,
    background: palettes.dp_app_backdrop_base,
    color: palettes.dp_brand_primary_core,
    borderRadius: '50%',
    lineHeight: 0,
  },
  sidebarContent: {
    overflow: 'auto',
    flexGrow: 1,
    borderLeft: `1px solid ${palettes.dp_app_border_base}`,
    background: palettes.dp_app_backdrop_base,
  },
});
