export class FetchError<ErrorResponse = any> extends Error {
  code: string;
  status: number;
  response: Response;
  conversationId: string;
  validationErrors: ErrorResponse;
  errors: ErrorResponse;
  constructor(response: any, code = 'Fetch failed') {
    super(code);

    this.name = 'FetchError';
    this.code = code;
    this.status = response.status;
    this.response = response;
    this.conversationId = response.ConversationId;
    this.validationErrors = response.validationErrors;
    this.errors = response.errors;
  }
}
