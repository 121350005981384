import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { StatusMessage } from '@trainline/depot-web';

import { Status } from '@contactcentre-web/redux-common/types/Timetable';

import DisruptionReasonItem from './DisruptionReasonItem';
import styles from './styles';
import messages from './messages';

export interface Props {
  delayReasons: string[];
  cancellationReasons: string[];
  status: Status;
}

const DisruptionReasons = ({ delayReasons, cancellationReasons, status }: Props) => {
  const showDelayReasons = status === Status.Delayed && delayReasons.length > 0;
  const showCancellationReasons = status === Status.Cancelled && cancellationReasons.length > 0;

  return (
    <div className={css(styles.container)}>
      {(status === Status.CancelledOnOriginStop ||
        status === Status.CancelledOnDestinationStop ||
        status === Status.CancelledOnOriginAndDestinationStop ||
        (status === Status.Cancelled && cancellationReasons.length === 0)) && (
        <div className={css(styles.alertContainer)}>
          <StatusMessage status="negative">
            <div className={css(styles.listItem)}>
              <FormattedMessage {...messages[status]} />
            </div>
          </StatusMessage>
        </div>
      )}
      {showDelayReasons && (
        <StatusMessage status="negative">
          <ul data-test="delay-list">
            {delayReasons.map((delayReason, index) => (
              <div key={index}>
                <DisruptionReasonItem reason={delayReason} isPartOfList={delayReasons.length > 1} />
              </div>
            ))}
          </ul>
        </StatusMessage>
      )}
      {showCancellationReasons && (
        <StatusMessage status="negative">
          <ul data-test="cancellation-list">
            {cancellationReasons.map((cancellationReason, index) => (
              <div key={index}>
                <DisruptionReasonItem
                  reason={cancellationReason}
                  isPartOfList={cancellationReasons.length > 1}
                />
              </div>
            ))}
          </ul>
        </StatusMessage>
      )}
    </div>
  );
};

export default DisruptionReasons;
