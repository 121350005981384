import { defineMessages } from 'react-intl';

export default defineMessages({
  status: {
    id: 'TicketStatusModal.status',
    defaultMessage: 'View status',
  },
  modal_title: {
    id: 'TicketStatusModal.modal_title',
    defaultMessage: 'Ticket status',
  },
  label_error: {
    id: 'TicketStatusModal.label_error',
    defaultMessage: "There's been an error while loading the ticket status.",
  },
  label_nostatus: {
    id: 'TicketStatusModal.label_nostatus',
    defaultMessage: 'No status has been reported for this ticket.',
  },
  button_done: {
    id: 'TicketStatusModal.button_done',
    defaultMessage: 'Done',
  },
  trainlineStatus_unknown: {
    id: 'TicketStatusModal.trainlineStatus_unknown',
    defaultMessage: 'Unknown',
  },
  button_generate: {
    id: 'TicketStatusModal.button_generate',
    defaultMessage: 'Generate',
  },
  failed_generate: {
    id: 'TicketStatusModal.failed_generate',
    defaultMessage: "There's been an error while generating the ticket.",
  },
  cancelledDescription: {
    id: 'TicketStatusModal.cancelledDescription',
    defaultMessage: 'Ticket will not be accepted for travel',
  },
  notUsedDescription: {
    id: 'TicketStatusModal.notUsedDescription',
    defaultMessage: 'Ticket available for travel',
  },
  scannedDescription: {
    id: 'TicketStatusModal.scannedDescription',
    defaultMessage: 'Ticket presented to scanner but not accepted for travel',
  },
  activeDescription: {
    id: 'TicketStatusModal.activeDescription',
    defaultMessage: 'Ticket already used',
  },
  unavailableDescription: {
    id: 'TicketStatusModal.unavailableDescription',
    defaultMessage: 'Ticket generation status is unknown',
  },
  fulfillingDescription: {
    id: 'TicketStatusModal.fulfillingDescription',
    defaultMessage: 'Ticket generation is in progress',
  },
  fulfilledDescription: {
    id: 'TicketStatusModal.fulfilledDescription',
    defaultMessage: 'Ticket is generated',
  },
  readyForFetchDescription: {
    id: 'TicketStatusModal.readyForFetchDescription',
    defaultMessage: 'Ticket is awaiting generation',
  },
  failedDescription: {
    id: 'TicketStatusModal.failedDescription',
    defaultMessage: 'Ticket generation has failed',
  },
});
