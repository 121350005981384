import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { useSelector } from 'react-redux';

import Search from '@contactcentre-web/search/Search';
import { getCurrentManagedGroupName } from '@contactcentre-web/authentication/redux/selectors';
import Accordion from '@contactcentre-web/common/Accordion/Accordion';

import styles from './styles';
import messages from './messages';

export const SearchPage = () => {
  const location = useLocation();
  const orderReference = new URLSearchParams(location.search).get('orderReference');
  const pnr = new URLSearchParams(location.search).get('pnr');
  const q = new URLSearchParams(location.search).get('q');
  const queryString = orderReference || pnr || q;
  const managedGroup = useSelector(getCurrentManagedGroupName);

  if (queryString) {
    return <Redirect to={`/customers/${location.search}`} />;
  }

  return (
    <section className={css(styles.page)}>
      <div className={css(styles.searchContainer)}>
        <h2 className={css(styles.title)}>
          <FormattedMessage {...messages.searchTitle} values={{ managedGroup }} />
        </h2>

        <div className={css(styles.inputContainer)}>
          <Search />
        </div>
        <div className={css(styles.accordionContainer)}>
          <Accordion tabIndex={0} heading={messages.searchOptionsHeading}>
            <FormattedMessage {...messages.bookingSearchOptions} />
            <div className={css(styles.unorderedList)}>
              <FormattedMessage
                {...messages.bookingSearchOptionsList}
                values={{
                  ul: (...msg: React.ReactNode[]) => <ul>{msg}</ul>,
                  li: (...msg: React.ReactNode[]) => <li>{msg}</li>,
                }}
              />
            </div>
          </Accordion>
          <Accordion tabIndex={0} heading={messages.howToFindBookingIdHeading}>
            <div>
              <FormattedMessage
                {...messages.bookingTransactionNumberOptions}
                values={{
                  p: (...msg: React.ReactNode[]) => <p>{msg}</p>,
                  b: (...msg: React.ReactNode[]) => <b>{msg}</b>,
                  linebreak: <br />,
                }}
              />
            </div>
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default SearchPage;
