import { createPortal } from 'react-dom';
import React, { FunctionComponent, createContext, useContext, useEffect } from 'react';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';
import FocusTrap from 'focus-trap-react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Heading, IconClose, Paragraph } from '@trainline/depot-web';

// import UsabillaButton from '../UsabillaButton';
import Button from '../Button';

import styles from './styles';

interface ModalContextProps {
  onClose: () => void;
}

const ModalContext = createContext<ModalContextProps>({ onClose: () => null });

interface ModalProps {
  onClose: () => void;
  testId?: string;
  styleSheet?: {
    content?: StyleDeclarationValue;
  };
  initialFocus?: HTMLElement | SVGElement | string | false;
}

export const Modal: FunctionComponent<ModalProps> = ({
  onClose,
  testId,
  styleSheet,
  initialFocus,
  children,
}) => {
  useHotkeys('esc', () => onClose());

  const portalElement = document.body;

  // Prevent body scroll when modal is open
  useEffect(() => {
    portalElement.style.overflow = 'hidden';

    return () => {
      portalElement.removeAttribute('style');
    };
  }, []);

  return createPortal(
    <>
      <ModalContext.Provider value={{ onClose }}>
        <FocusTrap focusTrapOptions={{ initialFocus }}>
          <div data-test={testId} className={css(styles.wrapper)}>
            <div
              role="dialog"
              aria-modal="true"
              data-testid="modal-content"
              className={css(styles.content, styleSheet?.content)}
            >
              {children}
            </div>
          </div>
        </FocusTrap>
      </ModalContext.Provider>
      {/* <UsabillaButton /> */}
    </>,
    portalElement
  );
};

export const ModalHeader: FunctionComponent = ({ children }) => {
  const { onClose } = useContext(ModalContext);

  return (
    <div className={css(styles.header)}>
      {children}
      <Button
        variant="ghost"
        testId="modal-close"
        ariaLabel="Close"
        onClick={onClose}
        styleSheet={styles.close}
      >
        <IconClose />
      </Button>
    </div>
  );
};

export const ModalTitle: FunctionComponent = ({ children }) => (
  <Heading typeStyle="title2" as="h2" color="base">
    {children}
  </Heading>
);

export const ModalSubtitle: FunctionComponent = ({ children }) => (
  <Paragraph typeStyle="body" as="p" color="base" fontWeight="regular">
    {children}
  </Paragraph>
);

interface ModalBodyProps {
  styleSheet?: StyleDeclarationValue;
}

export const ModalBody: FunctionComponent<ModalBodyProps> = ({ children, styleSheet }) => (
  <div className={styleSheet && css(styleSheet)}>{children}</div>
);

interface ModalFooterProps {
  contentAlignment?: 'end' | 'spaceBetween';
}

export const ModalFooter: FunctionComponent<ModalFooterProps> = ({
  children,
  contentAlignment = 'end',
}) => <div className={css(styles.footer, styles[contentAlignment])}>{children}</div>;
