import { defineMessages } from 'react-intl';

export default defineMessages({
  editPassengerDetails: {
    id: 'AlterPassengers.editPassengerDetails',
    defaultMessage: 'Edit passenger details',
  },
  editFailed: {
    id: 'AlterPassengers.editFailed',
    defaultMessage: 'Failed to save changes. Try again.',
  },
  editSuccess: {
    id: 'AlterPassengers.editSuccess',
    defaultMessage: 'Passenger details have been updated.',
  },
});
