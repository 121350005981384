import { defineMessages } from 'react-intl';

export default defineMessages({
  notesTitle: {
    id: 'NotesHeader.notesTitle',
    defaultMessage: 'Notes',
  },
  CustomerTopic: {
    id: 'NotesHeader.CustomerTopic',
    defaultMessage: "Customer's notes ({count})",
  },
  OrderTopic: {
    id: 'NotesHeader.OrderTopic',
    defaultMessage: "Orders's notes ({count})",
  },
  forCustomer: {
    id: 'NotesHeader.forCustomer',
    defaultMessage: 'For customer: {forename} {surname}',
  },
  orderId: {
    id: 'NotesHeader.orderId',
    defaultMessage: 'On order Id: {orderId}',
  },
});
