import React, { FunctionComponent } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { IconChevronLeft } from '@trainline/depot-web';

import Button from '@contactcentre-web/common/Button';

import SidebarHeader from '../../../SidebarHeader';
import type { Topic } from '../../module';

import messages from './messages';
import styles from './styles';

interface Props {
  clearTopic?: () => void;
  topic?: Topic;
  orderId?: string;
  count?: number;
}

const NotesHeader: FunctionComponent<Props> = ({ clearTopic, topic, orderId, count }) => (
  <SidebarHeader styleSheet={styles.notesHeader}>
    {clearTopic && (
      <Button variant="ghost" styleSheet={styles.backButton} onClick={clearTopic}>
        <IconChevronLeft className={css(styles.chevronBack)} />
      </Button>
    )}
    <div className={css(styles.headingDisplay)}>
      <div className={css(styles.headingInfo)}>
        <h3>
          {topic ? (
            <FormattedMessage {...messages[`${topic}Topic`]} values={{ count: `${count || 0}` }} />
          ) : (
            <FormattedMessage {...messages.notesTitle} />
          )}
        </h3>
        {topic === 'Order' && <FormattedMessage {...messages.orderId} values={{ orderId }} />}
      </div>
    </div>
  </SidebarHeader>
);

export default NotesHeader;
