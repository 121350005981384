import { defineMessages } from 'react-intl';

export default defineMessages({
  from: {
    id: 'BookingSummarySorter.from',
    defaultMessage: 'Product',
  },
  orderReference: {
    id: 'BookingSummarySorter.orderReference',
    defaultMessage: 'Order ID',
  },
  orderDate: {
    id: 'BookingSummarySorter.orderDate',
    defaultMessage: 'Date Booked',
  },
  departureDate: {
    id: 'BookingSummarySorter.departureDate',
    defaultMessage: 'Outward Travel date',
  },
  returnDate: {
    id: 'BookingSummarySorter.returnDate',
    defaultMessage: 'Return Travel date',
  },
  price: {
    id: 'BookingSummarySorter.price',
    defaultMessage: 'Price',
  },
  bookingStatus: {
    id: 'BookingSummarySorter.bookingStatus',
    defaultMessage: 'Vendor Status',
  },
  atTime: {
    id: 'BookingSummarySorter.atTime',
    defaultMessage: 'at {time}',
  },
  bookingLoadError: {
    id: 'CustomerBookingSummary.bookingLoadError',
    defaultMessage: 'Sorry, the details of this booking could not be fetched.',
  },
  inventoryReference: {
    id: 'CustomerBookingSummary.inventoryReference',
    defaultMessage: 'CTR / PNR',
  },
});
