import { defineMessages } from 'react-intl';

export default defineMessages({
  exchangesOrder: {
    id: 'ExchangesBookingAlert.exchangesOrder',
    defaultMessage: 'This is a changed booking. You can view the original booking {link}.',
  },
  amendSeatReservationExchange: {
    id: 'ExchangesBookingAlert.amendSeatReservationExchange',
    defaultMessage:
      'Customer amended their seat reservation. This is the new reservation. You can check the original booking {link}.',
  },
  exchanges: {
    id: 'ExchangesBookingAlert.exchanges',
    defaultMessage: 'here',
  },
});
