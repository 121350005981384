import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';

import styles from './styles';

export const Frame = ({ level, title, children }) => (
  <section className={css(styles.frame, styles[level.toLowerCase()])}>
    <h3 className={css(styles.title, styles[`title_${level.toLowerCase()}`])}>{title}</h3>
    <section className={css(styles.body)}>{children}</section>
  </section>
);

Frame.propTypes = {
  level: PropTypes.oneOf(['Failure', 'Warning']).isRequired,
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default Frame;
