import React, { Dispatch, SetStateAction } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import { css } from 'aphrodite/no-important';
import { Paragraph, StatusMessage } from '@trainline/depot-web';

import Expandable from '@contactcentre-web/common/Expandable';
import {
  Permission,
  Role,
  useUpdateRolePermissions,
} from '@contactcentre-web/hooks/api/useRolesAndManageablePermissions';
import Button from '@contactcentre-web/common/Button';
import Checkbox from '@contactcentre-web/common/Checkbox';

import messages from './messages';
import styles from './styles';

interface Props {
  roleId: string;
  roleName: string;
  managedPermissions: Permission[];
  initialPermissions: Permission[];
  setOpenTab: Dispatch<SetStateAction<string>>;
  openTab: string;
  setRolesAndPermissions: Dispatch<SetStateAction<Role[]>>;
}

const RolePermissions = ({
  initialPermissions,
  roleId,
  roleName,
  managedPermissions,
  setOpenTab,
  openTab,
  setRolesAndPermissions,
}: Props) => {
  const queryClient = useQueryClient();
  const { mutate, isSuccess, isError } = useUpdateRolePermissions({
    onSettled: () => queryClient.invalidateQueries('rolesAndPermissions'),
  });

  const havePermissionsNotChanged = initialPermissions.reduce((acc, { code, enabled }) => {
    const isCurrentPermissionEnabled = managedPermissions?.find(
      (currentPermission) => currentPermission.code === code
    )?.enabled;

    return isCurrentPermissionEnabled === enabled ? acc : false;
  }, true);

  return (
    <Expandable.Wrapper styleSheet={styles.expandableWrapper} testId="role-expandable">
      <Expandable.Anchor
        onClick={() => setOpenTab((prevOpenTab) => (prevOpenTab === roleName ? '' : roleName))}
        isOpen={openTab === roleName}
      >
        {roleName}
      </Expandable.Anchor>
      <Expandable.Content isOpen={openTab === roleName}>
        {(isSuccess || isError) && (
          <div className={css(styles.updateStatusMessage)}>
            <StatusMessage status={isSuccess ? 'positive' : 'negative'}>
              <FormattedMessage
                {...(isSuccess ? messages.changesSaved : messages.changesNotSaved)}
              />
            </StatusMessage>
          </div>
        )}
        <div className={css(styles.permissionsLabel)}>
          <Paragraph typeStyle="small" as="p" color="base" fontWeight="bold">
            <FormattedMessage {...messages.readOnlyPermissions} />
          </Paragraph>
        </div>
        {managedPermissions.map(({ code, name, enabled }) => (
          <Checkbox
            key={code}
            label={name}
            styleSheet={styles.checkbox}
            input={{
              name,
              value: enabled,
              onChange: () => {
                setRolesAndPermissions((prevRolesAndPermissions) =>
                  prevRolesAndPermissions.map((role) => ({
                    ...role,
                    managedPermissions:
                      role.roleId === roleId
                        ? role.managedPermissions.map((permission) => ({
                            ...permission,
                            enabled:
                              permission.code === code ? !permission.enabled : permission.enabled,
                          }))
                        : role.managedPermissions,
                  }))
                );
              },
            }}
          />
        ))}
        <Button
          variant="primary"
          size="small"
          disabled={havePermissionsNotChanged}
          styleSheet={styles.saveButton}
          onClick={() =>
            mutate({
              roleId,
              permissions: managedPermissions,
            })
          }
        >
          <FormattedMessage {...messages.save} />
        </Button>
      </Expandable.Content>
    </Expandable.Wrapper>
  );
};

export default RolePermissions;
