import { handleActions } from 'redux-actions';

import { load, update, refund, DISPOSE } from './module';

export const initialState = {
  order: null,
  loadStatus: load.LOAD_NONE,
  updateStatus: '',
  refundStatus: '',
};

// Some upstream errors shouldn't be treated like errors in the application
// For example when a fraud quote needs to be overriden
const nonErrorRequestFailures = ['refundRejectedByFraudProvider'];

const reducer = handleActions(
  {
    [DISPOSE]: () => initialState,
    [load.LOAD_ATTEMPT]: (state) => ({
      ...state,
      order: null,
      loadStatus: load.LOAD_ATTEMPT,
      refundStatus: '',
      updateStatus: '',
      requestStatus: null,
    }),
    [load.LOAD_SUCCESS]: (state, action) => ({
      ...state,
      loadStatus: load.LOAD_SUCCESS,
      order: action.payload,
    }),
    [load.LOAD_FAILED]: (state) => ({
      ...state,
      loadStatus: load.LOAD_FAILED,
    }),
    [update.UPDATE_ATTEMPT]: (state) => ({
      ...state,
      updateStatus: update.UPDATE_ATTEMPT,
      requestStatus: null,
    }),
    [update.UPDATE_SUCCESS]: (state, action) => ({
      ...state,
      updateStatus: update.UPDATE_SUCCESS,
      order: {
        ...state.order,
        quote: { ...action.payload },
      },
    }),
    [update.UPDATE_FAILED]: (state) => ({
      ...state,
      updateStatus: update.UPDATE_FAILED,
    }),
    [refund.REFUND_ATTEMPT]: (state) => ({
      ...state,
      refundStatus: refund.REFUND_ATTEMPT,
      requestStatus: null,
    }),
    [refund.REFUND_SUCCESS]: (state) => ({
      ...state,
      refundStatus: refund.REFUND_SUCCESS,
    }),
    [refund.REFUND_FAILED]: (state, { payload: { error, unprocessableReasons } }) => ({
      ...state,
      refundStatus: refund.REFUND_FAILED,
      requestStatus: {
        unprocessableReasons,
        error: nonErrorRequestFailures.includes(unprocessableReasons?.[0]?.reasonCode)
          ? null
          : error,
      },
    }),
  },
  initialState
);

export default reducer;
