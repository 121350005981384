import { useQuery } from 'react-query';

import request from '@contactcentre-web/utils/request';
import { adsServiceUrl } from '@contactcentre-web/utils/constants';

const POLL_TELEPHONY_FREQUENCY_IN_MILISSECONDS = 5000; // 5 seconds

export interface TelephonyStatus {
  username: string;
  loggedIn: boolean;
}

export const useTelephonyStatusPoll = () =>
  useQuery<TelephonyStatus>('telephonyStatus', () => request(`${adsServiceUrl}/phone`), {
    refetchInterval: POLL_TELEPHONY_FREQUENCY_IN_MILISSECONDS,
  });
