import { createSelector } from 'reselect';

import { SortDirection } from '@contactcentre-web/hooks/shared/useToggleSortDirection';
import type State from '@contactcentre-web/redux-common/types/State';
import type Currency from '@contactcentre-web/redux-common/types/Price';

import { loadStatus, approvalStatus, rejectStatus, overrideStatus } from './module';

const localState = (state: State) => state.refundsApproval;

const isLoading = createSelector(
  localState,
  (state) => state.loadStatus === loadStatus.LOAD_STATUS_INPROGRESS
);

const isLoadingNext = createSelector(
  localState,
  (state) => state.loadStatus === loadStatus.LOAD_NEXT_STATUS_INPROGRESS
);

const hasFailed = createSelector(
  localState,
  (state) => state.loadStatus === loadStatus.LOAD_STATUS_ERROR
);

const hasLoadNextFailed = createSelector(
  localState,
  (state) => state.loadStatus === loadStatus.LOAD_NEXT_ERROR
);

export interface RequestItem {
  bookingId: string;
  tmcQuoteReference: string;
  quoteReference: string;
  orderReference: string;
  policyType: string;
  customerId: string;
  id: string;
  origin: string;
  destination: string;
  isReturn: boolean;
  transactionPrice: Currency;
  ticketTypes: Array<string>;
  agent: string;
  createdAt: Date;
  hasQuoteExpired: boolean;
  reason: string;
  bookingFees: Currency;
  paymentFees: Currency;
  deliveryFees: Currency;
  adminFees: Currency;
  totalRefundable: Currency;
  lastOrderNote?: string;
  quoteConditions: Array<{ reason: { code: string } }>;
  refundableReasons: Array<{
    description: string;
    id: string;
    reasonCode: string;
    ticketType: string;
  }>;
}

export type RequestData = Array<RequestItem> & {
  nextPage: string;
  totalCount: number;
  pageSize: number;
};

const requests = createSelector(localState, (state) => {
  const errorRefunableItems = (state.refunds.errorItems || [])
    .filter(({ meta }) => !!meta?.creationDateTime)
    .map(({ meta }) => ({ ...meta }));

  const allItems = [...state.refunds.items, ...errorRefunableItems].sort((item1, item2) => {
    const order = item1.creationDateTime < item2.creationDateTime ? 1 : -1;
    return state.sortDirection === SortDirection.ASC ? -order : order;
  });

  const mappedItems = allItems.map((r) => ({
    bookingId: r.booking?.id,
    tmcQuoteReference: r.tmcQuoteReference,
    quoteReference: r.quoteReference,
    policyType: 'discretionary',
    orderReference: r.orderReference,
    customerId: r.customerId,
    id: r.id,
    origin: r.booking?.journey.origin,
    destination: r.booking?.journey.destination,
    isReturn: r.booking?.journey.isReturn,
    transactionPrice: r.booking?.transactionPrice,
    ticketTypes: r.booking?.ticketTypes,
    agent: r.requestedBy?.displayName,
    createdAt: r.creationDateTime,
    hasQuoteExpired: !!r.links?.find(({ rel }) => rel === 'delete'),
    reason: r && r.reason && r.reason?.description,
    bookingFees: r.refund?.fees.booking,
    paymentFees: r.refund?.fees.payment,
    deliveryFees: r.refund?.fees.delivery,
    adminFees: r.refund?.fees.admin,
    totalRefundable: r.refund?.totalRefundable,
    lastOrderNote: r.lastOrderNote,
    quoteConditions: r.refund?.quoteConditions,
    refundableReasons: r.refundableReasons,
  }));

  (mappedItems as RequestData).nextPage = state.refunds.nextPage;
  (mappedItems as RequestData).totalCount = state.refunds.totalCount;
  (mappedItems as RequestData).pageSize = state.refunds.pageSize;

  return mappedItems as RequestData;
});
const isApprovalInProgress = createSelector(
  localState,
  (state) => state.approvalStatus === approvalStatus.APPROVAL_STATUS_INPROGRESS
);
const hasApprovalFailed = createSelector(
  localState,
  (state) => state.approvalStatus === approvalStatus.APPROVAL_STATUS_ERROR
);

const approvalFailedMessage = createSelector(localState, (state) => state.approvalFailedMessage);

const requestId = createSelector(localState, (state) => state.requestId);

const hasApprovalSucceeded = createSelector(
  localState,
  (state) => state.approvalStatus === approvalStatus.APPROVAL_STATUS_SUCCESS
);

const isRejectInProgress = createSelector(
  localState,
  (state) => state.rejectStatus === rejectStatus.REJECT_STATUS_INPROGRESS
);
const hasRejectFailed = createSelector(
  localState,
  (state) => state.rejectStatus === rejectStatus.REJECT_STATUS_ERROR
);
const hasRejectSucceeded = createSelector(
  localState,
  (state) => state.rejectStatus === rejectStatus.REJECT_STATUS_SUCCESS
);
const isOverrideInProgress = createSelector(
  localState,
  (state) => state.overrideStatus === overrideStatus.OVERRIDE_STATUS_INPROGRESS
);

const refundsItems = createSelector(localState, (state) => state.refunds.items);

const refundsErrorItems = createSelector(localState, (state) => state.refunds.errorItems);

const hasMorePages = createSelector(localState, (state) => !!state.refunds.nextPage);

const isRefundsListEmpty = createSelector(
  localState,
  (state) => state.refunds.items.length === 0 && state.refunds.errorItems.length === 0
);

export default {
  isLoading,
  isLoadingNext,
  requests,
  hasFailed,
  isApprovalInProgress,
  hasApprovalFailed,
  hasApprovalSucceeded,
  hasLoadNextFailed,
  approvalFailedMessage,
  hasMorePages,
  isRefundsListEmpty,
  isRejectInProgress,
  isOverrideInProgress,
  hasRejectFailed,
  hasRejectSucceeded,
  refundsItems,
  refundsErrorItems,
  requestId,
};
