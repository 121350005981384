import { StyleSheet } from 'aphrodite/no-important';
import {
  borderRadius,
  palettes,
  spacers,
  typography,
} from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  frame: {
    backgroundColor: palettes.dp_app_backdrop_base,
    borderRadius: borderRadius.dp_r4,
    boxShadow: '0 4px 8px 0 rgba(33, 49, 77, 0.2)',
  },
  title: {
    borderRadius: `${borderRadius.dp_r4} ${borderRadius.dp_r4} 0px 0px`,
    padding: `${spacers.dp_s8} ${spacers.dp_s24}`,
    fontSize: typography.dp_large_font_size,
    lineHeight: typography.dp_large_line_height,
  },
  title_failure: {
    color: palettes.dp_app_text_inverted,
    backgroundColor: palettes.dp_app_negative_dark,
  },
  title_warning: {
    color: palettes.dp_app_text_inverted,
    backgroundColor: palettes.dp_app_warning_dark,
  },
  failure: {
    backgroundColor: palettes.dp_app_negative_lighter,
  },
  warning: {
    backgroundColor: palettes.dp_app_backdrop_one_lightest,
  },
  default: {
    backgroundColor: palettes.dp_app_backdrop_base,
  },
  body: {
    padding: `${spacers.dp_s16} ${spacers.dp_s24}`,
  },
});
