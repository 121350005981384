import {
  borderRadius,
  palettes,
  spacers,
  typography,
} from '@trainline/depot-web/css/themes/trainline';
import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({
  paymentDetails: {
    background: palettes.dp_app_backdrop_dark,
    borderRadius: borderRadius.dp_r4,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, auto)',
    gap: `${spacers.dp_s8} ${spacers.dp_s16}`,
    marginBottom: spacers.dp_s32,
    padding: spacers.dp_s16,
    fontSize: typography.dp_small_font_size_m,
    lineHeight: typography.dp_small_line_height,
  },
});
