import React, { FunctionComponent, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { reduxForm, FieldArray, InjectedFormProps, formValueSelector, change } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { Heading, StatusMessage } from '@trainline/depot-web';

import Loader from '@contactcentre-web/common/Loader';
import TextField from '@contactcentre-web/common/TextField';
import Select from '@contactcentre-web/common/Select';
import InfoBox from '@contactcentre-web/common/InfoBox';
import Button from '@contactcentre-web/common/Button';
import Checkbox from '@contactcentre-web/common/Checkbox';
import ManagedGroupAndRoles from '@contactcentre-web/common/ManagedGroupAndRoles';
import {
  normalizeCharacters,
  normalizeLength,
  normalizeUsername,
} from '@contactcentre-web/utils/normalize';
import {
  getCurrentManagedGroupId,
  currentManagedGroupHasSsoConfiguration,
} from '@contactcentre-web/authentication/redux/selectors';
import { useSites } from '@contactcentre-web/hooks/api/useSites';
import { useCanManageRoles } from '@contactcentre-web/hooks/api/useCanManageRoles';
import {
  useCreateNewAgent,
  CreateNewAgentFormData,
  ManagedGroupAndRoleType,
} from '@contactcentre-web/hooks/api/useCreateNewAgent';
import { validateEmail } from '@contactcentre-web/utils/validation/validators';

import NewAgentConfirmation from './components/NewAgentConfirmation';
import messages from './messages';
import styles from './styles';

const FORM_ID = 'NewAgentForm';

const CreateNewAgent: FunctionComponent<InjectedFormProps<CreateNewAgentFormData>> = ({
  initialize,
  handleSubmit,
  reset: resetForm,
}) => {
  const { formatMessage } = useIntl();
  const enableSso = useSelector((state) =>
    formValueSelector(FORM_ID)(state, 'enableSso')
  ) as boolean;
  const email = useSelector((state) => formValueSelector(FORM_ID)(state, 'email')) as string;
  const currentManagedGroupId = useSelector(getCurrentManagedGroupId);
  const ssoConfig = useSelector(currentManagedGroupHasSsoConfiguration);
  const { data: sites, isLoading: isLoadingSites } = useSites();
  const { data: roles, isLoading: isLoadingRoles } = useCanManageRoles();
  const {
    data: newAgentData,
    isLoading: isCreatingNewAgent,
    isSuccess: isCreateSuccess,
    mutate: createNewAgent,
    reset: resetMutation,
    error: createNewAgentError,
  } = useCreateNewAgent();
  const dispatch = useDispatch();

  const sitesOptions = sites?.map(({ name }) => ({ label: name, value: name }));

  const errorMappings = new Map([
    ['CCAS.UserNameAlreadyUsed', messages.usernameAlreadyExists],
    ['CCAS.EmployeeIdAlreadyUsed', messages.employeeIdAlreadyUsed],
    ['CCAS.EmailDomainIsNotValid', messages.emailIsNotAllowed],
    ['CCAS.EmailAlreadyUsed', messages.emailAlreadyExists],
    ['CCAS.SsoMissingEmail', messages.emailIsRequiredForSso],
    ['CCAS.EmailIsEmpty', messages.emailIsRequired],
  ]);

  const userCreateFailedMessages = () => {
    const errors = [];
    const createErrors = createNewAgentError?.errors?.errors || [];

    createErrors?.forEach(({ code }) => {
      if (errorMappings.has(code)) {
        errors.push(errorMappings.get(code));
      }
    });

    if (createErrors?.length && errors.length === 0) {
      errors.push(messages.error);
    }

    return errors;
  };

  useEffect(() => {
    initialize({
      roles: [
        {
          managedGroup: currentManagedGroupId,
          role: '',
        },
      ],
      enableSso: ssoConfig?.forceSsoUsers,
    });
  }, []);

  useEffect(() => {
    if (enableSso) {
      dispatch(change(FORM_ID, 'username', email));
    }
  }, [email, enableSso]);

  if (isLoadingSites || isLoadingRoles) {
    return <Loader />;
  }

  if (isCreateSuccess && newAgentData) {
    return (
      <NewAgentConfirmation
        {...newAgentData}
        reset={() => {
          resetMutation();
          resetForm();
        }}
      />
    );
  }

  return (
    <>
      <Heading typeStyle="title1" as="h1" color="base">
        <FormattedMessage {...messages.title} />
      </Heading>
      {userCreateFailedMessages().map((error) => (
        <div className={css(styles.container)} key={error.id}>
          <StatusMessage status="negative">
            <FormattedMessage {...error} />
          </StatusMessage>
        </div>
      ))}
      <form
        id={FORM_ID}
        data-testid={FORM_ID}
        className={css(styles.container)}
        onSubmit={handleSubmit((data) => createNewAgent(data))}
      >
        <div className={css(styles.column)}>
          <TextField name="firstName" messages={messages} normalize={normalizeLength(50)} />
        </div>
        <div className={css(styles.column)}>
          <TextField name="lastName" messages={messages} normalize={normalizeLength(50)} />
        </div>
        <div className={css(styles.fieldContainer)}>
          <div className={css(styles.column)}>
            <TextField name="email" messages={messages} normalize={normalizeLength(50)} />
          </div>
          {ssoConfig?.hasSsoProvider && (
            <div className={css(styles.column)}>
              <Checkbox
                className={css(styles.column)}
                name="enableSso"
                label={formatMessage(messages.enableSso)}
                defaultValue={ssoConfig.forceSsoUsers}
                readOnly={ssoConfig.forceSsoUsers}
              />
            </div>
          )}
        </div>
        {roles && (
          <div className={css(styles.column)}>
            <FieldArray
              name="roles"
              component={ManagedGroupAndRoles}
              props={{
                availableManagedGroupsAndRoles: roles,
                currentManagedGroupId,
              }}
              rerenderOnEveryChange
            />
          </div>
        )}
        <div className={css(styles.fieldContainer)}>
          <div className={css(styles.column)}>
            <TextField
              name="username"
              messages={messages}
              normalize={normalizeUsername}
              disabled={enableSso}
            />
          </div>
          <InfoBox
            styleSheet={styles.info}
            message={formatMessage(messages.usernameInfo)}
            visible={!enableSso}
          />
        </div>
        <div className={css(styles.column)}>
          <TextField name="employeeId" messages={messages} normalize={normalizeCharacters(20)} />
        </div>
        <div className={css(styles.column)}>
          <Select
            name="site"
            label={formatMessage(messages.site)}
            placeholder={formatMessage(messages.pleaseSelect)}
            messages={messages}
            options={sitesOptions}
          />
        </div>
        <div className={css(styles.column)}>
          <Button
            type="submit"
            variant="primary"
            size="xlarge"
            fullWidth
            testId="create-new-agent-button"
            loading={isCreatingNewAgent}
          >
            <FormattedMessage {...messages.submit} />
          </Button>
        </div>
      </form>
    </>
  );
};

export const validate = ({
  firstName,
  lastName,
  roles,
  username,
  site,
  email,
  enableSso,
}: CreateNewAgentFormData): any => {
  const errors = {} as CreateNewAgentFormData;

  if (!firstName) {
    errors.firstName = 'firstNameIsRequired';
  }
  if (!lastName) {
    errors.lastName = 'lastNameIsRequired';
  }

  if (email && !validateEmail(email)) {
    errors.email = 'emailIsInvalid';
  }

  if (!email && enableSso) {
    errors.email = 'emailIsRequiredForSso';
  }

  if (roles && roles.length) {
    const rolesArrayErrors = [] as ManagedGroupAndRoleType[];

    roles.forEach((managedGroupRole, index) => {
      const rolesErrors = {} as ManagedGroupAndRoleType;

      if (!managedGroupRole?.managedGroup) {
        rolesErrors.managedGroup = 'required';
        rolesArrayErrors[index] = rolesErrors;
      }

      if (!managedGroupRole?.role) {
        rolesErrors.role = 'required';
        rolesArrayErrors[index] = rolesErrors;
      }
    });

    if (rolesArrayErrors.length) {
      errors.roles = rolesArrayErrors;
    }
  }
  if (!username && !enableSso) {
    errors.username = 'usernameIsRequired';
  }
  if (!site) {
    errors.site = 'siteIsRequired';
  }

  return errors;
};

export default reduxForm<CreateNewAgentFormData>({
  form: FORM_ID,
  validate,
})(CreateNewAgent);
