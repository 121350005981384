import React from 'react';
import { FormattedMessage } from 'react-intl';

import Tabs from '@contactcentre-web/common/Tabs';
import Tab from '@contactcentre-web/common/Tabs/Tab';
import {
  Journey,
  Link,
  TicketType,
  Validity,
} from '@contactcentre-web/redux-common/types/SeasonProduct';
import ConditionsOfUse from '@contactcentre-web/redux-common/types/ConditionsOfUse';

import BookingInfo from '../BookingInfo';
import Tickets from '../Tickets';

import messages from './messages';

export interface Props {
  productId: string;
  journey: Journey;
  ticketType: TicketType;
  externallyModified: boolean;
  conditionsOfUse: ConditionsOfUse[];
  smartcardNumber?: string;
  deliveryMethod: string;
  isFlexiSeason: boolean;
  links: Link[];
  canBeReset: boolean;
  canBeCancelled: boolean;
  validity: Validity;
}

const Details = ({
  productId,
  journey,
  ticketType,
  externallyModified,
  conditionsOfUse,
  smartcardNumber,
  deliveryMethod,
  links,
  isFlexiSeason,
  canBeReset,
  canBeCancelled,
  validity,
}: Props) => (
  <section>
    <Tabs>
      <Tab default title={<FormattedMessage {...messages.bookingInfo} />}>
        <BookingInfo
          journey={journey}
          ticketType={ticketType}
          externallyModified={externallyModified}
          conditionsOfUse={conditionsOfUse}
          smartcardNumber={smartcardNumber}
          isFlexiSeason={isFlexiSeason}
          deliveryMethod={deliveryMethod}
          validity={validity}
        />
      </Tab>

      <Tab title={<FormattedMessage {...messages.tickets} />} testId="tickets-tab">
        <Tickets
          productId={productId}
          journey={journey}
          deliveryMethod={deliveryMethod}
          links={links}
          canBeReset={canBeReset}
          canBeCancelled={canBeCancelled}
        />
      </Tab>
    </Tabs>
  </section>
);

export default Details;
