import React from 'react';
import { css } from 'aphrodite/no-important';

import {
  Status,
  CallingType,
  DepartureAndArrival,
  DepartureAndArrivalStatus,
} from '@contactcentre-web/redux-common/types/Timetable';

import CallingPointsItemTime from './CallingPointsItemTime';
import styles from './styles';

export interface Props {
  status: Status;
  callingType: CallingType;
  locationName: string;
  departure?: DepartureAndArrival;
  arrival?: DepartureAndArrival;
}

const CallingPointsItem = ({ status, callingType, locationName, departure, arrival }: Props) => {
  const isOriginCallingPoint = callingType === CallingType.Origin;
  const isDestinationCallingPoint = callingType === CallingType.Destination;
  const isPickUpCallingPoint = callingType === CallingType.PickUp;

  const showDepartureData = isOriginCallingPoint || isPickUpCallingPoint;
  const isOriginOrDestinationCallingPoint = isOriginCallingPoint || isDestinationCallingPoint;

  const scheduledTime = showDepartureData ? departure?.scheduledTime : arrival?.scheduledTime;
  const realTime = showDepartureData ? departure?.realTime : arrival?.realTime;
  const itemStatus = showDepartureData ? departure?.statusType : arrival?.statusType;

  const notOnTime =
    itemStatus === DepartureAndArrivalStatus.Early || itemStatus === DepartureAndArrivalStatus.Late;
  const isJourneyCancelled = status === Status.Cancelled;
  const isCallingPointCancelled = itemStatus === DepartureAndArrivalStatus.Cancelled;

  return (
    <>
      <div className={css(styles.callingPointItem)}>
        <div className={css(styles.callingPointItemTime)}>
          <CallingPointsItemTime
            scheduledTime={scheduledTime}
            realTime={realTime}
            status={itemStatus}
            isJourneyCancelled={isJourneyCancelled}
            callingType={callingType}
          />
        </div>
        <div className={css(styles.callingPoints)}>
          <div
            className={css(
              styles.marker,
              isDestinationCallingPoint && styles.hideMarker,
              (isJourneyCancelled || notOnTime || isCallingPointCancelled) && styles.redMarker
            )}
            data-test="marker"
          >
            <div
              className={css(
                styles.point,
                isOriginOrDestinationCallingPoint && styles.biggerPoint,
                (isJourneyCancelled || notOnTime || isCallingPointCancelled) && styles.redPoint
              )}
              data-test="point"
            />
          </div>
        </div>
        <div
          className={css(
            styles.location,
            isOriginOrDestinationCallingPoint && styles.originOrDestinationLocation,
            isCallingPointCancelled && styles.locationStriked
          )}
          data-test={`calling-point-${callingType}-location-name`}
        >
          {locationName}
        </div>
      </div>
    </>
  );
};

export default CallingPointsItem;
