import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { StatusMessage } from '@trainline/depot-web';

import { Data, Rolename } from '../../SetupManagedGroup';
import { rolesMessages } from '../RolesList/RolesList';

import messages from './messages';
import styles from './styles';

interface Props {
  data: Data;
  managedGroupName: string;
}

const ManagedGroupAlert = ({ data, managedGroupName }: Props) => {
  const { formatMessage } = useIntl();

  const statesGroup: {
    existing: Array<Rolename>;
    failure: Array<Rolename>;
    success: Array<Rolename>;
  } = data.roles.reduce(
    (prev, { state, roleName }) => {
      const newState: string[] = prev[state];
      newState.push(roleName);
      return {
        ...prev,
        newState,
      };
    },
    { existing: [], failure: [], success: [] }
  );

  statesGroup[data?.firstUser.state || 'failure'].push('firstUser');

  const formatRolesNames = (rolesNames: Array<Rolename>) => (
    <strong>
      {rolesNames.map((roleName) => formatMessage(rolesMessages[roleName])).join(', ')}
    </strong>
  );

  return (
    <>
      {statesGroup.success.length > 0 && (
        <div className={css(styles.alert)} data-testid="alert_teamrole">
          <StatusMessage status="positive">
            <FormattedMessage
              {...messages.statesGroup_success}
              values={{
                managedGroupName,
                roles: formatRolesNames(statesGroup.success),
              }}
            />
          </StatusMessage>
        </div>
      )}
      {statesGroup.existing.length > 0 && (
        <div className={css(styles.alert)} data-testid="alert_teamrole">
          <StatusMessage status="info">
            <FormattedMessage
              {...messages.statesGroup_existing}
              values={{
                managedGroupName,
                roles: formatRolesNames(statesGroup.existing),
              }}
            />
          </StatusMessage>
        </div>
      )}
      {statesGroup.failure.length > 0 && (
        <div className={css(styles.alert)} data-testid="alert_teamrole">
          <StatusMessage status="negative">
            <FormattedMessage
              {...messages.statesGroup_failure}
              values={{
                managedGroupName,
                roles: formatRolesNames(statesGroup.failure),
              }}
            />
          </StatusMessage>
        </div>
      )}
    </>
  );
};

export default ManagedGroupAlert;
