import React, { Fragment, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import { VendorRegion } from '@contactcentre-web/hooks/api/useOrderHistory';

import OrderHistory from '../../../OrderHistory/OrderHistory';
import type { FareRefundableGroupedByTicketType } from '../../helpers';
import RefundJourney from '../RefundJourney/RefundJourney';
import RefundPassengerDetails from '../RefundPassengerDetails/RefundPassengerDetails';

import messages from './messages';

interface Props {
  faresGroupedByTicketType: Record<string, FareRefundableGroupedByTicketType[]>;
  numberOfFaresGroupedByTicketType: number;
}

const RefundFareInformation: FunctionComponent<Props> = ({
  faresGroupedByTicketType,
  numberOfFaresGroupedByTicketType,
}) => {
  const { formatMessage } = useIntl();
  return (
    <>
      {Object.values(faresGroupedByTicketType).map((fares, index) => (
        <Fragment key={index}>
          {numberOfFaresGroupedByTicketType > 1 && (
            <RefundJourney index={index} section="bookingInformation" />
          )}
          <OrderHistory.Items>
            {fares.map(
              (
                {
                  ticketType,
                  origin,
                  destination,
                  isReturnFare,
                  passengers,
                  vendorRegion,
                  productInventoryReferences,
                },
                index
              ) => (
                <Fragment key={index}>
                  {index !== 0 && <br />}
                  <OrderHistory.Item label={ticketType}>
                    <OrderHistory.Fare
                      origin={origin.name}
                      destination={destination.name}
                      isReturnFare={isReturnFare}
                    />
                  </OrderHistory.Item>
                  <OrderHistory.Item label={formatMessage(messages.passenger)}>
                    {passengers.map((passenger, index) => (
                      <RefundPassengerDetails
                        key={index}
                        passenger={passenger}
                        index={index}
                        length={passengers.length}
                      />
                    ))}
                  </OrderHistory.Item>
                  {vendorRegion && productInventoryReferences && (
                    <OrderHistory.Item
                      label={formatMessage(
                        vendorRegion?.toLowerCase() === VendorRegion.UK
                          ? messages.traCS
                          : messages.pnr
                      )}
                    >
                      {productInventoryReferences?.[0]}
                    </OrderHistory.Item>
                  )}
                </Fragment>
              )
            )}
          </OrderHistory.Items>
        </Fragment>
      ))}
    </>
  );
};

export default RefundFareInformation;
