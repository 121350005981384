import { defineMessages } from 'react-intl';

export default defineMessages({
  orderId: {
    id: 'OrderNotes.OrderContext.orderId',
    defaultMessage: 'Order ID',
  },
  customerId: {
    id: 'OrderNotes.OrderContext.customerId',
    defaultMessage: 'Customer ID',
  },
  agentId: {
    id: 'OrderNotes.OrderContext.agentId',
    defaultMessage: 'Agent ID',
  },
});
