import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { StatusMessage } from '@trainline/depot-web';

interface Props {
  message: MessageDescriptor;
}

const ChangeOfJourneyWarning: React.FC<Props> = ({ message }) => (
  <StatusMessage status="warning">
    <FormattedMessage {...message} />
  </StatusMessage>
);

export default ChangeOfJourneyWarning;
