import { defineMessages } from 'react-intl';

export default defineMessages({
  editUser: {
    id: 'ChangeEmail.editUser',
    defaultMessage: 'Edit user email - {username}',
  },
  email: {
    id: 'ChangeEmail.email',
    defaultMessage: 'Email',
  },
  enableSso: {
    id: 'ChangeEmail.enableSso',
    defaultMessage: 'Use email as username for SSO',
  },
  update: {
    id: 'ChangeEmail.update',
    defaultMessage: 'Update',
  },
  cancel: {
    id: 'ChangeEmail.cancel',
    defaultMessage: 'Cancel',
  },
  emailIsRequired: {
    id: 'ChangeEmail.emailIsRequired',
    defaultMessage: 'Email is required.',
  },
  emailIsInvalid: {
    id: 'ChangeEmail.emailIsInvalid',
    defaultMessage: 'Email is invalid.',
  },
  emailDomainIsNotValid: {
    id: 'ChangeEmail.emailDomainIsNotValid',
    defaultMessage: 'Email is not allowed',
  },
  emailAlreadyUsed: {
    id: 'ChangeEmail.emailAlreadyUsed',
    defaultMessage: 'This email already exists',
  },
  ssoAlreadyEnabled: {
    id: 'ChangeEmail.ssoAlreadyEnabled',
    defaultMessage: 'SSO was already enabled for this user.',
  },
  genericError: {
    id: 'ChangeEmail.genericError',
    defaultMessage: 'Something went wrong, please try again',
  },
});
