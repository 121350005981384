import React, { FunctionComponent, Dispatch } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { IconSearch } from '@trainline/depot-web';

import Select from '@contactcentre-web/common/Select';
import Button from '@contactcentre-web/common/Button';
import type State from '@contactcentre-web/redux-common/types/State';
import type Action from '@contactcentre-web/redux-common/types/Action';

import { getAvailableManagedGroups } from '../../redux/selectors';
import { actions, FORM_ID } from '../../redux/managedGroup/module';
import selectors from '../../redux/managedGroup/selectors';
import type { ManagedGroup } from '../../redux/service';

import styles from './styles';
import messages from './messages';

interface SelectManagedGroupData {
  managedGroup: string;
}

interface StateProps {
  managedGroups: Array<ManagedGroup>;
  isSubmitting: boolean;
}

interface DispatchProps {
  changeManagedGroup: (managedGroup: string) => void;
}

type Props = StateProps & DispatchProps;

const PlaceHolder = () => (
  <>
    <IconSearch className={css(styles.searchIcon)} />
    <FormattedMessage {...messages.selectPlaceholder} />
  </>
);

export const SelectManagedGroupForm: FunctionComponent<
  Props & InjectedFormProps<SelectManagedGroupData, Props>
> = ({ handleSubmit, managedGroups, isSubmitting, changeManagedGroup }) => {
  const sortedGroups =
    managedGroups &&
    managedGroups.sort((a, b) => {
      const aIsTrainline = a.name.includes('Thetrainline.com');
      const bIsTrainline = b.name.includes('Thetrainline.com');
      if (aIsTrainline) return -1;
      if (bIsTrainline) return 1;
      return a.name.localeCompare(b.name);
    });

  const managedGroupSelectOptions = sortedGroups.map(({ number, name }) => ({
    label: name,
    value: number,
  }));

  return (
    <form
      id={FORM_ID}
      data-testid="select-managed-group-form"
      className={css(styles.selectManagedGroupForm)}
      onSubmit={handleSubmit(({ managedGroup }) => changeManagedGroup(managedGroup))}
    >
      <div className={css(styles.inputContainer)}>
        <div className={css(styles.selectContainer)}>
          <Select
            name="managedGroup"
            label={<FormattedMessage {...messages.selectLabel} />}
            placeholder={<PlaceHolder />}
            options={managedGroupSelectOptions}
            messages={messages}
            noChevron
          />
        </div>
        <Button
          type="submit"
          variant="primary"
          size="small"
          testId="submit-managed-group-selection"
          styleSheet={styles.continueButton}
          loading={isSubmitting}
        >
          <FormattedMessage {...messages.continue} />
        </Button>
      </div>
    </form>
  );
};

const mapStateToProps = (state: State) => ({
  managedGroups: getAvailableManagedGroups(state),
  isSubmitting: selectors.isSubmitting(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  changeManagedGroup: (managedGroup: string) => dispatch(actions.submitAttempt(managedGroup)),
});

export const validate = ({ managedGroup }: SelectManagedGroupData) => ({
  managedGroup: !managedGroup ? 'selectManagedGroupError' : undefined,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<SelectManagedGroupData, Props>({
    form: FORM_ID,
    validate,
  })(SelectManagedGroupForm)
);
