import { defineMessages } from 'react-intl';

export default defineMessages({
  loadMore: {
    id: 'manualEntryConsoleQueueList.loadMore',
    defaultMessage: 'Load {loadMoreItemsCount} more',
  },
  loadingMore: {
    id: 'manualEntryConsoleQueueList.loadingMore',
    defaultMessage: 'Loading {loadMoreItemsCount} more',
  },
  noResults: {
    id: 'manualEntryConsoleQueueList.noResults',
    defaultMessage: 'The claim queue is empty',
  },
  noResultsFiltersApplied: {
    id: 'manualEntryConsoleQueueList.noResultsFiltersApplied',
    defaultMessage: 'No results',
  },
  noResultsFiltersAppliedSubTitle: {
    id: 'manualEntryConsoleQueueList.noResultsFiltersAppliedSubTitle',
    defaultMessage: 'Change your filter options and try again',
  },
});
