import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { IconInfo } from '@trainline/depot-web';

import Loader from '@contactcentre-web/common/DottedLoader';
import { canBebocPnrIntoEuPlatform as canBebocPnrIntoEuPlatformSelector } from '@contactcentre-web/authentication/redux/selectors';
import Link from '@contactcentre-web/common/Link';

import { selectors } from '../../module';

import styles from './styles';
import messages from './messages';

export const EuBookingsAlert = ({
  loadingStats,
  errored,
  capitaineUrl,
  canBebocPnrIntoEuPlatform,
}) =>
  loadingStats || errored || capitaineUrl ? (
    <div className={css(styles.container)}>
      <div className={css(styles.box)}>
        {loadingStats && (
          <div className={css(styles.loaderBox)} data-test-id="loader">
            <Loader styleSheet={styles.loader} bubbleStyleSheet={styles.loaderElementBubble} />
            <span className={css(styles.loaderText)}>
              <FormattedMessage {...messages.loadingStats} />
            </span>
          </div>
        )}
        {!loadingStats && errored && (
          <div className={css(styles.errorBox)} data-test-id="error">
            <IconInfo />
            <span className={css(styles.errorText)}>
              <FormattedMessage {...messages.errorMessage} />
            </span>
          </div>
        )}
        {!loadingStats && !errored && capitaineUrl && (
          <div className={css(styles.euBox)}>
            <FormattedMessage {...messages.euBookings} />{' '}
            {canBebocPnrIntoEuPlatform && (
              <span data-test-id="capitaine-link">
                <Link linkType="external" href={capitaineUrl}>
                  <FormattedMessage {...messages.link} />
                </Link>
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  ) : null;

EuBookingsAlert.propTypes = {
  loadingStats: PropTypes.bool.isRequired,
  errored: PropTypes.bool,
  capitaineUrl: PropTypes.string,
  canBebocPnrIntoEuPlatform: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  loadingStats: selectors.isLoadingStats(state),
  errored: selectors.failedLoadingStats(state),
  capitaineUrl: selectors.capitaineUri(state),
  canBebocPnrIntoEuPlatform: canBebocPnrIntoEuPlatformSelector(state),
});

const connected = connect(mapStateToProps)(EuBookingsAlert);
connected.displayName = 'EuBookingsAlert';

export default connected;
