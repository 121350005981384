import React from 'react';
import { FormattedNumber } from 'react-intl';

interface Props {
  amount: number;
  currency?: string;
  currencyCode?: string;
}

const FormattedCurrency = ({ amount, currency, currencyCode }: Props) => (
  <span>
    <FormattedNumber value={amount} maximumFractionDigits={2} minimumFractionDigits={2} />
    {` ${currency || currencyCode || ''}`}
  </span>
);

export default FormattedCurrency;
