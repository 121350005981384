import React, { Dispatch, FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { css } from 'aphrodite/no-important';
import { Heading, IconSwapUpDown, StatusMessage } from '@trainline/depot-web';

import type Action from '@contactcentre-web/redux-common/types/Action';
import type State from '@contactcentre-web/redux-common/types/State';
import Loader from '@contactcentre-web/common/Loader';
import Button from '@contactcentre-web/common/Button';
import Price from '@contactcentre-web/redux-common/types/Price';
import { getUserSite } from '@contactcentre-web/authentication/redux/selectors';
import useToggleSortDirection, {
  SortDirectionType,
} from '@contactcentre-web/hooks/shared/useToggleSortDirection';

import CompensationApprovalList from './components/CompensationApprovalList/CompensationApprovalList';
import CompensationApprovalConfirmationBox from './components/CompensationApprovalConfirmationBox/CompensationApprovalConfirmationBox';
import { actions, Approval, Pagination } from './module';
import selectors from './selectors';
import messages from './messages';
import styles from './styles';

interface StateProps {
  approvals: Approval[];
  pagination: Pagination | null;
  isLoading: boolean;
  agentSite: string;
  hasFailed: boolean;
  isResolutionInProgress: boolean;
  hasResolutionSucceeded: boolean;
  orderReference: string;
  type: string;
  amount: Price;
  errors?: Record<string, string>[];
}

interface DispatchProps {
  loadCompensationApprovals: (
    page?: number,
    perPage?: number,
    sortDirection?: SortDirectionType
  ) => void;
  onResolution: (
    type: string,
    id: string,
    amount: Price,
    orderReference: string,
    reason?: string,
    note?: string
  ) => void;
  reset: () => void;
}

export const CompensationApprovals: FunctionComponent<StateProps & DispatchProps> = ({
  approvals,
  pagination,
  isLoading,
  agentSite,
  hasFailed,
  onResolution,
  isResolutionInProgress,
  hasResolutionSucceeded,
  orderReference,
  type,
  amount,
  loadCompensationApprovals,
  reset,
}) => {
  const PAGE_NAME = 'CompensationApprovals';
  const {
    newSortDirection,
    sortDirection,
    toggleSortDirection,
    loadSortDirectionStorage,
    storeSortDirectionStorage,
  } = useToggleSortDirection(PAGE_NAME);
  const history = useHistory();
  const loadCompensationApprovalsRef = React.useRef(loadCompensationApprovals);
  const resetRef = React.useRef(reset);
  const { page } = pagination ?? {};

  React.useEffect(() => {
    const initialSortDirection = loadSortDirectionStorage(PAGE_NAME);
    if (initialSortDirection) {
      storeSortDirectionStorage(initialSortDirection, PAGE_NAME);
    }
  }, []);

  React.useEffect(() => {
    loadCompensationApprovalsRef.current();
    return () => resetRef.current();
  }, [loadCompensationApprovalsRef.current]);

  React.useEffect(() => {
    if (hasResolutionSucceeded) loadCompensationApprovals();
  }, [hasResolutionSucceeded]);

  const editHandler = (customerId: string, orderId: string, compensationId: string) => {
    reset();
    history.push(`/customers/${customerId}/bookings/${orderId}/compensation/${compensationId}`);
  };

  const loadMore = () => {
    if (!pagination) return;
    page && loadCompensationApprovals(page + 1, undefined, sortDirection);
  };

  const handleClick = () => {
    toggleSortDirection();
    storeSortDirectionStorage(newSortDirection, PAGE_NAME);
    reset();
    loadCompensationApprovals(page, undefined, newSortDirection);
  };

  return (
    <>
      <Heading typeStyle="title1" as="h1" color="base">
        <FormattedMessage {...messages.title} />
      </Heading>
      <Button
        variant="tertiary"
        size="small"
        onClick={handleClick}
        testId="sortDirectionButton"
        styleSheet={styles.toggleButton}
      >
        {
          <>
            <FormattedMessage
              {...messages.sortBy}
              values={{
                sortBy: newSortDirection,
                span: (msg: string) => <span>{msg}</span>,
              }}
            />
            <IconSwapUpDown />
          </>
        }
      </Button>
      <div className={css(styles.container)}>
        {isLoading && <Loader />}
        {hasFailed && (
          <section>
            <StatusMessage status="negative">
              <FormattedMessage {...messages.loadFailed} data-testid="error-banner" />
            </StatusMessage>
          </section>
        )}
        {hasResolutionSucceeded && (
          <CompensationApprovalConfirmationBox
            orderReference={orderReference}
            type={type}
            amount={amount}
          />
        )}
        {!isLoading && !hasFailed && (
          <>
            <section>
              {!!agentSite && (
                <StatusMessage status="info">
                  <FormattedMessage {...messages.bannerText} values={{ site: agentSite }} />
                </StatusMessage>
              )}
              <CompensationApprovalList
                approvals={approvals}
                onResolution={onResolution}
                isResolutionInProgress={isResolutionInProgress}
                hasResolutionSucceeded={hasResolutionSucceeded}
                onEdit={editHandler}
              />
            </section>
            {pagination && pagination.totalPages > pagination.page && (
              <Button
                variant="secondary"
                size="small"
                styleSheet={styles.loadMoreButton}
                onClick={loadMore}
                testId="load-more"
              >
                <FormattedMessage {...messages.loadMore} />
              </Button>
            )}
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: State) => ({
  approvals: selectors.approvals(state),
  pagination: selectors.pagination(state),
  isLoading: selectors.isLoading(state),
  hasFailed: selectors.hasFailed(state),
  agentSite: getUserSite(state),
  isResolutionInProgress: selectors.isResolutionInProgress(state),
  hasResolutionSucceeded: selectors.hasResolutionSucceeded(state),
  type: selectors.resolutionType(state),
  amount: selectors.resolutionAmount(state),
  orderReference: selectors.resolutionOrderReference(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  loadCompensationApprovals: (page?: number, perPage?: number, sortDirection?: SortDirectionType) =>
    dispatch(actions.loadAttempt(page, perPage, sortDirection)),
  onResolution: (
    type: string,
    id: string,
    amount: Price,
    orderReference: string,
    reason?: string,
    note?: string
  ) => {
    dispatch(actions.reset());
    dispatch(actions.resolutionAttempt(type, id, amount, orderReference, reason, note));
  },
  reset: () => dispatch(actions.reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompensationApprovals);
