import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  spacing: {
    marginInline: spacers.dp_s4,
  },
  verticalAlignMiddle: {
    verticalAlign: 'middle',
  },
  verticalAlignBottom: {
    verticalAlign: 'bottom',
  },
});
