import React from 'react';
import { groupBy, uniqBy } from 'ramda';

import Price from '@contactcentre-web/redux-common/types/Price';
import { LocalAreaJourneyWithTicketIndexes } from '@contactcentre-web/refunds/types';
import { RailCard } from '@contactcentre-web/redux-common/types/RailCard';

type AggregatedLocalAreaJourneyWithTicketIndexes = Omit<
  LocalAreaJourneyWithTicketIndexes,
  'farePassengers'
> & {
  farePassengers: (LocalAreaJourneyWithTicketIndexes['farePassengers'][0] & {
    passengersCount: Record<string, number>;
  })[];
};

type LocalAreaJourneyAggregatorProps = {
  journey: LocalAreaJourneyWithTicketIndexes;
  render: (
    _: LocalAreaJourneyWithTicketIndexes | AggregatedLocalAreaJourneyWithTicketIndexes
  ) => JSX.Element;
  shouldAggregate: boolean;
};

const priceAccumulator = (acc: Price, { amount, currencyCode }: Price) => ({
  currencyCode,
  amount: acc.amount + amount,
});

const getPassengersCount = (farePassengers: LocalAreaJourneyWithTicketIndexes['farePassengers']) =>
  Object.entries(
    groupBy<LocalAreaJourneyWithTicketIndexes['farePassengers']['0']>(({ type }) => type)(
      farePassengers
    )
  ).reduce(
    (acc, [passengerType, groupedPassengers]) => ({
      ...acc,
      [passengerType]: groupedPassengers.length,
    }),
    {} as Record<string, number>
  );

const defaultPrice = {
  amount: 0,
  currencyCode: 'GBP',
} as Price;

export const LocalAreaJourneyAggregator = ({
  shouldAggregate,
  journey,
  render,
}: LocalAreaJourneyAggregatorProps) => {
  if (!shouldAggregate) {
    return <>{render(journey)}</>;
  }

  const { farePassengers = [] } = journey;
  const tickets = farePassengers.flatMap(({ tickets }) => tickets);
  const defaultTicket = tickets[0];
  const defaultFarePassenger = farePassengers[0];

  if (!defaultTicket || !defaultFarePassenger) {
    return <>{render(journey)}</>;
  }

  const refundableAmounts = tickets
    .map((ticket) => ticket.refundableAmount)
    .filter(Boolean) as Price[];

  const refundableAmount =
    refundableAmounts.length > 0
      ? refundableAmounts.reduce(priceAccumulator, defaultPrice)
      : undefined;

  const customerPrice = tickets
    .map((ticket) => ticket.customerPrice)
    .filter(Boolean)
    .reduce(priceAccumulator, defaultPrice);

  const price = tickets
    .map((ticket) => ticket.price)
    .filter(Boolean)
    .reduce(priceAccumulator, defaultPrice);

  const aggregatedTicket = {
    ...defaultTicket,
    price,
    customerPrice,
    refundableAmount,
    refundable: tickets.every(({ refundable }) => refundable),
    isSelected: tickets.every(({ isSelected }) => isSelected),
    isEligible: tickets.every(({ isEligible }) => isEligible),
    isChangeable: tickets.every(({ isChangeable }) => isChangeable),
    friendlyTicketIndexes: tickets.flatMap(({ friendlyTicketIndexes }) => friendlyTicketIndexes),
  };

  const aggregatedJourney = {
    ...journey,
    farePassengers: [
      {
        ...defaultFarePassenger,
        tickets: [aggregatedTicket],
        railCards: uniqBy<RailCard, string>(
          ({ code }) => code,
          farePassengers.flatMap(({ railCards }) => railCards)
        ),
        passengersCount: getPassengersCount(farePassengers),
      },
    ],
  };

  return <>{render(aggregatedJourney)}</>;
};
