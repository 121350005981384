import { createActions, handleActions, Action } from 'redux-actions';
import { all, put, call, takeLatest } from 'redux-saga/effects';
import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';

import type State from '@contactcentre-web/redux-common/types/State';
import request from '@contactcentre-web/utils/request';
import { FetchError } from '@contactcentre-web/utils/error';
import { getUsername as getUsernameSelector } from '@contactcentre-web/authentication/redux/selectors';

export const SEARCH_FORM_ID = 'SearchForm';

const PREFIX = 'EDIT_USER/';

export const RESET_SEARCH = 'RESET_SEARCH';
export const RESET_STATE = 'RESET_STATE';
export const SEARCH_ATTEMPT = 'SEARCH_ATTEMPT';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILED = 'SEARCH_FAILED';
export const RESET_PASSWORD_ATTEMPT = 'RESET_PASSWORD_ATTEMPT';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const CHANGE_ROLE_ATTEMPT = 'CHANGE_ROLE_ATTEMPT';
export const CHANGE_ROLE_SUCCESS = 'CHANGE_ROLE_SUCCESS';
export const CHANGE_ROLE_FAILED = 'CHANGE_ROLE_FAILED';
export const CHANGE_SITE_ATTEMPT = 'CHANGE_SITE_ATTEMPT';
export const CHANGE_SITE_SUCCESS = 'CHANGE_SITE_SUCCESS';
export const CHANGE_SITE_FAILED = 'CHANGE_SITE_FAILED';
export const CHANGE_EMAIL_ATTEMPT = 'CHANGE_EMAIL_ATTEMPT';
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS';
export const CHANGE_EMAIL_FAILED = 'CHANGE_EMAIL_FAILED';
export const LOAD_USER_ATTEMPT = 'LOAD_USER_ATTEMPT';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_FAILED = 'LOAD_USER_FAILED';
export const DISABLE_USER_ATTEMPT = 'DISABLE_USER_ATTEMPT';
export const DISABLE_USER_SUCCESS = 'DISABLE_USER_SUCCESS';
export const DISABLE_USER_FAILED = 'DISABLE_USER_FAILED';
export const LOAD_USER_MG_AND_ROLES_ATTEMPT = 'LOAD_USER_MG_AND_ROLES_ATTEMPT';
export const LOAD_USER_MG_AND_ROLES_SUCCESS = 'LOAD_USER_MG_AND_ROLES_SUCCESS';
export const LOAD_USER_MG_AND_ROLES_FAILED = 'LOAD_USER_MG_AND_ROLES_FAILED';

export const actions = createActions(
  {
    [RESET_SEARCH]: () => null,
    [RESET_STATE]: () => null,
    [SEARCH_ATTEMPT]: (username) => ({ username }),
    [SEARCH_SUCCESS]: (data) => ({ ...data }),
    [SEARCH_FAILED]: (searchError) => ({ searchError }),
    [RESET_PASSWORD_ATTEMPT]: (userId) => ({ userId }),
    [RESET_PASSWORD_SUCCESS]: ({ newPassword }) => ({ newPassword }),
    [RESET_PASSWORD_FAILED]: () => null,
    [CHANGE_ROLE_ATTEMPT]: (userId, roles) => ({ userId, roles }),
    [CHANGE_ROLE_SUCCESS]: () => null,
    [CHANGE_ROLE_FAILED]: () => null,
    [CHANGE_SITE_ATTEMPT]: (userId, site) => ({ userId, site }),
    [CHANGE_SITE_SUCCESS]: () => null,
    [CHANGE_SITE_FAILED]: () => null,
    [CHANGE_EMAIL_ATTEMPT]: (userId, email, enableSso) => ({ userId, email, enableSso }),
    [CHANGE_EMAIL_SUCCESS]: (data) => ({ ...data }),
    [CHANGE_EMAIL_FAILED]: (changeEmailError) => ({ changeEmailError }),
    [LOAD_USER_ATTEMPT]: (username, site) => ({ username, site }),
    [LOAD_USER_SUCCESS]: (data) => ({ ...data }),
    [LOAD_USER_FAILED]: () => null,
    [DISABLE_USER_ATTEMPT]: (userId) => ({ userId }),
    [DISABLE_USER_SUCCESS]: () => null,
    [DISABLE_USER_FAILED]: () => null,
    [LOAD_USER_MG_AND_ROLES_ATTEMPT]: (userId) => ({ userId }),
    [LOAD_USER_MG_AND_ROLES_SUCCESS]: (data) => ({ ...data }),
    [LOAD_USER_MG_AND_ROLES_FAILED]: () => null,
  },
  { prefix: PREFIX }
);

export const RESET_PASSWORD_STATUS_PENDING = `${PREFIX}RESET_PASSWORD_STATUS_PENDING`;
export const RESET_PASSWORD_STATUS_SUCCESS = `${PREFIX}RESET_PASSWORD_STATUS_SUCCESS`;
export const SEARCH_STATUS_PENDING = `${PREFIX}SEARCH_STATUS_PENDING`;
export const SEARCH_STATUS_SUCCESS = `${PREFIX}SEARCH_STATUS_SUCCESS`;
export const SEARCH_STATUS_FAILED = `${PREFIX}SEARCH_STATUS_FAILED`;
export const CHANGE_ROLE_STATUS_PENDING = `${PREFIX}CHANGE_ROLE_STATUS_PENDING`;
export const CHANGE_ROLE_STATUS_SUCCESS = `${PREFIX}CHANGE_ROLE_STATUS_SUCCESS`;
export const CHANGE_SITE_STATUS_PENDING = `${PREFIX}CHANGE_SITE_STATUS_PENDING`;
export const CHANGE_SITE_STATUS_SUCCESS = `${PREFIX}CHANGE_SITE_STATUS_SUCCESS`;
export const CHANGE_SITE_STATUS_FAILED = `${PREFIX}CHANGE_SITE_STATUS_FAILED`;
export const CHANGE_EMAIL_STATUS_PENDING = `${PREFIX}CHANGE_EMAIL_STATUS_PENDING`;
export const CHANGE_EMAIL_STATUS_SUCCESS = `${PREFIX}CHANGE_EMAIL_STATUS_SUCCESS`;
export const CHANGE_EMAIL_STATUS_FAILED = `${PREFIX}CHANGE_EMAIL_STATUS_FAILED`;
export const LOAD_USER_STATUS_PENDING = `${PREFIX}LOAD_USER_STATUS_PENDING`;
export const LOAD_USER_STATUS_SUCCESS = `${PREFIX}LOAD_USER_STATUS_SUCCESS`;
export const DISABLE_USER_STATUS_PENDING = `${PREFIX}DISABLE_USER_STATUS_PENDING`;
export const DISABLE_USER_STATUS_SUCCESS = `${PREFIX}DISABLE_USER_STATUS_SUCCESS`;
export const LOAD_USER_MG_AND_ROLES_STATUS_PENDING = `${PREFIX}LOAD_USER_MG_AND_ROLES_STATUS_PENDING`;
export const LOAD_USER_MG_AND_ROLES_STATUS_SUCCESS = `${PREFIX}LOAD_USER_MG_AND_ROLES_STATUS_SUCCESS`;
export const LOAD_USER_MG_AND_ROLES_STATUS_FAILED = `${PREFIX}LOAD_USER_MG_AND_ROLES_STATUS_FAILED`;

type UserRolesResponse = {
  anyManagedGroup: boolean;
  homeManagedGroupId: string;
  items: Array<{
    managedGroup: { id: string; name: string };
    role: { id: string; name: string };
  }>;
};

export type SearchResult = {
  firstName: string;
  lastName: string;
  username: string;
  isDisabled: boolean;
};
export type SearchError = 'userNotFound' | 'genericError';
export type EmailError =
  | 'emailAlreadyUsed'
  | 'emailDomainIsNotValid'
  | 'ssoAlreadyEnabled'
  | 'genericError';
export interface EditUserState {
  newPassword: string | null;
  username: string | null;
  firstName: string | null;
  lastName: string | null;
  resetPasswordStatus: string;
  searchStatus: string;
  searchResults: Array<SearchResult>;
  searchError: SearchError | null;
  loadUserStatus: string;
  changeRoleStatus: string;
  changeSiteStatus: string;
  changeEmailStatus: string;
  changeEmailError: EmailError | null;
  isDisabled: boolean;
  disableUserStatus: string;
  site: string | null;
  email: string;
  userId: string;
  loadUserMgAndRolesStatus: string;
  userMgAndRoles: UserRolesResponse | null;
  isFromSsoProvider: boolean;
  disablePasswordReset: boolean;
  enableSso: boolean;
}

export const initialState = {
  newPassword: null,
  username: null,
  firstName: null,
  lastName: null,
  resetPasswordStatus: '',
  searchStatus: '',
  searchResults: [],
  searchError: null,
  loadUserStatus: '',
  changeRoleStatus: '',
  changeSiteStatus: '',
  changeEmailStatus: '',
  changeEmailError: null,
  isDisabled: false,
  disableUserStatus: '',
  site: null,
  email: '',
  userId: '',
  loadUserMgAndRolesStatus: '',
  userMgAndRoles: null,
  isFromSsoProvider: false,
  disablePasswordReset: false,
  enableSso: false,
};

type ActionPayload = {
  items: Array<SearchResult>;
  searchError: SearchError;
  changeEmailError: EmailError;
  newPassword: string;
} & UserRolesResponse;

const reducer = handleActions<EditUserState, ActionPayload>(
  {
    [RESET_SEARCH]: () => ({
      ...initialState,
    }),
    [RESET_STATE]: (state) => ({
      ...state,
      resetPasswordStatus: '',
      changeRoleStatus: '',
      changeSiteStatus: '',
      changeEmailStatus: '',
      disableUserStatus: '',
    }),
    [SEARCH_ATTEMPT]: (state) => ({
      ...state,
      username: null,
      searchStatus: SEARCH_STATUS_PENDING,
    }),
    [SEARCH_SUCCESS]: (state, { payload: { items } }) => ({
      ...initialState,
      ...state,
      searchResults: items,
      searchStatus: SEARCH_STATUS_SUCCESS,
    }),
    [SEARCH_FAILED]: (state, { payload: { searchError } }) => ({
      ...state,
      username: null,
      searchResults: [],
      searchStatus: SEARCH_STATUS_FAILED,
      searchError: searchError,
    }),
    [RESET_PASSWORD_ATTEMPT]: (state) => ({
      ...state,
      newPassword: null,
      resetPasswordStatus: RESET_PASSWORD_STATUS_PENDING,
    }),
    [RESET_PASSWORD_SUCCESS]: (state, { payload: { newPassword } }) => ({
      ...state,
      newPassword,
      resetPasswordStatus: RESET_PASSWORD_STATUS_SUCCESS,
    }),
    [RESET_PASSWORD_FAILED]: (state) => ({
      ...state,
      newPassword: null,
      resetPasswordStatus: '',
    }),
    [CHANGE_ROLE_ATTEMPT]: (state) => ({
      ...state,
      changeRoleStatus: CHANGE_ROLE_STATUS_PENDING,
    }),
    [CHANGE_ROLE_SUCCESS]: (state) => ({
      ...state,
      changeRoleStatus: CHANGE_ROLE_STATUS_SUCCESS,
    }),
    [CHANGE_ROLE_FAILED]: (state) => ({
      ...state,
      username: null,
      changeRoleStatus: '',
    }),
    [CHANGE_SITE_ATTEMPT]: (state) => ({
      ...state,
      changeSiteStatus: CHANGE_SITE_STATUS_PENDING,
    }),
    [CHANGE_SITE_SUCCESS]: (state) => ({
      ...state,
      changeSiteStatus: CHANGE_SITE_STATUS_SUCCESS,
    }),
    [CHANGE_SITE_FAILED]: (state) => ({
      ...state,
      changeSiteStatus: CHANGE_SITE_STATUS_FAILED,
    }),
    [CHANGE_EMAIL_ATTEMPT]: (state) => ({
      ...state,
      changeEmailStatus: CHANGE_EMAIL_STATUS_PENDING,
    }),
    [CHANGE_EMAIL_SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
      changeEmailStatus: CHANGE_EMAIL_STATUS_SUCCESS,
    }),
    [CHANGE_EMAIL_FAILED]: (state, { payload: { changeEmailError } }) => ({
      ...state,
      changeEmailStatus: CHANGE_EMAIL_STATUS_FAILED,
      changeEmailError: changeEmailError,
    }),
    [LOAD_USER_ATTEMPT]: (state) => ({
      ...state,
      username: null,
      loadUserStatus: LOAD_USER_STATUS_PENDING,
    }),
    [LOAD_USER_SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
      loadUserStatus: LOAD_USER_STATUS_SUCCESS,
      searchStatus: '',
    }),
    [LOAD_USER_FAILED]: (state) => ({
      ...state,
      username: null,
      loadUserStatus: '',
      searchStatus: '',
    }),
    [DISABLE_USER_ATTEMPT]: (state) => ({
      ...state,
      disableUserStatus: DISABLE_USER_STATUS_PENDING,
    }),
    [DISABLE_USER_SUCCESS]: (state) => ({
      ...state,
      isDisabled: true,
      disableUserStatus: DISABLE_USER_STATUS_SUCCESS,
    }),
    [DISABLE_USER_FAILED]: (state) => ({
      ...state,
      disableUserStatus: '',
    }),
    [LOAD_USER_MG_AND_ROLES_ATTEMPT]: (state) => ({
      ...state,
      loadUserMgAndRolesStatus: LOAD_USER_MG_AND_ROLES_STATUS_PENDING,
    }),
    [LOAD_USER_MG_AND_ROLES_SUCCESS]: (state, { payload }) => ({
      ...state,
      userMgAndRoles: payload,
      loadUserMgAndRolesStatus: LOAD_USER_MG_AND_ROLES_STATUS_SUCCESS,
    }),
    [LOAD_USER_MG_AND_ROLES_FAILED]: (state) => ({
      ...state,
      loadUserMgAndRolesStatus: LOAD_USER_MG_AND_ROLES_STATUS_FAILED,
    }),
  },
  initialState,
  { prefix: PREFIX }
);

export default reducer;

const localState = (state: State) => state.editUser;

const isResetPasswordPending = createSelector(
  localState,
  (state) => state.resetPasswordStatus === RESET_PASSWORD_STATUS_PENDING
);
const isResetPasswordSuccess = createSelector(
  localState,
  (state) => state.resetPasswordStatus === RESET_PASSWORD_STATUS_SUCCESS
);
const isSearchPending = createSelector(
  localState,
  (state) => state.searchStatus === SEARCH_STATUS_PENDING
);
const isSearchSuccess = createSelector(
  localState,
  (state) => state.searchStatus === SEARCH_STATUS_SUCCESS
);
const isLoadUserPending = createSelector(
  localState,
  (state) => state.loadUserStatus === LOAD_USER_STATUS_PENDING
);
const isChangeRolePending = createSelector(
  localState,
  (state) => state.changeRoleStatus === CHANGE_ROLE_STATUS_PENDING
);
const isChangeRoleSuccess = createSelector(
  localState,
  (state) => state.changeRoleStatus === CHANGE_ROLE_STATUS_SUCCESS
);
const isChangeSitePending = createSelector(
  localState,
  (state) => state.changeSiteStatus === CHANGE_SITE_STATUS_PENDING
);
const isChangeSiteSuccess = createSelector(
  localState,
  (state) => state.changeSiteStatus === CHANGE_SITE_STATUS_SUCCESS
);
const isChangeEmailPending = createSelector(
  localState,
  (state) => state.changeEmailStatus === CHANGE_EMAIL_STATUS_PENDING
);
const isChangeEmailSuccess = createSelector(
  localState,
  (state) => state.changeEmailStatus === CHANGE_EMAIL_STATUS_SUCCESS
);
const getNewPassword = createSelector(localState, (state) => state.newPassword);
const getUsername = createSelector(localState, (state) => state.username);
const getFullName = createSelector(localState, (state) => `${state.firstName} ${state.lastName}`);
const getUserSite = createSelector(localState, (state) => state.site);
const getUserEmail = createSelector(localState, (state) => state.email);
const getUserId = createSelector(localState, (state) => state.userId);
const getChangeRoleStatus = createSelector(localState, (state) => state.changeRoleStatus);
const getFormValues = formValueSelector(SEARCH_FORM_ID);
const isDisablePending = createSelector(
  localState,
  (state) => state.disableUserStatus === DISABLE_USER_STATUS_PENDING
);
const isDisableSuccess = createSelector(
  localState,
  (state) => state.disableUserStatus === DISABLE_USER_STATUS_SUCCESS
);
const getIsDisabled = createSelector(localState, (state) => state.isDisabled);
const isUserLoaded = createSelector(localState, (state) => state.username !== null);

const isCurrentUser = createSelector(
  getUsernameSelector,
  getUsername,
  (username1, username2) => username1 === username2
);
const isFromSsoProvider = createSelector(localState, (state) => state.isFromSsoProvider);
const disablePasswordReset = createSelector(localState, (state) => state.disablePasswordReset);

const canResetPassword = (state: State) => !getIsDisabled(state);
const canChangeSite = (state: State) => !getIsDisabled(state);
const canChangeEmail = (state: State) => !getIsDisabled(state);

const isNotCurrentUserAndIsNotDisabled = createSelector(
  isCurrentUser,
  getIsDisabled,
  (isCurrent, isDisabled) => !isCurrent && !isDisabled
);

const isLoadingUserMgAndRoles = createSelector(
  localState,
  (state) => state.loadUserMgAndRolesStatus === LOAD_USER_MG_AND_ROLES_STATUS_PENDING
);

const getUserHomeManagedGroupId = createSelector(
  localState,
  (state) => state.userMgAndRoles?.homeManagedGroupId
);

const isGlobalRoleUser = createSelector(
  localState,
  (state) => state.userMgAndRoles?.anyManagedGroup
);

const getUserManagedGroupsAndRoles = createSelector(
  localState,
  getUserHomeManagedGroupId,
  (state, userHomeManagedGroupId) =>
    state.userMgAndRoles?.items
      .map(({ managedGroup, role }) => ({
        managedGroup: managedGroup?.id,
        role: role?.id,
      }))
      .sort((firstEl, secondEl) =>
        firstEl.managedGroup === userHomeManagedGroupId
          ? -1
          : secondEl.managedGroup === userHomeManagedGroupId
          ? 1
          : 0
      )
);

const getSearchResults = createSelector(localState, (state) => state.searchResults);

const getSearchError = createSelector(localState, (state) =>
  state.searchStatus === SEARCH_STATUS_FAILED ? state.searchError : undefined
);

const getChangeEmailError = createSelector(localState, (state) =>
  state.changeEmailStatus === CHANGE_EMAIL_STATUS_FAILED ? state.changeEmailError : undefined
);

export const selectors = {
  isUserLoaded,
  isResetPasswordPending,
  isResetPasswordSuccess,
  isSearchPending,
  isLoadUserPending,
  isChangeRolePending,
  isChangeRoleSuccess,
  isChangeSitePending,
  isChangeSiteSuccess,
  isChangeEmailPending,
  isChangeEmailSuccess,
  getUsername,
  getFullName,
  getUserSite,
  getUserEmail,
  getUserId,
  getChangeRoleStatus,
  getNewPassword,
  getFormValues,
  isDisablePending,
  getIsDisabled,
  isDisableSuccess,
  isCurrentUser,
  canResetPassword,
  canChangeSite,
  canChangeEmail,
  canChangeRoles: isNotCurrentUserAndIsNotDisabled,
  canDisable: isNotCurrentUserAndIsNotDisabled,
  isSearchSuccess,
  isLoadingUserMgAndRoles,
  getUserManagedGroupsAndRoles,
  getUserHomeManagedGroupId,
  isGlobalRoleUser,
  getSearchResults,
  getSearchError,
  getChangeEmailError,
  isFromSsoProvider,
  disablePasswordReset,
};

type UsersSearchResponse = {
  items: Array<SearchResult>;
  totalCount: number;
};

export const requestSearch = (query: string) =>
  request<UsersSearchResponse>(`/authapi/users/search/${encodeURIComponent(query)}`);

type UserSearchResponse = {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  site: string;
  userId: string;
  isDisabled: boolean;
};

export const requestUser = (username: string) =>
  request<UserSearchResponse>(`/authapi/users/${username}`);

type ResetPasswordResponse = {
  newPassword: string;
};

export const requestResetPassword = (userId: string) =>
  request<ResetPasswordResponse>(`/authapi/users/${userId}/resetpassword`, {
    method: 'POST',
  });

type Roles = Array<{ managedGroup: string; role: string }>;

export const requestChangeRole = (userId: string, roles: Roles) =>
  request(`/authapi/users/${userId}/setroles`, {
    method: 'PATCH',
    body: {
      roles: roles?.map(({ managedGroup, role }) => ({
        managedGroupId: managedGroup,
        roleId: role,
      })),
    },
  });

export const requestChangeSite = (userId: string, site: string) =>
  request(`/authapi/users/${userId}/setsite`, {
    method: 'PATCH',
    body: {
      site,
    },
  });

export const requestChangeEmail = (userId: string, email: string, enableSso: boolean) =>
  request(`/authapi/users/${userId}/setemail`, {
    method: 'PATCH',
    body: {
      email,
      enableSso,
    },
  });

export const requestDisableUser = (userId: string) =>
  request(`/authapi/users/${userId}/disable`, {
    method: 'PATCH',
    body: {},
  });

export const requestUserMgAndRoles = (userId: string) =>
  request<UserRolesResponse>(`/authapi/users/${userId}/getroles`);

export function* searchSaga({ payload: { username: query } }: Action<{ username: string }>) {
  try {
    const response: UsersSearchResponse = yield call(requestSearch, query);
    yield put(actions.searchSuccess(response));
  } catch (error: unknown) {
    if (error instanceof FetchError && error.status === 404) {
      // user does not exist
      yield put(actions.searchFailed('userNotFound'));
    } else {
      yield put(actions.searchFailed('genericError'));
    }
  }
}

export function* resetPasswordSaga({ payload: { userId } }: Action<{ userId: string }>) {
  try {
    const response: ResetPasswordResponse = yield call(requestResetPassword, userId);
    yield put(actions.resetPasswordSuccess(response));
  } catch (error) {
    yield put(actions.resetPasswordFailed(error));
  }
}

export function* changeRoleSaga({
  payload: { userId, roles },
}: Action<{ userId: string; roles: Roles }>) {
  try {
    yield call(requestChangeRole, userId, roles);
    yield put(actions.changeRoleSuccess());
  } catch (error) {
    yield put(actions.changeRoleFailed(error));
  }
}

export function* changeSiteSaga({
  payload: { userId, site },
}: Action<{ userId: string; site: string }>) {
  try {
    const response: unknown = yield call(requestChangeSite, userId, site);
    yield put(actions.changeSiteSuccess(response));
  } catch (error) {
    yield put(actions.changeSiteFailed(error));
  }
}

export function* changeEmailSaga({
  payload: { userId, email, enableSso },
}: Action<{ userId: string; email: string; enableSso: boolean }>) {
  try {
    const response: unknown = yield call(requestChangeEmail, userId, email, enableSso);
    yield put(actions.changeEmailSuccess(response));
  } catch (error) {
    if (error instanceof FetchError && error.status === 400) {
      const validationCodeErrors = error?.errors?.errors || [];
      if (
        validationCodeErrors?.find(
          ({ code }: { code: string }) => code === 'CCAS.SsoAlreadyEnabled'
        )
      ) {
        yield put(actions.changeEmailFailed('ssoAlreadyEnabled'));
      }
      if (
        validationCodeErrors?.find(({ code }: { code: string }) => code === 'CCAS.EmailAlreadyUsed')
      ) {
        yield put(actions.changeEmailFailed('emailAlreadyUsed'));
      }
      if (
        validationCodeErrors?.find(
          ({ code }: { code: string }) => code === 'CCAS.EmailDomainIsNotValid'
        )
      ) {
        yield put(actions.changeEmailFailed('emailDomainIsNotValid'));
      }
    } else {
      yield put(actions.changeEmailFailed('genericError'));
    }
  }
}

export function* loadUserSaga({ payload: { username } }: Action<{ username: string }>) {
  try {
    const response: UserSearchResponse = yield call(requestUser, username);
    yield put(actions.loadUserSuccess(response));
  } catch (error) {
    yield put(actions.loadUserFailed(error));
  }
}

export function* disableUserSaga({ payload: { userId } }: Action<{ userId: string }>) {
  try {
    yield call(requestDisableUser, userId);
    yield put(actions.disableUserSuccess());
  } catch (error) {
    yield put(actions.disableUserFailed(error));
  }
}

export function* loadUserMgAndRolesSaga({ payload: { userId } }: Action<{ userId: string }>) {
  try {
    const response: UserRolesResponse = yield call(requestUserMgAndRoles, userId);
    yield put(actions.loadUserMgAndRolesSuccess(response));
  } catch (error) {
    yield put(actions.loadUserMgAndRolesFailed());
  }
}

export function* saga() {
  yield all([
    takeLatest(`${PREFIX}/SEARCH_ATTEMPT`, searchSaga),
    takeLatest(`${PREFIX}/RESET_PASSWORD_ATTEMPT`, resetPasswordSaga),
    takeLatest(`${PREFIX}/CHANGE_ROLE_ATTEMPT`, changeRoleSaga),
    takeLatest(`${PREFIX}/LOAD_USER_ATTEMPT`, loadUserSaga),
    takeLatest(`${PREFIX}/DISABLE_USER_ATTEMPT`, disableUserSaga),
    takeLatest(`${PREFIX}/CHANGE_SITE_ATTEMPT`, changeSiteSaga),
    takeLatest(`${PREFIX}/CHANGE_EMAIL_ATTEMPT`, changeEmailSaga),
    takeLatest(`${PREFIX}/LOAD_USER_MG_AND_ROLES_ATTEMPT`, loadUserMgAndRolesSaga),
  ]);
}
