import { defineMessages } from 'react-intl';

export default defineMessages({
  notRefundableUnderThisPolicy: {
    id: 'RefundableUnit.notRefundableUnderThisPolicy',
    defaultMessage: 'This is not refundable under this policy',
  },
  voidAlreadyInProgressForRefundable: {
    id: 'RefundableUnit.voidAlreadyInProgressForRefundable',
    defaultMessage:
      'There is an existing void request which is either already in progress or finished',
  },
  productInInvalidState: {
    id: 'RefundableUnit.productInInvalidState',
    defaultMessage:
      'The order contains products that are not in a refundable state (Issued/Voiding/Voided)',
  },
  paidFeesAreNotRefundableUnderThisPolicy: {
    id: 'RefundableUnit.paidFeesAreNotRefundableUnderThisPolicy',
    defaultMessage: 'Paid fees are not refundable under this policy',
  },
  productModifiedExternally: {
    id: 'RefundableUnit.productModifiedExternally',
    defaultMessage: 'The product has been modified externally by another work stream',
  },
  RefundRequestStarted: {
    id: 'RefundableUnit.refundRequestStarted',
    defaultMessage: 'Refund request for this product has already been started',
  },
  notVoidableUnderThisFareType: {
    id: 'RefundableUnit.notVoidableUnderThisFareType',
    defaultMessage: 'The ticket on this booking is not eligible for this type of refund',
  },
  notVoidableUnderGivenDeliveryType: {
    id: 'RefundableUnit.notVoidableUnderGivenDeliveryType',
    defaultMessage: 'This is not voidable under the given delivery type',
  },
  'CCRefundsService.NotRefundableVia1P': {
    id: 'RefundableUnit.CCRefundsService.NotRefundableVia1P',
    defaultMessage: 'This product is not voidable via TCC.',
  },
  policyExpired: {
    id: 'RefundableUnit.policyExpired',
    defaultMessage: 'Tickets expired and not eligible for refund',
  },
});
