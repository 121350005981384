import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormSection } from 'redux-form';

import RefundFee from '../RefundFee/RefundFee';

import messages from './messages';
import styles from './styles';

const RefundFees = ({ bookingFee, deliveryFee, paymentFee, disabled }) => {
  if (!bookingFee && !deliveryFee && !paymentFee) {
    return null;
  }

  return (
    <section className={css(styles.container)}>
      <FormSection name="feeRefundables" className={css(styles.feesSection)}>
        {bookingFee && bookingFee.price !== undefined && bookingFee.price !== null && (
          <RefundFee
            {...bookingFee}
            message={messages.serviceFee}
            value={bookingFee.price}
            disabled={disabled}
          />
        )}
        {paymentFee && paymentFee.price !== undefined && paymentFee.price !== null && (
          <RefundFee
            {...paymentFee}
            message={messages.paymentFee}
            value={paymentFee.price}
            disabled={disabled}
          />
        )}
        {deliveryFee && deliveryFee.price !== undefined && deliveryFee.price !== null && (
          <RefundFee
            {...deliveryFee}
            message={messages.deliveryFee}
            value={deliveryFee.price}
            disabled={disabled}
          />
        )}
      </FormSection>
    </section>
  );
};

const priceShape = {
  amount: PropTypes.number.isRequired,
  currencyCode: PropTypes.string.isRequired,
};

const feeShape = {
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isChangeable: PropTypes.bool.isRequired,
  value: PropTypes.shape(priceShape),
};

RefundFees.propTypes = {
  bookingFee: PropTypes.shape(feeShape),
  deliveryFee: PropTypes.shape(feeShape),
  paymentFee: PropTypes.shape(feeShape),
  disabled: PropTypes.bool,
};

export default RefundFees;
