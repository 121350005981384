import { defineMessages } from 'react-intl';

export default defineMessages({
  bookingHasBeenConverted: {
    id: 'ConvertedByBookingsAlert.bookingHasBeenConverted',
    defaultMessage: 'Booking {bookingNumber} in this order has been converted. View {link}.',
  },

  newBooking: {
    id: 'ConvertedByBookingsAlert.newBooking',
    defaultMessage: 'new booking',
  },
});
