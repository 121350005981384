import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { StatusMessage } from '@trainline/depot-web';

import type { CompensationError } from '../../reducer';

import messages from './messages';

interface Props {
  error: CompensationError;
}

const CompensationFailedAlert: FunctionComponent<Props> = ({ error }) => {
  const errorObject = messages[error.errorCode] || messages.compensation_genericError;

  return (
    <StatusMessage status="negative">
      <FormattedMessage {...errorObject} values={error.context} />
    </StatusMessage>
  );
};

export default CompensationFailedAlert;
