import React, { ReactElement, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InjectedFormProps, reduxForm } from 'redux-form';

import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from '@contactcentre-web/common/Modal';
import Button from '@contactcentre-web/common/Button';
import Select from '@contactcentre-web/common/Select';
import TextField from '@contactcentre-web/common/TextField';

import messages from './messages';

type FormData = {
  reason?: string;
  note?: string;
};

interface Props {
  children: ReactElement;
  onReject: (data: FormData) => void;
  isResolutionInProgress: boolean;
  hasResolutionSucceeded: boolean;
}

export const CompensationRejectionPrompt = ({
  children,
  isResolutionInProgress,
  hasResolutionSucceeded,
  onReject,
  handleSubmit,
}: Props & InjectedFormProps<FormData, Props>) => {
  const { formatMessage } = useIntl();
  const [visible, setVisible] = useState(false);

  React.useEffect(() => {
    if (hasResolutionSucceeded) {
      setVisible(false);
    }
  }, [hasResolutionSucceeded]);

  const reasonOptions = useMemo(
    () => [
      {
        label: formatMessage(messages.incorrectAmountReason),
        value: messages.incorrectAmountReason.defaultMessage,
      },
      {
        label: formatMessage(messages.incorrectRefundCodeReason),
        value: messages.incorrectRefundCodeReason.defaultMessage,
      },
      {
        label: formatMessage(messages.incompleteNotesReason),
        value: messages.incompleteNotesReason.defaultMessage,
      },
      { label: formatMessage(messages.otherReason), value: messages.otherReason.defaultMessage },
    ],
    []
  );

  return (
    <>
      {React.cloneElement(children, { onClick: () => setVisible(true) })}
      {visible && (
        <Modal onClose={() => setVisible(false)}>
          <ModalHeader>
            <ModalTitle>
              <FormattedMessage {...messages.title} />
            </ModalTitle>
          </ModalHeader>
          <form onSubmit={handleSubmit(onReject)}>
            <ModalBody>
              <Select
                name="reason"
                label={formatMessage(messages.reason)}
                placeholder={formatMessage(messages.reasonPlaceholder)}
                options={reasonOptions}
                messages={messages}
              />
              <TextField
                name="note"
                label={formatMessage(messages.notes)}
                type="multiline-text"
                rows={7}
                messages={messages}
              />
            </ModalBody>
            <ModalFooter contentAlignment="spaceBetween">
              <Button variant="tertiary" size="large" onClick={() => setVisible(false)}>
                <FormattedMessage {...messages.buttonCancel} />
              </Button>
              <Button
                type="submit"
                variant="primary"
                size="large"
                loading={isResolutionInProgress || false}
              >
                <FormattedMessage {...messages.buttonReject} />
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      )}
    </>
  );
};

export const validate = ({ reason, note }: FormData) => ({
  reason: !reason ? 'reasonRequired' : undefined,
  note: !note ? 'notesRequired' : undefined,
});

export default reduxForm<FormData, Props>({
  form: 'COMPENSATION_REJECTION_FORM',
  validate,
})(CompensationRejectionPrompt);
