import React, { FunctionComponent } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';

import FormattedDateInLondonTz from '@contactcentre-web/common/FormattedDateInLondonTz';
import FormattedTimeInLondonTz from '@contactcentre-web/common/FormattedTimeInLondonTz';
import Link from '@contactcentre-web/common/Link';

import type { BebocItem } from '../../module';

import messages from './messages';
import styles from './styles';

const Beboc: FunctionComponent<BebocItem> = ({
  agent,
  orderReference,
  createdAt,
  capitaineUrl,
}) => (
  <div className={css(styles.container)}>
    <div className={css(styles.header)}>
      <div>
        <div className={css(styles.labelPrimary)}>
          <FormattedMessage {...messages.bookingsLocked} />
        </div>
        <FormattedMessage
          {...messages.createdAt}
          values={{
            day: (
              <FormattedDateInLondonTz
                value={createdAt}
                year="numeric"
                month="short"
                day="numeric"
                addOffset
              />
            ),
            time: <FormattedTimeInLondonTz value={createdAt} addOffset />,
          }}
        />
      </div>
      <div>
        <div className={css(styles.labelSecondary)}>
          <FormattedMessage {...messages.booking} />
        </div>
        <FormattedMessage {...messages.appliesTo} values={{ orderReference }} />
      </div>
      <div className={css(styles.colAgent)}>
        <div className={css(styles.labelSecondary)}>
          <FormattedMessage {...messages.agent} />
        </div>
        {agent}
      </div>
    </div>
    <div className={css(styles.body)}>
      <FormattedMessage
        {...messages.body}
        values={{
          link: (
            <Link linkType="external" href={capitaineUrl}>
              <FormattedMessage {...messages.capitaine} />
            </Link>
          ),
        }}
      />
    </div>
  </div>
);

export default Beboc;
