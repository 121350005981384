import { defineMessages } from 'react-intl';

export default defineMessages({
  passengerTypes: {
    id: 'Refund.RefundPassengerDetails.passengerTypes',
    defaultMessage: `{count}
      {type, select,
      adult {{count, plural, one {Adult} other {Adults}}}
      child {{count, plural, one {Child} other {Children}}}
      senior {{count, plural, one {Senior} other {Seniors}}}
      infant {{count, plural, one {Infant} other {Infants}}}
      youth {{count, plural, one {Youth} other {Youths}}}
      other {{type}}
    }`,
  },
});
