import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';

import SidebarContent from '../../../SidebarContent';
import CustomerNoteFormContainer from '../CustomerNoteForm/CustomerNoteForm';
import CustomerNotesList from '../CustomerNotesList/CustomerNotesList';
import NotesHeader from '../NotesHeader/NotesHeader';
import type { Note, Topic, NoteSubmitFormData } from '../../module';

import messages from './messages';
import styles from './styles';

interface Props {
  topic: Topic;
  topicNotes: Array<Note>;
  onNoteSubmit: (data: NoteSubmitFormData) => void;
  clearTopic: () => void;
  selectTopic: ({ topic, topicId }: { topic: Topic; topicId: string }) => void;
  loading: boolean;
  topicId: string;
}

interface Params {
  orderId: string;
}

const NotesTopic: FunctionComponent<Props> = ({
  topic,
  topicNotes = [],
  onNoteSubmit,
  clearTopic,
  selectTopic,
  loading,
  topicId,
}) => {
  const match = useRouteMatch<Params>({
    path: '/customers/:customerId/bookings/:orderId',
    strict: true,
  });

  const listContainer = useRef<HTMLDivElement>(null);
  const scrollToLatest = () => {
    if (listContainer.current === null) return;
    listContainer.current.scrollTop = listContainer.current.scrollHeight;
  };

  const orderId = match?.params.orderId;

  useEffect(() => {
    window.requestAnimationFrame(scrollToLatest);
    return () => clearTopic && clearTopic();
  }, []);

  useEffect(() => {
    if (orderId) {
      selectTopic({
        topic: 'Order',
        topicId: orderId,
      });
    }
  }, [orderId]);

  useEffect(() => {
    scrollToLatest();
  }, [topicNotes.length]);

  return (
    <>
      <NotesHeader
        topic={topic}
        clearTopic={clearTopic}
        count={topicNotes.length}
        orderId={topicId}
      />
      <SidebarContent styleSheet={styles.content}>
        <div className={css(styles.notesList)} ref={listContainer}>
          {topicNotes.length > 0 ? (
            <CustomerNotesList notes={topicNotes} />
          ) : (
            <div className={css(styles.noNotes)}>
              <FormattedMessage {...messages.noNotes} />
            </div>
          )}
        </div>
        <CustomerNoteFormContainer
          onSubmit={onNoteSubmit}
          scrollToLatest={scrollToLatest}
          loading={loading}
        />
      </SidebarContent>
    </>
  );
};

export default NotesTopic;
