import React from 'react';

interface Props {
  className?: string;
}

const IconRail = ({ className = '' }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={18}
    viewBox="0 0 56 18"
    className={className}
  >
    <path
      d="M36.968 2l7.47 3.251H33V7.49h11.438l-5.979 2.788H33v2.325h5.881l7.546 3.477h5.33l-7.827-3.477H55v-2.325H43.605L49.4 7.49H55V5.251h-5.6L42.341 2z"
      fill="#EF2721"
      fillRule="evenodd"
    />
  </svg>
);

export default IconRail;
