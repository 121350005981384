import { defineMessages } from 'react-intl';

export default defineMessages({
  bookingNotRefreshed: {
    id: 'RefreshPnrFailMulti.bookingNotRefreshed',
    defaultMessage: 'Booking has not been refreshed',
  },
  info: {
    id: 'RefreshPnrFailMulti.info',
    defaultMessage:
      'As a result of an amend made to this booking, the product contains multiple PNRs, which our support software currently cannot handle. Refreshing the PNR on this system will result in a booking error.',
  },
  links: {
    id: 'RefreshPnrFailMulti.links',
    defaultMessage:
      'To complete the PNR refresh the agent must <a>open the booking in BEBOC /Capitaine</a>. A log will be made in the <link>Order History</link> if this function is completed.',
  },
});
