import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';

import Button from '../Button';

import styles from './styles';

export class SelectList extends React.Component {
  static getDerivedStateFromProps({ value }, { value: stateValue }) {
    if (stateValue !== value) {
      return {
        value,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);

    const { value } = props;
    this.state = {
      value,
    };
  }

  componentDidMount() {
    if (this.selectedElement) {
      this.list.scrollTop = this.selectedElement.offsetTop - this.list.offsetTop;
    }
  }

  itemSelected(value) {
    this.setState({
      value,
    });

    const { onValueChanged } = this.props;
    if (onValueChanged) {
      onValueChanged(value);
    }
  }

  render() {
    const { options, styleSheet = {} } = this.props;

    const { value } = this.state;

    return (
      <ul
        className={css([styles.list, styleSheet.box])}
        size={10}
        value={value}
        ref={(element) => {
          this.list = element;
        }}
      >
        {options.map(({ label, value: itemValue }) => (
          <li
            key={itemValue}
            className={css([styles.option, value === itemValue && styles.selected])}
            value={itemValue}
            ref={(element) => {
              if (value === itemValue) {
                this.selectedElement = element;
              }
            }}
          >
            <Button
              variant="ghost"
              styleSheet={styles.button}
              onClick={() => this.itemSelected(itemValue)}
            >
              {label}
            </Button>
          </li>
        ))}
      </ul>
    );
  }
}

SelectList.propTypes = {
  value: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  styleSheet: PropTypes.shape({
    box: PropTypes.object,
  }),
  onValueChanged: PropTypes.func,
};

export default SelectList;
