import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import OriginToDestination from '@contactcentre-web/common/OriginToDestination/OriginToDestination';
import StackedLabel from '@contactcentre-web/common/StackedLabel';
import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency/FormattedCurrency';
import FormattedDateInTz from '@contactcentre-web/common/FormattedDateInTz/FormattedDateInTz';
import FormattedTimeInTz from '@contactcentre-web/common/FormattedTimeInTz/FormattedTimeInTz';
import Link from '@contactcentre-web/common/Link/Link';

import { RequestItem } from '../../selectors';

import messages from './messages';
import styles from './styles';

const RefundCardHeader: FunctionComponent<RequestItem> = ({
  origin,
  destination,
  isReturn,
  orderReference,
  ticketTypes,
  agent,
  transactionPrice,
  createdAt,
}) => (
  <div className={css(styles.header)}>
    {(origin || orderReference) && (
      <StackedLabel
        label={<FormattedMessage {...messages.booking} />}
        styleSheet={{
          container: styles.bookingContainer,
          label: styles.headerLabel,
          value: styles.headerText,
        }}
      >
        {origin && (
          <OriginToDestination isReturn={isReturn} origin={origin} destination={destination} />
        )}
        <br />
        {orderReference && (
          <Link linkType="external" href={`/customers/?q=${orderReference}`}>
            {orderReference}
          </Link>
        )}
      </StackedLabel>
    )}
    {ticketTypes?.length > 0 && (
      <StackedLabel
        label={<FormattedMessage {...messages.ticketTypes} />}
        styleSheet={{
          label: styles.headerLabel,
          value: [styles.headerText, styles.bold],
        }}
      >
        <ul className={css(styles.ticketTypes)}>
          {ticketTypes.map((ticketType, index) => (
            <li key={index}>{ticketType}</li>
          ))}
        </ul>
      </StackedLabel>
    )}
    {agent && (
      <StackedLabel
        label={<FormattedMessage {...messages.agent} />}
        styleSheet={{
          label: styles.headerLabel,
          value: styles.headerText,
        }}
      >
        {agent}
      </StackedLabel>
    )}
    {transactionPrice && (
      <StackedLabel
        label={<FormattedMessage {...messages.price} />}
        styleSheet={{
          label: styles.headerLabel,
          value: [styles.headerText, styles.bold],
        }}
      >
        <FormattedCurrency {...transactionPrice} />
      </StackedLabel>
    )}
    <StackedLabel
      label={<FormattedMessage {...messages.date} />}
      styleSheet={{
        label: styles.headerLabel,
        value: styles.headerText,
      }}
    >
      <FormattedMessage
        {...messages.createdAt}
        values={{
          day: <FormattedDateInTz value={createdAt} year="numeric" month="short" day="numeric" />,
          time: <FormattedTimeInTz value={createdAt} />,
        }}
      />
    </StackedLabel>
  </div>
);

export default RefundCardHeader;
