import { defineMessages } from 'react-intl';

export default defineMessages({
  signInHeader: {
    id: 'LoginForm.signInHeader',
    defaultMessage: 'Sign in to your agent account',
  },
  signIn: {
    id: 'LoginForm.signIn',
    defaultMessage: 'Sign in',
  },
  redirectSSOForm: {
    id: 'LoginForm.redirectSSOForm',
    defaultMessage: 'SSO',
  },
  redirectLoginForm: {
    id: 'LoginForm.redirectLoginForm',
    defaultMessage: 'Sign in via username',
  },
  companyLabel: {
    id: 'LoginForm.companyLabel',
    defaultMessage: 'Company',
  },
  companyIsRequired: {
    id: 'LoginForm.companyIsRequired',
    defaultMessage: 'Company is required.',
  },
  usernameLabel: {
    id: 'LoginForm.usernameLabel',
    defaultMessage: 'Username',
  },
  passwordLabel: {
    id: 'LoginForm.passwordLabel',
    defaultMessage: 'Password',
  },
  usernameIsRequired: {
    id: 'LoginForm.usernameIsRequired',
    defaultMessage: 'Username is required.',
  },
  passwordIsRequired: {
    id: 'LoginForm.passwordIsRequired',
    defaultMessage: 'Password is required.',
  },
  selectManagedGroupFailed: {
    id: 'LoginForm.selectManagedGroupFailed',
    defaultMessage: 'Something has gone wrong while selecting the managed group, please try again.',
  },
  SSOValidationError: {
    id: 'LoginForm.SSOValidationError',
    defaultMessage:
      "We didn't find a company or agency with that name.{br}If you previously logged in with a username and password, click the ‘Sign in via username’ button below to enter your credentials.{br}{br}If you require assistance, contact your team lead or our support team.",
  },
  SSOUnspecified: {
    id: 'LoginForm.Unspecified',
    defaultMessage: 'Something went wrong, please try again.',
  },
  Unauthorized: {
    id: 'LoginForm.Unauthorized',
    defaultMessage:
      'Login failed. Please check your username and password and try again. {br}{br}If you require assistance, contact your team lead or our support team.',
  },
  locked_out: {
    id: 'LoginForm.LockedOut',
    defaultMessage:
      'Your account is temporarily locked due to multiple unsuccessful login attempts. Try again in 10 minutes.{br}{br} If you require assistance, contact your team lead or our support team.',
  },
  Forbidden: {
    id: 'LoginForm.Forbidden',
    defaultMessage:
      'Your account is temporarily locked due to multiple unsuccessful login attempts. Try again in 10 minutes.{br}{br} If you require assistance, contact your team lead or our support team.',
  },
});
