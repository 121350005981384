import { defineMessages } from 'react-intl';

export default defineMessages({
  NA: {
    id: 'RefundAmount.NA',
    defaultMessage: 'N/A',
  },
  editAmount: {
    id: 'RefundAmount.editAmount',
    defaultMessage: 'Edit',
  },
  setAmount: {
    id: 'RefundAmount.setAmount',
    defaultMessage: 'Set',
  },
  refundAmount_min_value: {
    id: 'RefundAmount.refundAmount_min_value',
    defaultMessage: 'Refund amount too low',
  },
  refundAmount_max_value: {
    id: 'RefundAmount.refundAmount_max_value',
    defaultMessage: 'Refund must be less than product price',
  },
});
