import Price from '@contactcentre-web/redux-common/types/Price';

import { Quote, RefundableTicketMapper, TermsAndConditionsRefundableTicket } from '../types';
import {
  isQuoteProcessable,
  isFraudNonProcessableQuote,
  isRefundableNonProcessable,
} from '../utils';

type RefundableAdminFee = {
  id: 'adminFee';
  description: 'adminFee';
  price: Price;
  isSelected: boolean;
  isChangeable: boolean;
};

type RefundableRailcard = {
  isSelected: boolean;
  refundable: boolean;
  notRefundableReason: string | undefined;
  notRefundableReasonDetail: string | undefined;
  refundableId: string;
  isChangeable: boolean;
  isEligible: boolean;
  refundableAmount: Price | undefined;
  canOverrideVoidableAmounts: boolean;
  expiryTimestampUtc: string;
};

type RefundableAdminFeeMapper = {
  quote: Quote;
  selectability?: {
    selectable: boolean;
    selectedByDefault: boolean;
  };
};

type RefundableRailcardMapper = {
  quote?: Quote;
  status: string;
  refundableId: string;
  selectability?: {
    selectable: boolean;
    selectedByDefault: boolean;
  };
  reason: string;
  reasonDetail: string;
  expiryTimestampUtc: string;
};

export function ticketRefundabilityMapper({
  quote,
  status,
  refundableId,
  selectability,
  reason,
  reasonDetail,
  fareType,
  allowedEntanglementCombinations,
  entanglementEnforcementMode,
}: RefundableTicketMapper): TermsAndConditionsRefundableTicket {
  const refundable = (status || '').toLowerCase() === 'refundable';

  const quoteRefundable =
    quote && quote.refundables.find(({ id: quoteId }) => quoteId === refundableId);

  return {
    fareType,
    allowedEntanglementCombinations,
    entanglementEnforcementMode,
    isSelected: isQuoteProcessable(quote) && !!quoteRefundable,
    refundable,
    notRefundableReason: refundable ? undefined : reason,
    notRefundableReasonDetail: refundable ? undefined : reasonDetail,
    refundableId,
    isChangeable:
      !isFraudNonProcessableQuote(quote) &&
      !!selectability &&
      selectability.selectable &&
      !isRefundableNonProcessable(quote, refundableId),
    isEligible: !!selectability && selectability.selectedByDefault,
    refundableAmount: quoteRefundable?.refundableAmount,
    canOverrideVoidableAmounts: !!quote?.customisations?.canOverrideVoidableAmounts,
  };
}

export function railcardRefundabilityMapper({
  quote,
  status,
  refundableId,
  selectability,
  reason,
  reasonDetail,
  expiryTimestampUtc,
}: RefundableRailcardMapper): RefundableRailcard {
  const refundable = (status || '').toLowerCase() === 'refundable';

  const quoteRefundable =
    quote && quote.refundables.find(({ id: quoteId }) => quoteId === refundableId);

  return {
    isSelected: isQuoteProcessable(quote) && !!quoteRefundable,
    refundable,
    notRefundableReason: refundable ? undefined : reason,
    notRefundableReasonDetail: refundable ? undefined : reasonDetail,
    refundableId,
    isChangeable:
      !isFraudNonProcessableQuote(quote) &&
      !!selectability &&
      selectability.selectable &&
      !isRefundableNonProcessable(quote, refundableId),
    isEligible: !!selectability && selectability.selectedByDefault,
    expiryTimestampUtc,
    refundableAmount: quoteRefundable?.refundableAmount,
    canOverrideVoidableAmounts: !!quote?.customisations?.canOverrideVoidableAmounts,
  };
}

export function adminFeedRefundabilityMapper({
  quote,
  selectability,
}: RefundableAdminFeeMapper): RefundableAdminFee {
  return {
    id: 'adminFee',
    description: 'adminFee',
    price: quote.adminFee.value,
    isSelected: isQuoteProcessable(quote) && !!quote.adminFee.deducted,
    isChangeable: !!selectability && selectability.selectable,
  };
}
