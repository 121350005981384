import { defineMessages } from 'react-intl';

export default defineMessages({
  pending: {
    id: 'FulfilmentStatus.pending',
    defaultMessage: 'Pending',
  },
  issuing: {
    id: 'FulfilmentStatus.issuing',
    defaultMessage: 'Issuing',
  },
  complete: {
    id: 'FulfilmentStatus.complete',
    defaultMessage: 'Complete',
  },
  voidpending: {
    id: 'FulfilmentStatus.voidpending',
    defaultMessage: 'Void Pending',
  },
  voided: {
    id: 'FulfilmentStatus.voided',
    defaultMessage: 'Voided',
  },
  abandoned: {
    id: 'FulfilmentStatus.abandoned',
    defaultMessage: 'Abandoned',
  },
  voidfailed: {
    id: 'FulfilmentStatus.voidfailed',
    defaultMessage: 'Void Failed',
  },
  failed: {
    id: 'FulfilmentStatus.failed',
    defaultMessage: 'Failed',
  },
  unspecified: {
    id: 'FulfilmentStatus.unspecified',
    defaultMessage: 'Unspecified',
  },
  cancelled: {
    id: 'FulfilmentStatus.cancelled',
    defaultMessage: 'Cancelled',
  },
  unknown: {
    id: 'FulfilmentStatus.unknown',
    defaultMessage: 'Unknown',
  },
});
