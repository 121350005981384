import React from 'react';
import { css } from 'aphrodite/no-important';

import Loader from '@contactcentre-web/common/Loader';

import styles from './styles';

const NotesLoading = () => (
  <div className={css(styles.loaderContainer)}>
    <Loader />
  </div>
);

export default NotesLoading;
