import { defineMessages } from 'react-intl';

export default defineMessages({
  bookingInfo: {
    id: 'SeasonBooking.Details.bookingInfo',
    defaultMessage: 'Booking info',
  },
  tickets: {
    id: 'SeasonBooking.Details.tickets',
    defaultMessage: 'Tickets',
  },
});
