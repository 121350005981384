import React from 'react';
import { FormattedMessage } from 'react-intl';

import RefundableCheckIcons from '../RefundableCheckIcons/RefundableCheckIcons';

import messages from './messages';

type RefundableStatusProps = {
  isRefundable: boolean;
  notRefundableReason?: string;
  notRefundableReasonDetail?: string;
};

export const RefundableStatus = ({
  isRefundable,
  notRefundableReason,
  notRefundableReasonDetail,
}: RefundableStatusProps) => {
  const notRefundablePopupMessage =
    !isRefundable && notRefundableReason && messages[notRefundableReason as keyof typeof messages];

  return (
    <RefundableCheckIcons isRefundable={isRefundable}>
      {notRefundablePopupMessage && <FormattedMessage {...notRefundablePopupMessage} />}
      {!notRefundablePopupMessage && notRefundableReasonDetail && (
        <span>{notRefundableReasonDetail}</span>
      )}
      {!notRefundablePopupMessage && !notRefundableReasonDetail && (
        <FormattedMessage {...messages.notRefundableUnderThisPolicy} />
      )}
    </RefundableCheckIcons>
  );
};
