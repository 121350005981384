import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import { compose, pluck, flatten, uniq, filter } from 'ramda';

import request from '@contactcentre-web/utils/request';
import { CUSTOMER_ORDER_SUCCESS } from '@contactcentre-web/redux-common/actions/order';
import { selectOrder } from '@contactcentre-web/redux-common/selectors/order';

import { extractQuoteUnprocessableReasons } from '../utils';

import actions, { load, update, refund } from './module';
import selectors from './selectors';

export const putQuote = (orderId, refundables, override) =>
  request(`/refundsapi/refunds/quotes`, {
    method: 'POST',
    body: {
      orderId,
      policy: 'same-day-void',
      refundableItems: refundables,
      overrideFraudProvider: override,
    },
  });

export const getEligility = (orderId) =>
  request(`/refundsapi/refunds/${orderId}/refund-eligibility?policy=same-day-void`);

export const postRefundRequest = (quoteReference) => {
  const uri = `/refundsapi/refunds/quotes/${quoteReference}/confirm`;
  const method = 'POST';
  return request(uri, {
    method,
  });
};

export function* loadSaga() {
  try {
    const state = yield select();
    const order = selectOrder(state);

    if (!order || !selectors.isLoadPending(state.sdv)) {
      // Either the order is not loaded yet
      // or the order has loaded, but we're not on the refunds screen
      return;
    }
    const { id } = order;

    const { refundables } = yield call(getEligility, id);

    const ticketRefundablesIds = compose(
      pluck('refundableId'),
      filter((refundable) => refundable.status === 'refundable'),
      flatten,
      pluck('passengerRefundables')
    )(refundables);

    const insuranceRefundablesIds = compose(
      pluck('refundableId'),
      filter((refundable) => refundable.status === 'refundable'),
      flatten,
      pluck('insuranceRefundables')
    )(refundables);

    let quote = selectors.getQuote(state);

    const refundableIds = ticketRefundablesIds.concat(insuranceRefundablesIds);

    if (refundableIds && refundableIds.length > 0) {
      quote = yield call(putQuote, id, refundableIds);
      quote.unprocessableReasons = extractQuoteUnprocessableReasons(quote);
    }

    yield put(actions.loadSuccess({ refundables, quote }));
  } catch (e) {
    yield put(actions.loadFailed());
  }
}

export function* updateSaga({ payload: { refundableIds, override } }) {
  try {
    const state = yield select();
    const order = selectOrder(state);
    const { id } = order;

    const mandatoryRefundablesIds = selectors.getMandatoryRefundablesIds(state);

    const allRefundablesIds = uniq([...mandatoryRefundablesIds, ...refundableIds]);

    const response = yield call(putQuote, id, allRefundablesIds, override);
    response.unprocessableReasons = extractQuoteUnprocessableReasons(response);

    yield put(actions.updateSuccess(response));
  } catch (e) {
    yield put(actions.updateFailed());
  }
}

export function* refundSaga() {
  const state = yield select();
  const quoteReference = selectors.getQuoteReference(state);

  try {
    yield call(postRefundRequest, quoteReference);
    yield put(actions.refundSuccess());
  } catch (error) {
    yield put(
      actions.refundFailed(
        { ...error },
        error?.validationErrors?.map(({ code }) => ({ reasonCode: code }))
      )
    );
  }
}

export function* saga() {
  yield all([
    takeLatest(load.LOAD_ATTEMPT, loadSaga),
    takeLatest(CUSTOMER_ORDER_SUCCESS, loadSaga),
    takeLatest(update.UPDATE_ATTEMPT, updateSaga),
    takeLatest(refund.REFUND_ATTEMPT, refundSaga),
  ]);
}

export default saga;
