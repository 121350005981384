import { defineMessages } from 'react-intl';

export default defineMessages({
  pnrRefreshed: {
    id: 'RefreshPnrForm.pnrRefreshed',
    defaultMessage: 'PNR has been refreshed',
  },
  pnrRefreshedDetails: {
    id: 'RefreshPnrForm.pnrRefreshedDetails',
    defaultMessage: 'The latest information is shown below.',
  },
  bookingUpToDate: {
    id: 'RefreshPnrForm.bookingUpToDate',
    defaultMessage: 'Booking is up to date',
  },
  bookingUpToDateDetails: {
    id: 'RefreshPnrForm.bookingUpToDateDetails',
    defaultMessage:
      'The booking has not been amended since the original transaction – the current version is the most recent.',
  },
  noNewPnr: {
    id: 'RefreshPnrForm.noNewPnr',
    defaultMessage: 'No new PNR has been generated.',
  },
});
