import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  container: {
    display: 'flex',
    margin: `${spacers.dp_s24} 0 ${spacers.dp_s48} 0`,
  },
  ticketDetails: {
    flex: 3,
    borderRight: `solid 1px ${colors.gray.alpha8}`,
  },
  ticketAssets: {
    flex: 2,
  },
});
