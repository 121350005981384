import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';

import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency';
import Price from '@contactcentre-web/redux-common/types/Price';
import DiscountType from '@contactcentre-web/redux-common/types/DiscountType';

import messages from './messages';
import styles from './styles';

interface Props {
  type: DiscountType;
  pricePaid: Price;
}

const CreditTrainlineRow = ({ type, pricePaid }: Props) => {
  const isCreditToTrainline = type === DiscountType.CreditToTrainline;

  if (!isCreditToTrainline) {
    return null;
  }

  return (
    <tr data-test="credit-trainline-row">
      <td className={css(styles.padHorizontal)} colSpan={4}>
        <table className={css([styles.table, styles.padHorizontal])}>
          <tbody>
            <tr>
              <td className={css([styles.itemCell])}>
                <FormattedMessage {...messages.creditToTrainline} />
              </td>
              <td className={css(styles.pricePaidCell)}>
                {pricePaid && (
                  <FormattedCurrency amount={pricePaid.amount} currency={pricePaid.currencyCode} />
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
};

export default CreditTrainlineRow;
