import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from '@contactcentre-web/common/Modal';
import Button from '@contactcentre-web/common/Button/Button';

import messages from './messages';

interface Props {
  onClose: () => void;
  onConfimation: () => void;
}

const ManualEntryConsoleGenerateReportModal = ({ onClose, onConfimation }: Props) => (
  <Modal onClose={onClose}>
    <ModalHeader>
      <ModalTitle>
        <FormattedMessage {...messages.header} />
      </ModalTitle>
    </ModalHeader>
    <ModalBody>
      <FormattedMessage {...messages.body} />
    </ModalBody>
    <ModalFooter contentAlignment="spaceBetween">
      <Button variant="tertiary" size="large" onClick={onClose}>
        <FormattedMessage {...messages.cancelButton} />
      </Button>
      <Button
        testId="downloadButton"
        variant="primary"
        size="large"
        onClick={() => {
          onConfimation();
          onClose();
        }}
      >
        <FormattedMessage {...messages.downloadButton} />
      </Button>
    </ModalFooter>
  </Modal>
);

export default ManualEntryConsoleGenerateReportModal;
