import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import qs from 'query-string';

import Loader from '@contactcentre-web/common/Loader';
import PageContainer from '@contactcentre-web/common/PageContainer/PageContainer';
import { selectors as orderSelectors } from '@contactcentre-web/customer-order/module';

import { actions as bookingRefundFormActions } from '../common/module';
import BookingList from '../common/BookingList';

import { actions } from './module';
import selectors from './selectors';
import styles from './styles';
import messages from './messages';

export const Discretionary = ({
  orderId,
  bookings,
  refundReasons,
  loadRefunds,
  loadPendingApproval,
  hasFailed,
  hasSucceeded,
  dispose,
  isApproveRefundSuccess,
}) => {
  const history = useHistory();
  const { orderId: orderReference, customerId, bookingId, quoteId } = useParams();
  const { search, pathname } = useLocation();

  useEffect(() => {
    if (bookingId && quoteId) {
      const { returnUrl } = qs.parse(search);
      loadPendingApproval(customerId, orderReference, returnUrl);
    } else {
      loadRefunds(customerId, orderReference);
    }
  }, []);

  React.useEffect(
    () =>
      history.listen((state) => {
        if (pathname !== state.pathname) {
          dispose();
        }
      }),
    [pathname]
  );

  React.useEffect(() => {
    if (isApproveRefundSuccess) {
      const { returnUrl } = qs.parse(search);
      if (returnUrl) {
        history.push(returnUrl);
      }
    }
  }, [isApproveRefundSuccess]);

  if (!hasFailed && !hasSucceeded) {
    return (
      <div className={css(styles.loaderContainer)}>
        <Loader />
      </div>
    );
  }

  return (
    <PageContainer>
      <div className={css(styles.container)}>
        <section>
          <BookingList
            orderReference={orderReference}
            orderHistoryRoute={`/customers/${customerId}/bookings/${orderReference}/history`}
            bookings={bookings}
            customerId={customerId}
            refundReasons={refundReasons}
            orderId={orderId}
            actionMessages={{
              process: messages.approveRefund,
            }}
            refundType="discretionary"
          />
        </section>
      </div>
    </PageContainer>
  );
};

Discretionary.propTypes = {
  orderId: PropTypes.string,
  loadRefunds: PropTypes.func.isRequired,
  loadPendingApproval: PropTypes.func.isRequired,
  dispose: PropTypes.func,
  bookings: PropTypes.array,
  refundReasons: PropTypes.array,
  hasFailed: PropTypes.bool.isRequired,
  hasSucceeded: PropTypes.bool.isRequired,
  isApproveRefundSuccess: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: selectors.inProgress(state) || orderSelectors.isLoadingOrder(state),
  hasSucceeded: selectors.hasSucceeded(state),
  hasFailed: selectors.hasFailed(state) || !!orderSelectors.selectError(state),
  bookings: selectors.getBookings(state),
  refundReasons: selectors.refundReasons(state),
  orderId: selectors.getOrderId(state),
  isApproveRefundSuccess: selectors.isAnyApproveRefundSuccess(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadRefunds: (orderReference) => {
    dispatch(actions.loadRefunds(orderReference));
  },
  loadPendingApproval: (orderReference, returnUrl) => {
    dispatch(actions.loadExistingRefund(orderReference, returnUrl));
  },
  dispose: () => {
    dispatch(actions.dispose());
    dispatch(bookingRefundFormActions.resetForm());
  },
});

const DiscretionaryContainer = connect(mapStateToProps, mapDispatchToProps)(Discretionary);
DiscretionaryContainer.displayName = 'Discretionary';

export default DiscretionaryContainer;
