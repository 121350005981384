import PropTypes from 'prop-types';
import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import MidDot from '@contactcentre-web/common/MidDot/MidDot';
import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency/FormattedCurrency';
import formatDuration from '@contactcentre-web/utils/duration';
import priceShape from '@contactcentre-web/utils/shape/price';

import Leg from '../Leg/Leg';

import messages from './messages';
import styles from './styles';

const FULFILMENT_STATUS_STYLES = {
  issuing: 'fulfilmentStatusIssuing',
  issued: 'fulfilmentStatusIssued',
  others: 'fulfilmentStatusOthers',
};

const Journey = ({
  changes,
  destination,
  deliveryRecipient,
  duration,
  legs,
  origin,
  transactionTotalPrice,
  inventoryTotalPrice,
  journeyNum,
  journeyCount,
  fulfilmentStatus,
  seatingPreferences,
  validityPeriod,
  convertedOrderReference,
}) => (
  <section className={css(styles.container)}>
    <div className={css(styles.row, styles.rowBooking)}>
      <div>
        <span className={css(styles.greyLabel)}>
          <FormattedMessage {...messages.journeyCount} values={{ journeyNum, journeyCount }} />
        </span>
        <div className={css(styles.directions)}>
          <h2 className={css(styles.title2)}>
            <FormattedMessage {...messages.directionOfJourney} values={{ origin, destination }} />
          </h2>
        </div>

        <div>
          <span className={css(styles.duration)}>
            <FormattedMessage {...messages.duration} />
          </span>
          <span className={css(styles.greyLabel)}>{formatDuration(duration)}</span>
          <MidDot />
          <span>
            <FormattedMessage {...messages.changes} />{' '}
          </span>
          <span className={css(styles.greyLabel)}>{changes}</span>
          <MidDot />

          <span>
            <FormattedMessage
              {...messages.fulfillment}
              values={{
                status: (
                  <span
                    className={css(
                      styles[
                        FULFILMENT_STATUS_STYLES[
                          fulfilmentStatus && fulfilmentStatus.toLowerCase()
                        ] || FULFILMENT_STATUS_STYLES.others
                      ]
                    )}
                  >
                    {fulfilmentStatus}
                  </span>
                ),
              }}
            />
          </span>
        </div>
      </div>
      {transactionTotalPrice && inventoryTotalPrice && (
        <div className={css(styles.price)}>
          <div className={css(styles.title2)}>
            <FormattedCurrency {...transactionTotalPrice} />
          </div>
          <div className={css(styles.inventoryPrice)}>
            {(convertedOrderReference ||
              transactionTotalPrice.currencyCode !== inventoryTotalPrice.currencyCode) && (
              <span>
                (<FormattedCurrency {...inventoryTotalPrice} />)
              </span>
            )}
          </div>
        </div>
      )}
    </div>

    {legs.map((leg, index, { length }) => (
      <Leg
        key={index}
        {...leg}
        deliveryRecipient={deliveryRecipient}
        seatingPreferences={seatingPreferences}
        legNum={index + 1}
        legCount={length}
      />
    ))}
    {validityPeriod && (
      <div className={css(styles.validityPeriod)}>
        <FormattedMessage
          {...messages.returnIsValidFor}
          values={{
            period: moment.duration(validityPeriod).humanize(),
          }}
        />
      </div>
    )}
  </section>
);

Journey.propTypes = {
  changes: PropTypes.number.isRequired,
  deliveryRecipient: PropTypes.object,
  destination: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  legs: PropTypes.arrayOf(PropTypes.object).isRequired,
  origin: PropTypes.string.isRequired,
  transactionTotalPrice: priceShape,
  inventoryTotalPrice: priceShape,
  journeyNum: PropTypes.number.isRequired,
  journeyCount: PropTypes.number.isRequired,
  fulfilmentStatus: PropTypes.string,
  seatingPreferences: PropTypes.string,
  validityPeriod: PropTypes.string,
  convertedOrderReference: PropTypes.string,
};

export default Journey;
