import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { css } from 'aphrodite/no-important';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { Paragraph } from '@trainline/depot-web';
import moment from 'moment';

import OriginToDestination from '@contactcentre-web/common/OriginToDestination/OriginToDestination';
import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency';
import {
  ClaimStatus,
  ManualEntryConsoleClaimItemResponse,
  ManualEntryConsoleProductType,
  useManualEntryConsoleClaimStatus,
} from '@contactcentre-web/hooks/api/useManualEntryConsole';
import Link from '@contactcentre-web/common/Link/Link';
import Button from '@contactcentre-web/common/Button/Button';
import HorizontalRule from '@contactcentre-web/common/HorizontalRule';
import Badge from '@contactcentre-web/common/Badge/Badge';

import ManualEntryConsoleStatusChangeFailed from '../ManualEntryConsoleStatusChangeFailed/ManualEntryConsoleStatusChangeFailed';
import ManualEntryConsoleDeleteModal from '../ManualEntryConsoleDeleteModal/ManualEntryConsoleDeleteModal';

import messages from './messages';
import styles from './styles';

interface Props {
  manualRecovery: ManualEntryConsoleClaimItemResponse;
  claimStatus: ClaimStatus;
}

const ManualEntryConsoleQueueItem = ({ manualRecovery, claimStatus }: Props) => {
  const history = useHistory();
  const TIME_TO_HIDE_MESSAGE = 3000; // 3 seconds
  const {
    id,
    origin,
    destination,
    orderReference,
    createdAt,
    createdBy,
    reasonCode,
    productType,
    ticketCount,
    orderTotalAmount,
    recoveredGrossAmount,
    adminFeeAmount,
    refundAmount,
    recoveredNetAmount,
    source,
    description,
  } = manualRecovery;
  const ref = useRef<HTMLElement>(null);
  const [isConfirmationModalOpened, setIsConfirmationModalOpened] = useState(false);
  const { mutate, error } = useManualEntryConsoleClaimStatus(id, claimStatus);
  const [claimStatusChangeStatus, setClaimStatusChangeStatus] = useState<{
    claimStatus?: ClaimStatus;
    isSuccess?: boolean;
    isError?: boolean;
  }>();
  const height = (!!claimStatusChangeStatus?.isSuccess && ref.current?.clientHeight) || 0;
  const isManualCompensation = source === 'manual';

  const updateClaimStatusTemporary = (
    claimStatus: ClaimStatus,
    isSuccess?: boolean,
    isError?: boolean
  ) => {
    setClaimStatusChangeStatus({ claimStatus, isSuccess, isError });
    setTimeout(() => setClaimStatusChangeStatus({}), TIME_TO_HIDE_MESSAGE);
  };

  const handleChangeStatus = useCallback(
    (claimStatus: ClaimStatus) => {
      mutate(claimStatus, {
        onSuccess: () => updateClaimStatusTemporary(claimStatus, true, false),
        onError: () => updateClaimStatusTemporary(claimStatus, false, true),
      });
    },
    [mutate]
  );

  return (
    <>
      {error && claimStatusChangeStatus?.isError && claimStatusChangeStatus.claimStatus && (
        <ManualEntryConsoleStatusChangeFailed
          claimStatus={claimStatusChangeStatus.claimStatus}
          error={error}
        />
      )}
      {isConfirmationModalOpened && (
        <ManualEntryConsoleDeleteModal
          onClose={() => setIsConfirmationModalOpened(false)}
          onConfimation={() => handleChangeStatus(ClaimStatus.Rejected)}
        />
      )}
      <section
        ref={ref}
        data-testid="manualEntryConsoleQueueItem"
        className={css([styles.container, !!claimStatusChangeStatus?.isSuccess && styles.approved])}
        style={height === 0 ? {} : { marginBottom: height * -1 }}
      >
        <header className={css(styles.header)}>
          <div>
            <Paragraph typeStyle="body" fontWeight="regular" as="p" color="subdued">
              <Link linkType="external" href={`/customers/?q=${orderReference}`}>
                {orderReference}
              </Link>{' '}
              • <FormattedCurrency {...orderTotalAmount} />
            </Paragraph>
            <Paragraph typeStyle="body" fontWeight="regular" as="p" color="base">
              {productType === ManualEntryConsoleProductType.railcardProduct ? (
                description
              ) : (
                <OriginToDestination
                  origin={origin.name}
                  destination={destination.name}
                  shouldVerticalAlignBottom
                />
              )}
            </Paragraph>
          </div>
          <div className={css(styles.claimMeta)}>
            <div className={css(styles.claimCreatedBy)}>
              <Paragraph typeStyle="body" fontWeight="regular" as="p" color="base">
                <FormattedMessage
                  {...messages.createdAtDate}
                  values={{
                    day: (
                      <FormattedDate value={createdAt} year="numeric" month="short" day="2-digit" />
                    ),
                    time: <FormattedTime value={createdAt} />,
                  }}
                />{' '}
                <Paragraph typeStyle="body" fontWeight="regular" as="span" color="subdued">
                  ({moment(createdAt).fromNow()})
                </Paragraph>
              </Paragraph>
              <Paragraph typeStyle="body" fontWeight="regular" as="p" color="subdued">
                <FormattedMessage {...messages.raisedBy} values={{ name: createdBy.displayName }} />
              </Paragraph>
            </div>
            {isManualCompensation && <Badge styleSheet={[styles.badge]}>Manual</Badge>}
          </div>
        </header>
        <div className={css(styles.grid)}>
          <div className={css(styles.columnLeft)}>
            <Paragraph typeStyle="body" fontWeight="regular" as="p" color="subdued">
              <FormattedMessage {...messages.reasonCode} />{' '}
              <strong>
                {reasonCode ? (
                  reasonCode.description
                ) : (
                  <FormattedMessage {...messages.noReasonCode} />
                )}
              </strong>
            </Paragraph>
            <Paragraph typeStyle="body" fontWeight="regular" as="p" color="subdued">
              <FormattedMessage
                {...messages.product}
                values={{
                  productType: productType,
                  b: (productType: ManualEntryConsoleProductType) => <strong>{productType}</strong>,
                }}
              />
            </Paragraph>
            <Paragraph typeStyle="body" fontWeight="regular" as="p" color="subdued">
              <FormattedMessage
                {...messages.numberOfTickets}
                values={{
                  numberOfTickets: <strong>{ticketCount}</strong>,
                }}
              />
            </Paragraph>
            {!isManualCompensation && (
              <Paragraph typeStyle="body" fontWeight="regular" as="p" color="subdued">
                <FormattedMessage
                  {...messages.compensationTotal}
                  values={{
                    ...refundAmount,
                    strong: () => (
                      <strong>
                        <FormattedCurrency {...refundAmount} />
                      </strong>
                    ),
                  }}
                />
              </Paragraph>
            )}
          </div>
          <div className={css(styles.columnCenter)}>
            <Paragraph typeStyle="body" fontWeight="regular" as="p" color="subdued">
              <FormattedMessage
                {...messages.recoverTotal}
                values={{
                  ...recoveredGrossAmount,
                  strong: () => (
                    <strong>
                      <FormattedCurrency {...recoveredGrossAmount} />
                    </strong>
                  ),
                }}
              />
            </Paragraph>
            <Paragraph typeStyle="body" fontWeight="regular" as="p" color="subdued">
              <FormattedMessage
                {...messages.adminFee}
                values={{
                  ...adminFeeAmount,
                  span: () => (
                    <span>
                      <FormattedCurrency {...adminFeeAmount} />
                    </span>
                  ),
                }}
              />
            </Paragraph>
            <HorizontalRule />
            <Paragraph typeStyle="body" fontWeight="regular" as="p" color="subdued">
              <FormattedMessage
                {...messages.netValue}
                values={{
                  ...recoveredNetAmount,
                  strong: () => (
                    <strong>
                      <FormattedCurrency {...recoveredNetAmount} />
                    </strong>
                  ),
                }}
              />
            </Paragraph>
          </div>
          <div className={css(styles.columnRight)}>
            {claimStatus === 'pending' && (
              <>
                <Button
                  size="small"
                  variant="tertiary"
                  onClick={() => history.push(`/mec-claim-edit/${id}`)}
                >
                  <FormattedMessage {...messages.editButton} />
                </Button>
                <Button
                  size="small"
                  variant="primary"
                  onClick={() => handleChangeStatus(ClaimStatus.Approved)}
                  disabled={recoveredGrossAmount.amount === 0 || !reasonCode}
                >
                  <FormattedMessage {...messages.approveButton} />
                </Button>
                <Button
                  size="small"
                  variant="destructive"
                  onClick={() => setIsConfirmationModalOpened(true)}
                >
                  <FormattedMessage {...messages.deleteButton} />
                </Button>
              </>
            )}
            {claimStatus === 'approved' && (
              <Button
                size="small"
                variant="tertiary"
                styleSheet={styles.danger}
                onClick={() => handleChangeStatus(ClaimStatus.Pending)}
              >
                <FormattedMessage {...messages.removeFromQueueButton} />
              </Button>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default ManualEntryConsoleQueueItem;
