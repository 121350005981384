import { StyleSheet } from 'aphrodite/no-important';
import { borderRadius, palettes, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '120px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacers.dp_s16,
  },
  formContainer: {
    background: palettes.dp_app_backdrop_base,
    padding: spacers.dp_s32,
    borderRadius: borderRadius.dp_r8,
    width: '456px',
  },
  headerDecoration: {
    position: 'absolute',
    top: 0,
    background: palettes.dp_brand_primary_core,
    width: '100%',
    height: '350px',
    borderRadius: '0px 0px 160px 0px',
    zIndex: -1,
  },
  heading: {
    marginTop: spacers.dp_s32,
    marginBottom: spacers.dp_s48,
  },
});
