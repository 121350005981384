import { defineMessages } from 'react-intl';

export default defineMessages({
  bulkUpload: {
    id: 'BulkUpload.bulkUpload',
    defaultMessage: 'Bulk upload',
  },
  bulkUploadType: {
    id: 'BulkUpload.bulkUploadType',
    defaultMessage: 'Bulk upload type',
  },
  uploadFraudPreventionData: {
    id: 'BulkUpload.uploadRefundAbusers',
    defaultMessage: 'Upload potential refund abusers',
  },
  bulkUploadSizeError: {
    id: 'BulkUpload.bulkUploadSizeError',
    defaultMessage: 'File size cannot exceed more than 0.5MB',
  },
});
