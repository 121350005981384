import { StyleSheet } from 'aphrodite/no-important';
import { palettes, spacers, borderRadius } from '@trainline/depot-web/css/themes/trainline';

import { zIndex } from '@contactcentre-web/styles/zIndex';

export default StyleSheet.create({
  wrapper: {
    position: 'fixed',
    zIndex: zIndex.modal,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    backdropFilter: 'blur(2px)',
    inset: '0px',
    animationName: {
      from: { opacity: 0 },
      to: { opacity: 1 },
    },
    animationDuration: '.15s',
  },
  content: {
    position: 'absolute',
    width: '650px',
    padding: spacers.dp_s32,
    inset: '50% auto auto 50%',
    transform: 'translate(-50%, -50%)',
    background: palettes.dp_app_backdrop_base,
    boxShadow: 'rgb(0 0 0 / 12%) 0px 4px 8px 0px',
    border: `solid 1px ${palettes.dp_app_border_base}`,
    borderRadius: borderRadius.dp_r4,
    maxHeight: '80vh',
    overflow: 'auto',
  },
  close: {
    position: 'absolute',
    top: spacers.dp_s24,
    right: spacers.dp_s24,
    height: '26px', // icon size 24px + 2px for the button border
    width: '26px', // icon size 24px + 2px for the button border
  },
  header: {
    margin: `0 ${spacers.dp_s16} ${spacers.dp_s24} 0`,
  },
  footer: {
    marginTop: spacers.dp_s32,
    display: 'flex',
  },
  end: {
    justifyContent: 'flex-end',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
});
