import type { Action } from 'redux-actions';

import tokenStore from '@contactcentre-web/utils/tokenStore';

import type { Features, ManagedGroup, ManagedGroupWithId, Permissions } from './service';
import type { Credentials } from './login/service';

const PREFIX = 'users';
export const LOGIN_REQUESTED = `${PREFIX}/LOGIN_REQUESTED`;
export const LOGIN_SUCCEEDED = `${PREFIX}/LOGIN_SUCCEEDED`;
export const LOGIN_FAILED = `${PREFIX}/LOGIN_FAILED`;
export const LOGOUT = `${PREFIX}/LOGOUT`;
export const LOGOUT_SUCCEEDED = `${PREFIX}/LOGOUT_SUCCEEDED`;
export const PASSWORD_CHANGE_REQUESTED = `${PREFIX}/PASSWORD_CHANGE_REQUESTED`;
export const PASSWORD_CHANGE_SUCCEEDED = `${PREFIX}/PASSWORD_CHANGE_SUCCEEDED`;
export const AUTHENTICATION_SUCCEDED = `${PREFIX}/AUTHENTICATION_SUCCEDED`;
export const LOAD_USER_INFO_SUCCESS = `${PREFIX}/LOAD_USER_INFO_SUCCESS`;
export const FORWARD_AUTH_FLOW = `${PREFIX}/FORWARD_AUTH_FLOW`;
export const AUTH_FLOW_FORWARDING = `${PREFIX}/AUTH_FLOW_FORWARDING`;
export const BUSINESS_SETTINGS_REQUESTED = `${PREFIX}/BUSINESS_SETTINGS_REQUESTED`;
export const BUSINESS_SETTINGS_REQUEST_SUCCESS = `${PREFIX}/BUSINESS_SETTINGS_REQUEST_SUCCESS`;
export const BUSINESS_SETTINGS_REQUEST_FAIL = `${PREFIX}/BUSINESS_SETTINGS_REQUEST_FAIL`;

export const flowActions = {
  resetPassword: '/change_password',
  selectManagedGroup: '/select-managed-group',
  selectUILanguage: '/select-ui-language',
};

export interface NextAction {
  action: string;
  context?: Record<string, unknown>;
}

export interface UsersState {
  loggedIn: boolean;
  userId: string | null;
  name: string | null;
  firstName: string | null;
  lastName: string | null;
  site: string;
  permissions: Permissions;
  homeManagedGroupId: string | null;
  managedGroup: ManagedGroupWithId;
  managedGroups: Array<ManagedGroup>;
  roleName: string | null;
  features: Features;
  loggedOut: boolean;
  authenticated: boolean;
  nextAction?: NextAction;
}

interface LoginSucceededPayload {
  userId: string;
  name: string;
  firstName: string;
  lastName: string;
  site: string;
  permissions: Permissions;
  homeManagedGroupId: string;
  managedGroup: ManagedGroupWithId;
  managedGroups: Array<ManagedGroup>;
  roleName: string;
  features: Features;
}

interface AuthenticationSucceededPayload {
  userId?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  site?: string;
  permissions?: Permissions;
  homeManagedGroupId?: string;
  managedGroups?: Array<ManagedGroup>;
}

interface LoadUserInfoSuccessPayload {
  permissions: Permissions;
  managedGroup: ManagedGroupWithId;
  managedGroups: Array<ManagedGroup>;
  roleName: string;
  features: Features;
}

export type UsersReducerPayload = LoginSucceededPayload &
  AuthenticationSucceededPayload &
  LoadUserInfoSuccessPayload &
  NextAction;

export const initialState = {
  loggedIn: tokenStore.validTokenExists(),
  userId: null,
  name: null,
  firstName: null,
  lastName: null,
  site: '',
  permissions: {} as Permissions,
  homeManagedGroupId: null,
  managedGroup: {} as ManagedGroupWithId,
  managedGroups: [] as Array<ManagedGroup>,
  roleName: null,
  features: {} as Features,
  loggedOut: false,
  authenticated: false,
};

export default (state: UsersState = initialState, action: Action<UsersReducerPayload>) => {
  switch (action.type) {
    case LOGIN_SUCCEEDED: {
      const {
        userId,
        name,
        firstName,
        lastName,
        site,
        permissions,
        homeManagedGroupId,
        managedGroup,
        managedGroups,
        roleName,
        features,
      } = action.payload;
      return {
        ...state,
        userId,
        name,
        firstName,
        lastName,
        site,
        loggedIn: true,
        authenticated: true,
        loggedOut: false,
        permissions,
        homeManagedGroupId,
        managedGroup,
        managedGroups,
        roleName,
        features,
      };
    }
    case AUTHENTICATION_SUCCEDED: {
      const {
        userId,
        name,
        firstName,
        lastName,
        site,
        permissions,
        homeManagedGroupId,
        managedGroups,
      } = action.payload;
      return {
        ...state,
        userId,
        name,
        firstName,
        lastName,
        site,
        permissions,
        managedGroups,
        homeManagedGroupId,
        authenticated: true,
      };
    }
    case FORWARD_AUTH_FLOW: {
      const { action: actionName, context } = action.payload;
      return {
        ...state,
        nextAction: {
          action: actionName,
          context,
        },
      };
    }
    case AUTH_FLOW_FORWARDING: {
      return {
        ...state,
        nextAction: undefined,
      };
    }
    case LOGOUT:
      return {
        ...initialState,
        loggedIn: false,
      };
    case LOGOUT_SUCCEEDED:
      return {
        ...initialState,
        loggedIn: false,
        loggedOut: true,
      };
    case LOAD_USER_INFO_SUCCESS: {
      const { permissions, managedGroup, managedGroups, roleName, features } = action.payload;
      return {
        ...state,
        permissions,
        managedGroup,
        managedGroups,
        roleName,
        features,
      };
    }
    case BUSINESS_SETTINGS_REQUEST_SUCCESS: {
      return {
        ...state,
        businessSettings: {
          ...action.payload,
        },
      };
    }
    case BUSINESS_SETTINGS_REQUEST_FAIL: {
      return {
        ...state,
        businessSettings: undefined,
      };
    }
    default:
      return state;
  }
};

const loginRequested = (credentials: Credentials, formId: string, redirect: string | null) => ({
  type: LOGIN_REQUESTED,
  payload: {
    credentials,
    formId,
    redirect,
  },
});

const loginSucceeded = ({
  userId,
  name,
  firstName,
  lastName,
  site,
  permissions,
  homeManagedGroupId,
  managedGroup,
  managedGroups,
  roleName,
  features,
}: LoginSucceededPayload) => ({
  type: LOGIN_SUCCEEDED,
  payload: {
    userId,
    name,
    lastName,
    firstName,
    site,
    permissions,
    homeManagedGroupId,
    managedGroup,
    managedGroups,
    roleName,
    features,
  },
});

const loginFailed = (error: unknown) => ({
  type: LOGIN_FAILED,
  error,
});

const authenticationSucceeded = (
  userId?: AuthenticationSucceededPayload['userId'],
  name?: AuthenticationSucceededPayload['name'],
  firstName?: AuthenticationSucceededPayload['firstName'],
  lastName?: AuthenticationSucceededPayload['lastName'],
  site?: AuthenticationSucceededPayload['site'],
  permissions?: AuthenticationSucceededPayload['permissions'],
  homeManagedGroupId?: AuthenticationSucceededPayload['homeManagedGroupId'],
  managedGroups?: AuthenticationSucceededPayload['managedGroups']
) => ({
  type: AUTHENTICATION_SUCCEDED,
  payload: {
    userId,
    name,
    firstName,
    lastName,
    site,
    permissions,
    homeManagedGroupId,
    managedGroups,
  },
});

const forwardAuthenticationFlow = (
  action: NextAction['action'],
  context?: NextAction['context']
) => ({
  type: FORWARD_AUTH_FLOW,
  payload: {
    action,
    context,
  },
});

const logout = () => ({
  type: LOGOUT,
});

const logoutSucceeded = () => ({
  type: LOGOUT_SUCCEEDED,
});

interface ChangePasswordCredentials {
  newPassword: string;
  oldPassword: string;
}

const passwordChangeRequested = (username: string, credentials: ChangePasswordCredentials) => ({
  type: PASSWORD_CHANGE_REQUESTED,
  payload: { username, credentials },
});

const passwordChangeSucceeded = (credentials: Credentials) => ({
  type: PASSWORD_CHANGE_SUCCEEDED,
  payload: {
    credentials,
  },
});

const loadUserInfoSuccess = ({
  permissions,
  managedGroup,
  managedGroups,
  roleName,
  features,
}: LoadUserInfoSuccessPayload) => ({
  type: LOAD_USER_INFO_SUCCESS,
  payload: {
    permissions,
    managedGroup,
    managedGroups,
    roleName,
    features,
  },
});

const authFlowForwarding = () => ({
  type: AUTH_FLOW_FORWARDING,
});

const businessSettingsRequested = () => ({
  type: BUSINESS_SETTINGS_REQUESTED,
});

const businessSettingsRequestSuccess = (payload: any) => ({
  type: BUSINESS_SETTINGS_REQUEST_SUCCESS,
  payload,
});

const businessSettingsRequestFail = () => ({
  type: BUSINESS_SETTINGS_REQUEST_FAIL,
});

export const actions = {
  businessSettingsRequested,
  businessSettingsRequestSuccess,
  businessSettingsRequestFail,
  loginRequested,
  loginSucceeded,
  loginFailed,
  authenticationSucceeded,
  forwardAuthenticationFlow,
  logout,
  logoutSucceeded,
  passwordChangeRequested,
  passwordChangeSucceeded,
  loadUserInfoSuccess,
  authFlowForwarding,
};
