import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'CompensationRequestConfirmation.header',
    defaultMessage: 'Confirm customer compensation',
  },
  previousCompensationWarning: {
    id: 'CompensationRequestConfirmation.previousCompensationWarning',
    defaultMessage:
      'There is a previous compensation request on {requestedDate} for {amount, number, withDecimalPlaces} {currencyCode}. Are you sure you want to process a new compensation?',
  },
  previousRefundWarning: {
    id: 'CompensationRequestConfirmation.previousRefundWarning',
    defaultMessage:
      'There is a previous refund request for this order. Please check order history for details. Are you sure you want to process compensation for {amount, number, withDecimalPlaces} {currencyCode}?',
  },
  amountWarning: {
    id: 'CompensationRequestConfirmation.amountWarning',
    defaultMessage:
      'Are you sure you want to give compensation for {amount, number, withDecimalPlaces} {currencyCode}? The total order value is {orderAmount, number, withDecimalPlaces} {currencyCode}.',
  },
  approveWarning: {
    id: 'CompensationRequestConfirmation.approveWarning',
    defaultMessage:
      'Are you sure you wish to reimburse {amount, number, withDecimalPlaces} {currencyCode} to the customer? This may take up to 24 hours to be reflected in the customer’s account.',
  },
  proceed: {
    id: 'CompensationRequestConfirmation.proceed',
    defaultMessage: 'Confirm',
  },
  cancel: {
    id: 'CompensationRequestConfirmation.cancel',
    defaultMessage: 'Cancel',
  },
});
