import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency/FormattedCurrency';
import priceShape from '@contactcentre-web/utils/shape/price';

import styles from './styles';

const ExtraRow = ({ message, price, inventoryPrice }) => (
  <tr>
    <td className={css(styles.itemCell)}>
      <div className={css(styles.container)}>
        <div className={css(styles.message)}>
          <FormattedMessage {...message} />
        </div>
      </div>
    </td>
    <td className={css(styles.productPriceCell)}>
      {!!(inventoryPrice && inventoryPrice.amount) && <FormattedCurrency {...inventoryPrice} />}
    </td>
    <td className={css(styles.pricePaidCell)}>
      {!!(price && price.amount) && <FormattedCurrency {...price} />}
    </td>
  </tr>
);

ExtraRow.propTypes = {
  message: PropTypes.object.isRequired,
  price: priceShape,
  inventoryPrice: priceShape,
};

export default ExtraRow;
