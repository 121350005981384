import { defineMessages } from 'react-intl';

export default defineMessages({
  cardTitle: {
    id: 'BookingHistory.cardTitle',
    defaultMessage: 'Booking history',
  },
  pnr: {
    id: 'BookingHistory.pnr',
    defaultMessage: 'booking id',
  },
  action: {
    id: 'BookingHistory.action',
    defaultMessage: 'action',
  },
  agent: {
    id: 'BookingHistory.agent',
    defaultMessage: 'agent',
  },
  timestamp: {
    id: 'BookingHistory.timestamp',
    defaultMessage: 'date created',
  },
  status: {
    id: 'BookingHistory.status',
    defaultMessage: 'status',
  },
  createdAt: {
    id: 'BookingHistory.createdAt',
    defaultMessage: '{day} at {time}',
  },
  statusActive: {
    id: 'BookingHistory.statusActive',
    defaultMessage: 'active',
  },
  statusInactive: {
    id: 'BookingHistory.statusInactive',
    defaultMessage: 'inactive',
  },
  actionTypes_refresh: {
    id: 'BookingHistory.actionTypes_refresh',
    defaultMessage: 'Refresh',
  },
  actionTypes_partialRefund: {
    id: 'BookingHistory.actionTypes_partialRefund',
    defaultMessage: 'Partial refund',
  },
  actionTypes_booking: {
    id: 'BookingHistory.actionTypes_booking',
    defaultMessage: 'Booking',
  },
  actionTypes_collectionRestrictionRemoved: {
    id: 'BookingHistory.actionTypes_collectionRestrictionRemoved',
    defaultMessage: 'Changed to Anycard',
  },
  actionTypes_unknown: {
    id: 'BookingHistory.actionTypes_unknown',
    defaultMessage: 'Unknown',
  },
  requestedBy: {
    id: 'BookingHistory.requestedBy',
    defaultMessage:
      '{requestorType, select, Automatic {N/A} Customer {Customer via self serve} Agent {{agent}}}',
  },
});
