import { StyleSheet } from 'aphrodite/no-important';
import {
  typography,
  spacers,
  fonts,
  borderRadius,
} from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    border: 'solid 1px #B9D3FC',
    borderRadius: borderRadius.dp_r4,
    background: '#F4F7FF',
    padding: spacers.dp_s32,
    marginBottom: spacers.dp_s32,
  },
  title: {
    fontFamily: typography.dp_title3_font_family,
    fontSize: typography.dp_title3_font_size,
    fontWeight: fonts.dp_font_weight_bold,
    lineHeight: typography.dp_title3_line_height,
    letterSpacing: typography.dp_title3_letter_spacing,
    paddingBottom: spacers.dp_s16,
  },
  body: {
    display: 'flex',
  },
  selectContainer: {
    width: '440px',
    marginRight: spacers.dp_s48,
  },
  info: {
    border: 'solid 1px #B9D3FC',
    marginTop: spacers.dp_s24,
  },
  button: {
    marginTop: spacers.dp_s8,
  },
  alreadyRefreshedBox: {
    width: undefined,
    border: 'solid 1px #B9D3FC',
    marginTop: spacers.dp_s24,
  },
});
