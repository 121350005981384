import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { css } from 'aphrodite/no-important';

import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency/FormattedCurrency';
import Badge from '@contactcentre-web/common/Badge/Badge';
import Loader from '@contactcentre-web/common/Loader';
import formatDuration from '@contactcentre-web/utils/duration';
import priceShape from '@contactcentre-web/utils/shape/price';

import CustomerBookingJourneyLeg from '../CustomerBookingJourneyLeg/CustomerBookingJourneyLeg';

import messages from './messages';
import styles from './styles';

const CustomerBookingJourney = ({
  changes,
  destination,
  deliveryRecipient,
  duration,
  legs,
  origin,
  transactionTotalPrice,
  inventoryTotalPrice,
  journeyNum,
  journeyCount,
  seatingPreferences,
  validityPeriod,
  convertedOrderReference,
  delayRepayStatus,
  isDelayRepayClaimLoading,
  vendor,
  bikeReservation,
  customerOrderTravellers,
  businessSettings,
}) => (
  <section className={css(styles.customerBookingJourney)}>
    <div className={css(styles.row, styles.rowBooking)}>
      <div>
        <div className={css(styles.greyLabel)}>
          <FormattedMessage {...messages.journeyCount} values={{ journeyNum, journeyCount }} />
        </div>
        <div className={css(styles.directions)}>
          <h2 className={css(styles.title2)}>
            <FormattedMessage {...messages.directionOfJourney} values={{ origin, destination }} />
          </h2>
        </div>

        {!validityPeriod && (
          <div className={css(styles.journeyInfo)} data-testid="journeyInfo">
            <span className={css(styles.duration)}>{formatDuration(duration)}</span>|
            <span className={css(styles.changes)}>
              <FormattedMessage {...messages.changes} values={{ changes }} />
            </span>
          </div>
        )}
      </div>
      {isDelayRepayClaimLoading ? (
        <div>
          <span className={css([styles.greyLabel, styles.delayRepayLabel])}>
            <FormattedMessage {...messages.delayRepayStatus} />
          </span>
          <Loader size={20} borderWidth={3} />
        </div>
      ) : (
        delayRepayStatus && (
          <div>
            <span className={css([styles.greyLabel, styles.delayRepayLabel])}>
              <FormattedMessage {...messages.delayRepayStatus} />
            </span>
            <Badge styleSheet={styles.badge} testId="delay-repay-status">
              <FormattedMessage
                {...(messages[`delayRepayStatus_${delayRepayStatus.toLowerCase()}`] ||
                  messages.delayRepayStatus_unknown)}
                values={{
                  status: delayRepayStatus,
                }}
              />
            </Badge>
          </div>
        )
      )}
      {transactionTotalPrice && inventoryTotalPrice && (
        <div className={css(styles.price)}>
          <div className={css(styles.title2)}>
            <FormattedCurrency {...transactionTotalPrice} />
          </div>
          <div className={css(styles.inventoryPrice)}>
            {(convertedOrderReference ||
              transactionTotalPrice.currencyCode !== inventoryTotalPrice.currencyCode) && (
              <span>
                (<FormattedCurrency {...inventoryTotalPrice} />)
              </span>
            )}
          </div>
        </div>
      )}
    </div>
    {legs.map((leg, index, { length }) => (
      <CustomerBookingJourneyLeg
        key={index}
        {...leg}
        deliveryRecipient={deliveryRecipient}
        seatingPreferences={seatingPreferences}
        legNum={index + 1}
        legCount={length}
        vendor={vendor}
        bikeReservation={bikeReservation}
        customerOrderTravellers={customerOrderTravellers}
        businessSettings={businessSettings}
      />
    ))}
    {validityPeriod && (
      <div className={css(styles.validityPeriod)}>
        {dayjs(validityPeriod).isValid() ? (
          <FormattedMessage
            {...messages.returnIsValidUntil}
            values={{
              period: (
                <FormattedDate
                  value={validityPeriod}
                  weekday="short"
                  day="2-digit"
                  month="short"
                  year="numeric"
                />
              ),
            }}
          />
        ) : (
          <FormattedMessage
            {...messages.returnIsValidFor}
            values={{
              period: validityPeriod,
            }}
          />
        )}
      </div>
    )}
  </section>
);

CustomerBookingJourney.propTypes = {
  changes: PropTypes.number.isRequired,
  deliveryRecipient: PropTypes.object,
  destination: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  legs: PropTypes.arrayOf(PropTypes.object).isRequired,
  origin: PropTypes.string.isRequired,
  transactionTotalPrice: priceShape,
  inventoryTotalPrice: priceShape,
  journeyNum: PropTypes.number.isRequired,
  journeyCount: PropTypes.number.isRequired,
  seatingPreferences: PropTypes.string,
  validityPeriod: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  convertedOrderReference: PropTypes.string,
  delayRepayStatus: PropTypes.string,
  isDelayRepayClaimLoading: PropTypes.bool,
  vendor: PropTypes.string,
  bikeReservation: PropTypes.object,
  customerOrderTravellers: PropTypes.shape({
    tenantId: PropTypes.string,
    itineraryId: PropTypes.string,
    passengerDetails: PropTypes.array,
  }),
  businessSettings: PropTypes.shape({
    persistPassengerDetails: PropTypes.bool,
  }),
};

export default CustomerBookingJourney;
