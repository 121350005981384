import { StyleSheet } from 'aphrodite/no-important';

import * as colors from '@contactcentre-web/styles/colors';

const interactionState = {
  backgroundColor: colors.gray.alpha8,
  color: colors.info.dark,
  textDecoration: 'none',
  outline: 'none',
};

export default StyleSheet.create({
  item: {
    color: colors.info.dark,
    cursor: 'pointer',
    display: 'block',
    textDecoration: 'none',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    ':focus': {
      ...interactionState,
    },
    ':hover': {
      ...interactionState,
    },
    ':active': {
      ...interactionState,
      backgroundColor: colors.gray.alpha12,
    },
    ':nth-child(1n) + ul > li': {
      padding: 0,
    },
  },
});
