import React from 'react';
import { StatusMessage } from '@trainline/depot-web';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import { useErrorMapping } from '@contactcentre-web/hooks/api/useErrorMapping';
import { FetchError } from '@contactcentre-web/utils/error';
import { ClaimStatus } from '@contactcentre-web/hooks/api/useManualEntryConsole';

import messages from './messages';
import styles from './styles';

interface Props {
  error: FetchError;
  claimStatus: ClaimStatus;
}

const ManualEntryConsoleStatusChangeFailed = ({ error, claimStatus }: Props) => {
  const errorMessage = useErrorMapping(error, messages, claimStatus);
  return (
    <div className={css(styles.message)}>
      <StatusMessage status="negative">
        <FormattedMessage {...errorMessage} />
      </StatusMessage>
    </div>
  );
};

export default ManualEntryConsoleStatusChangeFailed;
