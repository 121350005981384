import React from 'react';
import moment from 'moment';
import { FormattedTime } from 'react-intl';

interface Props {
  value: Date | string;
  hour?: '2-digit' | 'numeric';
  minute?: '2-digit' | 'numeric';
}

const FormattedTimeInTz = ({ value, ...otherProps }: Props) => {
  const m = moment.parseZone(value);
  const valueNoTz = m.add(m.utcOffset(), 'm').utc().toDate();

  return <FormattedTime {...otherProps} value={valueNoTz} timeZone="UTC" />;
};

export default FormattedTimeInTz;
