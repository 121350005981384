import React, { Dispatch, FunctionComponent, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { Heading } from '@trainline/depot-web';

import Button from '@contactcentre-web/common/Button';
import PageContainer from '@contactcentre-web/common/PageContainer';
import Link from '@contactcentre-web/common/Link';
import type State from '@contactcentre-web/redux-common/types/State';
import type Action from '@contactcentre-web/redux-common/types/Action';

import { actions, selectors } from '../../module';

import messages from './messages';
import styles from './styles';

interface StateProps {
  isResetPending: boolean;
  userId: string;
  isResetSuccess: boolean;
}

interface DispatchProps {
  resetConfirm: (userId: string) => void;
  resetState: () => void;
}

type Props = StateProps & DispatchProps;

interface Params {
  username: string;
}

export const ResetPasswordConfirm: FunctionComponent<Props> = ({
  isResetPending,
  userId,
  resetConfirm,
  isResetSuccess,
  resetState,
}) => {
  const { username } = useParams<Params>();
  const history = useHistory();
  const resetStateRef = useRef(resetState);

  React.useEffect(() => {
    resetStateRef.current();
    return () => resetStateRef.current();
  }, [resetStateRef]);

  React.useEffect(() => {
    if (isResetSuccess) {
      history.push(`/edit_user/${username}/reset_password/success`);
    }
  }, [isResetSuccess]);

  return (
    <PageContainer>
      <div className={css(styles.container)}>
        <div className={css(styles.innerContainer)}>
          <div className={css(styles.header)}>
            <Heading typeStyle="title1" as="h1" color="base">
              <FormattedMessage {...messages.confirmPasswordReset} />
            </Heading>
          </div>
          <p className={css(styles.resetThePasswordFor)}>
            <FormattedMessage
              {...messages.resetThePasswordFor}
              values={{
                username: <span className={css(styles.username)}>{username}</span>,
              }}
            />
          </p>
          <Button
            variant="primary"
            size="xlarge"
            fullWidth
            testId="reset-password-confirm-button"
            loading={isResetPending}
            onClick={() => resetConfirm(userId)}
          >
            <FormattedMessage {...messages.resetPassword} />
          </Button>
        </div>
        <div className={css(styles.cancel)}>
          <Link linkType="internal" to={`/edit_user/${username}`}>
            <FormattedMessage {...messages.cancel} />
          </Link>
        </div>
      </div>
    </PageContainer>
  );
};

const mapStateToProps = (state: State) => ({
  userId: selectors.getUserId(state),
  isResetPending: selectors.isResetPasswordPending(state),
  isResetSuccess: selectors.isResetPasswordSuccess(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  resetState: () => dispatch(actions.resetState()),
  resetConfirm: (userId: string) => dispatch(actions.resetPasswordAttempt(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordConfirm);
