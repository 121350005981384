import { defineMessages } from 'react-intl';

export default defineMessages({
  processRefund: {
    id: 'SameDayVoid.processRefund',
    defaultMessage: 'Process refund(s)',
  },
  loadFailed: {
    id: 'SameDayVoid.loadFailed',
    defaultMessage: 'Unable to load order information. Please reload the page.',
  },
  notVoidable: {
    id: 'SameDayVoid.notVoidable',
    defaultMessage: 'This order is not voidable.',
  },
  infoRefundSubmittedMessage: {
    id: 'SameDayVoid.infoRefundSubmittedMessage',
    defaultMessage:
      'The SDV refund has been submitted. For additional details, <link>go to booking history</link>.',
  },
  post: {
    id: 'SameDayVoid.post',
    defaultMessage:
      'Please advise the customer their ticket(s) will need posted back to Trainline Refunds, PO Box 23972, Edinburgh, EH3 5DA and from receiving the ticket(s) a refund will return back to the original payment method within 28 working days.',
  },
  collectpost: {
    id: 'SameDayVoid.collectpost',
    defaultMessage:
      'Please advise the customer their ticket(s) will need posted back to Trainline Refunds, PO Box 23972, Edinburgh, EH3 5DA and from receiving the ticket(s) a refund will return back to the original payment method within 28 working days.',
  },
  automatic: {
    id: 'SameDayVoid.automatic',
    defaultMessage:
      'The refund has been processed automatically back into the account used to pay for the tickets.',
  },
  alreadyvoided: {
    id: 'SameDayVoid.alreadyvoided',
    defaultMessage:
      'The refund has been processed back into the account used to pay for the tickets.',
  },
  deletefromdevice: {
    id: 'SameDayVoid.deletefromdevice',
    defaultMessage:
      'Before the refund can be processed, please advise the customer that they must remove the ticket from their device or reset the barcode in Support Central.',
  },
  unknown: {
    id: 'SameDayVoid.unknown',
    defaultMessage: 'Advise customer how to return tickets.',
  },
  warning_invalidproductcombination: {
    id: 'SameDayVoid.warning_invalidiproductcombination',
    defaultMessage:
      'We’re sorry, selected passengers from your inbound and outbound must match if you’re refunding from both journeys.',
  },
  warning_paymentdetailsrefundnotsupported: {
    id: 'SameDayVoid.warning_paymentdetailsrefundnotsupported',
    defaultMessage: "We're sorry, the payment method of this order does not support a refund.",
  },
  warning_lessthanzero: {
    id: 'SameDayVoid.warning_lessthanzero',
    defaultMessage: 'The refund admin fee may be greater than the value of the refund.',
  },
  warning_partlyusedsplitticket: {
    id: 'SameDayVoid.warning_partlyusedsplitticket',
    defaultMessage:
      'Split Ticket refund is not possible because customers have scanned part/all of the ticket for this journey',
  },
  warning_refundrejectedbyfraudprovider: {
    id: 'SameDayVoid.warning_refundrejectedbyfraudprovider',
    defaultMessage:
      '<b>Reference code RR1.</b>{br}Please override this block and continue to process the refund. If the customer is requesting help to self serve after-sales for future bookings then please raise a request to the F & P Revenue Protection Group queue in Freshdesk for further handling.',
  },
  warning_unspecified: {
    id: 'SameDayVoid.warning_unspecified',
    defaultMessage: 'We’re sorry, we are unable to refund your booking.',
  },
  noPermissions: {
    id: 'SameDayVoid.NoPermissions',
    defaultMessage: "You don't have permission to request a refund",
  },
  ticketsToRefund: {
    id: 'SameDayVoid.ticketsToRefund',
    defaultMessage: 'Tickets to refund',
  },
});
