import { StyleSheet } from 'aphrodite/no-important';
import {
  spacers,
  borderRadius,
  palettes,
  typography,
} from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';
import { zIndex } from '@contactcentre-web/styles/zIndex';

export const inputStyle = {
  cursor: 'pointer',
  position: 'relative',
  appearance: 'none',
  backgroundColor: palettes.dp_app_backdrop_base,
  color: palettes.dp_app_text_base,
  border: `1px solid ${palettes.dp_app_border_dark}`,
  borderRadius: borderRadius.dp_r4,
  padding: spacers.dp_s8,
  textOverflow: 'ellipsis',

  ':focus': {
    outline: '0 none',
  },
  ':disabled': {
    backgroundColor: palettes.dp_app_backdrop_dark,
  },
};

export const selectStyle = {
  select: {
    ...inputStyle,
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    ':disabled': {
      color: colors.text.muted,
      pointerEvent: 'none',
      cursor: 'auto',
    },
  },
  selectOpened: {
    borderBottom: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  selectOptions: {
    position: 'absolute',
    zIndex: zIndex.mid,
    minHeight: '100px',
    width: '100%',
    backgroundColor: colors.white,
    color: colors.gray.alpha80,
    border: `solid 1px ${colors.gray.alpha30}`,
    borderTopColor: colors.gray.alpha8,
    borderRadius: borderRadius.dp_r4,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    textOverflow: 'ellipsis',
    listStyleType: 'none',
    margin: '0',
    maxHeight: '60vh',
    overflow: 'scroll',

    ':disabled': {
      backgroundColor: colors.gray.alpha8,
    },
  },
  selectOption: {
    padding: spacers.dp_s8,
    cursor: 'pointer',

    ':focus': {
      backgroundColor: colors.brand.light,
    },
    ':hover': {
      backgroundColor: colors.brand.light,
    },
    display: 'flex',
    alignItems: 'center',
  },
  selectOptionDisabled: {
    pointerEvents: 'none',
    color: colors.gray.alpha8,
  },
  selectIcon: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: spacers.dp_s4,
  },
};

export default StyleSheet.create({
  ...selectStyle,
  label: {
    marginBottom: spacers.dp_s16,
    marginTop: `calc(${spacers.dp_s8} + 1px)` /* keep consistency with TextField */,
    display: 'block',
  },
  multiSelect: {
    position: 'relative',
  },
  selectOptions: {
    ...selectStyle.selectOptions,
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  errorContainer: {
    height: '24px',
    fontSize: typography.dp_mini_font_size_m,
    lineHeight: typography.dp_body_line_height,
    color: colors.negative.base,
    marginBottom: spacers.dp_s4,
  },
});
