import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { StatusMessage } from '@trainline/depot-web';

import PageContainer from '@contactcentre-web/common/PageContainer/PageContainer';
import * as productsSelectors from '@contactcentre-web/redux-common/selectors/products';
import ProductType from '@contactcentre-web/redux-common/types/ProductType';
import Link from '@contactcentre-web/common/Link';
import { iframePostMessageGenerator } from '@contactcentre-web/utils/iframePostMessage';
import { getCurrentManagedGroupId } from '@contactcentre-web/authentication/redux/selectors';

import TransactionSummary from '../TransactionSummary';
import CustomerBooking from '../CustomerBooking/CustomerBooking';
import CustomerRailcard from '../CustomerRailcard/CustomerRailcard';
import CustomerTravelcard from '../CustomerTravelcard';
import FlexiSeasonAlert from '../FlexiSeasonAlert';
import SeasonBooking from '../SeasonBooking';
import ChangeOfJourneyWarning from '../ChangeOfJourneyWarning';
import { selectors, actions } from '../../module';

import styles from './styles';
import messages from './messages';

export const OrderDetails = ({
  products,
  order,
  transactionSummary,
  isDelayRepayClaimLoading,
  cancelRailcard,
  isCancellingRailcard,
  cancelRailcardStatus,
  hasAmendedSeatReservation,
  refreshOrder,
  resetSeasonStatus,
  cancelSeasonStatus,
  hasAlternativeJourneys,
  fetchTravellers,
  customerOrderTravellers,
  itineraryId,
  businessSettings,
}) => {
  const { orderId, customerId } = useParams();
  const managedGroupId = useSelector(getCurrentManagedGroupId);
  const location = useLocation();
  const isNewOrderDefault = new URLSearchParams(location.search).get('newOrder') || false;

  const hasBookingChanges = (bookings) => bookings.some((b) => !!b.changedOrderReference);

  const getReferenceNumber = (travelBookings) => {
    if (travelBookings.length === 0) return '';
    const [travelBooking] = travelBookings;
    return travelBooking.changedOrderReference;
  };

  useEffect(() => {
    if (isNewOrderDefault) {
      iframePostMessageGenerator({
        orderReference: orderId,
        action: 'ADD',
        tenantId: managedGroupId,
      });
    }
    if (businessSettings?.persistPassengerDetails) {
      fetchTravellers({ itineraryId, orderId });
    }
  }, [isNewOrderDefault]);

  return (
    <>
      <PageContainer>
        {order.hasFeeFreeCoJ && <ChangeOfJourneyWarning message={messages.feeFreeCoj} />}
        {order.hasFeeFreeCojDueToCovidRestrictions && (
          <ChangeOfJourneyWarning message={messages.feeFreeCojDueToCovidRestrictions} />
        )}
        {order.status === 'Failed' && hasBookingChanges(order.travelBookings) && (
          <StatusMessage status="negative">
            <FormattedMessage
              {...messages.changeNotSuccessful}
              values={{
                link: (msg) => (
                  <Link
                    linkType="internal"
                    to={`/customers/${customerId}/bookings/${getReferenceNumber(
                      order.travelBookings
                    )}`}
                  >
                    {msg}
                  </Link>
                ),
              }}
              testId="coj-alert-banner"
            />
          </StatusMessage>
        )}
        <FlexiSeasonAlert
          cancelSeasonStatus={cancelSeasonStatus}
          resetSeasonStatus={resetSeasonStatus}
          customerId={customerId}
          orderId={orderId}
        />
        <div className={css(styles.orderDetailsContainer)} data-test="productInfo">
          {products.map((product, index) => {
            switch (product.type) {
              case ProductType.Railcard:
                return (
                  <CustomerRailcard
                    key={index}
                    {...product}
                    onCancel={() => cancelRailcard(product)}
                    isCancelling={isCancellingRailcard}
                    cancelStatus={cancelRailcardStatus}
                  />
                );
              case ProductType.Season:
                return <SeasonBooking key={index} index={index + 1} {...product} />;
              case ProductType.TravelProduct: {
                const insurance =
                  product.insurance ||
                  products.find(
                    (p) =>
                      p.type === ProductType.Insurance &&
                      p.insuredProductIds.some((b) => b === product.id)
                  );

                return (
                  <CustomerBooking
                    key={index}
                    {...product}
                    orderReference={orderId}
                    customerId={customerId}
                    bookingNum={index + 1}
                    isDelayRepayClaimLoading={isDelayRepayClaimLoading}
                    productId={product.id}
                    insurance={insurance}
                    refreshOrder={() => refreshOrder(customerId, orderId)}
                    hasAlternativeJourneys={hasAlternativeJourneys}
                    showEditBookingPassengers={product.canAlterPassengers}
                    customerOrderTravellers={customerOrderTravellers}
                    businessSettings={businessSettings}
                  />
                );
              }
              case ProductType.Travelcard: {
                return <CustomerTravelcard key={index} {...product} />;
              }
              default:
                return null;
            }
          })}
        </div>
      </PageContainer>
      <TransactionSummary
        {...transactionSummary}
        hasAmendedSeatReservation={hasAmendedSeatReservation}
      />
    </>
  );
};

OrderDetails.propTypes = {
  products: PropTypes.array.isRequired,
  order: PropTypes.shape({
    hasFeeFreeCoJ: PropTypes.bool,
    hasFeeFreeCojDueToCovidRestrictions: PropTypes.bool,
    customerId: PropTypes.string,
    status: PropTypes.string,
    travelBookings: PropTypes.array,
  }),
  transactionSummary: PropTypes.object,
  isDelayRepayClaimLoading: PropTypes.bool,
  cancelRailcard: PropTypes.func,
  refreshOrder: PropTypes.func,
  isCancellingRailcard: PropTypes.bool,
  cancelRailcardStatus: PropTypes.string,
  hasAmendedSeatReservation: PropTypes.bool,
  resetSeasonStatus: PropTypes.string,
  cancelSeasonStatus: PropTypes.string,
  hasAlternativeJourneys: PropTypes.bool,
  fetchTravellers: PropTypes.func,
  customerOrderTravellers: PropTypes.shape({
    tenantId: PropTypes.string,
    itineraryId: PropTypes.string,
    passengerDetails: PropTypes.array,
  }),
  businessSettings: PropTypes.shape({
    persistPassengerDetails: PropTypes.bool,
  }),
  itineraryId: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  cancelRailcard: (product) => dispatch(actions.cancelRailcardAttempt(product)),
  refreshOrder: (customerId, orderId) => dispatch(actions.orderRefresh(customerId, orderId)),
  fetchTravellers: (itineraryId, orderId) =>
    dispatch(actions.fetchTravellersAttempt(itineraryId, orderId)),
});

const mapStateToProps = (state) => ({
  order: selectors.getOrder(state),
  products: productsSelectors.getProducts(state),
  transactionSummary: selectors.transactionPaymentSummary(state),
  isDelayRepayClaimLoading: selectors.getDelayRepayClaimLoadingStatus(state),
  isCancellingRailcard: selectors.isCancellingRailcard(state),
  cancelRailcardStatus: selectors.cancelRailcardStatus(state),
  hasAmendedSeatReservation: selectors.hasAmendedSeatReservation(state),
  resetSeasonStatus: selectors.getResetSeasonStatus(state),
  cancelSeasonStatus: selectors.getCancelSeasonStatus(state),
  hasAlternativeJourneys: selectors.isAlsoValidOn(state),
  customerOrderTravellers: selectors.getCustomerOrderTravellers(state),
  itineraryId: selectors.getItineraryId(state),
  businessSettings: selectors.getBusinessSettings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
