import { StyleSheet } from 'aphrodite/no-important';
import {
  typography,
  palettes,
  spacers,
  borderRadius,
  fonts,
} from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  tableRow: {
    display: 'flex',
    alignItems: 'center',
  },
  tableHeader: {
    fontFamily: typography.dp_hero_font_family,
    fontSize: typography.dp_small_font_size_m,
    backgroundColor: palettes.dp_app_info_darkest,
    color: palettes.dp_app_text_inverted,
    padding: `${spacers.dp_s16} ${spacers.dp_s32}`,
    borderRadius: `${borderRadius.dp_r4} ${borderRadius.dp_r4} 0 0`,
  },
  booking: {
    position: 'relative',
    padding: `${spacers.dp_s24} ${spacers.dp_s32}`,
    cursor: 'pointer',
    backgroundColor: palettes.dp_app_backdrop_base,
    textDecoration: 'none',
    color: palettes.dp_app_text_base,
    ':nth-child(2n)': {
      backgroundColor: palettes.dp_comp_table_row,
    },
    ':hover': {
      backgroundColor: palettes.dp_comp_table_row_hover,
      ':before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '4px',
        backgroundColor: palettes.dp_comp_table_row_active_border,
        height: '100%',
      },
    },
  },
  tableColumn: {
    flex: '1 0 0%',
    display: 'flex',
    fontWeight: fonts.dp_font_weight_bold,
    flexDirection: 'column',
    paddingRight: spacers.dp_s16,
  },
  sortableColumn: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  productColumn: {
    flexGrow: 2.5,
    fontWeight: fonts.dp_font_weight_regular,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    flexDirection: 'row',
  },
  productTitle: {
    fontWeight: fonts.dp_font_weight_bold,
  },
  to: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingLeft: spacers.dp_s4,
  },
  from: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: spacers.dp_s4,
  },
  atTime: {
    fontWeight: fonts.dp_font_weight_regular,
  },
  errorMessage: {
    fontSize: typography.dp_small_font_size_m,
    color: palettes.dp_app_text_negative,
  },
  arrow: {
    flexShrink: 0,
  },
});
