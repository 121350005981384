import React, { Dispatch, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { css } from 'aphrodite/no-important';

import Button from '@contactcentre-web/common/Button';
import IconNotes from '@contactcentre-web/common/Icons/IconNotes';
import { getCustomerLoaded } from '@contactcentre-web/redux-common/selectors/customer';
import type Action from '@contactcentre-web/redux-common/types/Action';
import type State from '@contactcentre-web/redux-common/types/State';

import { actions as sidebarActions, selectors as sidebarSelectors } from '../../../../module';
import { selectors as notesSelectors } from '../../module';

import styles from './styles';

interface StateProps {
  visible: boolean;
  notesCount: number;
  loading: boolean;
}

interface DispatchProps {
  openNotes: () => void;
}

type Props = StateProps & DispatchProps;

export const CustomerNotesButton: FunctionComponent<Props> = ({
  visible,
  notesCount,
  openNotes,
  loading,
}) => (
  <Button
    variant="primary"
    size="small"
    styleSheet={[styles.customerNotesButton, visible && styles.visible]}
    testId="open-notes"
    onClick={openNotes}
  >
    <IconNotes className={css(styles.icon)} />
    {!loading && notesCount !== 0 && (
      <div className={css(styles.indicator)} data-testid="notesIndicatorCount">
        {notesCount}
      </div>
    )}
  </Button>
);

interface Params {
  customerId: string;
  orderId: string;
}

const mapStateToProps = (state: State, { match: { params } }: RouteComponentProps<Params>) => {
  const { customer, customerNotes } = state;
  return {
    visible: getCustomerLoaded(customer) && !sidebarSelectors.isOpen(state),
    notesCount: notesSelectors.getNotesCount(customerNotes.notes, params),
    loading: notesSelectors.getLoading(customerNotes),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  openNotes: () => dispatch(sidebarActions.openSidebar()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerNotesButton));
