import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';
import { IconClose, StatusMessage } from '@trainline/depot-web';

import Button from '@contactcentre-web/common/Button';
import type State from '@contactcentre-web/redux-common/types/State';
import type Action from '@contactcentre-web/redux-common/types/Action';

import ResendEmailFormContainer from './components/ResendEmailForm/ResendEmailForm';
import ResendEmailSuccessContainer from './components/ResendEmailSuccess/ResendEmailSuccess';
import ResendEmailLoader from './components/ResendEmailLoader/ResendEmailLoader';
import ResendEmailEligibilityFailure from './components/ResendEmailEligibilityFailure/ResendEmailEligibilityFailure';
import { actions, selectors } from './module';
import styles from './styles';

interface DispatchProps {
  checkEligibility: () => void;
  onClose: () => void;
  reset: () => void;
}

interface StateProps {
  isHidden: boolean;
  isSuccess: boolean;
  isCheckPending: boolean;
  isOrderConfirmationEligible: boolean;
}

export type Props = StateProps & DispatchProps;

export const ResendEmail = ({
  reset,
  isSuccess,
  isCheckPending,
  isOrderConfirmationEligible,
  checkEligibility,
  onClose,
}: Props) => {
  const resetRef = React.useRef(reset);
  const checkEligibilityRef = React.useRef(checkEligibility);
  React.useEffect(() => {
    resetRef.current();

    return () => resetRef.current();
  }, [resetRef]);
  React.useEffect(() => {
    checkEligibilityRef.current();
  }, [checkEligibilityRef]);

  if (isCheckPending) {
    return (
      <div className={css(styles.container)}>
        <ResendEmailLoader />
      </div>
    );
  }

  if (isOrderConfirmationEligible && !isSuccess) {
    return (
      <div className={css(styles.containerForm)}>
        <ResendEmailFormContainer />
        <Button
          variant="ghost"
          testId="ResendEmailClose"
          styleSheet={styles.closeButtonForm}
          onClick={onClose}
        >
          <IconClose />
        </Button>
      </div>
    );
  }

  const showSuccessMessage = isOrderConfirmationEligible && isSuccess;
  return (
    <div className={css(styles.container)}>
      <StatusMessage status={showSuccessMessage ? 'positive' : 'negative'}>
        <div className={css(styles.wrapper)}>
          {showSuccessMessage ? <ResendEmailSuccessContainer /> : <ResendEmailEligibilityFailure />}
          <Button
            variant="ghost"
            testId="ResendEmailClose"
            styleSheet={styles.closeButton}
            onClick={onClose}
          >
            <IconClose />
          </Button>
        </div>
      </StatusMessage>
    </div>
  );
};

ResendEmail.propTypes = {
  isSuccess: PropTypes.bool.isRequired,
  isCheckPending: PropTypes.bool.isRequired,
  checkEligibility: PropTypes.func.isRequired,
  isOrderConfirmationEligible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

const mapStateToProps = (state: State) => ({
  isHidden: selectors.isHidden(state.resendEmail),
  isSuccess: selectors.isSuccess(state.resendEmail),
  isCheckPending: selectors.isCheckPending(state.resendEmail),
  isOrderConfirmationEligible: selectors.isOrderConfirmationEligible(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  reset: () => dispatch(actions.reset()),
  hide: () => {
    dispatch(actions.hide());
  },
  checkEligibility: () => {
    dispatch(actions.checkPending());
  },
});

const connectedResendEmail = connect(mapStateToProps, mapDispatchToProps)(ResendEmail);
connectedResendEmail.displayName = 'ResendEmail';

export default connectedResendEmail;
