import { defineMessages } from 'react-intl';

export default defineMessages({
  selectReason: {
    id: 'OrderNotes.CrmReasonField.selectReason',
    defaultMessage: 'Select a Reason',
  },
  reasonRequired: {
    id: 'OrderNotes.CrmReasonField.reasonRequired',
    defaultMessage: 'Please provide a contact reason',
  },
});
