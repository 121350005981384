import { defineMessages } from 'react-intl';

export default defineMessages({
  bookingNotConverted: {
    id: 'FulfilmentConverterFail.bookingNotConverted',
    defaultMessage: 'Booking has not been converted',
  },
  travelProductNotFound: {
    id: 'FulfilmentConverterFail.travelProductNotFound',
    defaultMessage: 'Travel Product could not be found.',
  },
  noEligibilityFound: {
    id: 'FulfilmentConverterFail.noEligibilityFound',
    defaultMessage: 'There is no eligible conversions available for this order.',
  },
});
