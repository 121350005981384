import { defineMessages } from 'react-intl';

export default defineMessages({
  fulfilmentConverterProcessed: {
    id: 'FulfilmentConverterHistory.fulfilmentConverterProcessed',
    defaultMessage: 'Fulfilment Converter',
  },
  products: {
    id: 'FulfilmentConverterHistory.products',
    defaultMessage: 'Converted Products',
  },
  requestedBy: {
    id: 'FulfilmentConverterHistory.requestedBy',
    defaultMessage: 'Requested by: {agent}',
  },
  origin: {
    id: 'FulfilmentConverterHistory.origin',
    defaultMessage: 'Origin',
  },
  destination: {
    id: 'FulfilmentConverterHistory.destination',
    defaultMessage: 'Destination',
  },
  departure: {
    id: 'FulfilmentConverterHistory.departure',
    defaultMessage: 'departure',
  },
  requested: {
    id: 'FulfilmentConverterHistory.requested',
    defaultMessage: 'Requested',
  },
  pending: {
    id: 'FulfilmentConverterHistory.pending',
    defaultMessage: 'Pending',
  },
  approved: {
    id: 'FulfilmentConverterHistory.approved',
    defaultMessage: 'Converted',
  },
  rejected: {
    id: 'FulfilmentConverterHistory.rejected',
    defaultMessage: 'Rejected',
  },
  original: {
    id: 'FulfilmentConverterHistory.original',
    defaultMessage: 'Original delivery method',
  },
  new: {
    id: 'FulfilmentConverterHistory.new',
    defaultMessage: 'New delivery method',
  },
});
