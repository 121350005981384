import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmationEmailSentSuccessfully: {
    id: 'ResendEmailFailure.confirmationEmailSentSuccessfully',
    defaultMessage: 'Order confirmation email sent successfully.',
  },
  fulfilmentEmailsSentSuccessfully: {
    id: 'ResendEmailFailure.fulfilmentEmailsSentSuccessfully',
    defaultMessage: 'Booking fulfilment email(s) sent successfullfy.',
  },
  errors_bookingConfirmationResendFailed_wrongOrderStatus: {
    id: 'ResendEmailFailure.bookingConfirmationResendFailed_wrongOrderStatus',
    defaultMessage: 'Order confirmation email cannot be resent as the Order is in Failed status.',
  },
  errors_bookingConfirmationResendFailed_notEligible: {
    id: 'ResendEmailFailure.errors_bookingConfirmationResendFailed_notEligible',
    defaultMessage: 'This order is not eligible for resending the order confirmation email.',
  },
  errors_bookingConfirmationResendFailed_generic: {
    id: 'ResendEmailFailure.errors_bookingConfirmationResendFailed_generic',
    defaultMessage: 'There was an error resending the order confirmation email, please try again.',
  },
  errors_fulfilmentResendFailed: {
    id: 'ResendEmailFailure.errors_fulfilmentResendFailed',
    defaultMessage: 'The booking fulfilment email has not been sent for the following bookings:',
  },
  bookingText: {
    id: 'ResendEmailFailure.bookingText',
    defaultMessage: '{origin} to {destination}{isReturn, select, true {\u2000RTN}} - {travelDate}',
  },
});
