import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';
import { path } from 'ramda';
import { FormSection } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { StatusMessage } from '@trainline/depot-web';

import { getCurrentManagedGroupId } from '@contactcentre-web/authentication/redux/selectors';
import Loader from '@contactcentre-web/common/Loader';
import priceShape from '@contactcentre-web/utils/shape/price';
import ProductType from '@contactcentre-web/redux-common/types/ProductType';
import DateField from '@contactcentre-web/common/DateField';
import EntanglementWarning, {
  filterEntanglementWarnings,
} from '@contactcentre-web/common/EntanglementWarning';
import Link from '@contactcentre-web/common/Link';

import RecalculateButton from '../RecalculateButton';
import PaymentTotal from '../PaymentTotal';
import BookingSummaryHeader from '../BookingSummaryHeader';
import JourneyDetails from '../JourneyDetails';
import ProductDetails from '../ProductDetails';
import RefundRequestedAlert from '../RefundRequestedAlert';
import RefundReason from '../RefundReason';
import RefundFee from '../RefundFee/RefundFee';
import RefundFees from '../RefundFees';
import AfterSaleSystem from '../AfterSaleSystem';
import ProductTotals from '../ProductTotals';
import { RefundableUnit } from '../RefundableUnit';
import { LocalAreaJourneyAggregator } from '../LocalAreaJourneyAggregator';

import styles from './styles';
import messages from './messages';

export class Booking extends React.Component {
  constructor() {
    super();

    this.container = null;
  }

  render() {
    const {
      number,
      booking,
      productId,
      orderReference,
      orderHistoryRoute,
      ticketDetails,
      pending,
      success,
      error,
      selectionUpdated,
      refundReasons,
      refundReasonDisabled,
      isRefundable,
      ticketReturnMode = path(['refund', 'ticketReturnMode', 'name'], booking),
      ticketReturnAddress = path(['refund', 'ticketReturnMode', 'returnAddress'], booking),
      refundLessThanZeroFee,
      onRecalculate,
      canUpdateQuote,
      hasLoadQuoteFailed,
      warnWithMultipleFarePassengers,
      hasActiveRefundables,
      reload,
      refreshingQuote,
      isOverridable,
      overriding,
      noPermissionsAlert,
      canOverrideLastUsedDate,
      refundType,
      canApproveDiscretionaryRefunds,
      applyReasonCodeToAllFares,
      canRequestRefund,
      selectValidCombinations,
      currentValues,
      updateRefundableUnitListRef,
      isEntanglementWarningDisplayed,
      bookingHasEntanglements,
      form,
    } = this.props;

    const header = <BookingSummaryHeader {...booking} number={number} />;
    const isReasonCodePerRefundable = refundType === 'discretionary';
    if (hasActiveRefundables && !booking.quoteUuid && !hasLoadQuoteFailed) {
      return (
        <div>
          {header}
          <Loader />
        </div>
      );
    }

    if (hasLoadQuoteFailed) {
      return (
        <div>
          {header}
          <StatusMessage status="negative">
            <FormattedMessage {...messages.quoteLoadError} />
          </StatusMessage>
        </div>
      );
    }

    const entanglementWarnings = filterEntanglementWarnings(booking.quoteConditions);

    const isScannedTicket = booking.quoteConditions.some(
      (quoteCondition) =>
        path(['reason', 'code'], quoteCondition) &&
        path(['reason', 'code'], quoteCondition) === 'usedTicketRefund'
    );

    const hasPartialReturnRefundAgainstDisruption = booking.quoteConditions.some(
      (quoteCondition) =>
        path(['reason', 'code'], quoteCondition) &&
        path(['reason', 'code'], quoteCondition) === 'partialReturnRefundAgainstDisruption'
    );
    const reasonsCodeProps = {
      reasons: refundReasons,
      disabled: refundReasonDisabled || !isRefundable || pending,
      refundType,
      isUsedTicketRefund: isScannedTicket,
    };

    const disableRecalculate =
      (!canApproveDiscretionaryRefunds && (booking.state?.pendingRefunds?.length > 0 ?? false)) ||
      (isOverridable && !overriding) ||
      pending ||
      !canUpdateQuote ||
      refreshingQuote ||
      (!!bookingHasEntanglements && !!isEntanglementWarningDisplayed);

    const disableToggle =
      !canApproveDiscretionaryRefunds &&
      booking.state &&
      booking.state.pendingRefunds &&
      booking.state.pendingRefunds.length > 0
        ? true
        : pending || (isOverridable && !overriding);

    const generateWarnings = (warnings) => {
      const alerts = warnings
        .map(
          (warning) =>
            warning?.reasonCode &&
            messages[`warning_${warning.reasonCode.toLowerCase()}`] && (
              <StatusMessage key={warning} status="warning">
                <FormattedMessage
                  {...messages[`warning_${warning.reasonCode.toLowerCase()}`]}
                  values={{ b: (msg) => <b>{msg}</b>, br: <br /> }}
                  testId="quote-warning-alert"
                />
              </StatusMessage>
            )
        )
        .filter((alert) => alert != null);

      if (warnings.length > 0 && alerts.length === 0) {
        return [
          <StatusMessage status="warning">
            {typeof warnings[0] === 'string' &&
            Boolean(messages[`warning_${warnings[0].toLowerCase()}`]) ? (
              <FormattedMessage
                {...messages[`warning_${warnings[0].toLowerCase()}`]}
                testId="quote-warning-alert"
              />
            ) : (
              <FormattedMessage {...messages.warning_unspecified} testId="quote-warning-alert" />
            )}
          </StatusMessage>,
        ];
      }

      return alerts;
    };

    return (
      <div
        ref={(e) => {
          this.container = e;
        }}
      >
        {header}
        {booking.afterSaleSystem && <AfterSaleSystem afterSaleSystem={booking.afterSaleSystem} />}
        {booking.unprocessableReasons && generateWarnings(booking.unprocessableReasons)}
        {booking.state &&
          booking.state.pendingRefunds &&
          booking.state.pendingRefunds.map((pendingRefund, index) => (
            <RefundRequestedAlert state={pendingRefund} key={index} />
          ))}
        {booking.refundAlertMessage && (
          <div className={css(styles.notification)}>
            <StatusMessage status="warning">
              <FormattedMessage
                {...messages[booking.refundAlertMessage]}
                testId={`${booking.refundAlertMessage}`}
              />
            </StatusMessage>
          </div>
        )}
        {isRefundable && !error && !success && booking.ticketReturn && (
          <div className={css(styles.notification)}>
            <StatusMessage status="warning">
              <FormattedMessage {...messages.customerAdvisory} testId="customer-advisory" />
            </StatusMessage>
          </div>
        )}
        {!error && !!booking.partialRefundIsEntangled && (
          <div className={css(styles.notification)}>
            <StatusMessage status="info">
              <FormattedMessage
                {...messages.someRefundsNotPossible}
                testId="some-refunds-not-available-info"
              />
            </StatusMessage>
          </div>
        )}
        {hasPartialReturnRefundAgainstDisruption && (
          <div className={css(styles.notification)}>
            <StatusMessage status="warning">
              <FormattedMessage
                {...messages.partialReturnRefundAgainstDisruptionWarning}
                testId="has-partial-return-refund-against-disruption-warning"
              />
            </StatusMessage>
          </div>
        )}
        {isScannedTicket && (
          <div className={css(styles.notification)}>
            <StatusMessage status="warning">
              <FormattedMessage
                {...messages.hasScannedTicketsWarning}
                testId="has-scanned-tickets-warning"
              />
            </StatusMessage>
          </div>
        )}
        {booking.quoteHasEntanglement && !entanglementWarnings.length && (
          <div className={css(styles.notification)}>
            <StatusMessage status="negative">
              <FormattedMessage {...messages.entanglementWarning} />
            </StatusMessage>
          </div>
        )}
        {booking.quoteHasEntanglement &&
          entanglementWarnings.map((entanglementWarning, index) => (
            <EntanglementWarning
              testId={entanglementWarning}
              messageCode={entanglementWarning}
              level="negative"
              key={index}
            />
          ))}
        {isRefundable &&
          !error &&
          !success &&
          !!warnWithMultipleFarePassengers &&
          !!booking.journeys.find((journey) => journey.farePassengers.length > 1) &&
          (booking.deliveryMethod === 'AtocKiosk' ? (
            <div className={css(styles.notification)}>
              <StatusMessage status="warning">
                <FormattedMessage {...messages.TcRefundCtrMultipleFarePassengersWarn} />
              </StatusMessage>
            </div>
          ) : (
            booking.deliveryMethod === 'AtocMTicket' && (
              <div className={css(styles.notification)}>
                <StatusMessage status="warning">
                  <FormattedMessage {...messages.TcRefundMobileMultipleFarePassengersWarn} />
                </StatusMessage>
              </div>
            )
          ))}
        {booking.includesTgvMaxjourney && (
          <div className={css(styles.notification)}>
            <StatusMessage status="info">
              <FormattedMessage {...messages.includesTgvMaxJourney} testId="tgvMax-notice" />
            </StatusMessage>
          </div>
        )}
        {(success || booking.refund || booking.mangled) && (
          <div data-testid="RefundSubmittedInfo" className={css(styles.notification)}>
            <StatusMessage status="info">
              {booking.mangled ? (
                <FormattedMessage
                  testId="refund-confirm-message-with-refresh"
                  {...messages.infoRefundSubmittedMessageWithRefresh}
                  values={{
                    a: (msg) => (
                      <Link linkType="action" onClick={() => reload && reload()}>
                        {msg}
                      </Link>
                    ),
                    link: (msg) => (
                      <Link linkType="internal" to={orderHistoryRoute}>
                        {msg}
                      </Link>
                    ),
                  }}
                />
              ) : (
                <FormattedMessage
                  testId="refund-confirm-message"
                  {...messages.infoRefundSubmittedMessage}
                  values={{
                    link: (msg) => (
                      <Link linkType="internal" to={orderHistoryRoute}>
                        {msg}
                      </Link>
                    ),
                  }}
                />
              )}
              {ticketReturnMode && messages[ticketReturnMode] && (
                <span>
                  <FormattedMessage
                    testId="TicketReturnMode"
                    {...messages[ticketReturnMode]}
                    values={{
                      address: ticketReturnAddress
                        ? Object.values(ticketReturnAddress)
                            .filter((x) => !!x)
                            .join(', ')
                        : '',
                    }}
                  />
                </span>
              )}
            </StatusMessage>
          </div>
        )}
        {noPermissionsAlert}
        {booking.hasMultiplePassengers && (
          <div className={css(styles.notification)}>
            <StatusMessage status="warning">
              <FormattedMessage {...messages.travelcardPartialRefundWarning} />
            </StatusMessage>
          </div>
        )}
        {booking.type === ProductType.Railcard && (
          <FormSection name="railcardRefundables">
            {!booking.railcard ? (
              <StatusMessage status="negative">
                <FormattedMessage
                  {...messages.cannotDisplayRailcardError}
                  testId="cannotDisplayRailcardError"
                />
              </StatusMessage>
            ) : (
              <FormSection name={booking.railcard.refundableId}>
                <RefundableUnit
                  {...booking.railcard}
                  data-test-type="RefundableUnit"
                  orderReference={orderReference}
                  productType={booking.type}
                  productId={productId}
                  railcard={booking.railcard}
                  disabled={disableToggle}
                  isReasonCodePerRefundable={isReasonCodePerRefundable}
                  reasonsCode={reasonsCodeProps}
                  applyReasonCodeToAllFares={false}
                  selectValidCombinations={selectValidCombinations}
                  isSelected={
                    currentValues?.railcardRefundables?.[booking.railcard.refundableId]?.isSelected
                  }
                  updateRefundableUnitListRef={updateRefundableUnitListRef}
                  isApplyAllVisible={false}
                  formName={form}
                  formSection={`fareRefundables.${booking.railcard.refundableId}`}
                  refundLessThanZeroFee={refundLessThanZeroFee}
                />
              </FormSection>
            )}
          </FormSection>
        )}
        {booking.journeys.map((journey, journeyKey) => (
          <section
            key={`${journey.origin}-${journey.departAt}-${journeyKey}`}
            className={css(styles.journeyContainer)}
            data-test-id={`journeyContainer-${journeyKey}`}
          >
            {booking.type === ProductType.Season || booking.type === ProductType.Travelcard ? (
              <ProductDetails {...journey} localAreaValidity={booking.localAreaValidity} />
            ) : (
              booking.type !== ProductType.Railcard && (
                <JourneyDetails
                  {...journey}
                  bookingHasEntanglements={bookingHasEntanglements}
                  isReasonCodePerRefundable={isReasonCodePerRefundable}
                  insurance={booking.insurance}
                />
              )
            )}
            {journey.farePassengers.length === 0 && (
              <div className={css(styles.emptyAlert)}>
                <FormattedMessage {...messages.empty} />
              </div>
            )}
            {journey.farePassengers.length > 0 && (
              <FormSection name="fareRefundables" className={css(styles.farePassengers)}>
                {journey.farePassengers.map((passenger, idx) =>
                  passenger.tickets.map((ticket, index) => (
                    <FormSection
                      name={ticket.refundableId}
                      key={`${journey.origin}-${journey.departAt}-${journeyKey}-${idx}-${index}`}
                    >
                      <RefundableUnit
                        {...ticket}
                        {...passenger}
                        data-test-type="RefundableUnit"
                        orderReference={orderReference}
                        productType={booking.type}
                        productId={productId}
                        entanglements={ticket.allowedEntanglementCombinations}
                        friendlyTicketIndexMapping={booking.friendlyTicketIndexMapping}
                        ticketDocuments={ticketDetails?.documents}
                        disabled={disableToggle}
                        bookingHasEntanglements={bookingHasEntanglements}
                        isReasonCodePerRefundable={isReasonCodePerRefundable}
                        reasonsCode={reasonsCodeProps}
                        applyReasonCodeToAllFares={applyReasonCodeToAllFares}
                        selectValidCombinations={selectValidCombinations}
                        isSelected={
                          currentValues?.fareRefundables?.[ticket.refundableId]?.isSelected
                        }
                        updateRefundableUnitListRef={updateRefundableUnitListRef}
                        isApplyAllVisible={
                          booking.journeys.length > 1 ||
                          journey.farePassengers.length > 1 ||
                          passenger.tickets.length > 1
                        }
                        formName={form}
                        formSection={`fareRefundables.${ticket.refundableId}`}
                      />
                    </FormSection>
                  ))
                )}
              </FormSection>
            )}
          </section>
        ))}
        {booking.localAreaJourneys.length > 0 && (
          <>
            <h2 className={css(styles.bookingTitle)}>
              <FormattedMessage {...messages.localAreaProducts} />
            </h2>

            {booking.localAreaJourneys.map((journey) => (
              <section
                key={journey.id}
                className={css(styles.journeyContainer)}
                data-test-id={`localAreaJourneyContainer-${journey.id}`}
              >
                <LocalAreaJourneyAggregator
                  shouldAggregate={refundType === 'termsAndConditions'}
                  journey={journey}
                  render={(localAreaJourney) => {
                    if (localAreaJourney.farePassengers.length === 0) {
                      return null;
                    }

                    return (
                      <FormSection name="fareRefundables" className={css(styles.farePassengers)}>
                        {localAreaJourney.farePassengers.map((farePassenger) =>
                          farePassenger.tickets.map((ticket) => (
                            <FormSection name={ticket.refundableId} key={ticket.refundableId}>
                              <RefundableUnit
                                {...ticket}
                                {...farePassenger}
                                data-test-type="RefundableUnit"
                                orderReference={orderReference}
                                productType={booking.type}
                                productId={productId}
                                entanglements={ticket.allowedEntanglementCombinations}
                                friendlyTicketIndexMapping={booking.friendlyTicketIndexMapping}
                                ticketDocuments={ticketDetails?.documents}
                                disabled={refundType === 'termsAndConditions' || disableToggle}
                                bookingHasEntanglements={bookingHasEntanglements}
                                isReasonCodePerRefundable={isReasonCodePerRefundable}
                                reasonsCode={reasonsCodeProps}
                                applyReasonCodeToAllFares={applyReasonCodeToAllFares}
                                selectValidCombinations={selectValidCombinations}
                                isSelected={
                                  currentValues?.fareRefundables?.[ticket.refundableId]?.isSelected
                                }
                                updateRefundableUnitListRef={updateRefundableUnitListRef}
                                isApplyAllVisible={booking.localAreaJourneys.length > 1}
                                formName={form}
                                formSection={`fareRefundables.${ticket.refundableId}`}
                              />
                            </FormSection>
                          ))
                        )}
                      </FormSection>
                    );
                  }}
                />
              </section>
            ))}
          </>
        )}

        <section className={css(styles.totalsAndFees)}>
          <ProductTotals
            productTotal={
              !selectionUpdated && booking.productTotal ? booking.productTotal : undefined
            }
            discounts={booking.discounts}
          />
          <RefundFees {...booking.fees} disabled={pending} />
          {booking.adminFee?.price && (
            <RefundFee
              {...booking.adminFee}
              message={messages.adminFee}
              value={booking.adminFee.price}
              disabled={pending}
            />
          )}
        </section>
        {!isReasonCodePerRefundable && (
          <div className={css(styles.reasonContent)}>
            <RefundReason {...reasonsCodeProps} />
          </div>
        )}
        {canOverrideLastUsedDate && (
          <div className={css(styles.lastUsedDateContainer)}>
            <DateField
              name="overrideLastUsedDate"
              label={<FormattedMessage {...messages.refundCalculatedFrom} />}
              minDate={new Date(booking.journeys?.[0].validity?.from).setDate(
                new Date(booking.journeys?.[0].validity?.from).getDate() - 1
              )}
              maxDate={new Date().setMonth(new Date().getMonth())}
              messages={messages}
            />
          </div>
        )}
        <section className={css(styles.paymentContainer)}>
          <PaymentTotal
            canRequestRefund={canRequestRefund}
            currency={path(['total', 'currencyCode'], booking)}
            total={!selectionUpdated ? path(['total', 'amount'], booking) : undefined}
          />
          <div className={css(styles.recalculate)}>
            <RecalculateButton
              isLoading={refreshingQuote}
              isDisabled={disableRecalculate}
              onClick={onRecalculate}
              testId="recalculate-button"
            >
              <FormattedMessage {...messages.recalculate} />
            </RecalculateButton>
          </div>
        </section>
        {isEntanglementWarningDisplayed && (
          <StatusMessage status="negative">
            <FormattedMessage
              {...messages.warning_invalidRefundCombinationsSelected}
              testId="isBannerInvalidEntanglements"
            />
          </StatusMessage>
        )}
      </div>
    );
  }
}

Booking.propTypes = {
  number: PropTypes.number.isRequired,
  booking: PropTypes.shape({
    type: PropTypes.string,
    refundAlertMessage: PropTypes.string,
    afterSaleSystem: PropTypes.object,
    quoteHasEntanglement: PropTypes.bool,
    quoteConditions: PropTypes.arrayOf(
      PropTypes.shape({
        reason: PropTypes.shape({
          code: PropTypes.string,
        }),
      })
    ),
    railcard: PropTypes.shape({
      refundableId: PropTypes.string,
      validity: PropTypes.shape({
        from: PropTypes.string,
        to: PropTypes.string,
      }).isRequired,
    }),
    journeys: PropTypes.arrayOf(
      PropTypes.shape({
        origin: PropTypes.string,
        departAt: PropTypes.oneOfType([Date, PropTypes.string, PropTypes.object]),
        farePassengers: PropTypes.arrayOf(
          PropTypes.shape({
            price: priceShape,
          })
        ),
        validity: PropTypes.shape({
          from: PropTypes.string,
          to: PropTypes.string,
        }).isRequired,
      })
    ),
    localAreaJourneys: PropTypes.arrayOf(
      PropTypes.shape({
        farePassengers: PropTypes.arrayOf(
          PropTypes.shape({
            price: priceShape,
            tickets: PropTypes.arrayOf({}),
          })
        ),
        localAreaValidity: PropTypes.string.isRequired,
        inventoryPrice: priceShape,
      })
    ),
    discounts: PropTypes.array,
    friendlyTicketIndexMapping: PropTypes.object,
    partialRefundIsEntangled: PropTypes.bool,
    deliveryMethod: PropTypes.string,
    mangled: PropTypes.bool,
    fees: PropTypes.object,
    adminFee: PropTypes.object,
    productTotal: priceShape,
    ticketReturn: PropTypes.bool,
    quoteUuid: PropTypes.string,
    insurance: PropTypes.object,
    state: PropTypes.object,
    refund: PropTypes.shape({
      ticketReturnMode: PropTypes.shape({
        name: PropTypes.string.isRequired,
        returnAddress: PropTypes.object,
      }),
    }),
    includesTgvMaxjourney: PropTypes.bool,
    unprocessableReasons: PropTypes.arrayOf(
      PropTypes.shape({ reasonCode: PropTypes.string.isRequired })
    ),
    localAreaValidity: PropTypes.string,
    hasMultiplePassengers: PropTypes.bool,
  }).isRequired,
  productId: PropTypes.string.isRequired,
  orderReference: PropTypes.string.isRequired,
  orderHistoryRoute: PropTypes.string.isRequired,
  ticketDetails: PropTypes.shape({
    origin: PropTypes.string,
    destination: PropTypes.string,
    vendor: PropTypes.string,
    subVendor: PropTypes.string,
    passengers: PropTypes.array,
    documents: PropTypes.array,
    ticketClass: PropTypes.string,
    farePassengerId: PropTypes.string,
    deliveryMethod: PropTypes.string,
    ticketNumber: PropTypes.string,
  }),
  pending: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool,
  isRefundable: PropTypes.bool,
  selectionUpdated: PropTypes.bool,
  refundReasons: PropTypes.array,
  refundReasonDisabled: PropTypes.bool,
  ticketReturnMode: PropTypes.string,
  ticketReturnAddress: PropTypes.string,
  onRecalculate: PropTypes.func.isRequired,
  canUpdateQuote: PropTypes.bool.isRequired,
  hasLoadQuoteFailed: PropTypes.bool.isRequired,
  warnWithMultipleFarePassengers: PropTypes.bool,
  hasActiveRefundables: PropTypes.bool.isRequired,
  reload: PropTypes.func,
  refreshingQuote: PropTypes.bool.isRequired,
  isOverridable: PropTypes.bool,
  overriding: PropTypes.bool,
  noPermissionsAlert: PropTypes.object,
  canOverrideLastUsedDate: PropTypes.bool,
  refundType: PropTypes.string,
  canApproveDiscretionaryRefunds: PropTypes.bool.isRequired,
  applyReasonCodeToAllFares: PropTypes.func,
  selectValidCombinations: PropTypes.func,
  currentValues: PropTypes.object,
  canRequestRefund: PropTypes.bool.isRequired,
  updateRefundableUnitListRef: PropTypes.func,
  isEntanglementWarningDisplayed: PropTypes.bool,
  bookingHasEntanglements: PropTypes.bool,
  managedGroupId: PropTypes.string,
  form: PropTypes.string,
  refundLessThanZeroFee: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  managedGroupId: getCurrentManagedGroupId(state),
});

export default connect(mapStateToProps)(Booking);
