import { defineMessages } from 'react-intl';

export default defineMessages({
  bookingTitle: {
    id: 'ChangeOfJourney.BookingHeader.bookingTitle',
    defaultMessage: 'Booking {bookingNo}',
  },
  origin: {
    id: 'ChangeOfJourney.BookingHeader.origin',
    defaultMessage: '{origin} to',
  },
  destination: {
    id: 'ChangeOfJourney.BookingHeader.destination',
    defaultMessage: '{destination}, {isReturn, select, true {Return} false {Single}}',
  },
  datesTitle: {
    id: 'ChangeOfJourney.BookingHeader.datesTitle',
    defaultMessage: 'Dates',
  },
  label: {
    id: 'ChangeOfJourney.BookingHeader.label',
    defaultMessage: '{label} {value}',
  },
  depart: {
    id: 'ChangeOfJourney.BookingHeader.depart',
    defaultMessage: 'Out',
  },
  return: {
    id: 'ChangeOfJourney.BookingHeader.return',
    defaultMessage: 'Return',
  },
  fulfilmentTitle: {
    id: 'ChangeOfJourney.BookingHeader.fulfilmentTitle',
    defaultMessage: 'Fulfilment',
  },
  passengersTitle: {
    id: 'ChangeOfJourney.BookingHeader.passengersTitle',
    defaultMessage: 'Passengers',
  },
  passengerTypes: {
    id: 'ChangeOfJourney.BookingHeader.passengerTypes',
    defaultMessage: `{count} {passengerType, select,
      adult {{count, plural, one {Adult} other {Adults}}}
      child {{count, plural, one {Child} other {Children}}}
      senior {{count, plural, one {Senior} other {Seniors}}}
      other {Other {count, plural,  one {Passenger} other {Passengers}}}}`,
  },
  priceTitle: {
    id: 'ChangeOfJourney.BookingHeader.priceTitle',
    defaultMessage: 'Price',
  },
  returnIsValidFor: {
    id: 'ChangeOfJourney.returnIsValidFor',
    defaultMessage: 'valid for {period}',
  },
  returnIsValidUntil: {
    id: 'ChangeOfJourney.returnIsValidUntil',
    defaultMessage: 'valid until {period} at 04:29',
  },
});
