import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';

import Button from '../Button';

import PageItem from './components/PageItem';
import PaginatorCount from './components/PaginatorCount';
import styles from './styles';
import messages from './messages';

const Paginator = ({ children, currrentPage = 0, disabled = false }) => {
  const [activePage, setActivePage] = React.useState(currrentPage);

  const pageItemsArray = React.Children.toArray(children);
  const pageItemsCount = React.Children.count(children) - 1;

  const handleContinue = () => activePage < pageItemsCount && setActivePage(activePage + 1);
  const handlePrevious = () => activePage >= pageItemsCount && setActivePage(activePage - 1);

  const pageItems = pageItemsArray.map((pageItem, index) => {
    let active = false;

    if (activePage === index) {
      active = true;
    }

    return React.cloneElement(pageItem, { active, ...pageItem.props });
  });

  return (
    <div className={css(styles.paginator)}>
      <div className={css(styles.pageItemContainer)}>{pageItems}</div>
      <div className={css(styles.footer)}>
        {activePage !== 0 && (
          <Button
            testId="paginator-button-previous"
            variant="ghost"
            styleSheet={styles.outline}
            disabled={disabled}
            onClick={handlePrevious}
          >
            <FormattedMessage {...messages.previous} />
          </Button>
        )}
        <PaginatorCount
          data-testid="paginatorCount"
          currrentPage={activePage}
          pageItemsLength={pageItemsArray.length}
        />
        {activePage < pageItemsCount && (
          <Button
            testId="paginator-button-continue"
            variant="ghost"
            styleSheet={styles.solid}
            disabled={disabled}
            onClick={handleContinue}
          >
            <FormattedMessage {...messages.continue} />
          </Button>
        )}
      </div>
    </div>
  );
};

Paginator.propTypes = {
  children: PropTypes.arrayOf(PageItem).isRequired,
  currrentPage: PropTypes.number,
  disabled: PropTypes.bool,
};

export default Paginator;
