import { createSelector } from 'reselect';

import type State from '@contactcentre-web/redux-common/types/State';

import { LoadingStatus } from './module';

const localState = (state: State) => state.editCustomerProfile;

export const isLoading = createSelector(
  localState,
  (state) => state.loadState === LoadingStatus.INPROGRESS
);

export const isEditting = createSelector(
  localState,
  (state) => state.editState === LoadingStatus.INPROGRESS
);

export const editError = createSelector(localState, (state) =>
  state.editState === LoadingStatus.ERROR ? state.error : undefined
);

export const editSucceeded = createSelector(
  localState,
  (state) => state.editState === LoadingStatus.SUCCESS
);

export const getLanguages = createSelector(localState, (state) => state.languages);
