import { StyleSheet } from 'aphrodite/no-important';
import { fonts, palettes, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: spacers.dp_s48,
  },
  innerContainer: {
    width: '374px',
  },
  spacer: {
    marginBottom: spacers.dp_s16,
  },
  username: {
    color: palettes.dp_brand_primary_core,
    fontWeight: fonts.dp_font_weight_bold,
  },
  cancel: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: spacers.dp_s8,
  },
  button: {
    marginTop: spacers.dp_s8,
  },
});
