import { defineMessages } from 'react-intl';

export default defineMessages({
  journeyLeg: {
    id: 'CustomerBookingJourneyLeg.journeyLeg',
    defaultMessage: 'Out {legNum} of {legCount}',
  },

  routeDuration: {
    id: 'CustomerBookingJourneyLeg.routeDuration',
    defaultMessage: 'Duration',
  },

  transportInformation: {
    id: 'CustomerBookingJourneyLeg.transportInformation',
    defaultMessage: '{carrier}',
  },

  passengerInfo: {
    id: 'CustomerBookingJourneyLeg.passengerInfo',
    defaultMessage: 'Passenger info',
  },

  disruptionInfo: {
    id: 'CustomerBookingJourneyLeg.disruptionInfo',
    defaultMessage: 'Disruption info',
  },

  depart: {
    id: 'CustomerBookingJourneyLeg.depart',
    defaultMessage: 'Depart',
  },

  arrive: {
    id: 'CustomerBookingJourneyLeg.arrive',
    defaultMessage: 'Arrive',
  },

  carrier: {
    id: 'CustomerBookingJourneyLeg.carrier',
    defaultMessage: 'Carrier',
  },

  timetableLoadError: {
    id: 'CustomerBookingJourneyLeg.timetableLoadError',
    defaultMessage: 'Timetable information could not be loaded for this leg of the journey',
  },

  bikeSpace: {
    id: 'CustomerBookingJourneyLeg.bikeSpace',
    defaultMessage: 'Bike Space',
  },
});
