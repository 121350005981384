import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
  },

  passenger: {
    margin: `${spacers.dp_s24} 0`,
  },

  left: {
    margin: `0 ${spacers.dp_s24}`,
    width: '210px',
  },

  grayLabel: {
    color: colors.gray.alpha30,
  },

  infoValue: {
    marginLeft: spacers.dp_s4,
  },
});
