import React from 'react';

import TracsOnAccountApprovalsPage from '@contactcentre-web/tracs-on-account-approvals/TracsOnAccountApprovals';
import PageContainer from '@contactcentre-web/common/PageContainer/PageContainer';
import ErrorBoundary from '@contactcentre-web/common/ErrorBoundary/ErrorBoundary';

const VirtualTerminalPage = () => (
  <PageContainer>
    <ErrorBoundary>
      <TracsOnAccountApprovalsPage />
    </ErrorBoundary>
  </PageContainer>
);

export default VirtualTerminalPage;
