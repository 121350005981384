import { defineMessages } from 'react-intl';

export default defineMessages({
  afterSaleSystemAdditionalOptionAvailable: {
    id: 'AfterSaleSystem.additionalOptionsAvailable',
    defaultMessage:
      'Additional options are available with {vendorName} directly. Please visit {afterSaleSystemUrl} for more information.',
  },
  afterSaleSystemVendorNamePlaceholder: {
    id: 'AfterSaleSystem.vendorNamePlaceholder',
    defaultMessage: 'the vendor',
  },
  afterSaleSystemLinkPlaceholder: {
    id: 'AfterSaleSystem.linkPlaceholder',
    defaultMessage: "the vendor's system",
  },
});
