import { defineMessages } from 'react-intl';

export default defineMessages({
  markAsUnableToReadTicket: {
    id: 'paperTicketImage.markAsUnableToReadTicket',
    defaultMessage: 'Unable to read ticket',
  },
  markAsNoMatchInSystem: {
    id: 'paperTicketImage.markAsNoMatchInSystem',
    defaultMessage: 'No match in system',
  },
  markAsNoMatchInSystemError: {
    id: 'paperTicketImage.markAsNoMatchInSystemError',
    defaultMessage: 'Failed to mark as no match in system.',
  },
  unableToReadTicketError: {
    id: 'paperTicketImage.unableToReadTicketError',
    defaultMessage: 'Failed to mark as unable to read ticket.',
  },
  imageNotFoundError: {
    id: 'paperTicketImage.imageNotFoundError',
    defaultMessage: 'Unable to load scanned ticket image.',
  },
});
