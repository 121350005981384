import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { useHistory } from 'react-router-dom';

import PageContainer from '@contactcentre-web/common/PageContainer';
import Link from '@contactcentre-web/common/Link';
import type State from '@contactcentre-web/redux-common/types/State';

import { selectors } from '../../module';

import messages from './messages';
import styles from './styles';

interface StateProps {
  username: string | null;
  newPassword: string | null;
}

type Props = StateProps;

export const ResetPasswordSuccess: FunctionComponent<Props> = ({ username, newPassword }) => {
  const history = useHistory();

  React.useEffect(() => {
    if (!newPassword) {
      history.push('/edit_user/search');
    }
  }, [newPassword]);

  return (
    <PageContainer>
      <div className={css(styles.container)}>
        <h1 data-test="reset-password-success-message" className={css(styles.title)}>
          <FormattedMessage {...messages.passwordSuccessfullyReset} />
        </h1>
        <p className={css(styles.details)}>
          <FormattedMessage {...messages.agentUsername} />{' '}
          <span data-test="agent-username" className={css(styles.value)}>
            {username}
          </span>
        </p>
        <p className={css(styles.details)}>
          <FormattedMessage {...messages.temporaryPassword} />{' '}
          <span data-test="new-password" className={css(styles.value)}>
            {newPassword}
          </span>
        </p>
        <div className={css(styles.actionLinks)}>
          <div className={css(styles.actionLink)}>
            <Link linkType="internal" to={`/edit_user/${username}`}>
              <FormattedMessage {...messages.backToUser} />
            </Link>
          </div>
          <div className={css(styles.actionLink)}>
            <Link linkType="internal" to="/edit_user">
              <FormattedMessage {...messages.backToSearch} />
            </Link>
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

const mapStateToProps = (state: State) => ({
  username: selectors.getUsername(state),
  newPassword: selectors.getNewPassword(state),
});

export default connect(mapStateToProps)(ResetPasswordSuccess);
