import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { StatusMessage } from '@trainline/depot-web';

import Link from '@contactcentre-web/common/Link';

import messages from './messages';
import styles from './styles';

const ReplacementBookingAlert = ({
  bookingNumber,
  customerId,
  replacementOrderReference,
  replacementSubType,
}) => {
  const isChargeToTrainline = replacementSubType === 'creditNote';
  return (
    <div data-test="originalBookingAlert" className={css(styles.notification)}>
      <StatusMessage status="info">
        <div className={css(isChargeToTrainline && styles.multiLineMessage)}>
          <FormattedMessage
            data-test-id="replaced-message"
            {...(isChargeToTrainline
              ? messages.bookingHasBeenConverted
              : messages.bookingHasBeenReplaced)}
            values={{
              bookingNumber,
              link: (
                <Link
                  linkType="internal"
                  to={`/customers/${customerId}/bookings/${replacementOrderReference}`}
                >
                  <FormattedMessage
                    {...(isChargeToTrainline ? messages.goToNewBooking : messages.newBooking)}
                  />
                </Link>
              ),
            }}
          />
        </div>
      </StatusMessage>
    </div>
  );
};

ReplacementBookingAlert.propTypes = {
  bookingNumber: PropTypes.number.isRequired,
  customerId: PropTypes.string.isRequired,
  replacementOrderReference: PropTypes.string.isRequired,
  replacementSubType: PropTypes.string.isRequired,
};

export default ReplacementBookingAlert;
