import React, { ChangeEvent, FunctionComponent, ReactElement } from 'react';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';
import { useSelector } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import { es, nb, it, enGB, fr, sv, de } from 'date-fns/locale';
import { EventOrValueHandler } from 'redux-form';

import type State from '@contactcentre-web/redux-common/types/State';

import Label from '../Label/Label';
import textInputStyles from '../TextInput/styles';

import 'react-datepicker/dist/react-datepicker.css';

registerLocale('en-GB', enGB);
registerLocale('nb-NO', nb);
registerLocale('fr-FR', fr);
registerLocale('it-IT', it);
registerLocale('de-DE', de);
registerLocale('es-ES', es);
registerLocale('sv-SE', sv);

interface Props {
  label?: string | ReactElement;
  name: string;
  value: Date;
  minDate?: Date;
  maxDate?: Date;
  onChange: EventOrValueHandler<ChangeEvent<HTMLInputElement>>;
  styleSheet?: {
    calendar?: StyleDeclarationValue;
  };
}

const DateSelect: FunctionComponent<Props> = ({
  label,
  name,
  value,
  minDate,
  maxDate,
  onChange,
  styleSheet: { calendar } = {},
}) => {
  const currentLocale = useSelector((state: State) => state.profile.locale);

  const input = (
    <DatePicker
      name={name}
      locale={currentLocale}
      minDate={minDate}
      maxDate={maxDate}
      selected={value}
      dateFormat="d MMMM yyyy"
      onChange={(date, event) => {
        event?.preventDefault();
        onChange(date);
      }}
      className={css([textInputStyles.root, calendar])}
    />
  );

  return label ? (
    <Label htmlFor={name} label={label}>
      {input}
    </Label>
  ) : (
    input
  );
};

export default DateSelect;
