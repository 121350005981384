import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  insuranceIcon: {
    display: 'inline',
    width: '16px',
    height: '16px',
    verticalAlign: -2,
    marginRight: spacers.dp_s8,
  },
});
