import { palettes } from '@trainline/depot-web/css/themes/trainline';
import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({
  logo: {
    display: 'flex',
  },
  logoInverted: {
    ':nth-child(1n) > svg > path': {
      fill: palettes.dp_app_backdrop_base,
    },
  },
});
