import { defineMessages } from 'react-intl';

export default defineMessages({
  requestedSuccess: {
    id: 'CompensationSuccessfulAlert.requestedSuccess',
    defaultMessage: 'Compensation has been successfully requested.',
  },
  processedSuccess: {
    id: 'CompensationSuccessfulAlert.processedSuccess',
    defaultMessage: 'Compensation has been successfully processed.',
  },
});
