import React, { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency/FormattedCurrency';
import type { CompensationCarrierRequestHistory } from '@contactcentre-web/hooks/api/useOrderHistory';

import OrderHistory from '../OrderHistory/OrderHistory';

import messages from './messages';

const CompensationCarrierRequest: FunctionComponent<CompensationCarrierRequestHistory> = ({
  date,
  user,
  requestedAmount,
}) => {
  const { formatMessage } = useIntl();

  return (
    <OrderHistory.Card>
      <OrderHistory.Header date={date} user={user}>
        <FormattedMessage {...messages.compensation} />
      </OrderHistory.Header>
      <OrderHistory.Content color="negative" message={messages.compensationNotProcessed}>
        <OrderHistory.Items>
          {requestedAmount && requestedAmount.currencyCode && (
            <OrderHistory.Item
              label={formatMessage(messages.requestedAmount)}
              testId="requestedAmount"
            >
              <FormattedCurrency {...requestedAmount} />
            </OrderHistory.Item>
          )}
          <OrderHistory.Item
            label={formatMessage(messages.recommendationLabel)}
            testId="recommendation"
          >
            <FormattedMessage {...messages.recommendation} />
          </OrderHistory.Item>
        </OrderHistory.Items>
      </OrderHistory.Content>
    </OrderHistory.Card>
  );
};

export default CompensationCarrierRequest;
