import React, { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { compose, groupBy, map as rMap, toPairs, project } from 'ramda';

import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency/FormattedCurrency';
import StatusIndicator from '@contactcentre-web/common/StatusIndicator';
import type Price from '@contactcentre-web/redux-common/types/Price';

import type { Refundable, RefundableStatus, RefundableType } from '../../module';

import messages from './messages';
import styles from './styles';

export const getStatusWithCount = (status: string, type: Array<RefundableType>) => {
  const itemsCount = type.filter((t) => t === 'ProductRefundable').length;
  const feesCount = type.filter((t) => t !== 'ProductRefundable').length;

  return (
    <FormattedMessage
      {...messages.refundableCount}
      values={{
        status,
        itemsCount,
        feesCount,
        hasBothTypes: Boolean(itemsCount && feesCount),
      }}
    />
  );
};

interface Props {
  amountRequested?: Price | null;
  amountRefunded?: Price | null;
  adminFee?: Price | null;
  promoDiscount?: Price | null;
  reasonCode?: string | null;
  policyType: string | null;
  refundables?: Array<Refundable> | null;
}

const RefundTable: FunctionComponent<Props> = ({
  amountRequested,
  amountRefunded,
  adminFee,
  promoDiscount,
  reasonCode,
  policyType,
  refundables = [],
}) => {
  const { formatMessage } = useIntl();
  const isDiscretionaryPolicy = policyType === 'discretionary';
  const showPromoDiscount = promoDiscount && promoDiscount.amount !== 0;
  const displaysReasonPerRefundable =
    isDiscretionaryPolicy && refundables?.some((refundable) => refundable.ticketType !== null);

  const refundablesGroupedByStatus = refundables
    ? compose(
        rMap(([refundStatus, refundType]: [RefundableStatus, Array<{ type: RefundableType }>]) => ({
          status: refundStatus,
          type: refundType.map(({ type }) => type),
        })),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        toPairs,
        rMap(project(['type'])),
        groupBy(({ status }) => status)
      )(refundables)
    : [];

  const refundablesWithReasons = displaysReasonPerRefundable
    ? refundables
    : [{ reason: reasonCode, ticketType: undefined, status: undefined, type: undefined }];
  const refundReason = (reason?: string | null, type?: RefundableType) =>
    displaysReasonPerRefundable && type && type !== 'ProductRefundable' && messages[type]
      ? formatMessage(messages[type])
      : reason;

  return (
    <table className={css(styles.table)}>
      <thead>
        <tr className={css(styles.tableHead)}>
          <th className={css(styles.tableHeader)}>
            <FormattedMessage {...messages.requested} />
          </th>
          <th className={css(styles.tableHeader)}>
            <FormattedMessage {...messages.adminFee} />
          </th>
          {showPromoDiscount && (
            <th className={css(styles.tableHeader)}>
              <FormattedMessage {...messages.promoDiscount} />
            </th>
          )}
          <th className={css(styles.tableHeader)}>
            <FormattedMessage {...messages.refunded} />
          </th>
          {displaysReasonPerRefundable && (
            <th className={css(styles.tableHeader)}>
              <FormattedMessage {...messages.ticketType} />
            </th>
          )}
          {isDiscretionaryPolicy && (
            <th className={css(styles.tableHeader, styles.reasonCode)}>
              <FormattedMessage {...messages.reasonCode} />
            </th>
          )}
          <th className={css(styles.tableHeader)}>
            <FormattedMessage {...messages.refundStatus} />
          </th>
        </tr>
      </thead>
      <tbody>
        {refundablesWithReasons?.map(({ ticketType, reason, status, type }, index) => (
          <tr className={css(styles.tableBody)} key={index}>
            {index === 0 && (
              <>
                <td rowSpan={refundablesWithReasons.length} className={css(styles.tableCell)}>
                  {amountRequested && <FormattedCurrency {...amountRequested} />}
                </td>
                <td rowSpan={refundablesWithReasons.length} className={css(styles.tableCell)}>
                  {adminFee && <FormattedCurrency {...adminFee} />}
                </td>
                {showPromoDiscount && (
                  <td className={css(styles.tableCell)}>
                    <FormattedCurrency {...promoDiscount} />
                  </td>
                )}
                <td rowSpan={refundablesWithReasons.length} className={css(styles.tableCell)}>
                  {amountRefunded && <FormattedCurrency {...amountRefunded} />}
                </td>
              </>
            )}
            {displaysReasonPerRefundable && (
              <td className={css(styles.tableCell)} data-test="ticketType">
                {ticketType}
              </td>
            )}
            {isDiscretionaryPolicy && (
              <td className={css(styles.tableCell)} data-test="reasonCode">
                {refundReason(reason, type)}
              </td>
            )}
            {displaysReasonPerRefundable && status ? (
              <td className={css(styles.tableCell)} data-test="status">
                <StatusIndicator
                  status={status}
                  indicatorStyles={styles[`statusIndicator${status}`]}
                />
              </td>
            ) : (
              <td className={css(styles.tableCell)}>
                <ul className={css(styles.refundStatusList)}>
                  {refundablesGroupedByStatus.map((refundable, refundableIndex) => (
                    <li
                      key={`${refundable.status}-${refundableIndex}`}
                      className={css(styles.refundStatusListItem)}
                    >
                      <StatusIndicator
                        status={getStatusWithCount(refundable.status, refundable.type)}
                        indicatorStyles={styles[`statusIndicator${refundable.status}`]}
                      />
                    </li>
                  ))}
                </ul>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default RefundTable;
