import { StyleSheet } from 'aphrodite/no-important';
import { spacers, palettes } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  root: {
    width: '100%',
    height: '1px',
    background: 'none',
    opacity: 0.5,
    marginTop: spacers.dp_s8,
    marginBottom: spacers.dp_s8,
    borderTop: `1px solid ${palettes.dp_app_border_base}`,
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'none',
  },
});
