import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { connect } from 'react-redux';
import { StatusMessage } from '@trainline/depot-web';

import Button from '@contactcentre-web/common/Button';
import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from '@contactcentre-web/common/Modal';
import Loader from '@contactcentre-web/common/Loader';
import Link from '@contactcentre-web/common/Link';
import DocumentDownload from '@contactcentre-web/common/DocumentDownload';
import type { TicketStatus } from '@contactcentre-web/redux-common/types/TicketStatus';

import { actions, selectors } from '../../module';
import TicketSummary, {
  thirdPartyStatusMessage,
  trainlineStatusMessage,
} from '../TicketSummary/TicketSummary';

import messages from './messages';
import styles from './styles';

export const ticketUsageStatusTypes = [
  'AtocMTicket',
  'AtocETicket',
  'OBBOnDemandETicket',
  'OBBOnDemandPrintYourOwn',
];

interface StateProps {
  isLoading: boolean;
  hasErrored: boolean;
  status?: TicketStatus;
  isGeneratingTicket: boolean;
  hasFailedGeneratingTicket: boolean;
  hasGeneratedTicket: boolean;
}

interface DispatchProps {
  loadStatuses: (orderReference: string, productId: string) => void;
  generateTicket: (orderReference: string, productId: string, farePassengerId: string) => void;
  stopGenerateTicketPollingTask: () => void;
}

interface OwnProps {
  orderReference: string;
  productId: string;
  farePassengerId: string;
  deliveryMethod: string;
  documents?: {
    type: string;
    link: string;
  }[];
  displayText?: JSX.Element;
}

export interface Props extends StateProps, DispatchProps, OwnProps {}

const descriptionMessage = {
  NotUsed: messages.notUsedDescription,
  Scanned: messages.scannedDescription,
  Cancelled: messages.cancelledDescription,
  Active: messages.activeDescription,
  unavailable: messages.unavailableDescription,
  fulfilling: messages.fulfillingDescription,
  fulfilled: messages.fulfilledDescription,
  readyForFetch: messages.readyForFetchDescription,
  failed: messages.failedDescription,
};

export const TicketStatusModal = ({
  isLoading,
  hasErrored,
  status,
  orderReference,
  productId,
  loadStatuses,
  deliveryMethod,
  farePassengerId,
  isGeneratingTicket,
  hasFailedGeneratingTicket,
  hasGeneratedTicket,
  generateTicket,
  stopGenerateTicketPollingTask,
  documents,
  displayText,
}: Props) => {
  const [visible, toggle] = useState(false);

  useEffect(() => {
    if (visible) {
      loadStatuses(orderReference, productId);
    }
    return () => stopGenerateTicketPollingTask();
  }, [visible, orderReference, productId]);

  const canGenerateTicket =
    ['OBBOnDemandETicket', 'OBBOnDemandPrintYourOwn'].includes(deliveryMethod) &&
    status?.thirdPartyStatus === 'readyForFetch';

  return (
    <>
      {visible && (
        <Modal
          onClose={() => {
            if (!isLoading) toggle(false);
          }}
        >
          <ModalHeader>
            <ModalTitle>
              <FormattedMessage {...messages.modal_title} />
            </ModalTitle>
          </ModalHeader>
          <>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {hasErrored ? (
                  <StatusMessage status="negative">
                    <FormattedMessage {...messages.label_error} />
                  </StatusMessage>
                ) : (
                  <>
                    {status && ['AtocMTicket', 'AtocETicket'].includes(deliveryMethod) ? (
                      <TicketSummary deliveryMethod={deliveryMethod} status={status} />
                    ) : (
                      <>
                        <ModalBody>
                          {status ? (
                            <>
                              <section className={css(styles.status)}>
                                {status.trainlineStatus && (
                                  <>
                                    <span>
                                      {trainlineStatusMessage[status.trainlineStatus] ? (
                                        <FormattedMessage
                                          {...trainlineStatusMessage[status.trainlineStatus]}
                                        />
                                      ) : (
                                        status.trainlineStatus
                                      )}
                                    </span>
                                    {' - '}
                                  </>
                                )}
                                {status.thirdPartyStatus && (
                                  <>
                                    <span>
                                      {thirdPartyStatusMessage[status.thirdPartyStatus] ? (
                                        <FormattedMessage
                                          {...thirdPartyStatusMessage[status.thirdPartyStatus]}
                                        />
                                      ) : (
                                        status.thirdPartyStatus
                                      )}
                                    </span>
                                  </>
                                )}
                              </section>
                              <section className={css(styles.description)}>
                                {status.thirdPartyStatus &&
                                descriptionMessage[status.thirdPartyStatus] ? (
                                  <FormattedMessage
                                    {...descriptionMessage[status.thirdPartyStatus]}
                                  />
                                ) : (
                                  status.description
                                )}
                              </section>
                            </>
                          ) : (
                            <section className={css(styles.description)}>
                              <FormattedMessage {...messages.label_nostatus} />
                            </section>
                          )}
                          {documents && hasGeneratedTicket && (
                            <section className={css(styles.documentContainer)}>
                              {documents.map(({ link }, index) => (
                                <div key={index} className={css(styles.document)}>
                                  <DocumentDownload link={link} />
                                </div>
                              ))}
                            </section>
                          )}
                        </ModalBody>
                        <ModalFooter>
                          {canGenerateTicket ? (
                            <Button
                              variant="primary"
                              size="large"
                              onClick={() =>
                                generateTicket(orderReference, productId, farePassengerId)
                              }
                              loading={isGeneratingTicket}
                              testId="generate-button"
                            >
                              <FormattedMessage {...messages.button_generate} />
                            </Button>
                          ) : (
                            <Button
                              variant="primary"
                              size="large"
                              onClick={() => toggle(false)}
                              testId="done-button"
                            >
                              <FormattedMessage {...messages.button_done} />
                            </Button>
                          )}
                        </ModalFooter>
                        {hasFailedGeneratingTicket && (
                          <div
                            className={css(styles.generateErrorContainer)}
                            data-test="generate-ticket-error"
                          >
                            <FormattedMessage {...messages.failed_generate} />
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        </Modal>
      )}
      <Link linkType="action" styleSheet={styles.anchor} onClick={() => toggle(true)}>
        {displayText || <FormattedMessage {...messages.status} />}
      </Link>
    </>
  );
};

const mapStateToProps = (
  _: any,
  { farePassengerId, productId }: OwnProps
): ((state: any) => StateProps) => {
  const statusSelector = selectors.ticketStatusFactory(productId, farePassengerId);
  const loadingSelector = selectors.ticketStatusLoadingFactory(productId);
  const errorSelector = selectors.ticketStatusErrorFactory(productId);

  return (state: any) => ({
    isLoading: loadingSelector(state),
    status: statusSelector(state),
    hasErrored: errorSelector(state),
    isGeneratingTicket: selectors.isGeneratingTicket(state),
    hasFailedGeneratingTicket: selectors.hasFailedGeneratingTicket(state),
    hasGeneratedTicket: selectors.hasGeneratedTicket(state),
  });
};

const mapDispatchToProps = (dispatch: (action: any) => void): DispatchProps => ({
  loadStatuses: (orderReference: string, productId: string): void =>
    dispatch(actions.ticketStatusesAttempt(orderReference, productId)),
  generateTicket: (orderReference: string, productId: string, farePassengerId: string): void =>
    dispatch(actions.generateTicketAttempt(orderReference, productId, farePassengerId)),
  stopGenerateTicketPollingTask: (): void => dispatch(actions.stopGenerateTicketPollingTask()),
});

const connected = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(TicketStatusModal);

export default connected;
