import { LOCALE_CODES, DEFAULT_LOCALE_CODE } from './locales';
import enTranslationMessages from './locales/en-GB.json';
import noTranslationMessages from './locales/nb-NO.json';
import frTranslationMessages from './locales/fr-FR.json';
import itTranslationMessages from './locales/it-IT.json';
import deTranslationMessages from './locales/de-DE.json';
import esTranslationMessages from './locales/es-ES.json';
import svTranslationMessages from './locales/sv-SE.json';

const formatTranslationMessages = (
  locale: LOCALE_CODES,
  messages: Record<string, string>
): Record<string, string> => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE_CODE
      ? formatTranslationMessages(DEFAULT_LOCALE_CODE, enTranslationMessages)
      : {};

  return Object.keys(messages).reduce((formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE_CODE
        ? defaultFormattedMessages[key]
        : messages[key];

    return Object.assign(formattedMessages, { [key]: formattedMessage });
  }, {});
};

export const translationMessages = {
  [DEFAULT_LOCALE_CODE]: formatTranslationMessages(DEFAULT_LOCALE_CODE, enTranslationMessages),
  [LOCALE_CODES.NORWEGIAN]: formatTranslationMessages(
    LOCALE_CODES.NORWEGIAN,
    noTranslationMessages
  ),
  [LOCALE_CODES.FRENCH]: formatTranslationMessages(LOCALE_CODES.FRENCH, frTranslationMessages),
  [LOCALE_CODES.ITALIAN]: formatTranslationMessages(LOCALE_CODES.ITALIAN, itTranslationMessages),
  [LOCALE_CODES.GERMAN]: formatTranslationMessages(LOCALE_CODES.GERMAN, deTranslationMessages),
  [LOCALE_CODES.SPANISH]: formatTranslationMessages(LOCALE_CODES.SPANISH, esTranslationMessages),
  [LOCALE_CODES.SWEDISH]: formatTranslationMessages(LOCALE_CODES.SWEDISH, svTranslationMessages),
};
