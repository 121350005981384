import { StyleSheet } from 'aphrodite/no-important';

import { sidebarWidth } from '@contactcentre-web/styles/sizes';

const fadeIn = {
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
};

export default StyleSheet.create({
  layout: {
    // @keyframes are not working on IE10 and aphrodite
    opacity: 0,
    animationName: [fadeIn],
    animationDuration: '1s',
    animationDelay: '1s',
    animationFillMode: 'forwards',
    display: 'flex',
    position: 'relative',
  },
  layoutWithSidebar: {
    transition: '0.3s margin',
    marginRight: sidebarWidth,
  },
  main: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
});
