import { StyleSheet } from 'aphrodite/no-important';
import { palettes, spacers, fonts, typography } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  formContainer: {
    padding: `${spacers.dp_s24} 0`,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    rowGap: `${spacers.dp_s16}`,
  },
  row: {
    gridColumn: '1 / -1',
  },
  label: {
    fontWeight: fonts.dp_font_weight_regular,
    color: palettes.dp_app_text_base,
  },
  gray: {
    fontWeight: fonts.dp_font_weight_regular,
    color: palettes.dp_app_text_subdued,
  },
  paymentLabelContainer: {
    textAlign: 'right',
    marginBottom: spacers.dp_s8,
  },
  amount: {
    fontSize: typography.dp_title2_font_size_m,
    lineHeight: typography.dp_title2_line_height_m,
    fontWeight: fonts.dp_font_weight_bold,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pnr: {
    display: 'inline-flex',
    gap: spacers.dp_s8,
    marginLeft: spacers.dp_s16,
  },
});
