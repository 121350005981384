import React from 'react';

import PageContainer from '@contactcentre-web/common/PageContainer/PageContainer';
import ErrorBoundary from '@contactcentre-web/common/ErrorBoundary/ErrorBoundary';
import PaperTicketQueue from '@contactcentre-web/paper-ticket/PaperTicketQueue';

import styles from './styles';

const PaperTicketQueuePage = () => (
  <PageContainer styleSheet={styles.container}>
    <ErrorBoundary>
      <PaperTicketQueue />
    </ErrorBoundary>
  </PageContainer>
);

export default PaperTicketQueuePage;
