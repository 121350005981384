import { defineMessages } from 'react-intl';

export default defineMessages({
  validFrom: {
    id: 'RailcardHeader.validFrom',
    defaultMessage: 'Valid from',
  },
  validTo: {
    id: 'RailcardHeader.validTo',
    defaultMessage: 'Valid to',
  },
  deliveryMethod: {
    id: 'RailcardHeader.deliveryMethod',
    defaultMessage: 'Delivery Method',
  },
  productPrice: {
    id: 'RailcardHeader.productPrice',
    defaultMessage: 'Product Price',
  },
  seasonValidity: {
    id: 'RailcardHeader.seasonValidity',
    defaultMessage: 'Seasons Valid Until',
  },
  duration: {
    id: 'RailcardHeader.duration',
    defaultMessage: 'Duration',
  },
  travelDays: {
    id: 'RailcardHeader.travelDays',
    defaultMessage: 'Travel Days',
  },
});
