import { defineMessages } from 'react-intl';

export default defineMessages({
  bookingInformation: {
    id: 'BookingSummaryHeader.bookingInformation',
    defaultMessage:
      '{isTravelcardProduct, select, true {{localAreaValidity}} other {{from} to {to}}}',
  },
  railcardBookingInformation: {
    id: 'BookingSummaryHeader.railcardBookingInformation',
    defaultMessage: '{name}',
  },
  booking: {
    id: 'BookingSummaryHeader.booking',
    defaultMessage: 'Booking {number}',
  },
  railcardValidUntil: {
    id: 'BookingSummaryHeader.railcardValidUntil',
    defaultMessage: 'until {date}',
  },
});
