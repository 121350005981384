import { defineMessages } from 'react-intl';

export default defineMessages({
  orderNote: {
    id: 'OrderNotes.OrderNoteForm.orderNote',
    defaultMessage: 'Order note',
  },
  notice: {
    id: 'OrderNotes.OrderNoteForm.notice',
    defaultMessage: 'Notes will be logged against order in TCC',
  },
  noteRequired: {
    id: 'OrderNotes.OrderNoteForm.noteRequired',
    defaultMessage: 'You must enter some comments in the note field.',
  },
  logCall: {
    id: 'OrderNotes.OrderNoteForm.logCall',
    defaultMessage: 'Log Call',
  },
  contactReason: {
    id: 'OrderNotes.OrderNoteForm.ContactReason',
    defaultMessage: 'Contact reason (two-tier)',
  },
  missingCallId: {
    id: 'OrderNotes.OrderNoteForm.missingCallId',
    defaultMessage:
      'There was an error copying to Freshdesk. Please create a new Ticket in Freshdesk using a ticket template.',
  },
  sameCallId: {
    id: 'OrderNotes.OrderNoteForm.sameCallId',
    defaultMessage: `You'll be logging this call against the same call id that was used for the previous Log Call.`,
  },
  freshdeskTicketNotAvailable: {
    id: 'OrderNotes.OrderNoteForm.freshdeskTicketNotAvailable',
    defaultMessage:
      'Freshdesk ticket may take longer than usual to create. Please wait before you proceed.',
  },
});
