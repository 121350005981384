import React from 'react';

import ManualEntryConsoleEdit from '@contactcentre-web/manual-entry-console-edit/ManualEntryConsoleEdit';
import PageContainer from '@contactcentre-web/common/PageContainer/PageContainer';

import styles from './styles';

const ManualEntryConsoleEditPage = () => (
  <PageContainer styleSheet={styles.container}>
    <ManualEntryConsoleEdit />
  </PageContainer>
);

export default ManualEntryConsoleEditPage;
