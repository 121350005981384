import { StyleSheet } from 'aphrodite/no-important';
import {
  typography,
  spacers,
  fonts,
  borderRadius,
} from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

const failed = {
  color: '#ff585f',
  backgroundColor: '#ffeff0',
};

export default StyleSheet.create({
  container: {
    width: '100%',
  },
  header: {
    paddingTop: spacers.dp_s24,
    display: 'flex',
    flexDirection: 'row',
    background: '#fff',
    padding: `${spacers.dp_s24} ${spacers.dp_s64}`,
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  row: {
    flex: 1,
  },
  title: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    color: colors.gray.alpha30,
    textTransform: 'uppercase',
    marginBottom: spacers.dp_s4,
    fontWeight: fonts.dp_font_weight_semibold,
  },
  directions: {
    width: '33%',
  },
  direction: {
    fontFamily: typography.dp_title2_font_family,
    fontSize: typography.dp_title2_font_size,
    fontWeight: fonts.dp_font_weight_bold,
    lineHeight: typography.dp_title2_line_height,
    letterSpacing: '-0.3px', // TODO: replace with depot-web typography when updated in depot-web
    color: colors.gray.alpha80,
  },
  arrow: {
    opacity: 0.5,
    marginLeft: spacers.dp_s4,
  },
  returnLabel: {
    color: colors.gray.alpha54,
    fontWeight: fonts.dp_font_weight_regular,
  },
  otherItems: {
    maxWidth: '30%',
  },
  value: {
    wordWrap: 'break-word',
    paddingRight: spacers.dp_s16,
  },
  dateValue: {
    color: colors.gray.alpha54,
  },
  price: {
    textAlign: 'right',
    fontSize: typography.dp_body_font_size,
    color: colors.gray.alpha80,
  },
  inventoryPrice: {
    fontStyle: 'italic',
    fontSize: typography.dp_small_font_size_m,
    color: colors.gray.alpha54,
  },
  link: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    display: 'inline-block',
    backgroundColor: colors.info.light,
    color: colors.info.dark,
    fontWeight: fonts.dp_font_weight_bold,
    margin: `${spacers.dp_s4} 0 0 -${spacers.dp_s8}`,
    padding: `${spacers.dp_s4} ${spacers.dp_s8}`,
    cursor: 'pointer',
    ':visited': {
      color: colors.info.dark,
    },
    borderRadius: borderRadius.dp_r8,
  },

  fulfilmentStatus: {
    borderRadius: borderRadius.dp_r12,
    padding: `0 ${spacers.dp_s8}`,
    width: 'max-content',
    fontWeight: fonts.dp_font_weight_bold,
  },

  status_complete: {
    color: colors.positive.dark,
    backgroundColor: '#effff4',
  },

  status_failed: {
    color: failed.color,
    backgroundColor: failed.backgroundColor,
  },

  status_cancelled: {
    color: failed.color,
    backgroundColor: failed.backgroundColor,
  },

  status_abandoned: {
    color: failed.color,
    backgroundColor: failed.backgroundColor,
  },

  status_voidpending: {
    color: failed.color,
    backgroundColor: failed.backgroundColor,
  },

  status_voided: {
    color: failed.color,
    backgroundColor: failed.backgroundColor,
  },

  status_voidfailed: {
    color: failed.color,
    backgroundColor: failed.backgroundColor,
  },

  status_pending: {
    color: colors.warning.base,
    backgroundColor: colors.warning.light,
  },

  status_issuing: {
    color: colors.warning.base,
    backgroundColor: colors.warning.light,
  },

  status_unknown: {
    color: colors.gray.alpha80,
    backgroundColor: colors.gray.alpha4,
  },

  status_unspecified: {
    color: colors.gray.alpha80,
    backgroundColor: colors.gray.alpha4,
  },

  tooltip_complete: {
    cursor: 'pointer',
    border: `1px dashed ${colors.positive.dark}`,
  },

  tooltip_failed: {
    cursor: 'pointer',
    border: `1px dashed ${colors.negative.dark}`,
  },

  fulfilmentStatusErrorList: {
    'list-style-type': 'none',
  },
});
