import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Compensation.title',
    defaultMessage: 'Compensation',
  },
  requestCompensationNotPossible: {
    id: 'Compensation.requestCompensationNotPossible',
    defaultMessage:
      'It is not possible to request compensation on this order because no payment has been taken',
  },
  promoDiscountApplied: {
    id: 'Compensation.promoDiscountApplied',
    defaultMessage:
      'A discount code has been applied. Please check payment summary and only refund what customer has paid in cash. Discount amount should not be refunded.',
  },
  buttonBack: {
    id: 'Compensation.button_back',
    defaultMessage: 'Go back to <b>all approvals</b>',
  },
  requestingAgentAlert: {
    id: 'Compensation.requestingAgentAlert',
    defaultMessage: 'This compensation request was processed by {agentName} at {time} on {date}',
  },
});
