import { defineMessages } from 'react-intl';

export default defineMessages({
  modalTitle: {
    id: 'JourneyItemModal.modalTitle',
    defaultMessage: 'Journey summary',
  },
  modalSubTitle: {
    id: 'JourneyItemModal.modalSubTitle',
    defaultMessage: '{date} • {duration} • {type}',
  },
  destination: {
    id: 'JourneyItemModal.destination',
    defaultMessage: 'Train to {destination} • {carrier}',
  },
  stops: {
    id: 'JourneyItemModal.stops',
    defaultMessage: '{duration} • {stopsNumber} stops',
  },
  changingAt: {
    id: 'JourneyItemModal.changingAt',
    defaultMessage: 'Change at {station}',
  },
});
