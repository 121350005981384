import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import Tooltip from '../Tooltip';

import styles from './styles';
import messages from './messages';

const FulfilmentStatusWithErrors = (errors, anchor) => (
  <Tooltip anchor={anchor} orientation="Below">
    <ul className={css(styles.fulfilmentStatusErrorList)}>
      {errors.map((x, i) => (
        <li key={i}>{[x.code, x.detail].join(': ')}</li>
      ))}
    </ul>
  </Tooltip>
);

const FulfilmentStatus = (props) => {
  const { status, errors } = props;

  const anchorCss = (status && styles[`status_${status.toLowerCase()}`]) || styles.status_unknown;

  const hasErrors = errors && errors.length;
  const toolTipCss =
    hasErrors && (styles[`tooltip_${status.toLowerCase()}`] || styles.tooltip_complete);

  const message = status ? messages[status.toLowerCase()] : messages.unknown;

  const anchor = (
    <span className={css(styles.fulfilmentStatus, anchorCss, toolTipCss)}>
      {message && <FormattedMessage {...message} />}
      {!message && <>{status.toUpperCase()}</>}
    </span>
  );

  return hasErrors ? FulfilmentStatusWithErrors(errors, anchor) : anchor;
};

FulfilmentStatus.propTypes = {
  status: PropTypes.string,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      detail: PropTypes.string.isRequired,
    })
  ),
};

export default FulfilmentStatus;
