import { StyleSheet } from 'aphrodite/no-important';
import {
  typography,
  spacers,
  borderRadius,
  fonts,
} from '@trainline/depot-web/css/themes/trainline';

// non trainline colours
const lightGray = '#e0e0e0';
const transparentLightGray = 'rgba(140, 157, 161, 0.5)';
const transparentGray = 'rgba(140, 157, 161, 0.04)';

export default StyleSheet.create({
  commentIcon: {
    marginRight: spacers.dp_s8,
  },
  container: {
    alignItems: 'center',
    background: transparentGray,
    border: `solid 1px ${lightGray}`,
    borderRadius: borderRadius.dp_r4,
    display: 'flex',
    marginTop: spacers.dp_s32,
    marginBottom: spacers.dp_s32,
    padding: spacers.dp_s16,
    wordBreak: 'break-word',
  },
  defaultExpandButton: {
    alignItems: 'center',
    display: 'flex',
    fontSize: typography.dp_mini_font_size_m,
    width: '38%',
  },
  expandedButton: {
    backgroundColor: transparentLightGray,
  },
  message: {
    fontSize: typography.dp_small_font_size_m,
    fontWeight: fonts.dp_font_weight_regular,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    maxWidth: '130%',
    paddingRight: spacers.dp_s16,
    width: '130%',
  },
});
