import { defineMessages } from 'react-intl';

export default defineMessages({
  changes: {
    id: 'JourneyItem.changes',
    defaultMessage: '{changes, plural, one {# change} other {# changes}}',
  },
  direct: {
    id: 'JourneyItem.direct',
    defaultMessage: 'Direct',
  },
});
