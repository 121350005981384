import React, { FunctionComponent } from 'react';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';

import styles from './styles';

interface Props {
  styleSheet?: StyleDeclarationValue;
}

const HorizontalRule: FunctionComponent<Props> = ({ styleSheet }) => (
  <hr className={css(styles.root, styleSheet)} />
);

export default HorizontalRule;
