import React, { useState, useRef } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage, useIntl } from 'react-intl';
import { ControlledMenu, MenuHeader, MenuItem, MenuGroup } from '@szhsin/react-menu';
import { IconChevronDown, IconClose } from '@trainline/depot-web';
import { equals } from 'ramda';
import '@szhsin/react-menu/dist/index.css';

import { addPageAction } from '@contactcentre-web/utils/tracker';

import Button from '../../../Button';
import Checkbox from '../../../Checkbox';
import { getFilteredBookings } from '../../utils';
import { CustomerBooking, TicketTypeFilters, BookingStatusFilters, FilterType } from '../../types';

import './reactMenuOverrides.css';
import styles from './styles';
import messages from './messages';

interface Props {
  bookings: Array<CustomerBooking>;
  filteredBookings: Array<CustomerBooking>;
  appliedFilters: Set<FilterType>;
  addFilters: (filters: Set<FilterType>) => void;
}

const FilterMenu = ({ bookings, filteredBookings, appliedFilters, addFilters }: Props) => {
  const ref = useRef(null);
  const { formatMessage } = useIntl();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<Set<FilterType>>(new Set());

  const bookingsWithAppliedFilters = filteredBookings.map(({ orderReference }) => orderReference);
  const bookingsWithSelectedFilters = getFilteredBookings(bookings, selectedFilters).map(
    ({ orderReference }) => orderReference
  );

  const bookingsCount =
    selectedFilters.size > 0 ? bookingsWithSelectedFilters.length : bookings.length;
  const isShowBookingsButtonDisabled =
    bookingsCount === 0 || equals(bookingsWithAppliedFilters, bookingsWithSelectedFilters);

  const onSetSelectedFilter = (filter: FilterType) =>
    setSelectedFilters(() => {
      selectedFilters.has(filter) ? selectedFilters.delete(filter) : selectedFilters.add(filter);
      return new Set(selectedFilters);
    });

  return (
    <>
      <Button
        variant="tertiary"
        size="small"
        buttonRef={ref}
        onClick={() => {
          if (!isMenuOpen) {
            setSelectedFilters(new Set(appliedFilters));
          }
          setMenuOpen(!isMenuOpen);
        }}
        styleSheet={isMenuOpen ? [styles.button, styles.filtersButtonOpen] : styles.button}
      >
        <FormattedMessage
          {...messages.filters}
          values={{ hasFiltersApplied: appliedFilters.size > 0 }}
        />
        {isMenuOpen ? (
          <IconClose className={css(styles.icon)} />
        ) : (
          <IconChevronDown className={css(styles.icon)} />
        )}
      </Button>
      <ControlledMenu anchorRef={ref} isOpen={isMenuOpen} align="end" className={css(styles.menu)}>
        <MenuHeader className={css(styles.menuHeader)}>
          <FormattedMessage {...messages.filterBy} />
        </MenuHeader>
        <MenuGroup className={css(styles.filters)}>
          <MenuGroup>
            <MenuHeader className={css(styles.menuSubHeader)}>
              <FormattedMessage {...messages.ticketType} />
            </MenuHeader>
            {Object.values(TicketTypeFilters).map((ticketType) => (
              <MenuItem className={css(styles.menuItem)} key={ticketType}>
                <Checkbox
                  label={formatMessage(messages[ticketType])}
                  input={{
                    name: ticketType,
                    value: selectedFilters.has(ticketType),
                    onChange: () => onSetSelectedFilter(ticketType),
                  }}
                />
              </MenuItem>
            ))}
          </MenuGroup>
          <MenuGroup>
            <MenuHeader className={css(styles.menuSubHeader)}>
              <FormattedMessage {...messages.bookingStatus} />
            </MenuHeader>
            <MenuGroup className={css(styles.bookingStatusFilters)}>
              {Object.values(BookingStatusFilters).map((bookingStatus) => (
                <MenuItem className={css(styles.menuItem)} key={bookingStatus}>
                  <Checkbox
                    label={formatMessage(messages[bookingStatus])}
                    input={{
                      name: bookingStatus,
                      value: selectedFilters.has(bookingStatus),
                      onChange: () => onSetSelectedFilter(bookingStatus),
                    }}
                  />
                </MenuItem>
              ))}
            </MenuGroup>
          </MenuGroup>
        </MenuGroup>
        <MenuItem
          className={css(styles.menuItem)}
          onClick={() => {
            setMenuOpen(false);
            addFilters(selectedFilters);
            addPageAction(
              'filters-applied',
              Object.assign(
                {},
                ...Object.values(TicketTypeFilters).map((ticketTypeFilter) => ({
                  [ticketTypeFilter]: +selectedFilters.has(ticketTypeFilter),
                })),
                ...Object.values(BookingStatusFilters).map((bookingStatusFilter) => ({
                  [bookingStatusFilter]: +selectedFilters.has(bookingStatusFilter),
                }))
              )
            );
          }}
          disabled={isShowBookingsButtonDisabled}
        >
          <Button
            variant="primary"
            size="small"
            styleSheet={styles.showBookingsButton}
            disabled={isShowBookingsButtonDisabled}
          >
            <FormattedMessage {...messages.showBookings} values={{ bookingsCount }} />
          </Button>
        </MenuItem>
      </ControlledMenu>
    </>
  );
};

export default FilterMenu;
