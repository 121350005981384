import { defineMessages } from 'react-intl';

export default defineMessages({
  totalToRefund: {
    id: 'PaymentTotal.totalToRefund',
    defaultMessage: 'Total to refund',
  },
  notApplicable: {
    id: 'PaymentTotal.notApplicable',
    defaultMessage: 'N/A',
  },
});
