import React, { FunctionComponent } from 'react';
import { css } from 'aphrodite/no-important';
import { IconCheck, IconClose } from '@trainline/depot-web';

import Tooltip from '@contactcentre-web/common/Tooltip';

import styles from './styles';

interface Props {
  isRefundable: boolean;
}

const RefundableCheckIcons: FunctionComponent<Props> = ({ isRefundable, children }) =>
  isRefundable ? (
    <span className={css([styles.refundableRoundIcon])}>
      <IconCheck testId="checkIcon" />
    </span>
  ) : children ? (
    <Tooltip
      anchor={
        <span className={css([styles.refundableRoundIcon, styles.refundableRoundIconCross])}>
          <IconClose testId="crossIcon" />
        </span>
      }
      orientation="Above"
    >
      {children}
    </Tooltip>
  ) : (
    <span className={css([styles.refundableRoundIcon, styles.refundableRoundIconCross])}>
      <IconClose testId="crossIcon" />
    </span>
  );

export default RefundableCheckIcons;
