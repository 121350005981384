import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { StatusMessage } from '@trainline/depot-web';

import Link from '@contactcentre-web/common/Link';

import { selectors } from '../../../../module';

import messages from './messages';
import styles from './styles';

export const ConvertedOrderAlert = ({ link }) =>
  link == null ? null : (
    <div className={css(styles.notification)}>
      <StatusMessage status="info">
        <FormattedMessage
          {...messages.convertedByBooking}
          values={{
            link: (
              <Link linkType="internal" to={link}>
                <FormattedMessage {...messages.original} />
              </Link>
            ),
          }}
        />
      </StatusMessage>
    </div>
  );

ConvertedOrderAlert.propTypes = {
  link: PropTypes.string,
};
const mapStateToProps = (state) => ({
  link: selectors.getBookingsWithConvertedOrder(state.orders.order),
});

const ConvertedOrderAlertContainer = connect(mapStateToProps)(ConvertedOrderAlert);

ConvertedOrderAlertContainer.displayName = 'ConvertedOrderAlert';
export default ConvertedOrderAlertContainer;
