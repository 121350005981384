import React, { FunctionComponent } from 'react';
import { css } from 'aphrodite/no-important';
import { IconClose, StatusMessage } from '@trainline/depot-web';

import Button from '../Button';

import styles from './styles';

const getStatusMessageType = (type: string) => {
  switch (type) {
    case 'warning':
      return 'warning';
    case 'error':
      return 'negative';
    case 'success':
      return 'positive';
    default:
      return 'info';
  }
};

export interface Props {
  type: string;
  onClick?: React.EventHandler<any>;
}

const NotificationBox: FunctionComponent<Props> = ({ type, children, onClick }) => (
  <div className={css(styles.notificationBox)}>
    <StatusMessage status={getStatusMessageType(type)}>
      <div className={css(styles.notificationContent)}>
        <div>{children}</div>
        {onClick && (
          <Button variant="ghost" onClick={onClick}>
            <IconClose />
          </Button>
        )}
      </div>
    </StatusMessage>
  </div>
);

export default NotificationBox;
