import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import refreshPnrReducer from '@contactcentre-web/refresh-pnr/module';
import customerOrderReducer from '@contactcentre-web/customer-order/module';
import featuresReducer from '@contactcentre-web/feature-flags/module';
import editUserReducer from '@contactcentre-web/edit-user/module';
import replaceBookingReducer from '@contactcentre-web/replace-booking/module';
import resendEmailReducer from '@contactcentre-web/resend-email/module';
import currencyConverterReducer from '@contactcentre-web/currency-converter/module';
import orderHistoryReducer from '@contactcentre-web/order-history/module';
import changeOfJourneyReducer from '@contactcentre-web/change-of-journey/module';
import cojRedirect from '@contactcentre-web/coj-redirect/module';
import moveOrderReducer from '@contactcentre-web/move-order/module';
import orderActionsReducer from '@contactcentre-web/order-actions/reducer';
import orderNotesReducer from '@contactcentre-web/order-notes/reducer';
import detaxeReducer from '@contactcentre-web/detaxe/reducer';
import refundsFormReducer from '@contactcentre-web/refunds/common/module';
import termsAndConditionsReducer from '@contactcentre-web/refunds/TermsAndConditions/reducer';
import sameDayVoidReducer from '@contactcentre-web/refunds/SameDayVoid/reducer';
import discretionaryReducer from '@contactcentre-web/refunds/Discretionary/reducer';
import compensationApprovalsReducer from '@contactcentre-web/compensation-approvals/module';
import refundApprovalsReducer from '@contactcentre-web/refund-approvals/module';
import supersededBookingReducer from '@contactcentre-web/superseded-booking/module';
import customerBookingsReducer from '@contactcentre-web/customer-bookings/module';
import searchResultsReducer from '@contactcentre-web/search-results/module';
import usersReducer from '@contactcentre-web/authentication/redux/module';
import profileReducer from '@contactcentre-web/authentication/redux/profile/reducers';
import managedGroupReducer from '@contactcentre-web/authentication/redux/managedGroup/module';
import compensationReducer from '@contactcentre-web/compensation/reducer';
import appBarReducer from '@contactcentre-web/header/module';
import sidebarReducer from '@contactcentre-web/sidebar/module';
import customerNotesReducer from '@contactcentre-web/sidebar/components/CustomerNotes/module';
import editCustomerProfileReducer from '@contactcentre-web/edit-customer-profile/module';
import customerReducer from '@contactcentre-web/customer/module';

const createReducer = () =>
  combineReducers({
    features: featuresReducer,
    form: formReducer,
    users: usersReducer,
    orders: customerOrderReducer,
    simpleSearch: searchResultsReducer,
    customer: customerReducer,
    customerNotes: customerNotesReducer,
    customerBookingSummaries: customerBookingsReducer,
    appBarMessage: appBarReducer,
    editUser: editUserReducer,
    sidebar: sidebarReducer,
    replaceBooking: replaceBookingReducer,
    currencyConverter: currencyConverterReducer,
    resendEmail: resendEmailReducer,
    orderHistory: orderHistoryReducer,
    refreshPnr: refreshPnrReducer,
    supersededBooking: supersededBookingReducer,
    refundsApproval: refundApprovalsReducer,
    discretionaryRefunds: discretionaryReducer,
    sdv: sameDayVoidReducer,
    refundsForm: refundsFormReducer,
    changeOfJourney: changeOfJourneyReducer,
    refundsTermsAndConditions: termsAndConditionsReducer,
    cojRedirect,
    customerOrderCompensation: compensationReducer,
    editCustomerProfile: editCustomerProfileReducer,
    compensationApprovals: compensationApprovalsReducer,
    customerOrderMove: moveOrderReducer,
    orderActions: orderActionsReducer,
    orderNotes: orderNotesReducer,
    selectManagedGroup: managedGroupReducer,
    profile: profileReducer,
    detaxe: detaxeReducer,
  });

export default createReducer;
