import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'CompensationRejectionPrompt.title',
    defaultMessage: 'Reject customer compensation?',
  },
  reason: {
    id: 'CompensationRejectionPrompt.reason',
    defaultMessage: 'Reason for rejection',
  },
  reasonPlaceholder: {
    id: 'CompensationRejectionPrompt.reasonPlaceholder',
    defaultMessage: 'Select',
  },
  reasonRequired: {
    id: 'CompensationRejectionPrompt.reasonRequired',
    defaultMessage: 'Please select a reason for rejection',
  },
  incorrectAmountReason: {
    id: 'CompensationRejectionPrompt.incorrectAmountReason',
    defaultMessage: 'Incorrect amount',
  },
  incorrectRefundCodeReason: {
    id: 'CompensationRejectionPrompt.incorrectRefundCodeReason',
    defaultMessage: 'Incorrect refund code',
  },
  incompleteNotesReason: {
    id: 'CompensationRejectionPrompt.incompleteNotesReason',
    defaultMessage: 'Incomplete notes',
  },
  otherReason: {
    id: 'CompensationRejectionPrompt.otherReason',
    defaultMessage: 'Other',
  },
  notes: {
    id: 'CompensationRejectionPrompt.notes',
    defaultMessage: 'Notes',
  },
  notesRequired: {
    id: 'CompensationRejectionPrompt.notesRequired',
    defaultMessage: 'Please specify some notes to help the agents understand your decision',
  },
  buttonReject: {
    id: 'CompensationRejectionPrompt.buttonReject',
    defaultMessage: 'Reject request',
  },
  buttonCancel: {
    id: 'CompensationRejectionPrompt.buttonCancel',
    defaultMessage: 'Cancel',
  },
});
