import { StyleSheet } from 'aphrodite/no-important';
import { fonts, spacers, palettes } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    marginBottom: spacers.dp_s24,
  },
  loader: {
    display: 'inline-flex',
  },
  totalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: spacers.dp_s4,
  },
  totalNumber: {
    fontWeight: fonts.dp_font_weight_semibold,
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: spacers.dp_s24,
    marginBottom: spacers.dp_s24,
  },
  defaultTab: {
    borderColor: palettes.dp_comp_input_binary_selected_border_base,
    color: palettes.dp_comp_input_binary_selected_background_base,
    backgroundColor: palettes.dp_comp_input_binary_background_base,
    ':first-child': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    ':last-child': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  activeTab: {
    borderColor: palettes.dp_comp_input_binary_selected_border_base,
    backgroundColor: palettes.dp_comp_input_binary_selected_background_base,
    color: palettes.dp_app_text_inverted,
    ':hover': {
      backgroundColor: palettes.dp_comp_input_binary_selected_background_hover,
    },
    ':active': {
      backgroundColor: palettes.dp_comp_input_binary_selected_background_pressed,
    },
  },
  download: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  downloadButton: {
    display: 'flex',
    gap: spacers.dp_s12,
  },
  donwloadError: {
    color: palettes.dp_app_text_negative,
    marginTop: spacers.dp_s4,
  },
  reasonFilter: {
    maxWidth: '500px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});
