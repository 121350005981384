import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import qs from 'query-string';
import { Switch, Route, useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import useForceUpdate from 'use-force-update';
import { StatusMessage } from '@trainline/depot-web';

import Loader from '@contactcentre-web/common/Loader';
import * as productsSelectors from '@contactcentre-web/redux-common/selectors/products';
import orderActionKeys, {
  allActions,
  allActionsExcept,
} from '@contactcentre-web/order-actions/OrderActionKeys';
import OrderHistory from '@contactcentre-web/order-history/OrderHistory';
import ChangeOfJourney from '@contactcentre-web/change-of-journey/ChangeOfJourney';
import { getUserFeatures } from '@contactcentre-web/authentication/redux/selectors';
import PageContainer from '@contactcentre-web/common/PageContainer';
import SameDayVoidContainer from '@contactcentre-web/refunds/SameDayVoid/SameDayVoid';
import TermsAndConditionsContainer from '@contactcentre-web/refunds/TermsAndConditions/TermsAndConditions';
import DiscretionaryContainer from '@contactcentre-web/refunds/Discretionary/Discretionary';
import { actions as orderHistoryActions } from '@contactcentre-web/order-history/module';
import { useOrderHistory } from '@contactcentre-web/hooks/api/useOrderHistory';
import UpdateRefundStatus from '@contactcentre-web/update-refund-status/UpdateRefundStatus';
import Compensation from '@contactcentre-web/compensation/Compensation';

import { actions, selectors } from './module';
import OrderDetails from './components/OrderDetails';
import MovedOrderAlert from './components/MovedOrderAlert';
import PaymentStatus from './components/PaymentStatus';
import ExchangeFailed from './components/ExchangeFeedback/ExchangeFailed';
import ExchangeSuccessful from './components/ExchangeFeedback/ExchangeSuccessful';
import CustomerOrderHeader from './components/CustomerOrderHeader';
import OrderStatus from './components/OrderStatus';
import messages from './messages';
import styles from './styles';

const CONTEXTS = {
  orderDetails: {
    path: '',
    actions: allActionsExcept(orderActionKeys.viewBookings),
    showOrderSummary: true,
    showBanners: true,
  },
  orderHistory: {
    path: '/history',
    actions: allActionsExcept(orderActionKeys.orderHistory),
    showOrderSummary: true,
  },
  refundsSameDayVoid: {
    path: '/sdv',
    actions: allActionsExcept(orderActionKeys.cancelOrder),
  },
  refundsTermsAndConditions: {
    path: '/ts-and-cs-refund',
    actions: allActions(),
  },
  refundsDiscretionary: {
    path: '/discretionary/(requests)?/:quoteId?',
    actions: allActions(),
  },
  compensations: {
    path: '/compensation/:compensationId?',
    actions: allActionsExcept(orderActionKeys.compensation),
  },
  changeOfJourney: {
    path: '/coj',
    actions: allActionsExcept(orderActionKeys.changeOfJourney),
  },
  updateStatus: {
    path: '/update-status/:refundId',
    actions: allActions(),
  },
};

const withOrderHeader = (
  context,
  pathname,
  {
    order,
    customerEmail,
    products,
    bookingIndexConvertedAnyCard,
    passengersByType,
    cancelRailcardStatus,
    cancelledRailcardName,
    onReloadOrder,
    orderOnlyContainsRailcardProducts,
    orderOnlyContainsSeasonProducts,
    orderContainsSeasonProduct,
    transactionSummary,
    features,
  }
) => (
  <div className={css(styles.orderHeaderContainer)}>
    <CustomerOrderHeader
      {...order}
      displayOptions={{
        actions: context.actions,
        showOrderSummary: context.showOrderSummary,
        showBanners: !!context.showBanners,
      }}
      products={products}
      path={pathname}
      customerEmail={customerEmail}
      bookingIndexConvertedAnyCard={bookingIndexConvertedAnyCard}
      passengersByType={passengersByType}
      cancelRailcardStatus={cancelRailcardStatus}
      cancelledRailcardName={cancelledRailcardName}
      onReloadOrder={onReloadOrder}
      orderOnlyContainsRailcardProducts={orderOnlyContainsRailcardProducts}
      orderOnlyContainsSeasonProducts={orderOnlyContainsSeasonProducts}
      orderContainsSeasonProduct={orderContainsSeasonProduct}
      transactionSummary={transactionSummary}
      features={features}
    />
  </div>
);

export const CustomerOrder = ({
  order,
  isLoadingOrder,
  error,
  loadOrder,
  loadDelayRepayClaim,
  loadTransactionSummary,
  loadTimetables,
  loadOrderHistory,
  customerEmail,
  isLoadingTransactionSummary,
  transactionSummary,
  features,
  ...rest
}) => {
  const {
    path,
    params: { orderId, customerId },
  } = useRouteMatch();
  const { search, pathname } = useLocation();
  const {
    bookingchanged,
    moved,
    'removed-collection-restrictions': bookingIndexConvertedAnyCard,
  } = qs.parse(search);
  const forceUpdate = useForceUpdate();
  const history = useHistory();
  const loadOrderRef = React.useRef(loadOrder);
  const loadDelayRepayClaimRef = React.useRef(loadDelayRepayClaim);
  const loadTransactionSummaryRef = React.useRef(loadTransactionSummary);
  const loadTimetablesRef = React.useRef(loadTimetables);
  const loadOrderHistoryRef = React.useRef(loadOrderHistory);
  useOrderHistory(order?.id, order?.orderReference);

  React.useEffect(() => {
    loadOrderRef.current(customerId, orderId);

    return history.listen(() => {
      forceUpdate();
    });
  }, [orderId, customerId, loadOrderRef]);

  React.useEffect(() => {
    loadDelayRepayClaimRef.current(orderId);
  }, [orderId, loadDelayRepayClaimRef]);

  React.useEffect(() => {
    loadTransactionSummaryRef.current(orderId);
  }, [orderId, loadTransactionSummaryRef]);

  React.useEffect(() => {
    loadTimetablesRef.current(orderId);
  }, [orderId, loadTimetablesRef]);

  React.useEffect(() => {
    loadOrderHistoryRef.current(orderId);
  }, [orderId, loadOrderHistoryRef]);

  if (error) {
    return (
      <PageContainer>
        <StatusMessage status="negative">
          <FormattedMessage {...messages.error} values={{ orderId }} />
        </StatusMessage>
      </PageContainer>
    );
  }

  const contextRouteMatch = useRouteMatch({
    path: Object.keys(CONTEXTS).map((c) => `${path}${CONTEXTS[c].path}`),
    exact: true,
  });

  const currentContext = !contextRouteMatch
    ? CONTEXTS.orderDetails
    : Object.values(CONTEXTS).find((c) => `${path}${c.path}` === contextRouteMatch.path);

  const orderHeaderRenderer = () =>
    withOrderHeader(currentContext, pathname, {
      order,
      orderId,
      customerId,
      customerEmail,
      bookingIndexConvertedAnyCard,
      bookingchanged,
      moved,
      onReloadOrder: () => loadOrder(customerId, orderId),
      transactionSummary,
      features,
      ...rest,
    });

  const getRoutePath = (context) => `${path}${context.path}`;

  const renderOrderStatus = () => (
    <>
      <OrderStatus />
      <PaymentStatus orderStatus={order.status} />
      {bookingchanged === 'true' && <ExchangeSuccessful />}
      {bookingchanged === 'false' && <ExchangeFailed />}
      {moved !== undefined && (
        <MovedOrderAlert
          customerId={customerId}
          orderReference={orderId}
          emailAddress={customerEmail}
        />
      )}
    </>
  );

  const isLoadingOrderDetails = isLoadingOrder || isLoadingTransactionSummary;

  return (
    <div className={css(styles.pageContainerWrapper)}>
      {isLoadingOrderDetails && (
        <div className={css(styles.loaderContainer)}>
          <Loader />
        </div>
      )}
      {/* TODO: Move the routes below to CustomerPage component once we update to react router v6 */}
      {!isLoadingOrderDetails && order && (
        <Switch>
          <Route path={getRoutePath(CONTEXTS.orderHistory)} exact>
            {renderOrderStatus()}
          </Route>
          <Route path={getRoutePath(CONTEXTS.orderDetails)} exact>
            {renderOrderStatus()}
          </Route>
        </Switch>
      )}
      {!isLoadingOrderDetails && order && orderHeaderRenderer()}
      {/* this below  is temporary, will be deleted in near future  */}
      {order?.noRefundFee && (
        <PageContainer>
          <StatusMessage status="warning">
            We have processed a refund of the fee for this customer via the batch refund process. Do
            not process any additional fee refunds.
          </StatusMessage>
        </PageContainer>
      )}
      {/* this below  is temporary, will be deleted in near future  */}
      {order?.noRefund && (
        <PageContainer>
          <StatusMessage status="warning">
            We have processed a refund for this customer via the batch refund process. Do not
            process any additional refunds.
          </StatusMessage>
        </PageContainer>
      )}
      {/* this below  is temporary, will be deleted in near future  */}
      {order?.toRefundCurrencyExchangeFeeAuto && (
        <PageContainer>
          <StatusMessage status="warning">
            This customer has been charged in GBP for this order and as such we have also charged a
            currency exchange fee and their bank may have charged a fee for currency conversion.
            This may have been incorrect and not what the customer was expecting. Our currency
            exchange fee and a £4 gesture of goodwill to cover any bank fees are being automatically
            refunded to the customer in the next 5 to 7 working days. This fee refund will not show
            in TCC because it has been processed in Finance.
          </StatusMessage>
        </PageContainer>
      )}
      {/* this below  is temporary, will be deleted in near future  */}
      {order?.toRefundCurrencyExchangeFeeManual && (
        <PageContainer>
          <StatusMessage status="warning">
            This customer has been charged in GBP for this order and as such we have also charged a
            currency exchange fee and their bank may have charged a fee for currency conversion.
            This may have been incorrect and not what the customer was expecting. A compensation
            refund on a € order in the customers account or via Virtual Terminal can be processed
            for bank fees plus €4 for exchange fees under reason: ‘EU {'>'} Trainline {'>'} Refund
            Fees’.
          </StatusMessage>
        </PageContainer>
      )}
      {/* this below  is temporary, will be deleted in near future  */}
      {order?.duplicateFee && (
        <PageContainer>
          <StatusMessage status="warning">
            A technical error resulted in this customer being incorrectly charged two refund fees
            when this order was refunded. The duplicate refund fee has been manually refunded to the
            customer outside of Agent Tool (TCC). Do not process any further refunds for fees on
            this order.
          </StatusMessage>
        </PageContainer>
      )}
      {!isLoadingOrderDetails && order && (
        <Switch>
          <Route path={getRoutePath(CONTEXTS.orderHistory)} exact>
            <OrderHistory />
          </Route>
          <Route path={getRoutePath(CONTEXTS.refundsSameDayVoid)} exact>
            <SameDayVoidContainer />
          </Route>
          <Route path={getRoutePath(CONTEXTS.refundsTermsAndConditions)} exact>
            <TermsAndConditionsContainer />
          </Route>
          <Route path={getRoutePath(CONTEXTS.refundsDiscretionary)}>
            <DiscretionaryContainer />
          </Route>
          <Route path={getRoutePath(CONTEXTS.compensations)}>
            <Compensation />
          </Route>
          <Route path={getRoutePath(CONTEXTS.changeOfJourney)} exact>
            <ChangeOfJourney />
          </Route>
          <Route path={getRoutePath(CONTEXTS.updateStatus)} exact>
            <UpdateRefundStatus />
          </Route>
          <Route path={getRoutePath(CONTEXTS.orderDetails)}>
            <OrderDetails />
          </Route>
        </Switch>
      )}
    </div>
  );
};

CustomerOrder.propTypes = {
  loadOrder: PropTypes.func.isRequired,
  order: PropTypes.object,
  error: PropTypes.object,
  isLoadingOrder: PropTypes.bool.isRequired,
  products: PropTypes.array.isRequired,
  customerEmail: PropTypes.string,
  loadDelayRepayClaim: PropTypes.func.isRequired,
  passengersByType: PropTypes.array,
  cancelRailcardStatus: PropTypes.string,
  cancelledRailcardName: PropTypes.string,
  loadTransactionSummary: PropTypes.func.isRequired,
  orderOnlyContainsRailcardProducts: PropTypes.bool,
  orderOnlyContainsSeasonProducts: PropTypes.bool,
  orderContainsSeasonProduct: PropTypes.bool,
  loadTimetables: PropTypes.func.isRequired,
  isLoadingTransactionSummary: PropTypes.bool.isRequired,
  transactionSummary: PropTypes.object,
  features: PropTypes.object,
  loadOrderHistory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  customerEmail: selectors.getCustomerEmail(state.customer.selectedCustomer),
  order: selectors.getOrder(state),
  error: selectors.selectError(state),
  isLoadingOrder: selectors.isLoadingOrder(state),
  products: productsSelectors.getProducts(state),
  passengersByType: productsSelectors.getPassengersByTypeCount(state),
  cancelRailcardStatus: selectors.cancelRailcardStatus(state),
  cancelledRailcardName: selectors.cancelledRailcardName(state),
  orderOnlyContainsRailcardProducts: productsSelectors.orderOnlyContainsRailcardProducts(state),
  orderOnlyContainsSeasonProducts: productsSelectors.orderOnlyContainsSeasonProducts(state),
  orderContainsSeasonProduct: productsSelectors.orderContainsSeasonProduct(state),
  isLoadingTransactionSummary: selectors.isLoadingTransactionSummary(state),
  transactionSummary: selectors.getTransactionSummary(state),
  features: getUserFeatures(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadOrder: (customerId, orderId) => dispatch(actions.load(customerId, orderId)),
  loadDelayRepayClaim: (orderId) => dispatch(actions.delayRepayClaimLoad(orderId)),
  loadTransactionSummary: (orderId) => dispatch(actions.loadTransactionSummary(orderId)),
  loadTimetables: (orderId) => dispatch(actions.loadTimetablesAttempt(orderId)),
  loadOrderHistory: (orderId) => dispatch(orderHistoryActions.loadAttempt(orderId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOrder);
