import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { IconPaymentCard } from '@trainline/depot-web';

import priceShape from '@contactcentre-web/utils/shape/price';

import OrderPayment from './OrderPayment';
import PaymentDetails from './PaymentDetails';
import messages from './messages';
import styles from './styles';

const TransactionSummary = ({
  orderTotal,
  orderTotalBeforeDiscountsAndFees,
  products,
  fees,
  discounts,
  paymentStatus,
  paymentDate,
  paymentErrors,
  paymentTypeDetails,
  paymentServiceProvider,
  hasAmendedSeatReservation,
}) => (
  <section className={css(styles.container)}>
    <div className={css(styles.transactionSummaryContainer)}>
      <div className={css(styles.paymentDetailsInfo)}>
        <h3 className={css(styles.paymentSummaryTitle)}>
          <IconPaymentCard className={css(styles.iconTicket)} />
          <FormattedMessage {...messages.paymentSummary} />
        </h3>
        <PaymentDetails
          paymentTypeDetails={paymentTypeDetails}
          paymentServiceProvider={paymentServiceProvider}
        />
      </div>

      <div className={css(styles.paymentBlock, styles.rightAlign)}>
        <OrderPayment
          orderTotal={orderTotal}
          orderTotalBeforeDiscountsAndFees={orderTotalBeforeDiscountsAndFees}
          products={products}
          fees={fees}
          discounts={discounts}
          paymentStatus={paymentStatus}
          paymentDate={paymentDate}
          paymentErrors={paymentErrors}
          hasAmendedSeatReservation={hasAmendedSeatReservation}
        />
      </div>
    </div>
  </section>
);

TransactionSummary.propTypes = {
  orderTotal: priceShape,
  orderTotalBeforeDiscountsAndFees: priceShape,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  fees: PropTypes.arrayOf(PropTypes.object).isRequired,
  discounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  paymentStatus: PropTypes.string,
  paymentDate: PropTypes.string,
  paymentErrors: PropTypes.array,
  paymentTypeDetails: PropTypes.object,
  paymentServiceProvider: PropTypes.string,
  hasAmendedSeatReservation: PropTypes.bool,
};

export default TransactionSummary;
