import React, { Dispatch, FunctionComponent, MouseEventHandler, useRef } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { css } from 'aphrodite/no-important';
import { IconClose } from '@trainline/depot-web';

import Action from '@contactcentre-web/redux-common/types/Action';
import type State from '@contactcentre-web/redux-common/types/State';
import Button from '@contactcentre-web/common/Button';

import styles from './styles';
import ReplaceBookingForm from './components/ReplaceBookingForm/ReplaceBookingForm';
import { actions, selectors } from './module';

interface StateProps {
  replacementOrderReference?: string;
}

interface DispatchProps {
  reset: () => void;
}

interface Props extends DispatchProps, StateProps {
  onClose: MouseEventHandler<HTMLButtonElement>;
}
type Params = {
  customerId: string;
};

export const ReplaceBooking: FunctionComponent<Props> = ({
  replacementOrderReference,
  reset,
  onClose,
}) => {
  const {
    params: { customerId },
  } = useRouteMatch<Params>();

  const history = useHistory();
  const resetRef = useRef(reset);

  React.useEffect(() => {
    resetRef.current();

    return () => resetRef.current();
  }, [resetRef]);

  React.useEffect(() => {
    if (replacementOrderReference) {
      history.push(`/customers/${customerId}/bookings/${replacementOrderReference}`);
    }
  }, [replacementOrderReference]);

  return (
    <div className={css(styles.container)}>
      <ReplaceBookingForm />
      <Button
        variant="ghost"
        testId="replace-booking-close"
        onClick={onClose}
        styleSheet={styles.closeButton}
      >
        <IconClose />
      </Button>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  replacementOrderReference: selectors.getReplacementOrderReference(state.replaceBooking),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  reset: () => dispatch(actions.reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReplaceBooking);
