import React, { FunctionComponent } from 'react';

import CustomerListTitle from '../CustomerListTitle/CustomerListTitle';
import CustomerListItem from '../CustomerListItem/CustomerListItem';
import { CustomerSearchResultItem } from '../../utils/searchService';

interface Props {
  customers: Array<CustomerSearchResultItem>;
  search: string;
  onCustomerSelected: (id: string) => void;
}

const CustomerList: FunctionComponent<Props> = ({ customers, search, onCustomerSelected }) => (
  <div>
    <CustomerListTitle customersCount={customers.length} search={search} />

    <ul data-test="customerList">
      {customers.map((customer) => (
        <CustomerListItem key={customer.id} customer={customer} handleClick={onCustomerSelected} />
      ))}
    </ul>
  </div>
);

export default CustomerList;
