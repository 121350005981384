import React from 'react';

import ApprovalQueueContainer from '@contactcentre-web/virtual-terminal-approvals/ApprovalQueueContainer';
import PageContainer from '@contactcentre-web/common/PageContainer/PageContainer';
import ErrorBoundary from '@contactcentre-web/common/ErrorBoundary/ErrorBoundary';

const VirtualTerminalApprovalsPage = () => (
  <PageContainer>
    <ErrorBoundary>
      <ApprovalQueueContainer />
    </ErrorBoundary>
  </PageContainer>
);

export default VirtualTerminalApprovalsPage;
