import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { StatusMessage } from '@trainline/depot-web';

import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from '@contactcentre-web/common/Modal';
import SelectList from '@contactcentre-web/common/SelectList';
import Button from '@contactcentre-web/common/Button';
import refundReasonShape from '@contactcentre-web/utils/shape/refundReason';

import bookingMessages from '../Booking/messages';

import messages from './messages';
import styles from './styles';

const PARTIAL_RETURN_REFUND = '30ea1f71-75ad-4cac-ad82-52c4269d2a82';

const mapToSelectOptions = (item) => ({
  label: item.description,
  value: item.id,
});

function getReasons(reasons, selectedReasonCode) {
  const path = [];
  let reason = reasons.find(({ id }) => id === selectedReasonCode);
  while (reason) {
    path.push(reason.id);
    reason = reason.parent;
  }
  path.reverse();

  const [primaryReasonCode, secondaryReasonCode, tertiaryReasonCode] = path;

  return {
    primaryReasonCode,
    secondaryReasonCode,
    tertiaryReasonCode,
  };
}

export class RefundReasonModal extends React.Component {
  constructor(props) {
    super(props);

    const { reasons, value } = props;

    const reasons1 = reasons.filter(({ parent }) => !parent);
    const reasons2 = reasons1.reduce(
      (acc, { id }) => [...acc, ...reasons.filter(({ parent }) => parent && id === parent.id)],
      []
    );
    const reasons3 = reasons2.reduce(
      (acc, { id }) => [...acc, ...reasons.filter(({ parent }) => parent && id === parent.id)],
      []
    );

    this.reasons1 = reasons1;
    this.reasons2 = reasons2;
    this.reasons3 = reasons3;

    const { primaryReasonCode, secondaryReasonCode, tertiaryReasonCode } = getReasons(
      reasons,
      value
    );

    // HACK: Apparently if <select /> has a list of options on first render
    // the first one will be pre-selected, which is behaviour we don't want
    // Update the state here to trigger a re-render.
    this.state = {
      primaryReasonCodeValue: primaryReasonCode,
      secondaryReasonCodeValue: secondaryReasonCode,
      tertiaryReasonCodeValue: tertiaryReasonCode,
      filteredReasons1: this.reasons1.map(mapToSelectOptions),
      filteredReasons2: primaryReasonCode
        ? this.reasons2
            .filter(({ parent }) => parent && parent.id === primaryReasonCode)
            .map(mapToSelectOptions)
        : [],
      filteredReasons3: secondaryReasonCode
        ? this.reasons3
            .filter(({ parent }) => parent && parent.id === secondaryReasonCode)
            .map(mapToSelectOptions)
        : [],
      selectedValue: value,
    };
  }

  onLevel1SelectionUpdated(value) {
    const level2 = this.reasons2
      .filter(({ parent }) => parent && parent.id === value)
      .map(mapToSelectOptions);

    this.setState({
      primaryReasonCodeValue: value,
      secondaryReasonCodeValue: undefined,
      tertiaryReasonCodeValue: undefined,
      filteredReasons2: level2,
      filteredReasons3: [],
      selectedValue: undefined,
    });

    if (level2.length === 0) {
      this.onValueChanged(value);
    }
  }

  onLevel2SelectionUpdated(value) {
    const level3 = this.reasons3
      .filter(({ parent }) => parent && parent.id === value)
      .map(mapToSelectOptions);

    this.setState({
      secondaryReasonCodeValue: value,
      tertiaryReasonCodeValue: undefined,
      filteredReasons3: level3,
      selectedValue: undefined,
    });

    if (level3.length === 0) {
      this.onValueChanged(value);
    }
  }

  onLevel3SelectionUpdated(value) {
    this.setState({
      tertiaryReasonCodeValue: value,
    });

    this.onValueChanged(value);
  }

  onValueChanged(value) {
    this.setState({ selectedValue: value });
  }

  render() {
    const { onClosed, onConfirmed, isUsedTicketRefund, modalTitle } = this.props;
    const {
      primaryReasonCodeValue,
      secondaryReasonCodeValue,
      tertiaryReasonCodeValue,
      filteredReasons1,
      filteredReasons2,
      filteredReasons3,
      selectedValue,
    } = this.state;

    const path = [
      this.reasons1.find(({ id }) => primaryReasonCodeValue === id),
      this.reasons2.find(({ id }) => secondaryReasonCodeValue === id),
      this.reasons3.find(({ id }) => tertiaryReasonCodeValue === id),
    ].filter((i) => !!i);

    return (
      <Modal onClose={onClosed} styleSheet={{ content: styles.modalContent }}>
        <ModalHeader>
          <ModalTitle>{modalTitle ?? <FormattedMessage {...messages.title} />}</ModalTitle>
        </ModalHeader>
        <ModalBody styleSheet={styles.modalBody}>
          {secondaryReasonCodeValue === PARTIAL_RETURN_REFUND && isUsedTicketRefund && (
            <div className={css(styles.statusBox)}>
              <StatusMessage status="warning">
                <FormattedMessage {...bookingMessages.partialOpenReturnWarning} />
              </StatusMessage>
            </div>
          )}
          <div id="compensation-reasons" className={css(styles.selects)}>
            <SelectList
              key="reasons1"
              data-test-id="reasons1"
              styleSheet={{ box: styles.select }}
              options={filteredReasons1}
              value={primaryReasonCodeValue}
              onValueChanged={(val) => this.onLevel1SelectionUpdated(val)}
            />
            <SelectList
              key="reasons2"
              data-test-id="reasons2"
              styleSheet={{ box: styles.select }}
              options={filteredReasons2}
              value={secondaryReasonCodeValue}
              onValueChanged={(val) => this.onLevel2SelectionUpdated(val)}
            />
            <SelectList
              key="reasons3"
              data-test-id="reasons3"
              styleSheet={{ box: styles.select }}
              options={filteredReasons3}
              value={tertiaryReasonCodeValue}
              onValueChanged={(val) => this.onLevel3SelectionUpdated(val)}
            />
          </div>
        </ModalBody>
        <ModalFooter contentAlignment="spaceBetween">
          <span className={css(styles.reasonCode)}>
            <FormattedMessage {...messages.reasonCode} />
            <ul className={css(styles.path)}>
              {path.map(({ id, description }) => (
                <li key={id} className={css(styles.pathItem)}>
                  {description}
                </li>
              ))}
            </ul>
          </span>
          <Button
            variant="primary"
            size="large"
            id="confirm-reason-button"
            testId="confirm-button"
            disabled={!selectedValue}
            onClick={() => onConfirmed(selectedValue)}
          >
            <FormattedMessage {...messages.confirm} />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

RefundReasonModal.propTypes = {
  reasons: PropTypes.arrayOf(refundReasonShape),
  value: PropTypes.string,
  onClosed: PropTypes.func.isRequired,
  onConfirmed: PropTypes.func.isRequired,
  isUsedTicketRefund: PropTypes.bool,
  modalTitle: PropTypes.string,
};

export default RefundReasonModal;
