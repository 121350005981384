import { StyleSheet } from 'aphrodite/no-important';
import { typography, palettes, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  callingPointContent: {
    marginTop: `-${spacers.dp_s16}`,
  },
  callingPointsItem: {
    display: 'flex',
    height: '30px',
  },
  locationItemTime: {
    lineHeight: typography.dp_small_line_height,
    letterSpacing: typography.dp_small_letter_spacing,
    fontSize: typography.dp_small_font_size,
    marginRight: spacers.dp_s24,
    marginLeft: spacers.dp_s32,
  },
  locationItemTimeNoText: {
    marginLeft: spacers.dp_s96,
  },
  callingPoints: {
    position: 'relative',
  },
  callingPointMarker: {
    position: 'relative',
    paddingBottom: spacers.dp_s32,

    ':before': {
      content: '""',
      display: 'block',
      top: '11px',
      bottom: '-10px',
      left: spacers.dp_s12,
      position: 'absolute',
      borderLeft: `3px solid ${palettes.dp_brand_neutral_light}`,
    },
  },
  dash: {
    position: 'relative',
    ':before': {
      content: '""',
      position: 'absolute',
      width: '15px',
      height: '3px',
      left: '12px',
      top: '10px',
      border: `solid 2px ${palettes.dp_brand_neutral_light}`,
      borderRadius: '15%',
      backgroundColor: palettes.dp_brand_neutral_light,
    },
  },
  callingPointLocation: {
    lineHeight: typography.dp_small_line_height,
    letterSpacing: typography.dp_small_letter_spacing,
    fontSize: typography.dp_small_font_size,
    color: palettes.dp_brand_black,
    flexDirection: 'column',
    justifyContent: 'space-between',
    display: 'flex',
    height: '50px',
  },
});
