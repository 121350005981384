import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import { css } from 'aphrodite/no-important';
import { IconModeOfTransportTrain, StatusMessage } from '@trainline/depot-web';

import formatDuration from '@contactcentre-web/utils/duration';
import FormattedTimeInTz from '@contactcentre-web/common/FormattedTimeInTz/FormattedTimeInTz';
import FormattedDateInTz from '@contactcentre-web/common/FormattedDateInTz/FormattedDateInTz';
import Expandable from '@contactcentre-web/common/Expandable';
import TicketFromTo from '@contactcentre-web/common/TicketFromTo/TicketFromTo';
import { addPageAction } from '@contactcentre-web/utils/tracker';
import { Status } from '@contactcentre-web/redux-common/types/Timetable';

import Passenger from '../Passenger/Passenger';
import BoardingTooltip from '../BoardingTooltip/BoardingTooltip';
import DisruptionInfo from '../DisruptionInfo/DisruptionInfo';
import BikeReservation from '../BikeReservation/BikeReservation';

import messages from './messages';
import styles from './styles';

const CustomerBookingJourneyLeg = ({
  legNum,
  legCount,
  arriveAt,
  departAt,
  originCity,
  originStation,
  deliveryRecipient,
  destinationCity,
  destinationStation,
  passengers,
  transportInformation: { carrier, type, transportDesignation, timetableId },
  seatingPreferences,
  boardBeforeGuaranteed,
  timetable,
  vendor,
  bikeReservation,
  customerOrderTravellers,
  businessSettings,
}) => {
  const { formatMessage } = useIntl();
  const isNotOnTime = timetable && timetable.status !== Status.OnTime;
  const showDisruptionInfoTabVendors = ['ATOC', 'SNCF PAO', 'RENFE'];
  const showDisruptionInfoTab =
    showDisruptionInfoTabVendors.includes(vendor?.toUpperCase()) && (isNotOnTime || !timetable);

  const [openTab, setOpenTab] = useState('passenger-info');

  const handleDisruptionInfoClick = () =>
    setOpenTab(openTab === 'disruption-info' ? '' : 'disruption-info');

  const handlePassengerInfoClick = () => {
    if (openTab === 'passenger-info') {
      setOpenTab('');
    } else {
      setOpenTab('passenger-info');
      addPageAction('click-passenger-info');
    }
  };

  return (
    <div data-test="legInfo" className={css(styles.legInfo)}>
      <div className={css(styles.header)}>
        <IconModeOfTransportTrain className={css(styles.tripIcon)} />
        <div className={css(styles.row)}>
          <div className={css(styles.column)}>
            <span className={css(styles.grayLabel, styles.leg)}>
              <FormattedMessage {...messages.journeyLeg} values={{ legNum, legCount }} />
            </span>

            <TicketFromTo
              origin={originCity || originStation}
              destination={destinationCity || destinationStation}
            />
          </div>
          <div className={css(styles.column)}>
            <span className={css(styles.grayLabel, styles.leg)}>
              <FormattedMessage {...messages.depart} />
            </span>
            <span className={css(styles.legDate)}>
              <FormattedDateInTz
                value={departAt}
                year="numeric"
                month="short"
                day="numeric"
                weekday="short"
              />
            </span>
            <time className={css(styles.timeLabel)}>
              <FormattedTimeInTz value={departAt} hour="2-digit" minute="2-digit" />
              <BoardingTooltip boardBefore={boardBeforeGuaranteed} />
            </time>
          </div>
          <div className={css(styles.column)}>
            <span className={css(styles.grayLabel, styles.leg)}>
              <FormattedMessage {...messages.arrive} />
            </span>
            <span className={css(styles.legDate)}>
              <FormattedDateInTz
                value={arriveAt}
                year="numeric"
                month="short"
                day="numeric"
                weekday="short"
              />
            </span>
            <time className={css(styles.timeLabel)}>
              <FormattedTimeInTz value={arriveAt} hour="2-digit" minute="2-digit" />
            </time>
          </div>
          <div className={css(styles.column)}>
            <span className={css(styles.grayLabel, styles.leg)}>
              <FormattedMessage {...messages.routeDuration} />
            </span>
            <span className={css(styles.duration)}>
              {formatDuration(moment(arriveAt).diff(departAt))}
            </span>
          </div>
          <div className={css(styles.column)}>
            <span className={css(styles.grayLabel, styles.leg)}>
              <FormattedMessage {...messages.carrier} />
            </span>
            <span className={css(styles.transportInformation)} data-test="carrierName">
              <FormattedMessage
                {...messages.transportInformation}
                values={{ carrier: carrier || type }}
              />
            </span>
            <span className={css(styles.transportInformation)} data-test="transportDesignation">
              {transportDesignation} {timetableId}
            </span>
          </div>
          {bikeReservation && bikeReservation.status !== 'Unknown' && (
            <div className={css(styles.column)}>
              <span className={css(styles.grayLabel, styles.leg)}>
                <FormattedMessage {...messages.bikeSpace} />
              </span>
              <span className={css(styles.transportInformation)}>
                <BikeReservation
                  status={bikeReservation.status}
                  quantity={bikeReservation.quantity}
                />
              </span>
            </div>
          )}
        </div>
      </div>
      <div className={css(styles.tabs)}>
        <div className={css(styles.expandableAnchors)}>
          {showDisruptionInfoTab && (
            <Expandable.Wrapper styleSheet={styles.expandableDisruptionInfoWrapper}>
              <Expandable.Anchor
                onClick={handleDisruptionInfoClick}
                isOpen={openTab === 'disruption-info'}
                styleSheet={{
                  anchor: styles.expandableDisruptionInfoAnchor,
                  chevron: styles.expandableDisruptionInfoChevron,
                }}
                testId="disruptionInfoTab"
              >
                {formatMessage(messages.disruptionInfo)}
              </Expandable.Anchor>
            </Expandable.Wrapper>
          )}
          <Expandable.Wrapper styleSheet={styles.expandablePassengerInfoWrapper}>
            <Expandable.Anchor
              onClick={handlePassengerInfoClick}
              isOpen={openTab === 'passenger-info'}
              styleSheet={{
                anchor: styles.expandablePassengerInfoAnchor,
                chevron: styles.expandablePassengerInfoChevron,
              }}
              testId="passengerInfoTab"
            >
              {formatMessage(messages.passengerInfo)}
            </Expandable.Anchor>
          </Expandable.Wrapper>
        </div>
        {showDisruptionInfoTab && (
          <Expandable.Content
            onClick={handleDisruptionInfoClick}
            isOpen={openTab === 'disruption-info'}
            styleSheet={styles.expandableContent}
          >
            {isNotOnTime && <DisruptionInfo timetable={timetable} />}
            {!timetable && (
              <StatusMessage status="negative">
                <div data-testid="timetable-error">
                  <FormattedMessage {...messages.timetableLoadError} />
                </div>
              </StatusMessage>
            )}
          </Expandable.Content>
        )}
        <Expandable.Content
          onClick={handlePassengerInfoClick}
          isOpen={openTab === 'passenger-info'}
          styleSheet={styles.expandableContent}
        >
          {passengers.map((passenger, index) => (
            <Passenger
              key={index}
              {...passenger}
              deliveryRecipient={deliveryRecipient}
              passengerNum={index + 1}
              seatingPreferences={seatingPreferences}
              customerOrderTravellers={customerOrderTravellers}
              businessSettings={businessSettings}
            />
          ))}
        </Expandable.Content>
      </div>
    </div>
  );
};

CustomerBookingJourneyLeg.propTypes = {
  deliveryRecipient: PropTypes.object,
  legNum: PropTypes.number.isRequired,
  legCount: PropTypes.number.isRequired,
  arriveAt: PropTypes.string.isRequired,
  departAt: PropTypes.string.isRequired,
  originCity: PropTypes.string,
  originStation: PropTypes.string.isRequired,
  destinationCity: PropTypes.string,
  destinationStation: PropTypes.string.isRequired,
  passengers: PropTypes.arrayOf(PropTypes.object).isRequired,
  transportInformation: PropTypes.object.isRequired,
  seatingPreferences: PropTypes.string,
  boardBeforeGuaranteed: PropTypes.number,
  timetable: PropTypes.object,
  vendor: PropTypes.string,
  bikeReservation: PropTypes.object,
  customerOrderTravellers: PropTypes.shape({
    tenantId: PropTypes.string,
    itineraryId: PropTypes.string,
    passengerDetails: PropTypes.array,
  }),
  businessSettings: PropTypes.shape({
    persistPassengerDetails: PropTypes.bool,
  }),
};

export default CustomerBookingJourneyLeg;
