import React from 'react';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import RadioBox, { RadioBoxGroupConnector } from '@contactcentre-web/common/RadioBox';

import styles from '../../OrderNoteForm/styles';
import messages from '../messages';

export const surveyChoice = 'surveyChoice';

const SurveyChoiceRadioBox = () => (
  <>
    <FormattedMessage {...messages.surveyLabel}>
      {(value) => <span className={css(styles.fieldLabel)}>{value}</span>}
    </FormattedMessage>
    <Field name={surveyChoice} component={RadioBoxGroupConnector}>
      <RadioBox label={<FormattedMessage {...messages.yesLabel} />} value="yes" />
      <RadioBox label={<FormattedMessage {...messages.noLabel} />} value="no" />
    </Field>
  </>
);

export default SurveyChoiceRadioBox;
