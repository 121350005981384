import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import FulfilmentStatus from '@contactcentre-web/common/FulfilmentStatus';
import VendorStatus from '@contactcentre-web/common/VendorStatus';
import ProductType from '@contactcentre-web/redux-common/types/ProductType';
import OriginToDestination from '@contactcentre-web/common/OriginToDestination/OriginToDestination';

import styles from './styles';
import messages from './messages';

export const FramedLabel = ({ level, children }) => (
  <span className={css(styles.frame, styles[`frame_${level}`])}>{children}</span>
);
FramedLabel.propTypes = {
  level: PropTypes.oneOf(['positive', 'negative']).isRequired,
  children: PropTypes.node.isRequired,
};

const BookingsTableRow = ({
  type,
  origin,
  destination,
  isReturn,
  name,
  fulfilmentStatus,
  vendor,
  vendorStatus,
  hasBeenReimbursed,
}) => (
  <div className={css(styles.row, styles.data)}>
    <div className={css(styles.cell, styles.booking)}>
      {type === ProductType.Railcard ? (
        <FormattedMessage {...messages.railcard} values={{ name }} />
      ) : (
        <OriginToDestination isReturn={isReturn} origin={origin} destination={destination} />
      )}
    </div>
    <div className={css(styles.cell)}>{vendor}</div>
    <div className={css(styles.cell)}>
      <FulfilmentStatus status={fulfilmentStatus} />
    </div>
    <div className={css(styles.cell)}>
      <VendorStatus status={vendorStatus} highlighting="off" />
    </div>
    <div className={css(styles.cell)}>
      {hasBeenReimbursed && (
        <FramedLabel level="positive">
          <FormattedMessage {...messages.yes} />
        </FramedLabel>
      )}
      {!hasBeenReimbursed &&
        (fulfilmentStatus.toLowerCase() === 'failed' ? (
          <FramedLabel level="negative">
            <FormattedMessage {...messages.no} />
          </FramedLabel>
        ) : (
          <FormattedMessage {...messages.notApplicable} />
        ))}
    </div>
  </div>
);

BookingsTableRow.propTypes = {
  type: PropTypes.string.isRequired,
  origin: PropTypes.string,
  destination: PropTypes.string,
  isReturn: PropTypes.bool,
  name: PropTypes.string,
  fulfilmentStatus: PropTypes.string.isRequired,
  vendor: PropTypes.string.isRequired,
  vendorStatus: PropTypes.string,
  hasBeenReimbursed: PropTypes.bool,
};

export default BookingsTableRow;
