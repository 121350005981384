import { defineMessages } from 'react-intl';

export default defineMessages({
  notice: {
    id: 'EmailSearch.notice',
    defaultMessage:
      'Order <b>{orderReference}</b> will be moved from <b>{originalEmail}</b> to the selected account. Please note, any associated bookings will no longer appear in the original account.',
  },
  move_failed: {
    id: 'EmailSearch.move_failed',
    defaultMessage:
      'Order <b>{orderReference}</b> could not be moved from <b>{originalEmail}</b>. Please try again and if failed, report it to the ContactCentre technical team.',
  },
  button_search: {
    id: 'EmailSearch.button_search',
    defaultMessage: 'Search for account',
  },
  button_confirm: {
    id: 'EmailSearch.button_confirm',
    defaultMessage: 'Confirm move',
  },
});
