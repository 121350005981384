import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import NoSearchResult from '@contactcentre-web/common/NoSearchResult/NoSearchResult';
import Link from '@contactcentre-web/common/Link';

import type { LegacyProductResultItem } from '../../utils/searchService';

import messages from './messages';

interface Props {
  result: LegacyProductResultItem;
}

const LegacyResult: FunctionComponent<Props> = ({ result }) => (
  <div data-testid="pnr25kv">
    <NoSearchResult>
      <FormattedMessage
        {...(messages[result.legacySystem] || messages.Capitaine)}
        values={{
          a: (msg: string) => (
            <Link linkType="external" href={result ? result.uri : undefined}>
              {msg}
            </Link>
          ),
        }}
      />
    </NoSearchResult>
  </div>
);

export default LegacyResult;
