import { StyleSheet } from 'aphrodite/no-important';
import { palettes, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  appbar: {
    background: palettes.dp_app_backdrop_dark,
  },
  navbar: {
    padding: spacers.dp_s24,
  },

  changePasswordContainer: {
    paddingTop: spacers.dp_s64,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formContainer: {
    marginTop: spacers.dp_s16,
    width: '400px',
  },

  fieldGroup: {
    marginBottom: spacers.dp_s8,
  },
  button: {
    marginTop: spacers.dp_s24,
  },

  submitError: {
    marginTop: spacers.dp_s16,
    color: palettes.dp_app_text_negative,
    whiteSpace: 'pre-line',
  },
});
