import { createActions, handleActions } from 'redux-actions';

export const PREFIX = 'CUSTOMERS_EDIT';

// Constants
export const EDIT_CUSTOMER_PROFILE_REQUESTED = 'EDIT_CUSTOMER_PROFILE_REQUESTED';
export const EDIT_CUSTOMER_PROFILE_SUCCEEDED = 'EDIT_CUSTOMER_PROFILE_SUCCEEDED';
const EDIT_CUSTOMER_PROFILE_FAILED = 'EDIT_CUSTOMER_PROFILE_FAILED';
const EDIT_CUSTOMER_PROFILE_RESET = 'EDIT_CUSTOMER_PROFILE_RESET';
export const LOAD_CUSTOMER_LANGUAGES_REQUESTED = 'LOAD_CUSTOMER_LANGUAGES_REQUESTED';
const LOAD_CUSTOMER_LANGUAGES_SUCCEEDED = 'LOAD_CUSTOMER_LANGUAGES_SUCCEEDED';
const LOAD_CUSTOMER_LANGUAGES_FAILED = 'LOAD_CUSTOMER_LANGUAGES_FAILED';

export enum LoadingStatus {
  NONE = 'NONE',
  INPROGRESS = 'INPROGRESS',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export const actions = createActions(
  {
    [EDIT_CUSTOMER_PROFILE_REQUESTED]: (values) => ({ values }),
    [EDIT_CUSTOMER_PROFILE_SUCCEEDED]: (customerId) => ({ customerId }),
    [EDIT_CUSTOMER_PROFILE_FAILED]: (error) => ({ error }),
    [EDIT_CUSTOMER_PROFILE_RESET]: () => null,
    [LOAD_CUSTOMER_LANGUAGES_REQUESTED]: () => null,
    [LOAD_CUSTOMER_LANGUAGES_SUCCEEDED]: (items) => items,
    [LOAD_CUSTOMER_LANGUAGES_FAILED]: (error) => ({ error }),
  },
  { prefix: PREFIX }
);

export type Language = {
  language: string;
  countryCode: string;
};

export type EditCustomerProfileError = 'SSOV0006' | 'generic';

export interface EditCustomerProfileState {
  languages: Array<Language> | null;
  editState: LoadingStatus;
  loadState: LoadingStatus;
  error: EditCustomerProfileError | null;
}

const initialState = {
  languages: null,
  editState: LoadingStatus.NONE,
  loadState: LoadingStatus.NONE,
  error: null,
};

interface ActionPayload {
  items: Array<Language>;
  error: EditCustomerProfileError;
}

export default handleActions<EditCustomerProfileState, ActionPayload>(
  {
    [EDIT_CUSTOMER_PROFILE_REQUESTED]: (state) => ({
      ...state,
      editState: LoadingStatus.INPROGRESS,
    }),
    [EDIT_CUSTOMER_PROFILE_SUCCEEDED]: (state) => ({
      ...state,
      editState: LoadingStatus.SUCCESS,
    }),
    [EDIT_CUSTOMER_PROFILE_FAILED]: (state, { payload: { error } }) => ({
      ...state,
      error,
      editState: LoadingStatus.ERROR,
    }),
    [EDIT_CUSTOMER_PROFILE_RESET]: () => initialState,
    [LOAD_CUSTOMER_LANGUAGES_REQUESTED]: (state) => ({
      ...state,
      loadState: LoadingStatus.INPROGRESS,
    }),
    [LOAD_CUSTOMER_LANGUAGES_SUCCEEDED]: (state, { payload: { items } }) => ({
      ...state,
      languages: items,
      loadState: LoadingStatus.SUCCESS,
    }),
    [LOAD_CUSTOMER_LANGUAGES_FAILED]: (state, { payload: { error } }) => ({
      ...state,
      languages: null,
      error,
      loadState: LoadingStatus.ERROR,
    }),
  },
  initialState,
  { prefix: PREFIX }
);
