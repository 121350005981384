import { StyleSheet } from 'aphrodite/no-important';
import { fonts, spacers, typography } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    backgroundColor: '#fdf7c9',
    textAlign: 'center',
    padding: `${spacers.dp_s24} 0`,
    fontSize: typography.dp_body_font_size,
    lineHeight: typography.dp_body_line_height,
    letterSpacing: typography.dp_body_letter_spacing,
  },
  link: {
    fontWeight: fonts.dp_font_weight_bold,
    color: '#004FF9',
    textDecoration: 'none',
    ':visited': {
      color: '#004FF9',
    },
    ':hover': {
      textDecoration: 'underline',
    },
  },
});
