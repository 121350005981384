import { StyleSheet } from 'aphrodite/no-important';
import { fonts, palettes, typography } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  labelPrimary: {
    color: palettes.dp_app_link_base,
    lineHeight: typography.dp_body_line_height,
    fontWeight: fonts.dp_font_weight_bold,
    display: 'inline-block',
  },
  value: {
    display: 'inline-block',
  },
  horizontal: {
    display: 'flex',
    flexDirection: 'row',
  },
  vertical: {
    display: 'flex',
    flexDirection: 'column',
  },
});
