import { css } from 'aphrodite/no-important';
import React from 'react';
import { useParams } from 'react-router-dom';

import PageContainer from '@contactcentre-web/common/PageContainer';
import Loader from '@contactcentre-web/common/Loader';
import {
  useCustomerPassengers,
  useCustomerPurchasedCards,
} from '@contactcentre-web/hooks/api/useCustomerPassengers';

import Header from './components/Header/Header';
import Passenger from './components/Passenger/Passenger';
import Railcards from './components/Railcards/Railcards';
import styles from './styles';

const CustomerPassengers = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const { data: passengers, isLoading: isLoadingPassengers } = useCustomerPassengers(customerId);
  const { data: purchasedCards, isLoading: isLoadingPurchasedCards } =
    useCustomerPurchasedCards(customerId);

  if (isLoadingPassengers || isLoadingPurchasedCards) {
    return (
      <div className={css(styles.loaderContainer)}>
        <Loader />
      </div>
    );
  }

  return (
    <PageContainer>
      <Header />
      <section className={css(styles.passengerContainer)} data-testid="customerPassengerContainer">
        <Railcards cards={purchasedCards} />
        {passengers?.map((passenger, key) => (
          <Passenger key={key} passenger={passenger} />
        ))}
      </section>
    </PageContainer>
  );
};

export default CustomerPassengers;
