import { css } from 'aphrodite/no-important';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useQueryClient } from 'react-query';

import OpenInBeboc from '@contactcentre-web/beboc/OpenInBeboc';
import Button from '@contactcentre-web/common/Button';
import MoreOptionsButton from '@contactcentre-web/common/MoreOptionsButton/MoreOptionsButton';
import Tooltip from '@contactcentre-web/common/Tooltip';
import CurrencyConverter from '@contactcentre-web/currency-converter/CurrencyConverter';
import { actions as currencyConverterActions } from '@contactcentre-web/currency-converter/module';
import Detaxe from '@contactcentre-web/detaxe/Detaxe';
import { anyDetaxeEligibleBookings } from '@contactcentre-web/detaxe/selectors';
import MoveOrder from '@contactcentre-web/move-order/MoveOrder';
import { selectors as orderHistorySelectors } from '@contactcentre-web/order-history/module';
import OrderNotes from '@contactcentre-web/order-notes/OrderNotes';
import * as orderSelectors from '@contactcentre-web/redux-common/selectors/order';
import * as userSelectors from '@contactcentre-web/authentication/redux/selectors';
import {
  actions as refreshPnrActions,
  selectors as refreshPnrSelectors,
} from '@contactcentre-web/refresh-pnr/module';
import RefreshPnr from '@contactcentre-web/refresh-pnr/RefreshPnr';
import FulfilmentConverter from '@contactcentre-web/fulfilment-converter/FulfilmentConverter';
import {
  actions as replaceBookingActions,
  selectors as replaceBookingSelectors,
} from '@contactcentre-web/replace-booking/module';
import ReplaceBookingContainer from '@contactcentre-web/replace-booking/ReplaceBooking';
import { actions as resendEmailActions } from '@contactcentre-web/resend-email/module';
import ResendEmail from '@contactcentre-web/resend-email/ResendEmail';
import { addPageAction } from '@contactcentre-web/utils/tracker';
import RefundOptionsMenu from '@contactcentre-web/common/RefundOptionsMenu/RefundOptionsMenu';

import messages from './messages';
import NotVoidableTooltip from './components/NotVoidableTooltip/NotVoidableTooltip';
import orderActionKeys from './OrderActionKeys';
import RemoveCollectionRestrictionCardContainer from './components/RemoveCollectionRestrictionCard/RemoveCollectionRestrictionCard';
import selectors from './selectors';
import styles from './styles';

const actionBuilder = (action, context, onClose) => ({ action, context: { ...context, onClose } });

const actionContent = ({ action, context }, props) => {
  switch (action) {
    case orderActionKeys.replace:
      return <ReplaceBookingContainer {...context} {...props} />;
    case orderActionKeys.resendEmails:
      return <ResendEmail {...context} {...props} />;
    case orderActionKeys.refresh:
      return <RefreshPnr {...context} {...props} />;
    case orderActionKeys.fulfilmentConverter:
      return <FulfilmentConverter {...context} {...props} />;
    default:
      return null;
  }
};

actionContent.propTypes = {
  action: PropTypes.string.isRequired,
  context: PropTypes.object,
  canRequestDiscretionaryRefund: PropTypes.bool,
  customerId: PropTypes.string,
  orderReference: PropTypes.string,
  history: PropTypes.func,
};

const actionRenderer = (action, props) => <div>{actionContent(action, props)}</div>;

const expiryDurationLeft = (expiryTime) => {
  if (!expiryTime) {
    return null;
  }

  const end = moment(expiryTime);
  const diff = end.diff(Date.now());
  return moment.utc(diff).format('H[h] mm');
};

export const OrderActions = ({
  customerId,
  orderReference,
  actions: {
    [orderActionKeys.orderHistory]: orderHistory,
    [orderActionKeys.logCall]: notes,
    [orderActionKeys.viewBookings]: bookings,
    [orderActionKeys.cancelOrder]: cancel,
    [orderActionKeys.refund]: refund,
    [orderActionKeys.changeOfJourney]: coj,
    [orderActionKeys.replace]: replace,
    [orderActionKeys.currencyConverter]: currencyConverter,
    [orderActionKeys.openInCapitaine]: openInCapitaine,
    [orderActionKeys.resendEmails]: resendEmails,
    [orderActionKeys.fulfilmentConverter]: fulfilmentConverter,
    [orderActionKeys.refresh]: refresh,
    [orderActionKeys.compensation]: compensation,
    [orderActionKeys.moveOrder]: moveOrder,
    [orderActionKeys.removeCollectionRestrictions]: removeCollectionRestrictions,
    [orderActionKeys.detaxe]: detaxeRedirect,
  },
  isChangeableToAnyCardAvailable,
  isAnyBookingRefreshable,
  canRequestCompensation,
  openCurrencyConverter,
  canLogCall,
  expiryTime,
  canRequestDiscretionaryRefund,
  canMoveOrder,
  canReplaceOrder,
  canResendEmails,
  canBebocPnrIntoEuPlatform,
  canUseCurrencyConverter,
  canRequestCoj,
  showDetaxeOption,
  orderHistoryHasItems,
  canConvertFulfilments,
}) => {
  const [currentAction, setCurrentAction] = React.useState();
  const queryClient = useQueryClient();
  const { totalCount: newOrderHistoryTotalItems } =
    queryClient.getQueryData(['orderHistoryItems', orderReference]) || {};
  const showNotification = orderHistoryHasItems || newOrderHistoryTotalItems > 0;
  const resetAction = () => setCurrentAction(undefined);

  const [expiryTimeText, setExpiryTimeText] = React.useState(expiryDurationLeft(expiryTime));

  const handleChangeCurrency = () => {
    addPageAction('click-currency-converter');
    openCurrencyConverter();
  };

  React.useEffect(() => {
    const timer = setInterval(() => setExpiryTimeText(expiryDurationLeft(expiryTime)), 60000);

    // this will clear Timeout when component unmount like in willComponentUnmount
    return () => clearInterval(timer);
  }, []);

  const renderOrderNotes = () => (
    <OrderNotes
      orderId={orderReference}
      customerEmail={notes.customerEmail}
      actionRenderer={() => (
        <Button variant="tertiary" size="small" testId="log-call" styleSheet={styles.button}>
          <FormattedMessage {...messages.logCall} />
        </Button>
      )}
    />
  );

  const refundMenuItems = [
    {
      handle: (
        <Button
          variant="ghost"
          styleSheet={styles.linkButton}
          testId="TandCLink"
          to={`/customers/${customerId}/bookings/${orderReference}/ts-and-cs-refund`}
        >
          <FormattedMessage {...messages.tcsRefund} />
        </Button>
      ),
    },
    canRequestDiscretionaryRefund &&
      refund &&
      !refund.orderOnlyContainsSeasonProducts && {
        handle: (
          <Button
            variant="ghost"
            styleSheet={styles.linkButton}
            to={`/customers/${customerId}/bookings/${orderReference}/discretionary`}
          >
            <FormattedMessage {...messages.discretionaryRefund} />
          </Button>
        ),
      },
    showDetaxeOption &&
      detaxeRedirect && {
        'data-test': 'detaxe-form-action',
        handle: <Detaxe styleSheet={styles.linkButton} />,
      },
  ].filter((refundItems) => !!refundItems);

  const moreOptionsMenuItems = [
    canReplaceOrder &&
      replace && {
        'data-test': 'ReplaceBookingOpen',
        handle: (
          <Button
            variant="ghost"
            styleSheet={styles.linkButton}
            onClick={() =>
              setCurrentAction(actionBuilder(orderActionKeys.replace, replace, resetAction))
            }
          >
            <FormattedMessage {...messages.replace} />
          </Button>
        ),
      },
    removeCollectionRestrictions &&
      isChangeableToAnyCardAvailable && {
        'data-test': 'remove-collection-restriction',
        handle: <RemoveCollectionRestrictionCardContainer className={styles.linkButton} />,
      },
    canUseCurrencyConverter &&
      currencyConverter && {
        'data-test': 'CurrencyConverterOpen',
        handle: (
          <Button variant="ghost" onClick={handleChangeCurrency} styleSheet={styles.linkButton}>
            <FormattedMessage {...messages.currencyConverter} />
          </Button>
        ),
      },
    openInCapitaine &&
      canBebocPnrIntoEuPlatform && {
        'data-test': 'OpenCapitaine',
        handle: <OpenInBeboc orderReference={orderReference} link={openInCapitaine.context} />,
      },
    canResendEmails &&
      resendEmails && {
        'data-test': 'ResendEmailOpen',
        handle: (
          <Button
            variant="ghost"
            onClick={() =>
              setCurrentAction(
                actionBuilder(orderActionKeys.resendEmails, resendEmails, resetAction)
              )
            }
            styleSheet={styles.linkButton}
          >
            <FormattedMessage {...messages.resendEmail} />
          </Button>
        ),
      },
    fulfilmentConverter &&
      canConvertFulfilments && {
        'data-test': 'FulfilmentConverterOpen',
        handle: (
          <Button
            variant="ghost"
            styleSheet={styles.linkButton}
            onClick={() =>
              setCurrentAction(
                actionBuilder(orderActionKeys.fulfilmentConverter, fulfilmentConverter, resetAction)
              )
            }
          >
            <FormattedMessage {...messages.fulfilmentConverter} />
          </Button>
        ),
      },
    compensation &&
      canRequestCompensation && {
        'data-test': 'CompensationOpen',
        handle: (
          <Button
            variant="ghost"
            styleSheet={styles.linkButton}
            to={`/customers/${customerId}/bookings/${orderReference}/compensation`}
          >
            <FormattedMessage {...messages.compensation} />
          </Button>
        ),
      },
    canMoveOrder &&
      moveOrder && {
        'data-test-id': 'move-order-action',
        handle: (
          <div className={css(styles.linkButton)}>
            <MoveOrder {...moveOrder.context} />
          </div>
        ),
      },
  ].filter((item) => typeof item === 'object');

  return (
    <>
      <div className={css(styles.buttonsContainer)}>
        {bookings && (
          <Button
            variant="tertiary"
            size="small"
            styleSheet={styles.button}
            testId="ViewBookings"
            disabled={bookings.disabled}
            to={`/customers/${customerId}/bookings/${orderReference}`}
          >
            <FormattedMessage {...messages.viewBookings} />
          </Button>
        )}
        {orderHistory && (
          <Button
            variant="tertiary"
            size="small"
            styleSheet={styles.button}
            testId="BookingHistory"
            to={`/customers/${customerId}/bookings/${orderReference}/history`}
          >
            {showNotification && (
              <span
                className={css(styles.orderHistoryNotification)}
                data-testid="orderHistoryNotification"
              />
            )}
            <FormattedMessage {...messages.orderHistory} />
          </Button>
        )}
        {canLogCall &&
          (notes && notes.customerEmail
            ? renderOrderNotes()
            : canLogCall.disabled && (
                <Tooltip
                  orientation="Above"
                  anchor={
                    <Button variant="tertiary" size="small" styleSheet={styles.button} disabled>
                      <FormattedMessage {...messages.logCall} />
                    </Button>
                  }
                >
                  <FormattedMessage {...messages.customerEmailError} />
                </Tooltip>
              ))}
        {cancel &&
          (cancel.disabled && cancel.context && cancel.context.length > 0 ? (
            <NotVoidableTooltip
              testId="NotVoidableTooltip"
              button={
                <Button variant="tertiary" size="small" styleSheet={styles.button} disabled>
                  <FormattedMessage {...messages.void} />
                </Button>
              }
              reasons={cancel.context}
            />
          ) : (
            <Button
              variant="tertiary"
              size="small"
              styleSheet={styles.button}
              testId="CancelBooking"
              disabled={cancel.disabled}
              to={`/customers/${customerId}/bookings/${orderReference}/sdv`}
            >
              <FormattedMessage {...messages.void} />
              {expiryTimeText && (
                <>
                  {' '}
                  <FormattedMessage
                    {...messages.cancelExpiryTime}
                    values={{ expiryTime: expiryTimeText }}
                  >
                    {(txt) => <span className={css(styles.expiryTime)}>{txt}</span>}
                  </FormattedMessage>
                </>
              )}
            </Button>
          ))}
        {refundMenuItems.length === 1 ? (
          <div
            data-testid="RefundSingleButton"
            className={css(
              styles.buttonRefundsContainer,
              refund?.disabled && styles.buttonRefundsDisabled
            )}
          >
            {refundMenuItems[0].handle}
          </div>
        ) : (
          <RefundOptionsMenu
            testId="Refund"
            disabled={refund?.disabled}
            styleSheet={styles.button}
            items={refundMenuItems}
          />
        )}
        {canRequestCoj && coj && (
          <Button
            variant="tertiary"
            size="small"
            testId="coj"
            styleSheet={styles.button}
            to={`/customers/${customerId}/bookings/${orderReference}/coj`}
          >
            <FormattedMessage {...messages.coj} />
          </Button>
        )}
        <div className={css(styles.buttonGroup)}>
          {refresh && isAnyBookingRefreshable && (
            <Button
              variant="tertiary"
              size="small"
              styleSheet={styles.button}
              testId="RefreshBookingOpen"
              onClick={() =>
                setCurrentAction(actionBuilder(orderActionKeys.refresh, refresh, resetAction))
              }
            >
              <FormattedMessage {...messages.refreshBooking} />
            </Button>
          )}
          <MoreOptionsButton
            testId="MoreOptions"
            styleSheet={styles.moreButton}
            items={moreOptionsMenuItems}
          />
        </div>
        {currencyConverter && <CurrencyConverter {...currencyConverter.context} />}
      </div>
      {currentAction &&
        currentAction.context &&
        currentAction.context.target &&
        ReactDOM.createPortal(
          actionRenderer(currentAction, {
            orderReference,
            customerId,
          }),
          currentAction.context.target
        )}
    </>
  );
};

const actionItemShape = {
  disabled: PropTypes.bool,
  context: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  handler: PropTypes.func,
};

const actionsShape = Object.keys(orderActionKeys).reduce(
  (acc, action) => ({
    ...acc,
    [action]: PropTypes.oneOfType([PropTypes.shape(actionItemShape)]),
  }),
  {}
);

OrderActions.propTypes = {
  customerId: PropTypes.string.isRequired,
  orderReference: PropTypes.string.isRequired,
  disableSDV: PropTypes.bool.isRequired,
  disableOrder: PropTypes.bool.isRequired,
  disableRefund: PropTypes.bool.isRequired,
  actions: PropTypes.shape(actionsShape).isRequired,
  disableReplace: PropTypes.bool.isRequired,
  replace: PropTypes.func.isRequired,
  capitaineUrl: PropTypes.string,
  hasEuBookings: PropTypes.bool,
  notVoidableReasons: PropTypes.any,
  currencyData: PropTypes.object,
  openCurrencyConverter: PropTypes.func.isRequired,
  resendEmail: PropTypes.func.isRequired,
  isAnyBookingRefreshable: PropTypes.bool.isRequired,
  refreshBooking: PropTypes.func.isRequired,
  openRefundSelector: PropTypes.func.isRequired,
  canRequestCompensation: PropTypes.bool,
  canLogCall: PropTypes.bool.isRequired,
  isChangeableToAnyCardAvailable: PropTypes.bool.isRequired,
  expiryTime: PropTypes.object,
  canRequestDiscretionaryRefund: PropTypes.bool.isRequired,
  canMoveOrder: PropTypes.bool.isRequired,
  canReplaceOrder: PropTypes.bool.isRequired,
  canResendEmails: PropTypes.bool.isRequired,
  canBebocPnrIntoEuPlatform: PropTypes.bool.isRequired,
  canUseCurrencyConverter: PropTypes.bool.isRequired,
  canRequestCoj: PropTypes.bool.isRequired,
  canConvertFulfilments: PropTypes.bool.isRequired,
  showDetaxeOption: PropTypes.bool,
  orderHistoryHasItems: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  expiryTime: orderSelectors.selectSDVExpiryTime(state),
  disableReplace: !replaceBookingSelectors.isReplaceable(state.replaceBooking),
  isAnyBookingRefreshable: refreshPnrSelectors.isAnyBookingRefreshable(state),
  isChangeableToAnyCardAvailable: selectors.isChangeableToAnyCardAvailable(state),
  canRequestCompensation: userSelectors.canRequestCompensation(state),
  canLogCall: userSelectors.canLogCall(state),
  canRequestDiscretionaryRefund: userSelectors.canRequestDiscretionaryRefund(state),
  canMoveOrder: userSelectors.canMoveOrder(state),
  canReplaceOrder: userSelectors.canReplaceOrder(state),
  canResendEmails: userSelectors.canResendEmails(state),
  canBebocPnrIntoEuPlatform: userSelectors.canBebocPnrIntoEuPlatform(state),
  canUseCurrencyConverter: userSelectors.canUseCurrencyConverter(state),
  canRequestCoj: userSelectors.canRequestCoj(state),
  showDetaxeOption: anyDetaxeEligibleBookings(state),
  orderHistoryHasItems: orderHistorySelectors.orderHistoryHasItems(state),
  canConvertFulfilments: userSelectors.canConvertFulfilments(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  replace() {
    const { customerId, orderReference } = ownProps;
    dispatch(refreshPnrActions.hide());
    dispatch(resendEmailActions.hide());
    dispatch(replaceBookingActions.open(customerId, orderReference));
  },
  resendEmail() {
    dispatch(refreshPnrActions.hide());
    dispatch(replaceBookingActions.hide());
    dispatch(resendEmailActions.show());
  },
  refreshBooking() {
    dispatch(replaceBookingActions.hide());
    dispatch(resendEmailActions.hide());
    dispatch(refreshPnrActions.show());
  },
  openCurrencyConverter() {
    dispatch(currencyConverterActions.open());
  },
});

const connectedOrderActions = connect(mapStateToProps, mapDispatchToProps)(OrderActions);
connectedOrderActions.displayName = 'OrderActions';

export default connectedOrderActions;
