import { StyleSheet } from 'aphrodite/no-important';
import { fonts, spacers, typography } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  header: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#fbfbfc',
    borderBottom: 'solid 1px #e5e5e5',
    padding: `${spacers.dp_s16} ${spacers.dp_s24}`,
    justifyContent: 'space-between',
  },
  headerLabel: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    textTransform: 'uppercase',
    color: colors.gray.alpha30,
    fontWeight: fonts.dp_font_weight_regular,
  },
  bold: {
    fontWeight: fonts.dp_font_weight_bold,
  },
});
