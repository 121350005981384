import { StyleSheet } from 'aphrodite/no-important';
import { typography, spacers, fonts } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  container: {
    padding: `${spacers.dp_s32} 0`,
  },

  rowHeader: {
    marginBottom: spacers.dp_s32,
  },

  label: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    fontWeight: fonts.dp_font_weight_bold,
    textTransform: 'uppercase',
    color: colors.gray.alpha30,
  },
  orderReference: {
    fontSize: typography.dp_hero_font_size,
    fontWeight: fonts.dp_font_weight_bold,
    letterSpacing: typography.dp_hero_letter_spacing,
    lineHeight: 1,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  bookings: {
    maxWidth: '25%',
  },
});
