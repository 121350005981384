import React, { useState } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage, FormattedTime } from 'react-intl';
import { IconSingle, Paragraph } from '@trainline/depot-web';

import formatDuration from '@contactcentre-web/utils/duration';
import Button from '@contactcentre-web/common/Button';

import JourneyItemModal from '../../JourneyItemModal/JourneyItemModal';

import styles from './styles';
import messages from './messages';

interface JourneyProps {
  journey: any;
}

const JourneyItem = ({
  journey: { origin, destination, duration, changes, departAt, arriveAt, legs },
}: JourneyProps) => {
  const [isJourneyModalVisible, setJourneyModalVisibility] = useState(false);

  const toggleModal = (isVisible: boolean) => {
    setJourneyModalVisibility(isVisible);
  };

  const formattedChanges =
    changes > 0 ? (
      <FormattedMessage {...messages.changes} values={{ changes }} />
    ) : (
      <FormattedMessage {...messages.direct} />
    );

  const formattedDuration = formatDuration(duration);

  return (
    <>
      <Button
        variant="ghost"
        styleSheet={styles.container}
        onClick={() => {
          toggleModal(true);
        }}
      >
        <div>
          {origin}
          <Paragraph typeStyle="mini" as="p" color="base" fontWeight="bold">
            <FormattedTime value={departAt} />
          </Paragraph>
        </div>
        <div>
          <IconSingle />
        </div>
        <div>
          {destination}
          <Paragraph typeStyle="mini" as="p" color="base" fontWeight="bold">
            <FormattedTime value={arriveAt} />
          </Paragraph>
        </div>
        <div>
          <span className={css(styles.durationDetails)}>{formattedDuration}</span>
          <span className={css(styles.durationDetails)}>{formattedChanges}</span>
        </div>
      </Button>

      {isJourneyModalVisible && (
        <JourneyItemModal
          closeJourneyModal={toggleModal}
          journey={{
            origin,
            destination,
            duration: formattedDuration,
            changes: formattedChanges,
            departAt,
            arriveAt,
            legs,
          }}
        />
      )}
    </>
  );
};

export default JourneyItem;
