import React, { Component, ErrorInfo, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { StatusMessage } from '@trainline/depot-web';

import { noticeError } from '@contactcentre-web/utils/tracker';

import message from './messages';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    noticeError(error, {
      ...errorInfo,
      at: 'error-boundary',
    });
    this.setState((prevState) => ({ ...prevState, hasError: true }));
  }

  render() {
    if (this.state.hasError) {
      return (
        <StatusMessage status="negative">
          <FormattedMessage {...message.errorMessage} />
        </StatusMessage>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
