import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal, ModalHeader, ModalTitle, ModalBody } from '@contactcentre-web/common/Modal';
import Loader from '@contactcentre-web/common/Loader';
import type { TicketStatus } from '@contactcentre-web/redux-common/types/TicketStatus';

import TicketSummary from '../../../TicketSummary/TicketSummary';

import messages from './messages';

interface Props {
  toggleModal: () => void;
  ticketStatus: {
    statuses: Array<TicketStatus>;
    isLoading: boolean;
  };
}

const TicketStatusModal = ({ toggleModal, ticketStatus: { isLoading, statuses } }: Props) => (
  <Modal onClose={toggleModal}>
    <ModalHeader>
      <ModalTitle>
        <FormattedMessage {...messages.title} />
      </ModalTitle>
    </ModalHeader>
    <ModalBody>
      {isLoading ? (
        <Loader />
      ) : (
        statuses?.map((status, index) => (
          <TicketSummary
            status={status}
            ticketNumber={index + 1}
            totalOfTickets={statuses.length}
            key={index}
          />
        ))
      )}
    </ModalBody>
  </Modal>
);

export default TicketStatusModal;
