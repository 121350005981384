import { put, call, takeLatest } from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';
import type { Action } from 'redux-actions';

import { actions, PASSWORD_CHANGE_REQUESTED } from '../module';

import { FORM_ID } from './module';
import { changePassword } from './service';

export interface ChangePasswordRequestPayload {
  username: string;
  credentials: {
    newPassword: string;
    oldPassword: string;
  };
}

export function* requestChangePassword(requestData: ChangePasswordRequestPayload) {
  try {
    yield call(
      changePassword,
      requestData.username,
      requestData.credentials.newPassword,
      requestData.credentials.oldPassword
    );

    return undefined;
  } catch (error: any) {
    if (error.errors && error.errors.errors && error.errors.errors.length > 0) {
      return {
        _error: error.errors.errors[0].detail,
      };
    }

    return {
      _error: error.errors && error.errors.length > 0 ? error.errors[0].detail : error.message,
    };
  }
}

interface ChangePasswordResponse {
  _error: string;
}

export function* changePasswordSaga(action: Action<ChangePasswordRequestPayload>) {
  yield put(startSubmit(FORM_ID));
  const result: ChangePasswordResponse | undefined = yield call(
    requestChangePassword,
    action.payload
  );
  yield put(stopSubmit(FORM_ID, result));
  if (!result || !Object.prototype.hasOwnProperty.call(result, '_error')) {
    yield put(
      actions.passwordChangeSucceeded({
        username: action.payload.username,
        password: action.payload.credentials.newPassword,
      })
    );
  }
}

export default function* passwordSaga() {
  yield takeLatest(PASSWORD_CHANGE_REQUESTED, changePasswordSaga);
}
