import React, { ReactElement } from 'react';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';
import { useRouteMatch, Switch, Route, Redirect, useHistory } from 'react-router-dom';

import { notEmpty } from '@contactcentre-web/utils/typeGuards';

import Button from '../Button';

import { Props as TabProps } from './Tab';
import styles from './styles';

interface Props {
  styleSheet?: {
    outerTabsContainer?: StyleDeclarationValue;
    tabsContainer?: StyleDeclarationValue;
    tabContainer?: StyleDeclarationValue;
    tabContainerSelected?: StyleDeclarationValue;
    tabContainerDisabled?: StyleDeclarationValue;
    tabButton?: StyleDeclarationValue;
    tabButtonSelected?: StyleDeclarationValue;
    tabButtonDisabled?: StyleDeclarationValue;
    selection?: StyleDeclarationValue;
  };
  children: ReactElement<TabProps> | (ReactElement<TabProps> | null)[];
}

const Tabs = ({ children, styleSheet = {} }: Props) => {
  const history = useHistory();

  const childrenArray = (Array.isArray(children) ? children : [children]).filter(notEmpty);
  const defaultTabIndex = childrenArray.findIndex(({ props: { default: d } }) => !!d);
  const defaultTab = defaultTabIndex >= 0 ? childrenArray[defaultTabIndex] : undefined;

  const [selectedTab, setSelectedTab] = React.useState(defaultTabIndex || 0);

  const onSelectedTabChanged = (index: number, tabPath?: string) => {
    if (tabPath) {
      history.push(`${tabPath}`);
    } else {
      setSelectedTab(index);
    }
  };

  return (
    <div>
      <div className={css(styleSheet.outerTabsContainer)} style={{ backgroundColor: '#fff' }}>
        <div className={css(styles.tabsContainer, styleSheet.tabsContainer)}>
          {React.Children.map(
            childrenArray,
            ({ props: { title, disabled, testId, path: tabPath, exact } }, idx) => {
              const selected = tabPath
                ? useRouteMatch({
                    path: tabPath,
                    exact,
                  })
                : selectedTab === idx;

              return (
                <div
                  key={idx}
                  className={css(
                    styles.tabContainer,
                    selected ? styles.tabContainerSelected : null,
                    styleSheet.tabContainer,
                    selected ? styleSheet.tabContainerSelected : null,
                    disabled ? styleSheet.tabContainerDisabled : null
                  )}
                  data-testid={testId}
                >
                  <Button
                    variant="ghost"
                    testId={`tab-${idx}`}
                    styleSheet={
                      [
                        styles.tabButton,
                        styleSheet.tabButton,
                        selected && styleSheet.tabButtonSelected,
                        disabled && styleSheet.tabButtonDisabled,
                      ] as StyleDeclarationValue
                    }
                    disabled={disabled}
                    onClick={() => onSelectedTabChanged(idx, tabPath)}
                  >
                    {title}
                  </Button>
                  {selected && <div className={css(styles.selection, styleSheet.selection)} />}
                </div>
              );
            }
          )}
        </div>
      </div>
      {!childrenArray[selectedTab].props.path ? (
        childrenArray[selectedTab].props.children
      ) : (
        <Switch>
          {childrenArray.map(
            ({ props: { path: tabPath, exact, children: routeChildren } }, index) => (
              <Route key={index} path={tabPath} exact={exact}>
                {routeChildren}
              </Route>
            )
          )}
          {defaultTab && defaultTab.props.path && (
            <Route path="*">
              <Redirect to={defaultTab.props.path} />
            </Route>
          )}
        </Switch>
      )}
    </div>
  );
};

export default Tabs;
