import { StyleSheet } from 'aphrodite/no-important';
import { fonts, spacers, typography } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  grayLabel: {
    color: colors.gray.alpha30,
    fontSize: typography.dp_small_font_size_m,
  },

  legInfo: {
    marginTop: spacers.dp_s24,
  },

  leg: {
    marginTop: spacers.dp_s24,
    fontSize: typography.dp_body_font_size,
    lineHeight: typography.dp_body_line_height,
  },

  fromTo: {
    margin: `${spacers.dp_s16} 0`,
  },

  tripIcon: {
    marginRight: spacers.dp_s8,
    verticalAlign: 'middle',
  },

  legDates: {
    display: 'flex',
    flexDirection: 'column',
  },

  indentedContent: {
    marginLeft: spacers.dp_s32,
  },

  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  legSummary: {
    display: 'flex',
    flexDirection: 'row',
    justifyItems: 'flex-start',
  },

  legDate: {
    minWidth: '15px',
    display: 'inline-block',
  },

  timeTypeLabel: {
    minWidth: '40px',
    display: 'inline-block',
  },

  timeLabel: {
    minWidth: '70px',
    display: 'inline-block',
  },

  duration: {
    marginRight: spacers.dp_s4,
    display: 'inline-block',
  },

  station: {
    position: 'relative',
    ':after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: 2,
      width: '100%',
      borderBottom: `1px dashed ${colors.info.base}`,
    },
    marginRight: spacers.dp_s32,
    display: 'inline-block',
  },

  city: {
    paddingLeft: spacers.dp_s4,
  },

  transportInformationWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  transportInformation: {
    fontSize: typography.dp_body_font_size,
    lineHeight: typography.dp_body_line_height,
    letterSpacing: typography.dp_body_letter_spacing,
    fontWeight: fonts.dp_font_weight_bold,
    color: colors.info.dark,
    textTransform: 'capitalize',
  },
});
