import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

type BikeReservationStatus = 'Unknown' | 'Allowed' | 'AllowedWithReservation' | 'NotAllowed';

interface Props {
  status: BikeReservationStatus;
  quantity?: number;
}

const BikeReservation = ({ status, quantity }: Props) => {
  if (quantity) {
    return <FormattedMessage {...messages.reserved} values={{ quantity }} />;
  }

  if (status === 'Allowed') {
    return <FormattedMessage {...messages.reservationNotRequired} />;
  }

  if (status === 'AllowedWithReservation') {
    return <FormattedMessage {...messages.reservationRequired} />;
  }

  if (status == 'NotAllowed') {
    return <FormattedMessage {...messages.reservationNotAllowed} />;
  }

  return <>-</>;
};

export default BikeReservation;
