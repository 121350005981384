import { defineMessages } from 'react-intl';

export default defineMessages({
  ticketType: {
    id: 'TicketType.ticketType',
    defaultMessage: 'Ticket type: {value}',
  },
  comfortClass: {
    id: 'TicketType.comfortClass',
    defaultMessage: ' - {class}',
  },
  notAvailable: {
    id: 'TicketType.notAvailable',
    defaultMessage: 'N/A',
  },
});
