import { defineMessages } from 'react-intl';

export default defineMessages({
  bulkUploadGenericError: {
    id: 'BulkUpload.bulkUploadGenericError',
    defaultMessage: 'Something went wrong. Please review your upload and try again.',
  },
  bulkUploadSuccess: {
    id: 'BulkUpload.bulkUploadSuccess',
    defaultMessage: 'File has been successfully uploaded',
  },
  uploadFraudPreventionData: {
    id: 'BulkUpload.uploadRefundAbusers',
    defaultMessage: 'Upload potential refund abusers',
  },
  uploadFraudPreventionDataInvalidRequest: {
    id: 'BulkUpload.uploadRefundAbusers.uploadFraudPreventionDataInvalidRequest',
    defaultMessage: 'The upload was rejected. Is the file less than 0.5MB?',
  },
  uploadFraudPreventionErrorInvalidRow: {
    id: 'BulkUpload.uploadFraudPreventionErrorInvalidRow',
    defaultMessage: "The uploaded file doesn't contain the expected number of rows.",
  },
  uploadFraudPreventionErrorCustomerIdNonNumeric: {
    id: 'BulkUpload.uploadFraudPreventionErrorCustomerIdNonNumeric',
    defaultMessage: 'Customer identifiers are not numeric.',
  },
  uploadFraudPreventionErrorTagIdNotGuid: {
    id: 'BulkUpload.uploadFraudPreventionErrorTagIdNotGuid',
    defaultMessage: 'Tag identifiers are not in the correct format.',
  },
  uploadFraudPreventionErrorTagActionInvalid: {
    id: 'BulkUpload.uploadFraudPreventionErrorTagActionInvalid',
    defaultMessage: 'The tag action isn\'t "{addTagAction}" or "{removeTagAction}".',
  },
});
