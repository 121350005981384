import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';

import Tabs from '@contactcentre-web/common/Tabs/Tabs';
import Tab from '@contactcentre-web/common/Tabs/Tab';

import BookingLocked from '../BookingLocked/BookingLocked';
import BookingHeader from '../BookingHeader/BookingHeader';
import Journey from '../Journey/Journey';
import Tickets from '../Tickets/Tickets';

import styles from './styles';
import messages from './messages';

const Booking = (props) => {
  const {
    journeys,
    fulfilmentStatus,
    ticketDetails,
    ticketAssets,
    deliveryRecipient,
    manuallyLocked,
    convertedOrderReference,
  } = props;
  const isLockedCss = manuallyLocked ? styles.locked : null;

  return (
    <div>
      <div className={css(styles.row)}>
        <BookingHeader {...props} />
      </div>
      <Tabs>
        <Tab title={<FormattedMessage {...messages.itinerary} />} default>
          {manuallyLocked && <BookingLocked {...props} />}
          <div className={css(isLockedCss)}>
            {journeys.map((journey, index, { length }) => (
              <Journey
                key={index}
                {...journey}
                journeyNum={index + 1}
                journeyCount={length}
                convertedOrderReference={convertedOrderReference}
                fulfilmentStatus={fulfilmentStatus}
                deliveryRecipient={deliveryRecipient}
              />
            ))}
          </div>
        </Tab>
        <Tab title={<FormattedMessage {...messages.tickets} />}>
          {manuallyLocked && <BookingLocked {...props} />}
          <div className={css(isLockedCss)}>
            <Tickets ticketDetails={ticketDetails} ticketAssets={ticketAssets} />
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

Booking.propTypes = {
  journeys: PropTypes.arrayOf(PropTypes.object).isRequired,
  fulfilmentStatus: PropTypes.string,
  deliveryRecipient: PropTypes.object,
  ticketDetails: PropTypes.array,
  ticketAssets: PropTypes.array,
  manuallyLocked: PropTypes.bool,
  convertedOrderReference: PropTypes.string,
};

export default Booking;
