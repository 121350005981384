import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';
import { borderRadius } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  container: {
    width: 292,
    padding: spacers.dp_s12,
    borderRadius: borderRadius.dp_r8,
    background: colors.gray.alpha2,
    opacity: 1,
    transition: 'opacity 0.5s ease-in-out',
  },
  hidden: {
    opacity: 0,
  },
  infoIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: spacers.dp_s4,
  },
});
