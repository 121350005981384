import { StyleSheet } from 'aphrodite/no-important';
import { spacers, palettes, borderRadius } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  card: {
    borderRadius: borderRadius.dp_r8,
    backgroundColor: palettes.dp_app_backdrop_base,
    border: `solid 1px ${colors.gray.alpha12}`,
    boxShadow: `0 ${spacers.dp_s8} ${spacers.dp_s16} 0 rgba(33, 49, 77, 0.1)`,
    margin: `${spacers.dp_s24} 0`,
    overflow: 'hidden',
  },
});
