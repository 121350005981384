import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  content: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  notesList: {
    overflowX: 'hidden',
    overflowY: 'scroll',
    paddingBottom: spacers.dp_s32,
    flex: 1,
  },
  noNotes: {
    padding: spacers.dp_s16,
    textAlign: 'center',
  },
});
