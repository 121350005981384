import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';
import { debounce } from 'debounce';

import styles from './styles';

// This component will ONLY render the balloon ABOVE or BELOW the anchor as indicate by the `orientation` prop.
const Above = 'Above';
const Below = 'Below';
const arrowGap = 40;
const Align = {
  Left: 'Left',
  Right: 'Right',
  Center: 'Center',
};

class Tooltip extends React.Component {
  constructor(props) {
    super(props);

    this.anchor = null;
    this.contentBox = null;

    const { hideDelay = 200 } = props;

    this.handleScroll = this.handleScroll.bind(this);
    this.hideBalloon = debounce(this.hideBalloon, hideDelay);
    this.anchorHover = this.anchorHover.bind(this);
    this.anchorHoverOut = this.anchorHoverOut.bind(this);

    this.state = {
      balloonPosition: {
        balloonX: 0,
        balloonY: 0,
        width: 0,
        arrowX: 0,
        arrowY: 0,
        actualOrientation: Above,
      },
      balloonVisible: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousewheel', this.handleScroll);
    document.addEventListener('DOMMouseScroll', this.handleScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('mousewheel', this.handleScroll);
    document.removeEventListener('DOMMouseScroll', this.handleScroll);
  }

  handleScroll() {
    const { balloonVisible } = this.state;

    if (balloonVisible) {
      this.hideBalloon();
      this.hideBalloon.flush();
    }
  }

  setAnchorRef = (element) => {
    this.anchor = element;
  };

  setContentBoxRef = (element) => {
    this.contentBox = element;
  };

  getBalloonPosition(orientationOverride) {
    const { orientation, align } = this.props;

    // eslint-disable-next-line react/no-find-dom-node
    const contentBox = ReactDOM.findDOMNode(this.contentBox);
    // eslint-disable-next-line react/no-find-dom-node
    const anchor = ReactDOM.findDOMNode(this.anchor);

    const anchorRect = anchor.getBoundingClientRect();
    const contentBoxRect = contentBox.getBoundingClientRect();

    const windowWidth = global.innerWidth;
    const windowHeight = global.innerHeight;

    let balloonX = 0;
    let balloonY = 0;
    let arrowX = 0;
    const actualOrientation = orientationOverride || orientation;

    const anchorRectX = anchorRect.x === 0 ? 0 : anchorRect.x || anchorRect.left;
    const anchorRectY =
      (anchorRect.y === 0 ? 0 : anchorRect.y || anchorRect.top) + window.pageYOffset;

    if (anchorRect && contentBoxRect) {
      const visibleAnchorWidth =
        anchorRectX + anchorRect.width > windowWidth ? windowWidth - anchorRectX : anchorRect.width;

      switch (align) {
        case Align.Left:
          balloonX = anchorRectX;
          arrowX = anchorRectX + arrowGap;
          break;
        case Align.Right:
          balloonX = anchorRectX - (contentBoxRect.width - visibleAnchorWidth);
          arrowX = anchorRectX + visibleAnchorWidth - arrowGap;
          break;
        case Align.Center:
        default:
          balloonX = anchorRectX - (contentBoxRect.width - visibleAnchorWidth) / 2;
          arrowX = anchorRectX + visibleAnchorWidth / 2;
          break;
      }

      balloonY =
        actualOrientation === Above
          ? anchorRectY - contentBoxRect.height
          : anchorRectY + anchorRect.height;

      if (balloonX + contentBoxRect.width > windowWidth) {
        // If the alignment described above moves parts of the balloon outside of the view
        // reposition the baloon to use the available horizontal space.
        balloonX -= balloonX + contentBoxRect.width - windowWidth;
      }

      if (balloonX < 0) {
        balloonX = 0;
      }
    }

    if (!orientationOverride && balloonY < 0 && actualOrientation === Above) {
      return this.getBalloonPosition(Below);
    }

    if (
      orientationOverride === undefined &&
      balloonY + contentBoxRect.height > windowHeight &&
      actualOrientation === Below
    ) {
      return this.getBalloonPosition(Above);
    }

    return {
      x: balloonX,
      y: balloonY,
      width: contentBoxRect.width,
      arrowX,
      arrowY: 0,
      actualOrientation,
    };
  }

  hideBalloon = () => {
    this.setState({
      balloonVisible: false,
    });
  };

  showBalloon() {
    this.hideBalloon.clear();
    const { balloonVisible } = this.state;

    if (!balloonVisible) {
      this.setState({
        balloonPosition: this.getBalloonPosition(),
        balloonVisible: true,
      });
    }
  }

  anchorHover() {
    this.showBalloon();
  }

  anchorHoverOut() {
    this.hideBalloon();
  }

  render() {
    const { anchor, children, styleSheet } = this.props;
    const {
      balloonVisible,
      balloonPosition: { x, y, arrowX, actualOrientation },
    } = this.state;

    const showBalloon = balloonVisible && !!children;

    const arrow = (
      <div
        data-test-id="arrowBox"
        className={css([styles.arrow, styles[`arrow${actualOrientation}`]])}
        style={{ left: `${arrowX - x}px` }}
      />
    );

    const balloon = ReactDOM.createPortal(
      <div
        data-test-id="balloon"
        className={css(styles.balloon)}
        style={{
          top: `${y}px`,
          visibility: showBalloon ? 'visible' : 'hidden',
        }}
      >
        <div
          data-test-id="contentBox"
          ref={this.setContentBoxRef}
          className={css(styles.balloonContainer)}
          style={{ left: `${x}px` }}
          onPointerEnter={() => this.anchorHover()}
          onPointerLeave={(e) => this.anchorHoverOut(e)}
        >
          {actualOrientation === Below && arrow}
          <div className={css(styles.boxWithArrow, styleSheet)}>
            <div className={css(styles.balloonContent)}>{children}</div>
          </div>
          {actualOrientation === Above && arrow}
        </div>
      </div>,
      document.body
    );

    return (
      <>
        {balloon}
        <div
          data-test-id="anchor"
          ref={this.setAnchorRef}
          onPointerEnter={this.anchorHover}
          onPointerLeave={this.anchorHoverOut}
          className={css(styles.anchor)}
        >
          {anchor}
        </div>
      </>
    );
  }
}

Tooltip.propTypes = {
  anchor: PropTypes.node.isRequired,
  children: PropTypes.node,
  orientation: PropTypes.oneOf([Above, Below]),
  align: PropTypes.oneOf(Object.values(Align)),
  hideDelay: PropTypes.number,
  styleSheet: PropTypes.object,
};

export default Tooltip;
