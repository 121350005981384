export type CurrencyCode =
  | 'GBP'
  | 'EUR'
  | 'USD'
  | 'AUD'
  | 'JPY'
  | 'CAD'
  | 'CHF'
  | 'SEK'
  | 'DKK'
  | 'NOK';

export const currencyMap = [
  {
    code: 'GBP',
    symbol: '£',
    name: 'Pound Sterling',
  },
  {
    code: 'EUR',
    symbol: '€',
    name: 'Euro',
  },
  {
    code: 'USD',
    symbol: '$',
    name: 'US Dollar',
  },
  {
    code: 'AUD',
    symbol: 'A$',
    name: 'Australian Dollar',
  },
  {
    code: 'JPY',
    symbol: '¥',
    name: 'Japanese Yen',
  },
  {
    code: 'CAD',
    symbol: 'C$',
    name: 'Canadian Dollar',
  },
  {
    code: 'CHF',
    symbol: 'fr.',
    name: 'Swiss Franc',
  },
  {
    code: 'SEK',
    symbol: 'kr',
    name: 'Swedish Krona',
  },
  {
    code: 'DKK',
    symbol: 'kr',
    name: 'Danish Krone',
  },
  {
    code: 'NOK',
    symbol: 'kr',
    name: 'Norwegian Krone',
  },
];
