import type { Action } from 'redux-actions';
import { startSubmit, stopSubmit, reset } from 'redux-form';
import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { isEmpty } from 'ramda';

import { CUSTOMER_ORDER_SUCCESS } from '@contactcentre-web/redux-common/actions/order';
import { SAVE_ORDER_NOTE_SUCCESS } from '@contactcentre-web/order-notes/module';
import { GET_CUSTOMER_SUCCEEDED } from '@contactcentre-web/redux-common/selectors/customer';

import { actions, constants, Note, AddNoteRequestPayload, Topic } from './module';
import validateTextAreaField, { ValidateTextAreaFieldErrors } from './utils/customerNoteValidation';
import { getNotesRequest, addNoteRequest } from './utils/customerNoteService';

export const NOTE_FORM_ID = 'customerNoteForm';

interface GetNotesPayload {
  topic: Topic;
  topicId: string;
}

export function* requestGetNotesSaga(action: Action<GetNotesPayload>) {
  try {
    const response: Array<Note> = yield call(getNotesRequest, action.payload);
    yield put(actions.getNotesSucceeded(action.payload, response));
  } catch (e) {
    yield put(actions.getNotesFailed());
  }
}

export interface PutRequestNotesAction {
  type: string;
  customerId: string;
  payload: {
    order: { orderReference: string };
    topic: Topic;
    topicId: string;
    customerId: string;
  };
}

export function* putRequestNotesSaga(action: PutRequestNotesAction) {
  try {
    let topic: Topic;
    let topicId: string;
    switch (action.type) {
      case CUSTOMER_ORDER_SUCCESS:
        topic = 'Order';
        topicId = action.payload.order.orderReference;
        break;
      case GET_CUSTOMER_SUCCEEDED:
        topic = 'Customer';
        topicId = action.customerId;
        break;
      default:
        topic = action.payload.topic;
        topicId = action.payload.topicId;
        break;
    }
    yield put(actions.getNotesRequested(topic, topicId));
  } catch (e) {
    yield put(actions.getNotesFailed());
  }
}

export function* requestAddNoteSaga(payload: AddNoteRequestPayload) {
  try {
    const response: Note = yield call(addNoteRequest, payload);
    yield put(actions.addNoteSucceeded(payload, response));
  } catch (e) {
    yield put(actions.addNoteFailed());
  }
}

export function* addNoteSaga(action: Action<AddNoteRequestPayload>) {
  yield put(startSubmit(NOTE_FORM_ID));

  let errors: ValidateTextAreaFieldErrors = yield call(validateTextAreaField, action.payload);

  if (!isEmpty(errors)) {
    yield put(actions.addNoteFailed());
  } else {
    errors = yield call(requestAddNoteSaga, action.payload);
  }

  yield put(stopSubmit(NOTE_FORM_ID, errors));

  if (isEmpty(errors)) {
    yield put(reset(NOTE_FORM_ID));
  }

  yield put(actions.getNotesRequested(action.payload.topic, action.payload.topicId));
}

export default function* saga() {
  yield all([
    takeLatest(CUSTOMER_ORDER_SUCCESS, putRequestNotesSaga),
    takeLatest(SAVE_ORDER_NOTE_SUCCESS, putRequestNotesSaga),
    takeEvery(constants.GET_NOTES_REQUESTED, requestGetNotesSaga),
    takeLatest(constants.ADD_NOTE_REQUESTED, addNoteSaga),
  ]);
}
