import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'TracsOnAccount.header',
    defaultMessage: 'TraCS On Account refunds',
  },
  approvalQueueButton: {
    id: 'TracsOnAccount.approvalQueueButton',
    defaultMessage: 'Approval queue',
  },
});
