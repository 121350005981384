import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';

import styles from '../styles';

const PageItem = ({ children, style = [], active, ...props }) => {
  const display = active ? 'displayBlock' : 'displayNone';
  return (
    <section {...props} className={css(styles.pageItem, style, styles[display])}>
      {children}
    </section>
  );
};

PageItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.node]).isRequired,
  style: PropTypes.array,
  active: PropTypes.bool,
  completed: PropTypes.bool,
  disabled: PropTypes.bool,
  index: PropTypes.number,
};

export default PageItem;
