import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { isIE } from '@contactcentre-web/utils/browser-detect';
import BrowserNotSupportedBanner from '@contactcentre-web/common/BrowserNotSupportedBanner';

import ProtectedRouteContainer from './components/ProtectedRoute';
import LayoutContainer from './components/Layout';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import AppUpdatePage from './pages/AppUpdatePage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import SelectManagedGroupPage from './pages/SelectManagedGroupPage';
import SelectUILanguagePage from './pages/SelectUILanguagePage';
import CojRedirectPage from './pages/CojRedirectPage';

const App = () => (
  <>
    {isIE && <BrowserNotSupportedBanner />}
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route path="/logout" component={LogoutPage} />
        <Route path="/app_update" component={AppUpdatePage} />
        <ProtectedRouteContainer
          path="/change_password"
          component={ChangePasswordPage}
          allowPartiallyAuthenticated
        />
        <ProtectedRouteContainer
          path="/select-managed-group"
          component={SelectManagedGroupPage}
          allowPartiallyAuthenticated
        />
        <ProtectedRouteContainer
          path="/select-ui-language"
          component={SelectUILanguagePage}
          allowPartiallyAuthenticated
        />
        <ProtectedRouteContainer
          path="/coj-redirect/customers/:customerId/orders/:orderReference/bookings/:bookingId"
          exact
          component={CojRedirectPage}
        />
        <Route
          path="/agent-booking"
          component={(route) => {
            window.location.assign(route.location.pathname);
            return null;
          }}
        />
        <ProtectedRouteContainer path="*" component={LayoutContainer} />
      </Switch>
    </BrowserRouter>
  </>
);

export default App;
