import { all, put } from 'redux-saga/effects';

import { saga as customerOrderSaga } from '@contactcentre-web/customer-order/module';
import { saga as editUserSaga } from '@contactcentre-web/edit-user/module';
import { saga as replaceBookingSaga } from '@contactcentre-web/replace-booking/module';
import { saga as resendEmailSaga } from '@contactcentre-web/resend-email/module';
import { saga as currencyConverterSaga } from '@contactcentre-web/currency-converter/module';
import { saga as orderHistorySaga } from '@contactcentre-web/order-history/module';
import { saga as refreshPnrSaga } from '@contactcentre-web/refresh-pnr/module';
import { GENERAL_ERROR_MSG } from '@contactcentre-web/middlewares/errorHandleMiddleware';
import { saga as cojRedirectSaga } from '@contactcentre-web/coj-redirect/module';
import { saga as changeOfJourneySaga } from '@contactcentre-web/change-of-journey/module';
import moveOrderSaga from '@contactcentre-web/move-order/sagas';
import orderActionsSaga from '@contactcentre-web/order-actions/saga';
import orderNotesSaga from '@contactcentre-web/order-notes/saga';
import detaxeSaga from '@contactcentre-web/detaxe/sagas';
import discretionarySaga from '@contactcentre-web/refunds/Discretionary/sagas';
import sameDayVoidSaga from '@contactcentre-web/refunds/SameDayVoid/sagas';
import termsAndConditionsSaga from '@contactcentre-web/refunds/TermsAndConditions/sagas';
import compensationApprovalsSaga from '@contactcentre-web/compensation-approvals/sagas';
import refundApprovalsSaga from '@contactcentre-web/refund-approvals/sagas';
import supersededBookingSaga from '@contactcentre-web/superseded-booking/sagas';
import customerBookingsSaga from '@contactcentre-web/customer-bookings/sagas';
import searchResultsSaga from '@contactcentre-web/search-results/sagas';
import authenticationSaga from '@contactcentre-web/authentication/redux/sagas';
import compensationSaga from '@contactcentre-web/compensation/sagas';
import customerNoteSaga from '@contactcentre-web/sidebar/components/CustomerNotes/sagas';
import { saga as appBarSaga } from '@contactcentre-web/header/module';
import editCustomerProfileSaga from '@contactcentre-web/edit-customer-profile/sagas';
import customerSaga from '@contactcentre-web/customer/sagas';

const createSaga = () =>
  function* rootSaga() {
    try {
      yield all([
        authenticationSaga(),
        customerOrderSaga(),
        searchResultsSaga(),
        customerSaga(),
        customerNoteSaga(),
        customerBookingsSaga(),
        editUserSaga(),
        replaceBookingSaga(),
        currencyConverterSaga(),
        resendEmailSaga(),
        orderHistorySaga(),
        refreshPnrSaga(),
        supersededBookingSaga(),
        refundApprovalsSaga(),
        changeOfJourneySaga(),
        termsAndConditionsSaga(),
        cojRedirectSaga(),
        compensationSaga(),
        discretionarySaga(),
        sameDayVoidSaga(),
        editCustomerProfileSaga(),
        compensationApprovalsSaga(),
        moveOrderSaga(),
        orderActionsSaga(),
        orderNotesSaga(),
        appBarSaga(),
        detaxeSaga(),
      ]);
    } catch (err) {
      // eslint-disable-next-line no-undef
      newrelic.noticeError(err, {
        at: 'saga',
      });
      yield put(appBarSaga.rootSagaFailed(GENERAL_ERROR_MSG));
    }
  };

export default createSaga;
