import { StyleSheet } from 'aphrodite/no-important';
import { spacers, palettes, borderRadius } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    backgroundColor: '#FAFCFC',
    borderRadius: borderRadius.dp_r4,
    borderBottom: `solid 1px ${palettes.dp_app_border_base}`,
    boxShadow: `0 4px 8px 0 ${palettes.dp_comp_box_shadow}`,
    marginTop: spacers.dp_s32,
    overflow: 'hidden',
  },
  row: {
    display: 'flex',
    flexFDirection: 'row',
  },
  locked: {
    opacity: '0.5',
  },
  otherValidTrainsContainer: {
    display: 'flex',
  },
  tabContainer: {
    position: 'relative',
  },
});
