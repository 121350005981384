import { defineMessages } from 'react-intl';

export default defineMessages({
  booking: {
    id: 'RefreshPnrForm.booking',
    defaultMessage: 'Booking',
  },
  selectBooking: {
    id: 'RefreshPnrForm.selectBooking',
    defaultMessage: 'Select booking',
  },
  allBookings: {
    id: 'RefreshPnrForm.allBookings',
    defaultMessage: 'All bookings',
  },
  bookingMustBeSelected: {
    id: 'RefreshPnrForm.bookingMustBeSelected',
    defaultMessage: 'A booking must be selected',
  },
  refreshBooking: {
    id: 'RefreshPnrForm.refreshBooking',
    defaultMessage: 'Refresh booking',
  },
  bookingLabel: {
    id: 'RefreshPnrForm.bookingLabel',
    defaultMessage: 'Booking {bookingIndex} - {from} to {to} - {departureDay}',
  },
  info: {
    id: 'RefreshPnrForm.info',
    defaultMessage:
      "This function will create a new version of the customer's booking. It may no longer match what the customer sees in the app.",
  },
  alreadyRefreshed: {
    id: 'RefreshPnrForm.alreadyRefreshed',
    defaultMessage:
      'This booking has already been refreshed. If you need to perform another refresh, go to the latest version of the booking, by following the link in the order details, and refresh it.',
  },
});
