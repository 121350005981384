import { StyleSheet } from 'aphrodite/no-important';
import { typography, spacers, fonts } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  header: {
    display: 'flex',
    borderBottom: `1px solid ${colors.gray.alpha12}`,
    padding: `${spacers.dp_s24} ${spacers.dp_s32}`,
    gap: spacers.dp_s24,
  },
  labelSecondary: {
    fontSize: typography.dp_micro_font_size,
    letterSpacing: typography.dp_micro_letter_spacing,
    lineHeight: typography.dp_micro_line_height,
    textTransform: 'uppercase',
    fontWeight: fonts.dp_font_weight_bold,
    color: colors.gray.alpha30,
  },
  colAgent: {
    overflowWrap: 'break-word',
    maxWidth: '250px',
  },
});
