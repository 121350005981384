import { defineMessages } from 'react-intl';

export default defineMessages({
  itemHeaderTitle: {
    id: 'PaymentSummartyTableHead.item',
    defaultMessage: 'Item',
  },
  productPriceHeaderTitle: {
    id: 'PaymentSummartyTableHead.productPrice',
    defaultMessage: 'Product Price',
  },
  pricePaidHeaderTitle: {
    id: 'PaymentSummartyTableHead.pricePaid',
    defaultMessage: 'Price Paid',
  },
  orderPayment: {
    id: 'PaymentSummartyTableHead.orderPayment',
    defaultMessage: 'Order Payment',
  },
  amendReservation: {
    id: 'PaymentSummaryTableHead.amendReservation',
    defaultMessage:
      'No extra charges were made to amend the seat reservation.{br}This is the price breakdown for the original order but is still valid for the current order.',
  },
});
