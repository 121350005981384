import React from 'react';
import { css } from 'aphrodite/no-important';

import styles from './styles';

export interface Props {
  label: string | React.ReactElement;
  value: string | React.ReactElement;
  containerStyle?: React.CSSProperties;
}

const LabelWithValue = ({ label, value, containerStyle }: Props) => (
  <dl className={containerStyle ? css(containerStyle) : ''}>
    <dt className={css(styles.label)}>{label}</dt>
    <dd>{value}</dd>
  </dl>
);

export default LabelWithValue;
