import { borderRadius, palettes, spacers } from '@trainline/depot-web/css/themes/trainline';
import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({
  container: {
    border: `solid 1px ${palettes.dp_app_border_base}`,
    borderRadius: borderRadius.dp_r4,
    padding: spacers.dp_s32,
    marginBottom: spacers.dp_s32,
  },
  message: {
    textAlign: 'center',
    marginBottom: spacers.dp_s16,
  },
});
