import { defineMessages } from 'react-intl';

export default defineMessages({
  deletePassengerDel: {
    id: 'DeletePassenger.deletePassengerDel',
    defaultMessage: 'Delete this passenger',
  },
  deletePassengerDelButton: {
    id: 'DeletePassenger.deletePassengerDelButton',
    defaultMessage: 'Delete passenger',
  },
  passengerDOB: {
    id: 'DeletePassenger.passengerDOB',
    defaultMessage: 'DOB: {dob}',
  },
  passengerContactNumber: {
    id: 'DeletePassenger.passengerContactNumber',
    defaultMessage: 'Contact number: {number}',
  },
  passengerNotFoundErrorMessage: {
    id: 'DeletePassenger.passengerNotFoundErrorMessage',
    defaultMessage: "Customer's passenger not found, please refresh the page and try again",
  },
  genericErrorMessage: {
    id: 'DeletePassenger.genericErrorMessage',
    defaultMessage: "Failed to delete customer's passenger, please try again",
  },
});
