import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import type { Passenger } from '../../helpers';

import messages from './messages';

interface Props {
  passenger: Passenger;
  index: number;
  length: number;
}

const RefundPassengerDetails: FunctionComponent<Props> = ({ passenger, index, length }) => (
  <>
    {'count' in passenger ? (
      <FormattedMessage
        {...messages.passengerTypes}
        values={{ type: passenger.type.toLowerCase(), count: passenger.count }}
      />
    ) : (
      passenger.name
    )}
    {index !== length - 1 && ', '}
  </>
);

export default RefundPassengerDetails;
