import React, { FunctionComponent } from 'react';
import { css } from 'aphrodite/no-important';
import { connect } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { StatusMessage } from '@trainline/depot-web';

import Button from '@contactcentre-web/common/Button';
import TextField from '@contactcentre-web/common/TextField';
import type State from '@contactcentre-web/redux-common/types/State';

import managedGroupSelectors from '../../redux/managedGroup/selectors';

import messages from './messages';
import styles from './styles';

export const LOGIN_FORM_ID = 'login';

export interface LoginData {
  username: string;
  password: string;
}

interface StateProps {
  changeManagedGroupFailed: boolean;
}

export type Props = StateProps & {
  onSSOClick: () => void;
};

const getErrorMessage = (error: string) => {
  const errorMessage = Object.keys(messages).findIndex((message) => message === error);
  if (errorMessage) {
    return Object.values(messages)[errorMessage];
  }
  return messages.SSOUnspecified; // default error
};

export const LoginForm: FunctionComponent<Props & InjectedFormProps<LoginData, Props>> = ({
  handleSubmit,
  submitting,
  error,
  submitFailed,
  changeManagedGroupFailed,
  onSSOClick,
}) => (
  <form id={LOGIN_FORM_ID} className={css(styles.form)} onSubmit={handleSubmit} autoComplete="off">
    {error && (
      <StatusMessage status="negative">
        <span data-testid="loginFailedMsg">
          <FormattedMessage
            {...getErrorMessage(error)}
            values={{
              br: <br />,
            }}
          />
        </span>
      </StatusMessage>
    )}
    {changeManagedGroupFailed && (
      <StatusMessage status="negative">
        <span data-testid="changeManagedGroupFailedMsg">
          <FormattedMessage {...messages.selectManagedGroupFailed} />
        </span>
      </StatusMessage>
    )}
    <TextField
      id={`${LOGIN_FORM_ID}-username`}
      label={<FormattedMessage {...messages.usernameLabel} />}
      name="username"
      inputError={submitFailed}
      autoComplete="off"
      messages={messages}
      autoFocus
    />
    <TextField
      id={`${LOGIN_FORM_ID}-password`}
      label={<FormattedMessage {...messages.passwordLabel} />}
      name="password"
      type="password"
      inputError={submitFailed}
      autoComplete="off"
      messages={messages}
    />
    <Button
      type="submit"
      variant="primary"
      size="medium"
      fullWidth
      loading={submitting}
      testId="signIn"
    >
      <FormattedMessage {...messages.signIn} />
    </Button>
    <Button variant="tertiary" size="medium" onClick={onSSOClick} fullWidth testId="sso">
      <FormattedMessage {...messages.redirectSSOForm} />
    </Button>
  </form>
);

const mapStateToProps = (state: State) => ({
  changeManagedGroupFailed: managedGroupSelectors.hasSubmitFailed(state),
});

export const validate = ({ username, password }: LoginData) => ({
  username: !username ? 'usernameIsRequired' : undefined,
  password: !password ? 'passwordIsRequired' : undefined,
});

export default connect(mapStateToProps)(
  reduxForm<LoginData, Props>({
    form: LOGIN_FORM_ID,
    touchOnBlur: false,
    validate,
  })(LoginForm)
);
