import { defineMessages } from 'react-intl';

export default defineMessages({
  savedCardTitle: {
    id: 'CustomerPassengersSavedCardsList.savedCardTitle',
    defaultMessage: 'Saved customer cards',
  },
  savedCardSubtitle: {
    id: 'CustomerPassengersSavedCardsList.savedCardSubtitle',
    defaultMessage: 'Railcards, discounts and loyalty cards',
  },
});
