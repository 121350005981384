import { borderRadius, spacers, palettes } from '@trainline/depot-web/css/themes/trainline';
import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({
  container: {
    border: `solid 1px ${palettes.dp_app_positive_light}`,
    borderRadius: borderRadius.dp_r4,
    background: palettes.dp_app_positive_lightest,
    padding: spacers.dp_s32,
    marginBottom: spacers.dp_s32,
  },
  collectionReference: {
    marginTop: spacers.dp_s16,
  },
});
