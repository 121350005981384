import React, { FunctionComponent, ReactNode } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';

import FormattedDateInLondonTz from '@contactcentre-web/common/FormattedDateInLondonTz';
import FormattedTimeInLondonTz from '@contactcentre-web/common/FormattedTimeInLondonTz';
import StackedLabel from '@contactcentre-web/common/StackedLabel';

import BookingDetail from '../BookingDetail/BookingDetail';
import type { Booking } from '../../module';

import styles from './styles';
import messages from './messages';

interface Props {
  itemTypeContent: ReactNode;
  itemTimestamp: string | Date;
  bookings?: Array<Booking>;
  agent?: string;
}

const HistoryCardHeader: FunctionComponent<Props> = ({
  itemTypeContent,
  itemTimestamp,
  bookings,
  agent,
}) => (
  <div className={css(styles.header)}>
    <StackedLabel label={itemTypeContent}>
      <FormattedMessage
        {...messages.createdAt}
        values={{
          day: (
            <FormattedDateInLondonTz
              value={itemTimestamp}
              year="numeric"
              month="short"
              day="numeric"
              addOffset
            />
          ),
          time: <FormattedTimeInLondonTz value={itemTimestamp} addOffset />,
        }}
      />
    </StackedLabel>
    <StackedLabel
      label={
        <span className={css(styles.labelSecondary)}>
          <FormattedMessage {...messages.booking} />
        </span>
      }
    >
      <BookingDetail bookings={bookings} />
    </StackedLabel>
    {agent && (
      <StackedLabel
        label={
          <span className={css(styles.labelSecondary)}>
            <FormattedMessage {...messages.agent} />
          </span>
        }
        styleSheet={{
          container: { ...styles.colAgent },
        }}
      >
        {agent}
      </StackedLabel>
    )}
  </div>
);

export default HistoryCardHeader;
