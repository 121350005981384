import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';

import Link from '@contactcentre-web/common/Link';

import messages from './messages';
import styles from './styles';

const TicketAssets = ({ ticketAssets }) =>
  ticketAssets ? (
    <table className={css(styles.table)}>
      <thead>
        <tr>
          <th className={css(styles.cell, styles.headerCell)}>
            <FormattedMessage {...messages.documents} />
          </th>
          <th className={css(styles.cell, styles.headerCell)}>
            <FormattedMessage {...messages.type} />
          </th>
        </tr>
      </thead>
      <tbody className={css(styles.item)}>
        {ticketAssets.map(({ link }, idx) => (
          <tr key={link}>
            <td className={css(styles.cell, styles.bodyCell)}>
              <span className={css(styles.docIndex)}>{idx + 1}.</span>
              <Link linkType="external" href={link}>
                <FormattedMessage {...messages.document} values={{ idx: idx + 1 }} />
              </Link>
            </td>
            <td className={css(styles.cell, styles.bodyCell, styles.type)}>
              <FormattedMessage {...messages.pdf} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : null;

TicketAssets.propTypes = {
  ticketAssets: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
    })
  ),
};
export default TicketAssets;
