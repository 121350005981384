import { StyleSheet } from 'aphrodite/no-important';
import { palettes } from '@trainline/depot-web/css/themes/trainline';

const link = {
  cursor: 'pointer',
  color: palettes.dp_app_link_base,

  ':nth-child(1n) > svg': {
    fill: palettes.dp_app_link_base,
  },

  ':hover': {
    color: palettes.dp_app_link_hover,

    ':nth-child(1n) > svg': {
      fill: palettes.dp_app_link_hover,
    },
  },

  ':active': {
    color: palettes.dp_app_link_pressed,
    ':nth-child(1n) > svg': {
      fill: palettes.dp_app_link_pressed,
    },
  },

  ':focus-visible': {
    outline: 'none',
    boxShadow: `0 0 0 4px ${palettes.dp_comp_focus_ring}`,
  },
};

export default StyleSheet.create({
  default: {
    ...link,
    textDecoration: 'underline',
  },
  external: {
    display: 'inline-flex',
  },
  externalIcon: {
    width: '1em',
  },
  ghost: {
    ...link,
    ':disabled': {
      background: 'transparent',
    },
    ':disabled:hover': {
      background: 'transparent',
    },
  },
});
