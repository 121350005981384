import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  content: {
    whiteSpace: 'pre-wrap',
  },
  condition: {
    ':not(:first-child)': {
      marginTop: spacers.dp_s16,
    },
  },
});
