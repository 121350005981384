import { all, takeLatest, call, put } from 'redux-saga/effects';
import { Action } from 'redux-actions';

import request, { CUSTOM_HEADERS as headers } from '@contactcentre-web/utils/request';
import Price from '@contactcentre-web/redux-common/types/Price';
import { SortDirectionType } from '@contactcentre-web/hooks/shared/useToggleSortDirection';

import { actions, Pagination, PREFIX, LOAD_ATTEMPT, RESOLUTION_ATTEMPT } from './module';

export interface ResolutionResponse extends Pagination {
  items: {
    id: string;
    orderId: string;
    customerId: string;
    requestedDate: Date;
    amount: Price;
    reasonCode: string;
    note: string;
    isReasonCodeArchived: boolean;
  }[];
}

export interface ResolutionPayload {
  type: string;
  id: string;
  amount: Price;
  orderReference: string;
  reason: string;
  note: string;
}

export type LoadPayload = {
  page: number;
  perPage: number;
  sortDirection: SortDirectionType;
};

export const loadRequest = (page = 1, perPage = 50, sortDirection: SortDirectionType = 'Asc') => {
  const opts = {
    headers: {
      Accept: headers.accept.listMediaType,
      'Content-Type': headers.accept.listMediaType,
    },
  };

  return request(
    `/compensations?page=${page}&perPage=${perPage}&sortDirection=${sortDirection}`,
    opts
  );
};

export const resolutionRequest = (type: string, id: string, reason: string, note: string) =>
  request(`/compensations/${id}/${type}`, {
    method: 'POST',
    ...(type === 'reject' && { body: { reason, note } }),
  });

export function* loadCompensationApprovalsSaga({
  payload: { page, perPage, sortDirection },
}: Action<LoadPayload>) {
  try {
    const result: ResolutionResponse = yield call(loadRequest, page, perPage, sortDirection);
    if (!Array.isArray(result)) {
      yield put(actions.loadPaginationSuccess(result));
    } else {
      yield put(actions.loadSuccess(result));
    }
  } catch (error) {
    yield put(actions.loadError());
  }
}

export function* resolutionSaga({
  payload: { type, id, amount, orderReference, reason, note },
}: Action<ResolutionPayload>) {
  try {
    yield call(resolutionRequest, type, id, reason, note);
    yield put(actions.resolutionSuccess(type, id, amount, orderReference));
  } catch (err) {
    yield put(actions.resolutionError(err));
  }
}

export default function* saga() {
  yield all([
    takeLatest(`${PREFIX}/${LOAD_ATTEMPT}`, loadCompensationApprovalsSaga),
    takeLatest(`${PREFIX}/${RESOLUTION_ATTEMPT}`, resolutionSaga),
  ]);
}
