import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { StatusMessage } from '@trainline/depot-web';

import Link from '@contactcentre-web/common/Link';

import messages from './messages';
import styles from './styles';

interface Props {
  specialCojOrders?: Array<string>;
  customerId: string;
}

export const CojSpecial = ({ specialCojOrders, customerId }: Props) => {
  if (!specialCojOrders || specialCojOrders.length < 1) {
    return null;
  }

  return (
    <section className={css(styles.wrapper)} data-testid="coj-special-alert">
      <StatusMessage status="info">
        <p>
          <FormattedMessage {...messages.title} />
        </p>
        {specialCojOrders.map((order, index) => (
          <div key={index}>
            <Link linkType="internal" to={`/customers/${customerId}/bookings/${order}`}>
              <FormattedMessage
                {...messages.order}
                values={{
                  orderNumber: order,
                }}
              />
            </Link>
          </div>
        ))}
      </StatusMessage>
    </section>
  );
};
