import { path } from 'ramda';
import { createActions, handleActions } from 'redux-actions';

import {
  GET_CUSTOMER_SUCCEEDED,
  getCustomerLoaded,
  getSelectedCustomer,
  getCustomerEmail,
  getCustomerSucceeded,
} from '@contactcentre-web/redux-common/selectors/customer';

// Constants
const CUSTOMER_SELECTED = 'customers/CUSTOMER_SELECTED';
const GET_CUSTOMER_REQUESTED = 'customers/GET_CUSTOMER_REQUESTED';
const GET_CUSTOMER_FAILED = 'customers/GET_CUSTOMER_FAILED';
const CLEAR_CUSTOMER_DATA = 'customers/CLEAR_CUSTOMER_DATA';
const EDIT_CUSTOMER_REQUESTED = 'customers/EDIT_CUSTOMER_REQUESTED';
const EDIT_CUSTOMER_SUCCEEDED = 'customers/EDIT_CUSTOMER_SUCCEEDED';
const EDIT_CUSTOMER_FAILED = 'customers/EDIT_CUSTOMER_FAILED';

const CUSTOMER_STATS_SUCCEEDED = 'customers/CUSTOMER_STATS_SUCCEEDED';
const CUSTOMER_STATS_ERROR = 'customers/CUSTOMER_STATS_ERROR';

const CUSTOMER_STATS_STATUS_NONE = 'customers/CUSTOMER_STATS_STATUS_NONE';
const CUSTOMER_STATS_STATUS_INPROGRESS = 'customers/CUSTOMER_STATS_STATUS_INPROGRESS';
const CUSTOMER_STATS_STATUS_SUCCEEDED = 'customers/CUSTOMER_STATS_STATUS_SUCCEEDED';
const CUSTOMER_STATS_STATUS_FAILED = 'customers/CUSTOMER_STATS_STATUS_FAILED';

export const constants = {
  CUSTOMER_SELECTED,
  GET_CUSTOMER_REQUESTED,
  GET_CUSTOMER_SUCCEEDED,
  GET_CUSTOMER_FAILED,
  CLEAR_CUSTOMER_DATA,
  EDIT_CUSTOMER_REQUESTED,
  EDIT_CUSTOMER_SUCCEEDED,
  EDIT_CUSTOMER_FAILED,
  CUSTOMER_STATS_STATUS_NONE,
  CUSTOMER_STATS_STATUS_INPROGRESS,
  CUSTOMER_STATS_STATUS_SUCCEEDED,
  CUSTOMER_STATS_STATUS_FAILED,
  CUSTOMER_STATS_SUCCEEDED,
  CUSTOMER_STATS_ERROR,
};

// Reducer
const initialState = {
  selectedCustomer: null,
  customerStats: null,
  errorMessage: '',
  loading: false,
  submitted: false,
  error: false,
  customerStatsStatus: CUSTOMER_STATS_STATUS_NONE,
};

const reducer = handleActions(
  {
    [GET_CUSTOMER_REQUESTED]: (state) => ({
      ...state,
      selectedCustomer: null,
      customerStats: null,
      errorMessage: '',
      loading: true,
      submitted: false,
      error: false,
      customerStatsStatus: CUSTOMER_STATS_STATUS_NONE,
    }),
    [GET_CUSTOMER_SUCCEEDED]: (state, { payload }) => ({
      ...state,
      selectedCustomer: payload,
      loading: false,
      submitted: false,
      error: false,
      customerStatsStatus: CUSTOMER_STATS_STATUS_INPROGRESS,
    }),
    [GET_CUSTOMER_FAILED]: (state, { error }) => ({
      ...state,
      errorMessage: error,
      loading: false,
      submitted: false,
      error: true,
    }),
    [EDIT_CUSTOMER_REQUESTED]: (state) => ({
      ...state,
      loading: true,
      submitted: false,
      error: false,
    }),
    [EDIT_CUSTOMER_SUCCEEDED]: (state, { payload }) => ({
      ...state,
      selectedCustomer: payload,
      loading: false,
      submitted: true,
      error: false,
    }),
    [EDIT_CUSTOMER_FAILED]: (state, { payload }) => ({
      ...state,
      errorMessage: payload,
      loading: false,
      submitted: false,
      error: true,
    }),
    [CLEAR_CUSTOMER_DATA]: () => initialState,
    [CUSTOMER_STATS_SUCCEEDED]: (state, { payload }) => ({
      ...state,
      customerStatsStatus: CUSTOMER_STATS_STATUS_SUCCEEDED,
      customerStats: payload,
    }),
    [CUSTOMER_STATS_ERROR]: (state) => ({
      ...state,
      customerStatsStatus: CUSTOMER_STATS_STATUS_FAILED,
      customerStats: null,
    }),
  },
  initialState
);

export default reducer;

// Actions
const getCustomer = (customerId) => ({
  type: GET_CUSTOMER_REQUESTED,
  customerId,
});

const clearCustomer = () => ({
  type: CLEAR_CUSTOMER_DATA,
});

const getCustomerFailed = (payload) => ({
  type: GET_CUSTOMER_FAILED,
  error: payload,
});

const customerSelected = (customer) => ({
  type: CUSTOMER_SELECTED,
  customer,
});

export const editCustomerRequested = (payload) => ({
  type: EDIT_CUSTOMER_REQUESTED,
  payload,
});

export const editCustomerSucceeded = (payload) => ({
  type: EDIT_CUSTOMER_SUCCEEDED,
  payload,
});

export const editCustomerFailed = (payload) => ({
  type: EDIT_CUSTOMER_FAILED,
  payload,
});

const { customers: otherActions } = createActions({
  [CUSTOMER_STATS_SUCCEEDED]: (stats) => ({ ...stats }),
  [CUSTOMER_STATS_ERROR]: () => null,
});

export const actions = {
  getCustomer,
  getCustomerSucceeded,
  getCustomerFailed,
  customerSelected,
  clearCustomer,
  editCustomerRequested,
  editCustomerSucceeded,
  editCustomerFailed,
  ...otherActions,
};

// Selectors

const getCustomerError = (state) => state.error;
const getSubmitted = (state) => state.submitted;
const isLoadingStats = (state) =>
  state.customer.customerStatsStatus === CUSTOMER_STATS_STATUS_INPROGRESS;
const failedLoadingStats = (state) =>
  state.customer.customerStatsStatus === CUSTOMER_STATS_STATUS_FAILED;
const migrationDate = (state) => path(['customer', 'customerStats', 'migrationDate'], state);
const capitaineUri = (state) =>
  path(['customer', 'customerStats', 'capitaine', 'deepLinkUri'], state);

const customerStats = (state) => path(['customer', 'customerStats'], state);

export const selectors = {
  getCustomerLoaded,
  getSelectedCustomer,
  getCustomerEmail,
  getCustomerError,
  getSubmitted,
  isLoadingStats,
  failedLoadingStats,
  migrationDate,
  capitaineUri,
  customerStats,
};
