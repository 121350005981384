import { all, takeEvery, takeLatest, put, call, select } from 'redux-saga/effects';
import moment from 'moment';

import request from '@contactcentre-web/utils/request';
import { actions as customerOrderActions } from '@contactcentre-web/redux-common/actions/order';
import { selectOrder } from '@contactcentre-web/redux-common/selectors/order';

import {
  REQUEST_REFUND,
  LOAD_QUOTE,
  UPDATE_QUOTE,
  actions as refundsFormActions,
} from '../common/module';
import { extractQuoteUnprocessableReasons, extractValidationErrors } from '../utils';

import { actions, events } from './module';
import selectors from './selectors';

export const getRefundsRequest = (orderId) =>
  Promise.all([
    request(`/refundsapi/refunds/${orderId}/refund-eligibility?policy=discretionary`),
    request(`/refundsapi/orders/${orderId}/refunds`),
    request('/refundsapi/refund-reasons'),
    request(`/refundsapi/orders/${orderId}/requests`),
  ]).then(([refundables, { refunds }, reasons, pendingRefunds = []]) => ({
    refundables: { ...refundables },
    refunds,
    reasons,
    pendingRefunds,
  }));

export const getQuoteRequest = (orderId, bookingId, refundables, includeAdminFee) =>
  request(`/refundsapi/refunds/quotes`, {
    method: 'POST',
    body: {
      orderId,
      bookingId,
      policy: 'discretionary',
      refundableItems: refundables,
      includeAdminFee,
    },
  });

export const getExistingQuote = (quoteId) =>
  request(`/refundsapi/quotes/${quoteId}`, {
    method: 'GET',
  });

export const updateQuoteRequest = (
  quoteId,
  orderId,
  refundables,
  includeAdminFee,
  reason,
  isOverriding
) => {
  const uri = `/refundsapi/quotes/${quoteId}`;
  const method = 'PUT';

  return request(uri, {
    method,
    body: {
      orderId,
      policy: 'discretionary',
      refundables,
      includeAdminFee,
      reason: reason && {
        id: reason.code,
        type: reason.type,
      },
      overrideFraudProvider: isOverriding,
    },
  });
};

export const postRefundRequest = (orderReference, bookingId, quoteReference) => {
  const uri = `/refundsapi/quotes/${quoteReference}/request`;
  const method = 'POST';
  return request(uri, {
    method,
  });
};

export const approveRefundRequest = ({ href, method }) => request(href, { method });

export function* loadQuoteSaga({ payload: { orderId, bookingId, refundables } }) {
  const state = yield select();
  if (!selectors.hasSucceeded(state)) {
    return;
  }

  try {
    const pendingRefunds = yield selectors.pendingRefundRequested(state);

    const bookingPendingRefunds =
      pendingRefunds &&
      pendingRefunds.find((pendingRefund) => pendingRefund.bookingId === bookingId);

    let quote;
    if (bookingPendingRefunds) {
      quote = yield call(getExistingQuote, bookingPendingRefunds.quoteRef);
    } else {
      const isAdminFeeSelector = selectors.getAdminFee(bookingId);
      const includeAdminFee = yield call(isAdminFeeSelector, state);
      quote = yield call(getQuoteRequest, orderId, bookingId, refundables, includeAdminFee);
    }
    if (quote.unprocessableReasons) {
      quote.unprocessableReasons = extractQuoteUnprocessableReasons(quote);
    }

    yield put(
      refundsFormActions.loadQuoteSucceeded(orderId, bookingId, {
        ...quote,
        quoteUuid: moment.utc().format('YYYYMMDDHHmmss'),
      })
    );
  } catch (error) {
    if (error && error.validationErrors) {
      const mapped = extractValidationErrors(error);
      if (mapped.length !== 0) {
        yield put(
          refundsFormActions.loadQuoteSucceeded(orderId, bookingId, {
            quoteUuid: moment.utc().format('YYYYMMDDHHmmss'),
            productId: bookingId,
            quoteId: 'autogenerated-by-saga',
            refundables: [],
            unprocessableReasons: mapped.map((r) => ({ reasonCode: r })),
          })
        );

        return;
      }
    }

    yield put(refundsFormActions.loadQuoteFailed(orderId, bookingId, {}));
  }
}

export function* loadSaga() {
  try {
    const state = yield select();

    const order = selectOrder(state);
    if (!order || !selectors.inProgress(state)) {
      // Either the order is not loaded yet
      // or the order has loaded, but we're not on the refunds screen
      return;
    }

    const { refundables, refunds, reasons, pendingRefunds } = yield call(
      getRefundsRequest,
      order.id
    );

    yield put(actions.loadRefundsSucceeded(refundables, refunds, reasons, pendingRefunds));
  } catch (error) {
    yield put(actions.loadRefundsFailed(error));
  }
}

export function* updateQuoteSaga({
  payload: {
    orderId,
    bookingId,
    selection: { refundables, adminFee, reason },
    isOverriding,
  },
}) {
  const state = yield select();

  const bookingIDQuoteSelector = selectors.getBookingQuote(bookingId);
  const quoteID = yield call(bookingIDQuoteSelector, state);

  if (!selectors.hasSucceeded(state)) {
    return;
  }

  try {
    const quote = yield call(
      updateQuoteRequest,
      quoteID,
      orderId,
      refundables,
      !!adminFee && !!adminFee.deducted,
      reason,
      isOverriding
    );

    quote.unprocessableReasons =
      quote.unprocessableReasons && extractQuoteUnprocessableReasons(quote);
    yield put(
      refundsFormActions.updateQuoteSuccess(bookingId, undefined, {
        ...quote,
        quoteUuid: moment.utc().format('YYYYMMDDHHmmss'),
      })
    );
  } catch (err) {
    if (err.validationErrors) {
      const mapped = extractValidationErrors(err);
      if (mapped.length !== 0) {
        yield put(refundsFormActions.quoteValidationFailed(bookingId, mapped));

        return;
      }
    }

    yield put(refundsFormActions.updateQuoteError(bookingId, {}));
  }
}

export function* approveRefund({ payload: { approve, bookingId, customerId, orderReference } }) {
  try {
    const { status, refundId } = yield call(approveRefundRequest, approve);
    yield put(actions.approveRefundSuccess({ status: status.toLowerCase(), refundId, bookingId }));
    yield put(customerOrderActions.load(customerId, orderReference));
    yield put(actions.loadRefunds(orderReference));
  } catch (error) {
    yield put(
      actions.approveRefundFailed({
        bookingId,
        error,
        unprocessableReasons: error?.validationErrors?.map(({ code }) => ({ reasonCode: code })),
      })
    );
  }
}

export function* requestRefundSaga({
  payload: { bookingId, orderReference, quoteReference, customerId },
}) {
  const state = yield select();

  if (!selectors.hasSucceeded(state)) {
    return;
  }

  const bookingLinks = selectors.getPendingRefundLinks(bookingId);
  const canProcessBooking = selectors.canProcessBookingId(bookingId);
  const links = yield call(bookingLinks, state);
  const canProcess = yield call(canProcessBooking, state);

  try {
    if (!canProcess) {
      yield call(postRefundRequest, orderReference, bookingId, quoteReference);
      yield put(refundsFormActions.requestRefundSucceeded(bookingId));
      yield put(customerOrderActions.load(customerId, orderReference));
      yield put(actions.loadRefunds(orderReference));
    } else {
      yield put(
        actions.approveRefund({ approve: links.approve, bookingId, customerId, orderReference })
      );
    }
  } catch (error) {
    yield put(refundsFormActions.requestRefundFailed(bookingId, error));
  }
}

export default function* saga() {
  return yield all([
    takeLatest(events.LOAD_REFUNDS, loadSaga),
    takeLatest(events.LOAD_EXISTING_REFUND, loadSaga),
    takeEvery(LOAD_QUOTE, loadQuoteSaga),
    takeLatest(UPDATE_QUOTE, updateQuoteSaga),
    takeLatest(REQUEST_REFUND, requestRefundSaga),
    takeLatest(events.APPROVE_REFUND, approveRefund),
  ]);
}
