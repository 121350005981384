import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';
import { IconCheck } from '@trainline/depot-web';

import Tooltip from '../Tooltip';
import Button from '../Button';

import styles from './styles';

export class Checkbox extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (!props.defaultValue && props.input.value !== state.checked) {
      return {
        checked: props.input.value,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);

    const {
      input: { value },
      defaultValue,
    } = props;
    this.state = {
      checked: defaultValue ?? value ?? false,
    };
  }

  toggle() {
    const {
      input: { onChange },
      disabled,
      readOnly,
    } = this.props;
    if (disabled || readOnly) {
      return;
    }

    this.setState(
      ({ checked }) => ({ checked: !checked }),
      () => {
        onChange(!this.state.checked);
      }
    );
  }

  render() {
    const {
      input: { name },
      disabled,
      meta: { dirty, error } = {},
      errors = {},
      tooltipMessage,
      label,
      readOnly,
    } = this.props;
    const { checked } = this.state;
    const hasError = dirty && error;
    const showError = hasError && !!errors[error];

    return (
      <span className={css(styles.checkbox)}>
        <Tooltip
          anchor={
            <Button
              variant="ghost"
              disabled={disabled}
              styleSheet={[
                styles.default,
                checked ? styles.checked : null,
                showError ? styles.invalid : null,
                readOnly ? styles.readOnly : null,
              ]}
              name={name}
              onClick={() => this.toggle()}
            >
              {checked && <IconCheck className={css(styles.iconStyle)} />}
            </Button>
          }
          orientation="Above"
          hideDelay={0}
        >
          {errors[error]}
        </Tooltip>
        <input
          ref={this.checkbox}
          name={name}
          id={name}
          type="checkbox"
          readOnly
          disabled={disabled}
          className={css(styles.inputStyle)}
          value={checked}
        />
        <Tooltip
          anchor={
            <Button
              variant="ghost"
              onClick={() => this.toggle()}
              styleSheet={[styles.label, tooltipMessage && styles.inputWithTooltip]}
              testId="checkbox-label"
            >
              {label}
            </Button>
          }
          orientation="Above"
          hideDelay={0}
        >
          {tooltipMessage}
        </Tooltip>
      </span>
    );
  }
}

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.object.isRequired,
  errors: PropTypes.object,
  meta: PropTypes.shape({
    dirty: PropTypes.bool,
    error: PropTypes.string,
  }),
  tooltipMessage: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  defaultValue: PropTypes.bool,
  readOnly: PropTypes.bool,
};

const CheckboxField = ({ name, styleSheet, ...otherProps }) => (
  <section className={css(styles.container, styleSheet)}>
    {name ? (
      <Field
        type="checkbox"
        name={name}
        messageSpaceReserved={false}
        component={Checkbox}
        {...otherProps}
      />
    ) : (
      <Checkbox {...otherProps} />
    )}
  </section>
);

CheckboxField.propTypes = {
  name: PropTypes.string,
  styleSheet: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default CheckboxField;
