import React from 'react';
import { css } from 'aphrodite/no-important';

import OriginToDestination from '../OriginToDestination/OriginToDestination';

import styles, { fillColor } from './styles';

interface Props {
  origin: string;
  destination: string;
  isUnlimitedSize?: boolean;
}

const TicketFromTo = ({ origin, destination, isUnlimitedSize }: Props) => (
  <div>
    <h3 className={css(isUnlimitedSize ? styles.fromToUnlimited : styles.fromTo)}>
      <OriginToDestination
        shouldBreakArrow
        origin={origin}
        destination={destination}
        fillColor={fillColor}
      />
    </h3>
  </div>
);

export default TicketFromTo;
