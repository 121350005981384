import { defineMessages } from 'react-intl';

export default defineMessages({
  compensation_reloadError: {
    id: 'CompensationFailedAlert.compensation_reloadError',
    defaultMessage:
      'The compensation has been processed successfully, but there was an error reloading the data, please refresh this page.',
  },
  compensation_genericError: {
    id: 'CompensationFailedAlert.compensation_genericError',
    defaultMessage: "There's been an error processing the compensation request.",
  },
  'CompensationAmount.invalidRange': {
    id: 'CustomerOrderCompensation.invalidAmountLimit',
    defaultMessage:
      'Amount must be less than {amountLimit, number, withDecimalPlaces} {currencyCode}',
  },
});
