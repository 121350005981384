import { Action } from 'redux-actions';
import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@contactcentre-web/utils/request';

import {
  actions,
  BookingSummaries,
  BookingSummariesData,
  CUSTOMER_BOOKING_SUMMARIES_LOAD,
  PREFIX,
} from './module';

export function* loadCustomerBookingSummariesSaga({
  payload: { customerId },
}: Action<BookingSummariesData>) {
  try {
    if (!customerId) {
      throw new Error('No customer id');
    }

    const response: Array<BookingSummaries> = yield call(
      request,
      `/api/customers/v2/${customerId}/bookingsummaries`
    );
    yield put(actions.success(response));
  } catch (e) {
    yield put(actions.fail(e));
  }
}

export default function* saga() {
  yield takeLatest(
    `${PREFIX}/${CUSTOMER_BOOKING_SUMMARIES_LOAD}`,
    loadCustomerBookingSummariesSaga
  );
}
