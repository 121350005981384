import { StyleSheet } from 'aphrodite/no-important';
import { spacers, palettes, typography, fonts } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  tabsContainer: {
    borderBottom: `solid 1px ${palettes.dp_app_border_base}`,
    padding: `0 ${spacers.dp_s64}`,
  },
  tabContainer: {
    display: 'inline-block',
  },
  tabContainerSelected: {
    fontWeight: fonts.dp_font_weight_bold,
  },
  tabButton: {
    fontSize: typography.dp_small_font_size_m,
    letterSpacing: typography.dp_small_letter_spacing,
    lineHeight: typography.dp_small_line_height,
    cursor: 'pointer',
    textTransform: 'capitalize',
    padding: `${spacers.dp_s16} ${spacers.dp_s32} ${spacers.dp_s8}`,
    background: 'none',
    ':disabled': {
      cursor: 'auto',
    },
    textDecoration: 'none',
  },
  selection: {
    height: spacers.dp_s4,
    borderRadius: spacers.dp_s16,
    backgroundColor: palettes.dp_app_action_primary,
  },
});
