import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import moment from 'moment';

import { DeliveryMethod } from '@contactcentre-web/redux-common/types/DeliveryMethod';
import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency';
import VendorStatus from '@contactcentre-web/common/VendorStatus';
import FulfilmentStatus from '@contactcentre-web/common/FulfilmentStatus';
import { Validity } from '@contactcentre-web/redux-common/types/RailcardProduct';
import FulfilmentStatusEnum from '@contactcentre-web/redux-common/types/FulfilmentStatus';
import Price from '@contactcentre-web/redux-common/types/Price';
import { AdditionalValidity } from '@contactcentre-web/redux-common/types/RailcardProduct';
import Status from '@contactcentre-web/redux-common/types/FulfilmentStatus';

import styles from './styles';
import messages from './messages';

interface Props {
  name: string;
  validity: Validity;
  deliveryMethod: DeliveryMethod;
  price: Price;
  status: Status;
  additionalValidityDates: AdditionalValidity;
  fulfilmentStatus: FulfilmentStatusEnum;
}

const RailcardHeader = ({
  name,
  validity: { from, to, duration, travelDays },
  deliveryMethod,
  price,
  status,
  fulfilmentStatus,
  additionalValidityDates,
}: Props) => (
  <section className={css(styles.container)}>
    <div className={css(styles.col)}>
      <h2 className={css(styles.railcardName)} data-testid="railcard-name">
        {name}
      </h2>
      <div className={css(styles.status)}>
        <FulfilmentStatus status={fulfilmentStatus} />
      </div>
    </div>
    <div className={css(styles.col)}>
      <h4 className={css(styles.title)}>
        <FormattedMessage {...messages.validFrom} />
      </h4>
      <FormattedDate value={from} weekday="short" day="2-digit" month="short" year="numeric" />
      {duration && (
        <div className={css(styles.extraMargin)}>
          <h4 className={css(styles.title)}>
            <FormattedMessage {...messages.duration} />
          </h4>
          {moment.duration(duration).humanize()}
        </div>
      )}
    </div>
    <div className={css(styles.col)}>
      <h4 className={css(styles.title)}>
        <FormattedMessage {...messages.validTo} />
      </h4>
      <FormattedDate value={to} weekday="short" day="2-digit" month="short" year="numeric" />
      {travelDays && (
        <div className={css(styles.extraMargin)}>
          <div className={css(styles.col)}>
            <h4 className={css(styles.title)}>
              <FormattedMessage {...messages.travelDays} />
            </h4>
            {travelDays}
          </div>
        </div>
      )}
    </div>
    {additionalValidityDates && additionalValidityDates.season && (
      <div className={css(styles.col)}>
        <h4 className={css(styles.title)}>
          <FormattedMessage {...messages.seasonValidity} />
        </h4>
        <FormattedDate
          value={additionalValidityDates.season}
          weekday="short"
          day="2-digit"
          month="short"
          year="numeric"
        />
      </div>
    )}
    <div className={css(styles.col)}>
      <h4 className={css(styles.title)}>
        <FormattedMessage {...messages.deliveryMethod} />
      </h4>
      <div>
        <span>{deliveryMethod.displayName}</span>
        <div className={css(styles.status)}>
          <VendorStatus status={status} />
        </div>
      </div>
    </div>
    <div>
      <h4 className={css(styles.title)}>
        <FormattedMessage {...messages.productPrice} />
      </h4>
      <div className={css(styles.price)}>
        <strong>
          <FormattedCurrency {...price} />
        </strong>
      </div>
    </div>
  </section>
);

export default RailcardHeader;
