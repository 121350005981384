import React, { Dispatch, FunctionComponent, ReactNode } from 'react';
import { connect } from 'react-redux';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';
import { IconClose } from '@trainline/depot-web';

import Button from '@contactcentre-web/common/Button';
import type Action from '@contactcentre-web/redux-common/types/Action';

import styles from '../styles';
import { actions } from '../module';

interface DispatchProps {
  closeSidebar: () => void;
}

interface Props extends DispatchProps {
  styleSheet?: StyleDeclarationValue;
  children: ReactNode;
}

export const SidebarHeader: FunctionComponent<Props> = ({ children, closeSidebar, styleSheet }) => (
  <header className={css([styles.sidebarHeader, styleSheet])}>
    {children}
    <Button variant="ghost" styleSheet={styles.close} onClick={closeSidebar} testId="sidebar-close">
      <IconClose />
    </Button>
  </header>
);

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  closeSidebar: () => dispatch(actions.closeSidebar()),
});

export default connect(null, mapDispatchToProps)(SidebarHeader);
