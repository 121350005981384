import React, { FunctionComponent, ReactNode } from 'react';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';
import { IconInfo } from '@trainline/depot-web';

import Tooltip from '../Tooltip';

import styles from './styles';

interface Props {
  helpTip: ReactNode | string;
  styleSheet?: StyleDeclarationValue;
}

export const TooltipLabel: FunctionComponent<Props> = ({ children, helpTip, styleSheet = [] }) => (
  <div className={css(styles.container, styleSheet)}>
    {children}
    <Tooltip
      orientation="Above"
      anchor={
        <div className={css(styles.anchor)}>
          <IconInfo />
        </div>
      }
    >
      {helpTip}
    </Tooltip>
  </div>
);

export default TooltipLabel;
