import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { IconInfo, StatusMessage } from '@trainline/depot-web';

import TextField from '@contactcentre-web/common/TextField/TextField';
import Button from '@contactcentre-web/common/Button';
import Paginator, { PageItem } from '@contactcentre-web/common/Paginator';
import TextCounter from '@contactcentre-web/common/TextCounter';
import sessionStorage from '@contactcentre-web/utils/sessionStorage';
import { addPageAction } from '@contactcentre-web/utils/tracker';
import Loader from '@contactcentre-web/common/Loader';

import SurveyChoiceContainer from '../SurveyChoice/SurveyChoice';
import CrmReasonField from '../CrmReasonField';
import CrmApplicationUsed from '../CrmApplicationUsed/CrmApplicationUsed';
import CallCrmQueue from '../CallCrmQueue';

import styles from './styles';
import messages from './messages';

export const reasonCodeId = 'reasonCodeId';

const renderAlertBanner = (isLoading, isFreshdeskTicketNotAvailable, callId, lastLoggedCallId) => {
  if (isLoading || isFreshdeskTicketNotAvailable) {
    return (
      <div className={css(styles.alertBox)}>
        <StatusMessage status="warning">
          <FormattedMessage
            {...messages.freshdeskTicketNotAvailable}
            testId="freshdesk-ticket-not-available"
          />
          <Loader size={20} borderWidth={3} styleSheet={{ container: styles.loader }} />
        </StatusMessage>
      </div>
    );
  }

  if (!callId) {
    return (
      <div className={css(styles.alertBox)}>
        <StatusMessage status="warning">
          <FormattedMessage {...messages.missingCallId} testId="missing-callId-alert" />
        </StatusMessage>
      </div>
    );
  }

  if (callId === lastLoggedCallId) {
    return (
      <div className={css(styles.alertBox)}>
        <StatusMessage status="warning">
          <FormattedMessage {...messages.sameCallId} testId="same-callId" />
        </StatusMessage>
      </div>
    );
  }

  return null;
};

const OrderNoteForm = ({
  canSubmit,
  isLoading,
  isBusy,
  canLogCrm,
  change,
  form,
  reasonCodes,
  applicationTypes,
  callQueues,
  surveyChoices,
  disabled,
  callId,
  note,
  isFreshdeskTicketNotAvailable,
  managedGroupNumber,
}) => {
  const [orderNoteLength, setOrderNoteLength] = React.useState(note ? note.length : 0);
  const maxNoteLength = 500;
  const lastLoggedCallId = sessionStorage.get('lastLoggedCallId');
  const displaySurveyChoice = managedGroupNumber === 20;
  const { formatMessage } = useIntl();
  const isConflictingCallId = callId && callId === lastLoggedCallId;
  React.useEffect(() => {
    if (isConflictingCallId) {
      addPageAction('opened-log-call-page-with-same-callId', { callId });
    }
  }, [callId, lastLoggedCallId]);

  return (
    <section className={css(styles.note_container)}>
      <Paginator disabled={disabled}>
        <PageItem>
          {renderAlertBanner(isLoading, isFreshdeskTicketNotAvailable, callId, lastLoggedCallId)}
          <TextField
            label={formatMessage(messages.orderNote)}
            testId="LogCallField"
            name="note"
            styleSheet={{
              errorContainer: styles.textAreaError,
              input: styles.note_input,
              inputContainer: styles.note_input_container,
            }}
            type="multiline-text"
            maxLength={maxNoteLength}
            onChange={(event) => setOrderNoteLength(event.target.value.length)}
            messages={messages}
          />
          <div className={css(styles.notice)}>
            <div className={css(styles.noticeWithIcon)}>
              <IconInfo className={css(styles.commentIcon)} />
              <FormattedMessage {...messages.notice} />
            </div>
            <TextCounter textLength={orderNoteLength} maxLength={maxNoteLength} />
          </div>
          {canLogCrm && (
            <div className={css(styles.selectWrapper)}>
              <FormattedMessage {...messages.contactReason}>
                {(value) => <span className={css(styles.fieldLabel)}>{value}</span>}
              </FormattedMessage>
              <CrmReasonField name={reasonCodeId} reasonCodes={reasonCodes} />
              <CrmApplicationUsed applicationTypes={applicationTypes} />
            </div>
          )}
        </PageItem>
        <PageItem>
          {callQueues.length > 0 && <CallCrmQueue callQueues={callQueues} />}
          {displaySurveyChoice && (
            <SurveyChoiceContainer surveyChoices={surveyChoices} change={change} form={form} />
          )}
          <div className={css(styles.button_container)}>
            <Button
              type="submit"
              variant="primary"
              size="large"
              fullWidth
              testId="LogCallButton"
              disabled={!canSubmit}
              loading={isBusy}
            >
              <FormattedMessage {...messages.logCall} />
            </Button>
          </div>
        </PageItem>
      </Paginator>
    </section>
  );
};

OrderNoteForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isBusy: PropTypes.bool.isRequired,
  canSubmit: PropTypes.bool.isRequired,
  canLogCrm: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  reasonCodes: PropTypes.arrayOf(PropTypes.object),
  callQueues: PropTypes.arrayOf(PropTypes.object),
  surveyChoices: PropTypes.arrayOf(PropTypes.object),
  applicationTypes: PropTypes.arrayOf(PropTypes.object),
  callId: PropTypes.string,
  note: PropTypes.string,
  isFreshdeskTicketNotAvailable: PropTypes.bool,
  managedGroupNumber: PropTypes.number,
};

export default OrderNoteForm;
