import { defineMessages } from 'react-intl';

export default defineMessages({
  passengerHeader: {
    id: 'CustomerPassengersHeader.passengerHeader',
    defaultMessage: 'Passengers and customer cards',
  },
  backButton: {
    id: 'CustomerPassengersHeader.backButton',
    defaultMessage: 'Back',
  },
});
