import { StyleSheet } from 'aphrodite/no-important';
import { fonts, borderRadius, palettes, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  bookingNumber: {
    fontWeight: fonts.dp_font_weight_bold,
  },
  bookingHeader: {
    background: palettes.dp_app_backdrop_dark,
    border: `1px solid ${palettes.dp_app_border_base}`,
    borderRadius: borderRadius.dp_r4,
    padding: `${spacers.dp_s8} ${spacers.dp_s32}`,
    marginBottom: spacers.dp_s8,
  },
});
