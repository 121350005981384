import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  form: {
    marginTop: spacers.dp_s16,
  },
  errorBanner: {
    margin: `${spacers.dp_s16} 0`,
  },
});
