import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'aphrodite/no-important';

import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency';

import messages from './messages';
import styles from './styles';

const PaymentTotal = ({ currency, total, canRequestRefund }) => (
  <section className={css(styles.container)}>
    <h3 className={css(styles.totalToRefundLabel)}>
      <FormattedMessage {...messages.totalToRefund} />
    </h3>
    <span className={css(styles.totalToRefundValue)} data-test="TotalRefundAmount">
      {canRequestRefund && total !== undefined && total !== null && currency ? (
        <FormattedCurrency amount={total} currencyCode={currency} />
      ) : (
        <FormattedMessage {...messages.notApplicable} />
      )}
    </span>
  </section>
);

PaymentTotal.propTypes = {
  currency: PropTypes.string,
  total: PropTypes.number,
  canRequestRefund: PropTypes.bool.isRequired,
};

export default PaymentTotal;
