import { StyleSheet } from 'aphrodite/no-important';
import { fonts, spacers, typography } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    marginTop: spacers.dp_s16,
  },
  loadMoreButton: {
    marginInline: 'auto',
    display: 'block',
  },
  toggleButton: {
    marginInlineStart: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: spacers.dp_s4,
    ':nth-child(1n) > span': {
      fontWeight: fonts.dp_font_weight_regular,
      fontSize: typography.dp_mini_font_size,
    },
  },
});
