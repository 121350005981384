import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    width: '50%',
  },
  listItem: {
    marginLeft: spacers.dp_s24,
  },
  loneListItem: {
    listStyle: 'none',
  },
  alertContainer: {
    marginBottom: spacers.dp_s16,
  },
});
