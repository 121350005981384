import { StyleSheet } from 'aphrodite/no-important';
import { fonts, spacers, typography } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  table: {
    marginLeft: spacers.dp_s16,
    width: `calc(100% - ${spacers.dp_s16})`,
    borderSpacing: 0,
  },
  cell: {
    ':first-child': {
      width: '60%',
    },
  },
  headerCell: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    textTransform: 'uppercase',
    color: colors.gray.alpha30,
    textAlign: 'left',
    paddingLeft: spacers.dp_s8,
  },
  bodyCell: {
    padding: spacers.dp_s8,
    boxSizing: 'border-box',
    borderBottom: `1px solid ${colors.gray.alpha8}`,
  },
  type: {
    fontSize: typography.dp_small_font_size_m,
    letterSpacing: typography.dp_small_letter_spacing,
    lineHeight: typography.dp_small_line_height,
    textTransform: 'uppercase',
  },
  docIndex: {
    fontWeight: fonts.dp_font_weight_bold,
    marginRight: spacers.dp_s4,
  },
});
