import { createActions } from 'redux-actions';

const PREFIX = 'DETAXE';

const LOAD_SUCCESS = `${PREFIX}/LOAD_SUCCESS`;
const CREATE_REFUND_ATTEMPT = `${PREFIX}/CREATE_REFUND_ATTEMPT`;
const CREATE_REFUND_SUCCESS = `${PREFIX}/CREATE_REFUND_SUCCESS`;
const CREATE_REFUND_FAILED = `${PREFIX}/CREATE_REFUND_FAILED`;
const GET_REFUND_ATTEMPT = `${PREFIX}/GET_REFUND_ATTEMPT`;
const GET_REFUND_SUCCESS = `${PREFIX}/GET_REFUND_SUCCESS`;
const GET_REFUND_FAILED = `${PREFIX}/GET_REFUND_FAILED`;
const SET_REFUND_STATUS_ATTEMPT = `${PREFIX}/SET_REFUND_STATUS_ATTEMPT`;
const SET_REFUND_STATUS_SUCCESS = `${PREFIX}/SET_REFUND_STATUS_SUCCESS`;
const SET_REFUND_STATUS_FAILED = `${PREFIX}/SET_REFUND_STATUS_FAILED`;

export const actionTypes = {
  LOAD_SUCCESS,
  CREATE_REFUND_ATTEMPT,
  CREATE_REFUND_SUCCESS,
  CREATE_REFUND_FAILED,
  GET_REFUND_ATTEMPT,
  GET_REFUND_SUCCESS,
  GET_REFUND_FAILED,
  SET_REFUND_STATUS_ATTEMPT,
  SET_REFUND_STATUS_SUCCESS,
  SET_REFUND_STATUS_FAILED,
};

export const { detaxe: actionBuilder }: any = createActions({
  [LOAD_SUCCESS]: (products) => ({ products }),
  [CREATE_REFUND_ATTEMPT]: (productId) => ({ productId }),
  [CREATE_REFUND_SUCCESS]: () => null,
  [CREATE_REFUND_FAILED]: () => null,
  [GET_REFUND_ATTEMPT]: (refundId) => refundId,
  [GET_REFUND_SUCCESS]: (refundStatus) => ({ refundStatus }),
  [GET_REFUND_FAILED]: () => null,
  [SET_REFUND_STATUS_ATTEMPT]: (refundId, orderId, data) => ({
    refundId,
    orderId,
    data,
  }),
  [SET_REFUND_STATUS_SUCCESS]: () => null,
  [SET_REFUND_STATUS_FAILED]: () => null,
});
