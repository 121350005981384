import React, { ReactNode, FunctionComponent } from 'react';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import Paragraph, { ParagraphProps } from '@trainline/depot-web/components/Typography/Paragraph';

import Link from '@contactcentre-web/common/Link';

import styles from './styles';
import messages from './messages';

interface Props {
  status: string | ReactNode;
  indicatorStyles?: StyleDeclarationValue;
  labelStyles?: StyleDeclarationValue;
  testId?: string;
  canUpdateStatus?: boolean;
  customerId?: string;
  orderReference?: string;
  refundId?: string | null;
}

const StatusIndicator = ({
  status,
  indicatorStyles,
  labelStyles,
  testId,
  canUpdateStatus,
  customerId,
  orderReference,
  refundId,
}: Props) => (
  <div data-test={testId}>
    <div>
      <span className={css([styles.statusIndicator, indicatorStyles])} />
      <strong className={css([styles.statusLabel, labelStyles])}>{status}</strong>
    </div>
    {canUpdateStatus && customerId && orderReference && refundId && (
      <Link
        linkType="internal"
        to={`/customers/${customerId}/bookings/${orderReference}/update-status/${refundId}`}
      >
        <FormattedMessage {...messages.update} />
      </Link>
    )}
  </div>
);

export default StatusIndicator;

type Colors = 'base' | 'positive' | 'negative' | 'warning';

export interface StatusDetails {
  color: Colors;
  message: MessageDescriptor;
}

interface StatusIndicatorColoredProps {
  color?: Colors;
  typeStyle?: ParagraphProps['typeStyle'];
  fontWeight?: ParagraphProps['fontWeight'];
}

export const StatusIndicatorColored: FunctionComponent<StatusIndicatorColoredProps> = ({
  color = 'base',
  typeStyle = 'body',
  fontWeight = 'regular',
  children,
}) => (
  <div>
    <span className={css([styles.statusIndicator, styles[color]])} />
    <Paragraph as="span" typeStyle={typeStyle} fontWeight={fontWeight} color={color}>
      {children}
    </Paragraph>
  </div>
);
