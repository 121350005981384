import React from 'react';
import StatusMessage, {
  StatusMessageProps,
} from '@trainline/depot-web/components/Messages/StatusMessage';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import { UploadError, UploadErrorResponse } from '@contactcentre-web/hooks/api/useBulkUpload';

import messages from './messages';
import styles from './styles';

interface StatusMessage {
  status: StatusMessageProps['status'];
}

interface Props {
  isSuccess: boolean;
  uploadError: UploadErrorResponse | null;
}

interface ErrorMessage {
  id: string;
  defaultMessage: string;
  values?: any;
}

const bannerMessage: { [statusKey: string]: { id: string; defaultMessage: string; values?: any } } =
  {
    invalidrequest: messages['uploadFraudPreventionDataInvalidRequest'],
    invalidrow: messages['uploadFraudPreventionErrorInvalidRow'],
    customeridnonnumeric: messages['uploadFraudPreventionErrorCustomerIdNonNumeric'],
    tagidnotguid: {
      ...messages['uploadFraudPreventionErrorTagIdNotGuid'],
    },
    tagactioninvalid: {
      ...messages['uploadFraudPreventionErrorTagActionInvalid'],
      values: { addTagAction: 'add', removeTagAction: 'remove' },
    },
  };

const validationIssueError = 'cc.bulkuploadinvalidrequest';

const findErrorMessage = (response: UploadErrorResponse | null): Array<ErrorMessage> => {
  try {
    const uploadErrorResponse = response as UploadErrorResponse;

    let errorMessage = tryFindMultipleErrorMessage(uploadErrorResponse);

    if (errorMessage != null) {
      return errorMessage;
    }

    errorMessage = tryFindSingleErrorMessage(uploadErrorResponse);

    if (errorMessage != null) {
      return errorMessage;
    }
  } catch (error) {}

  return [];
};

const tryFindMultipleErrorMessage = (response: UploadErrorResponse): Array<ErrorMessage> | null => {
  if (!('errors' in response.errors)) {
    return null;
  }

  const validationError = response?.errors?.errors?.find((err) =>
    err.code.toLowerCase().includes(validationIssueError)
  );

  if (!validationError) return [];

  const errors = validationError.meta?.innerErrors.map(
    (err) => bannerMessage[err.code.toLowerCase()]
  );
  return errors.filter((code) => !!code);
};

const tryFindSingleErrorMessage = (response: UploadErrorResponse): Array<ErrorMessage> | null => {
  const errorList = response?.errors as Array<UploadError>;
  if (errorList[0].detail === undefined) {
    return null;
  }
  const errorMessage = {
    id: 'CC.unknown',
    defaultMessage: errorList[0].detail,
  };
  return [errorMessage];
};

const FraudPreventionUploadStatusMessage = ({ isSuccess, uploadError }: Props) => {
  const messageStatus: StatusMessage = isSuccess ? { status: 'positive' } : { status: 'negative' };
  const specificErrorMessages = findErrorMessage(uploadError);

  return (
    <StatusMessage status={messageStatus.status}>
      {isSuccess && <FormattedMessage {...messages['bulkUploadSuccess']} />}
      {!isSuccess && (
        <>
          <FormattedMessage {...messages['bulkUploadGenericError']} />
          <ul className={css(styles.list)}>
            {specificErrorMessages.map((bannerMessage) => (
              <li className={css(styles.listItem)} key={bannerMessage.id}>
                <FormattedMessage {...bannerMessage} />
              </li>
            ))}
          </ul>
        </>
      )}
    </StatusMessage>
  );
};

export default FraudPreventionUploadStatusMessage;
