import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { Heading, Paragraph, StatusMessage } from '@trainline/depot-web';

import Loader from '@contactcentre-web/common/Loader';
import Button from '@contactcentre-web/common/Button';
import {
  useRolesAndManageablePermissions,
  Role,
} from '@contactcentre-web/hooks/api/useRolesAndManageablePermissions';

import styles from './styles';
import messages from './messages';
import RolePermissions from './components/RolePermissions';

const ManagePermission = () => {
  const [openTab, setOpenTab] = useState('');
  const [rolesAndPermissions, setRolesAndPermissions] = useState<Array<Role>>([]);
  const { data, isLoading, isError, isSuccess, isFetching } = useRolesAndManageablePermissions();

  React.useEffect(() => {
    if (!isFetching && data) {
      setRolesAndPermissions(data);
    }
  }, [isFetching]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className={css(styles.title)}>
        <Heading typeStyle="title1" as="h1" color="base">
          <FormattedMessage {...messages.title} />
        </Heading>
      </div>
      {isError && (
        <StatusMessage status="negative">
          <FormattedMessage {...messages.error} />
        </StatusMessage>
      )}
      {isSuccess && rolesAndPermissions.length === 0 ? (
        <>
          <Paragraph typeStyle="body" as="p" color="base" fontWeight="regular">
            <FormattedMessage {...messages.noRolesGenerated} />
          </Paragraph>
          <Button
            variant="primary"
            size="large"
            styleSheet={styles.button}
            to="/setup-new-managed-group"
          >
            <FormattedMessage {...messages.goToGenerateRolesPage} />
          </Button>
        </>
      ) : (
        rolesAndPermissions.map(({ roleId, roleName, managedPermissions }, index) => {
          const initialPermissions =
            data?.find((role) => role.roleId === roleId)?.managedPermissions || [];

          return (
            <RolePermissions
              key={`${roleName}-${index}`}
              initialPermissions={initialPermissions}
              roleId={roleId}
              roleName={roleName}
              managedPermissions={managedPermissions}
              setOpenTab={setOpenTab}
              openTab={openTab}
              setRolesAndPermissions={setRolesAndPermissions}
            />
          );
        })
      )}
    </>
  );
};

export default ManagePermission;
