import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'virtualTerminalApprovalConfirmation.header',
    defaultMessage: "Confirm compensation's approval",
  },
  body: {
    id: 'virtualTerminalApprovalConfirmation.body',
    defaultMessage: 'This function will proceed with the compensation.',
  },
  proceed: {
    id: 'virtualTerminalApprovalConfirmation.proceed',
    defaultMessage: 'Proceed',
  },
  cancel: {
    id: 'virtualTerminalApprovalConfirmation.cancel',
    defaultMessage: 'Cancel',
  },
});
