import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import Link from '@contactcentre-web/common/Link';

import BookingDetail from '../BookingDetail/BookingDetail';
import { OrderHistoryCard, OrderHistoryCardHeader } from '../OrderHistoryCard/OrderHistoryCard';
import type { ConversionItem } from '../../module';

import messages from './messages';

export type ReplacementType = 'ReplacementFrom' | 'ReplacementTo';

interface Props extends ConversionItem {
  type: ReplacementType;
  customerId: string;
}

export const Replacement: FunctionComponent<Props> = ({
  agent,
  createdAt,
  bookings,
  requestorType,
  orderReference,
  type,
  customerId,
}) => (
  <OrderHistoryCard
    cardTitle={<FormattedMessage {...messages.replacementBooking} />}
    createdAt={createdAt}
    headerItems={
      <>
        <OrderHistoryCardHeader
          label={<FormattedMessage {...messages.booking} />}
          value={<BookingDetail bookings={bookings} />}
        />
        <OrderHistoryCardHeader
          label={<FormattedMessage {...messages.agent} />}
          value={
            <FormattedMessage
              {...messages.requestedBy}
              values={{
                requestorType,
                agent,
              }}
            />
          }
        />
      </>
    }
    cardBody={
      <FormattedMessage
        {...messages.body}
        values={{
          link: (
            <Link linkType="internal" to={`/customers/${customerId}/bookings/${orderReference}`}>
              <FormattedMessage {...messages[type]} />
            </Link>
          ),
        }}
      />
    }
  />
);

export default Replacement;
