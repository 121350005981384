import { fonts, palettes, spacers } from '@trainline/depot-web/css/themes/trainline';
import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({
  field_container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginTop: spacers.dp_s16,
  },
  label: {
    color: palettes.dp_app_text_base,
    fontWeight: fonts.dp_font_weight_regular,
    marginBottom: spacers.dp_s16,
  },
  label_container: {
    width: '100%',
  },
  field: {
    flex: '1 1 auto',
  },
  field_ok: {
    borderColor: palettes.dp_app_positive_mid,
  },
  icon: {
    flex: '0 0 auto',
    margin: `0 0 ${spacers.dp_s12} ${spacers.dp_s4}`,
    color: palettes.dp_app_positive_mid,
  },
});
