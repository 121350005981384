import { StyleSheet } from 'aphrodite/no-important';
import { fonts, spacers, typography } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  container: {
    width: '100%',
  },
  header: {
    paddingTop: spacers.dp_s24,
    display: 'flex',
    flexDirection: 'row',
    background: '#fff',
    padding: `${spacers.dp_s24} ${spacers.dp_s64}`,
    width: '100%',
    justifyContent: 'space-between',
  },
  label: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    color: colors.gray.alpha30,
    textTransform: 'uppercase',
    marginBottom: spacers.dp_s8,
    fontWeight: fonts.dp_font_weight_semibold,
  },
  value: {
    lineHeight: typography.dp_mini_font_size_m,
    fontWeight: fonts.dp_font_weight_semibold,
  },
  journey: {
    maxWidth: '30%',
  },
  journeyValue: {
    fontFamily: typography.dp_title2_font_family,
    fontSize: typography.dp_title2_font_size,
    fontWeight: fonts.dp_font_weight_bold,
    lineHeight: typography.dp_title2_line_height,
    letterSpacing: '-0.3px', //TODO: replace with depot-web typography when updated in depot-web
    color: colors.gray.alpha80,
  },
  productTypeLabel: {
    fontWeight: fonts.dp_font_weight_regular,
    color: colors.gray.alpha30,
  },
  deliveryMethodValue: {
    lineHeight: typography.dp_mini_font_size_m,
    fontWeight: fonts.dp_font_weight_regular,
    textTransform: 'capitalize',
  },
  ctrValue: {
    lineHeight: typography.dp_mini_font_size_m,
    fontWeight: fonts.dp_font_weight_regular,
  },
  price: {
    textAlign: 'right',
    fontWeight: fonts.dp_font_weight_bold,
  },
  validityLabel: {
    color: colors.black,
    textTransform: 'capitalize',
    fontWeight: fonts.dp_font_weight_regular,
    lineHeight: typography.dp_mini_font_size_m,
  },
  validityValue: {
    lineHeight: typography.dp_body_line_height,
  },
  validityToContainer: {
    marginTop: spacers.dp_s8,
  },
  status: {
    marginTop: spacers.dp_s4,
    fontWeight: fonts.dp_font_weight_bold,
    fontSize: typography.dp_mini_font_size_m,
  },
  trainlineProductStatus: {
    textAlign: 'left',
  },
});

export const fillColor = colors.gray.alpha30;
