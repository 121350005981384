import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import messages from './messages';
import styles from './styles';

interface Props {
  search: string;
  customersCount: number;
}

const CustomerListTitle: FunctionComponent<Props> = ({ search, customersCount }) => (
  <h1 className={css(styles.customerTitle)}>
    <span className={css(styles.customerResult)}>
      <FormattedMessage {...messages.customerNumber} values={{ customersCount }} />
    </span>{' '}
    <span data-testid="searchTerm">{search}</span>
  </h1>
);

export default CustomerListTitle;
