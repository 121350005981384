import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'ManagePermission.title',
    defaultMessage: 'Manage permission',
  },
  noRolesGenerated: {
    id: 'ManagePermission.noRolesGenerated',
    defaultMessage: `No roles have been generated. You'll need to do this first before assigning/editing permissions.`,
  },
  goToGenerateRolesPage: {
    id: 'ManagePermission.goToGenerateRolesPage',
    defaultMessage: 'Go to generate roles page',
  },
  error: {
    id: 'ManagePermission.error',
    defaultMessage: 'Something went wrong while getting the roles and permissions',
  },
});
