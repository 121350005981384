import { defineMessages } from 'react-intl';

export default defineMessages({
  addNoteButton: {
    id: 'CustomerNoteForm.addNoteButton',
    defaultMessage: 'Add note',
  },
  deleteDraft: {
    id: 'CustomerNoteForm.deleteDraft',
    defaultMessage: 'Delete draft  🗑',
  },
  writeNote: {
    id: 'CustomerNoteForm.writeNote',
    defaultMessage: 'Write a note…',
  },
});
