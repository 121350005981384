import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    columnGap: spacers.dp_s48,
  },
});
