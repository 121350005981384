import { MutationOptions, useMutation, useQuery } from 'react-query';

import request from '@contactcentre-web/utils/request';

export type Permission = {
  code: string;
  name: string;
  enabled: boolean;
};

export type Role = {
  roleId: string;
  roleName: string;
  managedPermissions: Array<Permission>;
};

type Roles = {
  items: Array<Role>;
};

type UpdateRolePermissionsPayload = {
  roleId: string;
  permissions: Array<Permission>;
};

export const useRolesAndManageablePermissions = () =>
  useQuery('rolesAndPermissions', () => request<Roles>('/authapi/roles/manageablepermissions'), {
    select: ({ items }) => items,
  });

export const useUpdateRolePermissions = (
  mutationOptions: MutationOptions<unknown, unknown, UpdateRolePermissionsPayload, unknown>
) =>
  useMutation(
    ({ roleId, permissions }) =>
      request(`/authapi/roles/${roleId}/permissions`, {
        method: 'PATCH',
        body: { permissions },
      }),
    mutationOptions
  );
