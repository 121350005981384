import React, { FunctionComponent } from 'react';
import { css } from 'aphrodite/no-important';

import styles from '../../../../styles';

const RevocationResultsTableBody: FunctionComponent = ({ children }) => (
  <tbody className={css(styles.tableBody)}>{children}</tbody>
);

export default RevocationResultsTableBody;
