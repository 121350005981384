import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { getFormValues } from 'redux-form';

import request from '@contactcentre-web/utils/request';
import * as customerOrderSelectors from '@contactcentre-web/redux-common/selectors/order';

import { changeCTR, actions } from './module';

const FORM_ID = 'RemoveRestrictionForm';

export const loadRemoveRestrictionReasons = (orderReference, bookingReference) =>
  request(`/api/orders/${orderReference}/bookings/${bookingReference}/removeRestrictionReasons`);

export const removeCollectionRestriction = (orderId, bookingId, reason) =>
  request(`/api/orders/${orderId}/bookings/${bookingId}/removeCollectionRestriction`, {
    method: 'POST',
    body: { reason },
  });

export function* removeCollectionRestrictionSaga({
  payload: { orderId, bookingId, bookingIndex, reason },
}) {
  try {
    yield call(removeCollectionRestriction, orderId, bookingId, reason);
    yield put(actions.removeRestrictionSuccess(bookingIndex));
  } catch (err) {
    const { validationErrors } = err;
    if (validationErrors && validationErrors.length) {
      // We receive error code from BE
      yield put(actions.removeRestrictionFailed());
    } else {
      // If we get a timeout, throw error so root middleware can handle and show generic error message
      yield put(actions.removeRestrictionNone(err));
    }
  }
}

export function* loadRemoveRestrictionReasonsSaga() {
  try {
    const state = yield select();
    const { bookingId } = getFormValues(FORM_ID)(state);
    const { orderReference } = customerOrderSelectors.getOrder(state);
    const reasons = yield call(loadRemoveRestrictionReasons, orderReference, bookingId);
    yield put(actions.removeRestrictionReasonsLoadSuccess(reasons));
  } catch (err) {
    yield put(actions.removeRestrictionReasonsLoadFailed(err));
  }
}

export default function* saga() {
  return yield all([
    takeLatest(changeCTR.REMOVE_RESTRICTION_ATTEMPT, removeCollectionRestrictionSaga),
    takeLatest(changeCTR.REMOVE_RESTRICTION_REASONS_LOAD, loadRemoveRestrictionReasonsSaga),
  ]);
}
