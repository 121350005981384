import { defineMessages } from 'react-intl';

export default defineMessages({
  discountType_promocode: {
    id: 'Refunds.ProductTotals.discountType_promocode',
    defaultMessage: 'Promo/voucher discount',
  },
  discountType_unknown: {
    id: 'Refunds.ProductTotals.discountType_unknown',
    defaultMessage: 'Unknown ({code})',
  },
  productsToRefund: {
    id: 'Refunds.ProductTotals.productsToRefund',
    defaultMessage: 'Products to refund',
  },
  productsToRefundNA: {
    id: 'Refunds.ProductTotals.productsToRefundNA',
    defaultMessage: 'N/A',
  },
});
