import { defineMessages } from 'react-intl';

export default defineMessages({
  orderId: {
    id: 'CustomerOrderHeader.orderId',
    defaultMessage: 'order id',
  },

  total: {
    id: 'CustomerOrderHeader.total',
    defaultMessage: 'total',
  },

  labelBookedVia: {
    id: 'CustomerOrderHeader.labelBookedVia',
    defaultMessage: 'booked via',
  },

  labelCustomerAuthentication: {
    id: 'CustomerOrderHeader.labelCustomerAuthentication',
    defaultMessage:
      '{customerAuthentication, select, RegisteredOrder {Registered Order} GuestOrder {Guest Order} Unknown {Unknown} other {Value Not Valid}}',
  },

  labelCreatedOn: {
    id: 'CustomerOrderHeader.labelCreatedOn',
    defaultMessage: 'Created on',
  },

  createdOn: {
    id: 'CustomerOrderHeader.createdOn',
    defaultMessage: '{date} at {time}',
  },

  labelPrice: {
    id: 'CustomerOrderHeader.labelPrice',
    defaultMessage: 'Price (inc. fees)',
  },

  tracsOrderNotification: {
    id: 'CustomerOrderHeader.tracsOrderNotification',
    defaultMessage: 'This is a tracs booking. Please use the T3 contact centre tool.',
  },

  convertedAnyCardSuccess: {
    id: 'CustomerOrderHeader.convertedAnyCardSuccess',
    defaultMessage:
      'Booking {bookingIndex} successfully coverted to anycard collection. You can <link>view the history log</link> for this action.',
  },
});
