import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'manualEntryConsoleHeader.title',
    defaultMessage: 'MEC claim queue',
  },
  claimsQueue: {
    id: 'manualEntryConsoleHeader.claimsQueue',
    defaultMessage: 'Claims in queue:',
  },
  approvedClaims: {
    id: 'manualEntryConsoleHeader.approvedClaims',
    defaultMessage: 'Approved claims:',
  },
  pendingClaimsButton: {
    id: 'manualEntryConsoleHeader.pendingClaimsButton',
    defaultMessage: 'Pending claims',
  },
  approvedClaimsButton: {
    id: 'manualEntryConsoleHeader.approvedClaimsButton',
    defaultMessage: 'Approved claims',
  },
  downloadMECSheet: {
    id: 'manualEntryConsoleHeader.downloadMECSheet',
    defaultMessage: 'Download MEC treasury sheet',
  },
  unableToDownload: {
    id: 'manualEntryConsoleHeader.unableToDownload',
    defaultMessage: 'Unable to download treasury sheet',
  },
  createNewClaim: {
    id: 'manualEntryConsoleHeader.createNewClaim',
    defaultMessage: 'Create new claim',
  },
  reasonCodeFilterButton: {
    id: 'manualEntryConsoleHeader.reasonCodeFilterButton',
    defaultMessage: 'Reason Code',
  },
  SingleUseTravelProductFilterButton: {
    id: 'manualEntryConsoleHeader.dailyTicketsFilterButton',
    defaultMessage: 'Daily tickets',
  },
  RailcardProductFilterButton: {
    id: 'manualEntryConsoleHeader.railcardsFilterButton',
    defaultMessage: 'Railcards',
  },
  SeasonTravelProductFilterButton: {
    id: 'manualEntryConsoleHeader.seasonsFilterButton',
    defaultMessage: 'Seasons',
  },
  filterReasonModalTitle: {
    id: 'manualEntryConsoleHeader.filterReasonModalTitle',
    defaultMessage: 'Filter by reason code',
  },
});
