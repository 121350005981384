import { createSelector } from 'reselect';
import { compose, pluck, flatten, propEq, filter, find, path, uniqWith, eqProps } from 'ramda';
import moment from 'moment';

import { selectors as orderSelectors } from '@contactcentre-web/customer-order/module';
import * as userSelectors from '@contactcentre-web/authentication/redux/selectors';

import {
  isFraudNonProcessable,
  isFraudNonProcessableQuote,
  isFraudNonProcessableQuoteOverride,
} from '../utils';

import { load, update, refund } from './module';

const localState = (state) => state.sdv;
const getOrder = (state) => orderSelectors.getOrder(state);
const getTransactionSummary = (state) => orderSelectors.getTransactionSummary(state);

const getSDVOrder = createSelector(
  (state) => state.sdv.order,
  (order) =>
    order
      ? {
          ...order,
          hasLockableBookings: orderSelectors.hasLockableBookings(order),
        }
      : null
);

const getRequestStatus = createSelector(localState, (state) => state.requestStatus);

const isLoadPending = (state) => state.loadStatus === load.LOAD_ATTEMPT;
const isLoadSuccess = (state) => state.loadStatus === load.LOAD_SUCCESS;
const isLoadFailed = (state) => state.loadStatus === load.LOAD_FAILED;
const isUpdatePending = (state) => state.updateStatus === update.UPDATE_ATTEMPT;
const isUpdateSuccess = (state) => state.updateStatus === update.UPDATE_SUCCESS;
const isUpdateFailed = (state) => state.updateStatus === update.UPDATE_FAILED;
const isRefundPending = (state) => state.refundStatus === refund.REFUND_ATTEMPT;
const isRefundSuccess = (state) => state.refundStatus === refund.REFUND_SUCCESS;
const isRefundFailed = (state) => state.refundStatus === refund.REFUND_FAILED;
const isRefundFailedWithErrors = (state) =>
  state.refundStatus === refund.REFUND_FAILED && !!state.requestStatus?.error;

const getInitialValues = createSelector(getSDVOrder, (order) => {
  if (order) {
    const { refundables } = order;
    return refundables.reduce(
      (prev, { id, isSelected }) => ({
        ...prev,
        [id]: isSelected,
      }),
      {}
    );
  }
  return null;
});

const getSelectability = (array, prop) =>
  compose(
    pluck('refundableId'),
    filter(
      (x) =>
        x && x.selectability && x.selectability.selectedByDefault && !x.selectability.selectable
    ),
    flatten,
    pluck(prop)
  )(array);

const getMandatoryRefundablesIds = createSelector(getSDVOrder, (order) => {
  if (order && order.refundables) {
    const { refundables } = order;
    const refundableFees = getSelectability(refundables, 'fees');
    const refundableTicket = getSelectability(refundables, 'passengerRefundables');
    const refundableInsurance = getSelectability(refundables, 'insuranceRefundables');
    const orderRefundables = refundableFees.concat(refundableTicket, refundableInsurance);

    return orderRefundables;
  }
  return [];
});

const getQuoteReference = createSelector(
  getSDVOrder,
  (order) => path(['quote', 'quoteId'], order) || null
);

const getTicketReturnModes = createSelector(getSDVOrder, (order) =>
  path(['travelBookings'], order)
    ? order.travelBookings
        .filter((b) => path(['refund', 'ticketReturnMode'], b))
        .map((b) => b.refund.ticketReturnMode)
        .filter((v, i, a) => a.findIndex((e) => e.name === v.name) === i)
    : []
);

const getFeeRefundable = (paymentSummaryFees, refundables, quote, type) => {
  const price = paymentSummaryFees.reduce(
    (acc, current) => ({
      amount: acc.amount + (current.price ? current.price.amount : 0),
      currencyCode: (current.price && current.price.currencyCode) || acc.currencyCode,
    }),
    { currencyCode: '', amount: 0 }
  );

  const { refundableId, selectability, status } = compose(
    find(propEq('type', type)),
    flatten,
    pluck('fees')
  )(refundables);

  const quoteRefundable =
    quote.refundables && quote.refundables.find(({ id }) => id === refundableId);

  return {
    id: refundableId,
    description: refundableId, // TODO: This will cause a lot of pain in the future, but we have to live with it for now
    isSelected: !!quoteRefundable,
    isChangeable:
      !!selectability &&
      selectability.selectable &&
      (!isFraudNonProcessableQuote(quote) || isFraudNonProcessableQuoteOverride(quote)),
    refundable: (status || '').toLowerCase() === 'refundable',
    price,
    isEligible: !!selectability && selectability.selectedByDefault,
  };
};

const canRequestStandardRefund = (state) => userSelectors.canRequestStandardRefund(state);
const hasRefundPermissions = createSelector(canRequestStandardRefund, (permission) => permission);

const getQuote = createSelector(
  getSDVOrder,
  getRequestStatus,
  getOrder,
  getTransactionSummary,
  (sdvOrder, sdvRequestStatus, order, transactionSummary) => {
    if (!sdvOrder || !order || !transactionSummary) {
      return {};
    }
    const {
      refundables,
      quote: {
        totalRefundableAmount,
        totalToRefund,
        ticketReturnMode,
        unprocessableReasons,
        discounts,
      },
    } = sdvOrder;

    const bookingFees = transactionSummary.fees.filter(({ type }) => type === 'booking-fee');
    const cardFees = transactionSummary.fees.filter(({ type }) => type === 'payment-fee');

    const isOverridable = isFraudNonProcessable(sdvOrder.quote, sdvRequestStatus);
    const isOverriden = isFraudNonProcessableQuoteOverride(sdvOrder.quote);
    const uniqueUnprocessableReasons = [
      ...(unprocessableReasons || []),
      ...(sdvRequestStatus?.unprocessableReasons ?? []),
    ]
      .filter(Boolean)
      .reduce(
        (reasonArr, unprocessableReason) =>
          reasonArr.some((r) => r.reasonCode === unprocessableReason?.reasonCode)
            ? reasonArr
            : [...reasonArr, unprocessableReason],
        []
      );

    return {
      totalRefundableAmount,
      totalToRefund,
      ticketReturnMode,
      bookingFee: getFeeRefundable(bookingFees, refundables, sdvOrder.quote, 'booking-fee'),
      paymentFee: getFeeRefundable(cardFees, refundables, sdvOrder.quote, 'payment-fee'),
      unprocessableReasons: uniqueUnprocessableReasons,
      canProcess:
        !uniqueUnprocessableReasons ||
        uniqueUnprocessableReasons.length === 0 ||
        (isOverridable && isOverriden),
      isOverridable,
      isOverriden,
      discounts,
    };
  }
);

const getBookings = createSelector(
  getOrder,
  getQuote,
  isRefundSuccess,
  isRefundFailed,
  userSelectors.getCurrentManagedGroupNumber,
  (order, quote, refundSuccess, refundFailed, managedGroupNumber) => {
    if (!order) {
      return [];
    }

    const { travelBookings } = order;

    return travelBookings
      .map(({ journeys, fulfilmentStatus, isReturn, vendor }) => {
        const farePassengers = (legs) =>
          compose(uniqWith(eqProps('id')), flatten, pluck('passengers'))(legs);

        const bookingJourneys = journeys.map(
          ({ origin, destination, departAt, arriveAt, legs }) => ({
            origin,
            destination,
            departAt,
            arriveAt,
            farePassengers: farePassengers(legs).map(
              ({ farePassengerId, id, type, railCards }) => ({
                farePassengerId,
                id,
                passengerType: type,
                railCards: railCards.map(({ code, name }) => ({ railcardType: code, name })),
              })
            ),
          })
        );

        const isATOC = vendor?.toLowerCase() === 'atoc';
        const isMG20 = managedGroupNumber === 20;

        const refundAlertMessage = (() => {
          if (
            !refundSuccess &&
            !refundFailed &&
            quote?.ticketReturnMode !== 'automatic' &&
            quote?.ticketReturnMode !== 'deletefromdevice' &&
            isATOC &&
            isMG20
          ) {
            return 'customerAdvisory';
          }
          if (quote?.ticketReturnMode === 'deletefromdevice') {
            return 'deleteFromDevicePreProcess';
          }
          return undefined;
        })();

        return {
          fulfilmentStatus,
          isReturn,
          refundAlertMessage,
          // return journeys are displayed just once, since they are connected.
          journeys: isReturn ? [bookingJourneys[0]] : bookingJourneys,
        };
      })
      .sort((travelBooking1, travelBooking2) =>
        moment
          .utc(travelBooking1.journeys[0]?.departAt)
          .diff(moment.utc(travelBooking2.journeys[0]?.departAt))
      );
  }
);

const selectors = {
  getOrder,
  getBookings,
  getQuote,
  getInitialValues,
  getQuoteReference,
  getMandatoryRefundablesIds,
  isLoadPending,
  isLoadSuccess,
  isLoadFailed,
  isUpdatePending,
  isUpdateSuccess,
  isUpdateFailed,
  isRefundPending,
  isRefundSuccess,
  isRefundFailed,
  isRefundFailedWithErrors,
  getTicketReturnModes,
  hasRefundPermissions,
};

export default selectors;
