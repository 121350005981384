import { defineMessages } from 'react-intl';

export default defineMessages({
  firstNameLabel: {
    id: 'AlterPassengersForm.firstNameLabel',
    defaultMessage: 'First name',
  },
  lastNameLabel: {
    id: 'AlterPassengersForm.lastNameLabel',
    defaultMessage: 'Last name',
  },
  saveChanges: {
    id: 'AlterPassengersForm.saveChanges',
    defaultMessage: 'Save changes',
  },
  validationFirstNameRequired: {
    id: 'AlterPassengersForm.validationFirstNameRequired',
    defaultMessage: 'Enter a first name',
  },
  validationLastNameRequired: {
    id: 'AlterPassengersForm.validationLastNameRequired',
    defaultMessage: 'Enter a last name',
  },
});
