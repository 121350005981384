import { StyleSheet } from 'aphrodite/no-important';
import { fonts, palettes, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  topicItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `${spacers.dp_s24} ${spacers.dp_s32}`,
    borderBottom: `1px solid ${palettes.dp_app_border_base}`,
  },
  topicTitle: {
    padding: spacers.dp_s16,
    fontWeight: fonts.dp_font_weight_semibold,
  },
});
