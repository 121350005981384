import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { css } from 'aphrodite/no-important';
import { StatusMessage } from '@trainline/depot-web';
import { FormattedMessage } from 'react-intl';

import Loader from '@contactcentre-web/common/Loader';
import { selectors as featureSelectors } from '@contactcentre-web/feature-flags/module';
import {
  PaperTicketQueueName,
  useNextPaperTicketFromQueue,
} from '@contactcentre-web/hooks/api/usePaperTicketQueue';

import PaperTicketHeader from './components/PaperTicketHeader/PaperTicketHeader';
import styles from './styles';
import PaperTicketImage from './components/PaperTicketImage/PaperTicketImage';
import PaperTicketForm from './components/PaperTicketForm/PaperTicketForm';
import messages from './messages';

const PaperTicketQueue = () => {
  const isPaperTicketEnabled = useSelector(featureSelectors.isPaperTicketEnabled);

  if (!isPaperTicketEnabled) {
    return null;
  }

  const location = useLocation();
  const history = useHistory();
  const initialQueue =
    (new URLSearchParams(location.search).get('queue') as PaperTicketQueueName) ??
    PaperTicketQueueName.Unmatched;
  const [currentTab, setCurrentTab] = useState(initialQueue);
  const { data, isLoading, error } = useNextPaperTicketFromQueue(currentTab);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('queue', currentTab);

    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, [currentTab]);

  return (
    <>
      <PaperTicketHeader currentTab={currentTab} tabChanges={setCurrentTab} />
      {isLoading && <Loader />}
      {error && (
        <StatusMessage status="negative">
          <FormattedMessage {...messages.unknownError} />
        </StatusMessage>
      )}
      {data?.ticket && (
        <section className={css(styles.container)} data-testid="paperTicketContainer">
          <PaperTicketImage ticket={data.ticket} currentTab={currentTab} />
          <PaperTicketForm ticket={data.ticket} currentTab={currentTab} />
        </section>
      )}
    </>
  );
};

export default PaperTicketQueue;
