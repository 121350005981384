import React from 'react';

import PageContainer from '@contactcentre-web/common/PageContainer';
import CojRedirect from '@contactcentre-web/coj-redirect/CojRedirect';

const CojRedirectPage = () => (
  <PageContainer>
    <CojRedirect />
  </PageContainer>
);

export default CojRedirectPage;
