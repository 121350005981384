import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'CompensationsList.title',
    defaultMessage: 'Previous compensation requests',
  },
  amount: {
    id: 'CompensationsList.amount',
    defaultMessage: 'amount',
  },
  reason: {
    id: 'CompensationsList.reason',
    defaultMessage: 'reason',
  },
  statusHeader: {
    id: 'CompensationsList.statusHeader',
    defaultMessage: 'status',
  },
  requested: {
    id: 'CompensationsList.requested',
    defaultMessage: 'date requested',
  },
  requestedBy: {
    id: 'CompensationsList.requestedBy',
    defaultMessage: 'requested by',
  },
  completion: {
    id: 'CompensationsList.completion',
    defaultMessage: 'completion date',
  },
  completedBy: {
    id: 'CompensationsList.completedBy',
    defaultMessage: 'completed by',
  },
  status: {
    id: 'CompensationsList.status',
    defaultMessage:
      '{status, select, Requested {Requested} Completed {Approved} Rejected {Rejected} Manual {Approved} Pending {Pending} other {Other}}',
  },
  empty: {
    id: 'CompensationsList.empty',
    defaultMessage: 'No previous requests.',
  },
  completionNA: {
    id: 'CompensationsList.completionNA',
    defaultMessage: 'N/A',
  },
  rejectionReason: {
    id: 'CompensationsList.rejectionReason',
    defaultMessage: '<b>Rejection reason:</b> {rejectionReason}',
  },
  rejectionNote: {
    id: 'CompensationsList.rejectionNote',
    defaultMessage: '<b>Rejection note:</b> {rejectionNote}',
  },
});
