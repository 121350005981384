import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';
import { StatusMessage } from '@trainline/depot-web';
import { FormattedMessage } from 'react-intl';

import BookingSummaryHeader from '../../../common/BookingSummaryHeader';
import JourneyDetails from '../../../common/JourneyDetails';

import styles from './styles';
import messages from './messages';

const SameDayVoidBooking = ({
  fulfilmentStatus,
  isReturn,
  journeys,
  number,
  insurance,
  isSDVRefundsCCRSEnabled,
  noPermissionsAlert,
  refundAlertMessage,
}) => (
  <div>
    <BookingSummaryHeader fulfilmentStatus={fulfilmentStatus} journeys={journeys} number={number} />
    {refundAlertMessage && messages[refundAlertMessage] && (
      <div className={css(styles.notification)}>
        <StatusMessage status="warning">
          <FormattedMessage {...messages[refundAlertMessage]} testId={refundAlertMessage} />
        </StatusMessage>
      </div>
    )}
    {noPermissionsAlert}
    <div>
      {journeys.map((journey, idx) => (
        <div key={idx} className={css(styles.journeyDetailsContainer)}>
          <JourneyDetails
            {...journey}
            isReturn={isReturn}
            insurance={insurance}
            farePassengers={journey.farePassengers}
            isSDVRefundsCCRSEnabled={isSDVRefundsCCRSEnabled}
          />
        </div>
      ))}
    </div>
  </div>
);

SameDayVoidBooking.propTypes = {
  fulfilmentStatus: PropTypes.string.isRequired,
  journeys: PropTypes.arrayOf(Object).isRequired,
  number: PropTypes.number.isRequired,
  isReturn: PropTypes.bool,
  insurance: PropTypes.object,
  isSDVRefundsCCRSEnabled: PropTypes.bool,
  noPermissionsAlert: PropTypes.object,
  refundAlertMessage: PropTypes.string,
};

export default SameDayVoidBooking;
