import { StyleSheet } from 'aphrodite/no-important';
import { fonts, spacers, typography } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    marginRight: spacers.dp_s24,
    paddingBottom: spacers.dp_s8,
  },
  lineAbove: {
    borderTop: 'dashed 1px #e0e0e0',
    paddingTop: spacers.dp_s8,
  },
  largeCol: {
    flex: 2,
  },
  extraLargeCol: {
    flex: 3,
  },
  col: {
    flex: 1,
    paddingLeft: spacers.dp_s16,
    wordWrap: 'break-word',
  },
  header: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    textTransform: 'uppercase',
    color: colors.gray.alpha30,
    textAlign: 'left',
    marginBottom: spacers.dp_s4,
    fontWeight: fonts.dp_font_weight_semibold,
  },
  tripIcon: {
    marginRight: spacers.dp_s12,
  },
  intendedContent: {
    marginLeft: spacers.dp_s32,
  },
  location: {
    color: colors.black,
    fontSize: typography.dp_body_font_size,
    lineHeight: typography.dp_body_line_height,
    letterSpacing: typography.dp_body_letter_spacing,
    fontWeight: fonts.dp_font_weight_bold,
  },
  line: {
    display: 'block',
  },
  route: {
    color: colors.gray.alpha54,
    fontWeight: fonts.dp_font_weight_regular,
  },
  ticketDetail: {
    marginBottom: spacers.dp_s24,
    ':last-child': {
      marginBottom: 0,
    },
  },
  farePassengers: {
    letterSpacing: typography.dp_small_letter_spacing,
    fontWeight: fonts.dp_font_weight_bold,
    display: 'block',
    fontSize: typography.dp_small_font_size_m,
    lineHeight: typography.dp_small_line_height,
  },
  fareConditionSummary: {
    color: colors.gray.alpha54,
    paddingTop: spacers.dp_s8,
    paddingRight: spacers.dp_s16,
  },
  fareCondition: {
    paddingBottom: spacers.dp_s16,
    textAlign: 'justify',
  },
  fulfilmentMethod: {
    color: '#1ed760',
  },
});
