import { defineMessages } from 'react-intl';

export default defineMessages({
  title_failure: {
    id: 'OrderStatus.title_failure',
    defaultMessage: 'Order failed',
  },
  title_partialfailure: {
    id: 'OrderStatus.title_partialfailure',
    defaultMessage: 'Partial order failure',
  },
  title_booking: {
    id: 'OrderStatus.title_booking',
    defaultMessage: 'booking/product',
  },
  title_fulfilment: {
    id: 'OrderStatus.title_fulfilment',
    defaultMessage: 'fulfilment',
  },
  title_fulfilment_tip: {
    id: 'OrderStatus.title_filfilment_tip',
    defaultMessage:
      'This is the status of whether the booking was created and tickets were issued successfully',
  },
  title_vendor: {
    id: 'OrderStatus.title_vendor',
    defaultMessage: 'vendor',
  },
  title_vendor_tip: {
    id: 'OrderStatus.title_vendor_tip',
    defaultMessage: ' This is the current status of the booking in the vendor system',
  },
  title_reimbursed: {
    id: 'OrderStatus.title_reimbursed',
    defaultMessage: 'auto refund',
  },
  title_carrier_or_connection: {
    id: 'OrderStatus.title_carrier_or_connection',
    defaultMessage: 'Carrier/Connection',
  },
  railcard: {
    id: 'OrderStatus.railcard',
    defaultMessage: '{name}',
  },
  notApplicable: {
    id: 'OrderStatus.notAppplicable',
    defaultMessage: '-',
  },
  yes: {
    id: 'OrderStatus.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'OrderStatus.no',
    defaultMessage: 'No',
  },
  recommendations: {
    id: 'OrderStatus.recommendations',
    defaultMessage: 'Recommendations:',
  },
  reimbursement_notice: {
    id: 'OrderStatus.reimbursement_notice',
    defaultMessage:
      'A refund has automatically been issued for {reimbursementAmount} {reimbursementCurrencyCode} {fees} for {bookings} {insurances}. Confirm this with the customer. No further action needed.',
  },
  insuranceProductDetails: {
    id: 'OrderStatus.insuranceProductDetails',
    defaultMessage:
      '{hasBookings, select, false {} true {and\u0020for}} {single, select, false {{first, select, false {{last, select, true {\u0020and} false {,}}} true {}}} true {}} {description}',
  },
  reimbursementFailNoticeForUK: {
    id: 'OrderStatus.reimbursementFailNoticeForUK',
    defaultMessage:
      'The customer should have been refunded for {bookings} {insurances} via {paymentServiceProvider}. Please verify the refund has been processed. If no refund has been issued by the payment system within 5 working days, please process a compensation plus booking fees using reason code Outside T&Cs - Outage - Fulfilment Issue.',
  },
  reimbursementFailNoticeForEU: {
    id: 'OrderStatus.reimbursementFailNoticeForEU',
    defaultMessage:
      'The customer should have been refunded for {bookings} {insurances} via {paymentServiceProvider}. Please verify the refund has been processed. If no refund has been issued, please process a compensation plus booking fees using EU – Failed Booking.',
  },
  reimbursementFailNoticeForEUAndUK: {
    id: 'OrderStatus.reimbursementFailNoticeForEUAndUK',
    defaultMessage:
      'The customer should have been refunded for {bookings} {insurances} via {paymentServiceProvider}. Please verify the refund has been processed. If no refund has been issued, please process a compensation plus booking fees using "EU – Failed Booking" for EU products and "Outside T&Cs - Outage - Fulfilment Issue" for UK products.',
  },
  reimbursementFailNoticeForSeason: {
    id: 'OrderStatus.reimbursementFailNoticeForSeason',
    defaultMessage:
      'The customer should have been refunded for {bookings} via {paymentServiceProvider}. Please verify the refund has been processed. If no refund has been issued by the payment system within 5 working days, please process a compensation plus booking fees using reason code Season - Fulfilment Failure.',
  },
  reimbursement_fail_notice_external_payment: {
    id: 'OrderStatus.reimbursement_fail_notice_external_payment',
    defaultMessage:
      'There may have been an issue when attempting payment for {bookings}. Check on the carriers website if the ticket has been issued or not. If the ticket has been issued, please follow the process regarding external payment timeouts. If the ticket has not been issued, no payment has been taken.',
  },
  reimbursement_notice_fees: {
    id: 'OrderStatus.reimbursement_notice_fees',
    defaultMessage: '(including {amount} {currencyCode} fees)',
  },
  fraud: {
    id: 'OrderStatus.fraud',
    defaultMessage:
      'Payment has not been authorised. Reference code DM3 - if customer contacts please escalate to the F & P – Suspect Activity Group',
  },
});
