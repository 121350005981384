import React, { FunctionComponent } from 'react';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';

import styles from './styles';

interface Props {
  styleSheet?: StyleDeclarationValue;
}

const PageContainer: FunctionComponent<Props> = ({ styleSheet, children }) => (
  <div className={css(styles.container, styleSheet)}>{children}</div>
);

export default PageContainer;
