import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  backButton: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacers.dp_s24,

    ':nth-child(1n) > svg': {
      transform: 'scaleX(-1)',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
