import { defineMessages } from 'react-intl';

export default defineMessages({
  reasonCode: {
    id: 'CompensationApprovalsDetails.reasonCode',
    defaultMessage: 'reason code',
  },
  edit: {
    id: 'CompensationApprovalsDetails.edit',
    defaultMessage: 'edit',
  },
  approve: {
    id: 'CompensationApprovalsDetails.approve',
    defaultMessage: 'approve',
  },
  reject: {
    id: 'CompensationApprovalsDetails.reject',
    defaultMessage: 'reject',
  },
  reasonCodeArchived: {
    id: 'CompensationApprovalsDetails.reasonCodeArchived',
    defaultMessage:
      'Important information: This reason code has been archived. You can still edit, approve, or reject this compensation.',
  },
});
