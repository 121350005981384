import { StyleSheet } from 'aphrodite/no-important';
import { fonts, spacers, typography } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  passengerContainer: {
    marginTop: spacers.dp_s12,
  },
  passengerContent: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
  },
  passengerHeader: {
    fontFamily: typography.dp_title2_font_family,
    fontSize: typography.dp_title2_font_size_m,
    fontWeight: fonts.dp_font_weight_bold,
    lineHeight: typography.dp_title2_line_height_m,
    letterSpacing: '-0.3px', //TODO: replace with depot-web typography when updated in depot-web
    paddingTop: spacers.dp_s12,
  },
  backLink: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  arrowLeft: {
    marginRight: spacers.dp_s4,
  },
});
