import { StyleSheet } from 'aphrodite/no-important';
import { fonts, borderRadius, spacers, palettes } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  select: {
    width: '528px',
    margin: `${spacers.dp_s16} 0`,
  },
  value: {
    margin: `0 0 ${spacers.dp_s4} 0`,
  },
  error: {
    color: 'red',
    display: 'block',
  },
  reasonContent: {
    display: 'inline-block',
    overflow: 'hidden',
    marginRight: spacers.dp_s4,
  },
  reason: {
    cursor: 'text',
  },
  inactive: {
    cursor: 'default',
  },
  changeableReasons: {
    borderRadius: borderRadius.dp_r4,
    border: `solid 1px ${palettes.dp_app_border_dark}`,
    padding: `${spacers.dp_s8} ${spacers.dp_s16}`,
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  path: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  pathItem: {
    display: 'inline',
    fontWeight: fonts.dp_font_weight_bold,
    whiteSpace: 'nowrap',
    ':not(:first-child)': {
      ':before': {
        content: "'/'",
        padding: `0 ${spacers.dp_s4}`,
      },
    },
  },
});
