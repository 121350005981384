import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '@contactcentre-web/common/Button';
import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from '@contactcentre-web/common/Modal';

import messages from './messages';

const RefundModal = ({
  children,
  header,
  onCloseClick,
  onProceedClick,
  onCancelClick,
  processButtonTestId,
  disabledButton,
  loading,
}) => (
  <Modal onClose={onCloseClick}>
    <ModalHeader>
      <ModalTitle>{header}</ModalTitle>
    </ModalHeader>
    <ModalBody>{children}</ModalBody>
    <ModalFooter contentAlignment="spaceBetween">
      <Button variant="tertiary" size="large" onClick={onCancelClick}>
        <FormattedMessage {...messages.cancelButtonText} />
      </Button>
      <Button
        variant="primary"
        size="large"
        testId={processButtonTestId}
        disabled={disabledButton || loading}
        loading={loading}
        onClick={onProceedClick}
      >
        <FormattedMessage {...messages.processButtonText} />
      </Button>
    </ModalFooter>
  </Modal>
);

RefundModal.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onCloseClick: PropTypes.func,
  onProceedClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  processButtonTestId: PropTypes.string,
  disabledButton: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
};

export default RefundModal;
