import React, { Dispatch, FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { css } from 'aphrodite/no-important';

import Button from '@contactcentre-web/common/Button';
import type State from '@contactcentre-web/redux-common/types/State';
import Action from '@contactcentre-web/redux-common/types/Action';

import { actions, selectors } from '../../module';

import messages from './messages';
import styles from './styles';

interface Props {
  redirectToBookingFlow: () => void;
  hasRedirectedSuccessfully?: boolean;
}

export const AgentBookingButton: FunctionComponent<Props> = ({
  redirectToBookingFlow,
  hasRedirectedSuccessfully,
}) => {
  const [isBookNowActive, setIsBookNowActive] = useState(false);

  React.useEffect(() => {
    if (hasRedirectedSuccessfully && isBookNowActive) {
      window.location.assign('/agent-booking/redirect');
    }
  }, [hasRedirectedSuccessfully]);

  const handleClick = () => {
    setIsBookNowActive(true);
    redirectToBookingFlow();
  };

  return (
    <div className={css(styles.container)} data-test-id="go-to-agent-booking">
      <Button variant="primary" size="small" onClick={handleClick} styleSheet={styles.button}>
        <FormattedMessage {...messages.bookNow} />
      </Button>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  hasRedirectedSuccessfully: selectors.redirectToBookingFlowSuccess(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  redirectToBookingFlow: () => dispatch(actions.redirectToBookingFlowAttempt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgentBookingButton);
