import { useMutation } from 'react-query';

import request from '@contactcentre-web/utils/request';

export interface UploadError {
  code: string;
  detail: string;
  meta: {
    innerErrors: Array<UploadError>;
  };
}

export interface UploadErrorResponse {
  status: number;
  errors:
    | Array<UploadError>
    | {
        errors?: Array<UploadError>;
      };
}

export const useBulkUpload = () =>
  useMutation<unknown, UploadErrorResponse, FormData, unknown>((variables: FormData) =>
    request(
      `/api/bulkupload`,
      {
        method: 'POST',
        body: variables,
      },
      false,
      true
    )
  );
