import React, { FunctionComponent, useState } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { IconSingle } from '@trainline/depot-web';
import { useDispatch, useSelector } from 'react-redux';
import { StatusMessage } from '@trainline/depot-web';

import OriginToDestination from '@contactcentre-web/common/OriginToDestination/OriginToDestination';
import StatusIndicator from '@contactcentre-web/common/StatusIndicator';
import Loader from '@contactcentre-web/common/Loader';
import { actionBuilder } from '@contactcentre-web/detaxe/module';
import { Reason } from '@contactcentre-web/detaxe/sagas';
import {
  getDetaxeRefundStatus,
  getLoadingDetaxeRefundStatus,
} from '@contactcentre-web/detaxe/selectors';
import DocumentDownload from '@contactcentre-web/common/DocumentDownload';

import styles from './styles';
import messages from './messages';
import UpdateRefundStatusForm, { FormData } from './components/UpdateRefundStatusForm';

interface Params {
  refundId: string;
  orderId: string;
}

export type StatusTypes =
  | 'initiated'
  | 'submitted'
  | 'appealed'
  | 'accepted'
  | 'approved'
  | 'rejected'
  | 'default';

interface RefundStatus {
  destinationStation: string;
  originStation: string;
  status: StatusTypes;
  claimAmount: string;
  transitionsTo: Array<string>;
  contactReasons: Array<Reason>;
  contactReasonId: Reason | undefined;
}
const UpdateRefundStatus: FunctionComponent = () => {
  const { refundId, orderId } = useParams<Params>();
  const refundStatus: RefundStatus = useSelector((state) => getDetaxeRefundStatus(state));
  const isLoading: boolean = useSelector((state) => getLoadingDetaxeRefundStatus(state));

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(actionBuilder.getRefundAttempt({ refundId: refundId }));
  }, []);

  const onSubmitForm = (data: FormData) => {
    dispatch(actionBuilder.setRefundStatusAttempt(refundId, orderId, data));
  };

  const {
    status = 'default',
    claimAmount,
    destinationStation,
    originStation,
    transitionsTo,
    contactReasons,
    contactReasonId,
  } = refundStatus;

  const statusIndicatorStyles = {
    initiated: styles.statusIndicatorInitiated,
    submitted: styles.statusIndicatorSubmitted,
    appealed: styles.statusIndicatorAppealed,
    accepted: styles.statusIndicatorAccepted,
    approved: styles.statusIndicatorApproved,
    rejected: styles.statusIndicatorRejected,
    default: styles.statusIndicatorDefault,
  };

  const [pdfDownloadError, setPdfDownloadError] = useState(false);

  const sncfDetaxePdfErrorHandler = () => {
    setPdfDownloadError(true);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className={css(styles.page)}>
      <div className={css(styles.formWrapper)}>
        {pdfDownloadError && (
          <div className={css(styles.pdfErrorNotificationContainer)}>
            <StatusMessage status="negative" className={css(styles.pdfDownloadErrorMessage)}>
              <FormattedMessage {...messages.pdfDownloadErrorMessage} />
            </StatusMessage>
          </div>
        )}
        <h1 className={css(styles.title)}>
          <FormattedMessage {...messages.detaxeHeader} />
        </h1>
        <span className={css(styles.originDestination)}>
          <OriginToDestination origin={originStation} destination={destinationStation} />
        </span>
        <section className={css(styles.status)}>
          <span className={css(styles.statusMessage)}>
            <FormattedMessage {...messages.currentStatus} />:{' '}
          </span>
          <StatusIndicator status={status} indicatorStyles={{ ...statusIndicatorStyles[status] }} />
          <span className={css(styles.downloadLink)}>
            <DocumentDownload
              link={`/ExceptionalRefunds/${refundId}/pdf`}
              withRequest
              fileName={`Dossier Voyage - ${orderId}.pdf`}
              format="link"
              errorHandler={sncfDetaxePdfErrorHandler}
            >
              <FormattedMessage {...messages.downloadDossierVoyage} />
              <IconSingle className={css(styles.downloadIcon)} />
            </DocumentDownload>
          </span>
        </section>
        <section>
          <UpdateRefundStatusForm
            onSubmitForm={onSubmitForm}
            transitionsTo={transitionsTo}
            currentStatus={status}
            initialValues={{
              claimAmount,
            }}
            contactReasons={contactReasons}
            contactReasonId={contactReasonId}
          />
        </section>
      </div>
    </div>
  );
};

export default UpdateRefundStatus;
