import { Validity } from '@contactcentre-web/redux-common/types/Validity';
import { Journey, TravelTicket } from '@contactcentre-web/redux-common/types/Journey';
import LocalAreaProduct from '@contactcentre-web/redux-common/types/LocalAreaProduct';

import {
  Quote,
  LocalAreaTicket,
  PassengerRefundable,
  DiscretionaryRefundableTicket,
} from '../types';
import { getFarePassengers, passengersExtractor } from '../utils';

import { ticketRefundabilityMapper } from './ticket-mapper';

export const journeysWithRefundability = (
  journeys: (Journey & { validity?: Validity })[],
  passengerRefundables: PassengerRefundable[],
  quote: Quote,
  isReturn: boolean
) => {
  let canProcess = false;

  const refundJourneys = journeys
    .map((journey) => {
      const { origin, destination, departAt, arriveAt, validity } = journey;
      const passengers = passengersExtractor(journey);

      const { farePassengers: mappedFps, canProcess: canProcessRefundable } = getFarePassengers<
        TravelTicket,
        DiscretionaryRefundableTicket
      >(passengers, passengerRefundables, ticketRefundabilityMapper, quote);

      canProcess = canProcess || canProcessRefundable;

      return {
        origin,
        destination,
        departAt,
        arriveAt,
        isReturn,
        validity,
        farePassengers: mappedFps,
      };
    })
    .filter(Boolean);

  return {
    journeys: refundJourneys,
    canProcess,
  };
};
export const localAreasWithRefundibility = (
  localAreaProducts: LocalAreaProduct[],
  passengerRefundables: PassengerRefundable[],
  quote: Quote
) => {
  const journeys = localAreaProducts.map((localAreaProduct) => {
    const passengers = localAreaProduct.passengers.map((passenger) => ({
      ...passenger,
      railCards: passenger.railcards || [],
      tickets: passenger.tickets.map((ticket) => ({
        ...ticket,
        localAreaValidity: localAreaProduct.localAreaValidity,
        farePassengerId: passenger.farePassengerId,
        passengerType: passenger.type,
        customerPrice: ticket.price,
      })),
    }));

    const { farePassengers, canProcess } = getFarePassengers<
      LocalAreaTicket,
      DiscretionaryRefundableTicket
    >(passengers, passengerRefundables, ticketRefundabilityMapper, quote);

    return {
      ...localAreaProduct,
      farePassengers,
      canProcess,
    };
  });

  const canProcess = journeys.every((journey) => journey.canProcess);

  return {
    journeys,
    canProcess,
  };
};
