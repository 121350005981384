import { StyleSheet } from 'aphrodite/no-important';
import { palettes, typography, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  link: {
    fontSize: typography.dp_small_font_size_m,
    lineHeight: typography.dp_small_line_height,
    color: palettes.dp_app_text_base,
    width: '100%',
    padding: `${spacers.dp_s8} ${spacers.dp_s24} ${spacers.dp_s8} ${spacers.dp_s16}`,
  },
});
