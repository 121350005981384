import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { StatusMessage, Heading } from '@trainline/depot-web';

import InfoBox from '@contactcentre-web/common/InfoBox';
import Button from '@contactcentre-web/common/Button';
import { NewAgentData } from '@contactcentre-web/hooks/api/useCreateNewAgent';

import messages from './messages';
import styles from './styles';

interface Props extends NewAgentData {
  reset: () => void;
}

const NewAgentConfirmation = ({ firstName, lastName, username, password, reset }: Props) => (
  <>
    <div data-testid="agent-created-success-message">
      <StatusMessage status="positive">
        <FormattedMessage {...messages.successMessage} />
      </StatusMessage>
    </div>
    <div className={css(styles.title)}>
      <Heading typeStyle="title1" as="h1" color="base" testId="newAgentName">
        {firstName} {lastName}
      </Heading>
    </div>
    <div className={css(styles.innerContainer)}>
      <div className={css(styles.loginDetails)}>
        <div className={css(styles.label)}>
          <FormattedMessage {...messages.username} />
        </div>
        <div className={css(styles.value)} data-testid="new-agent-username">
          {username}
        </div>
        <div className={css(styles.label)}>
          <FormattedMessage {...messages.temporaryPassword} />
        </div>
        <div className={css(styles.value)}>{password}</div>
        <Button variant="primary" size="large" styleSheet={styles.button} onClick={reset}>
          <FormattedMessage {...messages.addAnotherAgent} />
        </Button>
      </div>
      <InfoBox styleSheet={styles.infoBox} message={<FormattedMessage {...messages.info} />} />
    </div>
  </>
);

export default NewAgentConfirmation;
