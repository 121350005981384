import { StyleSheet } from 'aphrodite/no-important';
import { palettes, spacers, typography } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  journeyContainer: {
    marginBottom: spacers.dp_s16,
  },
  form: {
    padding: `0  0 ${spacers.dp_s16} 0`,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    margin: `${spacers.dp_s16} 0`,
  },
  notification: {
    margin: `${spacers.dp_s16} 0`,
  },
  farePassengers: {
    margin: `${spacers.dp_s16} 0`,
  },
  paymentContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  recalculate: {
    margin: `${spacers.dp_s16} ${spacers.dp_s32} 0 0`,
  },
  emptyAlert: {
    marginTop: spacers.dp_s16,
  },
  alert: {
    whiteSpace: 'pre-line',
  },
  reasonContent: {
    width: '40%',
  },
  totalsAndFees: {
    margin: `${spacers.dp_s32} 0`,
  },
  lastUsedDateContainer: {
    width: '20%',
    paddingTop: spacers.dp_s24,
  },
  refundErrorMessage: {
    marginBottom: spacers.dp_s16,
  },
  selectedBox: {
    boxShadow: `0 0 0 2px ${palettes.dp_brand_primary_core}`,
  },
  bookingTitle: {
    color: palettes.dp_app_text_base,
    fontSize: typography.dp_title1_font_size,
    marginTop: spacers.dp_s32,
    marginBottom: spacers.dp_s32,
  },
});
