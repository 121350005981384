import { defineMessages } from 'react-intl';

export default defineMessages({
  selectQueue: {
    id: 'OrderNotes.CallCrmQueue.selectQueue',
    defaultMessage: 'Select a Call Queue',
  },
  queueLabel: {
    id: 'OrderNotes.CallCrmQueue.queueLabel',
    defaultMessage: 'Call Queue',
  },
  callCrmQueueRequired: {
    id: 'OrderNotes.CallCrmQueue.callCrmQueueRequired',
    defaultMessage: 'Please provide a call queue',
  },
});
