import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';

import styles from './styles';
import messages from './messages';

const ExchangeFailed = () => (
  <div className={css(styles.container)}>
    <div className={css([styles.notice, styles.failed])}>
      <h4>
        <FormattedMessage {...messages.titleFailed} />
      </h4>
    </div>
  </div>
);

export default ExchangeFailed;
