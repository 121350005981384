import { ReactElement, ReactNode } from 'react';

export interface Props {
  default?: boolean;
  title: ReactElement | string;
  children: ReactNode;
  testId?: string;
  disabled?: boolean;
  path?: string;
  exact?: boolean;
}

const Tab = ({}: Props) => null;

export default Tab;
