import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import StackedLabel from '@contactcentre-web/common/StackedLabel';
import FormattedBookings from '@contactcentre-web/common/FormattedBookings';
import FormattedInsuranceProducts from '@contactcentre-web/common/FormattedInsuranceProducts';
import priceShape from '@contactcentre-web/utils/shape/price';

import styles from './styles';
import messages from './messages';
import ExternalPaymentLabel from './ExternalPaymentLabel';

const ReimbursedSuccessLabel = ({
  bookingsStatusDetails,
  reimbursedBookings,
  reimbursedInsurances,
  externalPaymentBookings,
  allFailsAreExternalPaymentBookings,
  allFailsAreExternalPaymentInsurances,
}) => (
  <StackedLabel
    orientation="horizontal"
    label={<FormattedMessage {...messages.recommendations} />}
    styleSheet={{
      container: styles.recommendations,
      label: styles.label_recommendations,
      value: styles.value_recommendations,
    }}
  >
    {((!allFailsAreExternalPaymentBookings && reimbursedBookings.length > 0) ||
      (!allFailsAreExternalPaymentInsurances && reimbursedInsurances.length > 0)) && (
      <FormattedMessage
        {...messages.reimbursement_notice}
        values={{
          reimbursementAmount:
            bookingsStatusDetails.reimbursementValue &&
            bookingsStatusDetails.reimbursementValue.amount,
          reimbursementCurrencyCode:
            bookingsStatusDetails.reimbursementValue &&
            bookingsStatusDetails.reimbursementValue.currencyCode,
          fees: bookingsStatusDetails.feesValue && (
            <FormattedMessage
              {...messages.reimbursement_notice_fees}
              values={bookingsStatusDetails.feesValue}
            />
          ),
          bookings: <FormattedBookings bookings={reimbursedBookings} />,
          insurances: (
            <FormattedInsuranceProducts
              insurances={reimbursedInsurances}
              messages={messages}
              hasBookings={!!reimbursedBookings.length}
            />
          ),
        }}
      />
    )}
    {externalPaymentBookings.length > 0 && (
      <ExternalPaymentLabel
        externalPaymentBookings={externalPaymentBookings}
        allFailsAreExternalPaymentBookings={allFailsAreExternalPaymentBookings}
      />
    )}
  </StackedLabel>
);

ReimbursedSuccessLabel.propTypes = {
  bookingsStatusDetails: PropTypes.shape({
    reimbursementValue: priceShape.isRequired,
    feesValue: priceShape,
  }).isRequired,
  reimbursedBookings: PropTypes.arrayOf(PropTypes.object).isRequired,
  externalPaymentBookings: PropTypes.arrayOf(PropTypes.object).isRequired,
  allFailsAreExternalPaymentBookings: PropTypes.bool.isRequired,
  reimbursedInsurances: PropTypes.arrayOf(PropTypes.object),
  allFailsAreExternalPaymentInsurances: PropTypes.bool.isRequired,
};

export default ReimbursedSuccessLabel;
