import { css } from 'aphrodite/no-important';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Heading } from '@trainline/depot-web';

import type State from '@contactcentre-web/redux-common/types/State';
import Link from '@contactcentre-web/common/Link';

import { selectors } from '../../module';

import messages from './messages';
import styles from './styles';

interface StateProps {
  historyUrl: string | null;
  capitaineUrl: string | null;
}

export const RefreshPnrFailMulti = ({ historyUrl, capitaineUrl }: StateProps) => (
  <div className={css(styles.container)}>
    <Heading as="h1" typeStyle="title3" color="base">
      <FormattedMessage {...messages.bookingNotRefreshed} />
    </Heading>
    <div className={css(styles.body)}>
      <p>
        <FormattedMessage {...messages.info} />
      </p>
      <br />
      {historyUrl && capitaineUrl && (
        <p>
          <FormattedMessage
            {...messages.links}
            values={{
              link: (msg: string) => (
                <Link linkType="internal" to={historyUrl}>
                  {msg}
                </Link>
              ),
              a: (msg: string) => (
                <Link linkType="external" href={capitaineUrl}>
                  {msg}
                </Link>
              ),
            }}
          />
        </p>
      )}
    </div>
  </div>
);

const mapStateToProps = (state: State) => ({
  historyUrl: selectors.getHistoryUrl(state),
  capitaineUrl: selectors.getCapitaineUrl(state),
});

const RefreshPnrFailMultiContainer = connect(mapStateToProps)(RefreshPnrFailMulti);
RefreshPnrFailMultiContainer.displayName = 'RefreshPnrFailMulti';

export default RefreshPnrFailMultiContainer;
