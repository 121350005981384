import { defineMessages } from 'react-intl';

export default defineMessages({
  documents: {
    id: 'SupersededBooking.TicketAssets.documents',
    defaultMessage: 'documents',
  },
  type: {
    id: 'SupersededBooking.TicketAssets.type',
    defaultMessage: 'type',
  },
  document: {
    id: 'SupersededBooking.TicketAssets.document',
    defaultMessage: 'document-{idx}',
  },
  pdf: {
    id: 'SupersededBooking.TicketAssets.pdf',
    defaultMessage: 'pdf',
  },
});
