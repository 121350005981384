import React, { useState } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { IconChevronLeft, IconChevronRight, Paragraph } from '@trainline/depot-web';

import Button from '@contactcentre-web/common/Button';

import messages from './messages';
import styles from './styles';

interface Props {
  validCombinations: string[][];
  friendlyTicketIndexMapping?: Record<string, string>;
  setIsOpen: (isOpen: boolean) => void;
  selectValidCombinations: (refundables: string[]) => void;
}

const ValidRefundCombinationsCarousel = ({
  validCombinations = [],
  setIsOpen,
  friendlyTicketIndexMapping = {},
  selectValidCombinations,
}: Props) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalValidCombinations = validCombinations.length;

  const nextSlide = () => setCurrentSlide((currentSlide) => currentSlide + 1);

  const previousSlide = () => setCurrentSlide((currentSlide) => currentSlide - 1);

  const slideDisplayValues = (slide: number) =>
    validCombinations[slide]
      .map((ticketId) => friendlyTicketIndexMapping[ticketId])
      .sort()
      .join(' + ');

  return (
    <div className={css(styles.carousel)}>
      <div className={css(styles.carouselSlides)}>
        <div className={css(styles.carouselSlide)}>
          <Button
            styleSheet={styles.carouselNavigationButtonPrevious}
            onClick={previousSlide}
            variant="ghost"
            disabled={currentSlide === 0}
            testId="previous-slide"
          >
            <IconChevronLeft />
          </Button>
          <div className={css(styles.carouselSlideCombination)}>
            {totalValidCombinations === 0 ? (
              <FormattedMessage {...messages.noValidCombinations} />
            ) : (
              <>
                <Paragraph typeStyle="body" as="p" fontWeight="bold" color="base">
                  <FormattedMessage
                    {...messages.combination}
                    values={{ combinationIndex: String.fromCharCode(currentSlide + 65) }} // convert index to letter A-Z upper case
                  />
                </Paragraph>
                <Paragraph typeStyle="small" as="p" color="base" fontWeight="regular">
                  <FormattedMessage
                    {...messages.ticketIDs}
                    values={{ ticketIDs: slideDisplayValues(currentSlide) }}
                  />
                </Paragraph>
              </>
            )}
          </div>
          <Button
            styleSheet={styles.carouselNavigationButtonNext}
            onClick={nextSlide}
            variant="ghost"
            disabled={currentSlide === totalValidCombinations - 1 || totalValidCombinations === 0}
            testId="next-slide"
          >
            <IconChevronRight />
          </Button>
        </div>
        <Button
          styleSheet={styles.buttonSelect}
          variant="secondary"
          fullWidth
          size="small"
          disabled={totalValidCombinations === 0}
          onClick={() => {
            setIsOpen(false);
            selectValidCombinations(validCombinations[currentSlide]);
          }}
        >
          <FormattedMessage {...messages.select} />
        </Button>
      </div>
      <div className={css(styles.carouselPagination)}>
        <Paragraph typeStyle="mini" as="p" color="subdued" fontWeight="regular">
          <FormattedMessage
            {...messages.pagination}
            values={{
              currentSlide: totalValidCombinations === 0 ? 0 : currentSlide + 1,
              totalSlides: totalValidCombinations,
            }}
          />
        </Paragraph>
      </div>
    </div>
  );
};

export default ValidRefundCombinationsCarousel;
