import { defineMessages } from 'react-intl';

export default defineMessages({
  passenger: {
    id: 'SupersededBooking.Passenger.passenger',
    defaultMessage: 'Passenger {passengerNum}',
  },

  unreservedSeating: {
    id: 'SupersededBooking.Passenger.unreservedSeating',
    defaultMessage: 'Unreserved seating',
  },

  railcardLabel: {
    id: 'SupersededBooking.Passenger.railcardLabel',
    defaultMessage: 'Railcard: {value}',
  },

  railcard: {
    id: 'SupersededBooking.Passenger.railcard',
    defaultMessage: '{value}',
  },

  ticketType: {
    id: 'SupersededBooking.Passenger.ticketType',
    defaultMessage: 'Ticket type: {value}',
  },

  deliveryMethod: {
    id: 'SupersededBooking.Passenger.deliveryMethod',
    defaultMessage: 'Delivery method: {value}',
  },

  ticketClass: {
    id: 'SupersededBooking.Passenger.ticketClass',
    defaultMessage: 'Class: {value}',
  },

  deliveryAddress: {
    id: 'SupersededBooking.Passenger.deliveryAddress',
    defaultMessage: 'Delivery address: {value}',
  },

  notAvailable: {
    id: 'SupersededBooking.Passenger.notAvailable',
    defaultMessage: 'N/A',
  },

  seatingPreferencesLabel: {
    id: 'SupersededBooking.Passenger.seatingPreferencesLabel',
    defaultMessage: 'Seat Preferences: {value}',
  },

  seatingPreferences: {
    id: 'SupersededBooking.Passenger.seatingPreferences',
    defaultMessage: '{value}',
  },

  dateOfBirth: {
    id: 'SupersededBooking.Passenger.dateOfBirth',
    defaultMessage: 'DoB: {value}',
  },
});
