import React, { FunctionComponent } from 'react';
import { Route, Redirect } from 'react-router-dom';

type Props = {
  path: string;
  hasPermission?: boolean;
  component: FunctionComponent;
  key?: string;
};

const PermissionBasedRoute = ({ hasPermission, path, component, key }: Props) => (
  <Route
    path={path}
    key={key}
    render={() => {
      if (hasPermission) {
        return React.createElement(component);
      } else if (hasPermission === false) {
        return <Redirect to="/login" />;
      }
      return <div />; // hasPermission might not be loaded yet
    }}
  />
);

export default PermissionBasedRoute;
