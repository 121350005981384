import { defineMessages } from 'react-intl';

export default defineMessages({
  savedCardNumber: {
    id: 'CustomerPassengersSavedCard.savedCardNumber',
    defaultMessage: 'Card no: {number}',
  },
  savedCardStartDate: {
    id: 'CustomerPassengersSavedCard.savedCardStartDate',
    defaultMessage: 'Start date: {validityStart}',
  },
  savedCardExpiryDate: {
    id: 'CustomerPassengersSavedCard.savedCardExpiryDate',
    defaultMessage: 'Expiry date: {validityEnd}',
  },
});
