import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';

import { PurchasedCard } from '@contactcentre-web/hooks/api/useCustomerPassengers';
import Link from '@contactcentre-web/common/Link';

import messages from './messages';
import styles from './styles';

interface Props {
  cards?: Array<PurchasedCard>;
}

const Railcards = ({ cards }: Props) => (
  <section className={css(styles.cardsContent)}>
    <h3 className={css(styles.railcardsTitle)}>
      <FormattedMessage {...messages.railcardsHeader} />
    </h3>
    <ul data-test="rail-cards-list">
      {cards && cards.length > 0 ? (
        cards.map(({ name, railcardNumber, customerId, orderReference }, key) => (
          <li key={key} className={css(styles.cardsList)} data-testid="railCardLink">
            <Link linkType="internal" to={`/customers/${customerId}/bookings/${orderReference}`}>
              {`${name}:  ${railcardNumber}`}
            </Link>
          </li>
        ))
      ) : (
        <li className={css(styles.cardsList)}>
          <FormattedMessage {...messages.railcardsNotAvailable} />
        </li>
      )}
    </ul>
    {cards && cards.length > 0 && (
      <p className={css(styles.railcardsCaption)}>
        <FormattedMessage {...messages.railcardsFooter} />
      </p>
    )}
  </section>
);

export default Railcards;
