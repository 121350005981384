import { useMutation } from 'react-query';

import request from '@contactcentre-web/utils/request';

interface CallResponse {
  id: number;
  status: string;
  recordingEnabled: boolean;
}

export const useToggleRecording = () =>
  useMutation((recordingEnabled: boolean) =>
    request<CallResponse>('agent-call-v2/recording', {
      method: 'POST',
      body: JSON.stringify({ recordingEnabled }),
    })
  );
