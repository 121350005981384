import { defineMessages } from 'react-intl';

export default defineMessages({
  exchangedOrder: {
    id: 'ExchangedBookingAlert.exchangedOrder',
    defaultMessage: 'This booking has been changed. You can view the updated booking {link}.',
  },
  exchanged: {
    id: 'ExchangedBookingAlert.exchanged',
    defaultMessage: 'here',
  },
});
