import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmationEmailNotFound: {
    id: 'ResendEmailEligibilityFailure.confirmationEmailNotFound',
    defaultMessage: 'Confirmation email not found',
  },
  message: {
    id: 'ResendEmailEligibilityFailure.message',
    defaultMessage:
      'Unfortunately we could not locate a copy of the original booking confirmation email.',
  },
});
