import { defineMessages } from 'react-intl';

export default defineMessages({
  selectReason: {
    id: 'RefundReason.selectPrimaryReason',
    defaultMessage: 'Reason code',
  },
  reason: {
    id: 'RefundReason.reason',
    defaultMessage: '{label}',
  },
  validation_required: {
    id: 'RefundReason.validation_required',
    defaultMessage: 'You need to provide a reason code.',
  },
  validation_unknown: {
    id: 'RefundReason.validation_unknown',
    defaultMessage: 'An unspecified validation error occurred.',
  },
  noReason: {
    id: 'RefundReason.noReason',
    defaultMessage: 'None selected',
  },
  additionalInfo: {
    id: 'RefundReason.additionalInfo',
    defaultMessage: 'Additional information',
  },
  applyReasonsToAll: {
    id: 'RefundableUnit.applyReasonsToAll',
    defaultMessage: 'Apply reasons code to all',
  },
});
