import { createSelector } from 'reselect';
import {
  compose,
  flatten,
  pluck,
  uniq,
  any,
  symmetricDifferenceWith,
  eqBy,
  prop,
  filter,
} from 'ramda';
import moment from 'moment';

export const selectOrder = (state) => state.orders.order;

export const selectProducts = (state) => state.orders.products;

export const hasLockableBookings = ({ travelBookings }) =>
  travelBookings
    ? travelBookings.some(
        ({ vendorRegion, manuallyLocked }) => vendorRegion === 'eu' && !manuallyLocked
      )
    : false;

export const hasEuBookings = ({ travelBookings }, products) =>
  travelBookings?.some(({ vendorRegion }) => vendorRegion && vendorRegion.toLowerCase() === 'eu') ||
  products?.some(({ vendorRegion }) => vendorRegion && vendorRegion.toLowerCase() === 'eu');

export const mapCurrencyData = ({ travelBookings, conversionContext }, products) => {
  const ratesBatchId = conversionContext ? conversionContext.ratesBatchId : null;
  const marginsBatchId = conversionContext ? conversionContext.marginsBatchId : null;

  let transactionCurrency;
  let inventoryCurrencies;

  if (products?.length > 0) {
    transactionCurrency =
      products && products.length ? products[0].inventoryPrice?.currencyCode : null;

    inventoryCurrencies = uniq(
      (products || []).reduce(
        (results, product) =>
          product?.price?.currencyCode !== transactionCurrency
            ? [...results, product?.price?.currencyCode]
            : results,
        []
      )
    ).map((currencyCode) => ({
      value: currencyCode,
      label: currencyCode?.toUpperCase(),
    }));
  } else {
    transactionCurrency =
      travelBookings && travelBookings.length
        ? travelBookings[0].transactionTotalPrice.currencyCode
        : null;

    inventoryCurrencies = uniq(
      (travelBookings || []).reduce(
        (results, { inventoryTotalPrice: { currencyCode } }) =>
          currencyCode !== transactionCurrency ? [...results, currencyCode] : results,
        []
      )
    ).map((currencyCode) => ({
      value: currencyCode,
      label: currencyCode.toUpperCase(),
    }));
  }

  return {
    ratesBatchId,
    marginsBatchId,
    transactionCurrency,
    inventoryCurrencies,
    initialValues: {
      inventoryCurrency:
        inventoryCurrencies && inventoryCurrencies.length ? inventoryCurrencies[0].value : '',
    },
  };
};

const getUkJourneys = (travelBookings) =>
  compose(
    flatten(),
    pluck('journeys'),
    filter(({ vendorRegion }) => vendorRegion && vendorRegion.toLowerCase() === 'uk')
  )(travelBookings);

const getTicketTypes = (journeys) =>
  compose(
    flatten(),
    pluck('type'),
    flatten(),
    pluck('tickets'),
    flatten(),
    pluck('passengers'),
    flatten(),
    pluck('legs'),
    flatten()
  )(journeys);

export const hasFeeFreeCoJ = ({ createdOn, travelBookings }) => {
  const nov1 = moment.utc([2020, 10, 1]);
  const dec2 = moment.utc([2020, 11, 2]);
  if (moment.utc(createdOn).isSameOrAfter(nov1)) {
    return false;
  }

  const uKjourneys = getUkJourneys(travelBookings);
  if (uKjourneys.length === 0) {
    return false;
  }

  const departures = compose(flatten(), pluck('departAt'))(uKjourneys);
  if (!departures.some((departure) => moment.utc(departure).isBetween(nov1, dec2, 'd', '[]'))) {
    return false;
  }

  const ticketTypes = getTicketTypes(uKjourneys);
  return ticketTypes.some((ticketType) => /advance/i.test(ticketType));
};

export const hasFeeFreeCojDueToCovidRestrictions = ({ createdOn, travelBookings }) => {
  const jan4 = moment.utc([2021, 0, 4]);
  const jan5 = moment.utc([2021, 0, 5]);
  if (moment.utc(createdOn).isAfter(jan4, 'day')) {
    return false;
  }

  const uKjourneys = getUkJourneys(travelBookings);
  if (uKjourneys.length === 0) {
    return false;
  }

  const departures = compose(flatten(), pluck('departAt'))(uKjourneys);
  if (!departures.some((departure) => moment.utc(departure).isSameOrAfter(jan5, 'day'))) {
    return false;
  }

  const ticketTypes = getTicketTypes(uKjourneys);
  return ticketTypes.some((ticketType) => /advance/i.test(ticketType));
};

export const getPaymentErrors = createSelector(selectOrder, (order) => {
  const rootPaymentErrors =
    (order.errors && order.errors.filter(({ code }) => code === '66001.30')) || [];
  const errorSelector = compose(
    flatten(),
    pluck('code'),
    filter((e) => !!e),
    flatten(),
    pluck('innerErrors'),
    flatten(),
    filter((e) => !!e),
    pluck('meta')
  );
  return errorSelector(rootPaymentErrors);
});

export const getOrder = createSelector(selectOrder, selectProducts, (order, products) =>
  order
    ? {
        ...order,
        hasLockableBookings: hasLockableBookings(order),
        hasEuBookings: hasEuBookings(order, products),
        currencyData: mapCurrencyData(order, products),
        hasFeeFreeCoJ: hasFeeFreeCoJ(order),
        hasFeeFreeCojDueToCovidRestrictions: hasFeeFreeCojDueToCovidRestrictions(order),
      }
    : undefined
);

const isJourneySplitTicket = (journeys) =>
  journeys.some(
    (journey) =>
      journey.splitTicketType === 'DirectSplit' || journey.splitTicketType === 'InterchangeSplit'
  );

export const isJourneyMultifareTicket = (journeys) =>
  journeys.some(({ splitTicketType = '' }) => splitTicketType.toLowerCase() === 'multifare');

const getReturnDate = (journeys) => {
  const { departAt } = journeys[journeys.length - 1];
  // departAt is 0001-01-01T00:00:00+00:00 when the ticket has a validity period....
  return departAt === '0001-01-01T00:00:00+00:00' ? null : departAt;
};

const documentDetails = ({ link, type, deliverableId }) => ({
  deliverableId,
  type,
  link,
});

const groupByDocumentsAndFarePassengerId = (passengers) => {
  const hasSameDocuments = (a, b) => {
    const condition = eqBy(prop('deliverableId'));
    return symmetricDifferenceWith(condition, a, b).length === 0;
  };

  /**
   * groups passengers with same document (same deliverableId) and farePassengerId,
   * if a passenger has the same deriverableId and at the same time the passengerID,
   * it means it's a return journey and we dont need to repeat that passenger
   * * */
  return passengers.reduce((acc, current) => {
    const documentIndex = acc.findIndex(
      (passenger) =>
        current.documents &&
        current.documents.length > 0 &&
        passenger.documents &&
        passenger.documents.length > 0 &&
        hasSameDocuments(passenger.documents, current.documents) &&
        passenger.farePassengerId === current.farePassengerId
    );

    if (documentIndex > -1) {
      if (!acc[documentIndex].passengers.find((passenger) => passenger.id === current.id)) {
        acc[documentIndex] = {
          ...acc[documentIndex],
          passengers: acc[documentIndex].passengers.concat({
            name: current.name,
            type: current.type,
            id: current.id,
          }),
        };
      }
      return acc;
    }

    acc.push({
      ...current,
      passengers: [{ name: current.name, type: current.type, id: current.id }],
    });

    return acc;
  }, []);
};

const getTicketDetails = (journeys, { vendor, subVendor, isReturn }, ticketAssets) => {
  const flattenPassengers = flatten(
    journeys.map(({ legs }) =>
      flatten(
        legs.map(({ passengers }) =>
          flatten(
            passengers.map(({ name, type, tickets }) =>
              flatten(
                tickets.map(
                  ({
                    id,
                    originStation: origin,
                    destinationStation: destination,
                    deliverableIds,
                    class: ticketClass,
                    farePassengerId,
                    deliveryMethod,
                    ticketNumber,
                    type: ticketType,
                    checkInUrl,
                    deliverables,
                    fareConditions,
                  }) => ({
                    id,
                    name,
                    type,
                    vendor,
                    subVendor,
                    isReturn,
                    origin,
                    ticketType,
                    destination,
                    ticketClass,
                    farePassengerId,
                    deliveryMethod,
                    ticketNumber,
                    fareConditions,
                    documents:
                      deliverableIds &&
                      deliverableIds
                        .map((deliverableId) =>
                          ticketAssets.find((asset) => asset.deliverableId === deliverableId)
                        )
                        .filter((deliverable) => deliverable !== undefined)
                        .map((document) => documentDetails(document)),
                    checkInUrl,
                    deliverables,
                  })
                )
              )
            )
          )
        )
      )
    )
  );

  return groupByDocumentsAndFarePassengerId(flattenPassengers).map(
    ({
      ticketClass,
      documents,
      destination,
      fareConditions,
      origin,
      isReturn: isReturnP,
      vendor: operator,
      subVendor: subOperator,
      passengers,
      farePassengerId,
      ticketType,
      deliveryMethod,
      ticketNumber,
      checkInUrl,
      deliverables,
    }) => ({
      ticketClass,
      documents,
      destination,
      origin,
      fareConditions,
      type: ticketType,
      isReturn: isReturnP,
      vendor: operator,
      subVendor: subOperator,
      passengers: passengers.map(({ name, type }) => ({
        name,
        type,
      })),
      deliverables,
      farePassengerId,
      deliveryMethod,
      ticketNumber,
      checkInUrl,
    })
  );
};

const hasTgvRailCard = (journeys) =>
  compose(
    any((name) => name === 'Abonnement TGVmax'),
    pluck('name'),
    flatten(),
    pluck('railCards'),
    flatten(),
    pluck('passengers'),
    flatten(),
    pluck('legs')
  )(journeys) === true;

const isReturnJourney = (journeys) => journeys.length > 1;

const selectDelayRepayClaim = (state) => state.orders.delayRepayClaim;

const getDelayRepayClaim = createSelector(
  selectDelayRepayClaim,
  (delayRepayClaim) => delayRepayClaim
);

export const getDelayRepayClaimLoadingStatus = createSelector(
  selectDelayRepayClaim,
  (delayRepayClaim) => delayRepayClaim.loading
);

const getJourneyDelayRepayStatus = (journey, delayRepayClaim) => {
  const journeyDelayRepay =
    delayRepayClaim &&
    delayRepayClaim.items &&
    delayRepayClaim.items.find(({ journeyId }) => journeyId === journey.id);

  return journeyDelayRepay && journeyDelayRepay.status;
};

const getTimetables = (state) => state.orders.timetables && state.orders.timetables.items;

const getLegTimetable = (leg, timetables) =>
  timetables && timetables.find(({ legId }) => legId === leg.id);

const getBookingJourneyLegs = ({ legs }, timetables) =>
  legs.map((leg) => ({
    ...leg,
    timetable: getLegTimetable(leg, timetables),
  }));

const getBookingJourneys = (journeys, delayRepayClaim, timetables) =>
  journeys.map((journey) => ({
    ...journey,
    legs: getBookingJourneyLegs(journey, timetables),
    delayRepayStatus: getJourneyDelayRepayStatus(journey, delayRepayClaim),
  }));

export const getBookings = createSelector(
  getOrder,
  getDelayRepayClaim,
  getTimetables,
  (order, delayRepayClaim, timetables) => {
    if (order && order.travelBookings) {
      const { capitaineUrl, travelBookings: bookings } = order;
      return bookings
        .map(({ journeys, priceBreakdown, ticketAssets, ...booking }, bookingIndex) => ({
          ...booking,
          bookingIndex,
          journeys: getBookingJourneys(journeys, delayRepayClaim, timetables),
          priceBreakdown,
          capitaineUrl,
          origin: journeys[0].origin,
          destination: journeys[0].destination,
          outDate: journeys[0].departAt,
          returnDate: getReturnDate(journeys),
          isReturn: isReturnJourney(journeys),
          hasTgvRailCard: hasTgvRailCard(journeys),
          ticketDetails: getTicketDetails(journeys, booking, ticketAssets),
          isSplitTicket: isJourneySplitTicket(journeys),
          isMultifareTicket: isJourneyMultifareTicket(journeys),
        }))
        .sort((booking1, booking2) =>
          moment.utc(booking1.outDate).diff(moment.utc(booking2.outDate))
        );
    }
    return [];
  }
);

export const getFirstBookingCtr = createSelector(
  getOrder,
  (order) =>
    (order && order.travelBookings && order.travelBookings[0] && order.travelBookings[0].ctr) ||
    null
);

export const selectSDVExpiryTime = (state) =>
  state.orders.order && state.orders.order.voidable.expiryTime;

export const getOrderId = createSelector(getOrder, (order) => order?.id);
export const getOrderReference = createSelector(getOrder, (order) => order?.orderReference);
