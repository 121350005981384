import { defineMessages } from 'react-intl';

export default defineMessages({
  customerDetail: {
    id: 'VirtualTerminalCustomerForm.customerDetail',
    defaultMessage: 'Customer details',
  },
  firstName: {
    id: 'VirtualTerminalCustomerForm.firstName',
    defaultMessage: 'First name',
  },
  lastName: {
    id: 'VirtualTerminalCustomerForm.lastName',
    defaultMessage: 'Last name',
  },
  emailAddress: {
    id: 'VirtualTerminalCustomerForm.emailAddress',
    defaultMessage: 'Email address',
  },
  notes: {
    id: 'VirtualTerminalCustomerForm.notes',
    defaultMessage: 'Notes',
  },
  validation_firstName_invalid: {
    id: 'VirtualTerminalForm.validation_firstName_invalid',
    defaultMessage: 'Please enter a first name',
  },
  validation_lastName_invalid: {
    id: 'VirtualTerminalForm.validation_lastName_invalid',
    defaultMessage: 'Please enter a last name',
  },
  validation_email_invalid: {
    id: 'VirtualTerminalForm.validation_email_invalid',
    defaultMessage: 'Please enter a valid email address format',
  },
  validation_email_required: {
    id: 'VirtualTerminalForm.validation_email_required',
    defaultMessage: 'Please enter an email address',
  },
  validation_notes_invalid: {
    id: 'VirtualTerminalForm.validation_notes_invalid',
    defaultMessage: 'The field is required and cannot be blank',
  },
  validation_reasonCode_invalid: {
    id: 'VirtualTerminalForm.validation_reasonCode_invalid',
    defaultMessage: 'Please select a refund reason',
  },
});
