import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  errorContainer: {
    marginBottom: spacers.dp_s32,
  },
  editButton: {
    marginTop: spacers.dp_s16,
  },
});
