import React from 'react';
import { FormattedMessage } from 'react-intl';

import StackedLabel from '@contactcentre-web/common/StackedLabel';

import styles from './styles';
import messages from './messages';

const FraudLabel = () => (
  <StackedLabel
    orientation="horizontal"
    label={<FormattedMessage {...messages.recommendations} />}
    styleSheet={{
      label: styles.label_recommendations,
      value: styles.value_recommendations,
    }}
  >
    <FormattedMessage {...messages.fraud} />
  </StackedLabel>
);

export default FraudLabel;
