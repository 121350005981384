import { StyleSheet } from 'aphrodite/no-important';
import {
  typography,
  spacers,
  borderRadius,
  fonts,
} from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  cardList: {
    listStyle: 'none',
    maxWidth: '250px',
    ':not(:first-child)': {
      marginBottom: spacers.dp_s8,
    },
  },
  card: {
    display: 'inline-block',
    fontFamily: typography.dp_hero_font_family,
    fontSize: typography.dp_micro_font_size,
    padding: `1px ${spacers.dp_s4}`,
    borderRadius: borderRadius.dp_r2,
    color: colors.white,
    fontWeight: fonts.dp_font_weight_bold,
    marginTop: spacers.dp_s4,
    marginRight: spacers.dp_s4,
  },
});
