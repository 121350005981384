import { StyleSheet } from 'aphrodite/no-important';
import { borderRadius, spacers } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  noteContext: {
    borderRadius: borderRadius.dp_r2,
    border: `solid 1px ${colors.gray.alpha30}`,
    backgroundColor: '#f1f3f7',
    width: '100%',
    flex: '0 0 auto',
    padding: spacers.dp_s24,
    borderSpacing: '1px',
  },
  noteContext_row: {
    display: 'table-row',
  },
  noteContext_cell: {
    display: 'table-cell',
  },
  notesContext_label: {
    color: '#8c9da1',
    paddingRight: spacers.dp_s16,
  },
});
