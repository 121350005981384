import { StyleSheet } from 'aphrodite/no-important';
import { fonts, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  modalContent: {
    width: '910px',
  },
  modalBody: {
    display: 'flex',
    height: '50vh',
  },
  statusBox: {
    margin: `${spacers.dp_s16} ${spacers.dp_s32}`,
  },
  selects: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'stretch',
    margin: `0 ${spacers.dp_s12} 0 ${spacers.dp_s24}`,
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  select: {
    flex: '1 1 auto',
    width: '100%',
    cursor: 'default',

    ':not(:first-child)': {
      marginLeft: spacers.dp_s16,
    },
  },
  reasonCode: {
    flex: '1 1 auto',
  },
  path: {
    display: 'inline',
    paddingLeft: spacers.dp_s4,
  },
  pathItem: {
    display: 'inline',
    fontWeight: fonts.dp_font_weight_bold,
    ':not(:first-child)': {
      ':before': {
        content: "'/'",
        padding: `0 ${spacers.dp_s4}`,
      },
    },
  },
});
