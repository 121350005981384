import { defineMessages } from 'react-intl';

export default defineMessages({
  compensation: {
    id: 'CompensationCarrierRequest.compensation',
    defaultMessage: 'Compensation',
  },
  compensationNotProcessed: {
    id: 'CompensationCarrierRequest.compensationNotProcessed',
    defaultMessage: 'The refund has not been processed yet.',
  },
  requestedAmount: {
    id: 'CompensationCarrierRequest.requestedAmount',
    defaultMessage: 'Requested amount',
  },
  recommendationLabel: {
    id: 'CompensationCarrierRequest.recommendationLabel',
    defaultMessage: 'Recommendation',
  },
  recommendation: {
    id: 'CompensationCarrierRequest.recommendation',
    defaultMessage:
      'Please check no money or voucher has returned to the customer in the payment system or carrier website, and inform the customer to check if the refund has been processed within 5 working days.',
  },
});
