import { createSelector } from 'reselect';

import type State from '@contactcentre-web/redux-common/types/State';
import * as customerSelectors from '@contactcentre-web/redux-common/selectors/customer';
import * as orderSelectors from '@contactcentre-web/redux-common/selectors/order';

import { searchStates, moveStates } from './module';

const localState = (state: State) => state.customerOrderMove;
const customerState = (state: State) => state.customer;

export const isSearchInProgress = createSelector(
  localState,
  (state) => state.searchStatus === searchStates.INPROGRESS
);

export const customers = createSelector(localState, (state) => state.customers);

export const originalEmail = createSelector(customerState, (state) =>
  customerSelectors.getCustomerEmail(state)
);
export const customerId = createSelector(customerState, (state) =>
  customerSelectors.getSelectedCustomerId(state)
);

export const orderReference = createSelector(
  orderSelectors.getOrder,
  (order) => order.orderReference
);

export const orderId = createSelector(orderSelectors.getOrder, (order) => order.id);

export const canMove = createSelector(localState, (state) => state.canMove);

export const isMoveInProgress = createSelector(
  localState,
  (state) => state.moveStatus === moveStates.INPROGRESS
);

export const hasMovedSuccessfully = createSelector(
  localState,
  (state) => state.moveStatus === moveStates.SUCCESSFUL
);

export const newCustomerId = createSelector(localState, (state) => state.newCustomerId);

export const hasMoveFailed = createSelector(
  localState,
  (state) => state.moveStatus === moveStates.FAILED
);
