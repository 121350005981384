import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { StatusMessage } from '@trainline/depot-web';

import Link from '@contactcentre-web/common/Link';
import FulfilmentStatus from '@contactcentre-web/redux-common/types/FulfilmentStatus';

import messages from './messages';
import styles from './styles';

const ReplacedOrderAlert = ({ customerId, products }) => {
  const isChargeToTrainline = (replacementSubType) => replacementSubType === 'creditNote';
  return products.reduce(
    (prev, { replacedOrderReference, replacementSubType, fulfilmentStatus }) =>
      /* the fulfilment status check was add to prevet this messege to be displied when it cames from BookingFlow
       while it is still processing. Tech improvement ticket ATLEISURE-339 */
      replacedOrderReference &&
      fulfilmentStatus?.toLowerCase() !== FulfilmentStatus.Issuing &&
      fulfilmentStatus?.toLowerCase() !== FulfilmentStatus.Locked ? (
        <div className={css(styles.notification)}>
          <StatusMessage status="info">
            <div
              className={css(isChargeToTrainline(replacementSubType) && styles.multiLineMessage)}
            >
              <FormattedMessage
                data-test-id="replacement-message"
                {...(isChargeToTrainline(replacementSubType)
                  ? messages.replacementBookingWithChargeToTrainline
                  : messages.replacementBooking)}
                values={{
                  link: (
                    <Link
                      linkType="internal"
                      to={`/customers/${customerId}/bookings/${replacedOrderReference}`}
                    >
                      <FormattedMessage
                        {...(isChargeToTrainline(replacementSubType)
                          ? messages.goToOriginal
                          : messages.original)}
                      />
                    </Link>
                  ),
                }}
              />
            </div>
          </StatusMessage>
        </div>
      ) : (
        prev
      ),
    null
  );
};

ReplacedOrderAlert.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerId: PropTypes.string.isRequired,
};

export default ReplacedOrderAlert;
