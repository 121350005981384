import React, { ReactElement, FunctionComponent } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { StatusMessage } from '@trainline/depot-web';
import DatePicker, { registerLocale } from 'react-datepicker';
import { es, nb, it, enGB, fr, sv, de } from 'date-fns/locale';
import { useSelector } from 'react-redux';
import { IconChevronDown, IconChevronUp } from '@trainline/depot-web';

import Link from '@contactcentre-web/common/Link';
import Button from '@contactcentre-web/common/Button';
import { useAlternativeJourneys } from '@contactcentre-web/hooks/api/useAlternativeJourneys';
import Loader from '@contactcentre-web/common/Loader';
import { Journey, Link as ILink } from '@contactcentre-web/redux-common/types/Journey';

import styles from './styles';
import messages from './messages';
import JourneyItem from './components/JourneyItem/JourneyItem';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('en-GB', enGB);
registerLocale('nb-NO', nb);
registerLocale('fr-FR', fr);
registerLocale('it-IT', it);
registerLocale('de-DE', de);
registerLocale('es-ES', es);
registerLocale('sv-SE', sv);

type Profile = {
  locale: string;
};

type State = {
  profile: Profile;
};

type SearchType = 'date' | 'earlier' | 'later';

interface AlternativeJourneysProps {
  journey?: Journey;
  journeyDirection: string;
  isOpenReturn: boolean;
}

const findAlternativeJourneyLink = (journey: Journey | undefined) =>
  findLinkByRel(journey?.links, 'alternative-journeys');

const findEarlierJourneyLink = (links: ILink[] | undefined) => findLinkByRel(links, 'earlier');

const findLaterJourneyLink = (links: ILink[] | undefined) => findLinkByRel(links, 'later');

const findLinkByRel = (links: ILink[] | undefined, rel: string) =>
  links?.find((link: ILink) => link.rel === rel)?.href || '';

const NoOptions: FunctionComponent = ({ children }) => (
  <div className={css(styles.noOptionsContainer)}>{children}</div>
);

const AlternativeJourneys = ({
  journey,
  journeyDirection,
  isOpenReturn,
}: AlternativeJourneysProps): ReactElement => {
  const currentLocale = useSelector((state: State) => state.profile.locale);
  const originalAlternativeJourneyLink = findAlternativeJourneyLink(journey);
  const [alternativeJourneyLink, setAlternativeJourneyLink] = React.useState(
    originalAlternativeJourneyLink
  );
  const { data, isLoading, error } = useAlternativeJourneys(
    journeyDirection,
    alternativeJourneyLink
  );
  const [alternativeJourneys, setAlternativeJourneys] = React.useState({
    journeys: [],
    links: [],
  });
  const [dateTime, setDateTime] = React.useState(new Date(journey?.departAt || ''));
  const [searchType, setSearchType] = React.useState<SearchType>('date');
  const [noEarlierResults, setNoEarlierResults] = React.useState(false);
  const [noLaterResults, setNoLaterResults] = React.useState(false);
  const isEmptyBecauseOpenReturn =
    journeyDirection == 'inward' && isOpenReturn && alternativeJourneys.journeys.length == 0;

  const resetAlternativeJourneys = () => {
    setAlternativeJourneys({
      journeys: [],
      links: [],
    });
  };

  const loadJourneyByDate = (date: Date) => {
    setSearchType('date');
    setAlternativeJourneyLink(
      `${originalAlternativeJourneyLink}?departAfter=${new Date(date).toISOString()}`
    );
  };

  const loadMoreJourneys = (link: string, searchType: SearchType) => {
    setSearchType(searchType);
    setAlternativeJourneyLink(link);
  };

  const displayNoFurtherPageResultsWarning = () => {
    if (searchType == 'earlier') {
      setNoEarlierResults(true);
    } else if (searchType == 'later') {
      setNoLaterResults(true);
    }
  };

  const clearPageResultsWarning = () => {
    setNoLaterResults(false);
    setNoEarlierResults(false);
  };

  React.useEffect(() => {
    const hasJourneysButWithErrors =
      error && (alternativeJourneys?.journeys.length > 0 || alternativeJourneys?.links.length > 0);
    const hasDataAndJourneys = data && data.journeys?.length > 0;
    const hasDataButNoJourneys = data && data.journeys?.length === 0;

    if (hasDataAndJourneys) {
      clearPageResultsWarning();
      setAlternativeJourneys(data);
      return;
    }

    if (hasDataButNoJourneys) {
      if (searchType == 'date') {
        clearPageResultsWarning();
        resetAlternativeJourneys();
      } else {
        displayNoFurtherPageResultsWarning();
      }
      return;
    }

    if (hasJourneysButWithErrors) {
      resetAlternativeJourneys();
      return;
    }
  }, [error, data]);

  return (
    <div className={css(styles.container)}>
      {journey &&
        (alternativeJourneys.journeys.length != 0 ||
          error ||
          isEmptyBecauseOpenReturn ||
          isLoading) && (
          <>
            <p className={css(styles.directionsText)}>
              {journeyDirection === 'outward' ? (
                <FormattedMessage {...messages.outbound} />
              ) : (
                <FormattedMessage {...messages.return} />
              )}
            </p>
            <p className={css(styles.tripText)}>
              <FormattedMessage
                {...messages.trip}
                values={{ origin: journey.origin, destination: journey.destination }}
              />
            </p>
          </>
        )}
      {error && !isEmptyBecauseOpenReturn && (
        <div className={css(styles.statusMessage)}>
          <StatusMessage status="negative">
            <FormattedMessage
              {...messages.fetchFailed}
              values={{
                link: (
                  <Link
                    linkType="action"
                    onClick={() =>
                      loadMoreJourneys(
                        `${originalAlternativeJourneyLink}?v=${new Date().getTime()}`,
                        'date'
                      )
                    }
                  >
                    <FormattedMessage {...messages.tryAgain} />
                  </Link>
                ),
              }}
            />
          </StatusMessage>
        </div>
      )}
      {isLoading && <Loader size={38} borderWidth={5} />}
      {!isLoading && journey && alternativeJourneys && (
        <>
          {isEmptyBecauseOpenReturn && (
            <div data-testid="openReturnBanner" className={css(styles.alertOpenReturn)}>
              <StatusMessage status="info">
                <FormattedMessage {...messages.openReturn} />
              </StatusMessage>
            </div>
          )}
          {!error && (
            <>
              {alternativeJourneys.journeys.length == 0 && !isEmptyBecauseOpenReturn && (
                <NoOptions data-testid="noOptionsBanner">
                  {journeyDirection === 'outward' ? (
                    <FormattedMessage {...messages.noOutboundOptions} />
                  ) : (
                    <FormattedMessage {...messages.noReturnOptions} />
                  )}
                </NoOptions>
              )}
              {noEarlierResults && (
                <div className={css(styles.topWarning)}>
                  <StatusMessage status="warning">
                    <FormattedMessage {...messages.noEarlierResults} />
                  </StatusMessage>
                </div>
              )}
              {alternativeJourneys.journeys.length > 0 && (
                <>
                  <div className={css(styles.topMenu)}>
                    <DatePicker
                      selected={dateTime}
                      locale={currentLocale}
                      dateFormat="d MMM yyyy HH:mm"
                      showTimeSelect
                      disabledKeyboardNavigation
                      onChange={(date: Date) => {
                        setDateTime(date);
                      }}
                      onCalendarClose={() => {
                        loadJourneyByDate(dateTime);
                      }}
                      className={css([styles.calendar])}
                    />
                    <Button
                      variant="ghost"
                      testId="earlierTrains"
                      styleSheet={styles.buttonControl}
                      disabled={noEarlierResults}
                      onClick={() =>
                        loadMoreJourneys(
                          findEarlierJourneyLink(alternativeJourneys?.links),
                          'earlier'
                        )
                      }
                    >
                      <FormattedMessage {...messages.earlierTrains} />
                      <IconChevronUp />
                    </Button>
                  </div>
                  {alternativeJourneys.journeys.map((journey: any, index: React.Key) => (
                    <JourneyItem journey={journey} key={index} />
                  ))}
                  <div className={css(styles.bottomMenu)}>
                    <Button
                      variant="ghost"
                      testId="laterTrains"
                      styleSheet={styles.buttonControl}
                      disabled={noLaterResults}
                      onClick={() =>
                        loadMoreJourneys(findLaterJourneyLink(alternativeJourneys?.links), 'later')
                      }
                    >
                      <FormattedMessage {...messages.laterTrains} />
                      <IconChevronDown />
                    </Button>
                  </div>
                </>
              )}
              {noLaterResults && (
                <StatusMessage status="warning">
                  <FormattedMessage {...messages.noLaterResults} />
                </StatusMessage>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AlternativeJourneys;
