import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';

import FormattedCurrency from '../FormattedCurrency/FormattedCurrency';

import styles from './styles';

interface Props {
  message: MessageDescriptor;
  value?: {
    amount: number;
    currencyCode?: string;
    currency?: string;
  };
  styleSheet?: { value: StyleDeclarationValue; label: StyleDeclarationValue };
}

const getAmountToDisplay = (amount: number) => (amount < 0 || !amount ? 0 : amount);

const FeeLabel = ({ message, value, styleSheet }: Props) => {
  if (value) {
    const currency = value.currencyCode || value.currency || '';
    const amount = getAmountToDisplay(value.amount);
    return (
      <>
        <span className={css([styles.adjustLabel, styleSheet?.label])}>
          <FormattedMessage {...message} />:
        </span>
        <span className={css([styles.adjustValue, styleSheet?.value])}>
          <FormattedCurrency amount={amount} currency={currency} />
        </span>
      </>
    );
  }
  return (
    <span className={css([styles.adjustLabel, styleSheet?.label])}>
      <FormattedMessage {...message} />
    </span>
  );
};

export default FeeLabel;
