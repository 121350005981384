import { defineMessages } from 'react-intl';

export default defineMessages({
  boardBefore: {
    id: 'BoardingTooltip.boardBefore',
    defaultMessage: 'Boarding time {time} before departure.',
  },
  minutes: {
    id: 'BoardingTooltip.minutes',
    defaultMessage: ' {boardBeforeMinutes} minutes',
  },
  hours: {
    id: 'BoardingTooltip.hours',
    defaultMessage: ' {boardBeforeHrs} hours',
  },
});
