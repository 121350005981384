import { StyleSheet } from 'aphrodite/no-important';
import { spacers, typography } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  refundableAmount: {
    display: 'flex',
    gap: spacers.dp_s8,
  },
  input: {
    width: '100px',
    flexGrow: 0,
  },
  error: {
    position: 'absolute',
    width: '150px',
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
  },
});
