import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import Price from '@contactcentre-web/redux-common/types/Price';
import Button from '@contactcentre-web/common/Button';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@contactcentre-web/common/Modal';

import messages from './messages';

interface Props {
  compensation: Price;
  onCloseClick: () => void;
  onRejectClick: () => void;
}

const ApprovalRejection = ({ compensation, onCloseClick, onRejectClick }: Props) => (
  <Modal onClose={onCloseClick}>
    <ModalHeader>
      <ModalTitle>
        <FormattedMessage {...messages.header} />
      </ModalTitle>
    </ModalHeader>
    <ModalBody>
      <FormattedMessage
        {...messages.body}
        values={{
          ...compensation,
          amount: (
            <FormattedNumber
              value={compensation.amount}
              maximumFractionDigits={2}
              minimumFractionDigits={2}
            />
          ),
          strong: (msg: string) => <strong>{msg}</strong>,
        }}
      />
    </ModalBody>
    <ModalFooter contentAlignment="spaceBetween">
      <Button variant="tertiary" size="large" onClick={onCloseClick}>
        <FormattedMessage {...messages.cancel} />
      </Button>
      <Button variant="destructive" size="large" onClick={onRejectClick}>
        <FormattedMessage {...messages.reject} />
      </Button>
    </ModalFooter>
  </Modal>
);

export default ApprovalRejection;
