import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'ChangePassword.title',
    defaultMessage: 'Change password',
  },
  oldPassword: {
    id: 'ChangePasswordForm.oldPassword',
    defaultMessage: 'Current Password',
  },
  newPassword: {
    id: 'ChangePasswordForm.newPassword',
    defaultMessage: 'New Password',
  },
  confirmPassword: {
    id: 'ChangePasswordForm.confirmPassword',
    defaultMessage: 'Confirm Password',
  },
  submitButton: {
    id: 'ChangePasswordForm.submitButton',
    defaultMessage: 'Change password',
  },
  oldPasswordIsRequired: {
    id: 'ChangePasswordForm.oldPasswordIsRequired',
    defaultMessage: 'Current Password is required.',
  },
  newPasswordIsRequired: {
    id: 'ChangePasswordForm.newPasswordIsRequired',
    defaultMessage: 'New Password is required.',
  },
  confirmPasswordIsRequired: {
    id: 'ChangePasswordForm.confirmPasswordIsRequired',
    defaultMessage: 'Confirm Password is required.',
  },
  passwordsShouldBeTheSame: {
    id: 'ChangePasswordForm.passwordsShouldBeTheSame',
    defaultMessage: 'Passwords should be the same.',
  },
  passwordIsTooShort: {
    id: 'ChangePasswordForm.passwordIsTooShort',
    defaultMessage: 'Passwords must be at least 6 characters.',
  },
  noAlphaNum: {
    id: 'ChangePasswordForm.noAlphaNum',
    defaultMessage: 'Passwords must have at least one non alphanumeric character.',
  },
  noDigit: {
    id: 'ChangePasswordForm.noDigit',
    defaultMessage: "Passwords must have at least one digit ('0'-'9').",
  },
  noUpperCase: {
    id: 'ChangePasswordForm.noUpperCase',
    defaultMessage: "Passwords must have at least one uppercase ('A'-'Z').",
  },
  noLowerCase: {
    id: 'ChangePasswordForm.noLowerCase',
    defaultMessage: "Passwords must have at least one lowercase ('a'-'z').",
  },
});
