import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Reimbursement.title',
    defaultMessage: 'Automatic reimbursement',
  },
  message: {
    id: 'Reimbursement.message',
    defaultMessage:
      'Payment taken at time of order. {amount} being automatically returned to customer for this booking.',
  },
});
