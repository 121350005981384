import { useQuery } from 'react-query';

import type Price from '@contactcentre-web/redux-common/types/Price';
import request from '@contactcentre-web/utils/request';

export type AgentType = 'Agent' | 'System' | 'Customer' | 'Unknown';

export interface User {
  type: AgentType;
  name?: string;
}

export type Status = 'Requested' | 'Completed' | 'Rejected' | 'Pending';

export type OrderHistoryType =
  | 'Compensation'
  | 'CompensationCarrierRequest'
  | 'FulfilmentConverted'
  | 'Refund'
  | 'RefundRequestCreated'
  | 'RefundRequestRejected';

type VirtualTerminalType = 'Credit' | 'Debit';

interface OrderHistory {
  type: OrderHistoryType;
  user: User;
  date?: Date; // undefined in case data cannot be resolve, e.g. a link to a resource (Refund) fails with an error
}

export interface CardPaymentDetails {
  paymentMethod: 'card';
  cardType: string; // "visa", "mastercard", etc
  tokenizedCardNumber: string; // xxxx-xxxx-xxxx-1234
  holderName: string; // FirstName LastName
}

export interface OtherPaymentDetails {
  paymentMethod: string; // "paypal", "googlepay", etc
  paidBy?: string;
  billingEmailAddress?: string;
}

export interface CompensationHistory extends OrderHistory {
  payload: {
    id: string;
    reasonCode: string;
    status: Status;
    requestNote?: string;
    requestedAmount: Price;
    completingUser?: User;
    completingAt?: Date;
    completingNote?: string;
    completingReason?: string;
    refundedAmount?: Price;
    paymentType?: string;
    paymentDetails?: CardPaymentDetails | OtherPaymentDetails;
    virtualTerminalType?: VirtualTerminalType;
  };
}

export interface CompensationCarrierRequestHistory extends OrderHistory {
  requestedAmount?: Price;
}

export interface FulfilmentConverterHistory extends OrderHistory {
  id: string;
  status: Status;
  originalFulfilment: string;
  convertedFulfilment: string;
  convertedProducts: Array<string>;
}

export type RefundPolicy =
  | 'terms-and-conditions'
  | 'same-day-void'
  | 'discretionary'
  | 'changeOfJourney'
  | 'unknown';

export type RefundStatus = 'requested' | 'processing' | 'processed' | 'timedout' | 'rejected';

export type RefundableStatus =
  | 'pending'
  | 'voiding'
  | 'voided'
  | 'blocked'
  | 'rejected'
  | 'refunded'
  | 'failed'
  | 'requested';

export type RefundableType = 'fareRefundable' | 'feeRefundable' | 'railcardRefundable' | 'unknown';

interface Refundable {
  type: RefundableType;
  reason?: { description: string };
  status: RefundableStatus;
  statusError?:
    | 'refundsProtocol.notRefundableUnderThisPolicy'
    | 'refundsProtocol.voidAlreadyInProgressForRefundable'
    | 'refundsProtocol.voidableAlreadyVoided'
    | 'refundsProtocol.policyExpired'
    | 'refundsProtocol.ticketAlreadyCollected'
    | 'refundsProtocol.productInInvalidState'
    | 'refundsProtocol.RefundRequestStarted'
    | 'refundsProtocol.productModifiedExternally'
    | 'refundsProtocol.partOfPartiallyUsedSplitTicketSet';
}

export enum VendorRegion {
  UK = 'uk',
  EU = 'eu',
}

export interface FareRefundable extends Refundable {
  origin: { name: string };
  destination: { name: string };
  isReturnFare: boolean;
  ticketType: string;
  passenger: {
    type: string;
    name?: string;
  };
  vendorRegion?: VendorRegion;
  productInventoryReferences?: string[];
}

export interface FeeRefundable extends Refundable {
  name: string;
}

export interface RailcardRefundable extends Refundable {
  cardHolders: Array<{
    fullName: string;
    isPrimary: boolean;
  }>;
  number: string;
  name: string;
}

export interface RefundPayment {
  refundedAmount: Price;
  paymentDetails: CardPaymentDetails | OtherPaymentDetails;
}

export interface ChargedFee {
  name: 'AdminFee' | 'InventoryFee';
  amount: Price;
}

export interface RefundHistory extends OrderHistory {
  payload: {
    policy: RefundPolicy;
    status: RefundStatus;
    refundId?: string;
    requestedAmount?: Price;
    isPartial?: boolean;
    refundables?: Array<FareRefundable | FeeRefundable | RailcardRefundable>;
    payments?: Array<RefundPayment>;
    chargedFees?: Array<ChargedFee>;
  };
  linkedEvent?: RefundHistory;
}

export type OrderHistoryItem =
  | CompensationHistory
  | CompensationCarrierRequestHistory
  | FulfilmentConverterHistory
  | RefundHistory;

export interface OrderItemsHistory {
  items: Array<OrderHistoryItem>;
  totalCount: number;
  errors?: Array<{
    code: string;
    detail: string;
    meta: {
      severity: string;
      type: OrderHistoryType;
    };
  }>;
}

export const useOrderHistory = (orderId: string, orderReference: string) =>
  useQuery(
    ['orderHistoryItems', orderReference],
    () => request<OrderItemsHistory>(`/orderhistoryapi/${orderId}`, undefined, false),
    {
      enabled: !!orderId,
    }
  );
