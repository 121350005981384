import { defineMessages } from 'react-intl';

export default defineMessages({
  compensationCredit: {
    id: 'VirtualTerminalTotalAmount.compensationCredit',
    defaultMessage: 'Credit',
  },
  compensationDebit: {
    id: 'VirtualTerminalTotalAmount.compensationDebit',
    defaultMessage: 'Debit',
  },
});
