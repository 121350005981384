import { StyleSheet } from 'aphrodite/no-important';
import { spacers, palettes } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  text: {
    marginTop: spacers.dp_s16,
  },
  list: {
    listStyle: 'none',
    marginTop: spacers.dp_s24,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: spacers.dp_s12,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
  },
  listItemText: {
    marginLeft: spacers.dp_s8,
  },
  formContainer: {
    marginTop: spacers.dp_s24,
  },
  success: {
    color: palettes.dp_app_text_positive,
  },
  existing: {
    color: palettes.dp_app_text_base,
  },
  failure: {
    color: palettes.dp_app_text_negative,
  },
  default: {
    color: palettes.dp_app_text_base,
  },
});
