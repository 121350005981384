import { StyleSheet } from 'aphrodite/no-important';
import { typography, fonts, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  subtitle: {
    fontSize: typography.dp_small_font_size_m,
    letterSpacing: typography.dp_small_letter_spacing,
    lineHeight: typography.dp_small_line_height,
    marginBottom: spacers.dp_s32,
  },
  title: {
    fontSize: typography.dp_body_font_size,
    lineHeight: typography.dp_body_line_height,
    letterSpacing: typography.dp_body_letter_spacing,
    fontWeight: fonts.dp_font_weight_bold,
    marginBottom: spacers.dp_s8,
  },
  cardWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
  },
  card: {
    ':not(:last-child)': {
      marginBottom: spacers.dp_s12,
    },
  },
});
