import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import BookingDetail from '../BookingDetail/BookingDetail';
import type { Booking } from '../../module';

import styles from './styles';
import messages from './messages';

const BookingTicketType: FunctionComponent<Booking> = ({
  bookingId,
  origin,
  destination,
  isReturn,
  ticketsByType,
  transactionReference,
  type,
  localAreaValidity,
}) => (
  <div>
    <BookingDetail
      bookings={[
        {
          bookingId,
          origin,
          destination,
          isReturn,
          transactionReference,
          type,
          localAreaValidity,
        },
      ]}
    />
    <div>
      {ticketsByType?.map(({ type: ticketType, count }) => (
        <span
          className={css(styles.capitalized, styles.ticketsType)}
          key={`${bookingId}_${ticketType}`}
        >
          <FormattedMessage
            {...messages.ticketByType}
            values={{
              type: ticketType,
              count,
            }}
          />
        </span>
      ))}
    </div>
  </div>
);

export default BookingTicketType;
