import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  loaderContainer: {
    padding: spacers.dp_s96,
  },
  container: {
    marginTop: spacers.dp_s48,
  },
});
