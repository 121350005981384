import React from 'react';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';
import {
  Menu as MenuContainer,
  MenuHeader,
  MenuItem,
  SubMenu,
  MenuItemProps,
  MenuHeaderProps,
  SubMenuProps,
} from '@szhsin/react-menu';

import '@szhsin/react-menu/dist/index.css';
import styles from './styles';

export interface ItemsProps {
  handle: React.ReactElement;
  items?: ItemsProps[];
  groups?: GroupProps[];
  onClick?: React.EventHandler<any>;
  className?: string;
  'data-test'?: string;
}

interface Props extends ItemsProps {
  styleSheet?: StyleDeclarationValue;
  testId: string;
}

interface GroupProps {
  header: React.ReactNode;
  items: ItemsProps[];
}

const generateSubitems = (
  items: ItemsProps[]
): React.ReactElement<SubMenuProps | MenuItemProps>[] =>
  items.map(({ handle, items, onClick, className, ...rest }, index) =>
    items && items.length > 0 ? (
      <SubMenu key={index} label={handle} itemClassName={`${className || ''} ${css(styles.item)}`}>
        {generateSubitems(items)}
      </SubMenu>
    ) : (
      <MenuItem key={index} onClick={onClick} className={`${className || ''} ${css(styles.item)}`}>
        <div {...rest}>{handle}</div>
      </MenuItem>
    )
  );

const Menu = (props: Props): React.ReactElement<Props> => {
  const items: React.ReactElement<SubMenuProps | MenuItemProps | MenuHeaderProps>[] = [];

  if (props.items) {
    items.push(...generateSubitems(props.items));
  }

  if (props.groups) {
    items.push(
      ...props.groups.reduce<React.ReactElement<SubMenuProps | MenuItemProps | MenuHeaderProps>[]>(
        (prev, { header, items }, index) => [
          ...prev,
          <MenuHeader key={`header-${index}`}>{header}</MenuHeader>,
          ...generateSubitems(items),
        ],
        []
      )
    );
  }

  return (
    <MenuContainer menuButton={props.handle} align="end" offsetY={4}>
      {items}
    </MenuContainer>
  );
};

export default Menu;
