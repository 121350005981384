import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@contactcentre-web/common/Button';

import styles from '../../styles';
import messages from '../../messages';

export interface ExportCustomersRevocationResultsButtonProps {
  items: {
    customerId: string;
    preferredLanguage: string;
    managedGroupId: string;
    statusMessage: string;
  }[];
  downloadFileName: string;
  disabled: boolean;
  onExportSuccess?: () => void;
}

const ExportCustomersRevocationResultsButton = ({
  items,
  disabled,
  onExportSuccess,
  downloadFileName,
}: ExportCustomersRevocationResultsButtonProps) => {
  const handleExportButtonClick = () => {
    if (!items || items.length === 0) {
      return;
    }

    const content = buildContent();
    const blob = new Blob([content], { type: 'text/csv' });
    exportBlob(blob);
  };

  const buildContent = () => {
    const csvHeader = 'customerdId,managedGroupId,languageTag,status\n';
    const csvRows = items
      .map(
        (item) =>
          `${item.customerId},${item.managedGroupId},${item.preferredLanguage},${item.statusMessage}\n`
      )
      .join('');
    return csvHeader + csvRows;
  };

  const exportBlob = (blob: Blob) => {
    if (!blob) {
      return;
    }

    const url = URL.createObjectURL(blob);

    const aElement = document.createElement('a');
    aElement.href = url;
    aElement.download = `${downloadFileName}.csv`;

    aElement.click();

    if (onExportSuccess) {
      onExportSuccess();
    }
  };

  return (
    <Button
      type="submit"
      variant="secondary"
      size="small"
      testId="search-user-button"
      disabled={disabled}
      styleSheet={styles.exportButton}
      onClick={handleExportButtonClick}
    >
      <FormattedMessage {...messages.exportButton} />
    </Button>
  );
};

export default ExportCustomersRevocationResultsButton;
