import { defineMessages } from 'react-intl';

export default defineMessages({
  bookingLockedMessage: {
    id: 'SupersededBooking.BookingLocked.bookingLockedMessage',
    defaultMessage: ' - this booking has been manually locked.',
  },
  capitaineLock: {
    id: 'SupersededBooking.BookingLocked.capitaineLock',
    defaultMessage:
      "this booking has been modified in <link>BEBOC /Capitaine</link>. The booking details here may be out of date. Some functions (eg. refund, etc) can't be used here anymore.",
  },
  tracksLock: {
    id: 'SupersededBooking.BookingLocked.tracksLock',
    defaultMessage:
      "this booking has been modified in TraCS. The booking details here may be out of date. Some functions (eg. refund, etc) can't be used here anymore.",
  },
});
