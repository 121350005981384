import { Action } from 'redux-actions';
import { all, takeLatest, put, call } from 'redux-saga/effects';
import { startSubmit, stopSubmit, reset, setSubmitFailed } from 'redux-form';

import request from '@contactcentre-web/utils/request';

import {
  actions,
  SEARCH_ATTEMPT,
  MOVE_ATTEMPT,
  FORM_ID,
  PREFIX,
  SearchRequest,
  SearchRequestResponse,
  MoveOrderRequest,
  MoveOrderBodyRequest,
} from './module';

export const searchRequest = (email: string) => request(`/api/search/v3?query=${email}`);
export const moveRequest = (
  customerId: string,
  orderReference: string,
  payload: MoveOrderBodyRequest
) =>
  request(`/api/customers/v2/${customerId}/orders/${orderReference}/move`, {
    method: 'POST',
    body: payload,
  });

export function* searchEmailSaga({ payload: { email } }: Action<SearchRequest>) {
  try {
    yield put(startSubmit(FORM_ID));

    const results: SearchRequestResponse = yield call(searchRequest, email);
    if (results.totalCount === 0) {
      yield put(stopSubmit(FORM_ID, { email: 'email_noResults' }));
      yield put(actions.searchSuccess(false, results.items));

      return;
    }

    if (results.totalCount > 1) {
      yield put(stopSubmit(FORM_ID, { email: 'email_multipleResults' }));
      yield put(actions.searchSuccess(false, results.items));
      return;
    }

    yield put(stopSubmit(FORM_ID));
    yield put(actions.searchSuccess(true, results.items));
  } catch (err) {
    yield put(stopSubmit(FORM_ID));
    yield put(setSubmitFailed(FORM_ID));
    yield put(actions.searchError(err));
  }
}

export function* moveOrderSaga({
  payload: { customerId, orderReference, orderId, newCustomerId },
}: Action<MoveOrderRequest>) {
  try {
    yield put(startSubmit(FORM_ID));

    yield call(moveRequest, customerId, orderReference, { newCustomerId, orderId });
    yield put(actions.moveSuccess(newCustomerId));

    yield put(stopSubmit(FORM_ID));
    yield put(reset(FORM_ID));
  } catch {
    yield put(stopSubmit(FORM_ID));
    yield put(actions.moveError());
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(`${PREFIX}/${SEARCH_ATTEMPT}`, searchEmailSaga),
    takeLatest(`${PREFIX}/${MOVE_ATTEMPT}`, moveOrderSaga),
  ]);
}
