import * as enLanguages from 'iso-countries-languages/res/languages/en.json';
import * as noLanguages from 'iso-countries-languages/res/languages/no.json';
import * as frLanguages from 'iso-countries-languages/res/languages/fr.json';
import * as itLanguages from 'iso-countries-languages/res/languages/it.json';
import * as deLanguages from 'iso-countries-languages/res/languages/de.json';
import * as esLanguages from 'iso-countries-languages/res/languages/es.json';
import * as svLanguages from 'iso-countries-languages/res/languages/sv.json';
import Cookies from 'js-cookie';

import { translationMessages } from './i18n';
import { LOCALE_CODES, DEFAULT_LOCALE_CODE } from './locales';

const STATIC_IMAGES_COUNTRY_FLAGS_URL =
  'https://static.trainlinecontent.com/content/WEB/images/country-flags';

export const getLocale = () => {
  const languageCookieValue = Cookies.get('languageCode');

  if (languageCookieValue) {
    return languageCookieValue;
  }

  return Object.values(LOCALE_CODES).includes(navigator.language as LOCALE_CODES)
    ? navigator.language
    : DEFAULT_LOCALE_CODE;
};

export const getLocaleInfo = (locale: LOCALE_CODES) => {
  const messages = translationMessages[locale];

  return { locale, messages };
};

const getLanguage = (locale: LOCALE_CODES) => {
  const language = (locale && locale.split('-')[0]) || 'en';

  switch (locale) {
    case LOCALE_CODES.ENGLISH:
      return 'en';
    case LOCALE_CODES.NORWEGIAN:
      return 'no';
    case LOCALE_CODES.FRENCH:
      return 'fr';
    case LOCALE_CODES.ITALIAN:
      return 'it';
    case LOCALE_CODES.GERMAN:
      return 'de';
    case LOCALE_CODES.SPANISH:
      return 'es';
    case LOCALE_CODES.SWEDISH:
      return 'sv';
    default:
      return language;
  }
};

const getCountry = (lang: string) => lang.split('-')[1];

export const getLanguageName = (lang: LOCALE_CODES, inLang: LOCALE_CODES) => {
  const languagesInCurrentLocale: Record<string, Record<string, string>> = {
    [getLanguage(LOCALE_CODES.ENGLISH)]: enLanguages,
    [getLanguage(LOCALE_CODES.NORWEGIAN)]: noLanguages,
    [getLanguage(LOCALE_CODES.FRENCH)]: frLanguages,
    [getLanguage(LOCALE_CODES.ITALIAN)]: itLanguages,
    [getLanguage(LOCALE_CODES.GERMAN)]: deLanguages,
    [getLanguage(LOCALE_CODES.SPANISH)]: esLanguages,
    [getLanguage(LOCALE_CODES.SWEDISH)]: svLanguages,
  };

  const currentLanguage = getLanguage(inLang);
  const language = getLanguage(lang);

  return languagesInCurrentLocale[currentLanguage][language];
};

export const getCountryFlagLink = (lang: string) => {
  const country = getCountry(lang);
  return `${STATIC_IMAGES_COUNTRY_FLAGS_URL}/${country.toLowerCase()}.svg`;
};

const availableLocales = [
  LOCALE_CODES.ENGLISH,
  LOCALE_CODES.NORWEGIAN,
  LOCALE_CODES.FRENCH,
  LOCALE_CODES.ITALIAN,
  LOCALE_CODES.GERMAN,
  LOCALE_CODES.SPANISH,
  LOCALE_CODES.SWEDISH,
];

export const getAvailableLocales = () => availableLocales.filter((l) => typeof l === 'string');
