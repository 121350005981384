import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { StatusMessage } from '@trainline/depot-web';

import { Modal, ModalHeader, ModalTitle } from '@contactcentre-web/common/Modal';
import { actions as orderActions } from '@contactcentre-web/redux-common/actions/order';
import Button from '@contactcentre-web/common/Button';

import { actions } from '../../module';
import selectors from '../../selectors';

import RemoveCollectionRestrictionFormContainer from './RemoveCollectionRestrictionForm';
import messages from './messages';

export const RemoveCollectionRestrictionCard = ({
  className,
  role,
  isRemoveRestrictionInProgress,
  isRemoveRestrictionSuccess,
  reset,
  reloadOrder,
  bookingIndexConvertedAnyCard,
}) => {
  const [visible, setVisible] = useState(false);
  const {
    params: { customerId, orderId },
  } = useRouteMatch();
  const history = useHistory();
  const resetRef = React.useRef(reset);
  const reloadRef = React.useRef(reloadOrder);

  React.useEffect(() => () => resetRef.current(), [resetRef, orderId]);

  React.useEffect(() => {
    if (isRemoveRestrictionSuccess) {
      reloadRef.current(customerId, orderId);
      history.push(`?removed-collection-restrictions=${bookingIndexConvertedAnyCard}`);
    }
  }, [isRemoveRestrictionSuccess, reloadRef]);

  const showModal = () => {
    reset();
    setVisible(true);
  };

  return (
    <>
      <Button
        variant="ghost"
        testId="ChangeToCTROpen"
        id="ChangeToCTROpen"
        styleSheet={className}
        role={role}
        onClick={() => showModal()}
      >
        <FormattedMessage {...messages.changeableToAnyCard} />
      </Button>
      {visible && (
        <Modal testId="ctrModal" onClose={() => setVisible(false)}>
          <ModalHeader>
            <ModalTitle>
              <FormattedMessage {...messages.header} />
            </ModalTitle>
          </ModalHeader>
          <StatusMessage status="info">
            <FormattedMessage {...messages.note} />
          </StatusMessage>
          <RemoveCollectionRestrictionFormContainer
            isRemoveRestrictionInProgress={isRemoveRestrictionInProgress}
          />
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  isRemoveRestrictionInProgress: selectors.isRemoveRestrictionInProgress(state),
  isRemoveRestrictionSuccess: selectors.isRemoveRestrictionSuccess(state),
  bookingIndexConvertedAnyCard: selectors.bookingIndexConvertedAnyCard(state),
});

const mapDispatchToProps = (dispatch) => ({
  reset: () => dispatch(actions.reset()),
  reloadOrder: (customerId, orderId) => dispatch(orderActions.load(customerId, orderId)),
});

RemoveCollectionRestrictionCard.propTypes = {
  className: PropTypes.string,
  isRemoveRestrictionInProgress: PropTypes.bool,
  isRemoveRestrictionSuccess: PropTypes.bool,
  role: PropTypes.string,
  reset: PropTypes.func.isRequired,
  reloadOrder: PropTypes.func.isRequired,
  bookingIndexConvertedAnyCard: PropTypes.number,
};

const RemoveCollectionRestrictionCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoveCollectionRestrictionCard);

RemoveCollectionRestrictionCardContainer.displayName = 'RemoveCollectionRestrictionCard';

export default RemoveCollectionRestrictionCardContainer;
