import React from 'react';
import { FunctionComponent } from 'enzyme';

import ListSummary from '@contactcentre-web/common/ListSummary/ListSummary';

import CustomerNotesItem from '../CustomerNotesItem/CustomerNotesItem';
import type { Note } from '../../module';

interface Props {
  notes: Array<Note>;
}

const CustomerNotesList: FunctionComponent<Props> = ({ notes = [] }) => (
  <div>
    <ul key="list">
      {notes.map((note) => (
        <CustomerNotesItem key={note.id} {...note} />
      ))}
    </ul>
    <ListSummary type="notes" total={notes.length} />
  </div>
);

export default CustomerNotesList;
