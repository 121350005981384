import React, { InputHTMLAttributes, RefObject, TextareaHTMLAttributes } from 'react';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';

import styles from './styles';

type HTMLAttributesProps =
  | ({ type: 'multiline-text' } & TextareaHTMLAttributes<HTMLTextAreaElement>)
  | ({ type?: string } & InputHTMLAttributes<HTMLInputElement>);

export type TextInputProps = {
  testId?: string;
  ariaLabel?: string;
  ariaDescribedby?: string;
  ariaOwns?: string;
  ariaAutocomplete?: 'list' | 'none' | 'both' | 'inline';
  styleSheet?: StyleDeclarationValue;
  inputError?: boolean;
  inputRef?: RefObject<HTMLTextAreaElement | HTMLInputElement>;
} & HTMLAttributesProps;

const TextInput = ({
  type,
  name,
  disabled,
  testId,
  ariaLabel,
  ariaDescribedby,
  ariaOwns,
  ariaAutocomplete,
  readOnly = false,
  inputRef,
  styleSheet,
  inputError = false,
  ...props
}: TextInputProps) => {
  const inputClassName = css(
    styles.root,
    type === 'multiline-text' && styles.textarea,
    inputError && styles.error,
    styleSheet
  );

  return type === 'multiline-text' ? (
    <textarea
      id={name}
      name={name}
      className={inputClassName}
      disabled={disabled}
      readOnly={readOnly}
      data-test={testId} // deprecated
      data-testid={testId}
      ref={inputRef as RefObject<HTMLTextAreaElement>}
      aria-label={ariaLabel}
      aria-disabled={disabled}
      aria-describedby={ariaDescribedby}
      aria-owns={ariaOwns}
      aria-autocomplete={ariaAutocomplete}
      {...(props as TextareaHTMLAttributes<HTMLTextAreaElement>)}
    />
  ) : (
    <input
      type={type}
      id={name}
      name={name}
      className={inputClassName}
      disabled={disabled}
      readOnly={readOnly}
      data-test={testId} // deprecated
      data-testid={testId}
      ref={inputRef as RefObject<HTMLInputElement>}
      aria-label={ariaLabel}
      aria-disabled={disabled}
      aria-describedby={ariaDescribedby}
      aria-owns={ariaOwns}
      aria-autocomplete={ariaAutocomplete}
      {...(props as InputHTMLAttributes<HTMLInputElement>)}
    />
  );
};

export default TextInput;
