import { StyleSheet } from 'aphrodite/no-important';
import { palettes, spacers, typography, fonts } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  customerListItemButton: {
    width: '100%',
  },

  customerListItem: {
    listStyle: 'none',
    cursor: 'pointer',
  },

  customerInfoRows: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  customerNameLastActive: {
    color: palettes.dp_app_text_base,
    fontWeight: fonts.dp_font_weight_regular,
    fontSize: typography.dp_body_font_size,
    lineHeight: typography.dp_body_line_height,
    marginBottom: spacers.dp_s4,
  },

  customerName: {
    marginRight: spacers.dp_s12,
  },

  lastActive: {
    color: colors.gray.alpha30,
    fontWeight: fonts.dp_font_weight_regular,
    fontSize: typography.dp_micro_font_size_m,
    lineHeight: typography.dp_micro_line_height_m,
  },

  customerEmail: {
    fontSize: typography.dp_small_font_size_m,
    letterSpacing: typography.dp_small_letter_spacing,
    lineHeight: typography.dp_small_line_height,
    color: palettes.dp_app_text_base,
    fontWeight: fonts.dp_font_weight_regular,
    marginBottom: spacers.dp_s8,
  },

  customerPhones: {
    fontSize: typography.dp_small_font_size_m,
    letterSpacing: typography.dp_small_letter_spacing,
    lineHeight: typography.dp_small_line_height,
    color: palettes.dp_app_text_base,
  },

  ruler: {
    marginTop: spacers.dp_s24,
    marginBottom: spacers.dp_s24,
  },
});
