import { defineMessages } from 'react-intl';

export default defineMessages({
  editPassengerButton: {
    id: 'EditPassenger.editPassengerButton',
    defaultMessage: 'Edit details',
  },
  editPassengerFormHeader: {
    id: 'EditPassenger.editPassengerFormHeader',
    defaultMessage: 'Edit passenger details',
  },
  passengerNotFoundErrorMessage: {
    id: 'EditPassenger.passengerNotFoundErrorMessage',
    defaultMessage: "Customer's passenger not found, please refresh the page and try again",
  },
  genericErrorMessage: {
    id: 'EditPassenger.genericErrorMessage',
    defaultMessage: "Failed to edit customer's passenger, please try again",
  },
});
