import { Heading } from '@trainline/depot-web';
import { css } from 'aphrodite/no-important';
import React, { useState, ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormSection } from 'redux-form';

import { currencyMap } from '@contactcentre-web/utils/currencies';
import TextField from '@contactcentre-web/common/TextField';
import Label from '@contactcentre-web/common/Label/Label';
import Select from '@contactcentre-web/common/Select';
import Button from '@contactcentre-web/common/Button';

import messages from './messages';
import styles, { selectStyle } from './styles';

export const FORM_PROCEED = 'proceedCompensationForm';

export type Props = {
  canNotProceed: boolean;
  isTerminalDebit: boolean;
};

const ProceedCompensation = ({ canNotProceed, isTerminalDebit }: Props) => {
  const { formatMessage } = useIntl();
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const TOTAL_MAX_AMOUNT = 10000;
  const TOTAL_MIN_AMOUNT = 0.2;
  const STEP = 0.01;

  const handleChangeInput = (
    event: ChangeEvent<any> | ChangeEvent<HTMLInputElement> | undefined
  ) => {
    const { value } = event?.target;
    const amount = Number(value);
    setButtonDisabled(
      !value || isNaN(amount) || amount < TOTAL_MIN_AMOUNT || amount > TOTAL_MAX_AMOUNT
    );
  };
  const currencyOptions = Object.values(currencyMap).map((currency) => ({
    label: currency.code,
    value: currency.code,
  }));

  return (
    <FormSection name={FORM_PROCEED}>
      <Heading typeStyle="title3" as="h2" color="inverted">
        <FormattedMessage
          {...(isTerminalDebit ? messages.compensationDebit : messages.compensationCredit)}
        />
      </Heading>
      <div className={css(styles.container)}>
        <Label
          htmlFor="totalAmount"
          label={formatMessage(messages.total)}
          styleSheet={styles.labelColor}
        >
          <div className={css(styles.inputContainer)}>
            <TextField
              name="totalAmount"
              type="number"
              min={TOTAL_MIN_AMOUNT}
              max={TOTAL_MAX_AMOUNT}
              step={STEP}
              data-testid="totalAmount"
              onChange={(event) => handleChangeInput(event)}
              styleSheet={{ input: styles.inputCurrency }}
            />
            <Select name="currencyCode" options={currencyOptions} selectStyle={selectStyle} />
          </div>
        </Label>

        <Button
          type="submit"
          variant="primary"
          size="small"
          styleSheet={styles.button}
          disabled={isButtonDisabled || canNotProceed}
        >
          <FormattedMessage {...messages.continue} />
        </Button>
      </div>
    </FormSection>
  );
};

export default ProceedCompensation;
