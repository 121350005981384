import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@contactcentre-web/common/Button';
import Link from '@contactcentre-web/common/Link';
import { useDownloadPdf } from '@contactcentre-web/hooks/api/useDownloadPdf';

import messages from './messages';
import styles from './styles';

interface Props {
  link: string;
  onClick?: () => void;
  withRequest?: boolean;
  fileName?: string;
  format?: 'button' | 'link';
  errorHandler?: (error: unknown) => void;
}

const DocumentDownload: FunctionComponent<Props> = ({
  link,
  onClick,
  withRequest,
  fileName,
  children,
  format = 'button',
  errorHandler,
}) => {
  const { mutate, isLoading } = useDownloadPdf(fileName, errorHandler);

  const handleClick = () => {
    onClick && onClick();
    withRequest ? mutate(link) : window.open(link);
  };

  return (
    <>
      {format === 'button' ? (
        <Button variant="tertiary" size="small" styleSheet={styles.button} onClick={handleClick}>
          {isLoading ? (
            <FormattedMessage {...messages.downloading} />
          ) : (
            children || <FormattedMessage {...messages.document} />
          )}
        </Button>
      ) : (
        <Link linkType="action" onClick={handleClick} styleSheet={styles.ghostButton}>
          {children || <FormattedMessage {...messages.document} />}
        </Link>
      )}
    </>
  );
};

export default DocumentDownload;
