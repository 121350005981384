import { StyleSheet } from 'aphrodite/no-important';
import { spacers, typography } from '@trainline/depot-web/css/themes/trainline';

const typographyStyles = {
  fontSize: typography.dp_small_font_size_m,
  letterSpacing: typography.dp_small_letter_spacing,
  lineHeight: typography.dp_small_line_height,
};

export default StyleSheet.create({
  table: {
    width: '100%',
  },
  padHorizontal: {
    padding: `0 ${spacers.dp_s16}`,
  },
  itemCell: {
    ...typographyStyles,
    textAlign: 'left',
    width: '180px',
  },
  priceFeeCell: {
    ...typographyStyles,
    paddingRight: spacers.dp_s32,
    textAlign: 'right',
    width: '100px',
  },
  pricePaidCell: {
    ...typographyStyles,
    padding: `0 ${spacers.dp_s48} ${spacers.dp_s8} 0`,
    textAlign: 'right',
    width: '100px',
  },
});
