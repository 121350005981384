import { defineMessages } from 'react-intl';

export default defineMessages({
  booking: {
    id: 'SeasonBooking.Header.booking',
    defaultMessage: 'Booking {index}',
  },
  validity: {
    id: 'SeasonBooking.Header.validity',
    defaultMessage: 'validity',
  },
  validFrom: {
    id: 'SeasonBooking.Header.validFrom',
    defaultMessage: 'valid from',
  },
  validTo: {
    id: 'SeasonBooking.Header.validTo',
    defaultMessage: 'valid to',
  },
  deliveryMethod: {
    id: 'SeasonBooking.Header.deliveryMethod',
    defaultMessage: 'delivery method',
  },
  ctr: {
    id: 'SeasonBooking.Header.ctr',
    defaultMessage: 'Ctr',
  },
  productPrice: {
    id: 'SeasonBooking.Header.productPrice',
    defaultMessage: 'product price',
  },
  productType: {
    id: 'SeasonBooking.Header.productType',
    defaultMessage: '{isFlexiSeason, select, true {Flexi ticket} other {Season}}',
  },
  sTicketTooltip: {
    id: 'SeasonBooking.Header.sTicketTooltip',
    defaultMessage: 'Known as mobile barcode in-app',
  },
});
