import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'CompensationApprovals.title',
    defaultMessage: 'Compensation requests',
  },
  countSummary: {
    id: 'CompensationApprovals.countSummary',
    defaultMessage: 'Viewing {lowerBound}-{upperBound} of {totalCount}',
  },
  bannerText: {
    id: 'CompensationApprovals.bannerText',
    defaultMessage:
      'You are viewing compensation requests for approval, processed by the {site} team.',
  },
  loadFailed: {
    id: 'CompensationApprovals.loadFailed',
    defaultMessage: 'There was an error loading the compensations, please try again later',
  },
  resolutionFailed: {
    id: 'CompensationApprovals.resolutionFailed',
    defaultMessage: 'There was an error loading the compensations, please try again later',
  },
  loadMore: {
    id: 'CompensationApprovals.loadMore',
    defaultMessage: 'Load more',
  },
  sortBy: {
    id: 'CompensationApprovals.sortBy',
    defaultMessage: '<span>Sort by: </span>  {sortBy, select, Asc {Oldest} Desc {Newest}}',
  },
});
