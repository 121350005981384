import { handleActions } from 'redux-actions';
import moment from 'moment';

import {
  LOAD_CALL_CRM_QUEUES,
  LOAD_CALL_CRM_QUEUES_SUCCESS,
  LOAD_CALL_CRM_QUEUES_FAILED,
  LOAD_CALL_CRM_QUEUES_NO_SITE_USER_ERROR,
  LOAD_SURVEY_CHOICES,
  LOAD_SURVEY_CHOICES_SUCCESS,
  LOAD_SURVEY_CHOICES_FAILED,
  SAVE_ORDER_NOTE_RESET,
  SAVE_ORDER_NOTE_ATTEMPT,
  SAVE_ORDER_NOTE_SUCCESS,
  LOAD_ADS_VERSION_COMPATIBLE,
  LOAD_ADS_VERSION_INCOMPATIBLE,
  LOAD_ADS_VERSION_FAILED,
  loadStates,
  adsStates,
  LOAD_ADS_LOGGED_IN_STATUS_SUCCESS,
  LOAD_ADS_LOGGED_IN_STATUS_FAILED,
  ADS_LOG_OUT_SUCCESS,
  ADS_LOG_OUT_FAILED,
  CHANGE_ADS_TOKEN,
  CHANGE_ADS_TOKEN_SUCCESS,
  CHANGE_ADS_TOKEN_FAILED,
  LOAD_ADS_LOGGED_IN,
  LOAD_ADS_LOGGED_IN_SUCCESS,
  LOAD_ADS_LOGGED_IN_FAILED,
  ADS_RETRY_COUNT,
  ADS_RETRY_COUNT_SUCCESS,
  ADS_RETRY_COUNT_FAILED,
} from './module';

const initialState = {
  loadState: loadStates.NONE,
  error: '',
  callQueues: {
    callQueues: [],
    error: '',
  },
  surveyChoices: {
    surveyChoices: [],
    error: false,
  },
  hasSaved: false,
  adsState: adsStates.INACTIVE,
  adsVersion: '',
  isLoggedInToAds: false,
  adsToken: '',
  adsExpiresIn: 0,
  adsExpiresAt: '',
  isLoggedInToAdsToken: false,
  adsRetryCount: 0,
  adsRetryTokenRefreshCount: 0,
};

const reducer = handleActions(
  {
    [LOAD_CALL_CRM_QUEUES]: (state) => ({
      ...state,
      loadState: loadStates.INPROGRESS,
    }),
    [LOAD_CALL_CRM_QUEUES_SUCCESS]: (state, { payload }) => ({
      ...state,
      callQueues: {
        ...state.callQueues,
        callQueues: [...payload],
      },
      loadState: loadStates.SUCCESS,
    }),
    [LOAD_CALL_CRM_QUEUES_NO_SITE_USER_ERROR]: (state, { payload: error }) => ({
      ...state,
      callQueues: {
        ...state.callQueues,
        error,
      },
      loadState: loadStates.ERROR,
    }),
    [LOAD_CALL_CRM_QUEUES_FAILED]: (state, { payload: error }) => ({
      ...state,
      error,
      loadState: loadStates.ERROR,
    }),
    [LOAD_SURVEY_CHOICES]: (state) => ({
      ...state,
      loadState: loadStates.INPROGRESS,
    }),
    [LOAD_SURVEY_CHOICES_SUCCESS]: (state, { payload }) => ({
      ...state,
      surveyChoices: {
        ...state.surveyChoices,
        surveyChoices: [...payload],
      },
      loadState: loadStates.SUCCESS,
    }),
    [LOAD_SURVEY_CHOICES_FAILED]: (state, { payload: error }) => ({
      ...state,
      callQueues: {
        ...state.callQueues,
        error,
      },
      error: true,
      loadState: loadStates.ERROR,
    }),
    [SAVE_ORDER_NOTE_RESET]: (state) => ({
      ...state,
      hasSaved: false,
    }),
    [SAVE_ORDER_NOTE_ATTEMPT]: (state) => ({
      ...state,
      hasSaved: false,
    }),
    [SAVE_ORDER_NOTE_SUCCESS]: (state) => ({
      ...state,
      hasSaved: true,
    }),
    [LOAD_ADS_VERSION_COMPATIBLE]: (state, { payload: version }) => ({
      ...state,
      adsState: adsStates.ACTIVE,
      adsVersion: version,
    }),
    [LOAD_ADS_VERSION_INCOMPATIBLE]: (state, { payload: version }) => ({
      ...state,
      adsState: adsStates.INCOMPATIBLE,
      adsVersion: version,
    }),
    [LOAD_ADS_VERSION_FAILED]: (state) => ({
      ...state,
      adsState: adsStates.INACTIVE,
    }),
    [LOAD_ADS_LOGGED_IN_STATUS_SUCCESS]: (state, { payload: isLoggedIn }) => ({
      ...state,
      isLoggedInToAds: isLoggedIn,
    }),
    [LOAD_ADS_LOGGED_IN_STATUS_FAILED]: (state) => ({
      ...state,
      isLoggedInToAds: false,
    }),
    [CHANGE_ADS_TOKEN]: (state) => ({
      ...state,
    }),
    [CHANGE_ADS_TOKEN_SUCCESS]: (
      state,
      { payload: { access_token: token, expires_in: expiresIn } }
    ) => ({
      ...state,
      adsToken: token,
      adsExpiresIn: expiresIn,
      adsExpiresAt: moment().add(expiresIn, 'seconds'),
      adsRetryTokenRefreshCount: 0,
    }),
    [CHANGE_ADS_TOKEN_FAILED]: (state) => ({
      ...state,
      adsRetryTokenRefreshCount: state.adsRetryTokenRefreshCount + 1,
    }),
    [LOAD_ADS_LOGGED_IN]: (state) => ({
      ...state,
      isLoggedInToAdsToken: false,
    }),
    [LOAD_ADS_LOGGED_IN_SUCCESS]: (state) => ({
      ...state,
      isLoggedInToAdsToken: true,
    }),
    [LOAD_ADS_LOGGED_IN_FAILED]: (state) => ({
      ...state,
      isLoggedInToAdsToken: false,
    }),
    [ADS_LOG_OUT_SUCCESS]: (state) => ({
      ...state,
      isLoggedInToAdsToken: false,
    }),
    [ADS_LOG_OUT_FAILED]: (state) => ({
      ...state,
    }),
    [ADS_RETRY_COUNT]: (state) => ({
      ...state,
      adsRetryCount: 0,
    }),
    [ADS_RETRY_COUNT_SUCCESS]: (state) => ({
      ...state,
      adsRetryCount: 0,
    }),
    [ADS_RETRY_COUNT_FAILED]: (state) => ({
      ...state,
      adsRetryCount: state.adsRetryCount + 1,
    }),
  },
  initialState
);

export default reducer;
