export enum LOCALE_CODES {
  ENGLISH = 'en-GB',
  NORWEGIAN = 'nb-NO',
  FRENCH = 'fr-FR',
  ITALIAN = 'it-IT',
  GERMAN = 'de-DE',
  SPANISH = 'es-ES',
  SWEDISH = 'sv-SE',
}

export const DEFAULT_LOCALE_CODE = LOCALE_CODES.ENGLISH;
