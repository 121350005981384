export const zIndex = {
  base: 1,
  mid: 10,
  header: 15,
  sidebar: 16,
  modal: 20,
  notification: 30,
};

export default zIndex;
