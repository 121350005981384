import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useTrackOpenInBeboc } from '@contactcentre-web/hooks/api/useTrackOpenInBeboc';
import Button from '@contactcentre-web/common/Button';

import styles from './styles';
import messages from './messages';

interface Props {
  orderReference: string;
  link: string;
}

const OpenInBeboc = ({ orderReference, link }: Props) => {
  const { mutate: trackOpenInBebocMutate } = useTrackOpenInBeboc(orderReference);

  return (
    <Button
      variant="ghost"
      onClick={() => {
        trackOpenInBebocMutate();
        window.open(link);
      }}
      styleSheet={styles.link}
    >
      <FormattedMessage {...messages.openInBeboc} />
    </Button>
  );
};

export default OpenInBeboc;
