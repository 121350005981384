import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'CojSpecial.title',
    defaultMessage:
      'The following order(s) is eligible for extended change of journey without fees due to covid restriction:',
  },
  order: {
    id: 'CojSpecial.order',
    defaultMessage: 'Order {orderNumber}',
  },
});
