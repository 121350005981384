import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { IconRailcard, IconSingle } from '@trainline/depot-web';

import Price from '@contactcentre-web/redux-common/types/Price';
import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency';
import { Railcard } from '@contactcentre-web/refunds/types';

import RefundableCheckIcons from '../RefundableCheckIcons/RefundableCheckIcons';

import messages from './messages';
import styles from './styles';

interface Props {
  railcardData: Railcard;
  refundableQuote?: Price;
  isRefundable: boolean;
  notRefundableReason?: string;
}

const RailcardRefundable = ({
  railcardData,
  refundableQuote,
  isRefundable,
  notRefundableReason,
}: Props) => {
  const notRefundableReasonKey = notRefundableReason?.split('.').pop() as keyof typeof messages;
  const nonRefundableReason =
    messages[notRefundableReasonKey] ?? messages.notRefundableUnderThisPolicy;

  return (
    <section className={css(styles.railCardRefundableContainer)}>
      <div className={css(styles.railCardRefundableColumn)}>
        <FormattedMessage
          {...messages.railcard}
          values={{
            railcardType: (
              <div className={css(styles.railCardRefundableFlex)}>
                <IconRailcard />
                <span className={css(styles.railCardRefundableValue)}>{railcardData.name}</span>
              </div>
            ),
            span: (msg: string) => <span>{msg}</span>,
          }}
        />
      </div>
      <div className={css(styles.railCardRefundableColumn)}>
        <FormattedMessage {...messages.productPrice} />
        <div className={css(styles.railCardRefundableValue)}>
          <FormattedCurrency {...railcardData.price} />
        </div>
      </div>
      <div className={css(styles.railCardRefundableColumn)}>
        <FormattedMessage {...messages.refundableAmount} />
        <div className={css(styles.railCardRefundableValue)}>
          {refundableQuote ? (
            <FormattedCurrency {...refundableQuote} />
          ) : (
            <FormattedMessage {...messages.NA} />
          )}
        </div>
      </div>
      <div className={css(styles.railCardRefundableColumn)}>
        <FormattedMessage {...messages.refundable} />
        <RefundableCheckIcons isRefundable={isRefundable}>
          <FormattedMessage {...nonRefundableReason} />
        </RefundableCheckIcons>
      </div>
      <div className={css(styles.railCardRefundableColumnGrid)}>
        <FormattedMessage
          {...messages.validity}
          values={{
            ...railcardData.validity,
            from: (
              <div className={css(styles.railCardRefundableValue)}>
                <FormattedDate
                  value={railcardData.validity.from}
                  day="2-digit"
                  month="short"
                  year="numeric"
                />
              </div>
            ),
            arrow: (
              <span className={css(styles.railCardRefundableArrow)}>
                <IconSingle />
              </span>
            ),
            to: (
              <div className={css(styles.railCardRefundableValue)}>
                <FormattedDate
                  value={railcardData.validity.to}
                  day="2-digit"
                  month="short"
                  year="numeric"
                />
              </div>
            ),
            span: (msg: string) => (
              <span className={css(styles.railCardRefundableLabel)}>{msg}</span>
            ),
          }}
        />
      </div>
      <div className={css(styles.railCardRefundableColumn)}>
        <FormattedMessage {...messages.refundEligibility} />
        <div className={css(styles.railCardRefundableValue)}>
          {railcardData.expiryTimestampUtc ? (
            <FormattedMessage
              {...messages.expiryDate}
              values={{
                ...railcardData.expiryTimestampUtc,
                expiryDate: (
                  <FormattedDate
                    value={railcardData.expiryTimestampUtc}
                    day="2-digit"
                    month="short"
                    year="numeric"
                  />
                ),
              }}
            />
          ) : (
            <FormattedMessage {...messages.NA} />
          )}
        </div>
      </div>
      <div className={css(styles.railCardRefundableColumn)}>
        <FormattedMessage
          {...messages.issuedTo}
          values={{
            ...railcardData.issuedTo,
            name: railcardData.issuedTo.map(
              ({ firstName, lastName }) => `${firstName} ${lastName}`
            ),
            strong: (msg: string) => (
              <span className={css(styles.railCardRefundableValue)}>{msg}</span>
            ),
          }}
        />
      </div>
    </section>
  );
};

export default RailcardRefundable;
