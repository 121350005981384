import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { StatusMessage, Heading } from '@trainline/depot-web';

import PageContainer from '@contactcentre-web/common/PageContainer/PageContainer';

import { selectors } from '../../module';

import messages from './messages';

const OrderError = ({ errorCode }) => (
  <StatusMessage status="negative">
    <Heading typeStyle="title3">
      <FormattedMessage {...messages.title_failed} />
    </Heading>
    {errorCode && (
      <>
        <FormattedMessage {...messages.error_code} values={{ errorCode }} />{' '}
      </>
    )}
    {errorCode && messages[`paymentError_${errorCode.toLowerCase()}`] ? (
      <FormattedMessage {...messages[`paymentError_${errorCode.toLowerCase()}`]} />
    ) : (
      <FormattedMessage {...messages.paymentError_unknown} />
    )}
  </StatusMessage>
);
OrderError.propTypes = {
  errorCode: PropTypes.string,
};

const OrderPending = () => (
  <StatusMessage status="warning">
    <Heading typeStyle="title3">
      <FormattedMessage {...messages.title_pending} />
    </Heading>
    <FormattedMessage {...messages.payment_pending} />
  </StatusMessage>
);

const nonPendingPaymentStatuses = ['complete', 'paid', 'partiallyrefunded', 'unknown'];
const nonPendingOrderStatuses = ['complete', 'failed'];

const isNotPendingOrderOrPaymentStatus = (orderStatus, paymentStatus) =>
  nonPendingOrderStatuses.includes(orderStatus.toLowerCase()) ||
  nonPendingPaymentStatuses.includes(paymentStatus.toLowerCase());

const isNotFailedOrderWithPaymentFailure = (orderStatus, { status: paymentStatus, hasErrors }) =>
  (orderStatus.toLowerCase() !== 'failed' ||
    nonPendingPaymentStatuses.includes(paymentStatus.toLowerCase())) &&
  !hasErrors;

export const PaymentStatus = ({ orderStatus, paymentStatus }) => {
  const isNotPending = isNotPendingOrderOrPaymentStatus(orderStatus, paymentStatus.status);
  const hasNotErrored = isNotFailedOrderWithPaymentFailure(orderStatus, paymentStatus);
  if (isNotPending && hasNotErrored) {
    return null;
  }

  return (
    <PageContainer>
      {hasNotErrored ? <OrderPending /> : <OrderError errorCode={paymentStatus.errorCode} />}
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  paymentStatus: selectors.paymentStatus(state),
});

PaymentStatus.propTypes = {
  orderStatus: PropTypes.string.isRequired,
  paymentStatus: PropTypes.shape({
    status: PropTypes.string.isRequired,
    hasErrors: PropTypes.bool,
    errorCode: PropTypes.string,
  }).isRequired,
};

const connected = connect(mapStateToProps)(PaymentStatus);
connected.displayName = 'PaymentStatus';

export default connected;
