import { defineMessages } from 'react-intl';

export default defineMessages({
  resetSeasonSuccess: {
    id: 'FlexiSeasonAlert.resetSeasonSuccess',
    defaultMessage:
      'All tickets in this booking have been reset. Check <link>order history</link> for details.',
  },
  cancelSeasonSuccess: {
    id: 'FlexiSeasonAlert.cancelSeasonSuccess',
    defaultMessage:
      'All tickets in this booking have been cancelled. Check <link>order history</link> for details.',
  },
});
