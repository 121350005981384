import React from 'react';
import { css } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Tooltip from '@contactcentre-web/common/Tooltip';
import messages from '@contactcentre-web/common/VendorStatus/messages';
import Loader from '@contactcentre-web/common/Loader/Loader';

import DeliveryAddress from '../DeliveryAddress/DeliveryAddress';

import styles from './styles';

const VENDOR_STATUS_STYLES = {
  issuing: 'statusIssuing',
  issued: 'statusIssued',
  others: 'statusOthers',
};

const VendorStatus = ({ deliveryMethod, status, deliveryRecipient }) => {
  const message = status ? messages[status.toLowerCase()] : null;
  const statusStyle =
    status && styles[VENDOR_STATUS_STYLES[status.toLowerCase()] || VENDOR_STATUS_STYLES.others];
  const deliveryAddress = deliveryRecipient && deliveryRecipient.address && (
    <DeliveryAddress {...deliveryRecipient} />
  );

  return (
    <div>
      {deliveryMethod && (
        <Tooltip
          orientation="Below"
          anchor={
            <span className={css(deliveryRecipient && styles.deliveryAddress)}>
              {deliveryMethod}
            </span>
          }
        >
          {deliveryAddress}
        </Tooltip>
      )}
      {status ? (
        <div className={css(statusStyle, styles.status)}>
          {message && <FormattedMessage {...message} />}
          {!message && <>{status.toLowerCase().toUpperCase()}</>}
        </div>
      ) : (
        <Loader size={32} borderWidth={5} borderColor="white" />
      )}
    </div>
  );
};

VendorStatus.propTypes = {
  deliveryMethod: PropTypes.string,
  status: PropTypes.string,
  deliveryRecipient: PropTypes.object,
};

export default VendorStatus;
