import { StyleSheet } from 'aphrodite/no-important';
import { palettes } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    background: 'none',
    right: 28,
    top: 28,
    cursor: 'pointer',
    color: palettes.dp_app_text_base,
    borderWidth: 0,
    lineHeight: 0,
  },
});
