import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { css } from 'aphrodite/no-important';
import { Helmet } from 'react-helmet';

// import UsabillaButton from '@contactcentre-web/common/UsabillaButton';
import Header from '@contactcentre-web/header/Header';
import Sidebar from '@contactcentre-web/sidebar/Sidebar';
import * as authSelectors from '@contactcentre-web/authentication/redux/selectors';
import {
  selectors as sidebarSelectors,
  actions as sidebarActions,
} from '@contactcentre-web/sidebar/module';
import CustomerNotesButtonContainer from '@contactcentre-web/sidebar/components/CustomerNotes/components/CustomerNotesButton/CustomerNotesButton';

import PermissionBasedRoute from '../PermissionBasedRoute/PermissionBasedRoute';
import metaHead from '../../config/meta-head';
import CustomerPage from '../../pages/CustomerPage';
import RefundApprovalsPage from '../../pages/RefundApprovalsPage';
import CompensationApprovalsPage from '../../pages/CompensationApprovalsPage';
import SearchPage from '../../pages/SearchPage';
import EditUserPage from '../../pages/EditUserPage';
import CreateNewAgentPage from '../../pages/CreateNewAgentPage';
import BulkUploadsPage from '../../pages/BulkUploadsPage';
import VirtualTerminalPage from '../../pages/VirtualTerminalPage';
import VirtualTerminalApprovalsPage from '../../pages/VirtualTerminalApprovalsPage';
import TracsOnAccountPage from '../../pages/TracsOnAccountPage';
import TracsOnAccountApprovalPage from '../../pages/TracsOnAccountApprovalPage';
import NotFoundPage from '../../pages/NotFoundPage';
import SetupManagedGroupPage from '../../pages/SetupManagedGroupPage';
import ManagePermissionPage from '../../pages/ManagePermissionPage';
import SearchResultsPage from '../../pages/SearchResultsPage';
import ManagedGroupsPage from '../../pages/ManagedGroupsPage';
import ManualEntryConsolePage from '../../pages/ManualEntryConsolePage';
import ManualEntryConsoleEditPage from '../../pages/ManualEntryConsoleEditPage';
import ManualEntryConsoleAddPage from '../../pages/ManualEntryConsoleAddPage';
import PaperTicketQueuePage from '../../pages/PaperTicketQueuePage';
import CustomerRevocationPage from '../../pages/CustomerRevocationPage';

import styles from './styles';

export class Layout extends Component {
  componentDidUpdate(prevProps) {
    const { isSidebarOpen } = this.props;
    if (!isSidebarOpen && prevProps.isSidebarOpen) {
      this.clearAfterTransition();
    }
  }

  clear = () => {
    const { clearSidebar, toClearSidebar } = this.props;
    if (toClearSidebar) clearSidebar();

    this.container.removeEventListener('transitionend', this.clear);
  };

  clearAfterTransition = () => {
    this.container.addEventListener('transitionend', this.clear);
  };

  render() {
    const {
      isSidebarOpen,
      canRequestUnlimitedRefund,
      canApproveUnlimitedRefund,
      canViewMecClaim,
      canManageUnmatchedTickets,
      canRevokeCustomerPassword,
    } = this.props;
    return (
      <div
        ref={(el) => {
          this.container = el;
        }}
        className={css([styles.layout, isSidebarOpen && styles.layoutWithSidebar])}
      >
        <Helmet {...metaHead} />
        <div className={css(styles.main)}>
          <Header />
          <Switch>
            <Route path="/customers/:customerId" component={CustomerPage} />
            <Route path="/customers" exact component={SearchResultsPage} />
            <PermissionBasedRoute
              path="/customers-revocation"
              component={CustomerRevocationPage}
              hasPermission={canRevokeCustomerPassword}
            />
            <Route path="/create-new-agent" exact component={CreateNewAgentPage} />
            <Route path="/edit_user" component={EditUserPage} />
            <Route path="/refund-approvals" component={RefundApprovalsPage} />
            <Route path="/compensation-approvals" component={CompensationApprovalsPage} />
            <Route path="/bulk-uploads" component={BulkUploadsPage} />
            <PermissionBasedRoute
              path="/paper-ticket-approvals"
              component={PaperTicketQueuePage}
              hasPermission={canManageUnmatchedTickets}
            />
            <PermissionBasedRoute
              path="/mec-claim-approvals"
              component={ManualEntryConsolePage}
              hasPermission={canViewMecClaim}
            />
            <PermissionBasedRoute
              path="/mec-claim-edit/:claimId"
              component={ManualEntryConsoleEditPage}
              hasPermission={canViewMecClaim}
            />
            <PermissionBasedRoute
              path="/mec-claim-add"
              component={ManualEntryConsoleAddPage}
              hasPermission={canViewMecClaim}
            />
            <PermissionBasedRoute
              path="/virtual-terminal-credit"
              component={VirtualTerminalPage}
              hasPermission={canRequestUnlimitedRefund}
              key="virtual-terminal-credit"
            />
            <PermissionBasedRoute
              path="/virtual-terminal-debit"
              component={VirtualTerminalPage}
              hasPermission={canApproveUnlimitedRefund}
              key="virtual-terminal-debit"
            />
            <PermissionBasedRoute
              path="/virtual-terminal-approvals"
              component={VirtualTerminalApprovalsPage}
              hasPermission={canApproveUnlimitedRefund}
            />
            <PermissionBasedRoute
              path="/tracs-onaccount-refunds"
              component={TracsOnAccountPage}
              hasPermission={canRequestUnlimitedRefund}
            />
            <PermissionBasedRoute
              path="/tracs-onaccount-approvals"
              component={TracsOnAccountApprovalPage}
              hasPermission={canApproveUnlimitedRefund}
            />
            <Route path="/setup-new-managed-group" component={SetupManagedGroupPage} />
            <Route path="/manage-permission" component={ManagePermissionPage} />
            <Route path="/managed-groups/:managedGroup" component={ManagedGroupsPage} />
            <Route path="/" component={SearchPage} />
            <Route path="*">
              <NotFoundPage />
            </Route>
          </Switch>
        </div>
        <Route
          path="/customers/:customerId/:path/:orderId"
          render={({
            match: {
              params: { path, ...rest },
            },
          }) => (!path || path === 'bookings') && <CustomerNotesButtonContainer params={rest} />}
        />
        <Sidebar isOpen={isSidebarOpen} />
        {/* <UsabillaButton /> */}
      </div>
    );
  }
}

Layout.propTypes = {
  isSidebarOpen: PropTypes.bool.isRequired,
  clearSidebar: PropTypes.func.isRequired,
  toClearSidebar: PropTypes.bool.isRequired,
  canRequestUnlimitedRefund: PropTypes.bool.isRequired,
  canApproveUnlimitedRefund: PropTypes.bool.isRequired,
  canViewMecClaim: PropTypes.bool,
  canManageUnmatchedTickets: PropTypes.bool,
  canRevokeCustomerPassword: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isSidebarOpen: sidebarSelectors.isOpen(state),
  toClearSidebar: sidebarSelectors.getClearSidebar(state),
  canRequestUnlimitedRefund: authSelectors.canRequestUnlimitedRefund(state),
  canApproveUnlimitedRefund: authSelectors.canApproveUnlimitedRefund(state),
  canViewMecClaim: authSelectors.canViewMecClaim(state),
  canManageUnmatchedTickets: authSelectors.canManageUnmatchedTickets(state),
  canRevokeCustomerPassword: authSelectors.canRevokeCustomerPassword(state),
});

const mapDispatchToProps = (dispatch) => ({
  clearSidebar: () => dispatch(sidebarActions.clearSidebar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
