import React, { FunctionComponent, ReactElement } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';

import FormattedDateInLondonTz from '@contactcentre-web/common/FormattedDateInLondonTz';
import FormattedTimeInLondonTz from '@contactcentre-web/common/FormattedTimeInLondonTz';

import messages from './messages';
import styles from './styles';

interface OrderHistoryCardProps {
  createdAt: string | Date;
  cardTitle?: ReactElement | string;
  headerItems: ReactElement;
  cardBody?: ReactElement | null;
}

export const OrderHistoryCard: FunctionComponent<OrderHistoryCardProps> = ({
  createdAt,
  cardTitle,
  headerItems,
  cardBody,
}) => (
  <div className={css(styles.container)}>
    <div className={css(styles.header)}>
      <div className={css(styles.col)}>
        <div className={css(styles.labelPrimary)}>{cardTitle}</div>
        <FormattedMessage
          {...messages.createdAt}
          values={{
            day: (
              <FormattedDateInLondonTz
                value={createdAt}
                year="numeric"
                month="short"
                day="numeric"
                addOffset
              />
            ),
            time: <FormattedTimeInLondonTz value={createdAt} addOffset />,
          }}
        />
      </div>
      {headerItems}
    </div>
    {cardBody && <div className={css(styles.body)}>{cardBody}</div>}
  </div>
);

interface OrderHistoryCardHeaderProps {
  label: ReactElement | string;
  value?: ReactElement | string | null;
  testId?: string;
}

export const OrderHistoryCardHeader: FunctionComponent<OrderHistoryCardHeaderProps> = ({
  label,
  value,
  testId,
}) => {
  const defaultMessage = (label as ReactElement).props.defaultMessage;
  return (
    <div
      className={css(
        styles.col,
        defaultMessage === 'Requested By' && styles.colAgent,
        defaultMessage === 'Booking' && styles.colBooking
      )}
      data-test={testId}
    >
      <div className={css(styles.labelSecondary)}>{label}</div>
      {value}
    </div>
  );
};
