import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import FormattedDateInLondonTz from '@contactcentre-web/common/FormattedDateInLondonTz';
import HorizontalRule from '@contactcentre-web/common/HorizontalRule';
import Button from '@contactcentre-web/common/Button';

import MostRecentBooking from '../MostRecentBooking/MostRecentBooking';
import type { CustomerSearchResultItem } from '../../utils/searchService';

import styles from './styles';
import messages from './messages';

interface Props {
  customer: CustomerSearchResultItem;
  handleClick: (id: string) => void;
}

const CustomerListItem: FunctionComponent<Props> = ({ customer, handleClick }) => {
  const {
    id,
    forename,
    surname,
    email,
    address: { addressLine1, addressLine2, addressLine3, postcode },
    phoneNumbers: [dayTime, mobile, evening],
    lastActive: { accessMethod, time },
    latestBooking,
  } = customer;

  return (
    <Button
      variant="ghost"
      styleSheet={styles.customerListItemButton}
      onClick={() => handleClick(id)}
    >
      <li data-testid="customerSummary" className={css(styles.customerListItem)}>
        <HorizontalRule styleSheet={styles.ruler} />

        <div className={css(styles.customerInfoRows)}>
          <div>
            <div className={css(styles.customerNameLastActive)}>
              <span className={css(styles.customerName)}>{`${surname}, ${forename}`}</span>

              <span className={css(styles.lastActive)}>
                <FormattedMessage
                  {...messages.lastActive}
                  values={{
                    date: (
                      <FormattedDateInLondonTz
                        value={time}
                        year="numeric"
                        month="short"
                        day="numeric"
                        weekday="short"
                        hour="2-digit"
                        minute="2-digit"
                      />
                    ),
                    accessMethod,
                  }}
                />
              </span>
            </div>

            <div className={css(styles.customerEmail)}>
              {`${email} - ${addressLine1}, ${addressLine2}, ${addressLine3}, ${postcode}`}
            </div>

            <div className={css(styles.customerPhones)}>
              <FormattedMessage
                {...messages.customerPhones}
                values={{
                  mobile: mobile.value,
                  evening: evening.value,
                  dayTime: dayTime.value,
                }}
              />
            </div>
          </div>
          <MostRecentBooking {...latestBooking} />
        </div>
      </li>
    </Button>
  );
};

export default CustomerListItem;
