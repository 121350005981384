import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    height: '100%',
    flex: '1 1 auto',
  },
  content: {
    flex: '1 1 auto',
  },
  spinner: {
    margin: `${spacers.dp_s32} auto`,
  },
  header: {
    width: '100%',
    flex: '0 1 auto',
  },
  customerContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
});
