import { defineMessages } from 'react-intl';

export default defineMessages({
  replacementBooking: {
    id: 'ReplacedOrderAlert.replacementBooking',
    defaultMessage: 'This is a replacement booking. View {link}.',
  },
  replacementBookingWithChargeToTrainline: {
    id: 'ReplacedOrderAlert.replacementBookingWithChargeToTrainline',
    defaultMessage:
      'This is a replacement order that has been charged to Trainline. Check FreshDesk for notes. \n {link}',
  },
  original: {
    id: 'ReplacedOrderAlert.original',
    defaultMessage: 'original',
  },
  goToOriginal: {
    id: 'ReplacedOrderAlert.goToOriginal',
    defaultMessage: 'Go to the old booking',
  },
});
