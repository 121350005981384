import { StyleSheet } from 'aphrodite/no-important';
import { palettes, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  buttonContent: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonText: {
    marginLeft: spacers.dp_s8,
  },
  icon: {
    fill: palettes.dp_app_backdrop_base,
  },
  refreshAnimation: {
    animationName: {
      to: {
        transform: 'rotate(360deg)',
      },
    },
    animationDuration: '1s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
});
