import { InsuranceProduct } from '@contactcentre-web/redux-common/types/InsuranceProduct';
import { Product } from '@contactcentre-web/redux-common/types/Product';
import { SeasonProduct } from '@contactcentre-web/redux-common/types/SeasonProduct';
import { TravelProduct } from '@contactcentre-web/redux-common/types/TravelProduct';
import { IssuedTo, RailcardProduct } from '@contactcentre-web/redux-common/types/RailcardProduct';
import { LocalAreaProduct } from '@contactcentre-web/redux-common/types/LocalAreaProduct';
import FulfilmentStatus from '@contactcentre-web/redux-common/types/FulfilmentStatus';
import { Validity } from '@contactcentre-web/redux-common/types/Validity';
import Price from '@contactcentre-web/redux-common/types/Price';
import DeliveryMethod from '@contactcentre-web/redux-common/types/DeliveryMethod';
import { ProductType } from '@contactcentre-web/redux-common/types/ProductType';

import {
  isInsuranceProduct,
  isLocalAreaProduct,
  isRailcardProduct,
  isSeasonProduct,
  isTravelProduct,
} from './utils';

type NormalisedTicket = {
  farePassengerId: string;
  type?: string;
  name?: string;
  originStation?: string;
  destinationStation?: string;
  fareConditions?: { name: string; description: string }[];
};
type NormalisedPassenger = {
  tickets: NormalisedTicket[];
};
type NormalisedLeg = { passengers: NormalisedPassenger[] };
type NormalisedJourney = { legs: NormalisedLeg[]; validity?: Validity };

export type NormalisedProduct = {
  id: string;
  type: ProductType;
  fulfilmentStatus: FulfilmentStatus;
  deliveryMethod?: string;
  localAreaValidity?: string;
  journeys: NormalisedJourney[];
  hasMultiplePassengers?: boolean;
  railcardDetails?: {
    name: string;
    validity: Validity;
    issuedTo: IssuedTo[];
    price: Price;
    deliveryMethod: DeliveryMethod;
  };
};

const normaliseInsuranceProduct = (product: InsuranceProduct): NormalisedProduct => {
  const { id, type, fulfilmentStatus } = product;

  return { id, type, fulfilmentStatus, journeys: [] };
};
const normaliseSeasonProduct = (product: SeasonProduct): NormalisedProduct => {
  const { id, type, status, journey, validity, ticketType, conditionsOfUse, deliveryMethod } =
    product;

  return {
    id,
    type,
    fulfilmentStatus: status,
    deliveryMethod: deliveryMethod.id,
    journeys: [
      {
        ...journey,
        validity,
        legs: [
          {
            passengers: [
              {
                ...journey.passenger,
                tickets: [
                  {
                    farePassengerId: journey.passenger.farePassengerId,
                    originStation: journey.origin,
                    destinationStation: journey.destination,
                    type: ticketType.displayName,
                    name: journey.passenger.fullName,
                    fareConditions: conditionsOfUse,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };
};

const normaliseRailCardProduct = (product: RailcardProduct): NormalisedProduct => {
  const { id, type, status, name, validity, issuedTo, price, deliveryMethod } = product;

  return {
    id,
    type,
    fulfilmentStatus: status,
    journeys: [],
    railcardDetails: { name, validity, issuedTo, price, deliveryMethod },
  };
};

const normaliseLocalAreaProduct = (product: LocalAreaProduct): NormalisedProduct => {
  const { id, type, status, validity, localAreaValidity, passengers } = product;

  return {
    id,
    type,
    fulfilmentStatus: status,
    localAreaValidity,
    hasMultiplePassengers: passengers.length > 1,
    journeys: [
      {
        validity,
        legs: [
          {
            passengers: [
              {
                tickets: passengers.map((passenger) => {
                  const { farePassengerId, type } = passenger;

                  return {
                    farePassengerId,
                    passengerType: type,
                  };
                }),
              },
            ],
          },
        ],
      },
    ],
  };
};

const normaliseTravelProduct = (product: TravelProduct): NormalisedProduct => ({
  ...product,
  fulfilmentStatus: product.fulfilmentStatus.toLowerCase() as FulfilmentStatus,
});

function mapper(product: Product) {
  if (isInsuranceProduct(product)) return normaliseInsuranceProduct(product);
  if (isRailcardProduct(product)) return normaliseRailCardProduct(product);
  if (isLocalAreaProduct(product)) return normaliseLocalAreaProduct(product);
  if (isSeasonProduct(product)) return normaliseSeasonProduct(product);
  if (isTravelProduct(product)) return normaliseTravelProduct(product);

  return null;
}

export const normaliseProduct = (product: Product) => {
  const normalised = mapper(product);

  if (!normalised) {
    throw new Error(`This product type ${product.type} doesn't exist or is not properly mapped.`);
  }

  return normalised;
};
