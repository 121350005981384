import { Heading } from '@trainline/depot-web';
import { css } from 'aphrodite/no-important';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CurrencyCode, currencyMap } from '@contactcentre-web/utils/currencies';
import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency';

import messages from './messages';
import styles from './styles';

interface Props {
  compensationForm: { totalAmount: number; currencyCode?: CurrencyCode };
  isTerminalDebit: boolean;
}

const TotalAmount = ({ compensationForm, isTerminalDebit }: Props) => (
  <>
    <Heading typeStyle="title3" as="h2" color="inverted">
      <FormattedMessage
        {...(isTerminalDebit ? messages.compensationDebit : messages.compensationCredit)}
      />
    </Heading>
    <div className={css(styles.container)}>
      <Heading typeStyle="hero" as="h3" color="inverted" testId="requestCompensationValue">
        {currencyMap.find((currency) => currency.code === compensationForm.currencyCode)?.symbol}
        <FormattedCurrency amount={compensationForm.totalAmount} />
      </Heading>
    </div>
  </>
);

export default TotalAmount;
