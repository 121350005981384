import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { Link as RouterLink } from 'react-router-dom';
import { StatusMessage } from '@trainline/depot-web';

import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency/FormattedCurrency';
import OrderActions from '@contactcentre-web/order-actions/OrderActions';
import FormattedDateInLondonTz from '@contactcentre-web/common/FormattedDateInLondonTz/FormattedDateInLondonTz';
import FormattedTimeInLondonTz from '@contactcentre-web/common/FormattedTimeInLondonTz/FormattedTimeInLondonTz';
import CopyToClipboard from '@contactcentre-web/common/CopyToClipboard';
import orderActionKeys from '@contactcentre-web/order-actions/OrderActionKeys';
import priceShape from '@contactcentre-web/utils/shape/price';
import Link from '@contactcentre-web/common/Link';

import ConvertedByBookingsAlertContainer from './components/ConvertedByBookingsAlert/ConvertedByBookingsAlert';
import ReplacementBookingsAlert from './components/ReplacementBookingsAlert/ReplacementBookingsAlert';
import ReplacedOrderAlert from './components/ReplacedOrderAlert/ReplacedOrderAlert';
import ConvertedOrderAlertContainer from './components/ConvertedOrderAlert/ConvertedOrderAlert';
import BookingsSection from './components/BookingsSection/BookingsSection';
import TicketsSection from './components/TicketsSection/TicketsSection';
import OrderStatus from './components/OrderStatus';
import LabelWithValue from './components/LabelWithValue/LabelWithValue';
import CancelRailcardStatus from './components/CancelRailcardStatus/CancelRailcardStatus';
import messages from './messages';
import styles from './styles';

const CustomerOrderHeader = ({
  displayOptions: { showOrderSummary = false, actions = {}, showBanners = false } = {},
  customerId,
  customerEmail,
  capitaineUrl,
  orderReference,
  id: orderId,
  bookedVia,
  customerAuthentication,
  createdOn,
  voidable,
  products,
  transactionSummary: { orderTotal },
  passengersByType,
  source,
  hasEuBookings,
  currencyData,
  path,
  status,
  errors,
  bookingIndexConvertedAnyCard,
  cancelRailcardStatus,
  cancelledRailcardName,
  onReloadOrder,
  orderOnlyContainsRailcardProducts,
  orderOnlyContainsSeasonProducts,
  orderContainsSeasonProduct,
  features,
}) => {
  const orderDetailUrl = `/customers/${customerId}/bookings/${orderReference}`;
  const isOrderDetailPage = path === orderDetailUrl;
  const orderReferenceClass = isOrderDetailPage
    ? [styles.orderReference, styles.orderReferenceSelected]
    : styles.orderReference;
  const [actionContainer, setActionContainer] = React.useState();
  const isCustomerTypeVisible = features?.customerType;

  return (
    <section className={css(styles.container)}>
      <div className={css(styles.row, styles.rowHeader)}>
        <dl>
          <dt className={css(styles.label)}>
            <FormattedMessage {...messages.orderId} />
          </dt>
          <dd className={css(styles.orderReferenceContainer)}>
            <RouterLink
              data-test="OrderDetail"
              to={orderDetailUrl}
              className={css(orderReferenceClass)}
            >
              {orderReference}
            </RouterLink>
            <CopyToClipboard value={orderReference} />
          </dd>
          {customerAuthentication && isCustomerTypeVisible && (
            <span className={css(styles.customerAuthentication)} data-testid="customerTypeLabel">
              <FormattedMessage
                {...messages.labelCustomerAuthentication}
                values={{ customerAuthentication }}
              />
            </span>
          )}
        </dl>
        <OrderActions
          orderReference={orderReference}
          customerId={customerId}
          disableOrder={false}
          disableSDV={false}
          disableRefund={false}
          path={path}
          actions={{
            ...actions,
            [orderActionKeys.logCall]: actions[orderActionKeys.logCall] && { customerEmail },
            [orderActionKeys.viewBookings]: actions[orderActionKeys.viewBookings],
            [orderActionKeys.openInCapitaine]:
              actions[orderActionKeys.openInCapitaine] &&
              (capitaineUrl && hasEuBookings
                ? {
                    context: capitaineUrl,
                  }
                : undefined),
            [orderActionKeys.cancelOrder]:
              actions[orderActionKeys.cancelOrder] &&
              (!orderOnlyContainsRailcardProducts
                ? {
                    disabled: !voidable.isVoidable,
                    context: voidable.notVoidableReasons,
                  }
                : undefined),
            [orderActionKeys.refund]:
              actions[orderActionKeys.refund] &&
              (!orderOnlyContainsRailcardProducts
                ? {
                    disabled:
                      source === 'tracs' ||
                      (orderOnlyContainsSeasonProducts &&
                        products &&
                        products[0].externallyModified),
                    target: actionContainer,
                    orderOnlyContainsSeasonProducts,
                  }
                : undefined),
            [orderActionKeys.changeOfJourney]:
              actions[orderActionKeys.changeOfJourney] &&
              (!orderContainsSeasonProduct && !orderOnlyContainsRailcardProducts ? {} : undefined),
            [orderActionKeys.replace]:
              actions[orderActionKeys.replace] &&
              (!orderContainsSeasonProduct && !orderOnlyContainsRailcardProducts
                ? {
                    target: actionContainer,
                  }
                : undefined),
            [orderActionKeys.currencyConverter]:
              actions[orderActionKeys.currencyConverter] &&
              (currencyData &&
              currencyData.inventoryCurrencies.length > 0 &&
              currencyData.ratesBatchId &&
              currencyData.marginsBatchId
                ? {
                    context: currencyData,
                  }
                : undefined),
            [orderActionKeys.resendEmails]: actions[orderActionKeys.resendEmails] && {
              target: actionContainer,
            },
            [orderActionKeys.fulfilmentConversion]:
              actions[orderActionKeys.fulfilmentConversion] &&
              (!orderOnlyContainsRailcardProducts ? {} : undefined),
            [orderActionKeys.refresh]:
              actions[orderActionKeys.refresh] &&
              (!orderContainsSeasonProduct && !orderOnlyContainsRailcardProducts
                ? {
                    target: actionContainer,
                  }
                : undefined),
            [orderActionKeys.fulfilmentConverter]: actions[orderActionKeys.fulfilmentConverter] && {
              target: actionContainer,
            },
            [orderActionKeys.moveOrder]: actions[orderActionKeys.moveOrder] && {
              context: { customerId, orderReference, orderId },
            },
          }}
        />
      </div>
      <div
        ref={(el) => {
          setActionContainer(el);
        }}
      />
      {showBanners && (
        <>
          <div data-test="replaceBookingAlert">
            <ReplacedOrderAlert customerId={customerId} products={products} />
          </div>
          <ConvertedOrderAlertContainer />
          <ConvertedByBookingsAlertContainer />
          <ReplacementBookingsAlert customerId={customerId} products={products} />

          {bookingIndexConvertedAnyCard > -1 && (
            <div className={css(styles.alert)} data-testid="bookingConvertedAnyCard">
              <StatusMessage status="positive">
                <FormattedMessage
                  {...messages.convertedAnyCardSuccess}
                  values={{
                    bookingIndex: bookingIndexConvertedAnyCard + 1,
                    link: (msg) => (
                      <Link
                        linkType="internal"
                        to={`/customers/${customerId}/bookings/${orderReference}/history`}
                      >
                        {msg}
                      </Link>
                    ),
                  }}
                />
              </StatusMessage>
            </div>
          )}
          {source === 'tracs' && (
            <div className={css(styles.notification)}>
              <StatusMessage status="negative">
                <FormattedMessage
                  {...messages.tracsOrderNotification}
                  testId="tracksOrderNotification"
                />
              </StatusMessage>
            </div>
          )}
        </>
      )}
      {showOrderSummary && (
        <div className={css(styles.row)} data-test-id="CustomerOrderHeader.orderSummary">
          <BookingsSection products={products} />
          <TicketsSection passengersByType={passengersByType} />
          <LabelWithValue
            label={<FormattedMessage {...messages.labelCreatedOn} />}
            value={
              <FormattedMessage
                {...messages.createdOn}
                values={{
                  date: (
                    <FormattedDateInLondonTz
                      value={createdOn}
                      year="numeric"
                      month="short"
                      day="numeric"
                      weekday="short"
                    />
                  ),
                  time: (
                    <FormattedTimeInLondonTz value={createdOn} hour="2-digit" minute="2-digit" />
                  ),
                }}
              />
            }
          />
          <LabelWithValue
            label={<FormattedMessage {...messages.labelBookedVia} />}
            value={
              <dl>
                <dt>{bookedVia}</dt>
              </dl>
            }
            containerStyle={styles.bookedViaContainer}
          />
          <div className={css(styles.alignRight)}>
            <LabelWithValue
              label={<FormattedMessage {...messages.total} />}
              value={
                orderTotal && Object.keys(orderTotal).length ? (
                  <span className={css(styles.price)}>
                    <FormattedCurrency {...orderTotal} />
                  </span>
                ) : null
              }
            />
            <OrderStatus status={status} errors={errors} />
          </div>
        </div>
      )}
      {cancelRailcardStatus && (
        <CancelRailcardStatus
          status={cancelRailcardStatus}
          name={cancelledRailcardName}
          onReloadOrder={onReloadOrder}
        />
      )}
    </section>
  );
};

CustomerOrderHeader.propTypes = {
  customerId: PropTypes.string.isRequired,
  customerEmail: PropTypes.string,
  capitaineUrl: PropTypes.string,
  orderReference: PropTypes.string.isRequired,
  bookedVia: PropTypes.string.isRequired,
  customerAuthentication: PropTypes.string.isRequired,
  createdOn: PropTypes.string.isRequired,
  voidable: PropTypes.shape({
    isVoidable: PropTypes.bool,
    notVoidableReasons: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  transactionSummary: PropTypes.shape({
    orderTotal: priceShape.isRequired,
  }).isRequired,
  passengersByType: PropTypes.array.isRequired,
  source: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.object),
  hasLockableBookings: PropTypes.bool.isRequired,
  hasEuBookings: PropTypes.bool.isRequired,
  currencyData: PropTypes.object,
  status: PropTypes.string,
  errors: PropTypes.array,
  path: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  bookingIndexConvertedAnyCard: PropTypes.number,
  cancelRailcardStatus: PropTypes.string,
  cancelledRailcardName: PropTypes.string,
  onReloadOrder: PropTypes.func.isRequired,
  orderOnlyContainsRailcardProducts: PropTypes.bool,
  orderOnlyContainsSeasonProducts: PropTypes.bool,
  orderContainsSeasonProduct: PropTypes.bool,
  displayOptions: PropTypes.shape({
    actions: PropTypes.object,
    showOrderSummary: PropTypes.bool,
    showBanners: PropTypes.bool,
  }),
  features: PropTypes.shape({
    customerType: PropTypes.bool,
  }),
};

export default CustomerOrderHeader;
