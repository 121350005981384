import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import OriginToDestination from '@contactcentre-web/common/OriginToDestination/OriginToDestination';
import StatusIndicator from '@contactcentre-web/common/StatusIndicator';

import { OrderHistoryCard, OrderHistoryCardHeader } from '../OrderHistoryCard/OrderHistoryCard';
import type { DelayRepayClaimItem } from '../../module';

import messages from './messages';
import styles from './styles';

const mapStatusToSyle = {
  Processing: styles.statusIndicatorProcessing,
  Submitted: styles.statusIndicatorProcessing,
  CreditIssueFailure: styles.statusIndicatorProcessing,
  Approved: styles.statusIndicatorApproved,
  PaidByToc: styles.statusIndicatorApproved,
  CreditIssueSuccess: styles.statusIndicatorApproved,
  Rejected: styles.statusIndicatorRejected,
};

const DelayRepayClaim: FunctionComponent<DelayRepayClaimItem> = ({
  createdAt,
  journey: { origin, destination, bookingReferenceId },
  claimId,
  status,
}) => (
  <OrderHistoryCard
    cardTitle={<FormattedMessage {...messages.title} />}
    createdAt={createdAt}
    headerItems={
      <>
        <OrderHistoryCardHeader
          label={<FormattedMessage {...messages.journeyLabel} />}
          value={
            <>
              <OriginToDestination origin={origin} destination={destination} />
              {` (${bookingReferenceId})`}
            </>
          }
        />
        <OrderHistoryCardHeader
          label={<FormattedMessage {...messages.claimLabel} />}
          value={claimId}
        />
        <OrderHistoryCardHeader
          label={<FormattedMessage {...messages.statusLabel} />}
          value={
            <StatusIndicator
              status={
                <FormattedMessage
                  {...((messages as any)[`delayRepayStatus_${status.toLowerCase()}`] ||
                    messages.delayRepayStatus_unknown)}
                  values={{
                    status,
                  }}
                />
              }
              testId="delayRepayItemStatus"
              indicatorStyles={mapStatusToSyle[status]}
            />
          }
          testId="status"
        />
      </>
    }
  />
);

export default DelayRepayClaim;
