import { defineMessages } from 'react-intl';

export default defineMessages({
  editUser: {
    id: 'ChangeRole.editUser',
    defaultMessage: 'Edit user - {username}',
  },
  cancel: {
    id: 'ChangeRole.cancel',
    defaultMessage: 'Cancel',
  },
});
