import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'stretch',
  },
  feesSection: {
    display: 'inherit',
  },
});
