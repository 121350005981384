import { StyleSheet } from 'aphrodite/no-important';
import { palettes, fonts, typography, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    backgroundColor: palettes.dp_app_backdrop_dark,
    borderBottom: `1px solid ${palettes.dp_app_border_base}`,
    padding: spacers.dp_s24,
  },
  headerLabel: {
    color: palettes.dp_app_text_subdued,
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    fontWeight: fonts.dp_font_weight_semibold,
    textTransform: 'uppercase',
    marginBottom: spacers.dp_s8,
  },
  bookingContainer: {
    maxWidth: '30%',
  },
  headerText: {
    color: palettes.dp_app_text_base,
  },
  bold: {
    fontWeight: fonts.dp_font_weight_bold,
  },
  ticketTypes: {
    listStyleType: 'none',
  },
});
