import React, { Dispatch, FunctionComponent, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Heading } from '@trainline/depot-web';

import ErrorBoundary from '@contactcentre-web/common/ErrorBoundary/ErrorBoundary';
import PageContainer from '@contactcentre-web/common/PageContainer/PageContainer';
import Loader from '@contactcentre-web/common/Loader';
import type Action from '@contactcentre-web/redux-common/types/Action';
import type State from '@contactcentre-web/redux-common/types/State';
import { getLocale } from '@contactcentre-web/authentication/redux/profile/selectors';

import BookingItem from './components/BookingItem/BookingItem';
import styles from './styles';
import messages from './messages';
import { selectors, actions, COJBooking } from './module';

interface StateProps {
  bookings: COJBooking[];
  isLoading: boolean;
  hasFailed: boolean;
  locale: string;
}

interface DispatchProps {
  loadEligibility: (customerId: string, orderReference: string) => void;
}

type Params = {
  customerId: string;
  orderId: string;
};

export const ChangeOfJourney: FunctionComponent<StateProps & DispatchProps> = ({
  loadEligibility,
  isLoading,
  bookings,
  hasFailed,
  locale,
}) => {
  const { customerId, orderId } = useParams<Params>();
  const loadEligibiltyRef = useRef(loadEligibility);

  useEffect(() => {
    loadEligibiltyRef.current(customerId, orderId);
  }, [customerId, orderId, loadEligibiltyRef]);

  if (isLoading) {
    return (
      <div className={css(styles.loaderContainer)}>
        <Loader />
      </div>
    );
  }

  return (
    <ErrorBoundary>
      <PageContainer styleSheet={styles.changeOfJourney}>
        <div className={css(styles.header)}>
          <Heading typeStyle="title2" as="h2" color="base">
            <FormattedMessage {...messages.title} />
          </Heading>
        </div>
        {!hasFailed &&
          bookings.map((booking, index) => (
            <BookingItem locale={locale} booking={booking} bookingNo={index + 1} key={index} />
          ))}
      </PageContainer>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: State) => ({
  bookings: selectors.getBookings(state),
  isLoading: selectors.isLoading(state),
  hasFailed: selectors.hasFailed(state),
  locale: getLocale(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  loadEligibility: (customerId: string, orderReference: string) =>
    dispatch(actions.loadEligibility(customerId, orderReference)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeOfJourney);
