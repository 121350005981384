/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { StyleDeclarationValue, css } from 'aphrodite/no-important';
import React, { ReactNode, useState } from 'react';
import { IconChevronDown, IconChevronUp } from '@trainline/depot-web';
import { FormattedMessage } from 'react-intl';

import styles from './styles';

type AccordionProps = {
  setAccordionOpen?: boolean;
  heading: any;
  styleSheet?: StyleDeclarationValue;
  testId?: string;
  children?: ReactNode;
  tabIndex?: number;
};
const Accordion = ({
  setAccordionOpen,
  heading,
  styleSheet,
  testId,
  tabIndex,
  children,
}: AccordionProps) => {
  const [displayContentPanel, setDisplayContentPanel] = useState(setAccordionOpen);
  return (
    <>
      <div
        onClick={() => setDisplayContentPanel(!displayContentPanel)}
        className={css([styles.accordionContainer, styleSheet])}
        data-test-id={testId}
        role="button"
        tabIndex={tabIndex}
        aria-expanded={setAccordionOpen}
      >
        <p className={css([styles.heading, styleSheet])}>
          <FormattedMessage {...heading} />
        </p>

        {displayContentPanel ? <IconChevronUp /> : <IconChevronDown />}
      </div>
      {displayContentPanel && (
        <div
          id="accordion-content"
          tabIndex={tabIndex}
          className={css([styles.contentContainer, styleSheet])}
        >
          {children}
        </div>
      )}
    </>
  );
};

export default Accordion;
