import request from '@contactcentre-web/utils/request';
import type ProductType from '@contactcentre-web/redux-common/types/ProductType';
import type Price from '@contactcentre-web/redux-common/types/Price';

export const searchApiUrl = '/api/search/v3';

export interface SearchParams {
  query?: string;
  pnr?: string;
}

export enum SearchResultType {
  Product = 'Product',
  Customer = 'Customer',
  Capitaine = 'Product25kv',
  LegacyProduct = 'LegacyProduct',
  ProductReference = 'ProductReference',
}

export interface ProductReferenceItem {
  friendlyOrderId: string;
  managedGroupNumber: number;
}

export interface ProductSearchResultItem {
  type: ProductType;
  customerId: string;
  from: string;
  to: string;
  isReturn: boolean;
  orderReference: string;
  inventoryReference: string;
  orderDate: Date;
  departureDate: Date | null;
  price: Price;
  bookingStatus: string;
  name?: string;
}

export interface LatestBooking {
  orderDate: Date;
  from: string;
  to: string;
  isReturn: boolean;
  departureDate: Date;
  bookedVia: string;
  orderReference?: string;
  error: boolean;
}

export interface CustomerSearchResultItem {
  id: string;
  forename: string;
  surname: string;
  email: string;
  address: {
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    addressLine4: string;
    addressLine5: string;
    postcode: string;
    countryCode: string;
  };
  phoneNumbers: Array<{
    label: string;
    value: string;
  }>;
  lastActive: { accessMethod: string; time: Date };
  latestBooking: LatestBooking;
}

export type LegacySystem = 'Capitaine' | 'Tracs';

export interface LegacyProductResultItem {
  uri: string;
  legacySystem: LegacySystem;
}

export type SearchResultsItem = ProductSearchResultItem &
  CustomerSearchResultItem &
  LegacyProductResultItem &
  ProductReferenceItem;

export interface SearchResults {
  totalCount: number;
  searchResultType: SearchResultType;
  items: Array<SearchResultsItem>;
  errors?: unknown;
}

export const search = (searchParams: SearchParams) =>
  request<SearchResults>(searchApiUrl, {
    params: {
      query: searchParams.query,
      pnr: searchParams.pnr,
    },
  });
