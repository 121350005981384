import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

interface Props {
  length?: number;
  index: number;
  textComponent: JSX.Element;
}

const RefundPayment: FunctionComponent<Props> = ({ length, index, textComponent }) => (
  <div>
    <FormattedMessage
      {...(length && length > 1 ? messages.payments : messages.payment)}
      values={{
        index: index + 1,
        text: textComponent,
      }}
    />
  </div>
);

export default RefundPayment;
