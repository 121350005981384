import React from 'react';

interface Props {
  className?: string;
}

const IconDownload = ({ className = '' }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.25 13.9393L11.25 4.55C11.25 4.10817 11.5858 3.75 12 3.75C12.4142 3.75 12.75 4.10817 12.75 4.55L12.75 13.9393L14.4697 12.2197C14.7626 11.9268 15.2374 11.9268 15.5303 12.2197C15.8232 12.5126 15.8232 12.9874 15.5303 13.2803L12.5303 16.2803C12.2374 16.5732 11.7626 16.5732 11.4697 16.2803L8.46967 13.2803C8.17678 12.9874 8.17678 12.5126 8.46967 12.2197C8.76256 11.9268 9.23744 11.9268 9.53033 12.2197L11.25 13.9393Z"
      fill="#192325"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 10.75C3.91421 10.75 4.25 11.0858 4.25 11.5V18C4.25 18.4142 4.58579 18.75 5 18.75H19C19.4142 18.75 19.75 18.4142 19.75 18V11.5C19.75 11.0858 20.0858 10.75 20.5 10.75C20.9142 10.75 21.25 11.0858 21.25 11.5V18C21.25 19.2426 20.2426 20.25 19 20.25H5C3.75736 20.25 2.75 19.2426 2.75 18V11.5C2.75 11.0858 3.08579 10.75 3.5 10.75Z"
      fill="#192325"
    />
  </svg>
);

export default IconDownload;
