import React, { FunctionComponent, ReactText } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import NotificationBox from '@contactcentre-web/common/NotificationBox/NotificationBox';

export interface RevocationNotificationProps {
  type: NotificationType;
  messages: MessageDescriptor[];
  values: Record<string, ReactText>;
}
export enum NotificationType {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
}

const RevocationNotification: FunctionComponent<RevocationNotificationProps> = ({
  type,
  messages,
  values,
}) => (
  <NotificationBox type={type}>
    {messages.map((message) => (
      <div key={message.id}>
        <FormattedMessage id={message.id} defaultMessage={message.defaultMessage} values={values} />
      </div>
    ))}
  </NotificationBox>
);

export default RevocationNotification;
