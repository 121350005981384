import React, { FunctionComponent } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { Paragraph } from '@trainline/depot-web';

import Button from '@contactcentre-web/common/Button';

import BookingHeader from '../BookingHeader/BookingHeader';
import BookingNotChangeableReason from '../BookingNotChangeableReason/BookingNotChangeableReason';
import { COJBooking } from '../../module';

import styles from './styles';
import messages from './messages';

interface Props {
  booking: COJBooking;
  bookingNo: number;
  locale: string;
}

const BookingItem: FunctionComponent<Props> = ({ booking, bookingNo, locale }) => {
  const ticketTypesValues: number[] = Object.values(booking.ticketsByType);
  const isSingleTicket = !(ticketTypesValues.length > 1 || ticketTypesValues?.[0] > 1);

  const strippedLocale = locale?.substring(0, 2) || 'en';

  return (
    <div className={css(styles.bookingItem)}>
      <div className={css(styles.row)}>
        <BookingHeader booking={booking} bookingNo={bookingNo} />
      </div>
      <div className={css(styles.row, styles.rowLast, styles.ticketDetails)}>
        <div>
          <div className={css(styles.ticketsTitle)}>
            <Paragraph typeStyle="body" as="p" color="base" fontWeight="bold">
              <FormattedMessage {...messages.ticketsTitle} values={{ isSingleTicket }} />
            </Paragraph>
          </div>
          <ul className={css(styles.ticketList)}>
            {Object.keys(booking.ticketsByType).map((type) => (
              <li key={type} className={css(styles.ticketType)}>
                <FormattedMessage
                  {...messages.ticketTypes}
                  values={{ ticketType: type.toLowerCase(), count: booking.ticketsByType[type] }}
                />
              </li>
            ))}
          </ul>
        </div>
        <div className={css(styles.buttonContainer)}>
          <Button
            variant="primary"
            size="large"
            disabled={!booking.changeability.changeable}
            onClick={() =>
              window.open(`${booking.changeability.changeUrl}&lang=${strippedLocale}`, '_self')
            }
          >
            <FormattedMessage
              {...messages.changeButton}
              values={{ isChangeable: booking.changeability.changeable }}
            />
          </Button>
          {!booking.changeability.changeable && booking.changeability.reason && (
            <BookingNotChangeableReason reasonCode={booking.changeability.reason} />
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingItem;
