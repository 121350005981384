import React, { MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from '@contactcentre-web/common/Modal';
import Button from '@contactcentre-web/common/Button';

import messages from './messages';

interface Props {
  toggleModal: () => void;
  name: string;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  isCancelling: boolean;
}

const CancelRailcardModal = ({ toggleModal, name, onCancel, isCancelling }: Props) => (
  <Modal onClose={toggleModal}>
    <ModalHeader>
      <ModalTitle>
        <FormattedMessage {...messages.cancelRailcardTitle} values={{ railcardName: name }} />
      </ModalTitle>
    </ModalHeader>
    <ModalBody>
      <FormattedMessage {...messages.cancelRailcardDescription} values={{ br: <br /> }} />
    </ModalBody>
    <ModalFooter contentAlignment="spaceBetween">
      <Button variant="tertiary" size="large" onClick={toggleModal}>
        <FormattedMessage {...messages.goBack} />
      </Button>
      <Button
        variant="destructive"
        size="large"
        onClick={onCancel}
        loading={isCancelling}
        testId="confirm-cancel-railcard"
      >
        <FormattedMessage {...messages.confirmCancel} />
      </Button>
    </ModalFooter>
  </Modal>
);

export default CancelRailcardModal;
