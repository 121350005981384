import { compose, flatten, path, pluck, uniqBy, all, any } from 'ramda';

import {
  Journey,
  Leg,
  Passenger as LegPassenger,
} from '@contactcentre-web/redux-common/types/Journey';
import { Product } from '@contactcentre-web/redux-common/types/Product';
import { InsuranceProduct } from '@contactcentre-web/redux-common/types/InsuranceProduct';
import { RailcardProduct } from '@contactcentre-web/redux-common/types/RailcardProduct';
import { LocalAreaProduct } from '@contactcentre-web/redux-common/types/LocalAreaProduct';
import { SeasonProduct } from '@contactcentre-web/redux-common/types/SeasonProduct';
import { TravelProduct } from '@contactcentre-web/redux-common/types/TravelProduct';
import { Order } from '@contactcentre-web/redux-common/types/Order';
import ProductType from '@contactcentre-web/redux-common/types/ProductType';
import FulfilmentStatus from '@contactcentre-web/redux-common/types/FulfilmentStatus';

import {
  Quote,
  Passenger,
  PassengerRefundable,
  TermsAndConditionsRefundableTicket,
  RefundableTicketMapper,
  RefundableTicket,
  JourneyWithRefundability,
} from './types';

export const extractQuoteUnprocessableReasons = (quote: Quote) =>
  (quote.unprocessableReasons || []).map(({ reasonCode, ...rest }) => ({
    reasonCode: /^[^.]+\.(.+)$/i.exec(reasonCode as string)?.[1],
    ...rest,
  }));

export const extractValidationErrors = (error: { validationErrors: { code: string }[] }) =>
  error.validationErrors.map(({ code }) => /^[^.]+\.(.+)$/i.exec(code)?.[1]).filter(Boolean);

export const isFraudNonProcessableQuote = (quote?: Quote) =>
  ['refundrejectedbyfraudprovider', 'lessthanzero'].includes(
    quote?.unprocessableReasons?.[0]?.reasonCode?.toLowerCase() as string
  );

export const isFraudNonProcessable = (quote: Quote | undefined, approveRefund: any) =>
  quote?.unprocessableReasons?.[0]?.reasonCode?.toLowerCase() === 'refundrejectedbyfraudprovider' ||
  approveRefund?.unprocessableReasons?.[0]?.reasonCode?.toLowerCase() ===
    'refundrejectedbyfraudprovider';

export const isFraudNonProcessableQuoteOverride = (quote?: Quote) =>
  !!quote && isFraudNonProcessableQuote(quote) && quote.unprocessableReasons[0].overridden === true;

export const extractUsedTicketRefundables = (quote?: Quote) => {
  if (!quote || !quote.unprocessableReasons) {
    return undefined;
  }

  const splitTicketsReasons = quote.unprocessableReasons.find(
    ({ reasonCode }) => reasonCode?.toLowerCase() === 'partlyUsedSplitTicket'.toLowerCase()
  );
  return splitTicketsReasons && splitTicketsReasons.refundableIds;
};

const quoteConditionHasTravelCancellation = (condition: Quote['quoteConditions'][0]) =>
  condition.reason?.code?.startsWith('containsTravelCancellation');

export const getRefundReason = (
  reasons: { id: string; name: string; description: string }[],
  { travelBookings }: Order,
  quote: Quote
) => {
  const [adminFeeReason, cancelledReason, travelCancellationReason] = reasons;
  const hasCancelledJourney = travelBookings.some(
    (travelBooking) => travelBooking.fulfilmentStatus.toLowerCase() === FulfilmentStatus.Cancelled
  );

  if (hasCancelledJourney) {
    return cancelledReason.id;
  }

  const hasTravelCancellation =
    quote &&
    !!quote.quoteConditions?.find((condition) => quoteConditionHasTravelCancellation(condition));

  if (hasTravelCancellation) {
    return travelCancellationReason.id;
  }

  return adminFeeReason.id;
};

export const isQuoteProcessable = (quote?: Quote) =>
  !quote ||
  !quote.unprocessableReasons ||
  quote.unprocessableReasons.length === 0 ||
  isFraudNonProcessableQuote(quote);

export const isRefundableNonProcessable = (quote: undefined | Quote, refundableId: string) =>
  quote && quote.unprocessableRefundables && quote.unprocessableRefundables.includes(refundableId);

export const passengersExtractor = compose<
  Journey,
  Leg[],
  LegPassenger[][],
  LegPassenger[],
  LegPassenger[]
>(
  uniqBy<LegPassenger, string[]>((p) => p.farePassengerIds),
  flatten,
  pluck('passengers'),
  path<Leg[]>(['legs']) as (x: Journey) => Leg[]
);

export const getFarePassengers = <
  AnyTicket extends { farePassengerId: string; type?: string },
  AnyRefundableTicket
>(
  passengers: Passenger<AnyTicket>[],
  passengerRefundables: PassengerRefundable[],
  ticketMapper: (props: RefundableTicketMapper) => AnyRefundableTicket,
  quote?: Quote
) => {
  if (!passengerRefundables) {
    return {
      farePassengers: [] as Passenger<AnyTicket & AnyRefundableTicket>[],
      canProcess: false,
    };
  }

  let canProcess = false;

  const mappedFarePassengers = passengerRefundables
    .map(
      ({
        farePassengers,
        refundableId,
        selectability,
        reason,
        reasonDetail,
        status,
        allowedEntanglementCombinations,
        entanglementEnforcementMode,
      }) => {
        const associatedPax = passengers
          .map(({ tickets, ...passenger }) => ({
            ...passenger,
            tickets: tickets
              .filter((ticket) => farePassengers.find((fp) => fp === ticket.farePassengerId))
              .map(
                (ticket) =>
                  ({
                    ...ticket,
                    ...ticketMapper({
                      quote,
                      status,
                      refundableId,
                      selectability,
                      reason,
                      reasonDetail,
                      allowedEntanglementCombinations,
                      entanglementEnforcementMode,
                      fareType: ticket.type,
                    }),
                  } as AnyTicket & AnyRefundableTicket)
              ),
          }))
          .filter((passenger) => passenger.tickets && passenger.tickets.length > 0);

        if (associatedPax.length === 0) {
          return undefined;
        }

        canProcess =
          canProcess ||
          (!!selectability && (selectability.selectedByDefault || selectability.selectable));

        return associatedPax[0];
      }
    )
    .filter(Boolean);

  return {
    farePassengers: mappedFarePassengers,
    canProcess: isQuoteProcessable(quote) && canProcess,
  };
};

export const getFriendlyTicketIdMap = (
  journeys: { farePassengers: Passenger<TermsAndConditionsRefundableTicket>[] }[]
) => {
  let friendlyTicketIndex = 0;
  const map: Record<string, number> = {};

  journeys.forEach((journey) => {
    journey.farePassengers.forEach((farePassenger) => {
      farePassenger.tickets.forEach((ticket) => {
        friendlyTicketIndex += 1;
        map[ticket.refundableId] = friendlyTicketIndex;
      });
    });
  });
  return map;
};

export const addFriendlyTicketIndexes = (
  journeys: { farePassengers: Passenger<TermsAndConditionsRefundableTicket>[] }[],
  friendlyTicketIndexMapping: Record<string, number>
) =>
  journeys.map((journey) => ({
    ...journey,
    farePassengers: journey.farePassengers.map((farePassenger) => ({
      ...farePassenger,
      tickets: farePassenger.tickets.map((ticket) => ({
        ...ticket,
        friendlyTicketIndexes: [friendlyTicketIndexMapping[ticket.refundableId]].filter(Boolean),
      })),
    })),
  }));

type Refundable = {
  productId: string;
  entanglements: [];
  chargedFees: [];
  fees: [];
  insuranceRefundables: [];
  passengerRefundables: PassengerRefundable[];
  railcardRefundables: [];
};

const createEmptyRefundable = () =>
  ({
    insuranceRefundables: [],
    passengerRefundables: [],
    railcardRefundables: [],
    entanglements: [],
    chargedFees: [],
    fees: [],
  } as Omit<Refundable, 'productId'>);

export const getRefundableProduct = (id: string, refundables: Refundable[]) => ({
  ...createEmptyRefundable(),
  ...refundables.find(({ productId }) => productId === id),
});

export const getRefundableLocalAreaProduct = (
  localAreaProducts: LocalAreaProduct[],
  refundables: Refundable[]
) => {
  const localAreaProductIds = localAreaProducts.map((localAreaProduct) => localAreaProduct.id);

  return refundables
    .filter(({ productId }) => localAreaProductIds.includes(productId))
    .reduce(
      (
        acc,
        {
          insuranceRefundables = [],
          passengerRefundables = [],
          railcardRefundables = [],
          entanglements = [],
          chargedFees = [],
          fees = [],
        }
      ) =>
        ({
          insuranceRefundables: acc.insuranceRefundables.concat(insuranceRefundables),
          passengerRefundables: acc.passengerRefundables.concat(passengerRefundables),
          railcardRefundables: acc.railcardRefundables.concat(railcardRefundables),
          entanglements: acc.entanglements.concat(entanglements),
          chargedFees: acc.chargedFees.concat(chargedFees),
          fees: acc.fees.concat(fees),
        } as Omit<Refundable, 'productId'>),
      createEmptyRefundable()
    );
};

const farePassengersExtractor = compose<
  { farePassengers: Passenger<RefundableTicket>[] }[],
  Passenger<RefundableTicket>[][],
  Passenger<RefundableTicket>[]
>(flatten, pluck('farePassengers'));

export const isPartialRefundEntangled = (
  refundJourneys: { farePassengers: Passenger<RefundableTicket>[] }[]
) => {
  const mappedFarePassengers = farePassengersExtractor(refundJourneys);
  const tickets = compose<Passenger<RefundableTicket>[], RefundableTicket[][], RefundableTicket[]>(
    flatten,
    pluck('tickets')
  )(mappedFarePassengers);

  return (
    any((ticket) => ticket.refundable, tickets) &&
    all((ticket) => !ticket.isEligible && !ticket.isChangeable, tickets)
  );
};

export const applySplitSaveFixToRefundJourneys = (
  journeys: JourneyWithRefundability[],
  passengerRefundables: PassengerRefundable[]
) => {
  const refundableIds = compose<PassengerRefundable[], PassengerRefundable[], string[]>(
    pluck('refundableId'),
    uniqBy((x) => x.refundableId)
  )(passengerRefundables);

  return journeys
    .map((journey) => ({
      ...journey,
      farePassengers: journey.farePassengers.map((farePassenger) => ({
        ...farePassenger,
        tickets: farePassenger.tickets.filter(({ refundableId }) => {
          const refundableIdIndex = refundableIds.findIndex((r) => r === refundableId);
          if (refundableIdIndex === -1) {
            return false;
            // Removed passengerRefundable duplicated by return ticket in a split save, e.g.
            // Return travel from A to C with split in B, with a return fare between A and B
            // has 2 journeys (A -> B and B -> A) with a shared fare (A <-> C) between them
          }
          refundableIds.splice(refundableIdIndex, 1);
          return true;
        }),
      })),
    }))
    .filter((journey) => journey.farePassengers.length > 0);
};
export function isInsuranceProduct(product: Product): product is InsuranceProduct {
  return product.type === ProductType.Insurance;
}

export function isRailcardProduct(product: Product): product is RailcardProduct {
  return product.type === ProductType.Railcard;
}

export function isLocalAreaProduct(product: Product): product is LocalAreaProduct {
  return product.type === ProductType.Travelcard;
}

export function isSeasonProduct(product: Product): product is SeasonProduct {
  return product.type === ProductType.Season;
}

export function isTravelProduct(product: Product): product is TravelProduct {
  return product.type === ProductType.TravelProduct;
}
