import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from '@contactcentre-web/common/Modal';
import Button from '@contactcentre-web/common/Button';
import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency';
import type Currency from '@contactcentre-web/redux-common/types/Price';

import messages from './messages';

export type RefundActions = 'approve' | 'reject';

interface Props {
  total: Currency;
  onProcess: () => void;
  isProcessing: boolean;
  onClose: () => void;
  action: RefundActions;
}

export const RefundActionConfirm: FunctionComponent<Props> = ({
  total,
  onProcess,
  isProcessing,
  onClose,
  action,
}) => (
  <Modal onClose={onClose}>
    <ModalHeader>
      <ModalTitle>
        <FormattedMessage {...messages[`header_${action}`]} />
      </ModalTitle>
    </ModalHeader>
    <ModalBody>
      <FormattedMessage
        {...messages[`body_${action}`]}
        values={{
          total: <FormattedCurrency {...total} />,
        }}
      />
    </ModalBody>
    <ModalFooter contentAlignment="spaceBetween">
      <Button variant="tertiary" size="large" onClick={onClose}>
        <FormattedMessage {...messages.cancel} />
      </Button>
      <Button variant="primary" size="large" loading={!!isProcessing} onClick={onProcess}>
        <FormattedMessage {...messages[`confirm_${action}`]} />
      </Button>
    </ModalFooter>
  </Modal>
);

export default RefundActionConfirm;
