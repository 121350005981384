import { StyleSheet } from 'aphrodite/no-important';
import { borderRadius, palettes, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    margin: `${spacers.dp_s24} 0 ${spacers.dp_s16} 0`,
    padding: spacers.dp_s32,
    backgroundColor: palettes.dp_comp_message_info_background,
    border: `solid 1px ${palettes.dp_comp_message_info_border}`,
    borderRadius: borderRadius.dp_r4,
  },
  title: {
    color: palettes.dp_app_text_base,
    paddingBottom: spacers.dp_s16,
    textAlign: 'center',
  },
});
