import React, { useRef, Dispatch, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { Heading, StatusMessage } from '@trainline/depot-web';

import Loader from '@contactcentre-web/common/Loader';
import Link from '@contactcentre-web/common/Link';
import Button from '@contactcentre-web/common/Button';
import PageContainer from '@contactcentre-web/common/PageContainer';
import type State from '@contactcentre-web/redux-common/types/State';
import { currentManagedGroupHasSsoProvider } from '@contactcentre-web/authentication/redux/selectors';
import type Action from '@contactcentre-web/redux-common/types/Action';
import TextField from '@contactcentre-web/common/TextField';
import { validateEmail } from '@contactcentre-web/utils/validation/validators';
import Checkbox from '@contactcentre-web/common/Checkbox';

import { actions, selectors, EmailError } from '../../module';

import messages from './messages';
import styles from './styles';

export const FORM_ID = 'changeEmail';

type ChangeEmailFormData = {
  email: string;
  enableSso: boolean;
};

interface Params {
  username: string;
}

interface StateProps {
  isChangeEmailPending: boolean;
  isChangeEmailSuccess: boolean;
  isUserLoaded: boolean;
  isLoadUserPending: boolean;
  userId: string;
  email: string;
  username?: string | null;
  changeEmailError?: EmailError | null;
  currentManagedGroupHasSsoProvider: boolean;
  isFromSsoProvider: boolean;
}

interface DispatchProps {
  changeEmail: (userId: string, email: string, enableSso: boolean) => void;
  loadUser: (username: string) => void;
  resetState: () => void;
}

type Props = StateProps & DispatchProps;

export const ChangeEmail: FunctionComponent<
  Props & InjectedFormProps<ChangeEmailFormData, Props>
> = ({
  changeEmail,
  isChangeEmailPending,
  isChangeEmailSuccess,
  handleSubmit,
  loadUser,
  isUserLoaded,
  isLoadUserPending,
  resetState,
  userId,
  username,
  email,
  changeEmailError,
  currentManagedGroupHasSsoProvider,
  isFromSsoProvider,
  initialize,
}) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const currentUsername = username || useParams<Params>().username;

  const resetStateRef = useRef(resetState);

  React.useEffect(() => {
    resetStateRef.current();
    return () => resetStateRef.current();
  }, [resetStateRef]);

  React.useEffect(() => {
    if (!isUserLoaded) {
      loadUser(currentUsername);
    }
  }, [isUserLoaded]);

  React.useEffect(() => {
    initialize({ email, enableSso: isFromSsoProvider });
  }, [email, isFromSsoProvider]);

  React.useEffect(() => {
    if (isChangeEmailSuccess) {
      history.push(`/edit_user/${currentUsername}?successfulAction=changeEmail`);
    }
  }, [isChangeEmailSuccess]);

  return (
    <PageContainer>
      {isLoadUserPending ? (
        <div className={css(styles.container)}>
          <Loader />
        </div>
      ) : (
        <form
          id={FORM_ID}
          onSubmit={handleSubmit(({ email, enableSso }) => changeEmail(userId, email, enableSso))}
        >
          <div className={css(styles.container)}>
            <div className={css(styles.innerContainer)}>
              <div className={css(styles.spacer)}>
                <Heading typeStyle="title1" as="h1" color="base">
                  <FormattedMessage
                    {...messages.editUser}
                    values={{
                      username: <span className={css(styles.username)}>{currentUsername}</span>,
                    }}
                  />
                </Heading>
              </div>
              {changeEmailError && messages[changeEmailError] && (
                <div className={css(styles.spacer)}>
                  <StatusMessage status="negative">
                    <FormattedMessage {...messages[changeEmailError]} />
                  </StatusMessage>
                </div>
              )}
              <TextField name="email" messages={messages} />
              <Checkbox
                name="enableSso"
                id="enableSso"
                label={formatMessage(messages.enableSso)}
                disabled={isFromSsoProvider || !currentManagedGroupHasSsoProvider}
              />
              <Button
                type="submit"
                variant="primary"
                size="xlarge"
                fullWidth
                loading={isChangeEmailPending}
                testId="change-test-confirm-button"
                styleSheet={styles.button}
              >
                <FormattedMessage {...messages.update} />
              </Button>
            </div>
            <div className={css(styles.cancel)}>
              <Link linkType="internal" to={`/edit_user/${currentUsername}`}>
                <FormattedMessage {...messages.cancel} />
              </Link>
            </div>
          </div>
        </form>
      )}
    </PageContainer>
  );
};

const mapStateToProps = (state: State) => ({
  isChangeEmailPending: selectors.isChangeEmailPending(state),
  isChangeEmailSuccess: selectors.isChangeEmailSuccess(state),
  isFromSsoProvider: selectors.isFromSsoProvider(state),
  isLoadUserPending: selectors.isLoadUserPending(state),
  isUserLoaded: selectors.isUserLoaded(state),
  email: selectors.getUserEmail(state),
  username: selectors.getUsername(state),
  userId: selectors.getUserId(state),
  changeEmailError: selectors.getChangeEmailError(state),
  currentManagedGroupHasSsoProvider: currentManagedGroupHasSsoProvider(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  loadUser: (username: string) => dispatch(actions.loadUserAttempt(username)),
  changeEmail: (userId: string, email: string, enableSso: boolean) =>
    dispatch(actions.changeEmailAttempt(userId, email, enableSso)),
  resetState: () => dispatch(actions.resetState()),
});

export const validate = ({ email }: ChangeEmailFormData) => {
  const errors = {} as Record<keyof ChangeEmailFormData, string>;
  errors.email = !email ? 'emailIsRequired' : !validateEmail(email) ? 'emailIsInvalid' : '';

  return errors;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<ChangeEmailFormData, Props>({
    form: FORM_ID,
    validate,
    destroyOnUnmount: false,
  })(ChangeEmail)
);
