import { defineMessages } from 'react-intl';

export default defineMessages({
  notAvailable: {
    id: 'TicketSummary.notAvailable',
    defaultMessage: 'N/A',
  },
  notUsedStatus: {
    id: 'TicketSummary.notUsedStatus',
    defaultMessage: 'Not used',
  },
  scannedStatus: {
    id: 'TicketSummary.scannedStatus',
    defaultMessage: 'Scanned',
  },
  cancelledStatus: {
    id: 'TicketSummary.cancelledStatus',
    defaultMessage: 'Cancelled',
  },
  activeStatus: {
    id: 'TicketSummary.activeStatus',
    defaultMessage: 'Active',
  },
  unavailableStatus: {
    id: 'TicketSummary.unavailableStatus',
    defaultMessage: 'Unavailable',
  },
  fulfillingStatus: {
    id: 'TicketSummary.fulfillingStatus',
    defaultMessage: 'Fulfilling',
  },
  fulfilledStatus: {
    id: 'TicketSummary.fulfilledStatus',
    defaultMessage: 'Fulfilled',
  },
  readyForFetchStatus: {
    id: 'TicketSummary.readyForFetchStatus',
    defaultMessage: 'Ready for fetch',
  },
  failedStatus: {
    id: 'TicketSummary.failedStatus',
    defaultMessage: 'Failed',
  },
  availableStatus: {
    id: 'TicketSummary.availableStatus',
    defaultMessage: 'Available',
  },
  downloadedStatus: {
    id: 'TicketSummary.downloadedStatus',
    defaultMessage: 'Downloaded',
  },
  activatedStatus: {
    id: 'TicketSummary.activatedStatus',
    defaultMessage: 'Activated',
  },
  refundRequestedStatus: {
    id: 'TicketSummary.refundRequestedStatus',
    defaultMessage: 'Refund requested',
  },
  ticketNumber: {
    id: 'TicketSummary.ticketNumber',
    defaultMessage: 'Ticket {ticketNumber}',
  },
  utnNumber: {
    id: 'TicketSummary.utnNumber',
    defaultMessage: 'UTN: {vendorId}',
  },
  internalStatus: {
    id: 'TicketSummary.internalStatus',
    defaultMessage: 'External status: {internalStatus}',
  },
  noScans: {
    id: 'TicketSummary.noScans',
    defaultMessage: 'No scans',
  },
  tlStatus: {
    id: 'TicketSummary.tlStatus',
    defaultMessage: 'TL status: {tlStatus}',
  },
  scanNumber: {
    id: 'TicketSummary.scanNumber',
    defaultMessage: 'Scan {scanNumber}',
  },
  scanTime: {
    id: 'TicketSummary.scanTime',
    defaultMessage: 'Scan date and time',
  },
  actionText: {
    id: 'TicketSummary.scanAction',
    defaultMessage: 'Action',
  },
  reasonText: {
    id: 'TicketSummary.scanReason',
    defaultMessage: 'Reason',
  },
  device: {
    id: 'TicketSummary.device',
    defaultMessage: 'Ticket validator',
  },
  scanMode: {
    id: 'TicketSummary.scanMode',
    defaultMessage: 'Scan mode',
  },
  scanStatus: {
    id: 'TicketSummary.scanStatus',
    defaultMessage: 'Scan status',
  },
  location: {
    id: 'TicketSummary.location',
    defaultMessage: 'Location',
  },
  utn: {
    id: 'TicketSummary.utn',
    defaultMessage: 'UTN',
  },
});
