import { defineMessages } from 'react-intl';

export default defineMessages({
  processButtonText: {
    id: 'RefundModal.processButtonText',
    defaultMessage: 'Process',
  },
  cancelButtonText: {
    id: 'RefundModal.cancelButtonText',
    defaultMessage: 'Cancel',
  },
});
