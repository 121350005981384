import React from 'react';
import { FormattedMessage } from 'react-intl';

import BookingDetail from '../BookingDetail/BookingDetail';
import { OrderHistoryCard, OrderHistoryCardHeader } from '../OrderHistoryCard/OrderHistoryCard';
import type { FlexiSeasonItem } from '../../module';

import messages from './messages';

export type FlexiSeasonType = 'FlexiSeasonReset' | 'FlexiSeasonCancel';

interface Props extends FlexiSeasonItem {
  type: FlexiSeasonType;
}

const FlexiSeason = ({ type, createdAt, bookings, agent }: Props) => (
  <OrderHistoryCard
    createdAt={createdAt}
    cardTitle={<FormattedMessage {...messages[type]} />}
    headerItems={
      <>
        <OrderHistoryCardHeader
          label={<FormattedMessage {...messages.booking} />}
          value={<BookingDetail bookings={bookings} />}
        />
        <OrderHistoryCardHeader
          label={<FormattedMessage {...messages.requestedBy} />}
          value={agent}
        />
      </>
    }
  />
);

export default FlexiSeason;
