import { css } from 'aphrodite/no-important';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Heading } from '@trainline/depot-web';

import type State from '@contactcentre-web/redux-common/types/State';

import { selectors, SuccessContext } from '../../module';

import messages from './messages';
import styles from './styles';

export interface Props {
  context: SuccessContext;
}
export const ResendEmailSuccess = ({
  context: { resendConfirmationEmail, resendFulfilmentEmail, emails },
}: Props) => {
  const [firstEmail, ...otherEmails] = emails.map((email, index) => (
    <strong key={index}>{email}</strong>
  ));

  return (
    <div>
      <div className={css(styles.title)}>
        <Heading typeStyle="title3" as="h1" color="base" testId="confirmationEmailSuccessHeader">
          <FormattedMessage {...messages.confirmationEmailSentSuccessfully} />
        </Heading>
      </div>
      {resendConfirmationEmail && (
        <p data-test="ConfirmationEmailSuccessSubHeader">
          <FormattedMessage {...messages.confirmationEmailSentSuccessfully} />
        </p>
      )}
      {resendFulfilmentEmail && (
        <p data-test="FulfilmentEmailsSentSuccessfully">
          <FormattedMessage {...messages.fulfilmentEmailsSentSuccessfully} />
        </p>
      )}
      <p data-test="ResendEmailSentTo">
        <FormattedMessage
          {...messages.confirmationSent}
          values={{
            firstEmail,
          }}
        />

        {emails.length > 1 && (
          <>
            {' '}
            <FormattedMessage
              {...messages.additionally}
              values={{
                emailsCount: emails.length,
                ...otherEmails,
              }}
            />
          </>
        )}
      </p>
    </div>
  );
};
const mapStateToProps = (state: State) => ({
  context: selectors.getSuccessContext(state),
});

const ResendEmailSuccessContainer = connect(mapStateToProps)(ResendEmailSuccess);
ResendEmailSuccessContainer.displayName = 'ResendEmailSuccess';

export default ResendEmailSuccessContainer;
