import { call, takeLatest, take, put, race } from 'redux-saga/effects';

import { setManagedGroup } from '@contactcentre-web/utils/tracker';

import loginService from '../login/service';
import { LOGOUT, LOGIN_REQUESTED, actions } from '../module';

export function* saga() {
  try {
    yield call(setManagedGroup, 0);

    const { loggedOut } = yield race({
      loggedOut: call(loginService.logout),
      loggingIn: take(LOGIN_REQUESTED),
    });

    if (loggedOut) {
      yield put(actions.logoutSucceeded());
    }
  } catch {
    yield put(actions.logoutSucceeded());
  }
}

export default function* logoutSaga() {
  yield takeLatest(LOGOUT, saga);
}
