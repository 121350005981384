import React, { FunctionComponent, ReactNode } from 'react';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';

import styles from './styles';

interface Props {
  label: ReactNode | string;
  orientation?: 'horizontal' | 'vertical';
  styleSheet?: {
    container?: StyleDeclarationValue;
    label?: StyleDeclarationValue;
    value?: StyleDeclarationValue;
  };
}

const StackedLabel: FunctionComponent<Props> = ({
  label,
  orientation = 'vertical',
  styleSheet,
  children,
}) => (
  <div className={css([styles[orientation], styleSheet?.container])}>
    <div className={css([styles.labelPrimary, styleSheet?.label])}>{label}</div>
    <div className={css([styles.value, styleSheet?.value])}>{children}</div>
  </div>
);

export default StackedLabel;
