import { defineMessages } from 'react-intl';

export default defineMessages({
  header404: {
    id: 'NotFoundPage.header404',
    defaultMessage: '404 page not found',
  },
  notFoundMessage: {
    id: 'NotFoundPage.notFoundMessage',
    defaultMessage: 'We are sorry but the page you are looking for does not exist.',
  },
});
