import { createActions, handleActions } from 'redux-actions';

import { Customer } from '@contactcentre-web/redux-common/types/Customer';

export interface CustomerMoveOrderResponse {
  customers: Array<any>;
  canMove: boolean;
  newCustomerId: string | undefined;
}
export interface CustomerMoveOrderState {
  searchStatus: string;
  customers: Array<any>;
  moveStatus: string;
  canMove: boolean;
  newCustomerId: string | undefined;
}
export interface SearchRequestResponse {
  errors: Array<any>;
  items: Array<Customer>;
  searchResultType: string;
  totalCount: number;
}

export interface SearchRequest {
  email: string;
}
export interface MoveOrderBodyRequest {
  newCustomerId: string;
  orderId: string;
}

export interface MoveOrderRequest {
  customerId: string;
  orderReference: string;
  orderId: string;
  newCustomerId: string;
}

export const FORM_ID = 'MoverOrder-EmailSearch';

export const PREFIX = 'CustomerMoveOrder';
export const RESET = `RESET`;
export const SEARCH_ATTEMPT = `SEARCH_ATTEMPT`;
export const SEARCH_SUCCESS = `SEARCH_SUCCESS`;
export const SEARCH_ERROR = `SEARCH_ERROR`;

export const MOVE_ATTEMPT = `MOVE_ATTEMPT`;
export const MOVE_SUCCESS = `MOVE_SUCCESS`;
export const MOVE_ERROR = `MOVE_ERROR`;

export const searchStates = {
  NONE: `${PREFIX}/SEARCH_STATE_NONE`,
  INPROGRESS: `${PREFIX}/SEARCH_STATE_INPROGRESS`,
  SUCCESSFUL: `${PREFIX}/SEARCH_STATE_SUCCESSFUL`,
  FAILED: `${PREFIX}/SEARCH_STATE_FAILED`,
};

export const moveStates = {
  NONE: `${PREFIX}/MOVE_STATE_NONE`,
  INPROGRESS: `${PREFIX}/MOVE_STATE_INPROGRESS`,
  SUCCESSFUL: `${PREFIX}/MOVE_STATE_SUCCESSFUL`,
  FAILED: `${PREFIX}/MOVE_STATE_FAILED`,
};

export const initialState = {
  searchStatus: searchStates.NONE,
  customers: [],
  moveStatus: moveStates.NONE,
  canMove: false,
  newCustomerId: undefined,
};
const nullPayload = () => null;
export const actions = createActions(
  {
    [RESET]: () => nullPayload,
    [SEARCH_ATTEMPT]: (email) => ({ email }),
    [SEARCH_SUCCESS]: (canMove, customers) => ({ customers, canMove }),
    [SEARCH_ERROR]: () => nullPayload,
    [MOVE_ATTEMPT]: (customerId, orderReference, orderId, newCustomerId) => ({
      customerId,
      orderReference,
      orderId,
      newCustomerId,
    }),
    [MOVE_SUCCESS]: (newCustomerId) => ({ newCustomerId }),
    [MOVE_ERROR]: () => nullPayload,
  },
  { prefix: PREFIX }
);
type ActionPayload = CustomerMoveOrderResponse;
export default handleActions<CustomerMoveOrderState, ActionPayload>(
  {
    [RESET]: () => initialState,
    [SEARCH_ATTEMPT]: (state) => ({
      ...state,
      searchStatus: searchStates.INPROGRESS,
      customers: [],
    }),
    [SEARCH_SUCCESS]: (state, { payload: { customers, canMove } }) => ({
      ...state,
      searchStatus: searchStates.SUCCESSFUL,
      customers,
      canMove,
    }),
    [SEARCH_ERROR]: (state) => ({
      ...state,
      searchStatus: searchStates.FAILED,
    }),
    [MOVE_ATTEMPT]: (state) => ({
      ...state,
      moveStatus: moveStates.INPROGRESS,
    }),
    [MOVE_SUCCESS]: (state, { payload: { newCustomerId } }) => ({
      ...state,
      moveStatus: moveStates.SUCCESSFUL,
      newCustomerId,
    }),
    [MOVE_ERROR]: (state) => ({
      ...state,
      moveStatus: moveStates.FAILED,
    }),
  },
  initialState,
  { prefix: PREFIX }
);
