import { StyleSheet } from 'aphrodite/no-important';
import {
  fonts,
  palettes,
  spacers,
  typography,
  borderRadius,
} from '@trainline/depot-web/css/themes/trainline';

import { defaultStyleButtonComponent } from '@contactcentre-web/common/Button/styles';

const defaultButtonStyle = {
  padding: `${spacers.dp_s8} ${spacers.dp_s12}`,
  fontSize: typography.dp_small_font_size_m,
  lineHeight: typography.dp_small_line_height,
};

export default StyleSheet.create({
  buttonsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '750px',
    justifyContent: 'flex-end',
    gap: `${spacers.dp_s4}`,
  },
  buttonGroup: {
    display: 'flex',
    flexWrap: 'no-wrap',
    gap: `${spacers.dp_s4}`,
  },
  buttonRefundsContainer: {
    ...defaultStyleButtonComponent,
    fontSize: typography.dp_small_font_size_m,
    fontWeight: fonts.dp_font_weight_bold,
    lineHeight: typography.dp_small_line_height,
    backgroundColor: palettes.dp_comp_button_tertiary_background,
    color: palettes.dp_app_text_base,
    borderRadius: borderRadius.dp_r4,
    borderColor: palettes.dp_app_border_base,
    ':hover': {
      backgroundColor: palettes.dp_comp_button_tertiary_background_hover,
    },
    ':active': {
      backgroundColor: palettes.dp_comp_button_tertiary_background_pressed,
    },
    ':nth-child(1n) > button': {
      padding: `${spacers.dp_s8} ${spacers.dp_s12}`,
    },
  },

  buttonRefundsDisabled: {
    cursor: 'not-allowed',
    backgroundColor: palettes.dp_app_backdrop_darker,
    color: palettes.dp_app_text_disabled,
    ':hover': {
      backgroundColor: palettes.dp_app_backdrop_darker,
    },

    ':nth-child(1n) > button': {
      pointerEvents: 'none',
    },
  },

  button: {
    ...defaultButtonStyle,
  },
  moreButton: {
    ...defaultButtonStyle,
    height: '100%',
  },
  expiryTime: {
    fontWeight: fonts.dp_font_weight_regular,
  },
  linkButton: {
    ...defaultButtonStyle,
    paddingRight: spacers.dp_s24,
    paddingLeft: spacers.dp_s16,
    color: palettes.dp_app_text_base,
    width: '100%',
    border: 0,
  },
  orderHistoryNotification: {
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    backgroundColor: palettes.dp_app_negative_dark,
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: spacers.dp_s4,
  },
});
