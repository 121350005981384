import { all, takeLatest, call, put, select, takeEvery } from 'redux-saga/effects';
import moment from 'moment';

import request from '@contactcentre-web/utils/request';
import { actions as customerOrderActions } from '@contactcentre-web/redux-common/actions/order';
import { getProducts } from '@contactcentre-web/redux-common/selectors/products';
import { selectOrder } from '@contactcentre-web/redux-common/selectors/order';

import {
  REQUEST_REFUND,
  UPDATE_QUOTE,
  LOAD_QUOTE,
  actions as refundsFormActions,
} from '../common/module';
import {
  extractQuoteUnprocessableReasons,
  extractValidationErrors,
  extractUsedTicketRefundables,
} from '../utils';

import actions, { events, seasonRefund } from './module';
import selectors from './selectors';

export const getRefundsRequest = (orderId) =>
  Promise.all([
    request(`/refundsapi/refunds/${orderId}/refund-eligibility?policy=terms-and-conditions`),
    request(`/refundsapi/orders/${orderId}/refunds`),
  ]).then(([refundables, { refunds }]) => ({
    refundables: { ...refundables },
    refunds,
  }));

export const getQuote = (orderId, bookingId, refundables, includeAdminFee) =>
  request(`/refundsapi/refunds/quotes`, {
    method: 'POST',
    body: {
      orderId,
      bookingId,
      policy: 'terms-and-conditions',
      refundableItems: refundables,
      includeAdminFee,
    },
  });

export const postRefundRequest = (quoteReference) => {
  const uri = `/refundsapi/refunds/quotes/${quoteReference}/confirm`;
  const method = 'POST';
  return request(uri, {
    method,
  });
};

export const updateQuoteRequest = ({
  orderId,
  bookingId,
  refundables,
  includeAdminFee,
  isOverriding,
  overrideLastUsedDate,
}) => {
  const uri = `/refundsapi/refunds/quotes`;
  const method = 'POST';
  return request(uri, {
    method,
    body: {
      orderId,
      bookingId,
      policy: 'terms-and-conditions',
      refundables,
      includeAdminFee,
      overrideFraudProvider: isOverriding,
      overrideLastUsedDate,
    },
  });
};

export function* loadSaga() {
  try {
    const state = yield select();
    const order = selectOrder(state);
    if (!order || !selectors.inProgress(state)) {
      // Either the order is not loaded yet
      // or the order has loaded, but we're not on the refunds screen
      return;
    }

    const { refundables, refunds } = yield call(getRefundsRequest, order.id);
    yield put(actions.loadRefundsSucceeded(refundables, refunds));
  } catch (error) {
    yield put(actions.loadRefundsFailed(error));
  }
}

export function* requestRefundSaga({
  payload: { bookingId, orderReference, quoteReference, customerId },
}) {
  const state = yield select();
  if (!selectors.hasLoadedSuccessfully(state)) {
    // The form has been loaded from another module.
    return;
  }

  try {
    yield call(postRefundRequest, quoteReference);
    yield put(refundsFormActions.requestRefundSucceeded(bookingId));
  } catch (error) {
    const unprocessableReasons = error?.validationErrors?.map(({ code }) => ({ reasonCode: code }));
    yield put(refundsFormActions.requestRefundFailed(bookingId, error, unprocessableReasons));
    return;
  }

  yield put(customerOrderActions.load(customerId, orderReference));
  yield put(actions.loadRefunds(orderReference));
}

export function* loadQuoteSaga({ payload: { orderId, bookingId, refundables } }) {
  const state = yield select();
  if (!selectors.hasLoadedSuccessfully(state)) {
    // The form has been loaded from another module.
    return;
  }

  try {
    const isAdminFeeSelector = selectors.getAdminFee(bookingId);
    const includeAdminFee = isAdminFeeSelector(state);
    let quote = yield call(getQuote, orderId, bookingId, refundables, includeAdminFee);
    quote.unprocessableReasons =
      quote.unprocessableReasons && extractQuoteUnprocessableReasons(quote);
    const unprocessableRefundables = extractUsedTicketRefundables(quote) || [];

    if (unprocessableRefundables.length > 0) {
      quote = yield call(
        getQuote,
        orderId,
        bookingId,
        refundables.filter((r) => !unprocessableRefundables.includes(r)),
        includeAdminFee
      );
    }

    yield put(
      refundsFormActions.loadQuoteSucceeded(orderId, bookingId, {
        ...quote,
        quoteUuid: moment.utc().format('YYYYMMDDHHmmss'),
        unprocessableRefundables,
      })
    );
  } catch (error) {
    if (error.validationErrors) {
      const mapped = extractValidationErrors(error);
      if (mapped.length !== 0) {
        yield put(
          refundsFormActions.loadQuoteSucceeded(orderId, bookingId, {
            quoteUuid: moment.utc().format('YYYYMMDDHHmmss'),
            productId: bookingId,
            quoteId: 'autogenerated-by-saga',
            refundables: [],
            unprocessableReasons: mapped.map((r) => ({ reasonCode: r })),
          })
        );

        return;
      }
    }

    yield put(refundsFormActions.loadQuoteFailed(orderId, bookingId, {}));
  }
}

export function* updateQuoteSaga({ payload }) {
  const state = yield select();
  if (!selectors.hasLoadedSuccessfully(state)) {
    return;
  }

  const quotes = selectors.getQuotes(state);
  const {
    bookingId,
    selection: { refundables, adminFee },
  } = payload;

  try {
    const quote = yield call(updateQuoteRequest, {
      ...payload,
      refundables,
      includeAdminFee: !!adminFee && !!adminFee.deducted,
    });

    quote.unprocessableReasons =
      quote.unprocessableReasons && extractQuoteUnprocessableReasons(quote);
    const unprocessableRefundables =
      extractUsedTicketRefundables(quote) ||
      (quotes && quotes[bookingId] && quotes[bookingId].unprocessableRefundables) ||
      [];

    yield put(
      refundsFormActions.updateQuoteSuccess(bookingId, undefined, {
        ...quote,
        quoteUuid: moment.utc().format('YYYYMMDDHHmmss'),
        unprocessableRefundables,
      })
    );
  } catch (err) {
    if (err.validationErrors) {
      const mapped = extractValidationErrors(err);
      if (mapped.length !== 0) {
        yield put(refundsFormActions.quoteValidationFailed(bookingId, mapped));

        return;
      }
    }

    yield put(refundsFormActions.updateQuoteError(bookingId, {}));
  }
}

export function* seasonOrderRefundSaga() {
  try {
    const state = yield select();
    const products = getProducts(state);
    const refundLink = products && products[0].links.find(({ rel }) => rel === 'Refund');
    yield call(request, refundLink.href, { method: refundLink.meta.method });
    yield put(actions.seasonOrderRefundSuccess());
  } catch (error) {
    yield put(actions.seasonOrderRefundFail());
  }
}

export function* saga() {
  yield all([
    takeLatest(events.LOAD_REFUNDS, loadSaga),
    takeEvery(LOAD_QUOTE, loadQuoteSaga),
    takeLatest(REQUEST_REFUND, requestRefundSaga),
    takeLatest(UPDATE_QUOTE, updateQuoteSaga),
    takeLatest(seasonRefund.SEASON_ORDER_REFUND_ATTEMPT, seasonOrderRefundSaga),
  ]);
}

export default saga;
