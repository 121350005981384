import { StyleSheet } from 'aphrodite/no-important';
import { fonts, spacers, typography } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

const typographyStyles = {
  fontSize: typography.dp_small_font_size_m,
  letterSpacing: typography.dp_small_letter_spacing,
  lineHeight: typography.dp_small_line_height,
};

export default StyleSheet.create({
  noPreviousBookingsContainer: {
    ...typographyStyles,
    color: colors.gray.alpha30,
  },

  errorContainer: {
    ...typographyStyles,
    color: colors.negative.base,
    textAlign: 'right',
    width: '320px',
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
  },

  orderDate: {
    color: colors.gray.alpha30,
    fontSize: typography.dp_micro_font_size_m,
    lineHeight: typography.dp_micro_line_height_m,
    textAlign: 'right',
  },

  journeyRoute: {
    ...typographyStyles,
    color: colors.gray.alpha80,
    textAlign: 'right',
  },

  departureDetails: {
    ...typographyStyles,
    color: colors.gray.alpha80,
    textAlign: 'right',
  },

  bookedVia: {
    color: colors.gray.alpha30,
    fontSize: typography.dp_micro_font_size_m,
    lineHeight: typography.dp_micro_line_height_m,
    marginLeft: spacers.dp_s8,
  },

  orderReferenceContainer: {
    textAlign: 'right',
  },

  orderReference: {
    fontWeight: fonts.dp_font_weight_bold,
  },
});
