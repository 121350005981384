import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  refundStatusAndReasonCodeGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: spacers.dp_s32,
    gridColumn: 'span 2',
  },
});
