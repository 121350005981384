import { defineMessages } from 'react-intl';

export default defineMessages({
  product: {
    id: 'RefundRailcardInformation.product',
    defaultMessage: 'Product',
  },
  railcardNumber: {
    id: 'RefundRailcardInformation.railcardNumber',
    defaultMessage: 'Railcard number',
  },
  railcardHolder: {
    id: 'RefundRailcardInformation.railcardHolder',
    defaultMessage: 'Railcard holder',
  },
  firstRailcardHolder: {
    id: 'RefundRailcardInformation.firstRailcardHolder',
    defaultMessage: 'First railcard holder',
  },
  secondRailcardHolder: {
    id: 'RefundRailcardInformation.secondRailcardHolder',
    defaultMessage: 'Second railcard holder',
  },
});
