import { css } from 'aphrodite/no-important';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Heading } from '@trainline/depot-web';

import type State from '@contactcentre-web/redux-common/types/State';

import { selectors } from '../../module';

import messages from './messages';
import styles from './styles';
interface StateProps {
  refreshed: boolean;
}

export const RefreshPnrSuccess = ({ refreshed }: StateProps) => (
  <div className={css(styles.container)}>
    <div className={css(styles.title)}>
      <Heading as="h1" typeStyle="title3" color="base">
        {refreshed ? (
          <FormattedMessage {...messages.pnrRefreshed} />
        ) : (
          <FormattedMessage {...messages.bookingUpToDate} />
        )}
      </Heading>
    </div>
    {refreshed ? (
      <p>
        <FormattedMessage {...messages.pnrRefreshedDetails} />
      </p>
    ) : (
      <div>
        <p>
          <FormattedMessage {...messages.bookingUpToDateDetails} />
        </p>
        <p>
          <FormattedMessage {...messages.noNewPnr} />
        </p>
      </div>
    )}
  </div>
);

const mapStateToProps = ({ refreshPnr: state }: State) => ({
  refreshed: selectors.isRefreshed(state),
});

const RefreshPnrSuccessContainer = connect(mapStateToProps)(RefreshPnrSuccess);
RefreshPnrSuccessContainer.displayName = 'RefreshPnrSuccess';

export default RefreshPnrSuccessContainer;
