import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import FormattedDateInLondonTz from '@contactcentre-web/common/FormattedDateInLondonTz/FormattedDateInLondonTz';
import FormattedTimeInLondonTz from '@contactcentre-web/common/FormattedTimeInLondonTz/FormattedTimeInLondonTz';

import messages from './messages';
import styles from './styles';

const LabelWithValue = ({ label, value }) => (
  <dl>
    <dt className={css(styles.label)}>{label}</dt>
    <dd>{value}</dd>
  </dl>
);

LabelWithValue.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

const OrderHeader = ({ orderReference, bookedVia, createdOn, bookings, passengersByType }) => (
  <section className={css(styles.container)}>
    <div className={css(styles.row, styles.rowHeader)}>
      <dl>
        <dt className={css(styles.label)}>
          <FormattedMessage {...messages.orderId} />
        </dt>
        <dd className={css(styles.orderReference)}>{orderReference}</dd>
      </dl>
    </div>
    <div className={css(styles.row)}>
      <dl className={css(styles.bookings)}>
        <dt className={css(styles.label)}>
          <FormattedMessage {...messages.bookings} values={{ count: bookings.length }} />
        </dt>
        {bookings.map(({ isReturn, origin, destination }, idx) => (
          <dd key={idx}>
            <FormattedMessage
              {...messages.directions}
              values={{
                withReturn: isReturn ? <FormattedMessage {...messages.withReturn} /> : null,
                origin,
                destination,
              }}
            />
          </dd>
        ))}
      </dl>

      <dl>
        <dt className={css(styles.label)}>
          <FormattedMessage
            {...messages.tickets}
            values={{
              count: passengersByType.count,
            }}
          />
        </dt>
        {passengersByType.passengers.map(({ type, passengers }) => (
          <dd key={type}>
            <FormattedMessage {...messages[type]} values={{ count: passengers.length }} />
          </dd>
        ))}
      </dl>

      <LabelWithValue
        label={<FormattedMessage {...messages.labelCreatedOn} />}
        value={
          <FormattedMessage
            {...messages.createdOn}
            values={{
              date: (
                <FormattedDateInLondonTz
                  value={createdOn}
                  year="numeric"
                  month="short"
                  day="numeric"
                  weekday="short"
                />
              ),
              time: <FormattedTimeInLondonTz value={createdOn} hour="2-digit" minute="2-digit" />,
            }}
          />
        }
      />

      <LabelWithValue label={<FormattedMessage {...messages.labelBookedVia} />} value={bookedVia} />
    </div>
  </section>
);

OrderHeader.propTypes = {
  orderReference: PropTypes.string.isRequired,
  bookedVia: PropTypes.string.isRequired,
  createdOn: PropTypes.string.isRequired,
  passengersByType: PropTypes.object.isRequired,
  source: PropTypes.string.isRequired,
  bookings: PropTypes.arrayOf(PropTypes.object),
};

export default OrderHeader;
