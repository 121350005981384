import { call, put, takeLatest, all, select, take } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import * as semver from 'semver';
import { startSubmit, stopSubmit } from 'redux-form';
import moment from 'moment';

import request from '@contactcentre-web/utils/request';
import { addPageAction } from '@contactcentre-web/utils/tracker';
import * as customerOrderSelectors from '@contactcentre-web/redux-common/selectors/order';
import { GENERAL_ERROR_MSG } from '@contactcentre-web/middlewares/errorHandleMiddleware';
import { LOGIN_SUCCEEDED, LOGOUT } from '@contactcentre-web/authentication/redux/module';
import {
  userId,
  getUsername,
  getHomeManagedGroupId,
  canLogCall,
} from '@contactcentre-web/authentication/redux/selectors';
import sessionStorage from '@contactcentre-web/utils/sessionStorage';
import { adsServiceUrl } from '@contactcentre-web/utils/constants';

import {
  actions,
  selectors as orderNoteSelectors,
  SAVE_ORDER_NOTE_ATTEMPT,
  LOAD_CALL_CRM_QUEUES,
  LOAD_SURVEY_CHOICES,
  CHANGE_ADS_TOKEN_SUCCESS,
  CHANGE_ADS_TOKEN_FAILED,
  LOAD_ADS_LOGGED_IN_SUCCESS,
  LOAD_ADS_VERSION_COMPATIBLE,
  LOG_ACS_LOAD_CALL_NOT_FOUND,
  LOG_ACS_LOAD_CALL_FAILURE,
  LOG_CRM_REASON_CODES_EMPTY,
  LOG_CRM_REASON_CODES_FAILURE,
} from './module';

export const adsMinimumVersion = '^1.2.0';
const maximumTokenRefreshDelayInMs = 60000;

export const loadCallCrmQueues = () => request('/crm/queues?integrationType=FreshDesk');

export const loadSurveyChoices = () => request('/crm/surveychoices?integrationType=FreshDesk');

export const loadAdsVersion = () => request(`${adsServiceUrl}/version`, {}, false);

export const loadAdsLoggedInStatus = () => request(`${adsServiceUrl}/phone`, {}, false);

export const loadAdsLoggedIn = (token, userName) =>
  request(`${adsServiceUrl}/sgp/login`, { method: 'POST', body: { token, userName } });

export const adsLogout = () =>
  request(`${adsServiceUrl}/phone/logout`, {
    method: 'POST',
  });

export const isAdsVersionCompatible = (version) => semver.satisfies(version, adsMinimumVersion);

export const isCompatibleWithAcs = (state) => orderNoteSelectors.isAcsCompatibleAdsActive(state);

export const storeCallId = (callId) => sessionStorage.set('lastLoggedCallId', callId);

export const saveOrderNoteRequest = (values) =>
  request('/api/notes/incident', {
    method: 'POST',
    body: values,
  });

export const changeTokenRequest = () =>
  request('/agent-call-v2/auth', {
    method: 'POST',
  });

export const saveOrderNoteRequestLegacy = (values) =>
  request(`/api/notes`, {
    method: 'POST',
    body: values,
  });

export function* logError({ payload: { description, callId, errors } }) {
  const state = yield select();
  const user = userId(state);
  const userName = getUsername(state);
  const attributes = {
    userId: user,
    userName: userName?.includes('@') ? '[REDACTED]' : userName,
    homeManagedGroupId: getHomeManagedGroupId(state),
    canLogCall: canLogCall(state),
    adsVersion: orderNoteSelectors.getAdsVersion(state),
    callId,
    errors,
  };

  addPageAction(description, attributes);
}

export function* loadCallCrmQueuesSaga() {
  try {
    const callQueues = yield call(loadCallCrmQueues);
    yield put(actions.loadCallCrmQueuesSuccess(callQueues));
  } catch (error) {
    const errorCode = error.errors && error.errors.map(({ code }) => code).pop();
    if (error.status === 400) {
      yield put(actions.loadCallCrmQueuesNoSiteUserError(errorCode));
      return;
    }
    yield put(actions.loadCallCrmQueuesFailed(GENERAL_ERROR_MSG));
  }
}

export function* loadSurveyChoicesSaga() {
  try {
    const surveyChoices = yield call(loadSurveyChoices);
    yield put(actions.loadSurveyChoicesSuccess(surveyChoices));
  } catch (error) {
    yield put(actions.loadSurveyChoicesFailed(GENERAL_ERROR_MSG));
  }
}

export function* saveOrderNoteSaga({
  payload: {
    formId,
    values: {
      note,
      reasonCodeId,
      decisionTreeCode,
      callCrmQueueId: queueId,
      surveyChoiceId,
      callId,
      applicationUsedId,
    },
  },
}) {
  try {
    yield put(startSubmit(formId));
    const state = yield select();
    const { orderReference } = customerOrderSelectors.getOrder(state);
    const payload = {
      topicId: orderReference,
      topic: 'Order',
      body: note,
      reasonCodeId,
      decisionTreeCode: decisionTreeCode || null,
      surveyChoiceId,
      queueId,
      callId,
      integrationType: 'FreshDesk',
      applicationUsedId,
    };

    const lastLoggedCallId = sessionStorage.get('lastLoggedCallId');

    yield call(saveOrderNoteRequest, payload);
    yield put(actions.saveOrderNoteSuccess(payload));
    if (callId === lastLoggedCallId) {
      yield call(logError, { payload: { description: 'logged-call-with-same-callId', callId } });
    }
    yield call(storeCallId, callId);
    yield put(stopSubmit(formId, {}));
  } catch (e) {
    yield put(stopSubmit(formId, {}));
    yield put(actions.saveOrderNoteFailed(e));
  }
}

export function* retryChangeAdsTokenSaga(error, retryFunction) {
  const state = yield select();
  const adsRetryCount = orderNoteSelectors.getAdsRetryCount(state);
  if (error.status === 401 && adsRetryCount < 1)
    try {
      if (isCompatibleWithAcs(state)) {
        const adsToken = yield call(changeTokenRequest);
        yield put(actions.changeAdsTokenSuccess(adsToken));
        yield put(actions.adsRetryCountFailed());
        if (adsRetryCount < 1) {
          yield call(retryFunction);
        }
      }
    } catch (err) {
      yield put(actions.changeAdsTokenFailed());
    }
}

export function* loadAdsVersionSaga() {
  try {
    const adsVersion = yield call(loadAdsVersion);
    if (isAdsVersionCompatible(adsVersion.version)) {
      yield put(actions.loadAdsVersionCompatible(adsVersion.version));
    } else {
      yield call(logError, { payload: { description: 'incompatible-ADS-version' } });
      yield put(actions.loadAdsVersionIncompatible(adsVersion.version));
    }
  } catch (err) {
    yield call(logError, { payload: { description: 'ADS-not-running', errors: err } });
    yield put(actions.loadAdsVersionFailed());
    yield call(retryChangeAdsTokenSaga, err, loadAdsVersionSaga);
  }
}

export function* loadAdsLoggedInStatusSaga() {
  try {
    const adsLoggedInStatus = yield call(loadAdsLoggedInStatus);
    if (!adsLoggedInStatus.loggedIn) {
      yield call(logError, { payload: { description: 'ADS-not-logged-in' } });
    }

    yield put(actions.loadAdsLoggedInStatusSuccess(adsLoggedInStatus.loggedIn));
  } catch (err) {
    yield put(actions.loadAdsLoggedInStatusFailed());
    yield call(retryChangeAdsTokenSaga, err, loadAdsLoggedInStatusSaga);
  }
}

export function* adsRefreshTokenSaga() {
  try {
    const state = yield select();

    if (isCompatibleWithAcs(state)) {
      const adsToken = yield call(changeTokenRequest);

      yield put(actions.changeAdsTokenSuccess(adsToken));
    }
  } catch (e) {
    yield put(actions.changeAdsTokenFailed());
  }
}

export function* delayedRefreshTokenSaga() {
  const state = yield select();
  const retries = orderNoteSelectors.getAdsRetryRefreshTokenCount(state);

  const retryDelay = retries * 5000;
  yield delay(
    retryDelay > maximumTokenRefreshDelayInMs ? maximumTokenRefreshDelayInMs : retryDelay
  );

  yield call(adsRefreshTokenSaga);
}

export function* adsRefreshTokenLoopSaga() {
  const state = yield select();
  const token = orderNoteSelectors.getAdsToken(state);
  const expiresAt = orderNoteSelectors.getAdsExpiresAt(state);

  if (!token) {
    return;
  }

  const now = moment();
  const timeToDelay = moment(expiresAt).diff(now, 'ms') - 5000;

  yield delay(timeToDelay);
  yield call(adsRefreshTokenSaga);
  if (!token) {
    // We've most likely logged out at some point ...
  }
}

export function* adsLoginSaga() {
  try {
    const state = yield select();

    const adsToken = orderNoteSelectors.getAdsToken(state);
    const isValid = orderNoteSelectors.isAdsValidToken(state);
    if (!isValid) {
      yield call(adsRefreshTokenSaga);
    } else {
      yield put(actions.loadAdsLoggedInSuccess());
    }
    const username = getUsername(state);
    yield call(loadAdsLoggedIn, adsToken, username);
    yield call(adsRefreshTokenLoopSaga);

    yield put(actions.loadAdsLoggedInSuccess());
  } catch (err) {
    yield put(actions.loadAdsLoggedInFailed());
  }
}
export function* adsLogoutSaga() {
  try {
    const previousState = yield select();
    yield take(LOGOUT);
    if (isCompatibleWithAcs(previousState)) {
      yield call(adsLogout);
      yield put(actions.adsLogOutSuccess());
    }
  } catch (err) {
    yield put(actions.adsLogOutFailed());
  }
}

export function* initAdsSaga() {
  const state = yield select();
  return yield all([
    adsRefreshTokenSaga(),
    isCompatibleWithAcs(state)
      ? takeLatest(LOAD_ADS_LOGGED_IN_SUCCESS, loadAdsLoggedInStatusSaga)
      : loadAdsLoggedInStatusSaga(),
    takeLatest(CHANGE_ADS_TOKEN_SUCCESS, adsLoginSaga),
    takeLatest(CHANGE_ADS_TOKEN_FAILED, delayedRefreshTokenSaga),
    adsLogoutSaga(),
  ]);
}

export default function* saga() {
  return yield all([
    takeLatest(LOGIN_SUCCEEDED, loadAdsVersionSaga),
    takeLatest(LOAD_ADS_VERSION_COMPATIBLE, initAdsSaga),
    takeLatest(LOAD_SURVEY_CHOICES, loadSurveyChoicesSaga),
    takeLatest(LOAD_CALL_CRM_QUEUES, loadCallCrmQueuesSaga),
    takeLatest(SAVE_ORDER_NOTE_ATTEMPT, saveOrderNoteSaga),
    takeLatest(LOG_ACS_LOAD_CALL_NOT_FOUND, logError),
    takeLatest(LOG_ACS_LOAD_CALL_FAILURE, logError),
    takeLatest(LOG_CRM_REASON_CODES_EMPTY, logError),
    takeLatest(LOG_CRM_REASON_CODES_FAILURE, logError),
  ]);
}
