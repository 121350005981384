import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { StatusMessage } from '@trainline/depot-web';

import { TracsOnAccountError } from '@contactcentre-web/hooks/api/useTracsOnAccount';

import messages from './messages';
import styles from './styles';

const ErrorMessages = ({ errors }: TracsOnAccountError) => {
  const errorsArray = Array.isArray(errors) ? errors : errors?.errors;
  return (
    <>
      {errorsArray?.map(({ code, meta }) => (
        <div className={css(styles.errorMessage)} key={code}>
          <StatusMessage status="negative">
            {code === 'CompensationAmount.invalidRange' ? (
              <FormattedMessage
                {...messages.invalidRangeError}
                values={{
                  amountLimit: meta?.context?.amountLimit,
                  currencyCode: meta?.context?.currencyCode,
                }}
              />
            ) : (
              <FormattedMessage {...messages.genericError} />
            )}
          </StatusMessage>
        </div>
      ))}
    </>
  );
};

export default ErrorMessages;
