import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import messages from './messages';
import styles from './styles';

const OnboardServices = ({ onboardServices }) => {
  const groupedOnboardServices =
    onboardServices &&
    onboardServices.reduce((partial, itemName) => {
      const existingGroupItem = partial.find(
        (currentGroupItem) => currentGroupItem.name === itemName
      );

      if (existingGroupItem) {
        existingGroupItem.count += 1;
        existingGroupItem.text = `${existingGroupItem.count} x ${existingGroupItem.name}`;
      } else {
        partial.push({
          name: itemName,
          count: 1,
          text: `1 x ${itemName}`,
        });
      }
      return partial;
    }, []);

  return onboardServices && onboardServices.length > 0 ? (
    <ul className={css(styles.list)}>
      {groupedOnboardServices.map((onboardService, index) => (
        <li key={index}>{onboardService.text}</li>
      ))}
    </ul>
  ) : (
    <FormattedMessage {...messages.notAvailable} />
  );
};

OnboardServices.propTypes = {
  onboardServices: PropTypes.arrayOf(PropTypes.string),
};

export default OnboardServices;
