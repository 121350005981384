import { StyleSheet } from 'aphrodite/no-important';
import { palettes, spacers } from '@trainline/depot-web/css/themes/trainline';

import zIndex from '@contactcentre-web/styles/zIndex';

const inputPadding = `calc(${spacers.dp_s8} + ${spacers.dp_s24} + ${spacers.dp_s4})`;
const searchIconActive = { fill: palettes.dp_brand_secondary_core };

export default StyleSheet.create({
  container: {
    width: '100%',
    display: 'flex',
    gap: spacers.dp_s8,
  },
  searchInputContainer: {
    width: '100%',
    position: 'relative',
    flexGrow: 1,
    ':hover .search-input-icon': {
      ...searchIconActive,
    },
  },
  searchInput: {
    borderColor: palettes.dp_app_border_base,
    paddingLeft: inputPadding,
    ':focus': {
      paddingLeft: inputPadding,
    },
    ':hover:not(:disabled)': {
      background: palettes.dp_brand_secondary_lightest,
    },
  },
  searchIcon: {
    position: 'absolute',
    zIndex: zIndex.base,
    left: spacers.dp_s12,
    top: `calc(50% - ${spacers.dp_s12})`,
    userSelect: 'none',
    pointerEvents: 'none',
    fill: palettes.dp_app_border_dark,
  },
  searchIconActive: {
    ...searchIconActive,
  },
  searchHeaderInline: {
    width: '541px',
  },
});
