import { defineMessages } from 'react-intl';

export default defineMessages({
  inventoryCurrency: {
    id: 'CurrencyConverterForm.inventoryCurrency',
    defaultMessage: 'Inventory Currency',
  },

  calculateButton: {
    id: 'CurrencyConverterForm.calculateButton',
    defaultMessage: 'Calculate',
  },
  amountRequired: {
    id: 'CurrencyConverterForm.amountRequired',
    defaultMessage: 'Please enter an amount.',
  },
  inventoryCurrencyRequired: {
    id: 'CurrencyConverterForm.inventoryCurrencyRequired',
    defaultMessage: 'Please select a currency to convert from.',
  },
});
