import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  feeDetails: {
    display: 'flex',
    alignItems: 'stretch',
    paddingLeft: spacers.dp_s16,
  },
  feeDetail: {
    paddingRight: spacers.dp_s32,
  },
  feeRefundedTxt: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  feeRefunded: {
    color: colors.positive.base,
    marginRight: spacers.dp_s4,
  },
  feeNotRefunded: {
    color: colors.gray.alpha30,
    marginRight: spacers.dp_s4,
  },
});
