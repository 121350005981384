import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';
import { IconChevronDown, IconChevronUp } from '@trainline/depot-web';

import Button from '@contactcentre-web/common/Button';

import styles from './styles';

const ExpandableTableRow = ({ thead, tbody, children, type }) => {
  const [isOpen, setOpen] = React.useState(false);
  const chidlrenRows = React.Children.toArray(children);
  const hasChildren = chidlrenRows.length;
  return (
    <tr>
      <td colSpan="4" className={css(styles.padH)}>
        <table
          className={css(
            styles.expandableTableRow,
            type === 'insurance-product' && styles.insuranceTableRow
          )}
        >
          {thead}
          <tbody>
            <tr>
              {tbody}
              <td className={css(styles.tr, !hasChildren && styles.extraSpace)}>
                {hasChildren ? (
                  <Button
                    variant="ghost"
                    testId="button-to-expand"
                    styleSheet={styles.button}
                    onClick={() => setOpen((prevIsOpen) => !prevIsOpen)}
                  >
                    {isOpen ? <IconChevronUp /> : <IconChevronDown />}
                  </Button>
                ) : null}
              </td>
            </tr>
            {children &&
              isOpen &&
              chidlrenRows.map((row) =>
                React.cloneElement(row, {
                  ...row.props,
                  className: `${row.props.className} ${css(styles.expandedRow)}`,
                  'data-test-id': 'expanded-content',
                })
              )}
          </tbody>
        </table>
      </td>
    </tr>
  );
};

ExpandableTableRow.propTypes = {
  thead: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  tbody: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.array]),
  type: PropTypes.string,
};

export default ExpandableTableRow;
