import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';

import { selectors } from '../../../../module';

import ConvertedByBookingAlert from './ConvertedByBookingAlert';

export const ConvertedByBookingsAlert = ({ convertedBookings }) => (
  <div>
    {convertedBookings &&
      convertedBookings.map(({ bookingNumber, convertedByLink }) => (
        <ConvertedByBookingAlert
          convertedByLink={convertedByLink}
          bookingNumber={bookingNumber}
          key={bookingNumber}
        />
      ))}
  </div>
);

ConvertedByBookingsAlert.propTypes = {
  convertedBookings: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = (state) => ({
  convertedBookings: selectors.getConvertedBookings(state.orders.order),
});

const ConvertedByBookingsAlertContainer = connect(mapStateToProps)(ConvertedByBookingsAlert);

ConvertedByBookingsAlertContainer.displayName = 'ConvertedByBookingsAlert';

export default ConvertedByBookingsAlertContainer;
