import { StyleSheet } from 'aphrodite/no-important';
import { borderRadius, spacers, palettes } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  formContainer: {
    marginTop: spacers.dp_s24,
  },
  warning: {
    display: 'flex',
    gap: spacers.dp_s8,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacers.dp_s16,
    border: `solid 1px ${palettes.dp_app_border_base}`,
    borderRadius: borderRadius.dp_r8,
    padding: spacers.dp_s24,
    marginBottom: spacers.dp_s24,
    marginTop: spacers.dp_s24,
  },
  formRow: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: spacers.dp_s8,
  },
  formButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});
