import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import StackedLabel from '@contactcentre-web/common/StackedLabel';

import ExternalPaymentLabel from './ExternalPaymentLabel';
import RecommendationMessage from './RecommendationMessage';
import styles from './styles';
import messages from './messages';

const ReimbursedFailLabel = ({
  paymentServiceProvider,
  reimbursedBookings,
  reimbursedInsurances,
  externalPaymentBookings,
  allFailsAreExternalPaymentBookings,
  allFailsAreExternalPaymentInsurances,
}) => (
  <StackedLabel
    orientation="horizontal"
    label={<FormattedMessage {...messages.recommendations} />}
    styleSheet={{
      container: styles.recommendations,
      label: styles.label_recommendations,
      value: styles.value_recommendations,
    }}
  >
    {((!allFailsAreExternalPaymentBookings && reimbursedBookings.length > 0) ||
      (!allFailsAreExternalPaymentInsurances && reimbursedInsurances.length > 0)) && (
      <RecommendationMessage
        paymentServiceProvider={paymentServiceProvider}
        reimbursedBookings={reimbursedBookings}
        reimbursedInsurances={reimbursedInsurances}
      />
    )}
    {externalPaymentBookings.length > 0 && (
      <ExternalPaymentLabel
        externalPaymentBookings={externalPaymentBookings}
        allFailsAreExternalPaymentBookings={allFailsAreExternalPaymentBookings}
      />
    )}
  </StackedLabel>
);

ReimbursedFailLabel.propTypes = {
  paymentServiceProvider: PropTypes.string.isRequired,
  reimbursedBookings: PropTypes.arrayOf(PropTypes.object).isRequired,
  externalPaymentBookings: PropTypes.arrayOf(PropTypes.object).isRequired,
  allFailsAreExternalPaymentBookings: PropTypes.bool.isRequired,
  reimbursedInsurances: PropTypes.arrayOf(PropTypes.object),
  allFailsAreExternalPaymentInsurances: PropTypes.bool.isRequired,
};

export default ReimbursedFailLabel;
