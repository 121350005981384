import React, { FunctionComponent } from 'react';
import { css } from 'aphrodite/no-important';

import CompensationApprovalHeader from '../CompensationApprovalHeader/CompensationApprovalHeader';
import CompensationApprovalDetails from '../CompensationApprovalDetails/CompensationApprovalDetails';
import type { Approval } from '../../module';

import styles from './styles';

interface StateProps {
  approval: Approval;
  isResolutionInProgress: boolean;
  hasResolutionSucceeded: boolean;
}

interface DispatchProps {
  onApprove: (reasonCode: string, note: string) => void;
  onReject: (data: { reason?: string; note?: string }) => void;
  onEdit: (customerId: string, orderId: string, id: string) => void;
}

const CompensationApprovalCard: FunctionComponent<StateProps & DispatchProps> = ({
  approval,
  ...others
}) => (
  <div className={css(styles.card)}>
    <CompensationApprovalHeader {...approval} {...others} />
    <CompensationApprovalDetails {...approval} {...others} />
  </div>
);

export default CompensationApprovalCard;
