import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'SeasonBooking.CancelTicketsModal.title',
    defaultMessage: 'Cancel all tickets?',
  },
  description: {
    id: 'SeasonBooking.CancelTicketsModal.description',
    defaultMessage:
      'This will cancel all tickets in the season ticket. Refunds will have to be done manually.',
  },
  confirmCancel: {
    id: 'SeasonBooking.CancelTicketsModal.confirmCancel',
    defaultMessage: 'Cancel all tickets',
  },
  error: {
    id: 'SeasonBooking.CancelTicketsModal.error',
    defaultMessage: 'Something went wrong while cancelling the ticket. Try again.',
  },
});
