import { defineMessages } from 'react-intl';

export default defineMessages({
  booking: {
    id: 'RefundCardHeader.booking',
    defaultMessage: 'Booking',
  },
  ticketTypes: {
    id: 'RefundCardHeader.ticketTypes',
    defaultMessage: 'ticket types',
  },
  agent: {
    id: 'RefundCardHeader.agent',
    defaultMessage: 'agent',
  },
  price: {
    id: 'RefundCardHeader.price',
    defaultMessage: 'price',
  },
  date: {
    id: 'RefundCardHeader.date',
    defaultMessage: 'date',
  },
  createdAt: {
    id: 'RefundCardHeader.createdAt',
    defaultMessage: '{day} at {time}',
  },
});
