export enum Status {
  Delayed = 'delayed',
  Cancelled = 'cancelled',
  CancelledOnOriginStop = 'cancelledOnOriginStop',
  CancelledOnDestinationStop = 'cancelledOnDestinationStop',
  CancelledOnOriginAndDestinationStop = 'cancelledOnOriginAndDestinationStop',
  OnTime = 'onTime',
}

export enum CallingType {
  Normal = 'normal',
  Origin = 'origin',
  Destination = 'destination',
  None = 'none',
  PickUp = 'pickUp',
  SetDown = 'setDown',
  Passing = 'passing',
  Request = 'request',
}

export enum DepartureAndArrivalStatus {
  OnTime = 'onTime',
  Late = 'late',
  Early = 'early',
  Cancelled = 'cancelled',
}

export type DepartureAndArrival = {
  scheduledTime?: Date;
  realTime?: Date;
  stepCompleted?: boolean;
  statusType?: DepartureAndArrivalStatus;
};

export type CallingPoint = {
  locationName: string;
  callingType: CallingType;
  departure?: DepartureAndArrival;
  arrival?: DepartureAndArrival;
};

type Timetable = {
  legId: string;
  productId: string;
  status: Status;
  carrier: string;
  transportMode: string;
  delayReasons: string[];
  cancellationReasons: string[];
  callingPoints: CallingPoint[];
};

export default Timetable;
