import { StyleSheet } from 'aphrodite/no-important';
import { spacers, palettes, fonts, typography } from '@trainline/depot-web/css/themes/trainline';

export const inputCurrencyStyles = StyleSheet.create({
  input: {
    borderRighWidth: 0,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
  },
  container: {
    padding: 0,
  },
});

export const selectValueStyle = {
  fontWeight: fonts.dp_font_weight_bold,
};

export const selectStyle = {
  backgroundColor: palettes.dp_app_backdrop_dark,
  borderLeftWidth: 0,
  borderBottomLeftRadius: 0,
  borderTopLeftRadius: 0,
};

export default StyleSheet.create({
  resultContainer: {
    position: 'relative',
    marginTop: spacers.dp_s32,
    color: palettes.dp_app_text_inverted,
    ':before': {
      position: 'absolute',
      content: '""',
      top: 0,
      bottom: `-${spacers.dp_s32}`,
      right: `-${spacers.dp_s32}`,
      left: `-${spacers.dp_s32}`,
      backgroundColor: palettes.dp_app_backdrop_three_darkest,
    },
  },
  row: {
    display: 'flex',
  },
  button: {
    transform: 'translateY(-50%)',
  },
  inventoryCurrencyLabel: {
    paddingBottom: spacers.dp_s8,
  },
  inputCurrency: {
    flex: 1,
  },
  selectCurrency: {
    width: '60px',
  },
  result: {
    textAlign: 'center',
    transform: 'translateY(-10%)',
  },
  resultAmount: {
    fontFamily: typography.dp_hero_font_family,
    fontSize: typography.dp_hero_font_size,
    fontWeight: fonts.dp_font_weight_bold,
    lineHeight: typography.dp_hero_line_height,
    letterSpacing: '-0.2px', //TODO: replace with depot-web typography when updated in depot-web
  },
  fade: {
    opacity: 0.5,
  },
});
