import { StyleSheet } from 'aphrodite/no-important';
import { spacers, typography } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  bookingTitle: {
    color: colors.gray.alpha80,
    padding: `${spacers.dp_s8} 0`,
    fontSize: typography.dp_title3_font_size,
    lineHeight: typography.dp_title3_line_height,
  },
  details: {
    color: colors.gray.alpha33,
    fontSize: typography.dp_body_font_size,
    lineHeight: typography.dp_body_line_height,
    display: 'flex',
    alignItems: 'flex-start',
  },
  detailsContainer: {
    display: 'flex',
    marginRight: spacers.dp_s24,
  },
  dateRow: {
    marginRight: spacers.dp_s8,
    textAlign: 'right',
  },
  dateLabel: {
    textAlign: 'right',
    marginRight: spacers.dp_s8,
  },
  dateValue: {
    color: colors.gray.alpha80,
  },
  passengersContainer: {
    marginRight: spacers.dp_s24,
    display: 'flex',
    alignItems: 'center',
  },
  passengers: {
    height: '20px',
    verticalAlign: 'middle',
    marginRight: spacers.dp_s8,
  },
});
