import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form';
import { css } from 'aphrodite/no-important';
import { injectIntl } from 'react-intl';
import { IconChevronDown } from '@trainline/depot-web';

import intlShape from '@contactcentre-web/utils/shape/intl';

import Button from '../Button';
import { Checkbox } from '../Checkbox';
import Label from '../Label/Label';

import styles from './styles';
import messages from './messages';

export class MultiSelectDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    this.onMouseDownOutside = this.onMouseDownOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onMouseDownOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onMouseDownOutside, false);
  }

  onMouseDownOutside(e) {
    if (this.dropdown && !this.dropdown.contains(e.target)) {
      this.setState({
        isOpen: false,
      });
    }
  }

  onOptionClicked = (value) => {
    const {
      input: { value: selection, onChange },
    } = this.props;

    const index = selection.indexOf(value);
    if (index < 0) {
      onChange([...selection, value]);
    } else {
      onChange(selection.filter((v) => v !== value));
    }
  };

  onToggle = () => {
    const { disabled } = this.props;
    if (!disabled) {
      this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
    }
  };

  onChangeOption = (option, isChecked) => {
    const { onOptionChange } = this.props;
    onOptionChange(option, isChecked);
  };

  render() {
    const {
      label,
      items,
      disabled,
      input: { value: selection },
      noSelectedOptionsText,
      errors,
      meta: { touched, error },
      intl,
    } = this.props;
    const { isOpen } = this.state;

    let selectedOptions = '';
    if (selection.length > 0) {
      selectedOptions =
        selection.length === 1
          ? items.find(({ value }) => value === selection[0]).text
          : intl.formatMessage(messages.itemCount, { count: selection.length });
    }

    return (
      <div>
        <Label ariaExpanded={isOpen} styleSheet={styles.label} disabled={disabled} label={label} />

        <div
          className={css(styles.multiSelect)}
          ref={(element) => {
            this.dropdown = element;
          }}
          data-test="SelectBookingFulfilmentDropdown"
        >
          <Button
            variant="ghost"
            onClick={this.onToggle}
            disabled={disabled}
            styleSheet={[styles.select, this.state.isOpen && styles.selectOpened]}
            ariaExpanded={this.state.isOpen}
            tabIndex={0}
            testId="multiselect-label"
            role="listbox"
          >
            {selectedOptions.length > 0 ? (
              <span>{selectedOptions}</span>
            ) : (
              <span>{noSelectedOptionsText}</span>
            )}
            <IconChevronDown className={css(styles.selectIcon)} />
          </Button>
          {isOpen && (
            <ul className={css(styles.selectOptions)}>
              {items.map((option) => (
                <li
                  className={css(
                    styles.selectOption,
                    option.disabled && styles.selectOptionDisabled
                  )}
                  role="option"
                  aria-selected={option.selected}
                  value={option.value}
                  key={option.value}
                >
                  <Button variant="ghost" onClick={() => this.onOptionClicked(option.value)}>
                    <Checkbox
                      id={option.value}
                      input={{
                        onChange: () => this.onOptionClicked(option.value),
                        value: !!selection.find((v) => v === option.value),
                      }}
                      disabled={option.disabled}
                    />
                    <span>{option.text}</span>
                  </Button>
                </li>
              ))}
            </ul>
          )}
          {touched && error && errors[error] && (
            <span className={css(styles.errorContainer)}>{errors[error]}</span>
          )}
        </div>
      </div>
    );
  }
}

MultiSelectDropdown.propTypes = {
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onOptionChange: PropTypes.func,
  noSelectedOptionsText: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    value: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
  }),
  errors: PropTypes.object,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  intl: intlShape,
};

const MultiSelectDropdownField = ({ ...others }) => (
  <Field component={MultiSelectDropdown} {...others} />
);

MultiSelectDropdownField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
};

export default injectIntl(MultiSelectDropdownField);
