import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { css } from 'aphrodite/no-important';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from '@contactcentre-web/common/Button';
import TextCounter from '@contactcentre-web/common/TextCounter';

import NotesTextarea from '../NotesTextarea/NotesTextarea';
import { NOTE_FORM_ID } from '../../sagas';
import type { NoteSubmitFormData } from '../../module';

import messages from './messages';
import styles from './styles';

export interface CustomerNoteData {
  note: string;
}

export interface Props {
  onSubmit: (data: NoteSubmitFormData) => void;
  scrollToLatest: () => void;
  loading: boolean;
}

export const CustomerNoteForm: FunctionComponent<
  Props & InjectedFormProps<CustomerNoteData, Props>
> = ({ reset, handleSubmit, error, scrollToLatest, loading }) => {
  const { formatMessage } = useIntl();
  const [customerNoteLength, setCustomerNoteLength] = useState(0);
  const maxNoteLength = 500;

  return (
    <form
      id={NOTE_FORM_ID}
      className={css(styles.noteForm)}
      onSubmit={(event) => {
        handleSubmit(event);
        setCustomerNoteLength(0);
        reset();
      }}
      autoComplete="off"
    >
      <Field
        name="note"
        id={`${NOTE_FORM_ID}-note`}
        error={error}
        placeholder={formatMessage(messages.writeNote)}
        onFocus={() => scrollToLatest()}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setCustomerNoteLength(event.target.value.length)
        }
        maxLength={maxNoteLength}
        component={NotesTextarea}
      />
      <div className={css(styles.noteFooter)}>
        <Button
          variant="ghost"
          styleSheet={styles.deleteDraft}
          onClick={() => {
            setCustomerNoteLength(0);
            reset();
          }}
        >
          <FormattedMessage {...messages.deleteDraft} />
        </Button>
        <TextCounter textLength={customerNoteLength} maxLength={maxNoteLength} />
        <Button
          type="submit"
          variant="primary"
          size="small"
          styleSheet={styles.addNoteButton}
          loading={loading}
        >
          <FormattedMessage {...messages.addNoteButton} />
        </Button>
      </div>
    </form>
  );
};

export default reduxForm<CustomerNoteData, Props>({ form: NOTE_FORM_ID })(CustomerNoteForm);
