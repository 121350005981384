import { defineMessages } from 'react-intl';

export default defineMessages({
  historic: {
    id: 'CustomerNotesItem.Historic',
    defaultMessage: 'Original Booking Note (Replaced)',
  },
  current: {
    id: 'CustomerNotesItem.Current',
    defaultMessage: 'Current Booking Note',
  },
});
