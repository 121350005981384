import { defineMessages } from 'react-intl';

export default defineMessages({
  combination: {
    id: 'ValidRefundCombinationsCarousel.combination',
    defaultMessage: 'Combination {combinationIndex}',
  },
  select: {
    id: 'ValidRefundCombinationsCarousel.select',
    defaultMessage: 'Select',
  },
  ticketIDs: {
    id: 'ValidRefundCombinationsCarousel.ticketIDs',
    defaultMessage: 'Ticket IDs: {ticketIDs}',
  },
  pagination: {
    id: 'ValidRefundCombinationsCarousel.pagination',
    defaultMessage: 'Showing {currentSlide} of {totalSlides}',
  },
  noValidCombinations: {
    id: 'ValidRefundCombinationsCarousel.noValidCombinations',
    defaultMessage: 'No valid refund combinations',
  },
});
