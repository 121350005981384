import React, { FunctionComponent, useState } from 'react';
import { css } from 'aphrodite/no-important';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { change, InjectedFormProps, reduxForm, touch } from 'redux-form';

import Price from '@contactcentre-web/redux-common/types/Price';
import { selectors as featuresSelectors } from '@contactcentre-web/feature-flags/module';
import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency';
import TextField from '@contactcentre-web/common/TextField';
import Link from '@contactcentre-web/common/Link';

import messages from './messages';
import styles from './styles';

export interface Props {
  refundableQuote?: Price;
  productPrice: Price;
  refundType?: string;
  refundableFormName: string;
  refundableFormSection: string;
  canOverrideVoidableAmounts: boolean;
}

export const FORM_ID = 'RefundAmountForm';

export const RefundAmountForm: FunctionComponent<
  Props & InjectedFormProps<RefundAmountData, Props>
> = ({
  handleSubmit,
  error,
  invalid,
  form,
  refundableQuote,
  refundType,
  refundableFormName,
  refundableFormSection,
  canOverrideVoidableAmounts,
}) => {
  const dispatch = useDispatch();
  const isOverridingDiscretionaryRefundAmountEnabled =
    useSelector(featuresSelectors.isOverridingDiscretionaryRefundAmountEnabled) &&
    refundType === 'discretionary' &&
    canOverrideVoidableAmounts;
  const [isEditing, setIsEditing] = useState(false);

  const changeRefundAmount = (data: RefundAmountData) => {
    if (!data.refundAmount) {
      // if empty field, reset to initial value
      dispatch(change(form, 'refundAmount', refundableQuote?.amount.toFixed(2)));
    } else if (parseFloat(data.refundAmount) === refundableQuote?.amount) {
      dispatch(change(refundableFormName, `${refundableFormSection}.overrideAmount`, undefined));
    } else {
      dispatch(
        change(refundableFormName, `${refundableFormSection}.overrideAmount`, data.refundAmount)
      );
      // to enable recalculate button
      dispatch(touch(refundableFormName, `${refundableFormSection}.overrideAmount`));
    }
    setIsEditing(false);
  };

  return refundableQuote ? (
    <>
      <div className={css(styles.refundableAmount)}>
        {isOverridingDiscretionaryRefundAmountEnabled ? (
          <>
            <TextField
              testId="refund-amount"
              type="number"
              name="refundAmount"
              messages={messages}
              hasClearInputIcon={false}
              step="0.01"
              styleSheet={{
                input: styles.input,
                inputContainer: styles.input,
                errorContainer: styles.error,
              }}
              disabled={!isEditing}
            />
            {isEditing ? (
              <Link
                linkType="action"
                onClick={handleSubmit(changeRefundAmount)}
                disabled={invalid}
                type="button"
              >
                <FormattedMessage {...messages.setAmount} />
              </Link>
            ) : (
              <Link linkType="action" onClick={() => setIsEditing(true)}>
                <FormattedMessage {...messages.editAmount} />
              </Link>
            )}
            {error}
          </>
        ) : (
          <FormattedCurrency {...refundableQuote} />
        )}
      </div>
    </>
  ) : (
    <FormattedMessage {...messages.NA} />
  );
};

export interface RefundAmountData {
  refundAmount: string;
}

export const validate = (
  { refundAmount }: RefundAmountData,
  { productPrice }: Props
): Record<string, string | undefined> => {
  const minValue =
    refundAmount && parseFloat(refundAmount) < 0.01 ? 'refundAmount_min_value' : undefined;
  const maxValue =
    refundAmount && parseFloat(refundAmount) > productPrice?.amount
      ? 'refundAmount_max_value'
      : undefined;

  return {
    refundAmount: minValue || maxValue,
  };
};

export default reduxForm<RefundAmountData, Props>({
  validate,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(RefundAmountForm);
