import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import Loader from '@contactcentre-web/common/Loader';

import messages from './messages';
import styles from './styles';

const ResendEmailLoader = () => (
  <div className={css(styles.container)}>
    <h1 className={css(styles.message)}>
      <FormattedMessage {...messages.checking} />
    </h1>
    <Loader />
  </div>
);

export default ResendEmailLoader;
