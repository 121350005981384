import { defineMessages } from 'react-intl';

export default defineMessages({
  Capitaine: {
    id: 'LegacyResult.capitaine',
    defaultMessage:
      'This is a 25KV order and can’t be opened here. Please <a>login to Capitaine</a> to check this order.',
  },
  Tracs: {
    id: 'LegacyResult.tracs',
    defaultMessage:
      'This is a Tracs order and can’t be opened here. Please <a>login to Tracs</a> to check this order.',
  },
});
