import React, { useEffect } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage, useIntl } from 'react-intl';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { Paragraph, StatusMessage } from '@trainline/depot-web';

import TextField from '@contactcentre-web/common/TextField';
import {
  PaperTicketQueueName,
  PaperTicketValidateRequest,
  Ticket,
  usePaperTicketValidate,
} from '@contactcentre-web/hooks/api/usePaperTicketQueue';
import Button from '@contactcentre-web/common/Button';

import { styles } from './styles';
import messages from './messages';

export const FORM_ID = 'paperTicketForm';

export interface PaperTicketFormProps {
  ticket: Ticket;
  currentTab: PaperTicketQueueName;
}

export const PaperTicketForm = ({
  ticket,
  currentTab,
  handleSubmit,
  initialize,
}: PaperTicketFormProps & InjectedFormProps<PaperTicketValidateRequest, PaperTicketFormProps>) => {
  const { formatMessage } = useIntl();
  const {
    mutate,
    error,
    reset: resetFormSubmission,
    isLoading: isSubmittingForm,
  } = usePaperTicketValidate(currentTab);

  const {
    transactionId,
    batchNumber,
    sequenceNumber,
    fromStationName,
    toStationName,
    price: { amount, currencyCode } = {},
    ticketNumber,
    validUntil,
  } = ticket;

  useEffect(() => {
    if (ticket) {
      initialize({
        transactionId,
        batchNumber,
        sequenceNumber,
        fromStationName,
        toStationName,
        price: `${amount} ${currencyCode}`,
        ticketNumber,
        validUntil,
      });
      resetFormSubmission();
    }
  }, [ticket, initialize]);

  const handleFormSubmit = (form: PaperTicketValidateRequest) => {
    mutate({
      id: ticket.id,
      transactionId: form.transactionId,
      fromStationName: form.fromStationName,
      toStationName: form.toStationName,
      validUntil: form.validUntil,
      price: form.price,
      batchNumber: form.batchNumber,
      sequenceNumber: form.sequenceNumber,
      scannedDate: ticket.scannedDate,
      ticketNumber: form.ticketNumber,
      nlcId: ticket.nlcId,
      machineId: ticket.machineId,
      windowId: ticket.windowId,
    });
  };

  return (
    <div className={css(styles.container)}>
      {error && (
        <div className={css(styles.errorContainer)}>
          <StatusMessage status="negative">
            {error.errors?.validationSuccessful === false ? (
              <FormattedMessage {...messages.validationUnsuccessful} />
            ) : (
              <FormattedMessage {...messages.unknownError} />
            )}
          </StatusMessage>
        </div>
      )}
      {ticket && (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className={css(styles.row)}>
            <Paragraph as="p" typeStyle="small" color="base">
              <FormattedMessage
                {...messages.batchNumber}
                values={{ batchNumber: <strong>{batchNumber}</strong> }}
              />
            </Paragraph>
            <Paragraph as="p" typeStyle="small" color="base">
              <FormattedMessage
                {...messages.sequenceNumber}
                values={{ sequenceNumber: <strong>{sequenceNumber}</strong> }}
              />
            </Paragraph>
          </div>
          <div className={css(styles.field)}>
            <TextField
              name="transactionId"
              label={formatMessage(messages.transactionId)}
              messages={messages}
            />
          </div>
          <div className={css(styles.row)}>
            <TextField
              name="fromStationName"
              label={formatMessage(messages.from)}
              messages={messages}
            />
            <TextField
              name="toStationName"
              label={formatMessage(messages.to)}
              messages={messages}
            />
          </div>
          <div className={css(styles.row)}>
            <TextField name="price" label={formatMessage(messages.price)} messages={messages} />
            <TextField
              name="validUntil"
              label={formatMessage(messages.validUntil)}
              messages={messages}
            />
          </div>
          <div className={css(styles.field)}>
            <TextField
              name="ticketNumber"
              label={formatMessage(messages.ticketNumber)}
              messages={messages}
            />
          </div>
          <Button
            variant="primary"
            size="small"
            styleSheet={styles.button}
            type="submit"
            loading={isSubmittingForm}
          >
            <FormattedMessage {...messages.validate} />
          </Button>
        </form>
      )}
    </div>
  );
};

const validate = ({ transactionId }: PaperTicketValidateRequest) => ({
  transactionId: !transactionId ? 'validation_transactionId_required' : undefined,
});

export default reduxForm<PaperTicketValidateRequest, PaperTicketFormProps>({
  form: FORM_ID,
  validate,
})(PaperTicketForm);
