import { StyleSheet } from 'aphrodite/no-important';
import { palettes, spacers, borderRadius } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  loader: {
    marginRight: spacers.dp_s16,
  },
  error: {
    display: 'flex',
    alignItems: 'center',
    color: palettes.dp_app_warning_mid,
  },
  errorText: {
    marginLeft: spacers.dp_s4,
  },
  languageImg: {
    verticalAlign: 'middle',
    maxWidth: '19px',
  },
  language: {
    textTransform: 'uppercase',
    marginLeft: spacers.dp_s8,
    verticalAlign: 'middle',
  },
  logo: {
    backgroundColor: palettes.dp_brand_primary_core,
    color: palettes.dp_brand_white,
    fontFamily: 'Trainline',
    display: 'inline-block',
    width: spacers.dp_s16,
    height: spacers.dp_s16,
    lineHeight: spacers.dp_s16,
    textAlign: 'center',
    marginRight: spacers.dp_s8,
    borderRadius: borderRadius.dp_r2,
  },
});
