import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { IconClose } from '@trainline/depot-web';

import Button from '../../../Button';
import { FilterType } from '../../types';

import messages from './messages';
import styles from './styles';

interface Props {
  appliedFilters: Set<FilterType>;
  removeFilter: (filter: FilterType) => void;
}

const AppliedFilters = ({ appliedFilters, removeFilter }: Props) => (
  <>
    {Array.from(appliedFilters).map((appliedFilter) => (
      <span
        key={appliedFilter}
        className={css(styles.appliedFilter)}
        data-testid={`appliedFilter-${appliedFilter}`}
      >
        <FormattedMessage {...messages[appliedFilter]} />
        <Button
          variant="ghost"
          styleSheet={styles.removeButton}
          onClick={() => removeFilter(appliedFilter)}
          testId="removeIcon"
        >
          <IconClose className={css(styles.icon)} />
        </Button>
      </span>
    ))}
  </>
);

export default AppliedFilters;
