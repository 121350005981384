import { StyleSheet } from 'aphrodite/no-important';
import { palettes, spacers, typography, fonts } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    padding: spacers.dp_s24,
  },
  notification: {
    marginBottom: spacers.dp_s24,
  },
  columns: {
    display: 'grid',
    gridTemplateColumns: '3fr 2fr 0.7fr',
    columnGap: spacers.dp_s32,
  },
  feesContainer: {
    width: '100%',
    justifyContent: 'space-between',
    ':not(:last-of-type)': {
      marginBottom: spacers.dp_s16,
    },
  },
  label: {
    color: palettes.dp_app_text_subdued,
    fontSize: typography.dp_mini_font_size_m,
    lineHeight: typography.dp_mini_line_height_m,
    fontWeight: fonts.dp_font_weight_semibold,
    textTransform: 'uppercase',
  },
  value: {
    color: palettes.dp_app_text_base,
    fontWeight: fonts.dp_font_weight_bold,
  },
  button: {
    marginBottom: spacers.dp_s16,
  },
  overrideButton: {
    marginTop: spacers.dp_s16,
  },
  reasonName: {
    marginTop: spacers.dp_s16,
    fontSize: typography.dp_small_font_size_m,
  },
  ticketTypeList: {
    listStylePosition: 'inside',
    fontSize: typography.dp_mini_font_size_m,
    fontWeight: fonts.dp_font_weight_regular,
  },
  expiredQuoteTooltip: {
    display: 'flex',
    flexDirection: 'column',
  },
});
