import { defineMessages } from 'react-intl';

export default defineMessages({
  approvalQueue: {
    id: 'VirtualTerminalApprovalQueue.approvalQueue',
    defaultMessage: 'Approval queue',
  },
  virtualTerminal: {
    id: 'VirtualTerminalApprovalQueue.virtualTerminal',
    defaultMessage: 'Virtual terminal',
  },
});
