import { createActions } from 'redux-actions';
import { createSelector } from 'reselect';
import moment from 'moment';
import * as semver from 'semver';

import * as userSelector from '@contactcentre-web/authentication/redux/selectors';

const PREFIX = 'ORDER_NOTES/';

export const LOAD_CALL_CRM_QUEUES = `${PREFIX}LOAD_CALL_CRM_QUEUES`;
export const LOAD_CALL_CRM_QUEUES_SUCCESS = `${PREFIX}LOAD_CALL_CRM_QUEUES_SUCCESS`;
export const LOAD_CALL_CRM_QUEUES_FAILED = `${PREFIX}LOAD_CALL_CRM_QUEUES_FAILED`;
export const LOAD_CALL_CRM_QUEUES_NO_SITE_USER_ERROR = `${PREFIX}LOAD_CALL_CRM_QUEUES_NO_SITE_USER_ERROR`;
export const LOAD_SURVEY_CHOICES = `${PREFIX}LOAD_SURVEY_CHOICES`;
export const LOAD_SURVEY_CHOICES_SUCCESS = `${PREFIX}LOAD_SURVEY_CHOICES_SUCCESS`;
export const LOAD_SURVEY_CHOICES_FAILED = `${PREFIX}LOAD_SURVEY_CHOICES_FAILED`;
export const SAVE_ORDER_NOTE_RESET = `${PREFIX}SAVE_ORDER_NOTE_RESET`;
export const SAVE_ORDER_NOTE_ATTEMPT = `${PREFIX}SAVE_ORDER_NOTE_ATTEMPT`;
export const SAVE_ORDER_NOTE_SUCCESS = `${PREFIX}SAVE_ORDER_NOTE_SUCCESS`;
export const SAVE_ORDER_NOTE_FAILED = `${PREFIX}SAVE_ORDER_NOTE_FAILED`;
export const ADS_LOG_OUT_SUCCESS = `${PREFIX}ADS_LOG_OUT_SUCCESS`;
export const ADS_LOG_OUT_FAILED = `${PREFIX}ADS_LOG_OUT_FAILED`;
export const LOAD_ADS_VERSION_COMPATIBLE = `${PREFIX}LOAD_ADS_VERSION_COMPATIBLE`;
export const LOAD_ADS_VERSION_INCOMPATIBLE = `${PREFIX}LOAD_ADS_VERSION_INCOMPATIBLE`;
export const LOAD_ADS_VERSION_FAILED = `${PREFIX}LOAD_ADS_VERSION_FAILED`;
export const LOAD_ADS_LOGGED_IN_STATUS_SUCCESS = `${PREFIX}LOAD_ADS_LOGGED_IN_STATUS_SUCCESS`;
export const LOAD_ADS_LOGGED_IN_STATUS_FAILED = `${PREFIX}LOAD_ADS_LOGGED_IN_STATUS_FAILED`;
export const CHANGE_ADS_TOKEN = `${PREFIX}CHANGE_ADS_TOKEN`;
export const CHANGE_ADS_TOKEN_SUCCESS = `${PREFIX}CHANGE_ADS_TOKEN_SUCCESS`;
export const CHANGE_ADS_TOKEN_FAILED = `${PREFIX}CHANGE_ADS_TOKEN_FAILED`;
export const LOAD_ADS_LOGGED_IN = `${PREFIX}LOAD_ADS_LOGGED_IN`;
export const LOAD_ADS_LOGGED_IN_SUCCESS = `${PREFIX}LOAD_ADS_LOGGED_IN_SUCCESS`;
export const LOAD_ADS_LOGGED_IN_FAILED = `${PREFIX}LOAD_ADS_LOGGED_IN_FAILED`;
export const ADS_RETRY_COUNT = `${PREFIX}ADS_RETRY_COUNT`;
export const ADS_RETRY_COUNT_SUCCESS = `${PREFIX}ADS_RETRY_COUNT_SUCCESS`;
export const ADS_RETRY_COUNT_FAILED = `${PREFIX}ADS_RETRY_COUNT_FAILED`;
export const LOG_ACS_LOAD_CALL_NOT_FOUND = `${PREFIX}LOG_ACS_LOAD_CALL_NOT_FOUND`;
export const LOG_ACS_LOAD_CALL_FAILURE = `${PREFIX}LOG_ACS_LOAD_CALL_FAILURE`;
export const LOG_CRM_REASON_CODES_EMPTY = `${PREFIX}LOG_CRM_REASON_CODES_EMPTY`;
export const LOG_CRM_REASON_CODES_FAILURE = `${PREFIX}LOG_CRM_REASON_CODES_FAILURE`;

export const loadStates = {
  NONE: `${PREFIX}NONE`,
  INPROGRESS: `${PREFIX}INPROGRESS`,
  SUCCESS: `${PREFIX}SUCCESS`,
  ERROR: `${PREFIX}ERROR`,
};

export const adsStates = {
  ACTIVE: `ADS/ACTIVE`,
  INACTIVE: `ADS/INACTIVE`,
  INCOMPATIBLE: `ADS/INCOMPATIBLE`,
};

export const { orderNotes: actions } = createActions({
  [LOAD_CALL_CRM_QUEUES]: () => null,
  [LOAD_CALL_CRM_QUEUES_SUCCESS]: (queues) => (queues && queues.items ? queues.items : queues),
  [LOAD_CALL_CRM_QUEUES_NO_SITE_USER_ERROR]: (error) => error,
  [LOAD_CALL_CRM_QUEUES_FAILED]: (error) => error,
  [LOAD_SURVEY_CHOICES]: () => null,
  [LOAD_SURVEY_CHOICES_SUCCESS]: (surveyChoices) =>
    surveyChoices && surveyChoices.items ? surveyChoices.items : surveyChoices,
  [LOAD_SURVEY_CHOICES_FAILED]: (error) => error,
  [SAVE_ORDER_NOTE_RESET]: () => null,
  [SAVE_ORDER_NOTE_ATTEMPT]: (formId, values) => ({ formId, values }),
  [SAVE_ORDER_NOTE_SUCCESS]: (payload) => payload,
  [SAVE_ORDER_NOTE_FAILED]: (e) => ({ e }),
  [LOAD_ADS_VERSION_COMPATIBLE]: (version) => version,
  [LOAD_ADS_VERSION_INCOMPATIBLE]: (minVersion) => minVersion,
  [LOAD_ADS_VERSION_FAILED]: () => null,
  [LOAD_ADS_LOGGED_IN_STATUS_SUCCESS]: (isLoggedIn) => isLoggedIn,
  [LOAD_ADS_LOGGED_IN_STATUS_FAILED]: () => null,
  [CHANGE_ADS_TOKEN]: () => null,
  [CHANGE_ADS_TOKEN_SUCCESS]: (data) => data,
  [CHANGE_ADS_TOKEN_FAILED]: () => null,
  [LOAD_ADS_LOGGED_IN]: () => null,
  [LOAD_ADS_LOGGED_IN_SUCCESS]: (data) => data,
  [LOAD_ADS_LOGGED_IN_FAILED]: () => null,
  [ADS_LOG_OUT_SUCCESS]: () => null,
  [ADS_LOG_OUT_FAILED]: () => null,
  [ADS_RETRY_COUNT]: () => null,
  [ADS_RETRY_COUNT_SUCCESS]: () => null,
  [ADS_RETRY_COUNT_FAILED]: () => null,
  [LOG_ACS_LOAD_CALL_NOT_FOUND]: (payload) => ({
    ...payload,
    description: 'missing-call-id',
  }),
  [LOG_ACS_LOAD_CALL_FAILURE]: (payload) => ({
    ...payload,
    description: 'load-call-id-failed',
  }),
  [LOG_CRM_REASON_CODES_EMPTY]: (payload) => ({
    ...payload,
    description: 'empty-reason-codes',
  }),
  [LOG_CRM_REASON_CODES_FAILURE]: (payload) => ({
    ...payload,
    description: 'load-reason-codes-failed',
  }),
});

export const getAdsError = (adsState) => {
  switch (adsState) {
    case adsStates.INCOMPATIBLE:
      return 'incompatible';
    case adsStates.INACTIVE:
      return 'inactive';
    default:
      return undefined;
  }
};

const localState = (state) => state.orderNotes;
const getCallCrmQueues = (state) => state.orderNotes.callQueues.callQueues;
const getCallCrmQueuesErrors = (state) => state.orderNotes.callQueues.error;
const getSurveyChoices = (state) => state.orderNotes.surveyChoices.surveyChoices;
const hasSaved = createSelector(localState, (state) => state.hasSaved);
const isAdsAvailable = (state) => state.orderNotes.adsState === adsStates.ACTIVE;
const getAdsStateError = (state) => getAdsError(state.orderNotes.adsState);
const getAdsVersion = (state) => state.orderNotes.adsVersion;
const isLoggedInToAds = (state) => state.orderNotes.isLoggedInToAds;
const getAdsToken = (state) => state.orderNotes.adsToken;
const getAdsExpiresIn = (state) => state.orderNotes.adsExpiresIn;
const getAdsExpiresAt = (state) => state.orderNotes.adsExpiresAt;
const isAdsValidToken = (state) => {
  const { adsToken, adsExpiresAt } = state.orderNotes;
  if (adsToken === null || typeof adsToken === 'undefined') {
    return false;
  }
  const now = moment();
  const tokenExpire = moment(adsExpiresAt);
  const duration = Math.round(moment.duration(tokenExpire.diff(now)).as('m'));
  return duration > 0;
};
const getAdsRetryCount = (state) => state.orderNotes.adsRetryCount;
const getAdsRetryRefreshTokenCount = (state) => state.orderNotes.adsRetryTokenRefreshCount;

const acsCompatibleMinimumAdsVersion = '^1.6.0';
const isAcsCompatibleVersion = (state) =>
  semver.satisfies(getAdsVersion(state), acsCompatibleMinimumAdsVersion);

const isAcsCompatibleAdsActive = (state) =>
  userSelector.canLogCall(state) && isAcsCompatibleVersion(state);

export const selectors = {
  getCallCrmQueues,
  getSurveyChoices,
  getCallCrmQueuesErrors,
  hasSaved,
  getAdsStateError,
  isAdsAvailable,
  getAdsVersion,
  isLoggedInToAds,
  getAdsToken,
  getAdsExpiresIn,
  getAdsExpiresAt,
  isAdsValidToken,
  getAdsRetryCount,
  getAdsRetryRefreshTokenCount,
  isAcsCompatibleAdsActive,
};
