import { StyleSheet } from 'aphrodite/no-important';
import {
  spacers,
  palettes,
  borderRadius,
  typography,
} from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    background: palettes.dp_brand_white,
    boxShadow: `0 0 4px ${palettes.dp_comp_box_shadow}, 0 2px 6px ${palettes.dp_comp_box_shadow}`,
    borderRadius: borderRadius.dp_r4,
    padding: spacers.dp_s16,
    margin: `${spacers.dp_s12} 0`,
    width: '100%',
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    textAlign: 'center',
  },
  durationDetails: {
    display: 'block',
    color: palettes.dp_app_link_base,
  },
});
