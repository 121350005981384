import { StyleSheet } from 'aphrodite/no-important';
import { typography, spacers, borderRadius } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  cardsContent: {
    width: '324px',
    minHeight: '140px',
    background: colors.gray.alpha8,
    border: `1px solid ${colors.gray.alpha12}`,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.12), 0px 2px 6px rgba(0, 0, 0, 0.12)',
    padding: spacers.dp_s32,
    borderRadius: borderRadius.dp_r2,
    marginRight: spacers.dp_s32,
    marginTop: spacers.dp_s32,
  },
  cardsList: {
    listStyle: 'none',
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    margin: `${spacers.dp_s8} 0`,
  },
  railcardsTitle: {
    marginBottom: spacers.dp_s4,
  },
  railcardsCaption: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    color: colors.gray.alpha54,
    marginTop: spacers.dp_s8,
  },
});
