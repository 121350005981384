import { fonts, spacers } from '@trainline/depot-web/css/themes/trainline';
import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({
  container: {
    margin: `0 0 ${spacers.dp_s16}`,
  },
  errorBookings: {
    listStyleType: 'none',
  },
  errorBookingsItem: {
    fontWeight: fonts.dp_font_weight_bold,
    paddingLeft: spacers.dp_s16,
  },
});
