import { StyleSheet } from 'aphrodite/no-important';
import { palettes, spacers, typography, fonts } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  default: {
    borderRadius: '32px',
    padding: `2px ${spacers.dp_s8}`,
    fontSize: typography.dp_small_font_size_m,
    lineHeight: typography.dp_small_line_height,
    fontWeight: fonts.dp_font_weight_semibold,
    width: 'max-content',
  },
  info: {
    backgroundColor: palettes.dp_comp_message_info_background,
    border: `1px solid ${palettes.dp_comp_message_info_border}`,
    color: palettes.dp_app_info_darker,
  },
  warning: {
    backgroundColor: palettes.dp_comp_message_warning_background,
    border: `1px solid ${palettes.dp_comp_message_warning_border}`,
    color: palettes.dp_app_text_warning,
  },
  positive: {
    backgroundColor: palettes.dp_comp_message_positive_background,
    border: `1px solid ${palettes.dp_comp_message_positive_border}`,
    color: palettes.dp_app_text_positive,
  },
  negative: {
    backgroundColor: palettes.dp_comp_message_negative_background,
    border: `1px solid ${palettes.dp_comp_message_negative_border}`,
    color: palettes.dp_app_negative_darker,
  },
});
