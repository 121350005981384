import { StyleSheet } from 'aphrodite/no-important';
import {
  spacers,
  borderRadius,
  typography,
  fonts,
} from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  railcardLabel: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    padding: `${spacers.dp_s4} ${spacers.dp_s8}`,
    fontWeight: fonts.dp_font_weight_bold,
    color: colors.white,
    borderRadius: borderRadius.dp_r2,
    backgroundColor: '#ed936c',
    marginLeft: spacers.dp_s8,
  },
});
