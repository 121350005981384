import { defineMessages } from 'react-intl';

export default defineMessages({
  bookingInfo: {
    id: 'CustomerRailcard.bookingInfo',
    defaultMessage: 'Booking info',
  },
  renewalOrder: {
    id: 'CustomerRailcard.renewalOrder',
    defaultMessage: 'This is a renewal railcard order.',
  },
});
