import { defineMessages } from 'react-intl';

export default defineMessages({
  header_approve: {
    id: 'RefundActionConfirm.header_approve',
    defaultMessage: 'Confirm discretionary refund',
  },
  header_reject: {
    id: 'RefundActionConfirm.header_reject',
    defaultMessage: 'Reject discretionary refund?',
  },
  body_approve: {
    id: 'RefundActionConfirm.body_approve',
    defaultMessage:
      'Are you sure you wish to refund {total} to the customer? The refund may take up to 24 hours to be reflected in the customer’s account.',
  },
  body_reject: {
    id: 'RefundActionConfirm.body_reject',
    defaultMessage:
      'Are you sure you wish to reject refund {total} to the customer? Please be aware, the customer is likely to have been assured they will receive a refund.',
  },
  confirm_approve: {
    id: 'RefundActionConfirm.confirm_approve',
    defaultMessage: 'Refund',
  },
  confirm_reject: {
    id: 'RefundActionConfirm.confirm_reject',
    defaultMessage: 'Reject request',
  },
  cancel: {
    id: 'RefundActionConfirm.cancel',
    defaultMessage: 'Cancel',
  },
});
