import { put, call, race, take, takeLatest, all, select } from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';
import type { Action } from 'redux-actions';
import { delay } from 'redux-saga';

import { APP_INIT, selectors as featureSelectors } from '@contactcentre-web/feature-flags/module';
import tokenStore, { Token } from '@contactcentre-web/utils/tokenStore';
import request from '@contactcentre-web/utils/request';
import { setManagedGroup } from '@contactcentre-web/utils/tracker';

import { actionTypes as profileActionTypes } from '../profile/module';
import {
  actions,
  LOGIN_REQUESTED,
  LOGOUT,
  PASSWORD_CHANGE_SUCCEEDED,
  flowActions,
} from '../module';
import { actions as managedGroupActions, SUBMIT_SUCCESS, PREFIX } from '../managedGroup/module';
import {
  Features,
  getUserDetails,
  ManagedGroup,
  Permissions,
  UserDetails,
  ManagedGroupWithId,
  getBusinessSettings,
  BusinessSettings,
} from '../service';
import { getUserProfile } from '../profile/service';

import loginService, { LoginResponse, Credentials } from './service';

export function* onLoginSucceeded() {
  const {
    userId,
    userName,
    firstName,
    lastName,
    site,
    permissions,
    homeManagedGroupId,
    managedGroup,
    managedGroups,
    roleName,
    features,
  }: UserDetails = yield call(getUserDetails);

  yield put(
    actions.loginSucceeded({
      userId,
      name: userName,
      lastName,
      firstName,
      permissions,
      site,
      homeManagedGroupId,
      managedGroup,
      managedGroups,
      roleName,
      features,
    })
  );

  yield call(setManagedGroup, managedGroup.number);
}

export function* fetchBusinessSettings() {
  try {
    const response: BusinessSettings = yield call(getBusinessSettings);

    yield put(actions.businessSettingsRequestSuccess(response));
  } catch (error) {
    yield put(actions.businessSettingsRequestFail());
  }
}

export function* refreshToken() {
  const token: Token | undefined = yield call(tokenStore.getToken);
  if (!token?.refresh_token) {
    return null;
  }
  try {
    const newToken: Token | undefined = yield call(loginService.refreshToken);
    yield call(onLoginSucceeded, newToken);
    return newToken;
  } catch (e) {
    yield put(actions.logout());
    return null;
  }
}

const refreshRateOverrideInMs = 30000;
const offsetBeforeTokenExpirationInMs = 5000;
export function* refreshTokenLoop() {
  const refreshBeforeTokenExpiration: boolean = yield select(
    featureSelectors.isShortenAccessTokenLifetimeEnabled
  );
  let currentToken: Token | undefined = yield call(tokenStore.getToken);
  if (!currentToken) {
    return;
  }

  while (true) {
    const timeRemaining = currentToken?.expires_at
      ? currentToken.expires_at.getTime() - Date.now()
      : 0;
    const timeToDelay = refreshBeforeTokenExpiration
      ? refreshRateOverrideInMs
      : timeRemaining - offsetBeforeTokenExpirationInMs;
    yield delay(timeToDelay);

    currentToken = yield call(tokenStore.getToken);
    if (!currentToken) {
      return;
    }

    currentToken = yield call(refreshToken);
    if (!currentToken) {
      return;
    }
  }
}

export function* validateToken() {
  const token: Token = yield call(tokenStore.getToken);
  if (!token) {
    return;
  }

  try {
    const isValid: boolean = yield call(tokenStore.validTokenExists);
    if (isValid) {
      yield call(onLoginSucceeded, token);
      yield call(fetchBusinessSettings);
    }
    yield call(refreshTokenLoop);
  } catch {
    yield put(actions.logout());
  }
}

interface LoginAction {
  credentials: {
    username: string;
    password: string;
  };
  formId: string;
  redirect: string | null;
}

export function* loginSaga(loginRequestedAction: Action<LoginAction>) {
  const { credentials, formId, redirect } = loginRequestedAction.payload;
  yield put(startSubmit(formId));
  try {
    let response: LoginResponse = yield call(loginService.login, credentials);
    let userId: string;
    let userName: string;
    let firstName: string;
    let lastName: string;
    let site: string;
    let permissions: Permissions;
    let homeManagedGroupId: string;
    let managedGroup: ManagedGroupWithId;
    let managedGroups: Array<ManagedGroup>;
    let roleName: string;
    let features: Features;

    if (!response || 'status' in response) {
      throw response.status;
    }

    if (response.passwordResetRequired) {
      yield put(stopSubmit(formId, {}));
      yield put(actions.authenticationSucceeded());
      yield put(
        actions.forwardAuthenticationFlow(flowActions.resetPassword, {
          username: credentials.username,
        })
      );

      const {
        passwordChanged,
        logout,
      }: { passwordChanged: Action<{ credentials: Credentials }>; logout: Action<null> } =
        yield race({
          passwordChanged: take(PASSWORD_CHANGE_SUCCEEDED),
          logout: take(LOGOUT),
        });

      if (logout) {
        return;
      }

      const {
        credentials: { password },
      } = passwordChanged.payload;
      response = yield call(loginService.login, {
        username: credentials.username,
        password,
      });
    }

    ({
      userId,
      userName,
      firstName,
      lastName,
      site,
      permissions,
      homeManagedGroupId,
      managedGroup,
      managedGroups,
      roleName,
      features,
    } = yield call(getUserDetails));

    const profile = getUserProfile(userId) || {};

    if (!profile.locale) {
      yield put(actions.authenticationSucceeded(userId));
      yield put(actions.forwardAuthenticationFlow(flowActions.selectUILanguage));

      const { loggedOut } = yield race({
        localeSelected: take(profileActionTypes.SET_LOCALE),
        loggedOut: take(LOGOUT),
      });

      if (loggedOut) {
        return;
      }
    }

    if (!managedGroups || managedGroups.length === 0) {
      throw new Error('No managed groups returned.');
    }

    if (managedGroups.length > 1) {
      yield put(
        actions.authenticationSucceeded(
          userId,
          userName,
          firstName,
          lastName,
          site,
          permissions,
          homeManagedGroupId,
          managedGroups
        )
      );

      yield put(actions.forwardAuthenticationFlow(flowActions.selectManagedGroup));

      const { loggedOut }: { loggedOut: Action<null> } = yield race({
        managedGroupSelected: take(`${PREFIX}/${SUBMIT_SUCCESS}`),
        loggedOut: take(LOGOUT),
      });

      if (loggedOut) {
        return;
      }
    } else {
      // Switch managed group
      yield call(request, '/connect/switch-managed-group', {
        method: 'POST',
        body: { managedGroup: managedGroups[0].number },
      });
    }

    ({
      userId,
      userName,
      firstName,
      lastName,
      site,
      permissions,
      homeManagedGroupId,
      managedGroup,
      managedGroups,
      roleName,
      features,
    } = yield call(getUserDetails));

    yield put(actions.forwardAuthenticationFlow(redirect ? decodeURIComponent(redirect) : '/'));
    yield put(
      actions.loginSucceeded({
        userId,
        name: userName,
        lastName,
        firstName,
        permissions,
        site,
        homeManagedGroupId,
        managedGroup,
        managedGroups,
        roleName,
        features,
      })
    );

    yield call(setManagedGroup, managedGroup.number);
    yield race({
      logout: take(LOGOUT),
      validate: call(validateToken),
    });
  } catch (errorStatus) {
    const errorMessage = errorStatus === 403 ? 'Forbidden' : 'Unauthorized';
    yield put(stopSubmit(formId, { _error: errorMessage }));
    yield put(managedGroupActions.resetState());
  }
}

export default function* authSaga() {
  yield all([takeLatest(APP_INIT, validateToken), takeLatest(LOGIN_REQUESTED, loginSaga)]);
}
