import { createSelector, OutputSelector } from 'reselect';

import { FetchError } from '@contactcentre-web/utils/error';
import ProductType from '@contactcentre-web/redux-common/types/ProductType';
import type { FilterType } from '@contactcentre-web/common/BookingSearchResults/types';
import type State from '@contactcentre-web/redux-common/types/State';

import {
  SearchParams,
  SearchResultType,
  SearchResults,
  SearchResultsItem,
} from './utils/searchService';

// Const
const PREFIX = 'simpleSearch';
const SEARCH_RESET = `${PREFIX}/SEARCH_RESET`;
const SEARCH_REQUESTED = `${PREFIX}/SEARCH_REQUESTED`;
const SEARCH_SUCCEEDED = `${PREFIX}/SEARCH_SUCCEEDED`;
const SEARCH_FAILED = `${PREFIX}/SEARCH_FAILED`;
const SEARCH_ADD_FILTERS = `${PREFIX}/SEARCH_ADD_FILTERS`;
const SEARCH_REMOVE_FILTER = `${PREFIX}/SEARCH_REMOVE_FILTER`;

export const constants = {
  SEARCH_RESET,
  SEARCH_REQUESTED,
  SEARCH_SUCCEEDED,
  SEARCH_FAILED,
  SEARCH_ADD_FILTERS,
  SEARCH_REMOVE_FILTER,
};

export interface SimpleSearchState {
  searchResults: SearchResults;
  error: FetchError;
  searching: boolean;
  filters: Set<FilterType>;
}

// Reducer
export const initialState = {
  searchResults: { totalCount: 0, items: [] },
  error: {},
  searching: false,
  filters: new Set(),
};

export interface SearchAction {
  type: string;
  searchResults: SearchResults;
  error: FetchError;
  filters: Set<FilterType>;
  filter: FilterType;
}

const reducer = (state = initialState, action: SearchAction) => {
  switch (action.type) {
    case SEARCH_RESET:
      return initialState;
    case SEARCH_REQUESTED:
      return {
        ...initialState,
        searching: true,
      };
    case SEARCH_SUCCEEDED:
      return {
        ...state,
        searchResults: action.searchResults,
        searching: false,
      };
    case SEARCH_FAILED:
      return {
        ...state,
        searching: false,
        error: action.error,
      };
    case SEARCH_ADD_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case SEARCH_REMOVE_FILTER:
      state.filters.delete(action.filter);
      return {
        ...state,
        filters: new Set(state.filters),
      };
    default:
      return state;
  }
};

export default reducer;

// Actions
const searchReset = () => ({
  type: SEARCH_RESET,
});

const searchRequested = (searchQueryParams: SearchParams) => ({
  type: SEARCH_REQUESTED,
  payload: {
    searchQueryParams,
  },
});

const searchSucceeded = (searchResults = {} as SearchResults, searchQueryParams: SearchParams) => ({
  type: SEARCH_SUCCEEDED,
  searchResults,
  searchQueryParams,
});

const searchFailed = (error: unknown) => ({
  type: SEARCH_FAILED,
  error,
});

const searchAddFilters = (filters: Set<FilterType>) => ({
  type: SEARCH_ADD_FILTERS,
  filters,
});

const searchRemoveFilter = (filter: FilterType) => ({
  type: SEARCH_REMOVE_FILTER,
  filter,
});

export const actions = {
  searchReset,
  searchRequested,
  searchSucceeded,
  searchFailed,
  searchAddFilters,
  searchRemoveFilter,
};

// Selectors

const getSearchResultsSelector = (state: State) => state.simpleSearch.searchResults;
const getSearchingSelector = (state: State) => state.simpleSearch.searching;

export interface SearchResultProductDetails {
  productDetails: {
    from?: string;
    to?: string;
    isReturn?: boolean;
    inventoryReference?: string;
    departureDate?: Date;
    name?: string;
  };
}

interface SearchResultsFinal {
  totalCount: number;
  items: Array<SearchResultsItem & SearchResultProductDetails>;
}

const getSearchResults = createSelector(
  getSearchResultsSelector,
  ({ searchResultType, totalCount, items }) => ({
    totalCount,
    items:
      searchResultType !== SearchResultType.Product
        ? items
        : items.map(({ type, from, to, isReturn, inventoryReference, departureDate, ...rest }) => ({
            type,
            from,
            to,
            isReturn,
            inventoryReference,
            departureDate,
            // productDetails is needed for Bookings.jsx component
            productDetails:
              type === ProductType.TravelProduct
                ? {
                    from,
                    to,
                    isReturn,
                    inventoryReference,
                    departureDate,
                  }
                : {
                    name: rest.name,
                  },
            ...rest,
          })),
  })
) as unknown as OutputSelector<
  State,
  SearchResultsFinal,
  (res: SearchResults) => SearchResultsFinal
>;

const isCustomerSearch = createSelector(
  getSearchResultsSelector,
  ({ searchResultType }) => searchResultType === SearchResultType.Customer
);

const isLegacySearch = createSelector(
  getSearchResultsSelector,
  ({ searchResultType }) =>
    searchResultType === SearchResultType.Capitaine ||
    searchResultType === SearchResultType.LegacyProduct
);

const isProductSearch = createSelector(
  getSearchResultsSelector,
  ({ searchResultType }) => searchResultType === SearchResultType.Product
);

const isProductReference = createSelector(
  getSearchResultsSelector,
  ({ searchResultType }) => searchResultType === SearchResultType.ProductReference
);

const isSearchFailed = (state: State) => state.simpleSearch.error.status != null;

const getSearching = createSelector(getSearchingSelector, (searching) => searching);

const selectAppliedFilters = (state: State) => state.simpleSearch.filters;

export const selectors = {
  isSearchFailed,
  isCustomerSearch,
  isLegacySearch,
  isProductSearch,
  isProductReference,
  getSearchResults,
  getSearching,
  selectAppliedFilters,
};
