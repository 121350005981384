import React from 'react';
import { Field, WrappedFieldMetaProps, BaseFieldProps } from 'redux-form';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { css } from 'aphrodite/no-important';

import CustomTextField, { CustomTextFieldProps } from './components/CustomTextField';
import styles from './styles';

interface FormProps extends Omit<BaseFieldProps, 'name'> {
  input?: { name?: string };
  meta?: WrappedFieldMetaProps;
}

type Props = {
  displayErrorContainer?: boolean;
  messages?: Record<string, MessageDescriptor>;
} & CustomTextFieldProps &
  FormProps;

const TextFieldWrapper = (props: Props) => {
  const {
    input = {},
    messages,
    meta: { touched, error, submitting, form } = {},
    label,
    styleSheet,
    displayErrorContainer = true,
    inputError,
    ...others
  } = props;

  const { name = '' } = input;
  const { formatMessage } = useIntl();
  const smartLabel = !label && messages?.[name] ? formatMessage(messages[name]) : label;

  return (
    <div className={css(styleSheet?.container)}>
      <CustomTextField
        label={smartLabel}
        inputError={displayErrorContainer && ((touched && !!error) || inputError)}
        styleSheet={{
          ...styleSheet,
          container: styles.container,
          errorContainer: styles.errorContainer,
        }}
        disabled={submitting}
        formName={form}
        {...input}
        {...others}
      />
      {displayErrorContainer && touched && error && messages?.[error] && (
        <div className={css(styles.errorContainer, styleSheet?.errorContainer)}>
          <FormattedMessage {...messages[error]} />
        </div>
      )}
    </div>
  );
};

type TextFieldProps = { name?: string } & Props;

const TextField = ({ name, ...otherProps }: TextFieldProps) =>
  // if there is a name we automatically connect to redux-form
  name ? (
    <Field name={name} component={TextFieldWrapper} {...(otherProps as any)} />
  ) : (
    <CustomTextField
      styleSheet={{
        container: styles.container,
        errorContainer: styles.errorContainer,
      }}
      {...otherProps}
    />
  );

export default TextField;
