import { StyleSheet } from 'aphrodite/no-important';
import {
  fonts,
  spacers,
  typography,
  borderRadius,
} from '@trainline/depot-web/css/themes/trainline';

import { blue, gray } from '@contactcentre-web/styles/colors';

const button = {
  borderRadius: borderRadius.dp_r2,
  borderWidth: '1px',
  borderStyle: 'solid',
  textAlign: 'center',
  fontWeight: fonts.dp_font_weight_bold,
  fontSize: typography.dp_micro_font_size,
  lineHeight: typography.dp_micro_line_height,
  letterSpacing: typography.dp_micro_letter_spacing,
  padding: `${spacers.dp_s8} ${spacers.dp_s24}`,
  transition: 'border-color, color, background-color 150ms ease-in-out',
};

const outlinedStyle = {
  borderColor: blue.dark,
  backgroundColor: 'transparent',
  color: blue.dark,
  ':disabled': {
    borderColor: gray.alpha12,
    color: gray.alpha12,
    pointerEvents: 'none',
  },
};

const solidStyle = {
  borderColor: 'transparent',
  backgroundColor: blue.dark,
  color: '#fff',
  ':disabled': {
    backgroundColor: gray.alpha12,
    pointerEvents: 'none',
  },
};

const styles = StyleSheet.create({
  paginator: {
    width: '100%',
  },
  pageItemContainer: {
    width: '100%',
  },
  pageItem: {
    width: '100%',
  },
  displayBlock: {
    display: 'block',
  },
  displayNone: {
    display: 'none',
  },
  footer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: spacers.dp_s32,
    position: 'relative',
  },
  pageCount: {
    fontSize: typography.dp_small_font_size_m,
    letterSpacing: typography.dp_small_letter_spacing,
    lineHeight: typography.dp_small_line_height,
    fontWeight: fonts.dp_font_weight_regular,
    color: blue.dark,
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  solid: {
    ...button,
    ...solidStyle,
    marginLeft: 'auto',
    ':hover': {
      ...outlinedStyle,
    },
  },
  outline: {
    ...button,
    ...outlinedStyle,
    ':hover': {
      ...solidStyle,
    },
  },
});

export default styles;
