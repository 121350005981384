import React from 'react';

import VirtualTerminal from '@contactcentre-web/virtual-terminal/VirtualTerminal';
import PageContainer from '@contactcentre-web/common/PageContainer/PageContainer';
import ErrorBoundary from '@contactcentre-web/common/ErrorBoundary/ErrorBoundary';

const VirtualTerminalPage = () => (
  <PageContainer>
    <ErrorBoundary>
      <VirtualTerminal />
    </ErrorBoundary>
  </PageContainer>
);

export default VirtualTerminalPage;
