import React from 'react';

import SearchResults from '@contactcentre-web/search-results/SearchResults';
import PageContainer from '@contactcentre-web/common/PageContainer';

import styles from './styles';

const SearchResultsPage = () => (
  <PageContainer styleSheet={styles.container}>
    <SearchResults />
  </PageContainer>
);

export default SearchResultsPage;
