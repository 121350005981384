import { MessageDescriptor } from 'react-intl';

import { useTelephonyStatusPoll } from '@contactcentre-web/hooks/api/useTelephonyStatusPoll';
import {
  useMappingStatusPoll,
  MappingStatuses,
  MappingItem,
} from '@contactcentre-web/hooks/api/useMappingStatusPoll';

import messages from './messages';

type MessageItem = {
  source: MappingItem['source'];
  status: MessageDescriptor;
  isConnected: boolean;
  telephonyUsername?: string;
};

type IconStatus = 'connected' | 'disconnected' | 'warning';

type UseTelephonyReturn = {
  iconStatus: IconStatus;
  tooltipMessages?: MessageItem[] | MessageDescriptor;
  telephonyUsername?: string;
};

export const useTelephonyStatus = (): UseTelephonyReturn => {
  const { data: telephonyStatus, isError: isTelephonyError } = useTelephonyStatusPoll();
  const { data: mappingStatus, isError: isMappingError, error } = useMappingStatusPoll();
  let iconStatus: IconStatus = 'disconnected';

  // Icon logic
  const isTelephonyConnected = !isTelephonyError && telephonyStatus?.loggedIn;
  const items = mappingStatus?.items || [];
  const hasSources = items.length > 0;
  const sourcesStatus = items.map(({ mappingStatus }) => mappingStatus);

  if (isTelephonyConnected && hasSources && !isMappingError) {
    if (sourcesStatus.every((status) => status === MappingStatuses.OK)) {
      iconStatus = 'connected';
    } else if (sourcesStatus.some((status) => status === MappingStatuses.OK)) {
      iconStatus = 'warning';
    }
  }

  // Handling messaging
  const getDefaultMessage = (status: keyof typeof messages) =>
    messages[status] ?? messages.UnexpectedIssue;
  let mappingErrorNotFound =
    error?.response?.status === 404 ? messages.MappingNotFound : messages.UnexpectedIssue;

  if (!isTelephonyError && telephonyStatus && !telephonyStatus.loggedIn) {
    mappingErrorNotFound = messages.LoggedOut;
  }

  let tooltipMessages: MessageItem[] | MessageDescriptor = mappingErrorNotFound;
  if (hasSources) {
    tooltipMessages =
      (isTelephonyConnected &&
        mappingStatus?.items.map(({ source, mappingStatus, telephonyUsername }) => ({
          source,
          status: getDefaultMessage(mappingStatus),
          isConnected: mappingStatus === MappingStatuses.OK,
          telephonyUsername: telephonyUsername,
        }))) ||
      tooltipMessages;
  }

  return {
    iconStatus,
    tooltipMessages,
    telephonyUsername: isTelephonyConnected ? telephonyStatus?.username : '',
  };
};
