import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

import { brand, gray } from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  wrapper: {
    margin: `${spacers.dp_s16} 0`,
    position: 'relative',
  },
  label: {
    marginLeft: spacers.dp_s8,
    color: gray.alpha80,
  },
  input: {
    opacity: 0,
    width: '20px',
    height: '20px',
    display: 'inline-block',
    verticalAlign: 'middle',
    zIndex: 100,
  },
  check: {
    position: 'absolute',
    top: 2,
    left: 0,
    width: '20px',
    height: '20px',
    display: 'block',
    border: `2px solid ${gray.alpha54}`,
    borderRadius: '100%',
    pointerEvents: 'none',
  },
  checked: {
    border: `2px solid ${brand.quirk}`,
  },
  innerCircle: {
    position: 'absolute',
    width: '10px',
    height: '10px',
    top: '50%',
    left: '50%',
    borderRadius: '100%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: brand.quirk,
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
});
