import { StyleSheet } from 'aphrodite/no-important';
import { fonts, typography, spacers } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  customerBookingJourney: {
    margin: `${spacers.dp_s16} ${spacers.dp_s64} 0`,
    paddingBottom: spacers.dp_s16,
    ':last-child': {
      border: 'none',
      marginBottom: spacers.dp_s16,
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  rowBooking: {
    justifyContent: 'space-between',
  },
  greyLabel: {
    color: colors.gray.alpha30,
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    fontWeight: fonts.dp_font_weight_semibold,
    textTransform: 'uppercase',
    margin: `${spacers.dp_s8} 0`,
  },
  middot: {
    padding: spacers.dp_s8,
  },
  fulfilmentStatusIssued: {
    color: '#048231',
    fontWeight: fonts.dp_font_weight_bold,
  },
  fulfilmentStatusIssuing: {
    color: colors.warning.dark,
    fontWeight: fonts.dp_font_weight_bold,
  },
  fulfilmentStatusOthers: {
    color: colors.negative.dark,
    fontWeight: fonts.dp_font_weight_bold,
  },
  duration: {
    marginRight: spacers.dp_s4,
  },
  validityPeriod: {
    paddingTop: spacers.dp_s8,
    fontWeight: fonts.dp_font_weight_bold,
  },
  directions: {
    display: 'flex',
  },
  price: {
    textAlign: 'right',
    paddingTop: spacers.dp_s32,
  },
  title2: {
    fontFamily: typography.dp_title2_font_family,
    fontWeight: fonts.dp_font_weight_bold,
    letterSpacing: '-0.3px', // TODO: replace with depot-web typography when updated in depot-web
    fontSize: typography.dp_body_font_size,
    lineHeight: typography.dp_body_line_height,
    color: colors.gray.alpha80,
  },
  inventoryPrice: {
    fontStyle: 'italic',
    color: colors.gray.alpha54,
  },
  journeyInfo: {
    color: '#647479',
    fontSize: typography.dp_small_font_size_m,
    lineHeight: typography.dp_small_line_height,
    fontWeight: fonts.dp_font_weight_bold,
    margin: `${spacers.dp_s8} 0`,
  },
  changes: {
    marginLeft: spacers.dp_s4,
  },
  delayRepayLabel: {
    textTransform: 'uppercase',
  },
  badge: {
    textTransform: 'capitalize',
  },
});
