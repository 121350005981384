import { StyleSheet } from 'aphrodite/no-important';
import { fonts, spacers, palettes, typography } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  default: {
    color: palettes.dp_app_text_subtle,
    borderRadius: '999px',
    border: `solid 1px ${palettes.dp_app_border_base}`,
    backgroundColor: palettes.dp_comp_button_tertiary_background,
    padding: `${spacers.dp_s4} ${spacers.dp_s12}`,
    fontWeight: fonts.dp_font_weight_regular,
    fontSize: typography.dp_small_font_size,
    display: 'flex',
    ':hover': {
      borderColor: palettes.dp_comp_input_binary_selected_border_base,
      backgroundColor: palettes.dp_app_selection_lightest,
    },
    ':active': {
      borderWidth: '2px',
      borderColor: palettes.dp_comp_input_binary_selected_border_base,
      backgroundColor: palettes.dp_comp_input_binary_background_hover,
      margin: '-1px',
    },
  },
  active: {
    color: palettes.dp_comp_input_binary_selected_border_base,
    backgroundColor: palettes.dp_app_selection_lightest,
    borderWidth: '2px',
    borderColor: palettes.dp_comp_input_binary_selected_border_base,
    fontWeight: fonts.dp_font_weight_semibold,
    margin: '-1px',
  },
  container: {
    display: 'flex',
  },
  buttonWithIcon: {
    display: 'flex',
    alignItems: 'center',
    gap: spacers.dp_s4,
    paddingRight: spacers.dp_s8,
  },
  activeButtonWithRemoveButton: {
    borderRadius: '999px 0 0 999px',
    borderRight: 'none',
    paddingRight: spacers.dp_s4,
  },
  removeButton: {
    borderRadius: '0 999px 999px 0',
    borderLeft: 'none',
    paddingLeft: spacers.dp_s4,
    display: 'inline-flex',
    alignItems: 'center',
    paddingRight: spacers.dp_s8,
    ':hover': {
      backgroundColor: palettes.dp_app_selection_lighter,
    },
  },
});
