import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import PageContainer from '@contactcentre-web/common/PageContainer/PageContainer';
import Frame from '@contactcentre-web/common/Frame';
import priceShape from '@contactcentre-web/utils/shape/price';

import { selectors } from '../../module';

import BookingsTable from './BookingsTable';
import messages from './messages';
import FraudLabel from './FraudLabel';
import ReimbursedSuccessLabel from './ReimbursedSuccessLabel';
import ReimbursedFailLabel from './ReimbursedFailLabel';

export const OrderStatus = ({ bookingsStatusDetails }) => {
  if (!bookingsStatusDetails || bookingsStatusDetails.status.toLowerCase() === 'success') {
    return null;
  }

  const failedBookings = bookingsStatusDetails.bookings.filter(
    ({ fulfilmentStatus, hasBeenReimbursed }) =>
      fulfilmentStatus.toLowerCase() === 'failed' ||
      (fulfilmentStatus.toLowerCase() === 'pending' && hasBeenReimbursed) // HACK: upstream bug, products can get stuck in pending status
  );
  const successReimbursedBookings = failedBookings.filter(
    ({ hasBeenReimbursed, externalPaymentProvider }) =>
      !!hasBeenReimbursed && externalPaymentProvider == null
  );
  const failReimbursedBookings = failedBookings.filter(
    ({ hasBeenReimbursed, externalPaymentProvider }) =>
      !hasBeenReimbursed && externalPaymentProvider == null
  );
  const externalPaymentBookings = failedBookings.filter(
    ({ externalPaymentProvider }) => externalPaymentProvider != null
  );
  const failedInsurances = bookingsStatusDetails.insurancesDetails.filter(
    ({ fulfilmentStatus }) => fulfilmentStatus.toLowerCase() === 'failed'
  );
  const externalPaymentInsurances = failedInsurances.filter(
    ({ reimbursement: { externalPaymentProvider } }) => externalPaymentProvider !== null
  );
  const successReimbursedInsurances = failedInsurances.filter(
    ({ reimbursement: { hasBeenReimbursed, externalPaymentProvider } }) =>
      !!hasBeenReimbursed && externalPaymentProvider === null
  );
  const failReimbursedInsurances = failedInsurances.filter(
    ({ reimbursement: { hasBeenReimbursed, externalPaymentProvider } }) =>
      !hasBeenReimbursed && externalPaymentProvider === null
  );

  const displayReimbursedSuccessLabel =
    (successReimbursedBookings.length > 0 || successReimbursedInsurances.length > 0) &&
    !bookingsStatusDetails.fraudOrder;

  const displayReimbursedFailLabel =
    ((successReimbursedBookings.length === 0 && failedBookings.length > 0) ||
      (successReimbursedInsurances.length === 0 && failedInsurances.length > 0)) &&
    !bookingsStatusDetails.fraudOrder;

  return (
    <PageContainer>
      <Frame
        level={
          failReimbursedBookings.length === 0 &&
          externalPaymentBookings.length === 0 &&
          !bookingsStatusDetails.fraudOrder
            ? 'Warning'
            : 'Failure'
        }
        title={
          <FormattedMessage {...messages[`title_${bookingsStatusDetails.status.toLowerCase()}`]} />
        }
      >
        {!bookingsStatusDetails.fraudOrder && (
          <BookingsTable
            bookings={bookingsStatusDetails.bookings}
            insurances={bookingsStatusDetails.insurancesDetails}
          />
        )}
        {bookingsStatusDetails.fraudOrder && <FraudLabel />}
        {displayReimbursedSuccessLabel && (
          <ReimbursedSuccessLabel
            reimbursedInsurances={successReimbursedInsurances}
            reimbursedBookings={successReimbursedBookings}
            bookingsStatusDetails={bookingsStatusDetails}
            externalPaymentBookings={externalPaymentBookings}
            allFailsAreExternalPaymentBookings={
              failedBookings.length === externalPaymentBookings.length
            }
            allFailsAreExternalPaymentInsurances={
              failedInsurances.length === externalPaymentInsurances.length
            }
          />
        )}
        {displayReimbursedFailLabel && (
          <ReimbursedFailLabel
            reimbursedInsurances={failReimbursedInsurances}
            reimbursedBookings={failReimbursedBookings}
            paymentServiceProvider={bookingsStatusDetails.paymentServiceProvider}
            externalPaymentBookings={externalPaymentBookings}
            allFailsAreExternalPaymentBookings={
              failedBookings.length === externalPaymentBookings.length
            }
            allFailsAreExternalPaymentInsurances={
              failedInsurances.length === externalPaymentInsurances.length
            }
          />
        )}
      </Frame>
    </PageContainer>
  );
};

OrderStatus.propTypes = {
  bookingsStatusDetails: PropTypes.shape({
    status: PropTypes.string,
    reimbursementValue: priceShape,
    feesValue: priceShape,
    bookings: PropTypes.array.isRequired,
    fraudOrder: PropTypes.bool.isRequired,
    paymentServiceProvider: PropTypes.string.isRequired,
    insurancesDetails: PropTypes.arrayOf(PropTypes.object),
  }),
};

const mapStateToProps = (state) => ({
  bookingsStatusDetails: selectors.bookingsStatusDetails(state),
});

const connected = connect(mapStateToProps)(OrderStatus);
connected.displayName = 'OrderStatus';

export default connected;
