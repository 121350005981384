import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  editPassenger: {
    position: 'absolute',
    top: spacers.dp_s12 /* tabButton padding bottom - selection height */,
    right: spacers.dp_s64 /* tab container padding */,
  },
  errorContainer: {
    marginBottom: spacers.dp_s16,
  },
});
