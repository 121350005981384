import React from 'react';
import PropTypes from 'prop-types';

export const RadioBoxGroupContext = React.createContext();

const RadioBoxGroup = ({ children, name, value: valueProp, onChange, meta: { initial } }) => {
  const [value, setValue] = React.useState(initial || valueProp);

  const handleChange = (e) => {
    setValue(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <RadioBoxGroupContext.Provider value={{ name, onChange: handleChange, value }}>
      {React.Children.toArray(children)}
    </RadioBoxGroupContext.Provider>
  );
};

RadioBoxGroup.propTypes = {
  children: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  meta: PropTypes.object,
  initial: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default RadioBoxGroup;
