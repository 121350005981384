import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { startSubmit, stopSubmit, getFormValues } from 'redux-form';
import { call, all, put, select, takeLatest } from 'redux-saga/effects';

import request from '@contactcentre-web/utils/request';
import { getBookings, getOrder } from '@contactcentre-web/redux-common/selectors/order';
import { TravelProduct } from '@contactcentre-web/redux-common/types/TravelProduct';
import type State from '@contactcentre-web/redux-common/types/State';

import REASONS from './reasons.json';

// Constants
export const FORM_ID = 'ReplaceBooking';

const PREFIX = 'REPLACE_BOOKING/';
export const REPLACE_BOOKING_RESET = 'RESET';
export const REPLACE_BOOKING_OPEN = 'OPEN';
export const REPLACE_BOOKING_SHOW = 'SHOW';
export const REPLACE_BOOKING_HIDE = 'HIDE';
export const REPLACE_BOOKING_PENDING = 'PENDING';
export const REPLACE_BOOKING_SUCCESS = 'SUCCESS';
export const REPLACE_BOOKING_FAILED = 'FAILED';

const nullPayload = () => null;

export const actions = createActions(
  {
    [REPLACE_BOOKING_RESET]: nullPayload,
    [REPLACE_BOOKING_OPEN]: (customerId, orderId) => ({ customerId, orderId }),
    [REPLACE_BOOKING_SHOW]: nullPayload,
    [REPLACE_BOOKING_HIDE]: nullPayload,
    [REPLACE_BOOKING_PENDING]: nullPayload,
    [REPLACE_BOOKING_SUCCESS]: (replacementOrderReference) => ({ replacementOrderReference }),
    [REPLACE_BOOKING_FAILED]: nullPayload,
  },
  { prefix: PREFIX }
);

export const initialState = {
  hidden: true,
  pending: false,
  success: false,
  reasons: REASONS,
  replacementOrderReference: undefined,
};

const reducer = handleActions(
  {
    [REPLACE_BOOKING_RESET]: () => initialState,
    [REPLACE_BOOKING_OPEN]: () => initialState,
    [REPLACE_BOOKING_SHOW]: (state) => ({
      ...state,
      hidden: false,
    }),
    [REPLACE_BOOKING_HIDE]: () => initialState,
    [REPLACE_BOOKING_PENDING]: (state) => ({
      ...state,
      pending: true,
    }),
    [REPLACE_BOOKING_SUCCESS]: (state, { payload: { replacementOrderReference } }) => ({
      ...state,
      pending: false,
      success: true,
      replacementOrderReference,
    }),
    [REPLACE_BOOKING_FAILED]: (state) => ({
      ...state,
      pending: false,
    }),
  },
  initialState,
  { prefix: PREFIX }
);

export default reducer;

export interface ReplaceReason {
  value: string;
}

export interface ReplaceBookingState {
  hidden: boolean;
  pending: boolean;
  reasons: ReplaceReason[];
  success: boolean;
  replacementOrderReference?: string;
}

export interface ReplacementBooking {
  bookingIndex: number;
  from: string;
  to: string;
  departureDay: Date;
  id: string;
}
const isPending = (state: ReplaceBookingState) => state.pending;
const getReasons = (state: ReplaceBookingState) => state.reasons;
const getReplacementOrderReference = (state: ReplaceBookingState) =>
  state.replacementOrderReference;
const isReplaceable = (state: ReplaceBookingState) => state.success || state.hidden;

const getReplacementBookings = createSelector(
  getBookings,
  (bookings: TravelProduct[]): ReplacementBooking[] =>
    bookings.map(({ id, origin, destination, outDate }, index) => ({
      bookingIndex: index + 1,
      from: origin,
      to: destination,
      departureDay: outDate,
      id,
    }))
);

export const selectors = {
  getReasons,
  isPending,
  isReplaceable,
  getReplacementBookings,
  getReplacementOrderReference,
};

export function* openSaga() {
  yield put(actions.show());
}

export const replaceRequest = (
  orderReference: string,
  bookingId: string,
  body: { reason: string }
) => {
  const uri = `/api/orders/${orderReference}/bookings/${bookingId}/replace`;
  const method = 'POST';
  return request(uri, {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });
};

export type ReplaceFormData = {
  bookingId: string;
  reason: string;
};

export function* replaceSaga() {
  yield put(startSubmit(FORM_ID));
  const state: State = yield select();
  const { bookingId, reason } = getFormValues(FORM_ID)(state) as ReplaceFormData;
  const { orderReference } = getOrder(state);
  try {
    const { replacementOrderReference } = yield call(replaceRequest, orderReference, bookingId, {
      reason,
    });
    yield put(stopSubmit(FORM_ID, {}));
    yield put(actions.success(replacementOrderReference));
    yield put(actions.show());
  } catch (err: any) {
    const { validationErrors } = err;
    if (validationErrors && validationErrors.length) {
      yield put(
        stopSubmit(FORM_ID, {
          bookingId: 'notEligible',
        })
      );
      yield put(actions.failed());
    } else {
      yield put(stopSubmit(FORM_ID, {}));
      yield put(actions.failed(err));
    }
  }
}

export function* saga() {
  yield all([
    takeLatest(`${PREFIX}/${REPLACE_BOOKING_OPEN}`, openSaga),
    takeLatest(`${PREFIX}/${REPLACE_BOOKING_PENDING}`, replaceSaga),
  ]);
}
