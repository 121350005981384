import type State from '@contactcentre-web/redux-common/types/State';

export const OPEN_SIDEBAR = 'sidebar/OPEN_SIDEBAR';
export const CLOSE_SIDEBAR = 'sidebar/CLOSE';
export const CLEAR_SIDEBAR = 'sidebar/CLEAR';

export interface SidebarState {
  open?: boolean;
  clear?: boolean;
}

const initialState = {
  open: false,
  clear: false,
};

// Reducer
const reducer = (
  state: SidebarState = initialState,
  action: { type: string; payload?: boolean }
) => {
  switch (action.type) {
    case OPEN_SIDEBAR:
      return { clear: false, open: true };
    case CLOSE_SIDEBAR:
      return { ...state, open: false, clear: action.payload || state.clear };
    case CLEAR_SIDEBAR:
      return { ...state };
    default:
      return state;
  }
};

export default reducer;

// Actions
const openSidebar = () => ({
  type: OPEN_SIDEBAR,
});

const closeSidebar = (clear = false) => ({
  type: CLOSE_SIDEBAR,
  payload: clear,
});

const clearSidebar = () => ({
  type: CLEAR_SIDEBAR,
});

export const actions = {
  openSidebar,
  closeSidebar,
  clearSidebar,
};

// Selectors
const isOpen = ({ sidebar }: State) => sidebar.open;
const getClearSidebar = ({ sidebar }: State) => sidebar.clear;

export const selectors = {
  isOpen,
  getClearSidebar,
};
