import { borderRadius, palettes, spacers } from '@trainline/depot-web/css/themes/trainline';
import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({
  container: {
    border: `solid 1px ${palettes.dp_app_border_base}`,
    borderRadius: borderRadius.dp_r4,
    background: palettes.dp_app_backdrop_dark,
    padding: spacers.dp_s32,
    width: '70%',
  },
  containerWithTransaction: {
    borderRadius: `${borderRadius.dp_r4} ${borderRadius.dp_r4} 0 0`,
  },

  body: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: spacers.dp_s16,
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'flex-end',

    ':nth-child(1n) > div': {
      width: '62.5%',
      marginRight: spacers.dp_s16,
    },
  },
  info: {
    border: `solid 1px ${palettes.dp_app_border_base}`,
    marginTop: spacers.dp_s24,
  },
  button: {
    flexGrow: 1,
  },
  notification: {
    marginBottom: spacers.dp_s16,
  },

  errorContainer: {
    width: '100%',
    paddingLeft: spacers.dp_s8,
  },
  messageContainer: {
    marginTop: spacers.dp_s4,
  },
});
