import { Heading, IconInfo } from '@trainline/depot-web';
import { css } from 'aphrodite/no-important';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency';
import FormattedDateInLondonTz from '@contactcentre-web/common/FormattedDateInLondonTz';
import FormattedTimeInLondonTz from '@contactcentre-web/common/FormattedTimeInLondonTz';
import Tooltip from '@contactcentre-web/common/Tooltip';
import type { CompensationHistory } from '@contactcentre-web/hooks/api/useOrderHistory';

import messages from './messages';
import styles from './styles';

export interface Props {
  compensations: Array<CompensationHistory>;
}

const IconInfoAnchor = () => (
  <span className={css(styles.infoIconWrapper)}>
    <IconInfo className={css(styles.infoIcon)} />
  </span>
);

const CompensationsList = ({ compensations }: Props) => (
  <>
    <Heading typeStyle="title2" as="h2" color="base">
      <FormattedMessage {...messages.title} />
    </Heading>
    {compensations.length === 0 ? (
      <FormattedMessage {...messages.empty} />
    ) : (
      <div className={css(styles.compensations)}>
        <div className={css(styles.compensationsHeader)}>
          <div className={css(styles.compensationsCell, styles.compensationsHeaderCell)}>
            <FormattedMessage {...messages.amount} />
          </div>
          <div
            className={css(
              styles.compensationsCell,
              styles.compensationsHeaderCell,
              styles.wrappedExpando
            )}
          >
            <FormattedMessage {...messages.reason} />
          </div>
          <div className={css(styles.compensationsCell, styles.compensationsHeaderCell)}>
            <FormattedMessage {...messages.statusHeader} />
          </div>
          <div className={css(styles.compensationsCell, styles.compensationsHeaderCell)}>
            <FormattedMessage {...messages.requested} />
          </div>
          <div className={css(styles.compensationsCell, styles.compensationsHeaderCell)}>
            <FormattedMessage {...messages.requestedBy} />
          </div>
          <div className={css(styles.compensationsCell, styles.compensationsHeaderCell)}>
            <FormattedMessage {...messages.completion} />
          </div>
          <div className={css(styles.compensationsCell, styles.compensationsHeaderCell)}>
            <FormattedMessage {...messages.completedBy} />
          </div>
        </div>
        {compensations.map(({ date, user, payload }: CompensationHistory) => {
          const {
            requestedAmount,
            reasonCode,
            status,
            requestNote,
            completingReason,
            completingNote,
            completingAt,
            completingUser,
            id,
          } = payload;
          return (
            <div className={css(styles.compensationsRow)} key={id}>
              <div className={css([styles.compensationsCell, styles.value, styles.amount])}>
                <FormattedCurrency {...requestedAmount} />
              </div>
              <div className={css(styles.compensationsCell, styles.wrappedExpando)}>
                {reasonCode}
                {requestNote && (
                  <Tooltip orientation="Below" anchor={<IconInfoAnchor />}>
                    {requestNote}
                  </Tooltip>
                )}
              </div>
              <div className={css(styles.compensationsCell)}>
                <FormattedMessage {...messages.status} values={{ status }} />
                {(completingNote || completingReason) && (
                  <Tooltip orientation="Below" anchor={<IconInfoAnchor />}>
                    <FormattedMessage
                      {...messages.rejectionReason}
                      values={{
                        rejectionReason: completingReason,
                        b: (msg: string) => <b>{msg}</b>,
                      }}
                    />
                    <br />
                    <FormattedMessage
                      {...messages.rejectionNote}
                      values={{
                        rejectionNote: completingNote,
                        b: (msg: string) => <b>{msg}</b>,
                      }}
                    />
                  </Tooltip>
                )}
              </div>
              <div className={css(styles.compensationsCell)}>
                {date ? (
                  <>
                    <FormattedDateInLondonTz value={date} />{' '}
                    <FormattedTimeInLondonTz value={date} />
                  </>
                ) : (
                  <FormattedMessage {...messages.completionNA} />
                )}
              </div>
              <div className={css(styles.compensationsCell)}>{user?.name}</div>
              <div className={css(styles.compensationsCell)}>
                {completingAt ? (
                  <>
                    <FormattedDateInLondonTz value={completingAt} />{' '}
                    <FormattedTimeInLondonTz value={completingAt} />
                  </>
                ) : (
                  <FormattedMessage {...messages.completionNA} />
                )}
              </div>
              <div className={css(styles.compensationsCell)}>{completingUser?.name}</div>
            </div>
          );
        })}
      </div>
    )}
  </>
);

export default CompensationsList;
