import { createActions, handleActions } from 'redux-actions';

import Price from '@contactcentre-web/redux-common/types/Price';
import { SortDirectionType } from '@contactcentre-web/hooks/shared/useToggleSortDirection';

export const PREFIX = 'COMPENSATION_APPROVALS';
const RESET = `RESET`;
export const LOAD_ATTEMPT = `LOAD_ATTEMPT`;
const LOAD_SUCCESS = `LOAD_SUCCESS`;
const LOAD_PAGINATION_SUCCESS = `LOAD_PAGINATION_SUCCESS`;
const LOAD_ERROR = `LOAD_ERROR`;
export const RESOLUTION_ATTEMPT = `RESOLUTION_ATTEMPT`;
const RESOLUTION_SUCCESS = `RESOLUTION_SUCCESS`;
const RESOLUTION_ERROR = `RESOLUTION_ERROR`;

export const loadStates = {
  NONE: `NONE`,
  INPROGRESS: `INPROGRESS`,
  SUCCESS: `SUCCESS`,
  ERROR: `ERROR`,
};

export const resolutionStates = {
  NONE: `RESOLUTION_NONE`,
  INPROGRESS: `RESOLUTION_INPROGRESS`,
  SUCCESS: `RESOLUTION_SUCCESS`,
  ERROR: `RESOLUTION_ERROR`,
};

export interface Approval {
  id: string;
  orderId: string;
  customerId: string;
  requestedDate: Date;
  requestingUserName: string;
  reasonCode: string;
  note: string;
  isReasonCodeArchived: boolean;
  amount: Price;
}

export interface Pagination {
  page: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
}

export interface CompensationApprovalsState {
  loadStatus: string;
  resolutionStatus: string;
  approvals: Approval[];
  site: string;
  resolutionContext: Record<string, any>;
  errors: Record<string, string>[];
  pagination: Pagination | null;
}

export const actions = createActions(
  {
    [RESET]: () => null,
    [LOAD_ATTEMPT]: (page, perPage, sortDirection) => ({ page, perPage, sortDirection }),
    [LOAD_SUCCESS]: (approvals) => ({ approvals }),
    [LOAD_PAGINATION_SUCCESS]: (payload) => payload,
    [LOAD_ERROR]: (error) => ({ error }),
    [RESOLUTION_ATTEMPT]: (type, id, amount, orderReference, reason, note) => ({
      type,
      id,
      amount,
      orderReference,
      reason,
      note,
    }),
    [RESOLUTION_SUCCESS]: (type, id, amount, orderReference) => ({
      type,
      id,
      amount,
      orderReference,
    }),
    [RESOLUTION_ERROR]: (error) => ({ error }),
  },
  { prefix: PREFIX }
);

export const initialState: CompensationApprovalsState = {
  loadStatus: loadStates.NONE,
  resolutionStatus: resolutionStates.NONE,
  approvals: [],
  site: '',
  resolutionContext: {},
  pagination: null,
  errors: [],
};

interface ActionPayload extends Pagination {
  items: ConcatArray<Approval>;
  approvals: Approval[];
  type: string;
  orderReference: string;
  amount: Price;
  errors: Record<string, string>[];
  sortDirection: SortDirectionType;
}

export default handleActions<CompensationApprovalsState, ActionPayload>(
  {
    [RESET]: () => initialState,
    [LOAD_ATTEMPT]: (state) => ({
      ...state,
      loadStatus: loadStates.INPROGRESS,
    }),
    [LOAD_SUCCESS]: (state, { payload: { approvals, sortDirection } }) => ({
      ...state,
      approvals: approvals || [],
      sortDirection,
      loadStatus: loadStates.SUCCESS,
    }),
    [LOAD_PAGINATION_SUCCESS]: (state, { payload }) => ({
      ...state,
      approvals: state.approvals.concat(payload.items),
      pagination: {
        totalCount: payload.totalCount,
        totalPages: payload.totalPages,
        page: payload.page,
        pageSize: payload.pageSize,
        errors: payload.errors,
      },
      loadStatus: loadStates.SUCCESS,
    }),
    [LOAD_ERROR]: (state) => ({
      ...state,
      loadStatus: loadStates.ERROR,
    }),
    [RESOLUTION_ATTEMPT]: (state, { payload: { type, orderReference, amount } }) => ({
      ...state,
      resolutionStatus: resolutionStates.INPROGRESS,
      resolutionContext: {
        type,
        orderReference,
        amount,
      },
    }),
    [RESOLUTION_SUCCESS]: (state, { payload: { type, orderReference, amount } }) => ({
      ...state,
      resolutionStatus: resolutionStates.SUCCESS,
      resolutionContext: {
        type,
        orderReference,
        amount,
      },
    }),
    [RESOLUTION_ERROR]: (state) => ({
      ...state,
      resolutionStatus: resolutionStates.ERROR,
    }),
  },
  initialState,
  { prefix: PREFIX }
);
