import { createSelector } from 'reselect';
import { createActions, handleActions } from 'redux-actions';
import {
  compose,
  countBy,
  filter,
  flatten,
  map as rMap,
  minBy,
  pluck,
  reduce,
  take as rTake,
  sort,
  toLower,
  uniq,
} from 'ramda';
import { all, call, put, takeLatest, select, take, race } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import moment from 'moment';

import request from '@contactcentre-web/utils/request';
import {
  CUSTOMER_ORDER_LOAD,
  CUSTOMER_ORDER_SUCCESS,
  CUSTOMER_ORDER_FAIL,
  actions as commonActions,
} from '@contactcentre-web/redux-common/actions/order';
import * as commonSelectors from '@contactcentre-web/redux-common/selectors/order';
import * as productSelectors from '@contactcentre-web/redux-common/selectors/products';
import { actions as appBarActions } from '@contactcentre-web/header/module';
import ProductType from '@contactcentre-web/redux-common/types/ProductType';
import { isTravelProduct } from '@contactcentre-web/refunds/utils';

// Constants
const PREFIX = 'CUSTOMER_ORDER';
export const MANUALLY_LOCK_BOOKING = `${PREFIX}/MANUALLY_LOCK_BOOKING`;
export const LOAD_TRANSACTION_SUMMARY = `${PREFIX}/LOAD_TRANSACTION_SUMMARY`;
export const LOAD_TRANSACTION_SUMMARY_SUCCESS = `${PREFIX}/LOAD_TRANSACTION_SUMMARY_SUCCESS`;
export const LOAD_TRANSACTION_SUMMARY_FAIL = `${PREFIX}/LOAD_TRANSACTION_SUMMARY_FAIL`;
export const DELAY_REPAY_CLAIM_LOAD = `${PREFIX}/DELAY_REPAY_CLAIM_LOAD`;
export const DELAY_REPAY_CLAIM_SUCCESS = `${PREFIX}/DELAY_REPAY_CLAIM_SUCCESS`;
export const DELAY_REPAY_CLAIM_FAIL = `${PREFIX}/DELAY_REPAY_CLAIM_FAIL`;
export const CANCEL_RAILCARD_ATTEMPT = `${PREFIX}/CANCEL_RAILCARD_ATTEMPT`;
export const CANCEL_RAILCARD_SUCCESS = `${PREFIX}/CANCEL_RAILCARD_SUCCESS`;
export const CANCEL_RAILCARD_FAIL = `${PREFIX}/CANCEL_RAILCARD_FAIL`;
export const TICKET_STATUSES_ATTEMPT = `${PREFIX}/TICKET_STATUSES_ATTEMPT`;
export const TICKET_STATUSES_SUCCESS = `${PREFIX}/TICKET_STATUSES_SUCCESS`;
export const TICKET_STATUSES_FAIL = `${PREFIX}/TICKET_STATUSES_FAIL`;
export const GENERATE_TICKET_ATTEMPT = `${PREFIX}/GENERATE_TICKET_ATTEMPT`;
export const GENERATE_TICKET_SUCCESS = `${PREFIX}/GENERATE_TICKET_SUCCESS`;
export const GENERATE_TICKET_FAIL = `${PREFIX}/GENERATE_TICKET_FAIL`;
export const START_GENERATE_TICKET_POLLING_TASK = `${PREFIX}/START_GENERATE_TICKET_POLLING_TASK`;
export const STOP_GENERATE_TICKET_POLLING_TASK = `${PREFIX}/STOP_GENERATE_TICKET_POLLING_TASK`;
export const LOAD_TIMETABLES_ATTEMPT = `${PREFIX}/LOAD_TIMETABLES_ATTEMPT`;
export const LOAD_TIMETABLES_SUCCESS = `${PREFIX}/LOAD_TIMETABLES_SUCCESS`;
export const LOAD_TIMETABLES_FAIL = `${PREFIX}/LOAD_TIMETABLES_FAIL`;
export const RESET_SEASON_ATTEMPT = `${PREFIX}/RESET_SEASON_ATTEMPT`;
export const RESET_SEASON_SUCCESS = `${PREFIX}/RESET_SEASON_SUCCESS`;
export const RESET_SEASON_FAIL = `${PREFIX}/RESET_SEASON_FAIL`;
export const CANCEL_SEASON_ATTEMPT = `${PREFIX}/CANCEL_SEASON_ATTEMPT`;
export const CANCEL_SEASON_SUCCESS = `${PREFIX}/CANCEL_SEASON_SUCCESS`;
export const CANCEL_SEASON_FAIL = `${PREFIX}/CANCEL_SEASON_FAIL`;
export const FETCH_TRAVELLERS_ATTEMPT = `${PREFIX}/FETCH_TRAVELLERS_ATTEMPT`;
export const FETCH_TRAVELLERS_SUCCESS = `${PREFIX}/FETCH_TRAVELLERS_SUCCESS`;
export const FETCH_TRAVELLERS_FAIL = `${PREFIX}/FETCH_TRAVELLERS_FAIL`;

export const ORDER_REFRESH = `${PREFIX}/ORDER_REFRESH`;
export const FULFILMENT_UPDATE = `${PREFIX}/FULFILMENT_UPDATE`;

// Actions creator
const { customerOrder: internalActions } = createActions({
  [ORDER_REFRESH]: (customerId, orderReference) => ({ customerId, orderReference }),
  [FULFILMENT_UPDATE]: (order, status) => ({ order, status }),
  [CUSTOMER_ORDER_SUCCESS]: (order, voidable, products) => ({ order, voidable, products }),
  [CUSTOMER_ORDER_FAIL]: (error) => error,
  [MANUALLY_LOCK_BOOKING]: (bookingId) => ({ bookingId }),
  [LOAD_TRANSACTION_SUMMARY]: (orderId) => orderId,
  [LOAD_TRANSACTION_SUMMARY_SUCCESS]: (transactionSummary) => transactionSummary,
  [LOAD_TRANSACTION_SUMMARY_FAIL]: (error) => error,
  [DELAY_REPAY_CLAIM_LOAD]: (orderId) => orderId,
  [DELAY_REPAY_CLAIM_SUCCESS]: (items) => items,
  [DELAY_REPAY_CLAIM_FAIL]: (error) => error,
  [CANCEL_RAILCARD_ATTEMPT]: (product) => product,
  [CANCEL_RAILCARD_SUCCESS]: (payload) => payload,
  [CANCEL_RAILCARD_FAIL]: (error) => error,
  [TICKET_STATUSES_ATTEMPT]: (orderReference, productId) => ({ orderReference, productId }),
  [TICKET_STATUSES_SUCCESS]: (productId, statuses) => ({ productId, statuses }),
  [TICKET_STATUSES_FAIL]: (productId) => ({ productId }),
  [GENERATE_TICKET_ATTEMPT]: (orderReference, productId, farePassengerId) => ({
    orderReference,
    productId,
    farePassengerId,
  }),
  [GENERATE_TICKET_SUCCESS]: (order, voidable) => ({ order, voidable }),
  [GENERATE_TICKET_FAIL]: () => null,
  [START_GENERATE_TICKET_POLLING_TASK]: (orderReference, productId, farePassengerId) => ({
    orderReference,
    productId,
    farePassengerId,
  }),
  [STOP_GENERATE_TICKET_POLLING_TASK]: () => null,
  [LOAD_TIMETABLES_ATTEMPT]: (orderId) => orderId,
  [LOAD_TIMETABLES_SUCCESS]: (payload) => payload,
  [LOAD_TIMETABLES_FAIL]: () => {},
  [RESET_SEASON_ATTEMPT]: (productLinks) => productLinks,
  [RESET_SEASON_SUCCESS]: () => null,
  [RESET_SEASON_FAIL]: () => null,
  [CANCEL_SEASON_ATTEMPT]: (productLinks, orderId) => ({
    productLinks,
    orderId,
  }),
  [CANCEL_SEASON_SUCCESS]: (products) => ({ products }),
  [CANCEL_SEASON_FAIL]: () => null,
  [FETCH_TRAVELLERS_ATTEMPT]: (orderId) => orderId,
  [FETCH_TRAVELLERS_SUCCESS]: (payload) => payload,
  [FETCH_TRAVELLERS_FAIL]: (error) => error,
});

export const actions = {
  ...internalActions,
  ...commonActions,
};

export const initialState = {
  order: null,
  products: null,
  loading: false,
  error: null,
  transactionSummary: {
    loading: false,
    error: null,
    type: null,
    orderTotal: {},
    products: [],
    fees: [],
  },
  delayRepayClaim: {
    loading: false,
    error: null,
    items: [],
  },
  cancelRailcard: {
    loading: false,
    status: null,
    railcardName: '',
  },
  ticketStatuses: {},
  generateTicket: {
    loading: false,
    error: false,
    success: false,
    orderReference: '',
    productId: '',
    farePassengerId: '',
  },
  timetables: {
    loading: false,
    items: [],
  },
  resetSeason: {
    status: null,
  },
  cancelSeason: {
    status: null,
  },
  customerOrderTravellers: null,
};

// Reducer
const reducer = handleActions(
  {
    [CUSTOMER_ORDER_LOAD]: (state) => ({
      ...state,
      order: null,
      products: null,
      error: null,
      loading: true,
      ticketStatuses: {},
      cancelRailcard: {
        loading: false,
        status: null,
        railcardName: '',
      },
      resetSeason: {
        status: null,
      },
      cancelSeason: {
        status: null,
      },
    }),
    [MANUALLY_LOCK_BOOKING]: (state, { payload: { bookingId } }) => ({
      ...state,
      order: {
        ...state.order,
        travelBookings: state.order.travelBookings.map(({ id, ...booking }) => ({
          ...booking,
          id,
          manuallyLocked: booking.manuallyLocked || bookingId === id,
        })),
      },
    }),
    [CUSTOMER_ORDER_SUCCESS]: (state, action) => ({
      ...state,
      order: {
        ...action.payload.order,
        voidable: action.payload.voidable,
      },
      products: action.payload.products.items,
      error: null,
      loading: false,
    }),
    [CUSTOMER_ORDER_FAIL]: (state, action) => ({
      ...state,
      order: null,
      products: null,
      error: action.payload,
      loading: false,
    }),
    [LOAD_TRANSACTION_SUMMARY]: (state) => ({
      ...state,
      transactionSummary: {
        ...state.transactionSummary,
        loading: true,
        error: false,
      },
    }),
    [LOAD_TRANSACTION_SUMMARY_SUCCESS]: (state, action) => ({
      ...state,
      transactionSummary: {
        ...state.transactionSummary,
        ...action.payload,
        loading: false,
        error: false,
      },
    }),
    [LOAD_TRANSACTION_SUMMARY_FAIL]: (state, action) => ({
      ...state,
      transactionSummary: {
        ...state.transactionSummary,
        loading: false,
        error: action.payload,
      },
    }),
    [DELAY_REPAY_CLAIM_LOAD]: (state) => ({
      ...state,
      delayRepayClaim: {
        ...state.delayRepayClaim,
        loading: true,
        error: false,
      },
    }),
    [DELAY_REPAY_CLAIM_SUCCESS]: (state, action) => ({
      ...state,
      delayRepayClaim: {
        ...state.delayRepayClaim,
        ...action.payload,
        loading: false,
        error: false,
      },
    }),
    [DELAY_REPAY_CLAIM_FAIL]: (state, action) => ({
      ...state,
      delayRepayClaim: {
        ...state.delayRepayClaim,
        loading: false,
        error: action.payload,
      },
    }),
    [CANCEL_RAILCARD_ATTEMPT]: (state) => ({
      ...state,
      cancelRailcard: {
        ...state.cancelRailcard,
        loading: true,
        status: null,
        railcardName: '',
      },
    }),
    [CANCEL_RAILCARD_SUCCESS]: (state, action) => ({
      ...state,
      cancelRailcard: {
        ...state.cancelRailcard,
        loading: false,
        status: 'success',
        railcardName: action.payload,
      },
    }),
    [CANCEL_RAILCARD_FAIL]: (state) => ({
      ...state,
      cancelRailcard: {
        ...state.cancelRailcard,
        loading: false,
        status: 'error',
        railcardName: '',
      },
    }),
    [TICKET_STATUSES_ATTEMPT]: (state, { payload: { productId } }) => ({
      ...state,
      ticketStatuses: {
        ...state.ticketStatuses,
        [productId]: {
          ...(state.ticketStatuses[productId] || {}),
          loading: true,
          error: false,
        },
      },
    }),
    [TICKET_STATUSES_SUCCESS]: (state, { payload: { productId, statuses } }) => ({
      ...state,
      ticketStatuses: {
        ...state.ticketStatuses,
        [productId]: {
          statuses,
          loading: false,
        },
      },
    }),
    [TICKET_STATUSES_FAIL]: (state, { payload: { productId } }) => ({
      ...state,
      ticketStatuses: {
        ...state.ticketStatuses,
        [productId]: {
          loading: false,
          error: true,
          statuses: undefined,
        },
      },
    }),
    [GENERATE_TICKET_ATTEMPT]: (state) => ({
      ...state,
      generateTicket: {
        ...state.generateTicket,
        loading: true,
        error: false,
        success: false,
      },
    }),
    [GENERATE_TICKET_SUCCESS]: (state, { payload: { order, voidable } }) => ({
      ...state,
      generateTicket: {
        ...state.generateTicket,
        loading: false,
        error: false,
        success: true,
      },
      order: {
        ...order,
        voidable,
      },
    }),
    [GENERATE_TICKET_FAIL]: (state) => ({
      ...state,
      generateTicket: {
        ...state.generateTicket,
        loading: false,
        error: true,
        success: false,
      },
    }),
    [START_GENERATE_TICKET_POLLING_TASK]: (
      state,
      { payload: { orderReference, productId, farePassengerId } }
    ) => ({
      ...state,
      generateTicket: {
        ...state.generateTicket,
        orderReference,
        productId,
        farePassengerId,
      },
    }),
    [STOP_GENERATE_TICKET_POLLING_TASK]: (state) => ({
      ...state,
      generateTicket: {
        ...state.generateTicket,
        loading: false,
        error: false,
      },
    }),
    [LOAD_TIMETABLES_ATTEMPT]: (state) => ({
      ...state,
      timetables: {
        loading: true,
        items: [],
      },
    }),
    [LOAD_TIMETABLES_SUCCESS]: (state, { payload: { items } }) => ({
      ...state,
      timetables: {
        loading: false,
        items,
      },
    }),
    [LOAD_TIMETABLES_FAIL]: (state) => ({
      ...state,
      timetables: {
        loading: false,
        items: [],
      },
    }),
    [RESET_SEASON_ATTEMPT]: (state) => ({
      ...state,
      resetSeason: {
        status: 'loading',
      },
    }),
    [RESET_SEASON_SUCCESS]: (state) => ({
      ...state,
      ticketStatuses: {},
      resetSeason: {
        status: 'success',
      },
    }),
    [RESET_SEASON_FAIL]: (state) => ({
      ...state,
      resetSeason: {
        status: 'error',
      },
    }),
    [CANCEL_SEASON_ATTEMPT]: (state) => ({
      ...state,
      cancelSeason: {
        status: 'loading',
      },
    }),
    [CANCEL_SEASON_SUCCESS]: (state, { payload: { products } }) => ({
      ...state,
      cancelSeason: {
        status: 'success',
      },
      products: products.items,
    }),
    [CANCEL_SEASON_FAIL]: (state) => ({
      ...state,
      cancelSeason: {
        status: 'error',
      },
    }),
    [FULFILMENT_UPDATE]: (state, { payload: { order, status } }) => ({
      ...state,
      order: {
        ...state.order,
        status,
        travelBookings: state.order.travelBookings.map(({ id, ...booking }) => {
          const orderBooking = order.find(({ id: orderBookingId }) => orderBookingId === id);
          return {
            ...booking,
            id,
            journeys: orderBooking.journeys ?? booking.journeys,
            fulfilmentStatus: orderBooking.fulfilmentStatus ?? booking.fulfilmentStatus,
            ticketAssets: orderBooking.ticketAssets || [],
            trainlineProductStatus:
              orderBooking.trainlineProductStatus ?? booking.trainlineProductStatus,
          };
        }),
      },
    }),
    [ORDER_REFRESH]: (state) => ({
      ...state,
      order: {
        ...state.order,
        travelBookings: state.order.travelBookings.map(({ id, ...booking }) => ({
          ...booking,
          id,
          fulfilmentStatus: null,
          ticketAssets: [],
        })),
      },
    }),
    [FETCH_TRAVELLERS_SUCCESS]: (state, action) => ({
      ...state,
      customerOrderTravellers: action.payload,
    }),
    [FETCH_TRAVELLERS_FAIL]: (state) => ({
      ...state,
      customerOrderTravellers: null,
    }),
  },
  initialState
);

export default reducer;

// Selectors
const getItineraryId = (state) => state.orders.order.travelBookings[0]?.itineraryId;
const getBusinessSettings = (state) => state.users.businessSettings;
const getCustomerOrderTravellers = (state) => state.orders.customerOrderTravellers;
const isLoadingOrder = (state) => state.orders.loading;
const isLoadingTransactionSummary = (state) => state.orders.transactionSummary.loading;
const selectError = (state) => state.orders.error;
const getTransactionSummary = (state) => state.orders.transactionSummary;

const isReturnJourney = (journeys) => journeys.length > 1;

const getTickets = createSelector(commonSelectors.getOrder, (order) => {
  if (!order || !order.travelBookings) {
    return [];
  }

  const { travelBookings: bookings } = order;
  return bookings.map(({ journeys, vendor, isReturn }) =>
    flatten(
      journeys.map(({ legs, origin, destination }) =>
        flatten(
          legs.map(({ passengers }) =>
            flatten(
              passengers.map(({ name, type }) => ({
                passengerName: name,
                passengerType: type,
                vendor,
                isReturn,
                origin,
                destination,
              }))
            )
          )
        )
      )
    )
  );
});

const getCustomerId = (state) => state && state.customerId;
const getCustomerEmail = (state) => state && state.email;
const getTravelBookings = (state) => state && state.travelBookings;

const getBookingsWithConvertedOrder = createSelector(
  getCustomerId,
  getTravelBookings,
  (customerId, bookings) =>
    bookings && bookings[0] && bookings[0].convertedOrderReference
      ? `/customers/${customerId}/bookings/${bookings[0].convertedOrderReference}`
      : null
);

const getConvertedBookings = createSelector(
  getCustomerId,
  getTravelBookings,
  (customerId, bookings) =>
    bookings &&
    bookings
      .map((booking, index) => ({
        bookingNumber: index + 1,
        convertedByLink: booking.convertedByOrderReference
          ? `/customers/${customerId}/bookings/${booking.convertedByOrderReference}`
          : null,
      }))
      .filter((booking) => booking.convertedByLink !== null)
);

const getLink = (state, link) => state.orders.order.links.find((l) => l.rel === link);
const isLoaded = (state) => state.orders.order !== null;

const insurancesBookingDetails = createSelector(
  productSelectors.getProducts,
  commonSelectors.getBookings,
  commonSelectors.getOrder,
  (products, bookings, { reimbursement, travelBookingsHaveSameInsurance }) => {
    let insuranceDetails = [];
    const bookingsWithFailedInsurances = bookings.filter(
      (booking) =>
        booking.insurance &&
        booking.insurance.trainlineProductStatus &&
        booking.insurance.trainlineProductStatus.issueStatus === 'Failed'
    );
    if (bookingsWithFailedInsurances.length && !travelBookingsHaveSameInsurance) {
      insuranceDetails = bookingsWithFailedInsurances.map(({ insurance, journeys }) => ({
        id: insurance.id,
        description: `${insurance.name} - ${insurance.insuranceType} - ${insurance.settlementCountry}`,
        fulfilmentStatus: insurance.trainlineProductStatus.issueStatus,
        errors: insurance.trainlineProductStatus.errors,
        vendor: insurance.vendor,
        vendorStatus: insurance.state,
        reimbursement: {
          hasBeenReimbursed: reimbursement && reimbursement.bookingIds.includes(insurance.id),
          reimbursementValue: reimbursement && reimbursement.value,
          feesValue: reimbursement && reimbursement.totalFees,
          externalPaymentProvider: insurance.trainlineProductStatus.externalPaymentProvider,
        },
        journeyDetails: [
          {
            origin: journeys[0].origin,
            destination: journeys[0].destination,
            isReturn: isReturnJourney(journeys),
          },
        ],
      }));
    } else if (bookingsWithFailedInsurances.length) {
      const failedInsurance = bookingsWithFailedInsurances[0].insurance;
      insuranceDetails = [
        {
          id: failedInsurance.id,
          description: `${failedInsurance.name} - ${failedInsurance.insuranceType} - ${failedInsurance.settlementCountry}`,
          fulfilmentStatus: failedInsurance.trainlineProductStatus.issueStatus,
          errors: failedInsurance.trainlineProductStatus.errors,
          vendor: failedInsurance.vendor,
          vendorStatus: failedInsurance.state,
          reimbursement: {
            hasBeenReimbursed:
              reimbursement && reimbursement.bookingIds.includes(failedInsurance.id),
            reimbursementValue: reimbursement && reimbursement.value,
            feesValue: reimbursement && reimbursement.totalFees,
            externalPaymentProvider: failedInsurance.trainlineProductStatus.externalPaymentProvider,
          },
          journeyDetails: bookings.map(({ journeys }) => ({
            origin: journeys[0].origin,
            destination: journeys[0].destination,
            isReturn: isReturnJourney(journeys),
          })),
        },
      ];
    } else if (products.length) {
      insuranceDetails = products
        .filter(
          (p) => p.type === ProductType.Insurance && p.fulfilmentStatus.toLowerCase() === 'failed'
        )
        .map((i) => ({
          id: i.id,
          description: `${i.name} - ${i.insuranceType} - ${i.settlementCountry || 'N/A'}`,
          fulfilmentStatus: i.fulfilmentStatus,
          errors: [],
          vendor: i.vendor || 'N/A',
          vendorStatus: i.status || 'Locked',
          reimbursement: {
            hasBeenReimbursed: reimbursement && reimbursement.bookingIds.includes(i.id),
            reimbursementValue: reimbursement && reimbursement.value,
            feesValue: reimbursement && reimbursement.totalFees,
            externalPaymentProvider: null,
          },
          journeyDetails: bookings
            .filter((b) => i.insuredProductIds.some((ip) => ip === b.id))
            .map(({ journeys }) => ({
              origin: journeys[0].origin,
              destination: journeys[0].destination,
              isReturn: isReturnJourney(journeys),
            })),
        }));
    }
    return insuranceDetails;
  }
);

const bookingsStatusDetails = createSelector(
  productSelectors.getProducts,
  commonSelectors.getOrder,
  insurancesBookingDetails,
  getTransactionSummary,
  (products, { reimbursement, errors }, insurancesDetails, { paymentServiceProvider }) => {
    if (!products) {
      return undefined;
    }

    const isSeasonProduct = (type) => type === ProductType.Season;
    const isSeasonOrRailcardProduct = (type) =>
      type === ProductType.Railcard || type === ProductType.Season;

    const productsPerStatus = compose(
      countBy(toLower),
      rMap(
        (product) =>
          (isSeasonOrRailcardProduct(product.type)
            ? product.fulfilmentStatus
            : product.trainlineProductStatus && product.trainlineProductStatus.issueStatus) || ''
      )
    )(products);

    const insurancesPerStatus = compose(
      countBy(toLower),
      rMap((insurance) => insurance.fulfilmentStatus || '')
    )(insurancesDetails);

    const fraudOrder = errors ? errors.some((e) => e.code === '66001.50') : false;
    let status = 'Success';
    if (fraudOrder) {
      status = 'Failure';
    } else if (productsPerStatus.failed > 0 || insurancesPerStatus.failed > 0) {
      status = productsPerStatus.failed === products.length ? 'Failure' : 'PartialFailure';
    }

    return {
      status,
      insurancesDetails,
      reimbursementValue: reimbursement && reimbursement.value,
      feesValue: reimbursement && reimbursement.totalFees,
      bookings: products
        .filter(({ type }) => type !== ProductType.Insurance)
        .map(
          ({
            id,
            type,
            origin,
            destination,
            isReturn,
            journey,
            name,
            vendor,
            vendorRegion,
            status: productStatus,
            fulfilmentStatus,
            trainlineProductStatus,
          }) => ({
            id,
            type,
            origin: isSeasonProduct(type) ? journey.origin : origin,
            destination: isSeasonProduct(type) ? journey.destination : destination,
            isReturn: isSeasonProduct(type) ? true : isReturn,
            name,
            vendor,
            vendorRegion,
            vendorStatus: isSeasonOrRailcardProduct(type) ? productStatus : fulfilmentStatus,
            fulfilmentStatus:
              (isSeasonOrRailcardProduct(type)
                ? fulfilmentStatus
                : trainlineProductStatus && trainlineProductStatus.issueStatus) || '',
            externalPaymentProvider:
              trainlineProductStatus && trainlineProductStatus.externalPaymentProvider,
            hasBeenReimbursed: reimbursement && reimbursement.bookingIds.includes(id),
          })
        ),
      fraudOrder,
      paymentServiceProvider,
    };
  }
);

const paymentErrorsCodes = [
  'ProcessorDeclined',
  'ProcessorError',
  'PaymentDetailsNotFound',
  'InvalidCurrencyForCardType',
  'InsufficientFunds',
  'InvalidCardNumber',
  'InvalidAddress',
  'InvalidCardExpiryDate',
  'InvalidCardDetailsCardSecurityCode',
  'InvalidAmount',
  'InvalidCurrency',
  'CardDetokenisationFailed',
  'PaymentMethodUnavailable',
  'Timeout',
  'InternalServerError',
  'Duplicate',
  'InvalidPayerAuthenticationResponse',
  'UnableToAquireLock',
  'IncorrectPaymentState',
  'IncorrectPaymentDetailsState',
  'InvalidPaymentMethod',
  'InvalidCardDetailsCardHolderName',
  'InvalidCardDetailsCardNumber',
  'InvalidCardDetailsCardNumber:BinRange',
  'InvalidCardDetailsCardNumber:FailedValidBinRangeRules',
  'InvalidCardExpiryDate:CardExpired',
  'InvalidCardDetailsCardType',
  'InvalidCardDetailsCardSecurityCode',
  'InvalidCardDetailsExpiryMonth',
  'InvalidCardDetailsExpiryYear',
  'InvalidBillingAddressLine1',
  'InvalidBillingAddressCountryCode',
  'InvalidBillingAddressCity',
  'InvalidBillingAddressPostCode',
  'InvalidStateCode',
  'InvalidEmailAddress',
  'PaymentServiceProviderUnavailable',
  'InvalidRefund',
  'InvalidRefundAmount',
];

const getPaymentStatus = createSelector(
  commonSelectors.getOrder,
  getTransactionSummary,
  (order, { paymentStatus }) => {
    const isPaymentSpecificTopErrorCode =
      order.errors &&
      order.errors.length > 0 &&
      !!order.errors.find(({ code }) => code === '66001.30');

    const errorSelector = compose(
      rTake(1),
      flatten(),
      pluck('code'),
      filter((e) => !!e),
      flatten(),
      pluck('innerErrors'),
      flatten(),
      filter((e) => !!e),
      pluck('meta')
    );
    const allErrorCodes = errorSelector(order.errors || []);
    const paymentErrorCode = allErrorCodes.find(
      (code) =>
        paymentErrorsCodes.find(
          (paymentError) => code.toLowerCase() === paymentError.toLowerCase()
        ) != null
    );
    const singleErrorCode = allErrorCodes.length > 0 ? allErrorCodes[0] : undefined;

    return {
      hasErrors: isPaymentSpecificTopErrorCode || paymentErrorCode != null,
      status: paymentStatus,
      errorCode: isPaymentSpecificTopErrorCode ? singleErrorCode : paymentErrorCode,
    };
  }
);

const uniqObjFromArray = (array, objKey) =>
  Array.from(new Set(array.map((a) => a[objKey]))).map((key) =>
    array.find((a) => a[objKey] === key)
  );

const transactionPaymentSummary = createSelector(
  (state) => state.orders.order.customerId,
  (state) => state.orders.order.travelBookings,
  getTransactionSummary,
  commonSelectors.getPaymentErrors,
  (customerId, bookings, summary, paymentErrors) => {
    const products =
      summary.products &&
      summary.products
        .map((product) => {
          if (isTravelProduct(product)) {
            const breakdownFarePassengerType = product.breakdown.filter(
              ({ type }) => type === 'fare-passenger'
            );

            const passengersBreakdown = breakdownFarePassengerType.map((item) => {
              const breakdownPerPassengerType = breakdownFarePassengerType
                .filter(({ details: { type } }) => type === item.details.type)
                .map(({ details, ...rest }) => ({
                  ...rest,
                  discounts: details.discounts,
                  passengerType: details.type,
                }));

              const subTotal = breakdownPerPassengerType
                .filter(({ price }) => price)
                .reduce((acc, curr) => acc + curr.price.amount, 0);

              return {
                type: item.type,
                passengerCount: breakdownPerPassengerType.length,
                details: breakdownPerPassengerType,
                passengerType: item.details.type,
                subTotal,
              };
            });

            const extrasBreakdown = product.breakdown
              .filter(({ type }) => type === 'extra-type')
              .map((item) => ({
                type: item.type,
                extraQuantity: item.selectedQuantity,
                extraName: item.displayName,
                price: item.price,
                inventoryPrice: item.inventoryPrice,
              }));

            const productLinks = product.productLinks
              ? product.productLinks.map(({ productId, ...productLink }) => ({
                  ...productLink,
                  link:
                    productLink.type === 'replaced-by'
                      ? bookings
                          .filter((booking) => booking.id === productId)
                          .map(
                            (booking) =>
                              `/customers/${customerId}/bookings/${booking.changedOrderReference}`
                          )[0]
                      : undefined,
                }))
              : [];

            return {
              ...product,
              productLinks,
              breakdown: [
                ...uniqObjFromArray(passengersBreakdown, 'passengerType'),
                ...extrasBreakdown,
              ],
            };
          }

          return product;
        })
        .sort((product1, product2) => {
          const departureDate1 = bookings.find((booking) => booking.id === product1.id)?.journeys[0]
            ?.departAt;
          const departureDate2 = bookings.find((booking) => booking.id === product2.id)?.journeys[0]
            ?.departAt;

          return moment.utc(departureDate1).diff(moment.utc(departureDate2));
        });

    const bookingFees = summary.fees.filter(({ type }) => type === 'booking-fee');
    const bookingFeesBreakdown = flatten(
      bookingFees.map(({ breakdown = [] }) =>
        breakdown.map(({ references, price }) => {
          const vendor = references.map((reference) => {
            const summaryProduct =
              summary.products && summary.products.find((product) => product.id === reference.id);
            return summaryProduct && summaryProduct.vendor;
          });

          return {
            price,
            vendor,
          };
        })
      )
    );

    const bookingFeesWithVendorOnBreakdown = bookingFees.map((bookingFee) => ({
      ...bookingFee,
      breakdown: bookingFeesBreakdown,
    }));

    const fees = summary.fees
      .filter(({ type }) => type !== 'booking-fee')
      .concat(bookingFeesWithVendorOnBreakdown)
      .map(({ price, inventoryPrice, ...fee }) => ({
        ...fee,
        price,
        inventoryPrice,
        hasPrice:
          (price !== undefined && price.amount !== 0) ||
          (inventoryPrice !== undefined && inventoryPrice.amount !== 0),
      }));

    return { ...summary, products, fees, paymentErrors };
  }
);

const selectCancelRailcard = (state) => state.orders.cancelRailcard;
const isCancellingRailcard = createSelector(selectCancelRailcard, (state) => state.loading);
const cancelRailcardStatus = createSelector(selectCancelRailcard, (state) => state.status);
const cancelledRailcardName = createSelector(selectCancelRailcard, (state) => state.railcardName);

const selectTicketStatuses = (state) => state.orders.ticketStatuses;
const ticketStatusFactory = (productId, farePassengerId) =>
  createSelector(selectTicketStatuses, (ticketStatuses) =>
    ticketStatuses[productId] && ticketStatuses[productId].statuses
      ? ticketStatuses[productId].statuses.find(({ scopeId }) => scopeId === farePassengerId)
      : null
  );
const ticketStatusLoadingFactory = (productId) =>
  createSelector(
    selectTicketStatuses,
    (ticketStatuses) => (ticketStatuses[productId] && ticketStatuses[productId].loading) || false
  );

const ticketStatusErrorFactory = (productId) =>
  createSelector(
    selectTicketStatuses,
    (ticketStatuses) => (ticketStatuses[productId] && ticketStatuses[productId].error) || false
  );

const hasAmendedSeatReservation = createSelector(commonSelectors.getBookings, (bookings) =>
  bookings.some(({ relatedByOrderSubType }) => relatedByOrderSubType === 'amendReservation')
);

const selectGenerateTicketState = (state) => state.orders.generateTicket;
const isGeneratingTicket = createSelector(selectGenerateTicketState, (state) => state.loading);
const hasFailedGeneratingTicket = createSelector(selectGenerateTicketState, (state) => state.error);
const hasGeneratedTicket = createSelector(selectGenerateTicketState, (state) => state.success);
const generatedTicketOrderReference = createSelector(
  selectGenerateTicketState,
  (state) => state.orderReference
);
const generatedTicketProductId = createSelector(
  selectGenerateTicketState,
  (state) => state.productId
);
const generatedTicketFarePassengerId = createSelector(
  selectGenerateTicketState,
  (state) => state.farePassengerId
);

const selectResetSeasonState = (state) => state.orders.resetSeason;
const getResetSeasonStatus = createSelector(selectResetSeasonState, (state) => state.status);

const selectCancelSeasonState = (state) => state.orders.cancelSeason;
const getCancelSeasonStatus = createSelector(selectCancelSeasonState, (state) => state.status);

const getSeasonTicketStatus = (productId) =>
  createSelector(
    selectTicketStatuses,
    ticketStatusLoadingFactory(productId),
    (ticketStatuses, isLoading) => ({
      statuses: ticketStatuses[productId] && ticketStatuses[productId].statuses,
      isLoading,
    })
  );

const isAlsoValidOn = createSelector(commonSelectors.getOrder, (state) => {
  if (state) {
    const { travelBookings: bookings } = state;
    if (bookings)
      return bookings.some((b) =>
        b?.journeys?.some(({ links }) => links?.some(({ rel }) => rel === 'alternative-journeys'))
      );
  }
  return false;
});

export const selectors = {
  getBusinessSettings,
  getItineraryId,
  getCustomerOrderTravellers,
  isLoadingOrder,
  isLoadingTransactionSummary,
  selectError,
  getBookingsWithConvertedOrder,
  getTickets,
  getConvertedBookings,
  getLink,
  isLoaded,
  getCustomerEmail,
  bookingsStatusDetails,
  insurancesBookingDetails,
  paymentStatus: getPaymentStatus,
  transactionPaymentSummary,
  isCancellingRailcard,
  cancelRailcardStatus,
  cancelledRailcardName,
  ticketStatusFactory,
  ticketStatusLoadingFactory,
  ticketStatusErrorFactory,
  hasAmendedSeatReservation,
  getTransactionSummary,
  isGeneratingTicket,
  hasFailedGeneratingTicket,
  hasGeneratedTicket,
  generatedTicketOrderReference,
  generatedTicketProductId,
  generatedTicketFarePassengerId,
  getResetSeasonStatus,
  getCancelSeasonStatus,
  getSeasonTicketStatus,
  ...commonSelectors,
  isAlsoValidOn,
};

const supportsRefunds = (refundables) =>
  refundables.every(
    ({ status, reason }) =>
      status !== 'NotRefundable' || reason !== 'CCRefundsService.NotRefundableVia1P'
  );

export function* getSdvEligibility(orderDetails) {
  if (orderDetails.source !== '1p') {
    return {
      isVoidable: false,
      notVoidableReasons: ['notSupported'],
    };
  }

  try {
    const { refundables, isRefundPossible } = yield call(
      request,
      `/refundsapi/refunds/${orderDetails.id}/refund-eligibility?policy=same-day-void`
    );

    if (!isRefundPossible && (!refundables || refundables.length === 0)) {
      return {
        isVoidable: false,
        notVoidableReasons: ['upstreamErrors'],
        expiryTime: null,
      };
    }

    if (!supportsRefunds(refundables)) {
      return {
        isVoidable: false,
        notVoidableReasons: ['notSupported'],
        expiryTime: null,
      };
    }

    if (
      refundables.some(
        (r) => r.passengerRefundables.length === 0 && r.insuranceRefundables.length === 0
      )
    ) {
      return {
        isVoidable: false,
        notVoidableReasons: ['productInInvalidState'],
        expiryTime: null,
      };
    }

    const notRefundables = compose(
      filter((refundable) => refundable.status === 'notRefundable'),
      flatten,
      pluck('passengerRefundables')
    )(refundables);

    let expiryTime = compose(
      reduce(
        minBy((t) => t),
        new Date(8640000000000000)
      ),
      rMap((refundable) => new Date(refundable.expiryTimestampUtc)),
      filter((refundable) => refundable.status === 'refundable'),
      flatten,
      pluck('passengerRefundables')
    )(refundables);

    const sortReasons = (a, b) => {
      if (a === 'policyExpired') {
        return -1;
      }
      if (b === 'policyExpired') {
        return 1;
      }

      return a.localeCompare(b);
    };

    const reasons = compose(
      sort(sortReasons),
      uniq,
      filter((r) => r !== undefined),
      pluck('reason')
    )(notRefundables);

    // removes source coming from BE [Source].[SpecificError]
    const notVoidableReasons = reasons.map((r) => {
      const prefix = r.split('.');
      return prefix.length > 1 ? prefix[1] : r;
    });

    const isVoidable = notRefundables.length === 0 && notVoidableReasons.length === 0;

    if (isVoidable === false) {
      expiryTime = null;
    }

    return {
      isVoidable,
      notVoidableReasons,
      expiryTime,
    };
  } catch (err) {
    return {
      isVoidable: false,
      notVoidableReasons: ['errorFetchingVoidability'],
      expiryTime: null,
    };
  }
}

// Sagas
export const getOrderDetailsAndProducts = (customerId, orderReference) =>
  Promise.all([
    request(`/api/customers/v2/${customerId}/orders/${orderReference}`),
    request(`/api/orders/${orderReference}/products`).catch(() => {}),
  ]).then(([orderDetails, products = { items: [], errors: [] }]) => ({
    orderDetails,
    products,
  }));

export const loadTicketStatusesRequest = (orderReference, productId) =>
  request(`/api/orders/${orderReference}/products/${productId}/ticketStatuses`);

export function* loadOrderSaga({ payload: { orderReference, customerId, force } }) {
  try {
    const state = yield select();
    const order = commonSelectors.selectOrder(state);
    if (!force && order) {
      yield put(actions.success(order));
      return;
    }

    const { orderDetails, products } = yield call(
      getOrderDetailsAndProducts,
      customerId,
      orderReference
    );

    const voidable = yield getSdvEligibility(orderDetails);

    yield put(actions.success(orderDetails, voidable, products));
  } catch (e) {
    yield put(actions.fail(e));
  }
}

export function* refreshOrderSaga({ payload: { orderReference, customerId } }) {
  try {
    const { orderDetails } = yield call(getOrderDetailsAndProducts, customerId, orderReference);

    const fulfilmentOrders = orderDetails.travelBookings.map(
      ({ id, fulfilmentStatus, ticketAssets, journeys, trainlineProductStatus }) => ({
        id,
        fulfilmentStatus,
        ticketAssets,
        journeys,
        trainlineProductStatus,
      })
    );

    yield put(actions.fulfilmentUpdate(fulfilmentOrders, orderDetails.status));
  } catch (e) {
    yield put(actions.fail(e));
  }
}

export function* loadTransactionSummarySaga({ payload: orderId }) {
  try {
    const transactionSummary = yield call(request, `/api/orders/${orderId}/transactionsummary`);
    yield put(actions.loadTransactionSummarySuccess(transactionSummary));
  } catch (error) {
    yield put(actions.loadTransactionSummaryFail(error));
  }
}

export function* loadDelayRepayClaimSaga({ payload: orderId }) {
  try {
    const items = yield call(request, `/api/orders/${orderId}/delayrepayclaims`);
    if (items && items.errors && items.errors.length > 0) {
      const delayRepayClaimErrorMessageId = 'delayRepayClaimError';
      yield put(appBarActions.showErrorMessage(delayRepayClaimErrorMessageId));
    }
    yield put(actions.delayRepayClaimSuccess(items));
  } catch (error) {
    yield put(actions.delayRepayClaimFail(error));
  }
}

export function* cancelRailcardSaga({ payload: product }) {
  try {
    const cancelLink = product.links.find(({ rel }) => rel === 'Cancel');
    yield call(request, cancelLink.href, { method: cancelLink.meta.method });
    yield put(actions.cancelRailcardSuccess(product.name));
  } catch (error) {
    yield put(actions.cancelRailcardFail());
  }
}

export function* loadTicketStatusesSaga({ payload: { orderReference, productId } }) {
  const state = yield select();
  const ticketStatus = selectTicketStatuses(state);
  try {
    if (ticketStatus[productId] && ticketStatus[productId].statuses) {
      yield put(actions.ticketStatusesSuccess(productId, ticketStatus[productId].statuses));
      return;
    }

    const statuses = yield call(loadTicketStatusesRequest, orderReference, productId);
    yield put(actions.ticketStatusesSuccess(productId, statuses));
  } catch (error) {
    yield put(actions.ticketStatusesFail(productId));
  }
}

export function* generateTicketPollingTaskFunction() {
  const state = yield select();
  const orderReference = generatedTicketOrderReference(state);
  const productId = generatedTicketProductId(state);
  const farePassengerId = generatedTicketFarePassengerId(state);
  const customerId = getCustomerId(state.orders.order);

  const maximumNumberOfAttempts = 20;
  let numberOfAttempts = 0;

  while (true) {
    numberOfAttempts += 1;

    if (numberOfAttempts > maximumNumberOfAttempts) {
      yield put(actions.stopGenerateTicketPollingTask());
    }

    try {
      const statuses = yield call(loadTicketStatusesRequest, orderReference, productId);
      const status = statuses.find(({ scopeId }) => scopeId === farePassengerId)?.thirdPartyStatus;

      if (status === 'fulfilling') {
        yield call(delay, 5000);
      } else if (status === 'fulfilled') {
        const order = yield call(
          request,
          `/api/customers/v2/${customerId}/orders/${orderReference}`
        );
        const voidable = yield getSdvEligibility(order);
        yield put(actions.ticketStatusesSuccess(productId, statuses));
        yield put(actions.generateTicketSuccess(order, voidable));
        yield put(actions.stopGenerateTicketPollingTask());
      } else {
        yield put(actions.ticketStatusesSuccess(productId, statuses));
        yield put(actions.stopGenerateTicketPollingTask());
      }
    } catch (error) {
      yield put(actions.stopGenerateTicketPollingTask());
      yield put(actions.generateTicketFail());
    }
  }
}

export function* generateTicketPollingTaskSaga() {
  while (true) {
    yield take(START_GENERATE_TICKET_POLLING_TASK);
    yield race([call(generateTicketPollingTaskFunction), take(STOP_GENERATE_TICKET_POLLING_TASK)]);
  }
}

export function* generateTicketSaga({ payload: { orderReference, productId, farePassengerId } }) {
  try {
    yield call(request, `/api/orders/${orderReference}/products/${productId}/tickets`, {
      method: 'POST',
    });
    yield put(actions.startGenerateTicketPollingTask(orderReference, productId, farePassengerId));
  } catch (error) {
    yield put(actions.generateTicketFail());
  }
}

export function* loadTimetablesSaga({ payload: orderId }) {
  try {
    const response = yield call(request, `/api/orders/${orderId}/timetables`);
    yield put(actions.loadTimetablesSuccess(response));
  } catch (error) {
    yield put(actions.loadTimetablesFail());
  }
}

export function* resetSeasonSaga({ payload: productLinks }) {
  try {
    const resetLink = productLinks.find(({ rel }) => rel === 'Reset');
    yield call(request, resetLink.href, { method: resetLink.meta.method });
    yield put(actions.resetSeasonSuccess());
  } catch (error) {
    yield put(actions.resetSeasonFail());
  }
}

export function* cancelSeasonSaga({ payload: { productLinks, orderId } }) {
  try {
    const cancelLink = productLinks.find(({ rel }) => rel === 'Cancel');
    yield call(request, cancelLink.href, { method: cancelLink.meta.method });
    const products = yield call(request, `/api/orders/${orderId}/products`);
    yield put(actions.cancelSeasonSuccess(products));
  } catch (error) {
    yield put(actions.cancelSeasonFail());
  }
}

export function* loadTravellersSaga({ payload: { orderId, itineraryId } }) {
  try {
    const response = yield call(
      request,
      `/api/orders/${orderId}/itineraries/${itineraryId}/passengers`
    );
    yield put(actions.fetchTravellersSuccess(response));
  } catch (error) {
    yield put(actions.fetchTravellersFail());
  }
}

export function* saga() {
  yield all([
    yield takeLatest(CUSTOMER_ORDER_LOAD, loadOrderSaga),
    yield takeLatest(LOAD_TRANSACTION_SUMMARY, loadTransactionSummarySaga),
    yield takeLatest(DELAY_REPAY_CLAIM_LOAD, loadDelayRepayClaimSaga),
    yield takeLatest(CANCEL_RAILCARD_ATTEMPT, cancelRailcardSaga),
    yield takeLatest(TICKET_STATUSES_ATTEMPT, loadTicketStatusesSaga),
    yield takeLatest(LOAD_TIMETABLES_ATTEMPT, loadTimetablesSaga),
    yield takeLatest(GENERATE_TICKET_ATTEMPT, generateTicketSaga),
    yield takeLatest(RESET_SEASON_ATTEMPT, resetSeasonSaga),
    yield takeLatest(CANCEL_SEASON_ATTEMPT, cancelSeasonSaga),
    yield takeLatest(FETCH_TRAVELLERS_ATTEMPT, loadTravellersSaga),
    yield takeLatest(ORDER_REFRESH, refreshOrderSaga),
    generateTicketPollingTaskSaga(),
  ]);
}
