import React, { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { StatusDetails } from '@contactcentre-web/common/StatusIndicator';
import FormattedDateInLondonTz from '@contactcentre-web/common/FormattedDateInLondonTz/FormattedDateInLondonTz';
import FormattedTimeInLondonTz from '@contactcentre-web/common/FormattedTimeInLondonTz/FormattedTimeInLondonTz';
import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency/FormattedCurrency';
import type { Status, CompensationHistory } from '@contactcentre-web/hooks/api/useOrderHistory';

import OrderHistory from '../OrderHistory/OrderHistory';
import { userDisplayName } from '../OrderHistory/helpers';

import messages from './messages';

const Compensation: FunctionComponent<CompensationHistory> = ({ date, user, payload }) => {
  const {
    reasonCode,
    requestedAmount,
    refundedAmount,
    completingUser,
    completingAt,
    requestNote,
    completingNote,
    status,
    paymentDetails,
    virtualTerminalType,
  } = payload;
  const { formatMessage } = useIntl();

  const statusMapper: Record<Status, StatusDetails> = {
    Requested: {
      color: 'base',
      message: messages.requested,
    },
    Pending: {
      color: 'base',
      message: messages.pending,
    },
    Completed: {
      color: 'positive',
      message: messages.approved,
    },
    Rejected: {
      color: 'negative',
      message: messages.rejected,
    },
  };

  const virtualTerminalTypeMapper = {
    Credit: {
      message: messages.virtualTerminalCreditProcessed,
    },
    Debit: {
      message: messages.virtualTerminalDebitProcessed,
    },
  };

  const orderHistoryTypeProcessed =
    virtualTerminalType && virtualTerminalTypeMapper[virtualTerminalType]
      ? virtualTerminalTypeMapper[virtualTerminalType].message
      : messages.compensationProcessed;

  return (
    <OrderHistory.Card>
      <OrderHistory.Header date={date} user={user}>
        <FormattedMessage {...orderHistoryTypeProcessed} />
      </OrderHistory.Header>
      <OrderHistory.Content {...statusMapper[status]}>
        <OrderHistory.Items>
          {paymentDetails && (
            <>
              <OrderHistory.Item label={formatMessage(messages.payment)} testId="payment">
                <OrderHistory.PaymentType paymentDetails={paymentDetails} />
              </OrderHistory.Item>
              <OrderHistory.Item
                label={formatMessage(messages.paymentHolderName)}
                testId="paymentHolderName"
              >
                <OrderHistory.PaymentHolderName paymentDetails={paymentDetails} />
              </OrderHistory.Item>
            </>
          )}
          <OrderHistory.Item label={formatMessage(messages.reasonCode)} testId="reasonCode">
            {reasonCode}
          </OrderHistory.Item>
          <OrderHistory.Item
            label={formatMessage(messages.requestedAmount)}
            testId="requestedAmount"
          >
            <FormattedCurrency {...requestedAmount} />
          </OrderHistory.Item>
          {refundedAmount && (
            <OrderHistory.Item
              label={formatMessage(messages.refundedAmount)}
              testId="refundedAmount"
            >
              <FormattedCurrency {...refundedAmount} />
            </OrderHistory.Item>
          )}
          {completingAt && completingUser && (
            <OrderHistory.Item
              label={formatMessage(messages.completedByLabel)}
              testId="completedByLabel"
            >
              <FormattedMessage
                {...messages.completedBy}
                values={{
                  name: userDisplayName(completingUser),
                  day: (
                    <FormattedDateInLondonTz
                      value={completingAt}
                      year="numeric"
                      month="short"
                      day="numeric"
                      addOffset
                    />
                  ),
                  time: <FormattedTimeInLondonTz value={completingAt} addOffset />,
                }}
              />
            </OrderHistory.Item>
          )}
          {requestNote && (
            <OrderHistory.Item label={formatMessage(messages.requestNote)} testId="requestNote">
              {requestNote}
            </OrderHistory.Item>
          )}
          {completingNote && (
            <OrderHistory.Item
              label={formatMessage(messages.completionNote)}
              testId="completingNote"
            >
              {completingNote}
            </OrderHistory.Item>
          )}
        </OrderHistory.Items>
      </OrderHistory.Content>
    </OrderHistory.Card>
  );
};

export default Compensation;
