import { defineMessages } from 'react-intl';

export default defineMessages({
  outbound: {
    id: 'AlternativeJourneys.outbound',
    defaultMessage: 'Outbound',
  },
  return: {
    id: 'AlternativeJourneys.return',
    defaultMessage: 'Return',
  },
  trip: {
    id: 'AlternativeJourneys.trip',
    defaultMessage: '{origin} to {destination}',
  },
  earlierTrains: {
    id: 'AlternativeJourneys.earlierTrains',
    defaultMessage: 'Earlier trains',
  },
  laterTrains: {
    id: 'AlternativeJourneys.laterTrains',
    defaultMessage: 'Later trains',
  },
  noOutboundOptions: {
    id: 'AlternativeJourneys.noOutboundOptions',
    defaultMessage: 'No outbound options',
  },
  noReturnOptions: {
    id: 'AlternativeJourneys.noReturnOptions',
    defaultMessage: 'No return options',
  },
  noEarlierResults: {
    id: 'AlternativeJourneys.noEarlierResults',
    defaultMessage: 'No more earlier trains',
  },
  noLaterResults: {
    id: 'AlternativeJourneys.noLaterResults',
    defaultMessage: 'No more later trains',
  },
  openReturn: {
    id: 'AlternativeJourneys.openReturn',
    defaultMessage: 'This is an Open Return journey but we can’t fetch any alternative journeys.',
  },
  fetchFailed: {
    id: 'AlternativeJourneys.fetchFailed',
    defaultMessage: 'Failed to fetch results. {link}',
  },
  tryAgain: {
    id: 'AlternativeJourneys.tryAgain',
    defaultMessage: 'Try again',
  },
});
