import { StyleSheet } from 'aphrodite/no-important';
import { fonts, spacers, typography } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  adjustLabel: {
    fontSize: typography.dp_body_font_size,
    lineHeight: typography.dp_body_line_height,
    color: colors.gray.alpha54,
  },
  adjustValue: {
    fontSize: typography.dp_body_font_size,
    lineHeight: typography.dp_body_line_height,
    color: colors.black,
    fontWeight: fonts.dp_font_weight_bold,
    paddingLeft: spacers.dp_s4,
  },
});
