import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@contactcentre-web/utils/request';

import { actions, SUPERSEDED_BOOKING_LOAD } from './module';

export function* loadSaga(action) {
  try {
    const { bookingUri, orderReference } = action.payload;
    const response = yield call(
      request,
      `/api/orders/${orderReference}/orderhistory/booking/${bookingUri}`
    );
    yield put(actions.loadSuccess(response));
  } catch (e) {
    yield put(actions.loadFailed(e));
  }
}

export default function* saga() {
  yield takeLatest(SUPERSEDED_BOOKING_LOAD, loadSaga);
}
