import { defineMessages } from 'react-intl';

export default defineMessages({
  transationFormTitle: {
    id: 'TracsOnAccountForm.transationFormTitle',
    defaultMessage: 'Transaction details',
  },
  customerFormTitle: {
    id: 'TracsOnAccountForm.customerFormTitle',
    defaultMessage: 'Customer details',
  },
  refundFormTitle: {
    id: 'TracsOnAccountForm.refundFormTitle',
    defaultMessage: 'Refund details',
  },
  paymentFormTitle: {
    id: 'TracsOnAccountForm.paymentFormTitle',
    defaultMessage: 'Refund',
  },
  managedGroup: {
    id: 'TracsOnAccountForm.managedGroup',
    defaultMessage: 'Managed group',
  },
  managedGroupPlaceholder: {
    id: 'TracsOnAccountForm.managedGroupPlaceholder',
    defaultMessage: 'Type name or number',
  },
  corporateReference: {
    id: 'TracsOnAccountForm.corporateReference',
    defaultMessage: 'Corporate reference',
  },
  tracsOrderReference: {
    id: 'TracsOnAccountForm.tracsOrderReference',
    defaultMessage: 'TraCS order reference',
  },
  surname: {
    id: 'TracsOnAccountForm.surname',
    defaultMessage: 'Surname',
  },
  notes: {
    id: 'TracsOnAccountForm.notes',
    defaultMessage: 'Notes',
  },
  total: {
    id: 'TracsOnAccountForm.total',
    defaultMessage: 'Total',
  },
  payOnAccountSubmit: {
    id: 'TracsOnAccountForm.payOnAccountSubmit',
    defaultMessage: 'Pay on account',
  },
});
