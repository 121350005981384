import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  customerNotesButton: {
    borderRadius: '50%',
    width: '64px',
    height: '64px',
    boxShadow: '0 6px 8px 0 rgba(0, 0, 0, 0.12)',
    position: 'fixed',
    right: spacers.dp_s24,
    bottom: spacers.dp_s24,
    transformOrigin: 'center center',
    transform: `translateX(calc(100% + ${spacers.dp_s24}))`,
    visibility: 'hidden',
    transition: 'all 0.3s ease-in, visibility 0s linear 0.3s',
    padding: 'unset',
    lineHeight: 0,
    overflow: 'visible',
  },
  visible: {
    visibility: 'visible',
    transform: 'translateX(0)',
    transition: 'all 0.3s ease-out',
  },
  icon: {
    height: '65%',
    width: '65%',
    transform: 'rotate(90deg) scaleX(-1)',
  },
  indicator: {
    display: 'flex',
    position: 'absolute',
    top: `-${spacers.dp_s4}`,
    right: spacers.dp_s4,
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    background: '#e02007',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
