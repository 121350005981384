import { useMutation, useQuery } from 'react-query';

import request from '@contactcentre-web/utils/request';

interface Meta {
  method: string;
}

interface Link {
  rel: string;
  href: string;
  meta: Meta;
}

export interface FulfilmentConvertOptions {
  productId: string;
  deliveryOptionsAvailable: Array<string>;
}
export interface FulfilmentConvertEligibilityData {
  fulfilmentConversionOptions: Array<FulfilmentConvertOptions>;
  link: Link;
}
export interface FulfilmentConvertEligibility {
  fulfilmentConversionOptions: Array<FulfilmentConvertEligibilityData>;
}

export type FulfilmentConvertData = {
  products: Array<string>;
};
export type FulfilmentConvertItem = {
  fulfilmentOption: string;
  productId: string;
};
export type FulfilmentConvertRequest = {
  url: string;
  conversionRequests: Array<FulfilmentConvertItem>;
};

type Error = {
  errors: Array<{ code: string; detail: string }>;
};

const headers = {
  'Content-Type': 'application/vnd.trainline.contact-centre-fulfilmentconversion.v1+json',
};

export const useFulfilmentConvertEligibility = (friendlyOrderId?: string) =>
  useQuery(
    ['fulfilmentConvertEligibility', friendlyOrderId],
    () =>
      request<FulfilmentConvertEligibilityData>(
        `/api/orders/${friendlyOrderId}/fulfilmentConversionEligibilities`,
        {
          headers,
        }
      ),
    {
      select: (item) => item,
    }
  );

export const useFulfilmentConvert = () =>
  useMutation<unknown, Error, FulfilmentConvertRequest, unknown>((data) =>
    request(`/${data.url}`, {
      method: 'POST',
      headers,
      body: { conversionRequests: data.conversionRequests },
    })
  );
