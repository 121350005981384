import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Refunds.RefundNonProcessableQuotePrompt.title',
    defaultMessage: 'Are you sure?',
  },
  content: {
    id: 'Refunds.RefundNonProcessableQuotePrompt.content',
    defaultMessage:
      'The refund request has been denied due to Reference code RR1.\nDoing this will enable the customer to get the refund.',
  },
  confirm: {
    id: 'Refunds.RefundNonProcessableQuotePrompt.confirm',
    defaultMessage: 'Yes, process refund',
  },
  cancel: {
    id: 'Refunds.RefundNonProcessableQuotePrompt.cancel',
    defaultMessage: 'No, go back',
  },
  enableButton: {
    id: 'Refunds.RefundNonProcessableQuotePrompt.enableButton',
    defaultMessage: 'Enable refund request',
  },
});
