import React, { FunctionComponent } from 'react';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { IconChevronDown, IconChevronUp } from '@trainline/depot-web';

import { ColumnName, AppliedSort } from '../../types';
import Button from '../../../Button';

import styles from './styles';
import messages from './messages';

interface SortableColumnProps {
  columnName: ColumnName;
  toggleOrderBy: (columnName: ColumnName) => void;
  isColumnSelected?: boolean;
  isOrderDesc?: boolean;
  styleSheet?: StyleDeclarationValue;
}

const SortableColumn: FunctionComponent<SortableColumnProps> = ({
  columnName,
  toggleOrderBy,
  isColumnSelected,
  isOrderDesc,
  styleSheet,
}) => (
  <Button
    variant="ghost"
    styleSheet={[styles.tableColumn, styles.sortableColumn, styleSheet]}
    onClick={() => toggleOrderBy(columnName)}
    testId={columnName}
  >
    <FormattedMessage {...messages[columnName]} />
    {isColumnSelected && (isOrderDesc ? <IconChevronDown /> : <IconChevronUp />)}
  </Button>
);

interface BookingsTableHeaderProps {
  onChange?: (columnName: ColumnName) => void;
  selectedSortOptions?: AppliedSort;
}

const BookingsTableHeader: FunctionComponent<BookingsTableHeaderProps> = ({
  onChange,
  selectedSortOptions,
}) => {
  const getSortableColumnProps = (columnName: ColumnName) => ({
    columnName,
    toggleOrderBy: onChange ? () => onChange(columnName) : () => null,
    isColumnSelected: selectedSortOptions && selectedSortOptions.sortPropName === columnName,
    isOrderDesc: selectedSortOptions && selectedSortOptions.sortOrderDesc,
  });

  return (
    <div className={css(styles.tableRow, styles.tableHeader)} data-test="bookingTableHeader">
      <SortableColumn
        {...getSortableColumnProps(ColumnName.BY_FROM)}
        styleSheet={[styles.productColumn, styles.productTitle]}
      />
      <SortableColumn {...getSortableColumnProps(ColumnName.BY_ORDER_ID)} />
      <SortableColumn {...getSortableColumnProps(ColumnName.BY_CTR_PNR)} />
      <SortableColumn {...getSortableColumnProps(ColumnName.BY_ORDER_DATE)} />
      <SortableColumn {...getSortableColumnProps(ColumnName.BY_DEPARTURE)} />
      <SortableColumn {...getSortableColumnProps(ColumnName.BY_RETURN)} />
      <SortableColumn {...getSortableColumnProps(ColumnName.BY_PRICE)} />
      <SortableColumn {...getSortableColumnProps(ColumnName.BY_BOOKING_STATUS)} />
    </div>
  );
};

export default BookingsTableHeader;
