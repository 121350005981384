import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  userAccountWithIcon: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  userAccount: {
    marginLeft: spacers.dp_s8,
    verticalAlign: 'middle',
  },
});
