import { createActions, handleActions, Action } from 'redux-actions';
import { takeLatest, all, put, select, call, race, take } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { Passenger, TravelTicket } from '@contactcentre-web/redux-common/types/Journey';
import * as commonOrderSelectors from '@contactcentre-web/redux-common/selectors/order';
import {
  actions as orderActions,
  CUSTOMER_ORDER_SUCCESS,
  CUSTOMER_ORDER_FAIL,
} from '@contactcentre-web/redux-common/actions/order';
import { selectors as orderSelectors } from '@contactcentre-web/customer-order/module';
import request, { HTTPMethod } from '@contactcentre-web/utils/request';
import State from '@contactcentre-web/redux-common/types/State';
import { TravelProduct } from '@contactcentre-web/redux-common/types/TravelProduct';

export const PREFIX = 'CHANGE-OF-JOURNEY';
export const LOAD_ELIGIBILITY = 'LOAD_ELIGIBILITY';
export const LOAD_ELIGIBILITY_SUCCESS = 'LOAD_ELIGIBILITY_SUCCESS';
export const LOAD_ELIGIBILITY_FAILURE = 'LOAD_ELIGIBILITY_FAILURE';
export const LOAD_ELIGIBILITY_STATUS_NONE = 'LOAD_ELIGIBILITY_STATUS_NONE';
export const LOAD_ELIGIBILITY_STATUS_INPROGRESS = 'LOAD_ELIGIBILITY_STATUS_INPROGRESS';
export const LOAD_ELIGIBILITY_STATUS_SUCCEEDED = 'LOAD_ELIGIBILITY_STATUS_SUCCEEDED';
export const LOAD_ELIGIBILITY_STATUS_FAILED = 'LOAD_ELIGIBILITY_STATUS_FAILED';

export const actions = createActions(
  {
    [LOAD_ELIGIBILITY]: (customerId, orderReference) => ({
      customerId,
      orderReference,
    }),
    [LOAD_ELIGIBILITY_SUCCESS]: (eligibility) => ({ eligibility }),
    [LOAD_ELIGIBILITY_FAILURE]: () => null,
  },
  { prefix: PREFIX }
);

export const initialState = {
  loadStatus: LOAD_ELIGIBILITY_STATUS_NONE,
  eligibility: {},
};

interface EligibilityState {
  [productId: string]: { changeUrl: string; status: string };
}

export interface ChangeOfJourneyState {
  loadStatus: string;
  eligibility?: EligibilityState;
}

interface Eligibility {
  productChangeabilities: {
    changeability: string;
    changeUrl: string;
    productId: string;
  }[];
}

interface ActionPayload {
  eligibility: Eligibility;
}

interface Changeability {
  changeable: boolean;
  reason?: string;
  changeUrl?: string;
}

interface TypesCount {
  [type: string]: number;
}
export interface COJBooking extends TravelProduct {
  passengerByType: TypesCount;
  ticketsByType: TypesCount;
  changeability: Changeability;
}

const reducer = handleActions<ChangeOfJourneyState, ActionPayload>(
  {
    [LOAD_ELIGIBILITY]: (state) => ({
      ...state,
      loadStatus: LOAD_ELIGIBILITY_STATUS_INPROGRESS,
    }),
    [LOAD_ELIGIBILITY_SUCCESS]: (state, { payload: { eligibility } }) => ({
      ...state,
      loadStatus: LOAD_ELIGIBILITY_STATUS_SUCCEEDED,
      eligibility: eligibility.productChangeabilities.reduce(
        (acc, chg) => ({
          ...acc,
          [chg.productId]: {
            status: chg.changeability,
            changeUrl: chg.changeUrl,
          },
        }),
        {}
      ),
    }),
    [LOAD_ELIGIBILITY_FAILURE]: (state) => ({
      ...state,
      loadStatus: LOAD_ELIGIBILITY_STATUS_FAILED,
    }),
  },
  initialState,
  { prefix: PREFIX }
);

const localState = (state: State) => state.changeOfJourney || initialState;
const isLoading = (state: State) =>
  localState(state).loadStatus === LOAD_ELIGIBILITY_STATUS_INPROGRESS ||
  orderSelectors.isLoadingOrder(state);
const hasFailed = (state: State) =>
  localState(state).loadStatus === LOAD_ELIGIBILITY_STATUS_FAILED ||
  orderSelectors.selectError(state);
const getEligibility = createSelector(localState, (state) => state.eligibility);

const getPassengerTypeCount = (booking: TravelProduct): TypesCount =>
  booking.journeys[0].legs[0].passengers.reduce((acc: TypesCount, { type }: Passenger) => {
    acc[type] = (acc[type] || 0) + 1;
    return acc;
  }, {});

const getTicketTypesCount = (booking: TravelProduct): TypesCount => {
  const tickets: TravelTicket[] = booking.journeys
    .map(({ legs }) =>
      legs
        .map(({ passengers }) => passengers.map(({ tickets }) => tickets))
        .flat()
        .flat()
    )
    .flat();

  return tickets.reduce((acc: { [ticketType: string]: number }, { type }: TravelTicket) => {
    acc[type] = (acc[type] || 0) + 1;
    return acc;
  }, {});
};

const getBookings = createSelector(
  getEligibility,
  commonOrderSelectors.getBookings,
  (eligibility, orderBookings) =>
    orderBookings.map((booking: TravelProduct) => ({
      ...booking,
      passengerByType: getPassengerTypeCount(booking),
      ticketsByType: getTicketTypesCount(booking),
      changeability: {
        changeable: eligibility?.[booking.id]?.status.toLowerCase() === 'changeable',
        reason:
          eligibility?.[booking.id]?.status.toLowerCase() !== 'changeable'
            ? eligibility?.[booking.id]?.status
            : null,
        changeUrl:
          eligibility?.[booking.id]?.status.toLowerCase() === 'changeable'
            ? eligibility[booking.id]?.changeUrl
            : null,
      },
    }))
);

export const selectors = {
  isLoading,
  getBookings,
  hasFailed,
};

export const getChangeabilityRequest = (uri: string, method?: HTTPMethod) =>
  request(uri, {
    method: method ? (method.toUpperCase() as HTTPMethod) : 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

export function* loadEligibilitySaga() {
  const state: State = yield select();
  const link = orderSelectors.getLink(state, 'changeability');
  if (!link) {
    throw Error('Invalid data in the order.');
  }

  const eligibility: Eligibility = yield call(getChangeabilityRequest, link.href, link.method);
  return eligibility;
}

export interface ChangeOfJourneyData {
  customerId: string;
  orderReference: string;
}

export function* loadEverything({
  payload: { customerId, orderReference },
}: Action<ChangeOfJourneyData>) {
  try {
    const state: State = yield select();
    let eligibility: Eligibility;
    if (!orderSelectors.isLoaded(state)) {
      yield put((orderActions as any).load(customerId, orderReference));
      const { order } = yield race({
        order: take(CUSTOMER_ORDER_SUCCESS),
        error: take(CUSTOMER_ORDER_FAIL),
      });

      if (order) {
        eligibility = yield loadEligibilitySaga();
      } else {
        throw new Error('Error loading order!');
      }
    } else {
      eligibility = yield call(loadEligibilitySaga);
    }

    yield put(actions.loadEligibilitySuccess(eligibility));
  } catch (error) {
    yield put(actions.loadEligibilityFailure(error));
  }
}

export function* saga() {
  yield all([takeLatest(`${PREFIX}/${LOAD_ELIGIBILITY}`, loadEverything)]);
}

export default reducer;
