import React, { Dispatch, useEffect } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { StatusMessage } from '@trainline/depot-web';

import BookingSearchResults from '@contactcentre-web/common/BookingSearchResults';
import { CustomerBooking, FilterType } from '@contactcentre-web/common/BookingSearchResults/types';
import ListSummary from '@contactcentre-web/common/ListSummary/ListSummary';
import Loader from '@contactcentre-web/common/Loader';
import PageContainer from '@contactcentre-web/common/PageContainer';
import type Action from '@contactcentre-web/redux-common/types/Action';
import type State from '@contactcentre-web/redux-common/types/State';

import messages from './messages';
import { actions, selectors } from './module';
import styles from './styles';

interface DispatchProps {
  loadCustomerBookingSummaries: () => void;
  setCustomerBookingSummariesSortOption: (sortPropName: string) => void;
  addFilters: (filters: Set<FilterType>) => void;
  removeFilter: (filter: FilterType) => void;
  reset: () => void;
}

interface StateProps {
  bookingSummaries: Array<CustomerBooking> | null;
  selectedSortOptions: { sortPropName: string; sortOrderDesc: boolean };
  loading: boolean;
  appliedFilters: Set<FilterType>;
}

export interface Props extends DispatchProps, StateProps {
  selectedCustomer: { id: string; forename: string; surname: string };
}

export const CustomerBookings = ({
  loadCustomerBookingSummaries,
  setCustomerBookingSummariesSortOption,
  selectedSortOptions,
  loading,
  selectedCustomer: { forename, surname },
  bookingSummaries,
  addFilters,
  removeFilter,
  appliedFilters,
  reset,
}: Props) => {
  const loadCustomerBookingSummariesRef = React.useRef(loadCustomerBookingSummaries);

  useEffect(() => {
    loadCustomerBookingSummariesRef.current();
    return () => reset();
  }, [loadCustomerBookingSummariesRef]);

  if (loading) {
    return (
      <div className={css(styles.container)}>
        <Loader />
      </div>
    );
  }

  if (!bookingSummaries) {
    return null;
  }

  const bookingSummariesCount = bookingSummaries.length;
  if (bookingSummariesCount === 0) {
    return (
      <PageContainer styleSheet={styles.container}>
        <StatusMessage status="info">
          <FormattedMessage {...messages.noBookings} />
        </StatusMessage>
      </PageContainer>
    );
  }

  if (bookingSummariesCount === 1) {
    return (
      <Redirect
        to={`/customers/${bookingSummaries[0].customerId}/bookings/${bookingSummaries[0].orderReference}`}
      />
    );
  }

  return (
    <PageContainer styleSheet={styles.container}>
      <BookingSearchResults
        bookings={bookingSummaries}
        onSort={setCustomerBookingSummariesSortOption}
        appliedSort={selectedSortOptions}
        footerRenderer={(bookingsCount) => (
          <ListSummary type="bookings" total={bookingsCount} name={`${forename} ${surname}`} />
        )}
        addFilters={addFilters}
        removeFilter={removeFilter}
        appliedFilters={appliedFilters}
      />
    </PageContainer>
  );
};

const mapStateToProps = (state: State) => ({
  bookingSummaries: selectors.selectSortedBookingSummaries(state),
  selectedSortOptions: selectors.selectCustomerBookingSummariesSortOptions(state),
  loading: selectors.selectLoading(state),
  appliedFilters: selectors.selectAppliedFilters(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<Action>,
  { selectedCustomer: { id: customerId } }: Props
) => ({
  reset: () => dispatch(actions.reset()),
  loadCustomerBookingSummaries: () => dispatch(actions.load(customerId)),
  setCustomerBookingSummariesSortOption: (sortPropName: string) =>
    dispatch(actions.sort(sortPropName)),
  addFilters: (filters: Set<FilterType>) => dispatch(actions.filtersAdd(filters)),
  removeFilter: (filter: FilterType) => dispatch(actions.filterRemove(filter)),
});

const CustomerBookingsContainer = connect(mapStateToProps, mapDispatchToProps)(CustomerBookings);

CustomerBookingsContainer.displayName = 'CustomerBookings';

export default CustomerBookingsContainer;
