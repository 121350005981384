import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { DeliveryMethodId } from '@contactcentre-web/redux-common/types/DeliveryMethod';
import Button from '@contactcentre-web/common/Button';
import { canOperateFlexiSeasonTickets } from '@contactcentre-web/authentication/redux/selectors';
import { Journey, Link as LinkType } from '@contactcentre-web/redux-common/types/SeasonProduct';
import Link from '@contactcentre-web/common/Link';
import type { TicketStatus } from '@contactcentre-web/redux-common/types/TicketStatus';

import { selectors, actions } from '../../../../module';
import ResetTicketsModal from '../ResetTicketsModal';
import CancelTicketsModal from '../CancelTicketsModal';
import TicketStatusModal from '../TicketStatusModal';

import styles from './styles';
import messages from './messages';

export type LoadingStatus = 'loading' | 'success' | 'error';

interface StateProps {
  canOperateFlexiSeasonTickets: boolean;
  resetSeasonStatus?: LoadingStatus;
  cancelSeasonStatus?: LoadingStatus;
  ticketStatus: {
    statuses: Array<TicketStatus>;
    isLoading: boolean;
  };
}

interface DispatchProps {
  onReset: (productLinks: LinkType[]) => void;
  onCancel: (productLinks: LinkType[], orderId: string) => void;
  loadTicketStatuses: (orderReference: string, productId: string) => void;
}

interface Props extends StateProps, DispatchProps {
  productId: string;
  journey: Journey;
  deliveryMethod: string;
  links: LinkType[];
  canBeReset: boolean;
  canBeCancelled: boolean;
}

export const Tickets = ({
  productId,
  journey: {
    origin,
    destination,
    class: ticketClass,
    passenger: { fullName: passengerName },
  },
  deliveryMethod,
  links,
  canOperateFlexiSeasonTickets,
  canBeReset,
  canBeCancelled,
  onReset,
  resetSeasonStatus,
  onCancel,
  cancelSeasonStatus,
  loadTicketStatuses,
  ticketStatus,
}: Props) => {
  const { orderId } = useParams<{ orderId: string }>();
  const [showResetTicketsModal, toggleResetTicketsModal] = React.useState(false);
  const [showCancelTicketsModal, toggleCancelTicketsModal] = React.useState(false);
  const [showTicketStatusModal, toggleTicketStatusModal] = React.useState(false);

  React.useEffect(() => {
    if (resetSeasonStatus === 'success') {
      toggleResetTicketsModal(false);
    }
    if (cancelSeasonStatus === 'success') {
      toggleCancelTicketsModal(false);
    }
  }, [resetSeasonStatus, cancelSeasonStatus]);

  React.useEffect(() => {
    if (showResetTicketsModal || showCancelTicketsModal || showTicketStatusModal) {
      loadTicketStatuses(orderId, productId);
    }
  }, [showResetTicketsModal, showCancelTicketsModal, showTicketStatusModal, loadTicketStatuses]);

  const displayResetAndCancelButtons =
    canOperateFlexiSeasonTickets && deliveryMethod === DeliveryMethodId.AtocMTicket;

  return (
    <section className={css(styles.container)}>
      <div className={css(styles.col, styles.originDestinationCol)}>
        <h4 className={css(styles.title)}>
          <FormattedMessage {...messages.from_to} />
        </h4>
        <FormattedMessage {...messages.originToDestination} values={{ origin, destination }} />
      </div>
      <div className={css(styles.col)}>
        <h4 className={css(styles.title)}>
          <FormattedMessage {...messages.class} />
        </h4>
        <div>{ticketClass}</div>
      </div>
      <div className={css(styles.col)}>
        <h4 className={css(styles.title)}>
          <FormattedMessage {...messages.passenger} />
        </h4>
        <div>{passengerName}</div>
      </div>
      {showTicketStatusModal && (
        <TicketStatusModal
          toggleModal={() => toggleTicketStatusModal(!showTicketStatusModal)}
          ticketStatus={ticketStatus}
        />
      )}
      <div className={css(styles.col)}>
        <h4 className={css(styles.title)}>
          <FormattedMessage {...messages.status} />
        </h4>
        <Link
          linkType="action"
          testId="view-status"
          onClick={() => toggleTicketStatusModal(!showTicketStatusModal)}
        >
          <FormattedMessage {...messages.view} />
        </Link>
      </div>
      {displayResetAndCancelButtons && (
        <div className={css(styles.col, styles.buttonsCol)}>
          {showResetTicketsModal && (
            <ResetTicketsModal
              toggleModal={() => toggleResetTicketsModal(!showResetTicketsModal)}
              onReset={() => onReset(links)}
              resetSeasonStatus={resetSeasonStatus}
              ticketStatus={ticketStatus}
            />
          )}
          <Button
            variant="secondary"
            size="small"
            onClick={() => toggleResetTicketsModal(!showResetTicketsModal)}
            disabled={!canBeReset}
            testId="reset-tickets-button"
            styleSheet={styles.button}
          >
            <FormattedMessage {...messages.resetAllTickets} />
          </Button>
          {showCancelTicketsModal && (
            <CancelTicketsModal
              toggleModal={() => toggleCancelTicketsModal(!showCancelTicketsModal)}
              onCancel={() => onCancel(links, orderId)}
              cancelSeasonStatus={cancelSeasonStatus}
              ticketStatus={ticketStatus}
            />
          )}
          <Button
            variant="secondary"
            size="small"
            onClick={() => toggleCancelTicketsModal(!showCancelTicketsModal)}
            disabled={!canBeCancelled}
            testId="cancel-tickets-button"
            styleSheet={[styles.button, styles.cancelButton]}
          >
            <FormattedMessage {...messages.cancelAllTickets} />
          </Button>
        </div>
      )}
    </section>
  );
};

const mapStateToProps = (
  _: any,
  { productId }: { productId: string }
): ((state: any) => StateProps) => {
  const ticketStatusSelector = selectors.getSeasonTicketStatus(productId);

  return (state: any): StateProps => ({
    canOperateFlexiSeasonTickets: canOperateFlexiSeasonTickets(state),
    resetSeasonStatus: selectors.getResetSeasonStatus(state),
    cancelSeasonStatus: selectors.getCancelSeasonStatus(state),
    ticketStatus: ticketStatusSelector(state),
  });
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  onReset: (productLinks: LinkType[]) => dispatch(actions.resetSeasonAttempt(productLinks)),
  onCancel: (productLinks: LinkType[], orderId: string) =>
    dispatch(actions.cancelSeasonAttempt(productLinks, orderId)),
  loadTicketStatuses: (orderReference: string, productId: string) =>
    dispatch(actions.ticketStatusesAttempt(orderReference, productId)),
});

const connected = connect(mapStateToProps, mapDispatchToProps)(Tickets);
connected.displayName = 'Tickets';

export default connected;
