import { createActions, handleActions } from 'redux-actions';

import type Currency from '@contactcentre-web/redux-common/types/Price';
import { SortDirectionType } from '@contactcentre-web/hooks/shared/useToggleSortDirection';

export interface RefundItemsState {
  booking: {
    id: string;
    transactionPrice: Currency;
    ticketTypes: Array<string>;
    journey: {
      origin: string;
      destination: string;
      isReturn: boolean;
    };
  };
  tmcQuoteReference: string;
  quoteReference: string;
  orderReference: string;
  customerId: string;
  id: string;
  requestedBy: { displayName: string; userName: string };
  creationDateTime: Date;
  expiryDateTime: Date;
  reason: { id: string; description: string; reasonCode: string };
  refund: {
    quoteConditions: Array<{ reason: { code: string } }>;
    totalRefundable: Currency;
    fees: {
      booking: Currency;
      payment: Currency;
      delivery: Currency;
      admin: Currency;
    };
  };
  lastOrderNote?: string;
  refundableReasons: Array<{
    description: string;
    id: string;
    reasonCode: string;
    ticketType: string;
  }>;
  links: Array<{
    href: string;
    method: string;
    rel: string;
  }>;
}

export interface RefundState {
  items: Array<RefundItemsState>;
  nextPage: string;
  totalCount: number;
  pageSize: number;
  errorItems: Array<{
    code: string;
    detail: string;
    meta: Partial<RefundItemsState> & { id: string; creationDateTime: Date; severity: string };
  }>;
}

export interface RefundsApprovalState {
  loadStatus: string;
  loadNextStatus: string;
  approvalStatus: string;
  approvalFailedMessage?: ApprovalFailedMessage;
  rejectStatus: string;
  deleteStatus: string;
  overrideStatus: string;
  refunds: RefundState;
  requestId: string;
  sortDirection: SortDirectionType;
}

export enum ApprovalFailedMessage {
  approvalError = 'approvalError',
  approvalInvalidQuoteError = 'approvalInvalidQuoteError',
  approvalRefundRequestAmended = 'approvalRefundRequestAmended',
  approvalRefundRequestRejectedByFraud = 'approvalRefundRequestRejectedByFraud',
  approvalRefundRequestAlreadyProcessed = 'approvalRefundRequestAlreadyProcessed',
}

export type LoadPayload = {
  sortDirection: SortDirectionType;
  perPage: number;
  page: number;
};

export const PREFIX = 'OVERSIGHT_PENDING_REFUNDS';

export const loadStatus = {
  LOAD_ATTEMPT: 'LOAD_ATTEMPT',
  LOAD_SUCCESS: 'LOAD_SUCCESS',
  LOAD_ERROR: 'LOAD_ERROR',
  LOAD_NEXT_ATTEMPT: 'LOAD_NEXT_ATTEMPT',
  LOAD_NEXT_SUCCESS: 'LOAD_NEXT_SUCCESS',
  LOAD_NEXT_ERROR: 'LOAD_NEXT_ERROR',
  LOAD_NEXT_STATUS_NONE: 'LOAD_NEXT_STATUS_NONE',
  LOAD_NEXT_STATUS_INPROGRESS: 'LOAD_NEXT_STATUS_INPROGRESS',
  LOAD_NEXT_STATUS_ERROR: 'LOAD_NEXT_STATUS_ERROR',
  LOAD_STATUS_NONE: 'LOAD_STATUS_NONE',
  LOAD_STATUS_INPROGRESS: 'LOAD_STATUS_INPROGRESS',
  LOAD_STATUS_ERROR: 'LOAD_STATUS_ERROR',
};

export const approvalStatus = {
  APPROVAL_ATTEMPT: 'APPROVAL_ATTEMPT',
  APPROVAL_ERROR: 'APPROVAL_ERROR',
  APPROVAL_ERROR_INVALID_QUOTE: 'APPROVAL_ERROR_INVALID_QUOTE',
  APPROVAL_ERROR_REFUND_REQUEST_AMENDED: 'APPROVAL_ERROR_REFUND_REQUEST_AMENDED',
  APPROVAL_ERROR_REFUND_REQUEST_PROCESSED: 'APPROVAL_ERROR_REFUND_REQUEST_PROCESSED',
  APPROVAL_ERROR_REFUND_REJECTED_BY_FRAUD: 'APPROVAL_ERROR_REFUND_REJECTED_BY_FRAUD',
  APPROVAL_SUCCESS: 'APPROVAL_SUCCESS',
  APPROVAL_STATUS_NONE: 'APPROVAL_STATUS_NONE',
  APPROVAL_STATUS_INPROGRESS: 'APPROVAL_STATUS_INPROGRESS',
  APPROVAL_STATUS_ERROR: 'APPROVAL_STATUS_ERROR',
  APPROVAL_STATUS_SUCCESS: 'APPROVAL_STATUS_SUCCESS',
};

export const rejectStatus = {
  REJECT_ATTEMPT: 'REJECT_ATTEMPT',
  REJECT_ERROR: 'REJECT_ERROR',
  REJECT_ERROR_REFUND_REQUEST_PROCESSED: 'REJECT_ERROR_REFUND_REQUEST_PROCESSED',
  REJECT_SUCCESS: 'REJECT_SUCCESS',
  REJECT_STATUS_NONE: 'REJECT_STATUS_NONE',
  REJECT_STATUS_INPROGRESS: 'REJECT_STATUS_INPROGRESS',
  REJECT_STATUS_ERROR: 'REJECT_STATUS_ERROR',
  REJECT_STATUS_SUCCESS: 'REJECT_STATUS_SUCCESS',
};

export const deleteStatus = {
  DELETE_ATTEMPT: 'DELETE_ATTEMPT',
  DELETE_ERROR: 'DELETE_ERROR',
  DELETE_ERROR_REFUND_REQUEST_PROCESSED: 'DELETE_ERROR_REFUND_REQUEST_PROCESSED',
  DELETE_SUCCESS: 'DELETE_SUCCESS',
  DELETE_STATUS_NONE: 'DELETE_STATUS_NONE',
  DELETE_STATUS_INPROGRESS: 'DELETE_STATUS_INPROGRESS',
  DELETE_STATUS_ERROR: 'DELETE_STATUS_ERROR',
  DELETE_STATUS_SUCCESS: 'DELETE_STATUS_SUCCESS',
};

export const overrideStatus = {
  OVERRIDE_ATTEMPT: 'OVERRIDE_ATTEMPT',
  OVERRIDE_ERROR: 'OVERRIDE_ERROR',
  OVERRIDE_SUCCESS: 'OVERRIDE_SUCCESS',
  OVERRIDE_STATUS_NONE: 'OVERRIDE_STATUS_NONE',
  OVERRIDE_STATUS_INPROGRESS: 'OVERRIDE_STATUS_INPROGRESS',
  OVERRIDE_STATUS_ERROR: 'OVERRIDE_STATUS_ERROR',
  OVERRIDE_STATUS_SUCCESS: 'OVERRIDE_STATUS_SUCCESS',
};

export const RESET_STATE = `RESET_STATE`;

const nullAction = () => null;
export const actions = createActions(
  {
    [loadStatus.LOAD_ATTEMPT]: (sortDirection) => ({ sortDirection }),
    [loadStatus.LOAD_SUCCESS]: (refunds) => ({ refunds }),
    [loadStatus.LOAD_ERROR]: (error) => ({ error }),
    [loadStatus.LOAD_NEXT_ATTEMPT]: (sortDirection) => ({ sortDirection }),
    [loadStatus.LOAD_NEXT_SUCCESS]: (refunds) => ({ refunds }),
    [loadStatus.LOAD_NEXT_ERROR]: (error) => ({ error }),
    [approvalStatus.APPROVAL_ATTEMPT]: (requestId) => ({ requestId }),
    [approvalStatus.APPROVAL_ERROR]: nullAction,
    [approvalStatus.APPROVAL_ERROR_INVALID_QUOTE]: nullAction,
    [approvalStatus.APPROVAL_ERROR_REFUND_REQUEST_AMENDED]: nullAction,
    [approvalStatus.APPROVAL_ERROR_REFUND_REQUEST_PROCESSED]: nullAction,
    [approvalStatus.APPROVAL_ERROR_REFUND_REJECTED_BY_FRAUD]: (requestId) => ({ requestId }),
    [approvalStatus.APPROVAL_SUCCESS]: (requestId) => ({ requestId }),
    [rejectStatus.REJECT_ATTEMPT]: (requestId) => ({ requestId }),
    [rejectStatus.REJECT_SUCCESS]: (requestId) => ({ requestId }),
    [rejectStatus.REJECT_ERROR]: nullAction,
    [rejectStatus.REJECT_ERROR_REFUND_REQUEST_PROCESSED]: nullAction,
    [deleteStatus.DELETE_ATTEMPT]: (requestId) => ({ requestId }),
    [deleteStatus.DELETE_SUCCESS]: (requestId) => ({ requestId }),
    [deleteStatus.DELETE_ERROR]: nullAction,
    [deleteStatus.DELETE_ERROR_REFUND_REQUEST_PROCESSED]: nullAction,
    [overrideStatus.OVERRIDE_ATTEMPT]: (requestId) => ({ requestId }),
    [overrideStatus.OVERRIDE_SUCCESS]: nullAction,
    [overrideStatus.OVERRIDE_ERROR]: nullAction,
    [RESET_STATE]: nullAction,
  },
  { prefix: PREFIX }
);

export const initialState: RefundsApprovalState = {
  loadStatus: loadStatus.LOAD_STATUS_NONE,
  loadNextStatus: loadStatus.LOAD_NEXT_STATUS_NONE,
  refunds: { items: [], nextPage: '', totalCount: 0, pageSize: 0, errorItems: [] },
  approvalStatus: approvalStatus.APPROVAL_STATUS_NONE,
  rejectStatus: rejectStatus.REJECT_STATUS_NONE,
  deleteStatus: deleteStatus.DELETE_STATUS_NONE,
  overrideStatus: overrideStatus.OVERRIDE_STATUS_NONE,
  requestId: '',
  sortDirection: 'Asc',
};

export default handleActions(
  {
    [loadStatus.LOAD_ATTEMPT]: (state, { payload: { sortDirection } }) => ({
      ...state,
      sortDirection,
      loadStatus: loadStatus.LOAD_STATUS_INPROGRESS,
    }),
    [loadStatus.LOAD_SUCCESS]: (state, { payload: { refunds } }) => ({
      ...state,
      loadStatus: loadStatus.LOAD_STATUS_NONE,
      refunds,
    }),
    [loadStatus.LOAD_ERROR]: (state) => ({
      ...state,
      loadStatus: loadStatus.LOAD_STATUS_ERROR,
    }),
    [approvalStatus.APPROVAL_ATTEMPT]: (state) => ({
      ...state,
      approvalStatus: approvalStatus.APPROVAL_STATUS_INPROGRESS,
      rejectStatus: rejectStatus.REJECT_STATUS_NONE,
    }),
    [approvalStatus.APPROVAL_ERROR]: (state) => ({
      ...state,
      approvalFailedMessage: ApprovalFailedMessage.approvalError,
      approvalStatus: approvalStatus.APPROVAL_STATUS_ERROR,
      requestId: '',
    }),
    [approvalStatus.APPROVAL_ERROR_INVALID_QUOTE]: (state) => ({
      ...state,
      approvalFailedMessage: ApprovalFailedMessage.approvalInvalidQuoteError,
      approvalStatus: approvalStatus.APPROVAL_STATUS_ERROR,
      requestId: '',
    }),
    [approvalStatus.APPROVAL_ERROR_REFUND_REQUEST_AMENDED]: (state) => ({
      ...state,
      approvalFailedMessage: ApprovalFailedMessage.approvalRefundRequestAmended,
      approvalStatus: approvalStatus.APPROVAL_STATUS_ERROR,
      requestId: '',
    }),
    [approvalStatus.APPROVAL_ERROR_REFUND_REJECTED_BY_FRAUD]: (
      state,
      { payload: { requestId } }
    ) => ({
      ...state,
      approvalFailedMessage: ApprovalFailedMessage.approvalRefundRequestRejectedByFraud,
      approvalStatus: approvalStatus.APPROVAL_STATUS_ERROR,
      requestId,
    }),
    [approvalStatus.APPROVAL_ERROR_REFUND_REQUEST_PROCESSED]: (state) => ({
      ...state,
      approvalFailedMessage: ApprovalFailedMessage.approvalRefundRequestAlreadyProcessed,
      approvalStatus: approvalStatus.APPROVAL_STATUS_ERROR,
      requestId: '',
    }),
    [approvalStatus.APPROVAL_SUCCESS]: (
      state: RefundsApprovalState,
      { payload: { requestId } }
    ) => {
      const items = state.refunds.items.filter((req) => req.id !== requestId);

      const refunds = {
        ...state.refunds,
        items,
        totalCount: state.refunds.totalCount - 1,
      };

      const newState = {
        ...state,
        refunds,
        approvalStatus: approvalStatus.APPROVAL_STATUS_SUCCESS,
        approvalFailedMessage: undefined,
      };

      return newState;
    },
    [rejectStatus.REJECT_ATTEMPT]: (state) => ({
      ...state,
      rejectStatus: rejectStatus.REJECT_STATUS_INPROGRESS,
      approvalStatus: approvalStatus.APPROVAL_STATUS_NONE,
      approvalFailedMessage: undefined,
    }),
    [rejectStatus.REJECT_SUCCESS]: (state, { payload: { requestId } }) => ({
      ...state,
      refunds: {
        ...state.refunds,
        items: state.refunds.items.filter((req) => req.id !== requestId),
        totalCount: state.refunds.totalCount - 1,
      },
      rejectStatus: rejectStatus.REJECT_STATUS_SUCCESS,
    }),
    [rejectStatus.REJECT_ERROR_REFUND_REQUEST_PROCESSED]: (state) => ({
      ...state,
      approvalFailedMessage: ApprovalFailedMessage.approvalRefundRequestAlreadyProcessed,
      rejectStatus: rejectStatus.REJECT_STATUS_ERROR,
    }),
    [rejectStatus.REJECT_ERROR]: (state) => ({
      ...state,
      rejectStatus: rejectStatus.REJECT_STATUS_ERROR,
      approvalFailedMessage: ApprovalFailedMessage.approvalError,
    }),
    [deleteStatus.DELETE_ATTEMPT]: (state) => ({
      ...state,
      deleteStatus: deleteStatus.DELETE_STATUS_INPROGRESS,
      approvalFailedMessage: undefined,
    }),
    [deleteStatus.DELETE_SUCCESS]: (state, { payload: { requestId } }) => ({
      ...state,
      refunds: {
        ...state.refunds,
        items: state.refunds.items.filter((req) => req.id !== requestId),
        errorItems: state.refunds.errorItems.filter((req) => req.meta.id !== requestId),
        totalCount: state.refunds.totalCount - 1,
      },
      deleteStatus: deleteStatus.DELETE_STATUS_SUCCESS,
    }),
    [deleteStatus.DELETE_ERROR_REFUND_REQUEST_PROCESSED]: (state) => ({
      ...state,
      deleteStatus: deleteStatus.DELETE_STATUS_ERROR,
    }),
    [deleteStatus.DELETE_ERROR]: (state) => ({
      ...state,
      deleteStatus: deleteStatus.DELETE_STATUS_ERROR,
    }),
    [overrideStatus.OVERRIDE_ATTEMPT]: (state) => ({
      ...state,
      overrideStatus: overrideStatus.OVERRIDE_STATUS_INPROGRESS,
      approvalStatus: approvalStatus.APPROVAL_STATUS_NONE,
    }),
    [overrideStatus.OVERRIDE_SUCCESS]: (state) => ({
      ...state,
      overrideStatus: overrideStatus.OVERRIDE_STATUS_SUCCESS,
    }),
    [overrideStatus.OVERRIDE_ERROR]: (state) => ({
      ...state,
      overrideStatus: overrideStatus.OVERRIDE_STATUS_ERROR,
    }),
    [RESET_STATE]: () => initialState,
    [loadStatus.LOAD_NEXT_ATTEMPT]: (state, { payload: { sortDirection } }) => ({
      ...state,
      sortDirection,
      loadStatus: loadStatus.LOAD_NEXT_STATUS_INPROGRESS, // TODO: probably want a different state here so we can show the loader at the bottom of the list
    }),
    [loadStatus.LOAD_NEXT_SUCCESS]: (state, { payload: { refunds } }) => {
      const newState = {
        ...state,
        loadStatus: loadStatus.LOAD_NEXT_STATUS_NONE,
      };

      newState.refunds.items = [...newState.refunds.items, ...refunds.items];
      newState.refunds.errorItems = [...newState.refunds.errorItems, ...refunds.errorItems];
      newState.refunds.nextPage = refunds.nextPage;

      return newState;
    },
    [loadStatus.LOAD_NEXT_ERROR]: (state) => ({
      ...state,
      loadStatus: loadStatus.LOAD_NEXT_STATUS_ERROR,
    }),
  },
  initialState,
  { prefix: PREFIX }
);
