import React, { FunctionComponent } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { Paragraph } from '@trainline/depot-web';

import StatusLabel, { Status } from '@contactcentre-web/common/StatusLabel';
import DocumentDownload from '@contactcentre-web/common/DocumentDownload';

import messages from './messages';
import styles from './styles';

type InvoiceStatus = 'fulfilling' | 'fulfilled' | 'failed' | 'deferred' | 'email';

interface Props {
  invoice: {
    status: InvoiceStatus;
    url?: string;
  };
}

const mapInvoiceStatus: Record<
  InvoiceStatus,
  { status?: Status; statusMessage?: MessageDescriptor; descriptionMessage?: MessageDescriptor }
> = {
  fulfilling: {
    status: 'info',
    statusMessage: messages.requested,
    descriptionMessage: messages.waitingForApproval,
  },
  fulfilled: { status: 'positive', statusMessage: messages.approved },
  failed: { status: 'negative', statusMessage: messages.rejected },
  deferred: {
    status: 'positive',
    statusMessage: messages.approved,
    descriptionMessage: messages.generating,
  },
  email: { descriptionMessage: messages.sentToEmail },
};

const Invoice: FunctionComponent<Props> = ({ invoice: { status, url } }) => {
  const invoiceStatus = mapInvoiceStatus[status].status;
  const invoiceStatusMessage = mapInvoiceStatus[status].statusMessage;
  const invoiceDescriptionMessage = mapInvoiceStatus[status].descriptionMessage;

  return (
    <>
      {invoiceStatus && invoiceStatusMessage && !url && (
        <StatusLabel status={invoiceStatus} styleSheet={styles.status}>
          <FormattedMessage {...invoiceStatusMessage} />
        </StatusLabel>
      )}
      {invoiceDescriptionMessage && !url && (
        <Paragraph typeStyle="small" as="p" color="base" fontWeight="regular">
          <FormattedMessage {...invoiceDescriptionMessage} />
        </Paragraph>
      )}
      {url && (
        <DocumentDownload link={url} withRequest fileName={`invoice-${new Date().getTime()}.pdf`}>
          <FormattedMessage {...messages.pdfInvoice} />
        </DocumentDownload>
      )}
    </>
  );
};

export default Invoice;
