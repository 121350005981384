import { StyleSheet } from 'aphrodite/no-important';
import {
  fonts,
  spacers,
  borderRadius,
  typography,
} from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  dateWrapper: {
    backgroundColor: colors.blue.dark,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `0 ${spacers.dp_s8} ${spacers.dp_s16}`,
  },
  orderPayment: {
    fontSize: typography.dp_body_font_size,
    lineHeight: typography.dp_body_line_height,
    letterSpacing: typography.dp_body_letter_spacing,
    fontWeight: fonts.dp_font_weight_bold,
    color: '#d5cef7',
    display: 'inline-flex',
    alignItems: 'center',
  },
  time: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    fontWeight: fonts.dp_font_weight_semibold,
    borderRadius: borderRadius.dp_r8,
    color: '#142f5f',
    backgroundColor: '#d5cef7',
    display: 'inherit',
    padding: `0 ${spacers.dp_s8}`,
  },
  header: {
    borderRadius: `${borderRadius.dp_r2} ${borderRadius.dp_r2} 0 0`,
    backgroundColor: '#f3f1fd',
    padding: `${spacers.dp_s16} ${spacers.dp_s24}`,
  },
  itemTableHeader: {
    textAlign: 'left',
    width: '180px',
  },
  ticketsTableHeader: {
    color: colors.blue.royal,
    fontSize: typography.dp_micro_font_size,
    fontWeight: fonts.dp_font_weight_regular,
    lineHeight: typography.dp_micro_line_height,
    paddingTop: spacers.dp_s16,
    paddingBottom: spacers.dp_s8,
    textTransform: 'uppercase',
    width: '100px',
  },
  ticketsCellAlignRight: {
    textAlign: 'right',
  },
  ticketsCellAlignLeft: {
    textAlign: 'left',
  },
  ticketsTable: {
    borderCollapse: 'collapse',
    tableLayout: 'fixed',
    width: '100%',
  },
  pl: {
    paddingLeft: spacers.dp_s24,
  },
  pr: {
    paddingRight: spacers.dp_s24,
  },
  infoIcon: {
    marginLeft: spacers.dp_s8,
  },
});
