import { StyleSheet } from 'aphrodite/no-important';
import { spacers, typography } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  select: {
    marginBottom: spacers.dp_s16,
  },
  noError: {
    height: '24px',
  },
  error: {
    fontSize: typography.dp_mini_font_size_m,
    height: '24px',
    color: 'red',
  },
});
