import { defineMessages } from 'react-intl';

export default defineMessages({
  detaxeRefund: {
    id: 'Detaxe.detaxeRefund',
    defaultMessage: 'SNCF eDetaxe refund',
  },
  modalHeader: {
    id: 'Detaxe.modalHeader',
    defaultMessage: 'Select booking',
  },
});
