import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector, formValues } from 'redux-form';

import SurveyChoiceSelect, { surveyChoiceId } from './components/SurveyChoiceSelect';
import SurveyChoiceRadioBox, { surveyChoice } from './components/SurveyChoiceRadioBox';

export const SurveyChoice = ({ surveyChoices, surveyChoiceValue = '', clearSurveyChoice }) => {
  const isTrue = surveyChoiceValue.trim().toLowerCase() === 'yes';
  const clearSurveyChoiceRef = React.useRef(clearSurveyChoice);

  React.useEffect(() => {
    if (!isTrue) {
      clearSurveyChoiceRef.current();
    }
  }, [isTrue, clearSurveyChoiceRef.current]);

  const yesSurveyReason = surveyChoices
    .filter((choice) => choice.sendSurvey)
    .map((choice) => ({ ...choice, name: '-' }));
  const noSurveyChoices = surveyChoices.filter((choice) => !choice.sendSurvey);
  const activeSurveyChoices = isTrue ? yesSurveyReason : noSurveyChoices;

  return (
    <>
      <SurveyChoiceRadioBox />
      <SurveyChoiceSelect surveyChoices={activeSurveyChoices} disabled={isTrue} />
    </>
  );
};

SurveyChoice.propTypes = {
  surveyChoices: PropTypes.arrayOf(PropTypes.object),
  surveyChoiceValue: PropTypes.string.isRequired,
  clearSurveyChoice: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { form }) => {
  const formSelector = formValueSelector(form);

  return { surveyChoiceValue: formSelector(state, surveyChoice) };
};

const mapDispactchToProps = (dispatch, { change }) => ({
  clearSurveyChoice: () => dispatch(change(surveyChoiceId, null)),
});

export default connect(
  mapStateToProps,
  mapDispactchToProps
)(formValues(surveyChoice)(SurveyChoice));
