import { StyleSheet } from 'aphrodite/no-important';
import { palettes, spacers, fonts, typography } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  profile: {
    display: 'grid',
    gridTemplateColumns: '3fr repeat(4, auto)',
    columnGap: spacers.dp_s16,
    alignItems: 'center',
    marginBottom: spacers.dp_s8,
    fontSize: typography.dp_small_font_size_m,
    lineHeight: typography.dp_small_line_height,
  },
  successAlert: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  container: {
    backgroundColor: palettes.dp_app_info_darkest,
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0 auto',
    fontSize: typography.dp_small_font_size,
    lineHeight: typography.dp_small_line_height,
  },
  profileDetails: {
    ':nth-child(n) > div:not(:first-child)': {
      marginTop: spacers.dp_s4,
    },
  },
  innerContainer: {
    margin: '0 auto',
    padding: `${spacers.dp_s24} 0`,
    color: palettes.dp_app_text_inverted,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  innerContainerCollapsed: {
    padding: `${spacers.dp_s24} 0`,
  },
  viewBookings: {
    marginLeft: spacers.dp_s16,
  },
  editProfileSuccess: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  closeButton: {
    lineHeight: 0,
  },
  headerButton: {
    background: 'none',
    color: palettes.dp_app_text_inverted,
    fontWeight: fonts.dp_font_weight_regular,
    fontSize: typography.dp_small_font_size_m,
    padding: `${spacers.dp_s4} ${spacers.dp_s8}`,
    ':hover': {
      color: palettes.dp_app_info_darkest,
    },
  },
  headerButtonActive: {
    fontWeight: fonts.dp_font_weight_bold,
    background: palettes.dp_app_backdrop_base,
    color: palettes.dp_app_info_darkest,
    ':focus': {
      boxShadow: 'none',
    },
  },
});
