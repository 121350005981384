import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import type { AgentType } from '@contactcentre-web/hooks/api/useOrderHistory';
import { BookingHistoryItem } from '@contactcentre-web/order-history/module';

import OrderHistory from '../OrderHistory/OrderHistory';

import messages from './messages';

const Refresh: FunctionComponent<BookingHistoryItem> = ({ createdAt, entries }) => {
  const agentName = entries?.find(({ agent, action }) => action === 'refresh' && agent !== null)
    ?.agent as string;
  const agentType = entries?.find(
    ({ requestorType, action }) => action === 'refresh' && requestorType
  )?.requestorType as AgentType;
  const user = { name: agentName, type: agentType };

  return (
    <OrderHistory.Card>
      <OrderHistory.Header date={createdAt} user={user}>
        <FormattedMessage {...messages.orderRefreshed} />
      </OrderHistory.Header>
    </OrderHistory.Card>
  );
};

export default Refresh;
