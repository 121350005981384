import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  note_container: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: spacers.dp_s24,
  },
  note_label: {
    flex: '0 0 auto',
  },
  note_input_container: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  note_input: {
    minHeight: '140px',
  },
  notice: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: spacers.dp_s4,
  },
  noticeWithIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  button_container: {
    flex: '0 0 auto',
    paddingTop: spacers.dp_s24,
  },
  textAreaError: {
    flex: '0 0 auto',
  },
  commentIcon: {
    marginRight: spacers.dp_s4,
  },
  selectWrapper: {
    margin: `${spacers.dp_s24} 0`,
  },
  disabledSelect: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  alertBox: {
    paddingTop: spacers.dp_s8,
    paddingBottom: spacers.dp_s8,
    marginBottom: spacers.dp_s16,
  },
  loader: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: spacers.dp_s8,
  },
});
