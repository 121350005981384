import { createSelector } from 'reselect';
import moment from 'moment';
import { compose, flatten, filter, find, path, pluck, any, prop } from 'ramda';

import * as orderSelectors from '@contactcentre-web/redux-common/selectors/order';
import * as userSelectors from '@contactcentre-web/authentication/redux/selectors';
import reduceToObj from '@contactcentre-web/utils/reduceToObj';
import { selectLocalAreaProducts } from '@contactcentre-web/redux-common/selectors/products';

import {
  addFriendlyTicketIndexes,
  applySplitSaveFixToRefundJourneys,
  getFriendlyTicketIdMap,
  getRefundableLocalAreaProduct,
  getRefundableProduct,
  isFraudNonProcessable,
  isFraudNonProcessableQuote,
  isFraudNonProcessableQuoteOverride,
  isPartialRefundEntangled,
} from '../utils';

import { journeysWithRefundability, localAreasWithRefundibility } from './utils';
import { loadRefundsState } from './module';

export const localState = (state) => state && state.discretionaryRefunds;

const inProgress = createSelector(
  localState,
  (state) => state.loadRefundsState === loadRefundsState.LOAD_REFUNDS_INPROGRESS
);

const hasSucceeded = createSelector(
  localState,
  (state) => state.loadRefundsState === loadRefundsState.LOAD_REFUNDS_SUCCESS
);

const hasFailed = createSelector(
  localState,
  (state) => state.loadRefundsState === loadRefundsState.LOAD_REFUNDS_ERROR
);

const getOrder = (state) => orderSelectors.getOrder(state);

const canVoid = createSelector(getOrder, (order) => !!path(['voidable', 'isVoidable'], order));

const rehydrateReasonCodesHierarchy = (reasons) => {
  const reasonCodes = reasons.map((reason) => ({
    ...reason,
    description: reason.name,
  }));

  // Attach parent node so that we can manipulate the hierarchy more easily.
  reasonCodes.forEach((r) => {
    if (r.parent) {
      // eslint-disable-next-line
      r.parent = reasonCodes.find(({ id }) => id === r.parent);
    }
  });

  return reasonCodes;
};

const refundReasonsRaw = createSelector(localState, (state) => state.refundReasons);
const refundReasons = createSelector(refundReasonsRaw, (reasons) =>
  reasons ? rehydrateReasonCodesHierarchy(reasons) : []
);

const pendingRefundRequested = createSelector(localState, (state) => state.pendingRefunds);

const getOrderId = createSelector(getOrder, (order) => (order && order.id) || '');

const getRefundables = createSelector(localState, (state) => state.refundables);

const getRefunds = createSelector(localState, (state) => state.refunds);

const getQuotes = createSelector(localState, (state) => state.quotes);

const getQuotesValidationErrors = createSelector(
  localState,
  (state) => state.quotesValidationErrors
);

const getPendingRefunds = (state) => state.discretionaryRefunds.pendingRefunds;

const getPendingRefundLinks = (bookingId) =>
  createSelector(
    getPendingRefunds,
    (pendingRefunds) =>
      pendingRefunds
        .filter((pendingRefund) => pendingRefund.bookingId === bookingId)
        .map(({ links }) =>
          links.reduce((acc, curr) => {
            acc[curr.rel] = curr;
            return acc;
          }, {})
        )[0]
  );

const canProcessBookingId = (bookingId) =>
  createSelector(
    getPendingRefunds,
    (pendingRefunds) =>
      pendingRefunds
        .filter((pendingRefund) => pendingRefund.bookingId === bookingId)
        .map(({ canProcess }) => canProcess)[0]
  );

const getApproveRefundState = (state) => state.discretionaryRefunds.approveRefundState;

const isApproveRefundPending = (bookingId) =>
  createSelector(getApproveRefundState, (approveRefundState) =>
    approveRefundState[bookingId] ? !!approveRefundState[bookingId].pending : false
  );

const getApproveRefundError = (bookingId) =>
  createSelector(getApproveRefundState, (approveRefundState) =>
    approveRefundState[bookingId] ? approveRefundState[bookingId].error : false
  );

const isAnyApproveRefundSuccess = createSelector(getApproveRefundState, (approveRefundState) =>
  Object.keys(approveRefundState).some((bookingId) =>
    approveRefundState[bookingId] ? !!approveRefundState[bookingId].success : false
  )
);

const quotesSelector = (state) => state.discretionaryRefunds.quotes;

const getBookingQuote = (bookingId) =>
  createSelector(
    quotesSelector,
    (quotes) => quotes && quotes[bookingId] && quotes[bookingId].quoteId
  );

const getAdminFee = (bookingId) =>
  createSelector(getRefundables, (refundables) =>
    compose(
      prop('selectedByDefault'),
      prop('selectability'),
      find((chargedFee) => chargedFee.type === 'admin-fee'),
      prop('chargedFees'),
      find((refund) => refund.productId === bookingId),
      prop('refundables')
    )(refundables)
  );

const getReturnUrl = createSelector(localState, (state) => state.returnUrl);

const isQuoteProcessable = (quote) =>
  !quote ||
  !quote.unprocessableReasons ||
  quote.unprocessableReasons.length === 0 ||
  isFraudNonProcessableQuote(quote);

const getFeeRefundable = (fees, feeType, quote) => {
  const feeRefundable = fees.find(({ type }) => type.toLowerCase() === feeType.toLowerCase());
  if (!feeRefundable) {
    return undefined;
  }

  const { refundableId, selectability, status } = feeRefundable;
  const quoteRefundable = quote && quote.refundables.find(({ id }) => id === refundableId);

  return {
    id: refundableId,
    description: refundableId, // TODO: This will cause a lot of pain in the future, but we have to live with it for now
    isSelected: isQuoteProcessable(quote) && !!quoteRefundable,
    isChangeable: isQuoteProcessable(quote) && !!selectability && selectability.selectable,
    refundable: (status || '').toLowerCase() === 'refundable',
    price: (quoteRefundable && quoteRefundable.refundableAmount) || {
      amount: 0,
      currencyCode: null,
    },
    isEligible: !!selectability && selectability.selectedByDefault,
  };
};

const refundForBooking = (refunds, bookingId) =>
  compose(
    find(({ productId }) => productId === bookingId),
    filter((item) => !!item),
    flatten(),
    pluck('refundableGroups')
  )(refunds);

const includesTgvMaxjourney = (journeys) =>
  compose(
    any((c) => c.name.toLowerCase() === 'Abonnement TGVmax'.toLowerCase()),
    flatten(),
    pluck('railCards'),
    flatten(),
    pluck('farePassengers')
  )(journeys);

const travelBookingToRefundBooking = (
  { id, journeys, insurance, fulfilmentStatus, isReturn, afterSaleSystem, vendor },
  refundables,
  refunds,
  pendingRefunds,
  quote,
  approveRefund,
  validationErrors,
  managedGroupNumber,
  localAreaProducts
) => {
  const productRefundable = getRefundableProduct(id, refundables);

  const { journeys: refundJourneys, canProcess: canProcessJourneys } = journeysWithRefundability(
    journeys,
    productRefundable.passengerRefundables,
    quote,
    isReturn
  );

  const localAreaRefundable = getRefundableLocalAreaProduct(localAreaProducts, refundables);

  const { journeys: refundLocalAreaJourneys, canProcess: canProcessLocalAreaJourneys } =
    localAreasWithRefundibility(localAreaProducts, localAreaRefundable.passengerRefundables, quote);

  const { chargedFees: productChargedFees, fees: productFees } = productRefundable;
  const { chargedFees: localAreaChargedFees, fees: localAreaFees } = localAreaRefundable;

  const fees = [...productFees, ...localAreaFees];
  const chargedFees = [...productChargedFees, ...localAreaChargedFees];

  const feeRefundables = {
    bookingFee: getFeeRefundable(fees, 'booking-fee', quote),
    paymentFee: getFeeRefundable(fees, 'payment-fee', quote),
    deliveryFee: getFeeRefundable(fees, 'delivery-fee', quote),
  };

  const refund = refundForBooking(refunds, id);

  const { adminFee } = reduceToObj(chargedFees, 'type');

  const partialRefundIsEntangled = isPartialRefundEntangled([
    ...refundJourneys,
    ...refundLocalAreaJourneys,
  ]);

  const fixedRefundJourneys = applySplitSaveFixToRefundJourneys(
    refundJourneys,
    productRefundable.passengerRefundables
  );

  const friendlyTicketIndexMapping = getFriendlyTicketIdMap([
    ...fixedRefundJourneys,
    ...refundLocalAreaJourneys,
  ]);

  const refundTravelJourneysWithTicketIndexes = addFriendlyTicketIndexes(
    fixedRefundJourneys,
    friendlyTicketIndexMapping
  );

  const refundLocalAreaJourneysWithTicketIndexes = addFriendlyTicketIndexes(
    refundLocalAreaJourneys,
    friendlyTicketIndexMapping
  );

  const refundAlertMessage = (() => {
    if (!quote) {
      return null;
    }

    switch (quote.ticketReturnMode) {
      case 'deletefromdevice':
        return 'deleteFromDevicePreProcess';
      case 'post':
      case 'collectpost': {
        const isATOC = vendor?.toLowerCase() === 'atoc';
        const isMG20 = managedGroupNumber === 20;
        return isATOC && isMG20 ? 'customerAdvisory' : null;
      }
      default:
        return null;
    }
  })();

  const quoteConditionHasEntanglement = (condition) =>
    condition.reason?.code?.startsWith('entanglementWarning');

  const quoteHasEntanglement = !!quote?.quoteConditions?.find((condition) =>
    quoteConditionHasEntanglement(condition)
  );

  const uniqueUnprocessableReasons = [
    ...(quote?.unprocessableReasons ?? []),
    ...(approveRefund?.unprocessableReasons ?? []),
    ...(validationErrors ?? []),
  ]
    .filter(Boolean)
    .reduce(
      (reasonArr, unprocessableReason) =>
        reasonArr.some((r) => r.reasonCode === unprocessableReason?.reasonCode)
          ? reasonArr
          : [...reasonArr, unprocessableReason],
      []
    );

  const isOverridable = isFraudNonProcessable(quote, approveRefund);
  const isOverriden = isFraudNonProcessableQuoteOverride(quote);

  const canRequest =
    pendingRefunds &&
    !pendingRefunds.filter((pendingRefund) => pendingRefund.bookingId === id).length &&
    (!isOverridable || isOverriden);

  const canProcess = !!canProcessJourneys && !!canProcessLocalAreaJourneys;

  const bookingPendingRefunds = (pendingRefunds || [])
    .filter((pendingRefund) => pendingRefund.bookingId === id)
    .map(({ agentUsername, requestDate, canProcess: canProcessRefund }) => ({
      agent: agentUsername,
      timestamp: requestDate,
      canProcess: canProcessRefund,
    }));

  return {
    id,
    journeys: refundTravelJourneysWithTicketIndexes,
    localAreaJourneys: refundLocalAreaJourneysWithTicketIndexes,
    refundable: canProcess,
    insurance,
    fulfilmentStatus,
    fees: feeRefundables,
    quoteReference: quote && quote.quoteId,
    quoteUuid: quote && quote.quoteUuid,
    quoteHasEntanglement,
    quoteConditions: quote?.quoteConditions || [],
    state: {
      canRequest,
      canProcess: bookingPendingRefunds.length
        ? bookingPendingRefunds[0].canProcess && (!isOverridable || isOverriden)
        : false,
      pendingRefunds: bookingPendingRefunds,
    },
    refund: refund && {
      ticketReturnMode: refund.ticketReturnMode && {
        name: refund.ticketReturnMode.name,
        returnAddress: refund.ticketReturnMode.address,
      },
    },
    adminFee: quote &&
      quote.adminFee && {
        id: 'adminFee',
        description: 'adminFee',
        price:
          quote.adminFee.value ||
          (quote.totalRefundableAmount && {
            amount: 0,
            currencyCode: quote.totalRefundableAmount.currencyCode,
          }),
        isSelected: isQuoteProcessable(quote) && !!quote.adminFee.deducted,
        isChangeable: adminFee && adminFee.selectability && adminFee.selectability.selectable,
      },
    discounts: quote?.discounts,
    reasonCode: quote && quote.reasonId,
    refundAlertMessage,
    productTotal: quote && quote.totalRefundableAmount,
    total: quote && quote.totalToRefund,
    mangled:
      !!refund && (refund.refundStatus === 'processing' || refund.refundStatus === 'requested'),
    partialRefundIsEntangled,
    includesTgvMaxjourney: includesTgvMaxjourney(refundJourneys),
    afterSaleSystem,
    unprocessableReasons: uniqueUnprocessableReasons,
    isOverridable,
    isOverriden,
  };
};

const getBookings = createSelector(
  getOrder,
  getRefundables,
  getRefunds,
  getQuotes,
  getApproveRefundState,
  getQuotesValidationErrors,
  pendingRefundRequested,
  userSelectors.getCurrentManagedGroupNumber,
  selectLocalAreaProducts,
  (
    order,
    { refundables } = {},
    refunds,
    quotes,
    approveRefund,
    quotesValidationErrors,
    pendingRefunds,
    managedGroupNumber,
    localAreaProducts
  ) => {
    if (!order || !refundables) {
      return [];
    }

    const { travelBookings } = order;

    return travelBookings
      .map((b) => {
        const travelBooking = b;

        const filteredLocalAreaProducts = localAreaProducts.filter(
          ({ ctr }) => travelBooking.ctr && travelBooking.ctr === ctr
        );

        const bookings = travelBookingToRefundBooking(
          travelBooking,
          refundables,
          refunds,
          pendingRefunds,
          quotes && quotes[travelBooking.id],
          approveRefund && approveRefund[travelBooking.id],
          quotesValidationErrors &&
            quotesValidationErrors[travelBooking.id] &&
            quotesValidationErrors[travelBooking.id].unprocessableReasons,
          managedGroupNumber,
          filteredLocalAreaProducts
        );

        // After the hack done in filteredJourneys, this will fix the isReturn in case of
        // return split saves in the journeys
        travelBooking.isReturn = travelBooking.isReturn && bookings.journeys.length === 1;

        return bookings;
      })
      .sort((travelBooking1, travelBooking2) =>
        moment
          .utc(travelBooking1.journeys[0]?.departAt)
          .diff(moment.utc(travelBooking2.journeys[0]?.departAt))
      );
  }
);

const selectors = {
  localState,
  inProgress,
  hasSucceeded,
  hasFailed,
  getBookings,
  refundReasons,
  pendingRefundRequested,
  canVoid,
  getOrderId,
  getPendingRefunds,
  getPendingRefundLinks,
  isApproveRefundPending,
  isAnyApproveRefundSuccess,
  getApproveRefundError,
  canProcessBookingId,
  getBookingQuote,
  getReturnUrl,
  getAdminFee,
};

export default selectors;
