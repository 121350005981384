import { StyleSheet } from 'aphrodite/no-important';
import {
  spacers,
  typography,
  palettes,
  borderRadius,
  fonts,
} from '@trainline/depot-web/css/themes/trainline';

const fadeMoveIn = {
  '0%': {
    opacity: 0,
    transform: 'translateY(-50px)',
  },
  '100%': {
    opacity: 1,
    transform: 'translateY(0)',
  },
};

export const fieldStyles = StyleSheet.create({
  label: {
    marginBottom: spacers.dp_s16,
    marginTop: `calc(${spacers.dp_s8} + 1px)` /* keep consistency with TextField */,
    display: 'block',
  },
});
// TODO: refactor colors if reuse ? since they are not part of ta-components.
export default StyleSheet.create({
  container: {
    border: `solid 1px ${palettes.dp_app_info_lighter}`,
    borderRadius: borderRadius.dp_r4,
    background: palettes.dp_app_backdrop_dark,
    padding: spacers.dp_s32,
    marginBottom: spacers.dp_s32,
  },
  title: {
    fontSize: typography.dp_title3_font_size,
    fontWeight: fonts.dp_font_weight_bold,
    lineHeight: typography.dp_title3_line_height,
    letterSpacing: typography.dp_title3_letter_spacing,
    paddingBottom: spacers.dp_s24,
  },
  column: {
    flex: 1,
  },
  button: {
    marginTop: spacers.dp_s8,
  },
  inputContainer: {
    width: '400px',
  },
  fieldContainer: {
    display: 'flex',
    marginBottom: spacers.dp_s8,
  },
  removeEmailButton: {
    color: palettes.dp_comp_input_border,
    padding: spacers.dp_s4,
    display: 'flex',
    alignItems: 'center',
  },
  addEmail: {
    color: palettes.dp_app_info_mid,
    marginBottom: spacers.dp_s16,
    display: 'flex',
    alignItems: 'center',
  },
  iconEmail: {
    fill: palettes.dp_app_action_primary,
    marginRight: spacers.dp_s8,
  },
  info: {
    border: `1px solid ${palettes.dp_app_info_lighter}`,
  },
  fieldsContainer: {
    display: 'flex',

    ':not(:last-of-type)': {
      borderBottom: `1px solid ${palettes.dp_app_info_lighter}`,
      paddingBottom: spacers.dp_s32,
      marginBottom: spacers.dp_s24,
    },
  },
  notification: {
    // @keyframes are not working on IE10 and aphrodite
    opacity: 0,
    animationName: [fadeMoveIn],
    animationDuration: '1s',
    animationFillMode: 'forwards',
  },
  checkboxContainer: {
    flexDirection: 'column',
  },
  checkbox: {
    marginBottom: spacers.dp_s24,
  },
  errorContainer: {
    height: spacers.dp_s24,
    fontSize: typography.dp_small_font_size_m,
    lineHeight: typography.dp_small_line_height,
    color: palettes.dp_app_negative_mid,
    marginBottom: spacers.dp_s4,
  },
});
