import React from 'react';

import PageContainer from '@contactcentre-web/common/PageContainer/PageContainer';
import ErrorBoundary from '@contactcentre-web/common/ErrorBoundary/ErrorBoundary';
import ManualEntryConsole from '@contactcentre-web/manual-entry-console/ManualEntryConsole';

import styles from './styles';

const ManualEntryConsolePage = () => (
  <PageContainer styleSheet={styles.container}>
    <ErrorBoundary>
      <ManualEntryConsole />
    </ErrorBoundary>
  </PageContainer>
);

export default ManualEntryConsolePage;
