import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency';

import HistoryCardHeader from '../HistoryCardHeader/HistoryCardHeader';
import type { ReimbursementItem } from '../../module';

import styles from './styles';
import messages from './messages';

const Reimbursement: FunctionComponent<ReimbursementItem> = ({
  createdAt,
  totalReimbursedAmount,
  bookings,
}) => (
  <div className={css(styles.container)}>
    <HistoryCardHeader
      itemTypeContent={<FormattedMessage {...messages.title} />}
      itemTimestamp={createdAt}
      bookings={bookings}
    />
    <div className={css(styles.body)}>
      {totalReimbursedAmount?.amount ? (
        <span>
          <FormattedMessage
            {...messages.message}
            values={{
              amount: <FormattedCurrency {...totalReimbursedAmount} />,
            }}
          />
        </span>
      ) : null}
    </div>
  </div>
);

export default Reimbursement;
