import React, { FunctionComponent, ReactElement } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';
import { Field, WrappedFieldProps } from 'redux-form';

import DateSelect from '../DateSelect';

import styles from './styles';

interface Props {
  messages?: Record<string, MessageDescriptor>;
  label?: string | ReactElement;
  name: string;
  minDate?: Date;
  maxDate?: Date;
  styleSheet?: {
    calendar?: StyleDeclarationValue;
  };
}

const DateFieldWrapper: FunctionComponent<Props & WrappedFieldProps> = ({
  input: { onChange, value },
  meta: { error, touched },
  messages,
  ...others
}) => (
  <div>
    <DateSelect {...others} onChange={onChange} value={value} />
    {touched && error && messages && messages[error] && (
      <div className={css(styles.errorContainer)}>
        <FormattedMessage {...messages[error]} />
      </div>
    )}
  </div>
);

const DateField = (props: Props) => <Field component={DateFieldWrapper} {...props} />;

export default DateField;
