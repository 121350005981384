import { Heading } from '@trainline/depot-web';
import { css } from 'aphrodite/no-important';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormSection } from 'redux-form';

import TextCounter from '@contactcentre-web/common/TextCounter';
import RefundReason from '@contactcentre-web/refunds/common/RefundReason';
import { VirtualTerminalResponse } from '@contactcentre-web/hooks/api/useVirtualTerminal';
import { useFetchReasonCodes } from '@contactcentre-web/hooks/api/useCompensation';
import TextField from '@contactcentre-web/common/TextField';

import PaymentDetails from '../PaymentDetails/PaymentDetails';

import messages from './messages';
import styles from './styles';

export const FORM_ID = 'VirtualTerminalCustomerForm';

export interface Props {
  data: VirtualTerminalResponse;
  notesLength: number;
}

export const FORM_CUSTOMER = 'customerForm';

const CustomerForm = ({ data, notesLength }: Props) => {
  const MAX_TEXT_LENGTH = 1000;

  const { data: reasons, isLoading, isError } = useFetchReasonCodes();
  const { formatMessage } = useIntl();

  return (
    <FormSection name={FORM_CUSTOMER} className={css(styles.wrapper)}>
      <div className={css(styles.container)}>
        {data?.paymentDetails && <PaymentDetails paymentDetails={data.paymentDetails} />}
        <Heading typeStyle="title3" as="h2" color="base">
          <FormattedMessage {...messages.customerDetail} />
        </Heading>
        <div className={css(styles.rowFields)}>
          <TextField
            label={formatMessage(messages.firstName)}
            name="firstName"
            maxLength={MAX_TEXT_LENGTH}
            messages={messages}
          />
          <TextField
            label={formatMessage(messages.lastName)}
            name="lastName"
            maxLength={MAX_TEXT_LENGTH}
            messages={messages}
          />
        </div>

        <TextField
          label={formatMessage(messages.emailAddress)}
          name="email"
          maxLength={MAX_TEXT_LENGTH}
          messages={messages}
        />
      </div>
      <div className={css(styles.container, styles.containerLast)}>
        {reasons && (
          <div className={css(styles.rowRefundReason)}>
            <RefundReason
              reasons={reasons}
              disabled={isLoading || isError}
              isReasonCodePerRefundable
              messages={messages}
            />
          </div>
        )}
        <TextField
          name="notes"
          label={formatMessage(messages.notes)}
          type="multiline-text"
          rows={7}
          maxLength={MAX_TEXT_LENGTH}
          messages={messages}
        />
        <TextCounter textLength={notesLength} maxLength={MAX_TEXT_LENGTH} align="right" />
      </div>
    </FormSection>
  );
};

export default CustomerForm;
