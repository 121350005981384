import { css } from 'aphrodite/no-important';
import React, { useState } from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';

import Link from '@contactcentre-web/common/Link';

import { CallingPoint } from '../../JourneyItemModal';

import messages from './messages';
import styles from './styles';

type CallingPointProps = {
  duration: string;
  callingPoints: CallingPoint[];
};

const CallingPoints = ({ duration, callingPoints }: CallingPointProps) => {
  const [showCallingPoints, setShowCallingPoints] = useState(false);
  return (
    <div className={css(styles.callingPointContent)}>
      <div className={css(styles.callingPointsItem)}>
        <div className={css(styles.callingPoints)}>
          <div className={css(styles.callingPointMarker)}></div>
        </div>
        {callingPoints.length > 0 ? (
          <>
            <div className={css(styles.locationItemTime)}>{duration}</div>
            <div className={css(styles.callingPointLocation)}>
              <Link
                linkType="action"
                onClick={() => setShowCallingPoints(!showCallingPoints)}
                data-test="show-calling-points"
              >
                {showCallingPoints ? (
                  <FormattedMessage {...messages.hideCallingPoints} />
                ) : (
                  <FormattedMessage {...messages.showCallingPoints} />
                )}
              </Link>
            </div>
          </>
        ) : (
          <>
            <div className={css(styles.locationItemTimeNoText)}>{duration}</div>
          </>
        )}
      </div>
      {showCallingPoints && (
        <>
          {callingPoints.map(
            ({ locationName, scheduledTime }: CallingPoint, indexCP: React.Key) => (
              <div
                className={css(styles.callingPointsItem)}
                key={`caling-point-index-${indexCP}`}
                data-test={`caling-point-index-${indexCP}`}
              >
                <div className={css(styles.callingPoints)}>
                  <div className={css(styles.callingPointMarker)} data-test="marker">
                    <div className={css(styles.dash)} data-test="point" />
                  </div>
                </div>
                <div className={css(styles.locationItemTime)}>
                  <FormattedTime value={scheduledTime} />
                </div>
                <div
                  className={css(styles.callingPointLocation)}
                  data-test={`calling-point-${locationName}-location-name`}
                >
                  {locationName}
                </div>
              </div>
            )
          )}
        </>
      )}
    </div>
  );
};

export default CallingPoints;
