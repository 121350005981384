import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from '@contactcentre-web/common/Modal';
import Button from '@contactcentre-web/common/Button';

import messages from './messages';

export interface RefundNonProcessableQuotePromptProps {
  onOverride: () => void;
  isEnablingOverride: boolean;
}

export const RefundNonProcessableQuotePrompt = ({
  onOverride,
  isEnablingOverride,
}: RefundNonProcessableQuotePromptProps) => {
  const [promptVisible, togglePrompt] = useState(false);

  return (
    <>
      {promptVisible && (
        <Modal onClose={() => togglePrompt(false)}>
          <ModalHeader>
            <ModalTitle>
              <FormattedMessage {...messages.title} />
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <FormattedMessage {...messages.content} />
          </ModalBody>
          <ModalFooter contentAlignment="spaceBetween">
            <Button
              variant="tertiary"
              size="large"
              onClick={() => togglePrompt(false)}
              testId="cancelButton"
            >
              <FormattedMessage {...messages.cancel} />
            </Button>
            <Button
              variant="primary"
              size="large"
              onClick={() => {
                onOverride();
                togglePrompt(false);
              }}
              testId="confirmButton"
            >
              <FormattedMessage {...messages.confirm} />
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <Button
        variant="primary"
        size="xlarge"
        onClick={() => togglePrompt(true)}
        loading={isEnablingOverride}
        disabled={isEnablingOverride}
        testId="enableButton"
      >
        <FormattedMessage {...messages.enableButton} />
      </Button>
    </>
  );
};

export default RefundNonProcessableQuotePrompt;
