import { defineMessages } from 'react-intl';

export default defineMessages({
  travelcard: {
    id: 'CustomerTravelcard.travelcard',
    defaultMessage: 'Add-on',
  },
  validity: {
    id: 'CustomerTravelcard.validity',
    defaultMessage: 'Validity',
  },
  validFrom: {
    id: 'CustomerTravelcard.validFrom',
    defaultMessage: 'Valid from',
  },
  validTo: {
    id: 'CustomerTravelcard.validTo',
    defaultMessage: 'Valid to',
  },
  deliveryMethod: {
    id: 'CustomerTravelcard.deliveryMethod',
    defaultMessage: 'Delivery method',
  },
  ctr: {
    id: 'CustomerTravelcard.ctr',
    defaultMessage: 'Ctr',
  },
  productPrice: {
    id: 'CustomerTravelcard.productPrice',
    defaultMessage: 'Product price',
  },
});
