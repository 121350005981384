import { createActions } from 'redux-actions';

const PREFIX = 'DISCRETIONARY_REFUNDS';
export const DISPOSE = `${PREFIX}/DISPOSE`;
const LOAD_REFUNDS = `${PREFIX}/LOAD_REFUNDS`;
const LOAD_REFUNDS_SUCCEEDED = `${PREFIX}/LOAD_REFUNDS_SUCCEEDED`;
const LOAD_REFUNDS_FAILED = `${PREFIX}/LOAD_REFUNDS_FAILED`;

export const LOAD_EXISTING_REFUND = `${PREFIX}/LOAD_EXISTING_REFUND`;
export const LOAD_EXISTING_REFUND_SUCCEEDED = `${PREFIX}/LOAD_EXISTING_REFUND_SUCCEEDED`;
export const LOAD_EXISTING_REFUND_FAILED = `${PREFIX}/LOAD_EXISTING_REFUND_FAILED`;

const LOAD_REFUNDS_NONE = `${PREFIX}/LOAD_REFUNDS_NONE`;
const LOAD_REFUNDS_INPROGRESS = `${PREFIX}/LOAD_REFUNDS_IN_PROGRESS`;
const LOAD_REFUNDS_SUCCESS = `${PREFIX}/LOAD_REFUNDS_SUCCESS`;
const LOAD_REFUNDS_ERROR = `${PREFIX}/LOAD_REFUNDS_ERROR`;

const REQUEST_REFUND_NONE = `${PREFIX}/REQUEST_REFUND_NONE`;
const REQUEST_REFUND_INPROGRESS = `${PREFIX}/REQUEST_REFUND_INPROGRESS`;
const REQUEST_REFUND_SUCCESS = `${PREFIX}/REQUEST_REFUND_SUCCESS`;
const REQUEST_REFUND_ERROR = `${PREFIX}/REQUEST_REFUND_ERROR`;

const APPROVE_REFUND = `${PREFIX}/APPROVE_REFUND`;
const APPROVE_REFUND_NONE = `${PREFIX}/APPROVE_REFUND_NONE`;
const APPROVE_REFUND_SUCCESS = `${PREFIX}/APPROVE_REFUND_SUCCESS`;
const APPROVE_REFUND_FAILED = `${PREFIX}/APPROVE_REFUND_FAILED`;

export const events = {
  DISPOSE,
  LOAD_REFUNDS,
  LOAD_REFUNDS_SUCCEEDED,
  LOAD_REFUNDS_FAILED,
  LOAD_EXISTING_REFUND,
  LOAD_EXISTING_REFUND_SUCCEEDED,
  LOAD_EXISTING_REFUND_FAILED,
  APPROVE_REFUND,
};

export const loadRefundsState = {
  LOAD_REFUNDS_NONE,
  LOAD_REFUNDS_INPROGRESS,
  LOAD_REFUNDS_SUCCESS,
  LOAD_REFUNDS_ERROR,
};

export const requestRefundState = {
  REQUEST_REFUND_NONE,
  REQUEST_REFUND_INPROGRESS,
  REQUEST_REFUND_SUCCESS,
  REQUEST_REFUND_ERROR,
};

export const approveRefundState = {
  APPROVE_REFUND_NONE,
  APPROVE_REFUND_SUCCESS,
  APPROVE_REFUND_FAILED,
};

export const { discretionaryRefunds: actions } = createActions({
  [DISPOSE]: null,
  [LOAD_REFUNDS]: (orderReference) => ({ orderReference }),
  [LOAD_REFUNDS_SUCCEEDED]: (refundables, refunds, refundReasons, pendingRefunds) => ({
    refundables,
    refunds,
    refundReasons,
    pendingRefunds,
  }),
  [LOAD_REFUNDS_FAILED]: (error) => ({ error }),
  [LOAD_EXISTING_REFUND]: (orderReference, returnUrl) => ({
    orderReference,
    returnUrl,
  }),
  [LOAD_EXISTING_REFUND_SUCCEEDED]: (refundables, quote, refundReasons) => ({
    refundables,
    quote,
    refundReasons,
  }),
  [LOAD_EXISTING_REFUND_FAILED]: (error) => ({ error }),
  [APPROVE_REFUND]: ({ approve, bookingId, customerId, orderReference }) => ({
    approve,
    bookingId,
    customerId,
    orderReference,
  }),
  [APPROVE_REFUND_SUCCESS]: ({ refundId, status, bookingId }) => ({ refundId, status, bookingId }),
  [APPROVE_REFUND_FAILED]: ({ bookingId, error, unprocessableReasons }) => ({
    bookingId,
    error,
    unprocessableReasons,
  }),
});
