import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { css } from 'aphrodite/no-important';
import { useIntl } from 'react-intl';

import TextInput from '@contactcentre-web/common/TextInput';
import {
  RevocationBatchResult,
  useCustomerRevocation,
} from '@contactcentre-web/hooks/api/useCustomerRevocation';
import Label from '@contactcentre-web/common/Label/Label';

import styles from './styles';
import RevocationNotification, {
  NotificationType,
  RevocationNotificationProps,
} from './components/RevocationNotification/RevocationNotification';
import ExportCustomersRevocationResultsButton from './components/ExportCustomersRevocationResultsButton/ExportCustomersRevocationResultsButton';
import RevocationResultsTable from './components/RevocationResultsTable/RevocationResultsTable';
import RevokeCustomersButton from './components/RevokeCustomersButton/RevokeCustomersButton';
import VerifyCustomersButton from './components/VerifyCustomersButton/VerifyCustomersButton';
import UploadCustomersCsvButton from './components/UploadCustomersCsvButton/UploadCustomersCsvButton';
import messages from './messages';
import {
  MAX_CUSTOMERID_COUNT,
  MIN_CUSTOMERID_COUNT,
  extractCustomerIdsFromText,
} from './utils/utils';
import { mapNotificationProps } from './components/RevocationNotification/utils';

const CustomerRevocation = () => {
  const defaultIncidentRef = 'INC-???';
  const incidentRefTextAreaPlaceholder = 'INC-XXX';
  const incidentRefMaxCharacters = 10;
  const emptyRevocationResults: RevocationBatchResult = {
    incidentReference: defaultIncidentRef,
    successCount: 0,
    failureCount: 0,
    results: [],
  };
  const { formatMessage } = useIntl();
  const textAreaPlaceholder = '12345678, 23456789, 34567890...';
  const customerIdsTextAreaRef = useRef<HTMLTextAreaElement>(null);
  const [incidentReportInput, setIncidentReportInput] = useState(defaultIncidentRef);
  const [canRevokeCustomers, setCanRevokeCustomers] = useState(false);
  const [isVerifiedInput, setIsVerifiedInput] = useState(false);
  const [isVerifyLoading, setIsVerifyLoading] = useState(false);
  const [customerIdsToRevoke, setCustomerIdsToRevoke] = useState<string[]>([]);
  const [revocationBatchResults, setRevocationBatchResults] =
    useState<RevocationBatchResult>(emptyRevocationResults);
  const hasResults = revocationBatchResults.results.length > 0;
  const [isInputEmpty, setIsInputEmpty] = useState(true);
  const isRevokeButtonDisabled = !(isVerifiedInput && canRevokeCustomers);
  const [notificationProps, setNotificationProps] = useState<RevocationNotificationProps>({
    messages: [messages.verificationInfoMessage],
    values: {
      min: MIN_CUSTOMERID_COUNT,
      max: MAX_CUSTOMERID_COUNT,
    },
    type: NotificationType.Info,
  });

  const handleIncidentReportInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const incidentReportInputElement = e.target;
    if (!incidentReportInputElement) return;

    setIncidentReportInput((prevValue) => {
      const newValue = incidentReportInputElement.value;
      return prevValue === newValue ? prevValue : newValue;
    });
  };

  const { mutate, isLoading: isRevokeLoading } = useCustomerRevocation({
    onSuccess: (response: RevocationBatchResult) => {
      setRevocationBatchResults(response);
      const textArea = customerIdsTextAreaRef.current;
      if (textArea) {
        textArea.value = '';
        const changeEvent = new Event('change', { bubbles: true });
        customerIdsTextAreaRef.current.dispatchEvent(changeEvent);
      }
      setNotificationProps({
        messages: [messages.revokeSuccessfulMessage],
        values: { count: response.results.length, successCount: response.successCount },
        type: response.failureCount === 0 ? NotificationType.Success : NotificationType.Warning,
      });
      setIsVerifiedInput(false);
    },
    onError: () => {
      setNotificationProps({
        messages: [messages.revokeFailedMessage],
        values: { statusCode: '401' },
        type: NotificationType.Error,
      });
    },
  });

  const handleFormSubmit = (evnt: FormEvent) => {
    evnt.preventDefault();
    setNotificationProps({
      messages: [messages.revokeInProgressMessage],
      values: {},
      type: NotificationType.Info,
    });
    mutate({
      incidentReference: incidentReportInput ?? defaultIncidentRef,
      customerIds: customerIdsToRevoke,
    });
  };

  const handleTextAreaChange = () => {
    setIsVerifiedInput(false);
    setIsInputEmpty(!customerIdsTextAreaRef.current?.value);
  };

  const handleCsvParseResults = (text: string) => {
    const hasCurrent = !!customerIdsTextAreaRef.current;
    if (!hasCurrent) return;
    customerIdsTextAreaRef.current.value = text;
    const changeEvent = new Event('change', { bubbles: true });
    customerIdsTextAreaRef.current.dispatchEvent(changeEvent);
  };

  const handleVerifyButtonClick = () => {
    setRevocationBatchResults(emptyRevocationResults);
    setIsVerifyLoading(true);

    const hasCurrent = !!customerIdsTextAreaRef.current;
    if (!hasCurrent) return;
    const textArea = customerIdsTextAreaRef.current;
    const extractionResults = extractCustomerIdsFromText(textArea.value);
    const newNotificationProps = mapNotificationProps(extractionResults);

    if (extractionResults.isSuccessful) {
      setCanRevokeCustomers(true);
      setIsVerifiedInput(true);
      setCustomerIdsToRevoke(extractionResults.customerIds);
    } else {
      setCanRevokeCustomers(false);
    }

    setNotificationProps(newNotificationProps);
    setIsVerifyLoading(false);
  };

  useEffect(() => {
    const hasCurrent = !!customerIdsTextAreaRef.current;
    if (!hasCurrent) return;
    customerIdsTextAreaRef.current.value = customerIdsToRevoke.join('\n');
  }, [customerIdsToRevoke]);

  const isLoading = isVerifyLoading || isRevokeLoading;

  return (
    <>
      <form
        onSubmit={handleFormSubmit}
        className={css(styles.container)}
        data-testid="customerIdInputForm"
      >
        <div className={css(styles.formSection)}>
          <div>
            <Label
              htmlFor="incident-reference"
              label={formatMessage(messages.incidentReferenceLabel)}
            />
            <TextInput
              id="incident-reference"
              name="incident-reference"
              value={incidentReportInput}
              styleSheet={styles.incidentReferenceButton}
              onChange={handleIncidentReportInputChange}
              disabled={isLoading}
              maxLength={incidentRefMaxCharacters}
              placeholder={incidentRefTextAreaPlaceholder}
            />
          </div>
        </div>

        <div className={css(styles.formSection)}>
          <div className={css(styles.flexRowWithSpaceBetween)}>
            <Label htmlFor="customer-ids" label={formatMessage(messages.customerIdsLabel)} />
            <UploadCustomersCsvButton disabled={isLoading} onParseResults={handleCsvParseResults} />
          </div>
          <div className={css(styles.flexRowWithSpaceBetween)}>
            <TextInput
              inputRef={customerIdsTextAreaRef}
              onChange={handleTextAreaChange}
              type="multiline-text"
              id="customer-ids"
              name="customer-ids"
              testId="customerIdsTextArea"
              placeholder={textAreaPlaceholder}
              styleSheet={styles.textAreaStyles}
              disabled={isLoading}
            />
          </div>
        </div>

        <RevocationNotification {...notificationProps} />

        <div className={css(styles.formSection, styles.flexRowWithSpaceBetween)}>
          <VerifyCustomersButton
            loading={isVerifyLoading}
            disabled={isLoading || isInputEmpty}
            onVerify={handleVerifyButtonClick}
          />
          <RevokeCustomersButton loading={isLoading} disabled={isRevokeButtonDisabled} />
        </div>
      </form>
      {!isRevokeLoading && hasResults && (
        <div className={css(styles.container)}>
          <RevocationResultsTable items={revocationBatchResults.results} />
          <ExportCustomersRevocationResultsButton
            downloadFileName={incidentReportInput}
            items={revocationBatchResults?.results}
            disabled={isLoading}
          />
        </div>
      )}
    </>
  );
};

export default CustomerRevocation;
