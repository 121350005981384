import React from 'react';
import { css } from 'aphrodite/no-important';
import { StatusMessage } from '@trainline/depot-web';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import styles from './styles';

interface Props {
  message: MessageDescriptor;
}

const OrderHistoryErrorMessage = ({ message }: Props) => (
  <div className={css(styles.errorsMessage)}>
    <StatusMessage status="negative">
      <FormattedMessage {...message} />
    </StatusMessage>
  </div>
);

export default OrderHistoryErrorMessage;
