import { StyleSheet } from 'aphrodite/no-important';
import { palettes, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  icon: {
    fill: palettes.dp_brand_black,
    width: '40px',
    height: '40px',
    paddingTop: spacers.dp_s8,
  },
});
