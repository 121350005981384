import { StyleSheet } from 'aphrodite/no-important';
import { fonts, palettes, spacers, typography } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  container: {
    padding: `${spacers.dp_s32} ${spacers.dp_s24}`,
    display: 'grid',
    gridTemplateColumns: '4fr 1fr 1fr',
  },
  buttonsContainer: {
    gridColumn: 3,
    display: 'flex',
    flexDirection: 'column',
    gap: spacers.dp_s12,
  },
  reasonCode: {
    color: palettes.dp_app_info_dark,
    fontWeight: fonts.dp_font_weight_bold,
  },
  label: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    textTransform: 'uppercase',
    color: colors.gray.alpha30,
    fontWeight: fonts.dp_font_weight_regular,
  },
  button: {
    textTransform: 'capitalize',
  },
});
