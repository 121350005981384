import { RefundableTicketMapper, DiscretionaryRefundableTicket } from '../types';
import { isQuoteProcessable } from '../utils';

export function ticketRefundabilityMapper({
  quote,
  status,
  refundableId,
  selectability,
  reason,
  reasonDetail,
  fareType,
}: RefundableTicketMapper): DiscretionaryRefundableTicket {
  const refundable = (status || '').toLowerCase() === 'refundable';

  const quoteRefundable =
    quote && quote.refundables.find(({ id: quoteId }) => quoteId === refundableId);

  return {
    fareType,
    refundable,
    refundableId,
    refundableReasonCode: quoteRefundable?.reasonCodeId,
    notRefundableReason: refundable ? undefined : reason,
    notRefundableReasonDetail: refundable ? undefined : reasonDetail,
    isSelected: isQuoteProcessable(quote) && !!quoteRefundable,
    isEligible: !!selectability && selectability.selectedByDefault,
    isChangeable: isQuoteProcessable(quote) && !!selectability && selectability.selectable,
    refundableAmount: quoteRefundable?.refundableAmount,
    canOverrideVoidableAmounts: !!quote?.customisations?.canOverrideVoidableAmounts,
  };
}
