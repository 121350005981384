import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { IconInfo, Paragraph, StatusMessage } from '@trainline/depot-web';

import {
  Modal,
  ModalBody,
  ModalTitle,
  ModalHeader,
  ModalFooter,
} from '@contactcentre-web/common/Modal';
import Button from '@contactcentre-web/common/Button';

import messages from './messages';
import styles from './styles';

type Props = {
  setModalVisible: (visible: boolean) => void;
};

const EntanglementsModal = ({ setModalVisible }: Props) => (
  <Modal styleSheet={{ content: styles.modal }} onClose={() => setModalVisible(false)}>
    <ModalHeader>
      <ModalTitle>
        <FormattedMessage {...messages.entanglementsModalTitle} />
      </ModalTitle>
    </ModalHeader>
    <ModalBody>
      <div className={css(styles.modalSubTitle)}>
        <Paragraph typeStyle="large" fontWeight="bold" as="p" color="base">
          <FormattedMessage {...messages.entanglementsModalSubTitle} />
        </Paragraph>
      </div>
      <div className={css(styles.modalBody)}>
        <Paragraph typeStyle="body" fontWeight="medium" as="p" color="base">
          <FormattedMessage {...messages.entanglementsModalBody} />
        </Paragraph>
      </div>
      <StatusMessage status="info">
        <div className={css(styles.modalInfo)}>
          <IconInfo className={css(styles.modalInfoIcon)} />
          <div>
            <FormattedMessage
              {...messages.entanglementsModalInfo}
              values={{
                strong: (msg: string) => <strong>{msg}</strong>,
              }}
            />
          </div>
        </div>
      </StatusMessage>
    </ModalBody>
    <ModalFooter>
      <Button fullWidth variant="secondary" size="small" onClick={() => setModalVisible(false)}>
        <FormattedMessage {...messages.entanglementsModalButton} />
      </Button>
    </ModalFooter>
  </Modal>
);

export default EntanglementsModal;
