import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  title: {
    marginBottom: spacers.dp_s24,
  },
  button: {
    marginTop: spacers.dp_s32,
  },
});
