import PropTypes from 'prop-types';
import React from 'react';

import ReplacementBookingAlert from './ReplacementBookingAlert';

const ReplacementBookingsAlert = ({ customerId, products }) => (
  <div>
    {products.map(({ id, replacementOrderReference, replacementSubType }, idx) =>
      replacementOrderReference ? (
        <ReplacementBookingAlert
          key={id}
          replacementOrderReference={replacementOrderReference}
          replacementSubType={replacementSubType}
          customerId={customerId}
          bookingNumber={idx + 1}
        />
      ) : null
    )}
  </div>
);

ReplacementBookingsAlert.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerId: PropTypes.string.isRequired,
};

export default ReplacementBookingsAlert;
