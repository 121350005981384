import camelCase from './camelCase';

const reduceToObj = (array, objProp) =>
  array.reduce((acc, curr) => {
    const prop = camelCase(curr[objProp]);

    acc[prop] = curr;
    return acc;
  }, {});

export default reduceToObj;
