import React, { useRef, Dispatch, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { Heading, StatusMessage } from '@trainline/depot-web';

import Tooltip from '@contactcentre-web/common/Tooltip';
import * as userSelectors from '@contactcentre-web/authentication/redux/selectors';
import Loader from '@contactcentre-web/common/Loader';
import PageContainer from '@contactcentre-web/common/PageContainer';
import Button from '@contactcentre-web/common/Button';
import Link from '@contactcentre-web/common/Link';
import type Action from '@contactcentre-web/redux-common/types/Action';
import type State from '@contactcentre-web/redux-common/types/State';

import { actions, selectors } from '../../module';
import DisableUserContainer from '../DisableUser/DisableUser';

import messages from './messages';
import styles from './styles';

interface StateProps {
  isLoadUserPending: boolean;
  isDisableSuccess: boolean;
  isDisabled: boolean;
  canResetPassword: boolean;
  canResetPasswordsPermission: boolean;
  canChangeRoles: boolean;
  canChangeSite: boolean;
  canChangeEmail: boolean;
  isCurrentUser: boolean;
  canManageUsers: boolean;
  fullName: string;
  disablePasswordReset: boolean;
}

interface DispatchProps {
  loadUser: (username: string) => void;
  resetState: () => void;
}

type Props = StateProps & DispatchProps;

interface Params {
  username: string;
}

export const EditActions: FunctionComponent<Props> = ({
  loadUser,
  isLoadUserPending,
  isDisableSuccess,
  isDisabled,
  canResetPassword,
  canResetPasswordsPermission,
  canChangeRoles,
  canChangeSite,
  canChangeEmail,
  isCurrentUser,
  resetState,
  canManageUsers,
  fullName,
  disablePasswordReset,
}) => {
  const { username } = useParams<Params>();
  const { search } = useLocation();
  const loadUserRef = useRef(loadUser);
  const resetStateRef = useRef(resetState);
  const successfulAction = new URLSearchParams(search).get('successfulAction') || '';
  const resetPasswordButton = (
    <Button
      variant="primary"
      size="xlarge"
      fullWidth
      testId="reset-password-button"
      to={`/edit_user/${username}/reset_password`}
      styleSheet={styles.button}
      disabled={!canResetPassword || disablePasswordReset}
    >
      <FormattedMessage {...messages.resetPassword} />
    </Button>
  );

  React.useEffect(() => {
    loadUserRef.current(username);
  }, [loadUserRef, username]);

  React.useEffect(() => () => resetStateRef.current(), [resetStateRef]);

  if (isLoadUserPending) {
    return (
      <div className={css(styles.container)}>
        <Loader />
      </div>
    );
  }

  return (
    <PageContainer>
      <div className={css(styles.container)}>
        <div className={css(styles.innerContainer)}>
          <div className={css(styles.header)}>
            <Heading typeStyle="title1" as="h1" color="base">
              <FormattedMessage {...messages.editUser} values={{ username, fullName }} />
            </Heading>
          </div>
          {(['changeRole', 'changeSite', 'changeEmail'].includes(successfulAction) ||
            isDisableSuccess) && (
            <div className={css(styles.alert)} data-testid="update-successful">
              <StatusMessage status="positive">
                <FormattedMessage {...messages.successfullyUpdated} />
              </StatusMessage>
            </div>
          )}
          {isDisabled && (
            <div className={css(styles.alert)} data-testid="user-disabled-warning">
              <StatusMessage status="warning">
                <FormattedMessage {...messages.userAlreadyDisabled} />
              </StatusMessage>
            </div>
          )}
          {isCurrentUser && (
            <div className={css(styles.alert)} data-testid="current-user-warning">
              <StatusMessage status="warning">
                <FormattedMessage {...messages.currentUserAvailableActions} />
              </StatusMessage>
            </div>
          )}
          {canResetPasswordsPermission &&
            (disablePasswordReset ? (
              <Tooltip
                orientation="Below"
                align="Left"
                anchor={<div className={css(styles.innerContainer)}>{resetPasswordButton}</div>}
              >
                <FormattedMessage {...messages.passwordDisabledSSO} />
              </Tooltip>
            ) : (
              resetPasswordButton
            ))}

          {canManageUsers && (
            <>
              <Button
                variant="primary"
                size="xlarge"
                fullWidth
                testId="change-role-button"
                to={`/edit_user/${username}/change_role`}
                styleSheet={styles.button}
                disabled={!canChangeRoles}
              >
                <FormattedMessage {...messages.changeRole} />
              </Button>
              <Button
                variant="primary"
                size="xlarge"
                fullWidth
                testId="change-site-button"
                to={`/edit_user/${username}/change_site`}
                styleSheet={styles.button}
                disabled={!canChangeSite}
              >
                <FormattedMessage {...messages.changeSite} />
              </Button>
              <Button
                variant="primary"
                size="xlarge"
                fullWidth
                testId="change-email-button"
                to={`/edit_user/${username}/change_email`}
                styleSheet={styles.button}
                disabled={!canChangeEmail}
              >
                <FormattedMessage {...messages.changeEmail} />
              </Button>
              <DisableUserContainer />
            </>
          )}
        </div>
        <div className={css(styles.cancel)}>
          <Link linkType="internal" to="/edit_user">
            <FormattedMessage {...messages.cancel} />
          </Link>
        </div>
      </div>
    </PageContainer>
  );
};

const mapStateToProps = (state: State) => ({
  isUserLoaded: selectors.isUserLoaded(state),
  isLoadUserPending: selectors.isLoadUserPending(state),
  isDisabled: selectors.getIsDisabled(state),
  isDisableSuccess: selectors.isDisableSuccess(state),
  canResetPassword: selectors.canResetPassword(state),
  canResetPasswordsPermission: userSelectors.canResetPasswords(state),
  canChangeSite: selectors.canChangeSite(state),
  canChangeEmail: selectors.canChangeEmail(state),
  canChangeRoles: selectors.canChangeRoles(state),
  isCurrentUser: selectors.isCurrentUser(state),
  canManageUsers: userSelectors.canManageUsers(state),
  fullName: selectors.getFullName(state),
  disablePasswordReset: selectors.disablePasswordReset(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  loadUser: (username: string) => dispatch(actions.loadUserAttempt(username)),
  resetState: () => dispatch(actions.resetState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditActions);
