import { defineMessages } from 'react-intl';

export default defineMessages({
  localAreaValidity: {
    id: 'RefundableUnit.localAreaValidity',
    defaultMessage: 'Product Name',
  },
  originDestination: {
    id: 'RefundableUnit.originDestination',
    defaultMessage: 'Origin-Destination',
  },
  refundTicket: {
    id: 'RefundableUnit.refundTicket',
    defaultMessage: 'Ticket type',
  },
  passengers: {
    id: 'RefundableUnit.passengers',
    defaultMessage: 'Passenger(s)',
  },
  price: {
    id: 'RefundableUnit.price',
    defaultMessage: 'Product Price',
  },
  RefundableAmount: {
    id: 'RefundableUnit.RefundableAmount',
    defaultMessage: 'Refundable Amount',
  },
  NA: {
    id: 'RefundableUnit.NA',
    defaultMessage: 'N/A',
  },
  railcards: {
    id: 'RefundableUnit.railcards',
    defaultMessage: 'Railcards',
  },
  notApplicable: {
    id: 'RefundableUnit.notApplicable',
    defaultMessage: 'Not applicable',
  },
  refundable: {
    id: 'RefundableUnit.refundable',
    defaultMessage: 'Refundable',
  },
  refundStatus: {
    id: 'RefundableUnit.refundStatus',
    defaultMessage: 'Refund status',
  },
  refund: {
    id: 'RefundableUnit.refund',
    defaultMessage: 'Refund?',
  },
  validation_refundablesRequired: {
    id: 'RefundableUnit.validation_refundablesRequired',
    defaultMessage: 'You must select at least one passenger / fare to refund',
  },
  coachSeat: {
    id: 'RefundableUnit.coachSeat',
    defaultMessage: 'Coach/Seat',
  },
  ticketStatus: {
    id: 'RefundableUnit.ticketStatus',
    defaultMessage: 'Ticket Status',
  },
  ticketId: {
    id: 'RefundableUnit.ticketId',
    defaultMessage: 'Ticket ID: {ticketId}',
  },
  tooltipTitle: {
    id: 'RefundableUnit.tooltipTitle',
    defaultMessage: 'Valid refund combinations',
  },
  tooltipDisableSelection: {
    id: 'RefundableUnit.tooltipDisableSelection',
    defaultMessage: 'Please select the ticket first to access the refund combinations.',
  },
  tooltipNonRefundableSelection: {
    id: 'RefundableUnit.tooltipNonRefundableSelection',
    defaultMessage:
      'The refund combinations are not available if a refund request has been sent, or the ticket has already been refunded.',
  },

  passengeradult: {
    id: 'RefundableUnit.passengeradult',
    defaultMessage: '{passengerCount, plural, one {# Adult} other {# Adults}}',
  },

  passengerchild: {
    id: 'RefundableUnit.passengerchild',
    defaultMessage: '{passengerCount, plural, one {# Child} other {# Children}}',
  },
});
