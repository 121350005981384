import { StyleSheet } from 'aphrodite/no-important';
import { fonts, typography, spacers } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  table: {
    width: '100%',
  },
  padHorizontal: {
    padding: `0 ${spacers.dp_s16}`,
  },
  padLeft: {
    paddingLeft: spacers.dp_s16,
  },
  breakdownTypeCell: {
    fontSize: typography.dp_micro_font_size,
    textAlign: 'left',
    width: '180px',
    paddingLeft: spacers.dp_s16,
  },
  itemCellHeading: {
    fontWeight: fonts.dp_font_weight_semibold,
    color: colors.gray.alpha34,
  },
  itemCell: {
    textAlign: 'left',
    width: '180px',
  },
  priceFeeCell: {
    fontSize: typography.dp_small_font_size_m,
    letterSpacing: typography.dp_small_letter_spacing,
    lineHeight: typography.dp_small_line_height,
    paddingRight: spacers.dp_s48,
    textAlign: 'right',
    width: '100px',
  },
  pricePaidCell: {
    fontSize: typography.dp_small_font_size_m,
    letterSpacing: typography.dp_small_letter_spacing,
    lineHeight: typography.dp_small_line_height,
    padding: `0 0 ${spacers.dp_s8}`,
    textAlign: 'right',
    width: '120px',
    paddingRight: spacers.dp_s48,
  },
  breakdownPriceCell: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
  },
});
