import React from 'react';

import CreateNewAgent from '@contactcentre-web/create-new-agent/CreateNewAgent';
import PageContainer from '@contactcentre-web/common/PageContainer';

import styles from './styles';

const CreateNewAgentPage = () => (
  <PageContainer styleSheet={styles.container}>
    <CreateNewAgent />
  </PageContainer>
);

export default CreateNewAgentPage;
