import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';

import { getLocaleInfo } from '@contactcentre-web/localisation/localisation';
import { getLocale } from '@contactcentre-web/authentication/redux/profile/selectors';
import { DEFAULT_LOCALE_CODE, LOCALE_CODES } from '@contactcentre-web/localisation/locales';

const formats = {
  number: {
    withDecimalPlaces: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
};

export const IntlProviderWrapper: FunctionComponent = ({ children }) => {
  const locale = useSelector(getLocale);
  const { locale: mappedLocale, messages } = getLocaleInfo(locale);

  // dynamically import locale data to reduce bundle size
  loadMomentLocale(locale);

  return (
    <IntlProvider locale={mappedLocale} messages={messages} formats={formats}>
      {children}
    </IntlProvider>
  );
};

export default IntlProviderWrapper;

export async function loadMomentLocale(locale: LOCALE_CODES) {
  if (locale === DEFAULT_LOCALE_CODE) {
    return null;
  }

  try {
    const [language] = locale.split('-');
    return await import(`moment/locale/${language.toLowerCase()}`).then((module) => module);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Locale ${locale} not supported or not found. Loading default locale instead`);
  }
}
