import { createSelector } from 'reselect';

import type State from '@contactcentre-web/redux-common/types/State';

import { STATUS_SUBMITTING, STATUS_SUCCESS, STATUS_ERROR } from './module';

const localState = (state: State) => state.selectManagedGroup;
const isSubmitting = createSelector(localState, (state) => state.status === STATUS_SUBMITTING);
const hasSubmitSucceeded = createSelector(localState, (state) => state.status === STATUS_SUCCESS);
const hasSubmitFailed = createSelector(localState, (state) => state.status === STATUS_ERROR);

export default {
  isSubmitting,
  hasSubmitSucceeded,
  hasSubmitFailed,
};
