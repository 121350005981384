// Both PACKAGE_VERSION and DEVELOPMENT constants are set during build time
// For reference, check webpack.prod.config.js and webpack.config.js files

declare const PACKAGE_VERSION: string;
declare const DEVELOPMENT: boolean;

export const packageVersion = PACKAGE_VERSION;
export const isDevelopment = DEVELOPMENT;

export const adsServiceUrl = (() =>
  DEVELOPMENT ? 'http://localhost:7493' : 'https://localhost:7493')();

export enum JourneyDirection {
  Outward = 'Outward',
  Inward = 'Inward',
}

export enum Vendors {
  ATOC = 'atoc',
}
