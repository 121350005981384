import { createActions } from 'redux-actions';

const PREFIX = 'REFUNDS_TERMS_AND_CONDITIONS';

const DISPOSE = `${PREFIX}/DISPOSE`;
const LOAD_REFUNDS = `${PREFIX}/LOAD_REFUNDS`;
const LOAD_REFUNDS_SUCCEEDED = `${PREFIX}/LOAD_REFUNDS_SUCCEEDED`;
const LOAD_REFUNDS_FAILED = `${PREFIX}/LOAD_REFUNDS_FAILED`;

const LOAD_REFUNDS_NONE = `${PREFIX}/LOAD_REFUNDS_NONE`;
const LOAD_REFUNDS_INPROGRESS = `${PREFIX}/LOAD_REFUNDS_IN_PROGRESS`;
const LOAD_REFUNDS_SUCCESS = `${PREFIX}/LOAD_REFUNDS_SUCCESS`;
const LOAD_REFUNDS_ERROR = `${PREFIX}/LOAD_REFUNDS_ERROR`;

const REQUEST_REFUND_FAILED = `${PREFIX}/REQUEST_REFUND_FAILED`;

const REQUEST_REFUND_NONE = `${PREFIX}/REQUEST_REFUND_NONE`;
const REQUEST_REFUND_INPROGRESS = `${PREFIX}/REQUEST_REFUND_INPROGRESS`;
const REQUEST_REFUND_SUCCESS = `${PREFIX}/REQUEST_REFUND_SUCCESS`;
const REQUEST_REFUND_ERROR = `${PREFIX}/REQUEST_REFUND_ERROR`;

const SEASON_ORDER_REFUND_RESET = `${PREFIX}/SEASON_ORDER_REFUND_RESET`;
const SEASON_ORDER_REFUND_ATTEMPT = `${PREFIX}/SEASON_ORDER_REFUND_ATTEMPT`;
const SEASON_ORDER_REFUND_SUCCESS = `${PREFIX}/SEASON_ORDER_REFUND_SUCCESS`;
const SEASON_ORDER_REFUND_FAIL = `${PREFIX}/SEASON_ORDER_REFUND_FAIL`;

export const events = {
  DISPOSE,
  LOAD_REFUNDS,
  LOAD_REFUNDS_SUCCEEDED,
  LOAD_REFUNDS_FAILED,
  REQUEST_REFUND_FAILED,
};

export const loadRefundsState = {
  LOAD_REFUNDS_NONE,
  LOAD_REFUNDS_INPROGRESS,
  LOAD_REFUNDS_SUCCESS,
  LOAD_REFUNDS_ERROR,
};

export const requestRefundState = {
  REQUEST_REFUND_NONE,
  REQUEST_REFUND_INPROGRESS,
  REQUEST_REFUND_SUCCESS,
  REQUEST_REFUND_ERROR,
};

export const seasonRefund = {
  SEASON_ORDER_REFUND_RESET,
  SEASON_ORDER_REFUND_ATTEMPT,
  SEASON_ORDER_REFUND_SUCCESS,
  SEASON_ORDER_REFUND_FAIL,
};

/* harcoded value: we may remove this one day... */
export const REFUND_REASONS = [
  {
    id: 'default',
    name: 'Admin Fee Applied',
    description: 'Admin Fee Applied',
  },
  {
    id: 'cancelled',
    name: 'Admin fee is waived because train(s) is cancelled',
    description: 'Admin fee is waived because train(s) is cancelled',
  },
  {
    id: 'travelCancellation',
    name: 'Refund fees are removed because train has been cancelled or removed from timetable',
    description:
      'Refund fees are removed because train has been cancelled or removed from timetable',
  },
];
/* End of harcoded value */

const { refundsTermsAndConditions: actions } = createActions({
  [DISPOSE]: null,
  [LOAD_REFUNDS]: (orderReference) => ({ orderReference }),
  [LOAD_REFUNDS_SUCCEEDED]: (refundables, refunds) => ({
    refundables,
    refunds,
  }),
  [LOAD_REFUNDS_FAILED]: (error) => ({ error }),
  [SEASON_ORDER_REFUND_RESET]: () => null,
  [SEASON_ORDER_REFUND_ATTEMPT]: () => null,
  [SEASON_ORDER_REFUND_SUCCESS]: () => null,
  [SEASON_ORDER_REFUND_FAIL]: () => null,
});

export default actions;
