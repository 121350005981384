import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { IconCheck, IconClose } from '@trainline/depot-web';

import messages from '../../messages';
import styles from '../../styles';
import { States, Data, RoleList } from '../../SetupManagedGroup';

interface Props {
  data?: Data;
  rolesList: RoleList;
}

export const rolesMessages = {
  admin: messages.adminOption,
  agent: messages.agentOption,
  firstUser: messages.firstUserOption,
  fraudTeam: messages.fraudTeamOption,
  frontOfficeTeam: messages.frontOfficeTeamOption,
  paymentTeam: messages.paymentTeamOption,
  readOnly: messages.readOnlyOption,
  refundTeam: messages.refundTeamOption,
  superAdmin: messages.superAdminOption,
  teamLead: messages.teamLeadOption,
  relationTeam: messages.relationTeamOption,
  customerServiceSupport: messages.customerServiceSupport,
  externalAgent: messages.externalAgent,
};

const RolesList = ({ data, rolesList }: Props) => (
  <ul className={css(styles.list)}>
    {rolesList.items.map(({ roleName }) => {
      const findIndex = data
        ? data?.roles.findIndex((localRoles) => localRoles.roleName === roleName)
        : -1;

      const roleStatus =
        findIndex >= 0 && data?.roles[findIndex].state ? data?.roles[findIndex].state : 'default';

      return (
        <li key={roleName} className={css(styles[roleStatus])}>
          <span className={css(styles.listItem)}>
            {roleStatus === States.Failure ? <IconClose /> : <IconCheck />}

            {rolesMessages[roleName] ? (
              <FormattedMessage {...rolesMessages[roleName]}>
                {(text) => <p className={css(styles.listItemText)}>{text}</p>}
              </FormattedMessage>
            ) : (
              <p className={css(styles.listItemText)}>{roleName}</p>
            )}
          </span>
        </li>
      );
    })}
    <li className={css(styles[data && data.firstUser.state ? data?.firstUser.state : 'default'])}>
      <span className={css(styles.listItem)}>
        {data?.firstUser.state === States.Failure ? <IconClose /> : <IconCheck />}
        <FormattedMessage {...messages.firstUserOption}>
          {(text) => <p className={css(styles.listItemText)}>{text}</p>}
        </FormattedMessage>
      </span>
    </li>
  </ul>
);

export default RolesList;
