import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { StyleDeclarationValue } from 'aphrodite/no-important';

import Button from '@contactcentre-web/common/Button';
import { Modal, ModalHeader, ModalTitle } from '@contactcentre-web/common/Modal';

import DetaxeForm from './components/DetaxeForm';
import { actionBuilder } from './module';
import { getBookings } from './selectors';
import DetaxeBooking from './types/DetaxeBooking';
import messages from './messages';

interface Props {
  bookings: DetaxeBooking[];
  createRefundAttempt: (booking: string) => void;
  styleSheet?: StyleDeclarationValue;
}

interface StateProps {
  bookings: DetaxeBooking[];
}

interface DispatchProps {
  createRefundAttempt: (productId: string) => void;
}

export const Detaxe = ({ bookings, createRefundAttempt, styleSheet }: Props) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      {visible && (
        <Modal testId="detaxe-form-modal" onClose={() => setVisible(false)}>
          <ModalHeader>
            <ModalTitle>
              <FormattedMessage {...messages.modalHeader} />
            </ModalTitle>
          </ModalHeader>
          <DetaxeForm bookings={bookings} onSubmitForm={createRefundAttempt} />
        </Modal>
      )}
      <Button variant="ghost" styleSheet={styleSheet} onClick={() => setVisible(true)}>
        <FormattedMessage {...messages.detaxeRefund} />
      </Button>
    </>
  );
};

const mapStateToProps = (state: any): StateProps => ({
  bookings: getBookings(state),
});

const mapDispatchToProps = (dispatch: (action: any) => void): DispatchProps => ({
  createRefundAttempt: (productId: string): void =>
    dispatch(actionBuilder.createRefundAttempt(productId)),
});

const connected = connect(mapStateToProps, mapDispatchToProps)(Detaxe);
export default connected;
