import { startSubmit, stopSubmit, reset } from 'redux-form';
import { takeLatest, put, call } from 'redux-saga/effects';
import { Action } from 'redux-actions';

import request from '@contactcentre-web/utils/request';

import {
  actions,
  PREFIX,
  EDIT_CUSTOMER_PROFILE_REQUESTED,
  LOAD_CUSTOMER_LANGUAGES_REQUESTED,
  Language,
} from './module';

export const FORM_ID = 'EditCustomerProfileForm';

export type EditCustomerProfilePayload = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  preferredCustomerLanguage: string;
  customerId: string;
};

export const editCustomerProfileRequest = (payload: EditCustomerProfilePayload) => {
  const { customerId } = payload;
  return request(`/api/customers/${customerId}`, { method: 'PATCH', body: payload });
};

type LanguagesResponse = {
  items: Array<Language>;
  totalCount: number;
};

export function* loadCustomerLanguagesSaga() {
  try {
    const languages: LanguagesResponse = yield call(request, '/api/languages');
    yield put(actions.loadCustomerLanguagesSucceeded(languages));
  } catch (error: any) {
    const errorCode = error.errors?.response?.errors[0]?.code
      ? error.errors.response.errors[0].code
      : 'generic';
    yield put(actions.loadCustomerLanguagesFailed(errorCode));
  }
}

export function* editCustomerProfileSaga({
  payload: { values },
}: Action<{ values: EditCustomerProfilePayload }>) {
  try {
    yield put(startSubmit(FORM_ID));
    yield call(editCustomerProfileRequest, values);
    yield put(reset(FORM_ID));
    yield put(actions.editCustomerProfileSucceeded(values.customerId));
    yield put(stopSubmit(FORM_ID));
  } catch (error: any) {
    const errorCode = error.errors?.response?.errors[0]?.code
      ? error.errors.response.errors[0].code
      : 'generic';
    yield put(stopSubmit(FORM_ID));
    yield put(actions.editCustomerProfileFailed(errorCode));
  }
}

export default function* saga() {
  yield takeLatest(`${PREFIX}/${LOAD_CUSTOMER_LANGUAGES_REQUESTED}`, loadCustomerLanguagesSaga);
  yield takeLatest(`${PREFIX}/${EDIT_CUSTOMER_PROFILE_REQUESTED}`, editCustomerProfileSaga);
}
