import { defineMessages } from 'react-intl';

export default defineMessages({
  createdAt: {
    id: 'RefundCard.createdAt',
    defaultMessage: '{day} at {time}',
  },
  bookingRefunded: {
    id: 'RefundCard.bookingRefunded',
    defaultMessage: 'Booking refunded',
  },
  samedayvoid: {
    id: 'RefundCard.samedayvoid',
    defaultMessage: 'SDV Refund requested',
  },
  termsandconditions: {
    id: 'RefundCard.termsandconditions',
    defaultMessage: 'T&C Refund requested',
  },
  discretionary: {
    id: 'RefundCard.discretionary',
    defaultMessage: 'Discretionary refund approved',
  },
  changeofjourney: {
    id: 'RefundCard.changeofjourney',
    defaultMessage: 'CoJ Refund requested',
  },
  refundBody: {
    id: 'RefundCard.refundBody',
    defaultMessage:
      '{requestorType, select, Customer {Refund requested by customer} other {Booking refunded}}',
  },
  booking: {
    id: 'RefundCard.booking',
    defaultMessage: 'booking',
  },
  agent: {
    id: 'RefundCard.agent',
    defaultMessage: 'agent',
  },
  requested: {
    id: 'RefundCard.requested',
    defaultMessage: 'requested',
  },
  adminFee: {
    id: 'RefundCard.adminFee',
    defaultMessage: 'admin fee',
  },
  BookingFee: {
    id: 'RefundCard.BookingFee',
    defaultMessage: 'Booking Fee',
  },
  DeliveryFee: {
    id: 'RefundCard.DeliveryFee',
    defaultMessage: 'Delivery Fee',
  },
  PaymentFee: {
    id: 'RefundCard.PaymentFee',
    defaultMessage: 'Payment Fee',
  },
  promoDiscount: {
    id: 'RefundCard.promoDiscount',
    defaultMessage: 'Promo/voucher discount',
  },
  refunded: {
    id: 'RefundCard.refunded',
    defaultMessage: 'refunded',
  },
  reasonCode: {
    id: 'RefundCard.reasonCode',
    defaultMessage: 'reason code',
  },
  ticketType: {
    id: 'RefundCard.ticketType',
    defaultMessage: 'ticket type',
  },
  refundStatus: {
    id: 'RefundCard.refundStatus',
    defaultMessage: 'refund status',
  },
  ticketByType: {
    id: 'RefundCard.ticketByType',
    defaultMessage: '{count, plural, one {# x {type}} other {# x {type}s}}',
  },
  paymentDetails: {
    id: 'RefundCard.paymentDetails',
    defaultMessage: '{type}: {details}',
  },
  requestedBy: {
    id: 'RefundCard.requestedBy',
    defaultMessage:
      '{requestorType, select, Automatic {N/A} Customer {Customer via self serve} Agent {{agent}} System {system}}',
  },
  approvedBy: {
    id: 'RefundCard.approvedBy',
    defaultMessage: 'Approved by',
  },
  refundableCount: {
    id: 'RefundCard.refundableCount',
    defaultMessage: `{status} ({itemsCount, select, 0 {} other {{itemsCount, plural, one {# item} other {# items}}}}{hasBothTypes, select, true { and } other {}}{feesCount, select, 0 {} other {{feesCount, plural, one {# fee} other {# fees}}}})`,
  },
});
