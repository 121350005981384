import { borderRadius, palettes, spacers } from '@trainline/depot-web/css/themes/trainline';
import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({
  container: {
    border: `solid 1px ${palettes.dp_app_info_lighter}`,
    borderRadius: borderRadius.dp_r4,
    background: palettes.dp_app_backdrop_dark,
    padding: spacers.dp_s32,
    marginBottom: spacers.dp_s32,
  },
  body: {
    display: 'flex',
  },
  selectContainer: {
    width: '440px',
    marginRight: spacers.dp_s48,
  },
  info: {
    border: `solid 1px ${palettes.dp_app_info_lighter}`,
    marginTop: spacers.dp_s24,
  },
  button: {
    marginTop: spacers.dp_s8,
  },
  alreadyRefreshedBox: {
    border: `solid 1px ${palettes.dp_app_info_lighter}`,
    marginTop: spacers.dp_s24,
  },
});
