import { put, call, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux-actions';

import request from '@contactcentre-web/utils/request';
import { setManagedGroup } from '@contactcentre-web/utils/tracker';

import { getUserDetails, UserDetails, getBusinessSettings, BusinessSettings } from '../service';
import { actions as userActions } from '../module';

import { actions, PREFIX, SUBMIT_ATTEMPT } from './module';

export function* selectManagedGroupSaga({
  payload: { managedGroupValue },
}: Action<{ managedGroupValue: string }>) {
  try {
    yield call(request, '/connect/switch-managed-group', {
      method: 'POST',
      body: { managedGroup: managedGroupValue },
    });

    const { permissions, managedGroup, managedGroups, roleName, features }: UserDetails =
      yield call(getUserDetails);

    yield put(
      userActions.loadUserInfoSuccess({
        permissions,
        managedGroup,
        managedGroups,
        roleName,
        features,
      })
    );

    yield call(setManagedGroup, managedGroup.number);
    yield put(actions.submitSuccess());
  } catch (error) {
    yield put(actions.submitError());
    yield put(userActions.logout());
  }

  try {
    const businessSettings: BusinessSettings = yield call(getBusinessSettings);
    yield put(userActions.businessSettingsRequestSuccess(businessSettings));
  } catch (error) {
    yield put(userActions.businessSettingsRequestFail());
  }
}

export default function* managedGroupsaga() {
  yield takeLatest(`${PREFIX}/${SUBMIT_ATTEMPT}`, selectManagedGroupSaga);
}
