import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import PassengerType from '@contactcentre-web/redux-common/types/PassengerType';

import LabelWithValue from '../LabelWithValue/LabelWithValue';

import messages from './messages';
import styles from './styles';

export interface Props {
  passengersByType: {
    type: PassengerType;
    passengers: number;
  }[];
}

const TicketsSection = ({ passengersByType }: Props) =>
  passengersByType.length > 0 ? (
    <dl>
      <dt className={css(styles.label)}>
        <FormattedMessage
          {...messages.ticketsWithCount}
          values={{
            count: passengersByType.reduce((current, next) => current + next.passengers, 0),
          }}
        />
      </dt>
      {passengersByType.map(({ type, passengers }) => (
        <dd key={type}>
          <FormattedMessage {...messages[type]} values={{ count: passengers }} />
        </dd>
      ))}
    </dl>
  ) : (
    <div data-test="tickets-not-available">
      <LabelWithValue
        label={<FormattedMessage {...messages.tickets} />}
        value={<FormattedMessage {...messages.notAvailable} />}
      />
    </div>
  );

export default TicketsSection;
