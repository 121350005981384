import { Heading } from '@trainline/depot-web';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import Loader from '@contactcentre-web/common/Loader';
import NoResultsContainer from '@contactcentre-web/common/NoResultsContainer/NoResultsContainer';
import { useVirtualTerminalApprovalQueue } from '@contactcentre-web/hooks/api/useVirtualTerminal';

import ApprovalQueueItem from '../ApprovalQueueItem/ApprovalQueueItem';

import messages from './messages';
import styles from './styles';

const ApprovalQueue = () => {
  const { data, isLoading } = useVirtualTerminalApprovalQueue();

  return (
    <>
      <Heading typeStyle="title3" as="h3" color="base">
        <FormattedMessage {...messages.approvalQueue} />
      </Heading>
      {isLoading && (
        <div className={css(styles.loaderContainer)}>
          <Loader />
        </div>
      )}
      {data?.length === 0 && !isLoading && <NoResultsContainer />}
      {data
        ?.sort((a, b) => new Date(a.requestedDate).getTime() - new Date(b.requestedDate).getTime())
        .map((compensation) => (
          <ApprovalQueueItem key={compensation.id} compensation={compensation} />
        ))}
    </>
  );
};

export default ApprovalQueue;
