import { defineMessages } from 'react-intl';

export default defineMessages({
  orderHistory: {
    id: 'OrderHistory.orderHistory',
    defaultMessage: 'Order History',
  },
  compensationError: {
    id: 'OrderHistory.compensationError',
    defaultMessage:
      'The Compensation details cannot be retrieved, please raise a ticket with the Payment Support team if needed.',
  },
  refundError: {
    id: 'OrderHistory.refundError',
    defaultMessage:
      'Refund details cannot be retrieved, for UK please raise a ticket with the Refunds Team and for EU orders with Backoffice Team if needed.',
  },
  refundRequestCreatedError: {
    id: 'OrderHistory.refundRequestCreatedError',
    defaultMessage:
      "Pending Discretionary Refund details cannot be retrieved, check if there's a refund and if needed please raise a ticket with the Refunds Team.",
  },
  refundRequestRejectedError: {
    id: 'OrderHistory.refundRequestRejectedError',
    defaultMessage:
      'Pending Discretionary Refund was rejected and we cannot retrieve the details, check with your manager and raise a new request if needed.',
  },
  defaultError: {
    id: 'OrderHistory.defaultError',
    defaultMessage: 'Sorry, something went wrong: Please try again later.',
  },
  emptyOrderHistoryQueue: {
    id: 'OrderHistory.emptyQueue',
    defaultMessage: 'No post-sales actions to report here yet',
  },
});
