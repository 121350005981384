import React, { Dispatch, FunctionComponent, useState } from 'react';
import { css } from 'aphrodite/no-important';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { StatusMessage } from '@trainline/depot-web';
import { StyleDeclarationValue } from 'aphrodite/no-important';

import { Modal, ModalHeader, ModalTitle } from '@contactcentre-web/common/Modal';
import Button from '@contactcentre-web/common/Button';
import Loader from '@contactcentre-web/common/Loader';
import type State from '@contactcentre-web/redux-common/types/State';
import type Action from '@contactcentre-web/redux-common/types/Action';

import EditCustomerProfileForm from './components/EditCustomerProfileForm/EditCustomerProfileForm';
import { isLoading, isEditting, editError, editSucceeded, getLanguages } from './selectors';
import { actions, EditCustomerProfileError, Language } from './module';
import { EditCustomerProfilePayload } from './sagas';
import messages from './messages';
import styles from './styles';

const errorMessageMapper = {
  SSOV0006: messages.SSOV0006ErrorMessage,
  generic: messages.genericErrorMessage,
};

interface StateProps {
  isLoadingData: boolean;
  isSubmitting: boolean;
  error?: EditCustomerProfileError | null;
  succeeded: boolean;
  languages: Array<Language> | null;
}

interface DispatchProps {
  onSubmitForm: (values: EditCustomerProfilePayload) => void;
  resetForm: () => void;
  loadCustomerLanguages: () => void;
}

interface Props extends StateProps, DispatchProps {
  forename: string;
  surname: string;
  email: string;
  preferredLanguage: string;
  customerId: string;
  editSucceededHandler: () => void;
  buttonStyleSheet: {
    button: StyleDeclarationValue;
    active: StyleDeclarationValue;
  };
}

export const EditCustomerProfile: FunctionComponent<Props> = ({
  languages,
  forename,
  surname,
  email,
  preferredLanguage,
  customerId,
  editSucceededHandler,
  onSubmitForm,
  resetForm,
  error,
  succeeded,
  isLoadingData,
  isSubmitting,
  loadCustomerLanguages,
  buttonStyleSheet,
}) => {
  const [visible, setVisible] = useState(false);

  React.useEffect(() => {
    if (visible) {
      loadCustomerLanguages();
    }
  }, [visible]);

  React.useEffect(() => {
    if (succeeded) {
      setVisible(false);
      editSucceededHandler();
      resetForm();
    }
  }, [succeeded]);

  const closeAndReset = () => {
    resetForm();
    setVisible(false);
  };

  return (
    <>
      <Button
        variant="tertiary"
        size="small"
        onClick={() => setVisible(true)}
        testId="open-modal"
        styleSheet={[buttonStyleSheet.button, visible && buttonStyleSheet.active]}
      >
        <FormattedMessage {...messages.editProfileButton} />
      </Button>
      {visible && (
        <Modal onClose={closeAndReset} styleSheet={{ content: styles.modalContent }}>
          <ModalHeader>
            <ModalTitle>
              <FormattedMessage {...messages.modalHeader} />
            </ModalTitle>
          </ModalHeader>
          {isLoadingData ? (
            <Loader />
          ) : (
            <>
              {error && errorMessageMapper[error] && (
                <div className={css(styles.errorMessage)} data-testid="editProfileError">
                  <StatusMessage status="negative">
                    <FormattedMessage {...errorMessageMapper[error]} />
                  </StatusMessage>
                </div>
              )}
              <EditCustomerProfileForm
                firstName={forename}
                lastName={surname}
                emailAddress={email}
                preferredCustomerLanguage={preferredLanguage}
                customerId={customerId}
                onEdit={onSubmitForm}
                isSubmitting={isSubmitting}
                languages={languages}
              />
            </>
          )}
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state: State) => ({
  isLoadingData: isLoading(state),
  isSubmitting: isEditting(state),
  error: editError(state),
  succeeded: editSucceeded(state),
  languages: getLanguages(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  onSubmitForm: (values: EditCustomerProfilePayload) =>
    dispatch(actions.editCustomerProfileRequested(values)),
  resetForm: () => dispatch(actions.editCustomerProfileReset()),
  loadCustomerLanguages: () => dispatch(actions.loadCustomerLanguagesRequested()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomerProfile);
