import { defineMessages } from 'react-intl';

export default defineMessages({
  amount: {
    id: 'CompensationForm.amount',
    defaultMessage: 'Amount (original transaction currency)',
  },
  invalidAmount: {
    id: 'CompensationForm.invalidAmount',
    defaultMessage: 'The compensation amount must be greater than 0.',
  },
  totalToRefund: {
    id: 'CompensationForm.totalToRefund',
    defaultMessage: 'Total to refund',
  },
  paymentDetails: {
    id: 'CompensationForm.paymentDetails',
    defaultMessage: 'Payment details',
  },
  processButton: {
    id: 'CompensationForm.processButton',
    defaultMessage: 'Process compensation',
  },
  submitRequestButton: {
    id: 'CompensationForm.submitRequestButton',
    defaultMessage: 'Submit Request',
  },
  noteLabel: {
    id: 'CompensationForm.noteLabel',
    defaultMessage: 'Additional comments',
  },
  noteLengthExceeded: {
    id: 'CompensationForm.noteLengthExceeded',
    defaultMessage: 'Comments cannot exceed 1000 characters',
  },
  productsLabel: {
    id: 'CompensationForm.productsLabel',
    defaultMessage: 'Select product to compensate:',
  },
  note_required: {
    id: 'CompensationForm.note_required',
    defaultMessage: 'You must provide a comment',
  },
  railcardLabel: {
    id: 'CompensationForm.railcardLabel',
    defaultMessage: '{name} – {railcardNumber}',
  },
  unknownLabel: {
    id: 'CompensationForm.unknownProductLabel',
    defaultMessage: '{productType} {id}',
  },
  products_required: {
    id: 'CompensationForm.products_required',
    defaultMessage: 'Please select one or more products this compensation is associated with',
  },
});
