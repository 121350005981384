import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { css } from 'aphrodite/no-important';

import Link from '@contactcentre-web/common/Link';
import Button from '@contactcentre-web/common/Button';
import { canCancelRailcard as canCancelRailcardSelector } from '@contactcentre-web/authentication/redux/selectors';
import {
  IssuedTo,
  Link as RailcardLink,
} from '@contactcentre-web/redux-common/types/RailcardProduct';
import ConditionsOfUse from '@contactcentre-web/redux-common/types/ConditionsOfUse';
import DocumentDownload from '@contactcentre-web/common/DocumentDownload';

import TermsAndConditionsModal from './TermsAndConditionsModal';
import CancelRailcardModal from './CancelRailcardModal';
import styles from './styles';
import messages from './messages';

interface Props {
  name: string;
  number: string;
  issuedTo: IssuedTo[];
  operator: string;
  conditionsOfUse: ConditionsOfUse;
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  isCancelling: boolean;
  cancelStatus: string;
  canBeCancelled?: boolean;
  canCancelRailcard?: boolean;
  links: RailcardLink[];
}

const RailcardDetails = ({
  name,
  number,
  issuedTo,
  operator,
  conditionsOfUse,
  onCancel,
  isCancelling,
  cancelStatus,
  canBeCancelled,
  links,
}: Props) => {
  const [showTermsAndConditionsModal, toggleTermsAndConditionsModal] = React.useState(false);
  const [showCancelRailcardModal, toggleCancelRailcardModal] = React.useState(false);
  const canCancelRailcard = useSelector(canCancelRailcardSelector);

  React.useEffect(() => {
    if (cancelStatus) {
      toggleCancelRailcardModal(false);
    }
  }, [cancelStatus]);

  const ticketAssetLink = links?.find(({ rel }) => rel === 'TicketAsset')?.href;

  return (
    <section className={css(styles.container)}>
      <div className={css(styles.col)}>
        <h4 className={css(styles.title)}>
          <FormattedMessage {...messages.product} />
        </h4>
        <div>
          {showTermsAndConditionsModal && (
            <TermsAndConditionsModal
              toggleModal={(): void => toggleTermsAndConditionsModal(!showTermsAndConditionsModal)}
              conditionsOfUse={conditionsOfUse}
            />
          )}
          <Link
            linkType="action"
            onClick={(): void => toggleTermsAndConditionsModal(!showTermsAndConditionsModal)}
            testId="railcard-name"
          >
            <strong>{name}</strong>
          </Link>
        </div>
      </div>
      <div className={css(styles.col)}>
        <h4 className={css(styles.title)}>
          <FormattedMessage {...messages.railcardNumber} />
        </h4>
        <strong>{number}</strong>
      </div>
      <div className={css(styles.col)}>
        <h4 className={css(styles.title)}>
          <FormattedMessage {...messages.issuedTo} />
        </h4>
        {issuedTo.map(({ firstName, lastName }, index) => (
          <div key={index}>
            <strong>{`${firstName} ${lastName}`}</strong>
          </div>
        ))}
      </div>
      <div className={css(styles.col)}>
        <h4 className={css(styles.title)}>
          <FormattedMessage {...messages.operator} />
        </h4>
        <strong className={css(styles.operator)}>{operator}</strong>
      </div>
      {ticketAssetLink && (
        <div className={css(styles.col)}>
          <h4 className={css(styles.title)}>
            <FormattedMessage {...messages.railcard} />
          </h4>
          <DocumentDownload link={ticketAssetLink} />
        </div>
      )}
      <div className={css(styles.col)}>
        <div className={css(styles.cancelButtonContainer)}>
          {showCancelRailcardModal && (
            <CancelRailcardModal
              toggleModal={(): void => toggleCancelRailcardModal(!showCancelRailcardModal)}
              onCancel={onCancel}
              name={name}
              isCancelling={isCancelling}
            />
          )}
          {canCancelRailcard && (
            <Button
              variant="destructive"
              size="small"
              onClick={(): void => toggleCancelRailcardModal(!showCancelRailcardModal)}
              disabled={!canBeCancelled}
              testId="cancel-railcard"
            >
              <FormattedMessage {...messages.cancel} />
            </Button>
          )}
        </div>
      </div>
    </section>
  );
};

export default RailcardDetails;
