import { StyleSheet } from 'aphrodite/no-important';
import { spacers, typography } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  table: {
    width: '100%',
  },
  padHorizontal: {
    padding: `0 ${spacers.dp_s16}`,
  },
  padLeft: {
    paddingLeft: spacers.dp_s16,
  },
  itemCell: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    textAlign: 'left',
    width: '180px',
  },
  priceFeeCell: {
    fontSize: typography.dp_small_font_size_m,
    letterSpacing: typography.dp_small_letter_spacing,
    lineHeight: typography.dp_small_line_height,
    paddingRight: spacers.dp_s32,
    textAlign: 'right',
    width: '100px',
  },
  pricePaidCell: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    padding: `0  ${spacers.dp_s48} ${spacers.dp_s8} 0`,
    textAlign: 'right',
    width: '100px',
  },
});
