import PropTypes from 'prop-types';
import React, { useEffect, Fragment } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { countBy, values } from 'ramda';

import TicketFromTo from '@contactcentre-web/common/TicketFromTo/TicketFromTo';
import { addPageAction } from '@contactcentre-web/utils/tracker';
import HorizontalRule from '@contactcentre-web/common/HorizontalRule';
import Loader from '@contactcentre-web/common/Loader';
import DocumentDownload from '@contactcentre-web/common/DocumentDownload';
import Link from '@contactcentre-web/common/Link';
import CopyToClipboard from '@contactcentre-web/common/CopyToClipboard';

import TicketTypeContainer from '../TicketType/TicketType';
import TicketStatusModal, { ticketUsageStatusTypes } from '../TicketStatusModal';

import messages from './messages';
import styles from './styles';

const PassengersDetails = ({ passengers }) => {
  if (passengers.length === 1) {
    return passengers[0].name || `1 ${passengers[0].type}`;
  }

  const passengersType = passengers.map(({ type }) => ({ type }));
  const passengersCount = countBy(values)(passengersType);

  return Object.keys(passengersCount)
    .map((p) => `${passengersCount[p]} ${p}`)
    .join(', ');
};

const Tickets = ({ orderReference, productId, ticketDetails, refreshOrder, fulfilmentStatus }) => {
  const fulfilmentPending = [null, 'Issuing', 'Locked'].includes(fulfilmentStatus);
  // Checking if eTickets are ready to stop refetching
  // This logic will improved with a future ticket, https://trainline.atlassian.net/browse/ATB2B-3296
  const documentsLoading = ticketDetails?.some(
    (ticket) =>
      ticket.documents.length === 0 && ticket.deliveryMethod.toLowerCase().includes('eticket')
  );

  const someTicketsRequireInformation = ticketDetails?.some(
    (ticket) =>
      ticket.deliverables &&
      ticket.deliverables.some((deliverable) => deliverable.needsData === true)
  );

  const ShouldDisplayPassengerDetailsRequiredMessage = (deliverables) =>
    !fulfilmentPending && deliverables?.some((deliverable) => deliverable.needsData === true);

  useEffect(() => {
    addPageAction('visit-ticket-tab');
  }, []);

  useEffect(() => {
    let timer;
    if (
      fulfilmentStatus === 'Issuing' ||
      fulfilmentStatus === 'Locked' ||
      (documentsLoading && !someTicketsRequireInformation)
    ) {
      timer = setTimeout(() => {
        refreshOrder();
      }, 5000);
    }

    return () => clearTimeout(timer);
  }, [fulfilmentStatus]);

  return (
    <section className={css(styles.section)}>
      {ticketDetails &&
        ticketDetails.map(
          (
            {
              origin,
              destination,
              vendor,
              subVendor,
              passengers,
              documents,
              ticketClass,
              fareConditions,
              type,
              farePassengerId,
              deliveryMethod,
              ticketNumber,
              checkInUrl,
              deliverables,
            },
            idx
          ) => (
            <Fragment key={idx}>
              <div className={css(styles.ticketDetails)}>
                <div className={css(styles.fromToRow)}>
                  <span className={css(styles.title)}>
                    <FormattedMessage {...messages.fromTo} />
                  </span>
                  <TicketFromTo origin={origin} destination={destination} />
                </div>
                <div>
                  <span className={css(styles.title)}>
                    <FormattedMessage {...messages.ticketType} />
                  </span>
                  <div className={css(styles.text)}>
                    <TicketTypeContainer
                      ticket={{
                        fareConditions,
                        type,
                      }}
                    />
                  </div>
                </div>
                <div>
                  <span className={css(styles.title)}>
                    <FormattedMessage {...messages.operator} />
                  </span>
                  <div className={css(styles.text)}>
                    {vendor}
                    {subVendor && <p>({subVendor})</p>}
                  </div>
                </div>
                <div>
                  <span className={css(styles.title)}>
                    <FormattedMessage {...messages.class} />
                  </span>
                  <p className={css(styles.text)}>{ticketClass}</p>
                </div>
                <div>
                  <span className={css(styles.title)}>
                    <FormattedMessage {...messages.passengers} />
                  </span>
                  <div className={css(styles.text)}>
                    <PassengersDetails passengers={passengers} />
                  </div>
                </div>
                <div>
                  <span className={css(styles.title)}>
                    <FormattedMessage {...messages.tcnNumber} />
                  </span>
                  <div className={css(styles.text)}>{ticketNumber || '-'}</div>
                </div>
                {checkInUrl && (
                  <div>
                    <span className={css(styles.title)}>
                      <FormattedMessage {...messages.checkInUrl} />
                    </span>
                    <div className={css(styles.checkInUrl)}>
                      <Link linkType="external" href={checkInUrl}>
                        <FormattedMessage {...messages.travellerCheckIn} />
                      </Link>
                      <CopyToClipboard value={checkInUrl} />
                    </div>
                  </div>
                )}
                <div>
                  <span className={css(styles.title)}>
                    <FormattedMessage {...messages.tickets} />
                  </span>
                  {checkInUrl && (
                    <div className={css(styles.text)}>
                      <FormattedMessage {...messages.pdfNotValid} />
                    </div>
                  )}
                  {!fulfilmentPending &&
                    deliverables?.every((deliverable) => deliverable.needsData === false) && (
                      <div>-</div>
                    )}
                  {ShouldDisplayPassengerDetailsRequiredMessage(deliverables) && (
                    <>
                      <div
                        data-testid="passenger-info-required-message"
                        className={css(styles.error)}
                      >
                        <FormattedMessage {...messages.advancePassengerInformationRequired} />
                      </div>
                    </>
                  )}
                  {fulfilmentPending && (
                    <Loader
                      size={32}
                      borderWidth={5}
                      borderColor="white"
                      styleSheet={{ container: styles.loaderContainer }}
                    />
                  )}
                  {documents &&
                    documents.length > 0 &&
                    !ShouldDisplayPassengerDetailsRequiredMessage(deliverables) &&
                    documents.map(({ link }, index) => (
                      <div key={index}>
                        <DocumentDownload
                          link={link}
                          onClick={() => addPageAction('click-download-pdf')}
                        />
                      </div>
                    ))}
                </div>
                <div>
                  <span className={css(styles.title)}>
                    <FormattedMessage {...messages.status} />
                  </span>
                  {farePassengerId && ticketUsageStatusTypes.includes(deliveryMethod) ? (
                    <TicketStatusModal
                      orderReference={orderReference}
                      productId={productId}
                      farePassengerId={farePassengerId}
                      deliveryMethod={deliveryMethod}
                      documents={documents}
                    />
                  ) : (
                    <div>-</div>
                  )}
                </div>
              </div>
              {ticketDetails.length !== idx + 1 && <HorizontalRule styleSheet={styles.line} />}
            </Fragment>
          )
        )}
    </section>
  );
};

Tickets.propTypes = {
  ticketDetails: PropTypes.array,
  orderReference: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
  fulfilmentStatus: PropTypes.string,
  refreshOrder: PropTypes.func,
};

export default Tickets;
