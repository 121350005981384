import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmationEmailSentSuccessfully: {
    id: 'ResendEmailSuccess.confirmationEmailSentSuccessfully',
    defaultMessage: 'Confirmation email sent successfully.',
  },
  fulfilmentEmailsSentSuccessfully: {
    id: 'ResendEmailSuccess.fulfilmentEmailsSentSuccessfully',
    defaultMessage: 'Fulfilment email(s) sent successfullfy.',
  },
  confirmationSent: {
    id: 'ResendEmailSuccess.confirmationSent',
    defaultMessage: 'Emails will be sent to {firstEmail}.',
  },
  additionally: {
    id: 'ResendEmailSuccess.additionally',
    defaultMessage: `Additionally, they will be sent to
      {emailsCount, plural,
        =2 {{0}}
        =3 {{0} and {1}}
        =4 {{0}, {1} and {2}}
        =5 {{0}, {1}, {2} and {3}}
        =6 {{0}, {1}, {2}, {3} and {4}}
        =7 {{0}, {1}, {2}, {3}, {4} and {5}}
        =8 {{0}, {1}, {2}, {3}, {4}, {5} and {6}}
        =9 {{0}, {1}, {2}, {3}, {4}, {5}, {6} and {7}}}`,
  },
});
