import { defineMessages } from 'react-intl';

export default defineMessages({
  completedDate: {
    id: 'OrderHistoryCard.createdAt',
    defaultMessage: '{day} at {time}',
  },
  title: {
    id: 'MoveOrder.title',
    defaultMessage: 'Order moved from another account',
  },
  agent: {
    id: 'MoveOrder.agent',
    defaultMessage: 'agent',
  },
  message: {
    id: 'MoveOrder.message',
    defaultMessage:
      'Order {orderReference} moved from account {fromCustomerEmail} to {toCustomerEmail}',
  },
});
