import React from 'react';
import { css } from 'aphrodite/no-important';
import { IconReturn, IconSingle } from '@trainline/depot-web';

import styles from './styles';

interface Props {
  origin?: string;
  destination?: string;
  fillColor?: string;
  isReturn?: boolean;
  shouldBreakArrow?: boolean;
  shouldVerticalAlignBottom?: boolean;
}

const OriginToDestination = ({
  origin,
  destination,
  fillColor,
  isReturn,
  shouldBreakArrow,
  shouldVerticalAlignBottom,
}: Props) => (
  <span>
    {origin}
    {shouldBreakArrow && <br data-testid="arrowLineBreak" />}
    {isReturn ? (
      <IconReturn
        className={css(
          !shouldBreakArrow && styles.spacing,
          shouldVerticalAlignBottom ? styles.verticalAlignBottom : styles.verticalAlignMiddle
        )}
        testId="iconReturn"
        fill={fillColor}
      />
    ) : (
      <IconSingle
        className={css(
          !shouldBreakArrow && styles.spacing,
          shouldVerticalAlignBottom ? styles.verticalAlignBottom : styles.verticalAlignMiddle
        )}
        testId="iconSingle"
        fill={fillColor}
      />
    )}
    {destination}
  </span>
);

export default OriginToDestination;
