import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import InfoBox from '@contactcentre-web/common/InfoBox';
import { Modal, ModalHeader, ModalBody } from '@contactcentre-web/common/Modal';
import Button from '@contactcentre-web/common/Button';

import messages from './messages';
import styles from './styles';

interface Props {
  reasonCode: string;
}

const BookingNotChangeableReason: FunctionComponent<Props> = ({ reasonCode }: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const messageKey = `notChangeableReason_${reasonCode.toLowerCase()}` as keyof typeof messages;

  return (
    <div>
      {modalVisible && (
        <Modal onClose={() => setModalVisible(false)}>
          <ModalHeader />
          <ModalBody>
            {messages[messageKey] ? (
              <FormattedMessage {...messages[messageKey]} />
            ) : (
              <FormattedMessage
                {...messages.notChangeableReason_notDocumented}
                values={{
                  reason: reasonCode,
                }}
              />
            )}
          </ModalBody>
        </Modal>
      )}
      <Button variant="ghost" onClick={() => setModalVisible(true)}>
        <InfoBox
          styleSheet={styles.notChangeableBox}
          message={<FormattedMessage {...messages.notChangeableReason} />}
        />
      </Button>
    </div>
  );
};

export default BookingNotChangeableReason;
