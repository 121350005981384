import { StyleSheet } from 'aphrodite/no-important';
import {
  palettes,
  spacers,
  borderRadius,
  fonts,
  typography,
} from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  ticket: {
    display: 'flex',
    boxShadow: `0px 0px ${spacers.dp_s4} ${palettes.dp_comp_box_shadow}, 0px 0px ${spacers.dp_s4} ${palettes.dp_comp_box_shadow}`,
    borderRadius: borderRadius.dp_r4,
    backgroundColor: palettes.dp_app_backdrop_base,
    marginBottom: spacers.dp_s24,
  },
  checkBox: {
    padding: `${spacers.dp_s24} ${spacers.dp_s12}`,
  },
  unit: {
    width: '100%',
    borderLeft: `1px solid ${palettes.dp_app_border_light}`,
    padding: spacers.dp_s16,
  },
  ticketDetails: {
    display: 'grid',
    gridTemplateColumns: '2fr repeat(3, 1fr)',
    columnGap: spacers.dp_s8,
    rowGap: spacers.dp_s16,
    marginBottom: spacers.dp_s16,
  },
  ticketActionsGrid: {
    display: 'grid',
    gridTemplateColumns:
      'minmax(0, 2fr) 25% 15% 19.5%' /* using percentages to align better ticketId with last column */,
    alignItems: 'flex-end',
    columnGap: spacers.dp_s8,
  },
  ticketActionsRow: {
    display: 'grid',
    gridTemplateColumns:
      '80.5% 19.5%' /* using percentages to align better ticketId - 19.5% - with last column */,
  },
  ticketStatus: {
    fontSize: typography.dp_small_font_size_m,
  },
  ticketStatusReason: {
    marginBottom: spacers.dp_s8,
    fontSize: typography.dp_small_font_size_m,
    paddingLeft: spacers.dp_s24,
  },
  ticketId: {
    fontSize: typography.dp_small_font_size_m,
  },

  label: {
    color: palettes.dp_app_text_subtle,
    fontWeight: fonts.dp_font_weight_regular,
  },
  labelText: {
    fontWeight: fonts.dp_font_weight_bold,
    fontSize: typography.dp_body_font_size,
  },
  roundIcon: {
    width: '24px',
    height: '24px',
    background: palettes.dp_app_positive_mid,
    display: 'inline-block',
    color: 'white',
    borderRadius: '50%',
    textAlign: 'center',
  },
  roundCross: {
    background: palettes.dp_app_negative_mid,
  },
  railcardLabel: {
    marginLeft: spacers.dp_s16,
  },
  railcardsList: {
    listStyleType: 'none',
  },
  railcardContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  railcardIcon: {
    marginTop: spacers.dp_s4,
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  ticketSelectionHelperWrapper: {
    display: 'flex',
  },
  ticketRefundableCheck: {
    flex: 1,
  },
  selectionHelperButton: {
    borderRadius: borderRadius.dp_r4,
    width: '40px',
    height: '40px',
    border: `1px solid ${palettes.dp_app_border_base}`,
    backgroundColor: palettes.dp_app_backdrop_base,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ':not(:disabled)': {
      color: palettes.dp_app_link_base,
      cursor: 'pointer',
    },
  },
});
