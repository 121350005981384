import React from 'react';

import { Data, RoleList, States } from '../../SetupManagedGroup';
import FirstUserInfo from '../FirstUserInfo';
import ManageGroupAlert from '../ManagedGroupAlert';
import RolesList from '../RolesList';

interface Props {
  data?: Data;
  managedGroupName: string;
  rolesList: RoleList;
}

const ConfirmationPage = ({ data, managedGroupName, rolesList }: Props) => (
  <>
    {data && <ManageGroupAlert data={data} managedGroupName={managedGroupName} />}
    <RolesList data={data} rolesList={rolesList} />
    {data?.firstUser.state === States.Success && (
      <FirstUserInfo
        username={data?.firstUser.username}
        password={data?.firstUser.password}
        managedGroupName={managedGroupName}
      />
    )}
  </>
);

export default ConfirmationPage;
