import { StyleSheet } from 'aphrodite/no-important';
import { palettes, spacers } from '@trainline/depot-web/css/themes/trainline';

export const leftContainerWidth = '70%';
export const rightContainerWidth = '30%';

export const selectStyle = {
  borderRadius: spacers.dp_s4,
  border: `1px solid ${palettes.dp_app_border_dark}`,
  borderWidth: '1px 1px 1px 0px',
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
};

export default StyleSheet.create({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: spacers.dp_s32,
    marginBottom: spacers.dp_s24,
    width: leftContainerWidth,
  },
  errorMessage: {
    marginBottom: spacers.dp_s24,
  },
});
