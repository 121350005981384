import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { StatusMessage } from '@trainline/depot-web';

import Tab from '@contactcentre-web/common/Tabs/Tab';
import Tabs from '@contactcentre-web/common/Tabs';
import RailcardProduct from '@contactcentre-web/redux-common/types/RailcardProduct';
import FulfilmentStatus from '@contactcentre-web/redux-common/types/FulfilmentStatus';

import RailcardHeader from '../RailcardHeader/RailcardHeader';
import RailcardDetails from '../RailcardDetails/RailcardDetails';

import styles from './styles';
import messages from './messages';

export interface Props extends RailcardProduct {
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  isCancelling: boolean;
  cancelStatus: string;
  canBeCancelled?: boolean;
  fulfilmentStatus: FulfilmentStatus;
}

const CustomerRailcard = ({
  name,
  validity,
  deliveryMethod,
  price,
  vendor,
  railcardNumber,
  issuedTo,
  conditionsOfUse,
  status,
  fulfilmentStatus,
  onCancel,
  isCancelling,
  cancelStatus,
  canBeCancelled,
  additionalValidityDates,
  links,
  isRenewal,
}: Props) => (
  <div className={css(styles.container)} data-testid="railcard-product">
    <RailcardHeader
      name={name}
      validity={validity}
      additionalValidityDates={additionalValidityDates}
      deliveryMethod={deliveryMethod}
      price={price}
      status={status}
      fulfilmentStatus={fulfilmentStatus}
    />
    {isRenewal && (
      <div className={css(styles.renewalAlertContainer)} data-testid="renewal-alert">
        <StatusMessage status="info">
          <FormattedMessage {...messages.renewalOrder} />
        </StatusMessage>
      </div>
    )}
    <Tabs>
      <Tab title={<FormattedMessage {...messages.bookingInfo} />} default>
        <RailcardDetails
          name={name}
          number={railcardNumber}
          issuedTo={issuedTo}
          operator={vendor}
          conditionsOfUse={conditionsOfUse}
          onCancel={onCancel}
          isCancelling={isCancelling}
          cancelStatus={cancelStatus}
          canBeCancelled={canBeCancelled}
          links={links}
        />
      </Tab>
    </Tabs>
  </div>
);

export default CustomerRailcard;
