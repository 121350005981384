import React, { FunctionComponent, useState } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { Heading, IconOpenReturn, IconSingle, Paragraph } from '@trainline/depot-web';

import FormattedDateInLondonTz from '@contactcentre-web/common/FormattedDateInLondonTz';
import FormattedTimeInLondonTz from '@contactcentre-web/common/FormattedTimeInLondonTz';
import Expandable from '@contactcentre-web/common/Expandable';
import { StatusDetails, StatusIndicatorColored } from '@contactcentre-web/common/StatusIndicator';
import type {
  CardPaymentDetails,
  OtherPaymentDetails,
  User,
} from '@contactcentre-web/hooks/api/useOrderHistory';

import { userDisplayName } from './helpers';
import messages from './messages';
import styles from './styles';

const Card: FunctionComponent = ({ children }) => (
  <div className={css(styles.card)}>{children}</div>
);

interface CreatedAtProps {
  date: Date;
}

const CreatedAt: FunctionComponent<CreatedAtProps> = ({ date }) => (
  <FormattedMessage
    {...messages.createdAt}
    values={{
      day: <FormattedDateInLondonTz value={date} year="numeric" month="short" day="numeric" />,
      time: <FormattedTimeInLondonTz value={date} addOffset />,
    }}
  />
);

interface HeaderProps {
  date?: Date;
  user: User;
}

const Header: FunctionComponent<HeaderProps> = ({ date, user, children }) => (
  <div className={css(styles.header)}>
    <Heading as="h3" typeStyle="title2" color="base">
      {children}
    </Heading>
    <div>
      {date && (
        <Paragraph as="p" typeStyle="body" color="base" fontWeight="regular" testId="date">
          <CreatedAt date={date} />
        </Paragraph>
      )}
      <Paragraph as="p" typeStyle="body" color="base" fontWeight="regular" testId="user">
        <FormattedMessage {...messages.requestedBy} values={{ user: userDisplayName(user) }} />
      </Paragraph>
    </div>
  </div>
);

interface ContentProps {
  withStatusIndicator?: boolean;
  isOpenByDefault?: boolean;
  color?: StatusDetails['color'];
  message: StatusDetails['message'];
}

const Content: FunctionComponent<ContentProps> = ({
  color,
  message,
  children,
  withStatusIndicator = true,
  isOpenByDefault = false,
}) => {
  const [isOpen, setIsOpen] = useState(isOpenByDefault);

  return (
    <div className={css(styles.content)}>
      <Expandable.Anchor onClick={() => setIsOpen((prev) => !prev)} isOpen={isOpen}>
        {withStatusIndicator ? (
          <StatusIndicatorColored color={color} fontWeight="bold" typeStyle="large">
            <FormattedMessage {...message} />
          </StatusIndicatorColored>
        ) : (
          <Paragraph as="span" color="base" fontWeight="bold" typeStyle="large">
            <FormattedMessage {...message} />
          </Paragraph>
        )}
      </Expandable.Anchor>
      <Expandable.Content isOpen={isOpen}>{children}</Expandable.Content>
    </div>
  );
};

const SimpleContent: FunctionComponent = ({ children }) => (
  <div className={css(styles.content)}>
    <Paragraph as="div" color="base" fontWeight="regular" typeStyle="body">
      {children}
    </Paragraph>
  </div>
);

const Items: FunctionComponent = ({ children }) => (
  <div className={css(styles.itemsGrid)}>{children}</div>
);

interface ItemProps {
  label: string;
  testId?: string;
}

const ItemTitle: FunctionComponent = ({ children }) => (
  <Paragraph as="p" typeStyle="body" fontWeight="bold" color="base">
    {children}
  </Paragraph>
);

const ItemContent: FunctionComponent = ({ children }) => (
  <Paragraph as="div" typeStyle="body" fontWeight="regular" color="base">
    {children}
  </Paragraph>
);

const Item: FunctionComponent<ItemProps> = ({ label, testId, children }) => (
  <div data-testid={testId}>
    <ItemTitle>{label}</ItemTitle>
    <ItemContent>{children}</ItemContent>
  </div>
);

export interface Row {
  cells: Array<string>;
}

export interface Table {
  headers: Array<string>;
  rows: Array<Row>;
}

interface ItemTableProps {
  label: string;
  testId?: string;
  table: Table;
}

const ItemTable: FunctionComponent<ItemTableProps> = ({ label, testId, table }) => {
  const { headers, rows } = table;

  return (
    <div data-testid={testId}>
      <Paragraph as="p" typeStyle="body" fontWeight="bold" color="base">
        {label}
      </Paragraph>
      <table className={css(styles.table)}>
        <thead>
          <tr className={css(styles.tableHead)}>
            {headers.map((title, index: number) => (
              <th key={index} className={css(styles.tableHeader)}>
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row: Row, index: number) => (
            <tr key={index} className={css(styles.tableBody)}>
              {row.cells.map((cell: string, indexCells: number) => (
                <td key={indexCells} className={css(styles.tableCell)}>
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

interface FareProps {
  origin: string;
  destination: string;
  isReturnFare?: boolean;
}

const Fare: FunctionComponent<FareProps> = ({ origin, destination, isReturnFare }) => (
  <div className={css(styles.fare)}>
    {origin}
    {isReturnFare ? (
      <IconOpenReturn className={css(styles.fareIcon)} />
    ) : (
      <IconSingle className={css(styles.fareIcon)} />
    )}
    {destination}
  </div>
);

interface PaymentProps {
  paymentDetails?: CardPaymentDetails | OtherPaymentDetails;
}

const PaymentType: FunctionComponent<PaymentProps> = ({ paymentDetails }) => {
  if (!paymentDetails) {
    return null;
  }

  if (paymentDetails.paymentMethod.toLowerCase() === 'unknown') {
    return <FormattedMessage {...messages.unknown} />;
  }

  const paymentValues =
    'tokenizedCardNumber' in paymentDetails
      ? {
          cardType: <span className={css(styles.paymentMethod)}>{paymentDetails.cardType}</span>,
          paymentMethod: paymentDetails.paymentMethod,
          cardNumber: paymentDetails.tokenizedCardNumber.substring(
            paymentDetails.tokenizedCardNumber.length - 4
          ),
        }
      : {
          paymentMethod: (
            <span className={css(styles.paymentMethod)}>{paymentDetails.paymentMethod}</span>
          ),
          email: paymentDetails.billingEmailAddress,
        };

  return <FormattedMessage {...messages.paymentDetails} values={paymentValues} />;
};

const PaymentHolderName: FunctionComponent<PaymentProps> = ({ paymentDetails }) => {
  if (!paymentDetails) {
    return <FormattedMessage {...messages.unknown} />;
  }

  return 'tokenizedCardNumber' in paymentDetails ? (
    <>{paymentDetails.holderName}</>
  ) : paymentDetails.paidBy ? (
    <>{paymentDetails.paidBy}</>
  ) : (
    <FormattedMessage {...messages.unknown} />
  );
};

export default {
  Card,
  CreatedAt,
  Header,
  Content,
  SimpleContent,
  Items,
  ItemTitle,
  ItemContent,
  Item,
  ItemTable,
  Fare,
  PaymentType,
  PaymentHolderName,
};
