import { defineMessages } from 'react-intl';

export default defineMessages({
  titleSuccess: {
    id: 'ExchangeFeedback.titleSuccess',
    defaultMessage: 'Change of Journey processed successfully',
  },
  titleFailed: {
    id: 'ExchangeFeedback.titleFailed',
    defaultMessage: 'Change of journey failed',
  },
});
