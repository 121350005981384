import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { Heading, IconClose, StatusMessage } from '@trainline/depot-web';

import PageContainer from '@contactcentre-web/common/PageContainer/PageContainer';
import Button from '@contactcentre-web/common/Button';
import EditCustomerProfile from '@contactcentre-web/edit-customer-profile/EditCustomerProfile';

import EuBookingsAlert from '../EuBookingsAlert';
import MigrationInfo from '../MigrationInfo';
import StatusInfo from '../StatusInfo';

import { CojSpecial } from './COJSpecial/CojSpecial';
import messages from './messages';
import styles from './styles';

const addressKeys = [
  'addressLine1',
  'addressLine2',
  'addressLine3',
  'addressLine4',
  'addressLine5',
  'postcode',
  'countryCode',
];

const formatAddress = (obj, keys) =>
  keys.reduce((prev, cur) => `${prev}${obj[cur] ? `${prev && ', '}${obj[cur]}` : ''}`, '');

const Header = ({
  title,
  forename,
  surname,
  address,
  email,
  customerPreferredLanguage,
  customerId,
  canNavigateToBookings,
  currentLocale,
  isCustomerPassengersPage,
  specialCojOrders,
  managedGroupNumber,
  canEditCustomerProfile,
  corporateReference,
  clientReference,
  corporateName,
}) => {
  const [editProfileSuccess, displayEditProfileSuccessNotification] = useState(false);

  const onEditSucceededHandler = () => {
    displayEditProfileSuccessNotification(true);
  };

  const removeNotification = () => {
    displayEditProfileSuccessNotification(false);
  };

  const shouldDisplayStatus = managedGroupNumber < 100;

  return (
    <>
      <div className={css(styles.container)}>
        <PageContainer styleSheet={styles.innerContainer}>
          <div>
            <div>
              {editProfileSuccess && (
                <StatusMessage status="positive">
                  <div className={css(styles.editProfileSuccess)}>
                    <FormattedMessage {...messages.customerProfileUpdated} />
                    <Button
                      variant="ghost"
                      onClick={removeNotification}
                      styleSheet={styles.closeButton}
                    >
                      <IconClose />
                    </Button>
                  </div>
                </StatusMessage>
              )}
            </div>
            <div className={css(styles.innerContainer)}>
              <div className={css(styles.profileContainer)}>
                <div className={css(styles.profileDetails)}>
                  <div className={css(styles.profile)}>
                    <Heading typeStyle="title2" as="h1" color="inverted">
                      {title} {forename} {surname}
                    </Heading>
                    {shouldDisplayStatus && <StatusInfo />}
                    <MigrationInfo
                      loadingStats
                      currentLocale={currentLocale}
                      preferredLanguage={customerPreferredLanguage}
                    />
                    {canEditCustomerProfile && (
                      <EditCustomerProfile
                        forename={forename}
                        surname={surname}
                        email={email}
                        preferredLanguage={customerPreferredLanguage}
                        customerId={customerId}
                        editSucceededHandler={onEditSucceededHandler}
                        buttonStyleSheet={{
                          button: styles.headerButton,
                          active: styles.headerButtonActive,
                        }}
                      />
                    )}
                    {shouldDisplayStatus && (
                      <Button
                        variant="tertiary"
                        size="small"
                        testId="SavedPassengerButton"
                        to={`/customers/${customerId}/passengers`}
                        styleSheet={[
                          styles.headerButton,
                          isCustomerPassengersPage && styles.headerButtonActive,
                        ]}
                      >
                        <span>
                          <FormattedMessage {...messages.savedPassengerButton} />
                        </span>
                      </Button>
                    )}
                  </div>
                  <div data-test="EmailAddressSearch">
                    <strong>
                      <FormattedMessage {...messages.email} />
                    </strong>{' '}
                    {email}
                  </div>
                  {address && (
                    <div>
                      <strong>
                        <FormattedMessage {...messages.address} />
                      </strong>{' '}
                      {formatAddress(address, addressKeys)}
                    </div>
                  )}
                  {corporateName && (
                    <div>
                      <strong>
                        <FormattedMessage {...messages.corporateName} />
                      </strong>{' '}
                      {corporateName}
                    </div>
                  )}
                  {clientReference && (
                    <div>
                      <strong>
                        <FormattedMessage {...messages.clientReference} />
                      </strong>{' '}
                      {clientReference}
                    </div>
                  )}
                  {corporateReference && (
                    <div>
                      <strong>
                        <FormattedMessage {...messages.corporateReference} />
                      </strong>{' '}
                      {corporateReference}
                    </div>
                  )}
                </div>
                {canNavigateToBookings && (
                  <div className={css(styles.viewBookings)}>
                    <Button variant="primary" size="large" to={`/customers/${customerId}/bookings`}>
                      <FormattedMessage {...messages.viewBookings} />
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </PageContainer>
        <EuBookingsAlert />
      </div>
      <CojSpecial specialCojOrders={specialCojOrders} customerId={customerId} />
    </>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  forename: PropTypes.string,
  surname: PropTypes.string,
  address: PropTypes.object,
  email: PropTypes.string,
  customerPreferredLanguage: PropTypes.string,
  customerId: PropTypes.string.isRequired,
  canNavigateToBookings: PropTypes.bool,
  currentLocale: PropTypes.string.isRequired,
  isCustomerPassengersPage: PropTypes.bool,
  specialCojOrders: PropTypes.arrayOf(String),
  managedGroupNumber: PropTypes.number.isRequired,
  canEditCustomerProfile: PropTypes.bool.isRequired,
  corporateReference: PropTypes.string,
  clientReference: PropTypes.string,
  corporateName: PropTypes.string,
};

export default Header;
