import { StyleSheet } from 'aphrodite/no-important';
import { typography, fonts } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  customerTitle: {
    fontFamily: typography.dp_hero_font_family,
    fontSize: typography.dp_hero_font_size,
    fontWeight: fonts.dp_font_weight_bold,
    lineHeight: typography.dp_hero_line_height,
    letterSpacing: '-0.2px', //TODO: replace with depot-web typography when updated in depot-web
    color: colors.gray.alpha80,
    height: '44px',
  },

  customerResult: {
    fontFamily: typography.dp_title1_font_family,
    fontWeight: fonts.dp_font_weight_regular,
  },
});
