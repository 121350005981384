import { defineMessages } from 'react-intl';

export default defineMessages({
  customerAdvisory: {
    id: 'SameDayVoid.customerAdvisory',
    defaultMessage:
      'Please advise the customer their ticket(s) will need posted back to Trainline Refunds, PO Box 23972, Edinburgh, EH3 5DA and from receiving the ticket(s) a refund will return back to the original payment method within 28 working days.',
  },
  deleteFromDevicePreProcess: {
    id: 'SameDayVoid.deleteFromDevicePreProcess',
    defaultMessage:
      'Inform customer to remove their ticket from their phone using the "Move tickets" option to receive their refund.',
  },
});
