import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  spinner: {
    flexShrink: 0,
    borderRadius: '50%',
    borderStyle: 'solid',
    animationName: {
      to: {
        transform: 'rotate(360deg)',
      },
    },
    animationDuration: '750ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
});
