import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import StatusIndicator from '@contactcentre-web/common/StatusIndicator';
import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency';
import { canRequestExternalRefund as canRequestExternalRefundSelector } from '@contactcentre-web/authentication/redux/selectors';

import BookingDetail from '../BookingDetail/BookingDetail';
import { OrderHistoryCard, OrderHistoryCardHeader } from '../OrderHistoryCard/OrderHistoryCard';
import type { ExternalRefundItem } from '../../module';

import messages from './messages';
import styles from './styles';

interface Props extends ExternalRefundItem {
  customerId: string;
  orderReference: string;
}

const ExternalRefund: FunctionComponent<Props> = ({
  createdAt,
  bookings,
  agent,
  status,
  vendor,
  customerId,
  orderReference,
  refundId,
  canUpdateStatus,
  claimAmount,
  contactReason,
}) => {
  const canRequestExternalRefund = useSelector(canRequestExternalRefundSelector);
  const shouldShowCardBody = !!contactReason;
  return (
    <OrderHistoryCard
      createdAt={createdAt}
      cardTitle={
        <FormattedMessage
          {...(vendor && messages[`${vendor}CardTitle`]
            ? messages[`${vendor}CardTitle`]
            : messages.defaultCardTitle)}
        />
      }
      headerItems={
        <>
          <OrderHistoryCardHeader
            label={<FormattedMessage {...messages.booking} />}
            value={<BookingDetail bookings={bookings} />}
          />
          {claimAmount ? (
            <OrderHistoryCardHeader
              label={<FormattedMessage {...messages.claimAmount} />}
              value={<FormattedCurrency {...claimAmount} />}
            />
          ) : null}
          <OrderHistoryCardHeader
            label={<FormattedMessage {...messages.requestedBy} />}
            value={agent}
          />
          <OrderHistoryCardHeader
            label={<FormattedMessage {...messages.status} />}
            value={
              <StatusIndicator
                status={status}
                customerId={customerId}
                refundId={refundId}
                orderReference={orderReference}
                canUpdateStatus={canUpdateStatus && canRequestExternalRefund}
                indicatorStyles={styles[`statusIndicator${status}`]}
              />
            }
          />
        </>
      }
      cardBody={
        shouldShowCardBody ? (
          <>
            {contactReason && (
              <OrderHistoryCardHeader
                label={<FormattedMessage {...messages.contactReason} />}
                value={<FormattedMessage {...messages[contactReason]} />}
              />
            )}
          </>
        ) : undefined
      }
    />
  );
};

export default ExternalRefund;
