import { StyleSheet } from 'aphrodite/no-important';
import {
  fonts,
  spacers,
  palettes,
  typography,
  borderRadius,
} from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  page: {
    height: '100%',
    background: `linear-gradient(${palettes.dp_brand_primary_core}, ${palettes.dp_brand_primary_core})`,
    backgroundSize: '100% 154px',
    backgroundRepeat: 'no-repeat',
    backgroundColor: palettes.dp_app_backdrop_base,
  },
  searchContainer: {
    maxWidth: '640px',
    backgroundColor: palettes.dp_app_backdrop_base,
    margin: `${spacers.dp_s80} auto`,
    padding: spacers.dp_s32,
    borderRadius: borderRadius.dp_r12,
  },
  title: {
    fontWeight: fonts.dp_font_weight_bold,
    fontSize: typography.dp_title3_font_size,
  },
  inputContainer: {
    marginTop: spacers.dp_s16,
  },
  paragraph: {
    fontWeight: 600,
    marginTop: '10px',
  },
  unorderedList: {
    listStylePosition: `inside`,
    padding: `15px`,
  },
  accordionContainer: {
    marginTop: '20px',
  },
});
