import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { StatusMessage } from '@trainline/depot-web';

import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from '@contactcentre-web/common/Modal';
import Button from '@contactcentre-web/common/Button';
import { actions as orderActions } from '@contactcentre-web/customer-order/module';

import selectors from '../../selectors';
import actions from '../../module';

import messages from './messages';

export const SeasonRefundModal = ({
  onConfirm,
  isRefunding,
  status,
  reloadOrder,
  onReset,
  onClose,
}) => {
  const history = useHistory();
  const { customerId, orderId: orderReference } = useParams();

  const goBack = () => history.push(`/customers/${customerId}/bookings/${orderReference}`);

  React.useEffect(() => {
    if (status === 'success') {
      goBack();
      reloadOrder(customerId, orderReference);
      onReset();
    }
  }, [status]);

  return (
    <Modal onClose={onClose || goBack}>
      <ModalHeader>
        <ModalTitle>
          <FormattedMessage {...messages.title} />
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <FormattedMessage {...messages.description} />
        {status === 'error' && (
          <StatusMessage status="negative">
            <FormattedMessage {...messages.refundError} />
          </StatusMessage>
        )}
      </ModalBody>
      <ModalFooter contentAlignment="spaceBetween">
        <Button variant="tertiary" size="large" onClick={onClose || goBack}>
          <FormattedMessage {...messages.cancelRefund} />
        </Button>
        <Button
          variant="primary"
          size="large"
          onClick={onConfirm}
          loading={isRefunding}
          testId="SeasonRefundButton"
        >
          <FormattedMessage {...messages.confirmRefund} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

SeasonRefundModal.propTypes = {
  onConfirm: PropTypes.func,
  isRefunding: PropTypes.bool,
  status: PropTypes.string,
  reloadOrder: PropTypes.func,
  onReset: PropTypes.func,
  onClose: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isRefunding: selectors.isRefundingSeasonOrder(state),
  status: selectors.refundSeasonOrderStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
  onConfirm: () => dispatch(actions.seasonOrderRefundAttempt()),
  onReset: () => dispatch(actions.seasonOrderRefundReset()),
  reloadOrder(customerId, orderId) {
    dispatch(orderActions.load(customerId, orderId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SeasonRefundModal);
