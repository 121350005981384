import { StyleSheet } from 'aphrodite/no-important';
import {
  palettes,
  spacers,
  typography,
  fonts,
  borderRadius,
} from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  container: {
    backgroundColor: colors.blue.dark,
    color: colors.white,
    paddingTop: spacers.dp_s32,
  },
  transactionSummaryContainer: {
    boxSizing: 'border-box',
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 auto',
    paddingBottom: spacers.dp_s48,
    width: '1036px',
  },
  paymentDetailsInfo: {
    width: '50%',
  },
  paymentSummaryTitle: {
    alignItems: 'center',
    display: 'flex',
    fontFamily: typography.dp_title2_font_family,
    fontSize: typography.dp_title2_font_size_m,
    fontWeight: fonts.dp_font_weight_bold,
    lineHeight: typography.dp_title2_line_height_m,
    letterSpacing: '-0.3px', // TODO: replace with depot-web typography when updated in depot-web
    marginBottom: spacers.dp_s16,
  },
  iconTicket: {
    marginRight: spacers.dp_s4,
    fill: palettes.dp_app_text_inverted,
  },
  infoBlock: {
    fontSize: typography.dp_body_font_size,
    lineHeight: typography.dp_body_line_height,
    marginRight: spacers.dp_s32,
    width: '190px',
  },
  infoPaidByBlock: {
    marginLeft: spacers.dp_s32,
  },
  paymentBlock: {
    width: '50%',
    backgroundColor: colors.white,
    color: '#3f4b4e',
    borderRadius: borderRadius.dp_r2,
    paddingBottom: spacers.dp_s16,
  },
  rightAlign: {
    marginLeft: 'auto',
  },
  blockTitle: {
    color: colors.blue.cyan,
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    textTransform: 'uppercase',
    marginTop: spacers.dp_s16,
    marginBottom: spacers.dp_s4,
  },
  statusType: {
    borderBottom: '1px dotted white',
    cursor: 'help',
  },
});
