import { css } from 'aphrodite/no-important';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { getBookings } from '@contactcentre-web/redux-common/selectors/order';
import type {
  FulfilmentConverterHistory,
  Status,
} from '@contactcentre-web/hooks/api/useOrderHistory';
import type { StatusDetails } from '@contactcentre-web/common/StatusIndicator';

import OrderHistory, { Row } from '../OrderHistory/OrderHistory';

import messages from './messages';
import styles from './styles';

interface Booking {
  id: string;
  bookingIndex: number;
  origin: string;
  destination: string;
  outDate: Date;
}
interface Booking {
  id: string;
  bookingIndex: number;
  origin: string;
  destination: string;
  outDate: Date;
}
const FulfilmentConverter: FunctionComponent<FulfilmentConverterHistory> = ({
  date,
  user,
  status,
  convertedProducts,
  originalFulfilment,
  convertedFulfilment,
}) => {
  const bookings = useSelector(getBookings);
  const { formatMessage } = useIntl();

  const statusMapper: Record<Status, StatusDetails> = {
    Requested: {
      color: 'base',
      message: messages.requested,
    },
    Pending: {
      color: 'base',
      message: messages.pending,
    },
    Completed: {
      color: 'positive',
      message: messages.approved,
    },
    Rejected: {
      color: 'negative',
      message: messages.rejected,
    },
  };

  const table = {
    headers: [
      formatMessage(messages.origin),
      formatMessage(messages.destination),
      formatMessage(messages.departure),
    ],
    rows: bookings.reduce((acc: Array<Row>, cur: Booking) => {
      if (convertedProducts && convertedProducts.indexOf(cur.id) > -1) {
        acc.push({ cells: [cur.origin, cur.destination, moment(cur.outDate).format('ll')] });
      }
      return acc;
    }, []),
  };

  return (
    <OrderHistory.Card>
      <OrderHistory.Header date={date} user={user}>
        <FormattedMessage {...messages.fulfilmentConverterProcessed} />
      </OrderHistory.Header>
      <OrderHistory.Content {...statusMapper[status]}>
        <OrderHistory.Items>
          <OrderHistory.Item
            label={formatMessage(messages.original)}
            testId="fulfimente-converter-original-delivery-method"
          >
            {originalFulfilment}
          </OrderHistory.Item>
          <OrderHistory.Item
            label={formatMessage(messages.new)}
            testId="fulfimente-converter-new-delivery-method"
          >
            {convertedFulfilment}
          </OrderHistory.Item>
        </OrderHistory.Items>
        {bookings?.length > 0 && (
          <div className={css(styles.tableContainer)}>
            <OrderHistory.ItemTable
              label={formatMessage(messages.products)}
              testId="fulfimente-converter-converted-products"
              table={table}
            />
          </div>
        )}
      </OrderHistory.Content>
    </OrderHistory.Card>
  );
};

export default FulfilmentConverter;
