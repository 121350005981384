import React, { FunctionComponent, LabelHTMLAttributes, ReactElement } from 'react';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';

import styles from './styles';

interface Props extends LabelHTMLAttributes<HTMLLabelElement> {
  testId?: string;
  ariaExpanded?: boolean;
  styleSheet?: StyleDeclarationValue;
  disabled?: boolean;
  label: string | ReactElement;
}

const Label: FunctionComponent<Props> = ({
  htmlFor,
  disabled,
  styleSheet,
  ariaExpanded,
  testId,
  label,
  children,
}) => (
  <label aria-expanded={ariaExpanded} htmlFor={htmlFor} data-test={testId}>
    <div className={css([styles.root, styleSheet, disabled && styles.disabled])}>{label}</div>
    {children}
  </label>
);
export default Label;
