export enum cookiesKeys {
  AUTH_TOKEN_KEY = 'authToken',
}

type CookieGetter = (name: string) => string | undefined;
type CookieSetter = (
  name: string,
  value: string | Record<string, any>,
  days?: number,
  path?: string
) => void;
type CookieRemover = (name: string, path?: string) => void;

interface CookieManager {
  getItem: CookieGetter;
  setItem: CookieSetter;
  removeItem: CookieRemover;
}

const DEFAULT_PATH = '/';

export const cookies: CookieManager = {
  getItem: (name) => {
    const cookies: string[] = document.cookie.split(';');

    return cookies.reduce((acc: string | undefined, entry: string) => {
      const [cookieName, cookieValue] = entry.split('=');
      if (cookieName.trim() === name) {
        return JSON.parse(decodeURIComponent(cookieValue));
      }
      return acc;
    }, undefined);
  },
  setItem: (name, value, days, path = DEFAULT_PATH): void => {
    const expirationDate: string = days
      ? new Date(new Date().getTime() + days * 24 * 60 * 60 * 1000).toUTCString()
      : '';
    document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(
      JSON.stringify(value)
    )}; expires=${expirationDate}; path=${path}`;
  },
  removeItem: (name, path = DEFAULT_PATH): void => cookies.setItem(name, '', -1, path),
};
