import { StyleSheet } from 'aphrodite/no-important';
import { fonts, typography, palettes, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  accordionContainer: {
    fontSize: typography.dp_body_font_size,
    fontWeight: fonts.dp_font_weight_semibold,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    display: 'flex',
    justifyContent: `space-between`,
    borderTop: `1px solid ${palettes.dp_app_border_base}`,
    padding: spacers.dp_s16,
    textAlign: 'left',
    width: 'inherit',
  },
  contentContainer: {
    padding: spacers.dp_s16,
  },
  heading: {
    marginTop: spacers.dp_s4,
  },
});
