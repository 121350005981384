import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  modalContent: {
    maxHeight: '100%',
    overflow: 'unset',
  },
  form: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  alertBox: {
    marginBottom: spacers.dp_s32,
  },
});
