import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import messages from './messages';
import styles from './styles';

const getSeatCoachDetails = (seatReservation) => {
  const hasSeatCoach =
    seatReservation &&
    ((seatReservation.compartment && seatReservation.compartment.identifier) ||
      seatReservation.seatNumber);

  const seatReservationTxt =
    hasSeatCoach &&
    seatReservation.compartment &&
    seatReservation.compartment.identifier &&
    seatReservation.seatNumber
      ? `${seatReservation.compartment.identifier} / ${seatReservation.seatNumber}`
      : hasSeatCoach;

  return seatReservationTxt;
};

const getSeatingOptions = (seatReservation) => {
  const seatingOptions =
    seatReservation && seatReservation.seatingOptions && seatReservation.seatingOptions.split(' ');
  return seatingOptions && seatingOptions.filter((option) => option && option !== '');
};

const ReservedSeat = ({ seatReservation }) => {
  const seatCoach = getSeatCoachDetails(seatReservation);
  const seatingOptions = getSeatingOptions(seatReservation);

  if (!seatCoach && (!seatingOptions || seatingOptions.length === 0)) {
    return <FormattedMessage {...messages.notAvailable} />;
  }

  return (
    <>
      {seatCoach && <div>{seatCoach}</div>}
      {seatingOptions && seatingOptions.length > 0 && (
        <ul className={css(styles.list)}>
          {seatingOptions.map((option, index) => (
            <li key={index}>{option}</li>
          ))}
        </ul>
      )}
    </>
  );
};

ReservedSeat.propTypes = {
  seatReservation: PropTypes.object,
};

export default ReservedSeat;
