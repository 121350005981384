import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  list: {
    listStyleType: 'none',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: spacers.dp_s16,
  },
  radio: {
    marginRight: spacers.dp_s16,
  },
});
