import { createActions } from 'redux-actions';

const PREFIX = 'CUSTOMER_ORDER/';
export const CUSTOMER_ORDER_LOAD = `${PREFIX}LOAD`;
export const CUSTOMER_ORDER_SUCCESS = `${PREFIX}SUCCESS`;
export const CUSTOMER_ORDER_FAIL = `${PREFIX}FAIL`;

export const { customerOrder: actions } = createActions({
  [CUSTOMER_ORDER_LOAD]: (customerId, orderReference, force = true) => ({
    customerId,
    orderReference,
    force,
  }),
});
