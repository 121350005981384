import { defineMessages } from 'react-intl';

export default defineMessages({
  resendConfirmationEmail: {
    id: 'ResendEmailForm.resendConfirmationEmail',
    defaultMessage: 'Resend emails',
  },
  resendEmail: {
    id: 'ResendEmailForm.resendEmail',
    defaultMessage: 'Resend email(s)',
  },
  resendBookingConfirmationEmail: {
    id: 'ResendEmailForm.resendBookingConfirmationEmail',
    defaultMessage: 'Resend order confirmation email',
  },
  resendBookingFulfilmentEmail: {
    id: 'ResendEmailForm.resendBookingFulfilmentEmail',
    defaultMessage: 'Resend booking fulfilment email(s)',
  },
  selectBookingFulfilment: {
    id: 'ResendEmailForm.selectBookingFulfilment',
    defaultMessage: 'Select booking to resend fulfilment',
  },
  selectBooking: {
    id: 'ResendEmailForm.selectBooking',
    defaultMessage: 'Please select booking(s)',
  },
  confirmEmail: {
    id: 'ResendEmailForm.confirmEmail',
    defaultMessage: "Confirm recipient's email",
  },
  addAnotherEmail: {
    id: 'ResendEmailForm.addAnotherEmail',
    defaultMessage: 'Add another email address',
  },
  emailPlaceholder: {
    id: 'ResendEmailForm.emailPlaceholder',
    defaultMessage: 'abc123@email.com',
  },
  emailNotValid: {
    id: 'ResendEmailForm.emailNotValid',
    defaultMessage: 'Email address invalid. Please use a valid email address',
  },
  emailRequired: {
    id: 'ResendEmailForm.emailRequired',
    defaultMessage: 'Email address is required.',
  },
  bookingText: {
    id: 'ResendEmailForm.bookingText',
    defaultMessage:
      '{origin} to {destination}{isReturn, select, true {\u2000RTN} other {}} - {travelDate}',
  },
  noFulfilmentBookingsTooltip: {
    id: 'ResendEmailForm.noFulfilmentBookingsTooltip',
    defaultMessage: 'There is no eligible bookings for fulfilment',
  },
  selection_required: {
    id: 'ResendEmailForm.selection_required',
    defaultMessage:
      'At least one resend option (order confirmation or fulfilment emails) must be selected.',
  },
  bookings_required: {
    id: 'ResendEmailForm.bookings_required',
    defaultMessage: 'Select which booking(s) to send the fulfilment emails for.',
  },
});
