import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import Select from '@contactcentre-web/common/Select';

import styles from '../../OrderNoteForm/styles';
import messages from '../messages';

export const surveyChoiceId = 'surveyChoiceId';

const mapOptions = ({ surveyChoiceId: id, name }) => ({ value: id, label: name });

const SurveyChoiceSelect = ({ surveyChoices = [{ value: '', label: '' }], disabled }) => {
  const surveyOptions = surveyChoices.map(mapOptions);
  return (
    <div data-test-id="survey-select" className={disabled ? css(styles.disabledSelect) : {}}>
      <FormattedMessage {...messages.surveyReasonLabel}>
        {(value) => <span className={css(styles.fieldLabel)}>{value}</span>}
      </FormattedMessage>
      <Select
        name={surveyChoiceId}
        placeholder={<FormattedMessage {...messages.selectSurveyReason} />}
        options={surveyOptions}
        messages={messages}
        disabled={disabled}
      />
    </div>
  );
};

SurveyChoiceSelect.propTypes = {
  surveyChoices: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool.isRequired,
};

export default SurveyChoiceSelect;
