import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { OrderHistoryCard, OrderHistoryCardHeader } from '../OrderHistoryCard/OrderHistoryCard';
import type { ConfirmationEmailItem } from '../../module';

import messages from './messages';

const ConfirmationEmail: FunctionComponent<ConfirmationEmailItem> = ({
  agent,
  createdAt,
  emails,
  orderReference,
}) => (
  <OrderHistoryCard
    createdAt={createdAt}
    cardTitle={<FormattedMessage {...messages.emailResent} />}
    headerItems={
      <>
        <OrderHistoryCardHeader
          label={<FormattedMessage {...messages.booking} />}
          value={<FormattedMessage {...messages.appliesTo} values={{ orderReference }} />}
        />
        <OrderHistoryCardHeader label={<FormattedMessage {...messages.agent} />} value={agent} />
      </>
    }
    cardBody={
      <strong>
        <FormattedMessage
          {...messages.body}
          values={{
            emailsCount: emails?.length,
            ...emails,
          }}
        />
      </strong>
    }
  />
);

export default ConfirmationEmail;
