import { Heading, IconChevronLeft, Paragraph } from '@trainline/depot-web';
import { css } from 'aphrodite/no-important';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { InjectedFormProps, reduxForm, formValueSelector } from 'redux-form';
import { useSelector } from 'react-redux';

import {
  ErrorTypes,
  StateTypes,
  TerminalTypes,
  useVirtualTerminalCompensation,
  useVirtualTerminalSearch,
  VirtualTerminalData,
  VirtualTerminalRequest,
  VirtualTerminalResponse,
} from '@contactcentre-web/hooks/api/useVirtualTerminal';
import { ApiError, ApiValidationError } from '@contactcentre-web/hooks/api/sharedTypes';
import Link from '@contactcentre-web/common/Link';
import * as userSelectors from '@contactcentre-web/authentication/redux/selectors';
import { validateEmail } from '@contactcentre-web/utils/validation/validators';

import CustomerForm, { FORM_CUSTOMER } from './components/CustomerForm/CustomerForm';
import PaymentIframe from './components/PaymentIframe/PaymentIframe';
import ProceedCompensation, {
  FORM_PROCEED,
} from './components/ProceedCompensation/ProceedCompensation';
import SearchForm from './components/SearchForm/SearchForm';
import StateModal from './components/StateModal/StateModal';
import TotalAmount from './components/TotalAmount/TotalAmount';
import messages from './messages';
import styles from './styles';

export const FORM_ID = 'VirtualTerminalForm';

export type Props = InjectedFormProps<VirtualTerminalData>;

export const VirtualTerminal = ({ initialize, pristine, invalid, handleSubmit }: Props) => {
  const isTerminalDebit = location.pathname.includes('virtual-terminal-debit');
  const history = useHistory();
  const [friendlyOrderId, setFriendlyOrderId] = useState<string>();
  const [showStateModal, setShowStateModal] = useState({
    state: StateTypes.INITIAL,
    errorCode: ErrorTypes.INITIAL,
  });
  const [formData, setFormData] = useState<VirtualTerminalRequest>();
  const [isPaymentFormVisible, setIsPaymentFormVisible] = useState(false);
  const [url, setUrl] = useState<string>();
  const [urlIframe, setUrlIframe] = useState<string>();
  const [displayMessage, setDisplayMessage] = useState<
    | 'transactionFound'
    | 'transactionNotFound'
    | 'userWithoutPermission'
    | 'requiredOrderId'
    | 'defaultErrorMessage'
    | undefined
  >();
  const { data, isError, error, isLoading, isSuccess } = useVirtualTerminalSearch(friendlyOrderId);

  const { mutate: requestCompensation, isLoading: loadingCompensationRequest } =
    useVirtualTerminalCompensation(
      {
        onSuccess: () => setShowStateModal({ ...showStateModal, state: StateTypes.SUCCESS }),
        onError: (error: ApiValidationError) => {
          setShowStateModal({
            ...showStateModal,
            state: StateTypes.ERROR,
            errorCode: (error.errors?.[0].code as ErrorTypes) ?? '',
          });
        },
      },
      isTerminalDebit ? TerminalTypes.DEBIT : TerminalTypes.CREDIT
    );

  const handleSearch = (orderId: string) => setFriendlyOrderId(orderId);

  const canApproveVirtualTerminalCompensation = useSelector(
    userSelectors.canApproveUnlimitedRefund
  );

  const findErrorMessage = (error?: ApiError | ApiValidationError | null) => {
    if (!error) return 'transactionNotFound';

    switch (error.status) {
      case 400:
        return 'requiredOrderId';
      case 403:
        return 'userWithoutPermission';
      case 404:
        return 'transactionNotFound';
      default:
        return 'defaultErrorMessage';
    }
  };

  const compensationForm = useSelector((state) => formValueSelector(FORM_ID)(state, FORM_PROCEED));
  const customerForm = useSelector((state) => formValueSelector(FORM_ID)(state, FORM_CUSTOMER));

  useEffect(() => {
    if (data?.platform) {
      setDisplayMessage('transactionFound');
      setUrl(`customers/${data.customer?.id}/bookings/${data.friendlyOrderReference}`);
      setUrlIframe(data.links?.find((p) => p.rel === 'paymentForm')?.href);
      initialize({
        customerForm: {
          firstName: !data.customer.isImplicitlyRegistered ? data.customer.firstName : '',
          lastName: !data.customer.isImplicitlyRegistered ? data.customer.lastName : '',
          email: !data.customer.isImplicitlyRegistered ? data.customer.email : '',
        },
        proceedCompensationForm: {
          totalAmount: data.paymentDetails?.totalTransaction?.amount ?? 0,
          currencyCode: data.paymentDetails?.totalTransaction?.currencyCode ?? '',
        },
      });
    } else if (isError) {
      setDisplayMessage(findErrorMessage(error));
    }
  }, [data?.orderReference, error]);

  const handleFormSubmit = (form: VirtualTerminalData) => {
    setIsPaymentFormVisible(true);
    const { orderReference, platform } = data as VirtualTerminalResponse;
    setFormData({
      requestingCustomer: {
        firstName: form.customerForm.firstName,
        lastName: form.customerForm.lastName,
        email: form.customerForm.email,
      },
      notes: form.customerForm.notes,
      orderReference: orderReference,
      platform: platform,
      reasonCodeId: form.customerForm.reasonCode,
      value: {
        amount: Number(form.proceedCompensationForm.totalAmount),
        currencyCode: form.proceedCompensationForm.currencyCode,
      },
    } as VirtualTerminalRequest);
  };

  const handleIframeSuccess = (token?: string) => {
    const data = { ...formData, paymentTokenId: token } as VirtualTerminalRequest;
    requestCompensation(data);
  };

  const handleOnModalClose = (state: StateTypes) => {
    setShowStateModal({ ...showStateModal, state: StateTypes.INITIAL });
    state === StateTypes.SUCCESS && history.push('/');
    state === StateTypes.ERROR && setIsPaymentFormVisible(false);
  };

  const shouldShowApprovalQueueNavigation =
    canApproveVirtualTerminalCompensation && !isPaymentFormVisible;

  return (
    <>
      {isPaymentFormVisible && (
        <Link
          styleSheet={styles.button}
          linkType="action"
          onClick={() => setIsPaymentFormVisible(false)}
        >
          <IconChevronLeft className={css(styles.arrowBack)} />
          <FormattedMessage {...messages.backButton} />
        </Link>
      )}
      <div className={css(styles.headerContainer)}>
        <Heading typeStyle="title1" as="h1" color="base">
          <FormattedMessage
            {...(isTerminalDebit ? messages.virtualTerminalDebit : messages.virtualTerminalCredit)}
          />
        </Heading>

        {!isTerminalDebit && shouldShowApprovalQueueNavigation && (
          <Link
            linkType="internal"
            to="/virtual-terminal-approvals"
            styleSheet={styles.linkAsButton}
          >
            <FormattedMessage {...messages.approvalQueueButton} />
          </Link>
        )}
      </div>
      <div className={css(styles.messageHelper)}>
        <Paragraph typeStyle="mini" as="p" color="base" fontWeight="regular">
          <FormattedMessage
            {...(isTerminalDebit
              ? messages.virtualTerminalDebitHelper
              : messages.virtualTerminalCreditHelper)}
          />
        </Paragraph>
      </div>
      <div className={css(styles.content)}>
        {isPaymentFormVisible && urlIframe && (
          <>
            <PaymentIframe
              url={urlIframe}
              onIframeSuccess={handleIframeSuccess}
              isLoading={loadingCompensationRequest}
            />
            <aside className={css(styles.paymentForm)}>
              <TotalAmount compensationForm={compensationForm} isTerminalDebit={isTerminalDebit} />
            </aside>
          </>
        )}
        {!isPaymentFormVisible && (
          <>
            <SearchForm
              onSearch={handleSearch}
              message={displayMessage}
              isLoading={isLoading}
              url={url}
              isError={isError}
              isSuccess={isSuccess}
              initialValues={{ friendlyOrderId }}
            />
            {isSuccess && data && (
              <form id={FORM_ID} onSubmit={handleSubmit(handleFormSubmit)}>
                <CustomerForm data={data} notesLength={customerForm?.notes?.length ?? 0} />
                <aside className={css(styles.paymentForm)}>
                  <ProceedCompensation
                    canNotProceed={pristine || invalid}
                    isTerminalDebit={isTerminalDebit}
                  />
                </aside>
              </form>
            )}
          </>
        )}
      </div>
      {showStateModal.state && (
        <StateModal
          onCloseModal={() => handleOnModalClose(showStateModal.state)}
          stateData={showStateModal}
          isTerminalDebit={isTerminalDebit}
        />
      )}
    </>
  );
};

export const validate = (
  data: VirtualTerminalData
): Record<string, Record<string, string | undefined>> => ({
  customerForm: {
    firstName: !data?.customerForm?.firstName ? 'validation_firstName_invalid' : undefined,
    lastName: !data?.customerForm?.lastName ? 'validation_lastName_invalid' : undefined,
    email: !data?.customerForm?.email
      ? 'validation_email_required'
      : !validateEmail(data?.customerForm?.email)
      ? 'validation_email_invalid'
      : undefined,
    notes: !data?.customerForm?.notes ? 'validation_notes_invalid' : undefined,
    reasonCode: !data?.customerForm?.reasonCode ? 'validation_reasonCode_invalid' : undefined,
  },
  proceedCompensationForm: {
    totalAmount: !data?.proceedCompensationForm?.totalAmount
      ? 'validation_totalAmount_required'
      : undefined,
  },
});

export default reduxForm<VirtualTerminalData>({
  form: FORM_ID,
  validate,
})(VirtualTerminal);
