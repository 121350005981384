import { defineMessages } from 'react-intl';

export default defineMessages({
  appUpdating: {
    id: 'AppUpdatePage.appUpdating',
    defaultMessage: 'Application updating',
  },
  refreshMessage: {
    id: 'AppUpdatePage.refreshMessage',
    defaultMessage: 'The application will automatically reload in {delay} secs...',
  },
  reloadNow: {
    id: 'AppUpdatePage.reloadNow',
    defaultMessage: 'Reload now',
  },
});
