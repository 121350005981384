import { cookies, cookiesKeys } from './cookieStorage';

export interface Token {
  access_token: string;
  refresh_token: string;
  expires_at: Date;
  expires_in: number;
  token_type: string;
}

const getToken = (): Token | undefined => {
  const token = cookies.getItem(cookiesKeys.AUTH_TOKEN_KEY) as unknown as Token;
  if (!token) {
    return undefined;
  }
  token.expires_at = new Date(token.expires_at);
  return token;
};

const setToken = (token: Token) => cookies.setItem(cookiesKeys.AUTH_TOKEN_KEY, token);

const clearToken = () => cookies.removeItem(cookiesKeys.AUTH_TOKEN_KEY);

const tokenIsExpired = (token: Token) => token.expires_at?.getTime() - Date.now() <= 0;

const validTokenExists = (): boolean => {
  const token = getToken();
  return !!token && !tokenIsExpired(token);
};

export default {
  validTokenExists,
  getToken,
  setToken,
  clearToken,
};
