import React from 'react';
import { FormattedMessage } from 'react-intl';

import ProductType from '@contactcentre-web/redux-common/types/ProductType';

import messages from './messages';

export interface Props {
  bookings: Array<{
    type: string;
    name?: string;
    origin?: string;
    destination?: string;
    isReturn?: boolean;
  }>;
}

export const FormattedBookings = ({ bookings }: Props) => (
  <>
    {bookings.map(({ type, name, origin, destination, isReturn }, index) => {
      const valuesContent = {
        single: bookings.length === 1,
        first: index === 0,
        last: index === bookings.length - 1,
      };

      switch (type) {
        case ProductType.Season:
          return (
            <FormattedMessage
              key={index}
              {...messages.season}
              values={{
                ...valuesContent,
                origin,
                destination,
              }}
            />
          );
        case ProductType.Railcard:
          return (
            <FormattedMessage
              key={index}
              {...messages.railcard}
              values={{
                ...valuesContent,
                name,
              }}
            />
          );
        default:
          return (
            <FormattedMessage
              key={index}
              {...messages.journey}
              values={{
                ...valuesContent,
                origin,
                destination,
                isReturn: !!isReturn,
              }}
            />
          );
      }
    })}
  </>
);

export default FormattedBookings;
