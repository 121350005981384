import React, { FunctionComponent } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { Paragraph } from '@trainline/depot-web';

import messages from './messages';
import styles from './styles';

interface Props {
  index: number;
  section: 'bookingInformation' | 'refundStatus';
}

const RefundJourney: FunctionComponent<Props> = ({ index, section }) => (
  <div className={css(styles[`${section}Fare`])}>
    <Paragraph as="p" typeStyle="body" fontWeight="bold" color="subdued">
      <FormattedMessage {...messages.journey} values={{ index: index + 1 }} />
    </Paragraph>
  </div>
);

export default RefundJourney;
