import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import { Action } from 'redux-actions';

import { SortDirectionType } from '@contactcentre-web/hooks/shared/useToggleSortDirection';
import { FetchError } from '@contactcentre-web/utils/error';
import request, { HTTPMethod } from '@contactcentre-web/utils/request';
import type State from '@contactcentre-web/redux-common/types/State';

import {
  actions,
  loadStatus,
  approvalStatus,
  rejectStatus,
  PREFIX,
  RefundsApprovalState,
  RefundItemsState,
  RefundState,
  deleteStatus,
  overrideStatus,
  LoadPayload,
} from './module';
import selectors, { RequestData } from './selectors';

interface RejectResponse {
  status: string;
}

interface ApproveResponse {
  status: string;
}

export const requestPendingRefunds = (page = 1, perPage = 10, sortDirection: SortDirectionType) =>
  request(`/refundsapi/requests?page=${page}&perPage=${perPage}&sortDirection=${sortDirection}`);

export const requestAction = (url: string, method: HTTPMethod = 'POST') => request(url, { method });

export const discApprovalsRequest = (
  page: number,
  perPage: number,
  sortDirection: SortDirectionType
) => requestPendingRefunds(page, perPage, sortDirection);

export function* loadNextIfRequired() {
  const state: State = yield select();
  const refundsListIsEmpty = selectors.isRefundsListEmpty(state);
  const morePages = selectors.hasMorePages(state);

  if (morePages && refundsListIsEmpty) {
    yield put(actions.loadNextAttempt());
  }
}

export function* loadPendingRefundsSaga({
  payload: { page, perPage, sortDirection = 'Asc' },
}: Action<LoadPayload>) {
  try {
    const state: State = yield select();
    const refunds: RefundState = yield call(
      discApprovalsRequest,
      page,
      perPage,
      sortDirection,
      state
    );

    yield put(actions.loadSuccess(refunds));
  } catch (e) {
    yield put(actions.loadError(e));
  }
}

export function* approveRequestSaga({ payload: { requestId } }: Action<{ requestId: string }>) {
  const state: State = yield select();
  const refundRequest: RefundItemsState | undefined = selectors
    .refundsItems(state)
    .find(({ id }) => id === requestId);

  const approveRequestLink = refundRequest?.links.find((l) => l.rel === 'approve');
  const approveRequestUrl = approveRequestLink ? approveRequestLink.href : '';

  try {
    const response: ApproveResponse = yield call(requestAction, approveRequestUrl);

    if (response !== undefined && response.status === 'Approved') {
      yield put(actions.approvalSuccess(requestId));
      yield call(loadNextIfRequired);
    } else if (response !== undefined && response.status === 'RefundRequestAmended') {
      yield put(actions.approvalErrorRefundRequestAmended());
    } else {
      yield put(actions.approvalError());
    }
  } catch (error: any) {
    if (error.status === 404) {
      yield put(actions.approvalErrorRefundRequestProcessed());
    } else if (
      error.status === 422 &&
      error.errors?.find((err: FetchError) => err.code === 'refundRejectedByFraudProvider')
    ) {
      yield put(actions.approvalErrorRefundRejectedByFraud(requestId));
    } else if (error.errors?.find((err: FetchError) => err.code === 'refundAprovalInvalidQuote')) {
      yield put(actions.approvalErrorInvalidQuote());
    } else {
      yield put(actions.approvalError());
    }
  }
}

export function* rejectRequestSaga({ payload: { requestId } }: Action<{ requestId: string }>) {
  const state: State = yield select();
  const refundRequest: RefundItemsState | undefined = selectors
    .refundsItems(state)
    .find(({ id }) => id === requestId);

  const rejectRequestLink = refundRequest?.links.find((l) => l.rel === 'reject');
  const rejectRequestUrl = rejectRequestLink ? rejectRequestLink.href : '';

  try {
    const response: RejectResponse = yield call(requestAction, rejectRequestUrl);

    if (response && response.status === 'RefundRequestAmended') {
      yield put(actions.rejectErrorRefundRequestProcessed());
      return;
    }

    yield put(actions.rejectSuccess(requestId));
    yield call(loadNextIfRequired);
  } catch (error: any) {
    if (error.status === 404) {
      yield put(actions.rejectErrorRefundRequestProcessed());
    } else {
      yield put(actions.rejectError(error));
    }
  }
}

export function* deleteRequestSaga({ payload: { requestId } }: Action<{ requestId: string }>) {
  const state: State = yield select();
  const errorItems = selectors.refundsErrorItems(state).map(({ meta }) => ({ ...meta }));
  const allItems = [...selectors.refundsItems(state), ...errorItems];
  const refundRequest = allItems.find(({ id }) => id === requestId);

  const deleteRequestLink = refundRequest?.links?.find(({ rel }) => rel === 'delete');
  const deleteRequestUrl = deleteRequestLink ? deleteRequestLink.href : '';

  try {
    const response: RejectResponse = yield call(requestAction, deleteRequestUrl, 'DELETE');

    if (response && response.status === 'RefundRequestAmended') {
      yield put(actions.rejectErrorRefundRequestProcessed());
      return;
    }

    yield put(actions.deleteSuccess(requestId));
    yield call(loadNextIfRequired);
  } catch (error: any) {
    if (error.status === 404) {
      yield put(actions.deleteErrorRefundRequestProcessed());
    } else {
      yield put(actions.deleteError(error));
    }
  }
}

export function* overrideAndApproveRequestSaga({
  payload: { requestId },
}: Action<{ requestId: string }>) {
  const state: State = yield select();
  const refundRequest: RefundItemsState | undefined = selectors
    .refundsItems(state)
    .find(({ id }) => id === requestId);

  const overrideRequestLink = refundRequest?.links?.find(({ rel }) => rel === 'override');
  const overrideRequestUrl = overrideRequestLink ? overrideRequestLink.href : '';

  try {
    yield call(requestAction, overrideRequestUrl);
    yield put(actions.overrideSuccess(requestId));
    yield put(actions.approvalAttempt(requestId));
  } catch (error: any) {
    yield put(actions.overrideError(error));
  }
}

export function* loadNextPendingRefundsSaga() {
  try {
    const refunds: RequestData = yield select(selectors.requests);
    const nextRefunds: RefundsApprovalState = yield call(request, refunds.nextPage);
    yield put(actions.loadNextSuccess(nextRefunds));
  } catch (e) {
    yield put(actions.loadNextError(e));
  }
}

export default function* saga() {
  yield all([
    takeLatest(`${PREFIX}/${loadStatus.LOAD_ATTEMPT}`, loadPendingRefundsSaga),
    takeLatest(`${PREFIX}/${loadStatus.LOAD_NEXT_ATTEMPT}`, loadNextPendingRefundsSaga),
    takeLatest(`${PREFIX}/${approvalStatus.APPROVAL_ATTEMPT}`, approveRequestSaga),
    takeLatest(`${PREFIX}/${rejectStatus.REJECT_ATTEMPT}`, rejectRequestSaga),
    takeLatest(`${PREFIX}/${deleteStatus.DELETE_ATTEMPT}`, deleteRequestSaga),
    takeLatest(`${PREFIX}/${overrideStatus.OVERRIDE_ATTEMPT}`, overrideAndApproveRequestSaga),
  ]);
}
