import { css } from 'aphrodite/no-important';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import FormattedDateInLondonTz from '@contactcentre-web/common/FormattedDateInLondonTz';
import { SavedCard as SavedCardType } from '@contactcentre-web/hooks/api/useCustomerPassengers';

import DeleteSavedCards from '../DeleteSavedCards/DeleteSavedCards';

import messages from './messages';
import styles from './styles';

interface Props {
  savedCard: SavedCardType;
  passengerId: string;
}

const SavedCard = ({ savedCard, passengerId }: Props) => {
  const { number, validityStart, validityEnd } = savedCard;

  return (
    <section className={css(styles.cardContent)}>
      {number && (
        <div className={css(styles.textLabel)} data-test="saved-card-number">
          <FormattedMessage
            {...messages.savedCardNumber}
            values={{
              number,
            }}
          />
        </div>
      )}
      {validityStart && (
        <div className={css(styles.textLabel)} data-test="saved-card-start">
          <FormattedMessage
            {...messages.savedCardStartDate}
            values={{
              validityStart: <FormattedDateInLondonTz value={validityStart} />,
            }}
          />
        </div>
      )}
      {validityEnd && (
        <div className={css(styles.textLabel)} data-test="saved-card-expiry">
          <FormattedMessage
            {...messages.savedCardExpiryDate}
            values={{
              validityEnd: <FormattedDateInLondonTz value={validityEnd} />,
            }}
          />
        </div>
      )}
      <DeleteSavedCards savedCard={savedCard} passengerId={passengerId} />
    </section>
  );
};

export default SavedCard;
