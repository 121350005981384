import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { Paragraph } from '@trainline/depot-web';

import Link from '@contactcentre-web/common/Link';

import messages from './messages';
import styles from './styles';

export const FulfilmentConverterSuccess = () => (
  <div className={css(styles.container)}>
    <Paragraph typeStyle="body" as="p" color="base" fontWeight="regular">
      <FormattedMessage
        {...messages.bookingConverted}
        values={{
          a: (msg: string) => (
            <Link linkType="action" onClick={() => window.location.reload()}>
              {msg}
            </Link>
          ),
        }}
      />
    </Paragraph>
  </div>
);

export default FulfilmentConverterSuccess;
