import React, { Dispatch, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { css } from 'aphrodite/no-important';
import qs from 'query-string';

import Search from '@contactcentre-web/search/Search';
import Logo from '@contactcentre-web/common/Logo/Logo';
import NotificationBox from '@contactcentre-web/common/NotificationBox/NotificationBox';
import notificationMessages from '@contactcentre-web/middlewares/messages';
import * as userSelector from '@contactcentre-web/authentication/redux/selectors';
import { selectors as adsSelectors } from '@contactcentre-web/order-notes/module';
import Action from '@contactcentre-web/redux-common/types/Action';
import type State from '@contactcentre-web/redux-common/types/State';

import AgentBookingButton from './components/AgentBookingButton/AgentBookingButton';
import ManagedGroup from './components/ManagedGroup/ManagedGroup';
import UserMenuContainer from './components/UserMenu/UserMenu';
import { selectors, actions } from './module';
import styles from './styles';
import TelephonyStatus from './components/TelephonyStatus/TelephonyStatus';

interface StateProps {
  message?: string;
  messageType?: string;
  conversationId?: string;
  managedGroup: string;
  roleName: string | null;
  canChangeManagedGroup: boolean;
  canUseBookingFlow: boolean;
  shouldShowTelephonyIcon: boolean;
}

interface DispatchProps {
  removeNotification: () => void;
}

interface Props extends StateProps, DispatchProps {
  searchTerm?: string;
  searchType?: string;
  isSearchPage?: boolean;
}

export const Header: FunctionComponent<Props> = ({
  removeNotification,
  message,
  messageType,
  conversationId,
  isSearchPage = useRouteMatch({
    path: '/',
    exact: true,
  }),
  managedGroup,
  roleName,
  canChangeManagedGroup,
  canUseBookingFlow,
  shouldShowTelephonyIcon,
}) => {
  const { search } = useLocation();
  const { q: searchQuery } = qs.parse(search) as { q?: string };

  return (
    <header className={css(styles.header)}>
      <nav className={css(styles.nav)}>
        <div className={css(styles.topColumn)}>
          <Logo />
          {isSearchPage && (
            <div className={css(styles.managedGroupContainer)}>
              <ManagedGroup
                managedGroup={managedGroup}
                roleName={roleName}
                canChangeManagedGroup={canChangeManagedGroup}
              />
            </div>
          )}
          <div className={css(styles.topRight)}>
            <UserMenuContainer />
            {shouldShowTelephonyIcon && (
              <div className={css(styles.telephonyIcon)}>
                <TelephonyStatus />
              </div>
            )}
            {canUseBookingFlow && <AgentBookingButton />}
          </div>
        </div>
        {!isSearchPage && (
          <div className={css(styles.bottomColumn)}>
            <ManagedGroup
              managedGroup={managedGroup}
              roleName={roleName}
              canChangeManagedGroup={canChangeManagedGroup}
            />
            <Search initialValues={{ search: searchQuery }} inline />
          </div>
        )}
        {message && messageType && (
          <NotificationBox type={messageType} onClick={removeNotification}>
            <div data-test="error-message">
              {notificationMessages[message] ? (
                <FormattedMessage {...notificationMessages[message]} />
              ) : (
                message
              )}
            </div>
            {conversationId && (
              <div className={css(styles.conversationId)}>
                <FormattedMessage
                  {...notificationMessages.conversationId}
                  values={{ conversationId }}
                />
              </div>
            )}
          </NotificationBox>
        )}
      </nav>
    </header>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  message: selectors.getMessage(state),
  messageType: selectors.getMessageType(state),
  conversationId: selectors.getConversationId(state),
  managedGroup: userSelector.getCurrentManagedGroupName(state),
  roleName: userSelector.getRoleName(state),
  canChangeManagedGroup: userSelector.isPartOfMultipleManagedGroups(state),
  canUseBookingFlow: userSelector.canUseBookingFlow(state),
  shouldShowTelephonyIcon: adsSelectors.isAcsCompatibleAdsActive(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
  removeNotification: () => dispatch(actions.hideErrorMessage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
