import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from '../../../../messages';

const RevocationResultsTableHeader = () => (
  <tr>
    <th>
      <span>
        <FormattedMessage {...messages.revocationResultTableCustomerIdColumn} />
      </span>
    </th>
    <th>
      <span>
        <FormattedMessage {...messages.revocationResultTableManagedGroupIdColumn} />
      </span>
    </th>
    <th>
      <span>
        <FormattedMessage {...messages.revocationResultTableLanguageColumn} />
      </span>
    </th>
    <th>
      <span>
        <FormattedMessage {...messages.revocationResultTableStatusColumn} />
      </span>
    </th>
  </tr>
);

export default RevocationResultsTableHeader;
