import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import PageContainer from '@contactcentre-web/common/PageContainer';

import messages from './messages';
import styles from './styles';

const NotFoundPage = () => (
  <PageContainer>
    <div className={css(styles.container)}>
      <h1>
        <FormattedMessage {...messages.header404} />
      </h1>
      <p>
        <FormattedMessage {...messages.notFoundMessage} />
      </p>
    </div>
  </PageContainer>
);

export default NotFoundPage;
