import { css } from 'aphrodite/no-important';
import React, { Dispatch } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';

import type State from '@contactcentre-web/redux-common/types/State';
import type Action from '@contactcentre-web/redux-common/types/Action';
import MultiSelectDropdown, {
  MultiSelectDropdownOptions,
} from '@contactcentre-web/common/MultiSelectDropdown/MultiSelectDropdown';
import Checkbox from '@contactcentre-web/common/Checkbox';
import Button from '@contactcentre-web/common/Button';

import { actions, BookingFulfilment, FORM_ID, ResendEmailData, selectors } from '../../module';
import ResendEmailFailureContainer from '../ResendEmailFailure/ResendEmailFailure';

import EmailList from './EmailList';
import messages from './messages';
import styles, { fieldStyles } from './styles';

export interface ResendEmailDataError {
  resendConfirmationEmail?: string;
  resendFulfilmentEmail?: string;
  bookings?: string;
  emails?: Array<string>;
}

type SelectType = {
  label: string;
  value: string;
  selected: boolean;
};
interface StateProps {
  isPending: boolean;
  bookings: Array<BookingFulfilment>;
  eligibleFulfilmentProducts: Array<string>;
}
export type Props = StateProps;

export const ResendEmailForm = ({
  initialize,
  handleSubmit,
  isPending,
  pristine,
  eligibleFulfilmentProducts,
  valid,
  bookings,
  change,
}: Props & InjectedFormProps<ResendEmailData, Props>) => {
  const { formatMessage } = useIntl();
  const [isResendConfirmationEmailChecked, setIsResendConfirmationEmailChecked] =
    React.useState(true);
  const [isResendFulfilmentEmailChecked, setIsResendFulfilmentEmailChecked] = React.useState(false);
  const emailsDisabled =
    isPending || (!isResendConfirmationEmailChecked && !isResendFulfilmentEmailChecked);
  const submitButtonDisabled = isPending || pristine || !valid;
  const fulfilmentBookingsDisabled = isPending || eligibleFulfilmentProducts.length === 0;
  const noFulfilmentBookingsTooltip = fulfilmentBookingsDisabled
    ? formatMessage(messages.noFulfilmentBookingsTooltip)
    : '';

  React.useEffect(() => {
    initialize({
      resendConfirmationEmail: true,
      bookings: [],
      emails: [''],
    });
  }, []);

  let selectOptions = [] as Array<MultiSelectDropdownOptions>;

  selectOptions = bookings.map(
    ({ id, origin, destination, isReturn, travelDate }: BookingFulfilment) => ({
      text: formatMessage(messages.bookingText, {
        origin,
        destination,
        isReturn,
        travelDate,
      }),
      value: id,
      disabled: !eligibleFulfilmentProducts.find((product) => product === id),
      selected: false,
    })
  );

  const onSelectedFulfilmentChange = (option: SelectType, isChecked: boolean) => {
    const item = selectOptions.find((i) => i.value === option.value);
    if (item) {
      selectOptions = [
        ...selectOptions.filter((i) => i.value !== option.value),
        { ...item, selected: isChecked },
      ];
    }
  };

  const handleResendFulfilmentEmailChecked = (checked: boolean) => {
    change('bookings', []);
    setIsResendFulfilmentEmailChecked(checked);
  };

  return (
    <form id={FORM_ID} className={css(styles.container)} onSubmit={handleSubmit}>
      <h1 className={css(styles.title)} data-test="ResendEmailHeader">
        <FormattedMessage {...messages.resendConfirmationEmail} />
      </h1>

      <ResendEmailFailureContainer />

      <div className={css(styles.fieldsContainer, styles.checkboxContainer)}>
        <Checkbox
          styleSheet={styles.checkbox}
          id="resendConfirmationEmail"
          name="resendConfirmationEmail"
          value={isResendConfirmationEmailChecked}
          onChange={(_: boolean, newValue: boolean) =>
            setIsResendConfirmationEmailChecked(newValue)
          }
          label={
            <span data-test="ResendConfirmationEmail">
              <FormattedMessage {...messages.resendBookingConfirmationEmail} />
            </span>
          }
          errors={{
            required: formatMessage(messages.selection_required),
          }}
        />
        <Checkbox
          id="resendFulfilmentEmail"
          name="resendFulfilmentEmail"
          disabled={fulfilmentBookingsDisabled}
          value={isResendFulfilmentEmailChecked}
          onChange={(_: boolean, newValue: boolean) => handleResendFulfilmentEmailChecked(newValue)}
          label={
            <span data-test="ResendFulfilmentEmail">
              <FormattedMessage {...messages.resendBookingFulfilmentEmail} />
            </span>
          }
          tooltipMessage={noFulfilmentBookingsTooltip}
        />
      </div>
      <div className={css(styles.fieldsContainer)}>
        <div className={css(styles.column)}>
          <div className={css(styles.inputContainer)} data-test="SelectBookingFulfilment">
            <MultiSelectDropdown
              name="bookings"
              data-test="somethingdsfsdfd"
              disabled={fulfilmentBookingsDisabled || !isResendFulfilmentEmailChecked}
              items={selectOptions}
              onOptionChange={onSelectedFulfilmentChange}
              label={formatMessage(messages.selectBookingFulfilment)}
              styleSheet={fieldStyles}
              noSelectedOptionsText={formatMessage(messages.selectBooking)}
              errors={{
                bookings_required: formatMessage(messages.bookings_required),
              }}
            />
          </div>
        </div>
        <div className={css(styles.column)} data-test="ConfirmRecipientEmail">
          <EmailList
            name="emails"
            disabled={emailsDisabled}
            label={formatMessage(messages.confirmEmail)}
          />
        </div>
      </div>
      <Button
        type="submit"
        variant="primary"
        size="large"
        testId="ResendEmailSubmit"
        disabled={submitButtonDisabled}
        loading={isPending}
        styleSheet={styles.button}
      >
        <FormattedMessage {...messages.resendEmail} />
      </Button>
    </form>
  );
};

const emailValidationRe =
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i; // eslint-disable-line

export const validate = ({
  emails = [],
  resendFulfilmentEmail,
  resendConfirmationEmail,
  bookings = [],
}: ResendEmailData): any => {
  const result = {} as ResendEmailDataError;
  const emailValidationResult = [] as Array<string>;
  emails.forEach((email, index) => {
    if (!email) {
      emailValidationResult[index] = 'emailRequired';
    } else if (!emailValidationRe.test(email)) {
      emailValidationResult[index] = 'emailNotValid';
    }
  });

  if (!resendConfirmationEmail && !resendFulfilmentEmail) {
    result.resendConfirmationEmail = 'required';
  }
  if (Object.keys(emailValidationResult).length > 0) {
    result.emails = emailValidationResult as Array<string>;
  }
  if (resendFulfilmentEmail && bookings.length === 0) {
    result.bookings = 'bookings_required';
  }

  return result;
};

const mapStateToProps = (state: State) => ({
  isPending: selectors.isPending(state.resendEmail),
  bookings: selectors.getBookings(state),
  eligibleFulfilmentProducts: selectors.getEligibleFulfilmentProducts(state),
});

export default connect(mapStateToProps)(
  reduxForm<ResendEmailData, Props>({
    form: FORM_ID,
    validate,
    onSubmit: (
      { resendConfirmationEmail, resendFulfilmentEmail, emails, bookings }: ResendEmailData,
      dispatch: Dispatch<Action>
    ) => {
      const emailAddresses = Object.keys(emails).map((_, key) => emails[key]);
      dispatch(
        actions.pending(resendConfirmationEmail, resendFulfilmentEmail, bookings, emailAddresses)
      );
    },
  })(ResendEmailForm)
);
