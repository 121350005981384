import { defineMessages } from 'react-intl';

export default defineMessages({
  issuing: {
    id: 'VendorStatus.issuing',
    defaultMessage: 'Issuing',
  },
  failed: {
    id: 'VendorStatus.failed',
    defaultMessage: 'Failed',
  },
  superseded: {
    id: 'VendorStatus.superseded',
    defaultMessage: 'Superseded',
  },
  locked: {
    id: 'VendorStatus.locked',
    defaultMessage: 'Locked',
  },
  issued: {
    id: 'VendorStatus.issued',
    defaultMessage: 'Issued',
  },
  exchanged: {
    id: 'VendorStatus.exchanged',
    defaultMessage: 'Exchanged',
  },
  partiallycancelled: {
    id: 'VendorStatus.partiallyCancelled',
    defaultMessage: 'Partially Cancelled',
  },
  cancelling: {
    id: 'VendorStatus.cancelling',
    defaultMessage: 'Cancelling',
  },
  cancelled: {
    id: 'VendorStatus.cancelled',
    defaultMessage: 'Cancelled',
  },
  unknown: {
    id: 'VendorStatus.unknown',
    defaultMessage: 'Unknown',
  },
  pending: {
    id: 'VendorStatus.pending',
    defaultMessage: 'Pending',
  },
  issuefailed: {
    id: 'VendorStatus.issueFailed',
    defaultMessage: 'Issue Failed',
  },
});
