export const NOTEFORM_ERROR_REQUIREDFIELD = 'Required text';
export const NOTEFORM_ERROR_LARGETEXT = 'The note must not be longer than 500 characters';

export type ValidateTextAreaFieldErrors = { _error?: string };

interface Values {
  note?: string;
}

const validateTextAreaField = (values: Values) => {
  const errors = {} as ValidateTextAreaFieldErrors;

  if (!values.note) {
    errors._error = NOTEFORM_ERROR_REQUIREDFIELD;
  }
  if (values.note && values.note.length > 500) {
    errors._error = NOTEFORM_ERROR_LARGETEXT;
  }

  return errors;
};

export default validateTextAreaField;
