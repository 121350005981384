import { defineMessages } from 'react-intl';

export default defineMessages({
  createReplaceTickets: {
    id: 'ReplaceBookingForm.createReplaceTickets',
    defaultMessage: 'Create replacement tickets',
  },
  booking: {
    id: 'ReplaceBookingForm.booking',
    defaultMessage: 'Booking',
  },
  selectBooking: {
    id: 'ReplaceBookingForm.selectBooking',
    defaultMessage: 'Select booking',
  },
  bookingMustBeSelected: {
    id: 'ReplaceBookingForm.bookingMustBeSelected',
    defaultMessage: 'A booking must be selected',
  },
  bookingLabel: {
    id: 'ReplaceBookingForm.bookingLabel',
    defaultMessage: 'Booking {bookingIndex} - {from} to {to} - {departureDay}',
  },
  replacementReason: {
    id: 'ReplaceBookingForm.replacementReason',
    defaultMessage: 'Replacement reason',
  },
  selectReason: {
    id: 'ReplaceBookingForm.selectReason',
    defaultMessage: 'Select reason',
  },
  reasonMustBeSelected: {
    id: 'ReplaceBookingForm.reasonMustBeSelected',
    defaultMessage: 'A reason must be selected',
  },
  replaceBooking: {
    id: 'ReplaceBookingForm.replaceBooking',
    defaultMessage: 'Replacement booking',
  },
  notEligible: {
    id: 'ReplaceBookingForm.notEligible',
    defaultMessage: 'Sorry, this booking is not eligible for replacement.',
  },
});
