import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';

import ProductType from '@contactcentre-web/redux-common/types/ProductType';
import MidDot from '@contactcentre-web/common/MidDot/MidDot';
import VendorStatus from '@contactcentre-web/common/VendorStatus/VendorStatus';
import FormattedDateInTz from '@contactcentre-web/common/FormattedDateInTz';

import styles from './styles';
import messages from './messages';

const BookingSummaryHeader = ({
  fulfilmentStatus,
  number,
  journeys,
  type,
  localAreaValidity,
  railcard,
}) => {
  const from = journeys[0]?.origin;
  // if one journey -> single
  // if 2 journeys -> return
  // so to is always journeys[0] destination
  const to = journeys[0]?.destination;
  const departureDay = journeys[0]?.departAt;

  return (
    <div className={css(styles.bookingHeader)}>
      <span className={css(styles.bookingNumber)}>
        <FormattedMessage {...messages.booking} values={{ number }} />
      </span>
      <MidDot />
      <VendorStatus status={fulfilmentStatus} />
      <MidDot />
      {type === ProductType.Railcard ? (
        railcard && (
          <>
            <FormattedMessage
              {...messages.railcardBookingInformation}
              values={{
                name: railcard.name,
              }}
            />
            {', '}
            <FormattedMessage
              {...messages.railcardValidUntil}
              values={{
                date: (
                  <span data-testid="expiry-date">
                    <FormattedDateInTz
                      value={railcard.validity.to}
                      year="numeric"
                      month="short"
                      day="2-digit"
                    />
                  </span>
                ),
              }}
            />
          </>
        )
      ) : (
        <>
          <FormattedMessage
            {...messages.bookingInformation}
            values={{
              isTravelcardProduct: type === ProductType.Travelcard,
              from,
              to,
              localAreaValidity,
            }}
          />
          {type === ProductType.TravelProduct && (
            <span data-testid="departure-date">
              {', '}
              <FormattedDateInTz value={departureDay} month="short" day="2-digit" />
            </span>
          )}
        </>
      )}
    </div>
  );
};

BookingSummaryHeader.propTypes = {
  fulfilmentStatus: PropTypes.string,
  journeys: PropTypes.arrayOf(Object),
  number: PropTypes.number,
  type: PropTypes.string,
  localAreaValidity: PropTypes.string,
  railcard: PropTypes.shape({
    name: PropTypes.string,
    validity: PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string,
    }),
  }),
};

export default BookingSummaryHeader;
