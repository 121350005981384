import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@contactcentre-web/common/Button';

import styles from '../../styles';
import messages from '../../messages';
export interface RevokeCustomersButtonProps {
  disabled: boolean;
  loading: boolean;
}

const RevokeCustomersButton = ({ disabled, loading }: RevokeCustomersButtonProps) => (
  <Button
    type="submit"
    variant="primary"
    size="small"
    testId="revokeCustomersButton"
    loading={loading}
    disabled={disabled}
    styleSheet={styles.searchButton}
  >
    <FormattedMessage {...messages.revokeCustomersButton} />
  </Button>
);

export default RevokeCustomersButton;
