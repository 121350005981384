import { StyleSheet } from 'aphrodite/no-important';
import { palettes, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  headerBar: {
    backgroundColor: palettes.dp_brand_primary_core,
    height: '16px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: spacers.dp_s80,
  },
  selectLanguageForm: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: spacers.dp_s48,
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  selectContainer: {
    width: '250px',
  },
  continueButton: {
    marginTop: '28px',
    marginLeft: spacers.dp_s16,
  },
  languageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  languageName: {
    marginLeft: spacers.dp_s12,
  },
});
