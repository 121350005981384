import { StyleSheet } from 'aphrodite/no-important';
import { borderRadius, palettes, spacers } from '@trainline/depot-web/css/themes/trainline';

const column = {
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacers.dp_s12,
} as const;

export default StyleSheet.create({
  container: {
    border: `1px solid ${palettes.dp_app_border_base}`,
    background: palettes.dp_app_backdrop_base,
    borderRadius: borderRadius.dp_r8,
    padding: spacers.dp_s24,
    marginBottom: spacers.dp_s24,
    position: 'relative',
  },

  approved: {
    opacity: 0,
    transition: 'opacity 500ms ease-in-out, margin-bottom 500ms ease-in-out',
    pointerEvents: 'none',
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: spacers.dp_s24,
    alignItems: 'flex-start',
  },
  claimMeta: {
    textAlign: 'right',
    whiteSpace: 'nowrap',
    display: 'flex',
  },
  claimCreatedBy: {
    display: 'flex',
    flexDirection: 'column',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1.5fr 1fr auto',
    columnGap: spacers.dp_s48,
  },
  columnLeft: {
    ...column,
  },
  columnCenter: {
    ':nth-child(1n) > p': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  columnRight: {
    ...column,
  },
  danger: {
    color: palettes.dp_app_text_negative,
  },
  badge: {
    marginLeft: spacers.dp_s12,
    display: 'inline',
    alignSelf: 'center',
  },
});
