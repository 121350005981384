import { StyleSheet } from 'aphrodite/no-important';
import { fonts, typography, spacers } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  tablePad: {
    paddingBottom: spacers.dp_s4,
  },
  padH: {
    padding: `0 ${spacers.dp_s16}`,
  },
  ticketsTable: {
    borderCollapse: 'collapse',
    tableLayout: 'fixed',
    width: '100%',
    marginBottom: spacers.dp_s4,
  },
  itemCell: {
    paddingRight: spacers.dp_s24,
    textAlign: 'left',
    width: '200px',
  },
  return: {
    color: colors.gray.alpha54,
    fontSize: spacers.dp_s16,
    fontWeight: fonts.dp_font_weight_regular,
    textAlign: 'left',
  },
  productPriceCell: {
    color: colors.gray.alpha58,
    fontSize: typography.dp_small_font_size_m,
    lineHeight: typography.dp_small_font_size_m,
    textAlign: 'right',
    width: '100px',
  },
  pricePaidCell: {
    fontWeight: fonts.dp_font_weight_bold,
    textAlign: 'right',
    width: '100px',
  },
  detailPad: {
    paddingLeft: spacers.dp_s16,
  },
  cojTitleCell: {
    color: colors.gray.alpha54,
    fontSize: typography.dp_mini_font_size_m,
    fontWeight: fonts.dp_font_weight_regular,
    textAlign: 'left',
  },
  cojOriginalBookingCell: {
    color: colors.gray.alpha54,
    fontSize: typography.dp_mini_font_size_m,
    fontWeight: fonts.dp_font_weight_regular,
    textAlign: 'right',
  },
});
