import { defineMessages } from 'react-intl';

export default defineMessages({
  journeyDetails: {
    id: 'MostRecentBooking.journeyDetails',
    defaultMessage: 'MOST RECENT BOOKING {date}',
  },
  orderReference: {
    id: 'MostRecentBooking.orderReference',
    defaultMessage: 'Order ID:',
  },

  noPreviousBookings: {
    id: 'MostRecentBooking.noPreviousBookings',
    defaultMessage: 'This customer has no previous bookings.',
  },

  error: {
    id: 'MostRecentBooking.error',
    defaultMessage: 'Sorry, the latest booking could not be fetched.',
  },

  errorWithOrderReference: {
    id: 'MostRecentBooking.errorWithOrderReference',
    defaultMessage:
      'Sorry, the latest booking for the order {orderReference} could not be fetched.',
  },
});
