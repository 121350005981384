import { css } from 'aphrodite/no-important';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Expandable from '@contactcentre-web/common/Expandable';
import { SavedCard as SavedCardType } from '@contactcentre-web/hooks/api/useCustomerPassengers';

import SavedCard from '../SavedCard/SavedCard';

import messages from './messages';
import styles from './styles';

interface Props {
  savedCards: Array<SavedCardType>;
  passengerId: string;
}

const SavedCardsList = ({ savedCards, passengerId }: Props) => {
  const [openTab, setOpenTab] = useState(-1);

  React.useEffect(() => {
    if (savedCards.length === 1) {
      setOpenTab(0);
    }
  }, [savedCards]);

  const handleCardsClick = (key: number) => {
    if (openTab === key) {
      setOpenTab(-1);
    } else {
      setOpenTab(key);
    }
  };

  return (
    <>
      <div className={css(styles.title)} data-test="saved-cards-list-title">
        <FormattedMessage {...messages.savedCardTitle} />
      </div>
      <div className={css(styles.subtitle)} data-test="saved-cards-list-subtitle">
        <FormattedMessage {...messages.savedCardSubtitle} />
      </div>
      <div className={css(styles.cardWrapper)}>
        {savedCards.map((card, key) => (
          <Expandable.Wrapper key={key} styleSheet={styles.card}>
            <Expandable.Anchor onClick={() => handleCardsClick(key)} isOpen={key === openTab}>
              {card.name}
            </Expandable.Anchor>
            <Expandable.Content isOpen={key === openTab}>
              <SavedCard savedCard={card} passengerId={passengerId} />
            </Expandable.Content>
          </Expandable.Wrapper>
        ))}
      </div>
    </>
  );
};

export default SavedCardsList;
