import { defineMessages } from 'react-intl';

export default defineMessages({
  payment: {
    id: 'Refund.RefundPayment.payment',
    defaultMessage: '{text}',
  },
  payments: {
    id: 'Refund.RefundPayment.payments',
    defaultMessage: 'Payment {index}: {text}',
  },
});
