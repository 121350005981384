import {
  borderRadius,
  fonts,
  palettes,
  spacers,
  typography,
} from '@trainline/depot-web/css/themes/trainline';
import { StyleSheet } from 'aphrodite/no-important';

export const selectStyle = {
  borderRadius: 0,
  borderWidth: '0 0 0 1px',
};

export default StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: spacers.dp_s16,
    width: '100%',
  },
  button: {
    margin: `${spacers.dp_s24} 0`,
    padding: spacers.dp_s16,
    width: '100%',
  },
  labelColor: {
    color: palettes.dp_app_text_inverted,
    width: '100%',
  },
  inputContainer: {
    color: palettes.dp_app_text_base,
    background: palettes.dp_app_text_inverted,
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    border: `solid 1px ${palettes.dp_app_border_base}`,
    borderRadius: borderRadius.dp_r4,
  },
  inputCurrency: {
    border: `none`,
  },
  currencyText: {
    fontSize: typography.dp_body_font_size,
    fontWeight: fonts.dp_font_weight_bold,
    padding: spacers.dp_s8,
  },
});
