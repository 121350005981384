import { defineMessages } from 'react-intl';

export default defineMessages({
  FlexiSeasonReset: {
    id: 'FlexiSeason.FlexiSeasonReset',
    defaultMessage: 'Ticket reset',
  },
  FlexiSeasonCancel: {
    id: 'FlexiSeason.FlexiSeasonCancel',
    defaultMessage: 'Ticket cancelled',
  },
  booking: {
    id: 'FlexiSeason.booking',
    defaultMessage: 'Booking',
  },
  requestedBy: {
    id: 'FlexiSeason.requestedBy',
    defaultMessage: 'Requested By',
  },
});
