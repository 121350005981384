import React from 'react';

import CompensationApprovalsContainer from '@contactcentre-web/compensation-approvals/CompensationApprovals';
import PageContainer from '@contactcentre-web/common/PageContainer';

import styles from './styles';

const CompensationApprovalsPage = () => (
  <PageContainer styleSheet={styles.container}>
    <CompensationApprovalsContainer />
  </PageContainer>
);

export default CompensationApprovalsPage;
