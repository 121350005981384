import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'FirstUser.title',
    defaultMessage: 'The following team lead has been created for {managedGroupName}',
  },
  username: {
    id: 'FirstUser.username',
    defaultMessage: 'Username',
  },
  pwd: {
    id: 'FirstUser.pwd',
    defaultMessage: 'Password',
  },
  note: {
    id: 'FirstUser.note',
    defaultMessage:
      'Take note of the username and password. If forgotten, you’ll need to raise a support ticket.',
  },
});
