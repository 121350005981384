import { StyleSheet } from 'aphrodite/no-important';
import { typography, palettes, spacers, fonts } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  bookingHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  col: {
    flexGrow: 1,

    ':first-child': {
      flexGrow: 2,
    },

    ':last-child': {
      textAlign: 'right',
    },
  },
  title: {
    fontSize: typography.dp_small_font_size_m,
    lineHeight: typography.dp_small_line_height,
    color: palettes.dp_brand_neutral_mid,
    textTransform: 'uppercase',
    marginBottom: spacers.dp_s8,
  },
  passengerList: {
    listStyleType: 'none',
  },
  transactionPrice: {
    fontSize: typography.dp_body_font_size,
    lineHeight: typography.dp_body_line_height,
    fontWeight: fonts.dp_font_weight_bold,
  },
  inventoryPrice: {
    fontStyle: 'italic',
    color: palettes.dp_brand_neutral_mid,
  },
});
