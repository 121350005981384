import React, { Dispatch, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '@contactcentre-web/common/Button';
import { Modal, ModalHeader, ModalTitle } from '@contactcentre-web/common/Modal';
import Action from '@contactcentre-web/redux-common/types/Action';
import type State from '@contactcentre-web/redux-common/types/State';

import EmailSearch from './components/EmailSearch/EmailSearch';
import messages from './messages';
import { actions } from './module';
import * as selectors from './selectors';

interface StateProps {
  moved: boolean;
  newCustomerId: string | undefined;
}
interface DispatchProps {
  reset: () => void;
}
interface Props extends StateProps, DispatchProps {
  orderReference: string;
}

export const MoveOrder = ({ moved, newCustomerId, orderReference, reset }: Props) => {
  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const resetRef = React.useRef(reset);

  React.useEffect(() => {
    if (moved) {
      setVisible(false);
      resetRef.current();
      history.push(`/customers/${newCustomerId}/bookings/${orderReference}?moved`);
    }
  }, [resetRef, moved, newCustomerId, orderReference]);

  return (
    <>
      <Button variant="ghost" onClick={() => setVisible(true)}>
        <FormattedMessage {...messages.action} />
      </Button>
      {visible && (
        <Modal testId="moveOrderModal" onClose={() => setVisible(false)}>
          <ModalHeader>
            <ModalTitle>
              <FormattedMessage {...messages.modal_title} />
            </ModalTitle>
          </ModalHeader>
          <EmailSearch orderReference={orderReference} />
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state: State) => ({
  moved: selectors.hasMovedSuccessfully(state),
  newCustomerId: selectors.newCustomerId(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  reset: () => dispatch(actions.reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MoveOrder);
