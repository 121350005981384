import { StyleSheet } from 'aphrodite/no-important';
import { spacers, borderRadius, palettes } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    borderRadius: borderRadius.dp_r4,
    boxShadow: `0 4px 8px 0 ${palettes.dp_comp_box_shadow}`,
    backgroundColor: palettes.dp_app_backdrop_base,
    padding: `${spacers.dp_s24} ${spacers.dp_s48}`,
  },
  compensationsContainer: {
    padding: `${spacers.dp_s24} 0`,
  },
  alert: {
    marginBottom: spacers.dp_s16,
  },
  buttonBack: {
    display: 'flex',
    color: palettes.dp_app_info_dark,
    borderRadius: borderRadius.dp_r12,
    backgroundColor: palettes.dp_app_info_lighter,
    padding: `${spacers.dp_s8} ${spacers.dp_s24}`,
    marginBottom: spacers.dp_s32,
  },
  buttonLabel: {
    paddingLeft: spacers.dp_s8,
  },
});
