import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import OriginToDestination from '@contactcentre-web/common/OriginToDestination/OriginToDestination';
import { ProductType } from '@contactcentre-web/redux-common/types/ProductType';

import type { Booking } from '../../module';

import messages from './messages';

interface Props {
  bookings?: Array<Booking>;
}

const BookingDetail: FunctionComponent<Props> = ({ bookings }) => (
  <>
    {bookings && bookings.length > 0 ? (
      bookings.map(
        ({
          isReturn,
          origin,
          destination,
          bookingId,
          transactionReference,
          type,
          localAreaValidity,
        }) => (
          <div key={bookingId}>
            {type === ProductType.Travelcard
              ? localAreaValidity
              : origin &&
                destination && (
                  <>
                    <OriginToDestination
                      isReturn={isReturn}
                      origin={origin}
                      destination={destination}
                    />
                    {transactionReference && ` (${transactionReference})`}
                  </>
                )}
          </div>
        )
      )
    ) : (
      <FormattedMessage {...messages.unkownBooking} />
    )}
  </>
);

export default BookingDetail;
