import { useQuery } from 'react-query';

import request from '@contactcentre-web/utils/request';

type Sites = {
  items: { name: string }[];
};

export const useSites = () =>
  useQuery('sites', () => request<Sites>('/authapi/sites/bycurrentmanagedgroup'), {
    select: ({ items }) => items,
  });
