import { defineMessages } from 'react-intl';

export default defineMessages({
  compensationProcessed: {
    id: 'Compensation.compensationProcessed',
    defaultMessage: 'Compensation',
  },
  virtualTerminalCreditProcessed: {
    id: 'Compensation.virtualTerminalCreditProcessed',
    defaultMessage: 'Virtual Terminal Credit',
  },
  virtualTerminalDebitProcessed: {
    id: 'Compensation.virtualTerminalDebitProcessed',
    defaultMessage: 'Virtual Terminal Debit',
  },
  status: {
    id: 'Compensation.status',
    defaultMessage: 'Refund status',
  },
  requestedBy: {
    id: 'Compensation.requestedBy',
    defaultMessage: 'Requested by: {agent}',
  },
  completedBy: {
    id: 'Compensation.completedBy',
    defaultMessage: '{name} on {day} at {time}',
  },
  requested: {
    id: 'Compensation.requested',
    defaultMessage: 'Requested',
  },
  pending: {
    id: 'Compensation.pending',
    defaultMessage: 'Pending',
  },
  approved: {
    id: 'Compensation.approved',
    defaultMessage: 'Approved',
  },
  rejected: {
    id: 'Compensation.rejected',
    defaultMessage: 'Rejected',
  },
  unableRetrieveDetails: {
    id: 'Compensation.unableRetrieveDetails',
    defaultMessage: 'Unable to retrieve all details',
  },
  payment: {
    id: 'Compensation.payment',
    defaultMessage: 'Payment',
  },
  paymentHolderName: {
    id: 'Compensation.paymentHolderName',
    defaultMessage: 'Payment holder name',
  },
  reasonCode: {
    id: 'Compensation.reasonCode',
    defaultMessage: 'Reason code',
  },
  requestedAmount: {
    id: 'Compensation.requestedAmount',
    defaultMessage: 'Requested amount',
  },
  refundedAmount: {
    id: 'Compensation.refundedAmount',
    defaultMessage: 'Refunded',
  },
  completedByLabel: {
    id: 'Compensation.completedByLabel',
    defaultMessage: 'Completed by',
  },
  requestNote: {
    id: 'Compensation.requestNote',
    defaultMessage: 'Additional comment from request',
  },
  completionNote: {
    id: 'Compensation.completionNote',
    defaultMessage: 'Additional comment from completion',
  },
});
