import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import Select from '@contactcentre-web/common/Select';

import styles from './styles';
import messages from './messages';

const mapOptions = ({ id, name }) => ({ value: id, label: name });

export const CrmReasonComponent = ({ value, onChange, reasonCodes, disabled }) => {
  const [reasonLevel1, setReasonLevel1] = React.useState('');
  const [reasonLevel2, setReasonLevel2] = React.useState('');
  const [crmReasonOptions, setCrmReasonOptions] = React.useState([]);

  React.useEffect(() => {
    setCrmReasonOptions(
      reasonCodes &&
        reasonCodes.filter((reason) => reason.parentId === reasonLevel1).map(mapOptions)
    );
    setReasonLevel2('');
  }, [reasonLevel1, reasonCodes]);

  React.useEffect(() => {
    if (value !== reasonLevel2) {
      onChange(reasonLevel2);
    }
  }, [onChange, reasonLevel2, value]);

  React.useEffect(() => {
    const l2Item = reasonCodes && reasonCodes.find(({ id }) => value === id);
    if (l2Item) {
      const l1Item = reasonCodes && reasonCodes.find(({ id }) => id === l2Item.parentId);
      setReasonLevel1(l1Item ? l1Item.id : '');
      setReasonLevel2(l2Item.id || '');
    } else {
      setReasonLevel1('');
      setReasonLevel2('');
    }
  }, [value, reasonCodes]);

  const parentCrmReasonOptions =
    reasonCodes && reasonCodes.filter((reason) => reason.parentId === null).map(mapOptions);

  return (
    <>
      <Select
        testId="parentCrmReason"
        placeholder={<FormattedMessage {...messages.selectReason} />}
        options={parentCrmReasonOptions}
        onChange={setReasonLevel1}
        value={reasonLevel1}
        containerStyle={styles.select}
        disabled={disabled}
        selectDirection="top"
      />
      <Select
        testId="crmReason"
        placeholder={<FormattedMessage {...messages.selectReason} />}
        options={crmReasonOptions}
        disabled={!reasonLevel1 || disabled}
        value={reasonLevel2}
        onChange={setReasonLevel2}
        selectDirection="top"
      />
    </>
  );
};

CrmReasonComponent.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  reasonCodes: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
};

const CrmReasonWrapper = ({
  input: { onChange, value },
  meta: { error, submitting, touched },
  ...others
}) => (
  <>
    <CrmReasonComponent {...others} onChange={onChange} value={value} disabled={submitting} />
    {error && messages[error] && touched ? (
      <FormattedMessage {...messages[error]}>
        {(text) => <span className={css(styles.error)}>{text}</span>}
      </FormattedMessage>
    ) : (
      <span className={css(styles.noError)}>&nbsp;</span>
    )}
  </>
);
CrmReasonWrapper.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.any,
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    submitting: PropTypes.bool,
    touched: PropTypes.bool,
  }),
};

const CrmReasonField = ({ name, ...others }) => (
  <Field name={name} {...others} component={CrmReasonWrapper} />
);

CrmReasonField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default CrmReasonField;
