import React, { FunctionComponent, useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import OriginToDestination from '@contactcentre-web/common/OriginToDestination/OriginToDestination';
import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency';
import MoneyField from '@contactcentre-web/common/MoneyField';
import StackedLabel from '@contactcentre-web/common/StackedLabel';
import Button from '@contactcentre-web/common/Button';
import TextField from '@contactcentre-web/common/TextField';
import CheckboxListField from '@contactcentre-web/common/CheckboxListField';
import { canApproveCompensations } from '@contactcentre-web/authentication/redux/selectors';
import { getProducts } from '@contactcentre-web/redux-common/selectors/products';
import RefundReason from '@contactcentre-web/refunds/common/RefundReason';
import { pushToDataLayer } from '@contactcentre-web/utils/tracker';
import type State from '@contactcentre-web/redux-common/types/State';
import type Price from '@contactcentre-web/redux-common/types/Price';
import { CompensationHistory, VendorRegion } from '@contactcentre-web/hooks/api/useOrderHistory';
import type { Product } from '@contactcentre-web/redux-common/types/Product';
import {
  isLocalAreaProduct,
  isRailcardProduct,
  isSeasonProduct,
  isTravelProduct,
} from '@contactcentre-web/refunds/utils';

import CompensationRequestConfirmation from '../CompensationRequestConfirmation/CompensationRequestConfirmation';
import selectors from '../../selectors';
import { FORM_ID } from '../../module';
import type { PendingCompensation, RehydratedReasonCode } from '../../reducer';
import type { RequestCompensationPayload, EditCompensationRequestPayload } from '../../sagas';

import styles from './styles';
import messages from './messages';

interface CompensationData {
  products: Array<string>;
  amount: string;
  note: string;
  reasonCode: string;
}

export interface Props {
  amount: string;
  orderAmount: Price | Record<string, never>;
  compensationReasons: Array<RehydratedReasonCode>;
  paymentType?: string;
  disabled: boolean;
  canConfirmCompensation: boolean;
  compensation?: PendingCompensation;
  previousCompensation?: CompensationHistory;
  products: Array<Product>;
  hasRefunds: boolean;
  requestCompensation: (data: RequestCompensationPayload | EditCompensationRequestPayload) => void;
}

export interface Warning {
  type:
    | 'amountWarning'
    | 'previousRefundWarning'
    | 'approveWarning'
    | 'previousCompensationWarning';
  amount: number;
  currencyCode: string;
  requestedDate?: Date;
  orderAmount?: number;
}

const getWarning = (
  canConfirmCompensation: boolean,
  hasRefunds: boolean,
  compensationAmount: number,
  orderAmount: number,
  currencyCode: string,
  previousCompensation?: CompensationHistory
): Warning | null => {
  if (previousCompensation) {
    return {
      ...previousCompensation.payload.requestedAmount,
      requestedDate: previousCompensation.date,
      type: 'previousCompensationWarning',
    };
  }

  if (hasRefunds) {
    return {
      amount: compensationAmount,
      currencyCode,
      type: 'previousRefundWarning',
    };
  }

  if (compensationAmount > orderAmount) {
    return {
      amount: compensationAmount,
      orderAmount,
      currencyCode,
      type: 'amountWarning',
    };
  }

  if (canConfirmCompensation) {
    return {
      amount: compensationAmount,
      currencyCode,
      type: 'approveWarning',
    };
  }

  return null;
};

export const CompensationForm: FunctionComponent<
  Props & InjectedFormProps<CompensationData, Props>
> = ({
  amount,
  orderAmount: { amount: orderAmount, currencyCode },
  compensationReasons,
  paymentType,
  initialize,
  handleSubmit,
  submitting,
  invalid,
  disabled,
  canConfirmCompensation,
  products,
  compensation,
  previousCompensation,
  hasRefunds,
  requestCompensation,
}) => {
  const [visible, setVisible] = useState(false);
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (compensation) {
      initialize({
        amount: String(compensation.value.amount),
        reasonCode: compensation.reasonCodeId,
        note: compensation.note,
        products: [],
      });
    } else {
      initialize({ products: products.map(({ id }) => id) });
    }
  }, [compensation]);

  const amountNum = parseFloat(amount || '0');
  const warning = getWarning(
    canConfirmCompensation,
    hasRefunds,
    amountNum,
    orderAmount,
    currencyCode,
    previousCompensation
  );

  const mapProductToCheckbox = (product: Product) => {
    if (isTravelProduct(product)) {
      return {
        value: product.id,
        label: (
          <>
            <OriginToDestination
              isReturn={product.isReturn}
              origin={product.origin}
              destination={product.destination}
            />
            {product.vendorRegion === VendorRegion.EU && (
              <span className={css(styles.pnr)}>
                <small>PNR</small>
                <strong>{product.transactionReference}</strong>
              </span>
            )}
          </>
        ),
      };
    }

    if (isRailcardProduct(product)) {
      return {
        value: product.id,
        label: formatMessage(messages.railcardLabel, {
          name: product.name,
          railcardNumber: product.railcardNumber,
        }),
      };
    }

    if (isSeasonProduct(product)) {
      return {
        value: product.id,
        label: (
          <>
            <OriginToDestination
              isReturn
              origin={product.journey.origin}
              destination={product.journey.destination}
            />
            {product.vendorRegion === VendorRegion.EU && (
              <span className={css(styles.pnr)}>
                <small>PNR</small>
                <strong>{product.transactionReference}</strong>
              </span>
            )}
          </>
        ),
      };
    }

    if (isLocalAreaProduct(product)) {
      return {
        value: product.id,
        label: product.localAreaValidity,
      };
    }

    return {
      value: product.id,
      label: formatMessage(messages.unknownLabel, {
        productType: product.type,
        id: product.id,
      }),
    };
  };

  const onCheckboxChange = (value = []) => {
    pushToDataLayer({
      event: 'ga_compensation',
      ga_event: {
        category: 'compensation',
        action: value.length ? 'checked' : 'unchecked',
        value: 0,
        nonInteration: 0,
      },
    });
    pushToDataLayer({
      event: 'ga4_compensation',
      event_name: 'compensation',
      event_params: {
        checkbox_status: value.length ? 'checked' : 'unchecked',
      },
    });
  };

  const onRequestCompensation = ({
    amount,
    reasonCode,
    note,
    products: selectedProducts,
  }: CompensationData) =>
    requestCompensation({
      value: { amount: parseFloat(amount), currencyCode },
      reasonCodeId: reasonCode,
      note: note.trim(),
      products: !compensation
        ? selectedProducts.map((id) => ({
            productId: id,
            vendor: products.find(({ id: bId }) => id === bId)?.vendor,
          }))
        : undefined,
    });

  return (
    <form
      id={FORM_ID}
      onSubmit={handleSubmit((values) => {
        if (!invalid && warning) {
          setVisible(true);
        } else {
          onRequestCompensation(values);
        }
      })}
      data-testid="compensationForm"
    >
      {visible && (
        <CompensationRequestConfirmation
          isProcessing={false}
          onProcess={handleSubmit((values) => {
            setVisible(false);
            onRequestCompensation(values);
          })}
          onClose={() => setVisible(false)}
          warning={warning}
        />
      )}
      <section className={css(styles.formContainer)}>
        {!compensation && (
          <div className={css(styles.row)}>
            <FormattedMessage {...messages.productsLabel} />
            <CheckboxListField
              name="products"
              options={products.map(mapProductToCheckbox)}
              messages={messages}
              disabled={disabled}
              onChange={onCheckboxChange}
            />
          </div>
        )}
        <div>
          <FormattedMessage {...messages.amount} />
          <MoneyField
            name="amount"
            currencyText={currencyCode}
            messages={messages}
            disabled={disabled}
          />
        </div>
        <div>
          <RefundReason reasons={compensationReasons} disabled={disabled} />
        </div>
        <div>
          <FormattedMessage {...messages.noteLabel} />
          <TextField
            name="note"
            type="multiline-text"
            maxLength={1000}
            rows={4}
            messages={messages}
            disabled={disabled}
          />
        </div>
        <div>
          <StackedLabel
            label={<FormattedMessage {...messages.totalToRefund} />}
            styleSheet={{
              label: {
                ...styles.label,
              },
              value: styles.amount,
              container: styles.paymentLabelContainer,
            }}
          >
            <FormattedCurrency amount={amountNum < 0 ? 0 : amountNum} currencyCode={currencyCode} />
          </StackedLabel>
          <StackedLabel
            label={<FormattedMessage {...messages.paymentDetails} />}
            styleSheet={{
              label: {
                ...styles.label,
                ...styles.gray,
              },
              container: styles.paymentLabelContainer,
            }}
          >
            {paymentType}
          </StackedLabel>
        </div>
      </section>
      <div className={css(styles.buttonContainer)}>
        <Button
          variant="primary"
          size="large"
          testId="compensationSubmit"
          type="submit"
          loading={submitting}
          disabled={submitting || disabled}
        >
          <FormattedMessage
            {...messages[canConfirmCompensation ? 'processButton' : 'submitRequestButton']}
          />
        </Button>
      </div>
    </form>
  );
};

const mapStateToProps = (state: State) => ({
  amount: formValueSelector(FORM_ID)(state, 'amount') as string,
  reasonCode: formValueSelector(FORM_ID)(state, 'reasonCode') as string,
  canConfirmCompensation: canApproveCompensations(state),
  products: getProducts(state),
  hasRefunds: selectors.hasRefunds(state),
});

export const validate = (
  { amount, reasonCode, note, products }: CompensationData,
  { compensation }: Props
) => ({
  amount: !amount || parseFloat(amount) <= 0 ? 'invalidAmount' : undefined,
  reasonCode: !reasonCode ? 'required' : undefined,
  note:
    !note || note.trim() === ''
      ? 'note_required'
      : note.trim().length > 1000
      ? 'noteLengthExceeded'
      : undefined,
  products: !compensation && (!products || products.length === 0) ? 'products_required' : undefined,
});

export default connect(mapStateToProps)(
  reduxForm<CompensationData, Props>({
    form: FORM_ID,
    validate,
  })(CompensationForm)
);
