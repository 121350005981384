import { StyleSheet } from 'aphrodite/no-important';
import { palettes, typography, fonts } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  statusIssued: {
    color: palettes.dp_app_text_positive,
  },
  statusIssuing: {
    color: palettes.dp_app_text_warning,
  },
  statusOthers: {
    color: palettes.dp_app_text_negative,
  },
  status: {
    fontSize: typography.dp_small_font_size_m,
    fontWeight: fonts.dp_font_weight_bold,
  },
  deliveryAddress: {
    color: palettes.dp_app_info_dark,
    fontWeight: fonts.dp_font_weight_bold,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
});
