import { StyleSheet } from 'aphrodite/no-important';
import { spacers, palettes, borderRadius } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    borderRadius: borderRadius.dp_r4,
    boxShadow: `0px 0px 4px ${palettes.dp_comp_box_shadow}, 0px 2px 6px ${palettes.dp_comp_box_shadow}`,
    marginTop: spacers.dp_s32,
    overflow: 'hidden',
  },
  renewalAlertContainer: {
    backgroundColor: palettes.dp_app_backdrop_base,
    padding: `0 ${spacers.dp_s64} ${spacers.dp_s8} ${spacers.dp_s64}`,
  },
});
