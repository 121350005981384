import { css } from 'aphrodite/no-important';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { PaymentDetail } from '@contactcentre-web/hooks/api/useVirtualTerminal';
import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency';

import messages from './messages';
import styles from './styles';

interface Props {
  paymentDetails: PaymentDetail;
}
const FormattedPropertyValue = (msg: string) => <strong>{msg}</strong>;

function PaymentDetails({ paymentDetails }: Props) {
  return (
    <div className={css(styles.paymentDetails)}>
      <div data-testid="accountHolder">
        <FormattedMessage
          {...messages.accountHolder}
          values={{
            value: paymentDetails?.accountHolder,
            strong: FormattedPropertyValue,
          }}
        />
      </div>
      <div data-testid="totalTransaction">
        <FormattedMessage
          {...messages.totalTransaction}
          values={{
            value: (
              <FormattedCurrency
                amount={paymentDetails?.totalTransaction?.amount || 0}
                currencyCode={paymentDetails?.totalTransaction?.currencyCode}
              />
            ),
            strong: FormattedPropertyValue,
          }}
        />{' '}
      </div>
      <div data-testid="paymentMethod">
        <FormattedMessage
          {...messages.paymentMethod}
          values={{
            value: paymentDetails?.paymentMethodDetails,
            strong: FormattedPropertyValue,
          }}
        />
      </div>
    </div>
  );
}

export default PaymentDetails;
