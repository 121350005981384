import { StyleSheet } from 'aphrodite/no-important';
import {
  borderRadius,
  fonts,
  spacers,
  typography,
} from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

// Non trainline colors
const lightBlue = '#f1f7ff';
const darkBlue = '#28529b';

export default StyleSheet.create({
  customerNotesItem: {
    margin: spacers.dp_s32,
  },
  container: {
    background: lightBlue,
    padding: spacers.dp_s24,
    borderRadius: borderRadius.dp_r4,
  },
  header: {
    display: 'flex',
    width: '100%',
    marginBottom: spacers.dp_s8,
    fontSize: typography.dp_small_font_size_m,
    lineHeight: typography.dp_small_line_height,
    color: darkBlue,
  },
  name: {
    fontWeight: fonts.dp_font_weight_bold,
  },
  notesheader: {
    fontWeight: fonts.dp_font_weight_bold,
    color: colors.black,
  },
  username: {
    fontWeight: fonts.dp_font_weight_bold,
    marginLeft: spacers.dp_s4,
  },
  location: {
    marginLeft: spacers.dp_s4,
    ':before': {
      content: '" · "',
      margin: `0 ${spacers.dp_s4}`,
    },
  },
  date: {
    marginTop: spacers.dp_s4,
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    textAlign: 'right',
    color: colors.gray.alpha54,
  },
  message: {
    fontSize: typography.dp_small_font_size_m,
    lineHeight: typography.dp_small_line_height,
    wordBreak: 'break-word',
  },
});
