import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';

import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency';
import Price from '@contactcentre-web/redux-common/types/Price';

import messages from './messages';
import styles from './styles';

interface Props {
  totalAmount: Price;
}

const TotalAmountBeforeDiscount = ({ totalAmount }: Props) => (
  <tr>
    <td colSpan={4}>
      <table className={css(styles.table)}>
        <tbody>
          <tr>
            <td className={css(styles.header)}>
              <FormattedMessage {...messages.totalBeforeDiscount} />
            </td>
            <td className={css(styles.totalAmount)}>
              <FormattedCurrency
                amount={totalAmount.amount || 0}
                currency={totalAmount.currencyCode}
              />
            </td>
            <td className={css(styles.extraSpace)} />
          </tr>
        </tbody>
      </table>
    </td>
  </tr>
);

export default TotalAmountBeforeDiscount;
