import { all, takeLatest, call, select, put } from 'redux-saga/effects';
import type { Action } from 'redux-actions';

import { getLocale, getAvailableLocales } from '@contactcentre-web/localisation/localisation';
import { setAcceptLanguage } from '@contactcentre-web/utils/request';
import type State from '@contactcentre-web/redux-common/types/State';

import { getUserId } from '../selectors';
import { AUTHENTICATION_SUCCEDED, LOGIN_SUCCEEDED, LOGOUT } from '../module';

import { getUserProfile, saveUserProfile, UserProfile } from './service';
import { actions, actionTypes } from './module';

const getSafeLocale = (locale: string) =>
  getAvailableLocales().some((l) => l === locale) ? locale : getLocale();

export function refresh() {
  window.location.reload();
}

export function* setLocaleSaga({
  payload: { locale, skipInit },
}: Action<{ locale: string; skipInit?: boolean }>) {
  try {
    const state: State = yield select();
    const userId: string | null = yield call(getUserId, state);

    if (userId != null) {
      const profile: UserProfile = (yield call(getUserProfile, userId)) || {};

      profile.locale = getSafeLocale(locale);

      yield call(saveUserProfile, userId, profile);
      yield call(setAcceptLanguage, locale);
      document.documentElement.lang = locale;
      if (!skipInit) {
        yield call(refresh);
      }
      yield put(actions.setLocaleSuccess(locale));
    }
  } catch (err) {
    // Swallow this error as there's no UI feedback (yet).
  }
}

export function* setLocaleSagaInit() {
  try {
    const defaultLocale = getLocale();
    const state: State = yield select();
    const userId: string | null = yield call(getUserId, state);
    let locale = defaultLocale;

    if (userId != null) {
      const profile: UserProfile = yield call(getUserProfile, userId);
      locale = getSafeLocale(profile?.locale || defaultLocale);
    }

    yield put(actions.setLocale(locale, true));
  } catch (err) {
    // Swallow this error as there's no UI feedback (yet).
  }
}

export default function* saga() {
  yield all([
    takeLatest(actionTypes.SET_LOCALE, setLocaleSaga),
    takeLatest(AUTHENTICATION_SUCCEDED, setLocaleSagaInit), // after user login, but its in the MG selector page
    takeLatest(LOGIN_SUCCEEDED, setLocaleSagaInit), // token exists, user refresh page with F5
    takeLatest(LOGOUT, setLocaleSagaInit), // for setting default lang
  ]);
}
