import React, { Ref, MouseEventHandler, FunctionComponent } from 'react';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';
import { useHistory } from 'react-router-dom';

import Loader from '../Loader';

import styles, { loaderBorderColor } from './styles';

type ButtonSize = 'small' | 'medium' | 'large' | 'xlarge';
type ButtonType = 'button' | 'submit' | 'reset';

export interface CommonButtonProps {
  id?: string;
  type?: ButtonType;
  fullWidth?: boolean;
  testId?: string;
  name?: string;
  tabIndex?: number;
  role?: string;
  ariaExpanded?: boolean;
  ariaLabel?: string;
  disabled?: boolean;
  loading?: boolean;
  showLoadingIndicator?: boolean;
  title?: string;
  buttonRef?: Ref<HTMLButtonElement>;
  styleSheet?: StyleDeclarationValue;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  to?: string;
}

type VariantAndSizeProps =
  | { variant: 'primary' | 'secondary' | 'tertiary' | 'destructive'; size: ButtonSize }
  | { variant: 'ghost'; size?: never };

type Props = CommonButtonProps & VariantAndSizeProps;

const Button: FunctionComponent<Props> = ({
  id,
  type = 'button',
  variant,
  size,
  fullWidth = false,
  testId,
  name,
  tabIndex,
  role,
  ariaExpanded,
  ariaLabel,
  disabled = false,
  loading = false,
  showLoadingIndicator = true,
  title,
  buttonRef,
  styleSheet,
  onClick,
  to,
  children,
}) => {
  const history = useHistory();
  const onClickFn = !onClick && to ? () => history.push(to) : onClick;

  return (
    <button
      id={id}
      type={type}
      className={css(
        styles[variant],
        size && styles[size],
        fullWidth && styles.fullWidth,
        loading && styles.loading,
        styleSheet
      )}
      onClick={onClickFn}
      disabled={disabled || loading}
      tabIndex={tabIndex}
      data-testid={testId}
      ref={buttonRef}
      title={title}
      name={name}
      role={role}
      aria-expanded={ariaExpanded}
      aria-label={ariaLabel}
    >
      {loading && showLoadingIndicator ? (
        <span className={css(styles.buttonContent)}>
          {children}
          <Loader
            size={size === 'small' ? 18 : 20}
            borderWidth={2}
            borderColor={loaderBorderColor}
            styleSheet={{ container: styles.loader }}
          />
        </span>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
