import { defineMessages } from 'react-intl';

export default defineMessages({
  journeyCount: {
    id: 'CustomerBookingJourney.journeyCount',
    defaultMessage: 'Journey {journeyNum} of {journeyCount}',
  },

  directionOfJourney: {
    id: 'CustomerBookingJourney.directionOfJourney',
    defaultMessage: '{origin} to {destination}',
  },

  changes: {
    id: 'CustomerBookingJourney.changes',
    defaultMessage: '{changes, plural, one {# change} other {# changes}}',
  },

  fulfillment: {
    id: 'CustomerBookingJourney.fulfillment',
    defaultMessage: 'Fulfillment status: {status}',
  },

  returnIsValidFor: {
    id: 'CustomerBookingJourney.returnIsValidFor',
    defaultMessage: 'The return is valid for {period}',
  },
  returnIsValidUntil: {
    id: 'CustomerBookingJourney.returnIsValidUntil',
    defaultMessage: 'The return is valid until {period} at 04:29',
  },
  delayRepayStatus: {
    id: 'CustomerBookingJourney.delayRepayStatus',
    defaultMessage: 'Trainline Delay repay',
  },

  delayRepayStatus_created: {
    id: 'CustomerBookingJourney.delayRepayStatus_created',
    defaultMessage: 'Created',
  },
  delayRepayStatus_eligible: {
    id: 'CustomerBookingJourney.delayRepayStatus_eligible',
    defaultMessage: 'Eligible',
  },
  delayRepayStatus_processing: {
    id: 'CustomerBookingJourney.delayRepayStatus_processing',
    defaultMessage: 'Processing',
  },
  delayRepayStatus_approved: {
    id: 'CustomerBookingJourney.delayRepayStatus_approved',
    defaultMessage: 'Approved',
  },
  delayRepayStatus_paid: {
    id: 'CustomerBookingJourney.delayRepayStatus_paid',
    defaultMessage: 'Paid',
  },
  delayRepayStatus_unsuccessful: {
    id: 'CustomerBookingJourney.delayRepayStatus_unsuccessful',
    defaultMessage: 'Unsuccessful',
  },
  delayRepayStatus_expired: {
    id: 'CustomerBookingJourney.delayRepayStatus_expired',
    defaultMessage: 'Expired',
  },
  delayRepayStatus_unknown: {
    id: 'CustomerBookingJourney.delayRepayStatus_unknown',
    defaultMessage: 'Unknown ({status})',
  },
});
