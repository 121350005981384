import { defineMessages } from 'react-intl';

export default defineMessages({
  passenger: {
    id: 'RefundFareInformation.passenger',
    defaultMessage: 'Passenger(s)',
  },
  traCS: {
    id: 'RefundFareInformation.traCS',
    defaultMessage: 'TraCS ID',
  },
  pnr: {
    id: 'RefundFareInformation.pnr',
    defaultMessage: 'PNR',
  },
});
