import { StyleSheet } from 'aphrodite/no-important';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  statusIndicatorProcessing: {
    borderColor: colors.warning.base,
    backgroundColor: colors.warning.base,
  },
  statusIndicatorApproved: {
    borderColor: colors.positive.base,
    backgroundColor: colors.positive.base,
  },
  statusIndicatorRejected: {
    borderColor: colors.negative.base,
    backgroundColor: colors.negative.base,
  },
});
