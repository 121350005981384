import request from '@contactcentre-web/utils/request';

export const changePassword = (username: string, newPassword: string, oldPassword: string) =>
  request(`/authapi/users/password`, {
    method: 'POST',
    body: {
      userName: username,
      newPassword,
      confirmNewPassword: newPassword,
      oldPassword,
    },
  });
