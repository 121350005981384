import React, { Dispatch, FunctionComponent, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { Heading, Paragraph, StatusMessage } from '@trainline/depot-web';

import TextField from '@contactcentre-web/common/TextField';
import Button from '@contactcentre-web/common/Button';
import PageContainer from '@contactcentre-web/common/PageContainer';
import type State from '@contactcentre-web/redux-common/types/State';
import type Action from '@contactcentre-web/redux-common/types/Action';

import { SEARCH_FORM_ID, actions, selectors, SearchResult, SearchError } from '../../module';

import messages from './messages';
import styles from './styles';

type SearchUserFormData = {
  username: string;
};

interface StateProps {
  isSearchPending: boolean;
  isSearchSuccess: boolean;
  searchResults: Array<SearchResult>;
  searchError?: SearchError | null;
}

interface DispatchProps {
  searchReset: () => void;
  searchAttempt: (data: SearchUserFormData) => void;
}

type Props = StateProps & DispatchProps;

export const SearchUser: FunctionComponent<
  Props & InjectedFormProps<SearchUserFormData, Props>
> = ({
  isSearchPending,
  isSearchSuccess,
  handleSubmit,
  searchReset,
  searchAttempt,
  searchResults,
  searchError,
}) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const searchResetRef = useRef(searchReset);
  const [searchQuery, setSearchQuery] = useState<string>();

  React.useEffect(() => () => searchResetRef.current(), [searchResetRef]);

  React.useEffect(() => {
    if (isSearchSuccess && searchResults?.length === 1) {
      history.push(`/edit_user/${searchResults[0].username}`);
    }
  }, [isSearchSuccess, searchResults]);

  return (
    <PageContainer>
      <form
        id={SEARCH_FORM_ID}
        method="post"
        onSubmit={handleSubmit((data) => {
          setSearchQuery(data?.username);
          searchAttempt(data);
        })}
        className={css(styles.container)}
      >
        <div>
          <div className={css(styles.spacer)}>
            <Heading typeStyle="title1" as="h1" color="base">
              <FormattedMessage {...messages.searchUser} />
            </Heading>
          </div>
          <div className={css(styles.description)}>
            <Paragraph typeStyle="body" as="p" color="base" fontWeight="regular">
              <FormattedMessage {...messages.description} />
            </Paragraph>
          </div>
          {searchError && messages[searchError] && (
            <div className={css(styles.spacer)}>
              <StatusMessage status="negative">
                <FormattedMessage {...messages[searchError]} />
              </StatusMessage>
            </div>
          )}
          <div className={css(styles.inputContainer)}>
            <div className={css(styles.usernameInput)}>
              <TextField
                name="username"
                messages={messages}
                placeholder={formatMessage(messages.search)}
              />
            </div>
            <Button
              type="submit"
              variant="primary"
              size="small"
              testId="search-user-button"
              loading={isSearchPending}
              styleSheet={styles.searchButton}
            >
              <FormattedMessage {...messages.search} />
            </Button>
          </div>
          {searchResults?.length > 1 && (
            <div className={css(styles.resultsContainer)}>
              <Paragraph typeStyle="body" as="p" color="base" fontWeight="bold">
                <FormattedMessage
                  {...messages.results}
                  values={{ numberOfResults: searchResults.length, searchQuery }}
                />
              </Paragraph>
              <ul className={css(styles.resultsList)}>
                {searchResults.map(({ firstName, lastName, isDisabled, username }) => (
                  <li key={username} className={css(styles.listItem)}>
                    <Button
                      variant="ghost"
                      styleSheet={styles.listItemButton}
                      onClick={() => history.push(`/edit_user/${username}`)}
                    >
                      <Paragraph
                        typeStyle="body"
                        as="p"
                        color={isDisabled ? 'disabled' : 'base'}
                        fontWeight="regular"
                      >
                        {firstName} {lastName}{' '}
                        {isDisabled && <FormattedMessage {...messages.disabled} />}
                      </Paragraph>
                      <Paragraph typeStyle="body" as="p" color="subdued" fontWeight="regular">
                        {username}
                      </Paragraph>
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </form>
    </PageContainer>
  );
};

const mapStateToProps = (state: State) => ({
  isSearchPending: selectors.isSearchPending(state),
  isSearchSuccess: selectors.isSearchSuccess(state),
  searchResults: selectors.getSearchResults(state),
  searchError: selectors.getSearchError(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  searchReset: () => dispatch(actions.resetSearch()),
  searchAttempt: ({ username }: SearchUserFormData) => dispatch(actions.searchAttempt(username)),
});

export const validate = ({ username }: SearchUserFormData) => {
  const errors = {} as SearchUserFormData;
  if (!username) {
    errors.username = 'usernameIsRequired';
  }
  return errors;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<SearchUserFormData, Props>({
    form: SEARCH_FORM_ID,
    validate,
    enableReinitialize: true,
  })(SearchUser)
);
