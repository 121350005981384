import { StyleSheet } from 'aphrodite/no-important';
import {
  borderRadius,
  palettes,
  spacers,
  typography,
} from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

const inputStyles = {
  borderRadius: `${borderRadius.dp_r4} 0 0 ${borderRadius.dp_r4}`,
  borderRight: 'none',
  width: '100px',
};

export default StyleSheet.create({
  container: {
    flexDirection: 'column',
    padding: 0,
  },
  inputContainer: {
    display: 'flex',
  },
  input: {
    ...inputStyles,
  },
  inputError: {
    ...inputStyles,
    borderColor: colors.negative.base,
  },
  currency: {
    border: `1px solid ${palettes.dp_app_border_dark}`,
    borderRadius: `0 ${borderRadius.dp_r4} ${borderRadius.dp_r4} 0`,
    display: 'flex',
    alignItems: 'center',
    borderLeft: 'none',
    padding: `0 ${spacers.dp_s8}`,
  },
  currencyDisabled: {
    borderColor: colors.gray.alpha12,
  },
  currencyError: {
    borderColor: colors.negative.base,
  },
  errorContainer: {
    height: '24px',
    fontSize: typography.dp_mini_font_size_m,
    lineHeight: typography.dp_body_line_height,
    color: colors.negative.base,
    marginBottom: spacers.dp_s4,
  },
});
