import { defineMessages } from 'react-intl';

export default defineMessages({
  reasonCodes: {
    id: 'RefundCardDetails.reasonCodes',
    defaultMessage: 'reason codes',
  },
  fees_service: {
    id: 'RefundCardDetails.serviceFee',
    defaultMessage: 'service fee',
  },
  fees_payment: {
    id: 'RefundCardDetails.paymentFee',
    defaultMessage: 'payment fee',
  },
  fees_delivery: {
    id: 'RefundCardDetails.deliveryFee',
    defaultMessage: 'delivery fee',
  },
  fees_admin: {
    id: 'RefundCardDetails.adminFee',
    defaultMessage: 'admin fee',
  },
  fees_noValue: {
    id: 'RefundCardDetails.fees_noValue',
    defaultMessage: '--',
  },
  total: {
    id: 'RefundCardDetails.total',
    defaultMessage: 'total',
  },
  edit: {
    id: 'RefundCardDetails.edit',
    defaultMessage: 'Edit',
  },
  approve: {
    id: 'RefundCardDetails.approve',
    defaultMessage: 'Approve',
  },
  reject: {
    id: 'RefundCardDetails.reject',
    defaultMessage: 'Reject',
  },
  clear: {
    id: 'RefundCardDetails.clear',
    defaultMessage: 'Clear',
  },
  scannedTicket: {
    id: 'RefundCardDetails.scannedTicket',
    defaultMessage:
      'Ticket has been scanned and you must confirm the disruption and the customer did not use ticket to reach destination before requesting refund.',
  },
  partialReturnRefundAgainstDisruption: {
    id: 'RefundCardDetails.partialReturnRefundAgainstDisruption',
    defaultMessage: 'The inward ticket has been scanned and needs extra check.',
  },
  expiredQuote: {
    id: 'expiredQuote',
    defaultMessage:
      'The refund quote has expired so this request cannot be edited nor approved. A new refund may need to be requested.',
  },
  approvalRefundRequestRejectedByFraudHeader: {
    id: 'RefundCard.approvalRefundRequestRejectedByFraudHeader',
    defaultMessage: 'Reference code RR1',
  },
  approvalRefundRequestRejectedByFraudBody: {
    id: 'RefundCard.approvalRefundRequestRejectedByFraudBody',
    defaultMessage:
      'Please override this block and continue to process the refund. If the customer is requesting help to self serve after-sales for future bookings then please raise a request to the F & P Revenue Protection Group queue in Freshdesk for further handling',
  },
  approvalRefundRequestRejectedByFraudAction: {
    id: 'RefundCard.approvalRefundRequestRejectedByFraudAction',
    defaultMessage: 'Override and approve',
  },
  approvalInvalidQuoteErrorBody: {
    id: 'RefundCard.approvalInvalidQuoteErrorBody',
    defaultMessage:
      'The quote for this refund request is no longer valid. Please reject this request and ask the customer to request a new refund.',
  },
});
