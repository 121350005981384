import { StyleSheet } from 'aphrodite/no-important';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  statusIndicatorinitiated: {
    borderColor: colors.warning.base,
    backgroundColor: colors.warning.base,
  },
  statusIndicatorsubmitted: {
    borderColor: colors.positive.base,
    backgroundColor: colors.positive.base,
  },
  statusIndicatorappealed: {
    borderColor: colors.positive.base,
    backgroundColor: colors.positive.base,
  },
  statusIndicatoraccepted: {
    borderColor: colors.positive.base,
    backgroundColor: colors.positive.base,
  },
  statusIndicatorapproved: {
    borderColor: colors.positive.base,
    backgroundColor: colors.positive.base,
  },
  statusIndicatorrejected: {
    borderColor: colors.negative.base,
    backgroundColor: colors.negative.base,
  },
});
