import { createActions } from 'redux-actions';

export const PREFIX = 'CustomerOrderCompensation';

export const RESET = 'RESET';
export const COMPENSATION_LOAD = 'LOAD';
export const COMPENSATION_LOAD_SUCCEEDED = 'LOAD_SUCCEEDED';
export const COMPENSATION_LOAD_FAILED = 'LOAD_FAILED';

const COMPENSATION_LOAD_NONE = 'LOAD_COMPENSATION_NONE';
const COMPENSATION_LOAD_INPROGRESS = 'LOAD_COMPENSATION_IN_PROGRESS';
const COMPENSATION_LOAD_SUCCESS = 'LOAD_COMPENSATION_SUCCESS';
const COMPENSATION_LOAD_ERROR = 'LOAD_COMPENSATION_ERROR';

export const COMPENSATION_ELIGIBILITY_LOAD = 'LOAD_ELIGIBILITY';

export const COMPENSATION_REQUEST_ATTEMPT = 'REQUEST_ATTEMPT';
export const COMPENSATION_REQUEST_SUCCESS = 'REQUEST_SUCCESS';
export const COMPENSATION_REQUEST_FAILURE = 'REQUEST_FAILURE';

export const COMPENSATION_REQUEST_STATE_NONE = 'COMPENSATION_REQUEST_STATE_NONE';
export const COMPENSATION_REQUEST_STATE_INPROGRESS = 'COMPENSATION_REQUEST_STATE_INPROGRESS';
export const COMPENSATION_REQUEST_STATE_SUCCESSFUL = 'COMPENSATION_REQUEST_STATE_SUCCESSFUL';
export const COMPENSATION_REQUEST_STATE_FAILED = 'COMPENSATION_REQUEST_STATE_FAILED';

export const REFUNDS_LOAD_SUCCEEDED = 'LOAD_REFUNDS_SUCCEEDED';
export const REFUNDS_LOAD_FAILED = 'LOAD_REFUNDS_FAILED';

const REFUNDS_LOAD_NONE = 'LOAD_REFUNDS_NONE';
const REFUNDS_LOAD_INPROGRESS = 'LOAD_REFUNDS_IN_PROGRESS';
const REFUNDS_LOAD_SUCCESS = 'LOAD_REFUNDS_SUCCESS';
const REFUNDS_LOAD_ERROR = 'LOAD_REFUNDS_ERROR';

export const FORM_ID = 'compensation-form';

export const events = {
  RESET,
  COMPENSATION_LOAD,
  COMPENSATION_LOAD_SUCCEEDED,
  COMPENSATION_LOAD_FAILED,
  COMPENSATION_REQUEST_ATTEMPT,
  COMPENSATION_REQUEST_SUCCESS,
  COMPENSATION_REQUEST_FAILURE,
  COMPENSATION_ELIGIBILITY_LOAD,
  REFUNDS_LOAD_SUCCEEDED,
  REFUNDS_LOAD_FAILED,
};

export const loadCompensationsState = {
  COMPENSATION_LOAD_NONE,
  COMPENSATION_LOAD_INPROGRESS,
  COMPENSATION_LOAD_SUCCESS,
  COMPENSATION_LOAD_ERROR,
};

export const loadRefundsState = {
  REFUNDS_LOAD_NONE,
  REFUNDS_LOAD_INPROGRESS,
  REFUNDS_LOAD_SUCCESS,
  REFUNDS_LOAD_ERROR,
};

export const requestCompensationsState = {
  COMPENSATION_REQUEST_STATE_NONE,
  COMPENSATION_REQUEST_STATE_INPROGRESS,
  COMPENSATION_REQUEST_STATE_SUCCESSFUL,
  COMPENSATION_REQUEST_STATE_FAILED,
};

export const actions = createActions(
  {
    [RESET]: () => null,
    [COMPENSATION_LOAD]: (id) => ({ id }),
    [COMPENSATION_LOAD_SUCCEEDED]: (compensationReasons, compensation) => ({
      compensationReasons,
      compensation,
    }),
    [COMPENSATION_LOAD_FAILED]: () => null,
    [COMPENSATION_REQUEST_ATTEMPT]: (orderId, orderReference, compensationId, values) => ({
      orderId,
      orderReference,
      compensationId,
      values,
    }),
    [COMPENSATION_REQUEST_SUCCESS]: () => null,
    [COMPENSATION_REQUEST_FAILURE]: (errorCode, context) => ({
      errorCode,
      context,
    }),
    [REFUNDS_LOAD_SUCCEEDED]: (refundResponse) => ({
      refundResponse,
    }),
    [REFUNDS_LOAD_FAILED]: () => null,
  },
  { prefix: PREFIX }
);
