import React, { ChangeEvent } from 'react';
import { css } from 'aphrodite/no-important';

import styles from './styles';

interface RadioBoxProps {
  label: React.ReactNode;
  value: string;
  name?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const RadioBox: React.FunctionComponent<RadioBoxProps> = ({
  name,
  label,
  value,
  onChange,
}) => (
  <div className={css(styles.item)}>
    <div className={css(styles.radio)}>
      <input name={name} type="radio" value={value} radioGroup={name} onChange={onChange} />
    </div>
    <div>{label}</div>
  </div>
);

export default RadioBox;
