import { defineMessages } from 'react-intl';

export default defineMessages({
  booking: {
    id: 'FulfilmentConverterForm.booking',
    defaultMessage: 'Booking',
  },
  selectBooking: {
    id: 'FulfilmentConverterForm.selectBooking',
    defaultMessage: 'Select booking',
  },
  allBookings: {
    id: 'FulfilmentConverterForm.allBookings',
    defaultMessage: 'All bookings',
  },
  bookingMustBeSelected: {
    id: 'FulfilmentConverterForm.bookingMustBeSelected',
    defaultMessage: 'A booking must be selected',
  },
  refreshBooking: {
    id: 'FulfilmentConverterForm.fulfilmentConverter',
    defaultMessage: 'Fulfilment Converter',
  },
  bookingLabel: {
    id: 'FulfilmentConverterForm.bookingLabel',
    defaultMessage: 'Booking {bookingIndex} - {from} to {to} - {departureDay}',
  },
  info: {
    id: 'FulfilmentConverterForm.info',
    defaultMessage:
      "This function will create a new version of the customer's booking. It may no longer match what the customer sees in the app.",
  },
  alreadyConverted: {
    id: 'FulfilmentConverterForm.alreadyConverted',
    defaultMessage: 'This booking has already been converted.',
  },
});
