import { defineMessages } from 'react-intl';

export default defineMessages({
  booking: {
    id: 'SupersededBooking.BookingHeader.booking',
    defaultMessage: 'Booking {index}',
  },
  origin: {
    id: 'SupersededBooking.BookingHeader.origin',
    defaultMessage: '{origin} to',
  },
  returnLabel: {
    id: 'SupersededBooking.BookingHeader.returnLabel',
    defaultMessage: 'Return',
  },
  returnDestination: {
    id: 'SupersededBooking.BookingHeader.returnDestination',
    defaultMessage: '{destination}, {returnLabel}',
  },

  deliveryMethod: {
    id: 'SupersededBooking.BookingHeader.deliveryMethod',
    defaultMessage: 'FUL',
  },
  ctr: {
    id: 'SupersededBooking.BookingHeader.ctr',
    defaultMessage: 'Ctr',
  },
  tracsTransId: {
    id: 'SupersededBooking.BookingHeader.tracsTransId',
    defaultMessage: 'Tracs Trans. Id',
  },
  pnr: {
    id: 'SupersededBooking.BookingHeader.pnr',
    defaultMessage: 'PNR',
  },
  transactionId: {
    id: 'SupersededBooking.BookingHeader.transactionId',
    defaultMessage: 'Transaction Id',
  },
  nxTicketRef: {
    id: 'SupersededBooking.BookingHeader.nxTicketRef',
    defaultMessage: 'NX Ticket Ref.',
  },
  dates: {
    id: 'SupersededBooking.BookingHeader.dates',
    defaultMessage: 'Dates',
  },
  productPrice: {
    id: 'SupersededBooking.BookingHeader.productPrice',
    defaultMessage: 'Product price',
  },

  date: {
    id: 'SupersededBooking.BookingHeader.date',
    defaultMessage: '{label} {date}',
  },

  out: {
    id: 'SupersededBooking.BookingHeader.out',
    defaultMessage: 'Out',
  },

  ret: {
    id: 'SupersededBooking.BookingHeader.ret',
    defaultMessage: 'Return',
  },

  bookingRefreshed: {
    id: 'SupersededBooking.BookingHeader.bookingRefreshed',
    defaultMessage: 'Booking refreshed',
  },

  partialRefund: {
    id: 'SupersededBooking.BookingHeader.partialRefund',
    defaultMessage: 'Partially refunded',
  },

  unknownSuperseding: {
    id: 'SupersededBooking.BookingHeader.unknownSuperseding',
    defaultMessage: 'Superseded',
  },
});
