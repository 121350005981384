import { defineMessages } from 'react-intl';

export default defineMessages({
  amountRequested: {
    id: 'RefundPaymentDetails.amountRequested',
    defaultMessage: 'Amount requested',
  },
  adminFee: {
    id: 'RefundPaymentDetails.adminFee',
    defaultMessage: '{amountOfAdminFees, select, 1 {Admin fee} other {Admin fees}}',
  },
  amountRefunded: {
    id: 'RefundPaymentDetails.amountRefunded',
    defaultMessage: 'Amount refunded',
  },
  paymentType: {
    id: 'RefundPaymentDetails.paymentType',
    defaultMessage: 'Payment type',
  },
  paymentHolderName: {
    id: 'RefundPaymentDetails.paymentHolderName',
    defaultMessage: 'Payment holder name',
  },
});
