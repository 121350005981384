import React from 'react';

import BulkUpload from '@contactcentre-web/bulk-uploads/BulkUpload';
import PageContainer from '@contactcentre-web/common/PageContainer/PageContainer';
import ErrorBoundary from '@contactcentre-web/common/ErrorBoundary/ErrorBoundary';

const BulkpUploadPage = () => (
  <PageContainer>
    <ErrorBoundary>
      <BulkUpload />
    </ErrorBoundary>
  </PageContainer>
);

export default BulkpUploadPage;
