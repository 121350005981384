import React, { FunctionComponent } from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';

import {
  EditActions,
  ResetPasswordConfirm,
  ResetPasswordSuccess,
  ChangeRole,
  ChangeSite,
  SearchUser,
  ChangeEmail,
} from '@contactcentre-web/edit-user';

const EditUserPage: FunctionComponent<RouteComponentProps> = ({ match: { path } }) => (
  <Switch>
    <Route path={`${path}/:username`} exact component={EditActions} />
    <Route path={`${path}/:username/reset_password`} exact component={ResetPasswordConfirm} />
    <Route path={`${path}/:username/reset_password/success`} component={ResetPasswordSuccess} />
    <Route path={`${path}/:username/change_role`} component={ChangeRole} />
    <Route path={`${path}/:username/change_site`} component={ChangeSite} />
    <Route path={`${path}/:username/change_email`} component={ChangeEmail} />
    <Route path={`${path}`} component={SearchUser} />
  </Switch>
);

export default EditUserPage;
