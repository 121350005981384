import { defineMessages } from 'react-intl';

export default defineMessages({
  lastActive: {
    id: 'CustomerListItem.lastActive',
    defaultMessage: 'LAST ACTIVE {date} ({accessMethod})',
  },

  customerPhones: {
    id: 'CustomerListItem.customerPhones',
    defaultMessage: 'Mob: {mobile} Home: {evening} Work: {dayTime}',
  },
});
