import React from 'react';
import { css } from 'aphrodite/no-important';
import { IconSearch } from '@trainline/depot-web';

import LoadMoreButton from '@contactcentre-web/common/LoadMoreButton/LoadMoreButton';
import {
  ClaimStatus,
  ManualEntryConsoleClaimItemResponse,
} from '@contactcentre-web/hooks/api/useManualEntryConsole';
import Loader from '@contactcentre-web/common/Loader';
import NoResultsContainer from '@contactcentre-web/common/NoResultsContainer/NoResultsContainer';

import ManualEntryConsoleQueueItem from '../ManualEntryConsoleQueueItem/ManualEntryConsoleQueueItem';
import ManualEntryConsoleMessageError from '../ManualEntryConsoleMessageError/ManualEntryConsoleMessageError';

import messages from './messages';
import styles from './styles';

interface Props {
  manualEntries: ManualEntryConsoleClaimItemResponse[];
  claimStatus: ClaimStatus;
  totalCount: number;
  pageSize: number;
  page: number;
  isLoading: boolean;
  isError: boolean;
  hasFiltersApplied: boolean;
  loadNextPage: () => void;
}

const ManualEntryConsoleQueueList = ({
  manualEntries,
  claimStatus,
  totalCount,
  pageSize,
  page,
  isLoading,
  isError,
  hasFiltersApplied,
  loadNextPage,
}: Props) => {
  const noClaims = totalCount === 0 || manualEntries.length === 0;

  return (
    <>
      {isLoading && manualEntries.length === 0 && <Loader />}
      {isError && <ManualEntryConsoleMessageError />}
      {noClaims && !isLoading && (
        <NoResultsContainer
          customMessage={hasFiltersApplied ? messages.noResultsFiltersApplied : messages.noResults}
          icon={
            hasFiltersApplied ? (
              <IconSearch className={css(styles.icon)} testId="IconSearch" />
            ) : undefined
          }
          subTitle={hasFiltersApplied ? messages.noResultsFiltersAppliedSubTitle : undefined}
        />
      )}
      {manualEntries.map((manualRecovery) => (
        <ManualEntryConsoleQueueItem
          key={manualRecovery.id}
          manualRecovery={manualRecovery}
          claimStatus={claimStatus}
        />
      ))}
      <LoadMoreButton
        totalCount={totalCount}
        pageSize={pageSize}
        page={page}
        fetchNextPage={loadNextPage}
        isLoading={isLoading}
      />
    </>
  );
};

export default ManualEntryConsoleQueueList;
