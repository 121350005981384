import React from 'react';
import { Heading } from '@trainline/depot-web';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  canValidatePaperTickets,
  canFinalReviewPaperTickets,
} from '@contactcentre-web/authentication/redux/selectors';
import Button from '@contactcentre-web/common/Button';
import { PaperTicketQueueName } from '@contactcentre-web/hooks/api/usePaperTicketQueue';

import messages from './messages';
import styles from './styles';

interface Props {
  currentTab: PaperTicketQueueName;
  tabChanges: (newTab: PaperTicketQueueName) => void;
}

const PaperTicketHeader = ({ currentTab, tabChanges }: Props) => {
  const tabsMenu = [
    {
      tabName: PaperTicketQueueName.Unmatched,
      buttonText: messages.unmatchedTabName,
      permission: useSelector(canValidatePaperTickets),
    },
    {
      tabName: PaperTicketQueueName.FailedValidation,
      buttonText: messages.failedValidationTabName,
      permission: useSelector(canFinalReviewPaperTickets),
    },
  ];

  return (
    <>
      <div className={css(styles.container)} data-testid="paperTicketHeader">
        <Heading typeStyle="title1" as="h1" color="base">
          <FormattedMessage {...messages.title} />
        </Heading>
      </div>
      <div className={css(styles.tabContainer)}>
        <div>
          {tabsMenu.map(({ tabName, buttonText, permission }) => (
            <Button
              key={tabName}
              testId={`${tabName}Button`}
              variant="tertiary"
              size="small"
              onClick={() => tabChanges(tabName)}
              disabled={!permission}
              styleSheet={[styles.defaultTab, currentTab === tabName && styles.activeTab]}
            >
              <FormattedMessage {...buttonText} />
            </Button>
          ))}
        </div>
      </div>
    </>
  );
};

export default PaperTicketHeader;
