import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { IconReturn, IconSingle } from '@trainline/depot-web';

import FormattedTimeInTz from '../../../FormattedTimeInTz/FormattedTimeInTz';
import FormattedDateInTz from '../../../FormattedDateInTz';
import { ProductDetails } from '../../types';

import styles from './styles';
import messages from './messages';

const TravelProductRenderer = ({
  from,
  to,
  isReturn,
  departureDate,
  inventoryReference,
  returnDate,
}: ProductDetails) => ({
  description: () => (
    <>
      <span className={css(styles.from)}>{from}</span>
      {isReturn ? (
        <IconReturn className={css(styles.arrow)} />
      ) : (
        <IconSingle className={css(styles.arrow)} />
      )}
      <span className={css(styles.to)}>{to}</span>
    </>
  ),
  departureDate: () =>
    departureDate ? (
      <>
        <FormattedDateInTz value={departureDate} year="numeric" month="short" day="numeric" />
        <div className={css(styles.atTime)}>
          <FormattedMessage
            {...messages.atTime}
            values={{
              time: <FormattedTimeInTz value={departureDate} hour="2-digit" minute="2-digit" />,
            }}
          />
        </div>
      </>
    ) : undefined,
  returnDate: () =>
    returnDate ? (
      <>
        <FormattedDateInTz value={returnDate} year="numeric" month="short" day="numeric" />
        <div className={css(styles.atTime)}>
          <FormattedMessage
            {...messages.atTime}
            values={{
              time: <FormattedTimeInTz value={returnDate} hour="2-digit" minute="2-digit" />,
            }}
          />
        </div>
      </>
    ) : undefined,
  inventoryReference: () => inventoryReference,
});

export default (productDetails: ProductDetails) => TravelProductRenderer(productDetails);
