import { defineMessages } from 'react-intl';

export default defineMessages({
  tickets: {
    id: 'CustomerOrderHeader.TicketsSection.tickets',
    defaultMessage: 'tickets',
  },
  ticketsWithCount: {
    id: 'CustomerOrderHeader.TicketsSection.ticketsWithCount',
    defaultMessage: 'tickets ({count})',
  },
  senior: {
    id: 'CustomerOrderHeader.TicketsSection.senior',
    defaultMessage: '{count, number} {count, plural, one {senior} other {seniors}}',
  },
  adult: {
    id: 'CustomerOrderHeader.TicketsSection.adult',
    defaultMessage: '{count, number} {count, plural, one {adult} other {adults}}',
  },
  child: {
    id: 'CustomerOrderHeader.TicketsSection.child',
    defaultMessage: '{count, number} {count, plural, one {child} other {children}}',
  },
  infant: {
    id: 'CustomerOrderHeader.TicketsSection.infant',
    defaultMessage: '{count, number} {count, plural, one {infant} other {infants}}',
  },
  youth: {
    id: 'CustomerOrderHeader.TicketsSection.youth',
    defaultMessage: '{count, number} {count, plural, one {youth} other {youths}}',
  },
  notAvailable: {
    id: 'CustomerOrderHeader.TicketsSection.notAvailable',
    defaultMessage: 'N/A',
  },
});
