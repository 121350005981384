import { createActions } from 'redux-actions';

const PREFIX = 'ORDER_ACTIONS/';
export const REMOVE_RESTRICTION_NONE = `${PREFIX}REMOVE_RESTRICTION_NONE`;
export const REMOVE_RESTRICTION_RESET = `${PREFIX}RESET`;
export const REMOVE_RESTRICTION_ATTEMPT = `${PREFIX}REMOVE_RESTRICTION_ATTEMPT`;
export const REMOVE_RESTRICTION_SUCCESS = `${PREFIX}REMOVE_RESTRICTION__SUCCESS`;
export const REMOVE_RESTRICTION_FAILED = `${PREFIX}REMOVE_RESTRICTION__FAILED`;
export const REMOVE_RESTRICTION_REASONS_LOAD = `${PREFIX}REMOVE_RESTRICTION_REASONS_LOAD`;
export const REMOVE_RESTRICTION_REASONS_LOAD_SUCCESS = `${PREFIX}REMOVE_RESTRICTION_REASONS_LOAD_SUCCESS`;
export const REMOVE_RESTRICTION_REASONS_LOAD_FAILED = `${PREFIX}REMOVE_RESTRICTION_REASONS_LOAD_FAILED`;

export const loadStates = {
  NONE: `${PREFIX}NONE`,
  INPROGRESS: `${PREFIX}INPROGRESS`,
  SUCCESS: `${PREFIX}SUCCESS`,
  ERROR: `${PREFIX}ERROR`,
};

export const changeCTR = {
  REMOVE_RESTRICTION_RESET,
  REMOVE_RESTRICTION_NONE,
  REMOVE_RESTRICTION_ATTEMPT,
  REMOVE_RESTRICTION_SUCCESS,
  REMOVE_RESTRICTION_FAILED,
  REMOVE_RESTRICTION_REASONS_LOAD,
  REMOVE_RESTRICTION_REASONS_LOAD_SUCCESS,
  REMOVE_RESTRICTION_REASONS_LOAD_FAILED,
};

export const { orderActions: actions } = createActions({
  [REMOVE_RESTRICTION_RESET]: () => null,
  [REMOVE_RESTRICTION_ATTEMPT]: (customerId, orderId, bookingId, bookingIndex, reason) => ({
    customerId,
    orderId,
    bookingId,
    bookingIndex,
    reason,
  }),
  [REMOVE_RESTRICTION_SUCCESS]: (bookingIndex) => ({
    bookingIndex,
  }),
  [REMOVE_RESTRICTION_FAILED]: () => null,
  [REMOVE_RESTRICTION_REASONS_LOAD]: () => null,
  [REMOVE_RESTRICTION_REASONS_LOAD_SUCCESS]: (removeRestrictionReasons) => removeRestrictionReasons,
  [REMOVE_RESTRICTION_REASONS_LOAD_FAILED]: () => null,
  [REMOVE_RESTRICTION_NONE]: () => null,
});
