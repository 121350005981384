import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { css } from 'aphrodite/no-important';
import { FormattedMessage, FormattedDate } from 'react-intl';

import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency/FormattedCurrency';
import priceShape from '@contactcentre-web/utils/shape/price';

import styles from './styles';
import messages from './messages';

export const getTransRefMessage = (vendor, vendorRegion) => {
  if (vendor === 'NationalExpress') {
    return 'nxTicketRef';
  }
  if (vendorRegion === 'uk') {
    return 'tracsTransId';
  }
  if (vendorRegion === 'eu') {
    return 'pnr';
  }
  return 'transactionId';
};

export const getSupersededMessage = (supersededAt) => {
  if (supersededAt === 'refresh') {
    return 'bookingRefreshed';
  }
  if (supersededAt === 'partialRefund') {
    return 'partialRefund';
  }
  return 'unknownSuperseding';
};

const BookingHeader = (props) => {
  const {
    bookingNum,
    deliveryMethod,
    origin,
    destination,
    isReturn,
    outDate,
    returnDate,
    transactionTotalPrice,
    inventoryTotalPrice,
    ctr,
    transactionReference,
    vendor,
    vendorRegion,
    convertedOrderReference,
    latestSupersededAt,
    orderReference,
    customerId,
  } = props;
  return (
    <div className={css(styles.container)}>
      <div className={css(styles.directions)}>
        <h4 className={css(styles.title)}>
          <FormattedMessage {...messages.booking} values={{ index: bookingNum }} />
        </h4>
        <div>
          <div className={css(styles.direction)}>
            <FormattedMessage {...messages.origin} values={{ origin }} />
          </div>
          <div className={css(styles.direction)}>
            {isReturn ? (
              <FormattedMessage
                {...messages.returnDestination}
                values={{
                  destination,
                  returnLabel: (
                    <span className={css(styles.returnLabel)}>
                      <FormattedMessage {...messages.returnLabel} />
                    </span>
                  ),
                }}
              />
            ) : (
              destination
            )}
          </div>
        </div>
      </div>
      <div>
        <h4 className={css(styles.title)}>
          <FormattedMessage {...messages.dates} />
        </h4>
        <div className={css(styles.date)}>
          <FormattedMessage
            {...messages.date}
            values={{
              label: (
                <strong>
                  <FormattedMessage {...messages.out} />
                </strong>
              ),
              date: (
                <span className={css(styles.dateValue)}>
                  <FormattedDate
                    value={outDate}
                    weekday="short"
                    day="2-digit"
                    month="short"
                    year="numeric"
                  />
                </span>
              ),
            }}
          />
        </div>
        {isReturn && returnDate && (
          <div className={css(styles.date)}>
            <FormattedMessage
              {...messages.date}
              values={{
                label: (
                  <strong>
                    <FormattedMessage {...messages.ret} />
                  </strong>
                ),
                date: (
                  <span className={css(styles.dateValue)}>
                    <FormattedDate
                      value={returnDate}
                      weekday="short"
                      day="2-digit"
                      month="short"
                      year="numeric"
                    />
                  </span>
                ),
              }}
            />
          </div>
        )}
      </div>
      <div className={css(styles.otherItems)}>
        <h4 className={css(styles.title)}>
          <FormattedMessage {...messages.deliveryMethod} />
        </h4>
        <div className={css(styles.value)}>{deliveryMethod}</div>
      </div>
      <div className={css(styles.otherItems)}>
        {transactionReference && (
          <div>
            <h4 className={css(styles.title)}>
              <FormattedMessage {...messages[getTransRefMessage(vendor, vendorRegion)]} />
            </h4>
            <div className={css(styles.value)}>{transactionReference}</div>

            {latestSupersededAt && (
              <Link
                className={css(styles.link)}
                to={`/customers/${customerId}/bookings/${orderReference}/history`}
              >
                <FormattedMessage {...messages[getSupersededMessage(latestSupersededAt)]} />
              </Link>
            )}
          </div>
        )}
      </div>
      <div className={css(styles.otherItems)}>
        {ctr && (
          <div>
            <h4 className={css(styles.title)}>
              <FormattedMessage {...messages.ctr} />
            </h4>
            <div className={css(styles.value)}>{ctr}</div>
          </div>
        )}
      </div>
      <div>
        <h4 className={css(styles.title)}>
          <FormattedMessage {...messages.productPrice} />
        </h4>
        <div className={css(styles.price)}>
          <strong>
            {transactionTotalPrice && <FormattedCurrency {...transactionTotalPrice} />}
          </strong>
        </div>
        <div className={css(styles.price, styles.inventoryPrice)}>
          {inventoryTotalPrice &&
            (convertedOrderReference ||
              transactionTotalPrice.currencyCode !== inventoryTotalPrice.currencyCode) && (
              <span>
                (<FormattedCurrency {...inventoryTotalPrice} />)
              </span>
            )}
        </div>
      </div>
    </div>
  );
};

BookingHeader.propTypes = {
  bookingNum: PropTypes.number.isRequired,
  deliveryMethod: PropTypes.string,
  outDate: PropTypes.string.isRequired,
  returnDate: PropTypes.string,
  origin: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
  isReturn: PropTypes.bool.isRequired,
  inventoryTotalPrice: priceShape,
  transactionTotalPrice: priceShape,
  ctr: PropTypes.string,
  transactionReference: PropTypes.string,
  travelRestriction: PropTypes.bool,
  vendor: PropTypes.string,
  vendorRegion: PropTypes.string,
  convertedOrderReference: PropTypes.string,
  latestSupersededAt: PropTypes.string,
  orderReference: PropTypes.string,
  customerId: PropTypes.string,
};

export default BookingHeader;
