import { useQuery } from 'react-query';

import request from '@contactcentre-web/utils/request';

interface ManagedGroupsData {
  description: string;
  id: string;
  isToc: boolean;
  name: string;
  number: number;
}

const sortedGroups = (managedGroups: Array<ManagedGroupsData>) =>
  managedGroups?.sort((a, b) => {
    const aIsTrainline = a.name.includes('Thetrainline.com');
    const bIsTrainline = b.name.includes('Thetrainline.com');
    if (aIsTrainline) return -1;
    if (bIsTrainline) return 1;
    return a.name.localeCompare(b.name);
  });

const headers = {
  Accept: 'application/vnd.trainline.platform-reference-data.v1+json',
};

export const useManagedGroups = () =>
  useQuery(
    'managedGroups',
    () => request<Array<ManagedGroupsData>>(`/platformapi/managedgroups`, { headers }),
    { select: (item) => sortedGroups(item) }
  );
