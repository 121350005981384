import { StyleSheet } from 'aphrodite/no-important';
import { borderRadius, palettes, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    display: 'grid',
    placeContent: 'center',
    textAlign: 'center',
    border: `1px solid ${palettes.dp_app_border_base}`,
    borderRadius: borderRadius.dp_r8,
    padding: spacers.dp_s24,
  },
  icon: {
    color: palettes.dp_app_positive_mid,
    height: 'auto',
    width: spacers.dp_s48,
    margin: 'auto',
  },
  subTitle: {
    color: palettes.dp_app_text_subdued,
  },
});
