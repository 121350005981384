import React from 'react';
import { FieldArray, WrappedFieldArrayProps, InjectedFormProps, reduxForm } from 'redux-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { css } from 'aphrodite/no-important';

import { ModalBody, ModalFooter } from '@contactcentre-web/common/Modal';
import TextField from '@contactcentre-web/common/TextField';
import Button from '@contactcentre-web/common/Button';
import { PassengerData, PassengersData } from '@contactcentre-web/hooks/api/useBookingPassengers';

import messages from './messages';
import styles from './styles';

export const FORM_ID = 'AlterPassengersForm';

type PassengerWithType = PassengerData & { typeAndIndex: string };
export type PassengersWithType = {
  passengers: Array<PassengerWithType>;
};

export interface Props {
  onSubmitForm: (data: PassengersData) => void;
  isSubmitting: boolean;
}

const Passengers = ({ fields }: WrappedFieldArrayProps<PassengerWithType>) => {
  const { formatMessage } = useIntl();
  return (
    <>
      {fields.map((passengerId, index) => (
        <div key={passengerId} className={css(styles.passengerSection)}>
          <div className={css(styles.passengerId)}>{fields.get(index).typeAndIndex}</div>
          <TextField
            name={`${passengerId}.firstName`}
            label={formatMessage(messages.firstNameLabel)}
            type="text"
            messages={messages}
          />
          <TextField
            name={`${passengerId}.lastName`}
            label={formatMessage(messages.lastNameLabel)}
            type="text"
            messages={messages}
          />
        </div>
      ))}
    </>
  );
};

export const AlterPassengersForm = ({
  handleSubmit,
  onSubmitForm,
  isSubmitting,
  dirty,
}: Props & InjectedFormProps<PassengersWithType, Props>) => (
  <form
    onSubmit={handleSubmit(({ passengers }) =>
      onSubmitForm({
        passengers: passengers.map(({ id, firstName, lastName }) => ({ id, firstName, lastName })),
      })
    )}
    className={css(styles.alterForm)}
  >
    <ModalBody>
      <FieldArray name="passengers" component={Passengers} props={{}} />
    </ModalBody>
    <ModalFooter>
      <Button
        type="submit"
        variant="primary"
        size="large"
        disabled={!dirty || isSubmitting}
        loading={isSubmitting}
      >
        <FormattedMessage {...messages.saveChanges} />
      </Button>
    </ModalFooter>
  </form>
);

export const validate = ({
  passengers = [],
}: PassengersWithType): Record<string, Record<string, string>> => ({
  passengers: passengers.reduce(
    (errors, { firstName, lastName }, index) => ({
      ...errors,
      [index]: {
        firstName: !firstName ? 'validationFirstNameRequired' : undefined,
        lastName: !lastName ? 'validationLastNameRequired' : undefined,
      },
    }),
    {}
  ),
});

export default reduxForm<PassengersWithType, Props>({
  form: FORM_ID,
  validate,
})(AlterPassengersForm);
