import React, { MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { StatusMessage } from '@trainline/depot-web';

import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from '@contactcentre-web/common/Modal';
import Button from '@contactcentre-web/common/Button';
import TicketSummary from '@contactcentre-web/customer-order/components/TicketSummary/TicketSummary';
import Loader from '@contactcentre-web/common/Loader';
import type { TicketStatus } from '@contactcentre-web/redux-common/types/TicketStatus';

import type { LoadingStatus } from '../Tickets/Tickets';

import styles from './styles';
import messages from './messages';

interface Props {
  toggleModal: () => void;
  onReset: MouseEventHandler<HTMLButtonElement>;
  resetSeasonStatus?: LoadingStatus;
  ticketStatus: {
    statuses?: Array<TicketStatus>;
    isLoading: boolean;
  };
}

const ResetTicketsModal = ({
  toggleModal,
  onReset,
  resetSeasonStatus,
  ticketStatus: { statuses, isLoading },
}: Props) => {
  const canReset =
    statuses?.filter(({ canReset }) => canReset) &&
    statuses?.filter(({ canReset }) => canReset).length > 0;
  const downloaded = statuses?.filter(({ canReset }) => canReset).length;
  const activated = statuses?.filter(({ isActivated }) => isActivated)?.length;

  return (
    <Modal onClose={toggleModal}>
      <ModalHeader>
        <ModalTitle>
          <FormattedMessage {...messages.title} />
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <Loader />
        ) : (
          statuses?.map((status, index) => (
            <TicketSummary
              status={status}
              ticketNumber={index + 1}
              totalOfTickets={statuses.length}
              key={index}
            />
          ))
        )}
        <br />
        {isLoading ? null : (
          <FormattedMessage
            {...messages.description}
            values={{ canReset, downloaded, activated }}
          />
        )}
        {resetSeasonStatus === 'error' && (
          <div className={css(styles.errorContainer)}>
            <StatusMessage status="negative">
              <FormattedMessage {...messages.error} />
            </StatusMessage>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          variant="primary"
          size="large"
          onClick={onReset}
          loading={resetSeasonStatus === 'loading'}
          disabled={!canReset || resetSeasonStatus === 'loading'}
          testId="confirm-reset-tickets"
        >
          <FormattedMessage {...messages.confirmReset} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ResetTicketsModal;
