import type {
  FareRefundable,
  FeeRefundable,
  RailcardRefundable,
} from '@contactcentre-web/hooks/api/useOrderHistory';

const groupFareRefundablesBySameFare = (refundables: Array<FareRefundable | FeeRefundable>) => {
  const fareRefundables = refundables.filter(
    ({ type }) => type === 'fareRefundable'
  ) as Array<FareRefundable>;

  return fareRefundables.reduce(
    (acc: Record<string, Array<FareRefundable>>, curr: FareRefundable) => {
      const groupKey = `${curr.origin.name}-${curr.destination.name}`;
      acc[groupKey] = acc[groupKey] || [];
      acc[groupKey].push(curr);
      return acc;
    },
    {}
  );
};

type PassengerTypesCount = {
  type: string;
  count: number;
};

const groupPassengersByType = (passengers: Array<FareRefundable['passenger']>) =>
  Object.values(
    passengers.reduce<Record<string, PassengerTypesCount>>((acc, curr) => {
      const objKey = curr.type;
      if (!acc.hasOwnProperty(objKey)) {
        acc[objKey] = { type: objKey, count: 0 };
      }
      acc[objKey].count += 1;
      return acc;
    }, {})
  );

export type Passenger = FareRefundable['passenger'] | PassengerTypesCount;

export interface FareRefundableGroupedByTicketType {
  origin: FareRefundable['origin'];
  destination: FareRefundable['destination'];
  isReturnFare: FareRefundable['isReturnFare'];
  ticketType: FareRefundable['ticketType'];
  vendorRegion: FareRefundable['vendorRegion'];
  productInventoryReferences: FareRefundable['productInventoryReferences'];
  passengers: Array<Passenger>;
}

export const getFareRefundablesGroupedByTicketType = (
  refundables: Array<FareRefundable | FeeRefundable> = []
) => {
  const fareRefundablesGroupedBySameFare = groupFareRefundablesBySameFare(refundables);

  const group = {} as Record<string, Array<FareRefundableGroupedByTicketType>>;

  Object.values(fareRefundablesGroupedBySameFare).forEach((fare, index) => {
    const groupKey = Object.keys(fareRefundablesGroupedBySameFare)[index];

    const groupByTicketType = fare.reduce<Array<FareRefundableGroupedByTicketType>>((acc, curr) => {
      const fareWithSameTicketType = acc.find((fare) => fare.ticketType === curr.ticketType);

      if (fareWithSameTicketType) {
        fareWithSameTicketType.passengers.push(curr.passenger);
      } else {
        acc.push({
          origin: curr.origin,
          destination: curr.destination,
          isReturnFare: curr.isReturnFare,
          ticketType: curr.ticketType,
          passengers: [curr.passenger],
          vendorRegion: curr.vendorRegion,
          productInventoryReferences: curr.productInventoryReferences,
        });
      }

      return acc;
    }, []);

    const groupByTicketTypeAndPassengerType = groupByTicketType.map((fare) => ({
      ...fare,
      passengers: fare.passengers.find((passenger) => 'name' in passenger)
        ? fare.passengers
        : groupPassengersByType(fare.passengers),
    }));

    group[groupKey] = groupByTicketTypeAndPassengerType;
  });

  return group;
};

export interface FareRefundableGroupedByRefundStatus {
  status: FareRefundable['status'];
  passengers: Array<Passenger>;
  statusError?: FareRefundable['statusError'];
  reasonCode?: string;
}

export const getFareRefundablesGroupedByRefundStatus = (
  refundables: Array<FareRefundable | FeeRefundable> = []
) => {
  const fareRefundablesGroupedBySameFare = groupFareRefundablesBySameFare(refundables);

  const group = {} as Record<string, Array<FareRefundableGroupedByRefundStatus>>;

  Object.values(fareRefundablesGroupedBySameFare).forEach((fare, index) => {
    const groupKey = Object.keys(fareRefundablesGroupedBySameFare)[index];

    const groupedFareRefundables = fare.reduce<Array<FareRefundableGroupedByRefundStatus>>(
      (acc, curr) => {
        const groupedFares = acc.find(
          (fare) =>
            fare.status === curr.status &&
            fare.reasonCode === curr.reason?.description &&
            fare.statusError === curr.statusError
        );

        if (groupedFares) {
          groupedFares.passengers.push(curr.passenger);
        } else {
          acc.push({
            status: curr.status,
            passengers: [curr.passenger],
            statusError: curr.statusError || undefined,
            reasonCode: curr.reason?.description,
          });
        }

        return acc;
      },
      []
    );

    const groupedFareRefundablesByPassengerType = groupedFareRefundables.map((fare) => ({
      ...fare,
      passengers: fare.passengers.find((passenger) => 'name' in passenger)
        ? fare.passengers
        : groupPassengersByType(fare.passengers),
    }));

    group[groupKey] = groupedFareRefundablesByPassengerType;
  });

  return group;
};

export const getAllFareRefundStatuses = (
  faresGroupedByRefundStatus: Record<string, Array<FareRefundableGroupedByRefundStatus>>
) =>
  Object.values(faresGroupedByRefundStatus)
    .map((fares) => fares.map(({ status }) => status))
    .flat();

export const getRailcardRefundables = (
  refundables: Array<FareRefundable | FeeRefundable | RailcardRefundable> = []
) => refundables.filter(({ type }) => type === 'railcardRefundable') as Array<RailcardRefundable>;

export const getAllRailcardRefundStatuses = (railcardRefundables: Array<RailcardRefundable> = []) =>
  railcardRefundables.map(({ status }) => status);
