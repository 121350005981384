import { defineMessages } from 'react-intl';

export default defineMessages({
  update: {
    id: 'ChangeRoleForm.update',
    defaultMessage: 'Update',
  },
  roleIsRequired: {
    id: 'ChangeRoleForm.roleIsRequired',
    defaultMessage: 'Role is required',
  },
  isGlobalRoleUser: {
    id: 'ChangeRoleForm.isGlobalRoleUser',
    defaultMessage: 'This user cannot be edited because it is a global role user',
  },
});
