import { defineMessages } from 'react-intl';

export default defineMessages({
  ticketCount: {
    id: 'manualEntryConsoleEditForm.ticketCount',
    defaultMessage: 'Number of tickets',
  },
  recoveredAmount: {
    id: 'manualEntryConsoleEditForm.recoveredAmount',
    defaultMessage: 'Recover total (Gross) ({currency})',
  },
  adminFeeAmount: {
    id: 'manualEntryConsoleEditForm.adminFeeAmount',
    defaultMessage: 'Admin fee ({currency})',
  },
  cancelButton: {
    id: 'manualEntryConsoleEditForm.cancelButton',
    defaultMessage: 'Cancel',
  },
  saveButton: {
    id: 'manualEntryConsoleEditForm.saveButton',
    defaultMessage: 'Save and add to approved queue',
  },
  genericError: {
    id: 'manualEntryConsoleEditForm.genericError',
    defaultMessage: 'Something went wrong. Please try again.',
  },
  'MRR.ClaimStatusUpdateNotValid': {
    id: 'manualEntryConsoleEditForm.ClaimStatusUpdateNotValid',
    defaultMessage: 'Only pending claims can be edited. Has the claim been approved or deleted?',
  },
  'MRR.ClaimReasonCodeInvalid': {
    id: 'manualEntryConsoleEditForm.ClaimReasonCodeInvalid',
    defaultMessage: 'Check the reason code and try again.',
  },
  'MRR.ClaimRecoverableTooLarge': {
    id: 'manualEntryConsoleEditForm.ClaimRecoverableTooLarge',
    defaultMessage: 'The recoverable amount supplied is too large',
  },
  'MRR.ClaimAdminFeeTooLarge': {
    id: 'manualEntryConsoleEditForm.ClaimAdminFeeTooLarge',
    defaultMessage: 'The admin fee supplied is too large',
  },
  'MRR.SeasonValidFromNotValid': {
    id: 'manualEntryConsoleEditForm.SeasonValidFromNotValid',
    defaultMessage: 'The season valid from date is in the future and cannot be approved yet',
  },
  adminFeeAmountGreaterRecoveredAmount: {
    id: 'manualEntryConsoleEditForm.adminFeeAmountGreaterRecoveredAmount',
    defaultMessage: 'The admin fee should be less than the recovery amount',
  },
  multipleClaimsForOrder: {
    id: 'manualEntryConsoleEditForm.multipleClaimsForOrder',
    defaultMessage: 'A claim has already been added or submitted for this order.',
  },
});
