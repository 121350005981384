import React from 'react';
import { css } from 'aphrodite/no-important';

import styles from '../../../../styles';

export type TableItem = {
  customerId: string;
  managedGroupId: string;
  preferredLanguage: string;
  statusMessage: string;
};

interface RevocationResultsTableItemProps {
  data: TableItem;
}
const RevocationResultsTableItem = ({ data }: RevocationResultsTableItemProps) => {
  const mapStatus = (status: string) => {
    switch (status) {
      case 'OK':
        return { statusText: 'OK', styling: css(styles.textSuccess) };
      case 'AMS/Revoke returned NotFound':
        return { statusText: 'Not Found', styling: css(styles.textNotFound) };
      default:
        return { statusText: 'Error', styling: css(styles.textFailed) };
    }
  };

  const { customerId, managedGroupId, preferredLanguage, statusMessage } = data;
  const { statusText, styling } = mapStatus(statusMessage);

  return (
    <tr>
      <td>
        <span>{customerId}</span>
      </td>
      <td>
        <span>{managedGroupId}</span>
      </td>
      <td>
        <span>{preferredLanguage}</span>
      </td>
      <td>
        <span className={styling}>{statusText}</span>
      </td>
    </tr>
  );
};

export default RevocationResultsTableItem;
