import { defineMessages } from 'react-intl';

export default defineMessages({
  notChangeableReason: {
    id: 'ChangeOfJourney.BookingNotChangeableReason.notChangeableReason',
    defaultMessage: 'Why is this not changeable?',
  },
  notChangeableReason_replaced: {
    id: 'ChangeOfJourney.BookingNotChangeableReason.notChangeableReason_replaced',
    defaultMessage: 'Sorry, this booking has already been replaced.',
  },
  notChangeableReason_refunding: {
    id: 'ChangeOfJourney.BookingNotChangeableReason.notChangeableReason_refunding',
    defaultMessage:
      'Sorry, this booking cannot be changed. A refund has already been requested for this booking.',
  },
  notChangeableReason_refunded: {
    id: 'ChangeOfJourney.BookingNotChangeableReason.notChangeableReason_refunded',
    defaultMessage: 'Sorry, this booking cannot be changed. It has already been refunded.',
  },
  notChangeableReason_mixed: {
    id: 'ChangeOfJourney.BookingNotChangeableReason.notChangeableReason_mixed',
    defaultMessage: "Part of this booking can be changed but can't be done via this tool.",
  },
  notChangeableReason_modifiedexternally: {
    id: 'ChangeOfJourney.BookingNotChangeableReason.notChangeableReason_modifiedexternally',
    defaultMessage:
      'Sorry, this booking has been manually adjusted so we cannot process a change online.',
  },
  notChangeableReason_notsupported: {
    id: 'ChangeOfJourney.BookingNotChangeableReason.notChangeableReason_notsupported',
    defaultMessage:
      'Sorry, this booking cannot be changed. This could be because of fare type, validity period or expiry.',
  },
  notChangeableReason_vendornotsupported: {
    id: 'ChangeOfJourney.BookingNotChangeableReason.notChangeableReason_vendornotsupported',
    defaultMessage:
      'Sorry, we are unable to offer change for this booking. Please contact the vendor for more information.',
  },
  'notChangeableReason_outsidet&c': {
    id: 'ChangeOfJourney.BookingNotChangeableReason.notChangeableReason_outsidet&c',
    defaultMessage:
      'Sorry, this booking cannot be changed. This could be because of fare type, validity period or expiry.',
  },
  notChangeableReason_notDocumented: {
    id: 'ChangeOfJourney.BookingNotChangeableReason.notChangeableReason_notDocumented',
    defaultMessage: "Unknown reason received from server (reasonCode: '{reason}').",
  },
});
