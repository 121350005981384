import React, { useState, FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { IconClose, StatusMessage } from '@trainline/depot-web';

import Button from '@contactcentre-web/common/Button';
import Price from '@contactcentre-web/redux-common/types/Price';

import messages from './messages';
import styles from './styles';

interface Props {
  type: string;
  amount: Price;
  orderReference: string;
}

const CompensationApprovalConfirmationBox: FunctionComponent<Props> = ({
  type,
  amount,
  orderReference,
}) => {
  const [visible, setVisible] = useState(true);

  if (!visible) {
    return null;
  }

  const typeMessageKey = `title_${type}` as keyof typeof messages;
  const bodyMessageKey = `body_${type}` as keyof typeof messages;

  return (
    <div className={css(styles.container)}>
      <StatusMessage status="positive">
        <h3 className={css(styles.header)}>
          <FormattedMessage {...messages[typeMessageKey]} values={{ orderReference }} />
          <Button
            variant="ghost"
            testId="compensation-approval-box-close"
            onClick={() => setVisible(false)}
          >
            <IconClose />
          </Button>
        </h3>
        <div>
          <FormattedMessage
            {...messages[bodyMessageKey]}
            values={{
              ...amount,
            }}
          />
        </div>
      </StatusMessage>
    </div>
  );
};

export default CompensationApprovalConfirmationBox;
