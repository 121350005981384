import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import RefundCard from '../RefundCard/RefundCard';
import { RequestData } from '../../selectors';

import messages from './messages';

interface Props {
  requests: RequestData;
  returnUrl: string;
}

const RefundsList: FunctionComponent<Props> = ({ requests, returnUrl }) =>
  requests.length === 0 ? (
    <div>
      <FormattedMessage {...messages.noPendingRefunds} />
    </div>
  ) : (
    <section>
      {requests.map((request) => (
        <RefundCard key={request.id} request={request} returnUrl={returnUrl} />
      ))}
    </section>
  );

export default RefundsList;
