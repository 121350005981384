import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import Tooltip from '@contactcentre-web/common/Tooltip';

import messages from './messages';
import styles from './styles';

const NotVoidableTooltip = ({ button, reasons }) =>
  !reasons || reasons.length === 0 ? null : (
    <Tooltip orientation="Above" anchor={button}>
      <span className={css(styles.tooltipContent)}>
        {reasons
          .map((reason, index) => {
            const message =
              messages[reason] === undefined ? messages.notSupported : messages[reason];
            return <FormattedMessage key={index} {...message} />;
          })
          .reduce((prev, curr) => [prev, ', ', curr])}
      </span>
    </Tooltip>
  );

NotVoidableTooltip.propTypes = {
  button: PropTypes.object.isRequired,
  reasons: PropTypes.arrayOf(PropTypes.string),
};

export default NotVoidableTooltip;
