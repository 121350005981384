import { defineMessages } from 'react-intl';

export default defineMessages({
  validFrom: {
    id: 'ProductDetails.validFrom',
    defaultMessage: 'Valid from:',
  },
  validTo: {
    id: 'ProductDetails.validTo',
    defaultMessage: 'Valid to:',
  },
  passenger: {
    id: 'ProductDetails.passenger',
    defaultMessage: '1 {passengerType}',
  },
});
