import { defineMessages } from 'react-intl';

export default defineMessages({
  buttonBack: {
    id: 'manualEntryConsoleEditHeader.buttonBack',
    defaultMessage: 'Back to queue',
  },
  title: {
    id: 'manualEntryConsoleEditHeader.title',
    defaultMessage: 'Edit claim',
  },
  orderReference: {
    id: 'manualEntryConsoleEditHeader.orderReference',
    defaultMessage: 'Order reference: {orderReference}',
  },
});
