import { StyleSheet } from 'aphrodite/no-important';
import { borderRadius, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  anchor: {
    display: 'inline-block',
  },
  balloon: {
    position: 'absolute',
    zIndex: '100',
    maxWidth: '50%',
    left: 0,
    top: 0,
    pointerEvents: 'none',
  },
  balloonContainer: {
    position: 'relative',
    display: 'inline-block',
    pointerEvents: 'auto',
  },
  balloonContent: {
    position: 'relative',
    display: 'inline-block',
    zIndex: '2',
  },
  boxWithArrow: {
    display: 'inline-block',
    position: 'relative',
    background: '#ffffff',
    border: '1px solid #d3d3d3',
    padding: spacers.dp_s12,
    borderRadius: borderRadius.dp_r4,
  },
  arrow: {
    position: 'relative',
    zIndex: '1',
    border: 'solid 1px #d3d3d3',
    transform: 'rotate(45deg)',
    width: '11px',
    height: '11px',
    backgroundColor: 'white',
  },
  arrowAbove: {
    borderTop: 'none',
    borderLeft: 'none',
    marginTop: '-5px',
    marginLeft: '-5px',
  },
  arrowBelow: {
    borderBottom: 'none',
    borderRight: 'none',
    marginTop: '5px',
    marginLeft: '-5px',
    marginBottom: '-5px',
  },
});
