import { defineMessages } from 'react-intl';

export default defineMessages({
  title_confirm: {
    id: 'CompensationApprovalConfirmationBox.title_confirm',
    defaultMessage: 'Compensation for Order {orderReference} complete',
  },
  title_reject: {
    id: 'CompensationApprovalConfirmationBox.title_reject',
    defaultMessage: 'Compensation for Order {orderReference} rejected',
  },
  body_confirm: {
    id: 'CompensationApprovalConfirmationBox.body_confirm',
    defaultMessage:
      '{amount, number, withDecimalPlaces} {currencyCode} will be refunded to the customer. Please inform the customer that the compensation may take up to 24 hours to be reflected in the recipient account. The processed compensation can be viewed in the order history log.',
  },
  body_reject: {
    id: 'CompensationApprovalConfirmationBox.body_reject',
    defaultMessage: 'Compensation has been rejected.',
  },
});
