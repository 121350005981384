import { StyleSheet } from 'aphrodite/no-important';
import {
  fonts,
  typography,
  spacers,
  borderRadius,
} from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  paymentStatusHeader: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    fontWeight: fonts.dp_font_weight_semibold,
    textAlign: 'right',
    color: colors.blue.royal,
    textTransform: 'uppercase',
  },
  tooltipWrapper: {
    textAlign: 'right',
  },
  errorList: {
    minWidth: '150px',
    listStyleType: 'none',
  },
  paymentStatus: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    borderRadius: borderRadius.dp_r12,
    padding: `0 ${spacers.dp_s8}`,
    width: 'max-content',
    fontWeight: fonts.dp_font_weight_bold,
  },
  status_paid: {
    color: colors.positive.dark,
    backgroundColor: colors.positive.light,
  },
  status_failed: {
    color: colors.loud.base,
    backgroundColor: colors.negative.light,
  },
  status_unknown: {
    color: colors.gray.alpha80,
    backgroundColor: colors.gray.alpha4,
  },
});
