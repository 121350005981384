import { defineMessages } from 'react-intl';

export default defineMessages({
  approved: {
    id: 'manualEntryConsoleStatusChangeFailed.approved',
    defaultMessage:
      'Approving claim failed. Try again. If issue persists, contact the Service Desk.',
  },
  rejected: {
    id: 'manualEntryConsoleStatusChangeFailed.rejected',
    defaultMessage: 'We can’t delete the claim. Please try again.',
  },
  pending: {
    id: 'manualEntryConsoleStatusChangeFailed.pending',
    defaultMessage: 'We can’t cancel this approved claim. Please try again.',
  },
  'MRR.SeasonValidFromNotValid': {
    id: 'manualEntryConsoleStatusChangeFailed.SeasonValidFromNotValid',
    defaultMessage: 'The season valid from date is in the future and cannot be approved yet.',
  },
});
