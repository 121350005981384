import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import Menu from '../Menu';
import Button from '../Button';

import messages from './messages';
import styles, { menuStyles } from './styles';

const MoreOptionsButton = ({ items, styleSheet, testId }) => {
  if (items && items.some((child) => child)) {
    return (
      <Menu
        data-test="MoreOptionsMenu"
        styleSheet={menuStyles}
        handle={
          <div>
            <Button variant="tertiary" size="small" testId={testId} styleSheet={styleSheet}>
              <div className={css(styles.tripleDot)}>
                <span className={css(styles.dot)} />
                <span className={css(styles.dot)} />
                <span className={css(styles.dot)} />
              </div>
            </Button>
          </div>
        }
        groups={[
          {
            header: <FormattedMessage {...messages.moreOptions} />,
            items: items.map((item) => ({
              ...item,
              className: css(menuStyles.item),
            })),
          },
        ]}
      />
    );
  }
  return null;
};

MoreOptionsButton.propTypes = {
  styleSheet: PropTypes.object,
  testId: PropTypes.string,
  items: PropTypes.array,
};
export default MoreOptionsButton;
