import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'aphrodite/no-important';

import styles from './styles';

const MidDot = ({ styleSheet = [] }) => (
  <span className={css(styles.middot, ...styleSheet)}>&middot;</span>
);

MidDot.propTypes = {
  styleSheet: PropTypes.arrayOf(PropTypes.object),
};

export default MidDot;
