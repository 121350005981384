import { StyleSheet } from 'aphrodite/no-important';
import { typography, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  action: {
    fontSize: typography.dp_hero_font_size,
    paddingLeft: spacers.dp_s16,
  },
});
