import { StyleSheet } from 'aphrodite/no-important';
import { typography, spacers, fonts } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  container: {
    width: '100%',
    paddingTop: spacers.dp_s24,
    display: 'flex',
    flexDirection: 'row',
    background: '#fff',
    padding: `${spacers.dp_s24} ${spacers.dp_s64}`,
  },
  col: {
    wordBreak: 'break-word',
    flex: '1 1 auto',
    padding: `0 ${spacers.dp_s8}`,
    ':first-child': {
      paddingLeft: 0,
    },
  },
  title: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    color: colors.gray.alpha30,
    textTransform: 'uppercase',
    marginBottom: spacers.dp_s4,
    fontWeight: fonts.dp_font_weight_semibold,
  },
  railcardName: {
    fontFamily: typography.dp_title2_font_family,
    fontSize: typography.dp_title2_font_size,
    fontWeight: fonts.dp_font_weight_bold,
    lineHeight: typography.dp_title2_line_height,
    letterSpacing: '-0.3px', //TODO: replace with depot-web typography when updated in depot-web
    color: colors.gray.alpha80,
    paddingTop: spacers.dp_s12,
  },
  price: {
    textAlign: 'right',
    fontSize: typography.dp_body_font_size,
  },
  status: {
    fontWeight: fonts.dp_font_weight_bold,
    fontSize: typography.dp_mini_font_size_m,
  },
  extraMargin: {
    marginTop: spacers.dp_s12,
  },
});
