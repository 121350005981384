import { defineMessages } from 'react-intl';

export default defineMessages({
  journeyLeg: {
    id: 'SupersededBooking.Leg.journeyLeg',
    defaultMessage: 'Leg {legNum} of {legCount}',
  },

  direction: {
    id: 'SupersededBooking.Leg.direction',
    defaultMessage: '{origin} to {destination}',
  },

  departAt: {
    id: 'SupersededBooking.Leg.departAt',
    defaultMessage: 'Dep.',
  },

  arriveAt: {
    id: 'SupersededBooking.Leg.arriveAt',
    defaultMessage: 'Arr.',
  },

  routeDuration: {
    id: 'SupersededBooking.Leg.routeDuration',
    defaultMessage: 'Duration:',
  },

  originStation: {
    id: 'SupersededBooking.Leg.originStation',
    defaultMessage: '{originStation}',
  },

  destinationStation: {
    id: 'SupersededBooking.Leg.destinationStation',
    defaultMessage: '{destinationStation}',
  },

  originCity: {
    id: 'SupersededBooking.Leg.originCity',
    defaultMessage: '({originCity})',
  },

  destinationCity: {
    id: 'SupersededBooking.Leg.destinationCity',
    defaultMessage: '({destinationCity})',
  },

  transportInformation: {
    id: 'SupersededBooking.Leg.transportInformation',
    defaultMessage: '{carrier}',
  },
});
