import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import CountryName from '@contactcentre-web/common/CountryName/CountryName';

import messages from './messages';
import styles from './styles';

const DeliveryAddress = ({ firstName, lastName, address }) => {
  if (!address) {
    return null;
  }

  const {
    addressLine1,
    addressLine2,
    addressLine3,
    addressLine4,
    addressLine5,
    countryCode,
    postcode,
  } = address;

  const countryName = <CountryName countryCode={countryCode} />;

  return (
    <>
      <div className={css(styles.grayLabel)}>
        <FormattedMessage {...messages.deliveryAddress} />
        {'  '}
      </div>
      <div className={css(styles.address)}>
        <div>
          {firstName} {lastName}
        </div>
        {[
          addressLine1,
          addressLine2,
          addressLine3,
          addressLine4,
          addressLine5,
          countryName,
          postcode,
        ]
          .filter((val) => !!val)
          .map((val, idx) => (
            <p key={idx}>{val}</p>
          ))}
      </div>
    </>
  );
};

DeliveryAddress.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  address: PropTypes.shape({
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    addressLine3: PropTypes.string,
    addressLine4: PropTypes.string,
    addressLine5: PropTypes.string,
    countryCode: PropTypes.string,
    postcode: PropTypes.string,
  }),
};

export default DeliveryAddress;
