import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  form: {
    marginTop: spacers.dp_s24,
  },
  fieldsColumn: {
    display: 'flex',
  },
  lastNameField: {
    marginLeft: spacers.dp_s16,
  },
  usernameField: {
    marginTop: spacers.dp_s16,
    width: '193px',
  },
  button: {
    marginTop: spacers.dp_s24,
  },
});
