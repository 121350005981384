import React, { useState, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { css } from 'aphrodite/no-important';
import { Heading, Paragraph } from '@trainline/depot-web';

import Logo from '@contactcentre-web/common/Logo/Logo';
import Button from '@contactcentre-web/common/Button';

import messages from './messages';
import styles from './styles';

export const RELOAD_DELAY = 5;

const AppUpdatePage = () => {
  const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout> | null>();
  const history = useHistory();

  const reload = useRef(() => {
    history.goBack();
  });

  const reloadNow = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }

    reload.current();
  };

  useEffect(() => {
    const timeout = setTimeout(reload.current, RELOAD_DELAY * 1000);
    setTimeoutId(timeout);

    return () => clearTimeout(timeout);
  }, [reload]);

  return (
    <div className={css(styles.container)}>
      <Logo />
      <div className={css(styles.row)}>
        <Heading typeStyle="title1" as="h1" color="base">
          <FormattedMessage {...messages.appUpdating} />
        </Heading>
      </div>
      <div className={css(styles.row)}>
        <Paragraph typeStyle="body" as="p" color="base" fontWeight="regular">
          <FormattedMessage
            {...messages.refreshMessage}
            values={{
              delay: RELOAD_DELAY,
            }}
          />
        </Paragraph>
      </div>
      <div className={css(styles.row)}>
        <Button variant="primary" size="large" testId="AppUpdateReloadNow" onClick={reloadNow}>
          <FormattedMessage {...messages.reloadNow} />
        </Button>
      </div>
    </div>
  );
};

export default AppUpdatePage;
