import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

import { info } from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: spacers.dp_s4,
    color: info.base,
  },
});
