import { MessageDescriptor } from 'react-intl';

import { FetchError } from '@contactcentre-web/utils/error';

export const useErrorMapping = (
  error: FetchError | null,
  messages: Record<string, MessageDescriptor>,
  defaultMessageKey: keyof typeof messages
) => {
  const errors = error?.errors?.errors;
  const allErrors = Array.isArray(errors)
    ? errors.flatMap((err) => (err.meta?.innerErrors ? err.meta.innerErrors : err))
    : [];
  const knownError = allErrors?.find(({ code }) => Object.keys(messages).includes(code));
  const knownErrorMessage = knownError?.code as keyof typeof messages;
  return knownErrorMessage ? messages[knownErrorMessage] : messages[defaultMessageKey];
};
