import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';

import styles from './styles';
import messages from './messages';

const ExchangeSuccessful = () => (
  <div className={css(styles.container)}>
    <div className={css([styles.notice, styles.successful])}>
      <h4>
        <FormattedMessage {...messages.titleSuccess} />
      </h4>
    </div>
  </div>
);

export default ExchangeSuccessful;
