import React from 'react';
import { useParams } from 'react-router';

import { useManualEntryConsoleClaimItem } from '@contactcentre-web/hooks/api/useManualEntryConsole';

import ManualEntryConsoleEditForm from './components/ManualEntryConsoleEditForm/ManualEntryConsoleEditForm';
import ManualEntryConsoleEditHeader from './components/ManualEntryConsoleEditHeader/ManualEntryConsoleEditHeader';

interface Params {
  claimId: string;
}

const ManualEntryConsoleEdit = () => {
  const { claimId } = useParams<Params>();
  const { data, isLoading } = useManualEntryConsoleClaimItem(claimId);

  return (
    <>
      <ManualEntryConsoleEditHeader orderReference={data?.orderReference} />
      <ManualEntryConsoleEditForm manualEntryConsoleItem={data} isLoading={isLoading} />
    </>
  );
};

export default ManualEntryConsoleEdit;
