import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { Paragraph } from '@trainline/depot-web';

import messages from './messages';
import styles from './styles';
interface FulfilmentConverterFailProps {
  error: string;
}

export const FulfilmentConverterFail = ({ error }: FulfilmentConverterFailProps) => {
  const getMessage = () => {
    switch (error.toLowerCase()) {
      case 'unhandlederror':
        return (
          <FormattedMessage
            data-testid="fulfilment-converter-error-message"
            {...messages.travelProductNotFound}
          />
        );
      case 'noeligibilityfound':
        return (
          <FormattedMessage
            data-testid="fulfilment-converter-error-message"
            {...messages.noEligibilityFound}
          />
        );
      default:
        return (
          <FormattedMessage
            data-testid="fulfilment-converter-error-message"
            {...messages.bookingNotConverted}
          />
        );
    }
  };
  return (
    <div className={css(styles.container)}>
      <Paragraph typeStyle="body" as="p" color="base" fontWeight="regular">
        {getMessage()}
      </Paragraph>
    </div>
  );
};

export default FulfilmentConverterFail;
