import { useMutation, MutationOptions } from 'react-query';

import request from '@contactcentre-web/utils/request';

export type RevocationBatchResult = {
  successCount: number;
  failureCount: number;
  incidentReference: string;
  results: Array<RevocationResult>;
};

export type RevocationResult = {
  customerId: string;
  managedGroupId: string;
  preferredLanguage: string;
  statusMessage: string;
};

export type RevocationBatchQuery = {
  customerIds: Array<string>;
  incidentReference: string;
};

export const useCustomerRevocation = (
  mutationOptions: MutationOptions<RevocationBatchResult, unknown, RevocationBatchQuery, unknown>
) =>
  useMutation<RevocationBatchResult, unknown, RevocationBatchQuery, unknown>(
    (revocationQuery) =>
      request(`/api/Customers/passwords/internal/revoke`, {
        method: 'POST',
        body: revocationQuery,
      }),
    mutationOptions
  );
