import { StyleSheet } from 'aphrodite/no-important';
import { palettes } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  refundableRoundIcon: {
    background: palettes.dp_app_positive_mid,
    display: 'inline-flex',
    color: palettes.dp_app_text_inverted,
    borderRadius: '50%',
  },

  refundableRoundIconCross: {
    background: palettes.dp_app_negative_mid,
  },
});
