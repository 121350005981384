import { StyleSheet } from 'aphrodite/no-important';
import { palettes, borderRadius, spacers } from '@trainline/depot-web/css/themes/trainline';

export const expandableStyles = (isOpened: boolean) =>
  StyleSheet.create({
    wrapper: {
      padding: spacers.dp_s24,
      borderColor: isOpened ? 'transparent' : palettes.dp_app_border_base,
      borderRadius: borderRadius.dp_r8,
      boxShadow: isOpened ? `0 0 0 2px ${palettes.dp_comp_input_border_focus}` : 'none',
      margin: `${spacers.dp_s16} 0`,
    },
  });

export default StyleSheet.create({
  expandableWrapper: {
    borderColor: palettes.dp_app_border_base,
    borderRadius: borderRadius.dp_r8,
  },
  expandableAnchor: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginRight: spacers.dp_s16,
  },
  expandableChevron: {
    backgroundColor: palettes.dp_app_backdrop_dark,
    padding: spacers.dp_s8,
    justifyContent: 'center',
    borderRadius: borderRadius.dp_r4,
  },
  requestedBy: {
    textAlign: 'right',
  },
  approvalItemContainer: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr auto',
    columnGap: spacers.dp_s48,
    paddingTop: spacers.dp_s16,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacers.dp_s12,
  },
  approvalItemRefundValue: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  itemDetailSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacers.dp_s24,
  },
  succeed: {
    opacity: 0,
    transition: 'opacity 500ms ease-in-out, margin-bottom 500ms ease-in-out',
    pointerEvents: 'none',
  },
});
