import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { StatusMessage } from '@trainline/depot-web';

import Link from '@contactcentre-web/common/Link';

import styles from './styles';
import messages from './messages';

export interface Props {
  status: 'success' | 'error';
  name: string;
  onReloadOrder: () => void;
}

const CancelRailcardStatus = ({ status, name, onReloadOrder }: Props) => (
  <div className={css(styles.container)}>
    <StatusMessage status={status === 'success' ? 'positive' : 'negative'}>
      {status === 'success' ? (
        <FormattedMessage
          {...messages.success}
          values={{
            railcardName: name,
            a: (msg: string) => (
              <Link linkType="action" onClick={onReloadOrder} testId="cancel-railcard-success">
                {msg}
              </Link>
            ),
          }}
        />
      ) : (
        <FormattedMessage {...messages.error} />
      )}
    </StatusMessage>
  </div>
);

export default CancelRailcardStatus;
