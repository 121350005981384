import { defineMessages } from 'react-intl';

export default defineMessages({
  defaultManagedGroupLabel: {
    id: 'ManagedGroupAndRoles.defaultManagedGroupLabel',
    defaultMessage: 'Default managed group',
  },
  additionalManagedGroupLabel: {
    id: 'ManagedGroupAndRoles.additionalManagedGroupLabel',
    defaultMessage: 'Additional managed group',
  },
  roleLabel: {
    id: 'ManagedGroupAndRoles.roleLabel',
    defaultMessage: 'Role',
  },
});
