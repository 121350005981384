import React, { useEffect } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { StatusMessage } from '@trainline/depot-web';

import Loader from '@contactcentre-web/common/Loader';
import Button from '@contactcentre-web/common/Button/Button';
import {
  PaperTicketQueueName,
  Ticket,
  usePaperTicketQueueImage,
  useMarkAsUnableToReadPaperTicket,
  useMarkAsNoMatchInSystem,
} from '@contactcentre-web/hooks/api/usePaperTicketQueue';

import messages from './messages';
import styles from './styles';

interface PaperTicketImageProps {
  ticket: Ticket;
  currentTab: PaperTicketQueueName;
}

const PaperTicketImage = ({ ticket, currentTab }: PaperTicketImageProps) => {
  const { scannedDate, batchNumber, sequenceNumber } = ticket ?? {};
  const formattedDate = scannedDate && new Date(scannedDate).toISOString().split('T')[0];

  const {
    data,
    isLoading,
    isError: failedToLoadImageError,
  } = usePaperTicketQueueImage(formattedDate, batchNumber, sequenceNumber);

  const {
    mutate: markAsUnableToRead,
    isError: failedToMarkAsUnableToReadError,
    reset: resetMarkAsUnableToRead,
    isLoading: isMarkingAsUnableToRead,
  } = useMarkAsUnableToReadPaperTicket(currentTab);

  const {
    mutate: markAsNoMatchInSystem,
    isError: failedToMarkAsNoMatchError,
    reset: resetMarkAsNoMatchInSystem,
    isLoading: isMarkingAsNoMatchInSystem,
  } = useMarkAsNoMatchInSystem(currentTab);

  useEffect(() => {
    resetMarkAsNoMatchInSystem();
    resetMarkAsUnableToRead();
  }, [ticket]);
  return (
    <div className={css(styles.container)}>
      {isLoading && <Loader />}
      {failedToLoadImageError && (
        <div className={css(styles.errorContainer)}>
          <StatusMessage status="negative">
            <FormattedMessage {...messages.imageNotFoundError} />
          </StatusMessage>
        </div>
      )}
      {failedToMarkAsNoMatchError && (
        <div className={css(styles.errorContainer)}>
          <StatusMessage status="negative">
            <FormattedMessage {...messages.markAsNoMatchInSystemError} />
          </StatusMessage>
        </div>
      )}
      {failedToMarkAsUnableToReadError && (
        <div className={css(styles.errorContainer)}>
          <StatusMessage status="negative">
            <FormattedMessage {...messages.unableToReadTicketError} />
          </StatusMessage>
        </div>
      )}
      {data && (
        <img
          className={css(styles.image)}
          src={data}
          alt={`Ticket: ${formattedDate}/${ticket?.batchNumber}/${ticket?.sequenceNumber}`}
        />
      )}
      {currentTab == PaperTicketQueueName.Unmatched && (
        <Button
          variant="tertiary"
          size="small"
          styleSheet={styles.button}
          onClick={() => markAsUnableToRead(ticket)}
          loading={isMarkingAsUnableToRead}
          showLoadingIndicator={true}
        >
          <FormattedMessage {...messages.markAsUnableToReadTicket} />
        </Button>
      )}
      {currentTab == PaperTicketQueueName.FailedValidation && (
        <Button
          variant="tertiary"
          size="small"
          styleSheet={styles.button}
          onClick={() => markAsNoMatchInSystem(ticket)}
          loading={isMarkingAsNoMatchInSystem}
        >
          <FormattedMessage {...messages.markAsNoMatchInSystem} />
        </Button>
      )}
    </div>
  );
};

export default PaperTicketImage;
