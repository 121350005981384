import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from '@contactcentre-web/common/Modal';
import Button from '@contactcentre-web/common/Button';
import Link from '@contactcentre-web/common/Link';
import { actions } from '@contactcentre-web/authentication/redux/managedGroup/module';

import styles from './styles';
import messages from './messages';

const ChangeManagedGroupModal: FunctionComponent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isVisible) {
      dispatch(actions.resetState());
    }
  }, [isVisible]);

  const history = useHistory();
  const onChangeManagedGroup = () => {
    history.push('/select-managed-group');
    setIsVisible(false);
  };

  return (
    <>
      {isVisible && (
        <Modal onClose={() => setIsVisible((prevIsVisible) => !prevIsVisible)}>
          <ModalHeader>
            <ModalTitle>
              <FormattedMessage {...messages.modalTitle} />
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <FormattedMessage {...messages.modalDescription} />
          </ModalBody>
          <ModalFooter contentAlignment="spaceBetween">
            <Button
              variant="tertiary"
              size="large"
              onClick={() => setIsVisible((prevIsVisible) => !prevIsVisible)}
            >
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button
              variant="primary"
              size="large"
              onClick={onChangeManagedGroup}
              testId="confirmation-change-button"
            >
              <FormattedMessage {...messages.changeManagedGroup} />
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <Link
        linkType="action"
        styleSheet={[styles.text, styles.link]}
        onClick={() => setIsVisible((prevIsVisible) => !prevIsVisible)}
        testId="change-managed-group-link"
      >
        <FormattedMessage {...messages.changeManagedGroup} />
      </Link>
    </>
  );
};

export default ChangeManagedGroupModal;
