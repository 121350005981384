import { defineMessages } from 'react-intl';

export default defineMessages({
  firstNameLabel: {
    id: 'EditCustomerProfileForm.firstNameLabel',
    defaultMessage: 'First name',
  },
  lastNameLabel: {
    id: 'EditCustomerProfileForm.lastNameLabel',
    defaultMessage: 'Last name',
  },
  customerLanguageLabel: {
    id: 'EditCustomerProfileForm.customerLanguageLabel',
    defaultMessage: 'Customer language',
  },
  emailAddressLabel: {
    id: 'EditCustomerProfileForm.emailAddressLabel',
    defaultMessage: 'Email address',
  },
  preferredLanguageLabel: {
    id: 'EditCustomerProfileForm.preferredLanguageLabel',
    defaultMessage: 'Language',
  },
  updateDetailsButton: {
    id: 'EditCustomerProfileForm.updateDetailsButton',
    defaultMessage: 'Update customer details',
  },
  firstNameErrorMessage: {
    id: 'EditCustomerProfileForm.firstNameErrorMessage',
    defaultMessage: 'First name must be between 1 and 30 characters',
  },
  lastNameErrorMessage: {
    id: 'EditCustomerProfileForm.lastNameErrorMessage',
    defaultMessage: 'Last name must be between 1 and 30 characters',
  },
  emailAddressErrorMessage: {
    id: 'EditCustomerProfileForm.emailAddressErrorMessage',
    defaultMessage: 'Please enter a valid email address',
  },
  preferredCustomerLanguageErrorMessage: {
    id: 'EditCustomerProfileForm.preferredCustomerLanguageErrorMessage',
    defaultMessage: 'Please select a customer language',
  },
});
