import React, { MouseEventHandler, FunctionComponent } from 'react';
import { css } from 'aphrodite/no-important';
import { IconRefresh } from '@trainline/depot-web';

import Button from '@contactcentre-web/common/Button';

import styles from './styles';

interface Props {
  isLoading: boolean;
  isDisabled: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  testId?: string;
}

const RecalculateButton: FunctionComponent<Props> = ({
  isLoading,
  isDisabled,
  onClick,
  testId,
  children,
}) => (
  <Button
    variant="primary"
    size="small"
    onClick={onClick}
    disabled={isDisabled}
    loading={isLoading}
    showLoadingIndicator={false}
    testId={testId}
  >
    <div className={css(styles.buttonContent)}>
      <IconRefresh className={css([styles.icon, isLoading && styles.refreshAnimation])} />
      <span className={css(styles.buttonText)}>{children}</span>
    </div>
  </Button>
);

export default RecalculateButton;
