import {
  useMutation,
  useQueryClient,
  useInfiniteQuery,
  useQuery,
  MutationOptions,
} from 'react-query';
import { saveAs } from 'file-saver';

import request, { HTTPMethod } from '@contactcentre-web/utils/request';
import { FetchError } from '@contactcentre-web/utils/error';
import Price from '@contactcentre-web/redux-common/types/Price';

import { PaginationResponse } from './sharedTypes';

const CLAIMS_QUERY_KEY = 'manualEntryConsoleClaims';
const CLAIM_ITEM_QUERY_KEY = 'manualEntryConsoleClaimItem';

interface Station {
  name: string;
  stationCode: string;
}

interface Agent {
  displayName: string;
  userName: string;
}

export enum ManualEntryConsoleProductType {
  singleUseTravelProduct = 'singleUseTravelProduct',
  seasonTravelProduct = 'seasonTravelProduct',
  railcardProduct = 'railcardProduct',
}

export enum ClaimStatus {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum ClaimFilter {
  SingleUseTravelProduct = 'SingleUseTravelProduct',
  RailcardProduct = 'RailcardProduct',
  SeasonTravelProduct = 'SeasonTravelProduct',
}

export enum ClaimSource {
  Manual = 'manual',
  Compensation = 'compensation',
  Unknown = 'unknown',
}

export interface ManualEntryConsoleClaimItemResponse {
  id: string;
  createdAt: Date;
  createdBy: Agent;
  orderReference: string;
  platform: string;
  status: string;
  source: ClaimSource;
  orderTotalAmount: Price;
  recoveredGrossAmount: Price;
  adminFeeAmount: Price;
  refundAmount: Price;
  recoveredNetAmount: Price;
  origin: Station;
  destination: Station;
  description: string;
  ticketCount: number;
  reasonCode: {
    id: string;
    description: string;
  } | null;
  productType: ManualEntryConsoleProductType;
  multipleClaimsForOrder: boolean;
}

interface ReportLink {
  rel: string; // "download"
  href: string;
  meta: {
    method: HTTPMethod;
  };
}
export interface ManualEntryConsoleReportResponse {
  id: string;
  links: ReportLink[];
}

const BFF_API_PREFIX = '/manual-refund-recovery';

interface ManualEntryConsoleApprovalRequest {
  itemsPerPage: number;
  claimStatus: string;
  productTypes: ClaimFilter[];
  reasonCode?: string;
}

const fetchItems = ({
  pageParam = 1,
  queryKey,
}: {
  pageParam?: number;
  queryKey: (string | ManualEntryConsoleApprovalRequest)[];
}) => {
  // first element is the query key string, we can ignore/skip it
  const [, queryParams] = queryKey;
  const { itemsPerPage, claimStatus, productTypes, reasonCode } =
    queryParams as ManualEntryConsoleApprovalRequest;
  const productTypeQuery = productTypes
    .sort((p1, p2) => (p1 > p2 ? 1 : -1))
    .map((productType) => `productTypes=${productType}`);

  const query = [
    `page=${pageParam}`,
    `perPage=${itemsPerPage}`,
    `status=${claimStatus}`,
    ...(reasonCode ? [`reasonCodeId=${reasonCode}`] : []),
    ...productTypeQuery,
  ].join('&');

  return request<PaginationResponse<ManualEntryConsoleClaimItemResponse>>(
    `${BFF_API_PREFIX}/claims?${query}`
  );
};

export const useManualEntryConsoleApprovalQueue = (
  itemsPerPage: number,
  claimStatus: ClaimStatus,
  productTypes: ClaimFilter[],
  reasonCode?: string
) =>
  useInfiniteQuery(
    [CLAIMS_QUERY_KEY, { itemsPerPage, claimStatus, productTypes, reasonCode }],
    fetchItems,
    {
      getNextPageParam: (lastPage) => (lastPage.page || 0) + 1,
    }
  );

export const useManualEntryConsoleClaimStatus = (id: string, currentClaimStatus: ClaimStatus) => {
  const queryClient = useQueryClient();
  const TIME_TO_REFRESH_AND_ANIMATE = 500; // 0.5 seconds

  return useMutation<unknown, FetchError, ClaimStatus, unknown>(
    (newClaimStatus: ClaimStatus) =>
      request(`${BFF_API_PREFIX}/claims/${id}/status`, {
        method: 'PATCH',
        body: { status: newClaimStatus },
      }),
    {
      onSuccess: () => {
        setTimeout(() => {
          queryClient.invalidateQueries([CLAIMS_QUERY_KEY, { claimStatus: currentClaimStatus }]);
        }, TIME_TO_REFRESH_AND_ANIMATE);
      },
      onError: () => {
        setTimeout(() => {
          queryClient.invalidateQueries([CLAIMS_QUERY_KEY, { claimStatus: currentClaimStatus }]);
        }, TIME_TO_REFRESH_AND_ANIMATE);
      },
    }
  );
};

export const useManualEntryConsoleReport = () => {
  const queryClient = useQueryClient();
  const {
    mutate: postGenerateReport,
    data,
    isError: isErrorGenerate,
    isSuccess: isSuccessGenerate,
    isLoading: isLoadingGenerate,
  } = useMutation(() =>
    request<ManualEntryConsoleReportResponse>(`${BFF_API_PREFIX}/report`, {
      method: 'POST',
    })
  );
  const reportLink = data?.links?.find(({ rel }) => rel === 'download');
  const method = reportLink?.meta.method;
  const link = reportLink?.href ?? '';

  const { isError: isErrorDownload, isLoading: isLoadingDownload } = useQuery(
    'mec-download-file',
    () =>
      request<Response>(`${BFF_API_PREFIX}/${link}`, {
        method,
      }),
    {
      enabled: !!isSuccessGenerate,
      onSuccess: (data) => {
        const contentDispositionHeader = data.headers.get('Content-Disposition') ?? '';
        const fileNameRegex = /filename=(?<filename>.*);/;
        const fileName = fileNameRegex.exec(contentDispositionHeader)?.groups?.filename;
        data.blob().then((rsp) => {
          saveAs(rsp, fileName);
          queryClient.invalidateQueries([CLAIMS_QUERY_KEY, { claimStatus: ClaimStatus.Approved }]);
        });
      },
    }
  );

  return {
    postGenerateReport,
    isError: isErrorGenerate || isErrorDownload,
    isLoading: isLoadingGenerate || isLoadingDownload,
  };
};

export const useManualEntryConsoleClaimItem = (claimId: string) =>
  useQuery(
    [CLAIM_ITEM_QUERY_KEY, claimId],
    () => request<ManualEntryConsoleClaimItemResponse>(`${BFF_API_PREFIX}/claims/${claimId}`),
    { select: (item) => item }
  );

export interface ManualEntryConsoleCreateClaimRequest {
  orderReference: string;
}

export const useManualEntryConsoleCreateClaim = (
  mutationOptions: MutationOptions<
    ManualEntryConsoleClaimItemResponse,
    FetchError,
    ManualEntryConsoleCreateClaimRequest,
    unknown
  >
) =>
  useMutation(
    (payload: ManualEntryConsoleCreateClaimRequest) =>
      request<ManualEntryConsoleClaimItemResponse>(`${BFF_API_PREFIX}/claims`, {
        method: 'POST',
        body: JSON.stringify(payload),
      }),
    mutationOptions
  );

export interface ManualEntryConsoleClaimEditRequest {
  claimId: string;
  recoveredAmount: Price;
  adminFeeAmount: Price;
  ticketCount: number;
  reasonCodeId: string;
}

export const useManualEntryConsoleClaimEditItem = (
  mutationOptions: MutationOptions<unknown, FetchError, ManualEntryConsoleClaimEditRequest, unknown>
) =>
  useMutation(
    ({ claimId, ...payload }: ManualEntryConsoleClaimEditRequest) =>
      request<unknown>(`${BFF_API_PREFIX}/claims/${claimId}`, {
        method: 'PUT',
        body: JSON.stringify(payload),
      }),
    mutationOptions
  );
