import { defineMessages } from 'react-intl';

export default defineMessages({
  itinerary: {
    id: 'SupersededBooking.itinerary',
    defaultMessage: 'itinerary',
  },

  tickets: {
    id: 'SupersededBooking.tickets',
    defaultMessage: 'tickets',
  },
});
