import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';

import { Modal, ModalHeader, ModalTitle, ModalBody } from '@contactcentre-web/common/Modal';
import Link from '@contactcentre-web/common/Link';

import messages from './messages';
import styles from './styles';

class FareDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { extraDetailsVisible: false };
  }

  setExtraDetailsVisibility(extraDetailsVisible) {
    this.setState(() => ({
      extraDetailsVisible,
    }));
  }

  openExtraDetailsModal = () => {
    this.setExtraDetailsVisibility(true);
  };

  closeExtraDetailsModal = () => {
    this.setExtraDetailsVisibility(false);
  };

  render() {
    const { name, conditionSummary, passengerTypes, conditions } = this.props;
    const { extraDetailsVisible } = this.state;
    return (
      <div className={css(styles.row, styles.lineAbove)}>
        <div className={css(styles.extraLargeCol)}>
          <div className={css(styles.fare)}>
            <Link linkType="action" testId="fare-details-name" onClick={this.openExtraDetailsModal}>
              {name}
            </Link>
            {extraDetailsVisible && (
              <Modal onClose={this.closeExtraDetailsModal}>
                <ModalHeader>
                  <ModalTitle>{name}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                  {conditions.map(({ name: title, description }, idx) => (
                    <div key={`${title}-${idx}`} className={css(styles.fareCondition)}>
                      <h3>{title}</h3>
                      <p>{description}</p>
                    </div>
                  ))}
                </ModalBody>
              </Modal>
            )}
            <p className={css(styles.fareConditionSummary)}>
              {conditionSummary || (
                <FormattedMessage {...messages.fareConditionSummaryNotAvailble} />
              )}
            </p>
          </div>
        </div>
        <div className={css(styles.col)}>
          <span className={css(styles.farePassengers)}>
            {passengerTypes &&
              passengerTypes
                .map(({ passengerType, count }, idx) => (
                  <FormattedMessage
                    {...messages.passengerTypeCount}
                    values={{ count, passengerType }}
                    key={`${passengerType}-${idx}`}
                  />
                ))
                .reduce((prev, curr) => [prev, ', ', curr])}
          </span>
        </div>
      </div>
    );
  }
}

FareDetails.propTypes = {
  name: PropTypes.string.isRequired,
  conditionSummary: PropTypes.string,
  conditions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
  passengerTypes: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number.isRequired,
      passengerType: PropTypes.string.isRequired,
    })
  ),
};

export default FareDetails;
