import { defineMessages } from 'react-intl';

export default defineMessages({
  agent: {
    id: 'approvalQueueItem.agent',
    defaultMessage: 'Agent: {agentName}',
  },
  requestedDate: {
    id: 'approvalQueueItem.requestedDate',
    defaultMessage: '{day} at {time}',
  },
  approveButton: {
    id: 'approvalQueueItem.approveButton',
    defaultMessage: 'Approve',
  },
  rejectButton: {
    id: 'approvalQueueItem.rejectButton',
    defaultMessage: 'Reject',
  },
  reasonCodes: {
    id: 'approvalQueueItem.reasonCodes',
    defaultMessage: 'Reason codes & ticket types',
  },
  notesFromAgent: {
    id: 'approvalQueueItem.notesFromAgent',
    defaultMessage: 'Notes from agent',
  },
  originalTransaction: {
    id: 'approvalQueueItem.originalTransaction',
    defaultMessage: 'Original transaction ({currencyCode}) <span>{amount}</span>',
  },
  compensation: {
    id: 'approvalQueueItem.compensation',
    defaultMessage: 'Compensation ({currencyCode}) <strong>{amount}</strong>',
  },
  errorApprovingMessage: {
    id: 'approvalQueueItem.errorApprovingMessage',
    defaultMessage: 'There was an error approving the compensation. Please try again later.',
  },
  approvedMessage: {
    id: 'approvalQueueItem.approvedMessage',
    defaultMessage: 'Success! The compensation has been approved.',
  },
  errorRejectingMessage: {
    id: 'approvalQueueItem.errorRejectingMessage',
    defaultMessage: 'There was an error rejecting the compensation. Please try again later.',
  },
  rejectedMessage: {
    id: 'approvalQueueItem.rejectedMessage',
    defaultMessage: 'Success! The compensation has been rejected.',
  },
  viewOriginalOrder: {
    id: 'approvalQueueItem.viewOriginalOrder',
    defaultMessage: 'View original order',
  },
});
