import { defineMessages } from 'react-intl';

export default defineMessages({
  product: {
    id: 'RailcardDetails.product',
    defaultMessage: 'Product',
  },
  railcardNumber: {
    id: 'RailcardDetails.railcardNumber',
    defaultMessage: 'Railcard No',
  },
  issuedTo: {
    id: 'RailcardDetails.issuedTo',
    defaultMessage: 'Issued to',
  },
  operator: {
    id: 'RailcardDetails.operator',
    defaultMessage: 'Operator',
  },
  railcard: {
    id: 'RailcardDetails.railcard',
    defaultMessage: 'Railcard',
  },
  cancel: {
    id: 'RailcardDetails.cancel',
    defaultMessage: 'Cancel',
  },
  cancelRailcardTitle: {
    id: 'RailcardDetails.CancelRailcardModal.cancelRailcardTitle',
    defaultMessage:
      'Are you sure you want to cancel this {railcardName} instead of doing a Terms and Conditions refund?',
  },
  cancelRailcardDescription: {
    id: 'RailcardDetails.CancelRailcardModal.cancelRailcardDescription',
    defaultMessage:
      'Cancelling this railcard will prevent a future Terms and Conditions refund and remove this railcard from the customer’s account.',
  },
  goBack: {
    id: 'RailcardDetails.CancelRailcardModal.goBack',
    defaultMessage: 'Close',
  },
  confirmCancel: {
    id: 'RailcardDetails.CancelRailcardModal.confirmCancel',
    defaultMessage: 'Cancel railcard',
  },
});
