import { createActions, handleActions, Action } from 'redux-actions';
import { startSubmit, stopSubmit } from 'redux-form';
import { createSelector } from 'reselect';
import { call, all, put, select, takeLatest } from 'redux-saga/effects';
import moment from 'moment';

import request from '@contactcentre-web/utils/request';
import * as customerOrderSelectors from '@contactcentre-web/redux-common/selectors/order';
import type State from '@contactcentre-web/redux-common/types/State';
import { TravelProduct } from '@contactcentre-web/redux-common/types/TravelProduct';

export interface BookingFulfilment {
  id: string;
  origin: string;
  destination: string;
  isReturn?: boolean;
  travelDate: string;
}

export interface ErrorContext {
  wasResendingConfirmationEmail: boolean;
  wasResendingFulfilmentEmail: boolean;
  bookingConfirmationError: string;
  bookingsFulfilmentErrors: Array<BookingFulfilment>;
}

export interface SuccessContext {
  resendConfirmationEmail: boolean;
  resendFulfilmentEmail: boolean;
  bookings: Array<string>;
  emails: Array<string>;
}

export interface ResendEmailData {
  resendConfirmationEmail: boolean;
  resendFulfilmentEmail: boolean;
  bookings: Array<string>;
  emails: Array<string>;
}

export interface ResendEmailCheck {
  isOrderConfirmationEligible: boolean;
  eligibleFulfilmentProducts: Array<string>;
  error: Array<Entries>;
}

export interface Entries {
  code: string;
  source: { reference: string };
}

export interface ResendEmailError {
  entries?: Array<Entries>;
  resendConfirmationEmail: boolean;
  resendFulfilmentEmail: boolean;
}

export interface ResendEmailState extends ResendEmailData {
  success: boolean;
  hidden: boolean;
  pending: boolean;
  emails: Array<string>;
  errors: ResendEmailError;
  checkPending: boolean;
  isOrderConfirmationEligible: boolean;
  eligibleFulfilmentProducts: Array<string>;
}

// Constants
export const FORM_ID = 'ResendEmail';

const PREFIX = 'RESEND_EMAIL/';
export const RESEND_EMAIL_RESET = `RESET`;
export const RESEND_EMAIL_SHOW = `SHOW`;
export const RESEND_EMAIL_HIDE = `HIDE`;
export const RESEND_EMAIL_CHECK_PENDING = `CHECK_PENDING`;
export const RESEND_EMAIL_CHECK_SUCCESS = `CHECK_SUCCESS`;
export const RESEND_EMAIL_CHECK_FAILED = `CHECK_FAILED`;
export const RESEND_EMAIL_CHECK_ERROR = `CHECK_ERROR`;
export const RESEND_EMAIL_PENDING = `PENDING`;
export const RESEND_EMAIL_SUCCESS = `SUCCESS`;
export const RESEND_EMAIL_FAILED = `FAILED`;

const nullPayload = () => null;

export const actions = createActions(
  {
    [RESEND_EMAIL_RESET]: nullPayload,
    [RESEND_EMAIL_SHOW]: nullPayload,
    [RESEND_EMAIL_HIDE]: nullPayload,
    [RESEND_EMAIL_PENDING]: (resendConfirmationEmail, resendFulfilmentEmail, bookings, emails) => ({
      resendConfirmationEmail,
      resendFulfilmentEmail,
      bookings,
      emails,
    }),
    [RESEND_EMAIL_SUCCESS]: (resendConfirmationEmail, resendFulfilmentEmail, bookings, emails) => ({
      resendConfirmationEmail,
      resendFulfilmentEmail,
      bookings,
      emails,
    }),
    [RESEND_EMAIL_FAILED]: (error, resendConfirmationEmail, resendFulfilmentEmail) => ({
      error,
      resendConfirmationEmail,
      resendFulfilmentEmail,
    }),
    [RESEND_EMAIL_CHECK_PENDING]: nullPayload,
    [RESEND_EMAIL_CHECK_SUCCESS]: (isOrderConfirmationEligible, eligibleFulfilmentProducts) => ({
      isOrderConfirmationEligible,
      eligibleFulfilmentProducts,
    }),
    [RESEND_EMAIL_CHECK_ERROR]: nullPayload,
  },
  { prefix: PREFIX }
);

export const initialState = {
  hidden: true,
  pending: false,
  success: false,
  emails: [],
  errors: {} as ResendEmailError,
  checkPending: false,
  isOrderConfirmationEligible: false,
  eligibleFulfilmentProducts: [],
  resendConfirmationEmail: false,
  resendFulfilmentEmail: false,
  bookings: [],
};
interface ActionPayload extends ResendEmailData, ResendEmailCheck {}
const reducer = handleActions<ResendEmailState, ActionPayload>(
  {
    [RESEND_EMAIL_RESET]: () => initialState,
    [RESEND_EMAIL_SHOW]: (state) => ({
      ...state,
      hidden: false,
    }),
    [RESEND_EMAIL_HIDE]: () => initialState,
    [RESEND_EMAIL_PENDING]: (state) => ({
      ...state,
      pending: true,
      errors: {} as ResendEmailError,
    }),
    [RESEND_EMAIL_SUCCESS]: (
      state,
      { payload: { resendConfirmationEmail, resendFulfilmentEmail, bookings, emails } }
    ) => ({
      ...state,
      resendConfirmationEmail,
      resendFulfilmentEmail,
      bookings,
      emails,
      pending: false,
      success: true,
      errors: {} as ResendEmailError,
    }),
    [RESEND_EMAIL_FAILED]: (
      state,
      { payload: { error, resendConfirmationEmail, resendFulfilmentEmail } }
    ) => ({
      ...state,
      pending: false,
      errors: {
        resendConfirmationEmail,
        resendFulfilmentEmail,
        entries: error,
      } as ResendEmailError,
    }),
    [RESEND_EMAIL_CHECK_PENDING]: (state) => ({
      ...state,
      checkPending: true,
      isOrderConfirmationEligible: false,
    }),
    [RESEND_EMAIL_CHECK_SUCCESS]: (
      state,
      { payload: { isOrderConfirmationEligible, eligibleFulfilmentProducts } }
    ) => ({
      ...state,
      checkPending: false,
      isOrderConfirmationEligible,
      eligibleFulfilmentProducts,
    }),
    [RESEND_EMAIL_CHECK_ERROR]: () => initialState,
  },
  initialState,
  { prefix: PREFIX }
);

export default reducer;

const localState = (state: State) => state.resendEmail;
const isHidden = (state: ResendEmailState) => state.hidden;
const isPending = (state: ResendEmailState): boolean => state.pending;
const isSuccess = (state: ResendEmailState) => state.success;
const getEmails = (state: ResendEmailState) => state.emails;
const isCheckPending = (state: ResendEmailState) => state.checkPending;
const getEligibleFulfilmentProducts = createSelector(
  localState,
  (resendEmail) => resendEmail.eligibleFulfilmentProducts
);
const isOrderConfirmationEligible = createSelector(
  localState,
  (resendEmail) => resendEmail.isOrderConfirmationEligible
);
const getBookings = createSelector(
  customerOrderSelectors.getBookings,
  (bookings: Array<TravelProduct>) =>
    bookings.map(({ id, origin, destination, isReturn, outDate }: TravelProduct) => ({
      id,
      origin,
      destination,
      isReturn,
      travelDate: moment(outDate).format('ll'),
    }))
);

const bookingConfirmationErrorRegExp = /^bookingConfirmationResendFailed(\.\w+)?/i;
const bookingsFulfilmentErrorsRegExp = /^fulfilmentResendFailed(\.\w+)?/i;
const getErrorContext = createSelector(localState, getBookings, (state, bookings) => {
  if (!state.errors.entries) {
    return undefined;
  }

  const bookingConfirmationError = state.errors.entries.find(({ code }: { code: string }) =>
    bookingConfirmationErrorRegExp.test(code)
  );
  const bookingsFulfilmentErrors = state.errors.entries.filter(({ code }: { code: string }) =>
    bookingsFulfilmentErrorsRegExp.test(code)
  );

  return {
    bookingConfirmationError: bookingConfirmationError
      ? bookingConfirmationError.code.replace('.', '_')
      : undefined,
    bookingsFulfilmentErrors: bookingsFulfilmentErrors?.map(
      ({ source: { reference } }: { source: { reference: string } }) =>
        bookings.find(({ id }) => reference === id)
    ),
    wasResendingConfirmationEmail: !!state.errors.resendConfirmationEmail,
    wasResendingFulfilmentEmail: !!state.errors.resendFulfilmentEmail,
  } as ErrorContext;
});
const getSuccessContext = createSelector(
  localState,
  ({ resendConfirmationEmail, resendFulfilmentEmail, bookings, emails }) =>
    ({
      resendConfirmationEmail,
      resendFulfilmentEmail,
      bookings,
      emails,
    } as SuccessContext)
);

export const selectors = {
  isOrderConfirmationEligible,
  isCheckPending,
  isHidden,
  isPending,
  isSuccess,
  getEmails,
  getBookings,
  getErrorContext,
  getSuccessContext,
  getEligibleFulfilmentProducts,
};

export const resendRequest = (
  id: string,
  orderReference: string,
  resendConfirmationEmail: boolean,
  resendFulfilmentEmail: boolean,
  bookings: Array<string>,
  emails: Array<string>
) => {
  const uri = `/api/orders/${id}/resendemails`;
  const method = 'POST';
  return request(uri, {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    body: {
      orderReference,
      emails,
      products: bookings,
      sendBookingConfirmationEmails: resendConfirmationEmail,
      sendBookingFulfilmentEmails: resendFulfilmentEmail,
    },
  });
};

export function* resendEmailSaga({
  payload: { resendConfirmationEmail, resendFulfilmentEmail, bookings, emails },
}: Action<ResendEmailData>) {
  yield put(startSubmit(FORM_ID));
  const state: State = yield select();
  const { orderReference, id } = customerOrderSelectors.getOrder(state);
  try {
    yield call(
      resendRequest,
      id,
      orderReference,
      resendConfirmationEmail,
      resendFulfilmentEmail,
      bookings,
      emails
    );

    yield put(stopSubmit(FORM_ID, {}));
    yield put(actions.success(resendConfirmationEmail, resendFulfilmentEmail, bookings, emails));
  } catch (err: any) {
    yield put(stopSubmit(FORM_ID, {}));
    if (err.response) {
      yield put(
        actions.failed(
          err.response.errors.errors as Array<Entries>,
          resendConfirmationEmail,
          resendFulfilmentEmail
        )
      );
    } else {
      yield put(actions.failed(err));
    }
  }
}

export const checkEligibilityRequest = (orderId: string) => {
  const uri = `/api/orders/${orderId}/resendeligibility`;
  const method = 'GET';
  return request(uri, {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export function* checkEligibilitySaga() {
  const state: State = yield select();
  const { id }: { id: string } = customerOrderSelectors.getOrder(state);
  try {
    const response: ResendEmailCheck = yield call(checkEligibilityRequest, id);
    yield put(
      actions.checkSuccess(
        response.isOrderConfirmationEligible,
        response.eligibleFulfilmentProducts
      )
    );
  } catch (err) {
    yield put(actions.checkError(err));
  }
}

export function* saga() {
  yield all([
    takeLatest(`${PREFIX}/${RESEND_EMAIL_PENDING}`, resendEmailSaga),
    takeLatest(`${PREFIX}/${RESEND_EMAIL_CHECK_PENDING}`, checkEligibilitySaga),
  ]);
}
