import { StyleSheet } from 'aphrodite/no-important';
import { spacers, palettes, borderRadius } from '@trainline/depot-web/css/themes/trainline';

const disabledStyles = {
  backgroundColor: 'transparent',
  color: 'inherit',
  ':nth-child(1n) > svg': {
    color: palettes.dp_app_border_base,
  },
};

const carouselNavigationButtonStyles = {
  color: palettes.dp_app_link_base,

  ':disabled': {
    ...disabledStyles,
  },

  ':disabled:hover': {
    ...disabledStyles,
  },
};

export default StyleSheet.create({
  carousel: {
    backgroundColor: palettes.dp_app_backdrop_base,
    marginTop: spacers.dp_s16,
  },
  carouselSlides: {
    borderRadius: borderRadius.dp_r4,
    border: `1px solid ${palettes.dp_app_border_base}`,
    padding: spacers.dp_s16,
  },
  carouselSlide: {
    display: 'flex',
  },
  carouselSlideCombination: {
    flex: 1,
    textAlign: 'center',
  },
  carouselNavigationButtonPrevious: {
    ...carouselNavigationButtonStyles,
  },
  carouselNavigationButtonNext: {
    ...carouselNavigationButtonStyles,
  },
  carouselPagination: {
    marginTop: spacers.dp_s8,
    textAlign: 'center',
  },
  buttonSelect: {
    marginTop: spacers.dp_s16,
  },
});
