import { StyleSheet } from 'aphrodite/no-important';
import {
  fonts,
  typography,
  spacers,
  borderRadius,
} from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

const priceCellStyles = {
  fontSize: typography.dp_small_font_size_m,
  letterSpacing: typography.dp_small_letter_spacing,
  lineHeight: typography.dp_small_line_height,
  padding: `${spacers.dp_s8} 0`,
  textAlign: 'right',
  verticalAlign: 'bottom',
  width: '100px',
};

export default StyleSheet.create({
  itemCell: {
    padding: `${spacers.dp_s8} 0`,
    textAlign: 'left',
    width: '180px',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  message: {
    paddingRight: spacers.dp_s8,
  },
  direction: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    backgroundColor: colors.info.light,
    color: colors.info.dark,
    fontWeight: fonts.dp_font_weight_bold,
    padding: `${spacers.dp_s4} ${spacers.dp_s8}`,
    borderRadius: borderRadius.dp_r2,
  },
  productPriceCell: {
    ...priceCellStyles,
    color: colors.gray.alpha58,
  },
  pricePaidCell: {
    ...priceCellStyles,
    fontWeight: fonts.dp_font_weight_bold,
  },
});
