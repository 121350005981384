import { StyleSheet } from 'aphrodite/no-important';
import {
  spacers,
  borderRadius,
  palettes,
  typography,
  fonts,
} from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  container: {
    backgroundColor: palettes.dp_app_backdrop_base,
    borderRadius: borderRadius.dp_r4,
    marginBottom: spacers.dp_s32,
    boxShadow: `0 0 4px ${palettes.dp_comp_box_shadow}, 0px 2px 6px ${palettes.dp_comp_box_shadow}`,
  },
  header: {
    display: 'flex',
    padding: `${spacers.dp_s16} ${spacers.dp_s32}`,
    gap: spacers.dp_s24,
  },
  col: {
    overflowWrap: 'break-word',
  },
  colBooking: {
    flex: 1,
  },
  colAgent: {
    maxWidth: '250px',
  },
  labelPrimary: {
    color: palettes.dp_app_info_light,
    lineHeight: typography.dp_body_line_height,
    fontWeight: fonts.dp_font_weight_bold,
  },
  labelSecondary: {
    fontSize: typography.dp_micro_font_size,
    letterSpacing: typography.dp_micro_letter_spacing,
    lineHeight: typography.dp_micro_line_height,
    textTransform: 'uppercase',
    fontWeight: fonts.dp_font_weight_bold,
    color: colors.gray.alpha30,
  },
  body: {
    borderTop: `1px solid ${palettes.dp_app_border_base}`,
    padding: spacers.dp_s32,
  },
});
