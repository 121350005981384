import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { Heading, Paragraph } from '@trainline/depot-web';

import Expandable from '@contactcentre-web/common/Expandable';
import type { TicketStatus } from '@contactcentre-web/redux-common/types/TicketStatus';

import messages from './messages';
import styles from './styles';
import TicketScanSummary from './components/TicketScanSummary';

interface Props {
  status: TicketStatus;
  deliveryMethod?: string;
  ticketNumber?: number;
  totalOfTickets?: number;
}

export const thirdPartyStatusMessage = {
  NotUsed: messages.notUsedStatus,
  Scanned: messages.scannedStatus,
  Cancelled: messages.cancelledStatus,
  Active: messages.activeStatus,
  unavailable: messages.unavailableStatus,
  fulfilling: messages.fulfillingStatus,
  fulfilled: messages.fulfilledStatus,
  readyForFetch: messages.readyForFetchStatus,
  failed: messages.failedStatus,
};

export const trainlineStatusMessage = {
  Available: messages.availableStatus,
  Downloaded: messages.downloadedStatus,
  Activated: messages.activatedStatus,
  RefundRequested: messages.refundRequestedStatus,
};

const TicketSummary = ({
  deliveryMethod,
  status: { thirdPartyStatus, trainlineStatus, vendorId, ticketScans },
  ticketNumber = 1,
  totalOfTickets = 1,
}: Props) => {
  const [isOpen, setIsOpen] = useState(totalOfTickets === 1);
  const internalStatus =
    thirdPartyStatus && thirdPartyStatusMessage[thirdPartyStatus] ? (
      <FormattedMessage {...thirdPartyStatusMessage[thirdPartyStatus]} />
    ) : (
      thirdPartyStatus || <FormattedMessage {...messages.notAvailable} />
    );

  const tlStatus =
    trainlineStatus && trainlineStatusMessage[trainlineStatus] ? (
      <FormattedMessage {...trainlineStatusMessage[trainlineStatus]} />
    ) : (
      trainlineStatus || <FormattedMessage {...messages.notAvailable} />
    );

  return (
    <div className={css(styles.ticketContainer)}>
      <Expandable.Anchor
        isOpen={isOpen}
        onClick={() => setIsOpen((prev) => !prev)}
        styleSheet={{ anchor: styles.ticketAnchor, chevron: styles.ticketChevron }}
      >
        <div className={css(styles.ticketNumber)}>
          <div>
            <Heading typeStyle="title3" color="base" as="h3">
              <FormattedMessage {...messages.ticketNumber} values={{ ticketNumber }} />
            </Heading>
            {vendorId && (
              <Paragraph typeStyle="small" as="p" color="base" fontWeight="regular">
                <FormattedMessage {...messages.utnNumber} values={{ vendorId }} />
              </Paragraph>
            )}
          </div>
          <div>
            {deliveryMethod === 'AtocMTicket' && (
              <Paragraph typeStyle="small" as="p" color="base" fontWeight="regular">
                <FormattedMessage {...messages.tlStatus} values={{ tlStatus }} />
              </Paragraph>
            )}
            <Paragraph typeStyle="small" as="p" color="base" fontWeight="regular">
              <FormattedMessage {...messages.internalStatus} values={{ internalStatus }} />
            </Paragraph>
          </div>
        </div>
      </Expandable.Anchor>
      <Expandable.Content isOpen={isOpen} styleSheet={styles.ticketScanContent}>
        {ticketScans?.length > 0 ? (
          ticketScans?.map((ticketScan, index) => (
            <TicketScanSummary
              ticketScan={ticketScan}
              index={index}
              totalOfScans={ticketScans.length}
              key={index}
            />
          ))
        ) : (
          <Paragraph typeStyle="body" as="p" color="base" fontWeight="regular">
            <FormattedMessage {...messages.noScans} />
          </Paragraph>
        )}
      </Expandable.Content>
    </div>
  );
};

export default TicketSummary;
