import { handleActions } from 'redux-actions';

import {
  REQUEST_REFUND_SUCCEEDED,
  UPDATE_QUOTE_SUCCESS,
  LOAD_QUOTE_SUCCEEDED,
  QUOTE_VALIDATION_FAILED,
} from '../common/module';

import {
  events,
  loadRefundsState,
  requestRefundState,
  REFUND_REASONS,
  seasonRefund,
} from './module';

const initialState = {
  loadRefundsState: loadRefundsState.LOAD_REFUNDS_NONE,
  requestRefundState: requestRefundState.REQUEST_REFUND_NONE,
  refundReasons: REFUND_REASONS,
  quotes: {},
  quotesValidationErrors: {},
  seasonRefund: {
    loading: false,
    status: null,
  },
};

const reducer = handleActions(
  {
    [events.DISPOSE]: () => initialState,
    [events.LOAD_REFUNDS]: (state) => ({
      ...state,
      loadRefundsState: loadRefundsState.LOAD_REFUNDS_INPROGRESS,
    }),
    [events.LOAD_REFUNDS_SUCCEEDED]: (state, { payload: { refundables, refunds } }) => ({
      ...state,
      loadRefundsState: loadRefundsState.LOAD_REFUNDS_SUCCESS,
      refundables,
      refunds,
    }),
    [events.LOAD_REFUNDS_FAILED]: (state) => ({
      ...state,
      loadRefundsState: loadRefundsState.LOAD_REFUNDS_ERROR,
    }),
    [QUOTE_VALIDATION_FAILED]: (state, { payload: { bookingId, errors } }) =>
      state.loadRefundsState === loadRefundsState.LOAD_REFUNDS_SUCCESS
        ? {
            ...state,
            quotesValidationErrors: {
              ...state.quotesValidationErrors,
              [bookingId]: {
                ...state.quotesValidationErrors[bookingId],
                unprocessableReasons: errors,
              },
            },
          }
        : state,
    [REQUEST_REFUND_SUCCEEDED]: (state, { payload: { bookingId } }) =>
      state.loadRefundsState === loadRefundsState.LOAD_REFUNDS_INPROGRESS
        ? {
            ...state,
            quotes: {
              ...state.quotes,
              [bookingId]: undefined,
            },
            requestRefundState: requestRefundState.REQUEST_REFUND_SUCCESS,
          }
        : state,
    [UPDATE_QUOTE_SUCCESS]: (state, { payload: { bookingId, quote } }) =>
      state.loadRefundsState === loadRefundsState.LOAD_REFUNDS_SUCCESS
        ? {
            ...state,
            quotes: {
              ...state.quotes,
              [bookingId]: quote,
            },
            quotesValidationErrors: {
              ...state.quotesValidationErrors,
              [bookingId]: undefined,
            },
          }
        : state,
    [LOAD_QUOTE_SUCCEEDED]: (state, { payload: { bookingId, quote } }) =>
      state.loadRefundsState === loadRefundsState.LOAD_REFUNDS_SUCCESS
        ? {
            ...state,
            quotes: {
              ...state.quotes,
              [bookingId]: quote,
            },
          }
        : state,
    [seasonRefund.SEASON_ORDER_REFUND_RESET]: () => initialState,
    [seasonRefund.SEASON_ORDER_REFUND_ATTEMPT]: (state) => ({
      ...state,
      seasonRefund: {
        loading: true,
        status: null,
      },
    }),
    [seasonRefund.SEASON_ORDER_REFUND_SUCCESS]: (state) => ({
      ...state,
      seasonRefund: {
        loading: false,
        status: 'success',
      },
    }),
    [seasonRefund.SEASON_ORDER_REFUND_FAIL]: (state) => ({
      ...state,
      seasonRefund: {
        loading: false,
        status: 'error',
      },
    }),
  },
  initialState
);

export default reducer;
