import { defineMessages } from 'react-intl';

export default defineMessages({
  orderId: {
    id: 'CompensationApprovalHeader.orderId',
    defaultMessage: 'Order Id',
  },
  requestedDate: {
    id: 'CompensationApprovalHeader.requestedDate',
    defaultMessage: '{day} at {time}',
  },
  date: {
    id: 'CompensationApprovalHeader.date',
    defaultMessage: 'date',
  },
  requestingUserName: {
    id: 'CompensationApprovalHeader.requestingUserName',
    defaultMessage: 'Agent',
  },
  total: {
    id: 'CompensationApprovalHeader.total',
    defaultMessage: 'Total',
  },
});
