declare const dataLayer: any;
declare const newrelic: any;

export const noticeError = (error: unknown, attributes: unknown): void =>
  newrelic.noticeError(error, attributes);

export const addPageAction = (name: string, attributes?: unknown): void =>
  newrelic.addPageAction(name, attributes);

export const setManagedGroup = (number: number): void =>
  dataLayer.push({
    event: 'managed_group',
    ga_event: {
      managedGroup: number,
    },
  });

export const pushToDataLayer = (data: Record<string, unknown>): void => dataLayer.push(data);
