import { defineMessages } from 'react-intl';

export default defineMessages({
  defaultCardTitle: {
    id: 'ExternalRefund.defaultCardTitle',
    defaultMessage: 'External Refund',
  },
  DetaxeRefundCardTitle: {
    id: 'ExternalRefund.DetaxeRefundCardTitle',
    defaultMessage: 'SNCF eDetaxe Refund',
  },
  booking: {
    id: 'ExternalRefund.booking',
    defaultMessage: 'Booking',
  },
  requestedBy: {
    id: 'ExternalRefund.requestedBy',
    defaultMessage: 'Requested By',
  },
  status: {
    id: 'ExternalRefund.status',
    defaultMessage: 'Status',
  },
  claimAmount: {
    id: 'ExternalRefund.claimAmount',
    defaultMessage: 'Claim amount',
  },
  // contact reasons
  contactReason: {
    id: 'ExternalRefund.contactReason',
    defaultMessage: 'Contact reason',
  },
  canceledTrainDisruption: {
    id: 'ExternalRefund.canceledTrainDisruption',
    defaultMessage: 'Cancelled train/Disruption',
  },
  refundedCanceledTrainDisruption: {
    id: 'ExternalRefund.refundedCanceledTrainDisruption',
    defaultMessage: 'Cancelled train/Disruption - Refunded',
  },
  onboardComfortIssues: {
    id: 'ExternalRefund.onboardComfortIssues',
    defaultMessage: 'Onboard comfort issues',
  },
  missedConnection: {
    id: 'ExternalRefund.missedConnection',
    defaultMessage: 'Missed connection',
  },
  deathIllness: {
    id: 'ExternalRefund.deathIllness',
    defaultMessage: 'Death/Illness',
  },
  voidVoucher: {
    id: 'ExternalRefund.voidVoucher',
    defaultMessage: 'Void voucher',
  },
  fareTypeRefunds: {
    id: 'ExternalRefund.fareTypeRefunds',
    defaultMessage: 'Fare type refunds',
  },
  duplicateBooking: {
    id: 'ExternalRefund.duplicateBooking',
    defaultMessage: 'Duplicate booking',
  },
  historicPNR: {
    id: 'ExternalRefund.historicPNR',
    defaultMessage: 'Historic PNR',
  },
  finalizeOptionSNCF: {
    id: 'ExternalRefund.finalizeOptionSNCF',
    defaultMessage: 'Finalize option SNCF',
  },
  railcard: {
    id: 'ExternalRefund.railcard',
    defaultMessage: 'Railcard',
  },
});
