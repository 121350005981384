import { defineMessages } from 'react-intl';

export default defineMessages({
  bookings: {
    id: 'CustomerOrderHeader.BookingsSection.bookings',
    defaultMessage: 'bookings ({count})',
  },
  directions: {
    id: 'CustomerOrderHeader.BookingsSection.directions',
    defaultMessage: '{isReturn, select, true {Return, } other {}}{origin} to {destination}',
  },
  season: {
    id: 'CustomerOrderHeader.BookingsSection.season',
    defaultMessage:
      '{isFlexiSeason, select, true {Flexi} other {Season}}, {origin} to {destination}',
  },
  travelcard: {
    id: 'CustomerOrderHeader.BookingsSection.travelcard',
    defaultMessage: 'Add-on, {localAreaValidity}',
  },
});
