import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'CreateNewAgent.title',
    defaultMessage: 'Create new agent',
  },
  firstName: {
    id: 'CreateNewAgent.firstName',
    defaultMessage: 'First name',
  },
  lastName: {
    id: 'CreateNewAgent.lastName',
    defaultMessage: 'Last name',
  },
  email: {
    id: 'CreateNewAgent.email',
    defaultMessage: 'Email',
  },
  username: {
    id: 'CreateNewAgent.username',
    defaultMessage: 'Username',
  },
  usernameInfo: {
    id: 'CreateNewAgent.usernameInfo',
    defaultMessage:
      'Agent usernames should be lower case and composed of first two initials of first name and full last name with no spaces.',
  },
  employeeId: {
    id: 'CreateNewAgent.employeeId',
    defaultMessage: 'Employee ID',
  },
  site: {
    id: 'CreateNewAgent.site',
    defaultMessage: 'Site',
  },
  enableSso: {
    id: 'CreateNewAgent.enableSso',
    defaultMessage: 'Use SSO instead of username to sign in',
  },
  pleaseSelect: {
    id: 'CreateNewAgent.pleaseSelect',
    defaultMessage: 'Please select...',
  },
  firstNameIsRequired: {
    id: 'CreateNewAgent.firstNameIsRequired',
    defaultMessage: 'First name is required',
  },
  lastNameIsRequired: {
    id: 'CreateNewAgent.lastNameIsRequired',
    defaultMessage: 'Last name is required',
  },
  emailIsRequired: {
    id: 'CreateNewAgent.emailIsRequired',
    defaultMessage: 'Email is required',
  },
  emailIsInvalid: {
    id: 'CreateNewAgent.emailIsInvalid',
    defaultMessage: 'Email is invalid',
  },
  emailIsNotAllowed: {
    id: 'CreateNewAgent.emailIsNotAllowed',
    defaultMessage: 'Email is not allowed',
  },
  emailAlreadyExists: {
    id: 'CreateNewAgent.emailAlreadyExists',
    defaultMessage: 'This email already exists',
  },
  usernameIsRequired: {
    id: 'CreateNewAgent.usernameIsRequired',
    defaultMessage: 'Username is required',
  },
  siteIsRequired: {
    id: 'CreateNewAgent.siteIsRequired',
    defaultMessage: 'Site is required',
  },
  usernameAlreadyExists: {
    id: 'CreateNewAgent.usernameAlreadyExists',
    defaultMessage: 'This username already exists',
  },
  employeeIdAlreadyUsed: {
    id: 'CreateNewAgent.employeeIdAlreadyUsed',
    defaultMessage: 'This employee ID was already used',
  },
  emailIsRequiredForSso: {
    id: 'CreateNewAgent.emailIsRequiredForSso',
    defaultMessage: 'An email address is required to enable SSO for this user',
  },
  submit: {
    id: 'CreateNewAgent.submit',
    defaultMessage: 'Submit',
  },
  error: {
    id: 'CreateNewAgent.error',
    defaultMessage: 'Something went wrong while creating a new agent',
  },
});
