import { defineMessages } from 'react-intl';

export default defineMessages({
  orderId: {
    id: 'SupersededBooking.OrderHeader.orderId',
    defaultMessage: 'order id',
  },

  withReturn: {
    id: 'SupersededBooking.OrderHeader.withReturn',
    defaultMessage: 'Return, ',
  },

  directions: {
    id: 'SupersededBooking.OrderHeader.directions',
    defaultMessage: '{withReturn} {origin} to {destination}',
  },
  bookings: {
    id: 'SupersededBooking.OrderHeader.bookings',
    defaultMessage: 'bookings ({count})',
  },

  tickets: {
    id: 'SupersededBooking.OrderHeader.tickets',
    defaultMessage: 'tickets ({count})',
  },

  senior: {
    id: 'SupersededBooking.OrderHeader.senior',
    defaultMessage: '{count, number} {count, plural, one {senior} other {seniors}}',
  },

  adult: {
    id: 'SupersededBooking.OrderHeader.adult',
    defaultMessage: '{count, number} {count, plural, one {adult} other {adults}}',
  },

  child: {
    id: 'SupersededBooking.OrderHeader.child',
    defaultMessage: '{count, number} {count, plural, one {child} other {children}}',
  },

  infant: {
    id: 'SupersededBooking.OrderHeader.infant',
    defaultMessage: '{count, number} {count, plural, one {infant} other {infants}}',
  },

  youth: {
    id: 'SupersededBooking.OrderHeader.youth',
    defaultMessage: '{count, number} {count, plural, one {youth} other {youths}}',
  },

  labelBookedVia: {
    id: 'SupersededBooking.OrderHeader.labelBookedVia',
    defaultMessage: 'booked via',
  },

  labelCreatedOn: {
    id: 'SupersededBooking.OrderHeader.labelCreatedOn',
    defaultMessage: 'Created on',
  },

  createdOn: {
    id: 'SupersededBooking.OrderHeader.createdOn',
    defaultMessage: '{date} at {time}',
  },
});
