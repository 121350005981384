import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { useParams, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { StatusMessage } from '@trainline/depot-web';

import Loader from '@contactcentre-web/common/Loader';
import PageContainer from '@contactcentre-web/common/PageContainer';
import Link from '@contactcentre-web/common/Link';
import locationShape from '@contactcentre-web/utils/shape/location';

import OrderHeader from './components/OrderHeader/OrderHeader';
import Booking from './components/Booking/Booking';
import { actions } from './module';
import selectors from './selectors';
import styles from './styles';
import messages from './messages';

export const SupersededBooking = ({
  error,
  loading,
  order,
  bookings,
  passengersByType,
  loadBooking,
}) => {
  const { orderId } = useParams();
  const { search } = useLocation();
  const { bookingUri } = qs.parse(search);
  const loadBookingRef = React.useRef(loadBooking);

  React.useEffect(() => {
    loadBookingRef.current(orderId, bookingUri);
  }, [orderId, bookingUri, loadBookingRef]);

  if (loading) {
    return (
      <div className={css(styles.loaderContainer)}>
        <Loader />
      </div>
    );
  }

  if (error || !order) {
    return null;
  }

  const { customerId, orderReference } = order;
  return (
    <PageContainer>
      <OrderHeader {...order} bookings={bookings} passengersByType={passengersByType} />
      <StatusMessage status="info">
        <FormattedMessage
          {...messages.alert}
          values={{
            viewBooking: (
              <Link linkType="internal" to={`/customers/${customerId}/bookings/${orderReference}`}>
                <FormattedMessage {...messages.viewBooking} />
              </Link>
            ),
          }}
        />
      </StatusMessage>
      <div className={css(styles.orderDetailsContainer)}>
        {bookings.map((booking, index) => (
          <Booking key={index} {...order} {...booking} bookingNum={index + 1} />
        ))}
      </div>
    </PageContainer>
  );
};

SupersededBooking.propTypes = {
  loadBooking: PropTypes.func.isRequired,
  location: locationShape,
  loading: PropTypes.bool.isRequired,
  order: PropTypes.object,
  bookings: PropTypes.array,
  passengersByType: PropTypes.object,
  error: PropTypes.object,
};

const mapStateToProps = (state) => ({
  loading: selectors.isLoading(state),
  error: selectors.getError(state),
  order: selectors.getOrder(state),
  bookings: selectors.getBookings(state),
  passengersByType: selectors.getPassengersByType(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadBooking: (orderReference, bookingUri) => dispatch(actions.load(orderReference, bookingUri)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupersededBooking);
