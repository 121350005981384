import { createSelector } from 'reselect';
import * as R from 'ramda';
import moment from 'moment';

import { isLocalAreaProduct } from '@contactcentre-web/refunds/utils';

import { ProductType } from '../types/ProductType';
import { Product } from '../types/Product';
import { TravelProduct } from '../types/TravelProduct';
import { RailcardProduct } from '../types/RailcardProduct';
import { Passenger, Leg, Journey } from '../types/Journey';
import { Order } from '../types/Order';

import {
  Journey as SeasonJourney,
  Passenger as SeasonPassenger,
  SeasonProduct,
} from './../types/SeasonProduct';
import { getBookings, getOrder } from './order';

const selectOrderProducts = (state: any): Product[] => state.orders.products ?? [];

// For now this only contains railcards and seasons
const selectProducts = createSelector(selectOrderProducts, (products: Product[]) =>
  products.filter((product: Product) => !isLocalAreaProduct(product))
);

// NOTE: Currently the product type 'travelcard-product' is used for
// LondonTravelcards and PlusBus products aka Local Area Products
export const selectLocalAreaProducts = createSelector(selectOrderProducts, (products: Product[]) =>
  products.filter(isLocalAreaProduct)
);

const railcardMap = (p: RailcardProduct): RailcardProduct & { canBeCancelled?: boolean } => ({
  ...p,
  canBeCancelled: p.links && p.links.some((l) => l.rel.toLowerCase() === 'cancel'),
});

const seasonMap = (
  product: SeasonProduct
): SeasonProduct & { canBeRefunded: boolean; canBeReset: boolean; canBeCancelled: boolean } => ({
  ...product,
  canBeRefunded:
    product.links && product.links.some((link) => link?.rel?.toLowerCase() === 'refund'),
  canBeReset: product.links && product.links.some((link) => link?.rel?.toLowerCase() === 'reset'),
  canBeCancelled:
    product.links && product.links.some((link) => link.rel?.toLowerCase() === 'cancel'),
});

const productMap = (p: Product): Product =>
  R.cond<Product, Product>([
    [
      (p: Product): boolean => p.type === ProductType.Railcard,
      (p): RailcardProduct => railcardMap(p as RailcardProduct),
    ],
    [
      (p: Product): boolean => p.type === ProductType.Season,
      (p): SeasonProduct => seasonMap(p as SeasonProduct),
    ],
    [R.T, (pi): Product => pi],
  ])(p);

export const getProducts = createSelector(
  getBookings,
  selectOrderProducts,
  (travelProducts: TravelProduct[], products: Product[]) => [
    ...(travelProducts || []).map(
      ({ type, ...rest }): Product => ({
        type: type || ProductType.TravelProduct,
        ...rest,
      })
    ),
    ...(products || []).map(productMap),
  ]
);

export const getBookingsPassengerByType = createSelector(getBookings, (bookings) =>
  R.compose<
    TravelProduct[],
    Array<Journey[]>,
    Journey[],
    Array<Leg[]>,
    Leg[],
    { [type: string]: Passenger[] }
  >(
    // This is required as for some reason ts-loader doesn't play nicely with .d.ts type augmentation
    // https://jira.thetrainline.com/browse/OCC-5077 for more context
    R.compose<Leg[], Array<Passenger[]>, Passenger[], Passenger[], { [type: string]: Passenger[] }>(
      R.groupBy(({ type }) => type.toLowerCase()),
      R.uniqBy(({ id }) => id),
      R.flatten,
      R.pluck('passengers')
    ),
    R.flatten,
    R.pluck('legs'),
    R.flatten,
    R.pluck('journeys')
  )(bookings)
);

const getBookingsPassengersByType = createSelector(
  getBookingsPassengerByType,
  (getBookingsPassengerByType) =>
    R.compose<{ [type: string]: Passenger[] }, { [type: string]: number }>(
      R.map<{ [type: string]: Passenger[] }, { [type: string]: number }>(R.length)
    )(getBookingsPassengerByType)
);

const getProductsPassengersByTypeCount = createSelector(getProducts, (products) =>
  R.compose<
    Product[],
    SeasonProduct[],
    SeasonJourney[],
    SeasonPassenger[],
    { [type: string]: SeasonPassenger[] },
    { [type: string]: number }
  >(
    R.map<{ [type: string]: SeasonPassenger[] }, { [type: string]: number }>(R.length),
    R.groupBy(({ passengerType }) => passengerType.toLowerCase()),
    R.pluck('passenger'),
    R.pluck('journey'),
    (items: Product[]): SeasonProduct[] =>
      R.filter(({ type }) => type === ProductType.Season, items) as SeasonProduct[]
  )(products)
);

export const getPassengersByTypeCount = createSelector(
  getBookingsPassengersByType,
  getProductsPassengersByTypeCount,
  (bookingsPassengers, productsPassengers) =>
    R.compose(
      R.map(([type, passengers]) => ({
        type,
        passengers,
      })),
      R.toPairs,
      R.mergeDeepWithKey(
        (_: string, bookingPassengers: number, productPassengers: number) =>
          bookingPassengers + productPassengers
      )
    )(bookingsPassengers, productsPassengers)
);

export const getRefundableProducts = createSelector(
  getOrder,
  selectProducts,
  (order: Order, products: Product[]) => {
    if (!order) {
      return [];
    }

    const { travelBookings } = order;
    return [
      ...(travelBookings || [])
        .map(({ type, ...rest }) => ({
          type: type || ProductType.TravelProduct,
          ...rest,
        }))
        .sort((travelBooking1, travelBooking2) =>
          moment
            .utc(travelBooking1.journeys[0]?.departAt)
            .diff(moment.utc(travelBooking2.journeys[0]?.departAt))
        ),
      ...(products || []),
    ];
  }
);

export const orderOnlyContainsRailcardProducts = createSelector(
  getProducts,
  (products) => products && products.every(({ type }) => type === ProductType.Railcard)
);

export const orderOnlyContainsSeasonProducts = createSelector(
  getProducts,
  (products) => products && products.every(({ type }) => type === ProductType.Season)
);

export const orderContainsSeasonProduct = createSelector(
  getProducts,
  (products) => products && products.some(({ type }) => type === ProductType.Season)
);
