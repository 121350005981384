import { defineMessages } from 'react-intl';

export default defineMessages({
  euBookings: {
    id: 'EuBookingsAlert.euBookings',
    defaultMessage: 'This customer has upcoming EU bookings.',
  },
  link: {
    id: 'EuBookingsAlert.link',
    defaultMessage: 'Open in /Capitaine',
  },
  loadingStats: {
    id: 'EuBookingsAlert.loadingStats',
    defaultMessage: 'Checking for EU bookings ...',
  },
  errorMessage: {
    id: 'EuBookingsAlert.errorMessage',
    defaultMessage: 'Unable to check whether this customer has any EU bookings. Refresh to retry.',
  },
});
