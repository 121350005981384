import { StyleSheet } from 'aphrodite/no-important';
import { fonts, spacers, typography } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  deletePassenger: {
    marginTop: spacers.dp_s24,
    textAlign: 'center',
  },
  errorContainer: {
    paddingBottom: spacers.dp_s32,
  },
  textLabel: {
    fontSize: typography.dp_small_font_size_m,
    letterSpacing: typography.dp_small_letter_spacing,
    lineHeight: typography.dp_small_line_height,
    marginBottom: spacers.dp_s8,
  },
  passengerName: {
    fontSize: typography.dp_body_font_size,
    lineHeight: typography.dp_body_line_height,
    letterSpacing: typography.dp_body_letter_spacing,
    fontWeight: fonts.dp_font_weight_bold,
    marginBottom: spacers.dp_s8,
  },
});
