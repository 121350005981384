import { defineMessages } from 'react-intl';

export default defineMessages({
  depart: {
    id: 'JourneyDetails.depart',
    defaultMessage: 'Departure:',
  },
  arrive: {
    id: 'JourneyDetails.arrive',
    defaultMessage: 'Arrival:',
  },
  passenger: {
    id: 'JourneyDetails.passenger',
    defaultMessage: '{passengerCount, plural, one {# Passenger} other {# Passengers}}',
  },
  passengeradult: {
    id: 'JourneyDetails.passengeradult',
    defaultMessage: '{passengerCount, plural, one {# Adult} other {# Adults}}',
  },
  passengersenior: {
    id: 'JourneyDetails.passengersenior',
    defaultMessage: '{passengerCount, plural, one {# Senior} other {# Seniors}}',
  },
  passengerchild: {
    id: 'JourneyDetails.passengerchild',
    defaultMessage: '{passengerCount, plural, one {# Child} other {# Children}}',
  },
  passengeryouth: {
    id: 'JourneyDetails.passengeryouth',
    defaultMessage: '{passengerCount, plural, one {# Youth} other {# Youths}}',
  },
  passengerinfant: {
    id: 'JourneyDetails.passengerinfant',
    defaultMessage: '{passengerCount, plural, one {# Infant} other {# Infants}}',
  },
  railCard: {
    id: 'JourneyDetails.railCard',
    defaultMessage: '{count}x{name}',
  },
  railCardWithSeparator: {
    id: 'JourneyDetails.railCardWithSeparator',
    defaultMessage: '{count}x{name} & ',
  },
  entanglements: {
    id: 'JourneyDetails.entanglements',
    defaultMessage: 'Entanglements',
  },
});
