import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  journeyDetailsContainer: {
    padding: `0 ${spacers.dp_s16} ${spacers.dp_s24} ${spacers.dp_s16}`,
    borderBottom: `1px solid ${colors.gray.alpha8}`,
  },
  notification: {
    marginTop: spacers.dp_s32,
  },
});
