import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalSubtitle,
  ModalBody,
} from '@contactcentre-web/common/Modal';
import type State from '@contactcentre-web/redux-common/types/State';
import type Action from '@contactcentre-web/redux-common/types/Action';

import ConverterFormContainer from './components/ConverterForm/ConverterForm';
import { actions, selectors } from './module';
import messages from './messages';
import styles from './styles';

interface StateProps {
  visible: boolean;
}

interface DispatchProps {
  onClose: () => void;
}

interface Props extends StateProps, DispatchProps {
  transactionCurrency: string;
  ratesBatchId: string;
  marginsBatchId: string;
  inventoryCurrencies: Array<{ label: string; value: string }>;
}

export const CurrencyConverter = ({ visible, onClose, ...props }: Props) =>
  visible ? (
    <Modal styleSheet={{ content: styles.modalContent }} onClose={onClose}>
      <ModalHeader>
        <ModalTitle>
          <FormattedMessage {...messages.header} />
        </ModalTitle>
        <ModalSubtitle>
          <FormattedMessage {...messages.disclaimer} />
        </ModalSubtitle>
      </ModalHeader>
      <ModalBody>
        <ConverterFormContainer {...props} />
      </ModalBody>
    </Modal>
  ) : null;

const mapStateToProps = (state: State) => ({
  visible: selectors.selectIsOpen(state.currencyConverter),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  onClose: () => dispatch(actions.close()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyConverter);
