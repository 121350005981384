import { defineMessages } from 'react-intl';

export default defineMessages({
  bookingInfo: {
    id: 'CustomerBooking.bookingInfo',
    defaultMessage: 'booking info',
  },
  tickets: {
    id: 'CustomerBooking.tickets',
    defaultMessage: 'tickets',
  },
  otherValidTrains: {
    id: 'CustomerBooking.otherValidTrains',
    defaultMessage: 'Other valid trains',
  },
});
