import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedDate, FormattedMessage } from 'react-intl';

import OriginToDestination from '@contactcentre-web/common/OriginToDestination/OriginToDestination';
import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency';
import FulfilmentStatus from '@contactcentre-web/common/FulfilmentStatus';
import priceShape from '@contactcentre-web/utils/shape/price';
import IconSplitSave from '@contactcentre-web/common/Icons/IconSplitSave';
import IconMultifare from '@contactcentre-web/common/Icons/IconMultifare';
import Link from '@contactcentre-web/common/Link';

import ExchangesBookingAlert from '../ExchangesBookingAlert';
import ExchangedBookingAlert from '../ExchangedBookingAlert';
import VendorStatus from '../VendorStatus/VendorStatus';
import Insurance from '../Insurance/Insurance';
import Invoice from '../Invoice';

import styles, { fillColor } from './styles';
import messages from './messages';

export const getTransRefMessage = (vendor, vendorRegion) => {
  if (vendor === 'NationalExpress') {
    return 'nxTicketRef';
  }
  if (vendorRegion === 'uk') {
    return 'tracsTransId';
  }
  if (vendorRegion === 'eu') {
    return 'pnr';
  }
  return 'transactionId';
};

export const getSupersededMessage = (supersededAt) => {
  if (supersededAt === 'refresh') {
    return 'bookingRefreshed';
  }
  if (supersededAt === 'partialRefund') {
    return 'partialRefund';
  }
  if (supersededAt === 'collectionRestrictionRemoved') {
    return 'collectionRestrictionRemoved';
  }
  return 'unknownSuperseding';
};

export const getCollectionConditionMessage = (condition) => {
  const conditionId = condition.length === 0 ? 'noCollectionConditions' : condition[0];
  return messages[conditionId] || messages.unknownCondition;
};

const BookingHeader = ({
  bookingNum,
  deliveryMethod,
  origin,
  destination,
  isReturn,
  outDate,
  returnDate,
  transactionTotalPrice,
  inventoryTotalPrice,
  ctr,
  ctrCollectionConditions,
  transactionReference,
  vendor,
  vendorRegion,
  convertedOrderReference,
  latestSupersededAt,
  orderReference,
  customerId,
  insurance,
  changedOrderReference,
  changedByOrderReference,
  fulfilmentStatus,
  trainlineProductStatus,
  deliveryRecipient,
  isSplitTicket,
  isMultifareTicket,
  relatedByOrderSubType,
  carrierInvoice,
}) => (
  <section className={css(styles.container)}>
    <div className={css(styles.header)}>
      <div className={css(styles.directions)}>
        <h4 className={css(styles.title)}>
          <FormattedMessage {...messages.booking} values={{ index: bookingNum }} />
        </h4>
        <div>
          <div className={css(styles.direction)}>
            <OriginToDestination
              isReturn={isReturn}
              origin={origin}
              destination={destination}
              fillColor={fillColor}
            />
          </div>
          {isReturn && (
            <span data-test-id="returnLabel" className={css(styles.returnLabel)}>
              <FormattedMessage {...messages.returnLabel} />
            </span>
          )}
          <div className={css(styles.status)}>
            <div className={css(styles.trainlineProductStatus)}>
              {trainlineProductStatus && (
                <FulfilmentStatus
                  status={trainlineProductStatus.issueStatus}
                  errors={trainlineProductStatus.errors}
                />
              )}
            </div>
            {isSplitTicket && <IconSplitSave className={css(styles.splitSave)} />}
            {isMultifareTicket && (
              <span className={css(styles.multifare)}>
                <IconMultifare />
              </span>
            )}
          </div>
        </div>
      </div>
      <div className={css(styles.row)}>
        <div className={css(styles.details)}>
          <div>
            <h4 className={css(styles.title)}>
              <FormattedMessage {...messages.dates} />
            </h4>
            <div className={css(styles.date)}>
              <FormattedMessage
                {...messages.date}
                values={{
                  label: (
                    <strong>
                      <FormattedMessage {...messages.out} />
                    </strong>
                  ),
                  date: (
                    <span className={css(styles.dateValue)}>
                      <FormattedDate
                        value={outDate}
                        weekday="short"
                        day="2-digit"
                        month="short"
                        year="numeric"
                      />
                    </span>
                  ),
                }}
              />
            </div>
            {isReturn && returnDate && (
              <div className={css(styles.date)}>
                <FormattedMessage
                  {...messages.date}
                  values={{
                    label: (
                      <strong>
                        <FormattedMessage {...messages.ret} />
                      </strong>
                    ),
                    date: (
                      <span className={css(styles.dateValue)}>
                        <FormattedDate
                          value={returnDate}
                          weekday="short"
                          day="2-digit"
                          month="short"
                          year="numeric"
                        />
                      </span>
                    ),
                  }}
                />
              </div>
            )}
          </div>
          <div className={css(styles.otherItems)}>
            <h4 className={css(styles.title)}>
              <FormattedMessage {...messages.deliveryMethod} />
            </h4>
            <VendorStatus
              status={fulfilmentStatus}
              deliveryMethod={deliveryMethod}
              deliveryRecipient={deliveryRecipient}
            />
          </div>
          <div className={css(styles.otherItems)}>
            {transactionReference && (
              <div>
                <h4 className={css(styles.title)}>
                  <FormattedMessage {...messages[getTransRefMessage(vendor, vendorRegion)]} />
                </h4>
                <div className={css(styles.value)} data-test="ReferenceValue">
                  {transactionReference}
                </div>

                {latestSupersededAt && (
                  <Link
                    linkType="internal"
                    to={`/customers/${customerId}/bookings/${orderReference}/history`}
                  >
                    <FormattedMessage {...messages[getSupersededMessage(latestSupersededAt)]} />
                  </Link>
                )}
              </div>
            )}
          </div>
          <div className={css(styles.otherItems)}>
            {ctr && (
              <div>
                <h4 className={css(styles.title)}>
                  <FormattedMessage {...messages.ctr} />
                </h4>
                <div className={css(styles.value)} data-test="CtrValue">
                  {ctr}
                </div>
                <span className={css(styles.ctrCollectionCondition)}>
                  <FormattedMessage {...getCollectionConditionMessage(ctrCollectionConditions)} />
                </span>
              </div>
            )}
          </div>
          <div>
            <h4 className={css(styles.title)}>
              <FormattedMessage {...messages.productPrice} />
            </h4>
            <div className={css(styles.price)}>
              <strong>
                <FormattedCurrency {...transactionTotalPrice} />
              </strong>
            </div>
            <div className={css(styles.price, styles.inventoryPrice)}>
              {(convertedOrderReference ||
                transactionTotalPrice.currencyCode !== inventoryTotalPrice.currencyCode) && (
                <span>
                  <FormattedCurrency {...inventoryTotalPrice} />
                </span>
              )}
            </div>
          </div>
        </div>
        {carrierInvoice && (
          <div className={css(styles.otherItems)}>
            <h4 className={css(styles.title)}>
              <FormattedMessage {...messages.invoice} />
            </h4>
            <Invoice invoice={carrierInvoice} />
          </div>
        )}
        {insurance && <Insurance {...insurance} />}
      </div>
    </div>
    <section>
      <ExchangedBookingAlert
        customerId={customerId}
        exchangedByOrderReference={changedByOrderReference}
      />
      <ExchangesBookingAlert
        customerId={customerId}
        exchangedOrderReference={changedOrderReference}
        type={relatedByOrderSubType}
      />
    </section>
  </section>
);

BookingHeader.propTypes = {
  bookingNum: PropTypes.number.isRequired,
  deliveryMethod: PropTypes.string,
  outDate: PropTypes.string.isRequired,
  returnDate: PropTypes.string,
  origin: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
  isReturn: PropTypes.bool.isRequired,
  inventoryTotalPrice: priceShape.isRequired,
  transactionTotalPrice: priceShape.isRequired,
  ctr: PropTypes.string,
  ctrCollectionConditions: PropTypes.array,
  transactionReference: PropTypes.string,
  travelRestriction: PropTypes.bool,
  vendor: PropTypes.string,
  vendorRegion: PropTypes.string.isRequired,
  convertedOrderReference: PropTypes.string,
  latestSupersededAt: PropTypes.string,
  orderReference: PropTypes.string,
  customerId: PropTypes.string,
  isSplitTicket: PropTypes.bool,
  insurance: PropTypes.object,
  changedOrderReference: PropTypes.string,
  changedByOrderReference: PropTypes.string,
  fulfilmentStatus: PropTypes.string,
  trainlineProductStatus: PropTypes.object,
  deliveryRecipient: PropTypes.object,
  relatedByOrderSubType: PropTypes.string,
  carrierInvoice: PropTypes.object,
  isMultifareTicket: PropTypes.bool,
};

export default BookingHeader;
