import React, { Dispatch, FunctionComponent, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation, Redirect, useHistory } from 'react-router-dom';
import { css } from 'aphrodite/no-important';
import { Heading } from '@trainline/depot-web';

import Logo from '@contactcentre-web/common/Logo/Logo';
import type State from '@contactcentre-web/redux-common/types/State';
import type Action from '@contactcentre-web/redux-common/types/Action';

import { actions as userActions, NextAction } from '../../redux/module';
import { selectLoggedIn, nextAction } from '../../redux/selectors';

import ChangePasswordForm from './ChangePasswordForm';
import styles from './styles';
import messages from './messages';

interface LocationState {
  username: string;
}

interface StateProps {
  isLoggedIn: boolean;
  nextAction?: NextAction;
}

interface DispatchProps {
  authFlowForwarding: () => void;
}

type Props = StateProps & DispatchProps;

export const ChangePassword: FunctionComponent<Props> = ({
  isLoggedIn,
  nextAction,
  authFlowForwarding,
}) => {
  const {
    search,
    state: { username },
  } = useLocation<LocationState>();
  const redirect = new URLSearchParams(search).get('redirect');
  const { replace } = useHistory();
  const replaceRef = useRef(replace);
  const authFlowForwardingRef = useRef(authFlowForwarding);

  React.useEffect(() => {
    if (nextAction) {
      if (authFlowForwardingRef.current) {
        authFlowForwardingRef.current();
      }
      replaceRef.current(nextAction.action, nextAction.context);
    }
  }, [nextAction, replaceRef, authFlowForwarding]);

  if (isLoggedIn) {
    return <Redirect to={redirect ? decodeURIComponent(redirect) : '/'} />;
  }

  return (
    <div>
      <div className={css(styles.appbar)}>
        <nav className={css(styles.navbar)}>
          <Logo />
        </nav>
      </div>
      <div className={css(styles.changePasswordContainer)}>
        <div>
          <Heading typeStyle="title1" as="h1" color="base">
            <FormattedMessage {...messages.title} />
          </Heading>
          <ChangePasswordForm username={username} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  isLoggedIn: selectLoggedIn(state),
  nextAction: nextAction(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  authFlowForwarding: () => dispatch(userActions.authFlowForwarding()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
