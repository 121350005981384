import { StyleSheet } from 'aphrodite/no-important';
import { palettes, borderRadius, spacers } from '@trainline/depot-web/css/themes/trainline';

export const styles = StyleSheet.create({
  container: {
    border: `1px solid ${palettes.dp_app_border_base}`,
    borderRadius: borderRadius.dp_r8,
    padding: spacers.dp_s16,
    flex: 1,
  },
  row: {
    display: 'flex',
    columnGap: spacers.dp_s16,
    marginBottom: spacers.dp_s24,
    ':nth-child(1n) > label': {
      flex: 1,
    },
    ':nth-child(1n) > p': {
      padding: spacers.dp_s4,
      backgroundColor: palettes.dp_app_backdrop_dark,
      border: `1px solid ${palettes.dp_app_border_base}`,
      borderRadius: borderRadius.dp_r4,
    },
  },
  field: {
    marginBottom: spacers.dp_s24,
  },
  button: {
    marginLeft: 'auto',
    display: 'block',
  },
  errorContainer: {
    marginBottom: spacers.dp_s16,
  },
});
