import { StyleSheet } from 'aphrodite/no-important';
import { fonts, spacers } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  bookingJourney: {
    fontWeight: fonts.dp_font_weight_bold,
  },
  bookingDetails: {
    display: 'flex',
  },
  bookingDetailsReturn: {
    fontWeight: fonts.dp_font_weight_semibold,
  },
  bookingDetailsDate: {
    marginLeft: spacers.dp_s16,
  },
  link: {
    textDecoration: 'none',
    color: colors.white,
  },
});
