import React from 'react';
import { css } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { IconInfo } from '@trainline/depot-web';

import FormattedDateInLondonTz from '@contactcentre-web/common/FormattedDateInLondonTz/FormattedDateInLondonTz';
import FormattedTimeInLondonTz from '@contactcentre-web/common/FormattedTimeInLondonTz/FormattedTimeInLondonTz';
import Tooltip from '@contactcentre-web/common/Tooltip';

import PaymentStatus from '../PaymentStatus';

import messages from './messages';
import styles from './styles';

const PaymentSummaryTableHead = ({
  paymentStatus,
  errors,
  paymentDate,
  hasAmendedSeatReservation,
}) => (
  <>
    <div className={css(styles.dateWrapper)}>
      <div className={css(styles.orderPayment)}>
        <FormattedMessage {...messages.orderPayment} />
        {hasAmendedSeatReservation && (
          <Tooltip
            orientation="Below"
            anchor={
              <span className={css(styles.infoIcon)}>
                <IconInfo />
              </span>
            }
          >
            <FormattedMessage {...messages.amendReservation} values={{ br: <br /> }} />
          </Tooltip>
        )}
      </div>
      {paymentDate && (
        <time className={css(styles.time)}>
          <FormattedDateInLondonTz value={paymentDate} year="numeric" month="short" day="numeric" />
          <span>, </span>
          <FormattedTimeInLondonTz value={paymentDate} hour="2-digit" minute="2-digit" />
        </time>
      )}
      {!paymentDate && <div>-</div>}
    </div>
    <div className={css(styles.infoBlock)} />
    <section className={css(styles.header)}>
      <PaymentStatus paymentStatus={paymentStatus} errors={errors} />
    </section>
    <table className={css(styles.ticketsTable)}>
      <thead>
        <tr>
          <th className={css(styles.ticketsTableHeader, styles.itemTableHeader, styles.pl)}>
            <FormattedMessage {...messages.itemHeaderTitle} />
          </th>
          <th className={css(styles.ticketsTableHeader, styles.ticketsCellAlignLeft)}>
            <FormattedMessage {...messages.productPriceHeaderTitle} />
          </th>
          <th className={css(styles.ticketsTableHeader, styles.ticketsCellAlignLeft, styles.pr)}>
            <FormattedMessage {...messages.pricePaidHeaderTitle} />
          </th>
        </tr>
      </thead>
    </table>
  </>
);

PaymentSummaryTableHead.propTypes = {
  paymentDate: PropTypes.string,
  paymentStatus: PropTypes.string,
  errors: PropTypes.array,
  hasAmendedSeatReservation: PropTypes.bool,
};

export default PaymentSummaryTableHead;
