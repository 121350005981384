import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import CountryName from '@contactcentre-web/common/CountryName/CountryName';

import PaymentServiceProviderInfo from '../PaymentServiceProviderInfo';

import styles from './styles';
import messages from './messages';

const parseAddress = (address) => {
  if (!address) return [];

  const countryName = <CountryName countryCode={address.countryCode} />;
  const addressLines = [
    address.addressLine1,
    address.addressLine2,
    address.addressLine3,
    address.addressLine4,
    address.addressLine5,
  ].filter((str) => str);

  addressLines[addressLines.length - 1] = `${addressLines[addressLines.length - 1]}, ${
    address.postcode
  }`;
  addressLines.push(countryName);

  return addressLines;
};

const PaymentDetails = ({ paymentTypeDetails, paymentServiceProvider }) => {
  const addressLines = parseAddress(paymentTypeDetails?.billingAddress);
  const addressLineElements = addressLines.map((x, i) => <div key={i}>{x}</div>);

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.infoBlock, styles.infoPaidByBlock)}>
        {paymentTypeDetails?.paidBy && (
          <>
            <div className={css(styles.blockTitle)}>
              <FormattedMessage {...messages.paidBy} />
            </div>
            <div>{paymentTypeDetails.paidBy}</div>
          </>
        )}
        <div className={css(styles.blockTitle)}>
          <FormattedMessage {...messages.paymentAddress} />
        </div>
        {addressLineElements}
        <div className={css(styles.billingEmailAddress)}>
          {paymentTypeDetails?.billingEmailAddress}
        </div>
      </div>

      <div className={css(styles.infoBlock)}>
        <div className={css(styles.blockTitle)}>
          <FormattedMessage {...messages.paymentType} />
        </div>
        <div>{paymentTypeDetails?.paymentType}</div>
        {paymentTypeDetails?.card?.tokenisedCardNumber && (
          <>
            <div>{paymentTypeDetails.card.type}</div>
            <div>{paymentTypeDetails.card.tokenisedCardNumber}</div>
          </>
        )}
        <PaymentServiceProviderInfo paymentServiceProvider={paymentServiceProvider} />
      </div>
    </div>
  );
};

PaymentDetails.propTypes = {
  paymentTypeDetails: PropTypes.shape({
    paymentType: PropTypes.string.isRequired,
    billingAddress: PropTypes.object,
    card: PropTypes.object,
    paidBy: PropTypes.string,
    billingEmailAddress: PropTypes.string,
  }),
  paymentServiceProvider: PropTypes.string,
};

export default PaymentDetails;
