import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import TooltipLabel from '@contactcentre-web/common/TooltipLabel';

import styles from './styles';
import messages from './messages';

const BookingsTableHeader = () => (
  <div className={css(styles.row, styles.header)}>
    <div className={css(styles.cell)}>
      <FormattedMessage {...messages.title_booking} />
    </div>
    <div className={css(styles.cell)}>
      <FormattedMessage {...messages.title_carrier_or_connection} />
    </div>
    <div className={css(styles.cell)}>
      <TooltipLabel helpTip={<FormattedMessage {...messages.title_fulfilment_tip} />}>
        <FormattedMessage {...messages.title_fulfilment} />
      </TooltipLabel>
    </div>
    <div className={css(styles.cell)}>
      <TooltipLabel helpTip={<FormattedMessage {...messages.title_vendor_tip} />}>
        <FormattedMessage {...messages.title_vendor} />
      </TooltipLabel>
    </div>
    <div className={css(styles.cell)}>
      <FormattedMessage {...messages.title_reimbursed} />
    </div>
  </div>
);

export default BookingsTableHeader;
