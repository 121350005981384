import { defineMessages } from 'react-intl';

export default defineMessages({
  convertedByBooking: {
    id: 'ConvertedOrderAlert.convertedByBooking',
    defaultMessage: 'This is a converted booking. View {link}.',
  },

  original: {
    id: 'ConvertedOrderAlert.original',
    defaultMessage: 'original',
  },
});
