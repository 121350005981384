import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'HelpBanner.title',
    defaultMessage: 'Select ticket(s) to T&C refund',
  },
  message: {
    id: 'HelpBanner.message',
    defaultMessage:
      'All bookings for this order are shown here – refundable fares are highlighted and selected by default. Please check as appropriate, based on the customer’s request.',
  },
});
