import React, { FunctionComponent } from 'react';
import { css } from 'aphrodite/no-important';

import RefundCardHeader from '../RefundCardHeader/RefundCardHeader';
import RefundCardDetailsContainer from '../RefundCardDetails/RefundCardDetails';
import { RequestItem } from '../../selectors';

import styles from './styles';

interface Props {
  request: RequestItem;
  returnUrl: string;
}

const RefundCard: FunctionComponent<Props> = ({ request, returnUrl }) => (
  <div className={css(styles.card)}>
    <RefundCardHeader {...request} />
    <RefundCardDetailsContainer {...request} returnUrl={returnUrl} />
  </div>
);

export default RefundCard;
