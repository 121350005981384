import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'CurrencyConverter.header',
    defaultMessage: 'Conversion Rate',
  },
  disclaimer: {
    id: 'CurrencyConverter.disclaimer',
    defaultMessage: '* At time of purchase',
  },
});
