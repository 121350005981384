import React, { FunctionComponent } from 'react';
import { Route } from 'react-router-dom';

import NotesHeader from '../NotesHeader/NotesHeader';
import NotesTopicButton from '../NotesTopicButton/NotesTopicButton';
import SidebarContent from '../../../SidebarContent';
import NotesLoading from '../NotesLoading/NotesLoading';
import type { Notes, Topic } from '../../module';

interface Props {
  onSetTopic: (topic: Topic, orderId: string) => void;
  notes: Notes;
  loading: boolean;
}

const NotesOverview: FunctionComponent<Props> = ({ onSetTopic, notes = {}, loading }) => (
  <>
    <NotesHeader />
    <SidebarContent>
      {loading ? (
        <NotesLoading />
      ) : (
        <Route
          path="/customers/:customerId/bookings/:orderId"
          render={({
            match: {
              params: { orderId },
            },
          }) => (
            <NotesTopicButton
              key={`${'order'}-${orderId}`}
              topic="Order"
              count={notes && notes.Order && notes.Order[orderId] ? notes.Order[orderId].length : 0}
              setTopic={() => onSetTopic('Order', orderId)}
            />
          )}
        />
      )}
    </SidebarContent>
  </>
);

export default NotesOverview;
