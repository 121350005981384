import { Paragraph, StatusMessage } from '@trainline/depot-web';
import { css } from 'aphrodite/no-important';
import React, { useState } from 'react';
import { FormattedMessage, FormattedNumber, FormattedDate, FormattedTime } from 'react-intl';

import Button from '@contactcentre-web/common/Button';
import Expandable from '@contactcentre-web/common/Expandable';
import Link from '@contactcentre-web/common/Link/Link';
import {
  useVirtualTerminalApproveCompensation,
  useVirtualTerminalRejectCompensation,
  VirtualTerminalApprovalQueueResponse,
} from '@contactcentre-web/hooks/api/useVirtualTerminal';
import HorizontalRule from '@contactcentre-web/common/HorizontalRule';

import ApprovalConfirmation from '../ApprovalConfirmation/ApprovalConfirmation';
import ApprovalRejection from '../ApprovalRejection/ApprovalRejection';

import messages from './messages';
import styles from './styles';

interface Props {
  compensation: VirtualTerminalApprovalQueueResponse;
}

const ApprovalQueueItem = ({ compensation }: Props) => {
  const [isOpened, setIsOpened] = useState(false);
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);
  const [isShowRejectionModal, setIsShowRejectionModal] = useState(false);

  const {
    mutate: approveCompensation,
    isLoading: isApprovalLoading,
    isError: isApprovalError,
    isSuccess: isApprovalSuccess,
  } = useVirtualTerminalApproveCompensation();

  const {
    mutate: rejectCompensation,
    isLoading: isRejectLoading,
    isError: isRejectError,
    isSuccess: isRejectSuccess,
  } = useVirtualTerminalRejectCompensation();

  const handleProceed = () => {
    setIsShowConfirmationModal(false);
    approveCompensation(compensation.id);
  };

  const handleReject = () => {
    setIsShowRejectionModal(false);
    rejectCompensation(compensation.id);
  };

  return (
    <>
      <section
        className={css(
          styles.approvalQueueItemWrapper,
          isOpened && styles.approvalQueueItemWrapperOpen
        )}
        data-testid="approvalQueueItem"
      >
        <div className={css(styles.approvalQueueItemColumnLeft)}>
          <Paragraph typeStyle="body" fontWeight="regular" as="p" color="base">
            {compensation.friendlyOrderId} • {compensation.customerName.firstName}{' '}
            {compensation.customerName.lastName}
          </Paragraph>
        </div>
        <div className={css(styles.approvalQueueItemColumnRight)}>
          <Paragraph typeStyle="body" fontWeight="regular" as="p" color="base">
            <FormattedMessage
              {...messages.requestedDate}
              values={{
                day: (
                  <FormattedDate
                    value={compensation.requestedDate}
                    year="numeric"
                    month="short"
                    day="2-digit"
                  />
                ),
                time: <FormattedTime value={compensation.requestedDate} />,
              }}
            />
          </Paragraph>
          <Expandable.Anchor
            onClick={() => setIsOpened((previousState) => !previousState)}
            isOpen={isOpened}
            styleSheet={{ anchor: styles.approvalQueueItemButton }}
          ></Expandable.Anchor>
          <Paragraph typeStyle="body" fontWeight="regular" as="p" color="subdued">
            <FormattedMessage
              {...messages.agent}
              values={{ agentName: compensation.requesterUsername }}
            />
          </Paragraph>
        </div>
        <Expandable.Content
          styleSheet={styles.approvalQueueItemExpandableContent}
          isOpen={isOpened}
        >
          <div className={css(styles.approvalQueueItemExpandableContainer)}>
            {(isApprovalError || isApprovalSuccess) && (
              <div className={css(styles.approvalQueueItemExpandableContentStatusMessages)}>
                {isApprovalError && (
                  <StatusMessage status="negative">
                    <FormattedMessage {...messages.errorApprovingMessage} />
                  </StatusMessage>
                )}
                {isApprovalSuccess && (
                  <StatusMessage status="positive">
                    <FormattedMessage {...messages.approvedMessage} />
                  </StatusMessage>
                )}
              </div>
            )}
            {(isRejectError || isRejectSuccess) && (
              <div className={css(styles.approvalQueueItemExpandableContentStatusMessages)}>
                {isRejectError && (
                  <StatusMessage status="negative">
                    <FormattedMessage {...messages.errorRejectingMessage} />
                  </StatusMessage>
                )}
                {isRejectSuccess && (
                  <StatusMessage status="positive">
                    <FormattedMessage {...messages.rejectedMessage} />
                  </StatusMessage>
                )}
              </div>
            )}
            <div className={css(styles.approvalQueueItemExpandableContentColumnLeft)}>
              <div>
                <Paragraph typeStyle="body" fontWeight="regular" as="p" color="subdued">
                  <FormattedMessage {...messages.reasonCodes} />
                </Paragraph>
                <Paragraph typeStyle="body" fontWeight="bold" as="p" color="base">
                  {compensation.reasonCode.description}
                </Paragraph>
              </div>
              <div>
                <Paragraph typeStyle="body" fontWeight="regular" as="p" color="subdued">
                  <FormattedMessage {...messages.notesFromAgent} />
                </Paragraph>
                <Paragraph typeStyle="body" fontWeight="bold" as="p" color="base">
                  {compensation.notes}
                </Paragraph>
              </div>
              <Link
                linkType="external"
                href={`/customers/${compensation.customerId}/bookings/${compensation.friendlyOrderId}`}
              >
                <FormattedMessage {...messages.viewOriginalOrder} />
              </Link>
            </div>
            <div>
              <Paragraph typeStyle="body" fontWeight="regular" as="p" color="subdued">
                <span className={css(styles.approvalQueueItemExpandableContentColumnCenterWrapper)}>
                  <FormattedMessage
                    {...messages.originalTransaction}
                    values={{
                      ...compensation.originalAmount,
                      span: () => (
                        <span>
                          <FormattedNumber
                            value={compensation.originalAmount.amount}
                            maximumFractionDigits={2}
                            minimumFractionDigits={2}
                          />
                        </span>
                      ),
                    }}
                  />
                </span>
              </Paragraph>
              <HorizontalRule />
              <Paragraph typeStyle="body" fontWeight="regular" as="p" color="subdued">
                <span className={css(styles.approvalQueueItemExpandableContentColumnCenterWrapper)}>
                  <FormattedMessage
                    {...messages.compensation}
                    values={{
                      ...compensation.compensationAmount,
                      strong: () => (
                        <strong>
                          <FormattedNumber
                            value={compensation.compensationAmount.amount}
                            maximumFractionDigits={2}
                            minimumFractionDigits={2}
                          />
                        </strong>
                      ),
                    }}
                  />
                </span>
              </Paragraph>
            </div>
            <div className={css(styles.approvalQueueItemExpandableContentColumnRight)}>
              <Button
                size="small"
                variant="primary"
                onClick={() => setIsShowConfirmationModal(true)}
                loading={isApprovalLoading}
                disabled={isApprovalSuccess || isRejectSuccess}
              >
                <FormattedMessage {...messages.approveButton} />
              </Button>
              <Button
                size="small"
                variant="destructive"
                loading={isRejectLoading}
                disabled={isApprovalSuccess || isRejectSuccess}
                onClick={() => setIsShowRejectionModal(true)}
              >
                <FormattedMessage {...messages.rejectButton} />
              </Button>
            </div>
          </div>
        </Expandable.Content>
      </section>
      {isShowConfirmationModal && (
        <ApprovalConfirmation
          onProceedClick={handleProceed}
          onCloseClick={() => setIsShowConfirmationModal(false)}
        />
      )}
      {isShowRejectionModal && (
        <ApprovalRejection
          compensation={compensation.compensationAmount}
          onRejectClick={handleReject}
          onCloseClick={() => setIsShowRejectionModal(false)}
        />
      )}
    </>
  );
};

export default ApprovalQueueItem;
