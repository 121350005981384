import { defineMessages } from 'react-intl';

export default defineMessages({
  legOf: {
    id: 'SupersededBooking.TicketDetails.legOf',
    defaultMessage: '{index} of {total}',
  },
  outward: {
    id: 'SupersededBooking.TicketDetails.outward',
    defaultMessage: 'out',
  },
  inward: {
    id: 'SupersededBooking.TicketDetails.inward',
    defaultMessage: 'in',
  },
  ful: {
    id: 'SupersededBooking.TicketDetails.ful',
    defaultMessage: 'ful',
  },
  operator: {
    id: 'SupersededBooking.TicketDetails.operator',
    defaultMessage: 'operator',
  },
  route: {
    id: 'SupersededBooking.TicketDetails.route',
    defaultMessage: '<line><loc>{origin}</loc> to</line> <loc>{destination}</loc>',
  },
  passengerTypeCount: {
    id: 'SupersededBooking.TicketDetails.ticket',
    defaultMessage: '{count} {passengerType}',
  },
  fareConditionSummaryNotAvailble: {
    id: 'SupersededBooking.TicketDetails.fareConditionSummaryNotAvailable',
    defaultMessage:
      'Fare conditions summary is not yet available. Click the fare to see the full conditions.',
  },
});
