import { StyleSheet } from 'aphrodite/no-important';
import { palettes, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  outContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
  },
  inputContainer: {
    position: 'relative',
    flex: '1 1 auto',
    width: '100%',
  },
  inputContainerWithInfoButton: {
    position: 'relative',
  },
  infoButtonContainer: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: '10px',
    lineHeight: 0,
  },
  infoButton: {
    display: 'flex',
    cursor: 'pointer',
    color: palettes.dp_app_border_dark,
  },
  ballon: {
    width: '40%',
    left: '50%',
  },
  clearButton: {
    position: 'absolute',
    right: spacers.dp_s12,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    border: 0,
  },
  clearIcon: {
    width: '12px',
    height: '12px',
    background: palettes.dp_brand_neutral_light,
    color: palettes.dp_app_text_inverted,
    borderRadius: ' 50%',
    padding: '2px',
  },
});
