import React, { useRef, useEffect, FunctionComponent, Dispatch } from 'react';
import { connect } from 'react-redux';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';

import HorizontalRule from '@contactcentre-web/common/HorizontalRule';
import BookingSearchResults from '@contactcentre-web/common/BookingSearchResults';
import Loader from '@contactcentre-web/common/Loader';
import ListSummary from '@contactcentre-web/common/ListSummary/ListSummary';
import type State from '@contactcentre-web/redux-common/types/State';
import type Action from '@contactcentre-web/redux-common/types/Action';
import type { FilterType } from '@contactcentre-web/common/BookingSearchResults/types';
import NoSearchResult from '@contactcentre-web/common/NoSearchResult/NoSearchResult';

import CustomerResults from './components/CustomerResults/CustomerResults';
import LegacyResult from './components/LegacyResult/LegacyResult';
import styles from './components/CustomerListItem/styles';
import type { SearchResultsItem } from './utils/searchService';
import { actions, SearchResultProductDetails, selectors } from './module';
import resultStyles from './styles';
import messages from './messages';

interface SearchCriteria {
  q?: string;
  pnr?: string;
  orderReference?: string;
}

interface StateProps {
  isCustomerSearch: boolean;
  isLegacySearch: boolean;
  isProductSearch: boolean;
  isProductReference: boolean;
  searchResults: {
    totalCount: number;
    items: Array<SearchResultsItem & SearchResultProductDetails>;
  };
  searching: boolean;
  isSearchFailed: boolean;
  appliedFilters: Set<FilterType>;
}

interface DispatchProps {
  reset: () => void;
  loadSearchResults: (queryParams: SearchCriteria) => void;
  addFilters: (filters: Set<FilterType>) => void;
  removeFilter: (filter: FilterType) => void;
}

type Props = StateProps & DispatchProps;

const getSearchCriteria = ({ q, pnr, orderReference }: SearchCriteria) =>
  q || pnr || orderReference;

export const constructUrl = (
  isCustomerSearch: boolean,
  { id, customerId, orderReference }: { id: string; customerId: string; orderReference: string }
) =>
  isCustomerSearch
    ? `/customers/${id}/bookings`
    : `/customers/${customerId}/bookings/${orderReference}`;

export const SearchResults: FunctionComponent<Props> = ({
  isCustomerSearch,
  isLegacySearch,
  isProductSearch,
  isProductReference,
  searchResults: { totalCount, items },
  searching,
  loadSearchResults,
  reset,
  isSearchFailed,
  addFilters,
  removeFilter,
  appliedFilters,
}) => {
  const { search } = useLocation();
  const history = useHistory();
  const loadSearchResultsRef = useRef(loadSearchResults);
  const queryParams = qs.parse(search);
  const resetRef = useRef(reset);

  useEffect(() => {
    resetRef.current();
    return () => resetRef.current();
  }, [resetRef]);

  useEffect(() => {
    const qp = qs.parse(search);
    if (Object.keys(qp).length === 0) {
      history.push('/');
    } else if (getSearchCriteria(qp)) loadSearchResultsRef.current(qp);
  }, [search, loadSearchResultsRef]);

  const hasItems = totalCount !== 0;
  const isProductResult = isProductSearch && hasItems;

  if (searching) {
    return <Loader />;
  }
  if (isSearchFailed) {
    return null;
  }

  if (isLegacySearch) {
    return <LegacyResult result={items[0]} />;
  }

  if (totalCount === 1 && (isProductSearch || isCustomerSearch)) {
    return <Redirect to={constructUrl(isCustomerSearch, items[0])} />;
  }

  if (isProductReference) {
    const friendlyOrderId = items[0].friendlyOrderId;
    const managedGroupNumber = items[0].managedGroupNumber;

    if (totalCount === 1) {
      return <Redirect to={`/managed-groups/${managedGroupNumber}?q=${friendlyOrderId}`} />;
    }

    return (
      <NoSearchResult>
        <FormattedMessage {...messages.noResultsSearchProductReference} />
      </NoSearchResult>
    );
  }

  if (isCustomerSearch) {
    return (
      <>
        <CustomerResults
          onCustomerSelected={(id) => history.push(`/customers/${id}/bookings`)}
          searching={searching}
          customers={items}
          criterion={getSearchCriteria(queryParams)}
        />
        {hasItems && <HorizontalRule styleSheet={styles.ruler} />}
        {hasItems && (
          <ListSummary type="results" total={totalCount} name={getSearchCriteria(queryParams)} />
        )}
      </>
    );
  }

  if (isProductResult) {
    return (
      <div className={css(resultStyles.bookingListContent)}>
        <BookingSearchResults
          bookings={items}
          addFilters={addFilters}
          removeFilter={removeFilter}
          appliedFilters={appliedFilters}
        />
      </div>
    );
  }

  return (
    <NoSearchResult>
      <FormattedMessage {...messages.noResultsSearchUnknown} />
    </NoSearchResult>
  );
};

const mapStateToProps = (state: State) => ({
  isCustomerSearch: selectors.isCustomerSearch(state),
  isLegacySearch: selectors.isLegacySearch(state),
  isProductSearch: selectors.isProductSearch(state),
  isProductReference: selectors.isProductReference(state),
  searchResults: selectors.getSearchResults(state),
  searching: selectors.getSearching(state),
  isSearchFailed: selectors.isSearchFailed(state),
  appliedFilters: selectors.selectAppliedFilters(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  reset: () => dispatch(actions.searchReset()),
  loadSearchResults: (queryParams: SearchCriteria) =>
    dispatch(
      actions.searchRequested({
        query: queryParams.q || queryParams.orderReference,
        pnr: queryParams.pnr,
      })
    ),
  addFilters: (filters: Set<FilterType>) => dispatch(actions.searchAddFilters(filters)),
  removeFilter: (filter: FilterType) => dispatch(actions.searchRemoveFilter(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
