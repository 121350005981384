import { defineMessages } from 'react-intl';

export default defineMessages({
  Storm: {
    id: 'TelephonyStatus.Storm',
    defaultMessage: 'Storm',
  },
  AmazonConnect: {
    id: 'TelephonyStatus.AmazonConnect',
    defaultMessage: 'Amazon Connect',
  },
  Ok: {
    id: 'TelephonyStatus.Ok',
    defaultMessage: 'Ok',
  },
  TelephonyAccountAlreadyMapped: {
    id: 'TelephonyStatus.TelephonyAccountAlreadyMapped',
    defaultMessage:
      'Another agent is already mapped to the {source} account. Contact your manager now.',
  },
  TelephonyAccountNotFound: {
    id: 'TelephonyStatus.TelephonyAccountNotFound',
    defaultMessage:
      'No {source} account was found with your email address. Contact your manager now.',
  },
  Unknown: {
    id: 'TelephonyStatus.Unknown',
    defaultMessage:
      'There was an unexpected issue mapping your account to {source}. Contact your manager now.',
  },
  MappingNotFound: {
    id: 'TelephonyStatus.MappingNotFound',
    defaultMessage:
      'Your account has not been mapped to any telephony provider. Contact your manager now.',
  },
  UnexpectedIssue: {
    id: 'TelephonyStatus.UnexpectedIssue',
    defaultMessage: 'An error occurred. Please wait a moment and refresh the page to try again.',
  },
  LoggedIn: {
    id: 'TelephonyStatus.LoggedIn',
    defaultMessage: 'Logged in to ADS as {username}',
  },
  LoggedOut: {
    id: 'TelephonyStatus.LoggedOut',
    defaultMessage: 'You need to authenticate into ADS',
  },
});
