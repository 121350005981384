import React, { Fragment, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import { RailcardRefundable } from '@contactcentre-web/hooks/api/useOrderHistory';

import OrderHistory from '../../../OrderHistory/OrderHistory';

import messages from './messages';

interface Props {
  railcardRefundables: Array<RailcardRefundable>;
}

const RefundRailcardInformation: FunctionComponent<Props> = ({ railcardRefundables }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      {railcardRefundables.map(({ cardHolders, name, number }, index) => {
        const sortedCardHolders = [
          ...cardHolders.filter((holder) => holder.isPrimary),
          ...cardHolders.filter((holder) => !holder.isPrimary),
        ];
        return (
          <OrderHistory.Items key={index}>
            <OrderHistory.Item label={formatMessage(messages.product)}>{name}</OrderHistory.Item>
            <OrderHistory.Item label={formatMessage(messages.railcardNumber)}>
              {number}
            </OrderHistory.Item>
            {sortedCardHolders.map(({ fullName, isPrimary }, index) => (
              <OrderHistory.Item
                label={
                  isPrimary && sortedCardHolders.length > 1
                    ? formatMessage(messages.firstRailcardHolder)
                    : index >= 1
                    ? formatMessage(messages.secondRailcardHolder)
                    : formatMessage(messages.railcardHolder)
                }
                key={index}
              >
                {fullName}
              </OrderHistory.Item>
            ))}
          </OrderHistory.Items>
        );
      })}
    </>
  );
};
export default RefundRailcardInformation;
