import { defineMessages } from 'react-intl';

export default defineMessages({
  requested: {
    id: 'Invoice.requested',
    defaultMessage: 'Requested',
  },
  approved: {
    id: 'Invoice.approved',
    defaultMessage: 'Approved',
  },
  rejected: {
    id: 'Invoice.rejected',
    defaultMessage: 'Rejected',
  },
  waitingForApproval: {
    id: 'Invoice.waitingForApproval',
    defaultMessage: 'Waiting for approval',
  },
  generating: {
    id: 'Invoice.generating',
    defaultMessage: 'Waiting to be generated',
  },
  sentToEmail: {
    id: 'Invoice.sentToEmail',
    defaultMessage: "The invoice has been sent to customer's email address",
  },
  pdfInvoice: {
    id: 'Invoice.pdfInvoice',
    defaultMessage: 'PDF invoice',
  },
});
