import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import MidDot from '@contactcentre-web/common/MidDot/MidDot';

import messages from './messages';

const SeatReservation = ({ compartment = {}, seatNumber, deck, type, position }) => {
  const { formatMessage } = useIntl();

  return (
    <span>
      {(compartment.identifier || seatNumber) && (
        <span>
          {[
            compartment.identifier &&
              formatMessage(messages.car, {
                compartment: compartment.identifier,
              }),
            seatNumber,
          ]
            .filter((v) => v)
            .join(', ')}
        </span>
      )}
      {(deck || type || position) && (
        <span>
          <MidDot />
          {[deck, type].filter((v) => v).join(', ')}
          {position && ` (${position.toLowerCase()})`}
        </span>
      )}
      {compartment.type && (
        <span>
          <MidDot />
          {compartment.type}
        </span>
      )}
    </span>
  );
};

SeatReservation.propTypes = {
  compartment: PropTypes.object,
  seatNumber: PropTypes.string,
  deck: PropTypes.string,
  type: PropTypes.string,
  position: PropTypes.string,
};

export default SeatReservation;
