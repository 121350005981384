import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import Price from '@contactcentre-web/redux-common/types/Price';
import ProductType from '@contactcentre-web/redux-common/types/ProductType';

import VendorStatus from '../../../VendorStatus';
import FormattedTimeInLondonTz from '../../../FormattedTimeInLondonTz';
import FormattedDateInLondonTz from '../../../FormattedDateInLondonTz';
import FormattedCurrency from '../../../FormattedCurrency';
import { CustomerBooking, ProductDetails } from '../../types';

import TravelProductRenderer from './TravelProductRenderer';
import RailcardProductRenderer from './RailcardProductRenderer';
import TravelcardProductRenderer from './TravelcardProductRenderer';
import styles from './styles';
import messages from './messages';

type ProductRenderer = {
  description?: () => JSX.Element | string | undefined;
  departureDate?: () => JSX.Element | undefined;
  returnDate?: () => JSX.Element | undefined;
  inventoryReference?: () => string | undefined;
};

const productRendererFactory = (
  productType: ProductType,
  productDetails: ProductDetails
): ProductRenderer => {
  switch (productType) {
    case ProductType.TravelProduct:
    case ProductType.Season:
      return TravelProductRenderer(productDetails);
    case ProductType.Railcard:
      return RailcardProductRenderer(productDetails);
    case ProductType.Travelcard:
      return TravelcardProductRenderer(productDetails);
    default:
      throw new Error("Unknown booking type, can't get renderer.");
  }
};

interface Props extends CustomerBooking {
  error?: boolean;
  type: ProductType;
  orderDate: Date;
  orderReference: string;
  price: Price;
  bookingStatus: string;
  customerId: string;
  productDetails: ProductDetails;
}

const Bookings: FunctionComponent<Props> = ({
  error,
  type,
  orderDate,
  orderReference,
  price: { amount, currencyCode },
  bookingStatus,
  customerId,
  productDetails,
}) => {
  const renderer = productRendererFactory(type, productDetails);

  return (
    <Link
      data-test-id="bookingSummary"
      data-test-type={type}
      className={css(styles.tableRow, styles.booking)}
      to={`/customers/${customerId}/bookings/${orderReference}`}
    >
      {error ? (
        <span className={css(styles.errorMessage)} data-test="error">
          <FormattedMessage {...messages.bookingLoadError} />
        </span>
      ) : (
        <>
          <div className={css(styles.tableColumn, styles.productColumn)} data-test="destination">
            {renderer.description && renderer.description()}
          </div>
          <div className={css(styles.tableColumn)} data-test="friendly-order-id">
            {orderReference}
          </div>
          <div className={css(styles.tableColumn)}>
            {(renderer.inventoryReference && renderer.inventoryReference()) || '-'}
          </div>
          <div className={css(styles.tableColumn)}>
            <FormattedDateInLondonTz value={orderDate} year="numeric" month="short" day="numeric" />
            <div className={css(styles.atTime)}>
              <FormattedMessage
                {...messages.atTime}
                values={{
                  time: (
                    <FormattedTimeInLondonTz value={orderDate} hour="2-digit" minute="2-digit" />
                  ),
                }}
              />
            </div>
          </div>
          <div className={css(styles.tableColumn)}>
            {(renderer.departureDate && renderer.departureDate()) || '-'}
          </div>
          <div className={css(styles.tableColumn)}>
            {(renderer.returnDate && renderer.returnDate()) || '-'}
          </div>
          <div className={css(styles.tableColumn)}>
            <FormattedCurrency amount={amount} currencyCode={currencyCode} />
          </div>
          <div className={css(styles.tableColumn)}>
            <VendorStatus status={bookingStatus} />
          </div>
        </>
      )}
    </Link>
  );
};

export default Bookings;
