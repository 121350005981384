import { useQuery, useMutation, MutationOptions } from 'react-query';

import request from '@contactcentre-web/utils/request';

export type SavedCard = {
  savedCardId: string;
  name: string;
  number?: string;
  validityStart?: Date;
  validityEnd?: Date;
};

export type Passenger = {
  passengerId: string;
  firstName: string;
  lastName: string;
  type: string;
  email: string;
  dateOfBirth: Date;
  phoneNumber?: string;
  phoneCountryCode?: string;
  savedCards: Array<SavedCard>;
};

type CustomerPassengers = {
  totalCount: number;
  items: Array<Passenger>;
};

export type PurchasedCard = {
  customerId: string;
  name: string;
  orderReference: string;
  railcardNumber: string;
};

type PurchasedCards = {
  totalCount: number;
  items: Array<PurchasedCard>;
};

export type PassengerData = {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  email: string;
  cardNumber: string;
  phoneCountryCode: string;
  phoneNumber: string;
};

type Error = {
  errors?: {
    errors: Array<{ code: string; detail: string }>;
  };
};

export const useCustomerPassengers = (customerId: string) =>
  useQuery(
    ['customerPassengers', customerId],
    () => request<CustomerPassengers>(`/api/customers/${customerId}/passengers`),
    {
      select: ({ items }) => items,
    }
  );

export const useCustomerPurchasedCards = (customerId: string) =>
  useQuery(
    ['customerPurchasedCards', customerId],
    () => request<PurchasedCards>(`/api/customers/${customerId}/purchasedCards`),
    { select: ({ items }) => items }
  );

export const useAddCustomerPassenger = (
  customerId: string,
  mutationOptions: MutationOptions<unknown, unknown, PassengerData, unknown>
) =>
  useMutation<unknown, unknown, PassengerData, unknown>(
    (passengerData) =>
      request(`/api/customers/${customerId}/passengers`, {
        method: 'POST',
        body: passengerData,
      }),
    mutationOptions
  );

export const useDeleteCustomerPassenger = (
  customerId: string,
  passengerId: string,
  mutationOptions: MutationOptions<unknown, Error, void, unknown>
) =>
  useMutation<unknown, Error, void, unknown>(
    () =>
      request(`/api/customers/${customerId}/passengers/${passengerId}`, {
        method: 'DELETE',
      }),
    mutationOptions
  );

export const useEditCustomerPassenger = (
  customerId: string,
  passengerId: string,
  mutationOptions: MutationOptions<unknown, Error, PassengerData, unknown>
) =>
  useMutation<unknown, Error, PassengerData, unknown>(
    (passengerData) =>
      request(`/api/customers/${customerId}/passengers/${passengerId}`, {
        method: 'PUT',
        body: passengerData,
      }),
    mutationOptions
  );

export const useDeleteSavedCard = (
  customerId: string,
  passengerId: string,
  savedCardId: string,
  mutationOptions: MutationOptions<unknown, Error, void, unknown>
) =>
  useMutation<unknown, Error, void, unknown>(
    () =>
      request(`/api/customers/${customerId}/passengers/${passengerId}/savedcards/${savedCardId}`, {
        method: 'DELETE',
      }),
    mutationOptions
  );
