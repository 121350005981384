import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from '@contactcentre-web/common/Modal';
import FormattedDateInLondonTz from '@contactcentre-web/common/FormattedDateInLondonTz';
import Button from '@contactcentre-web/common/Button';

import type { Warning } from '../CompensationForm/CompensationForm';

import messages from './messages';

export interface Props {
  onProcess: () => void;
  onClose: () => void;
  isProcessing: boolean;
  warning: Warning | null;
}

const getMessage = (warning: Warning | null) => {
  switch (warning?.type) {
    case 'amountWarning':
    case 'previousRefundWarning':
    case 'approveWarning':
      return <FormattedMessage {...messages[warning.type]} values={warning} />;
    case 'previousCompensationWarning':
      return (
        <FormattedMessage
          {...messages.previousCompensationWarning}
          values={{
            ...warning,
            requestedDate: (
              <FormattedDateInLondonTz
                value={warning.requestedDate || ''}
                year="numeric"
                month="short"
                day="numeric"
                addOffset
              />
            ),
          }}
        />
      );
    default:
      return null;
  }
};

const CompensationRequestConfirmation: FunctionComponent<Props> = ({
  onProcess,
  isProcessing,
  onClose,
  warning,
}) => (
  <Modal onClose={onClose}>
    <ModalHeader>
      <ModalTitle>
        <FormattedMessage {...messages.header} />
      </ModalTitle>
    </ModalHeader>
    <ModalBody>{getMessage(warning)}</ModalBody>
    <ModalFooter contentAlignment="spaceBetween">
      <Button variant="tertiary" size="large" onClick={onClose}>
        <FormattedMessage {...messages.cancel} />
      </Button>
      <Button
        variant="primary"
        size="large"
        testId="confirmCompensationButton"
        loading={!!isProcessing}
        onClick={onProcess}
      >
        <FormattedMessage {...messages.proceed} />
      </Button>
    </ModalFooter>
  </Modal>
);

export default CompensationRequestConfirmation;
