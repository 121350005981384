import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const FormattedInsuranceProducts = ({ insurances, messages, hasBookings }) =>
  insurances.map(({ description = '' }, index) => (
    <FormattedMessage
      key={index}
      {...messages.insuranceProductDetails}
      values={{
        description,
        single: insurances.length === 1,
        first: index === 0,
        last: index === insurances.length - 1,
        hasBookings,
      }}
    />
  ));

FormattedInsuranceProducts.propTypes = {
  insurances: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
    })
  ).isRequired,
  messages: PropTypes.shape({
    insuranceProductDetails: PropTypes.object,
  }).isRequired,
  hasBookings: PropTypes.bool.isRequired,
};

export default FormattedInsuranceProducts;
