import { defineMessages } from 'react-intl';

export default defineMessages({
  paidBy: {
    id: 'PaymentDetails.paidBy',
    defaultMessage: 'Paid by',
  },
  paymentAddress: {
    id: 'PaymentDetails.paymentAddress',
    defaultMessage: 'Payment address',
  },
  paymentType: {
    id: 'PaymentDetails.paymentType',
    defaultMessage: 'Payment Type',
  },
});
