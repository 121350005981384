import { css } from 'aphrodite/no-important';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IconClose } from '@trainline/depot-web';

import Loader from '@contactcentre-web/common/Loader';
import Button from '@contactcentre-web/common/Button';
import {
  useFulfilmentConvert,
  useFulfilmentConvertEligibility,
} from '@contactcentre-web/hooks/api/useFulfilmentConverter';
import { getBookings, getOrder } from '@contactcentre-web/redux-common/selectors/order';

import FulfilmentConverterFail from './components/FulfilmentConverterFail/FulfilmentConverterFail';
import FulfilmentConverterForm from './components/FulfilmentConverterForm/FulfilmentConverterForm';
import FulfilmentConverterSuccess from './components/FulfilmentConverterSuccess/FulfilmentConverterSuccess';
import styles from './styles';

interface FulfilmentConverterProps {
  onClose: () => void;
}
export const FulfilmentConverter = ({ onClose }: FulfilmentConverterProps) => {
  const booking = useSelector(getBookings);
  const friendlyOrderId = useSelector(getOrder)?.orderReference;
  const {
    mutate,
    isError: isConverterError,
    isLoading: isConverting,
    error: converterError,
    isSuccess: hasConverted,
  } = useFulfilmentConvert();
  const {
    data,
    isError: isEligilityError,
    isLoading: isLoadingEligibility,
  } = useFulfilmentConvertEligibility(friendlyOrderId);

  let body = null;

  const setFulfilmentContent = (): JSX.Element => {
    if (hasConverted) {
      return <FulfilmentConverterSuccess />;
    } else if (
      isConverterError ||
      isEligilityError ||
      !data?.fulfilmentConversionOptions ||
      data?.fulfilmentConversionOptions?.length === 0
    ) {
      const errorCodes = converterError?.errors[0]?.code?.split('.');
      let error = 'generic';

      if (errorCodes && errorCodes?.length > 0) {
        error = errorCodes[errorCodes.length - 1];
      }
      if (
        isEligilityError ||
        !data?.fulfilmentConversionOptions ||
        data?.fulfilmentConversionOptions?.length === 0
      ) {
        error = 'noEligibilityFound';
      }

      return <FulfilmentConverterFail error={error} />;
    } else {
      return (
        <FulfilmentConverterForm
          bookings={booking}
          onSubmitForm={mutate}
          alreadyConverted={isConverterError}
          isLoading={isConverting}
          eligibility={data}
        />
      );
    }
  };

  body = setFulfilmentContent();

  useEffect(() => {
    body = setFulfilmentContent();
  }, [isConverterError, hasConverted, isEligilityError, data]);

  return (
    <div className={css(styles.container)}>
      {isLoadingEligibility ? (
        <div className={css(styles.loader)}>
          <Loader />
        </div>
      ) : (
        <>
          <Button
            variant="ghost"
            testId="fulfilment-converter-close"
            onClick={onClose}
            styleSheet={styles.closeButton}
          >
            <IconClose />
          </Button>
          {body}
        </>
      )}
    </div>
  );
};

export default FulfilmentConverter;
