import { StyleSheet } from 'aphrodite/no-important';
import {
  borderRadius,
  fonts,
  palettes,
  spacers,
  typography,
} from '@trainline/depot-web/css/themes/trainline';

export const controlStyle = {
  fontSize: typography.dp_body_font_size,
  lineHeight: typography.dp_body_line_height,
  fontWeight: fonts.dp_font_weight_semibold,
  border: `1px solid ${palettes.dp_app_border_dark}`,
  borderRadius: borderRadius.dp_r4,
  padding: `${spacers.dp_s8} ${spacers.dp_s12}`,
  width: '100%',
};

export const controlNotDisabledStyle = {
  color: palettes.dp_app_text_base,
  backgroundColor: palettes.dp_app_backdrop_base,

  ':hover': {
    borderColor: palettes.dp_app_selection_dark,
    backgroundColor: palettes.dp_app_backdrop_dark,
  },
};

export const controlDisabledStyle = {
  backgroundColor: palettes.dp_app_backdrop_dark,
  color: palettes.dp_app_text_disabled,
  cursor: 'not-allowed',
};

export const focusedStyle = {
  borderWidth: borderRadius.dp_r2,
  borderColor: palettes.dp_app_selection_dark,
  padding: `calc(${spacers.dp_s8} - 1px) calc(${spacers.dp_s12} - 1px)`,
};

export const controlErrorStyle = {
  borderColor: palettes.dp_app_negative_mid,

  ':hover': {
    borderColor: palettes.dp_app_negative_mid,
    backgroundColor: palettes.dp_app_backdrop_base,
  },

  ':focus': {
    borderColor: palettes.dp_app_negative_mid,
  },
};

export const placeholderStyle = {
  color: palettes.dp_app_text_subdued,
  fontWeight: fonts.dp_font_weight_regular,
  margin: 0,
  display: 'flex',
  alignItems: 'center',
};

export const singleValueStyle = {
  margin: 0,
  ':hover': {
    backgroundColor: palettes.dp_app_selection_lightest,
  },
  ':focus': {
    backgroundColor: palettes.dp_app_selection_lightest,
  },
};

export const menuListStyle = {
  padding: spacers.dp_s8,
};

export const optionStyle = {
  padding: spacers.dp_s8,
  borderRadius: borderRadius.dp_r4,
};

export const selectedOptionStyle = {
  backgroundColor: palettes.dp_app_selection_dark,
};

export const singleValueDisabledStyle = {
  color: palettes.dp_app_text_disabled,
};

export const menuStyle = {
  marginTop: spacers.dp_s4,
  border: `1px solid ${palettes.dp_app_border_base}`,
  boxShadow: 'none',
  backgroundColor: palettes.dp_app_backdrop_base,
};

export const indicatorStyle = {
  display: 'none',
};

export default StyleSheet.create({
  chevron: {
    position: 'absolute',
    right: spacers.dp_s8,
    top: '50%',
    transform: 'translateY(-50%)',
    color: palettes.dp_app_selection_dark,
  },
  chevronDisabled: {
    color: palettes.dp_app_border_dark,
  },
  errorContainer: {
    fontSize: typography.dp_small_font_size_m,
    fontWeight: fonts.dp_font_weight_regular,
    color: palettes.dp_app_text_negative,
    marginBottom: spacers.dp_s4,
    marginTop: spacers.dp_s4,
  },
  optionValue: {
    fontSize: typography.dp_mini_font_size_m,
    color: palettes.dp_app_text_subdued,
  },
  optionValueSelected: {
    color: palettes.dp_app_text_inverted,
  },
});
