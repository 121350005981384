import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';

import * as colors from '@contactcentre-web/styles/colors';
import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency/FormattedCurrency';
import Loader from '@contactcentre-web/common/Loader';

import messages from './messages';
import styles from './styles';

const SameDayVoidTotal = ({ totalToRefund, isUpdatePending }) => (
  <div className={css(styles.paymentTotal)}>
    <div className={css(styles.totalRefund)}>
      <h3 className={css(styles.totalToRefundLabel)}>
        <FormattedMessage {...messages.totalToRefund} />
      </h3>
      {isUpdatePending ? (
        <span className={css(styles.calculatingAmount)}>
          <Loader
            size={17}
            borderWidth={3}
            borderColor={colors.brand.base}
            spinnerColor={colors.brand.lighter}
            styleSheet={{ container: styles.loaderContainer }}
          />
          <FormattedMessage {...messages.calculatingAmount} />
        </span>
      ) : (
        <span className={css(styles.totalToRefundValue)}>
          <FormattedCurrency {...totalToRefund} />
        </span>
      )}
    </div>
  </div>
);

SameDayVoidTotal.propTypes = {
  totalToRefund: PropTypes.object.isRequired,
  isUpdatePending: PropTypes.bool.isRequired,
};

export default SameDayVoidTotal;
