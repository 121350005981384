import { defineMessages } from 'react-intl';

export default defineMessages({
  errorMessage: {
    id: 'TracsOnAccountApprovalMessageError.errorMessage',
    defaultMessage: 'Failed to load the queue {link}',
  },
  refreshPage: {
    id: 'TracsOnAccountApprovalMessageError.refreshPage',
    defaultMessage: 'Refresh the page',
  },
});
