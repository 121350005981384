import { defineMessages } from 'react-intl';

export default defineMessages({
  bookingConverted: {
    id: 'OrderHistoryConverted.bookingConverted',
    defaultMessage: 'Booking converted',
  },
  booking: {
    id: 'OrderHistoryConverted.booking',
    defaultMessage: 'Booking',
  },
  agent: {
    id: 'OrderHistoryConverted.agent',
    defaultMessage: 'agent',
  },
  requestedBy: {
    id: 'OrderHistoryConverted.requestedBy',
    defaultMessage:
      '{requestorType, select, Customer {Customer via self serve} Agent {{agent}} other { }}',
  },
  body: {
    id: 'OrderHistoryConverted.body',
    defaultMessage: 'This booking has been converted to CTR. {link}',
  },
  ConvertedFrom: {
    id: 'OrderHistoryConverted.convertedFrom',
    defaultMessage: 'View original booking',
  },
  ConvertedTo: {
    id: 'OrderHistoryConverted.convertedTo',
    defaultMessage: 'View new booking',
  },
});
