import React from 'react';
import { connect } from 'react-redux';
import { InjectedFormProps, formValueSelector, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import OriginToDestination from '@contactcentre-web/common/OriginToDestination/OriginToDestination';
import { ModalBody, ModalFooter } from '@contactcentre-web/common/Modal';
import RadioBoxField, { RadioBox } from '@contactcentre-web/common/RadioBoxField';
import FormattedDateInTz from '@contactcentre-web/common/FormattedDateInTz';
import Button from '@contactcentre-web/common/Button';

import { getCreateRefundStatus } from '../../selectors';
import DetaxeBooking from '../../types/DetaxeBooking';
import DetaxeLoadStatus from '../../types/DetaxeLoadStatus';
import styles from '../../styles';

import messages from './messages';

const FORM_ID = 'DetaxeForm';

interface StateProps {
  booking?: string;
  createRefundStatus: DetaxeLoadStatus;
}

interface Props {
  bookings: DetaxeBooking[];
  handleSubmit?: (booking: string) => void;
  onSubmitForm: (booking: string) => void;
}

type DetaxeFormData = { booking: string };

export const DetaxeForm = ({
  bookings,
  booking,
  handleSubmit,
  createRefundStatus,
}: Props & StateProps & InjectedFormProps<DetaxeFormData, Props>) => {
  const redirectUrl = booking ? bookings.find(({ id }) => id === booking)?.detaxeUrl : undefined;
  const [hasSubmitted, setHasSubmitted] = React.useState(false);

  React.useEffect(() => {
    if (createRefundStatus === DetaxeLoadStatus.Success && hasSubmitted) {
      window.open(redirectUrl);
    }
  }, [createRefundStatus, hasSubmitted]);

  return (
    <>
      <section>
        <form
          onSubmit={(event): void => {
            handleSubmit(event);
            setHasSubmitted(true);
          }}
        >
          <ModalBody>
            <RadioBoxField name="booking">
              {bookings.map(({ id, origin, destination, isReturn, outDate }) => (
                <RadioBox
                  key={id}
                  label={
                    <span>
                      <div className={css(styles.bookingJourney)}>
                        <OriginToDestination
                          isReturn={isReturn}
                          origin={origin}
                          destination={destination}
                        />
                      </div>
                      <div className={css(styles.bookingDetails)}>
                        <div className={css(styles.bookingDetailsReturn)}>
                          {isReturn ? (
                            <FormattedMessage {...messages.bookingReturn} />
                          ) : (
                            <FormattedMessage {...messages.bookingSingle} />
                          )}
                        </div>
                        <div className={css(styles.bookingDetailsDate)}>
                          <FormattedDateInTz
                            value={outDate}
                            year="numeric"
                            month="short"
                            day="numeric"
                            weekday="short"
                            hour="2-digit"
                            minute="2-digit"
                          />
                        </div>
                      </div>
                    </span>
                  }
                  value={id}
                />
              ))}
            </RadioBoxField>
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              variant="primary"
              size="large"
              testId="detaxe-form-continue"
              disabled={!redirectUrl || createRefundStatus === DetaxeLoadStatus.Loading}
              loading={createRefundStatus === DetaxeLoadStatus.Loading}
            >
              <FormattedMessage {...messages.buttonContinue} />
            </Button>
          </ModalFooter>
        </form>
      </section>
    </>
  );
};

const selector = formValueSelector(FORM_ID);
const mapStateToProps = (state: any): StateProps => ({
  booking: selector(state, 'booking'),
  createRefundStatus: getCreateRefundStatus(state),
});

const form = reduxForm<DetaxeFormData, Props>({
  form: FORM_ID,
  onSubmit: ({ booking }: DetaxeFormData, _: any, { onSubmitForm }: Props) => {
    onSubmitForm(booking);
  },
})(connect(mapStateToProps)(DetaxeForm));

export default form;
