import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { reduxForm } from 'redux-form';

import Button from '@contactcentre-web/common/Button';
import Select from '@contactcentre-web/common/Select';
import { ModalBody, ModalFooter } from '@contactcentre-web/common/Modal';

import selectors from '../../selectors';
import { actions } from '../../module';

import RemoveCollectionRestrictionAlert from './RemoveCollectionRestrictionAlert';
import styles from './styles';
import messages from './messages';

export const FORM_ID = 'RemoveRestrictionForm';

export const RemoveCollectionRestrictionForm = ({
  bookingSelectOptions,
  removeRestrictionReasons,
  handleSubmit,
  valid,
  loadRemoveRestrictionReasons,
  isRemoveRestrictionInProgress,
  isRemoveRestrictionFailed,
}) => {
  const { formatMessage, formatDate } = useIntl();

  const getBookingSelectOptions = () =>
    bookingSelectOptions.map(
      ({ id, ctr, isReturn, departAt, origin, destination, changeableToAnyCard }, idx) => ({
        label: formatMessage(messages.bookingText, {
          idx: idx + 1,
          ctr,
          origin,
          departAt: formatDate(departAt, {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          }),
          destination,
          isReturn,
          changeableToAnyCard,
        }),
        value: id,
        disabled: !changeableToAnyCard,
      })
    );

  const getRemoveRestrictionReasons = () =>
    removeRestrictionReasons.map(({ code, description }) => ({
      label: description,
      value: code,
      disabled: false,
    }));

  React.useEffect(() => {
    loadRemoveRestrictionReasons();
  }, []);

  return (
    <>
      <form id={FORM_ID} onSubmit={handleSubmit} method="post">
        <ModalBody styleSheet={styles.form}>
          {isRemoveRestrictionFailed && <RemoveCollectionRestrictionAlert />}
          <Select
            name="bookingId"
            testId="bookingId"
            label={formatMessage(messages.bookingLabel)}
            placeholder={formatMessage(messages.bookingPlaceholder)}
            options={getBookingSelectOptions()}
          />
          <Select
            name="reason"
            testId="reason"
            label={formatMessage(messages.reasonLabel)}
            placeholder={formatMessage(messages.reasonPlaceholder)}
            options={getRemoveRestrictionReasons()}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            id="submit"
            variant="primary"
            size="large"
            testId="submit"
            disabled={!valid}
            loading={isRemoveRestrictionInProgress}
          >
            <FormattedMessage {...messages.buttonText} />
          </Button>
        </ModalFooter>
      </form>
    </>
  );
};

RemoveCollectionRestrictionForm.propTypes = {
  bookingSelectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  removeRestrictionReasons: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  handleSubmit: PropTypes.func.isRequired,
  loadRemoveRestrictionReasons: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  isRemoveRestrictionInProgress: PropTypes.bool.isRequired,
  orderId: PropTypes.string.isRequired,
  isRemoveRestrictionFailed: PropTypes.bool.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  loadRemoveRestrictionReasons: () => dispatch(actions.removeRestrictionReasonsLoad()),
});

const mapStateToProps = (state) => {
  const bookingOptions = selectors.getBookingsChangeableToAnyCard(state);

  return {
    bookingSelectOptions: bookingOptions,
    removeRestrictionReasons: selectors.removeRestrictionReasons(state),
    orderId: selectors.getOrderId(state),
    customerId: selectors.getCustomerId(state),
    initialValues: {
      bookingId: bookingOptions.length === 1 ? bookingOptions[0].id : undefined,
    },
    isRemoveRestrictionFailed: selectors.isRemoveRestrictionFailed(state),
  };
};

const validate = (values) => {
  const errors = {};
  if (!values.bookingId) {
    errors.bookingId = 'bookingMustBeSelected';
  }
  if (!values.reason) {
    errors.reason = 'reasonMustBeSelected';
  }
  return errors;
};

const RemoveCollectionRestrictionFormContainer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_ID,
    validate,
    onSubmit: ({ bookingId, reason }, dispatch, { orderId, customerId, bookingSelectOptions }) => {
      const bookingIndex = bookingSelectOptions.findIndex((booking) => booking.id === bookingId);
      return dispatch(
        actions.removeRestrictionAttempt(customerId, orderId, bookingId, bookingIndex, reason)
      );
    },
  })
)(RemoveCollectionRestrictionForm);

RemoveCollectionRestrictionFormContainer.displayName = 'RemoveCollectionRestrictionForm';

export default RemoveCollectionRestrictionFormContainer;
