import { defineMessages } from 'react-intl';

export default defineMessages({
  journey: {
    id: 'FormattedBookings.journey',
    defaultMessage:
      '{single, select, false {{first, select, false {{last, select, true { and} false {,}}} true {}}} true {}} {origin} to {destination} {isReturn, select, true {(RTN)} other {}}',
  },
  season: {
    id: 'FormattedBookings.season',
    defaultMessage:
      '{single, select, false {{first, select, false {{last, select, true { and} false {,}}} true {}}} true {}} {origin} to {destination} (Season Ticket)',
  },
  railcard: {
    id: 'FormattedBookings.railcard',
    defaultMessage:
      '{single, select, false {{first, select, false {{last, select, true { and} false {,}}} true {}}} true {}} {name}',
  },
});
