import React from 'react';
import { css } from 'aphrodite/no-important';

import styles from './styles';

export interface Props {
  reason: string;
  isPartOfList: boolean;
}

const DisruptionReasonItem = ({ reason, isPartOfList }: Props) => (
  <li
    className={css(styles.listItem, !isPartOfList && styles.loneListItem)}
    data-test="reason-item"
  >
    {reason}
  </li>
);

export default DisruptionReasonItem;
