import { Validity } from '@contactcentre-web/redux-common/types/Validity';
import { Journey, TravelTicket } from '@contactcentre-web/redux-common/types/Journey';
import { LocalAreaProduct } from '@contactcentre-web/redux-common/types/LocalAreaProduct';
import Price from '@contactcentre-web/redux-common/types/Price';

import { getFarePassengers, passengersExtractor } from '../utils';
import {
  LocalAreaTicket,
  PassengerRefundable,
  Quote,
  TermsAndConditionsRefundableTicket,
} from '../types';

import { railcardRefundabilityMapper, ticketRefundabilityMapper } from './ticket-mapper';

type RefundableRailcardTicket = {
  isSelected: boolean;
  refundable: boolean;
  notRefundableReason: string | undefined;
  notRefundableReasonDetail: string | undefined;
  refundableId: string;
  isChangeable: boolean;
  isEligible: boolean;
  expiryTimestampUtc: string;
  refundableAmount?: Price;
  canOverrideVoidableAmounts: boolean;
};

export const journeysWithRefundability = (
  journeys: (Journey & { validity?: Validity })[],
  passengerRefundables: PassengerRefundable[],
  quote: Quote,
  isReturn: boolean
) => {
  let canProcess = false;

  const refundJourneys = journeys
    .map((journey) => {
      const { origin, destination, departAt, arriveAt, validity } = journey;
      const passengers = passengersExtractor(journey);

      const { farePassengers: mappedFps, canProcess: canProcessRefundable } = getFarePassengers<
        TravelTicket,
        TermsAndConditionsRefundableTicket
      >(passengers, passengerRefundables, ticketRefundabilityMapper, quote);

      canProcess = canProcess || canProcessRefundable;

      return {
        origin,
        destination,
        departAt,
        arriveAt,
        isReturn,
        validity,
        farePassengers: mappedFps,
      };
    })
    .filter(Boolean);

  return {
    journeys: refundJourneys,
    canProcess,
  };
};

export const localAreasWithRefundibility = (
  localAreaProducts: LocalAreaProduct[],
  passengerRefundables: PassengerRefundable[],
  quote: Quote
) => {
  const journeys = localAreaProducts.map((localAreaProduct) => {
    const passengers = localAreaProduct.passengers.map((passenger) => ({
      ...passenger,
      railCards: passenger.railcards || [],
      tickets: passenger.tickets.map((ticket) => ({
        ...ticket,
        localAreaValidity: localAreaProduct.localAreaValidity,
        farePassengerId: passenger.farePassengerId,
        passengerType: passenger.type,
        customerPrice: ticket.price,
      })),
    }));

    const { farePassengers, canProcess } = getFarePassengers<
      LocalAreaTicket,
      TermsAndConditionsRefundableTicket
    >(passengers, passengerRefundables, ticketRefundabilityMapper, quote);

    return {
      ...localAreaProduct,
      farePassengers,
      canProcess,
    };
  });

  const canProcess = journeys.every((journey) => journey.canProcess);

  return {
    journeys,
    canProcess,
  };
};

export const railcardsWithRefundability = (
  railcardId: string,
  railcardRefundables: PassengerRefundable[],
  quote: Quote
): {
  canProcess: boolean;
  railcardRefundability: RefundableRailcardTicket | null;
} => {
  const refundableForRailcard = railcardRefundables?.find(
    (refundable) => refundable.id === railcardId
  );

  if (!refundableForRailcard) {
    return {
      railcardRefundability: null,
      canProcess: false,
    };
  }

  const { selectability, refundableId, status, expiryTimestampUtc, reason, reasonDetail } =
    refundableForRailcard;

  const canProcess =
    !!selectability && (selectability.selectedByDefault || selectability.selectable);

  return {
    canProcess,
    railcardRefundability: railcardRefundabilityMapper({
      quote,
      status,
      refundableId,
      selectability,
      reason,
      reasonDetail,
      expiryTimestampUtc,
    }),
  };
};
