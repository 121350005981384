import { defineMessages } from 'react-intl';

export default defineMessages({
  save: {
    id: 'RolePermissions.save',
    defaultMessage: 'Save',
  },
  readOnlyPermissions: {
    id: 'RolePermissions.readOnlyPermissions',
    defaultMessage: 'Permissions - You have read only permssions',
  },
  changesSaved: {
    id: 'RolePermissions.changesSaved',
    defaultMessage: 'Your changes have been saved',
  },
  changesNotSaved: {
    id: 'RolePermissions.changesNotSaved',
    defaultMessage: 'Something went wrong, your changes were not saved.',
  },
});
