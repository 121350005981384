import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'manualEntryConsoleGenerateReportModal.header',
    defaultMessage: 'Download MEC treasury sheet?',
  },
  body: {
    id: 'manualEntryConsoleGenerateReportModal.body',
    defaultMessage:
      'Are you sure you want to download the MEC treasury spreadsheet? Once the report is generated the approval queue will be cleared and the claims will be locked.',
  },
  cancelButton: {
    id: 'manualEntryConsoleGenerateReportModal.cancelButton',
    defaultMessage: 'Cancel',
  },
  downloadButton: {
    id: 'manualEntryConsoleGenerateReportModal.downloadButton',
    defaultMessage: 'Download',
  },
});
