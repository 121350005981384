import { defineMessages } from 'react-intl';

export default defineMessages({
  bookingHasBeenReplaced: {
    id: 'ReplacementBookingAlert.bookingHasBeenReplaced',
    defaultMessage: 'Booking {bookingNumber} in this order has been replaced. View {link}.',
  },
  bookingHasBeenConverted: {
    id: 'ReplacementBookingAlert.bookingHasBeenConverted',
    defaultMessage:
      'Booking {bookingNumber} should not be valid anymore as it has been replaced by doing credit to trainline (eg. TVTOD). Please check notes & Freshdesk for more information. {link}',
  },
  newBooking: {
    id: 'ReplacementBookingAlert.newBooking',
    defaultMessage: 'new booking',
  },
  goToNewBooking: {
    id: 'ReplacementBookingAlert.goToNewBooking',
    defaultMessage: 'Go to the new booking',
  },
});
