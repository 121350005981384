import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { IconSingle } from '@trainline/depot-web';

import FormattedDateInLondonTz from '@contactcentre-web/common/FormattedDateInLondonTz';
import FormattedTimeInLondonTz from '@contactcentre-web/common/FormattedTimeInLondonTz';
import StatusIndicator from '@contactcentre-web/common/StatusIndicator';
import Link from '@contactcentre-web/common/Link';

import HistoryCardHeader from '../HistoryCardHeader/HistoryCardHeader';
import type { BookingHistoryItem } from '../../module';

import messages from './messages';
import styles from './styles';

const BookingHistory: FunctionComponent<BookingHistoryItem> = ({
  orderReference,
  customerId,
  createdAt,
  bookings,
  entries,
}) => (
  <div data-test="BookingHistoryItem" className={css(styles.container)}>
    <HistoryCardHeader
      itemTypeContent={<FormattedMessage {...messages.cardTitle} />}
      itemTimestamp={createdAt}
      bookings={bookings}
    />
    <div>
      <div className={css(styles.row)}>
        <table className={css(styles.table)}>
          <thead>
            <tr className={css(styles.tableHead)}>
              <th className={css(styles.tableHeader)}>
                <FormattedMessage {...messages.pnr} />
              </th>
              <th className={css(styles.tableHeader)}>
                <FormattedMessage {...messages.action} />
              </th>
              <th className={css(styles.tableHeader)}>
                <FormattedMessage {...messages.agent} />
              </th>
              <th className={css(styles.tableHeader)}>
                <FormattedMessage {...messages.timestamp} />
              </th>
              <th className={css(styles.tableHeader)}>
                <FormattedMessage {...messages.status} />
              </th>
            </tr>
          </thead>
          <tbody>
            {entries?.map((e, i) => (
              <tr key={i} className={css(styles.tableBody)}>
                <td className={css(styles.tableCell)}>
                  {e.fulfilmentStatus.toLowerCase() !== 'issued' && (
                    <IconSingle className={css(styles.arrow)} />
                  )}
                  <Link
                    linkType="internal"
                    to={`/customers/${customerId}/bookings/${orderReference}/supersededbooking?bookingUri=${e.bookingUri}`}
                  >
                    {e.id}
                  </Link>
                </td>
                <td className={css(styles.tableCell)} data-test="actionTypes">
                  {messages[`actionTypes_${e.action}`] ? (
                    <FormattedMessage {...messages[`actionTypes_${e.action}`]} />
                  ) : (
                    <FormattedMessage {...messages.actionTypes_unknown} />
                  )}
                </td>
                <td className={css(styles.tableCell)}>
                  <FormattedMessage
                    {...messages.requestedBy}
                    values={{
                      requestorType: e.requestorType,
                      agent: e.agent,
                    }}
                  />
                </td>
                <td className={css(styles.tableCell)}>
                  <strong>
                    <FormattedMessage
                      {...messages.createdAt}
                      values={{
                        day: (
                          <FormattedDateInLondonTz
                            value={e.creationDateTime}
                            year="numeric"
                            month="short"
                            day="numeric"
                            addOffset
                          />
                        ),
                        time: <FormattedTimeInLondonTz value={e.creationDateTime} addOffset />,
                      }}
                    />
                  </strong>
                </td>
                <td className={css(styles.tableCell)}>
                  <StatusIndicator
                    indicatorStyles={
                      e.fulfilmentStatus.toLowerCase() === 'issued'
                        ? styles.statusIndicatorActive
                        : styles.statusIndicatorInactive
                    }
                    status={e.fulfilmentStatus}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

export default BookingHistory;
