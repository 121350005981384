import { StyleSheet } from 'aphrodite/no-important';
import { fonts, spacers, typography, palettes } from '@trainline/depot-web/css/themes/trainline';

import { zIndex } from '@contactcentre-web/styles/zIndex';

export const menuStyles = StyleSheet.create({
  item: {
    padding: 0,
    fontSize: typography.dp_small_font_size_m,
    letterSpacing: typography.dp_small_letter_spacing,
    lineHeight: typography.dp_small_line_height,
    color: palettes.dp_app_text_base,

    ':hover': {
      backgroundColor: palettes.dp_app_info_lightest,
    },
  },
});

export default StyleSheet.create({
  container: {
    position: 'relative',
    zIndex: zIndex.base,
  },
  tripleDot: {
    display: 'flex',
    gap: '2px',
  },
  dot: {
    borderRadius: '50%',
    width: '4px',
    height: '4px',
    backgroundColor: palettes.dp_brand_black,
  },
  menuTitle: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    color: palettes.dp_app_text_subdued,
    textTransform: 'uppercase',
    fontWeight: fonts.dp_font_weight_bold,
    padding: `${spacers.dp_s8} ${spacers.dp_s16}`,
  },
});
