import React from 'react';

interface Props {
  className?: string;
}

const IconInsurance = ({ className = '' }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    className={className}
  >
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#13181A"
        d="M17 3.667C17 9.389 13.314 14.398 9 17 4.686 14.398 1 9.389 1 3.667c2.556 0 5.822-.67 8-2.667 2.178 1.997 5.444 2.667 8 2.667z"
      />
      <path
        fill="#01C3A7"
        fillRule="nonzero"
        d="M9 2.846c1.592 1.536 4.266 2.295 6.154 2.462C14.927 9.61 11.326 13.34 9 15.154"
      />
      <path
        fill="#333"
        stroke="#13181A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".75"
        d="M9 5.308v4.307M4.077 6.538h9.846"
      />
    </g>
  </svg>
);

export default IconInsurance;
