import React from 'react';
import { css } from 'aphrodite/no-important';

import Timetable from '@contactcentre-web/redux-common/types/Timetable';

import CallingPointsItem from './CallingPointsItem';
import CallingPointsDetails from './CallingPointsDetails';
import styles from './styles';

export interface Props {
  timetable: Timetable;
}

const CallingPoints = ({ timetable: { status, carrier, transportMode, callingPoints } }: Props) => (
  <div className={css(styles.callingPointsContainer)}>
    <div className={css(styles.callingPointsItemContainer)}>
      {callingPoints.map(({ callingType, locationName, departure, arrival }, index) => (
        <div key={index} data-test={`callingType-${callingType}`}>
          <CallingPointsItem
            status={status}
            locationName={locationName}
            departure={departure}
            arrival={arrival}
            callingType={callingType}
          />
        </div>
      ))}
    </div>
    <div className={css(styles.callingPointsDetailsContainer)}>
      <CallingPointsDetails
        status={status}
        carrierName={carrier}
        transportMode={transportMode}
        callingPoints={callingPoints}
      />
    </div>
  </div>
);

export default CallingPoints;
