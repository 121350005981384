import { StyleSheet } from 'aphrodite/no-important';
import { fonts, spacers, typography } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  container: {
    width: '100%',
    paddingTop: spacers.dp_s24,
    display: 'flex',
    flexDirection: 'row',
    background: colors.white,
    padding: `${spacers.dp_s24} ${spacers.dp_s64}`,
  },
  col: {
    wordBreak: 'break-word',
    flex: '1 1 auto',
    padding: `0 ${spacers.dp_s8}`,
    ':first-child': {
      paddingLeft: 0,
    },
    ':last-child': {
      paddingRight: 0,
    },
  },
  title: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    color: colors.gray.alpha30,
    textTransform: 'uppercase',
    marginBottom: spacers.dp_s4,
    fontWeight: fonts.dp_font_weight_semibold,
  },
  price: {
    textAlign: 'right',
    fontSize: typography.dp_body_font_size,
    color: colors.gray.alpha80,
  },
  operator: {
    textTransform: 'capitalize',
  },
  content: {
    whiteSpace: 'pre-wrap',
  },
  cancelButtonContainer: {
    marginTop: spacers.dp_s16,
  },
});
