import React, { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { IconCheck, IconClose, Paragraph } from '@trainline/depot-web';
import { palettes } from '@trainline/depot-web/css/themes/trainline';

import Tooltip from '@contactcentre-web/common/Tooltip';
import IconTelephonyConnected from '@contactcentre-web/common/Icons/IconTelephonyConnected';
import IconTelephonyDisconnected from '@contactcentre-web/common/Icons/IconTelephonyDisconnected';
import IconTelephonyWarning from '@contactcentre-web/common/Icons/IconTelephonyWarning';

import styles from './styles';
import messages from './messages';
import { useTelephonyStatus } from './useTelephonyStatus';

const TelephonyStatus: FunctionComponent = () => {
  const { iconStatus, tooltipMessages, telephonyUsername } = useTelephonyStatus();
  const { formatMessage } = useIntl();

  const iconToDisplay = () => {
    if (iconStatus === 'connected') {
      return <IconTelephonyConnected className={css(styles.icon)} />;
    } else if (iconStatus === 'disconnected') {
      return <IconTelephonyDisconnected className={css(styles.icon)} />;
    } else {
      return <IconTelephonyWarning className={css(styles.icon)} />;
    }
  };

  return (
    <Tooltip anchor={iconToDisplay()} align="Left" styleSheet={styles.tooltip}>
      {telephonyUsername && (
        <Paragraph typeStyle="small" as="p" color="base" fontWeight="regular">
          <FormattedMessage {...messages.LoggedIn} values={{ username: telephonyUsername }} />
        </Paragraph>
      )}
      {Array.isArray(tooltipMessages) ? (
        tooltipMessages.map(({ source, status, isConnected, telephonyUsername }) => (
          <>
            <div className={css(styles.tooltipMessage)}>
              <strong className={css(styles.tooltipMessageSource)}>
                {formatMessage(messages[source], { defaultMessage: source })}
              </strong>{' '}
              {isConnected ? (
                <IconCheck fill={palettes.dp_app_positive_mid} size={24} />
              ) : (
                <IconClose fill={palettes.dp_app_negative_mid} size={24} />
              )}
            </div>
            <Paragraph typeStyle="small" as="p" color="base" fontWeight="regular">
              {isConnected ? (
                telephonyUsername
              ) : (
                <FormattedMessage
                  {...status}
                  values={{ source: formatMessage(messages[source], { defaultMessage: source }) }}
                />
              )}
            </Paragraph>
          </>
        ))
      ) : (
        <Paragraph
          typeStyle="small"
          as="p"
          color="base"
          fontWeight="regular"
          className={css(styles.tooltipErrorMessage)}
        >
          <FormattedMessage {...tooltipMessages} />
        </Paragraph>
      )}
    </Tooltip>
  );
};

export default TelephonyStatus;
