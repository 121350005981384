import { StyleSheet } from 'aphrodite/no-important';
import { fonts, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  passengerSection: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: spacers.dp_s16,
    rowGap: spacers.dp_s8,
    ':not(:last-child)': {
      marginBottom: spacers.dp_s32,
    },
  },
  passengerId: {
    gridColumn: 'span 2',
    textTransform: 'capitalize',
    fontWeight: fonts.dp_font_weight_semibold,
  },
  alterForm: {
    display: 'flex',
    flexDirection: 'column',
  },
});
