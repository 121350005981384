import { defineMessages } from 'react-intl';

export default defineMessages({
  ancillaryProducts: {
    id: 'Insurance.ancillaryProducts',
    defaultMessage: 'ancillary product(s)',
  },
  ancillaryFees: {
    id: 'Insurance.ancillaryFees',
    defaultMessage: 'ancillary fees',
  },
});
