import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Lock from '@contactcentre-web/common/Lock';
import Link from '@contactcentre-web/common/Link';

import messages from './messages';

const BookingLocked = ({ capitaineUrl, vendorRegion }) => (
  <Lock>
    {vendorRegion === 'eu' && (
      <FormattedMessage
        {...messages.capitaineLock}
        values={{
          link: (msg) => (
            <Link linkType="external" href={capitaineUrl}>
              {msg}
            </Link>
          ),
        }}
      />
    )}
    {vendorRegion === 'uk' && <FormattedMessage {...messages.tracksLock} />}
  </Lock>
);

BookingLocked.propTypes = {
  capitaineUrl: PropTypes.string.isRequired,
  vendorRegion: PropTypes.string.isRequired,
};

export default BookingLocked;
