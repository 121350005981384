import { spacers } from '@trainline/depot-web/css/themes/trainline';
import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({
  loaderContainer: {
    padding: spacers.dp_s48,
  },
  loadErrorContainer: {
    padding: spacers.dp_s32,
  },
  footer: {
    display: 'flex',
    paddingTop: spacers.dp_s24,
    paddingBottom: spacers.dp_s24,
    justifyContent: 'flex-end',
  },
  productTotals: {
    paddingLeft: spacers.dp_s16,
  },
});
