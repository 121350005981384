import React, { useState, useEffect, ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { useParams, useHistory } from 'react-router-dom';

import TextField from '@contactcentre-web/common/TextField';
import Select from '@contactcentre-web/common/Select';
import Button from '@contactcentre-web/common/Button';
import TextCounter from '@contactcentre-web/common/TextCounter';
import DetaxeLoadStatus from '@contactcentre-web/detaxe/types/DetaxeLoadStatus';
import { setRefundStatus } from '@contactcentre-web/detaxe/selectors';
import { Reason } from '@contactcentre-web/detaxe/sagas';

import { StatusTypes } from '../../UpdateRefundStatus';
import messages from '../../messages';
import styles from '../../styles';

const FORM_ID = 'UpdateStatusForm';

export type FormData = {
  note?: string;
  status?: string;
  reference?: string;
  claimAmount?: string;
  contactReason?: Reason;
};

interface StateProps {
  updateRefundStatus: DetaxeLoadStatus;
}

interface Props {
  transitionsTo?: Array<string>;
  onSubmitForm: (data: FormData) => void;
  currentStatus: StatusTypes;
  contactReasons: Array<Reason>;
  contactReasonId?: Reason;
}

export const UpdateRefundStatusForm = ({
  handleSubmit,
  transitionsTo,
  updateRefundStatus,
  currentStatus,
  contactReasons,
  contactReasonId,
}: Props & StateProps & InjectedFormProps<FormData, Props>) => {
  const { formatMessage } = useIntl();
  const { customerId, orderId } = useParams<{
    customerId: string;
    orderId: string;
  }>();
  const history = useHistory();

  const [isUpdating, setIsUpdating] = useState(false);
  const [noteLength, setNoteLength] = useState(0);

  useEffect(() => {
    if (updateRefundStatus === DetaxeLoadStatus.Success && isUpdating) {
      setIsUpdating(false);
      history.push(`/customers/${customerId}/bookings/${orderId}/history`);
    }
  }, [updateRefundStatus]);

  const optionsList = transitionsTo
    ? transitionsTo.map((transition) => ({
        label: transition.charAt(0).toUpperCase() + transition.slice(1),
        value: transition,
      }))
    : [];

  const contactReasonsList = contactReasons
    ? contactReasons.map((reason) => {
        const localMessage = formatMessage(messages[reason]);
        return {
          label: localMessage.charAt(0).toUpperCase() + localMessage.slice(1),
          value: reason,
        };
      })
    : [];

  return (
    <form
      onSubmit={(event) => {
        handleSubmit(event);
        setIsUpdating(true);
      }}
    >
      <div className={css(styles.fieldContainer)}>
        <Select
          label={<FormattedMessage {...messages.changeStatus} />}
          name="status"
          testId="change-status-select"
          placeholder={formatMessage(messages.selectStatus)}
          options={optionsList}
          messages={messages}
          searchable={false}
        />
      </div>

      {currentStatus === 'initiated' ? (
        <div className={css(styles.fieldContainer)}>
          <Select
            label={<FormattedMessage {...messages.contactReason} />}
            name="contactReason"
            testId="change-reason-select"
            placeholder={formatMessage(messages.selectReason)}
            options={contactReasonsList}
            messages={messages}
            searchable={false}
          />
        </div>
      ) : (
        <TextField
          name="contactReason"
          type="text"
          placeholder={
            contactReasonId
              ? formatMessage(messages[contactReasonId])
              : formatMessage(messages.unknownContactReason)
          }
          messages={messages}
          disabled={true}
          styleSheet={{ container: styles.contactReasonText }}
        />
      )}

      <TextField
        label={formatMessage(messages.rReference)}
        name="reference"
        type="text"
        placeholder={formatMessage(messages.rReferenceNumber)}
        messages={messages}
        styleSheet={{ container: styles.textFieldBox }}
      />

      <TextField
        label={formatMessage(messages.claimAmountTitle)}
        name="claimAmount"
        type="number"
        placeholder={formatMessage(messages.claimAmountPlaceholder)}
        messages={messages}
        styleSheet={{ container: [styles.textFieldBox, styles.claimAmountField] }}
        disabled={currentStatus !== 'initiated'}
      />

      <div className={css(styles.bottomForm)}>
        <div>
          <TextField
            label={formatMessage(messages.notes)}
            name="note"
            type="multiline-text"
            rows={4}
            messages={messages}
            styleSheet={{ container: styles.notesBox }}
            onChange={(event?: ChangeEvent<HTMLTextAreaElement>) =>
              setNoteLength(event?.target.value.length || 0)
            }
          />
          <div className={css(styles.textCounter)}>
            <TextCounter textLength={noteLength} maxLength={500} align="right" />
          </div>
          <span className={css(styles.notesText)}>
            <FormattedMessage {...messages.notesText} />
          </span>
        </div>
        <Button
          type="submit"
          variant="primary"
          size="large"
          testId="updateStatus-form"
          loading={updateRefundStatus === DetaxeLoadStatus.Loading}
          styleSheet={styles.searchButton}
        >
          <FormattedMessage {...messages.updateButton} />
        </Button>
      </div>
    </form>
  );
};

const mapStateToProps = (state: any): StateProps => ({
  updateRefundStatus: setRefundStatus(state),
});

export const validate = (
  { status, contactReason, claimAmount, note }: FormData,
  { currentStatus }: { currentStatus: StatusTypes }
) => ({
  status: !status ? 'validationStatusRequired' : undefined,
  claimAmount:
    !claimAmount && currentStatus === 'initiated' ? 'validationClaimAmountRequired' : undefined,
  contactReason:
    !contactReason && currentStatus === 'initiated' ? 'validationReasonRequired' : undefined,
  note: note && note.length > 500 ? 'validationNoteField' : undefined,
});

const form = reduxForm<FormData, Props>({
  form: FORM_ID,
  validate,
  onSubmit: (
    { note, status, reference, claimAmount, contactReason }: FormData,
    _: any,
    { onSubmitForm }: Props
  ) => {
    onSubmitForm({ note, status, reference, claimAmount, contactReason });
  },
})(connect(mapStateToProps)(UpdateRefundStatusForm));

export default form;
