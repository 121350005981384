import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'aphrodite/no-important';

import TicketAssets from '../TicketAssets/TicketAssets';
import TicketDetails from '../TicketDetails/TicketDetails';

import styles from './styles';

const Tickets = ({ ticketAssets, ticketDetails }) => (
  <div className={css(styles.container)}>
    <div className={css(styles.ticketDetails)}>
      <TicketDetails ticketDetails={ticketDetails} />
    </div>
    <div className={css(styles.ticketAssets)}>
      <TicketAssets ticketAssets={ticketAssets} />
    </div>
  </div>
);

Tickets.propTypes = {
  ticketAssets: PropTypes.array,
  ticketDetails: PropTypes.array,
};
export default Tickets;
