import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  container: {
    height: '35px',
    backgroundColor: colors.info.light,
    padding: `0 ${spacers.dp_s32}`,
  },
  box: {
    width: '1036px',
    margin: '0px auto',
    height: '100%',
  },
  loaderBox: {
    position: 'relative',
    height: '100%',
  },
  loader: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  loaderElementBubble: {
    backgroundColor: '#072456',
  },
  loaderText: {
    paddingLeft: spacers.dp_s12,
    position: 'absolute',
    top: '50%',
    left: spacers.dp_s32,
    transform: 'translateY(-50%)',
  },
  errorBox: {
    color: 'orange',
    display: 'inline-flex',
    alignItems: 'center',
    height: '100%',
  },
  errorText: {
    marginLeft: spacers.dp_s4,
  },
  euBox: {
    height: '100%',
    display: 'inline-flex',
    alignItems: 'center',
  },
});
