import React from 'react';
import { css } from 'aphrodite/no-important';

import { Modal, ModalHeader, ModalBody } from '@contactcentre-web/common/Modal';
import ConditionsOfUse from '@contactcentre-web/redux-common/types/ConditionsOfUse';

import styles from './styles';

export interface Props {
  toggleModal: () => void;
  conditionsOfUse: ConditionsOfUse[];
}

const TermsAndConditionsModal = ({ toggleModal, conditionsOfUse }: Props) => (
  <Modal onClose={toggleModal}>
    <ModalHeader />
    <ModalBody styleSheet={styles.content}>
      {conditionsOfUse.map(({ name, description }, index) => (
        <div key={index} className={css(styles.condition)}>
          <h2>{name}</h2>
          <p dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      ))}
    </ModalBody>
  </Modal>
);

export default TermsAndConditionsModal;
