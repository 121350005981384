import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { OrderHistoryCard, OrderHistoryCardHeader } from '../OrderHistoryCard/OrderHistoryCard';
import type { RailcardCancellationItem } from '../../module';

import messages from './messages';

const RailcardCancellation: FunctionComponent<RailcardCancellationItem> = ({
  createdAt,
  agent,
  railcardName,
  railcardNumber,
}) => (
  <OrderHistoryCard
    createdAt={createdAt}
    cardTitle={<FormattedMessage {...messages.cardTitle} />}
    headerItems={
      <>
        <OrderHistoryCardHeader
          label={<FormattedMessage {...messages.railcardName} />}
          value={railcardName}
        />
        <OrderHistoryCardHeader
          label={<FormattedMessage {...messages.railcardNumber} />}
          value={railcardNumber}
        />
        <OrderHistoryCardHeader label={<FormattedMessage {...messages.agent} />} value={agent} />
      </>
    }
  />
);

export default RailcardCancellation;
