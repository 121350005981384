import React, { Dispatch, FunctionComponent, useEffect } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { InjectedFormProps, reduxForm } from 'redux-form';

import Select from '@contactcentre-web/common/Select';
import Button from '@contactcentre-web/common/Button';
import FormattedLanguage from '@contactcentre-web/common/FormattedLanguage';
import {
  getAvailableLocales,
  getCountryFlagLink,
} from '@contactcentre-web/localisation/localisation';
import { DEFAULT_LOCALE_CODE } from '@contactcentre-web/localisation/locales';
import type Action from '@contactcentre-web/redux-common/types/Action';

import { actions as profileActions } from '../../redux/profile/module';

import styles from './styles';
import messages from './messages';

export const FORM_ID = 'selectUILanguage';

interface SelectLanguageData {
  locale: string;
}

export const SelectUILanguageForm: FunctionComponent<InjectedFormProps<SelectLanguageData>> = ({
  handleSubmit,
  initialize,
}) => {
  useEffect(() => {
    initialize({ locale: DEFAULT_LOCALE_CODE });
  }, []);

  const languageOptions = getAvailableLocales().map((locale) => ({
    label: (
      <div className={css(styles.languageContainer)}>
        <img src={getCountryFlagLink(locale)} alt={locale} width="24" height="18" />
        <FormattedLanguage styleSheet={styles.languageName} lang={locale} inLang={locale} />
      </div>
    ),
    value: locale,
  }));

  return (
    <form id={FORM_ID} className={css(styles.selectLanguageForm)} onSubmit={handleSubmit}>
      <div className={css(styles.inputContainer)}>
        <div className={css(styles.selectContainer)}>
          <Select
            name="locale"
            label={<FormattedMessage {...messages.selectLabel} />}
            options={languageOptions}
            messages={messages}
          />
        </div>
        <Button
          type="submit"
          variant="primary"
          size="small"
          styleSheet={styles.continueButton}
          testId="submit-ui-language-selection"
        >
          <FormattedMessage {...messages.continue} />
        </Button>
      </div>
    </form>
  );
};

export const validate = ({ locale }: SelectLanguageData) => ({
  locale: !locale ? 'selectLanguageError' : undefined,
});

export default reduxForm({
  form: FORM_ID,
  validate,
  onSubmit: ({ locale }: SelectLanguageData, dispatch: Dispatch<Action>) =>
    dispatch(profileActions.setLocale(locale, true)),
})(SelectUILanguageForm);
