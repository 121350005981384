import { css } from 'aphrodite/no-important';
import moment from 'moment';
import React, { Dispatch } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';

import type State from '@contactcentre-web/redux-common/types/State';
import type Action from '@contactcentre-web/redux-common/types/Action';
import Select from '@contactcentre-web/common/Select';
import InfoBox from '@contactcentre-web/common/InfoBox';
import Button from '@contactcentre-web/common/Button';

import {
  actions,
  FORM_ID,
  RefreshableBookingsData,
  RefreshRequestData,
  selectors,
} from '../../module';

import messages from './messages';
import styles from './styles';

interface StateProps {
  bookings: Array<RefreshableBookingsData>;
  failedAlreadyRefreshed: boolean;
  initialValues: {
    bookingId: string;
  };
}
type SelectType = {
  label: string;
  value: string;
};
type Props = StateProps;

export const RefreshPnrForm = ({
  bookings,
  handleSubmit,
  failedAlreadyRefreshed,
}: Props & InjectedFormProps<RefreshRequestData, Props>) => {
  const { formatMessage } = useIntl();

  const selectOptions = bookings.reduce(
    (
      acc: Array<SelectType>,
      current: RefreshableBookingsData,
      index: number,
      list: Array<RefreshableBookingsData>
    ) => {
      const { bookingIndex, from, to, departureDay, id } = current;
      if (index === 0 && list.length > 1) {
        acc.push({
          label: formatMessage(messages.allBookings),
          value: 'allBookings',
        });
      }

      acc.push({
        label: formatMessage(messages.bookingLabel, {
          bookingIndex: bookingIndex,
          from,
          to,
          departureDay: moment(departureDay).format('ll'),
        }),
        value: id,
      });

      return acc;
    },
    []
  );

  return (
    <form
      id={FORM_ID}
      className={css(styles.container)}
      onSubmit={handleSubmit}
      data-testid="refresh-pnr-form"
    >
      <h1 className={css(styles.title)}>
        <FormattedMessage {...messages.refreshBooking} />
      </h1>
      {failedAlreadyRefreshed && (
        <InfoBox
          styleSheet={styles.alreadyRefreshedBox}
          data-testId="RefreshPnr-failed-already-refreshed-box"
          message={<FormattedMessage {...messages.alreadyRefreshed} />}
        />
      )}
      <div className={css(styles.body)}>
        <div className={css(styles.selectContainer)}>
          <Select
            name="bookingId"
            testId="RefreshPnr-bookingId"
            label={<FormattedMessage {...messages.booking} />}
            placeholder={<FormattedMessage {...messages.selectBooking} />}
            messages={messages}
            options={selectOptions}
          />
        </div>
        <InfoBox styleSheet={styles.info} message={<FormattedMessage {...messages.info} />} />
      </div>
      <Button
        type="submit"
        variant="primary"
        size="xlarge"
        testId="RefreshPnrSubmit"
        styleSheet={styles.button}
      >
        <FormattedMessage {...messages.refreshBooking} />
      </Button>
    </form>
  );
};

export const validate = (values: RefreshRequestData) => {
  const errors = {} as RefreshRequestData;
  if (!values.bookingId) {
    errors.bookingId = 'bookingMustBeSelected';
  }
  return errors;
};

const mapStateToProps = (state: State) => {
  const refreshableBookings = selectors.getRefreshableBookings(state);
  return {
    bookings: refreshableBookings,
    failedAlreadyRefreshed: selectors.isFailedAlreadyRefreshed(state.refreshPnr),
    initialValues: {
      bookingId: refreshableBookings.length > 1 ? 'allBookings' : '',
    },
  };
};

export default connect(mapStateToProps)(
  reduxForm<RefreshRequestData, Props>({
    form: FORM_ID,
    validate,
    onSubmit: (_: any, dispatch: Dispatch<Action>) => {
      dispatch(actions.askConfirmation());
    },
  })(RefreshPnrForm)
);
