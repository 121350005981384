import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams, useLocation, useHistory, Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import {
  getAvailableManagedGroups,
  getCurrentManagedGroupNumber,
  selectAuthenticated,
} from '@contactcentre-web/authentication/redux/selectors';
import { actions } from '@contactcentre-web/authentication/redux/managedGroup/module';
import selectors from '@contactcentre-web/authentication/redux/managedGroup/selectors';
import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from '@contactcentre-web/common/Modal';
import Button from '@contactcentre-web/common/Button';

import messages from './messages';

interface Params {
  managedGroup: string;
}

const ManagedGroupsPage = () => {
  const { managedGroup } = useParams<Params>();
  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectAuthenticated);
  const currentManagedGroupNumber = useSelector(getCurrentManagedGroupNumber);
  const availableManagedGroups = useSelector(getAvailableManagedGroups);
  const isChangingManagedGroup = useSelector(selectors.isSubmitting);

  const query = new URLSearchParams(search).get('q');
  const managedGroupNumber = Number(managedGroup);
  const isQueryFromCurrentManagedGroup = managedGroupNumber === currentManagedGroupNumber;
  const hasAccessToQueryManagedGroup = availableManagedGroups.find(
    ({ number }) => number === managedGroupNumber
  );
  const managedGroupName = hasAccessToQueryManagedGroup?.name;

  const goToHomepage = () => history.push('/');

  if (isNaN(managedGroupNumber)) {
    return <Redirect to="/" />;
  }

  if (!query || !isAuthenticated) {
    return null;
  }

  if (isQueryFromCurrentManagedGroup) {
    return <Redirect to={`/customers/?q=${query}`} />;
  }

  if (!!hasAccessToQueryManagedGroup) {
    return (
      <Modal onClose={goToHomepage} initialFocus={false}>
        <ModalHeader>
          <ModalTitle>
            <FormattedMessage {...messages.changeManagedGroupTitle} />
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <FormattedMessage
            {...messages.changeManagedGroupBody}
            values={{
              br: <br />,
              name: <strong>{managedGroupName}</strong>,
            }}
          />
        </ModalBody>
        <ModalFooter contentAlignment="spaceBetween">
          <Button variant="tertiary" size="large" onClick={goToHomepage}>
            <FormattedMessage {...messages.modalReturnToHomeButton} />
          </Button>
          <Button
            variant="primary"
            size="large"
            onClick={() => dispatch(actions.submitAttempt(managedGroupNumber))}
            loading={isChangingManagedGroup}
          >
            <FormattedMessage {...messages.modalChangeButton} />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  return (
    <Modal onClose={goToHomepage} initialFocus={false}>
      <ModalHeader>
        <ModalTitle>
          <FormattedMessage {...messages.noAccessToManagedGroupTitle} />
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <FormattedMessage {...messages.noAccessToManagedGroupBody} />
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" size="large" onClick={goToHomepage}>
          <FormattedMessage {...messages.modalReturnToHomeButton} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ManagedGroupsPage;
