import { defineMessages } from 'react-intl';

export default defineMessages({
  modalHeader: {
    id: 'EditCustomerProfile.header',
    defaultMessage: 'Edit customer details',
  },
  editProfileButton: {
    id: 'EditCustomerProfile.editProfileButton',
    defaultMessage: 'Edit profile',
  },
  SSOV0006ErrorMessage: {
    id: 'EditCustomerProfile.emailAddressAlreadyExists',
    defaultMessage: 'Another account with this email already exists',
  },
  genericErrorMessage: {
    id: 'EditCustomerProfile.genericErrorMessage',
    defaultMessage: "Failed to update customer's profile, please try again",
  },
});
