import { StyleSheet } from 'aphrodite/no-important';
import {
  spacers,
  palettes,
  borderRadius,
  typography,
  fonts,
} from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    minHeight: '300px',
    flex: '1',
    padding: spacers.dp_s32,
  },
  noOptionsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    background: palettes.dp_brand_white,
    boxShadow: `0px 0px 4px ${palettes.dp_comp_box_shadow}, 0px 2px 6px ${palettes.dp_comp_box_shadow}`,
    borderRadius: borderRadius.dp_r8,
    padding: spacers.dp_s24,
    fontSize: typography.dp_title2_font_size,
    fontWeight: fonts.dp_font_weight_bold,
    color: palettes.dp_brand_neutral_dim,
  },
  directionsText: {
    textTransform: 'uppercase',
    fontSize: typography.dp_micro_font_size,
    color: palettes.dp_brand_neutral_dim,
  },
  tripText: {
    color: palettes.dp_brand_neutral_darkest,
    fontWeight: fonts.dp_font_weight_bold,
    marginTop: spacers.dp_s4,
    marginBottom: spacers.dp_s12,
  },
  topMenu: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: spacers.dp_s24,
  },
  bottomMenu: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: spacers.dp_s16,
  },
  buttonControl: {
    color: palettes.dp_app_link_base,
    flexShrink: 0,
    display: 'flex',
    fontWeight: fonts.dp_font_weight_semibold,
    ':disabled': {
      backgroundColor: 'none',
    },
  },
  loader: {
    marginTop: spacers.dp_s12,
  },
  alertOpenReturn: {
    marginTop: spacers.dp_s48,
    marginBottom: spacers.dp_s48,
    fontSize: typography.dp_small_font_size_m,
    lineHeight: typography.dp_small_line_height,
  },
  topWarning: {
    marginTop: spacers.dp_s12,
    marginBottom: `-${spacers.dp_s12}`,
  },
  calendar: {
    border: 'none',
    cursor: 'pointer',
    background: 'none',
    textDecoration: 'underline',
    color: palettes.dp_app_link_base,
    textShadow: `0 0 0 ${palettes.dp_brand_black}`,
    fontSize: typography.dp_small_font_size_m,
    lineHeight: typography.dp_small_line_height,
  },
  statusMessage: {
    margin: `${spacers.dp_s8} 0`,
  },
});
