import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { IconPassenger } from '@trainline/depot-web';

import FormattedDateInTz from '@contactcentre-web/common/FormattedDateInTz';
import OriginToDestination from '@contactcentre-web/common/OriginToDestination/OriginToDestination';

import messages from './messages';
import styles from './styles';

const ProductDetails = ({
  origin,
  destination,
  localAreaValidity,
  validity: { from, to },
  passenger,
}) => (
  <div>
    <h2 className={css(styles.bookingTitle)}>
      {localAreaValidity ?? (
        <OriginToDestination isReturn origin={origin} destination={destination} />
      )}
    </h2>
    <div className={css(styles.details)}>
      <div className={css(styles.detailsContainer)}>
        <div>
          <div className={css(styles.dateRow)}>
            <span className={css(styles.dateLabel)}>
              <FormattedMessage {...messages.validFrom} />
            </span>
            <span className={css(styles.dateValue)}>
              <FormattedDateInTz value={from} year="numeric" month="short" day="numeric" />
            </span>
          </div>
          <div className={css(styles.dateRow)}>
            <span className={css(styles.dateLabel)}>
              <FormattedMessage {...messages.validTo} />
            </span>
            <span className={css(styles.dateValue)}>
              <FormattedDateInTz value={to} year="numeric" month="short" day="numeric" />
            </span>
          </div>
        </div>
      </div>
      {passenger?.passengerType && (
        <div className={css(styles.passengersContainer)} data-testid="passenger-type">
          <IconPassenger />
          <FormattedMessage
            {...messages.passenger}
            values={{ passengerType: passenger.passengerType }}
          />
        </div>
      )}
    </div>
  </div>
);

ProductDetails.propTypes = {
  origin: PropTypes.string,
  destination: PropTypes.string,
  localAreaValidity: PropTypes.string,
  validity: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
  passenger: PropTypes.shape({
    passengerType: PropTypes.string,
  }),
};

export default ProductDetails;
