import React from 'react';
import { css } from 'aphrodite/no-important';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Heading } from '@trainline/depot-web';

import * as userSelectors from '@contactcentre-web/authentication/redux/selectors';
import Button from '@contactcentre-web/common/Button';
import { usePayTracsOnAccount } from '@contactcentre-web/hooks/api/useTracsOnAccount';

import ErrorMessages from './components/ErrorMessages/ErrorMessages';
import TracsOnAccountForm from './components/TracsOnAccountForm/TracsOnAccountForm';
import messages from './messages';
import styles from './styles';

const TracsOnAccount = () => {
  const history = useHistory();
  const canApproveUnlimitedRefund = useSelector(userSelectors.canApproveUnlimitedRefund);
  const { mutate, isSuccess, error, isLoading } = usePayTracsOnAccount({});

  if (isSuccess) {
    history.push('/tracs-onaccount-approvals');
  }

  return (
    <>
      <div className={css(styles.headerContainer)}>
        <Heading typeStyle="title1" as="h1" color="base">
          <FormattedMessage {...messages.header} />
        </Heading>
        {canApproveUnlimitedRefund && (
          <Button variant="tertiary" size="small" to="/tracs-onaccount-approvals">
            <FormattedMessage {...messages.approvalQueueButton} />
          </Button>
        )}
      </div>
      <ErrorMessages errors={error?.errors} />
      <TracsOnAccountForm payTracsOnAccount={mutate} isLoading={isLoading} />
    </>
  );
};

export default TracsOnAccount;
