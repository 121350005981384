import { StyleSheet } from 'aphrodite/no-important';
import { spacers, fonts, typography } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  button: {
    padding: `${spacers.dp_s4} ${spacers.dp_s12}`,
    fontWeight: fonts.dp_font_weight_bold,
    fontSize: typography.dp_body_font_size,
  },
  ghostButton: {
    display: 'flex',
  },
});
