import { StyleSheet } from 'aphrodite/no-important';
import { typography, spacers, fonts } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  container: {
    marginTop: spacers.dp_s16,
    paddingBottom: spacers.dp_s16,
    borderBottom: `1px solid ${colors.gray.alpha4}`,
    ':last-child': {
      border: 'none',
      marginBottom: spacers.dp_s24,
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  rowBooking: {
    justifyContent: 'space-between',
  },
  greyLabel: {
    color: colors.gray.alpha33,
    fontSize: typography.dp_small_font_size_m,
    lineHeight: typography.dp_small_line_height,
  },
  middot: {
    padding: spacers.dp_s8,
  },
  fulfilmentStatusIssued: {
    color: colors.positive.dark,
  },
  fulfilmentStatusIssuing: {
    color: colors.warning.dark,
  },
  fulfilmentStatusOthers: {
    color: colors.negative.dark,
  },
  duration: {
    marginRight: spacers.dp_s4,
  },
  validityPeriod: {
    paddingTop: spacers.dp_s16,
    fontWeight: fonts.dp_font_weight_bold,
  },
  directions: {
    display: 'flex',
  },
  price: {
    lineHeight: 1.75,
    textAlign: 'right',
    paddingTop: spacers.dp_s32,
  },
  title2: {
    fontFamily: typography.dp_title2_font_family,
    fontSize: typography.dp_title2_font_size_m,
    fontWeight: fonts.dp_font_weight_bold,
    lineHeight: typography.dp_title2_line_height_m,
    letterSpacing: '-0.3px', // TODO: replace with depot-web typography when updated in depot-web
  },
  inventoryPrice: {
    fontStyle: 'italic',
    color: colors.gray.alpha54,
  },
});
