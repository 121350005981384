import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { css } from 'aphrodite/no-important';
import { IconModeOfTransportTrain } from '@trainline/depot-web';

import FormattedTimeInTz from '@contactcentre-web/common/FormattedTimeInTz/FormattedTimeInTz';
import FormattedDateInTz from '@contactcentre-web/common/FormattedDateInTz/FormattedDateInTz';
import formatDuration from '@contactcentre-web/utils/duration';

import Passenger from '../Passenger/Passenger';

import messages from './messages';
import styles from './styles';

const Leg = ({
  legNum,
  legCount,
  arriveAt,
  departAt,
  originCity,
  originStation,
  deliveryRecipient,
  destinationCity,
  destinationStation,
  passengers,
  transportInformation: { carrier, type },
  seatingPreferences,
}) => (
  <div data-test="legInfo" className={css(styles.legInfo)}>
    <div>
      <span className={css(styles.grayLabel, styles.leg)}>
        <FormattedMessage {...messages.journeyLeg} values={{ legNum, legCount }} />
      </span>

      <h3 className={css(styles.fromTo)}>
        <IconModeOfTransportTrain className={css(styles.tripIcon)} />
        <FormattedMessage
          {...messages.direction}
          values={{
            origin: originCity || originStation,
            destination: destinationCity || destinationStation,
          }}
        />
      </h3>
      <div className={css(styles.indentedContent)}>
        <div className={css(styles.row)}>
          <div className={css(styles.legDates)}>
            <div className={css(styles.legSummary)}>
              <span className={css(styles.legDate)}>
                <FormattedDateInTz
                  value={departAt}
                  year="numeric"
                  month="short"
                  day="numeric"
                  weekday="short"
                />
              </span>

              <span className={css(styles.timeTypeLabel, styles.grayLabel)}>
                <FormattedMessage {...messages.departAt} />
              </span>

              <time className={css(styles.timeLabel)}>
                <FormattedTimeInTz value={departAt} hour="2-digit" minute="2-digit" />
              </time>
              <span className={css(styles.station)}>
                <FormattedMessage {...messages.originStation} values={{ originStation }} />
                {originCity && (
                  <span className={css(styles.city)}>
                    <FormattedMessage {...messages.originCity} values={{ originCity }} />
                  </span>
                )}
              </span>
            </div>
            <div className={css(styles.legSummary)}>
              <span className={css(styles.legDate)}>
                <FormattedTimeInTz value={arriveAt} hour="2-digit" minute="2-digit" />
              </span>

              <span className={css(styles.timeTypeLabel, styles.grayLabel)}>
                <FormattedMessage {...messages.arriveAt} />
              </span>

              <time className={css(styles.timeLabel)}>
                <FormattedTimeInTz value={arriveAt} hour="2-digit" minute="2-digit" />
              </time>

              <span className={css(styles.station)}>
                <FormattedMessage
                  {...messages.destinationStation}
                  values={{ destinationStation }}
                />
                {destinationCity && (
                  <span className={css(styles.city)}>
                    <FormattedMessage {...messages.destinationCity} values={{ destinationCity }} />
                  </span>
                )}
              </span>
            </div>
          </div>
          <div className={css(styles.transportInformationWrapper)}>
            <span className={css(styles.transportInformation)}>
              <FormattedMessage
                {...messages.transportInformation}
                values={{ carrier: carrier || type }}
              />
            </span>
          </div>
          <div>
            <span className={css(styles.duration)}>
              <FormattedMessage {...messages.routeDuration} />
            </span>
            <span className={css(styles.grayLabel)}>
              {formatDuration(moment(arriveAt).diff(departAt))}
            </span>
          </div>
        </div>

        {passengers.map((passenger, index) => (
          <Passenger
            key={index}
            {...passenger}
            deliveryRecipient={deliveryRecipient}
            passengerNum={index + 1}
            seatingPreferences={seatingPreferences}
          />
        ))}
      </div>
    </div>
  </div>
);

Leg.propTypes = {
  deliveryRecipient: PropTypes.object,
  legNum: PropTypes.number.isRequired,
  legCount: PropTypes.number.isRequired,
  arriveAt: PropTypes.string.isRequired,
  departAt: PropTypes.string.isRequired,
  originCity: PropTypes.string,
  originStation: PropTypes.string.isRequired,
  destinationCity: PropTypes.string,
  destinationStation: PropTypes.string.isRequired,
  passengers: PropTypes.arrayOf(PropTypes.object).isRequired,
  transportInformation: PropTypes.object.isRequired,
  seatingPreferences: PropTypes.string,
  boardBeforeGuaranteed: PropTypes.number,
};

export default Leg;
