import { defineMessages } from 'react-intl';

export default defineMessages({
  editUser: {
    id: 'EditActions.editUser',
    defaultMessage: 'Edit user - {username} - {fullName}',
  },
  resetPassword: {
    id: 'EditActions.resetPassword',
    defaultMessage: 'Reset password',
  },
  changeRole: {
    id: 'EditActions.changeRole',
    defaultMessage: 'Change role',
  },
  changeSite: {
    id: 'EditActions.changeSite',
    defaultMessage: 'Change site',
  },
  changeEmail: {
    id: 'EditActions.changeEmail',
    defaultMessage: 'Change email',
  },
  userAlreadyDisabled: {
    id: 'EditActions.userDisabled',
    defaultMessage: 'This user is currently disabled',
  },
  currentUserAvailableActions: {
    id: 'EditActions.currentUserAvailableActions',
    defaultMessage:
      'The only available actions for your own account is resetting the password and changing site',
  },
  cancel: {
    id: 'EditActions.cancel',
    defaultMessage: 'Cancel',
  },
  successfullyUpdated: {
    id: 'EditActions.successfullyUpdated',
    defaultMessage: 'Successfully updated.',
  },
  passwordDisabledSSO: {
    id: 'EditActions.passwordDisabledSSO',
    defaultMessage:
      'You can’t reset this password because this user’s access is via single-sign on.',
  },
});
