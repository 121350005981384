import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'manualEntryConsoleDeleteModal.header',
    defaultMessage: 'Delete this claim?',
  },
  body: {
    id: 'manualEntryConsoleDeleteModal.body',
    defaultMessage: 'This action is final and you cannot recover this claim.',
  },
  cancelButton: {
    id: 'manualEntryConsoleDeleteModal.cancelButton',
    defaultMessage: 'Cancel',
  },
  deleteButton: {
    id: 'manualEntryConsoleDeleteModal.deleteButton',
    defaultMessage: 'Delete claim',
  },
});
