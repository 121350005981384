import { useQuery } from 'react-query';

import request from '@contactcentre-web/utils/request';

export const useAlternativeJourneys = (journeyDirection: string, link: string) =>
  useQuery(`alternativeJourneys-${journeyDirection}-${link}`, () => request<any>(link), {
    select: ({ journeys, links }) => ({
      journeys,
      links,
    }),
  });
