import { StyleSheet } from 'aphrodite/no-important';
import { spacers, palettes, fonts } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  title: {
    margin: `${spacers.dp_s32} 0 ${spacers.dp_s8} 0`,
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  loginDetails: {
    width: '374px',
  },
  infoBox: {
    marginLeft: spacers.dp_s32,
    alignSelf: 'flex-start',
  },
  button: {
    marginTop: spacers.dp_s64,
  },
  label: {
    marginTop: spacers.dp_s16,
    color: palettes.dp_app_text_subdued,
  },
  value: {
    fontWeight: fonts.dp_font_weight_bold,
    color: palettes.dp_app_text_base,
  },
});
