import { css } from 'aphrodite/no-important';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FieldArray, WrappedFieldArrayProps } from 'redux-form';
import { IconEnvelope } from '@trainline/depot-web';

import Button from '@contactcentre-web/common/Button';
import Label from '@contactcentre-web/common/Label/Label';

import EmailInput from './EmailInput';
import messages from './messages';
import styles, { fieldStyles } from './styles';

interface Props {
  label: string;
  disabled: boolean;
}
interface PropsFieldSet extends Props {
  name: string;
}
const EmailList = ({
  label,
  fields,
  disabled,
  meta: { error },
}: Props & WrappedFieldArrayProps) => (
  <section>
    <Label styleSheet={fieldStyles.label} disabled={disabled} label={label} />

    {fields.map((email, index) => (
      <EmailInput
        key={index}
        disabled={disabled}
        name={email}
        canRemove={index > 0}
        onRemoveEmailClick={() => fields.remove(index)}
      />
    ))}
    {fields.length < 9 && (
      <Button
        variant="ghost"
        disabled={disabled}
        styleSheet={styles.addEmail}
        onClick={() => fields.push(undefined)}
      >
        <IconEnvelope className={css(styles.iconEmail)} />
        <FormattedMessage {...messages.addAnotherEmail} />
      </Button>
    )}
    {error && <span className={css(styles.errorContainer)}>{error}</span>}
  </section>
);

const EmailListFieldSet = ({ name, ...others }: PropsFieldSet) => (
  <FieldArray name={name} component={EmailList} {...others} />
);

export default EmailListFieldSet;
