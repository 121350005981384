import React, { ReactElement } from 'react';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';
import { IconInfo } from '@trainline/depot-web';

import styles from './styles';

interface Props {
  visible?: boolean;
  styleSheet?: StyleDeclarationValue;
  message: string | ReactElement;
}

const InfoBox = ({ visible = true, message, styleSheet }: Props) => (
  <div className={css([styles.container, styleSheet, visible ? null : styles.hidden])}>
    <IconInfo className={css(styles.infoIcon)} />
    {message}
  </div>
);

export default InfoBox;
