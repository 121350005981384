import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency';
import FeeLabel from '@contactcentre-web/common/FeeLabel';
import Price from '@contactcentre-web/redux-common/types/Price';

import messages from './messages';
import styles from './styles';

interface Props {
  productTotal?: Price;
  discounts?: Array<{
    type: string;
    value: Price;
  }>;
}

export const ProductTotals = ({ productTotal, discounts }: Props) => (
  <section className={css(styles.totals)}>
    <section className={css(styles.totalsRow)}>
      {productTotal ? (
        <FeeLabel
          message={messages.productsToRefund}
          value={productTotal}
          styleSheet={{
            label: styles.adjustLabel,
            value: styles.amount,
          }}
        />
      ) : (
        <>
          <span className={css(styles.adjustLabel)}>
            <FormattedMessage {...messages.productsToRefund} />:
          </span>
          <span className={css(styles.amount)}>
            <FormattedMessage {...messages.productsToRefundNA} />
          </span>
        </>
      )}
    </section>
    {discounts &&
      discounts.map(({ type, value }, index) => (
        <div key={index} className={css(styles.totalsRow)}>
          <FormattedMessage
            {...((messages as any)[`discountType_${type.toLowerCase()}`] ||
              messages.discountType_unknown)}
            values={{ code: type }}
          >
            {(content: string) => (
              <>
                <span className={css(styles.adjustLabel)}>{content}:</span>
              </>
            )}
          </FormattedMessage>
          <div className={css(styles.amount)}>
            <FormattedCurrency {...value} />
          </div>
        </div>
      ))}
  </section>
);

export default ProductTotals;
