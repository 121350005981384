export interface IframePostMessageBody {
  orderReference: string;
  action: 'ADD' | 'UPDATE' | 'REFUND' | 'VOID';
  tenantId: string;
}

export const iframePostMessageGenerator = ({
  orderReference,
  action,
  tenantId,
}: IframePostMessageBody) => {
  window.parent.postMessage(
    JSON.stringify({
      orderReference,
      action,
      tenantId,
    }),
    '*'
  );
};
