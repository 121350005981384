import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { IconChevronLeft } from '@trainline/depot-web';

import Link from '@contactcentre-web/common/Link';

import AddPassenger from '../AddPassenger/AddPassenger';

import messages from './messages';
import styles from './styles';

const Header = () => {
  const history = useHistory();

  return (
    <div className={css(styles.passengerContainer)}>
      <Link
        linkType="action"
        onClick={() => history.goBack()}
        testId="passenger-back-button"
        styleSheet={styles.backLink}
      >
        <IconChevronLeft className={css(styles.arrowLeft)} />
        <FormattedMessage {...messages.backButton} />
      </Link>
      <div>
        <div className={css(styles.passengerContent)}>
          <span className={css(styles.passengerHeader)}>
            <FormattedMessage {...messages.passengerHeader} />
          </span>
          <AddPassenger />
        </div>
      </div>
    </div>
  );
};

export default Header;
