import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { IconModeOfTransportTrain } from '@trainline/depot-web';

import FareDetails from './FareDetails';
import messages from './messages';
import styles from './styles';

const TicketsDetails = ({ ticketDetails }) =>
  ticketDetails.map(
    ({ origin, destination, deliveryMethod, vendor, faresByType, direction }, idx) => (
      <div className={css(styles.ticketDetail)} key={idx}>
        <div className={css(styles.row)}>
          <IconModeOfTransportTrain className={css(styles.tripIcon)} />
          <div className={css(styles.largeCol)}>
            <h2 className={css(styles.header)}>
              {direction && messages[direction.toLowerCase()] && (
                <span>
                  <FormattedMessage {...messages[direction.toLowerCase()]} />{' '}
                </span>
              )}
              <FormattedMessage
                {...messages.legOf}
                values={{
                  index: idx + 1,
                  total: ticketDetails.length,
                }}
              />
            </h2>
            <FormattedMessage
              {...messages.route}
              values={{
                origin,
                destination,
                line: (content) => <span className={css(styles.line)}>{content}</span>,
                loc: (location) => <span className={css(styles.location)}>{location}</span>,
              }}
            >
              {(content) => <span className={css(styles.route)}>{content}</span>}
            </FormattedMessage>
          </div>
          <div className={css(styles.col)}>
            <h2 className={css(styles.header)}>
              <FormattedMessage {...messages.ful} />
            </h2>
            <strong className={css(styles.fulfilmentMethod)}>{deliveryMethod}</strong>
          </div>
          <div className={css(styles.col)}>
            <h2 className={css(styles.header)}>
              <FormattedMessage {...messages.operator} />
            </h2>
            {vendor}
          </div>
        </div>
        <div className={css([styles.fares, styles.intendedContent])}>
          {faresByType.map(({ name, conditionSummary, conditions, passengerTypes }) => (
            <FareDetails
              key={`${name}-${idx}`}
              name={name}
              conditionSummary={conditionSummary}
              conditions={conditions}
              passengerTypes={passengerTypes}
            />
          ))}
        </div>
      </div>
    )
  );

TicketsDetails.propTypes = {
  ticketDetails: PropTypes.array,
};
export default TicketsDetails;
