import { defineMessages } from 'react-intl';

export default defineMessages({
  railcardLabel: {
    id: 'Passenger.railcardLabel',
    defaultMessage: 'Railcard: {value}',
  },
  railcard: {
    id: 'Passenger.railcard',
    defaultMessage: '{value}',
  },
  ticketType: {
    id: 'Passenger.ticketType',
    defaultMessage: 'Ticket type',
  },
  coachSeat: {
    id: 'Passenger.coachSeat',
    defaultMessage: 'Coach/Seat',
  },
  deliveryMethod: {
    id: 'Passenger.deliveryMethod',
    defaultMessage: 'Delivery method',
  },
  ticketClass: {
    id: 'Passenger.ticketClass',
    defaultMessage: 'Class',
  },
  ticketPreferences: {
    id: 'Passenger.ticketPreferences',
    defaultMessage: 'Preferences',
  },
  deliveryAddress: {
    id: 'Passenger.deliveryAddress',
    defaultMessage: 'Delivery address: {value}',
  },
  notAvailable: {
    id: 'Passenger.notAvailable',
    defaultMessage: 'N/A',
  },
  dateOfBirth: {
    id: 'Passenger.dateOfBirth',
    defaultMessage: 'DoB: {value}',
  },
  passengerTypeName: {
    id: 'Passenger.passengerTypeName',
    defaultMessage: '{value} passenger',
  },
  onBoardServices: {
    id: 'SeatReservation.onBoardServices',
    defaultMessage: 'Onboard services',
  },
});
