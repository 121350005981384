import { defineMessages } from 'react-intl';

export default defineMessages({
  requestedBy: {
    id: 'OrderHistory.requestedBy',
    defaultMessage: 'Requested by: {user}',
  },
  createdAt: {
    id: 'OrderHistory.createdAt',
    defaultMessage: '{day} at {time}',
  },
  paymentDetails: {
    id: 'OrderHistory.paymentDetails',
    defaultMessage:
      '{paymentMethod, select, card {{cardType} • Ending {cardNumber}} other {{paymentMethod} • {email}}}',
  },
  unknown: {
    id: 'OrderHistory.unknown',
    defaultMessage: 'Unknown',
  },
});
