import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';

import { Modal, ModalFooter, ModalHeader, ModalTitle } from '@contactcentre-web/common/Modal';
import {
  OnAccountAction,
  useTracsOnAccountValidator,
} from '@contactcentre-web/hooks/api/useTracsOnAccount';
import Button from '@contactcentre-web/common/Button';

import messages from './messages';

interface Props {
  action: OnAccountAction;
  id: string;
  onError: () => void;
  onSuccess: () => void;
}

const ItemValidatorButton = ({ action, id, onError, onSuccess }: Props) => {
  const TIME_TO_REFRESH_AND_ANIMATE = 500; // 0.5 seconds
  const queryClient = useQueryClient();
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);
  const buttonVariant = action === OnAccountAction.Approve ? 'primary' : 'destructive';

  const onUpdate = () => {
    setIsShowConfirmationModal(false);
    setTimeout(() => {
      queryClient.invalidateQueries({ queryKey: ['tracsOnAccountApprovalQueue'] });
    }, TIME_TO_REFRESH_AND_ANIMATE);
  };

  const { mutate, isLoading } = useTracsOnAccountValidator(id, action, {
    onSuccess: () => {
      onUpdate();
      onSuccess();
    },
    onError: () => {
      onUpdate();
      onError();
    },
  });

  return (
    <>
      <Button size="small" variant={buttonVariant} onClick={() => setIsShowConfirmationModal(true)}>
        <FormattedMessage {...messages[`${action}Button`]} />
      </Button>
      {isShowConfirmationModal && (
        <Modal onClose={() => setIsShowConfirmationModal(false)}>
          <ModalHeader>
            <ModalTitle>
              <FormattedMessage {...messages[`${action}Header`]} />
            </ModalTitle>
          </ModalHeader>
          <ModalFooter contentAlignment="spaceBetween">
            <Button
              variant="tertiary"
              size="large"
              onClick={() => setIsShowConfirmationModal(true)}
            >
              <FormattedMessage {...messages[`${action}Cancel`]} />
            </Button>
            <Button
              variant={buttonVariant}
              size="large"
              onClick={() => mutate()}
              loading={isLoading}
            >
              <FormattedMessage {...messages[`${action}Confirm`]} />
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default ItemValidatorButton;
