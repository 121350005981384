import React, { FunctionComponent } from 'react';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';

import styles from './styles';

export type Status = 'info' | 'warning' | 'positive' | 'negative';

interface StatusLabelProps {
  status: Status;
  styleSheet?: StyleDeclarationValue;
}

const StatusLabel: FunctionComponent<StatusLabelProps> = ({ status, styleSheet, children }) => (
  <div className={css(styles.default, styles[status], styleSheet)}>{children}</div>
);

export default StatusLabel;
