import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import locationShape from '@contactcentre-web/utils/shape/location';
import {
  selectLoggedIn,
  selectAuthenticated,
} from '@contactcentre-web/authentication/redux/selectors';

const ignoredPathsForRedirect = [
  '/',
  '/login',
  '/select-ui-language',
  '/select-managed-group',
  '/change_password',
];

export const ProtectedRoute = ({
  isAuthenticated,
  isLoggedIn,
  component,
  location: { pathname, search },
  allowPartiallyAuthenticated,
  ...rest
}) => (
  <Route
    {...rest}
    render={() => {
      if (isLoggedIn || (allowPartiallyAuthenticated && isAuthenticated)) {
        return React.createElement(component);
      }

      return (
        <Redirect
          to={
            (pathname && !ignoredPathsForRedirect.includes(pathname)) || search
              ? `/login?redirect=${encodeURIComponent(`${pathname}${search}`)}`
              : '/login'
          }
        />
      );
    }}
  />
);

ProtectedRoute.propTypes = {
  isLoggedIn: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  component: PropTypes.any,
  location: locationShape,
  allowPartiallyAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoggedIn: selectLoggedIn(state),
  isAuthenticated: selectAuthenticated(state),
});

export default connect(mapStateToProps)(ProtectedRoute);
