import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { UseMutateFunction } from 'react-query';
import { css } from 'aphrodite/no-important';
import { Paragraph } from '@trainline/depot-web';

import TextField from '@contactcentre-web/common/TextField';
import Button from '@contactcentre-web/common/Button';

import { Data, FormData } from '../../SetupManagedGroup';

import messages from './messages';
import styles from './styles';

const FORM_ID = 'generateUserForm';

interface Props {
  onSubmitData: UseMutateFunction<Data, unknown, FormData, unknown>;
  isLoading?: boolean;
  isRegenerate?: boolean;
}

export const GenerateUserForm = ({
  handleSubmit,
  onSubmitData,
  isLoading = false,
  isRegenerate = false,
}: Props & InjectedFormProps<FormData, Props>) => {
  const { formatMessage } = useIntl();

  return (
    <>
      {!isRegenerate && (
        <Paragraph typeStyle="body" as="p" color="base" fontWeight="regular">
          <FormattedMessage {...messages.description} values={{ br: <br /> }} />
        </Paragraph>
      )}
      <form
        id={FORM_ID}
        onSubmit={handleSubmit((formData) => onSubmitData(formData))}
        className={css(styles.form)}
      >
        {!isRegenerate && (
          <>
            <div className={css(styles.fieldsColumn)}>
              <TextField
                name="firstName"
                label={formatMessage(messages.firstNameLabel)}
                messages={messages}
              />
              <div className={css(styles.lastNameField)}>
                <TextField
                  name="lastName"
                  label={formatMessage(messages.lastNameLabel)}
                  messages={messages}
                />
              </div>
            </div>
            <div className={css(styles.usernameField)}>
              <TextField
                name="username"
                label={formatMessage(messages.usernameLabel)}
                messages={messages}
                infoButtonText={formatMessage(messages.tooltipText)}
              />
            </div>
          </>
        )}
        <Button
          type="submit"
          variant="primary"
          size="large"
          styleSheet={styles.button}
          loading={isLoading}
        >
          {isRegenerate ? (
            <FormattedMessage {...messages.regenerate} />
          ) : (
            <FormattedMessage {...messages.generate} />
          )}
        </Button>
      </form>
    </>
  );
};

export const validate = ({ firstName, lastName, username }: FormData) => ({
  firstName: !firstName ? 'firstNameRequired' : undefined,
  lastName: !lastName ? 'lastNameRequired' : undefined,
  username: !username ? 'usernameRequired' : undefined,
});

export default reduxForm<FormData, Props>({
  form: FORM_ID,
  validate,
})(GenerateUserForm);
