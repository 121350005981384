import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'aphrodite/no-important';

import Checkbox from '@contactcentre-web/common/Checkbox/Checkbox';
import FeeLabel from '@contactcentre-web/common/FeeLabel/FeeLabel';

import styles from './styles';

const RefundFee = ({ description, id, isChangeable, message, value, disabled }) => (
  <div className={css(styles.feeDetail)}>
    <Checkbox
      id={description}
      name={id}
      label={<FeeLabel message={message} value={value} styleSheet={styles.feeLabel} />}
      disabled={!isChangeable || disabled}
    />
  </div>
);

RefundFee.propTypes = {
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isChangeable: PropTypes.bool,
  message: PropTypes.object.isRequired,
  value: PropTypes.object,
  disabled: PropTypes.bool,
};

export default RefundFee;
