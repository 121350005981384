import { defineMessages } from 'react-intl';

export default defineMessages({
  passengerFormFirstNameLabel: {
    id: 'CustomerPassengerForm.passengerFormFirstNameLabel',
    defaultMessage: 'First name',
  },
  passengerFormLastNameLabel: {
    id: 'CustomerPassengerForm.passengerFormLastNameLabel',
    defaultMessage: 'Last name',
  },
  passengerFormEmailLabel: {
    id: 'CustomerPassengerForm.passengerFormEmailLabel',
    defaultMessage: 'Email address',
  },
  passengerFormDOBLabel: {
    id: 'CustomerPassengerForm.passengerFormDOBLabel',
    defaultMessage: "Passenger's DOB",
  },
  passengerFormCardNumberLabel: {
    id: 'CustomerPassengerForm.passengerFormCardNumberLabel',
    defaultMessage: 'ID card number',
  },
  passengerFormCardNumberPlaceholder: {
    id: 'CustomerPassengerForm.passengerFormCardNumberPlaceholder',
    defaultMessage: '(Spain only)',
  },
  passengerFormContactNumberLabel: {
    id: 'CustomerPassengerForm.passengerFormContactNumberLabel',
    defaultMessage: 'Contact number',
  },
  passengerFormCountryContactNumberLabel: {
    id: 'CustomerPassengerForm.passengerFormCountryContactNumberLabel',
    defaultMessage: 'County code',
  },
  passengerFormAddButton: {
    id: 'CustomerPassengerForm.passengerFormAddButton',
    defaultMessage: 'Add passenger',
  },
  passengerFormEditButton: {
    id: 'CustomerPassengerForm.passengerFormEditButton',
    defaultMessage: 'Save changes',
  },
  passengerFormCancelButton: {
    id: 'CustomerPassengerForm.passengerFormCancelButton',
    defaultMessage: 'Cancel',
  },
  validationFirstNameRequired: {
    id: 'CustomerPassengerForm.validationFirstNameRequired',
    defaultMessage: 'Enter a first name',
  },
  validationLastNameRequired: {
    id: 'CustomerPassengerForm.validationLastNameRequired',
    defaultMessage: 'Enter a last name',
  },
  validationDOBRequired: {
    id: 'CustomerPassengerForm.validationDOBRequired',
    defaultMessage: 'Enter a date of birth',
  },
  validationPhoneNumber: {
    id: 'CustomerPassengerForm.validationPhoneNumber',
    defaultMessage: 'Invalid phone number',
  },
  validationPhoneNumberRequired: {
    id: 'CustomerPassengerForm.validationPhoneNumberRequired',
    defaultMessage: 'Enter a phone number',
  },
  validationPhoneCountryCodeRequired: {
    id: 'CustomerPassengerForm.validationPhoneCountryCodeRequired',
    defaultMessage: 'Enter a country code',
  },
  validationPhoneCountryCode: {
    id: 'CustomerPassengerForm.validationPhoneCountryCode',
    defaultMessage: 'Invalid phone country code',
  },
});
