import { defineMessages } from 'react-intl';

export default defineMessages({
  createdAt: {
    id: 'OrderHistoryCard.createdAt',
    defaultMessage: '{day} at {time}',
  },
  booking: {
    id: 'HistoryCardHeader.booking',
    defaultMessage: 'booking',
  },
  agent: {
    id: 'HistoryCardHeader.agent',
    defaultMessage: 'agent',
  },
  agentNotAvailable: {
    id: 'HistoryCardHeader.agentNotAvailable',
    defaultMessage: 'N/A',
  },
});
