import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import IconRail from '@contactcentre-web/common/Icons/IconRail';
import { Status, CallingPoint, CallingType } from '@contactcentre-web/redux-common/types/Timetable';
import formatDuration from '@contactcentre-web/utils/duration';

import messages from './messages';
import styles from './styles';

export interface Props {
  status: Status;
  carrierName: string;
  transportMode: string;
  callingPoints: CallingPoint[];
}

const getDuration = (status: Status, callingPoints: CallingPoint[]) => {
  const originCallingPoint = callingPoints.find(
    ({ callingType }) => callingType === CallingType.Origin
  );
  const destinationCallingPoint = callingPoints.find(
    ({ callingType }) => callingType === CallingType.Destination
  );

  const scheduledDuration = moment(destinationCallingPoint?.arrival?.scheduledTime).diff(
    originCallingPoint?.departure?.scheduledTime
  );
  const realDuration = moment(destinationCallingPoint?.arrival?.realTime).diff(
    originCallingPoint?.departure?.realTime
  );

  const isRealDurationSameAsScheduledDuration = moment(realDuration).isSame(
    scheduledDuration,
    'minutes'
  );

  switch (status) {
    case Status.Delayed: {
      return (
        <>
          <div
            className={css(
              styles.callingPointTime,
              !isRealDurationSameAsScheduledDuration && styles.callingPointTimeStriked
            )}
            data-test="scheduled-time"
          >
            {formatDuration(scheduledDuration)}
          </div>
          {isRealDurationSameAsScheduledDuration ? null : (
            <div className={css(styles.callingPointTimeDelayed)} data-test="real-time">
              {formatDuration(realDuration)}
            </div>
          )}
        </>
      );
    }
    case Status.CancelledOnOriginStop:
    case Status.CancelledOnDestinationStop:
    case Status.CancelledOnOriginAndDestinationStop: {
      return (
        <>
          <div
            className={css(styles.callingPointTime, styles.callingPointTimeStriked)}
            data-test="scheduled-time"
          >
            {formatDuration(scheduledDuration)}
          </div>
          <div className={css(styles.cancelled)}>
            <FormattedMessage {...messages.cancelled} />
          </div>
        </>
      );
    }
    case Status.Cancelled: {
      return (
        <div className={css(styles.cancelled)}>
          <FormattedMessage {...messages.cancelled} />
        </div>
      );
    }
    default: {
      return (
        <div className={css(styles.callingPointTime)} data-test="scheduled-time">
          {formatDuration(scheduledDuration)}
        </div>
      );
    }
  }
};

const CallingPointsDetails = ({ status, carrierName, transportMode, callingPoints }: Props) => (
  <div className={css(styles.details)}>
    {transportMode === 'Train' && (
      <div className={css(styles.railIcon)}>
        <IconRail />
      </div>
    )}
    <div className={css(styles.carrier)}>{carrierName}</div>
    <div className={css(styles.duration)} data-test="calling-point-duration">
      {getDuration(status, callingPoints)}
    </div>
  </div>
);

export default CallingPointsDetails;
