import { defineMessages } from 'react-intl';

export default defineMessages({
  cardTitle: {
    id: 'RailcardCancellation.cardTitle',
    defaultMessage: 'Railcard cancelled',
  },
  railcardName: {
    id: 'RailcardCancellation.railcardName',
    defaultMessage: 'Product',
  },
  railcardNumber: {
    id: 'RailcardCancellation.railcardNumber',
    defaultMessage: 'Railcard No',
  },
  agent: {
    id: 'RailcardCancellation.agent',
    defaultMessage: 'Requested By',
  },
});
