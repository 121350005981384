import React, { createElement, createRef, ReactElement } from 'react';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';
import { omit } from 'ramda';
import { IconClose, IconInfo } from '@trainline/depot-web';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';

import transitions from '@contactcentre-web/styles/transitions';
import { TextInputProps } from '@contactcentre-web/common/TextInput/TextInput';

import Tooltip from '../../../Tooltip';
import Label from '../../../Label/Label';
import TextInput from '../../../TextInput';
import Button from '../../../Button';

import styles from './styles';

interface CustomProps {
  label?: string | ReactElement;
  infoButtonText?: string;
  styleSheet?: {
    container?: StyleDeclarationValue;
    input?: StyleDeclarationValue;
    inputContainer?: StyleDeclarationValue;
    errorContainer?: StyleDeclarationValue;
  };
  inputComponent?: ReactElement;
  inputError?: boolean;
  hasClearInputIcon?: boolean;
  formName?: string;
}

export type CustomTextFieldProps = CustomProps & TextInputProps;

const CustomInputField = ({
  hasClearInputIcon = true,
  infoButtonText,
  formName,
  styleSheet,
  inputComponent = createElement(TextInput),
  type = 'text',
  ...props
}: CustomTextFieldProps) => {
  const dispatch = useDispatch();
  const ownPropKeys = ['label', 'messages', 'input', 'displayErrorContainer'];
  const renderClearInputIcon =
    !infoButtonText &&
    ['text', 'search', 'email'].includes(type) &&
    props.value &&
    hasClearInputIcon &&
    !props.disabled;

  const inputRef = createRef<HTMLInputElement | HTMLTextAreaElement>();
  const inputProps = {
    ...omit(ownPropKeys, props),
    inputRef,
    type,
    styleSheet: [transitions.borderColor, styleSheet?.input],
  };
  return (
    <div
      className={css(
        styles.inputContainer,
        styleSheet && styleSheet.inputContainer,
        infoButtonText ? styles.inputContainerWithInfoButton : undefined
      )}
    >
      {React.cloneElement(inputComponent, inputProps)}
      {renderClearInputIcon && (
        <Button
          variant="ghost"
          onClick={() => {
            if (props.name && formName) {
              dispatch(change(formName, props.name, ''));
            }
            inputRef?.current?.focus();
          }}
          styleSheet={styles.clearButton}
          tabIndex={-1}
        >
          <IconClose className={css(styles.clearIcon)} />
        </Button>
      )}
      {infoButtonText && (
        <div className={css(styles.infoButtonContainer)}>
          <Tooltip
            anchor={
              <span className={css(styles.infoButton)}>
                <IconInfo />
              </span>
            }
            styleSheet={styles.ballon}
          >
            {infoButtonText}
          </Tooltip>
        </div>
      )}
    </div>
  );
};

const CustomTextField = (props: CustomTextFieldProps) => {
  const { name, label, styleSheet } = props;

  const containerStyleSheet = [styles.outContainer, styleSheet && styleSheet.container];
  return label ? (
    <Label htmlFor={name} label={label} styleSheet={containerStyleSheet}>
      <CustomInputField {...props} />
    </Label>
  ) : (
    <CustomInputField {...props} />
  );
};

export default CustomTextField;
