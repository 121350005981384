import { defineMessages } from 'react-intl';

export default defineMessages({
  disableUser: {
    id: 'DisableUser.disableUser',
    defaultMessage: 'Disable account',
  },
  disableUserConfirmPromptHeader: {
    id: 'DisableUser.disableUserConfirmPromptHeader',
    defaultMessage: 'Disable account?',
  },
  disableUserConfirmPromptConfirmation: {
    id: 'DisableUser.disableUserConfirmPromptConfirmation',
    defaultMessage: 'Are you sure you want to disable this account?',
  },
  disableUserConfirmPromptYes: {
    id: 'DisableUser.disableUserConfirmPromptYes',
    defaultMessage: 'Yes',
  },
  disableUserConfirmPromptCancel: {
    id: 'DisableUser.disableUserConfirmPromptCancel',
    defaultMessage: 'Cancel',
  },
  userAlreadyDisabled: {
    id: 'DisableUser.userDisabled',
    defaultMessage: 'This user currently is disabled',
  },
  cancel: {
    id: 'DisableUser.cancel',
    defaultMessage: 'Cancel',
  },
});
