import { useQuery, UseQueryOptions } from 'react-query';

import request from '@contactcentre-web/utils/request';

interface CallResponse {
  callId: string;
  agentQueueId: string;
}

export const useCallId = (
  options: UseQueryOptions<CallResponse, unknown, CallResponse, 'telephony-call-id'>
) =>
  useQuery(
    'telephony-call-id',
    () => request<CallResponse>('/agent-call-v2/callstates', { method: 'GET' }),
    {
      refetchInterval: 60000,
      refetchOnWindowFocus: 'always',
      ...options,
    }
  );
