import { defineMessages } from 'react-intl';

export default defineMessages({
  'terms-and-conditions': {
    id: 'Refund.termsAndConditions',
    defaultMessage: '{isPartial, select, true {Partial Ts&Cs refund} other {Ts&Cs refund}}',
  },
  'same-day-void': {
    id: 'Refund.sameDayVoid',
    defaultMessage: '{isPartial, select, true {Partial SDV refund} other {SDV refund}}',
  },
  discretionary: {
    id: 'Refund.discretionary',
    defaultMessage:
      '{isPartial, select, true {Partial Discretionary refund} other {Discretionary refund}}',
  },
  refund: {
    id: 'Refund.refund',
    defaultMessage: '{isPartial, select, true {Partial Refund} other {Refund}}',
  },
  bookingInformation: {
    id: 'Refund.bookingInformation',
    defaultMessage: 'Booking information',
  },
  refundStatus: {
    id: 'Refund.refundStatus',
    defaultMessage: 'Refund status',
  },
  multipleRefundStatuses: {
    id: 'Refund.multipleRefundStatuses',
    defaultMessage: 'Multiple refund statuses',
  },
  reasonCode: {
    id: 'Refund.reasonCode',
    defaultMessage: 'Reason code',
  },
  pendingStatus: {
    id: 'Refund.pendingStatus',
    defaultMessage: 'Pending',
  },
  voidingStatus: {
    id: 'Refund.voidingStatus',
    defaultMessage: 'Voiding',
  },
  voidedStatus: {
    id: 'Refund.voidedStatus',
    defaultMessage: 'Voided',
  },
  blockedStatus: {
    id: 'Refund.blockedStatus',
    defaultMessage: 'Blocked',
  },
  rejectedStatus: {
    id: 'Refund.rejectedStatus',
    defaultMessage: 'Rejected',
  },
  refundedStatus: {
    id: 'Refund.refundedStatus',
    defaultMessage: 'Refunded',
  },
  failedStatus: {
    id: 'Refund.failedStatus',
    defaultMessage: 'Failed',
  },
  requestedStatus: {
    id: 'Refund.requestedStatus',
    defaultMessage: 'Requested',
  },
  unknownStatus: {
    id: 'Refund.unknownStatus',
    defaultMessage: 'Unknown',
  },
  refundRequestAwaitingAuthorisation: {
    id: 'Refund.refundRequestAwaitingAuthorisation',
    defaultMessage: 'Awaiting refund request authorisation',
  },
  refundRequestAuthorised: {
    id: 'Refund.refundRequestAuthorised',
    defaultMessage: 'Refund request authorised by {user} on {date}',
  },
  refundRequestRejected: {
    id: 'Refund.refundRequestRejected',
    defaultMessage: 'Refund request rejected by {user} on {date}',
  },
  railcardInformation: {
    id: 'Refund.railcardInformation',
    defaultMessage: 'Railcard information',
  },
});
