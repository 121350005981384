import { useMutation, useQuery, useQueryClient } from 'react-query';

import { FetchError } from '@contactcentre-web/utils/error';
import request from '@contactcentre-web/utils/request';
import Price from '@contactcentre-web/redux-common/types/Price';

interface TicketImage {
  href: string;
  rel: string;
  method: string;
}

export interface Ticket {
  id?: number;
  transactionId: string;
  fromStationName?: string;
  toStationName?: string;
  validUntil?: string;
  price?: Price;
  batchNumber: string;
  sequenceNumber: number;
  scannedDate: Date;
  ticketNumber?: string;
  nlcId?: string;
  machineId?: number;
  windowId?: number;
}

export interface PaperTicketQueueResponse<T> {
  ticketImageUri: TicketImage;
  ticket: Ticket;
  matchedTickets: Array<T>;
  validationCount: number;
}

export interface PaperTicketQueueValidationErrorResponse {
  validationSuccessful: boolean;
  validationCount: number;
}

const paperTicketQueueKey = 'paperTicketQueue';

export enum PaperTicketQueueName {
  Unmatched = 'unmatched',
  FailedValidation = 'failedValidation',
}

export const useNextPaperTicketFromQueue = (paperTicketQueue: PaperTicketQueueName) =>
  useQuery(`${paperTicketQueueKey}-${paperTicketQueue}`, () =>
    request<PaperTicketQueueResponse<Ticket>>(`/api/ticket/${paperTicketQueue}`)
  );

export const usePaperTicketQueueImage = (
  scannedDate: string,
  batchNumber: string,
  sequenceNumber: number
) =>
  useQuery(
    ['paperTicketQueueImage', { scannedDate, batchNumber, sequenceNumber }],
    () => request<Blob>(`/api/ticket/${scannedDate}/${batchNumber}/${sequenceNumber}/image`),
    {
      enabled: !!scannedDate && !!batchNumber && !!sequenceNumber,
      select: (blob) => URL.createObjectURL(new Blob([blob], { type: 'image/jpeg' })),
    }
  );

export interface PaperTicketValidateRequest extends Omit<Ticket, 'price'> {
  price?: string;
}

export const usePaperTicketValidate = (paperTicketQueue: PaperTicketQueueName) =>
  ticketMutation<
    PaperTicketValidateRequest,
    PaperTicketQueueResponse<Ticket>,
    PaperTicketQueueValidationErrorResponse
  >('api/ticket/validate', paperTicketQueue);

export const useMarkAsUnableToReadPaperTicket = (paperTicketQueue: PaperTicketQueueName) =>
  ticketMutation<Ticket>('api/ticket/unreadable', paperTicketQueue);

export const useMarkAsNoMatchInSystem = (paperTicketQueue: PaperTicketQueueName) =>
  ticketMutation<Ticket>('api/ticket/nomatch', paperTicketQueue);

const ticketMutation = <TRequest, TResponse = unknown, TErrorResponse = unknown>(
  endpoint: string,
  paperTicketQueue: PaperTicketQueueName
) => {
  const queryClient = useQueryClient();

  return useMutation<TResponse, FetchError<TErrorResponse>, TRequest, unknown>(
    (data: TRequest) =>
      request<TResponse>(endpoint, {
        method: 'POST',
        body: JSON.stringify(data),
      }),
    { onSuccess: () => queryClient.invalidateQueries(`${paperTicketQueueKey}-${paperTicketQueue}`) }
  );
};
