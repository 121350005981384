import { createSelector } from 'reselect';

import { getBookings } from '@contactcentre-web/redux-common/selectors/order';

import { loadStates } from './module';

export const getOrder = (state) => state.orders && state.orders.order;

export const getRemoveRestrictionReasons = (state) => state.orderActions.removeRestrictionReasons;

const getOrderId = createSelector(getOrder, (order) => order && order.orderReference);

const getCustomerId = createSelector(getOrder, (order) => order.customerId);

const isChangeableToAnyCardAvailable = createSelector(
  getBookings,
  (bookings) => bookings && bookings.some((booking) => booking.changeableToAnyCard)
);

const removeRestrictionReasons = createSelector(getRemoveRestrictionReasons, (reasons) =>
  reasons.length === 0 ? [] : reasons
);

const getCTRfromBookings = (bookings) =>
  bookings.map(({ id, ctr, isReturn, changeableToAnyCard, journeys }) => {
    const { departAt, origin, destination } =
      journeys.find((journey) => journey.direction === 'Outward') || journeys[0];
    return {
      id,
      ctr,
      isReturn,
      departAt,
      origin,
      destination,
      changeableToAnyCard,
    };
  });

const getBookingsChangeableToAnyCard = createSelector(getBookings, (bookings) =>
  getCTRfromBookings(bookings)
);

const isRemoveRestrictionInProgress = (state) =>
  state.orderActions && state.orderActions.loadStatus === loadStates.INPROGRESS;

const bookingIndexConvertedAnyCard = (state) =>
  state.orderActions && state.orderActions.loadStatus === loadStates.SUCCESS
    ? state.orderActions.bookingIndex
    : -1;

const isRemoveRestrictionFailed = (state) =>
  state.orderActions && state.orderActions.loadStatus === loadStates.ERROR;

const isRemoveRestrictionSuccess = (state) =>
  state.orderActions && state.orderActions.loadStatus === loadStates.SUCCESS;

const selectors = {
  isChangeableToAnyCardAvailable,
  bookingIndexConvertedAnyCard,
  getBookingsChangeableToAnyCard,
  isRemoveRestrictionInProgress,
  isRemoveRestrictionFailed,
  getOrderId,
  getCustomerId,
  removeRestrictionReasons,
  isRemoveRestrictionSuccess,
};

export default selectors;
