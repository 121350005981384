import 'isomorphic-fetch';
import qs from 'qs';
import moment from 'moment';

import tokenStore from './tokenStore';
import { FetchError } from './error';
import { packageVersion as localVersion, isDevelopment } from './constants';

let acceptLanguage = 'en-GB';

export type HTTPMethod =
  | 'CONNECT'
  | 'DELETE'
  | 'GET'
  | 'HEAD'
  | 'OPTIONS'
  | 'PATCH'
  | 'POST'
  | 'PUT'
  | 'TRACE';

type DefaultOptions = {
  method: HTTPMethod;
  mode: RequestMode;
  redirect: RequestRedirect;
  cache: RequestCache;
};

type DefaultHeaders = {
  ConversationId: string;
  'Content-Type'?: string;
};

type RequestHeaders = {
  'Content-Type'?: string;
  Accept?: string;
  ConversationId?: string;
};

type Options = {
  method?: HTTPMethod;
  body?: any;
  params?: unknown;
  headers?: RequestHeaders;
};

export interface RequestObject extends DefaultOptions {
  body?: any;
  params?: unknown;
  headers: DefaultHeaders & RequestHeaders;
}

export const CUSTOM_HEADERS = {
  accept: {
    historyMediaType: 'application/vnd.contactcentre.compensation.history.v1+json',
    listMediaType: 'application/vnd.contactcentre.compensation.list.v1+json',
  },
};

const DEFAULT_OPTIONS: DefaultOptions = {
  method: 'GET',
  mode: 'cors',
  redirect: 'follow',
  cache: 'no-cache',
};

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
};

export function processResponse(response: Response) {
  if (response.status === 204) {
    return undefined;
  }

  const contentType = response.headers.get('Content-Type');

  if (
    contentType &&
    (contentType.toLowerCase().includes('application/json') ||
      contentType.toLowerCase().includes('+json'))
  ) {
    return response.json();
  }

  if (
    contentType
      ?.toLocaleLowerCase()
      .includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
  ) {
    return response;
  }

  if (
    contentType &&
    (contentType.toLowerCase().includes('application/pdf') ||
      contentType.toLowerCase().includes('image/jpeg'))
  ) {
    return response.blob();
  }

  return response.text();
}

export function handleResponse(
  response: Response,
  requestObj: RequestObject,
  signOutOnUnauthorized = true
) {
  const { status } = response;
  const { ConversationId } = requestObj.headers;

  if (status === 401 || status === 403) {
    const body = qs.parse(requestObj.body?.toString());
    if (body?.grant_type === 'refresh_token') {
      (window as any).newrelic.noticeError(
        new Error('Request to refresh access token returned Unauthorized'),
        {
          at: 'refresh-access-token',
          ConversationId: ConversationId,
        }
      );
    }

    if (signOutOnUnauthorized) {
      tokenStore.clearToken();
      if (window.location.pathname === '/login') {
        // We're already on the login page.
        return response;
      }

      const redirect = encodeURIComponent(
        window.location.pathname + (window.location.search || '')
      );
      return window.location.replace(
        window.location.pathname !== '/' ? `/login?redirect=${redirect}` : '/login'
      );
    }
  }

  if (!response.ok) {
    return response.json().then(
      (errors) => {
        // HACK: Added errors twice so that we can normalize the attribute name in the future.
        throw new FetchError({ validationErrors: errors, errors, status, ConversationId });
      },
      () => {
        throw new FetchError({ status, ConversationId });
      }
    );
  }

  const serverVersion = response.headers.get('X-Client-Version');
  const authRequest = /connect\/(token|logout)$/.test(response.url);

  // do not redirect to app update on auth requests to ensure the token is saved or cleared
  // do not redirect when server doesn't send the version header -> that's only sent by CC API,
  // do not redirect when version header is 0.0.0 (version that is set when in development - see server/src/constants.ts)
  if (
    !isDevelopment &&
    !authRequest &&
    serverVersion &&
    serverVersion !== '0.0.0' &&
    localVersion !== serverVersion
  ) {
    return window.location.replace('/app_update');
  }

  return processResponse(response);
}

export function handleNetworkError(error: Error, requestObj: RequestObject) {
  const { ConversationId } = requestObj.headers;
  throw new FetchError({ ...error, status: 'NETWORK_FAILURE', ConversationId });
}

export default function request<TResponse>(
  url: string,
  opts: Options = {},
  signOutOnUnauthorized = true,
  isFormDataContentType = false
): Promise<TResponse> {
  const fullUrl = opts.params ? `${url}?${qs.stringify(opts.params)}` : url;

  const headers = {
    ...(isFormDataContentType ? {} : DEFAULT_HEADERS),
    ...(opts.headers || {}),
    ConversationId:
      (opts.headers && opts.headers.ConversationId) ||
      `contactcentre-web-${moment.utc().format('YYYYMMDD.HHmmss.SSS')}`,
    'Accept-Language': acceptLanguage,
  };

  const body =
    typeof opts.body === 'object' && !isFormDataContentType ? JSON.stringify(opts.body) : opts.body;

  const requestObj: RequestObject = {
    ...DEFAULT_OPTIONS,
    ...opts,
    headers,
    body,
  };

  return fetch(fullUrl, requestObj).then(
    (response) => handleResponse(response, requestObj, signOutOnUnauthorized),
    (response) => handleNetworkError(response, requestObj)
  );
}

export const setAcceptLanguage = (language: string) => {
  acceptLanguage = language;
};
