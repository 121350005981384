import { StyleSheet } from 'aphrodite/no-important';
import {
  spacers,
  typography,
  palettes,
  borderRadius,
  fonts,
} from '@trainline/depot-web/css/themes/trainline';

const headerDefaultStyles = {
  color: palettes.dp_app_text_base,
  padding: 0,
  marginBottom: spacers.dp_s16,
  textTransform: 'none' as const,
};

export default StyleSheet.create({
  container: {
    marginBottom: spacers.dp_s16,
  },
  filtersResults: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bookingsCountText: {
    fontFamily: typography.dp_title2_font_family,
    fontSize: typography.dp_title2_font_size,
  },
  menu: {
    backgroundColor: palettes.dp_app_backdrop_base,
    color: palettes.dp_app_text_base,
    boxShadow: `0px 0px 4px ${palettes.dp_comp_box_shadow}, 0px 2px 6px ${palettes.dp_comp_box_shadow}`,
    borderRadius: borderRadius.dp_r4,
    padding: spacers.dp_s24,
    marginTop: spacers.dp_s12,
  },
  button: {
    display: 'inline-flex',
    alignItems: 'center',
    paddingRight: spacers.dp_s8, // removes the icon spacing
  },
  filtersButtonOpen: {
    borderColor: palettes.dp_app_selection_dark,
    boxShadow: `0 0 0 1px ${palettes.dp_app_selection_dark}`,
  },
  icon: {
    marginLeft: spacers.dp_s4,
  },
  menuItem: {
    padding: 0,
    marginBottom: spacers.dp_s12,
    fontSize: typography.dp_small_font_size_m,
    ':hover': {
      backgroundColor: palettes.dp_app_backdrop_base,
    },
    ':active': {
      color: palettes.dp_app_text_base,
    },
  },
  showBookingsButton: {
    marginTop: spacers.dp_s12,
  },
  appliedFiltersContainer: {
    marginTop: spacers.dp_s16,
    marginBottom: spacers.dp_s24,
  },
  appliedFilter: {
    color: palettes.dp_app_text_subdued,
    marginRight: spacers.dp_s32,
    display: 'inline-flex',
    alignItems: 'center',
  },
  removeButton: {
    width: '24px',
    height: '24px',
    borderWidth: 0,
  },
  menuHeader: {
    ...headerDefaultStyles,
    fontSize: typography.dp_body_font_size,
    fontWeight: fonts.dp_font_weight_bold,
  },
  menuSubHeader: {
    ...headerDefaultStyles,
    fontSize: typography.dp_small_font_size_m,
    fontWeight: fonts.dp_font_weight_semibold,
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    gap: spacers.dp_s32,
  },
  bookingStatusFilters: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
});
