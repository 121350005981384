import { defineMessages } from 'react-intl';

export default defineMessages({
  passwordSuccessfullyReset: {
    id: 'ResetPasswordSuccess.passwordSuccessfullyReset',
    defaultMessage: 'Password successfully reset',
  },
  agentUsername: {
    id: 'ResetPasswordSuccess.agentUsername',
    defaultMessage: 'Agent username:',
  },
  temporaryPassword: {
    id: 'ResetPasswordSuccess.temporaryPassword',
    defaultMessage: 'Temporary password:',
  },
  backToUser: {
    id: 'ResetPasswordSuccess.backToUser',
    defaultMessage: 'Back to user',
  },
  backToSearch: {
    id: 'ResetPasswordSuccess.backToSearch',
    defaultMessage: 'Search for another user',
  },
});
