import React from 'react';

import TracsOnAccountPage from '@contactcentre-web/tracs-on-account/TracsOnAccount';
import PageContainer from '@contactcentre-web/common/PageContainer/PageContainer';
import ErrorBoundary from '@contactcentre-web/common/ErrorBoundary/ErrorBoundary';

const VirtualTerminalPage = () => (
  <PageContainer>
    <ErrorBoundary>
      <TracsOnAccountPage />
    </ErrorBoundary>
  </PageContainer>
);

export default VirtualTerminalPage;
