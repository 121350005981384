import { StyleSheet } from 'aphrodite/no-important';
import {
  borderRadius,
  fonts,
  palettes,
  spacers,
  typography,
} from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  wrapper: {
    border: `1px solid ${palettes.dp_app_border_dark}`,
    borderRadius: borderRadius.dp_r4,
    padding: spacers.dp_s16,
  },
  anchor: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: palettes.dp_app_text_base,
    fontWeight: fonts.dp_font_weight_bold,
    fontSize: typography.dp_body_font_size,
    letterSpacing: typography.dp_body_letter_spacing,
    lineHeight: typography.dp_body_line_height,
    width: '100%',
  },
  content: {
    opacity: 0,
    transition: 'opacity .2s linear',
    display: 'none',
    marginTop: spacers.dp_s24,
  },
  openedContent: {
    opacity: 1,
    display: 'block',
  },
});
