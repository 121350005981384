import React, { Dispatch, FunctionComponent, useRef, useState } from 'react';
import { css } from 'aphrodite/no-important';
import { connect } from 'react-redux';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import { Heading } from '@trainline/depot-web';
import { FormattedMessage } from 'react-intl';

import localStorage, { LocalStorageKeys } from '@contactcentre-web/utils/localStorage';
import Logo from '@contactcentre-web/common/Logo/Logo';
import type State from '@contactcentre-web/redux-common/types/State';
import type Action from '@contactcentre-web/redux-common/types/Action';

import { selectLoggedIn, nextAction } from '../../redux/selectors';
import { actions, NextAction } from '../../redux/module';

import LoginForm, { LoginData, LOGIN_FORM_ID } from './LoginForm';
import styles from './styles';
import SSOForm, { SSOData } from './SSOForm';
import messages from './messages';

interface StateProps {
  isLoggedIn: boolean;
  nextAction?: NextAction;
}

interface DispatchProps {
  onLoginFormSubmit: (data: LoginData, redirect: string | null) => void;
  authFlowForwarding: () => void;
}

type Props = StateProps & DispatchProps;

export const Login: FunctionComponent<Props> = ({
  onLoginFormSubmit,
  isLoggedIn,
  nextAction,
  authFlowForwarding,
}) => {
  const history = useHistory();
  const { search } = useLocation();
  const redirect = new URLSearchParams(search).get('redirect');
  const replaceRef = useRef(history.replace);
  const authFlowForwardingRef = useRef(authFlowForwarding);
  const newLoginFlow = true;
  const isSSOLoginPreferred = !!localStorage.get(LocalStorageKeys.SSO_LOGIN_PREFERRED);
  const [isSSOFormEnabled, setIsSSOFormEnabled] = useState(isSSOLoginPreferred);

  React.useEffect(() => {
    if (nextAction) {
      if (authFlowForwardingRef.current) authFlowForwardingRef.current();
      replaceRef.current(`${nextAction.action}`, nextAction.context);
    }
  }, [nextAction, replaceRef, authFlowForwardingRef]);

  if (isLoggedIn) {
    return <Redirect to={redirect ? decodeURIComponent(redirect) : '/'} />;
  }

  return (
    <div className={css(styles.loginContainer)}>
      <div className={css(styles.headerDecoration)} />
      <Logo color="inverted" />
      <div className={css(styles.heading)}>
        <Heading typeStyle="hero" as="h1" color="inverted">
          <FormattedMessage {...messages.signInHeader} />
        </Heading>
      </div>
      <div className={css(styles.formContainer)}>
        {isSSOFormEnabled || newLoginFlow ? (
          <SSOForm
            onViaUsernameClick={() => setIsSSOFormEnabled(false)}
            isNewLoginFlow={newLoginFlow}
            onSubmit={(form: SSOData) => {
              localStorage.set(LocalStorageKeys.SSO_LOGIN_PREFERRED, true);
              window.open(`/sso/${form.company}`, '_self');
            }}
          />
        ) : (
          <LoginForm
            onSubmit={(form: LoginData) => {
              localStorage.set(LocalStorageKeys.SSO_LOGIN_PREFERRED, false);
              onLoginFormSubmit(form, redirect);
            }}
            onSSOClick={() => setIsSSOFormEnabled(true)}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  isLoggedIn: selectLoggedIn(state),
  nextAction: nextAction(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  onLoginFormSubmit: (formData: LoginData, redirect: string | null) =>
    dispatch(actions.loginRequested(formData, LOGIN_FORM_ID, redirect)),
  authFlowForwarding: () => dispatch(actions.authFlowForwarding()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
