import React, { useState } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { useParams } from 'react-router-dom';
import { css } from 'aphrodite/no-important';
import { StatusMessage } from '@trainline/depot-web';
import { useQueryClient } from 'react-query';

import FormattedDateInLondonTz from '@contactcentre-web/common/FormattedDateInLondonTz';
import Button from '@contactcentre-web/common/Button';
import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from '@contactcentre-web/common/Modal';
import Link from '@contactcentre-web/common/Link';
import {
  useDeleteCustomerPassenger,
  Passenger,
} from '@contactcentre-web/hooks/api/useCustomerPassengers';

import messages from './messages';
import styles from './styles';

interface Props {
  passenger: Passenger;
}

const getErrorMessageCode = (code: string, isEnd?: boolean): MessageDescriptor | null => {
  const idx = Object.getOwnPropertyNames(messages).indexOf(`${code}ErrorMessage`);
  return idx > -1
    ? Object.values(messages)[idx]
    : !isEnd
    ? getErrorMessageCode(`generic`, true)
    : null;
};

const DeletePassenger = ({ passenger }: Props) => {
  const { firstName, lastName, email, dateOfBirth, phoneNumber, phoneCountryCode, passengerId } =
    passenger;

  const { customerId } = useParams<{ customerId: string }>();
  const [isVisible, setIsVisible] = useState(false);
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error } = useDeleteCustomerPassenger(
    customerId,
    passengerId,
    {
      onSuccess: () => {
        setIsVisible(false);
        queryClient.invalidateQueries('customerPassengers');
      },
    }
  );

  const errorMessage = error?.errors?.errors[0].code.split('.')[1] || 'generic';

  return (
    <>
      <div className={css(styles.deletePassenger)}>
        <Link
          linkType="action"
          onClick={() => setIsVisible(true)}
          testId="delete-passenger-open-modal"
        >
          <FormattedMessage {...messages.deletePassengerDel} />
        </Link>
      </div>
      {isVisible && (
        <Modal onClose={() => setIsVisible(false)} testId="delete-passenger-form-modal">
          <ModalHeader>
            <ModalTitle>
              <FormattedMessage {...messages.deletePassengerDel} />
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            {isError && (
              <div className={css(styles.errorContainer)}>
                <StatusMessage status="negative">
                  <FormattedMessage {...getErrorMessageCode(errorMessage)} />
                </StatusMessage>
              </div>
            )}
            <div className={css(styles.passengerName)} data-test="passenger-name">
              {firstName} {lastName}
            </div>
            <div className={css(styles.textLabel)} data-test="passenger-email">
              {email}
            </div>
            {dateOfBirth && (
              <div className={css(styles.textLabel)} data-test="passenger-date-of-birth">
                <FormattedMessage
                  {...messages.passengerDOB}
                  values={{
                    dob: <FormattedDateInLondonTz value={dateOfBirth} />,
                  }}
                />
              </div>
            )}
            {phoneNumber && phoneCountryCode && (
              <div className={css(styles.textLabel)} data-test="passenger-phone-number">
                <FormattedMessage
                  {...messages.passengerContactNumber}
                  values={{
                    number: `${phoneCountryCode} ${phoneNumber}`,
                  }}
                />
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              variant="primary"
              size="large"
              testId="submit-passenger-delete"
              loading={isLoading}
              onClick={() => mutate()}
            >
              <FormattedMessage {...messages.deletePassengerDelButton} />
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default DeletePassenger;
