import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  loaderContainer: {
    padding: spacers.dp_s24,
  },
  alert: {
    marginBottom: spacers.dp_s16,
  },
});
