import React from 'react';
import { css } from 'aphrodite/no-important';

import Timetable from '@contactcentre-web/redux-common/types/Timetable';

import CallingPoints from '../CallingPoints/CallingPoints';
import DisruptionReasons from '../DisruptionReasons/DisruptionReasons';

import styles from './styles';

export interface Props {
  timetable: Timetable;
}

const DisruptionInfo = ({ timetable }: Props) => (
  <div className={css(styles.container)}>
    <CallingPoints timetable={timetable} />
    <DisruptionReasons
      delayReasons={timetable.delayReasons}
      cancellationReasons={timetable.cancellationReasons}
      status={timetable.status}
    />
  </div>
);

export default DisruptionInfo;
