import { spacers } from '@trainline/depot-web/css/themes/trainline';
import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: spacers.dp_s16,
    width: '100%',
  },
});
