import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'OrderNotes.header',
    defaultMessage: 'Log Call',
  },
  UserHaveNoSite: {
    id: 'OrderNotes.UserHaveNoSite',
    defaultMessage: 'You have no site assigned - please speak to a Team Leader.',
  },
  callQueuesGenericError: {
    id: 'OrderNotes.callQueuesGenericError',
    defaultMessage: 'Unable to get call queues, try again.',
  },
});
