import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'virtualTerminalApprovalRejection.header',
    defaultMessage: 'Reject compensation?',
  },
  body: {
    id: 'virtualTerminalApprovalRejection.body',
    defaultMessage:
      'Are you sure you want to reject the requested compensation of <strong>{amount} {currencyCode}</strong>? Please note that the customer is likely to have been assured they will receive a refund.',
  },
  reject: {
    id: 'virtualTerminalApprovalRejection.reject',
    defaultMessage: 'Reject',
  },
  cancel: {
    id: 'virtualTerminalApprovalRejection.cancel',
    defaultMessage: 'Cancel',
  },
});
