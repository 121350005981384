export default {
  head: {
    defaultTitle: 'Contact Centre',
    titleTemplate: '%s | Contact Centre',
    meta: [
      {
        name: 'description',
        content: 'Trainline contact centre application for managing customer rail bookings',
      },
    ],
    link: [
      {
        href: '/apple-touch-icon.png',
        rel: 'apple-touch-icon',
        sizes: '60x60',
      },
      {
        href: '/favicon-48x48.png',
        rel: 'icon',
        type: 'image/png',
        sizes: '48x48',
      },
      {
        href: '/favicon-32x32.png',
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
      },
      {
        href: '/favicon-16x16.png',
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
      },
      { href: '/manifest.json', rel: 'manifest' },
      { href: '/safari-pinned-tab.svg', rel: 'mask-icon' },
      {
        href: 'https://fonts.googleapis.com/css?family=Roboto',
        rel: 'stylesheet',
      },
    ],
  },
};
