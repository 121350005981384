import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { StatusMessage } from '@trainline/depot-web';

import { Customer } from '@contactcentre-web/redux-common/types/Customer';
import Action from '@contactcentre-web/redux-common/types/Action';
import type State from '@contactcentre-web/redux-common/types/State';

import { actions, SearchRequest } from '../../module';
import * as selectors from '../../selectors';
import EmailSearchForm from '../EmailSearchForm/EmailSearchForm';

import messages from './messages';

interface StateProps {
  searchInProgress: boolean;
  customers: Array<Customer>;
  originalEmail: string;
  canMove: boolean;
  moveFailed: boolean;
  moveInProgress: boolean;
  customerId: string;
  orderId: string;
}

interface DispatchProps {
  reset: () => void;
  searchEmail: (values: SearchRequest) => void;
  moveOrder: (
    customerId: string,
    orderReference: string,
    orderId: string,
    newCustomerId: string
  ) => void;
}

interface Props extends StateProps, DispatchProps {
  orderReference: string;
}

export const EmailSearch = ({
  reset,
  searchEmail,
  searchInProgress,
  originalEmail,
  orderReference,
  orderId,
  customerId,
  moveOrder,
  customers,
  canMove,
  moveFailed,
  moveInProgress,
}: Props) => {
  React.useEffect(() => {
    reset();
  }, []);

  return (
    <>
      {!moveFailed && (
        <StatusMessage status="info">
          <FormattedMessage
            {...messages.notice}
            values={{
              originalEmail,
              orderReference,
              b: (msg: string) => <b>{msg}</b>,
            }}
            data-testId="move-order-notice"
          />
        </StatusMessage>
      )}
      {moveFailed && (
        <StatusMessage status="negative">
          <FormattedMessage
            {...messages.move_failed}
            values={{
              originalEmail,
              orderReference,
              b: (msg: string) => <b>{msg}</b>,
            }}
            data-testId="move-order-failed-banner"
          />
        </StatusMessage>
      )}
      <EmailSearchForm
        onSubmitForm={(values: SearchRequest) =>
          canMove
            ? moveOrder(customerId, orderReference, orderId, customers[0].id)
            : searchEmail(values)
        }
        inProgress={searchInProgress || moveInProgress}
        submitLabel={
          canMove ? (
            <FormattedMessage {...messages.button_confirm} />
          ) : (
            <FormattedMessage {...messages.button_search} />
          )
        }
        originalEmail={originalEmail}
        buttonTestId={canMove ? 'confirm-move-button' : 'search-account-button'}
      />
    </>
  );
};

const mapStateToProps = (state: State) => ({
  searchInProgress: selectors.isSearchInProgress(state),
  customers: selectors.customers(state),
  originalEmail: selectors.originalEmail(state),
  canMove: selectors.canMove(state),
  moveFailed: selectors.hasMoveFailed(state),
  moveInProgress: selectors.isMoveInProgress(state),
  customerId: selectors.customerId(state),
  orderId: selectors.orderId(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  reset: () => dispatch(actions.reset()),
  searchEmail: ({ email }: SearchRequest) => dispatch(actions.searchAttempt(email)),
  moveOrder: (customerId: string, orderReference: string, orderId: string, newCustomerId: string) =>
    dispatch(actions.moveAttempt(customerId, orderReference, orderId, newCustomerId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailSearch);
