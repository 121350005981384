import { defineMessages } from 'react-intl';

export default defineMessages({
  totalToRefund: {
    id: 'SameDayVoidTotal.totalToRefund',
    defaultMessage: 'Total to refund',
  },
  calculatingAmount: {
    id: 'SameDayVoidTotal.calculatingAmount',
    defaultMessage: 'calculating amount',
  },
});
