import { defineMessages } from 'react-intl';

export default defineMessages({
  surveyLabel: {
    id: 'OrderNotes.SurveyChoice.surveyLabel',
    defaultMessage: 'Send customer survey',
  },
  surveyReasonLabel: {
    id: 'OrderNotes.SurveyChoice.surveyReasonLabel',
    defaultMessage: 'Reason for not submitting survey',
  },
  selectSurveyReason: {
    id: 'OrderNotes.SurveyChoice.selectSurveyReason',
    defaultMessage: 'Select a Survey Reason',
  },
  surveyChoiceRequired: {
    id: 'OrderNotes.SurveyChoice.surveyChoiceRequired',
    defaultMessage: 'Please provide a survey reason',
  },
  yesLabel: {
    id: 'OrderNotes.SurveyChoice.yesLabel',
    defaultMessage: 'Yes',
  },
  noLabel: {
    id: 'OrderNotes.SurveyChoice.noLabel',
    defaultMessage: 'No',
  },
});
