import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import Select from '@contactcentre-web/common/Select';

import styles from '../OrderNoteForm/styles';

import messages from './messages';

const mapOptions = ({ queueId, name }) => ({ value: queueId, label: name });

export const CallCrmQueue = ({ callQueues = [] }) => {
  const queueOptions = callQueues.map(mapOptions);

  return (
    <>
      <FormattedMessage {...messages.queueLabel}>
        {(value) => <span className={css(styles.fieldLabel)}>{value}</span>}
      </FormattedMessage>
      <Select
        name="callCrmQueueId"
        placeholder={<FormattedMessage {...messages.selectQueue} />}
        options={queueOptions}
        messages={messages}
      />
    </>
  );
};

CallCrmQueue.propTypes = {
  callQueues: PropTypes.arrayOf(PropTypes.object),
};

export default CallCrmQueue;
