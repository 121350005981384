import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { IconUserAccountPersonal } from '@trainline/depot-web';

import { selectors } from '../../module';

import styles from './styles';
import messages from './messages';

export const StatusInfo = ({ isGuest }) => (
  <span className={css(styles.userAccountWithIcon)}>
    <IconUserAccountPersonal />
    <FormattedMessage {...(isGuest ? messages.guest : messages.registered)}>
      {(content) => <span className={css(styles.userAccount)}>{content}</span>}
    </FormattedMessage>
  </span>
);

StatusInfo.propTypes = {
  isGuest: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const customer = selectors.getSelectedCustomer(state.customer);
  return {
    isGuest: customer === null || customer.isImplicitlyRegistered,
  };
};

const connected = connect(mapStateToProps)(StatusInfo);
connected.displayName = 'StatusInfo';

export default connected;
