import React, { Dispatch, FunctionComponent } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Heading } from '@trainline/depot-web';

import type Action from '@contactcentre-web/redux-common/types/Action';
import type State from '@contactcentre-web/redux-common/types/State';

import { actions as userActions, NextAction } from '../../redux/module';
import { nextAction } from '../../redux/selectors';

import SelectUILanguageForm from './SelectUILanguageForm';
import styles from './styles';
import messages from './messages';

interface StateProps {
  nextAction?: NextAction;
}

interface DispatchProps {
  authFlowForwarding: () => void;
}

type Props = StateProps & DispatchProps;

export const SelectUILanguage: FunctionComponent<Props> = ({ authFlowForwarding, nextAction }) => {
  const history = useHistory();
  const authFlowForwardingRef = React.useRef(authFlowForwarding);
  const replaceRef = React.useRef(history.replace);

  React.useEffect(() => {
    if (nextAction) {
      if (authFlowForwardingRef.current) {
        authFlowForwardingRef.current();
      }
      replaceRef.current(nextAction.action, nextAction.context);
    }
  }, [nextAction, authFlowForwarding, replaceRef]);

  return (
    <section>
      <div className={css(styles.headerBar)} />
      <div className={css(styles.container)}>
        <Heading typeStyle="title1" as="h1" color="base">
          <FormattedMessage {...messages.title} />
        </Heading>
        <SelectUILanguageForm />
      </div>
    </section>
  );
};

const mapStateToProps = (state: State) => ({
  nextAction: nextAction(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  authFlowForwarding: () => dispatch(userActions.authFlowForwarding()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectUILanguage);
