import { StyleSheet } from 'aphrodite/no-important';
import { spacers, borderRadius, fonts } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    borderRadius: borderRadius.dp_r4,
    border: `1px solid ${colors.gray.alpha2}`,
    marginBottom: spacers.dp_s32,
    boxShadow: '0 8px 12px 0 rgba(33, 49, 77, 0.1)',
  },
  body: {
    display: 'flex',
    padding: `${spacers.dp_s16} ${spacers.dp_s32}`,
    fontWeight: fonts.dp_font_weight_bold,
  },
});
