import React from 'react';

import { Modal, ModalHeader, ModalTitle, ModalBody } from '@contactcentre-web/common/Modal';
import ConditionsOfUse from '@contactcentre-web/redux-common/types/ConditionsOfUse';

import styles from './styles';

interface Props {
  toggleModal: () => void;
  conditionsOfUse: ConditionsOfUse;
}

const TermsAndConditionsModal = ({
  toggleModal,
  conditionsOfUse: { name, description },
}: Props) => (
  <Modal onClose={toggleModal}>
    <ModalHeader>
      <ModalTitle>{name}</ModalTitle>
    </ModalHeader>
    <ModalBody styleSheet={styles.content}>
      <p>{description}</p>
    </ModalBody>
  </Modal>
);

export default TermsAndConditionsModal;
