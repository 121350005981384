import { defineMessages } from 'react-intl';

export default defineMessages({
  bookings: {
    id: 'ListSummary.bookings',
    defaultMessage: 'End of {total, plural, one {# booking} other {# bookings}} for {name}',
  },
  notes: {
    id: 'ListSummary.notes',
    defaultMessage: 'End of {total, plural, one {# note} other {# notes}}',
  },
  results: {
    id: 'ListSummary.results',
    defaultMessage: 'End of {total, plural, one {# result} other {# results}} for {name}',
  },
});
