import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedTime } from 'react-intl';

import {
  DepartureAndArrivalStatus,
  CallingType,
} from '@contactcentre-web/redux-common/types/Timetable';

import styles from './styles';

export interface Props {
  scheduledTime?: Date;
  realTime?: Date;
  status?: DepartureAndArrivalStatus;
  isJourneyCancelled: boolean;
  callingType: CallingType;
}

const CallingPointsItemTime = ({
  scheduledTime,
  realTime,
  status,
  isJourneyCancelled,
  callingType,
}: Props) => {
  const displayRealTime =
    (status === DepartureAndArrivalStatus.Early || status === DepartureAndArrivalStatus.Late) &&
    realTime &&
    !isJourneyCancelled;
  const isOriginOrDestinationCallingPoint =
    callingType === CallingType.Origin || callingType === CallingType.Destination;
  const isCallingPointCancelled = status === DepartureAndArrivalStatus.Cancelled;

  return (
    <div className={css(styles.callingPointTimeContainer)}>
      <div
        className={css(
          styles.callingPointTime,
          isOriginOrDestinationCallingPoint && styles.originOrDestinationCallingPointTime,
          (displayRealTime || isCallingPointCancelled) && styles.callingPointTimeStriked
        )}
        data-test="scheduled-time"
      >
        {scheduledTime ? (
          <FormattedTime value={scheduledTime} />
        ) : (
          <div className={css(styles.callingPointTimeEmpty)}>&#8212;</div>
        )}
      </div>
      {displayRealTime && (
        <div className={css(styles.callingPointTimeDelayed)} data-test="real-time">
          <FormattedTime value={realTime} />
        </div>
      )}
    </div>
  );
};

export default CallingPointsItemTime;
