import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import styles from './styles';

interface Props {
  type: 'bookings' | 'notes' | 'results';
  total: number;
  name?: string;
}

const ListSummary = ({ total, type, name }: Props) => (
  <p className={css(styles.totalMessage)}>
    <FormattedMessage {...messages[type]} values={{ total, name }} />
  </p>
);

export default ListSummary;
