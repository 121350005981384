import { handleActions } from 'redux-actions';

import { changeCTR, loadStates } from './module';

const initialState = {
  loadStatus: loadStates.NONE,
  bookingId: null,
  reasonCode: null,
  removeRestrictionReasons: [],
};

const reducer = handleActions(
  {
    [changeCTR.REMOVE_RESTRICTION_RESET]: () => initialState,
    [changeCTR.REMOVE_RESTRICTION_NONE]: (state) => ({
      ...state,
      loadStatus: loadStates.NONE,
    }),
    [changeCTR.REMOVE_RESTRICTION_ATTEMPT]: (state) => ({
      ...state,
      loadStatus: loadStates.INPROGRESS,
    }),
    [changeCTR.REMOVE_RESTRICTION_SUCCESS]: (state, { payload: { bookingIndex } }) => ({
      ...state,
      loadStatus: loadStates.SUCCESS,
      bookingIndex,
    }),
    [changeCTR.REMOVE_RESTRICTION_FAILED]: (state) => ({
      ...state,
      loadStatus: loadStates.ERROR,
    }),
    [changeCTR.REMOVE_RESTRICTION_REASONS_LOAD]: (state) => ({
      ...state,
      loadStatus: loadStates.INPROGRESS,
    }),
    [changeCTR.REMOVE_RESTRICTION_REASONS_LOAD_SUCCESS]: (state, { payload }) => ({
      ...state,
      loadStatus: loadStates.NONE,
      removeRestrictionReasons: payload,
    }),
    [changeCTR.REMOVE_RESTRICTION_REASONS_LOAD_FAILED]: (state) => ({
      ...state,
      loadStatus: loadStates.ERROR,
    }),
    [changeCTR.REMOVE_RESTRICTION_REASONS_LOAD]: (state) => ({
      ...state,
      loadStatus: loadStates.INPROGRESS,
    }),
    [changeCTR.REMOVE_RESTRICTION_REASONS_LOAD_SUCCESS]: (state, { payload }) => ({
      ...state,
      loadStatus: loadStates.NONE,
      removeRestrictionReasons: payload,
    }),
    [changeCTR.REMOVE_RESTRICTION_REASONS_LOAD_FAILED]: (state) => ({
      ...state,
      loadStatus: loadStates.ERROR,
    }),
  },
  initialState
);
export default reducer;
