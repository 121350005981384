import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';

import { Modal, ModalHeader, ModalTitle, ModalBody } from '@contactcentre-web/common/Modal';
import { addPageAction } from '@contactcentre-web/utils/tracker';
import Link from '@contactcentre-web/common/Link';

import styles from './styles';
import messages from './messages';

const FareConditionsModal = ({ ticket, toggleModal }) => (
  <Modal onClose={toggleModal}>
    <ModalHeader>
      <ModalTitle>{ticket.type}</ModalTitle>
    </ModalHeader>
    <ModalBody>
      {ticket.fareConditions.map(({ name: title, description }, index) => (
        <div key={`${title}-${index}`} className={css(styles.fareCondition)}>
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      ))}
    </ModalBody>
  </Modal>
);

FareConditionsModal.propTypes = {
  ticket: PropTypes.object,
  toggleModal: PropTypes.func,
};

export const TicketType = ({ ticket }) => {
  const [showFareConditionsModal, toggleModal] = React.useState(false);

  return (
    <>
      {showFareConditionsModal && (
        <FareConditionsModal
          ticket={ticket}
          toggleModal={() =>
            toggleModal((prevShowFareConditionsModal) => !prevShowFareConditionsModal)
          }
        />
      )}
      {ticket.type ? (
        <Link
          linkType="action"
          testId="modal-open"
          onClick={() => {
            addPageAction('click-ticket-type');
            toggleModal((prevShowFareConditionsModal) => !prevShowFareConditionsModal);
          }}
        >
          {ticket.type}
        </Link>
      ) : (
        <FormattedMessage {...messages.notAvailable} />
      )}
    </>
  );
};

TicketType.propTypes = {
  ticket: PropTypes.object,
};

export default React.memo(TicketType);
