import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';

import styles from './styles';
import messages from './messages';

const STATUS_STYLES = {
  issuing: 'statusIssuing',
  issued: 'statusIssued',
  superseded: 'statusSuperseded',
  others: 'statusOthers',
};

const VendorStatus = ({ status, highlighting = 'on' }) => {
  if (!status) {
    return <span>-</span>;
  }

  const message = messages[status.toLowerCase()];

  return (
    <span
      className={css(
        styles[STATUS_STYLES[status.toLowerCase()] || STATUS_STYLES.others],
        highlighting === 'off' ? styles.noColor : undefined
      )}
      data-test="BookingFulfilmentStatusRefundScreen"
    >
      {message && <FormattedMessage {...message} />}
      {!message && <>{status.toUpperCase()}</>}
    </span>
  );
};

VendorStatus.propTypes = {
  status: PropTypes.string,
  highlighting: PropTypes.oneOf(['on', 'off']),
};

export default VendorStatus;
