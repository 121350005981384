import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  modalTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: spacers.dp_s16,
  },
});
