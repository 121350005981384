import { StyleSheet } from 'aphrodite/no-important';

const cubicEasing = 'cubic-bezier(0.075, 0.820, 0.165, 1.000)';

export default StyleSheet.create({
  transform: {
    transition: `transform 0.5s ${cubicEasing}`,
    willChange: 'transform',
  },
  visibilityVisible: {
    transition: `opacity 1s ${cubicEasing}`,
    willChange: 'visibility, opacity',
    visibility: 'visible',
    opacity: 1,
  },
  visibilityHidden: {
    willChange: 'visibility, opacity',
    visibility: 'hidden',
    opacity: 0,
  },
  borderColor: {
    transition: `border-color 1s ${cubicEasing}`,
  },
});
