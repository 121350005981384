import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { StatusMessage } from '@trainline/depot-web';

import Link from '@contactcentre-web/common/Link';

import styles from './styles';
import messages from './messages';

const renderLinkFrom = (link) => {
  if (!link) return messages.afterSaleSystemLinkPlaceholder.defaultMessage;

  try {
    const hostName = new URL(link).hostname;
    return (
      <Link linkType="external" href={link}>
        {hostName}
      </Link>
    );
  } catch (e) {
    return link;
  }
};

const AfterSaleSystem = (afterSaleSystem) => {
  const {
    afterSaleSystem: { afterSaleSystemUrl, vendorName },
  } = afterSaleSystem;
  const renderedLink = renderLinkFrom(afterSaleSystemUrl);

  return (
    <div className={css(styles.container)}>
      <StatusMessage status="info">
        <FormattedMessage
          {...messages.afterSaleSystemAdditionalOptionAvailable}
          values={{
            vendorName: vendorName || messages.afterSaleSystemVendorNamePlaceholder.defaultMessage,
            afterSaleSystemUrl: renderedLink,
          }}
        />
      </StatusMessage>
    </div>
  );
};

AfterSaleSystem.propTypes = {
  afterSaleSystem: PropTypes.shape({
    vendorName: PropTypes.string,
    afterSaleSystemUrl: PropTypes.string,
  }).isRequired,
};

export default AfterSaleSystem;
