import { StyleSheet } from 'aphrodite/no-important';
import {
  borderRadius,
  palettes,
  spacers,
  typography,
  fonts,
} from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  content: {
    position: 'relative',
    width: '100%',
    marginTop: spacers.dp_s32,
  },
  headerContainer: {
    position: 'relative',
    width: '70%',
    marginTop: spacers.dp_s32,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    ':nth-child(1n) sup': {
      fontSize: typography.dp_small_font_size,
    },
  },
  messageHelper: {
    marginTop: spacers.dp_s8,
  },
  button: {
    marginBottom: spacers.dp_s32,
    display: 'inline-flex',
    alignItems: 'center',
  },
  arrowBack: {
    marginRight: spacers.dp_s4,
  },
  paymentForm: {
    border: `solid 1px ${palettes.dp_app_border_base}`,
    borderRadius: borderRadius.dp_r4,
    background: palettes.dp_app_info_darkest,
    padding: spacers.dp_s32,
    position: 'absolute',
    top: 0,
    right: 0,
    width: `calc(30% - ${spacers.dp_s12})`,
  },
  linkAsButton: {
    textDecoration: 'none',
    padding: `${spacers.dp_s8} ${spacers.dp_s16}`,
    fontSize: typography.dp_body_font_size,
    fontWeight: fonts.dp_font_weight_bold,
    lineHeight: typography.dp_body_line_height,
    letterSpacing: typography.dp_large_letter_spacing,
    backgroundColor: palettes.dp_comp_button_tertiary_background,
    borderRadius: borderRadius.dp_r4,
    color: palettes.dp_app_text_base,
    cursor: 'pointer',
    border: '1px solid transparent',
    borderColor: palettes.dp_app_border_base,
    ':hover': {
      backgroundColor: palettes.dp_comp_button_tertiary_background_hover,
      color: palettes.dp_app_text_base,
    },
    ':active': {
      backgroundColor: palettes.dp_comp_button_tertiary_background_pressed,
      color: palettes.dp_app_text_base,
    },
  },
});
