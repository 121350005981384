import { defineMessages } from 'react-intl';

export default defineMessages({
  requestRefund: {
    id: 'BookingForm.requestRefund',
    defaultMessage: 'Request refund(s)',
  },
  processRefund: {
    id: 'BookingForm.processRefund',
    defaultMessage: 'Process refund(s)',
  },
  infoRefundSubmittedMessage: {
    id: 'BookingForm.infoRefundSubmittedMessage',
    defaultMessage:
      'The refund has been submitted. For additional details, <link>go to order history</link>.',
  },
  infoRefundSubmittedMessageWithRefresh: {
    id: 'BookingForm.infoRefundSubmittedMessageWithRefresh',
    defaultMessage:
      'The refund has been submitted – please <a>refresh page</a> to update booking – this may take a few seconds to process. For additional details, <link>go to order history</link>.',
  },
  quoteLoadError: {
    id: 'BookingForm.quoteLoadError',
    defaultMessage:
      'There was an error retrieving the refund quote for this journey. Try refreshing the screen ...',
  },
  error: {
    id: 'BookingForm.error',
    defaultMessage:
      'Oops, something went wrong and the refund request has failed. Please try again or refresh the page.',
  },
  paperTicketPost: {
    id: 'BookingForm.paperTicketPost',
    defaultMessage:
      'If it is a paper ticket then please advise the customer their ticket(s) will need posted back to Trainline Refunds, PO Box 23972, Edinburgh, EH3 5DA and from receiving the ticket(s) a refund will return back to the original payment method within 28 working days.',
  },
  customerAdvisory: {
    id: 'BookingForm.customerAdvisory',
    defaultMessage:
      'Please advise the customer their ticket(s) will need posted back to Trainline Refunds, PO Box 23972, Edinburgh, EH3 5DA and from receiving the ticket(s) a refund will return back to the original payment method within 28 working days.',
  },
  post: {
    id: 'BookingForm.post',
    defaultMessage:
      'Please advise the customer their ticket(s) will need posted back to Trainline Refunds, PO Box 23972, Edinburgh, EH3 5DA and from receiving the ticket(s) a refund will return back to the original payment method within 28 working days.',
  },
  collectpost: {
    id: 'BookingForm.collectpost',
    defaultMessage:
      'Please advise the customer their ticket(s) will need posted back to Trainline Refunds, PO Box 23972, Edinburgh, EH3 5DA and from receiving the ticket(s) a refund will return back to the original payment method within 28 working days.',
  },
  automatic: {
    id: 'BookingForm.automatic',
    defaultMessage:
      'The refund has been processed automatically back into the account used to pay for the tickets.',
  },
  alreadyvoided: {
    id: 'BookingForm.alreadyvoided',
    defaultMessage:
      'The refund has been processed back into the account used to pay for the tickets.',
  },
  deletefromdevice: {
    id: 'BookingForm.deletefromdevice',
    defaultMessage:
      'Before the refund can be processed, please advise the customer that they must remove the ticket from their device or reset the barcode in Support Central.',
  },
  deleteFromDevicePreProcess: {
    id: 'BookingForm.deleteFromDevicePreProcess',
    defaultMessage:
      'Inform customer to remove their ticket from their phone using the "Move tickets" option to receive their refund.',
  },
  unknown: {
    id: 'BookingForm.unknown',
    defaultMessage: 'Advise customer how to return tickets.',
  },
  someRefundsNotPossible: {
    id: 'BookingForm.someRefundsNotPossible',
    defaultMessage: 'Some refunds are not possible due to fare or railcard restrictions',
  },
  recalculate: {
    id: 'BookingForm.recalculate',
    defaultMessage: 'Recalculate',
  },
  TcRefundCtrMultipleFarePassengersWarn: {
    id: 'BookingForm.TcRefundCtrMultipleFarePassengersWarn',
    defaultMessage: 'Customer should collect all tickets and post unused ones back to Trainline.',
  },
  TcRefundMobileMultipleFarePassengersWarn: {
    id: 'BookingForm.TcRefundMobileMultipleFarePassengersWarn',
    defaultMessage: 'Mobile Tickets cannot be activated after any refund has been requested',
  },
  voucherError: {
    id: 'BookingForm.voucherError',
    defaultMessage:
      'This booking was purchased using <voucher type here> vouchers and is not refundable through Contact Centre',
  },
  includesTgvMaxJourney: {
    id: 'BookingForm.includesTgvMaxJourney',
    defaultMessage:
      'This booking includes a TGVmax ticket. Refunding these tickets will cancel your booking and reimburse your TGVmax account. This cannot be undone.',
  },
  empty: {
    id: 'BookingForm.empty',
    defaultMessage: 'Nothing to refund for this journey ...',
  },
  warning_invalidproductcombination: {
    id: 'BookingForm.warning_invalidiproductcombination',
    defaultMessage:
      'The selected passengers from the inbound and outbound journeys must match to refund both journeys.',
  },
  warning_paymentdetailsrefundnotsupported: {
    id: 'BookingForm.warning_paymentdetailsrefundnotsupported',
    defaultMessage: 'The payment method of this order does not support a refund.',
  },
  warning_overrideamountvalidationfailed: {
    id: 'BookingForm.warning_overrideamountvalidationfailed',
    defaultMessage:
      'Cannot override refundable amounts. This may be because the overriden amount is more than the customer paid.',
  },
  warning_lessthanzero: {
    id: 'BookingForm.warning_lessthanzero',
    defaultMessage: 'The refund admin fee may be greater than the value of the refund.',
  },
  warning_partlyusedsplitticket: {
    id: 'BookingForm.warning_partlyusedsplitticket',
    defaultMessage:
      'A refund of this SplitSave ticket is not possible because the traveller has scanned some or all of the tickets for this journey.',
  },
  warning_refundrejectedbyfraudprovider: {
    id: 'BookingForm.warning_refundrejectedbyfraudprovider',
    defaultMessage:
      '<b>Reference code RR1.</b>{br}Please override this block and continue to process the refund. If the customer is requesting help to self serve after-sales for future bookings then please raise a request to the F & P Revenue Protection Group queue in Freshdesk for further handling.',
  },
  warning_invalidRefundCombinationsSelected: {
    id: 'BookingForm.warning_invalidRefundCombinationsSelected',
    defaultMessage:
      'The ticket(s) selected may break a multi-passenger railcard or group saver fare. Click on the info icon next to the selected ticket to check its valid refund combinations.',
  },
  warning_unspecified: {
    id: 'BookingForm.warning_unspecified',
    defaultMessage:
      'The refund cannot be processed because of an unspecified error. Try again or raise a ticket to the Refunds Team.',
  },
  cannotDisplayRailcardError: {
    id: 'BookingForm.cannotDisplayRailcardError',
    defaultMessage: 'We’re sorry, this railcard cannot be displayed due to an error.',
  },
  updateQuoteError: {
    id: 'BookingForm.updateQuoteError',
    defaultMessage: 'Sorry, something went wrong: Please refresh the page.',
  },
  approveRefundNotFound: {
    id: 'BookingForm.approveRefundNotFound',
    defaultMessage:
      'The discretionary refund request you were trying to process has already been actioned. We have now removed it from this list.',
  },
  approveRefundDefaultError: {
    id: 'BookingForm.approveRefundDefaultError',
    defaultMessage: 'Something went wrong with processing',
  },
  manualRefund: {
    id: 'BookingForm.manualRefund',
    defaultMessage: 'Manual refund',
  },
  adminFee: {
    id: 'BookingForm.adminFee',
    defaultMessage: 'Admin fee',
  },
  refundCalculatedFrom: {
    id: 'BookingForm.refundCalculatedFrom',
    defaultMessage: 'Refund calculated from:',
  },
  entanglementWarning: {
    id: 'BookingForm.entanglementWarning',
    defaultMessage:
      'Some parts of this product are entangled, please check the fare and railcard T&Cs to ensure the remaining tickets are still valid.',
  },
  hasScannedTicketsWarning: {
    id: 'BookingForm.hasScannedTicketsWarning',
    defaultMessage:
      'Ticket has been scanned and you must confirm the disruption and the customer did not use ticket to reach destination before requesting refund.',
  },
  discretionaryRefundAutoRejected: {
    id: 'BookingForm.discretionaryRefundAutoRejected',
    defaultMessage:
      'The product is not a return ticket. You can not proceed further using this reason code.',
  },
  travelcardPartialRefundWarning: {
    id: 'BookingForm.travelcardPartialRefundWarning',
    defaultMessage:
      'If you do partial refund for a travelcard, the remaining travelcards will not be able to be refunded in the future.',
  },
  partialOpenReturnWarning: {
    id: 'BookingForm.partialOpenReturnWarning',
    defaultMessage:
      'Please check ticket scan status and make sure inward ticket is not used. We partially refund the inward ticket. This is calculated by deducting the full price with the most recent outward ticket. The refund fees should be applied.',
  },
  discretionaryRefundPaperTicketError: {
    id: 'BookingForm.discretionaryRefundPaperTicketError',
    defaultMessage:
      'TCC does not currently offer partial refunds for open return paper tickets, please use TraCS and request it there with admin fee applied reason.',
  },
  partialReturnRefundAgainstDisruptionWarning: {
    id: 'BookingForm.partialReturnRefundAgainstDisruptionWarning',
    defaultMessage: 'The inward ticket has been scanned and needs extra check',
  },
  discretionaryRefundNoEquivalentFareError: {
    id: 'BookingForm.discretionaryRefundNoEquivalentFareError',
    defaultMessage:
      "We can't calculate the refund, please calculate the refund manually and use compensation to process this",
  },
  localAreaProducts: {
    id: 'BookingForm.localAreaProducts',
    defaultMessage: 'Local Area Products',
  },
});
