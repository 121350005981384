import { defineMessages } from 'react-intl';

export default defineMessages({
  genericError: {
    id: 'TracsOnAccountError.genericError',
    defaultMessage: "There's been an error processing the On Account refund request.",
  },
  invalidRangeError: {
    id: 'TracsOnAccountError.invalidRangeError',
    defaultMessage:
      'Amount must be less than {amountLimit, number, withDecimalPlaces} {currencyCode}',
  },
});
