import { defineMessages } from 'react-intl';

export default defineMessages({
  previous: {
    id: 'Paginator.previous',
    defaultMessage: 'Previous',
  },
  continue: {
    id: 'Paginator.continue',
    defaultMessage: 'Continue',
  },
});
