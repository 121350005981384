export const parseCsv = (file: File | undefined, onGetResults: (text: string) => void) => {
  if (!file) {
    return;
  }

  const reader = new FileReader();
  reader.onload = (e) => readAsTextHandler(e, onGetResults);
  reader.readAsText(file);
};

const readAsTextHandler = (
  event: ProgressEvent<FileReader>,
  onTextRead?: (text: string) => void
) => {
  const text = event.target?.result as string;
  const firstRowIndex = text.indexOf('\n');
  const parsedText = firstRowIndex !== -1 ? text.substring(firstRowIndex + 1) : text;

  onTextRead && onTextRead(parsedText);
};

type ValidationErrors = {
  isOutOfRange: boolean;
};

type ValidationWarnings = {
  duplicatesRemovedCount: number;
  longerThanEightCharactersEntryCount: number;
};

export type ExtractionResult = {
  isSuccessful: boolean;
  customerIds: Array<string>;
  validationErrors: ValidationErrors;
  validationWarnings: ValidationWarnings;
};

export const MAX_CUSTOMERID_COUNT = 100;
export const MIN_CUSTOMERID_COUNT = 1;

export const extractCustomerIdsFromText = (inputText: string): ExtractionResult => {
  const extractedIds = inputText.match(/\d{8,}/g);
  if (!extractedIds) {
    return {
      isSuccessful: false,
      customerIds: [],
      validationErrors: { isOutOfRange: true },
      validationWarnings: { duplicatesRemovedCount: 0, longerThanEightCharactersEntryCount: 0 },
    };
  }
  const customerIds = extractedIds.map((x) => x.trim()).filter((x) => x.length > 0);
  const uniqueCustomerIds = [...new Set(customerIds)];
  const duplicateCount = customerIds.length - uniqueCustomerIds.length;
  const longerThanEightCharactersEntryCount = uniqueCustomerIds.filter((i) => i.length > 8).length;
  const customerIdsWithinRange =
    uniqueCustomerIds.length <= MAX_CUSTOMERID_COUNT &&
    uniqueCustomerIds.length >= MIN_CUSTOMERID_COUNT;
  return {
    isSuccessful: customerIdsWithinRange,
    customerIds: uniqueCustomerIds,
    validationErrors: { isOutOfRange: !customerIdsWithinRange },
    validationWarnings: {
      duplicatesRemovedCount: duplicateCount,
      longerThanEightCharactersEntryCount: longerThanEightCharactersEntryCount,
    },
  };
};
