import React, { useEffect, useState } from 'react';
import { css } from 'aphrodite/no-important';
import IframeResizer from 'iframe-resizer-react';
import { Heading } from '@trainline/depot-web';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { useCardPaymentForm } from '@contactcentre-web/hooks/api/useVirtualTerminal';
import Loader from '@contactcentre-web/common/Loader';
import { useToggleRecording } from '@contactcentre-web/hooks/api/usePhoneCall';
import * as userSelectors from '@contactcentre-web/authentication/redux/selectors';

import ToggleRecordingErrorModal from '../ToggleRecordingErrorModal/ToggleRecordingErrorModal';

import messages from './messages';
import styles from './styles';

interface Props {
  url: string;
  onIframeSuccess: (token?: string) => void;
  isLoading: boolean;
}
const PaymentIframe = ({ url, onIframeSuccess, isLoading }: Props) => {
  const [showToggleRecordingErrorModal, setShowToggleRecordingErrorModal] = useState(false);
  const [isRecording, setIsRecording] = useState(true);
  const canLogCall = useSelector(userSelectors.canLogCall);
  const { mutate: toggleRecording, isError: isToggleRecordingError } = useToggleRecording();
  const { isSuccess, token } = useCardPaymentForm(url);
  const handleToggleRecording = (recordingEnabled: boolean) => {
    if (!canLogCall) {
      return;
    }
    toggleRecording(recordingEnabled);
    setIsRecording(recordingEnabled);
  };

  useEffect(() => {
    if (isSuccess && token) {
      onIframeSuccess(token);
    }
  }, [isSuccess, token]);

  useEffect(() => {
    if (isToggleRecordingError && !isRecording) {
      setShowToggleRecordingErrorModal(true);
    }
  }, [isToggleRecordingError]);

  useEffect(() => {
    handleToggleRecording(false);

    return () => {
      handleToggleRecording(true);
    };
  }, []);

  return (
    <div className={css(styles.container)}>
      <Heading typeStyle="title3" as="h2" color="base">
        <FormattedMessage {...messages.title} />
      </Heading>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={css(styles.body)}>
          <IframeResizer src={url} data-testid="paymentIframe" />
        </div>
      )}
      {showToggleRecordingErrorModal && (
        <ToggleRecordingErrorModal onCloseModal={() => setShowToggleRecordingErrorModal(false)} />
      )}
    </div>
  );
};

export default PaymentIframe;
