import React, { Dispatch, FunctionComponent } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { IconChevronLeft, StatusMessage } from '@trainline/depot-web';

import { canApproveCompensations } from '@contactcentre-web/authentication/redux/selectors';
import Button from '@contactcentre-web/common/Button';
import FormattedDateInTz from '@contactcentre-web/common/FormattedDateInTz';
import FormattedTimeInTz from '@contactcentre-web/common/FormattedTimeInTz/FormattedTimeInTz';
import Loader from '@contactcentre-web/common/Loader';
import PageContainer from '@contactcentre-web/common/PageContainer';
import approvalsSelectors from '@contactcentre-web/compensation-approvals/selectors';
import { selectors as orderSelectors } from '@contactcentre-web/customer-order/module';
import DiscountType from '@contactcentre-web/redux-common/types/DiscountType';
import type {
  OrderItemsHistory,
  CompensationHistory,
} from '@contactcentre-web/hooks/api/useOrderHistory';
import type State from '@contactcentre-web/redux-common/types/State';
import type TransactionSummary from '@contactcentre-web/redux-common/types/TransactionSummary';
import type Action from '@contactcentre-web/redux-common/types/Action';

import CompensationFailedAlert from './components/CompensationFailedAlert/CompensationFailedAlert';
import CompensationForm from './components/CompensationForm/CompensationForm';
import CompensationsList from './components/CompensationsList/CompensationsList';
import CompensationSuccessfulAlert from './components/CompensationSuccessfulAlert/CompensationSuccessfulAlert';
import messages from './messages';
import { actions } from './module';
import selectors from './selectors';
import styles from './styles';
import type { CompensationError, PendingCompensation, RehydratedReasonCode } from './reducer';
import type { RequestCompensationPayload, EditCompensationRequestPayload } from './sagas';

interface StateProps {
  order: {
    id: string;
  };
  compensationReasons: Array<RehydratedReasonCode>;
  showSuccess: boolean;
  error?: CompensationError;
  successAlertMessageId: 'processedSuccess' | 'requestedSuccess';
  editingCompensation?: PendingCompensation;
  loadedSuccessfully: boolean;
  loadingFailed: boolean;
  hasResolutionSucceeded: boolean;
  transactionSummary: TransactionSummary;
}

interface DispatchProps {
  submit: (
    orderId: string,
    orderReference: string,
    compensationId: string,
    values: RequestCompensationPayload | EditCompensationRequestPayload
  ) => void;
  load: (id: string) => void;
  reset: () => void;
}

export type Props = StateProps & DispatchProps;

interface Params {
  compensationId: string;
  orderId: string;
}

export const Compensation: FunctionComponent<Props> = ({
  order,
  compensationReasons,
  showSuccess,
  error,
  successAlertMessageId,
  editingCompensation,
  submit,
  loadedSuccessfully,
  loadingFailed,
  load,
  reset,
  hasResolutionSucceeded,
  transactionSummary,
}) => {
  const loadRef = React.useRef(load);
  const resetRef = React.useRef(reset);
  const history = useHistory();
  const { compensationId, orderId: orderReference } = useParams<Params>();
  const queryClient = useQueryClient();
  const { data: { items: orderHistoryItems = [] } = {}, isFetching } =
    queryClient.getQueryState<OrderItemsHistory>(['orderHistoryItems', orderReference]) || {};

  React.useEffect(() => {
    resetRef.current();
    loadRef.current(compensationId);
  }, [compensationId, loadRef, resetRef]);

  React.useEffect(() => {
    if (showSuccess || error) {
      queryClient.invalidateQueries('orderHistoryItems');
    }
  }, [showSuccess, error]);

  const compensations = (
    orderHistoryItems.filter((p) => p.type === 'Compensation') as Array<CompensationHistory>
  )?.sort((x, y) => (new Date(x.date || 0) < new Date(y.date || 0) ? 1 : -1));

  React.useEffect(() => {
    if (hasResolutionSucceeded) {
      history.push('/compensation-approvals');
    }
  }, [hasResolutionSucceeded]);

  if (!order) {
    return null;
  }

  const { id: orderId } = order;

  const {
    orderTotal = {},
    paymentTypeDetails: { paymentType } = {},
    discounts,
  } = transactionSummary;
  const hasPromoDiscount = discounts?.some(
    (discount) => discount.type === DiscountType.PromoDiscount
  );

  return (
    <PageContainer>
      {((!loadedSuccessfully && !loadingFailed) || isFetching) && <Loader />}
      {(loadedSuccessfully || loadingFailed) && !isFetching && (
        <>
          {showSuccess && (
            <section className={css(styles.alert)} data-testid="compensationSuccess">
              <CompensationSuccessfulAlert messageId={successAlertMessageId} />
            </section>
          )}
          {error && (
            <section className={css(styles.alert)} data-testid="compensationError">
              <CompensationFailedAlert error={error} />
            </section>
          )}
          {!paymentType && (
            <StatusMessage status="info">
              <FormattedMessage {...messages.requestCompensationNotPossible} />
            </StatusMessage>
          )}
          {hasPromoDiscount && (
            <StatusMessage status="info" testId="promoDiscountApplied">
              <FormattedMessage {...messages.promoDiscountApplied} />
            </StatusMessage>
          )}

          {editingCompensation && (
            <>
              <Button
                variant="ghost"
                styleSheet={styles.buttonBack}
                onClick={() => history.push('/compensation-approvals')}
                testId="buttonBack"
              >
                <IconChevronLeft />
                <span>
                  <FormattedMessage
                    {...messages.buttonBack}
                    values={{ b: (msg: string) => <b>{msg}</b> }}
                  />
                </span>
              </Button>
              <div className={css(styles.alert)} data-testid="requesting-agent">
                <StatusMessage status="info">
                  <FormattedMessage
                    {...messages.requestingAgentAlert}
                    values={{
                      agentName: <b>{editingCompensation.requestingUserName}</b>,
                      time: <FormattedTimeInTz value={editingCompensation.requestedDate} />,
                      date: (
                        <FormattedDateInTz
                          value={editingCompensation.requestedDate}
                          year="numeric"
                          month="short"
                          day="numeric"
                        />
                      ),
                    }}
                  />
                </StatusMessage>
              </div>
            </>
          )}
          {!editingCompensation && (
            <section className={css(styles.compensationsContainer)}>
              <CompensationsList compensations={compensations} />
            </section>
          )}
          <section className={css(styles.container)}>
            <h2>
              <FormattedMessage {...messages.title} />
            </h2>
            <section>
              <CompensationForm
                paymentType={paymentType}
                orderAmount={orderTotal}
                compensationReasons={compensationReasons}
                requestCompensation={(
                  values: RequestCompensationPayload | EditCompensationRequestPayload
                ) => submit(orderId, orderReference, compensationId, values)}
                disabled={!paymentType}
                compensation={editingCompensation}
                previousCompensation={compensations[0]}
              />
            </section>
          </section>
        </>
      )}
    </PageContainer>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  order: orderSelectors.getOrder(state),
  showSuccess: selectors.requestHasSucceeded(state),
  error: selectors.errorCode(state),
  compensationReasons: selectors.compensationReasons(state),
  successAlertMessageId: canApproveCompensations(state) ? 'processedSuccess' : 'requestedSuccess',
  editingCompensation: selectors.compensation(state),
  loadedSuccessfully: selectors.loadHasSucceeded(state),
  loadingFailed: selectors.loadHasFailed(state),
  hasResolutionSucceeded: approvalsSelectors.hasResolutionSucceeded(state),
  transactionSummary: orderSelectors.getTransactionSummary(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  reset: () => dispatch(actions.reset()),
  load: (id: string) => dispatch(actions.load(id)),
  submit: (
    orderId: string,
    orderReference: string,
    compensationId: string,
    values: RequestCompensationPayload | EditCompensationRequestPayload
  ) => dispatch(actions.requestAttempt(orderId, orderReference, compensationId, values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Compensation);
