import React, { FunctionComponent } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';

import Button from '@contactcentre-web/common/Button';

import NotesTopicIcon from '../NotesTopicIcon/NotesTopicIcon';
import type { Topic } from '../../module';

import messages from './messages';
import styles from './styles';

interface Props {
  topic: Topic;
  count: number;
  setTopic: (topic: Topic) => void;
}

const NotesTopicButton: FunctionComponent<Props> = ({ topic, count, setTopic }) => (
  <Button
    variant="ghost"
    testId={`notesTopicButton${topic}`}
    styleSheet={styles.topicItem}
    onClick={() => setTopic(topic)}
    fullWidth
  >
    <NotesTopicIcon topic={topic} />
    <h3 className={css(styles.topicTitle)}>
      <FormattedMessage {...messages[topic]} values={{ count: `${count}` }} />
    </h3>
  </Button>
);

export default NotesTopicButton;
