import { useMutation } from 'react-query';

import request from '@contactcentre-web/utils/request';

export type ManagedGroupAndRoleType = { managedGroup: string; role: string };

export type CreateNewAgentFormData = {
  firstName: string;
  lastName: string;
  roles: ManagedGroupAndRoleType[];
  username: string;
  employeeId?: string;
  site: string;
  email: string;
  enableSso: boolean;
};

export type CreateNewAgentError = {
  errors?: {
    errors?: Array<{ code: string; detail: string }>;
  };
};

export type NewAgentData = {
  username: string;
  firstName: string;
  lastName: string;
  password: string;
};

export const useCreateNewAgent = () =>
  useMutation<NewAgentData, CreateNewAgentError, CreateNewAgentFormData, unknown>((data) =>
    request('/authapi/users', {
      method: 'POST',
      body: {
        ...data,
        roles: data.roles?.map(({ managedGroup, role }) => ({
          managedGroupId: managedGroup,
          roleId: role,
        })),
      },
    })
  );
