import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'ToggleRecordingErrorModal.title',
    defaultMessage: 'Failure to pause phone call',
  },
  body: {
    id: 'ToggleRecordingErrorModal.body',
    defaultMessage:
      "There's a problem with automatically pausing recording of calls to protect customers private payment information. Manually pause your phone call using the button in the telephony interface, and contact your team lead or the Service Desk after to fix this problem.",
  },
  button: {
    id: 'ToggleRecordingErrorModal.button',
    defaultMessage: 'Dismiss',
  },
});
