import React, { Fragment, useState } from 'react';
import { Paragraph } from '@trainline/depot-web';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import type { TicketScan } from '@contactcentre-web/redux-common/types/TicketStatus';
import Expandable from '@contactcentre-web/common/Expandable';

import styles from '../styles';
import messages from '../messages';

interface Props {
  ticketScan: TicketScan;
  index: number;
  totalOfScans: number;
}

const TicketScanSummary = ({ ticketScan, index, totalOfScans }: Props) => {
  const [isOpen, setIsOpen] = useState(totalOfScans === 1);

  return (
    <Expandable.Wrapper styleSheet={styles.scanWrapper}>
      <Expandable.Anchor isOpen={isOpen} onClick={() => setIsOpen((prev) => !prev)}>
        <Paragraph typeStyle="body" as="p" color="base" fontWeight="bold">
          <FormattedMessage {...messages.scanNumber} values={{ scanNumber: index + 1 }} />
        </Paragraph>
      </Expandable.Anchor>
      <Expandable.Content isOpen={isOpen}>
        <div className={css(styles.ticketScanDetails)} data-testid="ticketScan">
          {Object.entries(ticketScan).map(([ticketScanKey, ticketScanValue], index) => {
            const ticketScanProperty = ticketScanKey as keyof typeof messages;
            return (
              messages[ticketScanProperty] && (
                <Fragment key={index}>
                  <Paragraph typeStyle="small" as="p" color="base" fontWeight="bold">
                    <FormattedMessage {...messages[ticketScanProperty]} />
                  </Paragraph>
                  <Paragraph typeStyle="small" as="p" color="base" fontWeight="regular">
                    {ticketScanKey === 'scanTime' ? (
                      <FormattedDate
                        value={ticketScanValue}
                        minute="2-digit"
                        hour="2-digit"
                        day="numeric"
                        month="short"
                        year="numeric"
                      />
                    ) : (
                      ticketScanValue
                    )}
                  </Paragraph>
                </Fragment>
              )
            );
          })}
        </div>
      </Expandable.Content>
    </Expandable.Wrapper>
  );
};

export default TicketScanSummary;
