import React from 'react';
import moment from 'moment-timezone';
import { FormattedTime } from 'react-intl';

interface Props {
  value: Date | string;
  addOffset?: boolean;
  year?: '2-digit' | 'numeric';
  month?: '2-digit' | 'numeric' | 'narrow' | 'short' | 'long';
  weekday?: 'narrow' | 'short' | 'long';
  day?: '2-digit' | 'numeric';
  hour?: '2-digit' | 'numeric';
  minute?: '2-digit' | 'numeric';
}

const FormattedTimeInLondonTz = ({ value, addOffset, ...otherProps }: Props) => {
  const m = moment.parseZone(value);
  const valueLondonTz = addOffset === true ? m.add(m.utcOffset(), 'm').utc() : m.utc();

  if (moment.tz(value, 'Europe/London').format('zz') === 'BST') {
    valueLondonTz.add(1, 'h');
  }

  return <FormattedTime {...otherProps} value={valueLondonTz.toDate()} timeZone="UTC" />;
};

export default FormattedTimeInLondonTz;
