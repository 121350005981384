import React, { Dispatch } from 'react';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { css } from 'aphrodite/no-important';
import { InjectedFormProps, reduxForm } from 'redux-form';

import Select from '@contactcentre-web/common/Select';
import Button from '@contactcentre-web/common/Button';
import type State from '@contactcentre-web/redux-common/types/State';
import Action from '@contactcentre-web/redux-common/types/Action';

import {
  FORM_ID,
  actions,
  selectors,
  ReplaceReason,
  ReplacementBooking,
  ReplaceFormData,
} from '../../module';

import styles from './styles';
import messages from './messages';

type StateProps = {
  bookings: ReplacementBooking[];
  reasons: ReplaceReason[];
  isPending: boolean;
};
type Props = StateProps;

export const ReplaceBookingForm = ({
  bookings,
  handleSubmit,
  isPending,
  reasons,
}: Props & InjectedFormProps<ReplaceFormData, Props>) => {
  const { formatMessage } = useIntl();

  const bookingSelectOptions = bookings.map(({ bookingIndex, from, to, departureDay, id }) => ({
    label: formatMessage(messages.bookingLabel, {
      bookingIndex,
      from,
      to,
      departureDay: moment(departureDay).format('ll'),
    }),
    value: id,
  }));

  const reasonOptions = reasons?.map(({ value }) => ({
    label: value,
    value,
  }));

  return (
    <form id={FORM_ID} className={css(styles.container)} onSubmit={handleSubmit}>
      <h1 className={css(styles.title)}>
        <FormattedMessage {...messages.createReplaceTickets} />
      </h1>
      <div className={css(styles.fieldContainer)}>
        <Select
          name="bookingId"
          testId="ReplaceBooking-bookingId"
          label={<FormattedMessage {...messages.booking} />}
          placeholder={<FormattedMessage {...messages.selectBooking} />}
          messages={messages}
          disabled={isPending}
          options={bookingSelectOptions}
        />
      </div>
      <div className={css(styles.fieldContainer)}>
        <Select
          name="reason"
          testId="ReplaceBooking-reason"
          label={<FormattedMessage {...messages.replacementReason} />}
          placeholder={<FormattedMessage {...messages.selectReason} />}
          messages={messages}
          disabled={isPending}
          options={reasonOptions}
        />
      </div>
      <Button
        type="submit"
        variant="primary"
        size="large"
        testId="ReplaceBookingSubmit"
        loading={isPending}
        styleSheet={styles.button}
      >
        <FormattedMessage {...messages.replaceBooking} />
      </Button>
    </form>
  );
};

export const validate = (values: ReplaceFormData) => {
  const errors = {} as ReplaceFormData;
  if (!values.bookingId) {
    errors.bookingId = 'bookingMustBeSelected';
  }
  if (!values.reason) {
    errors.reason = 'reasonMustBeSelected';
  }
  return errors;
};

const mapStateToProps = (state: State) => ({
  bookings: selectors.getReplacementBookings(state),
  reasons: selectors.getReasons(state.replaceBooking),
  isPending: selectors.isPending(state.replaceBooking),
});

export default connect(mapStateToProps)(
  reduxForm<ReplaceFormData, Props>({
    form: FORM_ID,
    validate,
    onSubmit: (_: any, dispatch: Dispatch<Action>) => {
      dispatch(actions.pending());
    },
  })(ReplaceBookingForm)
);
