import { defineMessages } from 'react-intl';

export default defineMessages({
  feeFreeCoj: {
    id: 'OrderDetails.feeFreeCoj',
    defaultMessage:
      'One or more journeys are eligible for a fee-free COJ within 6 months of departure date in COJ flow. Please check on order history if they have done this before confirming any similar request.',
  },
  feeFreeCojDueToCovidRestrictions: {
    id: 'OrderDetails.feeFreeCojDueToCovidRestrictions',
    defaultMessage:
      'Due to Covid-19 restrictions, this booking is eligible for a fee-free COJ within 6 months which can be processed in the Change Journey feature or the customer can self-serve in My Account.',
  },
  changeNotSuccessful: {
    id: 'OrderDetails.changeNotSuccessful',
    defaultMessage:
      "Change is not successful. Customer has not been charged and/or will be automatically refunded. Please reprocess the change by clicking 'change journey' on the <link>original</link> booking.",
  },
});
