import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmPasswordReset: {
    id: 'ResetPasswordConfirm.confirmPasswordReset',
    defaultMessage: 'Confirm password reset',
  },
  resetThePasswordFor: {
    id: 'ResetPasswordConfirm.resetThePasswordFor',
    defaultMessage: 'Reset the password for {username}',
  },
  resetPassword: {
    id: 'ResetPasswordConfirm.resetPassword',
    defaultMessage: 'Reset password',
  },
  cancel: {
    id: 'ResetPasswordConfirm.cancel',
    defaultMessage: 'Cancel',
  },
});
