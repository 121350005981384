import { StyleSheet } from 'aphrodite/no-important';
import {
  fonts,
  spacers,
  typography,
  borderRadius,
} from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  table: {
    display: 'table',
    width: '100%',
    borderRadius: borderRadius.dp_r4,
    overflow: 'hidden',
    fontSize: typography.dp_mini_font_size_m,
    boxShadow: '0 4px 8px 0 rgba(157, 24, 6, 0.2)',
  },
  row: {
    display: 'table-row',
  },
  cell: {
    display: 'table-cell',
    borderLeft: `solid 1px ${colors.gray.alpha12}`,
    padding: `${spacers.dp_s8} ${spacers.dp_s16}`,
    textAlign: 'center',
    alignItems: 'center',
    alignContent: 'center',

    ':first-child': {
      borderLeft: `none`,
    },
  },
  header: {
    textTransform: 'uppercase',
    backgroundColor: '#fbfbfc',
    fontWeight: fonts.dp_font_weight_semibold,
  },
  data: {
    backgroundColor: colors.white,
    borderTop: `solid 1px ${colors.gray.alpha12}`,
  },
  booking: {
    textAlign: 'left',
  },
  insurance: {
    justifyContent: 'end',
  },
  frame: {
    borderRadius: borderRadius.dp_r12,
    padding: `0 ${spacers.dp_s8}`,
    width: 'max-content',
    fontWeight: fonts.dp_font_weight_bold,
  },
  frame_positive: {
    color: colors.positive.dark,
    backgroundColor: '#effff4',
  },
  frame_negative: {
    color: '#ff585f',
    backgroundColor: '#ffeff0',
  },
  recommendations: {
    marginTop: spacers.dp_s16,
  },
  label_recommendations: {
    color: colors.black,
    fontWeight: fonts.dp_font_weight_regular,
  },
  value_recommendations: {
    fontWeight: fonts.dp_font_weight_bold,
    marginLeft: spacers.dp_s4,
  },
  external_payment_recommendations: {
    marginTop: spacers.dp_s16,
  },
});
