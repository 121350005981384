import { defineMessages } from 'react-intl';

export default defineMessages({
  deliveryFee: {
    id: 'PaymentSummaryNext.deliveryFee',
    defaultMessage: 'Delivery Fee',
  },
  bookingFee: {
    id: 'PaymentSummaryNext.bookingFee',
    defaultMessage: 'Service Fee',
  },
  paymentFee: {
    id: 'PaymentSummaryNext.paymentFee',
    defaultMessage: 'Card Fee',
  },
  adminFee: {
    id: 'PaymentSummaryNext.adminFee',
    defaultMessage: 'Admin Fee',
  },
});
