import React, { useRef, useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, FormattedTime } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { Paragraph, StatusMessage } from '@trainline/depot-web';

import {
  OnAccountAction,
  TracsOnAccountApprovalQueueItemResponse,
} from '@contactcentre-web/hooks/api/useTracsOnAccount';
import Expandable from '@contactcentre-web/common/Expandable';
import HorizontalRule from '@contactcentre-web/common/HorizontalRule';

import ItemValidatorButton from '../ItemValidatorButton/ItemValidatorButton';

import styles, { expandableStyles } from './styles';
import messages from './messages';

interface Props {
  item: TracsOnAccountApprovalQueueItemResponse;
}
const ApprovaItem = ({ item }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isValidationFailed, setIsValidationFailed] = useState<OnAccountAction | null>(null);
  const [isValidationSucceed, setIsValidationSucceed] = useState<OnAccountAction | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const height = (!!isValidationSucceed && ref.current?.clientHeight) || 0;

  return (
    <div
      ref={ref}
      style={height === 0 ? {} : { marginBottom: height * -1 }}
      className={css([!!isValidationSucceed && styles.succeed])}
    >
      <Expandable.Wrapper styleSheet={expandableStyles(isOpen).wrapper}>
        <Expandable.Anchor
          onClick={() => setIsOpen((prev) => !prev)}
          isOpen={isOpen}
          styleSheet={{ chevron: styles.expandableChevron }}
          testId="tracsOnAccountApprovalItem"
        >
          <div className={css(styles.expandableAnchor)}>
            <Paragraph typeStyle="body" fontWeight="regular" as="p" color="base">
              {item.orderReference} • {item.requestingCustomer.lastName}
            </Paragraph>
            <div className={css(styles.requestedBy)}>
              <Paragraph typeStyle="body" fontWeight="regular" as="p" color="base">
                <FormattedMessage
                  {...messages.requestedDate}
                  values={{
                    day: (
                      <FormattedDate
                        value={item.requestedBy.executedAt}
                        year="numeric"
                        month="short"
                        day="2-digit"
                      />
                    ),
                    time: <FormattedTime value={item.requestedBy.executedAt} />,
                  }}
                />
              </Paragraph>
              <Paragraph typeStyle="body" fontWeight="regular" as="p" color="subdued">
                <FormattedMessage
                  {...messages.agent}
                  values={{ agentName: item.requestedBy.displayName }}
                />
              </Paragraph>
            </div>
          </div>
        </Expandable.Anchor>
        <Expandable.Content isOpen={isOpen}>
          <HorizontalRule />
          {isValidationFailed && (
            <StatusMessage status="negative">
              <FormattedMessage {...messages[`${isValidationFailed}Error`]} />
            </StatusMessage>
          )}
          <div className={css(styles.approvalItemContainer)}>
            <div className={css(styles.itemDetailSection)}>
              <div>
                <Paragraph typeStyle="body" fontWeight="regular" as="p" color="subdued">
                  <FormattedMessage {...messages.reasonCodes} />
                </Paragraph>
                <Paragraph typeStyle="body" fontWeight="bold" as="p" color="base">
                  {item.reasonCode.description}
                </Paragraph>
              </div>
              <div>
                <Paragraph typeStyle="body" fontWeight="regular" as="p" color="subdued">
                  <FormattedMessage {...messages.notes} />
                </Paragraph>
                <Paragraph typeStyle="body" fontWeight="bold" as="p" color="base">
                  {item.notes}
                </Paragraph>
              </div>
              <div>
                <div>
                  <Paragraph typeStyle="body" fontWeight="regular" as="span" color="subdued">
                    <FormattedMessage
                      {...messages.managedGroup}
                      values={{
                        managedGroup: (
                          <Paragraph typeStyle="body" fontWeight="bold" as="span" color="base">
                            {item.managedGroupNumber}
                          </Paragraph>
                        ),
                      }}
                    />
                  </Paragraph>
                </div>
                <div>
                  <Paragraph typeStyle="body" fontWeight="regular" as="span" color="subdued">
                    <FormattedMessage
                      {...messages.corporateReference}
                      values={{
                        corporateReference: (
                          <Paragraph typeStyle="body" fontWeight="bold" as="span" color="base">
                            {item.corporateReference}
                          </Paragraph>
                        ),
                      }}
                    />
                  </Paragraph>
                </div>
              </div>
            </div>
            <div>
              <Paragraph typeStyle="body" fontWeight="regular" as="p" color="subdued">
                <span className={css(styles.approvalItemRefundValue)}>
                  <FormattedMessage
                    {...messages.refund}
                    values={{
                      ...item.refundAmount,
                      strong: () => (
                        <strong>
                          <FormattedNumber
                            value={item.refundAmount.amount}
                            maximumFractionDigits={2}
                            minimumFractionDigits={2}
                          />
                        </strong>
                      ),
                    }}
                  />
                </span>
              </Paragraph>
            </div>
            <div className={css(styles.buttonsContainer)}>
              <ItemValidatorButton
                id={item.id}
                action={OnAccountAction.Approve}
                onError={() => setIsValidationFailed(OnAccountAction.Approve)}
                onSuccess={() => setIsValidationSucceed(OnAccountAction.Approve)}
              />
              <ItemValidatorButton
                id={item.id}
                action={OnAccountAction.Reject}
                onError={() => setIsValidationFailed(OnAccountAction.Reject)}
                onSuccess={() => setIsValidationSucceed(OnAccountAction.Reject)}
              />
            </div>
          </div>
        </Expandable.Content>
      </Expandable.Wrapper>
    </div>
  );
};
export default ApprovaItem;
