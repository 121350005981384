import { all, takeLatest, put, call } from 'redux-saga/effects';
import { handleActions, createActions } from 'redux-actions';

import { LOGIN_SUCCEEDED } from '@contactcentre-web/authentication/redux/module';
import request from '@contactcentre-web/utils/request';
import type State from '@contactcentre-web/redux-common/types/State';

const PREFIX = 'APP_BAR';
export const actionTypes = {
  REDIRECT_TO_BOOKING_FLOW_ATTEMPT: 'REDIRECT_TO_BOOKING_FLOW_ATTEMPT',
  REDIRECT_TO_BOOKING_FLOW_SUCCESS: 'REDIRECT_TO_BOOKING_FLOW_SUCCESS',
  REDIRECT_TO_BOOKING_FLOW_FAILURE: 'REDIRECT_TO_BOOKING_FLOW_FAILURE',
  APP_BAR_ROOT_SAGA_FAILED: 'ROOT_SAGA_FAILED',
  APP_BAR_SHOW_ERROR_MESSAGE: 'SHOW_ERROR_MESSAGE',
  APP_BAR_HIDE_ERROR_MESSAGE: 'HIDE_ERROR_MESSAGE',
  APP_BAR_RESET: 'APP_BAR_RESET',
};

export interface AppBarState {
  message?: string;
  messageType?: string;
  error?: boolean;
  conversationId?: string;
  redirectingToBookingFlow: boolean;
  redirectToBookingFlowError: boolean;
  redirectToBookingFlowSuccess: boolean;
}

interface Payload {
  message?: string;
  messageType?: string;
  error?: boolean;
  conversationId?: string;
}

export const actions = createActions(
  {
    [actionTypes.APP_BAR_RESET]: () => null,
    [actionTypes.REDIRECT_TO_BOOKING_FLOW_ATTEMPT]: () => null,
    [actionTypes.REDIRECT_TO_BOOKING_FLOW_SUCCESS]: () => null,
    [actionTypes.REDIRECT_TO_BOOKING_FLOW_FAILURE]: () => null,
    [actionTypes.APP_BAR_ROOT_SAGA_FAILED]: (message) => ({
      message,
      messageType: 'error',
      error: true,
    }),
    [actionTypes.APP_BAR_SHOW_ERROR_MESSAGE]: (message, conversationId) => ({
      message,
      messageType: 'error',
      error: true,
      conversationId,
    }),
    [actionTypes.APP_BAR_HIDE_ERROR_MESSAGE]: () => ({
      message: '',
      messageType: 'error',
      error: false,
    }),
  },
  { prefix: PREFIX }
);

// Reducer
export const initialState = {
  message: '',
  messageType: '',
  error: false,
  redirectingToBookingFlow: false,
  redirectToBookingFlowError: false,
  redirectToBookingFlowSuccess: false,
};

const handleErrorAction = (
  state: AppBarState,
  { payload: { error, message, messageType, conversationId } }: { payload: Payload }
) => ({
  ...state,
  message,
  messageType,
  error,
  conversationId,
});

const reducer = handleActions<AppBarState, Payload>(
  {
    [actionTypes.APP_BAR_RESET]: () => initialState,
    [actionTypes.APP_BAR_ROOT_SAGA_FAILED]: handleErrorAction,
    [actionTypes.APP_BAR_SHOW_ERROR_MESSAGE]: handleErrorAction,
    [actionTypes.APP_BAR_HIDE_ERROR_MESSAGE]: handleErrorAction,
    [actionTypes.REDIRECT_TO_BOOKING_FLOW_ATTEMPT]: (state) => ({
      ...state,
      redirectingToBookingFlow: true,
      redirectToBookingFlowError: false,
      redirectToBookingFlowSuccess: false,
    }),
    [actionTypes.REDIRECT_TO_BOOKING_FLOW_SUCCESS]: (state) => ({
      ...state,
      redirectToBookingFlowSuccess: true,
      redirectingToBookingFlow: false,
    }),
    [actionTypes.REDIRECT_TO_BOOKING_FLOW_FAILURE]: (state) => ({
      ...state,
      redirectToBookingFlowError: true,
      redirectingToBookingFlow: false,
    }),
  },
  initialState,
  { prefix: PREFIX }
);

export default reducer;

// Selectors
export const selectors = {
  getMessage: (state: State) => state.appBarMessage.message,
  getMessageType: (state: State) => state.appBarMessage.messageType,
  getConversationId: (state: State) => state?.appBarMessage?.conversationId,
  redirectToBookingFlowSuccess: (state: State) => state.appBarMessage.redirectToBookingFlowSuccess,
};

// Sagas
export function* redirectToBookingFlowSaga() {
  try {
    yield call(request, '/booking-flow-redirect');
    yield put(actions.redirectToBookingFlowSuccess());
  } catch (err) {
    yield put(actions.redirectToBookingFlowFailure());
    yield put(actions.showErrorMessage());
  }
}

export function* appBarResetSaga() {
  yield put(actions.appBarReset());
}

export function* saga() {
  yield all([
    takeLatest(
      `${PREFIX}/${actionTypes.REDIRECT_TO_BOOKING_FLOW_ATTEMPT}`,
      redirectToBookingFlowSaga
    ),
    takeLatest(LOGIN_SUCCEEDED, appBarResetSaga),
  ]);
}
