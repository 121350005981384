import { StyleSheet } from 'aphrodite/no-important';
import { palettes, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  totalMessage: {
    textAlign: 'center',
    marginTop: spacers.dp_s32,
    color: palettes.dp_app_text_subdued,
  },
});
