import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { IconInfo } from '@trainline/depot-web';

import Loader from '@contactcentre-web/common/DottedLoader';
import { getCountryFlagLink } from '@contactcentre-web/localisation/localisation';
import FormattedLanguage from '@contactcentre-web/common/FormattedLanguage/FormattedLanguage';

import { selectors } from '../../module';

import styles from './styles';
import messages from './messages';

export const MigrationInfo = ({
  preferredLanguage,
  migrationDate,
  loadingStats,
  errored,
  currentLocale,
}) => (
  <div>
    {loadingStats && (
      <div data-testid="loader" className={css(styles.loader)}>
        <Loader />
      </div>
    )}
    {!loadingStats && errored && (
      <div className={css(styles.error)} data-testid="error">
        <IconInfo />
        <span className={css(styles.errorText)}>
          <FormattedMessage {...messages.errorMessage} />
        </span>
      </div>
    )}
    {!loadingStats && !errored && preferredLanguage && (
      <div>
        <img
          className={css(styles.languageImg)}
          src={getCountryFlagLink(preferredLanguage)}
          alt={preferredLanguage}
        />
        <FormattedLanguage
          lang={preferredLanguage}
          inLang={currentLocale}
          styleSheet={styles.language}
        />
      </div>
    )}
    {!loadingStats && !errored && migrationDate && (
      <div>
        <span className={css(styles.logo)}>tl</span>
        <FormattedDate
          value={migrationDate}
          day="numeric"
          month="short"
          year="numeric"
          timeZone="UTC"
        />
      </div>
    )}
  </div>
);

MigrationInfo.propTypes = {
  loadingStats: PropTypes.bool.isRequired,
  errored: PropTypes.bool,
  preferredLanguage: PropTypes.string,
  migrationDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  currentLocale: PropTypes.string,
};

const mapStateToProps = (state) => ({
  loadingStats: selectors.isLoadingStats(state),
  errored: selectors.failedLoadingStats(state),
  migrationDate: selectors.migrationDate(state),
});

const connected = connect(mapStateToProps)(MigrationInfo);
connected.displayName = 'MigrationInfo';

export default connected;
