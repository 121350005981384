import React, { FunctionComponent, useMemo } from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';

import type {
  RefundHistory,
  RefundableStatus,
  RefundPolicy,
} from '@contactcentre-web/hooks/api/useOrderHistory';
import type { StatusDetails } from '@contactcentre-web/common/StatusIndicator';
import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency';

import OrderHistory from '../OrderHistory/OrderHistory';
import { userDisplayName } from '../OrderHistory/helpers';

import {
  getFareRefundablesGroupedByTicketType,
  getFareRefundablesGroupedByRefundStatus,
  getAllFareRefundStatuses,
  getRailcardRefundables,
  getAllRailcardRefundStatuses,
} from './helpers';
import styles from './styles';
import messages from './messages';
import RefundFareInformation from './components/RefundFareInformation/RefundFareInformation';
import RefundRailcardInformation from './components/RefundRailcardInformation/RefundRailcardInformation';
import RefundFareStatus from './components/RefundFareStatus/RefundFareStatus';
import RefundRailcardStatus from './components/RefundRailcardStatus/RefundRailcardStatus';
import RefundPaymentDetails from './components/RefundPaymentDetails/RefundPaymentDetails';

export type StatusMapperType = Record<
  RefundableStatus | 'multipleRefundStatuses' | 'unknown',
  StatusDetails
>;
export interface Props extends RefundHistory {
  isFirstOrderHistoryItem: boolean;
}

const Refund: FunctionComponent<Props> = ({
  date,
  user,
  payload,
  type,
  linkedEvent,
  isFirstOrderHistoryItem,
}) => {
  const refundRequestStatus = payload.status;
  const refundPolicy = payload.policy;
  const refundPayload = refundRequestStatus === 'rejected' ? linkedEvent?.payload : payload;

  const faresGroupedByTicketType = useMemo(
    () => getFareRefundablesGroupedByTicketType(refundPayload?.refundables),
    [refundPayload?.refundables]
  );
  const faresGroupedByRefundStatus = useMemo(
    () => getFareRefundablesGroupedByRefundStatus(refundPayload?.refundables),
    [refundPayload?.refundables]
  );
  const railcardRefundables = useMemo(
    () => getRailcardRefundables(refundPayload?.refundables),
    [refundPayload?.refundables]
  );

  const numberOfFaresGroupedByTicketType = Object.values(faresGroupedByTicketType).length;
  const numberOfFaresGroupedByRefundStatus = Object.values(faresGroupedByRefundStatus).length;
  const numberOfRailcardRefunds = railcardRefundables.length;

  const statusMapper: StatusMapperType = {
    pending: {
      color: 'base',
      message: messages.pendingStatus,
    },
    voiding: {
      color: 'base',
      message: messages.voidingStatus,
    },
    voided: {
      color: 'positive',
      message: messages.voidedStatus,
    },
    blocked: {
      color: 'negative',
      message: messages.blockedStatus,
    },
    rejected: {
      color: 'negative',
      message: messages.rejectedStatus,
    },
    refunded: {
      color: 'positive',
      message: messages.refundedStatus,
    },
    failed: {
      color: 'negative',
      message: messages.failedStatus,
    },
    requested: {
      color: 'base',
      message: messages.requestedStatus,
    },
    multipleRefundStatuses: {
      color: 'warning',
      message: messages.multipleRefundStatuses,
    },
    unknown: {
      color: 'base',
      message: messages.unknownStatus,
    },
  };

  const fareRefundStatuses = useMemo(
    () => getAllFareRefundStatuses(faresGroupedByRefundStatus),
    [faresGroupedByRefundStatus]
  );

  const railcardRefundStatuses = useMemo(
    () => getAllRailcardRefundStatuses(railcardRefundables),
    [railcardRefundables]
  );

  const hasMultipleRefundStatuses = (statuses: Array<RefundableStatus>) =>
    !statuses.every((status) => status === statuses[0]);

  const getStatusContentMessage = (statuses: Array<RefundableStatus>) =>
    hasMultipleRefundStatuses(statuses)
      ? statusMapper['multipleRefundStatuses']
      : statusMapper[statuses[0]]
      ? statusMapper[statuses[0]]
      : statusMapper['unknown'];

  const policyMessageKey = refundPolicy as keyof typeof messages & RefundPolicy;
  const isDiscretionaryPolicy = refundPolicy === 'discretionary';
  const requestedBy = linkedEvent?.user || user;
  const createdAt = linkedEvent?.date || date;
  const showRequestAuthorisationDetails =
    isDiscretionaryPolicy && date && (linkedEvent || refundRequestStatus === 'requested');

  return (
    <OrderHistory.Card>
      <OrderHistory.Header date={createdAt} user={requestedBy}>
        {messages[policyMessageKey] ? (
          <FormattedMessage
            {...messages[policyMessageKey]}
            values={{ isPartial: refundPayload?.isPartial }}
          />
        ) : (
          <FormattedMessage {...messages.refund} values={{ isPartial: refundPayload?.isPartial }} />
        )}
      </OrderHistory.Header>
      {showRequestAuthorisationDetails && (
        <OrderHistory.SimpleContent>
          <div
            className={css(styles.authorisationDetails)}
            data-testid="requestAuthorisationDetails"
          >
            <FormattedMessage
              {...(type === 'RefundRequestCreated'
                ? messages.refundRequestAwaitingAuthorisation
                : type === 'RefundRequestRejected'
                ? messages.refundRequestRejected
                : messages.refundRequestAuthorised)}
              values={{
                user: userDisplayName(user),
                date: <OrderHistory.CreatedAt date={date} />,
              }}
            />
            {refundPayload?.requestedAmount && (
              <FormattedCurrency {...refundPayload.requestedAmount} />
            )}
          </div>
        </OrderHistory.SimpleContent>
      )}
      {!!numberOfRailcardRefunds && (
        <OrderHistory.Content message={messages.railcardInformation} withStatusIndicator={false}>
          <RefundRailcardInformation railcardRefundables={railcardRefundables} />
        </OrderHistory.Content>
      )}
      {!!numberOfFaresGroupedByTicketType && (
        <OrderHistory.Content message={messages.bookingInformation} withStatusIndicator={false}>
          <RefundFareInformation
            faresGroupedByTicketType={faresGroupedByTicketType}
            numberOfFaresGroupedByTicketType={numberOfFaresGroupedByTicketType}
          />
        </OrderHistory.Content>
      )}
      {!!numberOfRailcardRefunds && (
        <OrderHistory.Content
          {...getStatusContentMessage(railcardRefundStatuses)}
          isOpenByDefault={
            isFirstOrderHistoryItem && hasMultipleRefundStatuses(railcardRefundStatuses)
          }
        >
          <OrderHistory.Items>
            <RefundRailcardStatus
              railcardRefundables={railcardRefundables}
              statusMapper={statusMapper}
            />
            <RefundPaymentDetails refundPayload={refundPayload} />
          </OrderHistory.Items>
        </OrderHistory.Content>
      )}
      {!!numberOfFaresGroupedByRefundStatus && (
        <OrderHistory.Content
          {...getStatusContentMessage(fareRefundStatuses)}
          isOpenByDefault={isFirstOrderHistoryItem && hasMultipleRefundStatuses(fareRefundStatuses)}
        >
          <OrderHistory.Items>
            <RefundFareStatus
              faresGroupedByRefundStatus={faresGroupedByRefundStatus}
              isDiscretionaryPolicy={isDiscretionaryPolicy}
              numberOfFaresGroupedByRefundStatus={numberOfFaresGroupedByRefundStatus}
              statusMapper={statusMapper}
            />
            <RefundPaymentDetails refundPayload={refundPayload} />
          </OrderHistory.Items>
        </OrderHistory.Content>
      )}
    </OrderHistory.Card>
  );
};

export default Refund;
