import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@contactcentre-web/common/Button';

import messages from '../../messages';
import styles from '../../styles';

export interface VerifyCustomersButtonProps {
  disabled: boolean;
  loading: boolean;
  onVerify: (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => void;
}

const VerifyCustomersButton = ({ disabled, loading, onVerify }: VerifyCustomersButtonProps) => (
  <Button
    variant="secondary"
    size="small"
    testId="verifyCustomersButton"
    loading={loading}
    disabled={disabled}
    styleSheet={styles.searchButton}
    onClick={onVerify}
  >
    <FormattedMessage {...messages.verifyCustomersButton} />
  </Button>
);

export default VerifyCustomersButton;
