import { css } from 'aphrodite/no-important';
import React from 'react';
import { connect } from 'react-redux';
import { IconClose } from '@trainline/depot-web';

import Button from '@contactcentre-web/common/Button';
import type State from '@contactcentre-web/redux-common/types/State';

import RefreshPnrConfirmationModal from './components/RefreshPnrConfirmation/RefreshPnrConfirmation';
import RefreshPnrFailMultiContainer from './components/RefreshPnrFailMulti/RefreshPnrFailMulti';
import RefreshPnrFormContainer from './components/RefreshPnrForm/RefreshPnrForm';
import RefreshPnrSuccessContainer from './components/RefreshPnrSuccess/RefreshPnrSuccess';
import { selectors } from './module';
import styles from './styles';

interface StateProps {
  isFailedMulti?: boolean;
  isSuccess?: boolean;
  onClose: () => void;
}
export const RefreshPnr = ({ isFailedMulti, isSuccess, onClose }: StateProps) => {
  let body = null;
  const setRefreshPnrContent = (): JSX.Element => {
    if (isFailedMulti) {
      return <RefreshPnrFailMultiContainer />;
    } else if (isSuccess) {
      return <RefreshPnrSuccessContainer />;
    } else {
      return <RefreshPnrFormContainer />;
    }
  };

  body = setRefreshPnrContent();

  return (
    <div className={css(styles.container)}>
      <Button
        variant="ghost"
        testId="RefreshPnrClose"
        styleSheet={styles.closeButton}
        onClick={onClose}
      >
        <IconClose />
      </Button>
      {body}
      <RefreshPnrConfirmationModal />
    </div>
  );
};

const mapStateToProps = ({ refreshPnr: state }: State) => ({
  isSuccess: selectors.isSuccess(state),
  isFailedMulti: selectors.isFailedMulti(state),
});

const connectedRefreshPnr = connect(mapStateToProps)(RefreshPnr);
connectedRefreshPnr.displayName = 'RefreshPnr';

export default connectedRefreshPnr;
