import { StyleSheet } from 'aphrodite/no-important';
import {
  typography,
  palettes,
  spacers,
  borderRadius,
  fonts,
} from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tripIcon: {
    marginRight: 10,
  },
  modalContent: {
    width: '520px',
  },
  callingPointsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: spacers.dp_s16,
  },
  callingPointsItemContainer: {
    flexDirection: 'column',
  },
  locationItem: {
    display: 'flex',
  },
  locationItemTime: {
    marginRight: spacers.dp_s24,
    marginLeft: spacers.dp_s32,
  },
  callingPoints: {
    position: 'relative',
  },
  marker: {
    position: 'relative',
    paddingBottom: spacers.dp_s32,

    ':before': {
      content: '""',
      display: 'block',
      top: '11px',
      bottom: '-30px',
      left: spacers.dp_s12,
      position: 'absolute',
      borderLeft: `3px solid ${palettes.dp_brand_neutral_light}`,
    },
  },
  point: {
    position: 'relative',
    ':before': {
      content: '""',
      position: 'absolute',
      width: '7px',
      height: '7px',
      left: '0px',
      top: '7px',
      border: `solid 2px ${palettes.dp_brand_neutral_light}`,
      borderRadius: '50%',
      backgroundColor: palettes.dp_brand_neutral_light,
    },
  },
  biggerPoint: {
    ':before': {
      width: '15px',
      height: '15px',
      left: '6px',
      top: '3px',
    },
  },
  originOrDestinationLocation: {
    fontWeight: fonts.dp_font_weight_bold,
    lineHeight: typography.dp_body_line_height,
    letterSpacing: typography.dp_body_letter_spacing,
    fontSize: typography.dp_body_font_size,
    color: palettes.dp_brand_black,
    flexDirection: 'column',
    justifyContent: 'space-between',
    display: 'flex',
    height: '50px',
  },
  changeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: palettes.dp_brand_neutral_lighter,
    borderRadius: borderRadius.dp_r4,
    padding: `${spacers.dp_s4} ${spacers.dp_s12}`,
    marginBottom: spacers.dp_s24,
    lineHeight: typography.dp_small_line_height,
    letterSpacing: typography.dp_small_letter_spacing,
    fontSize: typography.dp_small_font_size,
  },
});
