import React, { MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { StatusMessage } from '@trainline/depot-web';

import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from '@contactcentre-web/common/Modal';
import Button from '@contactcentre-web/common/Button';
import Loader from '@contactcentre-web/common/Loader';
import type { TicketStatus } from '@contactcentre-web/redux-common/types/TicketStatus';

import type { LoadingStatus } from '../Tickets/Tickets';
import TicketSummary from '../../../TicketSummary/TicketSummary';

import styles from './styles';
import messages from './messages';

interface Props {
  toggleModal: () => void;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  cancelSeasonStatus?: LoadingStatus;
  ticketStatus: {
    statuses?: Array<TicketStatus>;
    isLoading: boolean;
  };
}

const CancelTicketsModal = ({
  toggleModal,
  onCancel,
  cancelSeasonStatus,
  ticketStatus: { statuses, isLoading },
}: Props) => (
  <Modal onClose={toggleModal}>
    <ModalHeader>
      <ModalTitle>
        <FormattedMessage {...messages.title} />
      </ModalTitle>
    </ModalHeader>
    <ModalBody>
      {isLoading ? (
        <Loader />
      ) : (
        statuses?.map((status, index) => (
          <TicketSummary
            status={status}
            ticketNumber={index + 1}
            totalOfTickets={statuses.length}
            key={index}
          />
        ))
      )}
      <br />
      <FormattedMessage {...messages.description} />
      {cancelSeasonStatus === 'error' && (
        <div className={css(styles.errorContainer)}>
          <StatusMessage status="negative">
            <FormattedMessage {...messages.error} />
          </StatusMessage>
        </div>
      )}
    </ModalBody>
    <ModalFooter>
      <Button
        variant="primary"
        size="large"
        onClick={onCancel}
        loading={cancelSeasonStatus === 'loading'}
        testId="confirm-cancel-tickets"
      >
        <FormattedMessage {...messages.confirmCancel} />
      </Button>
    </ModalFooter>
  </Modal>
);

export default CancelTicketsModal;
