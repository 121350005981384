import React from 'react';
import { Field, WrappedFieldProps } from 'redux-form';
import { css } from 'aphrodite/no-important';

import styles from './styles';

type RadioBoxGroupStylesheet = {
  item?: StyleSheet;
};

interface RadioBoxGroupProps {
  styleSheet?: RadioBoxGroupStylesheet;
  name: string;
}

export const RadioBoxGroup: React.FC<WrappedFieldProps & RadioBoxGroupProps> = ({
  children,
  input: { name, onChange },
  styleSheet,
}) => (
  <ul className={css(styles.list, styleSheet?.item)}>
    {React.Children.toArray(children).map((item: any, index: number) => (
      <li key={index}>{React.cloneElement(item, { name, onChange })}</li>
    ))}
  </ul>
);

export const RadioBoxField: React.FC<RadioBoxGroupProps> = ({ name, children, ...others }) => (
  <Field name={name} component={RadioBoxGroup} {...others}>
    {children}
  </Field>
);

export default RadioBoxField;
