const allowedActionPrefixes = [
  '@@redux-form/',
  'CCW/',
  'FEATURE_TOGGLE/',
  '@@INIT',
  'users/',
  'SELECT_MANAGED_GROUP/',
  'PROFILE/',
  'APP_BAR/',
];

const SET_LOCALE_ACTION = 'PROFILE/SET_LOCALE_SUCCESS';
const shouldAllowActionThrough = (type: string) =>
  !!allowedActionPrefixes.find((prefix) => type.startsWith(prefix));

export default () => (next: any) => {
  let localeSet = false;
  const actionQueue: Array<any> = [];

  return (action: any): void => {
    if (localeSet) {
      next(action);
      return;
    }

    if (action.type === SET_LOCALE_ACTION) {
      next(action); // This will allow the current action through.

      actionQueue.forEach((a) => next(a));
      actionQueue.splice(0);
      localeSet = true;

      return;
    }

    if (action.type && shouldAllowActionThrough(action.type)) {
      next(action);
      return;
    }

    actionQueue.push(action);
  };
};
