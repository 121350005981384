import { StyleSheet } from 'aphrodite/no-important';
import { palettes, typography } from '@trainline/depot-web/css/themes/trainline';

const typographyStyles = {
  fontSize: typography.dp_small_font_size_m,
  letterSpacing: typography.dp_small_letter_spacing,
  lineHeight: typography.dp_small_line_height,
};

export default StyleSheet.create({
  fromTo: {
    ...typographyStyles,
    maxWidth: '200px',
  },
  fromToUnlimited: {
    ...typographyStyles,
  },
});

export const fillColor = palettes.dp_brand_primary_core;
