import React from 'react';
import { css } from 'aphrodite/no-important';

import SeasonProduct from '@contactcentre-web/redux-common/types/SeasonProduct';

import Header from './components/Header';
import Details from './components/Details';
import styles from './styles';

export interface Props extends SeasonProduct {
  index: number;
  canBeReset: boolean;
  canBeCancelled: boolean;
}

const SeasonBooking = ({
  index,
  id,
  journey,
  validity,
  deliveryMethod,
  status,
  ctr,
  transactionTotalPrice,
  ticketType,
  externallyModified,
  conditionsOfUse,
  smartcardNumber,
  fulfilmentStatus,
  links,
  isFlexiSeason,
  canBeReset,
  canBeCancelled,
}: Props) => (
  <section className={css(styles.container)} data-test-id={`season-booking-${id}`}>
    <Header
      index={index}
      journey={journey}
      validity={validity}
      deliveryMethod={deliveryMethod}
      status={status}
      ctr={ctr}
      transactionTotalPrice={transactionTotalPrice}
      fulfilmentStatus={fulfilmentStatus}
      isFlexiSeason={isFlexiSeason}
    />
    <Details
      productId={id}
      journey={journey}
      ticketType={ticketType}
      externallyModified={externallyModified}
      conditionsOfUse={conditionsOfUse}
      smartcardNumber={smartcardNumber}
      deliveryMethod={deliveryMethod.id}
      links={links}
      isFlexiSeason={isFlexiSeason}
      canBeReset={canBeReset}
      canBeCancelled={canBeCancelled}
      validity={validity}
    />
  </section>
);

export default SeasonBooking;
