import { StyleSheet } from 'aphrodite/no-important';
import {
  borderRadius,
  palettes,
  spacers,
  typography,
} from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  card: {
    background: palettes.dp_app_backdrop_base,
    boxShadow: `0 0 4px ${palettes.dp_comp_box_shadow}, 0px 2px 6px ${palettes.dp_comp_box_shadow}`,
    borderRadius: borderRadius.dp_r4,
    padding: `${spacers.dp_s24} ${spacers.dp_s32}`,
    marginBottom: spacers.dp_s32,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'right',
  },
  content: {
    marginTop: spacers.dp_s16,
    borderTop: `1px solid ${palettes.dp_app_border_base}`,
    paddingTop: spacers.dp_s16,
  },
  itemsGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: spacers.dp_s32,
    rowGap: spacers.dp_s24,
  },
  table: {
    width: '100%',
    borderSpacing: 0,
    borderRadius: borderRadius.dp_r4,
    border: `1px solid ${palettes.dp_brand_neutral_lighter}`,
    borderCollapse: 'separate',
    marginTop: spacers.dp_s8,
  },
  tableHead: {
    padding: spacers.dp_s8,
    fontSize: typography.dp_small_font_size_m,
    lineHeight: typography.dp_small_line_height,
    letterSpacing: typography.dp_small_letter_spacing,
    color: palettes.dp_brand_neutral_mid,
    textTransform: 'uppercase',
    backgroundColor: palettes.dp_brand_neutral_lightest,
    borderLeft: `1px solid ${palettes.dp_brand_neutral_mid}`,
    ':first-child': { borderLeft: 'none' },
  },
  tableHeader: {
    padding: spacers.dp_s8,
    borderLeft: `1px solid ${palettes.dp_brand_neutral_lighter}`,
    ':first-child': { borderLeft: 'none' },
  },
  tableBody: {
    textAlign: 'center',
    display: 'table-row',
  },
  tableCell: {
    padding: spacers.dp_s8,
    display: 'table-cell',
    borderLeft: `1px solid ${palettes.dp_brand_neutral_lighter}`,
    borderTop: `1px solid ${palettes.dp_brand_neutral_lighter}`,
    ':first-child': { borderLeft: 'none' },
  },
  fare: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  fareIcon: {
    margin: `0 ${spacers.dp_s4}`,
  },
  paymentMethod: {
    textTransform: 'capitalize',
  },
});
