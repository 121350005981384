import { StyleSheet } from 'aphrodite/no-important';
import { palettes, borderRadius, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  tooltip: {
    maxWidth: 360,
    overflow: 'hidden',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
  },
  icon: {
    borderRadius: borderRadius.dp_r4,
    ':hover': {
      background: palettes.dp_comp_button_tertiary_background_pressed,
    },
  },
  tooltipMessage: {
    display: 'flex',
    alignItems: 'center',
    marginTop: spacers.dp_s8,
  },
  tooltipMessageSource: {
    marginRight: spacers.dp_s4,
  },
  tooltipErrorMessage: {
    marginTop: spacers.dp_s8,
  },
});
