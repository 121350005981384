import { defineMessages } from 'react-intl';

export default defineMessages({
  fromTo: {
    id: 'Tickets.to',
    defaultMessage: 'From/To',
  },
  ticketType: {
    id: 'Tickets.ticketType',
    defaultMessage: 'Ticket type',
  },
  operator: {
    id: 'Tickets.operator',
    defaultMessage: 'Operator',
  },
  class: {
    id: 'Tickets.class',
    defaultMessage: 'Class',
  },
  passengers: {
    id: 'Tickets.passengers',
    defaultMessage: 'Passengers',
  },
  tcnNumber: {
    id: 'Tickets.tcnNumber',
    defaultMessage: 'TCN NO.',
  },
  tickets: {
    id: 'Tickets.tickets',
    defaultMessage: 'Ticket(s)',
  },
  status: {
    id: 'Tickets.status',
    defaultMessage: 'Status',
  },
  checkInUrl: {
    id: 'Tickets.checkinUrl',
    defaultMessage: 'Check-in URL',
  },
  travellerCheckIn: {
    id: 'Tickets.travellerCheckIn',
    defaultMessage: 'Traveller check-in',
  },
  pdfNotValid: {
    id: 'Tickets.pdfNotValid',
    defaultMessage: 'PDF not valid for travel',
  },
  advancePassengerInformationRequired: {
    id: 'Tickets.advancePassengerInformationRequired',
    defaultMessage: 'Advance passenger information required',
  },
});
