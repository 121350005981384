import React from 'react';

import ManagePermission from '@contactcentre-web/manage-permission/ManagePermission';
import PageContainer from '@contactcentre-web/common/PageContainer';

import styles from './styles';

const ManagePermissionPage = () => (
  <PageContainer styleSheet={styles.container}>
    <ManagePermission />
  </PageContainer>
);

export default ManagePermissionPage;
