const dayName = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const monthName = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const abreviateStr = (str: string) => str.substr(0, 3);

const abreviateWeekday = (day: number) => abreviateStr(dayName[day]);
const abreviateMonth = (month: number) => abreviateStr(monthName[month]);

const setLeadingZero = (str: number) => `0${str}`;

const getDateObject = (strDate: string) => {
  const date = new Date(strDate);
  return {
    day: date.getDay(),
    month: date.getMonth(),
    year: date.getFullYear(),
    hours: date.getHours(),
    minutes: date.getMinutes(),
  };
};

export const formatDate = (strDate: string) => {
  const { day, month, year } = getDateObject(strDate);
  const displayWeekday = abreviateWeekday(day);
  const displayMonth = abreviateMonth(month);
  return `${displayWeekday} ${day + 1} ${displayMonth} ${year}`;
};

export const formatTime = (strDate: string) => {
  const { hours, minutes } = getDateObject(strDate);
  const displayMinutes = minutes < 10 ? setLeadingZero(minutes) : minutes;
  return `${hours}:${displayMinutes}`;
};

export const fullDate = (strDate: string) => [formatDate(strDate), formatTime(strDate)].join(', ');

export const sortByDateProp = (list: any, propWithDateStr: string, orderDesc?: boolean) => {
  const sorted = [...list].sort(
    (a, b) => new Date(a[propWithDateStr]).getTime() - new Date(b[propWithDateStr]).getTime()
  );
  return orderDesc ? sorted.reverse() : sorted;
};

export const sortByDatePropWithExtract = (
  list: any,
  propWithDateStr: (value: any) => string | Date,
  orderDesc: boolean
) => {
  const sorted = [...list].sort(
    (a, b) => new Date(propWithDateStr(a)).getTime() - new Date(propWithDateStr(b)).getTime()
  );
  return orderDesc ? sorted.reverse() : sorted;
};

export default fullDate;
