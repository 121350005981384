import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import Price from '@contactcentre-web/redux-common/types/Price';

import CompensationApprovalCard from '../CompensationApprovalCard/CompensationApprovalCard';
import type { Approval } from '../../module';

import messages from './messages';
import styles from './styles';

interface StateProps {
  approvals: Approval[];
  isResolutionInProgress: boolean;
  hasResolutionSucceeded: boolean;
}

interface DispatchProps {
  onResolution: (
    type: string,
    id: string,
    amount: Price,
    orderId: string,
    reason?: string,
    note?: string
  ) => void;
  onEdit: (customerId: string, orderId: string, compensationId: string) => void;
}

const CompensationApprovalList: FunctionComponent<StateProps & DispatchProps> = ({
  approvals,
  onResolution,
  ...others
}) =>
  approvals.length === 0 ? (
    <div className={css(styles.container)}>
      <FormattedMessage {...messages.noPendingCompensations} />
    </div>
  ) : (
    <section>
      {approvals.map(({ id, amount, orderId, ...approval }) => (
        <CompensationApprovalCard
          key={id}
          approval={{ id, amount, orderId, ...approval }}
          {...others}
          onApprove={() => onResolution('confirm', id, amount, orderId)}
          onReject={(data: { reason?: string; note?: string }) =>
            onResolution('reject', id, amount, orderId, data?.reason, data?.note)
          }
        />
      ))}
    </section>
  );

export default CompensationApprovalList;
