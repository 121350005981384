import React, { FunctionComponent } from 'react';
import { css } from 'aphrodite/no-important';

import ChangeManagedGroupModal from './ChangeManagedGroupModal';
import styles from './styles';

interface Props {
  managedGroup: string;
  roleName: string | null;
  canChangeManagedGroup: boolean;
}

const ManagedGroup: FunctionComponent<Props> = ({
  managedGroup,
  roleName,
  canChangeManagedGroup,
}) => (
  <div className={css(styles.container)}>
    <span className={css(styles.title)}>{roleName}</span>
    <div className={css(styles.text)}>
      {managedGroup}
      {canChangeManagedGroup && (
        <>
          {' | '}
          <ChangeManagedGroupModal />
        </>
      )}
    </div>
  </div>
);

export default ManagedGroup;
