import { StyleSheet } from 'aphrodite/no-important';
import { spacers, palettes, borderRadius } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  approvalQueueOrderReference: {
    textDecoration: 'none',
    color: 'inherit',
  },
  approvalQueueItemWrapper: {
    border: `1px solid ${palettes.dp_app_border_base}`,
    borderRadius: borderRadius.dp_r8,
    padding: spacers.dp_s24,
    margin: `${spacers.dp_s16} 0`,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    alignItems: 'center',
    background: palettes.dp_app_backdrop_base,
  },
  approvalQueueItemWrapperOpen: {
    boxShadow: `0 0 0 2px ${palettes.dp_comp_input_border_focus}`,
    borderColor: 'transparent',
  },
  approvalQueueItemColumnLeft: {
    display: 'flex',
    columnGap: spacers.dp_s4,
  },
  approvalQueueItemColumnRight: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    columnGap: spacers.dp_s16,
    textAlign: 'right',
  },
  approvalQueueItemIcon: {
    color: palettes.dp_app_link_base,
  },
  approvalQueueItemButton: {
    gridArea: '1 / 2 / 3 / 3',
    backgroundColor: palettes.dp_app_backdrop_dark,
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: borderRadius.dp_r4,
  },
  approvalQueueItemExpandableContent: {
    gridColumn: '1 / -1',
    borderTop: `1px solid ${palettes.dp_app_border_base}`,
    paddingTop: spacers.dp_s24,
  },
  approvalQueueItemExpandableContainer: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr auto',
    columnGap: spacers.dp_s48,
  },
  approvalQueueItemExpandableContentColumnLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    rowGap: spacers.dp_s16,
  },
  approvalQueueItemExpandableContentColumnRight: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacers.dp_s12,
  },
  approvalQueueItemExpandableContentColumnCenterWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  approvalQueueItemExpandableContentStatusMessages: {
    gridArea: '1 / 1 / 2 / 4',
    marginBottom: spacers.dp_s24,
  },
});
