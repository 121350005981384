import { defineMessages } from 'react-intl';

export default defineMessages({
  railcardsHeader: {
    id: 'CustomerPassengersRailcards.railcardsHeader',
    defaultMessage: 'Railcards purchased at Trainline',
  },
  railcardsNotAvailable: {
    id: 'CustomerPassengersRailcards.railcardsNotAvailable',
    defaultMessage: 'No cards',
  },
  railcardsFooter: {
    id: 'CustomerPassengersRailcards.railcardsFooter',
    defaultMessage: 'These cards are linked to the account holder, so these cannot be edited',
  },
});
