import { defineMessages } from 'react-intl';

export default defineMessages({
  notExisting: {
    id: 'CountryName.notExisting',
    defaultMessage: 'N/A',
  },
  af: {
    id: 'CountryName.af',
    defaultMessage: 'Afghanistan',
  },
  ax: {
    id: 'CountryName.ax',
    defaultMessage: 'Aland Islands',
  },
  al: {
    id: 'CountryName.al',
    defaultMessage: 'Albania',
  },
  dz: {
    id: 'CountryName.dz',
    defaultMessage: 'Algeria',
  },
  as: {
    id: 'CountryName.as',
    defaultMessage: 'American Samoa',
  },
  ad: {
    id: 'CountryName.ad',
    defaultMessage: 'Andorra',
  },
  ao: {
    id: 'CountryName.ao',
    defaultMessage: 'Angola',
  },
  ai: {
    id: 'CountryName.ai',
    defaultMessage: 'Anguilla',
  },
  aq: {
    id: 'CountryName.aq',
    defaultMessage: 'Antarctica',
  },
  ag: {
    id: 'CountryName.ag',
    defaultMessage: 'Antigua and Barbuda',
  },
  ar: {
    id: 'CountryName.ar',
    defaultMessage: 'Argentina',
  },
  am: {
    id: 'CountryName.am',
    defaultMessage: 'Armenia',
  },
  aw: {
    id: 'CountryName.aw',
    defaultMessage: 'Aruba',
  },
  au: {
    id: 'CountryName.au',
    defaultMessage: 'Australia',
  },
  at: {
    id: 'CountryName.at',
    defaultMessage: 'Austria',
  },
  az: {
    id: 'CountryName.az',
    defaultMessage: 'Azerbaijan',
  },
  bs: {
    id: 'CountryName.bs',
    defaultMessage: 'Bahamas',
  },
  bh: {
    id: 'CountryName.bh',
    defaultMessage: 'Bahrain',
  },
  bd: {
    id: 'CountryName.bd',
    defaultMessage: 'Bangladesh',
  },
  bb: {
    id: 'CountryName.bb',
    defaultMessage: 'Barbados',
  },
  by: {
    id: 'CountryName.by',
    defaultMessage: 'Belarus',
  },
  be: {
    id: 'CountryName.be',
    defaultMessage: 'Belgium',
  },
  bz: {
    id: 'CountryName.bz',
    defaultMessage: 'Belize',
  },
  bj: {
    id: 'CountryName.bj',
    defaultMessage: 'Benin',
  },
  bm: {
    id: 'CountryName.bm',
    defaultMessage: 'Bermuda',
  },
  bt: {
    id: 'CountryName.bt',
    defaultMessage: 'Bhutan',
  },
  bo: {
    id: 'CountryName.bo',
    defaultMessage: 'Bolivia',
  },
  ba: {
    id: 'CountryName.ba',
    defaultMessage: 'Bosnia and Herzegovina',
  },
  bw: {
    id: 'CountryName.bw',
    defaultMessage: 'Botswana',
  },
  bv: {
    id: 'CountryName.bv',
    defaultMessage: 'Bouvet Island',
  },
  br: {
    id: 'CountryName.br',
    defaultMessage: 'Brazil',
  },
  vg: {
    id: 'CountryName.vg',
    defaultMessage: 'British Virgin Islands',
  },
  io: {
    id: 'CountryName.io',
    defaultMessage: 'British Indian Ocean Territory',
  },
  bn: {
    id: 'CountryName.bn',
    defaultMessage: 'Brunei Darussalam',
  },
  bg: {
    id: 'CountryName.bg',
    defaultMessage: 'Bulgaria',
  },
  bf: {
    id: 'CountryName.bf',
    defaultMessage: 'Burkina Faso',
  },
  bi: {
    id: 'CountryName.bi',
    defaultMessage: 'Burundi',
  },
  kh: {
    id: 'CountryName.kh',
    defaultMessage: 'Cambodia',
  },
  cm: {
    id: 'CountryName.cm',
    defaultMessage: 'Cameroon',
  },
  ca: {
    id: 'CountryName.ca',
    defaultMessage: 'Canada',
  },
  cv: {
    id: 'CountryName.cv',
    defaultMessage: 'Cape Verde',
  },
  ky: {
    id: 'CountryName.ky',
    defaultMessage: 'Cayman Islands',
  },
  cf: {
    id: 'CountryName.cf',
    defaultMessage: 'Central African Republic',
  },
  td: {
    id: 'CountryName.td',
    defaultMessage: 'Chad',
  },
  cl: {
    id: 'CountryName.cl',
    defaultMessage: 'Chile',
  },
  cn: {
    id: 'CountryName.cn',
    defaultMessage: 'China',
  },
  hk: {
    id: 'CountryName.hk',
    defaultMessage: 'Hong Kong, SAR China',
  },
  mo: {
    id: 'CountryName.mo',
    defaultMessage: 'Macao, SAR China',
  },
  cx: {
    id: 'CountryName.cx',
    defaultMessage: 'Christmas Island',
  },
  cc: {
    id: 'CountryName.cc',
    defaultMessage: 'Cocos (Keeling) Islands',
  },
  co: {
    id: 'CountryName.co',
    defaultMessage: 'Colombia',
  },
  km: {
    id: 'CountryName.km',
    defaultMessage: 'Comoros',
  },
  cg: {
    id: 'CountryName.cg',
    defaultMessage: 'Congo (Brazzaville)',
  },
  cd: {
    id: 'CountryName.cd',
    defaultMessage: 'Congo, (Kinshasa)',
  },
  ck: {
    id: 'CountryName.ck',
    defaultMessage: 'Cook Islands',
  },
  cr: {
    id: 'CountryName.cr',
    defaultMessage: 'Costa Rica',
  },
  ci: {
    id: 'CountryName.ci',
    defaultMessage: "Côte d'Ivoire",
  },
  hr: {
    id: 'CountryName.hr',
    defaultMessage: 'Croatia',
  },
  cu: {
    id: 'CountryName.cu',
    defaultMessage: 'Cuba',
  },
  cy: {
    id: 'CountryName.cy',
    defaultMessage: 'Cyprus',
  },
  cz: {
    id: 'CountryName.cz',
    defaultMessage: 'Czech Republic',
  },
  dk: {
    id: 'CountryName.dk',
    defaultMessage: 'Denmark',
  },
  dj: {
    id: 'CountryName.dj',
    defaultMessage: 'Djibouti',
  },
  dm: {
    id: 'CountryName.dm',
    defaultMessage: 'Dominica',
  },
  do: {
    id: 'CountryName.do',
    defaultMessage: 'Dominican Republic',
  },
  ec: {
    id: 'CountryName.ec',
    defaultMessage: 'Ecuador',
  },
  eg: {
    id: 'CountryName.eg',
    defaultMessage: 'Egypt',
  },
  sv: {
    id: 'CountryName.sv',
    defaultMessage: 'El Salvador',
  },
  gq: {
    id: 'CountryName.gq',
    defaultMessage: 'Equatorial Guinea',
  },
  er: {
    id: 'CountryName.er',
    defaultMessage: 'Eritrea',
  },
  ee: {
    id: 'CountryName.ee',
    defaultMessage: 'Estonia',
  },
  et: {
    id: 'CountryName.et',
    defaultMessage: 'Ethiopia',
  },
  fk: {
    id: 'CountryName.fk',
    defaultMessage: 'Falkland Islands (Malvinas)',
  },
  fo: {
    id: 'CountryName.fo',
    defaultMessage: 'Faroe Islands',
  },
  fj: {
    id: 'CountryName.fj',
    defaultMessage: 'Fiji',
  },
  fi: {
    id: 'CountryName.fi',
    defaultMessage: 'Finland',
  },
  fr: {
    id: 'CountryName.fr',
    defaultMessage: 'France',
  },
  gf: {
    id: 'CountryName.gf',
    defaultMessage: 'French Guiana',
  },
  pf: {
    id: 'CountryName.pf',
    defaultMessage: 'French Polynesia',
  },
  tf: {
    id: 'CountryName.tf',
    defaultMessage: 'French Southern Territories',
  },
  ga: {
    id: 'CountryName.ga',
    defaultMessage: 'Gabon',
  },
  gm: {
    id: 'CountryName.gm',
    defaultMessage: 'Gambia',
  },
  ge: {
    id: 'CountryName.ge',
    defaultMessage: 'Georgia',
  },
  de: {
    id: 'CountryName.de',
    defaultMessage: 'Germany',
  },
  gh: {
    id: 'CountryName.gh',
    defaultMessage: 'Ghana',
  },
  gi: {
    id: 'CountryName.gi',
    defaultMessage: 'Gibraltar',
  },
  gr: {
    id: 'CountryName.gr',
    defaultMessage: 'Greece',
  },
  gl: {
    id: 'CountryName.gl',
    defaultMessage: 'Greenland',
  },
  gd: {
    id: 'CountryName.gd',
    defaultMessage: 'Grenada',
  },
  gp: {
    id: 'CountryName.gp',
    defaultMessage: 'Guadeloupe',
  },
  gu: {
    id: 'CountryName.gu',
    defaultMessage: 'Guam',
  },
  gt: {
    id: 'CountryName.gt',
    defaultMessage: 'Guatemala',
  },
  gg: {
    id: 'CountryName.gg',
    defaultMessage: 'Guernsey',
  },
  gn: {
    id: 'CountryName.gn',
    defaultMessage: 'Guinea',
  },
  gw: {
    id: 'CountryName.gw',
    defaultMessage: 'Guinea-Bissau',
  },
  gy: {
    id: 'CountryName.gy',
    defaultMessage: 'Guyana',
  },
  ht: {
    id: 'CountryName.ht',
    defaultMessage: 'Haiti',
  },
  hm: {
    id: 'CountryName.hm',
    defaultMessage: 'Heard and Mcdonald Islands',
  },
  va: {
    id: 'CountryName.va',
    defaultMessage: 'Holy See (Vatican City State)',
  },
  hn: {
    id: 'CountryName.hn',
    defaultMessage: 'Honduras',
  },
  hu: {
    id: 'CountryName.hu',
    defaultMessage: 'Hungary',
  },
  is: {
    id: 'CountryName.is',
    defaultMessage: 'Iceland',
  },
  in: {
    id: 'CountryName.in',
    defaultMessage: 'India',
  },
  id: {
    id: 'CountryName.id',
    defaultMessage: 'Indonesia',
  },
  ir: {
    id: 'CountryName.ir',
    defaultMessage: 'Iran, Islamic Republic of',
  },
  iq: {
    id: 'CountryName.iq',
    defaultMessage: 'Iraq',
  },
  ie: {
    id: 'CountryName.ie',
    defaultMessage: 'Ireland',
  },
  im: {
    id: 'CountryName.im',
    defaultMessage: 'Isle of Man',
  },
  il: {
    id: 'CountryName.il',
    defaultMessage: 'Israel',
  },
  it: {
    id: 'CountryName.it',
    defaultMessage: 'Italy',
  },
  jm: {
    id: 'CountryName.jm',
    defaultMessage: 'Jamaica',
  },
  jp: {
    id: 'CountryName.jp',
    defaultMessage: 'Japan',
  },
  je: {
    id: 'CountryName.je',
    defaultMessage: 'Jersey',
  },
  jo: {
    id: 'CountryName.jo',
    defaultMessage: 'Jordan',
  },
  kz: {
    id: 'CountryName.kz',
    defaultMessage: 'Kazakhstan',
  },
  ke: {
    id: 'CountryName.ke',
    defaultMessage: 'Kenya',
  },
  ki: {
    id: 'CountryName.ki',
    defaultMessage: 'Kiribati',
  },
  kp: {
    id: 'CountryName.kp',
    defaultMessage: 'Korea (North)',
  },
  kr: {
    id: 'CountryName.kr',
    defaultMessage: 'Korea (South)',
  },
  kw: {
    id: 'CountryName.kw',
    defaultMessage: 'Kuwait',
  },
  kg: {
    id: 'CountryName.kg',
    defaultMessage: 'Kyrgyzstan',
  },
  la: {
    id: 'CountryName.la',
    defaultMessage: 'Lao PDR',
  },
  lv: {
    id: 'CountryName.lv',
    defaultMessage: 'Latvia',
  },
  lb: {
    id: 'CountryName.lb',
    defaultMessage: 'Lebanon',
  },
  ls: {
    id: 'CountryName.ls',
    defaultMessage: 'Lesotho',
  },
  lr: {
    id: 'CountryName.lr',
    defaultMessage: 'Liberia',
  },
  ly: {
    id: 'CountryName.ly',
    defaultMessage: 'Libya',
  },
  li: {
    id: 'CountryName.li',
    defaultMessage: 'Liechtenstein',
  },
  lt: {
    id: 'CountryName.lt',
    defaultMessage: 'Lithuania',
  },
  lu: {
    id: 'CountryName.lu',
    defaultMessage: 'Luxembourg',
  },
  mk: {
    id: 'CountryName.mk',
    defaultMessage: 'Macedonia, Republic of',
  },
  mg: {
    id: 'CountryName.mg',
    defaultMessage: 'Madagascar',
  },
  mw: {
    id: 'CountryName.mw',
    defaultMessage: 'Malawi',
  },
  my: {
    id: 'CountryName.my',
    defaultMessage: 'Malaysia',
  },
  mv: {
    id: 'CountryName.mv',
    defaultMessage: 'Maldives',
  },
  ml: {
    id: 'CountryName.ml',
    defaultMessage: 'Mali',
  },
  mt: {
    id: 'CountryName.mt',
    defaultMessage: 'Malta',
  },
  mh: {
    id: 'CountryName.mh',
    defaultMessage: 'Marshall Islands',
  },
  mq: {
    id: 'CountryName.mq',
    defaultMessage: 'Martinique',
  },
  mr: {
    id: 'CountryName.mr',
    defaultMessage: 'Mauritania',
  },
  mu: {
    id: 'CountryName.mu',
    defaultMessage: 'Mauritius',
  },
  yt: {
    id: 'CountryName.yt',
    defaultMessage: 'Mayotte',
  },
  mx: {
    id: 'CountryName.mx',
    defaultMessage: 'Mexico',
  },
  fm: {
    id: 'CountryName.fm',
    defaultMessage: 'Micronesia, Federated States of',
  },
  md: {
    id: 'CountryName.md',
    defaultMessage: 'Moldova',
  },
  mc: {
    id: 'CountryName.mc',
    defaultMessage: 'Monaco',
  },
  mn: {
    id: 'CountryName.mn',
    defaultMessage: 'Mongolia',
  },
  me: {
    id: 'CountryName.me',
    defaultMessage: 'Montenegro',
  },
  ms: {
    id: 'CountryName.ms',
    defaultMessage: 'Montserrat',
  },
  ma: {
    id: 'CountryName.ma',
    defaultMessage: 'Morocco',
  },
  mz: {
    id: 'CountryName.mz',
    defaultMessage: 'Mozambique',
  },
  mm: {
    id: 'CountryName.mm',
    defaultMessage: 'Myanmar',
  },
  na: {
    id: 'CountryName.na',
    defaultMessage: 'Namibia',
  },
  nr: {
    id: 'CountryName.nr',
    defaultMessage: 'Nauru',
  },
  np: {
    id: 'CountryName.np',
    defaultMessage: 'Nepal',
  },
  nl: {
    id: 'CountryName.nl',
    defaultMessage: 'Netherlands',
  },
  an: {
    id: 'CountryName.an',
    defaultMessage: 'Netherlands Antilles',
  },
  nc: {
    id: 'CountryName.nc',
    defaultMessage: 'New Caledonia',
  },
  nz: {
    id: 'CountryName.nz',
    defaultMessage: 'New Zealand',
  },
  ni: {
    id: 'CountryName.ni',
    defaultMessage: 'Nicaragua',
  },
  ne: {
    id: 'CountryName.ne',
    defaultMessage: 'Niger',
  },
  ng: {
    id: 'CountryName.ng',
    defaultMessage: 'Nigeria',
  },
  nu: {
    id: 'CountryName.nu',
    defaultMessage: 'Niue',
  },
  nf: {
    id: 'CountryName.nf',
    defaultMessage: 'Norfolk Island',
  },
  mp: {
    id: 'CountryName.mp',
    defaultMessage: 'Northern Mariana Islands',
  },
  no: {
    id: 'CountryName.no',
    defaultMessage: 'Norway',
  },
  om: {
    id: 'CountryName.om',
    defaultMessage: 'Oman',
  },
  pk: {
    id: 'CountryName.pk',
    defaultMessage: 'Pakistan',
  },
  pw: {
    id: 'CountryName.pw',
    defaultMessage: 'Palau',
  },
  ps: {
    id: 'CountryName.ps',
    defaultMessage: 'Palestinian Territory',
  },
  pa: {
    id: 'CountryName.pa',
    defaultMessage: 'Panama',
  },
  pg: {
    id: 'CountryName.pg',
    defaultMessage: 'Papua New Guinea',
  },
  py: {
    id: 'CountryName.py',
    defaultMessage: 'Paraguay',
  },
  pe: {
    id: 'CountryName.pe',
    defaultMessage: 'Peru',
  },
  ph: {
    id: 'CountryName.ph',
    defaultMessage: 'Philippines',
  },
  pn: {
    id: 'CountryName.pn',
    defaultMessage: 'Pitcairn',
  },
  pl: {
    id: 'CountryName.pl',
    defaultMessage: 'Poland',
  },
  pt: {
    id: 'CountryName.pt',
    defaultMessage: 'Portugal',
  },
  pr: {
    id: 'CountryName.pr',
    defaultMessage: 'Puerto Rico',
  },
  qa: {
    id: 'CountryName.qa',
    defaultMessage: 'Qatar',
  },
  re: {
    id: 'CountryName.re',
    defaultMessage: 'Réunion',
  },
  ro: {
    id: 'CountryName.ro',
    defaultMessage: 'Romania',
  },
  ru: {
    id: 'CountryName.ru',
    defaultMessage: 'Russian Federation',
  },
  rw: {
    id: 'CountryName.rw',
    defaultMessage: 'Rwanda',
  },
  bl: {
    id: 'CountryName.bl',
    defaultMessage: 'Saint-Barthélemy',
  },
  sh: {
    id: 'CountryName.sh',
    defaultMessage: 'Saint Helena',
  },
  kn: {
    id: 'CountryName.kn',
    defaultMessage: 'Saint Kitts and Nevis',
  },
  lc: {
    id: 'CountryName.lc',
    defaultMessage: 'Saint Lucia',
  },
  mf: {
    id: 'CountryName.mf',
    defaultMessage: 'Saint-Martin (French part)',
  },
  pm: {
    id: 'CountryName.pm',
    defaultMessage: 'Saint Pierre and Miquelon',
  },
  vc: {
    id: 'CountryName.vc',
    defaultMessage: 'Saint Vincent and Grenadines',
  },
  ws: {
    id: 'CountryName.ws',
    defaultMessage: 'Samoa',
  },
  sm: {
    id: 'CountryName.sm',
    defaultMessage: 'San Marino',
  },
  st: {
    id: 'CountryName.st',
    defaultMessage: 'Sao Tome and Principe',
  },
  sa: {
    id: 'CountryName.sa',
    defaultMessage: 'Saudi Arabia',
  },
  sn: {
    id: 'CountryName.sn',
    defaultMessage: 'Senegal',
  },
  rs: {
    id: 'CountryName.rs',
    defaultMessage: 'Serbia',
  },
  sc: {
    id: 'CountryName.sc',
    defaultMessage: 'Seychelles',
  },
  sl: {
    id: 'CountryName.sl',
    defaultMessage: 'Sierra Leone',
  },
  sg: {
    id: 'CountryName.sg',
    defaultMessage: 'Singapore',
  },
  sk: {
    id: 'CountryName.sk',
    defaultMessage: 'Slovakia',
  },
  si: {
    id: 'CountryName.si',
    defaultMessage: 'Slovenia',
  },
  sb: {
    id: 'CountryName.sb',
    defaultMessage: 'Solomon Islands',
  },
  so: {
    id: 'CountryName.so',
    defaultMessage: 'Somalia',
  },
  za: {
    id: 'CountryName.za',
    defaultMessage: 'South Africa',
  },
  gs: {
    id: 'CountryName.gs',
    defaultMessage: 'South Georgia and the South Sandwich Islands',
  },
  ss: {
    id: 'CountryName.ss',
    defaultMessage: 'South Sudan',
  },
  es: {
    id: 'CountryName.es',
    defaultMessage: 'Spain',
  },
  lk: {
    id: 'CountryName.lk',
    defaultMessage: 'Sri Lanka',
  },
  sd: {
    id: 'CountryName.sd',
    defaultMessage: 'Sudan',
  },
  sr: {
    id: 'CountryName.sr',
    defaultMessage: 'Suriname',
  },
  sj: {
    id: 'CountryName.sj',
    defaultMessage: 'Svalbard and Jan Mayen Islands',
  },
  sz: {
    id: 'CountryName.sz',
    defaultMessage: 'Swaziland',
  },
  se: {
    id: 'CountryName.se',
    defaultMessage: 'Sweden',
  },
  ch: {
    id: 'CountryName.ch',
    defaultMessage: 'Switzerland',
  },
  sy: {
    id: 'CountryName.sy',
    defaultMessage: 'Syrian Arab Republic (Syria)',
  },
  tw: {
    id: 'CountryName.tw',
    defaultMessage: 'Taiwan, Republic of China',
  },
  tj: {
    id: 'CountryName.tj',
    defaultMessage: 'Tajikistan',
  },
  tz: {
    id: 'CountryName.tz',
    defaultMessage: 'Tanzania, United Republic of',
  },
  th: {
    id: 'CountryName.th',
    defaultMessage: 'Thailand',
  },
  tl: {
    id: 'CountryName.tl',
    defaultMessage: 'Timor-Leste',
  },
  tg: {
    id: 'CountryName.tg',
    defaultMessage: 'Togo',
  },
  tk: {
    id: 'CountryName.tk',
    defaultMessage: 'Tokelau',
  },
  to: {
    id: 'CountryName.to',
    defaultMessage: 'Tonga',
  },
  tt: {
    id: 'CountryName.tt',
    defaultMessage: 'Trinidad and Tobago',
  },
  tn: {
    id: 'CountryName.tn',
    defaultMessage: 'Tunisia',
  },
  tr: {
    id: 'CountryName.tr',
    defaultMessage: 'Turkey',
  },
  tm: {
    id: 'CountryName.tm',
    defaultMessage: 'Turkmenistan',
  },
  tc: {
    id: 'CountryName.tc',
    defaultMessage: 'Turks and Caicos Islands',
  },
  tv: {
    id: 'CountryName.tv',
    defaultMessage: 'Tuvalu',
  },
  ug: {
    id: 'CountryName.ug',
    defaultMessage: 'Uganda',
  },
  ua: {
    id: 'CountryName.ua',
    defaultMessage: 'Ukraine',
  },
  ae: {
    id: 'CountryName.ae',
    defaultMessage: 'United Arab Emirates',
  },
  gb: {
    id: 'CountryName.gb',
    defaultMessage: 'United Kingdom',
  },
  us: {
    id: 'CountryName.us',
    defaultMessage: 'United States of America',
  },
  um: {
    id: 'CountryName.um',
    defaultMessage: 'US Minor Outlying Islands',
  },
  uy: {
    id: 'CountryName.uy',
    defaultMessage: 'Uruguay',
  },
  uz: {
    id: 'CountryName.uz',
    defaultMessage: 'Uzbekistan',
  },
  vu: {
    id: 'CountryName.vu',
    defaultMessage: 'Vanuatu',
  },
  ve: {
    id: 'CountryName.ve',
    defaultMessage: 'Venezuela (Bolivarian Republic)',
  },
  vn: {
    id: 'CountryName.vn',
    defaultMessage: 'Viet Nam',
  },
  vi: {
    id: 'CountryName.vi',
    defaultMessage: 'Virgin Islands, US',
  },
  wf: {
    id: 'CountryName.wf',
    defaultMessage: 'Wallis and Futuna Islands',
  },
  eh: {
    id: 'CountryName.eh',
    defaultMessage: 'Western Sahara',
  },
  ye: {
    id: 'CountryName.ye',
    defaultMessage: 'Yemen',
  },
  zm: {
    id: 'CountryName.zm',
    defaultMessage: 'Zambia',
  },
  zw: {
    id: 'CountryName.zw',
    defaultMessage: 'Zimbabwe',
  },
});
