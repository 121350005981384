import { defineMessages } from 'react-intl';

export default defineMessages({
  noResultsSearchProductReference: {
    id: 'NoResults.noResultsProductReferenceType',
    defaultMessage:
      'Multiple products in different managed groups were found, contact Service Desk for support.',
  },
  noResultsSearchUnknown: {
    id: 'NoResults.noResultsSearchUnknow',
    defaultMessage: 'No results found',
  },
});
