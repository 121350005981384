import React, { FunctionComponent } from 'react';
import { css } from 'aphrodite/no-important';
import { IconModeOfTransportTrain, IconPassenger } from '@trainline/depot-web';

import type { Topic } from '../../module';

import styles from './styles';

const iconMap = {
  Customer: IconPassenger,
  Order: IconModeOfTransportTrain,
};

interface Props {
  topic: Topic;
}

const NotesTopicIcon: FunctionComponent<Props> = ({ topic }) => {
  const Icon = iconMap[topic];
  return Icon ? <Icon className={css(styles.icon)} /> : null;
};

export default NotesTopicIcon;
