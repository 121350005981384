import { defineMessages } from 'react-intl';

export default defineMessages({
  entanglementsModalTitle: {
    id: 'EntanglementsModal.entanglementsModalTitle',
    defaultMessage: 'What’s an entanglement?',
  },
  entanglementsModalSubTitle: {
    id: 'EntanglementsModal.entanglementsModalSubTitle',
    defaultMessage:
      'If two or more refundable items depend on each other, they make an entanglement.',
  },
  entanglementsModalBody: {
    id: 'EntanglementsModal.entanglementsModalBody',
    defaultMessage:
      'This could be because they were bought with a Railcard, or a group discount was applied.',
  },
  entanglementsModalInfo: {
    id: 'EntanglementsModal.entanglementsModalInfo',
    defaultMessage:
      'To check <strong>which items can be refunded together</strong>, hit the info icon next to any selected ticket.',
  },
  entanglementsModalButton: {
    id: 'EntanglementsModal.entanglementsModalButton',
    defaultMessage: 'Got it',
  },
});
