import { createActions } from 'redux-actions';

// Constants
const PREFIX = 'SDV/';
export const DISPOSE = `${PREFIX}DISPOSE`;

export const LOAD_NONE = `${PREFIX}LOAD_NONE`;
export const LOAD_ATTEMPT = `${PREFIX}LOAD_ATTEMPT`;
export const LOAD_QUOTE = `${PREFIX}LOAD_QUOTE`;
export const LOAD_SUCCESS = `${PREFIX}LOAD_SUCCESS`;
export const LOAD_FAILED = `${PREFIX}LOAD_FAILED`;

export const UPDATE_ATTEMPT = `${PREFIX}UPDATE_ATTEMPT`;
export const UPDATE_SUCCESS = `${PREFIX}UPDATE_SUCCESS`;
export const UPDATE_FAILED = `${PREFIX}UPDATE_FAILED`;

export const REFUND_ATTEMPT = `${PREFIX}REFUND_ATTEMPT`;
export const REFUND_SUCCESS = `${PREFIX}REFUND_SUCCESS`;
export const REFUND_FAILED = `${PREFIX}REFUND_FAILED`;

export const load = {
  LOAD_ATTEMPT,
  LOAD_SUCCESS,
  LOAD_FAILED,
};

export const update = {
  UPDATE_ATTEMPT,
  UPDATE_SUCCESS,
  UPDATE_FAILED,
};

export const refund = {
  REFUND_ATTEMPT,
  REFUND_SUCCESS,
  REFUND_FAILED,
};

const nullPayloadCreator = () => null;

const { sdv: actions } = createActions({
  [DISPOSE]: () => null,
  [LOAD_ATTEMPT]: (orderReference, customerId) => ({
    orderReference,
    customerId,
  }),
  [LOAD_SUCCESS]: (order) => order,
  [LOAD_FAILED]: nullPayloadCreator,
  [UPDATE_ATTEMPT]: (refundableIds, override) => ({ refundableIds, override }),
  [UPDATE_SUCCESS]: (quote) => quote,
  [UPDATE_FAILED]: nullPayloadCreator,
  [REFUND_ATTEMPT]: nullPayloadCreator,
  [REFUND_SUCCESS]: nullPayloadCreator,
  [REFUND_FAILED]: (error, unprocessableReasons) => ({ error, unprocessableReasons }),
});

export default actions;
