import { spacers, typography, palettes } from '@trainline/depot-web/css/themes/trainline';
import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({
  list: {
    border: 'solid 1px #c2dafc',
    display: 'inline-block',
    listStyle: 'none',
    overflowY: 'auto',
  },
  option: {
    fontSize: typography.dp_small_font_size_m,
    lineHeight: typography.dp_small_line_height,
    ':hover': {
      backgroundColor: palettes.dp_comp_table_row_hover,
    },
  },
  selected: {
    backgroundColor: '#4b96f8',
  },
  button: {
    padding: `${spacers.dp_s8} ${spacers.dp_s24}`,
    width: '100%',
    display: 'block',
  },
});
