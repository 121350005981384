import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';

import styles from '../styles';

const PaginatorCount = ({ currrentPage, pageItemsLength, ...props }) => (
  <div {...props} className={css(styles.pageCount)}>
    {`Page ${currrentPage + 1} of ${pageItemsLength}`}
  </div>
);

PaginatorCount.propTypes = {
  currrentPage: PropTypes.number.isRequired,
  pageItemsLength: PropTypes.number.isRequired,
};

export default PaginatorCount;
