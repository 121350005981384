import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import FulfilmentStatus from '@contactcentre-web/common/FulfilmentStatus';
import VendorStatus from '@contactcentre-web/common/VendorStatus';
import TooltipLabel from '@contactcentre-web/common/TooltipLabel';
import OriginToDestination from '@contactcentre-web/common/OriginToDestination/OriginToDestination';

import styles from './styles';
import messages from './messages';
import { FramedLabel } from './BookingsTableRow';

const InsuranceTableRow = ({
  description,
  fulfilmentStatus,
  vendor,
  vendorStatus,
  reimbursement: { hasBeenReimbursed },
  journeyDetails,
}) => (
  <div className={css(styles.row, styles.data)}>
    <div className={css(styles.cell, styles.booking)}>
      <TooltipLabel
        styleSheet={styles.insurance}
        helpTip={journeyDetails.map(({ origin, destination, isReturn }, idx) => (
          <OriginToDestination
            key={`tooltip-${idx}`}
            isReturn={isReturn}
            origin={origin}
            destination={destination}
          />
        ))}
      >
        {description}
      </TooltipLabel>
    </div>
    <div className={css(styles.cell)}>{vendor}</div>
    <div className={css(styles.cell)}>
      <FulfilmentStatus status={fulfilmentStatus} />
    </div>
    <div className={css(styles.cell)}>
      <VendorStatus status={vendorStatus} highlighting="off" />
    </div>
    <div className={css(styles.cell)}>
      {fulfilmentStatus.toLowerCase() === 'failed' &&
        (hasBeenReimbursed ? (
          <FramedLabel level="positive">
            <FormattedMessage {...messages.yes} />
          </FramedLabel>
        ) : (
          <FramedLabel level="negative">
            <FormattedMessage {...messages.no} />
          </FramedLabel>
        ))}
      {fulfilmentStatus.toLowerCase() !== 'failed' && (
        <FormattedMessage {...messages.notApplicable} />
      )}
    </div>
  </div>
);

InsuranceTableRow.propTypes = {
  description: PropTypes.string.isRequired,
  fulfilmentStatus: PropTypes.string.isRequired,
  vendor: PropTypes.string.isRequired,
  vendorStatus: PropTypes.string.isRequired,
  reimbursement: PropTypes.shape({
    hasBeenReimbursed: PropTypes.bool,
    reimbursementValue: PropTypes.object,
    feesValue: PropTypes.object,
    externalPaymentProvider: PropTypes.any,
  }).isRequired,
  journeyDetails: PropTypes.arrayOf(
    PropTypes.shape({
      origin: PropTypes.string,
      destination: PropTypes.string,
      isReturn: PropTypes.bool,
    })
  ).isRequired,
};

export default InsuranceTableRow;
