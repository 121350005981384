import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import Menu from '../Menu';
import Button from '../Button';

import messages from './messages';
import styles, { menuStyles } from './styles';

const RefundOptionsMenu = ({ items, styleSheet, testId, disabled }) => {
  if (items && items.some((child) => child)) {
    return (
      <div className={css(styles.container)}>
        <div className={css(styles.menuItems)}>
          <Menu
            data-test="RefundOptionsMenu"
            styleSheet={menuStyles}
            handle={
              <div>
                <Button
                  variant="tertiary"
                  size="small"
                  testId={testId}
                  styleSheet={styleSheet}
                  disabled={disabled}
                >
                  <FormattedMessage {...messages.refundOptions} />
                </Button>
              </div>
            }
            groups={[
              {
                items: items.map((item) => ({
                  ...item,
                  className: css(menuStyles.item),
                })),
              },
            ]}
          />
        </div>
      </div>
    );
  }
  return null;
};

RefundOptionsMenu.propTypes = {
  styleSheet: PropTypes.object,
  testId: PropTypes.string,
  items: PropTypes.array,
  disabled: PropTypes.bool,
};
export default RefundOptionsMenu;
