import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { Heading } from '@trainline/depot-web';

import LoadMoreButton from '@contactcentre-web/common/LoadMoreButton/LoadMoreButton';
import Loader from '@contactcentre-web/common/Loader';
import NoResultsContainer from '@contactcentre-web/common/NoResultsContainer/NoResultsContainer';
import { useTracsOnAccountApprovalQueue } from '@contactcentre-web/hooks/api/useTracsOnAccount';

import styles from './styles';
import messages from './messages';
import ApprovaItem from './components/ApprovaItem/ApprovaItem';
import ErrorMessage from './components/ErrorMessage/ErrorMessage';

const TracsOnAccountApprovals = () => {
  const NUMBER_OF_ITEMS_PER_PAGE = 50;
  const { data, isLoading, isError, fetchNextPage } =
    useTracsOnAccountApprovalQueue(NUMBER_OF_ITEMS_PER_PAGE);

  const { pages = [] } = data || {};
  const allItems = pages.flatMap(({ items }) => items);
  const {
    pageSize = 0,
    totalCount = 0,
    page = 0,
  } = pages.length > 0 ? pages[pages.length - 1] : {};
  const noResults = totalCount === 0 || allItems.length === 0;

  return (
    <>
      <div className={css(styles.headerContainer)}>
        <Heading typeStyle="title1" as="h1" color="base">
          <FormattedMessage {...messages.header} />
        </Heading>
        <Heading typeStyle="title3" as="h3" color="base">
          <FormattedMessage {...messages.subTitle} />
        </Heading>
      </div>
      {isError && <ErrorMessage />}
      {isLoading && <Loader />}
      {noResults && !isLoading && <NoResultsContainer />}
      {allItems.map((item) => (
        <ApprovaItem key={item.id} item={item} />
      ))}
      <LoadMoreButton
        totalCount={totalCount}
        pageSize={pageSize}
        page={page}
        fetchNextPage={fetchNextPage}
        isLoading={isLoading}
      />
    </>
  );
};

export default TracsOnAccountApprovals;
