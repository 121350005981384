import { defineMessages } from 'react-intl';

export default defineMessages({
  load: {
    id: 'LoadMoreButton.load',
    defaultMessage: 'Load {loadMoreItemsCount} more',
  },
  loading: {
    id: 'LoadMoreButton.loading',
    defaultMessage: 'Loading {loadMoreItemsCount} more',
  },
});
