import { StyleSheet } from 'aphrodite/no-important';
import { fonts, spacers, typography } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  table: {
    padding: `${spacers.dp_s24} ${spacers.dp_s64}`,
    display: 'table',
    width: '100%',
  },
  header: {
    display: 'table-header-group',
    textTransform: 'uppercase',
    color: colors.gray.alpha30,
    fontWeight: fonts.dp_font_weight_semibold,
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: 0,
    marginBottom: spacers.dp_s8,
  },
  row: {
    display: 'table-row',
    width: '100%',
  },
  cell: {
    display: 'table-cell',
    justifyContent: 'space-between',
  },
  passengerTypeHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  passengerTypeContent: {
    textTransform: 'capitalize',
    fontWeight: fonts.dp_font_weight_bold,
  },
  locked: {
    opacity: 0.5,
  },
  railcardContainer: {
    marginLeft: spacers.dp_s24,
  },
});
