import React from 'react';
import { css } from 'aphrodite/no-important';

import styles from '../../styles';

import RevocationResultsTableHeader from './components/RevocationResultsTableHeader/RevocationResultsTableHeader';
import RevocationResultsTableBody from './components/RevocationResultsTableBody/RevocationResultsTableBody';
import RevocationResultsTableItem, {
  TableItem,
} from './components/RevocationResultsTableItem/RevocationResultsTableItem';

export interface RevocationResultsTableProps {
  items: Array<TableItem>;
}

const RevocationResultsTable = (props: RevocationResultsTableProps) => {
  const { items } = props;

  return (
    <table className={css(styles.table)} data-testid="resultsTable">
      <RevocationResultsTableHeader />
      <RevocationResultsTableBody>
        {items.map((item) => (
          <RevocationResultsTableItem data={item} key={item.customerId} />
        ))}
      </RevocationResultsTableBody>
    </table>
  );
};

export default RevocationResultsTable;
