import React from 'react';

import SetupManagedGroup from '@contactcentre-web/setup-managed-group/SetupManagedGroup';
import PageContainer from '@contactcentre-web/common/PageContainer';

import styles from './styles';

const SetupManagedGroupPage = () => (
  <PageContainer styleSheet={styles.container}>
    <SetupManagedGroup />
  </PageContainer>
);

export default SetupManagedGroupPage;
