import request from '@contactcentre-web/utils/request';
import tokenStore, { Token } from '@contactcentre-web/utils/tokenStore';

export interface Credentials {
  username: string;
  password: string;
}

export interface LoginResponse {
  access_token: string;
  refresh_token: string;
  expires_in: number; // in seconds
  token_type: string;
  passwordResetRequired?: boolean;
  status?: number;
  id_token?: string;
}

const endpoints = {
  token: '/connect/token',
  logout: '/connect/logout',
};

const formUrlEncode = (obj: Credentials) =>
  Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key as keyof Credentials])}`)
    .join('&');

const persistToken = (token: Omit<Token, 'expires_at'>) => {
  const tokenLifetimeInSeconds = token.expires_in * 1000;
  tokenStore.setToken({
    ...token,
    expires_at: new Date(Date.now() + tokenLifetimeInSeconds),
  });
};

const login = async (credentials: Credentials) => {
  const formData = `grant_type=password&scope=offline_access&${formUrlEncode(credentials)}`;

  const response = await request<LoginResponse>(endpoints.token, {
    method: 'POST',
    body: formData,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });

  if (response && 'access_token' in response) {
    if (!response.passwordResetRequired) {
      persistToken(response);
      return tokenStore.getToken();
    }
  }
  return response;
};

const refreshToken = async () => {
  const token = tokenStore.getToken();

  const response = await request<LoginResponse>(endpoints.token, {
    method: 'POST',
    body: `refresh_token=${
      token && token.refresh_token
    }&grant_type=refresh_token&scope=offline_access`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  if (response && !response.passwordResetRequired) {
    persistToken(response);
    return tokenStore.getToken();
  }
  return response;
};

const isLoggedIn = () => tokenStore.validTokenExists();

const logout = () => {
  const token = tokenStore.getToken();

  if (token) {
    tokenStore.clearToken();
  }
  return null;
};

export default {
  login,
  logout,
  isLoggedIn,
  persistToken,
  refreshToken,
};
