import React, { AnchorHTMLAttributes, FunctionComponent } from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';
import { IconExternalLink } from '@trainline/depot-web';

import Button, { CommonButtonProps } from '../Button';

import styles from './styles';

type InternalLinkProps = { linkType: 'internal' } & LinkProps;
type ExternalLinkProps = { linkType: 'external' } & AnchorHTMLAttributes<HTMLAnchorElement>;
type ActionLinkProps = { linkType: 'action' } & CommonButtonProps;

type Props = (InternalLinkProps | ExternalLinkProps | ActionLinkProps) & {
  styleSheet?: StyleDeclarationValue;
};

const Link: FunctionComponent<Props> = ({ styleSheet, linkType, ...props }) =>
  linkType === 'internal' ? (
    <RouterLink className={css([styles.default, styleSheet])} {...(props as InternalLinkProps)} />
  ) : linkType === 'external' ? (
    // eslint-disable-next-line react/forbid-elements
    <a
      {...(props as ExternalLinkProps)}
      className={css([styles.default, styles.external, styleSheet])}
      target="_blank"
      rel="noreferrer"
    >
      {props.children}
      <IconExternalLink className={css(styles.externalIcon)} />
    </a>
  ) : linkType === 'action' ? (
    <Button
      variant="ghost"
      styleSheet={[styles.ghost, styleSheet]}
      {...(props as ActionLinkProps)}
    />
  ) : null;

export default Link;
