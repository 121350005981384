import { defineMessages } from 'react-intl';

export default defineMessages({
  from_to: {
    id: 'SeasonBooking.Tickets.from_to',
    defaultMessage: 'From/To',
  },
  class: {
    id: 'SeasonBooking.Tickets.class',
    defaultMessage: 'Class',
  },
  passenger: {
    id: 'SeasonBooking.Tickets.passenger',
    defaultMessage: 'Passenger',
  },
  resetAllTickets: {
    id: 'SeasonBooking.Tickets.resetAllTickets',
    defaultMessage: 'Reset all tickets',
  },
  originToDestination: {
    id: 'SeasonBooking.Tickets.originToDestination',
    defaultMessage: '{origin} to {destination}',
  },
  cancelAllTickets: {
    id: 'SeasonBooking.Tickets.cancelAllTickets',
    defaultMessage: 'Cancel all tickets',
  },
  status: {
    id: 'SeasonBooking.Tickets.status',
    defaultMessage: 'Status',
  },
  view: {
    id: 'SeasonBooking.Tickets.view',
    defaultMessage: 'View',
  },
});
