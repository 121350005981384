import { StyleSheet } from 'aphrodite/no-important';
import { spacers, typography } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export const menuStylesheet = StyleSheet.create({
  root: {
    border: `1px solid ${colors.gray.alpha30}`,
    borderRadius: '6px',
    overflow: 'hidden',
  },
});

const defaultPadding = {
  padding: `${spacers.dp_s8} ${spacers.dp_s16}`,
};

export default StyleSheet.create({
  menuItem: {
    padding: `${spacers.dp_s8} ${spacers.dp_s24} ${spacers.dp_s8} ${spacers.dp_s16}`,
  },
  menuButton: {
    ...defaultPadding,
    width: '100%',
  },
  menu: {
    marginLeft: spacers.dp_s16,
    transition: 'max-height .2s ease-in-out, opacity .2s linear',
    textAlign: 'left',
  },
  button: {
    background: 'none',
    textAlign: 'right',
    display: 'flex',
    justifyItems: 'center',
    cursor: 'pointer',
  },
  username: {
    fontSize: typography.dp_small_font_size,
    lineHeight: typography.dp_small_line_height,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  dropdown: {
    marginLeft: spacers.dp_s4,
    fontSize: typography.dp_small_font_size,
    lineHeight: typography.dp_small_line_height,
  },
  localeContainer: {
    display: 'flex',
    alignItems: 'center',
    ...defaultPadding,
    width: '100%',
  },
  localeName: {
    marginLeft: spacers.dp_s8,
  },
  selectedLocaleContainer: {
    width: spacers.dp_s16,
    height: spacers.dp_s16,
    marginRight: spacers.dp_s8,
  },
  selectedLocale: {
    mask: 'url(/tick-mask.svg) no-repeat center / contain',
    backgroundColor: 'green',
    width: spacers.dp_s16,
    height: spacers.dp_s16,
  },
});
