import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { StatusMessage } from '@trainline/depot-web';

import FormattedDateInTz from '@contactcentre-web/common/FormattedDateInTz/FormattedDateInTz';
import FormattedTimeInTz from '@contactcentre-web/common/FormattedTimeInTz/FormattedTimeInTz';

import messages from './messages';
import styles from './styles';

export const RefundRequestedAlert = ({ state: { agent, timestamp } = {} }) => (
  <section>
    {agent && timestamp && (
      <div className={css(styles.notification)}>
        <StatusMessage status="info">
          <FormattedMessage
            {...messages.alertText}
            values={{
              agent: <b>{agent}</b>,
              time: <FormattedTimeInTz value={timestamp} />,
              date: (
                <FormattedDateInTz value={timestamp} year="numeric" month="short" day="numeric" />
              ),
            }}
          />
        </StatusMessage>
      </div>
    )}
  </section>
);

RefundRequestedAlert.propTypes = {
  state: PropTypes.shape({
    agent: PropTypes.string,
    timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  }),
};

export default RefundRequestedAlert;
