import { defineMessages } from 'react-intl';

export default defineMessages({
  Customer: {
    id: 'NotesCategoryButton.Customer',
    defaultMessage: 'Customer notes ({count})',
  },
  Order: {
    id: 'NotesCategoryButton.Order',
    defaultMessage: 'Order notes ({count})',
  },
});
