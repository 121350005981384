import { defineMessages } from 'react-intl';

export default defineMessages({
  failed: {
    id: 'OrderStatus.failed',
    defaultMessage: 'Order failed',
  },
  successful: {
    id: 'OrderStatus.successful',
    defaultMessage: 'Order successful',
  },
  fulfilment: {
    id: 'OrderStatus.fulfilment',
    defaultMessage: 'Fulfilment',
  },
  paymentPending: {
    id: 'OrderStatus.paymentPending',
    defaultMessage: 'Payment pending',
  },
  unknown: {
    id: 'OrderStatus.unknown',
    defaultMessage: 'Unknown',
  },
  66001.1: {
    id: 'OrderStatus.code.66001.1',
    defaultMessage: 'All Unknown Errors',
  },
  66001.2: {
    id: 'OrderStatus.code.66001.2',
    defaultMessage: 'Invalid State',
  },
  66001.41: {
    id: 'OrderStatus.code.66001.41',
    defaultMessage: 'Payment Offer Invalid',
  },
  66001.42: {
    id: 'OrderStatus.code.66001.42',
    defaultMessage: 'Invoice Not Found',
  },
  66001.43: {
    id: 'OrderStatus.code.66001.43',
    defaultMessage: 'No Trainline Invoice',
  },
  66001.71: {
    id: 'OrderStatus.code.66001.71',
    defaultMessage: 'Basket Retrieval Failed',
  },
  66001.72: {
    id: 'OrderStatus.code.66001.72',
    defaultMessage: 'Basket Invalid',
  },
  66001.81: {
    id: 'OrderStatus.code.66001.81',
    defaultMessage: 'Payment Details Retrieval Failed',
  },
  66001.82: {
    id: 'OrderStatus.code.66001.82',
    defaultMessage: 'Payment Creation Failed',
  },
  66001.83: {
    id: 'OrderStatus.code.66001.83',
    defaultMessage: 'Payment Not Found',
  },
  66001.84: {
    id: 'OrderStatus.code.66001.84',
    defaultMessage: 'Payment Retrieval Failed',
  },
  66001.91: {
    id: 'OrderStatus.code.66001.91',
    defaultMessage: 'Customer Retrieval Failed',
  },
  66001.92: {
    id: 'OrderStatus.code.66001.92',
    defaultMessage: 'Context Not Found',
  },
  66001.93: {
    id: 'OrderStatus.code.66001.93',
    defaultMessage: 'Context Retrieval Failed',
  },
  66001.94: {
    id: 'OrderStatus.code.66001.94',
    defaultMessage: 'Operation Timed Out',
  },
  66001.95: {
    id: 'OrderStatus.code.66001.95',
    defaultMessage: 'International Customer Service Call Failed',
  },
  66001.96: {
    id: 'OrderStatus.code.66001.96',
    defaultMessage: 'ConversionRatesChanged',
  },
  66001.101: {
    id: 'OrderStatus.code.66001.101',
    defaultMessage: 'Product Replacement Not Supported',
  },
  66001.141: {
    id: 'OrderStatus.code.66001.141',
    defaultMessage: 'Credit Details Retrieval Faled',
  },
  66001.142: {
    id: 'OrderStatus.code.66001.142',
    defaultMessage: 'Credit Creation Failed',
  },
  '66001.10': {
    id: 'OrderStatus.code.66001.10',
    defaultMessage: 'Product Issuing Failed',
  },
  '66001.20': {
    id: 'OrderStatus.code.66001.20',
    defaultMessage: 'Product Locking Failed ',
  },
  '66001.30': {
    id: 'OrderStatus.code.66001.30',
    defaultMessage: 'Take Payment Failed',
  },
  '66001.40': {
    id: 'OrderStatus.code.66001.40',
    defaultMessage: 'Payment Offer Retrieval Failed',
  },
  '66001.50': {
    id: 'OrderStatus.code.66001.50',
    defaultMessage: 'Fraud Check Failed',
  },
  '66001.60': {
    id: 'OrderStatus.code.66001.60',
    defaultMessage: 'Basket Pricing Retrieval Failed',
  },
  '66001.70': {
    id: 'OrderStatus.code.66001.70',
    defaultMessage: 'Basket Not Found',
  },
  '66001.80': {
    id: 'OrderStatus.code.66001.80',
    defaultMessage: 'Payment Details Not Found',
  },
  '66001.90': {
    id: 'OrderStatus.code.66001.90',
    defaultMessage: 'Customer Not Found',
  },
  '66001.100': {
    id: 'OrderStatus.code.66001.100',
    defaultMessage: 'Product Replacement Failed',
  },
  '66001.110': {
    id: 'OrderStatus.code.66001.110',
    defaultMessage: 'Product Retrieval Failed',
  },
  '66001.120': {
    id: 'OrderStatus.code.66001.120',
    defaultMessage: 'Create Order Request Invalid',
  },
  '66001.130': {
    id: 'OrderStatus.code.66001.130',
    defaultMessage: 'Create Conversion Context Failed',
  },
  '66001.140': {
    id: 'OrderStatus.code.66001.140',
    defaultMessage: 'Credit Details Not Found',
  },
});
