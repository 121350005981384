import { defineMessages } from 'react-intl';

export default defineMessages({
  description: {
    id: 'GenerateUserForm.description',
    defaultMessage:
      'The first user generated is always an account with the Team Lead role. Specify first name, last name and username below.{br}A password will also be automatically generated.',
  },
  firstNameLabel: {
    id: 'GenerateUserForm.firstNameLabel',
    defaultMessage: 'First name',
  },
  lastNameLabel: {
    id: 'GenerateUserForm.lastNameLabel',
    defaultMessage: 'Last name',
  },
  usernameLabel: {
    id: 'GenerateUserForm.usernameLabel',
    defaultMessage: 'Username',
  },
  firstNameRequired: {
    id: 'GenerateUserForm.firstNameRequired',
    defaultMessage: 'Enter a first name',
  },
  lastNameRequired: {
    id: 'GenerateUserForm.lastNameRequired',
    defaultMessage: 'Enter a last name',
  },
  usernameRequired: {
    id: 'GenerateUserForm.usernameRequired',
    defaultMessage: 'Enter a username',
  },
  tooltipText: {
    id: 'GenerateUserForm.tooltipText',
    defaultMessage:
      'We suggest the username to be the first two initals of the first name and the full last name, no spaces.',
  },
  generate: {
    id: 'GenerateUserForm.generate',
    defaultMessage: 'Generate',
  },
  regenerate: {
    id: 'GenerateUserForm.regenerate',
    defaultMessage: 'Regenerate',
  },
});
