import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';

import Tabs from '@contactcentre-web/common/Tabs/Tabs';
import Tab from '@contactcentre-web/common/Tabs/Tab';
import { getOpenReturnValidityPeriod } from '@contactcentre-web/utils/journeys';

import FareRestrictions from '../FareRestrictions/FareRestrictions';
import BookingLocked from '../BookingLocked/BookingLocked';
import BookingHeader from '../BookingHeader/BookingHeader';
import CustomerBookingJourney from '../CustomerBookingJourney/CustomerBookingJourney';
import AlternativeJourneys from '../AlternativeJourneys/AlternativeJourneys';
import Tickets from '../Tickets/Tickets';
import AlterPassengers from '../AlterPassengers/AlterPassengers';

import styles from './styles';
import messages from './messages';

const findJourneyByDirection = (journeys, direction) =>
  journeys.find((journey) => journey.direction === direction);

const isOpenReturn = (journeyType) => journeyType?.toLowerCase() === 'openreturn';

const CustomerBooking = (props) => {
  const {
    journeys,
    ticketDetails,
    hasTgvRailCard,
    deliveryRecipient,
    travelRestriction,
    manuallyLocked,
    convertedOrderReference,
    latestSupersededAt,
    isDelayRepayClaimLoading,
    orderReference,
    productId,
    vendor,
    fulfilmentStatus,
    refreshOrder,
    hasAlternativeJourneys,
    journeyType,
    showEditBookingPassengers,
    origin,
    destination,
    isReturn,
    customerOrderTravellers,
    businessSettings,
    outDate,
  } = props;
  const isLockedCss = manuallyLocked ? styles.locked : null;
  const location = useLocation();
  const isNewOrderDefault = new URLSearchParams(location.search).get('newOrder') || false;
  const outwardJourney = findJourneyByDirection(journeys, 'Outward');
  const inwardJourney = findJourneyByDirection(journeys, 'Inward');
  const formattedValidityPeriod = getOpenReturnValidityPeriod(journeys, outDate, vendor || '');

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.row)}>
        <BookingHeader {...props} />
      </div>
      <div className={css(styles.tabContainer)}>
        <Tabs>
          <Tab title={<FormattedMessage {...messages.bookingInfo} />} default={!isNewOrderDefault}>
            {manuallyLocked && <BookingLocked {...props} />}
            {travelRestriction && <FareRestrictions />}
            <div className={css(isLockedCss)}>
              {journeys.map((journey, index, { length }) => (
                <CustomerBookingJourney
                  key={index}
                  {...journey}
                  journeyNum={index + 1}
                  journeyCount={length}
                  convertedOrderReference={convertedOrderReference}
                  deliveryRecipient={deliveryRecipient}
                  isDelayRepayClaimLoading={isDelayRepayClaimLoading}
                  vendor={vendor}
                  customerOrderTravellers={customerOrderTravellers}
                  businessSettings={businessSettings}
                  validityPeriod={journey.validityPeriod ? formattedValidityPeriod : undefined}
                />
              ))}
            </div>
          </Tab>
          <Tab title={<FormattedMessage {...messages.tickets} />} default={isNewOrderDefault}>
            {manuallyLocked && <BookingLocked {...props} />}
            {travelRestriction && <FareRestrictions />}
            <div className={css(isLockedCss)}>
              <Tickets
                ticketDetails={ticketDetails}
                hasTgvRailCard={hasTgvRailCard}
                documentsUpdated={!!latestSupersededAt}
                orderReference={orderReference}
                productId={productId}
                fulfilmentStatus={fulfilmentStatus}
                refreshOrder={refreshOrder}
              />
            </div>
          </Tab>
          {hasAlternativeJourneys ? (
            <Tab
              title={<FormattedMessage {...messages.otherValidTrains} />}
              testId="other-valid-trains-tab"
            >
              <div className={css(styles.otherValidTrainsContainer)}>
                <AlternativeJourneys
                  journey={outwardJourney}
                  isOpenReturn={isOpenReturn(journeyType)}
                  journeyDirection="outward"
                />
                <AlternativeJourneys
                  journey={inwardJourney}
                  isOpenReturn={isOpenReturn(journeyType)}
                  journeyDirection="inward"
                />
              </div>
            </Tab>
          ) : null}
        </Tabs>
        {showEditBookingPassengers && (
          <AlterPassengers
            orderId={orderReference}
            bookingId={productId}
            journeys={journeys}
            origin={origin}
            destination={destination}
            isReturn={isReturn}
          />
        )}
      </div>
    </div>
  );
};

CustomerBooking.propTypes = {
  journeys: PropTypes.arrayOf(PropTypes.object).isRequired,
  deliveryRecipient: PropTypes.object,
  travelRestriction: PropTypes.bool,
  ticketDetails: PropTypes.array,
  hasTgvRailCard: PropTypes.bool,
  manuallyLocked: PropTypes.bool,
  convertedOrderReference: PropTypes.string,
  latestSupersededAt: PropTypes.string,
  trainlineProductStatus: PropTypes.object,
  isDelayRepayClaimLoading: PropTypes.bool,
  orderReference: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
  insurance: PropTypes.object,
  vendor: PropTypes.string,
  fulfilmentStatus: PropTypes.string,
  refreshOrder: PropTypes.func,
  hasAlternativeJourneys: PropTypes.bool,
  journeyType: PropTypes.string,
  showEditBookingPassengers: PropTypes.bool,
  origin: PropTypes.string,
  destination: PropTypes.string,
  isReturn: PropTypes.bool,
  customerOrderTravellers: PropTypes.shape({
    tenantId: PropTypes.string,
    itineraryId: PropTypes.string,
    passengerDetails: PropTypes.array,
  }),
  businessSettings: PropTypes.shape({
    persistPassengerDetails: PropTypes.bool,
  }),
  outDate: PropTypes.string,
};

export default CustomerBooking;
