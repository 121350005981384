import { defineMessages } from 'react-intl';

export default defineMessages({
  passengerDOB: {
    id: 'CustomerPassengersPassengerDOB.passengerDOB',
    defaultMessage: 'DOB: {dob}',
  },
  passengerContactNumber: {
    id: 'CustomerPassengersPassengerDOB.passengerContactNumber',
    defaultMessage: 'Contact number: {number}',
  },
  passengerContactNumberNA: {
    id: 'CustomerPassengersPassengerDOB.passengerNA',
    defaultMessage: 'Contact number: N/A',
  },
  passengerAccountHolder: {
    id: 'CustomerPassengersPassengerDOB.passengerAccountHolder',
    defaultMessage: 'Primary account holder',
  },
  passengerLabel: {
    id: 'CustomerPassengersPassengerDOB.passengerLabel',
    defaultMessage: 'Passenger',
  },
});
