import React from 'react';
import { css } from 'aphrodite/no-important';

import CustomerNotes from './components/CustomerNotes/CustomerNotes';
import styles from './styles';

interface Props {
  isOpen: boolean;
}

const Sidebar = ({ isOpen }: Props) => (
  <aside className={css(styles.sidebar, isOpen && styles.sidebarOpen)} data-testid="notesDrawer">
    {isOpen && <CustomerNotes />}
  </aside>
);

export default Sidebar;
