import { IconWarning, Paragraph } from '@trainline/depot-web';
import { css } from 'aphrodite/no-important';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  ErrorTypes,
  ModalStateData,
  StateTypes,
} from '@contactcentre-web/hooks/api/useVirtualTerminal';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@contactcentre-web/common/Modal';
import Button from '@contactcentre-web/common/Button';

import messages from './messages';
import styles from './styles';

interface Props {
  onCloseModal: () => void;
  stateData: ModalStateData;
  isTerminalDebit: boolean;
}

const StateModal = ({ onCloseModal, stateData, isTerminalDebit }: Props) => {
  const { state, errorCode } = stateData;
  const errorMessageRollbackedFailed = errorCode.includes(ErrorTypes.ROLLBACK_FAILED);
  const isSuccess = state === StateTypes.SUCCESS;
  let messageBody;

  if (isSuccess) {
    messageBody = messages.body;
  } else if (isTerminalDebit) {
    messageBody = errorMessageRollbackedFailed
      ? messages.bodyWarningDebitRollbackFailed
      : messages.bodyWarningDebitGeneric;
  } else {
    messageBody = messages.bodyWarning;
  }

  return (
    <Modal styleSheet={{ content: styles.modal }} onClose={onCloseModal}>
      <ModalHeader>
        <ModalTitle>
          {isSuccess ? (
            <FormattedMessage {...(isTerminalDebit ? messages.titleDebit : messages.title)} />
          ) : (
            <div className={css(styles.modalTitle)}>
              <IconWarning />
              <FormattedMessage
                {...(isTerminalDebit ? messages.titleWarningDebit : messages.titleWarning)}
              />
            </div>
          )}
        </ModalTitle>
      </ModalHeader>
      {!(isSuccess && isTerminalDebit) && (
        <ModalBody>
          <div className={css(styles.modalBody)}>
            <Paragraph typeStyle="body" fontWeight="medium" as="p" color="base">
              <FormattedMessage {...messageBody} values={{ errorCode, br: <br /> }} />
            </Paragraph>
          </div>
        </ModalBody>
      )}
      <ModalFooter>
        <Button
          fullWidth
          variant={isSuccess ? 'primary' : 'secondary'}
          size="small"
          onClick={onCloseModal}
        >
          <FormattedMessage {...messages.button} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default StateModal;
