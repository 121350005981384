import React, { ChangeEvent, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import Button from '@contactcentre-web/common/Button';

import messages from '../../messages';
import styles from '../../styles';
import { parseCsv } from '../../utils/utils';

export interface UploadCustomersCsvButtonProps {
  onParseResults: (csvResults: string) => void;
  onFileChange?: (file: File) => void;
  onVirtualElementHandle?: (virtualElement: HTMLInputElement) => void;
  disabled: boolean;
}

const UploadCustomersCsvButton = ({
  onParseResults,
  onFileChange,
  disabled,
}: UploadCustomersCsvButtonProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleCsvUpload = (ev: ChangeEvent<HTMLInputElement>) => {
    const file = ev.target.files?.[0];
    if (file) {
      onFileChange?.(file);
      parseCsv(file, onParseResults);
    }
  };

  return (
    <Button
      variant="secondary"
      size="small"
      testId="search-user-button"
      disabled={disabled}
      styleSheet={styles.uploadCsvButtonStyles}
      onClick={handleButtonClick}
    >
      <input
        ref={fileInputRef}
        type="file"
        accept=".csv"
        className={css(styles.hidden)}
        onChange={handleCsvUpload}
      />
      <FormattedMessage {...messages.uploadCSVButton} />
    </Button>
  );
};

export default UploadCustomersCsvButton;
