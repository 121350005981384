import { defineMessages } from 'react-intl';

export default defineMessages({
  refundStatus: {
    id: 'RefundFareStatus.refundStatus',
    defaultMessage: 'Refund status',
  },
  reasonCode: {
    id: 'RefundFareStatus.reasonCode',
    defaultMessage: 'Reason code',
  },
  'refundsProtocol.notRefundableUnderThisPolicy': {
    id: 'RefundFareStatus.refundsProtocol.notRefundableUnderThisPolicy',
    defaultMessage: 'This voidable is not refundable under this policy',
  },
  'refundsProtocol.voidAlreadyInProgressForRefundable': {
    id: 'RefundFareStatus.refundsProtocol.voidAlreadyInProgressForRefundable',
    defaultMessage:
      'This voidable is included in an existing void request which is already in progress',
  },
  'refundsProtocol.voidableAlreadyVoided': {
    id: 'RefundFareStatus.refundsProtocol.voidableAlreadyVoided',
    defaultMessage:
      'This voidable is included in an existing void request which has completed successfully',
  },
  'refundsProtocol.policyExpired': {
    id: 'RefundFareStatus.refundsProtocol.policyExpired',
    defaultMessage: 'Policy is expired and is no longer refundable',
  },
  'refundsProtocol.ticketAlreadyCollected': {
    id: 'RefundFareStatus.refundsProtocol.ticketAlreadyCollected',
    defaultMessage: 'Same day void is not available for collected tickets',
  },
  'refundsProtocol.productInInvalidState': {
    id: 'RefundFareStatus.refundsProtocol.productInInvalidState',
    defaultMessage: 'Product is not in a state that allow refunds',
  },
  'refundsProtocol.RefundRequestStarted': {
    id: 'RefundFareStatus.refundsProtocol.RefundRequestStarted',
    defaultMessage: 'Refund request for this transaction has already been started',
  },
  'refundsProtocol.productModifiedExternally': {
    id: 'RefundFareStatus.refundsProtocol.productModifiedExternally',
    defaultMessage: 'Product has been modified by an external system',
  },
  'refundsProtocol.partOfPartiallyUsedSplitTicketSet': {
    id: 'RefundFareStatus.refundsProtocol.partOfPartiallyUsedSplitTicketSet',
    defaultMessage: 'This voidable is part of a partially used split ticket set',
  },
});
