import React, { Fragment, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import { RefundHistory } from '@contactcentre-web/hooks/api/useOrderHistory';
import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency';

import OrderHistory from '../../../OrderHistory/OrderHistory';
import RefundPayment from '../RefundPayment/RefundPayment';

import messages from './messages';

interface Props {
  refundPayload?: RefundHistory['payload'];
}

const RefundPaymentDetails: FunctionComponent<Props> = ({ refundPayload }) => {
  const { formatMessage } = useIntl();

  const adminFees = refundPayload?.chargedFees?.filter(({ name }) => name === 'AdminFee');

  return (
    <>
      {refundPayload?.requestedAmount && (
        <OrderHistory.Item label={formatMessage(messages.amountRequested)}>
          <FormattedCurrency {...refundPayload.requestedAmount} />
        </OrderHistory.Item>
      )}
      {adminFees && adminFees.length > 0 && (
        <OrderHistory.Item
          label={formatMessage(messages.adminFee, { amountOfAdminFees: adminFees.length })}
        >
          {adminFees.map(({ amount }, index) => (
            <Fragment key={index}>
              <FormattedCurrency {...amount} />
              {index + 1 !== adminFees.length && <br />}
            </Fragment>
          ))}
        </OrderHistory.Item>
      )}
      {refundPayload?.payments && refundPayload.payments.length > 0 && (
        <>
          <OrderHistory.Item label={formatMessage(messages.amountRefunded)}>
            {refundPayload.payments.map(({ refundedAmount }, index) => (
              <RefundPayment
                key={index}
                length={refundPayload.payments?.length}
                index={index}
                textComponent={<FormattedCurrency {...refundedAmount} />}
              />
            ))}
          </OrderHistory.Item>
          <OrderHistory.Item label={formatMessage(messages.paymentType)}>
            {refundPayload.payments.map(({ paymentDetails }, index) => (
              <RefundPayment
                key={index}
                length={refundPayload.payments?.length}
                index={index}
                textComponent={<OrderHistory.PaymentType paymentDetails={paymentDetails} />}
              />
            ))}
          </OrderHistory.Item>
          <OrderHistory.Item label={formatMessage(messages.paymentHolderName)}>
            {refundPayload.payments.map(({ paymentDetails }, index) => (
              <RefundPayment
                key={index}
                length={refundPayload.payments?.length}
                index={index}
                textComponent={<OrderHistory.PaymentHolderName paymentDetails={paymentDetails} />}
              />
            ))}
          </OrderHistory.Item>
        </>
      )}
    </>
  );
};

export default RefundPaymentDetails;
