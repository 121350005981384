import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import {
  ColumnName,
  CustomerBooking,
  FilterType,
} from '@contactcentre-web/common/BookingSearchResults/types';
import Price from '@contactcentre-web/redux-common/types/Price';
import ProductType from '@contactcentre-web/redux-common/types/ProductType';
import type State from '@contactcentre-web/redux-common/types/State';
import { sortByDateProp, sortByDatePropWithExtract } from '@contactcentre-web/utils/date';
import { sortByPrice } from '@contactcentre-web/utils/price';

export interface ProductDetails {
  from?: string;
  to?: string;
  isReturn?: boolean;
  departureDate?: Date;
  inventoryReference?: string | null;
  name?: string;
  returnDate?: Date;
}

export interface BookingSummariesData {
  customerId: string;
}

export interface BookingSummaries {
  from?: string;
  to?: string;
  isReturn?: boolean;
  departureDate?: Date;
  paymentStatus?: string;
  isReplacementBooking?: boolean;
  isReplacedBooking?: boolean;
  isConversionBooking?: boolean;
  isConvertedBooking?: boolean;
  bookingStatus: string;
  inventoryReference?: string | null;
  type: ProductType;
  orderReference: string;
  orderDate: Date;
  price: Price;
  customerId: string;
  name?: string;
  productDetails?: ProductDetails | null;
  returnDate?: Date | null;
}

export interface CustomerBookingState {
  bookingSummaries?: Array<BookingSummaries> | null;
  loading: boolean;
  sortPropName: string;
  sortOrderDesc: boolean;
  filters: Set<FilterType>;
}

// Constants
export const PREFIX = 'CUSTOMER_BOOKING_SUMMARIES';
export const CUSTOMER_BOOKING_SUMMARIES_RESET = 'RESET';
export const CUSTOMER_BOOKING_SUMMARIES_LOAD = `LOAD`;
export const CUSTOMER_BOOKING_SUMMARIES_SUCCESS = `SUCCESS`;
export const CUSTOMER_BOOKING_SUMMARIES_FAIL = `FAIL`;
export const CUSTOMER_BOOKING_SUMMARIES_SORT = `SORT`;
export const CUSTOMER_BOOKING_SUMMARIES_FILTERS_ADD = `FILTERS_ADD`;
export const CUSTOMER_BOOKING_SUMMARIES_FILTER_REMOVE = `FILTER_REMOVE`;
export const MAX_DISPLAYED_BOOKINGS = 100;

// Actions
export const actions = createActions(
  {
    [CUSTOMER_BOOKING_SUMMARIES_RESET]: () => ({}),
    [CUSTOMER_BOOKING_SUMMARIES_LOAD]: (customerId) => ({ customerId }),
    [CUSTOMER_BOOKING_SUMMARIES_SUCCESS]: (bookingSummaries) => ({
      bookingSummaries,
    }),
    [CUSTOMER_BOOKING_SUMMARIES_FAIL]: (error) => error,
    [CUSTOMER_BOOKING_SUMMARIES_SORT]: (propName) => ({
      propName,
    }),
    [CUSTOMER_BOOKING_SUMMARIES_FILTERS_ADD]: (filters) => ({
      filters,
    }),
    [CUSTOMER_BOOKING_SUMMARIES_FILTER_REMOVE]: (filter) => ({
      filter,
    }),
  },
  { prefix: PREFIX }
);

// Reducer
export const initialState = {
  bookingSummaries: null,
  sortPropName: ColumnName.BY_DEPARTURE,
  sortOrderDesc: true,
  loading: false,
  filters: new Set<FilterType>(),
};

interface ActionPayload {
  bookingSummaries: BookingSummaries[];
  propName: ColumnName;
  filters: Set<FilterType>;
  filter: FilterType;
}
const reducer = handleActions<CustomerBookingState, ActionPayload>(
  {
    [CUSTOMER_BOOKING_SUMMARIES_RESET]: () => initialState,
    [CUSTOMER_BOOKING_SUMMARIES_LOAD]: (state) => ({
      ...state,
      loading: true,
    }),
    [CUSTOMER_BOOKING_SUMMARIES_SUCCESS]: (state, action) => ({
      ...state,
      bookingSummaries: action.payload.bookingSummaries,
      loading: false,
    }),
    [CUSTOMER_BOOKING_SUMMARIES_FAIL]: () => initialState,
    [CUSTOMER_BOOKING_SUMMARIES_SORT]: (state, { payload: { propName } }) => ({
      ...state,
      sortPropName: propName,
      sortOrderDesc: !state.sortOrderDesc,
    }),
    [CUSTOMER_BOOKING_SUMMARIES_FILTERS_ADD]: (state, { payload: { filters } }) => ({
      ...state,
      filters,
    }),
    [CUSTOMER_BOOKING_SUMMARIES_FILTER_REMOVE]: (state, { payload: { filter } }) => {
      state.filters.delete(filter);
      return {
        ...state,
      };
    },
  },
  initialState,
  { prefix: PREFIX }
);

export default reducer;

// Selectors
const localState = (state: State) => state.customerBookingSummaries;

const selectCustomerBookingSummaries = createSelector(
  localState,
  ({ bookingSummaries }) =>
    (bookingSummaries
      ? bookingSummaries.map(({ type = ProductType.TravelProduct, ...rest }) => ({
          type,
          orderReference: rest.orderReference,
          orderDate: rest.orderDate,
          price: rest.price,
          bookingStatus: rest.bookingStatus,
          customerId: rest.customerId,
          productDetails:
            rest.productDetails ||
            (type === ProductType.TravelProduct
              ? {
                  from: rest.from,
                  to: rest.to,
                  isReturn: rest.isReturn,
                  inventoryReference: rest.inventoryReference,
                  departureDate: rest.departureDate,
                  returnDate: rest.returnDate,
                }
              : {
                  name: rest.name,
                }),
        }))
      : bookingSummaries) as Array<CustomerBooking>
);

const selectLoading = createSelector(localState, (state) => state.loading);

export const selectCustomerBookingSummariesSortOptions = createSelector(localState, (state) => ({
  sortPropName: state.sortPropName,
  sortOrderDesc: state.sortOrderDesc,
}));

const sortNumber = (list: Array<BookingSummaries>, sortPropName: string, orderDesc: boolean) => {
  const sorted = [...list].sort((a, b) => {
    const idx = Object.keys(a).indexOf(sortPropName);

    return Object.values(a)[idx] - Object.values(b)[idx];
  });

  return orderDesc ? sorted.reverse() : sorted;
};

const sortString = (list: Array<BookingSummaries>, sortPropName: string, orderDesc: boolean) => {
  const sorted = [...list].sort((a, b) => {
    const idx = Object.keys(a).indexOf(sortPropName);

    return Object.values(a)[idx] - Object.values(b)[idx];
  });

  return orderDesc ? sorted.reverse() : sorted;
};

const sortStringWithExtract = (
  list: Array<BookingSummaries>,
  sortProp: (value: BookingSummaries) => string,
  orderDesc: boolean
) => {
  const sorted = list.sort((a, b) => sortProp(a).localeCompare(sortProp(b)));

  return orderDesc ? sorted.reverse() : sorted;
};

const sortByPropName = (
  bookingSummaries: Array<BookingSummaries>,
  sortPropName: string,
  sortOrderDesc: boolean
) => {
  switch (sortPropName) {
    case ColumnName.BY_DEPARTURE:
      return sortByDatePropWithExtract(
        bookingSummaries,
        ({ departureDate, productDetails }: BookingSummaries) =>
          departureDate || (productDetails && productDetails.departureDate) || new Date(0),
        sortOrderDesc
      );
    case ColumnName.BY_RETURN:
      return sortByDatePropWithExtract(
        bookingSummaries,
        ({ returnDate, productDetails }: BookingSummaries) =>
          returnDate || (productDetails && productDetails.returnDate) || new Date(0),
        sortOrderDesc
      );
    case ColumnName.BY_ORDER_DATE:
      return sortByDateProp(bookingSummaries, sortPropName, sortOrderDesc);
    case ColumnName.BY_ORDER_ID:
      return sortNumber(bookingSummaries, sortPropName, sortOrderDesc);
    case ColumnName.BY_BOOKING_STATUS:
      return sortString(bookingSummaries, sortPropName, sortOrderDesc);
    case ColumnName.BY_FROM:
      return sortStringWithExtract(
        bookingSummaries,
        ({ type, name, from, productDetails }: BookingSummaries) => {
          switch (type) {
            case ProductType.TravelProduct:
              return productDetails?.from || from || '';
            case ProductType.Railcard:
              return productDetails?.name || name || '';
            default:
              return '';
          }
        },
        sortOrderDesc
      );
    case ColumnName.BY_PRICE:
      return sortByPrice(bookingSummaries, sortPropName, sortOrderDesc);
    case ColumnName.BY_CTR_PNR:
      return sortStringWithExtract(
        bookingSummaries,
        ({ inventoryReference, productDetails }) =>
          inventoryReference || (productDetails && productDetails.inventoryReference) || '-',
        sortOrderDesc
      );
    default:
      return sortString(bookingSummaries, sortPropName, sortOrderDesc);
  }
};

const selectSortedBookingSummaries = createSelector(
  [selectCustomerBookingSummaries, selectCustomerBookingSummariesSortOptions],
  (bookingSummaries, { sortPropName, sortOrderDesc }) => {
    const sortedBookings =
      bookingSummaries && sortByPropName(bookingSummaries, sortPropName, sortOrderDesc);

    return sortedBookings && sortedBookings.slice(0, MAX_DISPLAYED_BOOKINGS);
  }
);

const selectAppliedFilters = createSelector(localState, (state) => state.filters);

export const selectors = {
  selectCustomerBookingSummaries,
  selectLoading,
  selectSortedBookingSummaries,
  selectCustomerBookingSummariesSortOptions,
  selectAppliedFilters,
};
