import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'SelectManagedGroup.title',
    defaultMessage: 'Select a managed group',
  },
  selectLabel: {
    id: 'SelectManagedGroup.selectLabel',
    defaultMessage: 'Your assigned managed groups',
  },
  selectPlaceholder: {
    id: 'SelectManagedGroup.selectPlaceholder',
    defaultMessage: 'Search',
  },
  continue: {
    id: 'SelectManagedGroup.continue',
    defaultMessage: 'Continue',
  },
  selectManagedGroupError: {
    id: 'SelectManagedGroup.selectManagedGroupError',
    defaultMessage: 'Select a managed group to continue',
  },
});
