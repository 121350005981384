import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'RefundReasonModal.title',
    defaultMessage: 'Select reason code',
  },
  confirm: {
    id: 'RefundReasonModal.confirm',
    defaultMessage: 'Confirm',
  },
  reasonCode: {
    id: 'RefundReasonModal.reasonCode',
    defaultMessage: 'Reason code:',
  },
});
