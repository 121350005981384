import { StyleSheet } from 'aphrodite/no-important';
import { spacers, palettes } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  ticketContainer: {
    ':not(:last-child)': {
      borderBottom: `1px solid ${palettes.dp_app_border_base}`,
    },
  },
  ticketAnchor: {
    padding: `${spacers.dp_s16} 0`,
  },
  ticketChevron: {
    padding: spacers.dp_s8,
    border: `1px solid ${palettes.dp_app_border_base}`,
    borderRadius: spacers.dp_s4,
    marginLeft: spacers.dp_s12,
  },
  scanWrapper: {
    borderColor: palettes.dp_app_border_base,
    marginBottom: spacers.dp_s8,
  },
  ticketNumber: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  ticketScan: {
    marginBottom: spacers.dp_s16,
  },
  ticketScanContent: {
    marginBottom: spacers.dp_s24,
  },
  ticketScanDetails: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    rowGap: spacers.dp_s4,
    marginTop: spacers.dp_s12,
  },
});
