import { spacers } from '@trainline/depot-web/css/themes/trainline';
import { StyleSheet } from 'aphrodite/no-important';

const frames = {
  '0%': {
    transform: 'scale(0)',
  },
  '40%': {
    transform: 'scale(1.0)',
  },
  '80%': {
    transform: 'scale(0)',
  },
  '100%': {
    transform: 'scale(0)',
  },
};

const spinner = {
  display: 'inline-block',
};

const bubble = {
  width: '6px',
  height: '6px',
  backgroundColor: 'white',
  borderRadius: '100%',
  marginRight: spacers.dp_s4,
  display: 'inline-block',
  animationName: {
    ...frames,
  },
  animationDuration: '1.4s',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'ease-in-out',
  animationFillMode: 'both',
};

export default StyleSheet.create({
  spinner,
  bounce1: {
    ...spinner,
    ...bubble,
    animationDelay: '-0.32s',
  },
  bounce2: {
    ...spinner,
    ...bubble,
    animationDelay: '-0.16s',
  },
  bounce3: {
    ...bubble,
  },
});
