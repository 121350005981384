import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import ProductType from '@contactcentre-web/redux-common/types/ProductType';
import { Journey } from '@contactcentre-web/redux-common/types/SeasonProduct';

import messages from './messages';
import styles from './styles';

export interface Props {
  products: Array<{
    type: ProductType;
    origin?: string;
    destination?: string;
    isReturn?: boolean;
    name?: string;
    journey?: Journey;
    localAreaValidity?: string;
    isFlexiSeason?: boolean;
  }>;
}

const BookingsSection = ({ products }: Props) => (
  <dl className={css(styles.bookings)}>
    <dt className={css(styles.label)}>
      <FormattedMessage {...messages.bookings} values={{ count: products.length }} />
    </dt>
    {products.map(
      (
        { type, name, origin, destination, journey, isReturn, localAreaValidity, isFlexiSeason },
        index
      ) => {
        switch (type) {
          case ProductType.Railcard:
            return (
              <dd data-testid="railcard-name" key={index}>
                {name}
              </dd>
            );
          case ProductType.Season:
            return (
              <dd data-testid="season-origin-destination" key={index}>
                <FormattedMessage
                  {...messages.season}
                  values={{
                    isFlexiSeason,
                    origin: journey?.origin,
                    destination: journey?.destination,
                  }}
                />
              </dd>
            );
          case ProductType.Travelcard:
            return (
              <dd data-testid="travelcard" key={index}>
                <FormattedMessage
                  {...messages.travelcard}
                  values={{
                    localAreaValidity,
                  }}
                />
              </dd>
            );
          case ProductType.Insurance:
            return null;
          default:
            return (
              <dd data-testid="travel-product-origin-destination" key={index}>
                <FormattedMessage
                  {...messages.directions}
                  values={{
                    isReturn,
                    origin,
                    destination,
                  }}
                />
              </dd>
            );
        }
      }
    )}
  </dl>
);

export default BookingsSection;
