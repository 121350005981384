import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { Heading, IconCheckbox } from '@trainline/depot-web';

import messages from './messages';
import styles from './styles';

interface Props {
  customMessage?: MessageDescriptor;
  icon?: React.ReactElement;
  subTitle?: MessageDescriptor;
}

const NoResultsContainer = ({ customMessage, icon, subTitle }: Props) => (
  <div className={css(styles.container)}>
    {icon ?? <IconCheckbox className={css(styles.icon)} testId="IconCheckbox" />}
    <Heading typeStyle="title2" as="h1" color="base">
      <FormattedMessage {...(customMessage ?? messages.noResults)} />
    </Heading>
    {subTitle && (
      <p className={css(styles.subTitle)}>
        <FormattedMessage {...subTitle} />
      </p>
    )}
  </div>
);

export default NoResultsContainer;
