import { all } from 'redux-saga/effects';

import authSaga from './login/sagas';
import passwordSaga from './changePassword/sagas';
import logoutSaga from './logout/sagas';
import managedGroupSaga from './managedGroup/sagas';
import profileSaga from './profile/sagas';

export default function* saga() {
  yield all([authSaga(), passwordSaga(), logoutSaga(), managedGroupSaga(), profileSaga()]);
}
