import { defineMessages } from 'react-intl';

export default defineMessages({
  total: {
    id: 'OrderPayment.total',
    defaultMessage: 'Total',
  },
  feeHeader: {
    id: 'OrderPayment.feeHeader',
    defaultMessage: 'Fees',
  },
  discountsHeader: {
    id: 'OrderPayment.discountsHeader',
    defaultMessage: 'Discounts',
  },
});
