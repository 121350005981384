import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  label: {
    color: colors.negative.base,
  },
  message: {
    color: colors.gray.alpha54,
    marginLeft: spacers.dp_s4,
  },
  container: {
    width: '100%',
    display: 'inline-block',
    padding: spacers.dp_s24,
    borderBottom: `1px dashed ${colors.loud.light}`,
  },
  iconWarning: {
    color: colors.negative.base,
    verticalAlign: 'middle',
    marginRight: spacers.dp_s4,
  },
});
