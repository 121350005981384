import React, { FunctionComponent } from 'react';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';
import { Paragraph } from '@trainline/depot-web';

import styles from './styles';

interface Props {
  styleSheet?: StyleDeclarationValue;
  testId?: string;
}

const Badge: FunctionComponent<Props> = ({ styleSheet, testId, children }) => (
  <div className={css([styles.badgeContainer, styleSheet])} data-test-id={testId}>
    <Paragraph as="span" typeStyle="small" color="base" fontWeight="bold">
      {children}
    </Paragraph>
  </div>
);

export default Badge;
