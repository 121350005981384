import { createSelector } from 'reselect';

import type State from '@contactcentre-web/redux-common/types/State';

import { loadCompensationsState, requestCompensationsState, loadRefundsState } from './module';
import type { ReasonData, PendingCompensation, CompensationError } from './reducer';

const localState = (state: State) => state.customerOrderCompensation;

const errorCode = createSelector(localState, (state): CompensationError | undefined =>
  state.requestState === requestCompensationsState.COMPENSATION_REQUEST_STATE_FAILED
    ? state.error || { errorCode: 'compensation_genericError' }
    : undefined
);

const requestHasSucceeded = createSelector(
  localState,
  (state) => state.requestState === requestCompensationsState.COMPENSATION_REQUEST_STATE_SUCCESSFUL
);

const loadInProgress = createSelector(
  localState,
  (state) => state.loadState === loadCompensationsState.COMPENSATION_LOAD_INPROGRESS
);

const loadHasSucceeded = createSelector(
  localState,
  (state) => state.loadState === loadCompensationsState.COMPENSATION_LOAD_SUCCESS
);

const loadHasFailed = createSelector(
  localState,
  (state) => state.loadState === loadCompensationsState.COMPENSATION_LOAD_ERROR
);

const rehydrateReasonCodesHierarchy = (
  reasons: Array<ReasonData>,
  pendingCompensation?: PendingCompensation
) => {
  // Attach parent node so that we can manipulate the hierarchy more easily.
  const res = reasons.map((r) => ({
    id: r.id,
    description: r.attributes.description,
    parent: r.attributes.parentId,
  }));

  res.forEach((r) => {
    if (r.parent) {
      (r.parent as unknown) = res.find(({ id }) => id === r.parent);
    }
  });

  // Checks if pending compensation reason exists on the list of active compensation reason codes
  // If it exists we return the reasons list unchanged
  // Otherwise we add the archived reason in the active reason list
  if (pendingCompensation) {
    if (res.find(({ id }) => id === pendingCompensation.reasonCodeId)) {
      return res;
    }
    return [...res, pendingCompensation.reasonCode];
  }
  return res;
};

const compensation = createSelector(localState, (state) => state.compensation);
const compensationReasonsRaw = createSelector(localState, (state) => state.compensationReasons);
const compensationReasons = createSelector(
  compensationReasonsRaw,
  compensation,
  (reasons, pendingCompensation) =>
    reasons ? rehydrateReasonCodesHierarchy(reasons.data, pendingCompensation) : []
);

const hasRefunds = createSelector(localState, (state) => state.hasRefunds === true);

const loadRefundsInProgress = createSelector(
  localState,
  (state) => state.loadRefundsState === loadRefundsState.REFUNDS_LOAD_INPROGRESS
);

export default {
  errorCode,
  requestHasSucceeded,
  loadInProgress,
  loadHasSucceeded,
  loadHasFailed,
  compensationReasons,
  compensation,
  hasRefunds,
  loadRefundsInProgress,
};
