import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import Link from '@contactcentre-web/common/Link';

import styles from './styles';
import messages from './messages';

const ProductCoJHeader = ({ productLink }) => (
  <thead>
    <tr>
      <th className={css(styles.cojTitleCell)}>
        <FormattedMessage
          {...messages.cojTitleCell}
          values={{
            linkType: productLink.type.replace('-', ''),
          }}
        />
      </th>
      {productLink.link && (
        <th colSpan="3" className={css(styles.cojOriginalBookingCell)}>
          <Link linkType="internal" to={productLink.link}>
            <FormattedMessage {...messages.original} />
          </Link>
        </th>
      )}
    </tr>
  </thead>
);

ProductCoJHeader.propTypes = {
  productLink: PropTypes.shape({
    type: PropTypes.oneOf(['replaces', 'replaced-by']),
    link: PropTypes.string,
  }),
};

export default ProductCoJHeader;
