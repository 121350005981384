import { defineMessages } from 'react-intl';

export default defineMessages({
  emailResent: {
    id: 'OrderHistoryConfirmationEmail.emailResent',
    defaultMessage: 'Email Resent',
  },
  booking: {
    id: 'OrderHistoryConfirmationEmail.booking',
    defaultMessage: 'Booking',
  },
  agent: {
    id: 'OrderHistoryConfirmationEmail.agent',
    defaultMessage: 'Agent',
  },
  appliesTo: {
    id: 'OrderHistoryConfirmationEmail.appliesTo',
    defaultMessage: 'Applies to order {orderReference}',
  },
  body: {
    id: 'OrderHistoryConfirmationEmail.body',
    defaultMessage: `Booking confirmation email resent to {emailsCount, plural,
      =1 {{0}}
      =2 {{0} and {1}}
      =3 {{0}, {1} and {2}}
      =4 {{0}, {1}, {2} and {3}}
      =5 {{0}, {1}, {2}, {3} and {4}}
      =6 {{0}, {1}, {2}, {3}, {4} and {5}}
      =7 {{0}, {1}, {2}, {3}, {4}, {5} and {6}}
      =8 {{0}, {1}, {2}, {3}, {4}, {5}, {6} and {7}}
      =9 {{0}, {1}, {2}, {3}, {4}, {5}, {6}, {7} and {8}}}`,
  },
});
