import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import styles from './styles';
import messages from './messages';

const PaymentServiceProviderInfo = ({ paymentServiceProvider }) => (
  <div>
    <div className={css(styles.blockTitle)}>
      <FormattedMessage {...messages.paymentPlatform} />
    </div>
    <div data-test-id="paymentServiceProviderMessage" id="paymentProviderName">
      {paymentServiceProvider || <FormattedMessage {...messages.paymentPlatformNone} />}
    </div>
  </div>
);

PaymentServiceProviderInfo.propTypes = {
  paymentServiceProvider: PropTypes.string,
};

export default PaymentServiceProviderInfo;
