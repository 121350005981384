import { StyleSheet } from 'aphrodite/no-important';
import { spacers, typography } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  textLabel: {
    marginBottom: spacers.dp_s8,
    fontSize: typography.dp_body_font_size,
  },
  cardContent: {
    marginBottom: spacers.dp_s8,
  },
});
