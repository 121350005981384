import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { Heading, IconSingle } from '@trainline/depot-web';

import Link from '@contactcentre-web/common/Link';

import messages from './messages';
import styles from './styles';

const ManualEntryConsoleAddHeader = () => (
  <>
    <Link linkType="internal" to="/mec-claim-approvals" styleSheet={styles.backButton}>
      <IconSingle />
      <FormattedMessage {...messages.buttonBack} />
    </Link>
    <div className={css(styles.header)} data-testid="manualEntryConsoleAddHeader">
      <Heading typeStyle="title2" as="h1" color="base">
        <FormattedMessage {...messages.title} />
      </Heading>
    </div>
  </>
);
export default ManualEntryConsoleAddHeader;
