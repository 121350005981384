import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({
  container: {
    backgroundColor: '#f6fafc',
    flex: 1,
  },
  bookingListContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    marginTop: 0,
  },
  bookingListContent: {
    flex: '1 1 auto',
  },
});
