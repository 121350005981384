import { defineMessages } from 'react-intl';

export default defineMessages({
  paymentPlatform: {
    id: 'PaymentServiceProviderInfo.paymentPlatform',
    defaultMessage: 'Payment Platform',
  },
  paymentPlatformNone: {
    id: 'PaymentServiceProviderInfo.paymentPlatformNone',
    defaultMessage: 'None',
  },
});
