import { defineMessages } from 'react-intl';

export default defineMessages({
  bookingsCount: {
    id: 'BookingsFilterHeader.bookingsCount',
    defaultMessage: 'Showing {bookingsCount, plural, one {# booking} other {# bookings}}',
  },
  filters: {
    id: 'FilterMenu.filters',
    defaultMessage: '{hasFiltersApplied, select, true {Filters applied} other {Filters}}',
  },
  filterBy: {
    id: 'FilterMenu.filterBy',
    defaultMessage: 'Filter by:',
  },
  ticketType: {
    id: 'FilterMenu.ticketType',
    defaultMessage: 'Ticket type',
  },
  bookingStatus: {
    id: 'FilterMenu.bookingStatus',
    defaultMessage: 'Booking status',
  },
  trainAndCoaches: {
    id: 'FilterMenu.trainAndCoaches',
    defaultMessage: '(Regular) train and coaches',
  },
  season: {
    id: 'FilterMenu.season',
    defaultMessage: 'Season',
  },
  londonTravelcard: {
    id: 'FilterMenu.londonTravelcard',
    defaultMessage: 'Add-on',
  },
  railcard: {
    id: 'FilterMenu.railcard',
    defaultMessage: 'Railcard',
  },
  Issued: {
    id: 'FilterMenu.Issued',
    defaultMessage: 'Issued',
  },
  Failed: {
    id: 'FilterMenu.Failed',
    defaultMessage: 'Failed',
  },
  Cancelled: {
    id: 'FilterMenu.Cancelled',
    defaultMessage: 'Cancelled',
  },
  Cancelling: {
    id: 'FilterMenu.Cancelling',
    defaultMessage: 'Cancelling',
  },
  Exchanged: {
    id: 'FilterMenu.Exchanged',
    defaultMessage: 'Exchanged',
  },
  Issuing: {
    id: 'FilterMenu.Issuing',
    defaultMessage: 'Issuing',
  },
  Locked: {
    id: 'FilterMenu.Locked',
    defaultMessage: 'Locked',
  },
  PartiallyCancelled: {
    id: 'FilterMenu.PartiallyCancelled',
    defaultMessage: 'Partially cancelled',
  },
  Superseded: {
    id: 'FilterMenu.Superseded',
    defaultMessage: 'Superseded',
  },
  Unknown: {
    id: 'FilterMenu.Unknown',
    defaultMessage: 'Unknown',
  },
  showBookings: {
    id: 'FilterMenu.showBookings',
    defaultMessage:
      '{bookingsCount, select, 0 {No bookings} other {Show {bookingsCount, plural, one {# booking} other {# bookings}}}}',
  },
});
