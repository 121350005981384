import { defineMessages } from 'react-intl';

export default defineMessages({
  booking: {
    id: 'BookingHeader.booking',
    defaultMessage: 'Booking {index}',
  },
  returnLabel: {
    id: 'BookingHeader.returnLabel',
    defaultMessage: 'Return',
  },
  returnDestination: {
    id: 'BookingHeader.returnDestination',
    defaultMessage: '{destination}',
  },
  deliveryMethod: {
    id: 'BookingHeader.deliveryMethod',
    defaultMessage: 'DELIVERY METHOD',
  },
  ctr: {
    id: 'BookingHeader.ctr',
    defaultMessage: 'Ctr',
  },
  tracsTransId: {
    id: 'BookingHeader.tracsTransId',
    defaultMessage: 'Tracs Trans. Id',
  },
  pnr: {
    id: 'BookingHeader.pnr',
    defaultMessage: 'PNR',
  },
  transactionId: {
    id: 'BookingHeader.transactionId',
    defaultMessage: 'Transaction Id',
  },
  nxTicketRef: {
    id: 'BookingHeader.nxTicketRef',
    defaultMessage: 'NX Ticket Ref.',
  },
  dates: {
    id: 'BookingHeader.dates',
    defaultMessage: 'Dates',
  },
  productPrice: {
    id: 'BookingHeader.productPrice',
    defaultMessage: 'Product price',
  },

  date: {
    id: 'BookingHeader.date',
    defaultMessage: '{label} {date}',
  },

  out: {
    id: 'BookingHeader.out',
    defaultMessage: 'Out',
  },

  ret: {
    id: 'BookingHeader.ret',
    defaultMessage: 'Return',
  },

  bookingRefreshed: {
    id: 'BookingHeader.bookingRefreshed',
    defaultMessage: 'Booking refreshed',
  },

  partialRefund: {
    id: 'BookingHeader.partialRefund',
    defaultMessage: 'Partially refunded',
  },

  collectionRestrictionRemoved: {
    id: 'BookingHeader.collectionRestrictionRemoved',
    defaultMessage: 'Changed to Anycard',
  },

  unknownSuperseding: {
    id: 'BookingHeader.unknownSuperseding',
    defaultMessage: 'Superseded',
  },
  mustProvideOneFormOfID: {
    id: 'BookingHeader.mustProvideOneFormOfID',
    defaultMessage: 'One form of ID',
  },
  mustPresentCardUsedForPayment: {
    id: 'BookingHeader.mustPresentCardUsedForPayment',
    defaultMessage: 'Payment card collect',
  },
  noCollectionConditions: {
    id: 'BookingHeader.noCollectionConditions',
    defaultMessage: 'Any card collect',
  },
  unknownCondition: {
    id: 'BookingHeader.unknownCondition',
    defaultMessage: 'Unknown Condition',
  },
  invoice: {
    id: 'BookingHeader.invoice',
    defaultMessage: 'Invoice',
  },
});
