import React, { FunctionComponent, TextareaHTMLAttributes } from 'react';
import { css } from 'aphrodite/no-important';

import styles from './styles';

interface Props {
  input: TextareaHTMLAttributes<HTMLTextAreaElement>;
  placeholder?: string;
  maxLength?: number;
}

const NoteTextarea: FunctionComponent<Props> = ({ input, placeholder, maxLength }) => (
  <div className={css(styles.NoteTextarea)}>
    <div className={css(styles.container)}>
      <div
        className={css([styles.hiddenSizeArea, styles.textAreaField])}
        dangerouslySetInnerHTML={{
          __html: `${(input.value as string).replace(/\n/g, '<br />')}&nbsp;`,
        }}
      />
      <textarea
        className={css([styles.textAreaField, styles.textAbsolute])}
        placeholder={placeholder}
        {...input}
        maxLength={maxLength}
      />
    </div>
  </div>
);

export default NoteTextarea;
