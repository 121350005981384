import { defineMessages } from 'react-intl';

export default defineMessages({
  success: {
    id: 'CancelRailcardStatus.success',
    defaultMessage: `The customer's {railcardName} has been cancelled. <a>Reload the order</a> to view the latest status.`,
  },
  error: {
    id: 'CancelRailcardStatus.error',
    defaultMessage: `There was an issue cancelling the customer's railcard, please try again.`,
  },
});
