import { defineMessages } from 'react-intl';

export default defineMessages({
  email: {
    id: 'CustomerHeader.email',
    defaultMessage: 'Email: ',
  },
  address: {
    id: 'CustomerHeader.address',
    defaultMessage: 'Address: ',
  },
  phonenumber: {
    id: 'CustomerHeader.phonenumber',
    defaultMessage: 'Phone: ',
  },
  alternativephonenumber1: {
    id: 'CustomerHeader.alternativephonenumber1',
    defaultMessage: 'Phone 2: ',
  },
  alternativephonenumber2: {
    id: 'CustomerHeader.alternativephonenumber2',
    defaultMessage: 'Phone 3: ',
  },
  languageLabel: {
    id: 'CustomerHeader.languageLabel',
    defaultMessage: 'Language: ',
  },
  migratedLabel: {
    id: 'CustomerHeader.migratedLabel',
    defaultMessage: 'Migrated: ',
  },
  viewBookings: {
    id: 'CustomerHeader.viewBookings',
    defaultMessage: 'View bookings',
  },
  editProfileButton: {
    id: 'CustomerHeader.editProfileButton',
    defaultMessage: 'Edit profile',
  },
  customerProfileUpdated: {
    id: 'CustomerHeader.customerProfileUpdated',
    defaultMessage: "The customer's profile has been successfully updated.",
  },
  savedPassengerButton: {
    id: 'CustomerHeader.savedPassengerButton',
    defaultMessage: 'Saved passengers',
  },
  corporateName: {
    id: 'CustomerHeader.corporateName',
    defaultMessage: 'Corporate Name:',
  },
  corporateReference: {
    id: 'CustomerHeader.corporateReference',
    defaultMessage: 'Corporate Reference:',
  },
  clientReference: {
    id: 'CustomerHeader.clientReference',
    defaultMessage: 'Client Reference:',
  },
});
