import React from 'react';
import { StatusMessage } from '@trainline/depot-web';
import { FormattedMessage } from 'react-intl';

import Link from '@contactcentre-web/common/Link';

import messages from './messages';
import styles from './styles';

const ErrorMessage = () => (
  <StatusMessage status="negative">
    <FormattedMessage
      {...messages.errorMessage}
      values={{
        link: (
          <Link linkType="internal" to="/tracs-onaccount-approvals" styleSheet={styles.link}>
            <FormattedMessage {...messages.refreshPage} />
          </Link>
        ),
      }}
    />
  </StatusMessage>
);

export default ErrorMessage;
