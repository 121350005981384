import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { InjectedFormProps, reduxForm } from 'redux-form';

import Select from '@contactcentre-web/common/Select';
import TextField from '@contactcentre-web/common/TextField';
import Button from '@contactcentre-web/common/Button';
import type State from '@contactcentre-web/redux-common/types/State';
import type Action from '@contactcentre-web/redux-common/types/Action';

import { FORM_ID, actions, selectors, CurrencyConversionResponse } from '../../module';

import messages from './messages';
import styles, { inputCurrencyStyles, selectStyle, selectValueStyle } from './styles';

type CurrencyConvertData = {
  amount: string;
  inventoryCurrency: string;
};

interface StateProps {
  isLoading?: boolean;
  result: CurrencyConversionResponse | null;
}

interface DispatchProps {
  convertCurrency: (
    amount: string,
    inventoryCurrency: string,
    transactionCurrency: string,
    ratesBatchId: string,
    marginsBatchId: string
  ) => void;
}

interface Props extends StateProps, DispatchProps {
  transactionCurrency: string;
  ratesBatchId: string;
  marginsBatchId: string;
  inventoryCurrencies: Array<{ label: string; value: string }>;
}

export const ConverterForm = ({
  transactionCurrency,
  ratesBatchId,
  marginsBatchId,
  result,
  inventoryCurrencies,
  isLoading,
  handleSubmit,
  convertCurrency,
}: Props & InjectedFormProps<CurrencyConvertData, Props>) => (
  <form
    id={FORM_ID}
    onSubmit={handleSubmit(({ amount, inventoryCurrency }) =>
      convertCurrency(amount, inventoryCurrency, transactionCurrency, ratesBatchId, marginsBatchId)
    )}
  >
    <div className={css(styles.row, styles.inventoryCurrencyLabel)}>
      <FormattedMessage {...messages.inventoryCurrency} />
    </div>
    <div className={css(styles.row)}>
      <div className={css(styles.inputCurrency)}>
        <TextField
          name="amount"
          placeholder="0.00"
          type="number"
          step="0.01"
          messages={messages}
          disabled={isLoading}
          styleSheet={inputCurrencyStyles}
        />
      </div>
      <div className={css(styles.selectCurrency)}>
        <Select
          name="inventoryCurrency"
          testId="CurrencyConverter-inventoryCurrency"
          messages={messages}
          disabled={isLoading || inventoryCurrencies.length === 1}
          options={inventoryCurrencies}
          noChevron={inventoryCurrencies.length === 1}
          displayErrorContainer={false}
          valueStyle={selectValueStyle}
          selectStyle={selectStyle}
        />
      </div>
    </div>
    <div className={css(styles.resultContainer)}>
      <div className={css(styles.row)}>
        <Button
          type="submit"
          variant="primary"
          size="large"
          fullWidth
          testId="CurrencyConverter-submit"
          loading={isLoading}
          styleSheet={styles.button}
        >
          <FormattedMessage {...messages.calculateButton} />
        </Button>
      </div>
      <div className={css(styles.result)}>
        {transactionCurrency.toUpperCase()}
        <div
          className={css(
            styles.resultAmount,
            !result || Number.isNaN(result.amount) ? styles.fade : null
          )}
        >
          <FormattedNumber
            value={(result && result.amount) || 0}
            maximumFractionDigits={2}
            minimumFractionDigits={2}
          />
        </div>
      </div>
    </div>
  </form>
);

const mapStateToProps = (state: State) => ({
  isLoading: selectors.selectIsLoading(state.currencyConverter),
  result: selectors.selectResult(state.currencyConverter),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  convertCurrency: (
    amount: string,
    inventoryCurrency: string,
    transactionCurrency: string,
    ratesBatchId: string,
    marginsBatchId: string
  ) =>
    dispatch(
      actions.convert(amount, inventoryCurrency, transactionCurrency, ratesBatchId, marginsBatchId)
    ),
});

export const validate = (values: CurrencyConvertData) => {
  const errors = {} as CurrencyConvertData;

  if (!values.amount) errors.amount = 'amountRequired';
  if (!values.inventoryCurrency) errors.inventoryCurrency = 'inventoryCurrencyRequired';

  return errors;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<CurrencyConvertData, Props>({
    form: FORM_ID,
    validate,
  })(ConverterForm)
);
