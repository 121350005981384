import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'SelectUILanguage.title',
    defaultMessage: 'Select language',
  },
  selectLabel: {
    id: 'SelectUILanguage.selectLabel',
    defaultMessage: 'Available languages*',
  },
  continue: {
    id: 'SelectUILanguage.continue',
    defaultMessage: 'Continue',
  },
  selectLanguageError: {
    id: 'SelectUILanguage.selectLanguageError',
    defaultMessage: 'Select a language to continue',
  },
});
