import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { StatusMessage } from '@trainline/depot-web';

import messages from './messages';

interface Props {
  messageId: 'requestedSuccess' | 'processedSuccess';
}

const CompensationSuccessfulAlert: FunctionComponent<Props> = ({ messageId }) => (
  <StatusMessage status="positive">
    <FormattedMessage {...messages[messageId]} />
  </StatusMessage>
);

export default CompensationSuccessfulAlert;
