import { StyleSheet } from 'aphrodite/no-important';
import { spacers, palettes, typography, fonts } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  table: {
    marginTop: spacers.dp_s32,
    width: '100%',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  tableHead: {
    color: palettes.dp_app_text_subdued,
    backgroundColor: palettes.dp_app_backdrop_dark,
  },
  tableHeader: {
    fontSize: typography.dp_small_font_size_m,
    fontWeight: fonts.dp_font_weight_semibold,
    textTransform: 'uppercase',
    padding: spacers.dp_s12,
    border: `1px solid ${palettes.dp_app_border_base}`,
  },
  reasonCode: {
    width: '300px',
  },
  tableBody: {
    textAlign: 'center',
  },
  tableCell: {
    fontWeight: fonts.dp_font_weight_bold,
    fontSize: typography.dp_small_font_size_m,
    color: palettes.dp_app_text_base,
    border: `1px solid ${palettes.dp_app_border_base}`,
    padding: `${spacers.dp_s8} ${spacers.dp_s16}`,
  },
  refundStatusList: {
    listStyle: 'none',
    textAlign: 'center',
  },
  refundStatusListItem: {
    padding: spacers.dp_s4,
  },
  ticketsType: {
    ':not(:last-child):after': {
      content: '", "',
    },
  },
  capitalized: {
    textTransform: 'capitalize',
  },
  ticketsByTypeList: {
    listStyleType: 'none',
  },
  statusIndicatorPending: {
    borderColor: colors.warning.base,
    backgroundColor: colors.warning.base,
  },
  statusIndicatorVoiding: {
    borderColor: colors.warning.base,
    backgroundColor: colors.warning.base,
  },
  statusIndicatorVoided: {
    borderColor: colors.negative.base,
    backgroundColor: colors.negative.base,
  },
  statusIndicatorBlocked: {
    borderColor: colors.negative.base,
    backgroundColor: colors.negative.base,
  },
  statusIndicatorRejected: {
    borderColor: colors.negative.base,
    backgroundColor: colors.negative.base,
  },
  statusIndicatorRefunded: {
    borderColor: colors.positive.base,
    backgroundColor: colors.positive.base,
  },
  statusIndicatorFailed: {
    borderColor: colors.negative.base,
    backgroundColor: colors.negative.base,
  },
  statusIndicatorApproved: {
    borderColor: colors.positive.base,
    backgroundColor: colors.positive.base,
  },
});
