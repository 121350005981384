import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  message: {
    color: colors.gray.alpha54,
  },
  container: {
    padding: `${spacers.dp_s24} 0`,
    marginLeft: spacers.dp_s64,
    marginRight: spacers.dp_s64,
    borderBottom: `1px dashed ${colors.loud.light}`,
  },
  label: {
    color: colors.warning.dark,
  },
  iconSecure: {
    color: colors.warning.dark,
    verticalAlign: 'middle',
    marginRight: spacers.dp_s4,
    display: 'inline',
  },
  link: {
    color: colors.info.base,
  },
});
