import { createSelector } from 'reselect';
import { compose, groupBy, uniqBy, flatten, map as rMap, toPairs, pluck, filter } from 'ramda';

const isLoading = (state) => state.supersededBooking.loading;
const getOrder = (state) => state.supersededBooking.order;
const getError = (state) => state.supersededBooking.error;
const isReturn = (journeys) => journeys.length > 1;

const getJourneys = compose(flatten, pluck('journeys'));
const getLegs = compose(flatten, pluck('legs'));
const getPassengers = compose(flatten, pluck('passengers'));
const flattenPassengers = ({ passengers }) => flatten(passengers);
const getTicket = compose(flatten, pluck('ticket'));

const groupPassengersByType = ({ travelBookings }) => {
  if (travelBookings) {
    const groupByType = groupBy(({ type }) => type.toLowerCase());
    const getUniqueId = ({ id }) => id;
    const getName = ({ name }) => name;
    const createListOfUniqueNames = ([type, value]) => ({
      type,
      passengers: uniqBy(getUniqueId, value).map(getName),
    });
    const mergeTypes = compose(
      rMap(createListOfUniqueNames),
      toPairs,
      groupByType,
      getPassengers,
      getLegs,
      getJourneys
    );
    const passengers = mergeTypes(travelBookings);
    const count = passengers.reduce((current, next) => current + next.passengers.length, 0);
    return {
      passengers,
      count,
    };
  }
  return null;
};

const getReturnDate = (journeys) => {
  const { departAt } = journeys[journeys.length - 1];
  // departAt is 0001-01-01T00:00:00+00:00 when the ticket has a validity period....
  return departAt === '0001-01-01T00:00:00+00:00' ? null : departAt;
};

const groupTicketByFareType = compose(
  groupBy(({ type }) => type),
  filter((ticket) => !!ticket)
);

const createPassengerTypeCounts = rMap(([passengerType, ticketsForPassenger]) => ({
  passengerType,
  count: ticketsForPassenger.length,
}));

const getTicketCountByPassengerType = (tickets) => {
  const groupedPassengers = groupBy(({ passengerType }) => passengerType, tickets);
  return createPassengerTypeCounts(toPairs(groupedPassengers));
};

const remapGroupedTickets = rMap(([name, tickets]) => ({
  name,
  conditionSummary: tickets[0].fareConditionSummary,
  conditions: tickets[0].fareConditions,
  count: tickets.length,
  passengerTypes: getTicketCountByPassengerType(tickets),
}));

const getTicketsGroupedByFares = compose(
  remapGroupedTickets,
  toPairs,
  groupTicketByFareType,
  getTicket,
  flattenPassengers
);

const getTicketDetails = (journeys, { deliveryMethod, vendor }) =>
  journeys.map(({ origin, destination, legs, direction }) => ({
    origin,
    destination,
    deliveryMethod,
    vendor,
    faresByType: legs[0] ? getTicketsGroupedByFares(legs[0]) : [],
    direction,
  }));

const getBookings = createSelector(getOrder, (order) => {
  if (order && order.travelBookings) {
    const { capitaineUrl, travelBookings: bookings } = order;

    return bookings.map(({ journeys, priceBreakdown, ...booking }, bookingIndex) => ({
      ...booking,
      bookingIndex,
      journeys,
      priceBreakdown,
      capitaineUrl,
      origin: journeys[0].origin,
      destination: journeys[0].destination,
      outDate: journeys[0].departAt,
      returnDate: getReturnDate(journeys),
      isReturn: isReturn(journeys),
      ticketDetails: getTicketDetails(journeys, booking),
      transactionTotalPrice: booking.transactionTotalPrice,
      inventoryTotalPrice: booking.transactionTotalPrice,
    }));
  }
  return [];
});

const getPassengersByType = createSelector(getOrder, (order) => {
  if (order && order.travelBookings) {
    return groupPassengersByType(order);
  }
  return null;
});

export default {
  isLoading,
  getError,
  getOrder,
  getBookings,
  getPassengersByType,
};
