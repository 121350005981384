import { StyleSheet } from 'aphrodite/no-important';
import { palettes, spacers, typography, fonts } from '@trainline/depot-web/css/themes/trainline';

import * as colors from '@contactcentre-web/styles/colors';

export default StyleSheet.create({
  container: {
    width: '100%',
    padding: `${spacers.dp_s24} ${spacers.dp_s64}`,
    background: palettes.dp_app_backdrop_base,
  },
  header: {
    display: 'flex',
    gap: spacers.dp_s16,
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  status: {
    display: 'flex',
    alignItems: 'center',
  },
  row: {
    flex: 1,
  },
  title: {
    fontSize: typography.dp_micro_font_size,
    lineHeight: typography.dp_micro_line_height,
    letterSpacing: typography.dp_micro_letter_spacing,
    color: colors.gray.alpha30,
    textTransform: 'uppercase',
    marginBottom: spacers.dp_s4,
    fontWeight: fonts.dp_font_weight_semibold,
  },
  directions: {
    width: '25%',
  },
  direction: {
    fontFamily: typography.dp_title2_font_family,
    fontSize: typography.dp_title2_font_size,
    fontWeight: fonts.dp_font_weight_bold,
    lineHeight: typography.dp_title2_line_height,
    letterSpacing: '-0.3px', // TODO: replace with depot-web typography when updated in depot-web
    color: colors.gray.alpha80,
  },
  returnLabel: {
    fontFamily: typography.dp_title2_font_family,
    fontSize: typography.dp_title2_font_size,
    fontWeight: fonts.dp_font_weight_regular,
    lineHeight: typography.dp_title2_line_height,
    letterSpacing: '-0.3px', // TODO: replace with depot-web typography when updated in depot-web
    color: colors.gray.alpha54,
  },
  otherItems: {
    maxWidth: '30%',
  },
  value: {
    wordWrap: 'break-word',
    paddingRight: spacers.dp_s16,
  },
  dateValue: {
    color: colors.gray.alpha54,
  },
  price: {
    textAlign: 'right',
    fontSize: typography.dp_body_font_size,
    color: colors.gray.alpha80,
  },
  inventoryPrice: {
    fontStyle: 'italic',
    fontSize: typography.dp_small_font_size_m,
    color: colors.gray.alpha54,
  },

  fulfilmentStatus: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  fulfilmentStatusPositive: {
    fontWeight: fonts.dp_font_weight_bold,
    color: colors.positive.dark,
  },

  fulfilmentStatusNegative: {
    fontWeight: fonts.dp_font_weight_bold,
    color: colors.negative.dark,
  },

  tooltipPositive: {
    cursor: 'pointer',
    borderBottom: `1px dashed ${colors.positive.dark}`,
  },

  tooltipNegative: {
    cursor: 'pointer',
    borderBottom: `1px dashed ${colors.negative.dark}`,
  },

  fulfilmentStatusErrorList: {
    listStyleType: 'none',
  },

  splitSave: {
    marginLeft: spacers.dp_s24,
  },
  multifare: {
    marginLeft: spacers.dp_s24,
  },
  trainlineProductStatus: {
    textAlign: 'left',
  },

  ctrCollectionCondition: {
    color: colors.gray.alpha54,
  },
});

export const fillColor = colors.gray.alpha30;
