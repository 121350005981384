import { defineMessages } from 'react-intl';

export default defineMessages({
  action: {
    id: 'MoveOrder.modal_title',
    defaultMessage: 'Move order to new account',
  },
  modal_title: {
    id: 'MoveOrder.modal_title',
    defaultMessage: 'Move order to new account',
  },
});
