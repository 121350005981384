import React, { FunctionComponent } from 'react';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { IconChat } from '@trainline/depot-web';

import Button from '@contactcentre-web/common/Button';

import styles from './styles';
import messages from './messages';

const nonExpandedMaxChars = 99;

const greaterThan = (text: string, nChars: number) => text.length > nChars;

const truncate = (isExpanded: boolean, text: string) => {
  if (!isExpanded) {
    if (greaterThan(text, nonExpandedMaxChars)) {
      return `${text.substring(0, nonExpandedMaxChars)}...`;
    }
  }
  return text;
};

const orderNoteButton = (expand: () => void, styleSheet: StyleDeclarationValue) => (
  <Button
    variant="primary"
    size="small"
    testId="expand-button"
    onClick={() => expand()}
    styleSheet={styleSheet}
  >
    <IconChat className={css(styles.commentIcon)} />
    <FormattedMessage {...messages.viewFullNote} />
  </Button>
);

interface Props {
  expand: () => void;
  isExpanded: boolean;
  message: string;
}

export const OrderNoteItem: FunctionComponent<Props> = ({ expand, isExpanded, message }) => (
  <div className={css(styles.container)}>
    <div className={css(styles.message)}>{truncate(isExpanded, message)}</div>
    {isExpanded && orderNoteButton(expand, [styles.defaultExpandButton, styles.expandedButton])}
    {!isExpanded &&
      greaterThan(message, nonExpandedMaxChars) &&
      orderNoteButton(expand, styles.defaultExpandButton)}
  </div>
);

export default OrderNoteItem;
