import React from 'react';
import PropTypes from 'prop-types';

import RadioBoxGroup from './RadioBoxGroup';

export const RadioBoxGroupConnector = ({ children, input, ...rest }) => (
  <RadioBoxGroup {...input} {...rest}>
    {React.Children.toArray(children).map((item) =>
      React.cloneElement(item, { currentValue: input.value })
    )}
  </RadioBoxGroup>
);

RadioBoxGroupConnector.propTypes = {
  children: PropTypes.array,
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any,
  }).isRequired,
};

export default RadioBoxGroupConnector;
