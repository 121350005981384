import { defineMessages } from 'react-intl';

export default defineMessages({
  passenger: {
    id: 'SeasonBooking.BookingInfo.passenger',
    defaultMessage: 'passenger',
  },
  photocard: {
    id: 'SeasonBooking.BookingInfo.photocard',
    defaultMessage: 'photocard no',
  },
  smartcard: {
    id: 'SeasonBooking.BookingInfo.smartcard',
    defaultMessage: 'smartcard no',
  },
  class: {
    id: 'SeasonBooking.BookingInfo.class',
    defaultMessage: 'class',
  },
  ticketType: {
    id: 'SeasonBooking.BookingInfo.ticketType',
    defaultMessage: 'ticket type',
  },
  validity: {
    id: 'SeasonBooking.BookingInfo.validity',
    defaultMessage: 'validity',
  },
  passengerType: {
    id: 'SeasonBooking.BookingInfo.passengerType',
    defaultMessage: '{type} passenger',
  },
  externallyModified: {
    id: 'SeasonBooking.BookingInfo.externallyModified',
    defaultMessage: 'this booking has been modified. The booking details here may be out of date.',
  },
});
