import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { StatusMessage } from '@trainline/depot-web';

import Link from '@contactcentre-web/common/Link';

import messages from './messages';
import styles from './styles';

export const ExchangedBookingAlert = ({ customerId, exchangedByOrderReference }) =>
  exchangedByOrderReference ? (
    <div className={css(styles.notification)}>
      <StatusMessage status="info">
        <FormattedMessage
          {...messages.exchangedOrder}
          values={{
            link: (
              <Link
                linkType="internal"
                to={`/customers/${customerId}/bookings/${exchangedByOrderReference}`}
              >
                <FormattedMessage {...messages.exchanged} />
              </Link>
            ),
          }}
        />
      </StatusMessage>
    </div>
  ) : null;

ExchangedBookingAlert.propTypes = {
  customerId: PropTypes.string.isRequired,
  exchangedByOrderReference: PropTypes.string,
};

export default ExchangedBookingAlert;
