import { compose, flatten, pluck } from 'ramda';
import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { events } from '../Discretionary/module';

const PREFIX = 'REFUNDS';
export const REQUEST_REFUND = `${PREFIX}/REQUEST_REFUND`;
export const REQUEST_REFUND_SUCCEEDED = `${PREFIX}/REQUEST_REFUND_SUCCEEDED`;
export const REQUEST_REFUND_FAILED = `${PREFIX}/REQUEST_REFUND_FAILED`;
export const RESET_FORM = `${PREFIX}/RESET_FORM`;

export const SELECTION_UPDATED = `${PREFIX}/SELECTION_UPDATED`;
export const UPDATE_QUOTE = `${PREFIX}/UPDATE_QUOTE`;
export const UPDATE_QUOTE_SUCCESS = `${PREFIX}/UPDATE_QUOTE_SUCCESS`;
export const UPDATE_QUOTE_ERROR = `${PREFIX}/UPDATE_QUOTE_ERROR`;
export const QUOTE_VALIDATION_FAILED = `${PREFIX}/QUOTE_VALIDATION_FAILED`;

export const REFUND_REASONS = `${PREFIX}/REFUND_REASONS`;
export const REFUND_REASONS_SUCCESS = `${PREFIX}/REFUND_REASONS_SUCCESS`;
export const REFUND_REASONS_FAILED = `${PREFIX}/REFUND_REASONS_FAILED`;

export const LOAD_QUOTE = `${PREFIX}/LOAD_QUOTE`;
export const LOAD_QUOTE_FAILED = `${PREFIX}/LOAD_QUOTE_FAILED`;
export const LOAD_QUOTE_SUCCEEDED = `${PREFIX}/LOAD_QUOTE_SUCCEEDED`;

export const LOAD_QUOTE_STATE_INPROGRESS = `${PREFIX}/LOAD_QUOTE_STATE_INPROGRESS`;
export const LOAD_QUOTE_STATE_SUCCESS = `${PREFIX}/LOAD_QUOTE_STATE_SUCCESS`;
export const LOAD_QUOTE_STATE_FAILURE = `${PREFIX}/LOAD_QUOTE_STATE_FAILURE`;
export const ENTANGLEMENT_INVALID_COMBINATION = `${PREFIX}/ENTANGLEMENT_INVALID_COMBINATION`;

export const { refunds: actions } = createActions({
  [UPDATE_QUOTE]: (payload) => ({
    ...payload,
  }),
  [UPDATE_QUOTE_SUCCESS]: (bookingId, order, quote) => ({
    bookingId,
    order,
    quote,
  }),
  [UPDATE_QUOTE_ERROR]: (bookingId, error) => ({ bookingId, error }),
  [QUOTE_VALIDATION_FAILED]: (bookingId, errors) => ({ bookingId, errors }),
  [SELECTION_UPDATED]: (bookingId, isValid) => ({ bookingId, isValid }),
  [RESET_FORM]: null,
  [REQUEST_REFUND]: (orderReference, bookingId, quoteReference, tmcQuoteReference, customerId) => ({
    orderReference,
    bookingId,
    quoteReference,
    tmcQuoteReference,
    customerId,
  }),
  [REQUEST_REFUND_SUCCEEDED]: (bookingId) => ({ bookingId }),
  [REQUEST_REFUND_FAILED]: (bookingId, error, unprocessableReasons) => ({
    bookingId,
    error,
    unprocessableReasons,
  }),
  [LOAD_QUOTE]: (orderId, bookingId, refundables) => ({
    orderId,
    bookingId,
    refundables,
  }),
  [LOAD_QUOTE_FAILED]: (orderId, bookingId, error) => ({
    orderId,
    bookingId,
    error,
  }),
  [LOAD_QUOTE_SUCCEEDED]: (orderId, bookingId, quote) => ({
    orderId,
    bookingId,
    quote,
  }),
  [ENTANGLEMENT_INVALID_COMBINATION]: (isInValidCombination) => ({ isInValidCombination }),
});

const initialState = {
  requestsState: {},
  loadReasonsState: {},
};

// Some upstream errors shouldn't be treated like errors in the application
// For example when a fraud quote needs to be overriden
const nonErrorRequestFailures = ['refundRejectedByFraudProvider'];

const reducer = handleActions(
  {
    [RESET_FORM]: (state) => ({
      ...state,
      requestsState: {},
    }),
    [REQUEST_REFUND]: (state, { payload: { bookingId } }) => ({
      ...state,
      requestsState: {
        ...state.requestsState,
        [bookingId]: {
          ...state.requestsState[bookingId],
          pending: true,
          success: false,
          error: undefined,
          unprocessableReasons: undefined,
        },
      },
    }),
    [REQUEST_REFUND_SUCCEEDED]: (state, { payload: { bookingId } }) => ({
      ...state,
      requestsState: {
        ...state.requestsState,
        [bookingId]: {
          ...state.requestsState[bookingId],
          pending: false,
          success: true,
        },
      },
    }),
    [REQUEST_REFUND_FAILED]: (state, { payload: { bookingId, error, unprocessableReasons } }) => ({
      ...state,
      requestsState: {
        ...state.requestsState,
        [bookingId]: {
          ...state.requestsState[bookingId],
          pending: false,
          unprocessableReasons,
          error: nonErrorRequestFailures.includes(unprocessableReasons?.[0]?.reasonCode)
            ? undefined
            : error,
        },
      },
    }),
    [SELECTION_UPDATED]: (state, { payload: { bookingId, isValid } }) => ({
      ...state,
      requestsState: {
        ...state.requestsState,
        [bookingId]: {
          ...state.requestsState[bookingId],
          selectionUpdated: true,
          isValid,
        },
      },
    }),
    [UPDATE_QUOTE]: (state, { payload: { bookingId } }) => ({
      ...state,
      requestsState: {
        ...state.requestsState,
        [bookingId]: {
          ...state.requestsState[bookingId],
          refreshingQuote: true,
          success: false,
          updateError: undefined,
          unprocessableReasons: undefined,
        },
      },
    }),
    [UPDATE_QUOTE_SUCCESS]: (state, { payload: { bookingId } }) => ({
      ...state,
      requestsState: {
        ...state.requestsState,
        [bookingId]: {
          ...state.requestsState[bookingId],
          refreshingQuote: false,
          updateError: undefined,
          selectionUpdated: false,
        },
      },
    }),
    [UPDATE_QUOTE_ERROR]: (state, { payload: { bookingId, error } }) => ({
      ...state,
      requestsState: {
        ...state.requestsState,
        [bookingId]: {
          ...state.requestsState[bookingId],
          updateError: error,
          refreshingQuote: false,
        },
      },
    }),
    [QUOTE_VALIDATION_FAILED]: (state, { payload: { bookingId } }) => ({
      ...state,
      requestsState: {
        ...state.requestsState,
        [bookingId]: {
          ...state.requestsState[bookingId],
          refreshingQuote: false,
        },
      },
    }),
    [LOAD_QUOTE]: (state, { payload: { bookingId } }) => ({
      ...state,
      requestsState: {
        ...state.requestsState,
        [bookingId]: {
          ...state.requestsState[bookingId],
          loadQuoteState: LOAD_QUOTE_STATE_INPROGRESS,
        },
      },
    }),
    [LOAD_QUOTE_SUCCEEDED]: (state, { payload: { bookingId } }) => ({
      ...state,
      requestsState: {
        ...state.requestsState,
        [bookingId]: {
          ...state.requestsState[bookingId],
          loadQuoteState: LOAD_QUOTE_STATE_SUCCESS,
        },
      },
    }),
    [LOAD_QUOTE_FAILED]: (state, { payload: { bookingId } }) => ({
      ...state,
      requestsState: {
        ...state.requestsState,
        [bookingId]: {
          ...state.requestsState[bookingId],
          loadQuoteState: LOAD_QUOTE_STATE_FAILURE,
        },
      },
    }),
    [events.APPROVE_REFUND]: (state, { payload: { bookingId } }) => ({
      ...state,
      requestsState: {
        ...state.requestsState,
        [bookingId]: {
          ...state.requestsState[bookingId],
          pending: false,
        },
      },
    }),
    [ENTANGLEMENT_INVALID_COMBINATION]: (state, { payload: { isInValidCombination } }) => ({
      ...state,
      isInValidCombination,
    }),
  },
  initialState
);

const localState = (state) => state.refundsForm;
const stateFull = (state) => state;
const getRequestsState = createSelector(localState, (state) => state.requestsState);
const isPendingFactory = (bookingId) =>
  createSelector(localState, (state) =>
    state.requestsState[bookingId] ? !!state.requestsState[bookingId].pending : false
  );
const isRefreshingQuoteFactory = (bookingId) =>
  createSelector(localState, (state) =>
    state.requestsState[bookingId] ? !!state.requestsState[bookingId].refreshingQuote : false
  );
const isSuccessFactory = (bookingId) =>
  createSelector(localState, (state) =>
    state.requestsState[bookingId] ? !!state.requestsState[bookingId].success : false
  );
const isErrorFactory = (bookingId) =>
  createSelector(localState, (state) =>
    state.requestsState[bookingId] ? !!state.requestsState[bookingId].error : false
  );
const isWarningFactory = (bookingId) =>
  createSelector(
    localState,
    (state) =>
      state.requestsState[bookingId]?.pending === false &&
      state.requestsState[bookingId]?.success !== true &&
      (state.requestsState[bookingId]?.unprocessableReasons?.length ?? 0) > 0
  );
const requestRefundErrors = (bookingId) =>
  createSelector(localState, (state) =>
    state.requestsState[bookingId]?.error?.errors?.map((error) => error.code.split('.')[1] || [])
  );
const isSelectionUpdatedFactory = (bookingId) =>
  createSelector(localState, (state) =>
    state.requestsState[bookingId]
      ? state.requestsState[bookingId].selectionUpdated || false
      : false
  );
const isSelectionValidFactory = (bookingId) =>
  createSelector(localState, (state) => {
    if (
      state.requestsState[bookingId] === undefined ||
      state.requestsState[bookingId].isValid === undefined
    ) {
      return true;
    }

    return state.requestsState[bookingId].isValid;
  });
const updateQuoteErrorFactory = (bookingId) =>
  createSelector(localState, (state) =>
    state.requestsState[bookingId] ? state.requestsState[bookingId].updateError : undefined
  );
const hasLoadQuoteSucceededFactory = (bookingId) =>
  createSelector(
    localState,
    (state) =>
      (state.requestsState[bookingId] &&
        state.requestsState[bookingId].loadQuoteState === LOAD_QUOTE_STATE_SUCCESS) ||
      false
  );
const getQuote = (bookingId) =>
  createSelector(stateFull, (state) => {
    if (state.refundsTermsAndConditions?.quotes[bookingId])
      return state.refundsTermsAndConditions?.quotes[bookingId];
    if (state.discretionaryRefunds?.quotes[bookingId])
      return state.discretionaryRefunds?.quotes[bookingId];
    return {};
  });
const hasLoadQuoteFailedFactory = (bookingId) =>
  createSelector(
    localState,
    (state) =>
      (state.requestsState[bookingId] &&
        state.requestsState[bookingId].loadQuoteState === LOAD_QUOTE_STATE_FAILURE) ||
      false
  );

const canRequestRefund = (bookingId) =>
  createSelector(stateFull, (state) =>
    state.discretionaryRefunds?.quotes[bookingId]?.canRequestRefund === undefined
      ? true
      : state.discretionaryRefunds?.quotes[bookingId].canRequestRefund
  );

export const getRefundableEntanglements = (refundable, orderEntanglements = []) => {
  const { passengerRefundables = [], entanglements = [] } = refundable;

  const entanglementIds = passengerRefundables
    .map(({ entanglementId }) => entanglementId)
    .filter(Boolean);

  const uniqueEntanglementIds = [...new Set(entanglementIds)];

  const bookingEntanglements = orderEntanglements.filter((orderEntanglement) =>
    uniqueEntanglementIds.includes(orderEntanglement.id)
  );

  return entanglements.concat(bookingEntanglements);
};

const isBannerInvalidEntanglements = (bookingId) =>
  createSelector(stateFull, ({ refundsForm, refundsTermsAndConditions }) => {
    const isInValidCombination = !!refundsForm?.isInValidCombination;

    if (!isInValidCombination) {
      return false;
    }

    const { refundables = [], orderEntanglements = [] } =
      refundsTermsAndConditions?.refundables || {};

    const refundable = refundables.find((p) => p.productId === bookingId);

    const bookingEntanglements = getRefundableEntanglements(refundable, orderEntanglements);

    return bookingEntanglements.length > 0;
  });

const bookingHasEntanglements = createSelector(stateFull, (state) => {
  const passengerRefundables = compose(
    flatten(),
    pluck('passengerRefundables')
  )(
    state?.refundsTermsAndConditions?.refundables
      ? state?.refundsTermsAndConditions?.refundables?.refundables
      : state?.discretionaryRefunds
  );

  return !!passengerRefundables?.some((p) => p.entanglementEnforcementMode === 'strict');
});

export const selectors = {
  isPendingFactory,
  isSuccessFactory,
  isErrorFactory,
  isWarningFactory,
  isSelectionUpdatedFactory,
  updateQuoteErrorFactory,
  isRefreshingQuoteFactory,
  isSelectionValidFactory,
  hasLoadQuoteSucceededFactory,
  hasLoadQuoteFailedFactory,
  getQuote,
  getRequestsState,
  requestRefundErrors,
  canRequestRefund,
  isBannerInvalidEntanglements,
  bookingHasEntanglements,
};

export default reducer;
