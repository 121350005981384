import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'paperTicketHeader.title',
    defaultMessage: 'Paper ticket validation queue',
  },
  unmatchedTabName: {
    id: 'paperTicketHeader.unmatchedTabName',
    defaultMessage: 'Unmatched tickets',
  },
  failedValidationTabName: {
    id: 'paperTicketHeader.failedValidationTabName',
    defaultMessage: 'Enhanced review',
  },
});
