import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'RefundApprovals.title',
    defaultMessage: 'Discretionary refund approval',
  },
  approvalError: {
    id: 'RefundApprovals.approvalError',
    defaultMessage: 'An error occurred while approving the request. Please try again or report it.',
  },
  approvalRefundRequestAmended: {
    id: 'RefundApprovals.approvalRefundRequestAmended',
    defaultMessage:
      'This refund has been amended by someone else. Please refresh the page to see the latest version',
  },
  approvalSuccess: {
    id: 'RefundApprovals.approvalSuccess',
    defaultMessage: 'The discretionary refund has been approved.',
  },
  approvalRefundRequestAlreadyProcessed: {
    id: 'RefundApprovals.approvalRefundRequestAlreadyProcessed',
    defaultMessage:
      'The discretionary refund has has already been approved or rejected. Refresh the list to get most up-to-date state',
  },
  approvalRefundRequestRejectedByFraud: {
    id: 'RefundApprovals.approvalRefundRequestRejectedByFraud',
    defaultMessage:
      'Please override this block and continue to process the refund. If the customer is requesting help to self serve after-sales for future bookings then please raise a request to the F & P Revenue Protection Group queue in Freshdesk for further handling.',
  },
  rejectSuccess: {
    id: 'RefundApprovals.rejectSuccess',
    defaultMessage: 'The discretionary refund has been rejected.',
  },
  countSummary: {
    id: 'RefundApprovals.countSummary',
    defaultMessage: 'Viewing {lowerBound}-{upperBound} of {totalCount}',
  },
  approvalInvalidQuoteError: {
    id: 'RefundApprovals.approvalInvalidQuoteError',
    defaultMessage:
      'This Discretionary Refund request has expired. It will need to be re-created. Please reject this expired refund request to clear it from the approvals queue, and re-create a new Discretionary Refund in its place.',
  },
  loadMore: {
    id: 'RefundApprovals.loadMore',
    defaultMessage: 'Load {amount} more',
  },
  sortBy: {
    id: 'RefundApprovals.sortBy',
    defaultMessage: '<span>Sort by: </span>  {sortBy, select, Asc {Oldest} Desc {Newest}}',
  },
});
