import { StyleSheet } from 'aphrodite/no-important';
import { spacers, palettes, typography } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  searchButton: {
    minWidth: '40%',
  },
  exportButton: {
    minWidth: '30%',
    alignSelf: 'flex-end',
    marginTop: spacers.dp_s24,
  },
  incidentReferenceButton: {
    width: `60%`,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: `${spacers.dp_s48} auto 0`,
    width: '60%',
  },
  formSection: {
    marginTop: spacers.dp_s16,
    width: '100%',
    display: 'block',
  },
  flexRowWithSpaceBetween: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  textAreaStyles: {
    height: '40vh',
    width: `100%`,
  },
  genericButtonStyles: {
    marginLeft: spacers.dp_s12,
    width: '40%',
  },
  uploadCsvButtonStyles: {
    marginRight: 0,
    width: 'fit-content',
    height: 'fit-content',
    fontSize: typography.dp_mini_font_size,
    padding: `0 ${spacers.dp_s12}`,
    backgroundColor: palettes.dp_app_action_secondary,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    borderSpacing: 0,
  },
  tableBody: {
    ':nth-of-type(1n) tr:first-child': {
      backgroundColor: palettes.dp_app_backdrop_darker,
    },
    ':nth-of-type(1n) tr:first-child th:first-child': {
      borderTopLeftRadius: spacers.dp_s12,
      borderBottomLeftRadius: spacers.dp_s12,
    },
    ':nth-of-type(1n) tr:first-child th:last-child': {
      borderTopRightRadius: spacers.dp_s12,
      borderBottomRightRadius: spacers.dp_s12,
    },
    ':nth-of-type(1n) td:first-child': {
      borderTopLeftRadius: spacers.dp_s12,
      borderBottomLeftRadius: spacers.dp_s12,
    },
    ':nth-of-type(1n) td:last-child': {
      borderTopRightRadius: spacers.dp_s12,
      borderBottomRightRadius: spacers.dp_s12,
    },
    ':nth-of-type(1n) span': {
      display: 'inline-block',
      paddingTop: spacers.dp_s12,
      paddingBottom: spacers.dp_s12,
      textAlign: 'center',
      width: '100%',
    },
    ':nth-of-type(1n) tr:nth-of-type(3n+1)': {
      backgroundColor: palettes.dp_app_backdrop_darker,
    },
  },
  textSuccess: {
    color: palettes.dp_app_text_positive,
  },
  textNotFound: {
    color: palettes.dp_app_text_warning,
  },
  textFailed: {
    color: palettes.dp_app_text_negative,
  },
  hidden: {
    display: 'none',
  },
});
