import { defineMessages } from 'react-intl';

export default defineMessages({
  railcardOrPromotionSplit: {
    id: 'EntanglementWarning.RailcardOrPromotionSplit',
    defaultMessage:
      'This refund may break a multi-passenger railcard or group saver fare. Please check T&Cs and validity of the remaining tickets.',
  },
  childFlatFareInBooking: {
    id: 'EntanglementWarning.ChildFlatFareInBooking',
    defaultMessage:
      'This refund may break a multi-passenger fare. Please check the remaining passengers tickets are still valid, including any child fares.',
  },
  saverHalfReturnNotRefunded: {
    id: 'EntanglementWarning.SaverHalfReturnNotRefunded',
    defaultMessage:
      'Booking includes a saver half return which should only be used as part of a return journey. Make sure it is not being used for a single journey only.',
  },
  splitSavePartOfSegmentRefunded: {
    id: 'EntanglementWarning.SplitSavePartOfSegmentRefunded',
    defaultMessage:
      "Please check the status of the split save ticket to see if it's been used. If partially used, the remaining ticket is non-refundable, unless there is disruption.",
  },
  entanglementWarning: {
    id: 'EntanglementWarning.EntanglementWarning',
    defaultMessage:
      'Some parts of this product are entangled, please check the fare and railcard T&Cs to ensure the remaining tickets are still valid.',
  },
});
