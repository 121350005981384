import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  modal: {
    width: '365px',
  },
  modalSubTitle: {
    marginBottom: spacers.dp_s8,
  },
  modalBody: {
    marginBottom: spacers.dp_s16,
  },
  modalInfo: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  modalInfoIcon: {
    marginRight: spacers.dp_s4,
    flexShrink: 0,
  },
});
