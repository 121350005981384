import { borderRadius, fonts, palettes, spacers } from '@trainline/depot-web/css/themes/trainline';
import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({
  compensations: {
    display: 'table',
    width: '100%',
    borderRadius: borderRadius.dp_r4,
    boxShadow: '0 4px 8px 0 rgba(33, 49, 77, 0.2)',
    marginTop: spacers.dp_s16,
  },
  compensationsHeader: {
    display: 'table-header-group',
    textTransform: 'uppercase',
    backgroundColor: palettes.dp_app_backdrop_dark,
    color: palettes.dp_brand_neutral_dark,
  },
  compensationsHeaderCell: {
    borderTop: 'none',
    fontWeight: fonts.dp_font_weight_semibold,
  },
  compensationsRow: {
    display: 'table-row',
    backgroundColor: palettes.dp_app_backdrop_base,
  },
  compensationsCell: {
    display: 'table-cell',
    flex: '0 0 auto',
    padding: `${spacers.dp_s8} ${spacers.dp_s16}`,
    borderTop: `solid 1px ${palettes.dp_app_border_base}`,
    borderLeft: `solid 1px ${palettes.dp_app_border_base}`,
    ':first-child': {
      borderLeft: 'none',
    },
    textAlign: 'center',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  wrappedExpando: {
    flex: '1 1 auto',
    whiteSpace: 'normal',
  },
  value: {
    fontWeight: fonts.dp_font_weight_bold,
  },
  empty: {
    padding: `${spacers.dp_s8} ${spacers.dp_s16}`,
  },
  amount: {
    textAlign: 'right',
    paddingRight: spacers.dp_s8,
  },
  infoIcon: {
    fill: palettes.dp_brand_primary_mid,
  },
  infoIconWrapper: {
    verticalAlign: 'middle',
    marginLeft: spacers.dp_s4,
  },
});
