import { createSelector } from 'reselect';

// Important: Notes is being transformed. Backend is needed to add topic information to notes

// Const
const GET_NOTES_REQUESTED = 'customerNotes/GET_NOTES_REQUESTED';
const GET_NOTES_SUCCEEDED = 'customerNotes/GET_NOTES_SUCCEEDED';
const GET_NOTES_FAILED = 'customerNotes/GET_NOTES_FAILED';
const ADD_NOTE_REQUESTED = 'customerNotes/ADD_NOTE_REQUESTED';
const ADD_NOTE_SUCCEEDED = 'customerNotes/ADD_NOTE_SUCCEEDED';
const ADD_NOTE_FAILED = 'customerNotes/ADD_NOTE_FAILED';
const SET_TOPIC = 'customerNotes/SET_TOPIC';
const CLEAR_TOPIC = 'customerNotes/CLEAR_TOPIC';

export const constants = {
  GET_NOTES_REQUESTED,
  GET_NOTES_SUCCEEDED,
  GET_NOTES_FAILED,
  ADD_NOTE_REQUESTED,
  ADD_NOTE_SUCCEEDED,
  ADD_NOTE_FAILED,
  SET_TOPIC,
  CLEAR_TOPIC,
};

export type Topic = 'Order' | 'Customer';

export interface Note {
  id: string;
  body: string;
  createdAt: Date;
  topic: Topic;
  topicId: string;
  createdBy: {
    id: string;
    location: string;
    username: string;
    displayName: string;
  };
}

export interface Notes {
  [topic: string]: {
    [orderId: string]: Array<Note>;
  };
}

export interface CustomerNotesState {
  topic: string | null;
  topicId: string | null;
  notes: Notes;
  loading: boolean;
}

// Reducer
export const initialState = {
  topic: null,
  topicId: null,
  notes: {},
  loading: false,
};

const updateNotes = (notes: Notes, topic: string, id: string, toAdd: Array<Note> | Note = []) => {
  if (!topic || !id) return notes;

  const oldNotes = (notes[topic] && notes[topic][id]) || [];
  const newNotes = Array.isArray(toAdd) ? toAdd : [...oldNotes, toAdd];

  return {
    ...notes,
    [topic]: {
      [id]: newNotes,
    },
  };
};

export interface Action {
  type: string;
  payload: Array<Note> & Note;
  meta: {
    topic: string;
    topicId: string;
  };
}

const reducer = (state = initialState, { payload, type, meta }: Action) => {
  switch (type) {
    case GET_NOTES_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case GET_NOTES_SUCCEEDED:
      return {
        ...state,
        notes: updateNotes(state.notes, meta.topic, meta.topicId, payload),
        loading: false,
      };

    case GET_NOTES_FAILED:
      return {
        ...state,
        loading: false,
      };

    case ADD_NOTE_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case ADD_NOTE_SUCCEEDED:
      return {
        ...state,
        notes: updateNotes(state.notes, meta.topic, meta.topicId, payload),
        loading: false,
      };

    case ADD_NOTE_FAILED:
      return {
        ...state,
        loading: false,
      };

    case SET_TOPIC:
      return {
        ...state,
        topic: payload.topic,
        topicId: payload.topicId,
      };

    case CLEAR_TOPIC:
      return {
        ...state,
        topic: null,
        topicId: null,
      };

    default:
      return state;
  }
};

export default reducer;

// Actions
export const getNotesRequested = (topic: Topic, topicId: string) => ({
  type: GET_NOTES_REQUESTED,
  payload: { topic, topicId },
});

export const getNotesSucceeded = (
  meta: { topic: string; topicId: string },
  response: Array<Note>
) => ({
  type: GET_NOTES_SUCCEEDED,
  payload: response,
  meta,
});

export const getNotesFailed = () => ({
  type: GET_NOTES_FAILED,
});

export interface NoteSubmitFormData {
  note: string;
}

export interface AddNoteRequestPayload extends NoteSubmitFormData {
  topic: Topic;
  topicId: string;
}

export const addNoteRequested = (payload: AddNoteRequestPayload) => ({
  type: ADD_NOTE_REQUESTED,
  payload,
});

export const addNoteSucceeded = (meta: AddNoteRequestPayload, response: Note) => ({
  type: ADD_NOTE_SUCCEEDED,
  payload: response,
  meta,
});

export const addNoteFailed = () => ({
  type: ADD_NOTE_FAILED,
});

export const setTopic = (topic: Topic, topicId: string) => ({
  type: SET_TOPIC,
  payload: {
    topic,
    topicId,
  },
});

export const clearTopic = () => ({
  type: CLEAR_TOPIC,
});

export const actions = {
  getNotesRequested,
  getNotesSucceeded,
  getNotesFailed,
  addNoteRequested,
  addNoteSucceeded,
  addNoteFailed,
  setTopic,
  clearTopic,
};

// Selectors
const getNotes = (state: CustomerNotesState) => state.notes;
const getLoadingSelector = (state: CustomerNotesState) => state.loading;
const getLoading = createSelector(getLoadingSelector, (loading) => loading);
const getTopic = (state: CustomerNotesState) => state.topic;
const getTopicId = (state: CustomerNotesState) => state.topicId;

const getNotesCount = (
  notes: Notes,
  { customerId, orderId }: { customerId: string; orderId?: string }
) => {
  if (notes != null) {
    const customerNotes = notes.Customer ? notes.Customer[customerId] : 0;
    const customerNotesCount = customerNotes ? customerNotes.length : 0;
    if (orderId) {
      const orderNotes = notes.Order ? notes.Order[orderId] : null;
      return orderNotes == null ? 0 : customerNotesCount + orderNotes.length;
    }
    return customerNotesCount;
  }
  return 0;
};

export const selectors = {
  getNotes,
  getNotesCount,
  getLoading,
  getTopic,
  getTopicId,
};
