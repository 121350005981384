import { defineMessages } from 'react-intl';

export default defineMessages({
  ticketsTitle: {
    id: 'ChangeOfJourney.BookingItem.tickets',
    defaultMessage: '{isSingleTicket, plural, one {Ticket} other {Tickets}}',
  },
  ticketTypes: {
    id: 'ChangeOfJourney.BookingItem.ticketTypes',
    defaultMessage: '{count} \u00D7 {ticketType}',
  },
  changeButton: {
    id: 'ChangeOfJourney.BookingItem.changeButton',
    defaultMessage:
      '{isChangeable, select, true {Continue to search} false {Change not available}}',
  },
});
