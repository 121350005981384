import { defineMessages } from 'react-intl';

export default defineMessages({
  bookingSingle: {
    id: 'Detaxe.DetaxeForm.bookingSingle',
    defaultMessage: 'Single',
  },
  bookingReturn: {
    id: 'Detaxe.DetaxeForm.bookingReturn',
    defaultMessage: 'Return',
  },
  buttonContinue: {
    id: 'Detaxe.DetaxeForm.buttonContinue',
    defaultMessage: 'Continue',
  },
});
