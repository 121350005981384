import React, { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { useHistory } from 'react-router';
import { css } from 'aphrodite/no-important';
import { StatusMessage } from '@trainline/depot-web';

import { FetchError } from '@contactcentre-web/utils/error';
import TextField from '@contactcentre-web/common/TextField';
import Button from '@contactcentre-web/common/Button';
import {
  ManualEntryConsoleClaimItemResponse,
  useManualEntryConsoleCreateClaim,
} from '@contactcentre-web/hooks/api/useManualEntryConsole';
import { useErrorMapping } from '@contactcentre-web/hooks/api/useErrorMapping';

import messages from './messages';
import styles from './styles';

const FORM_ID = 'ManualEntryConsoleAddForm';

export const ManualEntryConsoleAddForm: FunctionComponent<InjectedFormProps<MECAddFormData>> = ({
  pristine,
  invalid,
  handleSubmit,
}) => {
  const { mutate, error, isLoading } = useManualEntryConsoleCreateClaim({
    onSuccess: (data: ManualEntryConsoleClaimItemResponse) => {
      history.push(`/mec-claim-edit/${data.id}`);
    },
  });
  const errorMessage = useErrorMapping(error, messages, 'genericError');
  const { formatMessage } = useIntl();
  const history = useHistory();

  const mapErrorCode = (error: FetchError) => {
    const { status } = error;

    switch (status) {
      case 403:
        return messages.noMatchingOrder;
      case 404:
        return messages.noProductToBeReclaimed;
      case 500:
        return errorMessage;
      default:
        return messages.genericError;
    }
  };

  const handleFormSubmit = (data: MECAddFormData) => {
    mutate(data);
  };

  return (
    <form
      id={FORM_ID}
      onSubmit={handleSubmit(handleFormSubmit)}
      data-testid="manualEntryConsoleAddForm"
      className={css(styles.formContainer)}
    >
      {error?.status && (
        <StatusMessage status="negative">
          <FormattedMessage {...mapErrorCode(error)} />
        </StatusMessage>
      )}
      <div className={css(styles.form)}>
        <TextField
          label={formatMessage(messages.friendlyOrderId)}
          testId="mec-orderReference"
          type="string"
          name="orderReference"
          messages={messages}
        />
      </div>
      <div className={css(styles.formButtons)}>
        <Button
          type="button"
          variant="tertiary"
          size="large"
          onClick={() => history.push('/mec-claim-approvals')}
        >
          <FormattedMessage {...messages.cancelButton} />
        </Button>
        <Button
          type="submit"
          variant="primary"
          size="large"
          disabled={pristine || invalid}
          loading={isLoading}
        >
          <FormattedMessage {...messages.createClaim} />
        </Button>
      </div>
    </form>
  );
};

export interface MECAddFormData {
  orderReference: string;
}

export const validate = (data: MECAddFormData): Record<string, string | undefined> => ({
  orderReference: !data?.orderReference ? 'validation_orderReference_invalid' : undefined,
});

export default reduxForm<MECAddFormData>({
  form: FORM_ID,
  validate,
})(ManualEntryConsoleAddForm);
