import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';

import NoSearchResult from '@contactcentre-web/common/NoSearchResult/NoSearchResult';

import CustomerList from '../CustomerList/CustomerList';
import type { CustomerSearchResultItem } from '../../utils/searchService';

import messages from './messages';
import styles from './styles';

interface Props {
  searching: boolean;
  customers: Array<CustomerSearchResultItem>;
  onCustomerSelected: (id: string) => void;
  criterion?: string;
}

const CustomerResults: FunctionComponent<Props> = ({
  searching,
  customers,
  onCustomerSelected,
  criterion,
}) => {
  const hasCustomer = customers.length > 0;

  if (criterion && !searching && !hasCustomer) {
    return (
      <div data-test="noResults" className={css(styles.container)}>
        <NoSearchResult>
          <FormattedMessage {...messages.noResults} />
        </NoSearchResult>
      </div>
    );
  }

  if (criterion && hasCustomer) {
    return (
      <div className={css(styles.container)}>
        <CustomerList
          customers={customers}
          search={criterion}
          onCustomerSelected={onCustomerSelected}
        />
      </div>
    );
  }

  return null;
};

export default CustomerResults;
