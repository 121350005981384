import React, { FunctionComponent, MouseEventHandler } from 'react';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';
import { IconChevronDown, IconChevronUp } from '@trainline/depot-web';

import Button from '../Button';

import styles from './styles';

interface AnchorProps {
  styleSheet?: { anchor?: StyleDeclarationValue; chevron?: StyleDeclarationValue };
  onClick: MouseEventHandler<HTMLButtonElement>;
  isOpen: boolean;
  testId?: string;
}

export const Anchor: FunctionComponent<AnchorProps> = ({
  styleSheet = {},
  onClick,
  isOpen,
  testId,
  children,
}) => (
  <Button
    variant="ghost"
    testId={testId || 'expandable-anchor'}
    styleSheet={[styles.anchor, styleSheet.anchor]}
    onClick={onClick}
    ariaExpanded={isOpen}
  >
    {children}
    {isOpen ? (
      <IconChevronUp className={css(styleSheet.chevron)} />
    ) : (
      <IconChevronDown className={css(styleSheet.chevron)} />
    )}
  </Button>
);

interface WrapperProps {
  styleSheet?: StyleDeclarationValue;
  testId?: string;
}

export const Wrapper: FunctionComponent<WrapperProps> = ({ styleSheet, testId, children }) => (
  <div className={css(styles.wrapper, styleSheet)} data-testid={testId}>
    {children}
  </div>
);

interface ContentProps {
  isOpen: boolean;
  styleSheet?: StyleDeclarationValue;
}

export const Content: FunctionComponent<ContentProps> = ({ isOpen, styleSheet, children }) => (
  <div
    data-testid="expandable-content"
    className={css(styles.content, styleSheet, isOpen && styles.openedContent)}
  >
    {children}
  </div>
);

export default {
  Anchor,
  Wrapper,
  Content,
};
