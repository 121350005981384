import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { OrderHistoryCard, OrderHistoryCardHeader } from '../OrderHistoryCard/OrderHistoryCard';
import type { MoveOrderItem } from '../../module';

import messages from './messages';

interface Props extends MoveOrderItem {
  orderReference: string;
}

const MoveOrder: FunctionComponent<Props> = ({
  orderReference,
  fromCustomerEmail,
  toCustomerEmail,
  createdAt,
  agent,
}) => (
  <OrderHistoryCard
    cardTitle={<FormattedMessage {...messages.title} />}
    createdAt={createdAt}
    headerItems={
      <>
        <OrderHistoryCardHeader label={<FormattedMessage {...messages.agent} />} value={agent} />
      </>
    }
    cardBody={
      <FormattedMessage
        {...messages.message}
        values={{
          orderReference,
          fromCustomerEmail,
          toCustomerEmail,
        }}
      />
    }
  />
);

export default MoveOrder;
