import { createSelector } from 'reselect';

import type State from '@contactcentre-web/redux-common/types/State';

import { loadStates, resolutionStates } from './module';

const localState = (state: State) => state.compensationApprovals;
const approvals = createSelector(localState, (state) => state.approvals);
const pagination = createSelector(localState, (state) => state.pagination || null);
const isLoading = createSelector(localState, (state) => state.loadStatus === loadStates.INPROGRESS);
const hasFailed = createSelector(localState, (state) => state.loadStatus === loadStates.ERROR);
const isResolutionInProgress = createSelector(
  localState,
  (state) => state.resolutionStatus === resolutionStates.INPROGRESS
);

const hasResolutionSucceeded = createSelector(
  localState,
  (state) => state.resolutionStatus === resolutionStates.SUCCESS
);

const hasResolutionFailed = createSelector(
  localState,
  (state) => state.resolutionStatus === resolutionStates.ERROR
);

const resolutionType = createSelector(localState, (state) =>
  state.resolutionStatus === resolutionStates.SUCCESS ||
  state.resolutionStatus === resolutionStates.ERROR
    ? state.resolutionContext.type
    : undefined
);

const resolutionAmount = createSelector(localState, (state) =>
  state.resolutionStatus === resolutionStates.SUCCESS ||
  state.resolutionStatus === resolutionStates.ERROR
    ? state.resolutionContext.amount
    : undefined
);

const resolutionOrderReference = createSelector(localState, (state) =>
  state.resolutionStatus === resolutionStates.SUCCESS ||
  state.resolutionStatus === resolutionStates.ERROR
    ? state.resolutionContext.orderReference
    : undefined
);

export default {
  approvals,
  pagination,
  isLoading,
  hasFailed,
  isResolutionInProgress,
  hasResolutionSucceeded,
  hasResolutionFailed,
  resolutionType,
  resolutionAmount,
  resolutionOrderReference,
};
