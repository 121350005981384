import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';
import { StatusMessage } from '@trainline/depot-web';

import Link from '@contactcentre-web/common/Link';

import messages from './messages';
import styles from './styles';

const ConvertedByBookingAlert = ({ bookingNumber, convertedByLink }) => (
  <div className={css(styles.notification)}>
    <StatusMessage status="info">
      <FormattedMessage
        {...messages.bookingHasBeenConverted}
        values={{
          bookingNumber,
          link: (
            <Link linkType="internal" to={convertedByLink}>
              <FormattedMessage {...messages.newBooking} />
            </Link>
          ),
        }}
      />
    </StatusMessage>
  </div>
);

ConvertedByBookingAlert.propTypes = {
  bookingNumber: PropTypes.number.isRequired,
  convertedByLink: PropTypes.string,
};

export default ConvertedByBookingAlert;
