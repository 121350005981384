import { createFeatureToggleReducer } from 'feature-toggle';

import type State from '@contactcentre-web/redux-common/types/State';

export const APP_INIT = 'CCW/APP_INIT';
export const appInit = () => ({ type: APP_INIT });

export default createFeatureToggleReducer();

export interface FeaturesState {
  fulfilment: boolean;
  shortenAccessTokenLifetime: boolean;
  paperTicket: boolean;
  overridingDiscretionaryRefundAmount: boolean;
  virtualTerminalDebiting: boolean;
  discountCard: boolean;
}

const isFulfilmentConversionEnabled = (state: FeaturesState) => state.fulfilment;
const isShortenAccessTokenLifetimeEnabled = (state: State) =>
  state.features.shortenAccessTokenLifetime;
const isPaperTicketEnabled = (state: State) => state.features.paperTicket;
const isOverridingDiscretionaryRefundAmountEnabled = (state: State) =>
  state.features.overridingDiscretionaryRefundAmount;
const isvirtualTerminalDebitingEnabled = (state: State) => state.features.virtualTerminalDebiting;

export const selectors = {
  isFulfilmentConversionEnabled,
  isShortenAccessTokenLifetimeEnabled,
  isPaperTicketEnabled,
  isOverridingDiscretionaryRefundAmountEnabled,
  isvirtualTerminalDebitingEnabled,
};
