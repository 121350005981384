import React from 'react';
import { css } from 'aphrodite/no-important';
import { FormattedMessage } from 'react-intl';

import LocalAreaProduct from '@contactcentre-web/redux-common/types/LocalAreaProduct';
import FormattedDateInLondonTz from '@contactcentre-web/common/FormattedDateInLondonTz';
import FormattedCurrency from '@contactcentre-web/common/FormattedCurrency';
import FulfilmentStatus from '@contactcentre-web/common/FulfilmentStatus';
import VendorStatus from '@contactcentre-web/common/VendorStatus';

import messages from './messages';
import styles from './styles';

const CustomerTravelcard = ({
  localAreaValidity,
  fulfilmentStatus,
  validity,
  deliveryMethod,
  status,
  ctr,
  transactionTotalPrice,
}: LocalAreaProduct) => (
  <div className={css(styles.container)}>
    <div className={css(styles.col, styles.localAreaValidityCol)}>
      <h3>{localAreaValidity}</h3>
      <FormattedMessage {...messages.travelcard}>
        {(text: string) => <p className={css(styles.productTypeLabel)}>{text}</p>}
      </FormattedMessage>
      <FulfilmentStatus status={fulfilmentStatus} />
    </div>
    <div className={css(styles.col)}>
      <FormattedMessage {...messages.validity}>
        {(text: string) => <h4 className={css(styles.title)}>{text}</h4>}
      </FormattedMessage>
      <div>
        <FormattedMessage {...messages.validFrom} />
        <br />
        <FormattedDateInLondonTz value={validity.from}>
          {(text: string) => <b>{text}</b>}
        </FormattedDateInLondonTz>
      </div>
      <div>
        <FormattedMessage {...messages.validTo} />
        <br />
        <FormattedDateInLondonTz value={validity.to}>
          {(text: string) => <b>{text}</b>}
        </FormattedDateInLondonTz>
      </div>
    </div>
    <div className={css(styles.col)}>
      <FormattedMessage {...messages.deliveryMethod}>
        {(text: string) => <h4 className={css(styles.title)}>{text}</h4>}
      </FormattedMessage>
      {deliveryMethod.displayName}
      <div className={css(styles.status)}>
        <VendorStatus status={status} />
      </div>
    </div>
    <div className={css(styles.col)}>
      <FormattedMessage {...messages.ctr}>
        {(text: string) => <h4 className={css(styles.title)}>{text}</h4>}
      </FormattedMessage>
      {ctr}
    </div>
    <div className={css(styles.priceCol)}>
      <FormattedMessage {...messages.productPrice}>
        {(text: string) => <h4 className={css(styles.title)}>{text}</h4>}
      </FormattedMessage>
      <b>
        <FormattedCurrency {...transactionTotalPrice} />
      </b>
    </div>
  </div>
);

export default CustomerTravelcard;
