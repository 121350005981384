import React from 'react';
import { FormattedMessage } from 'react-intl';

import FormattedBookings from '@contactcentre-web/common/FormattedBookings';
import FormattedInsuranceProducts from '@contactcentre-web/common/FormattedInsuranceProducts';
import ProductType from '@contactcentre-web/redux-common/types/ProductType';

import messages from './messages';

interface Props {
  paymentServiceProvider: string;
  reimbursedBookings: {
    type: ProductType;
    name?: string;
    origin?: string;
    destination?: string;
    isReturn?: boolean;
    vendorRegion?: string;
  }[];
  reimbursedInsurances: { description: string }[];
}

const RecommendationMessage = ({
  paymentServiceProvider,
  reimbursedBookings,
  reimbursedInsurances,
}: Props) => {
  const reimbursedBookingsVendorRegion = reimbursedBookings.map(({ vendorRegion }) => vendorRegion);

  const displayReimbursementFailMessageForSeason = reimbursedBookings.every(
    ({ type }) => type === ProductType.Season
  );
  const displayReimbursementFailMessageForEU = reimbursedBookingsVendorRegion.every(
    (reimbursedBookingVendorRegion) => reimbursedBookingVendorRegion === 'eu'
  );
  const displayReimbursementFailMessageForEUAndUK =
    reimbursedBookingsVendorRegion.includes('eu') && reimbursedBookingsVendorRegion.includes('uk');

  const bookings = <FormattedBookings bookings={reimbursedBookings} />;
  const insurances = (
    <FormattedInsuranceProducts
      insurances={reimbursedInsurances}
      messages={messages}
      hasBookings={!!reimbursedBookings.length}
    />
  );

  if (displayReimbursementFailMessageForSeason) {
    return (
      <FormattedMessage
        {...messages.reimbursementFailNoticeForSeason}
        values={{
          paymentServiceProvider,
          bookings,
        }}
      />
    );
  }

  if (displayReimbursementFailMessageForEU) {
    return (
      <FormattedMessage
        {...messages.reimbursementFailNoticeForEU}
        values={{
          paymentServiceProvider,
          bookings,
          insurances,
        }}
      />
    );
  }

  if (displayReimbursementFailMessageForEUAndUK) {
    return (
      <FormattedMessage
        {...messages.reimbursementFailNoticeForEUAndUK}
        values={{
          paymentServiceProvider,
          bookings,
          insurances,
        }}
      />
    );
  }

  return (
    <FormattedMessage
      {...messages.reimbursementFailNoticeForUK}
      values={{
        paymentServiceProvider,
        bookings,
        insurances,
      }}
    />
  );
};

export default RecommendationMessage;
