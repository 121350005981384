import { handleActions } from 'redux-actions';

import type Price from '@contactcentre-web/redux-common/types/Price';

import {
  PREFIX,
  events,
  loadCompensationsState,
  requestCompensationsState,
  loadRefundsState,
} from './module';

export interface ReasonData {
  id: string;
  type: string;
  attributes: {
    code: string;
    description: string;
    parentId: string | null;
    selectable: boolean;
    csgCode: string | null;
  };
}

export interface CompensationReasons {
  data: Array<ReasonData>;
}

interface Reason {
  id: string;
  description: string;
}

type ReasonCode = Reason & { parent: Reason & { parent: Reason | null } };

export type RehydratedReasonCode =
  | {
      id: string;
      description: string;
      parent: string | null;
    }
  | ReasonCode;

export interface PendingCompensation {
  id: string;
  note: string;
  reasonCode: ReasonCode;
  reasonCodeId: string;
  requestedDate: Date;
  requestingUserName: string;
  value: Price;
}

export type CompensationErrorCode = 'CompensationAmount.invalidRange' | 'compensation_genericError';

export interface CompensationError {
  errorCode: CompensationErrorCode;
  context?: {
    amountLimit: string;
    currencyCode: string;
  };
}

export interface CompensationState {
  requestState: string;
  loadState: string;
  loadRefundsState: string;
  compensation?: PendingCompensation;
  compensationReasons?: CompensationReasons;
  errorCode?: string;
  hasRefunds: boolean;
  error?: CompensationError;
}

const initialState = {
  requestState: requestCompensationsState.COMPENSATION_REQUEST_STATE_NONE,
  loadState: loadCompensationsState.COMPENSATION_LOAD_NONE,
  loadRefundsState: loadRefundsState.REFUNDS_LOAD_NONE,
  compensation: undefined,
  errorCode: undefined,
  hasRefunds: false,
};

interface CompensationPayload extends CompensationError {
  compensationReasons: CompensationReasons;
  compensation: PendingCompensation;
  refundResponse: { hasRefunds: boolean };
}

export default handleActions<CompensationState, CompensationPayload>(
  {
    [events.RESET]: () => initialState,
    [events.COMPENSATION_LOAD]: (state) => ({
      ...state,
      loadState: loadCompensationsState.COMPENSATION_LOAD_INPROGRESS,
      requestState: requestCompensationsState.COMPENSATION_REQUEST_STATE_NONE,
      loadRefundsState: loadRefundsState.REFUNDS_LOAD_INPROGRESS,
      errorCode: undefined,
      hasRefunds: false,
    }),
    [events.COMPENSATION_LOAD_SUCCEEDED]: (
      state,
      { payload: { compensationReasons, compensation } }
    ) => ({
      ...state,
      loadState: loadCompensationsState.COMPENSATION_LOAD_SUCCESS,
      compensationReasons,
      compensation,
    }),
    [events.COMPENSATION_LOAD_FAILED]: (state) => ({
      ...state,
      loadState: loadCompensationsState.COMPENSATION_LOAD_ERROR,
    }),
    [events.COMPENSATION_REQUEST_ATTEMPT]: (state) => ({
      ...state,
      requestState: requestCompensationsState.COMPENSATION_REQUEST_STATE_INPROGRESS,
    }),
    [events.COMPENSATION_REQUEST_SUCCESS]: (state) => ({
      ...state,
      requestState: requestCompensationsState.COMPENSATION_REQUEST_STATE_SUCCESSFUL,
    }),
    [events.COMPENSATION_REQUEST_FAILURE]: (state, { payload }) => ({
      ...state,
      requestState: requestCompensationsState.COMPENSATION_REQUEST_STATE_FAILED,
      error: payload,
    }),
    [events.REFUNDS_LOAD_SUCCEEDED]: (state, { payload: { refundResponse } }) => ({
      ...state,
      loadRefundsState: loadRefundsState.REFUNDS_LOAD_SUCCESS,
      hasRefunds: refundResponse != null && refundResponse.hasRefunds,
    }),
    [events.REFUNDS_LOAD_FAILED]: (state) => ({
      ...state,
      loadRefundsState: loadRefundsState.REFUNDS_LOAD_ERROR,
    }),
  },
  initialState,
  { prefix: PREFIX }
);
