import { defineMessages } from 'react-intl';

export default defineMessages({
  buttonBack: {
    id: 'manualEntryConsoleAddHeader.buttonBack',
    defaultMessage: 'Back to queue',
  },
  title: {
    id: 'manualEntryConsoleAddHeader.title',
    defaultMessage: 'Create claim',
  },
});
