import React, { Dispatch, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { StatusMessage, Heading, IconSwapUpDown } from '@trainline/depot-web';

import Button from '@contactcentre-web/common/Button';
import Loader from '@contactcentre-web/common/Loader';
import ErrorBoundary from '@contactcentre-web/common/ErrorBoundary/ErrorBoundary';
import type State from '@contactcentre-web/redux-common/types/State';
import type Action from '@contactcentre-web/redux-common/types/Action';
import useToggleSortDirection, {
  SortDirectionType,
} from '@contactcentre-web/hooks/shared/useToggleSortDirection';

import RefundsList from './components/RefundsList/RefundsList';
import { actions, ApprovalFailedMessage } from './module';
import selectors, { RequestData } from './selectors';
import messages from './messages';
import styles from './styles';

interface StateProps {
  isLoading: boolean;
  isLoadingNext: boolean;
  requests: RequestData;
  hasFailed: boolean;
  approvalFailed: boolean;
  approvalFailedMessage?: ApprovalFailedMessage;
  approvalSucceeded: boolean;
  rejectSucceeded: boolean;
  rejectFailed: boolean;
  hasLoadNextFailed: boolean;
  requestId?: string;
}

interface DispatchProps {
  loadPendingRefunds: (sortDirection?: SortDirectionType) => void;
  loadNextPendingRefunds: (sortDirection: SortDirectionType) => void;
  resetState: () => void;
}

export type Props = DispatchProps & StateProps;

export const RefundApprovals: FunctionComponent<Props> = ({
  loadPendingRefunds,
  approvalFailed,
  rejectFailed,
  isLoading,
  isLoadingNext,
  requests,
  hasFailed,
  approvalFailedMessage,
  approvalSucceeded,
  rejectSucceeded,
  requestId,
  loadNextPendingRefunds,
  resetState,
}) => {
  const PAGE_NAME = 'RefundsApprovals';
  const {
    newSortDirection,
    sortDirection,
    toggleSortDirection,
    loadSortDirectionStorage,
    storeSortDirectionStorage,
  } = useToggleSortDirection(PAGE_NAME);
  const { search, pathname } = useLocation();
  const { success } = qs.parse(search);
  const hasMore = !!requests.nextPage;
  const hasRequests = Array.isArray(requests) && requests.length > 0;
  const isInlineMessage = approvalFailedMessage && requestId;

  React.useEffect(() => {
    const initialSortDirection = loadSortDirectionStorage(PAGE_NAME);
    if (initialSortDirection) {
      storeSortDirectionStorage(initialSortDirection, PAGE_NAME);
    }
    loadPendingRefunds(sortDirection);

    return () => {
      resetState();
    };
  }, []);

  React.useEffect(() => {
    const shouldScrollToTop =
      (approvalFailed && !isInlineMessage) || rejectFailed || approvalSucceeded;
    if (shouldScrollToTop) {
      window.scrollTo(0, 0);
    }
  }, [approvalFailed, rejectFailed, approvalSucceeded, approvalFailedMessage]);

  const handleClick = () => {
    toggleSortDirection();
    storeSortDirectionStorage(newSortDirection, PAGE_NAME);
    resetState();
    loadPendingRefunds(newSortDirection);
  };

  return (
    <ErrorBoundary>
      <Heading typeStyle="title1" as="h1" color="base">
        <FormattedMessage {...messages.title} />
      </Heading>
      <Button
        variant="tertiary"
        size="small"
        onClick={handleClick}
        testId="sortDirectionButton"
        styleSheet={styles.toggleButton}
      >
        {
          <>
            <FormattedMessage
              {...messages.sortBy}
              values={{
                sortBy: newSortDirection,
                span: (msg: string) => <span>{msg}</span>,
              }}
            />
            <IconSwapUpDown />
          </>
        }
      </Button>
      <div className={css(styles.container)}>
        {isLoading && <Loader />}
        {!isLoading && !isLoadingNext && !hasFailed && (
          <section>
            {approvalFailed && approvalFailedMessage && !isInlineMessage && (
              <StatusMessage status="negative">
                <FormattedMessage
                  {...(messages[approvalFailedMessage] || messages.approvalError)}
                  data-testid="failure-banner"
                />
              </StatusMessage>
            )}
            {(approvalSucceeded || success) && (
              <StatusMessage status="positive">
                <FormattedMessage {...messages.approvalSuccess} data-testid="success-banner" />
              </StatusMessage>
            )}
            {rejectSucceeded && (
              <StatusMessage status="positive">
                <FormattedMessage {...messages.rejectSuccess} data-testid="reject-success-banner" />
              </StatusMessage>
            )}
            {rejectFailed && (
              <StatusMessage status="negative">
                <FormattedMessage {...messages.approvalError} data-testid="failure-banner" />
              </StatusMessage>
            )}
            <RefundsList requests={requests} returnUrl={encodeURI(`${pathname}?success=true`)} />
          </section>
        )}
      </div>
      <div className={css(styles.footer)}>
        {isLoadingNext && <Loader />}
        {hasRequests && !isLoading && !isLoadingNext && (
          <div className={css(styles.countSummary)}>
            <FormattedMessage
              {...messages.countSummary}
              values={{
                lowerBound: requests?.length !== 0 ? 1 : 0,
                upperBound: requests.length,
                totalCount: requests.totalCount,
              }}
            />
          </div>
        )}
        {hasMore && !isLoading && !isLoadingNext && (
          <Button
            variant="secondary"
            size="small"
            styleSheet={styles.loadMoreButton}
            onClick={() => {
              loadNextPendingRefunds(sortDirection);
            }}
          >
            <FormattedMessage
              {...messages.loadMore}
              values={{
                amount: Math.min(requests.pageSize, requests.totalCount - requests.length),
              }}
            />
          </Button>
        )}
      </div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: State) => ({
  isLoading: selectors.isLoading(state),
  isLoadingNext: selectors.isLoadingNext(state),
  requests: selectors.requests(state),
  hasFailed: selectors.hasFailed(state),
  approvalFailed: selectors.hasApprovalFailed(state),
  approvalFailedMessage: selectors.approvalFailedMessage(state),
  approvalSucceeded: selectors.hasApprovalSucceeded(state),
  rejectSucceeded: selectors.hasRejectSucceeded(state),
  rejectFailed: selectors.hasRejectFailed(state),
  hasLoadNextFailed: selectors.hasLoadNextFailed(state),
  requestId: selectors.requestId(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  loadPendingRefunds: (sortDirection?: SortDirectionType) =>
    dispatch(actions.loadAttempt(sortDirection)),
  loadNextPendingRefunds: (sortDirection: SortDirectionType) =>
    dispatch(actions.loadNextAttempt(sortDirection)),
  resetState: () => dispatch(actions.resetState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RefundApprovals);
