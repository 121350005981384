import { StyleSheet } from 'aphrodite/no-important';
import { fonts, palettes, spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  noteForm: {
    marginTop: 'auto',
    background: palettes.dp_app_backdrop_dark,
    width: '100%',
    borderTop: `1px solid ${palettes.dp_app_border_base}`,
  },
  addNoteButton: {
    marginTop: spacers.dp_s12,
    marginBottom: spacers.dp_s16,
  },
  deleteDraft: {
    fontWeight: fonts.dp_font_weight_bold,
  },
  noteFooter: {
    display: 'flex',
    width: '100%',
    padding: `0 ${spacers.dp_s16}`,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
