import { StyleSheet } from 'aphrodite/no-important';
import { fonts, typography } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  message: {
    fontSize: typography.dp_body_font_size,
    lineHeight: typography.dp_body_line_height,
    letterSpacing: typography.dp_body_letter_spacing,
    fontWeight: fonts.dp_font_weight_bold,
  },
});
