/**
 * @deprecated This is a deprecated object. Please use the palettes object from "@trainline/depot-web/css/themes/trainline"
 */
export const black = '#000000';
/**
 * @deprecated This is a deprecated object. Please use the palettes object from "@trainline/depot-web/css/themes/trainline"
 */
export const white = '#FFFFFF';

/**
 * @deprecated This is a deprecated object. Please use the palettes object from "@trainline/depot-web/css/themes/trainline"
 */
export const brand = {
  lighter: '#ECFBF9',
  light: '#B3F3E9',
  base: '#01C3A7',
  mid: '#48D5B5',
  hover: '#CBF4EA',
  quirk: '#01AA91',
  dark: '#017564',
};

/**
 * @deprecated This is a deprecated object. Please use the palettes object from "@trainline/depot-web/css/themes/trainline"
 */
export const spotlight = {
  light: '#D6E5FF',
  base: '#21314D',
  dark: '#121B2B',
};

/**
 * @deprecated This is a deprecated object. Please use the palettes object from "@trainline/depot-web/css/themes/trainline"
 */
export const loud = {
  light: '#FFE8EA',
  base: '#FF585F',
  dark: '#B32C33',
};

/**
 * @deprecated This is a deprecated object. Please use the palettes object from "@trainline/depot-web/css/themes/trainline"
 */
export const kevin = {
  light: '#FFF4D9',
  base: '#FFBD24',
  dark: '#B38419',
};

/**
 * @deprecated This is a deprecated object. Please use the palettes object from "@trainline/depot-web/css/themes/trainline"
 */
export const suit = {
  light: '#EEE9FD',
  base: '#AD93ED',
  dark: '#7564A1',
};

/**
 * @deprecated This is a deprecated object. Please use the palettes object from "@trainline/depot-web/css/themes/trainline"
 */
export const info = {
  superlight: '#EEF5FF',
  light: '#E1EEFF',
  base: '#4B96F8',
  dark: '#3367AB',
};

/**
 * @deprecated This is a deprecated object. Please use the palettes object from "@trainline/depot-web/css/themes/trainline"
 */
export const positive = {
  light: '#DFF9E8',
  base: '#1ED760',
  dark: '#16A348',
};

/**
 * @deprecated This is a deprecated object. Please use the palettes object from "@trainline/depot-web/css/themes/trainline"
 */
export const negative = {
  light: '#FDE9E6',
  base: '#E32D15',
  dark: '#C71C05',
};

/**
 * @deprecated This is a deprecated object. Please use the palettes object from "@trainline/depot-web/css/themes/trainline"
 */
export const warning = {
  light: '#FDEFDF',
  base: '#E88619',
  dark: '#B56A13',
};

/**
 * @deprecated This is a deprecated object. Please use the palettes object from "@trainline/depot-web/css/themes/trainline"
 */
export const gray = {
  alpha80: '#333333',
  alpha60: '#5B6466',
  alpha58: '#696969',
  alpha54: '#757575',
  alpha30: '#B2B2B2',
  alpha21: '#CACACA',
  alpha12: '#E0E0E0',
  alpha8: '#EBEBEB',
  alpha4: '#F5F5F5',
  alpha2: '#FAFAFA',
};

/**
 * @deprecated This is a deprecated object. Please use the palettes object from "@trainline/depot-web/css/themes/trainline"
 */
export const blue = {
  light: '#F6F9ff',
  dark: '#001848',
  navy: '#031439',
  heavy: '#002472',
  royal: '#4c4fdf',
  cyan: '#a8c8f2',
};

/**
 * @deprecated This is a deprecated object. Please use the palettes object from "@trainline/depot-web/css/themes/trainline"
 */
export const tint = {
  white10: 'rgba(255, 255, 255, 0.1)',
  white50: 'rgba(255, 255, 255, 0.5)',
};

/**
 * @deprecated This is a deprecated object. Please use the palettes object from "@trainline/depot-web/css/themes/trainline"
 */
export const state = {
  disabled: gray.alpha12,
};

/**
 * @deprecated This is a deprecated object. Please use the palettes object from "@trainline/depot-web/css/themes/trainline"
 */
export const text = {
  heading: gray.alpha80,
  body: gray.alpha80,
  muted: gray.alpha30,
  fieldLabel: '#8a9497',
};
