import { defineMessages } from 'react-intl';

export default defineMessages({
  virtualTerminalCredit: {
    id: 'VirtualTerminal.virtualTerminalCredit',
    defaultMessage: 'Virtual terminal credit',
  },
  virtualTerminalDebit: {
    id: 'VirtualTerminal.virtualTerminalDebit',
    defaultMessage: 'Virtual terminal debit',
  },
  virtualTerminalCreditHelper: {
    id: 'VirtualTerminal.virtualTerminalCreditHelper',
    defaultMessage: 'Crediting will refund the customer',
  },
  virtualTerminalDebitHelper: {
    id: 'VirtualTerminal.virtualTerminalDebitHelper',
    defaultMessage: 'Debiting will charge the customer’s card',
  },
  backButton: {
    id: 'VirtualTerminal.backButton',
    defaultMessage: 'Back',
  },
  approvalQueueButton: {
    id: 'VirtualTerminal.approvalQueueButton',
    defaultMessage: 'Approval queue',
  },
});
