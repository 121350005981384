import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { WrappedFieldArrayProps } from 'redux-form';
import { IconPlus, IconMinus } from '@trainline/depot-web';

import Select from '../Select';
import Button from '../Button';

import messages from './messages';
import styles from './styles';

export type ManagedGroupAndRolesType = Array<{
  managedGroup: { id: string; name: string };
  roles: Array<{ id: string; name: string }>;
}>;

export type ManagedGroupAndRoleType = { managedGroup?: string; role?: string };

type SelectFieldOptions = Array<{
  label: string;
  value: string;
}>;

interface Props {
  availableManagedGroupsAndRoles: ManagedGroupAndRolesType;
  currentManagedGroupId?: string;
}

const ManagedGroupAndRoles = ({
  fields,
  availableManagedGroupsAndRoles,
  currentManagedGroupId,
}: WrappedFieldArrayProps & Props) => {
  const managedGroups = availableManagedGroupsAndRoles?.map(({ managedGroup }) => managedGroup);
  const allFields: Array<ManagedGroupAndRoleType> = fields.getAll();

  const getManagedGroupsOptions = (currentIndex: number): SelectFieldOptions => {
    const alreadySelectedManagedGroupsExceptCurrent = allFields
      ?.filter(Boolean)
      .map(({ managedGroup }) => managedGroup)
      .filter((_, index) => index !== currentIndex);

    const managedGroupsAvailableToSelect = managedGroups?.filter(
      (managedGroup) => !alreadySelectedManagedGroupsExceptCurrent?.includes(managedGroup.id)
    );

    return managedGroupsAvailableToSelect?.map(({ name, id }) => ({
      label: name,
      value: id,
    }));
  };

  const getRolesOptions = (currentIndex: number): SelectFieldOptions => {
    const selectedManagedGroup =
      allFields && allFields.length > 0 && allFields[currentIndex]?.managedGroup;

    const roles = availableManagedGroupsAndRoles?.filter(
      ({ managedGroup }) => managedGroup.id === selectedManagedGroup
    )[0]?.roles;

    return roles?.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  };

  const isHomeManagedGroup = (currentIndex: number): boolean => {
    const managedGroup: string = fields.get(currentIndex)?.managedGroup;
    return managedGroup === currentManagedGroupId;
  };

  return (
    <section>
      {fields.map((name: string, index: number) => (
        <div className={css(styles.container)} key={index}>
          <Select
            label={
              <FormattedMessage
                {...(index === 0
                  ? messages.defaultManagedGroupLabel
                  : messages.additionalManagedGroupLabel)}
              />
            }
            name={`${name}.managedGroup`}
            testId={`managed-group-select-${index}`}
            options={getManagedGroupsOptions(index)}
            containerStyle={styles.containerSelect}
            disabled={isHomeManagedGroup(index)}
          />
          <Select
            label={<FormattedMessage {...messages.roleLabel} />}
            name={`${name}.role`}
            testId={`role-select-${index}`}
            options={getRolesOptions(index)}
            containerStyle={[styles.containerSelect, styles.roleContainer]}
          />
          <div className={css(styles.buttonsContainer)}>
            {index > 0 && (
              <Button
                variant="ghost"
                testId={`remove-field-${index}`}
                onClick={() => fields.remove(index)}
              >
                <IconMinus />
              </Button>
            )}
            {fields?.length < managedGroups?.length && fields?.length - 1 === index && (
              <Button
                variant="ghost"
                testId={`add-field-${index}`}
                onClick={() => fields.push(undefined)}
              >
                <IconPlus />
              </Button>
            )}
          </div>
        </div>
      ))}
    </section>
  );
};

export default ManagedGroupAndRoles;
