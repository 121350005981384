import { createActions, handleActions, Action } from 'redux-actions';
import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@contactcentre-web/utils/request';

type CurrencyConversionPayload = {
  amount: string;
  marginsBatchId: string;
  ratesBatchId: string;
  currencyCode: string;
  targetCurrencyCode: string;
};

export type CurrencyConversionResponse = {
  amount: number;
  currencyCode: string;
};

export interface CurrencyConverterState {
  open: boolean;
  loading: boolean;
  result: CurrencyConversionResponse | null;
  error: boolean;
}

export const FORM_ID = 'CurrencyConverter';

const PREFIX = 'CURRENCY_CONVERTER';

export const CURRENCY_CONVERTER_OPEN = 'OPEN';
export const CURRENCY_CONVERTER_CLOSE = 'CLOSE';
export const CURRENCY_CONVERTER_CONVERT = 'CONVERT';
export const CURRENCY_CONVERTER_SUCCESS = 'SUCCESS';
export const CURRENCY_CONVERTER_FAILED = 'FAILED';

export const actions = createActions(
  {
    [CURRENCY_CONVERTER_OPEN]: () => null,
    [CURRENCY_CONVERTER_CLOSE]: () => null,
    [CURRENCY_CONVERTER_CONVERT]: (
      amount,
      currencyCode,
      targetCurrencyCode,
      ratesBatchId,
      marginsBatchId
    ) => ({
      amount,
      currencyCode,
      targetCurrencyCode,
      ratesBatchId,
      marginsBatchId,
    }),
    [CURRENCY_CONVERTER_SUCCESS]: (result) => result,
    [CURRENCY_CONVERTER_FAILED]: () => null,
  },
  { prefix: PREFIX }
);

export const initialState = {
  open: false,
  loading: false,
  result: null,
  error: false,
};

type ActionPayload = CurrencyConversionResponse;

const reducer = handleActions<CurrencyConverterState, ActionPayload>(
  {
    [CURRENCY_CONVERTER_OPEN]: (state) => ({
      ...state,
      open: true,
    }),
    [CURRENCY_CONVERTER_CLOSE]: () => ({
      ...initialState,
    }),
    [CURRENCY_CONVERTER_CONVERT]: (state) => ({
      ...state,
      result: null,
      loading: true,
      error: false,
    }),
    [CURRENCY_CONVERTER_SUCCESS]: (state, { payload }) => ({
      ...state,
      result: payload,
      loading: false,
      error: false,
    }),
    [CURRENCY_CONVERTER_FAILED]: (state) => ({
      ...state,
      result: null,
      loading: false,
      error: true,
    }),
  },
  initialState,
  { prefix: PREFIX }
);

export default reducer;

export const selectors = {
  selectIsOpen: (state: CurrencyConverterState) => state.open,
  selectIsLoading: (state: CurrencyConverterState) => state.loading,
  selectResult: (state: CurrencyConverterState) => state.result,
};

export const conversionCurrencyRequest = (payload: CurrencyConversionPayload) =>
  request<CurrencyConversionResponse>('/api/price/priceConversion', {
    method: 'POST',
    body: payload,
  });

export function* convertCurrencySaga({ payload }: Action<CurrencyConversionPayload>) {
  try {
    const response: CurrencyConversionResponse = yield call(conversionCurrencyRequest, payload);
    yield put(actions.success(response));
  } catch (error) {
    yield put(actions.failed());
  }
}

export function* saga() {
  yield takeLatest(`${PREFIX}/${CURRENCY_CONVERTER_CONVERT}`, convertCurrencySaga);
}
